import {
    Avatar,
    Breadcrumbs,
    Button,
    Card,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useMemo } from 'react';
import {
    Link as RouterLink,
    NavLink,
    Navigate,
    useLocation,
    useParams,
    NavLinkProps,
    Outlet,
} from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import { useUser, useUserPermissions } from '~/hooks';
import {
    useFetchPatientForPatientSummaryPageQueryQuery,
    useUpdateCurrentOrgInNavMutation,
} from '~/schemaTypes';
import PatientHeaderComponent from '~/components/PatientHeader/PatientHeaderComponent';
import { useCheckPractice } from '../../../../hooks/useCheckPractice';

type PatientPageLinks = {
    text: string;
    route: string;
    hide: boolean;
};

const useStyles = makeStyles()(theme => ({
    root: {},
    sidebar: {
        minHeight: 400,
    },
    navLink: {
        transition: 'color .2s ease-in-out',
        color: '#000',
        '&:hover': {
            color: theme.colors.Primary(),
        },
        '&.active': {
            color: theme.colors.Primary(),
        },
    },
    navButton: {
        borderRadius: '0px',
        transition: 'color .2s ease-in-out',
        color: '#000',
        '&:hover': {
            color: theme.colors.Primary(),
        },
        '&.active': {
            color: theme.colors.Primary(),
        },
    },
}));

type RouteParams = {
    id: string;
    summaryId: string;
};

const Patient: React.FC = () => {
    const { id, summaryId } = useParams<RouteParams>();
    const { classes } = useStyles();
    const location = useLocation();
    const { data: userData } = useUser();
    const { pagePermissions } = useUserPermissions();
    const { pathname } = location;
    // todo: there could be better way to handle default route for parent component
    const isParent = pathname.split('/').length === 4;
    const PatientPages = useMemo<PatientPageLinks[]>(
        () => [
            {
                text: 'Overview',
                route: `/portal/patients/${id}/overview`,
                hide: !pagePermissions?.PatientDetails.Read,
            },
            {
                text: 'Patient Details',
                route: `/portal/patients/${id}/patient-details`,
                hide: !pagePermissions?.PatientDetails.Read,
            },
            {
                text: 'Appointments',
                route: `/portal/patients/${id}/appointments`,
                hide: !pagePermissions?.Appointments.Read,
            },
            {
                text: 'Measurements',
                route: `/portal/patients/${id}/measurements`,
                hide: !pagePermissions?.Measurements.Read,
            },
            {
                text: 'Survey Responses',
                route: `/portal/patients/${id}/survey-responses`,
                hide: !pagePermissions?.SurveyResponses.Read,
            },
            {
                text: 'HA Tasks',
                route: `/portal/patients/${id}/ha-tasks`,
                hide: !pagePermissions?.HaTasks.Read,
            },
            {
                text: 'Orders',
                route: `/portal/patients/${id}/orders`,
                hide: !pagePermissions?.Orders.Read,
            },
            {
                text: 'Notes',
                route: `/portal/patients/${id}/notes`,
                hide: !pagePermissions?.PatientNotes.Read,
            },
            {
                text: 'Message Center',
                route: `/portal/patients/${id}/chat`,
                hide: !pagePermissions?.MessageCenter.Read,
            },
            {
                text: 'Highlights',
                route: `/portal/patients/${id}/highlights`,
                hide: !pagePermissions?.PatientHighlights.Read,
            },
            {
                text: 'Profile Variables',
                route: `/portal/patients/${id}/profile-variables`,
                hide: !pagePermissions?.PatientProfileVariables.Read,
            },
            {
                text: 'Tags',
                route: `/portal/patients/${id}/tags`,
                hide: !pagePermissions?.PatientProfileVariables.Read,
            },
            {
                text: "To Do's",
                route: `/portal/patients/${id}/todos`,
                hide: !pagePermissions?.PatientTodos.Read,
            },
            {
                text: 'Actions',
                route: `/portal/patients/${id}/actions`,
                hide: !pagePermissions?.PatientActions.Read,
            },
            {
                text: 'Article Feed',
                route: `/portal/patients/${id}/article-feed`,
                hide: !pagePermissions?.PatientArticleFeed.Read,
            },
            {
                text: 'Care Team',
                route: `/portal/patients/${id}/care-team`,
                hide: !pagePermissions?.PatientCareTeam.Read,
            },
            {
                text: 'App Events',
                route: `/portal/patients/${id}/app-events`,
                hide: !pagePermissions?.AppEvents.Read,
            },
            {
                text: 'Clinical Summaries',
                route: `/portal/patients/${id}/clinical-summaries`,
                hide: !pagePermissions?.PatientClinicalSummaries.Read,
            },
        ],
        [id, pagePermissions],
    );

    const { data: patientData, loading: patientDataLoading } =
        useFetchPatientForPatientSummaryPageQueryQuery({
            variables: {
                input: {
                    id,
                },
            },
            fetchPolicy: 'no-cache',
        });

    const patient = patientData?.patient;

    const breadcrumbNameMap = useMemo<Record<string, string>>(
        () => ({
            '/patients': 'Patients',
            [`/portal/patients/${id}`]: patient?.fullName ?? '',
            [`/portal/patients/${id}/overview`]: 'Overview',
            [`/portal/patients/${id}/patient-details`]: 'Patient Details',
            [`/portal/patients/${id}/appointments`]: 'Appointments',
            [`/portal/patients/${id}/measurements`]: 'Measurements',
            [`/portal/patients/${id}/survey-responses`]: 'Survey Responses',
            [`/portal/patients/${id}/ha-tasks`]: 'HA Tasks',
            // [`/portal/patients/${id}/device-orders`]: 'Device Orders',
            [`/portal/patients/${id}/orders`]: 'Orders',
            [`/portal/patients/${id}/notes`]: 'Notes',
            [`/portal/patients/${id}/chat`]: 'Message Center',
            [`/portal/patients/${id}/whats-new`]: "What's New",
            [`/portal/patients/${id}/todos`]: "To Do's",
            [`/portal/patients/${id}/profile-variables`]: 'Profile Variables',
            [`/portal/patients/${id}/tags`]: 'Tags',
            [`/portal/patients/${id}/actions`]: 'Actions',
            [`/portal/patients/${id}/app-events`]: 'App Events',
            [`/portal/patients/${id}/clinical-summaries`]: 'Clinical Summaries',
            [`/portal/patients/${id}/clinical-summaries/${summaryId}`]: 'Clinical Summary',
        }),
        [id, patient?.fullName, summaryId],
    );

    const pathNames = location.pathname.split('/');

    const [updateCurrentOrg] = useUpdateCurrentOrgInNavMutation({
        refetchQueries: ['FetchCurrentUserForUseUserHook'],
    });

    const isWrongPracticeSet = useCheckPractice(
        userData?.currentUser,
        patientData?.patient?.practice.id,
    );
    useEffect(() => {
        if (!isWrongPracticeSet || !userData?.currentUser || !patientData?.patient) {
            return;
        }
        updateCurrentOrg({
            variables: {
                input: {
                    id: userData.currentUser.id,
                    data: {
                        currentOrg: patientData.patient.practice.id,
                    },
                },
            },
        });
    }, [userData, patientData, updateCurrentOrg, isWrongPracticeSet]);

    if (patientDataLoading || isWrongPracticeSet) {
        return <Loading subtitle="Loading patient data" />;
    }
    const CustomLink = React.forwardRef(
        (props: NavLinkProps, ref: React.Ref<HTMLAnchorElement>) => (
            <Button sx={{ width: '100%', padding: '8px 16px', borderRadius: '0' }}>
                <NavLink
                    ref={ref}
                    {...props}
                    className={({ isActive }) => `${classes.navLink} ${isActive ? 'active' : ''}`}
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        textDecoration: 'none',
                    }}
                />
            </Button>
        ),
    );
    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="Navigation Links">
                        {pathNames.map((value, index) => {
                            const last = index === pathNames.length - 1;
                            const to = `${pathNames.slice(0, index + 1).join('/')}`;
                            const label = breadcrumbNameMap[to];

                            return last ? (
                                <Typography color="primary" key={`${label}-${last}`}>
                                    {label}
                                </Typography>
                            ) : (
                                <Link
                                    component={RouterLink}
                                    color="inherit"
                                    to={to}
                                    key={`${label}-${last}`}
                                    underline="hover"
                                >
                                    {value === id ? patient?.fullName : value}
                                </Link>
                            );
                        })}
                    </Breadcrumbs>
                </Grid>
                <Grid item className={classes.sidebar} xs={12} md={3} lg={2}>
                    <Card style={{ padding: 5, position: 'sticky', top: 0 }}>
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        {patient?.firstName?.charAt(0)}
                                        {patient?.lastName?.charAt(0)}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`${patient?.firstName} ${patient?.lastName}`}
                                />
                            </ListItem>
                            <Divider />
                            {PatientPages.filter(page => !page.hide).map(({ text, route }, i) => (
                                <div key={route}>
                                    <ListItem component={CustomLink} to={route} hidden>
                                        <ListItemText
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                            }}
                                            primary={text}
                                        />
                                    </ListItem>
                                    {i < PatientPages.length - 1 && <Divider />}
                                </div>
                            ))}
                        </List>
                    </Card>
                </Grid>
                <Grid item xs={12} md={9} lg={10} style={{ minHeight: '80vh' }}>
                    {patient?.patientHeaderData && patient?.patientHeaderData.length > 0 && (
                        <PatientHeaderComponent
                            patientHeaderItems={patient?.patientHeaderData ?? []}
                        />
                    )}
                    {isParent ? (
                        <Navigate to={`/portal/patients/${id}/overview`} replace />
                    ) : (
                        <Outlet />
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default Patient;
