import {
    Autocomplete,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    TextField,
} from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import React, { useState } from 'react';
import Loading from '~/components/Loading/Loading';
import {
    useFetchTagsForFilterPatientsModalQuery,
    useGetUsersByPermissionForFilterPatientsModalQuery,
} from '~/schemaTypes';
import { PatientEnum } from '~/selectors';

type FilterPatientsModalProps = {
    open: boolean;
    onClose: () => void;
};

export const FilterPatientsModal: React.FC<FilterPatientsModalProps> = ({ open, onClose }) => {
    const [selectedTagIds, setSelectedTagIds] = useState<string[]>(
        localStorage
            .getItem('filteredTags')
            ?.split(',')
            .filter(str => str.length > 1) ?? [],
    );
    const [selectedAdvocate, setSelectedAdvocate] = useState<string>(
        localStorage.getItem('filteredAdvocate') ?? '',
    );
    const [enableAdvocateFilter, setEnableAdvocateFilter] = useState<boolean>(
        localStorage.getItem('enableAdvocateFilter') === 'true' ?? false,
    );
    const [filteredFirstName, setFilteredFirstName] = useState<string>(
        localStorage.getItem('filteredFirstName') ?? '',
    );
    const [filteredLastName, setFilteredLastName] = useState<string>(
        localStorage.getItem('filteredLastName') ?? '',
    );
    const [filteredExternalId, setFilteredExternalId] = useState<string>(
        localStorage.getItem('filteredExternalId') ?? '',
    );
    const [filteredBirthDate, setFilteredBirthDate] = useState<string>(
        localStorage.getItem('filteredBirthDate') ?? '',
    );
    const [filteredEmail, setFilteredEmail] = useState<string>(
        localStorage.getItem('filteredEmail') ?? '',
    );
    const [filteredMemberId, setFilteredMemberId] = useState<string>(
        localStorage.getItem('filteredMemberId') ?? '',
    );
    const [filterTestData, setFilterTestData] = useState<boolean>(
        localStorage.getItem('filterTestData') === 'true' ?? false,
    );

    const { data: tags, loading: tagsLoading } = useFetchTagsForFilterPatientsModalQuery({
        variables: {
            input: {
                filter: {
                    fields: {
                        isPatientFilter: true,
                    },
                },
            },
        },
    });

    const { data: advocates, loading: advocatesLoading } =
        useGetUsersByPermissionForFilterPatientsModalQuery({
            variables: {
                permission: 'isHealthAdvocate',
            },
        });

    const handleSelectTag = (event: React.ChangeEvent<unknown>, value: any) => {
        setSelectedTagIds(value);
    };

    const handleSelectAdvocate = (event: React.ChangeEvent<unknown>, value: any) => {
        setSelectedAdvocate(value);
    };

    const handleSave = () => {
        localStorage.setItem('filteredTags', `${selectedTagIds.join(',')}`);
        if (enableAdvocateFilter && selectedAdvocate) {
            localStorage.setItem('filteredAdvocate', `${selectedAdvocate}`);
        } else {
            localStorage.removeItem('filteredAdvocate');
        }
        localStorage.setItem('filteredFirstName', `${filteredFirstName}`);
        localStorage.setItem('filteredLastName', `${filteredLastName}`);
        localStorage.setItem('filteredExternalId', `${filteredExternalId}`);
        localStorage.setItem('filteredBirthDate', `${filteredBirthDate}`);
        localStorage.setItem('filteredEmail', `${filteredEmail}`);
        localStorage.setItem('filteredMemberId', `${filteredMemberId}`);
        localStorage.setItem('filterTestData', `${filterTestData}`);
        onClose();
    };

    const handleClearFilters = () => {
        setSelectedTagIds([]);
        setSelectedAdvocate('');
        setFilteredFirstName('');
        setFilteredLastName('');
        setFilteredExternalId('');
        setFilteredBirthDate('');
        setFilteredEmail('');
        setFilteredMemberId('');
        setFilterTestData(false);
    };

    if (tagsLoading || advocatesLoading) {
        return <Loading />;
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            data-test={PatientEnum.FILTER_PATIENTS_MODAL}
        >
            <DialogTitle>Filter Patients</DialogTitle>
            <DialogContent>
                <Autocomplete
                    multiple
                    options={tags?.tagsV2.results.map(tag => tag.id) ?? []}
                    getOptionLabel={tag => tags?.tagsV2.results.find(t => t.id === tag)?.name ?? ''}
                    filterSelectedOptions
                    value={selectedTagIds as string[]}
                    onChange={handleSelectTag}
                    disableCloseOnSelect
                    renderInput={params => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <TextField {...params} label="Filter Tags" variant="outlined" />
                    )}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={enableAdvocateFilter}
                            onChange={() => {
                                setEnableAdvocateFilter(!enableAdvocateFilter);
                                localStorage.setItem(
                                    'enableAdvocateFilter',
                                    `${!enableAdvocateFilter}`,
                                );
                            }}
                        />
                    }
                    label="Enable Assigned Health Advocate Filter"
                />
                <Autocomplete
                    options={
                        advocates?.getUsersByPermission
                            ?.map(user => user?.id)
                            .concat(['unassigned']) ?? []
                    }
                    getOptionLabel={user =>
                        advocates?.getUsersByPermission?.find(u => u?.id === user)?.name ?? user
                    }
                    filterSelectedOptions
                    disabled={!enableAdvocateFilter}
                    value={selectedAdvocate as string}
                    onChange={handleSelectAdvocate}
                    renderInput={params => (
                        <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label="Filter Assigned Health Advocates"
                            variant="outlined"
                        />
                    )}
                />
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TextField
                            label="Filter First Name"
                            variant="outlined"
                            fullWidth
                            value={filteredFirstName}
                            data-test={PatientEnum.FILTER_FIRST_NAME}
                            onChange={e => {
                                setFilteredFirstName(e.target.value);
                                localStorage.setItem('filteredFirstName', e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Filter Last Name"
                            variant="outlined"
                            fullWidth
                            value={filteredLastName}
                            data-test={PatientEnum.FILTER_LAST_NAME}
                            onChange={e => {
                                setFilteredLastName(e.target.value);
                                localStorage.setItem('filteredLastName', e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Filter External ID"
                            variant="outlined"
                            fullWidth
                            value={filteredExternalId}
                            onChange={e => {
                                setFilteredExternalId(e.target.value);
                                localStorage.setItem('filteredExternalId', e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DatePicker
                            id="date-picker-dialog"
                            label="Birth Date"
                            autoOk
                            margin="dense"
                            variant="inline"
                            format="MM/dd/yyyy"
                            InputLabelProps={{ shrink: true }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            value={filteredBirthDate ? moment(filteredBirthDate) : null}
                            onChange={(e: Date) => {
                                setFilteredBirthDate(e ? moment(e).format('MM/DD/YYYY') : '');
                                localStorage.setItem('filteredBirthDate', e?.toDateString() ?? '');
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Filter Email"
                            variant="outlined"
                            fullWidth
                            value={filteredEmail}
                            onChange={e => {
                                setFilteredEmail(e.target.value);
                                localStorage.setItem('filteredEmail', e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Filter Member ID"
                            variant="outlined"
                            fullWidth
                            value={filteredMemberId}
                            onChange={e => {
                                setFilteredMemberId(e.target.value);
                                localStorage.setItem('filteredMemberId', e.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filterTestData}
                                onChange={() => {
                                    setFilterTestData(!filterTestData);
                                    localStorage.setItem('filterTestData', `${!filterTestData}`);
                                }}
                            />
                        }
                        label="Enable Test Data To Be Displayed"
                    />
                </Grid>
                <Button onClick={handleClearFilters}>Clear Filters</Button>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setSelectedTagIds(
                            localStorage
                                .getItem('filteredTags')
                                ?.split(',')
                                .filter(str => str.length > 1) ?? [],
                        );
                        setSelectedAdvocate(localStorage.getItem('filteredAdvocate') ?? '');
                        onClose();
                    }}
                    color="primary"
                >
                    Close
                </Button>
                <Button
                    onClick={handleSave}
                    color="primary"
                    variant="contained"
                    data-test={PatientEnum.SUBMIT_FILTER}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
