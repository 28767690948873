import { UserProfileSpecifiedValueType } from '../shared/enums';
import {
    PatientProfileVariable,
    PatientProfileVariableModalFormType,
    PatientProfileVariableModalUpdateInputType,
    PatientProfileVariableSpecifiedValueType,
} from '../shared/types';
import { dateToISOString } from '../../../../../../helpers';

function toPlainStructure<ReturnType>(
    value: any,
    convertFunc: (i: any) => ReturnType = i => i.toString(),
): ReturnType[] {
    if (Array.isArray(value)) {
        return value.map(v => convertFunc(v.value));
    }

    return [];
}

const ProfileValueTypeFormatter = {
    [UserProfileSpecifiedValueType.Str]: (value: any) => value.toString(),
    [UserProfileSpecifiedValueType.Num]: (value: any) => +value,
    [UserProfileSpecifiedValueType.Bool]: (value: any) => value,
    [UserProfileSpecifiedValueType.Date]: (value: any) => dateToISOString(value),
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const toFormDefaultValues = ({
    value: { __typename, ...restValues },
    originId,
    notes,
}: PatientProfileVariable) => ({
    value: {
        ...restValues,
        strs: restValues.strs?.map(i => ({ value: i })) ?? [],
        nums: restValues.nums?.map(i => ({ value: i })) ?? [],
        bools: restValues.bools?.map(i => ({ value: i })) ?? [],
        dates: restValues.dates?.map(i => ({ value: i })) ?? [],
    },
    originId: originId ?? '',
    notes: notes ?? '',
});

export const toUpdateInput = (
    {
        patientId,
        patientProfileVariable: {
            value: { __typename, ...profileVariableValue },
            profileVariableDefId,
        },
        choiceValueType,
    }: {
        patientId: string;
        patientProfileVariable: PatientProfileVariable;
        choiceValueType?: UserProfileSpecifiedValueType | null;
    },
    {
        value: { specifiedValue, ...restFormValues } = {},
        originId: restFormOriginId,
        notes: restFormNotes,
    }: PatientProfileVariableModalFormType,
): PatientProfileVariableModalUpdateInputType => ({
    portalPatientId: patientId,
    profileVariableDefId,
    ...(restFormOriginId ? { originId: restFormOriginId } : {}),
    ...(restFormNotes ? { notes: restFormNotes } : {}),
    value: {
        ...profileVariableValue,
        ...restFormValues,
        num: restFormValues?.num ? +restFormValues.num : null,
        date: restFormValues?.date ? dateToISOString(restFormValues.date) : null,
        strs: toPlainStructure(restFormValues?.strs ?? []),
        nums: toPlainStructure(restFormValues?.nums ?? [], n => +n),
        bools: toPlainStructure(restFormValues?.bools ?? [], b => Boolean(b)),
        dates: toPlainStructure(restFormValues?.dates ?? [], d => dateToISOString(d)),
        specified: specifiedValue
            ? Object.entries(specifiedValue).reduce((result, [key, { value }]) => {
                  if (value === '' || value === null || !choiceValueType) return result;

                  const specifiedValue = {
                      choiceId: key,
                      type: choiceValueType,
                      val: {
                          str: null,
                          num: null,
                          date: null,
                          bool: null,
                          [choiceValueType]: ProfileValueTypeFormatter[choiceValueType](value),
                      },
                  };

                  return [...result, specifiedValue];
              }, [] as PatientProfileVariableSpecifiedValueType[])
            : [],
    },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const toViewModelValue = (value: PatientProfileVariable['value']) => ({
    ...value,
    specified:
        value.specified?.reduce((map, value) => {
            if (value?.choiceId) {
                map.set(value.choiceId, value);
            }

            return map;
        }, new Map<string, PatientProfileVariableSpecifiedValueType>()) ?? null,
});
