import React, { memo } from 'react';
import { Typography, Tooltip, Grid } from '@mui/material';
import { Warning } from '@mui/icons-material';
import { ClaimSubmittedStatus, ClaimSubmitted } from '~/schemaTypes';

type ClaimStatusProps = {
    claim: Pick<ClaimSubmitted, 'status' | 'errorText'>;
    allowUpload: boolean;
};

const ClaimStatus: React.FC<ClaimStatusProps> = ({ claim, allowUpload }) => {
    const { status, errorText } = claim;
    let displayStatus = status === ClaimSubmittedStatus.Completed ? 'Complete' : 'Incomplete';
    if (allowUpload) {
        displayStatus = 'Queued for Uploading';
    }

    const showError = status === ClaimSubmittedStatus.Error && errorText;
    return (
        <Grid container justifyContent="space-between">
            <Grid item>
                <Typography data-test={status}>{displayStatus}</Typography>
            </Grid>
            {showError && (
                <Grid item>
                    <Tooltip title={errorText}>
                        <Warning />
                    </Tooltip>
                </Grid>
            )}
        </Grid>
    );
};

export default memo(ClaimStatus);
