import React from 'react';
import {
    AlertSeverity,
    useDeleteVirtualCareAffiliateMutation,
    useFetchVirtualCareAffiliateByIdQuery,
    useUpdateVirtualCareAffiliatesMutation,
    VirtualCareAffiliatesUpdateInput,
    useGetVendorByIdFromExternalServiceLazyQuery,
} from '~/schemaTypes';
import { VCAffiliateForm } from '~/views/ConfigDashboard/VCAffiliates/components/VCAffiliateForm';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Divider, FormControl, Grid, Typography } from '@mui/material';
import { VCAffiliateFormContextProvider } from '~/views/ConfigDashboard/VCAffiliates/components/VCAffiliateFormContextProvider';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';
import { mapToFormValues } from '~/views/ConfigDashboard/VCAffiliates/mappers';
import { VCAffiliatesURLs } from '~/views/ConfigDashboard/VCAffiliates/components/VCAffiliatesURLs';
import { BackToVCAffiliatesList } from '~/views/ConfigDashboard/VCAffiliates/components/BackToVCAffiliatesList';

export const VCAffiliatesEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const handleCancel = () => {
        navigate('/app-config/vcaffiliates');
    };
    const { data } = useFetchVirtualCareAffiliateByIdQuery({
        variables: {
            input: {
                id: id ?? '',
            },
        },
        onError: () => {
            TriggerGlobalAlert({ severity: AlertSeverity.Error, message: 'Fetching failed' });
        },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const [getVendorQuery, { data: vendorData }] = useGetVendorByIdFromExternalServiceLazyQuery({
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    React.useEffect(() => {
        const vendorId = data?.virtualCareAffiliateById?.data?.BillDotComVendorId;
        if (vendorId) {
            getVendorQuery({
                variables: {
                    input: {
                        id: vendorId,
                    },
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.virtualCareAffiliateById?.data?.BillDotComVendorId]);
    const [updateVirtualCareAffiliate] = useUpdateVirtualCareAffiliatesMutation({
        onCompleted: data => {
            if (data.updateVirtualCareAffiliate?.success) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Success,
                    message: 'Affiliate updated',
                });
                navigate('/app-config/vcaffiliates');
            } else {
                TriggerGlobalAlert({ severity: AlertSeverity.Error, message: 'Update failed' });
            }
        },
    });

    const handleSubmit = (data: VirtualCareAffiliatesUpdateInput) => {
        if (id) {
            updateVirtualCareAffiliate({ variables: { input: { id, data } } });
        }
    };
    const [deleteVirtualCareAffiliate] = useDeleteVirtualCareAffiliateMutation({
        onCompleted: data => {
            if (data.deleteVirtualCareAffiliate?.success) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Success,
                    message: 'Affiliate was deleted',
                });
                navigate('/app-config/vcaffiliates');
            } else {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: 'Affiliate deletion failed',
                });
            }
        },
    });
    const handleDelete = () => {
        if (id) {
            TriggerGlobalConfirm({
                callback: () => deleteVirtualCareAffiliate({ variables: { input: id } }),
                message: 'Are you sure you want to delete this Affiliate?',
            });
        }
    };
    const affiliateFormValues = React.useMemo(
        () => mapToFormValues(data, vendorData),
        [data, vendorData],
    );

    const affiliateId = data?.virtualCareAffiliateById?.data?.affiliateId;
    const affiliateURL = data?.virtualCareAffiliateById?.affiliateURL;
    const affiliateSelfCheckURL = data?.virtualCareAffiliateById?.affiliateSelfCheckURL;
    return (
        <>
            <BackToVCAffiliatesList />
            <Typography variant="h6" className="title" paddingLeft="10px">
                Edit VC Affiliate
            </Typography>
            <Divider style={{ marginTop: '10px', marginBottom: '20px' }} />
            <VCAffiliatesURLs
                affiliateId={affiliateId}
                affiliateURL={affiliateURL}
                affiliateSelfCheckURL={affiliateSelfCheckURL}
            />
            <VCAffiliateFormContextProvider>
                <VCAffiliateForm
                    handleFormSubmit={handleSubmit}
                    values={affiliateFormValues}
                    isEdit
                >
                    <Grid xs={12} container item justifyContent="space-between">
                        <Grid>
                            <FormControl>
                                <Button variant="outlined" onClick={handleCancel}>
                                    Cancel
                                </Button>
                            </FormControl>
                            <FormControl>
                                <Button type="submit" color="secondary" variant="contained">
                                    Save
                                </Button>
                            </FormControl>
                        </Grid>
                        <Grid>
                            <FormControl>
                                <Button color="error" variant="contained" onClick={handleDelete}>
                                    Delete
                                </Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                </VCAffiliateForm>
            </VCAffiliateFormContextProvider>
        </>
    );
};
