import * as yup from 'yup';
import { OnSuccessfulZipCodeMatch } from '~/schemaTypes';
import { IVCAffiliatesFormValues } from '~/views/ConfigDashboard/VCAffiliates/interfaces/IVCAffiliatesFormValues';

export enum ON_SUCCESSFUL_ELIGIBILITY {
    ROUTE_DIRECTLY = 'ROUTE_DIRECTLY',
    ROUTE_TO_LANDING = 'ROUTE_TO_LANDING',
}

const isEmailSchema = yup.string().email();

export const VALIDATION_SCHEMA = (isEdit: boolean): yup.ObjectSchema<IVCAffiliatesFormValues> =>
    yup
        .object({
            internalName: yup.string().required(),
            externalName: yup.string().required(),
            contactName: yup.string().required(),
            contactEmail: yup.string().email().required(),
            contactPhoneNumber: yup.string().optional(),
            supportMethodType: yup.string().required(),
            supportMethodValue: yup.string().required(),
            onSuccessfulEligibility: yup.string().required(),
            routingURL: yup.string().optional(),
            phoneNumberForScheduling: yup.string().optional(),
            emailAddressForScheduling: yup.string().email().optional(),
            customSuccessText: yup.string().optional(),
            notifyCompanyWhenPatientPassesEligibility: yup.boolean().optional(),
            notificationEmail: yup.string().test('emails', 'Invalid emails', value => {
                if (!value) {
                    return true;
                }
                const invalidEmail = value
                    .split(',')
                    .map((email: string) => email.trim())
                    .find((v: string) => !isEmailSchema.isValidSync(v));
                return !invalidEmail;
            }),
            onSuccessfulZipCodeMatch: yup
                .mixed<OnSuccessfulZipCodeMatch>()
                .oneOf(Object.values(OnSuccessfulZipCodeMatch))
                .optional(),
            zipCodeMatchCustomSuccessString: yup
                .string()
                .when(['onSuccessfulZipCodeMatch'], ([onSuccessfulZipCodeMatch], schema) => {
                    if (onSuccessfulZipCodeMatch === OnSuccessfulZipCodeMatch.ToSuccessScreen) {
                        return schema.required();
                    }
                    return schema.nullable();
                }),
            BillDotComNameOnCheck: isEdit ? yup.string().optional() : yup.string().required(),
            BillDotComAddress1: isEdit ? yup.string().optional() : yup.string().required(),
            BillDotComAddress2: yup.string().optional(),
            BillDotComAddressCity: isEdit ? yup.string().optional() : yup.string().required(),
            BillDotComAddressState: isEdit ? yup.string().optional() : yup.string().required(),
            BillDotComAddressZip: isEdit ? yup.string().optional() : yup.string().required(),
            BillDotComVendorId: isEdit ? yup.string().required() : yup.string().optional(),
            website: yup.string().optional(),
            inviteAffiliate: isEdit ? yup.boolean().optional() : yup.boolean().required(),
        })
        .required();
