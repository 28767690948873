import React from 'react';
import { Grid, IconButton, TextField, Tooltip } from '@mui/material';
import copy from 'copy-to-clipboard';
import { TriggerGlobalAlert } from '~/state';
import { AlertSeverity } from '~/schemaTypes';
import { ContentCopy } from '@mui/icons-material';

interface IVCAffiliatesURLs {
    affiliateId?: string | null;
    affiliateURL?: string | null;
    affiliateSelfCheckURL?: string | null;
}

export const VCAffiliatesURLs: React.FC<IVCAffiliatesURLs> = props => {
    const { affiliateId, affiliateURL, affiliateSelfCheckURL } = props;
    return (
        <>
            <Grid container>
                <Grid item xs={10} sm={6}>
                    <TextField disabled fullWidth value={affiliateId ?? ''} label="Affiliate ID" />
                </Grid>
                <Grid item>
                    <Tooltip title={affiliateId}>
                        <IconButton
                            onClick={() => {
                                copy(`${affiliateId}`);
                                TriggerGlobalAlert({
                                    message: 'VC Affiliate ID is copied to clipboard',
                                    severity: AlertSeverity.Success,
                                });
                            }}
                            size="large"
                        >
                            <ContentCopy />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={10} sm={6}>
                    <TextField
                        disabled
                        fullWidth
                        value={affiliateURL ?? ''}
                        label="Affiliate URL"
                    />
                </Grid>
                <Grid item>
                    <Tooltip title={affiliateURL}>
                        <IconButton
                            onClick={() => {
                                copy(`${affiliateURL}`);
                                TriggerGlobalAlert({
                                    message: 'VC Affiliate URL is copied to clipboard',
                                    severity: AlertSeverity.Success,
                                });
                            }}
                            size="large"
                        >
                            <ContentCopy />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={10} sm={6}>
                    <TextField
                        disabled
                        fullWidth
                        value={affiliateSelfCheckURL || ''}
                        label="Affiliate Self-check URL"
                    />
                </Grid>
                <Grid item>
                    <Tooltip title={affiliateSelfCheckURL}>
                        <IconButton
                            onClick={() => {
                                copy(`${affiliateSelfCheckURL}`);
                                TriggerGlobalAlert({
                                    message: 'VC Affiliate Self Check URL is copied to clipboard',
                                    severity: AlertSeverity.Success,
                                });
                            }}
                            size="large"
                        >
                            <ContentCopy />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </>
    );
};
