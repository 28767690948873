import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { FetchSurveyResponsesForSurveyPageQueryQuery } from '~/schemaTypes';

type SurveyResponse = NonNullable<
    NonNullable<FetchSurveyResponsesForSurveyPageQueryQuery['currentUser']>['surveyResponsesV2']
>['results'][0];

const useStyles = makeStyles()({
    root: {
        padding: 40,
        '& > *:not(:last-child)': {
            marginBottom: 15,
        },
    },
});

type SurveyResultsProps = {
    survey?: SurveyResponse;
    results: SurveyResponse['answers'];
    score?: SurveyResponse['surveyScore'];
};

const SurveyResults: React.FC<SurveyResultsProps> = ({ survey, results, score }) => {
    const { classes } = useStyles();
    return (
        <div className={classes.root}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Typography variant="h5">Results</Typography>
                <Grid>
                    <Typography variant="subtitle2">{survey?.patient?.fullName}</Typography>
                    <Typography variant="subtitle2">
                        Date Taken: {moment(survey?.responseDate).format('MM/DD/YYYY')}
                    </Typography>
                </Grid>
            </Grid>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Question</TableCell>
                        <TableCell>Answer</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {_.chain(results)
                        .filter(({ questionId }) => !!questionId)
                        .sortBy(item => item.questionOrder)
                        .map((questionAnswer, index) => (
                            <TableRow>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{questionAnswer?.questionId}</TableCell>
                                <TableCell>{questionAnswer?.questionText}</TableCell>
                                <TableCell>
                                    <Grid container spacing={1} direction="column">
                                        {Array.isArray(questionAnswer?.answerText) ? (
                                            questionAnswer?.answerText?.map((answer: string) => (
                                                <Grid item>{`${answer}`}</Grid>
                                            ))
                                        ) : (
                                            <Grid item>{`${questionAnswer?.answerText}`}</Grid>
                                        )}
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        ))
                        .value()}
                </TableBody>
            </Table>
            {score && (
                <Typography>
                    Overall survey score: <strong>{score}</strong>
                </Typography>
            )}
        </div>
    );
};

export default SurveyResults;
