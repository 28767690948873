// This component has a UI Test
import {
    Badge,
    Checkbox,
    CircularProgress,
    Dialog,
    FormControlLabel,
    IconButton,
    Link as MuiLink,
    Menu,
    MenuItem,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    TablePagination,
} from '@mui/material';
import {
    Add,
    CloudUploadOutlined,
    Download,
    FilterList,
    InfoOutlined,
    Loop,
    MoreVert,
} from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import { format, toDate } from 'date-fns-tz';
import _ from 'lodash';
import MaterialTable, { MaterialTableProps, OrderByCollection } from '@material-table/core';
import moment from 'moment';
import React, { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import CSVImportModal from '~/components/CSVImportModal/CSVImportModal';
import { IFilterChange } from '~/components/CustomFilter/CustomFilter';
import tableIcons from '~/helpers/tableIcons';
import useUserPermissions from '~/hooks/useUserPermissions';
import {
    AlertSeverity,
    FetchPatientsForPatientsPageQueryDocument,
    FetchPatientsForPatientsPageQueryQuery,
    FetchPatientsForPatientsPageQueryQueryVariables,
    OrderByDirectionEnum,
    PatientFilterFilterInput,
    PatientInvitation,
    UploadManyPatientsForImporterDocument,
    UploadManyPatientsForImporterMutation,
    useDeletePatientOnPatientsPageMutation,
    useFetchPatientsExportQuery,
    useFetchPatientsForPatientsPageQueryQuery,
    useResendPatientInviteOnPatientsPageMutation,
    useResetPatientPasswordOnPatientsPageMutation,
    useUsersV2ForPatientsPageQuery,
} from '~/schemaTypes';
import { PatientEnum } from '~/selectors';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';
import { PAGESIZE } from '~/constants';
import { UserProfileDefsEnum } from '~/enums/UserProfileDefsEnum';
import { primaryColor } from '~/theme/WildTheme';
import { useAtom } from 'jotai';
import PatientProfileVariableLink from './PatientProfileVariableLink/PatientProfileVariableLink';
import { FilterColumnsModal } from './FilterColumnsModal/FilterColumnsModal';
import { FilterPatientsModal } from './FilterPatientsModal/FilterPatientsModal';
import PatientModal, { PATIENT_VALIDATION_SCHEMA } from './PatientModal/PatientModal';
import PatientNotesModal from './PatientNotesModal/PatientNotesModal';
import useStyles from './styles';
import { IPatientOrderChange, PatientPVRelatedFields, PatientSortingFields } from './types';
import VBCPatientModal from './VBCPatientModal/VBCPatientModal';
import { AddToMassUpdateModal } from './MassUpdateModal/AddToMassUpdateModal';
import { PatientsHiddenColumnsAtom } from './Patients.context';

type Patient = NonNullable<
    NonNullable<
        NonNullable<FetchPatientsForPatientsPageQueryQuery['currentUser']>['patientsV2']
    >['results'][0]
>;

// corresponds to columns={[]} passed to MaterialUI
const columnIdToSortingFieldMap: Record<number, PatientSortingFields | PatientPVRelatedFields> = {
    2: PatientSortingFields.firstName,
    3: PatientSortingFields.lastName,
    5: PatientSortingFields.createdAt,
    6: PatientSortingFields.assignedHealthAdvocate,
    11: PatientSortingFields.birthDate,
    13: PatientSortingFields.onboardingCompleteDate,
    14: PatientSortingFields.isPregnant,
    15: PatientSortingFields.dueDate,
    16: PatientSortingFields.deliveryDate,
};

const Patients: React.FC = () => {
    const { classes } = useStyles();
    const tableRef = useRef<HTMLDivElement>(null);

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [order, setOrder] = useState<IPatientOrderChange>({
        field: PatientSortingFields.createdAt,
        direction: OrderByDirectionEnum.Desc,
        isInit: true,
        fieldIsProfileDefId: false,
    });
    const [filter] = useState<IFilterChange<Patient> | null>(null);
    const [openFilterPatientsModal, setOpenFilterPatientsModal] = useState<boolean>(false);
    const [hiddenColumns] = useAtom(PatientsHiddenColumnsAtom);

    const filteredTags = localStorage.getItem('filteredTags');
    const filteredAdvocate = localStorage.getItem('filteredAdvocate');
    const filteredFirstName = localStorage.getItem('filteredFirstName');
    const filteredLastName = localStorage.getItem('filteredLastName');
    const filteredExternalId = localStorage.getItem('filteredExternalId');
    const filteredBirthDate = localStorage.getItem('filteredBirthDate');
    const filteredEmail = localStorage.getItem('filteredEmail');
    const filteredMemberId = localStorage.getItem('filteredMemberId');
    const filterTestData = localStorage.getItem('filterTestData') === 'true' ?? false;

    const fields = useMemo(() => {
        const fields: string[] = [];
        if (filteredTags) {
            fields.push('tagIds');
        }
        if (filteredAdvocate) {
            fields.push('assignedHealthAdvocate');
        }
        return fields;
    }, [filteredTags, filteredAdvocate]);

    const values = useMemo(() => {
        const values: (string | null)[][] = [];
        if (filteredTags) {
            values.push(filteredTags?.split(',') || ['']);
        }
        if (filteredAdvocate) {
            if (filteredAdvocate === 'unassigned') {
                values.push([null]);
            } else {
                values.push([filteredAdvocate || '']);
            }
        }
        return values;
    }, [filteredTags, filteredAdvocate]);

    // IMPORTANT: all patient entity related fields must use default filtering functionality from base resolver SYS-8582
    const filtersFromFilterModal = useMemo<IFilterChange<Patient>>(() => {
        const processed: IFilterChange<Patient> = {
            fields: {},
        };
        if (filteredFirstName) {
            processed.fields.firstName = filteredFirstName;
        }
        if (filteredLastName) {
            processed.fields.lastName = filteredLastName;
        }
        if (filteredEmail) {
            processed.fields.email = filteredEmail;
        }
        if (filteredExternalId) {
            processed.fields.externalId = filteredExternalId;
        }
        if (filteredBirthDate) {
            processed.fields.birthDate = filteredBirthDate;
        }
        if (filteredMemberId) {
            processed.fields.memberId = filteredMemberId;
        }
        return processed;
    }, [
        filteredFirstName,
        filteredLastName,
        filteredEmail,
        filteredExternalId,
        filteredBirthDate,
        filteredMemberId,
    ]);

    const fetchPatientsForPatientsPageQueryVariables: FetchPatientsForPatientsPageQueryQueryVariables =
        {
            input: {
                pagination: {
                    skip: page * rowsPerPage,
                    limit: rowsPerPage,
                },
                orderBy: {
                    field: order.field,
                    order: order.direction,
                    fieldIsProfileDefId: order.fieldIsProfileDefId,
                },
                filter: { ...filter, ...filtersFromFilterModal } as PatientFilterFilterInput | null,
                fields,
                values,
                filterTestData,
            },
        };

    const {
        data: patientsPageData,
        loading: patientsDataLoading,
        refetch: refetchPatientsPageData,
    } = useFetchPatientsForPatientsPageQueryQuery({
        variables: fetchPatientsForPatientsPageQueryVariables,
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true, // triggers loading to change even when calling refetch https://www.apollographql.com/docs/react/data/queries/#inspecting-loading-states
    });

    const [resendPatientInvite, { loading: patientInviteLoading }] =
        useResendPatientInviteOnPatientsPageMutation();

    const [resetPatientPassword, { loading: patientResetPasswordLoading }] =
        useResetPatientPasswordOnPatientsPageMutation();

    const [deletePatient, { loading: deletePatientLoading }] =
        useDeletePatientOnPatientsPageMutation({
            onCompleted: () => {
                refetchPatientsPageData();
            },
        });

    const { data: usersForAssignedHealthAdvocate, loading: usersToAssignLoading } =
        useUsersV2ForPatientsPageQuery();

    const [importOpen, setImportOpen] = useState<boolean>(false);
    const [questionsModalOpen, setQuestionsModalOpen] = useState<boolean>(false);
    const [filterAnsweredQuestions, setFilterAnsweredQuestions] = useState<boolean>(false);
    const [showPatientModal, setShowPatientModal] = useState(false);
    const [showMassUpdateModal, setShowMassUpdateModal] = useState(false);
    const [editPatientId, setEditPatientId] = useState<string>('');
    const [selectedPatient, setSelectedPatient] = useState<Patient>();
    const [patientResendLoadingId, setPatientResendLoadingId] = useState<string>('');
    const [patientResetPasswordLoadingId, setPatientResetPasswordLoadingId] = useState<string>('');
    const { pagePermissions } = useUserPermissions();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isPatientNotesModalOpen, setIsPatientNotesModalOpen] = useState<boolean>(false);
    const [hiddenColumnsAnchorEl, setHiddenColumnsAnchorEl] = useState<null | HTMLElement>(null);
    const [exportingStatus, setExportingStatus] = useState(false);

    const handleOpenHiddenColumnsModal = (event: React.MouseEvent<HTMLButtonElement>) => {
        setHiddenColumnsAnchorEl(event.currentTarget);
    };

    const VBC_PLATFORM = 'VBC';

    const handleResendInvite = async (id: string) => {
        resendPatientInvite({
            variables: {
                input: {
                    id,
                },
            },
        });
    };

    const handleResetPassword = async (id: string) => {
        resetPatientPassword({
            variables: {
                input: {
                    id,
                },
            },
        });
    };

    const handleMoreInfoClick = (event: any, patient: Patient) => {
        setSelectedPatient(patient);
        setAnchorEl(event.currentTarget);
    };

    const getDirection = (order: string): OrderByDirectionEnum =>
        order.toUpperCase() === OrderByDirectionEnum.Asc
            ? OrderByDirectionEnum.Asc
            : OrderByDirectionEnum.Desc;

    const handleOrderChange = (orderByCollection: OrderByCollection[]) => {
        // material table passes empty array some times, which causes undefined error
        if (!orderByCollection || orderByCollection.length === 0) {
            return;
        }
        const orderBy = orderByCollection[0];
        const field = columnIdToSortingFieldMap[orderBy.orderBy];
        let isInit = false;
        let fieldIsProfileDefId = false;
        if (field) {
            if (field !== order.field) {
                isInit = true;
            }
            const direction = getDirection(orderBy.orderDirection);
            const isPVRelated = Object.values(PatientPVRelatedFields).includes(
                field as PatientPVRelatedFields,
            );
            if (isPVRelated) {
                fieldIsProfileDefId = true;
            }
            const stateObject: IPatientOrderChange = {
                direction,
                field,
                isInit,
                fieldIsProfileDefId,
            };
            setOrder(stateObject);
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const getHeaderStyle = (field: PatientSortingFields): React.CSSProperties => ({
        fontWeight: order.field === field ? 'bold' : 'initial',
    });
    const getRiskColorStyles = (riskValue: number): React.CSSProperties => {
        const defaultStyle = {
            backgroundColor: 'white',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            minHeight: 86,
            height: '100%',
            flexDirection: 'column' as any,
            paddingTop: '16px',
            paddingBottom: '16px',
            lineHeight: 1,
            fontWeight: 'normal',
        };

        switch (riskValue) {
            case 1:
                defaultStyle.backgroundColor = '#D9EAD3';
                break;
            case 2:
            case 3:
                defaultStyle.backgroundColor = '#FFF2CC';
                break;
            case 4:
            case 5:
                defaultStyle.backgroundColor = '#E6B8AF';
                defaultStyle.fontWeight = 'bold';
                break;
            case 6:
                defaultStyle.backgroundColor = '#D9D3E9';
                defaultStyle.fontWeight = 'bold';
                break;
            default:
                break;
        }

        return defaultStyle;
    };

    let PatientAddModal = null;
    let showAddPatientButton = true;
    const parentPlatform = patientsPageData?.currentUser?.currentOrg?.appPlatform ?? null;
    const parentBundleId = patientsPageData?.currentUser?.currentOrg?.appBundleId ?? null;
    const usersForAssignedHealthAdvocateList = usersForAssignedHealthAdvocate?.usersV2?.results;
    const isVBCPlatform = parentPlatform === VBC_PLATFORM;
    if (isVBCPlatform) {
        if (parentBundleId == null) {
            showAddPatientButton = false;
        } else {
            PatientAddModal = (
                <VBCPatientModal
                    setOpen={setShowPatientModal}
                    onCreateCompleted={refetchPatientsPageData}
                    bundleId={parentBundleId}
                    setEditPatientId={setEditPatientId}
                    id={editPatientId}
                />
            );
        }
    } else {
        PatientAddModal = (
            <PatientModal
                setOpen={setShowPatientModal}
                setEditPatientId={setEditPatientId}
                id={editPatientId}
                onCreateCompleted={refetchPatientsPageData}
            />
        );
    }

    const { refetch: fetchPatientsBuffer } = useFetchPatientsExportQuery({
        fetchPolicy: 'no-cache',
        // prevent query execution on each render
        // lazy query executes on each variable update
        skip: true,
        onCompleted: data => {
            setExportingStatus(false);
            const { buffer } = new Uint8Array(
                data.currentUser?.exportPatients.data as unknown as Buffer,
            );
            const blob = new Blob([buffer]);

            const link = document.createElement('a');

            link.setAttribute('href', window.URL.createObjectURL(blob));
            link.setAttribute('download', data.currentUser?.exportPatients.fileName || '');
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        onError: () => {
            setExportingStatus(false);
        },
    });

    const riskScoreEnabled =
        patientsPageData?.currentUser?.currentOrg?.configOptions?.riskScoreEnabled ?? false;

    const handleDownloadPatientsCsv = () => {
        if (exportingStatus) return;
        setExportingStatus(true);

        const buildExcludedColumns = () => {
            return Object.keys(
                Object.fromEntries(Object.entries(hiddenColumns).filter(([, cValue]) => cValue)),
            );
        };

        fetchPatientsBuffer({
            input: {
                filter: {
                    ...filter,
                    ...filtersFromFilterModal,
                } as PatientFilterFilterInput | null,
                fields,
                values,
                filterTestData,
                exclude: buildExcludedColumns(),
            },
        });
    };
    return (
        <div
            className={classes.root}
            data-test={PatientEnum.PATIENTS_CONTAINER}
            ref={tableRef}
            style={{ overflow: 'auto' }}
        >
            <MaterialTable<Patient>
                isLoading={patientsDataLoading || deletePatientLoading || usersToAssignLoading}
                icons={tableIcons as MaterialTableProps<any>['icons']}
                actions={[
                    {
                        tooltip: 'Filter Patients',
                        onClick: () => {
                            setOpenFilterPatientsModal(true);
                        },
                        icon: () => (
                            <Badge
                                badgeContent={
                                    (localStorage
                                        .getItem('filteredTags')
                                        ?.split(',')
                                        .filter(str => str.length > 1).length as number) +
                                        (fields[0] === 'tagIds'
                                            ? values.length - 1
                                            : values.length) || 0
                                }
                                color="error"
                                data-test={PatientEnum.FILTER_PATIENTS_BUTTON}
                                style={{
                                    padding: '12px',
                                    backgroundColor: primaryColor,
                                    color: 'white',
                                    fontSize: '1rem',
                                    borderRadius: '1rem',
                                }}
                            >
                                Filter Patients
                            </Badge>
                        ),
                        isFreeAction: true,
                    },
                    {
                        onClick: event => {
                            handleOpenHiddenColumnsModal(event);
                        },
                        tooltip: 'Filter Columns',
                        icon: () => <FilterList />,
                        isFreeAction: true,
                    },
                    {
                        onClick: () => {
                            setEditPatientId('');
                            setShowPatientModal(true);
                        },
                        hidden: !pagePermissions?.PatientDetails.Edit || !showAddPatientButton,
                        icon: () => <Add />,
                        tooltip: !patientsPageData?.currentUser?.currentOrg.allowPatientCreation
                            ? 'Update practice settings to allow patient creation for this practice'
                            : 'Add New Patient',
                        isFreeAction: true,
                        disabled: !patientsPageData?.currentUser?.currentOrg.allowPatientCreation,
                    },
                    {
                        onClick: () => setImportOpen(true),
                        hidden: !pagePermissions?.PatientDetails.Edit || isVBCPlatform,
                        icon: () => <CloudUploadOutlined />,
                        tooltip: !patientsPageData?.currentUser?.currentOrg.allowPatientCreation
                            ? 'Update practice settings to allow patient creation for this practice'
                            : 'Import Patients',
                        isFreeAction: true,
                        disabled: !patientsPageData?.currentUser?.currentOrg.allowPatientCreation,
                    },
                    {
                        onClick: (e, patient) => handleMoreInfoClick(e, patient as Patient),
                        icon: () => <MoreVert />,
                    },
                    {
                        tooltip: 'Export CSV',
                        onClick: handleDownloadPatientsCsv,
                        icon: () =>
                            exportingStatus ? <Loop className={classes.rotating} /> : <Download />,
                        isFreeAction: true,
                        hidden: !pagePermissions?.PatientDetails.Export,
                    },
                ]}
                columns={[
                    {
                        title: 'Invitation Code',
                        field: 'invitationCode',
                        width: 32,
                        sorting: false,
                        align: 'center',
                        hidden: hiddenColumns.invitationCode,
                    },
                    {
                        title: 'External Id',
                        field: 'externalId',
                        align: 'center',
                        sorting: false,
                        hidden: hiddenColumns.externalId,
                        render: ({ externalId }) => {
                            if (externalId) {
                                return (
                                    <Tooltip title={externalId ?? ''}>
                                        <IconButton
                                            onClick={() => {
                                                copy(`${externalId}`);
                                                TriggerGlobalAlert({
                                                    message: 'External Id Copied to Clipboard',
                                                    severity: AlertSeverity.Success,
                                                });
                                            }}
                                            size="large"
                                        >
                                            <InfoOutlined />
                                        </IconButton>
                                    </Tooltip>
                                );
                            }
                            return '';
                        },
                    },
                    {
                        title: 'First Name',
                        field: 'firstName',
                        width: 32,
                        render: ({ id, firstName }) => (
                            <MuiLink
                                to={`/portal/patients/${id}`}
                                component={Link}
                                className={classes.tasksButton}
                                underline="hover"
                            >
                                <Typography>{firstName}</Typography>
                            </MuiLink>
                        ),
                        headerStyle: getHeaderStyle(PatientSortingFields.firstName),
                        hidden: hiddenColumns.firstName,
                    },
                    {
                        title: 'Last Name',
                        field: 'lastName',
                        width: 32,
                        render: ({ id, lastName }) => (
                            <MuiLink
                                to={`/portal/patients/${id}`}
                                component={Link}
                                className={classes.tasksButton}
                                underline="hover"
                            >
                                <Typography>{lastName}</Typography>
                            </MuiLink>
                        ),
                        headerStyle: getHeaderStyle(PatientSortingFields.lastName),
                        hidden: hiddenColumns.lastName,
                    },
                    {
                        title: 'Risk Score',
                        render: ({ riskScore }) => (
                            <Typography sx={getRiskColorStyles(riskScore?.value ?? 0)}>
                                {riskScore?.tierName && (
                                    <>
                                        <span>{riskScore?.tierName}</span>
                                        <br />
                                    </>
                                )}
                                <span>{riskScore?.value}</span>
                            </Typography>
                        ),
                        customSort: (a, b) => {
                            const firstVal = a.riskScore?.value ?? 0;
                            const secondVal = b.riskScore?.value ?? 0;
                            return firstVal - secondVal;
                        },
                        width: 32,
                        cellStyle: { padding: 0 },
                        hidden:
                            !riskScoreEnabled ||
                            !pagePermissions?.PatientRiskScore.Read ||
                            hiddenColumns.riskScore,
                    },
                    {
                        title: 'Created',
                        render: ({ createdAt }) => (
                            <Typography>
                                {createdAt && moment.utc(createdAt).format('MM/DD/yyyy')}
                            </Typography>
                        ),
                        customSort: (a, b) =>
                            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
                        headerStyle: getHeaderStyle(PatientSortingFields.createdAt),
                        hidden: hiddenColumns.createdAt,
                    },
                    {
                        title: 'Assigned Health Advocate',
                        field: 'assignedHealthAdvocate',
                        render: ({ assignedHealthAdvocate }) => (
                            <Typography>
                                {
                                    usersForAssignedHealthAdvocateList?.find(
                                        user => user.id === assignedHealthAdvocate,
                                    )?.name
                                }
                            </Typography>
                        ),
                        headerStyle: getHeaderStyle(PatientSortingFields.assignedHealthAdvocate),
                        hidden: hiddenColumns.assignedHealthAdvocate,
                    },
                    {
                        title: 'Open Tasks',
                        field: 'openAdvocateTaskCount',
                        disableClick: !pagePermissions?.HealthAdvocate.Read,
                        render: ({ openAdvocateTaskCount, id }) => (
                            <MuiLink
                                to={`/portal/patients/${id}/ha-tasks`}
                                component={Link}
                                className={classes.tasksButton}
                                underline="hover"
                            >
                                <Typography>{openAdvocateTaskCount}</Typography>
                            </MuiLink>
                        ),
                        hidden: hiddenColumns.openAdvocateTaskCount,
                        sorting: false,
                    },
                    {
                        title: 'Open Critical Tasks',
                        field: 'openCriticalAdvocateTaskCount',
                        disableClick: !pagePermissions?.HealthAdvocate.Read,
                        render: ({ openCriticalAdvocateTaskCount, id }) => (
                            <MuiLink
                                to={`/portal/patients/${id}/ha-tasks`}
                                component={Link}
                                className={classes.tasksButton}
                                underline="hover"
                            >
                                <Typography>{openCriticalAdvocateTaskCount}</Typography>
                            </MuiLink>
                        ),
                        hidden: hiddenColumns.openCriticalAdvocateTaskCount,
                        sorting: false,
                    },
                    {
                        title: 'App Registration Complete',
                        render: ({ onboardingDate }) => (
                            <Typography>
                                {onboardingDate && moment.utc(onboardingDate).format('MM/DD/yyyy')}
                            </Typography>
                        ),
                        customSort: (a, b) =>
                            new Date(a.onboardingDate ?? '').getTime() -
                            new Date(b.onboardingDate ?? '').getTime(),
                        sorting: false,
                        hidden: hiddenColumns.appRegistrationCompleted,
                    },
                    {
                        title: 'Call Status',
                        width: 32,
                        sorting: false,
                        align: 'center',
                        hidden: hiddenColumns.patientCallStatus,
                        render: ({ id, patientCallStatus, appBundleId }) => (
                            <PatientProfileVariableLink
                                patientId={id}
                                appBundleId={appBundleId || ''}
                                profileVariableDefId={UserProfileDefsEnum.CALL_STATUS}
                            >
                                {updatedPatientCallStatus => (
                                    <Typography>
                                        {updatedPatientCallStatus ?? patientCallStatus}
                                    </Typography>
                                )}
                            </PatientProfileVariableLink>
                        ),
                    },
                    {
                        title: 'Birth Date',
                        render: ({ birthDate, id, appBundleId }) => (
                            <PatientProfileVariableLink
                                patientId={id}
                                appBundleId={appBundleId || ''}
                                profileVariableDefId={UserProfileDefsEnum.BIRTH_DATE}
                            >
                                {updatedBirthDate => (
                                    <Typography>
                                        {(updatedBirthDate ?? birthDate) &&
                                            format(
                                                // dates are recieved as UTC ISO strings by default, we need to screen out the timestamp to avoid recalculation
                                                toDate(
                                                    (updatedBirthDate ?? birthDate).split('T')[0],
                                                ),
                                                'MM/dd/yyyy',
                                            )}
                                    </Typography>
                                )}
                            </PatientProfileVariableLink>
                        ),
                        customSort: (a, b) =>
                            new Date(a.birthDate ?? '').getTime() -
                            new Date(b.birthDate ?? '').getTime(),
                        headerStyle: getHeaderStyle(PatientSortingFields.birthDate),
                        hidden: hiddenColumns.birthDate,
                    },
                    {
                        title: 'Practice',
                        field: 'practice.name',
                        sorting: false,
                        render: ({ id, practice }) => (
                            <MuiLink
                                to={`/portal/patients/${id}/care-team`}
                                component={Link}
                                className={classes.tasksButton}
                                underline="hover"
                            >
                                <Typography>{practice.name}</Typography>
                            </MuiLink>
                        ),
                        hidden: hiddenColumns.practice,
                    },
                    {
                        title: 'Onboarding Completed',
                        render: ({ onboardingCompleteDate }) => (
                            <Typography>
                                {onboardingCompleteDate &&
                                    moment.utc(onboardingCompleteDate).format('MM/DD/yyyy')}
                            </Typography>
                        ),
                        customSort: (a, b) =>
                            new Date(a.onboardingCompleteDate).getTime() -
                            new Date(b.onboardingCompleteDate).getTime(),
                        hidden: hiddenColumns.onboardingCompleteDate,
                        headerStyle: getHeaderStyle(PatientSortingFields.onboardingCompleteDate),
                    },
                    {
                        title: 'Is Pregnant',
                        render: ({ isPregnant, id, appBundleId }) => (
                            <PatientProfileVariableLink
                                patientId={id}
                                appBundleId={appBundleId || ''}
                                profileVariableDefId={UserProfileDefsEnum.IS_PREGNANT}
                            >
                                {updatedIsPregnant => (
                                    <Typography>
                                        {updatedIsPregnant ?? isPregnant ? 'Yes' : 'No'}
                                    </Typography>
                                )}
                            </PatientProfileVariableLink>
                        ),
                        customSort: (a, b) => (a.isPregnant ? 1 : 0) - (b.isPregnant ? 1 : 0),
                        hidden: hiddenColumns.isPregnant,
                        headerStyle: getHeaderStyle(PatientSortingFields.isPregnant),
                    },
                    {
                        title: 'Due Date',
                        render: ({ dueDate, id, appBundleId }) => (
                            <PatientProfileVariableLink
                                patientId={id}
                                appBundleId={appBundleId || ''}
                                profileVariableDefId={UserProfileDefsEnum.DUE_DATE}
                            >
                                {updatedDueDate => (
                                    <Typography>
                                        {(updatedDueDate ?? dueDate) &&
                                            moment
                                                .utc(updatedDueDate ?? dueDate)
                                                .format('MM/DD/yyyy')}
                                    </Typography>
                                )}
                            </PatientProfileVariableLink>
                        ),
                        customSort: (a, b) =>
                            new Date(a.dueDate ?? '').getTime() -
                            new Date(b.dueDate ?? '').getTime(),
                        hidden: hiddenColumns.dueDate,
                        headerStyle: getHeaderStyle(PatientSortingFields.dueDate),
                    },
                    {
                        title: 'Delivery Date',
                        render: ({ deliveryDate, id, appBundleId }) => (
                            <PatientProfileVariableLink
                                patientId={id}
                                appBundleId={appBundleId || ''}
                                profileVariableDefId={UserProfileDefsEnum.DELIVERY_DATE}
                            >
                                {updatedDeliveryDate => (
                                    <Typography>
                                        {(updatedDeliveryDate ?? deliveryDate) &&
                                            format(
                                                new Date(updatedDeliveryDate ?? deliveryDate),
                                                'MM/dd/yyyy',
                                                {
                                                    timeZone: 'UTC',
                                                },
                                            )}
                                    </Typography>
                                )}
                            </PatientProfileVariableLink>
                        ),
                        customSort: (a, b) =>
                            new Date(a.deliveryDate ?? '').getTime() -
                            new Date(b.deliveryDate ?? '').getTime(),
                        hidden: hiddenColumns.deliveryDate,
                        headerStyle: getHeaderStyle(PatientSortingFields.deliveryDate),
                    },
                    {
                        title: 'Email',
                        field: 'email',
                        hidden: hiddenColumns.email,
                    },
                    {
                        title: 'Pateint ID',
                        field: 'id',
                        width: 32,
                        sorting: false,
                        align: 'center',
                        hidden: hiddenColumns.id,
                    },
                ]}
                data={patientsPageData?.currentUser?.patientsV2?.results ?? []}
                onOrderCollectionChange={handleOrderChange}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={patientsPageData?.currentUser?.patientsV2?.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                }}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                    search: false,
                    exportAllData: pagePermissions?.PatientDetails.Export,
                    paginationPosition: 'both',
                }}
                title="Patients"
            />
            <AddToMassUpdateModal
                isOpen={showMassUpdateModal}
                onClose={() => {
                    setShowMassUpdateModal(false);
                }}
                patientId={selectedPatient?.id}
            />

            <Dialog
                scroll="paper"
                open={showPatientModal}
                fullWidth
                aria-labelledby="form-dialog-title"
                data-test={PatientEnum.PATIENT_MODAL}
            >
                {PatientAddModal}
            </Dialog>
            <Dialog open={importOpen} fullWidth>
                <CSVImportModal<UploadManyPatientsForImporterMutation>
                    modelLabel="Patient"
                    setOpen={setImportOpen}
                    validationSchema={PATIENT_VALIDATION_SCHEMA}
                    constantFields={[
                        {
                            options:
                                patientsPageData?.currentUser?.organizations.map(
                                    ({ id, brandingName }) => ({ label: brandingName, value: id }),
                                ) ?? [],
                            name: 'practiceId',
                            label: 'Practice',
                            required: true,
                            defaultValue: patientsPageData?.currentUser?.currentOrg.id ?? '',
                        },
                        {
                            options: [
                                {
                                    label: 'None',
                                    value: PatientInvitation.None,
                                },
                                {
                                    label: 'Send Email & Generate Code',
                                    value: PatientInvitation.EmailCode,
                                },
                                {
                                    label: 'Generate Code',
                                    value: PatientInvitation.GenerateCode,
                                },
                            ],
                            name: 'invitationMode',
                            label: 'Patient Invitation',
                            required: true,
                            defaultValue: PatientInvitation.EmailCode,
                        },
                    ]}
                    fields={[
                        {
                            name: 'email',
                            label: 'Email',
                            required: true,
                            example: 'test@test.com',
                        },
                        {
                            name: 'firstName',
                            label: 'First Name',
                            required: true,
                            example: 'Beth',
                        },
                        {
                            name: 'lastName',
                            label: 'Last Name',
                            required: true,
                            example: 'Sanchez',
                        },
                        {
                            name: 'birthDate',
                            label: 'Birth Date',
                            required: true,
                            example: '2021-02-02T23:14:49.100Z',
                            isDate: true,
                        },
                        {
                            name: 'externalId',
                            label: 'External ID',
                            description: 'An unique identifier for use in an external system.',
                            required: false,
                            example: 'ZVDF2PW42JTWP4E2IJF',
                        },
                        {
                            name: 'phoneNumber',
                            label: 'Phone Number',
                            required: false,
                            example: '3606781103',
                        },
                        {
                            name: 'mailingAddress_street1',
                            label: 'Mailing Address: Line 1',
                            required: false,
                            example: '1423 6th Ave',
                        },
                        {
                            name: 'mailingAddress_street2',
                            label: 'Mailing Address: Line 2',
                            required: false,
                            example: 'APT 7',
                        },
                        {
                            name: 'mailingAddress_city',
                            label: 'Mailing Address: City',
                            required: false,
                            example: 'San Francisco',
                        },
                        {
                            name: 'mailingAddress_state',
                            label: 'Mailing Address: State',
                            description: '2 letter state code',
                            required: false,
                            example: 'CA',
                        },
                        {
                            name: 'mailingAddress_code',
                            label: 'Mailing Address: Zip Code',
                            description: '5 digit Zip Code',
                            required: false,
                            example: '94118',
                        },
                    ]}
                    uploadConfiguration={{
                        mutation: UploadManyPatientsForImporterDocument,
                        options: {
                            onCompleted: data =>
                                data.createManyPatients?.success
                                    ? data.createManyPatients.resourcesCreated
                                    : null,
                            onUpdate: (cache, response) => {
                                if (response.data?.createManyPatients?.success) {
                                    const newPatients =
                                        response.data.createManyPatients.resourcesCreated.reduce(
                                            (prev, { success, resourceCreated }) => {
                                                if (success && resourceCreated) {
                                                    return [...prev, resourceCreated];
                                                }
                                                return prev;
                                            },
                                            [] as Patient[],
                                        );
                                    if (newPatients.length > 0) {
                                        const currentData =
                                            cache.readQuery<FetchPatientsForPatientsPageQueryQuery>(
                                                {
                                                    query: FetchPatientsForPatientsPageQueryDocument,
                                                },
                                            );
                                        if (currentData?.currentUser?.patientsV2) {
                                            cache.writeQuery<FetchPatientsForPatientsPageQueryQuery>(
                                                {
                                                    query: FetchPatientsForPatientsPageQueryDocument,
                                                    data: {
                                                        currentUser: {
                                                            ...currentData?.currentUser,
                                                            patientsV2: {
                                                                ...currentData?.currentUser
                                                                    ?.patientsV2,
                                                                results: [
                                                                    ...newPatients,
                                                                    ...currentData?.currentUser
                                                                        ?.patientsV2.results,
                                                                ],
                                                            },
                                                        },
                                                    },
                                                },
                                            );
                                        }
                                    }
                                }
                            },
                        },
                    }}
                />
            </Dialog>
            <Menu
                id="patient-drop-down"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                    setAnchorEl(null);
                }}
            >
                {pagePermissions?.PatientDetails.Read && (
                    <MenuItem to={`/portal/patients/${selectedPatient?.id}`} component={Link}>
                        View Patient Details
                    </MenuItem>
                )}
                {pagePermissions?.Appointments.Read && (
                    <MenuItem
                        to={`/portal/patients/${selectedPatient?.id}/appointments`}
                        component={Link}
                    >
                        Appointments
                    </MenuItem>
                )}
                {pagePermissions?.Measurements.Read && (
                    <MenuItem
                        to={`/portal/patients/${selectedPatient?.id}/measurements`}
                        component={Link}
                    >
                        Measurements
                    </MenuItem>
                )}
                {pagePermissions?.SurveyResponses.Read && (
                    <MenuItem
                        to={`/portal/patients/${selectedPatient?.id}/survey-responses`}
                        component={Link}
                    >
                        Surveys
                    </MenuItem>
                )}
                {pagePermissions?.PatientQuestions.Read && (
                    <MenuItem
                        onClick={() => {
                            setQuestionsModalOpen(true);
                            setAnchorEl(null);
                        }}
                    >
                        Patient Q-List
                    </MenuItem>
                )}
                {pagePermissions?.MessageCenter.Read && (
                    <MenuItem to={`/portal/patients/${selectedPatient?.id}/chat`} component={Link}>
                        Message Center
                    </MenuItem>
                )}
                {pagePermissions?.PatientNotes.Read && (
                    <MenuItem to={`/portal/patients/${selectedPatient?.id}/notes`} component={Link}>
                        Notes
                    </MenuItem>
                )}
                {pagePermissions?.PatientDetails.Edit && (
                    <MenuItem
                        onClick={() => {
                            setEditPatientId(selectedPatient?.id);
                            setShowPatientModal(true);
                        }}
                    >
                        Edit Patient
                    </MenuItem>
                )}
                {pagePermissions?.PatientDetails.Edit && (
                    <MenuItem
                        disabled={
                            (patientInviteLoading &&
                                patientResendLoadingId === selectedPatient?.id) ||
                            !selectedPatient?.invitationCode
                        }
                        onClick={() => {
                            TriggerGlobalConfirm({
                                message: `Are you sure you want to resend invite?`,
                                callback: () => {
                                    handleResendInvite(selectedPatient?.id);
                                    setPatientResendLoadingId(selectedPatient?.id);
                                },
                            });
                        }}
                    >
                        <span>Resend Invite</span>
                        {patientInviteLoading && patientResendLoadingId === selectedPatient?.id && (
                            <CircularProgress size={20} style={{ marginLeft: 5 }} />
                        )}
                    </MenuItem>
                )}
                {pagePermissions?.PatientDetails.Edit && (
                    <MenuItem
                        disabled={
                            patientResetPasswordLoading &&
                            patientResetPasswordLoadingId === selectedPatient?.id
                        }
                        onClick={() => {
                            TriggerGlobalConfirm({
                                message: `Are you sure you want to reset the password?`,
                                callback: () => {
                                    handleResetPassword(selectedPatient?.id);
                                    setPatientResetPasswordLoadingId(selectedPatient?.id);
                                },
                            });
                        }}
                    >
                        <span>Reset Password</span>
                        {patientResetPasswordLoading &&
                            patientResetPasswordLoadingId === selectedPatient?.id && (
                                <CircularProgress size={20} style={{ marginLeft: 5 }} />
                            )}
                    </MenuItem>
                )}
                {pagePermissions?.MassUpdate.Edit && (
                    <MenuItem
                        onClick={() => {
                            setShowMassUpdateModal(true);
                            setAnchorEl(null);
                        }}
                    >
                        Add to Mass Update List
                    </MenuItem>
                )}

                {pagePermissions?.PatientDetails.Delete && (
                    <MenuItem
                        onClick={() => {
                            if (selectedPatient) {
                                const { id, firstName, lastName } = selectedPatient;
                                TriggerGlobalConfirm({
                                    message: `Do you want to delete the patient ${firstName} ${lastName}`,
                                    callback: () => {
                                        deletePatient({
                                            variables: {
                                                input: {
                                                    id,
                                                },
                                            },
                                        });
                                    },
                                });
                            }
                            setAnchorEl(null);
                        }}
                        className={classes.deletePatientTitle}
                    >
                        Delete Patient
                    </MenuItem>
                )}
            </Menu>
            {isPatientNotesModalOpen && (
                <PatientNotesModal
                    handleClose={() => setIsPatientNotesModalOpen(false)}
                    isModalOpen={isPatientNotesModalOpen}
                    patientId={selectedPatient?.id}
                />
            )}
            <Dialog
                open={questionsModalOpen}
                onClose={() => setQuestionsModalOpen(false)}
                fullWidth
            >
                <div className={classes.questionModal}>
                    <div className="headerRow">
                        <Typography variant="h5">
                            {selectedPatient?.id &&
                                patientsPageData?.currentUser?.patientsV2?.results?.find(
                                    ({ id }) => id === selectedPatient.id,
                                )?.firstName}
                            &apos;s Questions
                        </Typography>
                        <FormControlLabel
                            style={{
                                margin: 0,
                            }}
                            label="View Answered Questions"
                            control={
                                <Switch
                                    checked={filterAnsweredQuestions}
                                    onChange={e => setFilterAnsweredQuestions(e.target.checked)}
                                />
                            }
                        />
                    </div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Submitted</TableCell>
                                <TableCell align="center">Question</TableCell>
                                <TableCell align="center">Answered</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_(selectedPatient?.questions)
                                .filter(({ isDone }) => (!filterAnsweredQuestions ? !isDone : true))
                                .sortBy('submittedAt')
                                .reverse()
                                .value()
                                .map(({ id, text, isDone, submittedAt }) => (
                                    <TableRow key={id}>
                                        <TableCell align="center">
                                            {submittedAt &&
                                                new Date(submittedAt).toLocaleDateString()}
                                        </TableCell>
                                        <TableCell align="center">{text}</TableCell>
                                        <TableCell align="center">
                                            <Checkbox checked={isDone} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    {selectedPatient?.questions.length === 0 && (
                        <div className="noQuestions">
                            <Typography>This patient currently has no questions</Typography>
                        </div>
                    )}
                </div>
            </Dialog>
            <FilterColumnsModal
                hiddenColumnsAnchorEl={hiddenColumnsAnchorEl}
                setHiddenColumnsAnchorEl={setHiddenColumnsAnchorEl}
            />
            <FilterPatientsModal
                open={openFilterPatientsModal}
                onClose={() => setOpenFilterPatientsModal(false)}
            />
        </div>
    );
};

export default Patients;
