import React, { useCallback, useMemo, useState, useRef } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { format, toDate } from 'date-fns-tz';
import copy from 'copy-to-clipboard';
import MaterialTable, { MTableAction } from '@material-table/core';

import {
    AlertSeverity,
    OrderByDirectionEnum,
    useVirtualCareAffiliatePatientsQuery,
} from '~/schemaTypes';
import { PAGESIZE } from '~/constants';
import { Button, IconButton, TablePagination, Tooltip, Typography, Link } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import useAffiliateCareView from '~/hooks/useAffiliateCareView';
import { TriggerGlobalAlert } from '~/state';
import useUserPermissions from '~/hooks/useUserPermissions';

const Patients = () => {
    const { userAffiliateId } = useAffiliateCareView();
    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(PAGESIZE);
    const [searchInput, setSearchInput] = useState<string>('');
    const tableRef = useRef<HTMLDivElement>(null);
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement>, pageNumber: number) => {
        setPage(pageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    const onSearchChange = useCallback((searchString: string) => {
        setSearchInput(searchString);
    }, []);
    const fetchVariables = useMemo(() => {
        return {
            input: {
                pagination: {
                    skip: page * rowsPerPage,
                    limit: rowsPerPage,
                },
                orderBy: {
                    field: 'createdAt',
                    order: OrderByDirectionEnum.Desc,
                },
                affiliateId: userAffiliateId ?? 'no-data',
                ...(searchInput && { search: searchInput.trim() }),
            },
        };
    }, [page, rowsPerPage, userAffiliateId, searchInput]);
    const { data, loading } = useVirtualCareAffiliatePatientsQuery({
        variables: fetchVariables,
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const createPatientHandler = useCallback(
        () => history(`/affiliate-care/patients/${userAffiliateId}/1?sc=true`),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [userAffiliateId],
    );
    return (
        <div ref={tableRef}>
            <MaterialTable
                title="Eligible Patients"
                columns={[
                    {
                        title: 'id',
                        field: 'id',
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'Patient Id Copied to Clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                        sorting: false,
                        customFilterAndSearch: () => true,
                    },
                    {
                        title: 'First Name',
                        field: 'firstName',
                        render: ({ firstName, id }) => (
                            <Link to={`/affiliate-care/patients/${id}`} component={RouterLink}>
                                {firstName}
                            </Link>
                        ),
                        sorting: false,
                        searchable: false,
                    },
                    {
                        title: 'Last Name',
                        field: 'lastName',
                        render: ({ lastName, id }) => (
                            <Link to={`/affiliate-care/patients/${id}`} component={RouterLink}>
                                {lastName}
                            </Link>
                        ),
                        sorting: false,
                        searchable: false,
                    },
                    {
                        title: 'DOB',
                        field: 'birthDate',
                        render: ({ birthDate }) =>
                            format(toDate(birthDate.split('T')[0]), 'MM/dd/yyyy'),
                        sorting: false,
                        searchable: false,
                    },
                    {
                        title: 'Phone Number',
                        field: 'phoneNumber',
                        render: ({ phoneNumber }) => (
                            <Typography data-test={phoneNumber}>{phoneNumber}</Typography>
                        ),
                        sorting: false,
                        searchable: false,
                    },
                    {
                        title: 'Email',
                        field: 'email',
                        render: ({ email }) => <Typography data-test={email}>{email}</Typography>,
                        sorting: false,
                        searchable: false,
                    },
                    {
                        title: 'Date Created',
                        field: 'createdAt',
                        render: ({ createdAt }) =>
                            format(toDate(createdAt.split('T')[0]), 'MM/dd/yyyy'),
                        sorting: false,
                        searchable: false,
                    },
                ]}
                data={data?.virtualCareAffiliatePatients?.results ?? []}
                options={{
                    search: true,
                    paging: true,
                    pageSize: rowsPerPage,
                    pageSizeOptions: [10, PAGESIZE, 50],
                    debounceInterval: 500,
                }}
                isLoading={loading}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onSearchChange={onSearchChange}
                totalCount={data?.virtualCareAffiliatePatients?.total ?? 0}
                page={page}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={data?.virtualCareAffiliatePatients?.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                    Action: props => {
                        const {
                            action: { isFreeAction, icon, onClick, hidden },
                        } = props;
                        if (isFreeAction && !hidden) {
                            return (
                                <Button variant="outlined" onClick={onClick}>
                                    {icon}
                                </Button>
                            );
                        }
                        return <MTableAction {...props} />;
                    },
                }}
                actions={[
                    {
                        isFreeAction: true,
                        onClick: createPatientHandler,
                        icon: 'Add Eligible Patient',
                        hidden: !pagePermissions?.VirtualCarePatients.Edit,
                    },
                ]}
            />
        </div>
    );
};

export default Patients;
