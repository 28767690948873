import {
    Avatar,
    Card,
    CardMedia,
    Fade,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { displayDateWithAbbrTimeZone, isToday } from '~/helpers/dateHelpers';
import { FetchChatRoomMessagesForMessageCenterQuery } from '~/schemaTypes';
import { TriggerGlobalImagePreview } from '~/state';
import parse from 'html-react-parser';

type ChatRoom = NonNullable<FetchChatRoomMessagesForMessageCenterQuery['chatRoom']>;
type ChatMessageType = NonNullable<ChatRoom['messages'][0]>;
type ChatRoomParticipants = NonNullable<ChatRoom['participants'][0]>;

const useStyles = makeStyles<{ isOwner: boolean; messageReadByPatient: boolean }>()(
    (theme, { isOwner, messageReadByPatient }) => ({
        root: {
            width: 350,
            marginBottom: 30,
            zIndex: 5,
            position: 'relative',
            alignSelf: isOwner ? 'flex-end' : 'flex-start',
            '& .card': {
                padding: 20,
                position: 'relative',
                background: isOwner ? theme.colors.Primary(100) : 'lightgray',
                marginBottom: 0,
                overflow: 'visible',
                '& .messageTimestamp': {
                    position: 'absolute',
                    right: '5px',
                    bottom: '5px',
                },
                '& .messageReadReceipt': {
                    color: !messageReadByPatient ? theme.colors.Primary() : '#424242',
                    position: 'absolute',
                    bottom: -25,
                    right: 0,
                },
            },
        },
        carouselItem: {
            position: 'relative',
            '& .overlayDiv': {
                position: 'absolute',
                top: 0,
                height: '100%',
                width: '100%',
                display: 'none',
            },
            '&:hover': {
                '& .overlayDiv': {
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    opacity: 0.8,
                    '& .hoverText': {
                        display: 'none',
                        color: '#fff',
                        opacity: 1,
                    },
                    '&:hover': {
                        boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.2)',
                        '& .hoverText': {
                            display: 'inherit',
                        },
                    },
                },
            },
        },
        carouselIcons: {
            background: '#fff',
            boxShadow: theme.shadows[1],
            transition: 'color .2s',
            '&:hover': {
                opacity: 1,
                background: '#fff',
                color: theme.colors.Primary(),
            },
        },
    }),
);

type ChatMessageProps = {
    chatRoom: ChatRoom | null;
    chatMessage: ChatMessageType;
    currentUserId?: string;
};

const ChatMessage: React.FC<ChatMessageProps> = ({ chatRoom, chatMessage, currentUserId }) => {
    const messageReadByPatient = useMemo(
        () =>
            !!(
                chatRoom?.patient.id &&
                (chatMessage.read as string[]).includes(chatRoom?.patient.id)
            ),
        [chatRoom?.patient.id, chatMessage.read],
    );

    const isOwner = currentUserId === chatMessage.senderId;
    const { classes } = useStyles({
        isOwner,
        messageReadByPatient,
    });

    const sender = useMemo(() => {
        const { senderId } = chatMessage;
        const possibleSenders = [
            ...((chatRoom && chatRoom.participants) ?? []),
            {
                id: chatRoom?.patient.id,
                name: `${chatRoom?.patient.firstName} ${chatRoom?.patient.lastName}`,
            } as ChatRoomParticipants,
        ];
        return _.find(possibleSenders, ({ id }) => id === senderId);
    }, [chatRoom, chatMessage]);

    const getInitials = (sender: ChatRoomParticipants) => {
        const [firstName, lastName] = sender.name.split(' ');

        return `${firstName?.[0] ?? ''}${lastName?.[0] ?? ''}`;
    };

    const senderInitials = sender ? getInitials(sender) : 'WF';

    return (
        <Fade timeout={500} in>
            <Grid
                container
                spacing={1}
                alignItems="flex-end"
                wrap="nowrap"
                direction={isOwner ? 'row-reverse' : 'row'}
                className={classes.root}
                data-message-id={chatMessage.id}
            >
                <Grid item>
                    <Tooltip title={sender?.name ?? 'Wildflower'}>
                        <Avatar>{senderInitials}</Avatar>
                    </Tooltip>
                </Grid>
                <Grid item xs={10}>
                    <Card className="card">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Carousel
                                    showThumbs={false}
                                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                        hasPrev && (
                                            <IconButton
                                                className={classes.carouselIcons}
                                                onClick={onClickHandler}
                                                title={label}
                                                style={{
                                                    position: 'absolute',
                                                    zIndex: 2,
                                                    top: 'calc(50% - 15px)',
                                                    width: 30,
                                                    height: 30,
                                                    cursor: 'pointer',
                                                    left: 15,
                                                }}
                                                size="large"
                                            >
                                                <ChevronLeftOutlined />
                                            </IconButton>
                                        )
                                    }
                                    renderArrowNext={(onClickHandler, hasNext, label) =>
                                        hasNext && (
                                            <IconButton
                                                className={classes.carouselIcons}
                                                onClick={onClickHandler}
                                                title={label}
                                                style={{
                                                    position: 'absolute',
                                                    zIndex: 2,
                                                    top: 'calc(50% - 15px)',
                                                    width: 30,
                                                    height: 30,
                                                    cursor: 'pointer',
                                                    right: 15,
                                                }}
                                                size="large"
                                            >
                                                <ChevronRightOutlined />
                                            </IconButton>
                                        )
                                    }
                                >
                                    {chatMessage.attachments?.map(uuid => (
                                        <div className={classes.carouselItem} key={uuid}>
                                            {/* eslint-disable-next-line jsx-a11y/alt-text */}
                                            <img
                                                src={
                                                    uuid?.startsWith('http')
                                                        ? uuid
                                                        : `https://ucarecdn.com/${uuid}/`
                                                }
                                            />
                                            <Card
                                                onClick={() =>
                                                    TriggerGlobalImagePreview({
                                                        imageSrc: uuid?.startsWith('http')
                                                            ? uuid
                                                            : `https://ucarecdn.com/${uuid}/`,
                                                    })
                                                }
                                                className="overlayDiv"
                                            >
                                                <Typography variant="h6" className="hoverText">
                                                    Click to view
                                                </Typography>
                                            </Card>
                                        </div>
                                    ))}
                                </Carousel>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" paragraph style={{ maxWidth: '100%' }}>
                                    {chatMessage.text && parse(chatMessage.text)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="messageTimestamp" variant="subtitle1">
                                    {displayDateWithAbbrTimeZone({
                                        isoDateStr: chatMessage.createdAt,
                                        format: `${
                                            isToday(new Date(chatMessage.createdAt))
                                                ? 'h:mm a'
                                                : 'MMM DD, h:mm a'
                                        }`,
                                        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    })}
                                </Typography>
                            </Grid>
                        </Grid>
                        <CardMedia />
                        {chatMessage.senderId !== chatRoom?.patient.id && (
                            <Typography className="messageReadReceipt" variant="subtitle2">
                                {messageReadByPatient ? 'Read' : 'Unread'}
                            </Typography>
                        )}
                    </Card>
                </Grid>
            </Grid>
        </Fade>
    );
};

export default ChatMessage;
