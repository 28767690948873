import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Divider, Grid, TextField, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ArrowBack, FileCopy, Save } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import { LocalizedStringTextFields } from '~/components/LocalizedStringTextFields/LocalizedStringTextFields';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    AlertSeverity,
    AllMessageCenterTemplatesDocument,
    MessageCenterTemplate,
    MessageCenterTemplateDocument,
    useCreateMessageCenterTemplateMutation,
    useMessageCenterTemplateLazyQuery,
    useUpdateMessageCenterTemplateMutation,
} from '~/schemaTypes';
import { MessageCenterTemplatesEnum } from '~/selectors';
import { TriggerGlobalAlert } from '~/state';
import { templateTokens } from './helpers/templateTokens';
import { defaultFormValues, toFormValuesFromMessageTemplate } from './helpers/toFormValues';
import { formValuesToCreateInput, formValuesToUpdateInput } from './helpers/toRequestInput';
import { MessageCenterTemplateFormInput } from './types/MessageCenterTemplateFormInput';
import { MESSAGE_CENTER_TEMPLATE_SCHEMA } from './yupSchema';

const MESSAGE_CENTER_TEMPLATES_PATHNAME = '/app-config/message-center-templates';

export const useStyles = makeStyles()({
    root: {},
    buttonWrap: {
        paddingLeft: '10px',
        paddingBottom: '10px',
    },
    checkboxWrap: {
        paddingLeft: '10px',
    },
    actionButtons: {
        marginTop: '5px',
        marginLeft: '5px',
        marginRight: '5px',
    },
});

const MessageTemplate: React.FC = () => {
    const { classes } = useStyles();
    const history = useNavigate();
    const [close, setClose] = useState(false);
    const params = useParams<{ id: string }>();
    const isEditMode = 'id' in params;
    const messageTemplateId = isEditMode ? params.id : '';

    const [getMessageTemplate, { data: messageTemplateData, loading: messageTemplateLoading }] =
        useMessageCenterTemplateLazyQuery();

    const [createMessageTemplate, { loading: createMessageTemplateLoading }] =
        useCreateMessageCenterTemplateMutation({
            awaitRefetchQueries: true,
            onCompleted: data => {
                if (close) history(MESSAGE_CENTER_TEMPLATES_PATHNAME);
                else
                    history(
                        `${MESSAGE_CENTER_TEMPLATES_PATHNAME}/edit/${data?.createMessageCenterTemplate?.resourceCreated?.id}`,
                    );
            },
            refetchQueries: [{ query: AllMessageCenterTemplatesDocument }],
        });

    const [updateMessageTemplate, { loading: updateMessageTemplateLoading }] =
        useUpdateMessageCenterTemplateMutation({
            awaitRefetchQueries: true,
            onCompleted: () => {
                if (close) history(MESSAGE_CENTER_TEMPLATES_PATHNAME);
            },
            refetchQueries: [
                {
                    query: MessageCenterTemplateDocument,
                    variables: { input: { id: isEditMode ? messageTemplateId : '' } },
                },
            ],
        });

    useEffect(() => {
        if (isEditMode) {
            getMessageTemplate({ variables: { input: { id: messageTemplateId } } });
        }
    }, [messageTemplateId, isEditMode, getMessageTemplate]);

    const {
        register,
        handleSubmit: handleFormSubmit,
        reset,

        formState: { errors },
    } = useForm<MessageCenterTemplateFormInput>({
        resolver: yupResolver(MESSAGE_CENTER_TEMPLATE_SCHEMA as any),
        defaultValues: defaultFormValues(),
    });

    const handleSubmit: SubmitHandler<MessageCenterTemplateFormInput> = form => {
        if (errors.content || errors.title) {
            return;
        }
        if (isEditMode && messageTemplateId) {
            updateMessageTemplate({
                variables: {
                    input: { id: messageTemplateId, data: formValuesToUpdateInput(form) },
                },
            });
        } else {
            createMessageTemplate({
                variables: {
                    input: formValuesToCreateInput(form),
                },
            });
        }
    };

    useEffect(() => {
        if (isEditMode && messageTemplateData?.messageCenterTemplate) {
            reset(
                toFormValuesFromMessageTemplate(
                    messageTemplateData.messageCenterTemplate as MessageCenterTemplate,
                ),
            );
        }
    }, [isEditMode, messageTemplateData, reset]);

    const renderForm = () => {
        return (
            <form className={classes.root} onSubmit={handleFormSubmit(handleSubmit)}>
                <Grid container item xs={12}>
                    <Grid container item xs={12}>
                        <TextField
                            variant="outlined"
                            type="text"
                            label="Title"
                            {...register('title', { required: true })}
                            required
                            error={Boolean(errors.title)}
                            helperText={errors.title?.message}
                            fullWidth
                        />
                    </Grid>
                    <Grid container item xs={12}>
                        <LocalizedStringTextFields
                            name="content"
                            label="Message Content"
                            register={register}
                            required={{ en: true, es: false }}
                            rhfErrors={errors}
                            multiline
                        />
                    </Grid>
                    <Grid container item xs={12}>
                        <OutlinedSection title="Click To Copy Tokens">
                            <Grid item xs={12}>
                                {templateTokens.map(token => {
                                    return (
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                copy(token.token);
                                                TriggerGlobalAlert({
                                                    severity: AlertSeverity.Success,
                                                    message: `${token.text} copied`,
                                                });
                                            }}
                                            className={classes.actionButtons}
                                            data-test={token.testId}
                                        >
                                            <FileCopy />
                                            {token.text}
                                        </Button>
                                    );
                                })}
                            </Grid>
                        </OutlinedSection>
                    </Grid>
                    <div style={{ width: '100%', textAlign: 'right' }}>
                        <Button
                            onClick={() => {
                                handleFormSubmit(handleSubmit);
                            }}
                            type="submit"
                            startIcon={<Save />}
                            color="secondary"
                            variant="contained"
                            className={classes.actionButtons}
                        >
                            Save
                        </Button>
                        <Button
                            onClick={() => {
                                setClose(true);
                                handleFormSubmit(handleSubmit);
                            }}
                            type="submit"
                            startIcon={<Save />}
                            color="secondary"
                            variant="contained"
                            className={classes.actionButtons}
                            data-test={MessageCenterTemplatesEnum.SAVE_AND_CLOSE}
                        >
                            Save &amp; Close
                        </Button>
                    </div>
                </Grid>
            </form>
        );
    };

    if (createMessageTemplateLoading || updateMessageTemplateLoading || messageTemplateLoading) {
        return <Loading />;
    }

    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                <Button
                    component={Link}
                    to={MESSAGE_CENTER_TEMPLATES_PATHNAME}
                    startIcon={<ArrowBack />}
                >
                    Back to Message Center Templates
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <Typography
                        variant="h6"
                        paragraph
                        data-test={MessageCenterTemplatesEnum.ADD_MESSAGE_CENTER_TEMPLATE}
                    >
                        {isEditMode ? 'Edit' : 'Add'} Message Center Template
                    </Typography>
                    <Divider style={{ marginBottom: 20 }} />
                    {renderForm()}
                </Card>
            </Grid>
        </Grid>
    );
};

export default MessageTemplate;
