import { LocalizedString } from '../types/LocalizedString';
import { MessageCenterTemplateFormInput } from '../types/MessageCenterTemplateFormInput';

type MessageCenterTemplateFormType = {
    title: string;
    content: LocalizedString;
};

export const formValuesToUpdateInput = (
    form: MessageCenterTemplateFormInput,
): MessageCenterTemplateFormType => {
    const { content, title, ...rest } = form;

    return {
        ...rest,
        title,
        content,
    };
};

export const formValuesToCreateInput = (
    form: MessageCenterTemplateFormInput,
): MessageCenterTemplateFormType => {
    const { title, content, ...rest } = form;

    return {
        ...rest,
        title,
        content,
    };
};
