export const LBS = 2.20462;

export const round = (num: number): number => Math.round(num);

export const weightValueLabel = (num: number, unit: string | null): string => {
    if (unit === 'kg') {
        return `${round(num * LBS)} (lbs)`;
    }
    return `${round(num)} (${unit})`;
};
