import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
    DateTime: any;
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: any;
    /** Mongo object id scalar type */
    ObjectId: any;
};

export type AmsApp = {
    __typename?: 'AMSApp';
    bundleId: Scalars['String'];
    /** Full app config resolved from app defaults, then app values, and then expression overrides */
    config: Scalars['JSON'];
    defaultValues: Array<ConfigValueDef>;
    fullName: Scalars['String'];
    id: Scalars['String'];
    logo?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    productConst?: Maybe<AmsProduct>;
    values: Array<ConfigValue>;
};

export type AmsClient = {
    __typename?: 'AMSClient';
    createdAt: Scalars['String'];
    id: Scalars['String'];
    isSuper: Scalars['Boolean'];
    logo?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    prefix: Scalars['String'];
    updatedAt: Scalars['String'];
};

/** The AMS product. */
export enum AmsProduct {
    Ddp = 'DDP',
    Grow = 'GROW',
    Hybrid = 'HYBRID',
    Uddp = 'UDDP',
    Ugrow = 'UGROW',
    VbcPlatform = 'VBC_PLATFORM',
}

export type AmsTag = {
    __typename?: 'AMSTag';
    categoryId: Scalars['String'];
    categoryName: Scalars['String'];
    id: Scalars['String'];
    name: Scalars['String'];
};

export type AmsTagCategory = {
    __typename?: 'AMSTagCategory';
    id: Scalars['String'];
    name: Scalars['String'];
};

export type AmsUnit = {
    __typename?: 'AMSUnit';
    applicationId: Scalars['String'];
    clientId: Scalars['String'];
    contentBundleId?: Maybe<Scalars['String']>;
    createdAt: Scalars['String'];
    eligibilityConfigurationId?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    name: Scalars['String'];
    segmentConst: Scalars['Float'];
    updatedAt: Scalars['String'];
};

export type AadvocateNotificationsByUserInput = {
    limit?: InputMaybe<Scalars['Float']>;
    periodInDays: Scalars['Float'];
    userId: Scalars['ObjectId'];
};

export type AccessCode = {
    __typename?: 'AccessCode';
    _id: Scalars['String'];
    birthDate: Scalars['String'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    unitName: Scalars['String'];
};

export type AccessCodeInput = {
    birthDate?: InputMaybe<Scalars['String']>;
    firstName?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
    unitName?: InputMaybe<Scalars['String']>;
};

export type AccessCodeQuery = {
    unitName?: InputMaybe<Scalars['String']>;
};

/** Source of current action */
export enum ActionSource {
    Automation = 'Automation',
    HaTask = 'HATask',
    Manually = 'Manually',
}

export type ActiveEpisodeInput = {
    conditionId?: InputMaybe<Scalars['String']>;
    conditionTypeId?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['String']>;
};

export type AddApprovalsForAppMutationResponse = MutationResponse & {
    __typename?: 'AddApprovalsForAppMutationResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type AddHaTodoTaskResponse = MutationResponse & {
    __typename?: 'AddHATodoTaskResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ProxiedTodoTask>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type AddNoteFromConsultantInput = {
    insuranceId?: InputMaybe<Scalars['String']>;
    note?: InputMaybe<Scalars['String']>;
    patientDOB?: InputMaybe<Scalars['DateTime']>;
    patientFirstName?: InputMaybe<Scalars['String']>;
    patientLastName?: InputMaybe<Scalars['String']>;
    providerName?: InputMaybe<Scalars['String']>;
    providerNumber?: InputMaybe<Scalars['String']>;
    sessionDate?: InputMaybe<Scalars['DateTime']>;
};

export type AddNoteFromConsultantResponse = MutationResponse & {
    __typename?: 'AddNoteFromConsultantResponse';
    message: Scalars['String'];
    patientId?: Maybe<Scalars['String']>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type AddOrganizationInviteCodeInput = {
    inviteCode: Scalars['String'];
    orgId: Scalars['ObjectId'];
};

export type AddOrganizationInviteCodeResponse = MutationResponse & {
    __typename?: 'AddOrganizationInviteCodeResponse';
    message: Scalars['String'];
    organization?: Maybe<Organization>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type AddRedirectUrlToTtPatientInput = {
    patientId: Scalars['ObjectId'];
    url: Scalars['String'];
};

export type AddRedirectUrlToTtPatientResponse = MutationResponse & {
    __typename?: 'AddRedirectUrlToTTPatientResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type AddToPatientListInput = {
    id: Scalars['String'];
    patientIds: Array<Scalars['ObjectId']>;
};

export type AddToPatientListResponse = MutationResponse & {
    __typename?: 'AddToPatientListResponse';
    id: Scalars['String'];
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type AddVcZipCodeToLocationInput = {
    affiliateId?: InputMaybe<Scalars['ObjectId']>;
    locationId?: InputMaybe<Scalars['String']>;
    locationName?: InputMaybe<Scalars['String']>;
    routingMethod?: InputMaybe<RoutingMethod>;
    zipCode?: InputMaybe<Scalars['String']>;
};

export type AdditionalPatientFieldsInput = {
    bundleId: Scalars['String'];
    configName: Scalars['String'];
};

export type AdditionalPatientFieldsResponse = {
    __typename?: 'AdditionalPatientFieldsResponse';
    careTeamMembers: Array<CareTeamMembersByType>;
    profileDefs: Array<UserProfileDef>;
};

export type Address = {
    __typename?: 'Address';
    city?: Maybe<Scalars['String']>;
    code?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
    street1?: Maybe<Scalars['String']>;
    street2?: Maybe<Scalars['String']>;
};

export type AddressInput = {
    city?: InputMaybe<Scalars['String']>;
    code?: InputMaybe<Scalars['String']>;
    state?: InputMaybe<Scalars['String']>;
    street1?: InputMaybe<Scalars['String']>;
    street2?: InputMaybe<Scalars['String']>;
};

export type AdvocateCreatedTodoTasksInput = {
    currentDate: Scalars['DateTime'];
    isComplete: Scalars['Boolean'];
};

export type AdvocateNotification = BaseEntity & {
    __typename?: 'AdvocateNotification';
    advocateTaskId: Scalars['ObjectId'];
    createdAt: Scalars['DateTime'];
    displayDate?: Maybe<Scalars['DateTime']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    type: AdvocateNotificationType;
    updatedAt: Scalars['DateTime'];
    userId: Scalars['ObjectId'];
    viewed: Scalars['Boolean'];
};

export type AdvocateNotificationFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<AdvocateNotificationUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type AdvocateNotificationInput = {
    advocateTaskId: Scalars['ObjectId'];
    displayDate?: InputMaybe<Scalars['DateTime']>;
    type: AdvocateNotificationType;
    userId: Scalars['ObjectId'];
    viewed: Scalars['Boolean'];
};

export type AdvocateNotificationOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type AdvocateNotificationPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type AdvocateNotificationPaginationQueryResponse = PaginationResponse & {
    __typename?: 'AdvocateNotificationPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<AdvocateNotification>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type AdvocateNotificationQueryInput = {
    filter?: InputMaybe<AdvocateNotificationFilterInput>;
    orderBy?: InputMaybe<AdvocateNotificationOrderByInput>;
    pagination?: InputMaybe<AdvocateNotificationPaginationInput>;
};

/** Type of advocate notification. */
export enum AdvocateNotificationType {
    Assigned = 'assigned',
    Unassigned = 'unassigned',
}

export type AdvocateNotificationUpdateInput = {
    advocateTaskId?: InputMaybe<Scalars['ObjectId']>;
    displayDate?: InputMaybe<Scalars['DateTime']>;
    type?: InputMaybe<AdvocateNotificationType>;
    userId?: InputMaybe<Scalars['ObjectId']>;
    viewed?: InputMaybe<Scalars['Boolean']>;
};

export type AdvocateTask = BaseEntity & {
    __typename?: 'AdvocateTask';
    actionTypes: Array<PatientActionType>;
    advocateNotifications: Array<AdvocateNotification>;
    appointmentId?: Maybe<Scalars['ObjectId']>;
    assignedTo?: Maybe<Scalars['ObjectId']>;
    checklist: Array<AdvocateTaskChecklistItem>;
    checklistWithActionTypes: Array<AdvocateTaskChecklistWithActionTypesItem>;
    commentAuthors: Array<User>;
    comments: Array<AdvocateTaskComment>;
    completedAt?: Maybe<Scalars['DateTime']>;
    createdAt: Scalars['DateTime'];
    createdBy: Scalars['ObjectId'];
    description: Scalars['String'];
    displayDate: Scalars['DateTime'];
    dueDate: Scalars['DateTime'];
    history: Array<AdvocateTaskHistoryItem>;
    historyFigures: Array<User>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    label: Scalars['String'];
    overdue?: Maybe<Scalars['Boolean']>;
    patient?: Maybe<Patient>;
    patientActions: Array<PatientAction>;
    patientId?: Maybe<Scalars['ObjectId']>;
    priority: Scalars['Float'];
    slaTimer?: Maybe<SlaTimer>;
    slaTimerId?: Maybe<Scalars['ObjectId']>;
    status: AdvocateTaskTypeStatus;
    taskCreator: User;
    typeId: Scalars['ObjectId'];
    types: Array<AdvocateTaskType>;
    updatedAt: Scalars['DateTime'];
    usersToAssign: Array<User>;
    watchedBy: Array<Scalars['ObjectId']>;
    watchers: Array<User>;
};

export type AdvocateTaskChecklistItem = {
    __typename?: 'AdvocateTaskChecklistItem';
    actionTypeIds: Array<Scalars['ObjectId']>;
    completedAt?: Maybe<Scalars['DateTime']>;
    completedBy?: Maybe<Scalars['ObjectId']>;
    description?: Maybe<Scalars['String']>;
    endSlaTimerOnComplete?: Maybe<Scalars['Boolean']>;
    id: Scalars['ObjectId'];
    label: Scalars['String'];
};

export type AdvocateTaskChecklistItemInput = {
    actionTypeIds: Array<Scalars['ObjectId']>;
    completedAt?: InputMaybe<Scalars['DateTime']>;
    completedBy?: InputMaybe<Scalars['ObjectId']>;
    description?: InputMaybe<Scalars['String']>;
    endSlaTimerOnComplete?: InputMaybe<Scalars['Boolean']>;
    id: Scalars['ObjectId'];
    label: Scalars['String'];
};

export type AdvocateTaskChecklistWithActionTypesItem = {
    __typename?: 'AdvocateTaskChecklistWithActionTypesItem';
    actionTypeIds: Array<Scalars['ObjectId']>;
    actionTypes: Array<PatientActionType>;
    completedAt?: Maybe<Scalars['DateTime']>;
    completedBy?: Maybe<Scalars['ObjectId']>;
    description?: Maybe<Scalars['String']>;
    endSlaTimerOnComplete?: Maybe<Scalars['Boolean']>;
    id: Scalars['ObjectId'];
    label: Scalars['String'];
};

export type AdvocateTaskComment = {
    __typename?: 'AdvocateTaskComment';
    authorId: Scalars['ObjectId'];
    message: Scalars['String'];
    when: Scalars['DateTime'];
};

export type AdvocateTaskCommentInput = {
    authorId: Scalars['ObjectId'];
    message: Scalars['String'];
    when: Scalars['DateTime'];
};

export type AdvocateTaskFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<AdvocateTaskUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type AdvocateTaskHistoryItem = {
    __typename?: 'AdvocateTaskHistoryItem';
    id: Scalars['ObjectId'];
    what: Scalars['String'];
    when: Scalars['DateTime'];
    whoId: Scalars['ObjectId'];
};

export type AdvocateTaskHistoryItemInput = {
    id: Scalars['ObjectId'];
    what: Scalars['String'];
    when: Scalars['DateTime'];
    whoId: Scalars['ObjectId'];
};

export type AdvocateTaskInput = {
    appointmentId?: InputMaybe<Scalars['ObjectId']>;
    assignedTo?: InputMaybe<Scalars['ObjectId']>;
    checklist: Array<AdvocateTaskChecklistItemInput>;
    comments: Array<AdvocateTaskCommentInput>;
    completedAt?: InputMaybe<Scalars['DateTime']>;
    createdBy: Scalars['ObjectId'];
    dateTriggerId?: InputMaybe<Scalars['ObjectId']>;
    description: Scalars['String'];
    displayDate: Scalars['DateTime'];
    dueDate: Scalars['DateTime'];
    eventTriggerId?: InputMaybe<Scalars['ObjectId']>;
    history: Array<AdvocateTaskHistoryItemInput>;
    isTestData: Scalars['Boolean'];
    label: Scalars['String'];
    patientId: Scalars['ObjectId'];
    priority: Scalars['Float'];
    slaTimerId?: InputMaybe<Scalars['ObjectId']>;
    status: AdvocateTaskTypeStatus;
    tagTriggerId?: InputMaybe<Scalars['ObjectId']>;
    trendTriggerId?: InputMaybe<Scalars['ObjectId']>;
    typeId: Scalars['ObjectId'];
    watchedBy: Array<Scalars['ObjectId']>;
};

export type AdvocateTaskOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type AdvocateTaskPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type AdvocateTaskPaginationQueryResponse = PaginationResponse & {
    __typename?: 'AdvocateTaskPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<AdvocateTask>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type AdvocateTaskQueryInput = {
    filter?: InputMaybe<AdvocateTaskFilterInput>;
    orderBy?: InputMaybe<AdvocateTaskOrderByInput>;
    pagination?: InputMaybe<AdvocateTaskPaginationInput>;
};

export type AdvocateTaskTemplate = BaseEntity & {
    __typename?: 'AdvocateTaskTemplate';
    checklist: Array<AdvocateTaskChecklistItem>;
    createdAt: Scalars['DateTime'];
    description: Scalars['String'];
    id: Scalars['ObjectId'];
    interventionDurationMinutes?: Maybe<Scalars['Float']>;
    isTestData?: Maybe<Scalars['Boolean']>;
    label: Scalars['String'];
    priority: Scalars['Float'];
    shouldNotify?: Maybe<Scalars['Boolean']>;
    slaId?: Maybe<Scalars['ObjectId']>;
    typeId: Scalars['ObjectId'];
    updatedAt: Scalars['DateTime'];
    whenTimeline?: Maybe<AdvocateTaskTemplateTimeline>;
    whenType: AdvocateTaskTemplateWhenType;
};

export type AdvocateTaskTemplateFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<AdvocateTaskTemplateUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type AdvocateTaskTemplateInput = {
    checklist?: InputMaybe<Array<InputMaybe<AdvocateTaskChecklistItemInput>>>;
    description: Scalars['String'];
    interventionDurationMinutes?: InputMaybe<Scalars['Float']>;
    label: Scalars['String'];
    priority: Scalars['Float'];
    shouldNotify?: InputMaybe<Scalars['Boolean']>;
    slaId?: InputMaybe<Scalars['ObjectId']>;
    typeId: Scalars['ObjectId'];
    whenTimeline?: InputMaybe<AdvocateTaskTemplateTimelineInput>;
    whenType?: InputMaybe<AdvocateTaskTemplateWhenType>;
};

export type AdvocateTaskTemplateOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type AdvocateTaskTemplatePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type AdvocateTaskTemplatePaginationQueryResponse = PaginationResponse & {
    __typename?: 'AdvocateTaskTemplatePaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<AdvocateTaskTemplate>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type AdvocateTaskTemplateQueryInput = {
    filter?: InputMaybe<AdvocateTaskTemplateFilterInput>;
    orderBy?: InputMaybe<AdvocateTaskTemplateOrderByInput>;
    pagination?: InputMaybe<AdvocateTaskTemplatePaginationInput>;
};

export type AdvocateTaskTemplateTimeline = {
    __typename?: 'AdvocateTaskTemplateTimeline';
    durationMinutes: Scalars['Float'];
    excludeApps?: Maybe<Array<Scalars['String']>>;
    includeApps?: Maybe<Array<Scalars['String']>>;
    offsetDays: Scalars['Float'];
    /** @deprecated Use the offsetDays, offsetMonths and offsetYears instead. */
    offsetMinutes?: Maybe<Scalars['Float']>;
    offsetMonths: Scalars['Float'];
    offsetSequence?: Maybe<Scalars['Float']>;
    offsetYears: Scalars['Float'];
    tags?: Maybe<Array<Scalars['String']>>;
    timelineId: Scalars['ObjectId'];
};

export type AdvocateTaskTemplateTimelineInput = {
    durationMinutes: Scalars['Float'];
    excludeApps?: InputMaybe<Array<Scalars['String']>>;
    includeApps?: InputMaybe<Array<Scalars['String']>>;
    offsetDays: Scalars['Float'];
    offsetMinutes?: InputMaybe<Scalars['Float']>;
    offsetMonths: Scalars['Float'];
    offsetSequence?: InputMaybe<Scalars['Float']>;
    offsetYears: Scalars['Float'];
    tags?: InputMaybe<Array<Scalars['String']>>;
    timelineId: Scalars['ObjectId'];
};

export type AdvocateTaskTemplateUpdateInput = {
    assignedTo?: InputMaybe<Scalars['ObjectId']>;
    checklist?: InputMaybe<Array<InputMaybe<AdvocateTaskChecklistItemInput>>>;
    description?: InputMaybe<Scalars['String']>;
    interventionDurationMinutes?: InputMaybe<Scalars['Float']>;
    label?: InputMaybe<Scalars['String']>;
    priority?: InputMaybe<Scalars['Float']>;
    shouldNotify?: InputMaybe<Scalars['Boolean']>;
    slaId?: InputMaybe<Scalars['ObjectId']>;
    typeId?: InputMaybe<Scalars['ObjectId']>;
    whenTimeline?: InputMaybe<AdvocateTaskTemplateTimelineInput>;
    whenType?: InputMaybe<AdvocateTaskTemplateWhenType>;
};

/** Advocate task template when type. */
export enum AdvocateTaskTemplateWhenType {
    Intervention = 'intervention',
    Timeline = 'timeline',
}

export type AdvocateTaskTimelineViewerFetchInput = {
    tags?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    timelines?: InputMaybe<Array<InputMaybe<TimelineViewerFetchInputTimeline>>>;
};

export type AdvocateTaskType = BaseEntity & {
    __typename?: 'AdvocateTaskType';
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    label: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type AdvocateTaskTypeFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<AdvocateTaskTypeUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type AdvocateTaskTypeInput = {
    label: Scalars['String'];
};

export type AdvocateTaskTypeOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type AdvocateTaskTypePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type AdvocateTaskTypePaginationQueryResponse = PaginationResponse & {
    __typename?: 'AdvocateTaskTypePaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<AdvocateTaskType>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type AdvocateTaskTypeQueryInput = {
    filter?: InputMaybe<AdvocateTaskTypeFilterInput>;
    orderBy?: InputMaybe<AdvocateTaskTypeOrderByInput>;
    pagination?: InputMaybe<AdvocateTaskTypePaginationInput>;
};

/** Advocate task type status. */
export enum AdvocateTaskTypeStatus {
    Closed = 'Closed',
    Open = 'Open',
}

export type AdvocateTaskTypeUpdateInput = {
    label?: InputMaybe<Scalars['String']>;
};

export type AdvocateTaskUpdateInput = {
    appointmentId?: InputMaybe<Scalars['ObjectId']>;
    assignedTo?: InputMaybe<Scalars['ObjectId']>;
    checklist?: InputMaybe<Array<InputMaybe<AdvocateTaskChecklistItemInput>>>;
    comments?: InputMaybe<Array<InputMaybe<AdvocateTaskCommentInput>>>;
    completedAt?: InputMaybe<Scalars['DateTime']>;
    createdBy?: InputMaybe<Scalars['ObjectId']>;
    description?: InputMaybe<Scalars['String']>;
    displayDate?: InputMaybe<Scalars['DateTime']>;
    dueDate?: InputMaybe<Scalars['DateTime']>;
    history?: InputMaybe<Array<InputMaybe<AdvocateTaskHistoryItemInput>>>;
    label?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    priority?: InputMaybe<Scalars['Float']>;
    slaTimerId?: InputMaybe<Scalars['ObjectId']>;
    status?: InputMaybe<AdvocateTaskTypeStatus>;
    typeId?: InputMaybe<Scalars['ObjectId']>;
    watchedBy?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
};

export type AdvocateTasksCustomFiltersInput = {
    customDue?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    due?: InputMaybe<Scalars['String']>;
    filterTestData?: InputMaybe<Scalars['Boolean']>;
    importance?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
    label?: InputMaybe<Scalars['String']>;
    patients?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AdvocateTasksExtendedFilteringFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<AdvocateTaskUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type AdvocateTasksExtendedFilteringOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type AdvocateTasksExtendedFilteringPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type AffiliateClaim = BaseEntity & {
    __typename?: 'AffiliateClaim';
    affiliate?: Maybe<VirtualCareAffiliatesEntity>;
    affiliateId: Scalars['String'];
    batchId?: Maybe<Scalars['Float']>;
    createdAt: Scalars['DateTime'];
    dateTime: Scalars['DateTime'];
    errorText?: Maybe<Scalars['String']>;
    fileName: Scalars['String'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    location?: Maybe<Scalars['String']>;
    source?: Maybe<AffiliateClaimSource>;
    status: AffiliateClaimStatus;
    updatedAt: Scalars['DateTime'];
};

export type AffiliateClaimFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<AffiliateClaimFilteringInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type AffiliateClaimFilteringFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<AffiliateClaimFilteringInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type AffiliateClaimFilteringInput = {
    affiliateId?: InputMaybe<Scalars['String']>;
    dateTime?: InputMaybe<Scalars['DateTime']>;
    file?: InputMaybe<Array<Scalars['String']>>;
    fileName?: InputMaybe<Scalars['String']>;
    internalName?: InputMaybe<Scalars['String']>;
    source?: InputMaybe<AffiliateClaimSource>;
    status?: InputMaybe<AffiliateClaimStatus>;
};

export type AffiliateClaimFilteringOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type AffiliateClaimFilteringPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type AffiliateClaimFromBuilder = {
    birthDate?: InputMaybe<Scalars['String']>;
    dateOfService?: InputMaybe<Scalars['String']>;
    firstName?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    serviceType?: InputMaybe<Scalars['String']>;
};

export type AffiliateClaimFromBuilderInput = {
    affiliateId?: InputMaybe<Scalars['String']>;
    claims?: InputMaybe<Array<InputMaybe<AffiliateClaimFromBuilder>>>;
};

export type AffiliateClaimInput = {
    affiliateId: Scalars['String'];
    dateTime: Scalars['DateTime'];
    file?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fileName: Scalars['String'];
};

export type AffiliateClaimOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type AffiliateClaimPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type AffiliateClaimPaginationQueryResponse = PaginationResponse & {
    __typename?: 'AffiliateClaimPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<AffiliateClaim>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type AffiliateClaimQueryInput = {
    filter?: InputMaybe<AffiliateClaimFilterInput>;
    orderBy?: InputMaybe<AffiliateClaimOrderByInput>;
    pagination?: InputMaybe<AffiliateClaimPaginationInput>;
};

export type AffiliateClaimResponse = MutationResponse & {
    __typename?: 'AffiliateClaimResponse';
    claim?: Maybe<AffiliateClaim>;
    error?: Maybe<AffiliateClaimResponseError>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type AffiliateClaimResponseError = {
    __typename?: 'AffiliateClaimResponseError';
    data?: Maybe<Array<Scalars['String']>>;
    message?: Maybe<Scalars['String']>;
};

export type AffiliateClaimSettingsResponse = {
    __typename?: 'AffiliateClaimSettingsResponse';
    inFutureValidationEnabled?: Maybe<Scalars['Boolean']>;
};

/** Indicates claims source */
export enum AffiliateClaimSource {
    Builder = 'builder',
    File = 'file',
}

/** Indicates claim processing result */
export enum AffiliateClaimStatus {
    Error = 'error',
    Processed = 'processed',
    Submitted = 'submitted',
}

export type AffiliateInvoice = BaseEntity & {
    __typename?: 'AffiliateInvoice';
    AffiliateClaims: Array<Scalars['ObjectId']>;
    AffiliateId: Scalars['ObjectId'];
    affiliate: VirtualCareAffiliatesEntity;
    affiliateId: Scalars['String'];
    apiError?: Maybe<Scalars['String']>;
    batchClaimId?: Maybe<Scalars['Float']>;
    billComInvoiceId?: Maybe<Scalars['String']>;
    billId?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    lineItems?: Maybe<Array<Maybe<LineItem>>>;
    paymentStatus: AffiliateInvoicePaymentStatus;
    paymentTotal?: Maybe<Scalars['String']>;
    statusWasChanged?: Maybe<Scalars['Boolean']>;
    updatedAt: Scalars['DateTime'];
    vendorId: Scalars['String'];
};

export type AffiliateInvoiceFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<AffiliateInvoiceUpdateInput>;
    fieldsInList?: InputMaybe<AffiliateInvoicesFieldInListFilterInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type AffiliateInvoiceInput = {
    AffiliateClaims: Array<Scalars['ObjectId']>;
    batchClaimId: Scalars['Float'];
    paymentStatus: AffiliateInvoicePaymentStatus;
};

export type AffiliateInvoiceOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type AffiliateInvoicePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type AffiliateInvoicePaginationQueryResponse = PaginationResponse & {
    __typename?: 'AffiliateInvoicePaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<AffiliateInvoice>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

/** Payment Status result */
export enum AffiliateInvoicePaymentStatus {
    InProcess = 'inProcess',
    Open = 'open',
    PaidInFull = 'paidInFull',
    PartialPayment = 'partialPayment',
    Scheduled = 'scheduled',
}

export type AffiliateInvoiceQueryInput = {
    filter?: InputMaybe<AffiliateInvoiceFilterInput>;
    orderBy?: InputMaybe<AffiliateInvoiceOrderByInput>;
    pagination?: InputMaybe<AffiliateInvoicePaginationInput>;
};

export type AffiliateInvoiceUpdateInput = {
    affiliateId?: InputMaybe<Scalars['String']>;
    billId?: InputMaybe<Scalars['String']>;
    paymentStatus?: InputMaybe<AffiliateInvoicePaymentStatus>;
    paymentTotal?: InputMaybe<Scalars['String']>;
};

export type AffiliateInvoicesFieldInListFilterInput = {
    billId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    paymentStatus?: InputMaybe<Array<InputMaybe<AffiliateInvoicePaymentStatus>>>;
};

export type AgeProfileValueSelector = {
    patientIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum AlertSeverity {
    Error = 'error',
    Info = 'info',
    Success = 'success',
    Warning = 'warning',
}

export type AlexaDataInput = {
    breastFeedingType?: InputMaybe<Scalars['String']>;
    measurementUnit?: InputMaybe<Scalars['String']>;
    payload?: InputMaybe<Scalars['String']>;
    version?: InputMaybe<Scalars['String']>;
};

export type AlexaDataType = {
    __typename?: 'AlexaDataType';
    breastFeedingType?: Maybe<Scalars['String']>;
    measurementUnit?: Maybe<Scalars['String']>;
    payload?: Maybe<Scalars['String']>;
    version?: Maybe<Scalars['String']>;
};

/** Which app platform (data models, flows, etc.) is this? */
export enum AppPlatform {
    Swc = 'SWC',
    Vbc = 'VBC',
}

export type AppUser = BaseEntity & {
    __typename?: 'AppUser';
    appBundleId?: Maybe<Scalars['String']>;
    articleContent?: Maybe<ContentStatus>;
    articleTime?: Maybe<Scalars['Float']>;
    birthDate?: Maybe<Scalars['DateTime']>;
    contentCreated?: Maybe<Scalars['Boolean']>;
    createdAt: Scalars['DateTime'];
    email?: Maybe<Scalars['String']>;
    familyId?: Maybe<Scalars['String']>;
    familyRole?: Maybe<FamilyRole>;
    firstName?: Maybe<Scalars['String']>;
    highlightContent?: Maybe<ContentStatus>;
    highlightTime?: Maybe<Scalars['Float']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    language?: Maybe<Language>;
    lastName?: Maybe<Scalars['String']>;
    mfaCode?: Maybe<Scalars['String']>;
    mfaExpiration?: Maybe<Scalars['DateTime']>;
    notificationTokens?: Maybe<Scalars['JSON']>;
    organizationId?: Maybe<Scalars['ObjectId']>;
    passwordSalt?: Maybe<Scalars['String']>;
    patientId?: Maybe<Scalars['ObjectId']>;
    persistentDbEncryptionToken?: Maybe<Scalars['String']>;
    phoneNumber?: Maybe<Scalars['String']>;
    readingLevel?: Maybe<ReadingLevel>;
    textOptOutSent?: Maybe<Scalars['Boolean']>;
    toDoContent?: Maybe<ContentStatus>;
    toDoTime?: Maybe<Scalars['Float']>;
    updatedAt: Scalars['DateTime'];
    useHighlights?: Maybe<Scalars['Boolean']>;
    userEntityIdMigrated?: Maybe<Scalars['String']>;
};

export type AppUserAcceptInviteInput = {
    appBundleId: Scalars['String'];
    email: Scalars['String'];
    externalId?: InputMaybe<Scalars['String']>;
    healthPlan?: InputMaybe<Scalars['String']>;
    inviteCode: Scalars['String'];
    language?: InputMaybe<Language>;
    lob?: InputMaybe<Scalars['String']>;
    memberId?: InputMaybe<Scalars['String']>;
    password: Scalars['String'];
};

export type AppUserArticle = BaseEntity & {
    __typename?: 'AppUserArticle';
    appBundleId: Scalars['String'];
    appUserId: Scalars['ObjectId'];
    articleCacheId: Scalars['ObjectId'];
    articleId: Scalars['ObjectId'];
    bookmarked?: Maybe<Scalars['Boolean']>;
    bookmarkedOn?: Maybe<Scalars['DateTime']>;
    createdAt: Scalars['DateTime'];
    firstViewed?: Maybe<Scalars['DateTime']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    organizationId?: Maybe<Scalars['ObjectId']>;
    updatedAt: Scalars['DateTime'];
    viewed?: Maybe<Scalars['Boolean']>;
};

export type AppUserArticleFeed = {
    __typename?: 'AppUserArticleFeed';
    availableUntil: Scalars['DateTime'];
    id: Scalars['String'];
    summary: Scalars['String'];
    title: Scalars['String'];
};

export type AppUserArticleFeedResult = {
    __typename?: 'AppUserArticleFeedResult';
    items: Array<AppUserArticleFeed>;
};

export type AppUserArticleFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<AppUserArticleUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type AppUserArticleInput = {
    appBundleId: Scalars['String'];
    appUserId: Scalars['ObjectId'];
    articleCacheId: Scalars['ObjectId'];
    articleId: Scalars['ObjectId'];
    bookmarked?: InputMaybe<Scalars['Boolean']>;
    bookmarkedOn?: InputMaybe<Scalars['DateTime']>;
    firstViewed?: InputMaybe<Scalars['DateTime']>;
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    viewed?: InputMaybe<Scalars['Boolean']>;
};

export type AppUserArticleOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type AppUserArticlePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type AppUserArticlePaginationQueryResponse = PaginationResponse & {
    __typename?: 'AppUserArticlePaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<AppUserArticle>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type AppUserArticleQueryInput = {
    filter?: InputMaybe<AppUserArticleFilterInput>;
    orderBy?: InputMaybe<AppUserArticleOrderByInput>;
    pagination?: InputMaybe<AppUserArticlePaginationInput>;
};

export type AppUserArticleUpdateInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    appUserId?: InputMaybe<Scalars['ObjectId']>;
    articleCacheId?: InputMaybe<Scalars['ObjectId']>;
    articleId?: InputMaybe<Scalars['ObjectId']>;
    bodyText?: InputMaybe<Scalars['String']>;
    bookmarked?: InputMaybe<Scalars['Boolean']>;
    bookmarkedOn?: InputMaybe<Scalars['DateTime']>;
    firstViewed?: InputMaybe<Scalars['DateTime']>;
    icon?: InputMaybe<ContentIcon>;
    image?: InputMaybe<Scalars['String']>;
    summaryText?: InputMaybe<Scalars['String']>;
    titleText?: InputMaybe<Scalars['String']>;
    viewed?: InputMaybe<Scalars['Boolean']>;
};

export type AppUserByEmailInput = {
    email?: InputMaybe<Scalars['String']>;
};

export type AppUserEventTrigger = BaseEntity & {
    __typename?: 'AppUserEventTrigger';
    appBundleId: Scalars['String'];
    appUserId: Scalars['ObjectId'];
    createdAt: Scalars['DateTime'];
    eventTriggerId: Scalars['ObjectId'];
    eventTriggerName: Scalars['String'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    organizationId?: Maybe<Scalars['ObjectId']>;
    patientId: Scalars['ObjectId'];
    profileValueId?: Maybe<Scalars['ObjectId']>;
    trackerId?: Maybe<Scalars['ObjectId']>;
    updatedAt: Scalars['DateTime'];
};

export type AppUserEventTriggerFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<AppUserEventTriggerUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type AppUserEventTriggerInput = {
    appBundleId: Scalars['String'];
    appUserId: Scalars['ObjectId'];
    eventTriggerId: Scalars['ObjectId'];
    eventTriggerName: Scalars['String'];
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    patientId: Scalars['ObjectId'];
    profileValueId?: InputMaybe<Scalars['ObjectId']>;
    trackerId?: InputMaybe<Scalars['ObjectId']>;
};

export type AppUserEventTriggerOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type AppUserEventTriggerPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type AppUserEventTriggerPaginationQueryResponse = PaginationResponse & {
    __typename?: 'AppUserEventTriggerPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<AppUserEventTrigger>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type AppUserEventTriggerQueryInput = {
    filter?: InputMaybe<AppUserEventTriggerFilterInput>;
    orderBy?: InputMaybe<AppUserEventTriggerOrderByInput>;
    pagination?: InputMaybe<AppUserEventTriggerPaginationInput>;
};

export type AppUserEventTriggerUpdateInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    appUserId?: InputMaybe<Scalars['ObjectId']>;
    eventTriggerId?: InputMaybe<Scalars['ObjectId']>;
    eventTriggerName?: InputMaybe<Scalars['String']>;
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    profileValueId?: InputMaybe<Scalars['ObjectId']>;
    trackerId?: InputMaybe<Scalars['ObjectId']>;
};

export type AppUserEventTriggerUsedInput = {
    appUserId?: InputMaybe<Scalars['String']>;
    eventTriggerId?: InputMaybe<Scalars['String']>;
};

export type AppUserFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<AppUserUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type AppUserIdFromEmailResponseData = {
    __typename?: 'AppUserIdFromEmailResponseData';
    appUserId: Scalars['String'];
};

export type AppUserInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    appUserId?: InputMaybe<Scalars['String']>;
    portalPatientId?: InputMaybe<Scalars['String']>;
};

export type AppUserLoggedIn = {
    __typename?: 'AppUserLoggedIn';
    appUserId?: Maybe<Scalars['ObjectId']>;
    authToken?: Maybe<Scalars['String']>;
    isFirstLoginAfterMigration?: Maybe<Scalars['Boolean']>;
    language?: Maybe<Language>;
    mfaRequired?: Maybe<Scalars['Boolean']>;
    patientId?: Maybe<Scalars['ObjectId']>;
    persistentDbEncryptionToken?: Maybe<Scalars['String']>;
    phoneLast4digit?: Maybe<Scalars['String']>;
};

export type AppUserOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type AppUserPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type AppUserPaginationQueryResponse = PaginationResponse & {
    __typename?: 'AppUserPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<AppUser>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type AppUserQueryInput = {
    filter?: InputMaybe<AppUserFilterInput>;
    orderBy?: InputMaybe<AppUserOrderByInput>;
    pagination?: InputMaybe<AppUserPaginationInput>;
};

export type AppUserRecordInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    articleContent?: InputMaybe<ContentStatus>;
    articleTime?: InputMaybe<Scalars['Float']>;
    birthDate?: InputMaybe<Scalars['DateTime']>;
    contentCreated?: InputMaybe<Scalars['Boolean']>;
    email?: InputMaybe<Scalars['String']>;
    familyId?: InputMaybe<Scalars['String']>;
    familyRole?: InputMaybe<FamilyRole>;
    firstName?: InputMaybe<Scalars['String']>;
    highlightContent?: InputMaybe<ContentStatus>;
    highlightTime?: InputMaybe<Scalars['Float']>;
    language?: InputMaybe<Language>;
    lastName?: InputMaybe<Scalars['String']>;
    mfaCode?: InputMaybe<Scalars['String']>;
    mfaExpiration?: InputMaybe<Scalars['DateTime']>;
    notificationTokens?: InputMaybe<Scalars['JSON']>;
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    passwordSalt?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    persistentDbEncryptionToken?: InputMaybe<Scalars['String']>;
    phoneNumber?: InputMaybe<Scalars['String']>;
    readingLevel?: InputMaybe<ReadingLevel>;
    textOptOutSent?: InputMaybe<Scalars['Boolean']>;
    toDoContent?: InputMaybe<ContentStatus>;
    toDoTime?: InputMaybe<Scalars['Float']>;
    useHighlights?: InputMaybe<Scalars['Boolean']>;
    userEntityIdMigrated?: InputMaybe<Scalars['String']>;
};

export type AppUserResponseData = {
    __typename?: 'AppUserResponseData';
    appBundleId?: Maybe<Scalars['String']>;
    appUserId?: Maybe<Scalars['ObjectId']>;
    familyId?: Maybe<Scalars['String']>;
    patientId?: Maybe<Scalars['String']>;
};

export type AppUserSetContentStatusInput = {
    appUserId?: InputMaybe<Scalars['String']>;
    articleStatus?: InputMaybe<ContentStatus>;
    articleTime?: InputMaybe<Scalars['Float']>;
    highlightStatus?: InputMaybe<ContentStatus>;
    highlightTime?: InputMaybe<Scalars['Float']>;
    toDoStatus?: InputMaybe<ContentStatus>;
    toDoTime?: InputMaybe<Scalars['Float']>;
};

export type AppUserTelemetry = BaseEntity & {
    __typename?: 'AppUserTelemetry';
    appUserId?: Maybe<Scalars['ObjectId']>;
    createdAt: Scalars['DateTime'];
    data?: Maybe<Scalars['JSON']>;
    eventDate: Scalars['DateTime'];
    eventType: Scalars['String'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    organizationId?: Maybe<Scalars['ObjectId']>;
    updatedAt: Scalars['DateTime'];
};

export type AppUserTelemetryInput = {
    appUserId?: InputMaybe<Scalars['ObjectId']>;
    data?: InputMaybe<Scalars['JSON']>;
    eventDate?: InputMaybe<Scalars['DateTime']>;
    eventType?: InputMaybe<Scalars['String']>;
};

export type AppUserTelemetryPaginationInput = {
    appUserId?: InputMaybe<Scalars['String']>;
    eventType?: InputMaybe<Scalars['String']>;
    limit?: InputMaybe<Scalars['Float']>;
    skip?: InputMaybe<Scalars['Float']>;
};

export type AppUserTelemetryResult = {
    __typename?: 'AppUserTelemetryResult';
    eventTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
    itemcount?: Maybe<Scalars['Float']>;
    items?: Maybe<Array<Maybe<AppUserTelemetry>>>;
};

export type AppUserTokenResponse = MutationResponse & {
    __typename?: 'AppUserTokenResponse';
    data?: Maybe<AppUserLoggedIn>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type AppUserTrendTrigger = BaseEntity & {
    __typename?: 'AppUserTrendTrigger';
    appBundleId: Scalars['String'];
    appUserId: Scalars['ObjectId'];
    createdAt: Scalars['DateTime'];
    expressionId?: Maybe<Scalars['ObjectId']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    organizationId?: Maybe<Scalars['String']>;
    patientId: Scalars['ObjectId'];
    trendData?: Maybe<Array<TrendData>>;
    trendTriggerId: Scalars['ObjectId'];
    trendTriggerName: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type AppUserTrendTriggerFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<AppUserTrendTriggerUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type AppUserTrendTriggerInput = {
    appBundleId: Scalars['String'];
    appUserId: Scalars['ObjectId'];
    expressionId?: InputMaybe<Scalars['ObjectId']>;
    organizationId?: InputMaybe<Scalars['String']>;
    patientId: Scalars['ObjectId'];
    trendData?: InputMaybe<Array<TrendDataInput>>;
    trendTriggerId: Scalars['ObjectId'];
    trendTriggerName: Scalars['String'];
};

export type AppUserTrendTriggerOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type AppUserTrendTriggerPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type AppUserTrendTriggerPaginationQueryResponse = PaginationResponse & {
    __typename?: 'AppUserTrendTriggerPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<AppUserTrendTrigger>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type AppUserTrendTriggerQueryInput = {
    filter?: InputMaybe<AppUserTrendTriggerFilterInput>;
    orderBy?: InputMaybe<AppUserTrendTriggerOrderByInput>;
    pagination?: InputMaybe<AppUserTrendTriggerPaginationInput>;
};

export type AppUserTrendTriggerUpdateInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    appUserId?: InputMaybe<Scalars['ObjectId']>;
    expressionId?: InputMaybe<Scalars['ObjectId']>;
    organizationId?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    trendData?: InputMaybe<Array<InputMaybe<TrendDataInput>>>;
    trendTriggerId?: InputMaybe<Scalars['ObjectId']>;
    trendTriggerName?: InputMaybe<Scalars['String']>;
};

export type AppUserUpdateInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    articleContent?: InputMaybe<ContentStatus>;
    articleTime?: InputMaybe<Scalars['Float']>;
    birthDate?: InputMaybe<Scalars['DateTime']>;
    contentCreated?: InputMaybe<Scalars['Boolean']>;
    email?: InputMaybe<Scalars['String']>;
    familyId?: InputMaybe<Scalars['String']>;
    familyRole?: InputMaybe<FamilyRole>;
    firstName?: InputMaybe<Scalars['String']>;
    highlightContent?: InputMaybe<ContentStatus>;
    highlightTime?: InputMaybe<Scalars['Float']>;
    language?: InputMaybe<Language>;
    lastName?: InputMaybe<Scalars['String']>;
    mfaCode?: InputMaybe<Scalars['String']>;
    mfaExpiration?: InputMaybe<Scalars['DateTime']>;
    notificationTokens?: InputMaybe<Scalars['JSON']>;
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    passwordSalt?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    persistentDbEncryptionToken?: InputMaybe<Scalars['String']>;
    phoneNumber?: InputMaybe<Scalars['String']>;
    readingLevel?: InputMaybe<ReadingLevel>;
    textOptOutSent?: InputMaybe<Scalars['Boolean']>;
    toDoContent?: InputMaybe<ContentStatus>;
    toDoTime?: InputMaybe<Scalars['Float']>;
    useHighlights?: InputMaybe<Scalars['Boolean']>;
    userEntityIdMigrated?: InputMaybe<Scalars['String']>;
};

export type AppUserWithPVs = {
    __typename?: 'AppUserWithPVs';
    appBundleId?: Maybe<Scalars['String']>;
    appUserId?: Maybe<Scalars['String']>;
    patientId?: Maybe<Scalars['String']>;
    profileValues?: Maybe<Array<Maybe<ProfileVariable>>>;
};

export type AppUserWithPVsInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    limit?: InputMaybe<Scalars['Float']>;
    skip?: InputMaybe<Scalars['Float']>;
};

export type AppUsersByPatientsIdInput = {
    patientId?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
};

export type AppUsersByPatientsIds = {
    __typename?: 'AppUsersByPatientsIds';
    results: Array<AppUser>;
};

export type AppUsersFieldsInListInput = {
    fieldsInList?: InputMaybe<AppUsersByPatientsIdInput>;
};

export type AppUsersGroupedByBundleId = {
    __typename?: 'AppUsersGroupedByBundleId';
    appBundleId: Scalars['String'];
    appUsers: Array<AppUser>;
};

export type AppUsersGroupedByBundleIdResponse = {
    __typename?: 'AppUsersGroupedByBundleIdResponse';
    apps: Array<AppUsersGroupedByBundleId>;
};

export type Application = BaseEntity & {
    __typename?: 'Application';
    appBundleId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    deepLinkUrls: Array<ApplicationUrl>;
    emailLogoFile?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    isActive: Scalars['Boolean'];
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type ApplicationFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ApplicationUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ApplicationInput = {
    appBundleId: Scalars['String'];
    deepLinkUrls: Array<ApplicationUrlInput>;
    emailLogoFile?: InputMaybe<Scalars['String']>;
    isActive: Scalars['Boolean'];
    name: Scalars['String'];
};

export type ApplicationOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ApplicationPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ApplicationPaginationQueryResponse = PaginationResponse & {
    __typename?: 'ApplicationPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Application>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ApplicationQueryInput = {
    filter?: InputMaybe<ApplicationFilterInput>;
    orderBy?: InputMaybe<ApplicationOrderByInput>;
    pagination?: InputMaybe<ApplicationPaginationInput>;
};

export type ApplicationUpdateInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    deepLinkUrls?: InputMaybe<Array<ApplicationUrlInput>>;
    emailLogoFile?: InputMaybe<Scalars['String']>;
    isActive?: InputMaybe<Scalars['Boolean']>;
    name?: InputMaybe<Scalars['String']>;
};

export type ApplicationUrl = {
    __typename?: 'ApplicationUrl';
    environment: Scalars['String'];
    url: Scalars['String'];
};

export type ApplicationUrlInput = {
    environment: Scalars['String'];
    url: Scalars['String'];
};

export type Appointment = BaseEntity & {
    __typename?: 'Appointment';
    createdAt: Scalars['DateTime'];
    description?: Maybe<Scalars['String']>;
    /** Positive Integer */
    durationMinutes?: Maybe<Scalars['Float']>;
    externalId?: Maybe<Scalars['String']>;
    externalProviderId?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    isVirtualVisit?: Maybe<Scalars['Boolean']>;
    /** For Appointment Notes from Lifeline */
    note?: Maybe<Scalars['String']>;
    patient: Patient;
    /** For phone created appointments */
    physicalLocation?: Maybe<Scalars['String']>;
    /** Default to patient primary practice */
    practice?: Maybe<Organization>;
    reportKey?: Maybe<Scalars['String']>;
    source: AppointmentSource;
    staff?: Maybe<User>;
    /** For phone created appointments */
    staffName?: Maybe<Scalars['String']>;
    startDate: Scalars['DateTime'];
    status?: Maybe<Scalars['String']>;
    timezoneName?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
    virtualLink?: Maybe<Scalars['String']>;
    visitLocationDepartment?: Maybe<Scalars['String']>;
    visitLocationFacility?: Maybe<Scalars['String']>;
    visitProviderEmail?: Maybe<Scalars['String']>;
    visitProviderFirstName?: Maybe<Scalars['String']>;
    visitProviderLastName?: Maybe<Scalars['String']>;
    visitProviderLocationAddress?: Maybe<Scalars['String']>;
    visitProviderLocationCity?: Maybe<Scalars['String']>;
    visitProviderLocationState?: Maybe<Scalars['String']>;
    visitProviderLocationZip?: Maybe<Scalars['String']>;
    visitProviderPhoneNumber?: Maybe<Scalars['String']>;
    /** For content promotion? */
    visitType?: Maybe<Scalars['String']>;
};

export type AppointmentFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<AppointmentUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

/** Status of appointment import */
export enum AppointmentImportStatus {
    AppointmentNotFound = 'APPOINTMENT_NOT_FOUND',
    Created = 'CREATED',
    Deleted = 'DELETED',
    PatientNotFound = 'PATIENT_NOT_FOUND',
    Rescheduled = 'RESCHEDULED',
    TsException = 'TS_EXCEPTION',
    Updated = 'UPDATED',
}

export type AppointmentInput = {
    description?: InputMaybe<Scalars['String']>;
    /** Positive Integer */
    durationMinutes?: InputMaybe<Scalars['Float']>;
    externalId?: InputMaybe<Scalars['String']>;
    externalProviderId?: InputMaybe<Scalars['String']>;
    isVirtualVisit?: InputMaybe<Scalars['Boolean']>;
    /** For Appointment Notes from Lifeline */
    note?: InputMaybe<Scalars['String']>;
    patientId: Scalars['ObjectId'];
    /** For phone created appointments */
    physicalLocation?: InputMaybe<Scalars['String']>;
    practiceId?: InputMaybe<Scalars['ObjectId']>;
    reportKey?: InputMaybe<Scalars['String']>;
    source: AppointmentSource;
    staffId?: InputMaybe<Scalars['ObjectId']>;
    /** For phone created appointments */
    staffName?: InputMaybe<Scalars['String']>;
    startDate: Scalars['DateTime'];
    status?: InputMaybe<Scalars['String']>;
    timezoneName?: InputMaybe<Scalars['String']>;
    virtualLink?: InputMaybe<Scalars['String']>;
    visitLocationDepartment?: InputMaybe<Scalars['String']>;
    visitLocationFacility?: InputMaybe<Scalars['String']>;
    visitProviderEmail?: InputMaybe<Scalars['String']>;
    visitProviderFirstName?: InputMaybe<Scalars['String']>;
    visitProviderLastName?: InputMaybe<Scalars['String']>;
    visitProviderLocationAddress?: InputMaybe<Scalars['String']>;
    visitProviderLocationCity?: InputMaybe<Scalars['String']>;
    visitProviderLocationState?: InputMaybe<Scalars['String']>;
    visitProviderLocationZip?: InputMaybe<Scalars['String']>;
    visitProviderPhoneNumber?: InputMaybe<Scalars['String']>;
    /** For content promotion? */
    visitType?: InputMaybe<Scalars['String']>;
};

export type AppointmentInputWithPatientExternalId = {
    appointment: AppointmentInput;
    deleteAppointment: Scalars['Boolean'];
    patientExternalId: Scalars['String'];
    rescheduledAppointmentExternalId?: InputMaybe<Scalars['String']>;
};

/** List of appointment methods */
export enum AppointmentMethod {
    AtHome = 'AtHome',
    OnDemand = 'OnDemand',
    Scheduled = 'Scheduled',
    Virtual = 'Virtual',
}

export type AppointmentOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type AppointmentPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type AppointmentPaginationQueryResponse = PaginationResponse & {
    __typename?: 'AppointmentPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Appointment>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type AppointmentQueryInput = {
    filter?: InputMaybe<AppointmentFilterInput>;
    orderBy?: InputMaybe<AppointmentOrderByInput>;
    pagination?: InputMaybe<AppointmentPaginationInput>;
};

export type AppointmentResponseMeta = {
    __typename?: 'AppointmentResponseMeta';
    externalId: Scalars['String'];
    id?: Maybe<Scalars['ObjectId']>;
    msg?: Maybe<Scalars['String']>;
    prevExternalId?: Maybe<Scalars['String']>;
    status: AppointmentImportStatus;
};

/** Source of appointment creation */
export enum AppointmentSource {
    Dorsata = 'DORSATA',
    Portal = 'PORTAL',
    Redox = 'REDOX',
    Simplifed = 'SIMPLIFED',
    Timetap = 'TIMETAP',
    User = 'USER',
}

export type AppointmentUpdateInput = {
    description?: InputMaybe<Scalars['String']>;
    /** Positive Integer */
    durationMinutes?: InputMaybe<Scalars['Float']>;
    externalId?: InputMaybe<Scalars['String']>;
    externalProviderId?: InputMaybe<Scalars['String']>;
    isVirtualVisit?: InputMaybe<Scalars['Boolean']>;
    /** For Appointment Notes from Lifeline */
    note?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    /** For phone created appointments */
    physicalLocation?: InputMaybe<Scalars['String']>;
    practiceId?: InputMaybe<Scalars['ObjectId']>;
    reportKey?: InputMaybe<Scalars['String']>;
    source?: InputMaybe<AppointmentSource>;
    staffId?: InputMaybe<Scalars['ObjectId']>;
    /** For phone created appointments */
    staffName?: InputMaybe<Scalars['String']>;
    startDate?: InputMaybe<Scalars['DateTime']>;
    status?: InputMaybe<Scalars['String']>;
    timezoneName?: InputMaybe<Scalars['String']>;
    virtualLink?: InputMaybe<Scalars['String']>;
    visitLocationDepartment?: InputMaybe<Scalars['String']>;
    visitLocationFacility?: InputMaybe<Scalars['String']>;
    visitProviderEmail?: InputMaybe<Scalars['String']>;
    visitProviderFirstName?: InputMaybe<Scalars['String']>;
    visitProviderLastName?: InputMaybe<Scalars['String']>;
    visitProviderLocationAddress?: InputMaybe<Scalars['String']>;
    visitProviderLocationCity?: InputMaybe<Scalars['String']>;
    visitProviderLocationState?: InputMaybe<Scalars['String']>;
    visitProviderLocationZip?: InputMaybe<Scalars['String']>;
    visitProviderPhoneNumber?: InputMaybe<Scalars['String']>;
    /** For content promotion? */
    visitType?: InputMaybe<Scalars['String']>;
};

/** Status of article approval. */
export enum ApprovalStatus {
    Approved = 'approved',
    Denied = 'denied',
    Pending = 'pending',
}

export type ApproveAllForAppInput = {
    appBundleId: Scalars['String'];
    approvedBy: Scalars['String'];
};

export type ApproveAllForAppResponse = MutationResponse & {
    __typename?: 'ApproveAllForAppResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type Article = BaseEntity & {
    __typename?: 'Article';
    allowedApps?: Maybe<Array<Maybe<Scalars['String']>>>;
    articleCategory?: Maybe<ArticleCategory>;
    articleCategoryId: Scalars['ObjectId'];
    callToActions?: Maybe<Array<CallToAction>>;
    changesToPublish?: Maybe<Scalars['Boolean']>;
    converted?: Maybe<Scalars['Boolean']>;
    createdAt: Scalars['DateTime'];
    description?: Maybe<Scalars['String']>;
    groups?: Maybe<Array<Scalars['ObjectId']>>;
    icon?: Maybe<ContentIcon>;
    id: Scalars['ObjectId'];
    imageContent?: Maybe<SimpleContent>;
    imageContentId?: Maybe<Scalars['ObjectId']>;
    isTestData?: Maybe<Scalars['Boolean']>;
    keywords?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    references?: Maybe<Scalars['String']>;
    relatedArticleIds?: Maybe<Array<Scalars['ObjectId']>>;
    reportingCategorys?: Maybe<Array<Scalars['ObjectId']>>;
    stackId?: Maybe<Scalars['Float']>;
    toDelete?: Maybe<Scalars['Boolean']>;
    updatedAt: Scalars['DateTime'];
    variants?: Maybe<Array<ContentVariant>>;
};

export type ArticleApproval = BaseEntity & {
    __typename?: 'ArticleApproval';
    appBundleId: Scalars['String'];
    approvedBy?: Maybe<Scalars['String']>;
    articleId: Scalars['ObjectId'];
    articleName: Scalars['String'];
    createdAt: Scalars['DateTime'];
    dateApproved?: Maybe<Scalars['DateTime']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    language: Language;
    processed?: Maybe<Scalars['Boolean']>;
    readingLevel: ReadingLevel;
    status?: Maybe<ApprovalStatus>;
    updatedAt: Scalars['DateTime'];
};

export type ArticleApprovalCustomFilters = {
    appBundleId?: InputMaybe<Scalars['String']>;
    articleGroupIds?: InputMaybe<Array<Scalars['String']>>;
    operator?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
};

export type ArticleApprovalFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ArticleApprovalUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ArticleApprovalInput = {
    appBundleId: Scalars['String'];
    approvedBy?: InputMaybe<Scalars['String']>;
    articleId: Scalars['ObjectId'];
    articleName: Scalars['String'];
    dateApproved?: InputMaybe<Scalars['DateTime']>;
    language: Language;
    processed?: InputMaybe<Scalars['Boolean']>;
    readingLevel: ReadingLevel;
    status?: InputMaybe<ApprovalStatus>;
};

export type ArticleApprovalListFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ArticleApprovalUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ArticleApprovalListInput = {
    customFilters?: InputMaybe<ArticleApprovalCustomFilters>;
    filter?: InputMaybe<ArticleApprovalListFilterInput>;
    orderBy?: InputMaybe<ArticleApprovalListOrderByInput>;
    pagination?: InputMaybe<ArticleApprovalListPaginationInput>;
};

export type ArticleApprovalListOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ArticleApprovalListPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ArticleApprovalOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ArticleApprovalPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ArticleApprovalPaginationQueryResponse = PaginationResponse & {
    __typename?: 'ArticleApprovalPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ArticleApproval>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ArticleApprovalQueryInput = {
    filter?: InputMaybe<ArticleApprovalFilterInput>;
    orderBy?: InputMaybe<ArticleApprovalOrderByInput>;
    pagination?: InputMaybe<ArticleApprovalPaginationInput>;
};

export type ArticleApprovalUpdateInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    approvedBy?: InputMaybe<Scalars['String']>;
    articleId?: InputMaybe<Scalars['ObjectId']>;
    articleName?: InputMaybe<Scalars['String']>;
    dateApproved?: InputMaybe<Scalars['DateTime']>;
    language?: InputMaybe<Language>;
    processed?: InputMaybe<Scalars['Boolean']>;
    readingLevel?: InputMaybe<ReadingLevel>;
    status?: InputMaybe<ApprovalStatus>;
};

export type ArticleCache = BaseEntity & {
    __typename?: 'ArticleCache';
    appBundleId: Scalars['String'];
    articleId: Scalars['ObjectId'];
    bodyText?: Maybe<Scalars['String']>;
    callToActions?: Maybe<Array<Cta>>;
    createdAt: Scalars['DateTime'];
    fromLanguage?: Maybe<Language>;
    fromReadingLevel?: Maybe<ReadingLevel>;
    icon?: Maybe<ContentIcon>;
    id: Scalars['ObjectId'];
    image?: Maybe<Scalars['String']>;
    isCopy: Scalars['Boolean'];
    isTestData?: Maybe<Scalars['Boolean']>;
    language: Language;
    readinglevel: ReadingLevel;
    relatedArticleIds?: Maybe<Array<Scalars['ObjectId']>>;
    simpleContentIds?: Maybe<Array<Scalars['ObjectId']>>;
    summaryText?: Maybe<Scalars['String']>;
    titleText?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
};

export type ArticleCacheFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ArticleCacheUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ArticleCacheInput = {
    appBundleId: Scalars['String'];
    articleId: Scalars['ObjectId'];
    bodyText?: InputMaybe<Scalars['String']>;
    callToActions?: InputMaybe<Array<CtaInput>>;
    fromLanguage?: InputMaybe<Language>;
    fromReadingLevel?: InputMaybe<ReadingLevel>;
    icon?: InputMaybe<ContentIcon>;
    image?: InputMaybe<Scalars['String']>;
    isCopy: Scalars['Boolean'];
    language: Language;
    readinglevel: ReadingLevel;
    relatedArticleIds?: InputMaybe<Array<Scalars['ObjectId']>>;
    simpleContentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
    summaryText?: InputMaybe<Scalars['String']>;
    titleText?: InputMaybe<Scalars['String']>;
};

export type ArticleCacheOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type ArticleCachePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ArticleCachePaginationQueryResponse = PaginationResponse & {
    __typename?: 'ArticleCachePaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ArticleCache>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ArticleCacheQueryInput = {
    filter?: InputMaybe<ArticleCacheFilterInput>;
    orderBy?: InputMaybe<ArticleCacheOrderByInput>;
    pagination?: InputMaybe<ArticleCachePaginationInput>;
};

export type ArticleCacheUpdateInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    articleId?: InputMaybe<Scalars['ObjectId']>;
    bodyText?: InputMaybe<Scalars['String']>;
    callToActions?: InputMaybe<Array<InputMaybe<CtaInput>>>;
    fromLanguage?: InputMaybe<Language>;
    fromReadingLevel?: InputMaybe<ReadingLevel>;
    icon?: InputMaybe<ContentIcon>;
    image?: InputMaybe<Scalars['String']>;
    isCopy?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Language>;
    readinglevel?: InputMaybe<ReadingLevel>;
    relatedArticleIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    simpleContentIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    summaryText?: InputMaybe<Scalars['String']>;
    titleText?: InputMaybe<Scalars['String']>;
};

export type ArticleCategory = BaseEntity & {
    __typename?: 'ArticleCategory';
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type ArticleCategoryFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ArticleCategoryUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ArticleCategoryInput = {
    name: Scalars['String'];
};

export type ArticleCategoryOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ArticleCategoryPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ArticleCategoryPaginationQueryResponse = PaginationResponse & {
    __typename?: 'ArticleCategoryPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ArticleCategory>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ArticleCategoryQueryInput = {
    filter?: InputMaybe<ArticleCategoryFilterInput>;
    orderBy?: InputMaybe<ArticleCategoryOrderByInput>;
    pagination?: InputMaybe<ArticleCategoryPaginationInput>;
};

export type ArticleCategoryUpdateInput = {
    name: Scalars['String'];
};

export type ArticleFeed = BaseEntity & {
    __typename?: 'ArticleFeed';
    appBundleId: Scalars['String'];
    appUserId: Scalars['ObjectId'];
    articleId: Scalars['ObjectId'];
    createdAt: Scalars['DateTime'];
    dateAdded: Scalars['DateTime'];
    effectiveDate: Scalars['DateTime'];
    excludedByTags?: Maybe<Scalars['Boolean']>;
    expiryDate: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    organizationId?: Maybe<Scalars['ObjectId']>;
    patientId: Scalars['ObjectId'];
    priority: Scalars['Float'];
    promotionId: Scalars['ObjectId'];
    tags?: Maybe<Array<ArticleFeedTag>>;
    timelineId?: Maybe<Scalars['ObjectId']>;
    title?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
};

export type ArticleFeedFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ArticleFeedUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ArticleFeedInput = {
    appBundleId: Scalars['String'];
    appUserId: Scalars['ObjectId'];
    articleId: Scalars['ObjectId'];
    dateAdded: Scalars['DateTime'];
    effectiveDate: Scalars['DateTime'];
    excludedByTags?: InputMaybe<Scalars['Boolean']>;
    expiryDate: Scalars['DateTime'];
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    patientId: Scalars['ObjectId'];
    priority: Scalars['Float'];
    promotionId: Scalars['ObjectId'];
    tags?: InputMaybe<Array<InputMaybe<ArticleFeedTagInput>>>;
    timelineId?: InputMaybe<Scalars['ObjectId']>;
    title?: InputMaybe<Scalars['String']>;
};

export type ArticleFeedItem = {
    __typename?: 'ArticleFeedItem';
    availableUntil?: Maybe<Scalars['DateTime']>;
    bookmarked?: Maybe<Scalars['Boolean']>;
    category?: Maybe<Scalars['String']>;
    icon?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
    promotionId?: Maybe<Scalars['String']>;
    summary?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    username?: Maybe<Scalars['String']>;
    viewed?: Maybe<Scalars['Boolean']>;
};

export type ArticleFeedOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type ArticleFeedPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ArticleFeedPaginationQueryResponse = PaginationResponse & {
    __typename?: 'ArticleFeedPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ArticleFeed>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ArticleFeedQueryInput = {
    filter?: InputMaybe<ArticleFeedFilterInput>;
    orderBy?: InputMaybe<ArticleFeedOrderByInput>;
    pagination?: InputMaybe<ArticleFeedPaginationInput>;
};

export type ArticleFeedResult = {
    __typename?: 'ArticleFeedResult';
    contentBeingCreated?: Maybe<Scalars['Boolean']>;
    itemcount?: Maybe<Scalars['Float']>;
    items?: Maybe<Array<Maybe<ArticleFeedItem>>>;
};

export type ArticleFeedTag = {
    __typename?: 'ArticleFeedTag';
    state?: Maybe<Scalars['Boolean']>;
    tagId: Scalars['ObjectId'];
};

export type ArticleFeedTagInput = {
    state?: InputMaybe<Scalars['Boolean']>;
    tagId?: InputMaybe<Scalars['ObjectId']>;
};

export type ArticleFeedTimelinesNotFoundInput = {
    appUserId?: InputMaybe<Scalars['String']>;
    timelines?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ArticleFeedUpdateInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    appUserId?: InputMaybe<Scalars['ObjectId']>;
    articleId?: InputMaybe<Scalars['ObjectId']>;
    dateAdded?: InputMaybe<Scalars['DateTime']>;
    effectiveDate?: InputMaybe<Scalars['DateTime']>;
    excludedByTags?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Scalars['DateTime']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    priority?: InputMaybe<Scalars['Float']>;
    promotionId?: InputMaybe<Scalars['ObjectId']>;
    tags?: InputMaybe<Array<InputMaybe<ArticleFeedTagInput>>>;
    timelineId?: InputMaybe<Scalars['ObjectId']>;
    title?: InputMaybe<Scalars['String']>;
};

export type ArticleFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ArticleUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ArticleGetManyInput = {
    appUserId?: InputMaybe<Scalars['String']>;
    articleIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ArticleGroup = BaseEntity & {
    __typename?: 'ArticleGroup';
    appInfo?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    excludeApps?: Maybe<Array<Scalars['String']>>;
    id: Scalars['ObjectId'];
    inUse?: Maybe<Scalars['Boolean']>;
    includeApps?: Maybe<Array<Scalars['String']>>;
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type ArticleGroupFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ArticleGroupUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ArticleGroupInput = {
    excludeApps?: InputMaybe<Array<Scalars['String']>>;
    includeApps?: InputMaybe<Array<Scalars['String']>>;
    name: Scalars['String'];
};

export type ArticleGroupOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ArticleGroupPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ArticleGroupPaginationQueryResponse = PaginationResponse & {
    __typename?: 'ArticleGroupPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ArticleGroup>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ArticleGroupQueryInput = {
    filter?: InputMaybe<ArticleGroupFilterInput>;
    orderBy?: InputMaybe<ArticleGroupOrderByInput>;
    pagination?: InputMaybe<ArticleGroupPaginationInput>;
};

export type ArticleGroupUpdateInput = {
    excludeApps?: InputMaybe<Array<Scalars['String']>>;
    includeApps?: InputMaybe<Array<Scalars['String']>>;
    name: Scalars['String'];
};

export type ArticleInput = {
    articleCategoryId: Scalars['ObjectId'];
    callToActions?: InputMaybe<Array<CallToActionInput>>;
    converted?: InputMaybe<Scalars['Boolean']>;
    description?: InputMaybe<Scalars['String']>;
    groups?: InputMaybe<Array<Scalars['ObjectId']>>;
    icon?: InputMaybe<ContentIcon>;
    imageContentId?: InputMaybe<Scalars['ObjectId']>;
    keywords?: InputMaybe<Scalars['String']>;
    name: Scalars['String'];
    references?: InputMaybe<Scalars['String']>;
    relatedArticleIds?: InputMaybe<Array<Scalars['ObjectId']>>;
    reportingCategorys?: InputMaybe<Array<Scalars['ObjectId']>>;
    stackId?: InputMaybe<Scalars['Float']>;
    toDelete?: InputMaybe<Scalars['Boolean']>;
    variants?: InputMaybe<Array<ContentVariantInput>>;
};

export type ArticleListFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ArticleUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ArticleListInput = {
    filter?: InputMaybe<ArticleListFilterInput>;
    orderBy?: InputMaybe<ArticleListOrderByInput>;
    pagination?: InputMaybe<ArticleListPaginationInput>;
};

export type ArticleListOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ArticleListPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ArticleListResponse = PaginationResponse & {
    __typename?: 'ArticleListResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Article>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ArticleOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ArticlePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ArticlePickerFetchInput = {
    categoryId?: InputMaybe<Scalars['String']>;
    groupId?: InputMaybe<Scalars['String']>;
    searchTerm?: InputMaybe<Scalars['String']>;
};

export type ArticlePickerFetchResponse = {
    __typename?: 'ArticlePickerFetchResponse';
    data?: Maybe<Array<Maybe<Article>>>;
    total?: Maybe<Scalars['Float']>;
};

export type ArticlePromotion = BaseEntity & {
    __typename?: 'ArticlePromotion';
    article?: Maybe<Article>;
    articleId: Scalars['ObjectId'];
    createdAt: Scalars['DateTime'];
    duration?: Maybe<Scalars['Float']>;
    durationMinutes?: Maybe<Scalars['Float']>;
    durationTimeUnit?: Maybe<TimeUnit>;
    endDate?: Maybe<Scalars['DateTime']>;
    excludeApps?: Maybe<Array<Scalars['String']>>;
    id: Scalars['ObjectId'];
    includeApps?: Maybe<Array<Scalars['String']>>;
    isTestData?: Maybe<Scalars['Boolean']>;
    name?: Maybe<Scalars['String']>;
    offset?: Maybe<Scalars['Float']>;
    offsetDays?: Maybe<Scalars['Float']>;
    offsetMinutes?: Maybe<Scalars['Float']>;
    offsetMonths?: Maybe<Scalars['Float']>;
    offsetSequence?: Maybe<Scalars['Float']>;
    offsetTimeUnit?: Maybe<TimeUnit>;
    offsetYears?: Maybe<Scalars['Float']>;
    priority: Scalars['Float'];
    startDate?: Maybe<Scalars['DateTime']>;
    tagIds?: Maybe<Array<Scalars['ObjectId']>>;
    timeline?: Maybe<WhatsNewTimeline>;
    timelineId?: Maybe<Scalars['ObjectId']>;
    title?: Maybe<Scalars['String']>;
    titleSp?: Maybe<Scalars['String']>;
    type?: Maybe<ArticlePromotionType>;
    typeName?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
};

export type ArticlePromotionFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ArticlePromotionUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ArticlePromotionInput = {
    articleId: Scalars['ObjectId'];
    duration?: InputMaybe<Scalars['Float']>;
    durationMinutes?: InputMaybe<Scalars['Float']>;
    durationTimeUnit?: InputMaybe<TimeUnit>;
    endDate?: InputMaybe<Scalars['DateTime']>;
    excludeApps?: InputMaybe<Array<Scalars['String']>>;
    includeApps?: InputMaybe<Array<Scalars['String']>>;
    name?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Float']>;
    offsetDays?: InputMaybe<Scalars['Float']>;
    offsetMinutes?: InputMaybe<Scalars['Float']>;
    offsetMonths?: InputMaybe<Scalars['Float']>;
    offsetSequence?: InputMaybe<Scalars['Float']>;
    offsetTimeUnit?: InputMaybe<TimeUnit>;
    offsetYears?: InputMaybe<Scalars['Float']>;
    priority: Scalars['Float'];
    startDate?: InputMaybe<Scalars['DateTime']>;
    tagIds?: InputMaybe<Array<Scalars['ObjectId']>>;
    timelineId?: InputMaybe<Scalars['ObjectId']>;
    title?: InputMaybe<Scalars['String']>;
    titleSp?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<ArticlePromotionType>;
};

export type ArticlePromotionListFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ArticlePromotionUpdateInputForV2Query>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ArticlePromotionListInput = {
    filter?: InputMaybe<ArticlePromotionListFilterInput>;
    orderBy?: InputMaybe<ArticlePromotionListOrderByInput>;
    pagination?: InputMaybe<ArticlePromotionListPaginationInput>;
};

export type ArticlePromotionListOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ArticlePromotionListPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ArticlePromotionListResponse = PaginationResponse & {
    __typename?: 'ArticlePromotionListResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ArticlePromotion>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ArticlePromotionOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ArticlePromotionPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ArticlePromotionQueryInput = {
    filter?: InputMaybe<ArticlePromotionFilterInput>;
    orderBy?: InputMaybe<ArticlePromotionOrderByInput>;
    pagination?: InputMaybe<ArticlePromotionPaginationInput>;
};

/** Type of article promotion. */
export enum ArticlePromotionType {
    FixedDate = 'fixedDate',
    Timeline = 'timeline',
}

export type ArticlePromotionUpdateInput = {
    articleId?: InputMaybe<Scalars['ObjectId']>;
    duration?: InputMaybe<Scalars['Float']>;
    durationMinutes?: InputMaybe<Scalars['Float']>;
    durationTimeUnit?: InputMaybe<TimeUnit>;
    endDate?: InputMaybe<Scalars['DateTime']>;
    excludeApps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    includeApps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    name?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Float']>;
    offsetDays?: InputMaybe<Scalars['Float']>;
    offsetMinutes?: InputMaybe<Scalars['Float']>;
    offsetMonths?: InputMaybe<Scalars['Float']>;
    offsetSequence?: InputMaybe<Scalars['Float']>;
    offsetTimeUnit?: InputMaybe<TimeUnit>;
    offsetYears?: InputMaybe<Scalars['Float']>;
    priority?: InputMaybe<Scalars['Float']>;
    startDate?: InputMaybe<Scalars['DateTime']>;
    tagIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    timelineId?: InputMaybe<Scalars['ObjectId']>;
    title?: InputMaybe<Scalars['String']>;
    titleSp?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<ArticlePromotionType>;
};

export type ArticlePromotionUpdateInputForV2Query = {
    articleId?: InputMaybe<Scalars['ObjectId']>;
    articleName?: InputMaybe<Scalars['String']>;
    duration?: InputMaybe<Scalars['Float']>;
    durationMinutes?: InputMaybe<Scalars['Float']>;
    durationTimeUnit?: InputMaybe<TimeUnit>;
    endDate?: InputMaybe<Scalars['DateTime']>;
    excludeApps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    includeApps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    name?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Float']>;
    offsetDays?: InputMaybe<Scalars['Float']>;
    offsetMinutes?: InputMaybe<Scalars['Float']>;
    offsetMonths?: InputMaybe<Scalars['Float']>;
    offsetSequence?: InputMaybe<Scalars['Float']>;
    offsetTimeUnit?: InputMaybe<TimeUnit>;
    offsetYears?: InputMaybe<Scalars['Float']>;
    priority?: InputMaybe<Scalars['Float']>;
    startDate?: InputMaybe<Scalars['DateTime']>;
    tagIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    timelineId?: InputMaybe<Scalars['ObjectId']>;
    title?: InputMaybe<Scalars['String']>;
    titleSp?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<ArticlePromotionType>;
};

export type ArticleQueryInput = {
    filter?: InputMaybe<ArticleFilterInput>;
    orderBy?: InputMaybe<ArticleOrderByInput>;
    pagination?: InputMaybe<ArticlePaginationInput>;
};

export type ArticleUpdateInput = {
    articleCategoryId?: InputMaybe<Scalars['ObjectId']>;
    callToActions?: InputMaybe<Array<CallToActionInput>>;
    converted?: InputMaybe<Scalars['Boolean']>;
    description?: InputMaybe<Scalars['String']>;
    groups?: InputMaybe<Array<Scalars['ObjectId']>>;
    icon?: InputMaybe<ContentIcon>;
    imageContentId?: InputMaybe<Scalars['ObjectId']>;
    keywords?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    references?: InputMaybe<Scalars['String']>;
    relatedArticleIds?: InputMaybe<Array<Scalars['ObjectId']>>;
    reportingCategorys?: InputMaybe<Array<Scalars['ObjectId']>>;
    stackId?: InputMaybe<Scalars['Float']>;
    toDelete?: InputMaybe<Scalars['Boolean']>;
    variants?: InputMaybe<Array<ContentVariantInput>>;
};

export type ArticlesForSimpleContentInput = {
    limit?: InputMaybe<Scalars['Float']>;
    simpleContentId?: InputMaybe<Scalars['String']>;
    skip?: InputMaybe<Scalars['Float']>;
};

export type AsyncJob = BaseEntity & {
    __typename?: 'AsyncJob';
    appBundleId?: Maybe<Scalars['String']>;
    contentTypeName?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    errorMsg?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    patientId?: Maybe<Scalars['ObjectId']>;
    status?: Maybe<AsyncJobStatus>;
    type?: Maybe<AsyncJobType>;
    updatedAt: Scalars['DateTime'];
};

export type AsyncJobFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<AsyncJobUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type AsyncJobInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    errorMsg?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    status?: InputMaybe<AsyncJobStatus>;
    type?: InputMaybe<AsyncJobType>;
};

export type AsyncJobOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type AsyncJobPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type AsyncJobPaginationQueryResponse = PaginationResponse & {
    __typename?: 'AsyncJobPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<AsyncJob>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type AsyncJobQueryInput = {
    filter?: InputMaybe<AsyncJobFilterInput>;
    orderBy?: InputMaybe<AsyncJobOrderByInput>;
    pagination?: InputMaybe<AsyncJobPaginationInput>;
};

/** Async Job type status. */
export enum AsyncJobStatus {
    Completed = 'completed',
    Error = 'error',
    Started = 'started',
}

/** Async Job type. */
export enum AsyncJobType {
    RebuildPatientCache = 'RebuildPatientCache',
}

export type AsyncJobUpdateInput = {
    errorMsg?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    status?: InputMaybe<AsyncJobStatus>;
    type?: InputMaybe<AsyncJobType>;
};

export type Attachment = {
    __typename?: 'Attachment';
    label: Scalars['String'];
    uuid: Scalars['String'];
};

export type AttachmentInput = {
    label: Scalars['String'];
    uuid: Scalars['String'];
};

export type BhfMonthlyReportData = {
    __typename?: 'BHFMonthlyReportData';
    fileControlNum?: Maybe<Scalars['String']>;
    invoiceNum?: Maybe<Scalars['String']>;
    key?: Maybe<Scalars['String']>;
    version?: Maybe<Scalars['Float']>;
};

export type BhfMonthlyReportDataInput = {
    fileControlNum?: InputMaybe<Scalars['String']>;
    invoiceNum?: InputMaybe<Scalars['String']>;
    key?: InputMaybe<Scalars['String']>;
    version?: InputMaybe<Scalars['Float']>;
};

export type BaseEntity = {
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    updatedAt: Scalars['DateTime'];
};

export type BatchTask = BaseEntity & {
    __typename?: 'BatchTask';
    addedBy: Scalars['String'];
    completedAt?: Maybe<Scalars['DateTime']>;
    completedRecords?: Maybe<Scalars['Float']>;
    createdAt: Scalars['DateTime'];
    errorLog?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    priority: Scalars['Float'];
    runAfter: Scalars['DateTime'];
    settings?: Maybe<Scalars['JSON']>;
    startedAt?: Maybe<Scalars['DateTime']>;
    status: BatchTaskStatus;
    totalRecords?: Maybe<Scalars['Float']>;
    type: BatchTaskType;
    updatedAt: Scalars['DateTime'];
};

export type BatchTaskFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<BatchTaskUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type BatchTaskInput = {
    addedBy: Scalars['String'];
    completedAt?: InputMaybe<Scalars['DateTime']>;
    completedRecords?: InputMaybe<Scalars['Float']>;
    errorLog?: InputMaybe<Scalars['String']>;
    priority: Scalars['Float'];
    runAfter: Scalars['DateTime'];
    settings?: InputMaybe<Scalars['JSON']>;
    startedAt?: InputMaybe<Scalars['DateTime']>;
    status: BatchTaskStatus;
    totalRecords?: InputMaybe<Scalars['Float']>;
    type: BatchTaskType;
    updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BatchTaskOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type BatchTaskPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type BatchTaskPaginationQueryResponse = PaginationResponse & {
    __typename?: 'BatchTaskPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<BatchTask>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type BatchTaskQueryInput = {
    filter?: InputMaybe<BatchTaskFilterInput>;
    orderBy?: InputMaybe<BatchTaskOrderByInput>;
    pagination?: InputMaybe<BatchTaskPaginationInput>;
};

/** Status of Batch Task. */
export enum BatchTaskStatus {
    Cancelled = 'cancelled',
    Complete = 'complete',
    Error = 'error',
    NotStarted = 'notStarted',
    Running = 'running',
}

/** Type of Task to execute. */
export enum BatchTaskType {
    ApproveArticles = 'approveArticles',
    ConfigCacheRebuild = 'configCacheRebuild',
    ConfigPromotion = 'configPromotion',
    ContentCacheRebuild = 'contentCacheRebuild',
    DeleteAppPatients = 'deleteAppPatients',
    DirectoryCacheRebuild = 'directoryCacheRebuild',
    EligibilityRecheck = 'eligibilityRecheck',
    InitializeApp = 'initializeApp',
    InvoicePaymentStatusRecheck = 'invoicePaymentStatusRecheck',
    PatientCacheRebuild = 'patientCacheRebuild',
    ProcessPatientList = 'processPatientList',
    ProcessSimpleContent = 'processSimpleContent',
    PublishArticles = 'publishArticles',
    RecalcTags = 'recalcTags',
    TagCacheRebuild = 'tagCacheRebuild',
}

export type BatchTaskUpdateInput = {
    addedBy?: InputMaybe<Scalars['String']>;
    completedAt?: InputMaybe<Scalars['DateTime']>;
    completedRecords?: InputMaybe<Scalars['Float']>;
    errorLog?: InputMaybe<Scalars['String']>;
    priority?: InputMaybe<Scalars['Float']>;
    runAfter?: InputMaybe<Scalars['DateTime']>;
    settings?: InputMaybe<Scalars['JSON']>;
    startedAt?: InputMaybe<Scalars['DateTime']>;
    status?: InputMaybe<BatchTaskStatus>;
    totalRecords?: InputMaybe<Scalars['Float']>;
    type?: InputMaybe<BatchTaskType>;
    updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BioScreen = BaseEntity & {
    __typename?: 'BioScreen';
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    /** Description */
    label: LocalizedString;
    shortcuts: Array<BioScreenShortcut>;
    surveyGroups: Array<BioScreenSurveyGroup>;
    target: BioScreenTargetType;
    updatedAt: Scalars['DateTime'];
    yourHealthInfo: BioScreenShortcut;
};

export type BioScreenFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<BioScreenUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type BioScreenInput = {
    /** Description */
    label: LocalizedStringInput;
    shortcuts: Array<BioScreenShortcutInput>;
    surveyGroups: Array<BioScreenSurveyGroupInput>;
    target: BioScreenTargetType;
    yourHealthInfo: BioScreenShortcutInput;
};

export type BioScreenOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type BioScreenPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type BioScreenPaginationQueryResponse = PaginationResponse & {
    __typename?: 'BioScreenPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<BioScreen>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type BioScreenQueryInput = {
    filter?: InputMaybe<BioScreenFilterInput>;
    orderBy?: InputMaybe<BioScreenOrderByInput>;
    pagination?: InputMaybe<BioScreenPaginationInput>;
};

export type BioScreenShortcut = {
    __typename?: 'BioScreenShortcut';
    icon: BioScreenShortcutIcon;
    /** Shortcut Instructions */
    instructions: LocalizedString;
    /** Shortcut Label */
    label?: Maybe<LocalizedString>;
    survey: Array<SurveyDef>;
    surveyGroup: Array<BioScreenSurveyGroup>;
    surveyGroupId?: Maybe<Scalars['String']>;
    surveyId?: Maybe<Scalars['ObjectId']>;
    tags?: Maybe<Array<Maybe<Scalars['String']>>>;
    type: BioScreenShortcutType;
};

/** The screen shortcut icon. */
export enum BioScreenShortcutIcon {
    Flower = 'flower',
    HealthSummary = 'healthSummary',
    Pregnancy = 'pregnancy',
}

export type BioScreenShortcutInput = {
    icon: BioScreenShortcutIcon;
    /** Shortcut Instructions */
    instructions: LocalizedStringInput;
    /** Shortcut Label */
    label?: InputMaybe<LocalizedStringInput>;
    surveyGroupId?: InputMaybe<Scalars['String']>;
    surveyId?: InputMaybe<Scalars['ObjectId']>;
    tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    type: BioScreenShortcutType;
};

/** The screen shortcut target data type. */
export enum BioScreenShortcutType {
    Survey = 'survey',
    SurveyGroup = 'surveyGroup',
    YourHealthInfo = 'yourHealthInfo',
}

export type BioScreenSurvey = {
    __typename?: 'BioScreenSurvey';
    /** Survey Group Label */
    label: LocalizedString;
    surveyId: Scalars['ObjectId'];
    tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BioScreenSurveyGroup = {
    __typename?: 'BioScreenSurveyGroup';
    id: Scalars['String'];
    /** Survey Label */
    label: LocalizedString;
    surveys: Array<BioScreenSurvey>;
    tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BioScreenSurveyGroupInput = {
    id: Scalars['String'];
    /** Survey Label */
    label: LocalizedStringInput;
    surveys: Array<BioScreenSurveyInput>;
    tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BioScreenSurveyInput = {
    /** Survey Group Label */
    label: LocalizedStringInput;
    surveyId: Scalars['ObjectId'];
    tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** The usage target for the screen. */
export enum BioScreenTargetType {
    Mobile = 'mobile',
    Portal = 'portal',
}

export type BioScreenUpdateInput = {
    label?: InputMaybe<LocalizedStringInput>;
    shortcuts?: InputMaybe<Array<InputMaybe<BioScreenShortcutInput>>>;
    surveyGroups?: InputMaybe<Array<InputMaybe<BioScreenSurveyGroupInput>>>;
    target?: InputMaybe<BioScreenTargetType>;
    yourHealthInfo?: InputMaybe<BioScreenShortcutInput>;
};

export type BloodGlucoseMeasurement = {
    __typename?: 'BloodGlucoseMeasurement';
    activityOption?: Maybe<Scalars['String']>;
    dateTime?: Maybe<Scalars['DateTime']>;
    glucose?: Maybe<Scalars['Float']>;
    meal?: Maybe<Scalars['String']>;
    mealOption?: Maybe<Scalars['String']>;
    mealTime?: Maybe<Scalars['DateTime']>;
    movement?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
};

export type BloodGlucoseMeasurementInput = {
    activityOption?: InputMaybe<Scalars['String']>;
    dateTime?: InputMaybe<Scalars['DateTime']>;
    glucose?: InputMaybe<Scalars['Float']>;
    meal?: InputMaybe<Scalars['String']>;
    mealOption?: InputMaybe<Scalars['String']>;
    mealTime?: InputMaybe<Scalars['DateTime']>;
    movement?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
};

export type BloodPressureMeasurement = {
    __typename?: 'BloodPressureMeasurement';
    diastolic?: Maybe<Scalars['Float']>;
    pulse?: Maybe<Scalars['Float']>;
    symptoms?: Maybe<Array<Maybe<Scalars['String']>>>;
    symptomsOption?: Maybe<Scalars['String']>;
    systolic?: Maybe<Scalars['Float']>;
};

export type BloodPressureMeasurementInput = {
    diastolic?: InputMaybe<Scalars['Float']>;
    pulse?: InputMaybe<Scalars['Float']>;
    symptoms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    symptomsOption?: InputMaybe<Scalars['String']>;
    systolic?: InputMaybe<Scalars['Float']>;
};

export type BreastFeedingInput = {
    alexaData?: InputMaybe<AlexaDataInput>;
    createdAt?: InputMaybe<Scalars['DateTime']>;
    createdBy?: InputMaybe<Scalars['String']>;
    datetime?: InputMaybe<Scalars['DateTime']>;
    id?: InputMaybe<Scalars['String']>;
    isManual?: InputMaybe<Scalars['Boolean']>;
    leftSideDuration?: InputMaybe<Scalars['Float']>;
    note?: InputMaybe<Scalars['String']>;
    rightSideDuration?: InputMaybe<Scalars['Float']>;
    side?: InputMaybe<Scalars['String']>;
};

export type BreastFeedingType = {
    __typename?: 'BreastFeedingType';
    alexaData?: Maybe<AlexaDataType>;
    createdAt?: Maybe<Scalars['DateTime']>;
    createdBy?: Maybe<Scalars['String']>;
    datetime?: Maybe<Scalars['DateTime']>;
    id?: Maybe<Scalars['String']>;
    isManual?: Maybe<Scalars['Boolean']>;
    leftSideDuration?: Maybe<Scalars['Float']>;
    note?: Maybe<Scalars['String']>;
    rightSideDuration?: Maybe<Scalars['Float']>;
    side?: Maybe<Scalars['String']>;
};

export type BulkUpdateProfileValue = {
    keyId: Scalars['ObjectId'];
    patientId: Scalars['ObjectId'];
    profileVariableDefId: Scalars['String'];
    type?: InputMaybe<ProfileValueType>;
    val: ProfileValueDataInput;
};

export type BulkUpdateProfileValuesInput = {
    profileValues: Array<BulkUpdateProfileValue>;
};

export type BulkUpdateResponse = {
    __typename?: 'BulkUpdateResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
};

export type BundleIdForPatientResponseData = {
    __typename?: 'BundleIdForPatientResponseData';
    appBundleId: Scalars['String'];
};

export type Cta = {
    __typename?: 'CTA';
    action: CtaType;
    icon?: Maybe<ContentIcon>;
    label?: Maybe<Scalars['String']>;
    parameter1?: Maybe<Scalars['String']>;
    parameter2?: Maybe<Scalars['String']>;
    tagId?: Maybe<Scalars['ObjectId']>;
    tagList?: Maybe<Array<Scalars['ObjectId']>>;
};

export type CtaInput = {
    action?: InputMaybe<CtaType>;
    icon?: InputMaybe<ContentIcon>;
    label?: InputMaybe<Scalars['String']>;
    parameter1?: InputMaybe<Scalars['String']>;
    parameter2?: InputMaybe<Scalars['String']>;
    tagId?: InputMaybe<Scalars['ObjectId']>;
    tagList?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
};

/** The type of the Call To Action. */
export enum CtaType {
    AddToQList = 'AddToQList',
    DailyReminder = 'DailyReminder',
    OneTimeReminder = 'OneTimeReminder',
    OpenExternalLink = 'OpenExternalLink',
    OpenInternalLink = 'OpenInternalLink',
    PopUpMessage = 'PopUpMessage',
    TapToCall = 'TapToCall',
    TapToCallPopUp = 'TapToCallPopUp',
    WeeklyReminder = 'WeeklyReminder',
}

export type CacheContentDirectory = {
    __typename?: 'CacheContentDirectory';
    contentBeingCreated?: Maybe<Scalars['Boolean']>;
    description?: Maybe<Scalars['String']>;
    displayStyle?: Maybe<Scalars['String']>;
    entries?: Maybe<Array<Maybe<CacheDirectoryEntry>>>;
    icon?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
    label?: Maybe<Scalars['String']>;
};

export type CacheDirectoryArticle = {
    __typename?: 'CacheDirectoryArticle';
    bookmarked?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
    summary?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    viewed?: Maybe<Scalars['Boolean']>;
};

export type CacheDirectoryEntry = {
    __typename?: 'CacheDirectoryEntry';
    article?: Maybe<CacheDirectoryArticle>;
    icon?: Maybe<Scalars['String']>;
    internalLink?: Maybe<CacheDirectoryInternalLink>;
    phone?: Maybe<CacheDirectoryPhone>;
    subDirectory?: Maybe<CacheSubDirectory>;
    survey?: Maybe<CacheDirectorySurvey>;
    tracker?: Maybe<CacheDirectoryTracker>;
    type?: Maybe<Scalars['String']>;
};

export type CacheDirectoryInternalLink = {
    __typename?: 'CacheDirectoryInternalLink';
    description?: Maybe<Scalars['String']>;
    label?: Maybe<Scalars['String']>;
    link?: Maybe<Scalars['String']>;
};

export type CacheDirectoryPhone = {
    __typename?: 'CacheDirectoryPhone';
    description?: Maybe<Scalars['String']>;
    label?: Maybe<Scalars['String']>;
    number?: Maybe<Scalars['String']>;
};

export type CacheDirectorySurvey = {
    __typename?: 'CacheDirectorySurvey';
    id?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
    summary?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export type CacheDirectoryTracker = {
    __typename?: 'CacheDirectoryTracker';
    description?: Maybe<Scalars['String']>;
    label?: Maybe<Scalars['String']>;
    trackertype?: Maybe<Scalars['String']>;
};

export type CacheSubDirectory = {
    __typename?: 'CacheSubDirectory';
    description?: Maybe<Scalars['String']>;
    displayStyle?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
    label?: Maybe<Scalars['String']>;
};

export type Calendar = BaseEntity & {
    __typename?: 'Calendar';
    createdAt: Scalars['DateTime'];
    days: Array<CalendarDay>;
    endDate: Scalars['DateTime'];
    endTime: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    offDays?: Maybe<Array<Scalars['DateTime']>>;
    startDate: Scalars['DateTime'];
    startTime: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
};

export type CalendarDay = {
    __typename?: 'CalendarDay';
    date: Scalars['DateTime'];
    endOfWorkDay?: Maybe<Scalars['DateTime']>;
    startOfWorkDay?: Maybe<Scalars['DateTime']>;
    workDay: Scalars['Boolean'];
};

export type CalendarFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<CalendarUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type CalendarInput = {
    endDate: Scalars['DateTime'];
    endTime: Scalars['DateTime'];
    name: Scalars['String'];
    offDays?: InputMaybe<Array<Scalars['DateTime']>>;
    startDate: Scalars['DateTime'];
    startTime: Scalars['DateTime'];
};

export type CalendarOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type CalendarPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type CalendarPaginationQueryResponse = PaginationResponse & {
    __typename?: 'CalendarPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Calendar>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type CalendarQueryInput = {
    filter?: InputMaybe<CalendarFilterInput>;
    orderBy?: InputMaybe<CalendarOrderByInput>;
    pagination?: InputMaybe<CalendarPaginationInput>;
};

export type CalendarUpdateInput = {
    endDate?: InputMaybe<Scalars['DateTime']>;
    endTime?: InputMaybe<Scalars['DateTime']>;
    id?: InputMaybe<Scalars['ObjectId']>;
    name?: InputMaybe<Scalars['String']>;
    offDays?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
    startDate?: InputMaybe<Scalars['DateTime']>;
    startTime?: InputMaybe<Scalars['DateTime']>;
};

export type CallToAction = {
    __typename?: 'CallToAction';
    buttonText?: Maybe<LocalizedStringLoose>;
    defaultButtonText?: Maybe<Scalars['Boolean']>;
    excludeApps?: Maybe<Array<Maybe<Scalars['String']>>>;
    id?: Maybe<Scalars['ObjectId']>;
    includeApps?: Maybe<Array<Maybe<Scalars['String']>>>;
    parameter1?: Maybe<LocalizedStringLoose>;
    parameter2?: Maybe<LocalizedStringLoose>;
    simpleContent?: Maybe<SimpleContent>;
    simpleContentId?: Maybe<Scalars['ObjectId']>;
    /** @deprecated replaced by tag list */
    tag?: Maybe<Tag>;
    /** @deprecated replaced by taglist */
    tagId?: Maybe<Scalars['ObjectId']>;
    tagList?: Maybe<Array<Scalars['ObjectId']>>;
    type: CtaType;
};

export type CallToActionInput = {
    buttonText?: InputMaybe<LocalizedStringLooseInput>;
    defaultButtonText?: InputMaybe<Scalars['Boolean']>;
    excludeApps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Scalars['ObjectId']>;
    includeApps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    parameter1?: InputMaybe<LocalizedStringLooseInput>;
    parameter2?: InputMaybe<LocalizedStringLooseInput>;
    simpleContentId?: InputMaybe<Scalars['ObjectId']>;
    tagId?: InputMaybe<Scalars['ObjectId']>;
    tagList?: InputMaybe<Array<Scalars['ObjectId']>>;
    type: CtaType;
};

export type CarePlan = BaseEntity & {
    __typename?: 'CarePlan';
    /** App Label */
    appLabel?: Maybe<LocalizedString>;
    baseCarePlan?: Maybe<CarePlan>;
    baseCarePlanId?: Maybe<Scalars['ObjectId']>;
    createdAt: Scalars['DateTime'];
    excludeApps?: Maybe<Array<Scalars['String']>>;
    groups: Array<CarePlanTodoGroup>;
    id: Scalars['ObjectId'];
    includeApps?: Maybe<Array<Scalars['String']>>;
    isTestData?: Maybe<Scalars['Boolean']>;
    /** Portal Label */
    portalLabel?: Maybe<LocalizedString>;
    tags?: Maybe<Array<Scalars['String']>>;
    timeline?: Maybe<WhatsNewTimeline>;
    timelineId?: Maybe<Scalars['ObjectId']>;
    type: CarePlanType;
    updatedAt: Scalars['DateTime'];
    viewLimitMinutes?: Maybe<Scalars['Float']>;
};

export type CarePlanByTodoTasks = {
    __typename?: 'CarePlanByTodoTasks';
    carePlanId?: Maybe<Scalars['String']>;
    carePlanName?: Maybe<LocalizedString>;
    groups?: Maybe<Array<Maybe<GroupByTodoTasks>>>;
};

export type CarePlanFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<CarePlanUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type CarePlanInput = {
    /** App Label */
    appLabel?: InputMaybe<LocalizedStringInput>;
    baseCarePlanId?: InputMaybe<Scalars['ObjectId']>;
    excludeApps?: InputMaybe<Array<Scalars['String']>>;
    groups: Array<CarePlanTodoGroupInput>;
    includeApps?: InputMaybe<Array<Scalars['String']>>;
    /** Portal Label */
    portalLabel?: InputMaybe<LocalizedStringInput>;
    tags?: InputMaybe<Array<Scalars['String']>>;
    timelineId?: InputMaybe<Scalars['ObjectId']>;
    type: CarePlanType;
    viewLimitMinutes?: InputMaybe<Scalars['Float']>;
};

export type CarePlanOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type CarePlanPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type CarePlanPaginationQueryResponse = PaginationResponse & {
    __typename?: 'CarePlanPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<CarePlan>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type CarePlanQueryInput = {
    filter?: InputMaybe<CarePlanFilterInput>;
    orderBy?: InputMaybe<CarePlanOrderByInput>;
    pagination?: InputMaybe<CarePlanPaginationInput>;
};

export type CarePlanTimelineData = {
    __typename?: 'CarePlanTimelineData';
    _id?: Maybe<Scalars['ObjectId']>;
    articleId?: Maybe<Scalars['ObjectId']>;
    durationMinutes?: Maybe<Scalars['Float']>;
    excludeApps?: Maybe<Array<Maybe<Scalars['String']>>>;
    groupId?: Maybe<Scalars['ObjectId']>;
    includeApps?: Maybe<Array<Maybe<Scalars['String']>>>;
    offsetDays?: Maybe<Scalars['Float']>;
    offsetMonths?: Maybe<Scalars['Float']>;
    offsetSequence?: Maybe<Scalars['Float']>;
    offsetYears?: Maybe<Scalars['Float']>;
    priority?: Maybe<Scalars['Float']>;
    tags?: Maybe<Array<Maybe<Scalars['String']>>>;
    templateId?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
    timelineId?: Maybe<Scalars['ObjectId']>;
    title?: Maybe<Scalars['String']>;
};

export type CarePlanTimelineViewerFetchInput = {
    tags?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    timelines?: InputMaybe<Array<InputMaybe<TimelineViewerFetchInputTimeline>>>;
};

export type CarePlanTodoGroup = {
    __typename?: 'CarePlanTodoGroup';
    /** Todo Template Description */
    description?: Maybe<LocalizedString>;
    id: Scalars['ObjectId'];
    /** Todo Template Title */
    label?: Maybe<LocalizedString>;
    suppressedTemplates?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
    templates: Array<CarePlanTodoTemplate>;
};

export type CarePlanTodoGroupInput = {
    /** Todo Template Description */
    description?: InputMaybe<LocalizedStringInput>;
    id: Scalars['ObjectId'];
    /** Todo Template Title */
    label?: InputMaybe<LocalizedStringInput>;
    suppressedTemplates?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    templates: Array<CarePlanTodoTemplateInput>;
};

export type CarePlanTodoTemplate = {
    __typename?: 'CarePlanTodoTemplate';
    articleId: Scalars['ObjectId'];
    /** Todo Template Description */
    description?: Maybe<LocalizedString>;
    durationMinutes?: Maybe<Scalars['Float']>;
    id: Scalars['ObjectId'];
    isEssential: Scalars['Boolean'];
    isRepeating: Scalars['Boolean'];
    notificationData?: Maybe<NotificationData>;
    notificationText?: Maybe<Scalars['String']>;
    offsetDays?: Maybe<Scalars['Float']>;
    offsetMinutes?: Maybe<Scalars['Float']>;
    offsetMonths?: Maybe<Scalars['Float']>;
    offsetSequence?: Maybe<Scalars['Float']>;
    offsetYears?: Maybe<Scalars['Float']>;
    priority: Scalars['Float'];
    repeatCount?: Maybe<Scalars['Float']>;
    repeatIntervalMinutes?: Maybe<Scalars['Float']>;
    shouldSendNotification?: Maybe<Scalars['Boolean']>;
    tags?: Maybe<Array<Scalars['String']>>;
    targetDate?: Maybe<Scalars['DateTime']>;
    /** Todo Template Title */
    title?: Maybe<LocalizedString>;
    type: CarePlanTodoTemplateClockType;
};

/** Care plan todo template clock type */
export enum CarePlanTodoTemplateClockType {
    FixedDate = 'fixedDate',
    Timeline = 'timeline',
}

export type CarePlanTodoTemplateInput = {
    articleId: Scalars['ObjectId'];
    /** Todo Template Description */
    description?: InputMaybe<LocalizedStringInput>;
    durationMinutes?: InputMaybe<Scalars['Float']>;
    id: Scalars['ObjectId'];
    isEssential: Scalars['Boolean'];
    isRepeating: Scalars['Boolean'];
    notificationData?: InputMaybe<NotificationInput>;
    notificationText?: InputMaybe<Scalars['String']>;
    offsetDays?: InputMaybe<Scalars['Float']>;
    offsetMinutes?: InputMaybe<Scalars['Float']>;
    offsetMonths?: InputMaybe<Scalars['Float']>;
    offsetSequence?: InputMaybe<Scalars['Float']>;
    offsetYears?: InputMaybe<Scalars['Float']>;
    priority: Scalars['Float'];
    repeatCount?: InputMaybe<Scalars['Float']>;
    repeatIntervalMinutes?: InputMaybe<Scalars['Float']>;
    shouldSendNotification?: InputMaybe<Scalars['Boolean']>;
    tags?: InputMaybe<Array<Scalars['String']>>;
    targetDate?: InputMaybe<Scalars['DateTime']>;
    /** Todo Template Title */
    title?: InputMaybe<LocalizedStringInput>;
    type: CarePlanTodoTemplateClockType;
};

/** Care plan type */
export enum CarePlanType {
    Base = 'base',
    Derived = 'derived',
}

export type CarePlanUpdateInput = {
    appLabel?: InputMaybe<LocalizedStringInput>;
    baseCarePlanId?: InputMaybe<Scalars['ObjectId']>;
    excludeApps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groups?: InputMaybe<Array<InputMaybe<CarePlanTodoGroupInput>>>;
    includeApps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    portalLabel?: InputMaybe<LocalizedStringInput>;
    tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    timelineId?: InputMaybe<Scalars['ObjectId']>;
    type?: InputMaybe<CarePlanType>;
    viewLimitMinutes?: InputMaybe<Scalars['Float']>;
};

export type CarePlansListInput = {
    appUserId?: InputMaybe<Scalars['String']>;
};

export type CareTeam = BaseEntity & {
    __typename?: 'CareTeam';
    appBundleId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    memberTypes: Array<CareTeamType>;
    name: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type CareTeamContact = BaseEntity & {
    __typename?: 'CareTeamContact';
    address: Address;
    careTeamMemberType?: Maybe<CareTeamMemberType>;
    careTeamMemberTypeId: Scalars['ObjectId'];
    createdAt: Scalars['DateTime'];
    emailAddress: Scalars['String'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    phoneNumber: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    url: Scalars['String'];
};

export type CareTeamContactFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<CareTeamContactUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type CareTeamContactForCareTeamMember = {
    __typename?: 'CareTeamContactForCareTeamMember';
    address: Address;
    careTeamMemberTypeId: Scalars['ObjectId'];
    emailAddress: Scalars['String'];
    name: Scalars['String'];
    phoneNumber: Scalars['String'];
    url: Scalars['String'];
};

export type CareTeamContactForCareTeamMemberInput = {
    address: AddressInput;
    careTeamMemberTypeId: Scalars['ObjectId'];
    emailAddress: Scalars['String'];
    name: Scalars['String'];
    phoneNumber: Scalars['String'];
    url: Scalars['String'];
};

export type CareTeamContactInput = {
    address: AddressInput;
    careTeamMemberTypeId: Scalars['ObjectId'];
    emailAddress: Scalars['String'];
    name: Scalars['String'];
    phoneNumber: Scalars['String'];
    url: Scalars['String'];
};

export type CareTeamContactOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type CareTeamContactPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type CareTeamContactQueryInput = {
    filter?: InputMaybe<CareTeamContactFilterInput>;
    orderBy?: InputMaybe<CareTeamContactOrderByInput>;
    pagination?: InputMaybe<CareTeamContactPaginationInput>;
};

export type CareTeamContactUpdateInput = {
    address?: InputMaybe<AddressInput>;
    emailAddress?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    phoneNumber?: InputMaybe<Scalars['String']>;
    url?: InputMaybe<Scalars['String']>;
};

export type CareTeamContactV2FilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<CareTeamContactUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type CareTeamContactV2OrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type CareTeamContactV2PaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type CareTeamContactsListInput = {
    filter?: InputMaybe<CareTeamContactV2FilterInput>;
    orderBy?: InputMaybe<CareTeamContactV2OrderByInput>;
    pagination?: InputMaybe<CareTeamContactV2PaginationInput>;
};

export type CareTeamContactsResponse = PaginationResponse & {
    __typename?: 'CareTeamContactsResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<CareTeamContact>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type CareTeamFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<CareTeamUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type CareTeamInput = {
    appBundleId: Scalars['String'];
    memberTypes: Array<CareTeamTypeInput>;
    name: Scalars['String'];
};

export type CareTeamMember = BaseEntity & {
    __typename?: 'CareTeamMember';
    afterHoursPhoneNumber?: Maybe<Scalars['String']>;
    appBundleId: Scalars['String'];
    appDefault?: Maybe<Scalars['Boolean']>;
    careTeamMemberContactId?: Maybe<Scalars['ObjectId']>;
    careTeamMemberNPI?: Maybe<Scalars['String']>;
    careTeamMemberTIN?: Maybe<Scalars['String']>;
    careTeamMemberType?: Maybe<CareTeamMemberType>;
    careTeamMemberTypeId?: Maybe<Scalars['ObjectId']>;
    consentAgreement?: Maybe<Scalars['String']>;
    consentRequired?: Maybe<Scalars['Boolean']>;
    createdAt: Scalars['DateTime'];
    eligibilityCheck?: Maybe<Scalars['String']>;
    eligibilityRecheckDays?: Maybe<Scalars['Float']>;
    id: Scalars['ObjectId'];
    isActive?: Maybe<Scalars['Boolean']>;
    isTestData?: Maybe<Scalars['Boolean']>;
    /** Care Team Member Name */
    label: LocalizedString;
    nameWithType: Scalars['String'];
    overridenCareTeamMemberContact?: Maybe<CareTeamContactForCareTeamMember>;
    practiceCode?: Maybe<Scalars['String']>;
    practiceExternalId?: Maybe<Scalars['String']>;
    prerequisiteTeamMembers?: Maybe<Array<Scalars['ObjectId']>>;
    profileDefId?: Maybe<Scalars['ObjectId']>;
    updatedAt: Scalars['DateTime'];
    usState?: Maybe<Scalars['String']>;
    user?: Maybe<User>;
    userId?: Maybe<Scalars['ObjectId']>;
    zipCode?: Maybe<Scalars['String']>;
};

export type CareTeamMemberFieldInListFilterInput = {
    _id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CareTeamMemberFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<CareTeamMemberUpdateInput>;
    fieldsInList?: InputMaybe<CareTeamMemberFieldInListFilterInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type CareTeamMemberInput = {
    afterHoursPhoneNumber?: InputMaybe<Scalars['String']>;
    appBundleId: Scalars['String'];
    appDefault?: InputMaybe<Scalars['Boolean']>;
    careTeamMemberContactId?: InputMaybe<Scalars['ObjectId']>;
    careTeamMemberNPI?: InputMaybe<Scalars['String']>;
    careTeamMemberTIN?: InputMaybe<Scalars['String']>;
    careTeamMemberTypeId?: InputMaybe<Scalars['ObjectId']>;
    consentAgreement?: InputMaybe<Scalars['String']>;
    consentRequired?: InputMaybe<Scalars['Boolean']>;
    eligibilityCheck?: InputMaybe<Scalars['String']>;
    eligibilityRecheckDays?: InputMaybe<Scalars['Float']>;
    isActive?: InputMaybe<Scalars['Boolean']>;
    /** Care Team Member Name */
    label: LocalizedStringInput;
    overridenCareTeamMemberContact?: InputMaybe<CareTeamContactForCareTeamMemberInput>;
    practiceCode?: InputMaybe<Scalars['String']>;
    practiceExternalId?: InputMaybe<Scalars['String']>;
    prerequisiteTeamMembers?: InputMaybe<Array<Scalars['ObjectId']>>;
    profileDefId?: InputMaybe<Scalars['ObjectId']>;
    usState?: InputMaybe<Scalars['String']>;
    userId?: InputMaybe<Scalars['ObjectId']>;
    zipCode?: InputMaybe<Scalars['String']>;
};

export type CareTeamMemberOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type CareTeamMemberPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type CareTeamMemberPaginationQueryResponse = PaginationResponse & {
    __typename?: 'CareTeamMemberPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<CareTeamMember>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type CareTeamMemberQueryInput = {
    filter?: InputMaybe<CareTeamMemberFilterInput>;
    orderBy?: InputMaybe<CareTeamMemberOrderByInput>;
    pagination?: InputMaybe<CareTeamMemberPaginationInput>;
};

export type CareTeamMemberType = BaseEntity & {
    __typename?: 'CareTeamMemberType';
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isJob?: Maybe<Scalars['Boolean']>;
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    profileDef?: Maybe<UserProfileDef>;
    profileDefId: Scalars['ObjectId'];
    updatedAt: Scalars['DateTime'];
};

export type CareTeamMemberTypeFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<CareTeamMemberTypeUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type CareTeamMemberTypeInput = {
    isJob?: InputMaybe<Scalars['Boolean']>;
    name: Scalars['String'];
    profileDefId: Scalars['ObjectId'];
};

export type CareTeamMemberTypeOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type CareTeamMemberTypePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type CareTeamMemberTypePaginationQueryResponse = PaginationResponse & {
    __typename?: 'CareTeamMemberTypePaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<CareTeamMemberType>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type CareTeamMemberTypeQueryInput = {
    filter?: InputMaybe<CareTeamMemberTypeFilterInput>;
    orderBy?: InputMaybe<CareTeamMemberTypeOrderByInput>;
    pagination?: InputMaybe<CareTeamMemberTypePaginationInput>;
};

export type CareTeamMemberTypeUpdateInput = {
    isJob?: InputMaybe<Scalars['Boolean']>;
    name?: InputMaybe<Scalars['String']>;
};

export type CareTeamMemberUpdateInput = {
    afterHoursPhoneNumber?: InputMaybe<Scalars['String']>;
    appDefault?: InputMaybe<Scalars['Boolean']>;
    careTeamMemberContactId?: InputMaybe<Scalars['ObjectId']>;
    careTeamMemberNPI?: InputMaybe<Scalars['String']>;
    careTeamMemberTIN?: InputMaybe<Scalars['String']>;
    careTeamMemberTypeId?: InputMaybe<Scalars['ObjectId']>;
    consentAgreement?: InputMaybe<Scalars['String']>;
    consentRequired?: InputMaybe<Scalars['Boolean']>;
    eligibilityCheck?: InputMaybe<Scalars['String']>;
    eligibilityRecheckDays?: InputMaybe<Scalars['Float']>;
    isActive?: InputMaybe<Scalars['Boolean']>;
    /** Care Team Member Name */
    label?: InputMaybe<LocalizedStringInput>;
    overridenCareTeamMemberContact?: InputMaybe<CareTeamContactForCareTeamMemberInput>;
    practiceCode?: InputMaybe<Scalars['String']>;
    practiceExternalId?: InputMaybe<Scalars['String']>;
    prerequisiteTeamMembers?: InputMaybe<Array<Scalars['ObjectId']>>;
    profileDefId?: InputMaybe<Scalars['ObjectId']>;
    usState?: InputMaybe<Scalars['String']>;
    userId?: InputMaybe<Scalars['ObjectId']>;
    zipCode?: InputMaybe<Scalars['String']>;
};

export type CareTeamMembersByType = {
    __typename?: 'CareTeamMembersByType';
    careTeamMembers: Array<CareTeamMember>;
    careTeamType: CareTeamMemberType;
};

export type CareTeamMembersByTypeNamesInput = {
    bundleId: Scalars['String'];
    careTeamsTypeNames: Array<Scalars['String']>;
};

export type CareTeamOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type CareTeamPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type CareTeamPaginationQueryResponse = PaginationResponse & {
    __typename?: 'CareTeamPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<CareTeam>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type CareTeamQueryInput = {
    filter?: InputMaybe<CareTeamFilterInput>;
    orderBy?: InputMaybe<CareTeamOrderByInput>;
    pagination?: InputMaybe<CareTeamPaginationInput>;
};

export type CareTeamType = {
    __typename?: 'CareTeamType';
    activeMembersCount?: Maybe<Scalars['Float']>;
    hiddenToPhone: Scalars['Boolean'];
    id: Scalars['ObjectId'];
    isRequired: Scalars['Boolean'];
    label?: Maybe<LocalizedString>;
    readOnly: Scalars['Boolean'];
};

export type CareTeamTypeInput = {
    activeMembersCount?: InputMaybe<Scalars['Float']>;
    hiddenToPhone: Scalars['Boolean'];
    id: Scalars['ObjectId'];
    isRequired: Scalars['Boolean'];
    label?: InputMaybe<LocalizedStringInput>;
    readOnly: Scalars['Boolean'];
};

export type CareTeamTypeWithDefId = {
    __typename?: 'CareTeamTypeWithDefId';
    activeMembersCount?: Maybe<Scalars['Float']>;
    hiddenToPhone: Scalars['Boolean'];
    id: Scalars['ObjectId'];
    isRequired: Scalars['Boolean'];
    label?: Maybe<LocalizedString>;
    profileDefId: Scalars['String'];
    readOnly: Scalars['Boolean'];
    value?: Maybe<PvValue>;
};

export type CareTeamTypeWithMembers = {
    __typename?: 'CareTeamTypeWithMembers';
    members?: Maybe<Array<Maybe<CareTeamMember>>>;
    type: CareTeamTypeWithDefId;
};

export type CareTeamTypesInput = {
    appBundleId: Scalars['String'];
    choosedCareTeamTypes?: InputMaybe<Array<InputMaybe<ChoosedCareTeamTypeInput>>>;
};

export type CareTeamUpdateInput = {
    appBundleId: Scalars['String'];
    memberTypes?: InputMaybe<Array<InputMaybe<CareTeamTypeInput>>>;
    name: Scalars['String'];
};

export type ChangeAppUserPasswordInput = {
    newPassword: Scalars['String'];
    oldPassword: Scalars['String'];
};

export type ChangePatientOrgResponse = MutationResponse & {
    __typename?: 'ChangePatientOrgResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type ChangePatientOrganizationInput = {
    orgId: Scalars['String'];
    patientId: Scalars['String'];
};

export type ChatConversation = BaseEntity & {
    __typename?: 'ChatConversation';
    /** Chat Room that this conversation is part of */
    chatRoom: ChatRoom;
    /** Chat Room that this conversation is part of */
    chatRoomId: Scalars['ObjectId'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    /** Messages in this conversation */
    messages?: Maybe<Array<ChatMessage>>;
    /** Time it took to resolve the conversation in milliseconds */
    resolutionTime?: Maybe<Scalars['Float']>;
    /** Date and time when the conversation was resolved */
    resolvedAt?: Maybe<Scalars['DateTime']>;
    /** Date and time when the conversation started */
    startedAt?: Maybe<Scalars['DateTime']>;
    updatedAt: Scalars['DateTime'];
};

export type ChatConversationFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ChatConversationUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ChatConversationInput = {
    /** Chat Room that this conversation is part of */
    chatRoomId: Scalars['ObjectId'];
    /** Time it took to resolve the conversation in milliseconds */
    resolutionTime?: InputMaybe<Scalars['Float']>;
    /** Date and time when the conversation was resolved */
    resolvedAt?: InputMaybe<Scalars['DateTime']>;
    /** Date and time when the conversation started */
    startedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChatConversationOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ChatConversationPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ChatConversationPaginationQueryResponse = PaginationResponse & {
    __typename?: 'ChatConversationPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ChatConversation>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ChatConversationQueryInput = {
    filter?: InputMaybe<ChatConversationFilterInput>;
    orderBy?: InputMaybe<ChatConversationOrderByInput>;
    pagination?: InputMaybe<ChatConversationPaginationInput>;
};

export type ChatConversationUpdateInput = {
    chatRoomId?: InputMaybe<Scalars['ObjectId']>;
    resolvedAt?: InputMaybe<Scalars['DateTime']>;
    startedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChatMessage = BaseEntity & {
    __typename?: 'ChatMessage';
    attachments?: Maybe<Array<Maybe<Scalars['String']>>>;
    cacheId?: Maybe<Scalars['String']>;
    chatConversationId: Scalars['ObjectId'];
    chatRoomId: Scalars['ObjectId'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    read?: Maybe<Array<Maybe<Scalars['String']>>>;
    senderId?: Maybe<Scalars['String']>;
    text?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
};

export type ChatMessageFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ChatMessageUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ChatMessageInput = {
    attachments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    cacheId?: InputMaybe<Scalars['String']>;
    chatConversationId?: InputMaybe<Scalars['ObjectId']>;
    chatRoomId: Scalars['ObjectId'];
    read?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    senderId?: InputMaybe<Scalars['String']>;
    text?: InputMaybe<Scalars['String']>;
};

export type ChatMessageOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ChatMessagePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ChatMessagePaginationQueryResponse = PaginationResponse & {
    __typename?: 'ChatMessagePaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ChatMessage>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ChatMessageQueryInput = {
    filter?: InputMaybe<ChatMessageFilterInput>;
    orderBy?: InputMaybe<ChatMessageOrderByInput>;
    pagination?: InputMaybe<ChatMessagePaginationInput>;
};

export type ChatMessageUpdateInput = {
    attachments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    cacheId?: InputMaybe<Scalars['String']>;
    chatConversationId?: InputMaybe<Scalars['ObjectId']>;
    chatRoomId?: InputMaybe<Scalars['ObjectId']>;
    read?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    text?: InputMaybe<Scalars['String']>;
};

export type ChatRoom = BaseEntity & {
    __typename?: 'ChatRoom';
    /** Care team member assigned to this chat room */
    careTeamMemberType: CareTeamMemberType;
    /** Care team member type associated with the chatroom */
    careTeamMemberTypeId: Scalars['ObjectId'];
    /** Conversations that belong to this chat room */
    conversations: Array<ChatConversation>;
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    latestMessage?: Maybe<ChatMessage>;
    messages: Array<ChatMessage>;
    numberOfMessages?: Maybe<Scalars['Float']>;
    numberOfUnreadMessages?: Maybe<Scalars['Float']>;
    openConversationId?: Maybe<Scalars['ObjectId']>;
    /** Organization the chatroom is in */
    organizationId: Scalars['ObjectId'];
    /** Health Care Advocates or Portal Users who are following this chat */
    participants: Array<User>;
    /** Patient who is the subject/owner of this chat */
    patient: Patient;
    updatedAt: Scalars['DateTime'];
};

export type ChatRoomFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ChatRoomUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ChatRoomInput = {
    /** Care team member type associated with the chatroom */
    careTeamMemberTypeId: Scalars['ObjectId'];
    openConversationId?: InputMaybe<Scalars['ObjectId']>;
    /** Organization the chatroom is in */
    organizationId: Scalars['ObjectId'];
    /** Health Care Advocates or Portal Users who are following this chat */
    participantIds: Array<Scalars['ObjectId']>;
    /** Patient who is the subject/owner of this chat */
    patientId: Scalars['ObjectId'];
};

export type ChatRoomOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ChatRoomPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ChatRoomPaginationQueryResponse = PaginationResponse & {
    __typename?: 'ChatRoomPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ChatRoom>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ChatRoomQueryInput = {
    filter?: InputMaybe<ChatRoomFilterInput>;
    orderBy?: InputMaybe<ChatRoomOrderByInput>;
    pagination?: InputMaybe<ChatRoomPaginationInput>;
};

export type ChatRoomUpdateInput = {
    careTeamMemberTypeId?: InputMaybe<Scalars['ObjectId']>;
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    participants?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
};

export type CheckEligibilityFormInput = {
    Destination?: InputMaybe<DestinationNames>;
    address?: InputMaybe<AddressInput>;
    appointmentMethod?: InputMaybe<AppointmentMethod>;
    birthDate?: InputMaybe<Scalars['DateTime']>;
    email?: InputMaybe<Scalars['String']>;
    firstName?: InputMaybe<Scalars['String']>;
    insuranceGroupNumber?: InputMaybe<Scalars['String']>;
    insuranceId?: InputMaybe<Scalars['String']>;
    insuredAddress?: InputMaybe<AddressInput>;
    insuredFirstName?: InputMaybe<Scalars['String']>;
    insuredLastName?: InputMaybe<Scalars['String']>;
    insuredPhoneNumber?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
    patientRelationshipToInsured?: InputMaybe<Scalars['String']>;
    phoneNumber?: InputMaybe<Scalars['String']>;
    sex?: InputMaybe<Scalars['String']>;
    subscriberBirthDate?: InputMaybe<Scalars['DateTime']>;
    subscriberSex?: InputMaybe<Scalars['String']>;
};

export type CheckEligibilityFormResponse = MutationResponse & {
    __typename?: 'CheckEligibilityFormResponse';
    isEligible: Scalars['Boolean'];
    isMocked: Scalars['Boolean'];
    message: Scalars['String'];
    resultId?: Maybe<Scalars['String']>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CheckEligibilityInput = {
    bundleId: Scalars['String'];
    patientIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ChoosedCareTeamTypeInput = {
    careTeamMemberId: Scalars['String'];
    careTeamTypeId: Scalars['String'];
};

export type ClaimCreatePatientInput = {
    billingType?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['String']>;
    relatedEntryId?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<MessageEntryType>;
    visitDate?: InputMaybe<Scalars['String']>;
};

/** Claim Payment status */
export enum ClaimPaymentStatus {
    InProcess = 'inProcess',
    PaidInFull = 'paidInFull',
}

export type ClaimSubmitted = BaseEntity & {
    __typename?: 'ClaimSubmitted';
    BillAddr1?: Maybe<Scalars['String']>;
    BillCity?: Maybe<Scalars['String']>;
    BillName?: Maybe<Scalars['String']>;
    BillState?: Maybe<Scalars['String']>;
    BillTin?: Maybe<Scalars['String']>;
    BillTinType?: Maybe<Scalars['String']>;
    BillZip?: Maybe<Scalars['String']>;
    Charge_1?: Maybe<Scalars['String']>;
    ClaimRef: Scalars['ObjectId'];
    DiagCodeA?: Maybe<Scalars['String']>;
    DiagPtrs_1?: Maybe<Scalars['String']>;
    ICDindicator?: Maybe<Scalars['String']>;
    Modifiers_1?: Maybe<Scalars['String']>;
    POS_1?: Maybe<Scalars['String']>;
    PatAddr?: Maybe<Scalars['String']>;
    PatBirthdate?: Maybe<Scalars['String']>;
    PatCity?: Maybe<Scalars['String']>;
    PatFName?: Maybe<Scalars['String']>;
    PatLName?: Maybe<Scalars['String']>;
    PatRel?: Maybe<Scalars['String']>;
    PatSex?: Maybe<Scalars['String']>;
    PatState?: Maybe<Scalars['String']>;
    PatZip?: Maybe<Scalars['String']>;
    PayerName?: Maybe<Scalars['String']>;
    PaymentStatus?: Maybe<ClaimPaymentStatus>;
    ProcCode_1?: Maybe<Scalars['String']>;
    SbrAddr?: Maybe<Scalars['String']>;
    SbrBirthdate?: Maybe<Scalars['String']>;
    SbrCity?: Maybe<Scalars['String']>;
    SbrFName?: Maybe<Scalars['String']>;
    SbrID?: Maybe<Scalars['String']>;
    SbrLName?: Maybe<Scalars['String']>;
    SbrSex?: Maybe<Scalars['String']>;
    SbrState?: Maybe<Scalars['String']>;
    SbrZip?: Maybe<Scalars['String']>;
    ServiceFrom_1?: Maybe<Scalars['String']>;
    Units_1?: Maybe<Scalars['String']>;
    affiliateId?: Maybe<Scalars['String']>;
    affiliateInvoice?: Maybe<AffiliateInvoice>;
    affiliateInvoiceId?: Maybe<Scalars['ObjectId']>;
    batchId?: Maybe<Scalars['Float']>;
    createdAt: Scalars['DateTime'];
    destination?: Maybe<Scalars['String']>;
    errorText?: Maybe<Scalars['String']>;
    /** Set to true if need to upload claim to the 3rd party service. Ignore config value */
    forceUpload?: Maybe<Scalars['Boolean']>;
    fromBuilder?: Maybe<Scalars['Boolean']>;
    id: Scalars['ObjectId'];
    invoice_rate?: Maybe<Scalars['String']>;
    isTestData?: Maybe<Scalars['Boolean']>;
    organizationId?: Maybe<Scalars['String']>;
    patient: Patient;
    patientId?: Maybe<Scalars['ObjectId']>;
    /** External id of source entity */
    relatedEntryId?: Maybe<Scalars['String']>;
    relatedEntryType?: Maybe<MessageEntryType>;
    reportClaimId?: Maybe<Scalars['String']>;
    sameUploaded?: Maybe<Scalars['Boolean']>;
    service_type?: Maybe<Scalars['String']>;
    /** Claim was created from this source */
    source?: Maybe<ClaimSubmittedSource>;
    /** Show result of claim handle */
    status?: Maybe<ClaimSubmittedStatus>;
    timeTapClientId?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
    /** Show if claim was uploaded to the 3rd service */
    uploaded?: Maybe<Scalars['Boolean']>;
};

export type ClaimSubmittedFieldInListFilterInput = {
    organizationId?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Array<InputMaybe<ClaimSubmittedStatus>>>;
};

export type ClaimSubmittedGlobalSearchInputFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ClaimSubmittedUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ClaimSubmittedGlobalSearchInputOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ClaimSubmittedGlobalSearchInputPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ClaimSubmittedGlobalSearchResponse = PaginationResponse & {
    __typename?: 'ClaimSubmittedGlobalSearchResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ClaimSubmitted>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ClaimSubmittedInput = {
    BillAddr1?: InputMaybe<Scalars['String']>;
    BillCity?: InputMaybe<Scalars['String']>;
    BillName?: InputMaybe<Scalars['String']>;
    BillState?: InputMaybe<Scalars['String']>;
    BillTin?: InputMaybe<Scalars['String']>;
    BillTinType?: InputMaybe<Scalars['String']>;
    BillZip?: InputMaybe<Scalars['String']>;
    Charge_1?: InputMaybe<Scalars['String']>;
    DiagCodeA?: InputMaybe<Scalars['String']>;
    DiagPtrs_1?: InputMaybe<Scalars['String']>;
    ICDindicator?: InputMaybe<Scalars['String']>;
    Modifiers_1?: InputMaybe<Scalars['String']>;
    POS_1?: InputMaybe<Scalars['String']>;
    PatAddr?: InputMaybe<Scalars['String']>;
    PatBirthdate?: InputMaybe<Scalars['String']>;
    PatCity?: InputMaybe<Scalars['String']>;
    PatFName?: InputMaybe<Scalars['String']>;
    PatLName?: InputMaybe<Scalars['String']>;
    PatRel?: InputMaybe<Scalars['String']>;
    PatSex?: InputMaybe<Scalars['String']>;
    PatState?: InputMaybe<Scalars['String']>;
    PatZip?: InputMaybe<Scalars['String']>;
    PayerName?: InputMaybe<Scalars['String']>;
    PaymentStatus?: InputMaybe<ClaimPaymentStatus>;
    ProcCode_1?: InputMaybe<Scalars['String']>;
    SbrAddr?: InputMaybe<Scalars['String']>;
    SbrBirthdate?: InputMaybe<Scalars['String']>;
    SbrCity?: InputMaybe<Scalars['String']>;
    SbrFName?: InputMaybe<Scalars['String']>;
    SbrID?: InputMaybe<Scalars['String']>;
    SbrLName?: InputMaybe<Scalars['String']>;
    SbrSex?: InputMaybe<Scalars['String']>;
    SbrState?: InputMaybe<Scalars['String']>;
    SbrZip?: InputMaybe<Scalars['String']>;
    ServiceFrom_1?: InputMaybe<Scalars['String']>;
    Units_1?: InputMaybe<Scalars['String']>;
    affiliateId?: InputMaybe<Scalars['String']>;
    batchId?: InputMaybe<Scalars['Float']>;
    destination?: InputMaybe<Scalars['String']>;
    errorText?: InputMaybe<Scalars['String']>;
    fromBuilder?: InputMaybe<Scalars['Boolean']>;
    invoice_rate?: InputMaybe<Scalars['String']>;
    organizationId?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    relatedEntryId?: InputMaybe<Scalars['String']>;
    relatedEntryType?: InputMaybe<MessageEntryType>;
    searchInput?: InputMaybe<Scalars['String']>;
    service_type?: InputMaybe<Scalars['String']>;
    source?: InputMaybe<ClaimSubmittedSource>;
    status?: InputMaybe<ClaimSubmittedStatus>;
};

/** Claim Submitted incoming data source. */
export enum ClaimSubmittedSource {
    Affiliate = 'affiliate',
    File = 'file',
    Message = 'message',
}

/** Claim Submitted status. */
export enum ClaimSubmittedStatus {
    Completed = 'completed',
    Error = 'error',
    InProgress = 'inProgress',
    Ready = 'ready',
}

export type ClaimSubmittedUpdateInput = {
    affiliateId?: InputMaybe<Scalars['String']>;
    affiliateInvoiceId?: InputMaybe<Scalars['ObjectId']>;
    batchId?: InputMaybe<Scalars['Float']>;
    organizationId?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    status?: InputMaybe<ClaimSubmittedStatus>;
    uploaded?: InputMaybe<Scalars['Boolean']>;
};

export type ClearPatientProfileKeyValuesInput = {
    keyId: Scalars['ObjectId'];
    patientId: Scalars['ObjectId'];
};

export type ClearPatientProfileValueInput = {
    patientId: Scalars['ObjectId'];
    profileVariableDefId: Scalars['ObjectId'];
};

export type ClinicalInfo = {
    __typename?: 'ClinicalInfo';
    abbreviation?: Maybe<Scalars['String']>;
    code?: Maybe<Scalars['String']>;
    codeset?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    notes?: Maybe<Array<Maybe<Scalars['String']>>>;
    units?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

export type ClinicalInfoInput = {
    abbreviation?: InputMaybe<Scalars['String']>;
    code?: InputMaybe<Scalars['String']>;
    codeset?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    units?: InputMaybe<Scalars['String']>;
    value?: InputMaybe<Scalars['String']>;
};

export type ClinicalSummary = BaseEntity & {
    __typename?: 'ClinicalSummary';
    createdAt: Scalars['DateTime'];
    demographics?: Maybe<Demographics>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    logId?: Maybe<Scalars['String']>;
    medications?: Maybe<Array<Medication>>;
    patient?: Maybe<Patient>;
    patientId?: Maybe<Scalars['ObjectId']>;
    practiceId: Scalars['ObjectId'];
    problems?: Maybe<Array<Problem>>;
    /** Free text form of the problems summary. */
    problemsText?: Maybe<Scalars['String']>;
    redoxEhrId?: Maybe<Scalars['String']>;
    redoxSourceId?: Maybe<Scalars['String']>;
    resolvedProblems?: Maybe<Array<ResolvedProblem>>;
    /** Free text form of the problems summary */
    resolvedProblemsText?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
    visitId?: Maybe<Scalars['String']>;
};

export type ClinicalSummaryFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ClinicalSummaryUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ClinicalSummaryInput = {
    demographics?: InputMaybe<DemographicsInput>;
    logId?: InputMaybe<Scalars['String']>;
    medications?: InputMaybe<Array<MedicationInput>>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    problems?: InputMaybe<Array<ProblemInput>>;
    /** Free text form of the problems summary. */
    problemsText?: InputMaybe<Scalars['String']>;
    redoxEhrId?: InputMaybe<Scalars['String']>;
    redoxSourceId?: InputMaybe<Scalars['String']>;
    resolvedProblems?: InputMaybe<Array<ResolvedProblemInput>>;
    /** Free text form of the problems summary */
    resolvedProblemsText?: InputMaybe<Scalars['String']>;
    visitId?: InputMaybe<Scalars['String']>;
};

export type ClinicalSummaryOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ClinicalSummaryPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ClinicalSummaryPaginationQueryResponse = PaginationResponse & {
    __typename?: 'ClinicalSummaryPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ClinicalSummary>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ClinicalSummaryQueryInput = {
    filter?: InputMaybe<ClinicalSummaryFilterInput>;
    orderBy?: InputMaybe<ClinicalSummaryOrderByInput>;
    pagination?: InputMaybe<ClinicalSummaryPaginationInput>;
};

export type ClinicalSummaryUpdateInput = {
    demographics?: InputMaybe<DemographicsInput>;
    logId?: InputMaybe<Scalars['String']>;
    medications?: InputMaybe<Array<MedicationInput>>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    practiceId?: InputMaybe<Scalars['ObjectId']>;
    problems?: InputMaybe<Array<ProblemInput>>;
    /** Free text form of the problems summary. */
    problemsText?: InputMaybe<Scalars['String']>;
    redoxEhrId?: InputMaybe<Scalars['String']>;
    redoxSourceId?: InputMaybe<Scalars['String']>;
    resolvedProblems?: InputMaybe<Array<ResolvedProblemInput>>;
    /** Free text form of the problems summary */
    resolvedProblemsText?: InputMaybe<Scalars['String']>;
    visitId?: InputMaybe<Scalars['String']>;
};

/** Clock type for time dependent content */
export enum ClockType {
    FixedDate = 'fixedDate',
    Timeline = 'timeline',
}

export type CloneArticleInput = {
    articleId?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type CloneArticleMutationResponse = MutationResponse & {
    __typename?: 'CloneArticleMutationResponse';
    article?: Maybe<Article>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CloneBioScreenInput = {
    id: Scalars['ObjectId'];
    label: LocalizedStringInput;
};

export type CloneBioScreenResponse = MutationResponse & {
    __typename?: 'CloneBioScreenResponse';
    bioScreen?: Maybe<BioScreen>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CloneVariantInput = {
    articleId?: InputMaybe<Scalars['String']>;
    fromAppBundleId?: InputMaybe<Scalars['String']>;
    fromLanguage?: InputMaybe<Scalars['String']>;
    fromReadingLevel?: InputMaybe<Scalars['String']>;
    toAppBundleId?: InputMaybe<Scalars['String']>;
    toLanguage?: InputMaybe<Scalars['String']>;
    toReadingLevel?: InputMaybe<Scalars['String']>;
};

export type CloneVariantMutationResponse = MutationResponse & {
    __typename?: 'CloneVariantMutationResponse';
    appBundleId?: Maybe<Scalars['String']>;
    article?: Maybe<Article>;
    language?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    readingLevel?: Maybe<Scalars['String']>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CloseAppTaskInput = {
    appTaskId: Scalars['ObjectId'];
};

export type CloseAppTaskResponse = MutationResponse & {
    __typename?: 'CloseAppTaskResponse';
    appTaskId: Scalars['String'];
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CloseHighlightFeedResponse = MutationResponse & {
    __typename?: 'CloseHighlightFeedResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CognitoTokens = {
    __typename?: 'CognitoTokens';
    accessToken: Scalars['String'];
    idToken: Scalars['String'];
    refreshToken: Scalars['String'];
};

export type CombinedQuestion = {
    __typename?: 'CombinedQuestion';
    createdAt: Scalars['DateTime'];
    id: Scalars['String'];
    isDone: Scalars['Boolean'];
    /** Date question was submitted on app */
    submittedAt: Scalars['DateTime'];
    text: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type CompleteAllTodoTasksInput = {
    dateCompleted: Scalars['DateTime'];
    ids: Array<Scalars['String']>;
};

export type CompletePotentialUserFlowMutationResponse = MutationResponse & {
    __typename?: 'CompletePotentialUserFlowMutationResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CompleteTodoTasksResponse = MutationResponse & {
    __typename?: 'CompleteTodoTasksResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CompletedTodoInput = {
    appUserId?: InputMaybe<Scalars['String']>;
};

export type CompoundQuestion = BaseEntity & {
    __typename?: 'CompoundQuestion';
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    /** Compound Question Label */
    label: LocalizedString;
    /** Profile Definition Type */
    profileValueType: UserProfileValueType;
    /** List of Profile Definitions */
    questionProfileDefs: Array<CompoundQuestionProfileDef>;
    updatedAt: Scalars['DateTime'];
};

export type CompoundQuestionCustomFiltersInput = {
    profileDefinition?: InputMaybe<Scalars['String']>;
};

export type CompoundQuestionFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<CompoundQuestionUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type CompoundQuestionInput = {
    /** Compound Question Label */
    label: LocalizedStringInput;
    /** Profile Definition Type */
    profileValueType: UserProfileValueType;
    /** List of Profile Definitions */
    questionProfileDefs: Array<CompoundQuestionProfileDefInput>;
};

export type CompoundQuestionOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type CompoundQuestionPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type CompoundQuestionProfileDef = {
    __typename?: 'CompoundQuestionProfileDef';
    /** Compound Question Profile Def Override */
    labelOverride?: Maybe<LocalizedStringLoose>;
    questionProfileDef?: Maybe<UserProfileDef>;
    /** Profile Definition Id */
    questionProfileDefId: Scalars['ObjectId'];
    value?: Maybe<UserProfileDefChoiceValue>;
};

export type CompoundQuestionProfileDefInput = {
    /** Compound Question Profile Def Override */
    labelOverride?: InputMaybe<LocalizedStringLooseInput>;
    /** Profile Definition Id */
    questionProfileDefId: Scalars['ObjectId'];
    value?: InputMaybe<UserProfileDefChoiceValueInput>;
};

export type CompoundQuestionQueryInput = {
    filter?: InputMaybe<CompoundQuestionFilterInput>;
    orderBy?: InputMaybe<CompoundQuestionOrderByInput>;
    pagination?: InputMaybe<CompoundQuestionPaginationInput>;
};

export type CompoundQuestionUpdateInput = {
    label?: InputMaybe<LocalizedStringInput>;
    profileValueType?: InputMaybe<Scalars['String']>;
    questionProfileDefs?: InputMaybe<Array<InputMaybe<CompoundQuestionProfileDefInput>>>;
};

export type CompoundQuestionsListFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<CompoundQuestionUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type CompoundQuestionsListInput = {
    customFilters?: InputMaybe<CompoundQuestionCustomFiltersInput>;
    filter?: InputMaybe<CompoundQuestionsListFilterInput>;
    orderBy?: InputMaybe<CompoundQuestionsListOrderByInput>;
    pagination?: InputMaybe<CompoundQuestionsListPaginationInput>;
};

export type CompoundQuestionsListOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type CompoundQuestionsListPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type CompoundQuestionsResponse = PaginationResponse & {
    __typename?: 'CompoundQuestionsResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<CompoundQuestion>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type Condition = BaseEntity & {
    __typename?: 'Condition';
    conditionTypeId?: Maybe<Scalars['ObjectId']>;
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    organizationId?: Maybe<Scalars['ObjectId']>;
    patientId?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
};

export type ConditionFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ConditionUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ConditionInput = {
    conditionTypeId?: InputMaybe<Scalars['ObjectId']>;
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    patientId?: InputMaybe<Scalars['String']>;
};

export type ConditionOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type ConditionPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ConditionPaginationQueryResponse = PaginationResponse & {
    __typename?: 'ConditionPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Condition>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ConditionQueryInput = {
    filter?: InputMaybe<ConditionFilterInput>;
    orderBy?: InputMaybe<ConditionOrderByInput>;
    pagination?: InputMaybe<ConditionPaginationInput>;
};

export type ConditionType = BaseEntity & {
    __typename?: 'ConditionType';
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    name: LocalizedString;
    updatedAt: Scalars['DateTime'];
};

export type ConditionTypeFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ConditionTypeUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ConditionTypeInput = {
    name: LocalizedStringInput;
};

export type ConditionTypeOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ConditionTypePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ConditionTypePaginationQueryResponse = PaginationResponse & {
    __typename?: 'ConditionTypePaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ConditionType>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ConditionTypeQueryInput = {
    filter?: InputMaybe<ConditionTypeFilterInput>;
    orderBy?: InputMaybe<ConditionTypeOrderByInput>;
    pagination?: InputMaybe<ConditionTypePaginationInput>;
};

export type ConditionTypeUpdateInput = {
    name?: InputMaybe<LocalizedStringInput>;
};

export type ConditionUpdateInput = {
    conditionTypeId?: InputMaybe<Scalars['ObjectId']>;
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    patientId?: InputMaybe<Scalars['String']>;
};

export type ConfigExpression = BaseEntity & {
    __typename?: 'ConfigExpression';
    appConfigIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
    createdAt: Scalars['DateTime'];
    description?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    measurementTypes?: Maybe<Array<Scalars['String']>>;
    name: Scalars['String'];
    profileDefIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
    /** Expression data input */
    rules: Scalars['JSON'];
    symptoms?: Maybe<Array<Scalars['String']>>;
    updatedAt: Scalars['DateTime'];
    useType: ExpressionUseType;
};

export type ConfigExpressionFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ConfigExpressionUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ConfigExpressionInput = {
    appConfigIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    description?: InputMaybe<Scalars['String']>;
    measurementTypes?: InputMaybe<Array<Scalars['String']>>;
    name: Scalars['String'];
    profileDefIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    /** Expression data input */
    rules: Scalars['JSON'];
    symptoms?: InputMaybe<Array<Scalars['String']>>;
    useType: ExpressionUseType;
};

export type ConfigExpressionListInput = {
    filter?: InputMaybe<ConfigExpressionV2FilterInput>;
    orderBy?: InputMaybe<ConfigExpressionV2OrderByInput>;
    pagination?: InputMaybe<ConfigExpressionV2PaginationInput>;
};

export type ConfigExpressionOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ConfigExpressionPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ConfigExpressionQueryInput = {
    filter?: InputMaybe<ConfigExpressionFilterInput>;
    orderBy?: InputMaybe<ConfigExpressionOrderByInput>;
    pagination?: InputMaybe<ConfigExpressionPaginationInput>;
};

export type ConfigExpressionResponse = PaginationResponse & {
    __typename?: 'ConfigExpressionResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ConfigExpression>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ConfigExpressionUpdateInput = {
    appConfigIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    description?: InputMaybe<Scalars['String']>;
    measurementTypes?: InputMaybe<Array<Scalars['String']>>;
    name?: InputMaybe<Scalars['String']>;
    profileDefIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    /** Expression Rules */
    rules?: InputMaybe<Scalars['JSON']>;
    symptoms?: InputMaybe<Array<Scalars['String']>>;
    useType?: InputMaybe<ExpressionUseType>;
};

export type ConfigExpressionV2FilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ConfigExpressionUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ConfigExpressionV2OrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ConfigExpressionV2PaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ConfigValue = BaseEntity & {
    __typename?: 'ConfigValue';
    app: AmsApp;
    appBundleId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    overrides?: Maybe<Array<Maybe<ExpressionOverride>>>;
    updatedAt: Scalars['DateTime'];
    value: ConfigValueDictionary;
    valueDef: ConfigValueDef;
};

export type ConfigValueDef = BaseEntity & {
    __typename?: 'ConfigValueDef';
    category: Scalars['String'];
    createdAt: Scalars['DateTime'];
    defaultValue: ConfigValueDictionary;
    description?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    potentialValues: Array<ConfigValueDictionary>;
    updatedAt: Scalars['DateTime'];
    validationPattern?: Maybe<Scalars['String']>;
    valueType: ConfigValueType;
};

export type ConfigValueDefFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ConfigValueDefUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ConfigValueDefInput = {
    category: Scalars['String'];
    defaultValue: ConfigValueDictionaryInput;
    description?: InputMaybe<Scalars['String']>;
    name: Scalars['String'];
    potentialValues: Array<ConfigValueDictionaryInput>;
    validationPattern?: InputMaybe<Scalars['String']>;
    valueType: ConfigValueType;
};

export type ConfigValueDefOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ConfigValueDefPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ConfigValueDefPaginationQueryResponse = PaginationResponse & {
    __typename?: 'ConfigValueDefPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ConfigValueDef>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ConfigValueDefQueryInput = {
    filter?: InputMaybe<ConfigValueDefFilterInput>;
    orderBy?: InputMaybe<ConfigValueDefOrderByInput>;
    pagination?: InputMaybe<ConfigValueDefPaginationInput>;
};

export type ConfigValueDefUpdateInput = {
    category?: InputMaybe<Scalars['String']>;
    defaultValue?: InputMaybe<ConfigValueDictionaryInput>;
    description?: InputMaybe<Scalars['String']>;
    name: Scalars['String'];
    potentialValues?: InputMaybe<Array<InputMaybe<ConfigValueDictionaryInput>>>;
    validationPattern?: InputMaybe<Scalars['String']>;
    valueType?: InputMaybe<Scalars['String']>;
};

export type ConfigValueDictionary = {
    __typename?: 'ConfigValueDictionary';
    bool?: Maybe<Scalars['Boolean']>;
    boolArr?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
    localizedStr?: Maybe<LocalizedString>;
    localizedStrArr?: Maybe<Array<Maybe<LocalizedString>>>;
    num?: Maybe<Scalars['Float']>;
    numArr?: Maybe<Array<Maybe<Scalars['Float']>>>;
    str?: Maybe<Scalars['String']>;
    strArr?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ConfigValueDictionaryInput = {
    bool?: InputMaybe<Scalars['Boolean']>;
    boolArr?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
    localizedStr?: InputMaybe<LocalizedStringInput>;
    localizedStrArr?: InputMaybe<Array<InputMaybe<LocalizedStringInput>>>;
    num?: InputMaybe<Scalars['Float']>;
    numArr?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
    str?: InputMaybe<Scalars['String']>;
    strArr?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ConfigValueFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ConfigValueUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ConfigValueInput = {
    appBundleId: Scalars['String'];
    name: Scalars['String'];
    overrides?: InputMaybe<ExpressionOverrideInput>;
    value: ConfigValueDictionaryInput;
    valueDefId: Scalars['ObjectId'];
};

export type ConfigValueOrDefaultInput = {
    bundleId: Scalars['String'];
    configValueId?: InputMaybe<Scalars['ObjectId']>;
    name?: InputMaybe<Scalars['String']>;
};

export type ConfigValueOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ConfigValuePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ConfigValuePaginationQueryResponse = PaginationResponse & {
    __typename?: 'ConfigValuePaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ConfigValue>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ConfigValueQueryInput = {
    filter?: InputMaybe<ConfigValueFilterInput>;
    orderBy?: InputMaybe<ConfigValueOrderByInput>;
    pagination?: InputMaybe<ConfigValuePaginationInput>;
};

/** Possible value types of config values */
export enum ConfigValueType {
    Bool = 'Bool',
    BoolArr = 'BoolArr',
    LocalizedStr = 'LocalizedStr',
    LocalizedStrArr = 'LocalizedStrArr',
    Num = 'Num',
    NumArr = 'NumArr',
    Str = 'Str',
    StrArr = 'StrArr',
}

export type ConfigValueUpdateInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    order?: InputMaybe<Scalars['Float']>;
    value?: InputMaybe<ConfigValueDictionaryInput>;
};

export type ContentDeleteInput = {
    id?: InputMaybe<Scalars['String']>;
    isImage?: InputMaybe<Scalars['Boolean']>;
};

export type ContentDirectory = BaseEntity & {
    __typename?: 'ContentDirectory';
    appDescription?: Maybe<LocalizedString>;
    createdAt: Scalars['DateTime'];
    description?: Maybe<Scalars['String']>;
    displayStyle?: Maybe<DirectoryDisplayStyle>;
    icon?: Maybe<ContentIcon>;
    id: Scalars['ObjectId'];
    imageContent?: Maybe<SimpleContent>;
    imageContentId?: Maybe<Scalars['ObjectId']>;
    isTestData?: Maybe<Scalars['Boolean']>;
    items?: Maybe<Array<DirectoryItem>>;
    keywords?: Maybe<Scalars['String']>;
    label: LocalizedString;
    name: Scalars['String'];
    reportingCategorys?: Maybe<Array<Scalars['ObjectId']>>;
    taggedImages?: Maybe<Array<TaggedImageShared>>;
    updatedAt: Scalars['DateTime'];
};

export type ContentDirectoryFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ContentDirectoryUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ContentDirectoryInput = {
    appDescription?: InputMaybe<LocalizedStringInput>;
    description?: InputMaybe<Scalars['String']>;
    displayStyle?: InputMaybe<DirectoryDisplayStyle>;
    icon?: InputMaybe<ContentIcon>;
    imageContentId?: InputMaybe<Scalars['ObjectId']>;
    items?: InputMaybe<Array<DirectoryItemInput>>;
    keywords?: InputMaybe<Scalars['String']>;
    label: LocalizedStringInput;
    name: Scalars['String'];
    reportingCategorys?: InputMaybe<Array<Scalars['ObjectId']>>;
    taggedImages?: InputMaybe<Array<TaggedImageInput>>;
};

export type ContentDirectoryListFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ContentDirectoryUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ContentDirectoryListInput = {
    filter?: InputMaybe<ContentDirectoryListFilterInput>;
    orderBy?: InputMaybe<ContentDirectoryListOrderByInput>;
    pagination?: InputMaybe<ContentDirectoryListPaginationInput>;
};

export type ContentDirectoryListOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ContentDirectoryListPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ContentDirectoryListResponse = PaginationResponse & {
    __typename?: 'ContentDirectoryListResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ContentDirectory>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ContentDirectoryOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ContentDirectoryPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ContentDirectoryPickerFetchInput = {
    parentDirectoryId?: InputMaybe<Scalars['String']>;
    searchTerm?: InputMaybe<Scalars['String']>;
};

export type ContentDirectoryPickerFetchResponse = {
    __typename?: 'ContentDirectoryPickerFetchResponse';
    data?: Maybe<Array<Maybe<ContentDirectory>>>;
    total?: Maybe<Scalars['Float']>;
};

export type ContentDirectoryQueryInput = {
    filter?: InputMaybe<ContentDirectoryFilterInput>;
    orderBy?: InputMaybe<ContentDirectoryOrderByInput>;
    pagination?: InputMaybe<ContentDirectoryPaginationInput>;
};

export type ContentDirectoryUpdateInput = {
    appDescription?: InputMaybe<LocalizedStringInput>;
    description?: InputMaybe<Scalars['String']>;
    displayStyle?: InputMaybe<DirectoryDisplayStyle>;
    icon?: InputMaybe<ContentIcon>;
    imageContentId?: InputMaybe<Scalars['ObjectId']>;
    items?: InputMaybe<Array<DirectoryItemInput>>;
    keywords?: InputMaybe<Scalars['String']>;
    label?: InputMaybe<LocalizedStringInput>;
    name?: InputMaybe<Scalars['String']>;
    reportingCategorys?: InputMaybe<Array<Scalars['ObjectId']>>;
    taggedImages?: InputMaybe<Array<TaggedImageInput>>;
};

/** Content Icons. */
export enum ContentIcon {
    About = 'about',
    Aboutfilled = 'aboutfilled',
    Abouttheapp = 'abouttheapp',
    Accouncircle = 'accouncircle',
    Accountfamily = 'accountfamily',
    Accountfamilymember = 'accountfamilymember',
    Add = 'add',
    Addcircle = 'addcircle',
    Addcircledashed = 'addcircledashed',
    Addheart = 'addheart',
    Addreminder = 'addreminder',
    Arrowback = 'arrowback',
    Arrowup = 'arrowup',
    Arrowupfilled = 'arrowupfilled',
    Assessment = 'assessment',
    Assignment = 'assignment',
    Attachment = 'attachment',
    Attention = 'attention',
    Babboostfilled = 'babboostfilled',
    Babyboost = 'babyboost',
    Birthplan = 'birthplan',
    Bloodpressure = 'bloodpressure',
    Bloodpressuretracker = 'bloodpressuretracker',
    Bluetooth = 'bluetooth',
    Bookshelf = 'bookshelf',
    Branch = 'branch',
    Branchfilled = 'branchfilled',
    Building = 'building',
    Buildingfilled = 'buildingfilled',
    Calculator = 'calculator',
    Calendar = 'calendar',
    Calendarfilled = 'calendarfilled',
    Calendarnavicon = 'calendarnavicon',
    Call = 'call',
    Camera = 'camera',
    Camerafilled = 'camerafilled',
    Cancel = 'cancel',
    Care = 'care',
    Check = 'check',
    Checkboxchecked = 'checkboxchecked',
    Checkboxunchecked = 'checkboxunchecked',
    Checkcircle = 'checkcircle',
    Checkedlist = 'checkedlist',
    Clear = 'clear',
    Configuration = 'configuration',
    Configurationfilled = 'configurationfilled',
    Content = 'content',
    Covidcymptomschecker = 'covidcymptomschecker',
    Create = 'create',
    Dailytracker = 'dailytracker',
    Dailytrackerfilled = 'dailytrackerfilled',
    Delete = 'delete',
    Deviceorder = 'deviceorder',
    Diaperdirty = 'diaperdirty',
    Diaperdirtycircled = 'diaperdirtycircled',
    Diaperdirtycircledfilled = 'diaperdirtycircledfilled',
    Diaperdry = 'diaperdry',
    Diaperdrycircled = 'diaperdrycircled',
    Diaperdrycircledfilled = 'diaperdrycircledfilled',
    Diapermixed = 'diapermixed',
    Diapermixedcircled = 'diapermixedcircled',
    Diapermixedcircledfilled = 'diapermixedcircledfilled',
    Diapertracker = 'diapertracker',
    Diapertrackerfilled = 'diapertrackerfilled',
    Diaperwet = 'diaperwet',
    Diaperwetcircled = 'diaperwetcircled',
    Diaperwetcircledfilled = 'diaperwetcircledfilled',
    Direction = 'direction',
    Diversity = 'diversity',
    Doctorfilled = 'doctorfilled',
    Doctorprovider = 'doctorprovider',
    Document = 'document',
    Dot = 'dot',
    Drop = 'drop',
    Duedate = 'duedate',
    Duedatecalculatorfilled = 'duedatecalculatorfilled',
    Editprofile = 'editprofile',
    Error = 'error',
    Exclamationmark = 'exclamationmark',
    Explore = 'explore',
    Explorenavicon = 'explorenavicon',
    Familymemberplaceholder = 'familymemberplaceholder',
    Feedingtracker = 'feedingtracker',
    Feedingtrackerfilled = 'feedingtrackerfilled',
    Filter = 'filter',
    Filterhighlighted = 'filterhighlighted',
    Flag = 'flag',
    Flower = 'flower',
    Folder = 'folder',
    Folderfilled = 'folderfilled',
    Formatlistbulleted = 'formatlistbulleted',
    Gallery = 'gallery',
    Gear = 'gear',
    Growth = 'growth',
    Growthtracker = 'growthtracker',
    Growthtrackerfilled = 'growthtrackerfilled',
    Hamburger = 'hamburger',
    Healthkit = 'healthkit',
    Healthsummary = 'healthsummary',
    Heartoutline = 'heartoutline',
    Heighttrackerfilled = 'heighttrackerfilled',
    Home = 'home',
    Info = 'info',
    Invitationfilled = 'invitationfilled',
    Keyboardarrowdown = 'keyboardarrowdown',
    Keyboardarrowleft = 'keyboardarrowleft',
    Keyboardarrowright = 'keyboardarrowright',
    Keyboardarrowup = 'keyboardarrowup',
    Kickcounter = 'kickcounter',
    Kicks = 'kicks',
    Language = 'language',
    Learnlibrary = 'learnlibrary',
    Learnlibraryfilled = 'learnlibraryfilled',
    Lifehacks = 'lifehacks',
    Logout = 'logout',
    Logoutfilled = 'logoutfilled',
    Mail = 'mail',
    Mailfilled = 'mailfilled',
    Manageinvitations = 'manageinvitations',
    Markednote = 'markednote',
    Maternalmentalsurvey = 'maternalmentalsurvey',
    Messagecenter = 'messagecenter',
    Milestones = 'milestones',
    Milestonesfilled = 'milestonesfilled',
    Moodboost = 'moodboost',
    Moodboostfilled = 'moodboostfilled',
    More = 'more',
    Morenavicon = 'morenavicon',
    Moretoolsfilled = 'moretoolsfilled',
    Movehint = 'movehint',
    Movement = 'movement',
    Movementtracker = 'movementtracker',
    Movementtrackerfilled = 'movementtrackerfilled',
    Newspaper = 'newspaper',
    None = 'none',
    Note = 'note',
    Noteadd = 'noteadd',
    Notfound = 'notfound',
    Ovulationnegative = 'ovulationnegative',
    Ovulationnegativecircled = 'ovulationnegativecircled',
    Ovulationnegativecircledfilled = 'ovulationnegativecircledfilled',
    Ovulationnosex = 'ovulationnosex',
    Ovulationnosexcircled = 'ovulationnosexcircled',
    Ovulationnosexcircledfilled = 'ovulationnosexcircledfilled',
    Ovulationnotest = 'ovulationnotest',
    Ovulationnotestcircled = 'ovulationnotestcircled',
    Ovulationnotestcircledfilled = 'ovulationnotestcircledfilled',
    Ovulationpositive = 'ovulationpositive',
    Ovulationpositivecircled = 'ovulationpositivecircled',
    Ovulationpositivecircledfilled = 'ovulationpositivecircledfilled',
    Ovulationprotectedsex = 'ovulationprotectedsex',
    Ovulationprotectedsexcircled = 'ovulationprotectedsexcircled',
    Ovulationprotectedsexcircledfilled = 'ovulationprotectedsexcircledfilled',
    Ovulationtracker = 'ovulationtracker',
    Ovulationtrackerfilled = 'ovulationtrackerfilled',
    Ovulationunprotectedsex = 'ovulationunprotectedsex',
    Ovulationunprotectedsexcircled = 'ovulationunprotectedsexcircled',
    Ovulationunprotectedsexcircledfilled = 'ovulationunprotectedsexcircledfilled',
    Parenting101 = 'parenting101',
    Partnercontent = 'partnercontent',
    Partnercontentfilled = 'partnercontentfilled',
    Partners = 'partners',
    Password = 'password',
    Pause = 'pause',
    Play = 'play',
    Poorconnection = 'poorconnection',
    Postpartum = 'postpartum',
    Prefferedlanguage = 'prefferedlanguage',
    Pregnancy = 'pregnancy',
    Pregnancyweighttrackerfilled = 'pregnancyweighttrackerfilled',
    Privacy = 'privacy',
    Profilesmile = 'profilesmile',
    Providers = 'providers',
    Providersfilled = 'providersfilled',
    Pulse = 'pulse',
    Qlist = 'qlist',
    Qlistfilled = 'qlistfilled',
    Radiobuttonchecked = 'radiobuttonchecked',
    Radiobuttonunchecked = 'radiobuttonunchecked',
    Refresh = 'refresh',
    Reminder = 'reminder',
    Reminders = 'reminders',
    Resources = 'resources',
    Resourcesnavicon = 'resourcesnavicon',
    Search = 'search',
    Searchfilled = 'searchfilled',
    Selectedstate = 'selectedstate',
    Sendusemail = 'sendusemail',
    Services = 'services',
    Settings = 'settings',
    Settingsfilled = 'settingsfilled',
    Share = 'share',
    Shield = 'shield',
    Shieldfilled = 'shieldfilled',
    Smilefilled = 'smilefilled',
    Splashscreencheck = 'splashscreencheck',
    Star = 'star',
    Step = 'step',
    Stepoutline = 'stepoutline',
    Supportfilled = 'supportfilled',
    Surveys = 'surveys',
    Surveysfilled = 'surveysfilled',
    Tablet = 'tablet',
    Termsofuse = 'termsofuse',
    Time = 'time',
    Timefilled = 'timefilled',
    Todo = 'todo',
    Todofilled = 'todofilled',
    Tools = 'tools',
    Trackers = 'trackers',
    Trackersnavicon = 'trackersnavicon',
    Upcomingappointements = 'upcomingappointements',
    Vaccinationtracker = 'vaccinationtracker',
    Vaccinationtrackerfilled = 'vaccinationtrackerfilled',
    Versions = 'versions',
    Visibility = 'visibility',
    Visibilityoff = 'visibilityoff',
    Warning = 'warning',
    Weeklyanddaily = 'weeklyanddaily',
    Weeklynewborn = 'weeklynewborn',
    Weeklypregnant = 'weeklypregnant',
    Weeklypregnantfilled = 'weeklypregnantfilled',
    Weeklyupdates = 'weeklyupdates',
    Weighttracker = 'weighttracker',
    Weighttrackerfilled = 'weighttrackerfilled',
    Whotocall = 'whotocall',
    Worklife = 'worklife',
    Zipcode = 'zipcode',
}

export type ContentPickerFetchInput = {
    searchTerm?: InputMaybe<Scalars['String']>;
    types?: InputMaybe<Array<InputMaybe<ContentType>>>;
};

export type ContentPickerFetchResponse = {
    __typename?: 'ContentPickerFetchResponse';
    data?: Maybe<Array<Maybe<SimpleContent>>>;
    total?: Maybe<Scalars['Float']>;
};

/** Status built content */
export enum ContentStatus {
    Building = 'building',
    None = 'none',
    Processed = 'processed',
}

/** Simple Content Type. */
export enum ContentType {
    AddDependentProfile = 'addDependentProfile',
    AppointmentHistory = 'appointmentHistory',
    ArticleLink = 'articleLink',
    BabyBoostLink = 'babyBoostLink',
    CareTeam = 'careTeam',
    ContentDirectoryLink = 'contentDirectoryLink',
    DueDateCalculator = 'dueDateCalculator',
    EligibilityCheckLink = 'eligibilityCheckLink',
    ExploreScreen = 'exploreScreen',
    ExternalLink = 'externalLink',
    Highlights = 'highlights',
    Image = 'image',
    LearnLibrary = 'learnLibrary',
    MessageCenter = 'messageCenter',
    Milestones = 'milestones',
    PhoneNumber = 'phoneNumber',
    QListLink = 'qListLink',
    Resources = 'resources',
    SurveyLink = 'surveyLink',
    Surveys = 'surveys',
    Todolist = 'todolist',
    Tracker = 'tracker',
    TrackerView = 'trackerView',
    UserBio = 'userBio',
    Video = 'video',
    WeekByWeek = 'weekByWeek',
    WhoToCall = 'whoToCall',
}

export type ContentVariant = {
    __typename?: 'ContentVariant';
    appBundleId?: Maybe<Scalars['String']>;
    body?: Maybe<Array<MarkupElement>>;
    bodyMarkup?: Maybe<Scalars['String']>;
    callToActions?: Maybe<Array<CallToAction>>;
    dateEdited?: Maybe<Scalars['DateTime']>;
    datePublished?: Maybe<Scalars['DateTime']>;
    editedBy?: Maybe<Scalars['String']>;
    imageContentId?: Maybe<Scalars['ObjectId']>;
    language: Language;
    readingLevel: ReadingLevel;
    simpleContentRefs?: Maybe<Array<SimpleContentRef>>;
    summary?: Maybe<Array<MarkupElement>>;
    summaryMarkup?: Maybe<Scalars['String']>;
    title?: Maybe<Array<MarkupElement>>;
    titleMarkup?: Maybe<Scalars['String']>;
};

export type ContentVariantInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    body?: InputMaybe<Array<MarkupElementInput>>;
    bodyMarkup?: InputMaybe<Scalars['String']>;
    callToActions?: InputMaybe<Array<CallToActionInput>>;
    dateEdited?: InputMaybe<Scalars['DateTime']>;
    datePublished?: InputMaybe<Scalars['DateTime']>;
    editedBy?: InputMaybe<Scalars['String']>;
    imageContentId?: InputMaybe<Scalars['ObjectId']>;
    language: Language;
    readingLevel: ReadingLevel;
    simpleContentRefs?: InputMaybe<Array<SimpleContentRefInput>>;
    summary?: InputMaybe<Array<MarkupElementInput>>;
    summaryMarkup?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Array<MarkupElementInput>>;
    titleMarkup?: InputMaybe<Scalars['String']>;
};

export type ContentWhereUsedFetchInput = {
    id?: InputMaybe<Scalars['String']>;
    isImage?: InputMaybe<Scalars['Boolean']>;
};

export type CountAppUsersInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
};

export type CreatManyClaimsByPatientIdsResponse = MutationResponse & {
    __typename?: 'CreatManyClaimsByPatientIdsResponse';
    message: Scalars['String'];
    meta?: Maybe<CreateClaimsMeta>;
    resourcesCreated: Array<CreateSubmittedClaimViaFileResponse>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateAdvocateNotificationResponse = MutationResponse & {
    __typename?: 'CreateAdvocateNotificationResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AdvocateNotification>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateAdvocateTaskResponse = MutationResponse & {
    __typename?: 'CreateAdvocateTaskResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AdvocateTask>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateAdvocateTaskTemplateEncodedResponse = MutationResponse & {
    __typename?: 'CreateAdvocateTaskTemplateEncodedResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AdvocateTaskTemplate>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateAdvocateTaskTemplateResponse = MutationResponse & {
    __typename?: 'CreateAdvocateTaskTemplateResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AdvocateTaskTemplate>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateAdvocateTaskTypeResponse = MutationResponse & {
    __typename?: 'CreateAdvocateTaskTypeResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AdvocateTaskType>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateAffiliateInvoiceResponse = MutationResponse & {
    __typename?: 'CreateAffiliateInvoiceResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AffiliateInvoice>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateAppUserArticleResponse = MutationResponse & {
    __typename?: 'CreateAppUserArticleResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AppUserArticle>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateAppUserEventTriggerResponse = MutationResponse & {
    __typename?: 'CreateAppUserEventTriggerResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AppUserEventTrigger>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateAppUserForPatientInput = {
    appBundleId: Scalars['String'];
    birthDate: Scalars['String'];
    email: Scalars['String'];
    firstName: Scalars['String'];
    isCreatedByPortal?: InputMaybe<Scalars['Boolean']>;
    isTestData?: InputMaybe<Scalars['Boolean']>;
    lastName: Scalars['String'];
};

export type CreateAppUserForPatientResponse = {
    __typename?: 'CreateAppUserForPatientResponse';
    appUserId?: Maybe<Scalars['String']>;
    patientId?: Maybe<Scalars['String']>;
};

export type CreateAppUserForVcPatientInput = {
    patientId?: InputMaybe<Scalars['String']>;
};

export type CreateAppUserForVcPatientResponse = MutationResponse & {
    __typename?: 'CreateAppUserForVCPatientResponse';
    data?: Maybe<AppUser>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateAppUserInput = {
    appBundleId: Scalars['String'];
    birthDate: Scalars['String'];
    ehrIds?: InputMaybe<Array<InputMaybe<EhrIdsInput>>>;
    ehrIntegration?: InputMaybe<Scalars['String']>;
    email: Scalars['String'];
    externalId?: InputMaybe<Scalars['String']>;
    firstName: Scalars['String'];
    gender?: InputMaybe<Scalars['String']>;
    groupNumber?: InputMaybe<Scalars['String']>;
    healthPlan?: InputMaybe<Scalars['String']>;
    insuranceId?: InputMaybe<Scalars['String']>;
    insuranceName?: InputMaybe<Scalars['String']>;
    insuredAddress?: InputMaybe<AddressInput>;
    insuredFirstName?: InputMaybe<Scalars['String']>;
    insuredLastName?: InputMaybe<Scalars['String']>;
    insuredPhoneNumber?: InputMaybe<Scalars['String']>;
    invitationMode?: InputMaybe<PatientInvitation>;
    isCreatedByPortal?: InputMaybe<Scalars['Boolean']>;
    isTestData?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Language>;
    lastName: Scalars['String'];
    lob?: InputMaybe<Scalars['String']>;
    mailingAddress?: InputMaybe<AddressInput>;
    memberId?: InputMaybe<Scalars['String']>;
    passwordSalt?: InputMaybe<Scalars['String']>;
    patientBuildList?: InputMaybe<PatientBuildListInput>;
    patientCreatedByExternalDataSource?: InputMaybe<Scalars['Boolean']>;
    patientRelationshipToInsured?: InputMaybe<Scalars['String']>;
    phoneNumber?: InputMaybe<Scalars['String']>;
    race?: InputMaybe<Scalars['String']>;
    redoxEhrId?: InputMaybe<Scalars['String']>;
    redoxSourceId?: InputMaybe<Scalars['String']>;
};

export type CreateAppUserMutationResponse = MutationResponse & {
    __typename?: 'CreateAppUserMutationResponse';
    data?: Maybe<AppUserResponseData>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateAppUserResponse = MutationResponse & {
    __typename?: 'CreateAppUserResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AppUser>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateAppUserSsoInput = {
    appBundleId: Scalars['String'];
    birthDate: Scalars['String'];
    ehrIds?: InputMaybe<Array<InputMaybe<EhrIdsInput>>>;
    ehrIntegration?: InputMaybe<Scalars['String']>;
    email: Scalars['String'];
    externalId?: InputMaybe<Scalars['String']>;
    firstName: Scalars['String'];
    gender?: InputMaybe<Scalars['String']>;
    groupNumber?: InputMaybe<Scalars['String']>;
    healthPlan?: InputMaybe<Scalars['String']>;
    insuranceId?: InputMaybe<Scalars['String']>;
    insuranceName?: InputMaybe<Scalars['String']>;
    insuredAddress?: InputMaybe<AddressInput>;
    insuredFirstName?: InputMaybe<Scalars['String']>;
    insuredLastName?: InputMaybe<Scalars['String']>;
    insuredPhoneNumber?: InputMaybe<Scalars['String']>;
    invitationMode?: InputMaybe<PatientInvitation>;
    isCreatedByPortal?: InputMaybe<Scalars['Boolean']>;
    isTestData?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Language>;
    lastName: Scalars['String'];
    lob?: InputMaybe<Scalars['String']>;
    mailingAddress?: InputMaybe<AddressInput>;
    memberId?: InputMaybe<Scalars['String']>;
    oAuthCode?: InputMaybe<Scalars['String']>;
    password: Scalars['String'];
    passwordSalt?: InputMaybe<Scalars['String']>;
    patientBuildList?: InputMaybe<PatientBuildListInput>;
    patientCreatedByExternalDataSource?: InputMaybe<Scalars['Boolean']>;
    patientRelationshipToInsured?: InputMaybe<Scalars['String']>;
    phoneNumber?: InputMaybe<Scalars['String']>;
    race?: InputMaybe<Scalars['String']>;
    redoxEhrId?: InputMaybe<Scalars['String']>;
    redoxSourceId?: InputMaybe<Scalars['String']>;
};

export type CreateAppUserTelemetryResponse = MutationResponse & {
    __typename?: 'CreateAppUserTelemetryResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AppUserTelemetry>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateAppUserTrendTriggerResponse = MutationResponse & {
    __typename?: 'CreateAppUserTrendTriggerResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AppUserTrendTrigger>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateApplicationResponse = MutationResponse & {
    __typename?: 'CreateApplicationResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Application>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateAppointmentResponse = MutationResponse & {
    __typename?: 'CreateAppointmentResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Appointment>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateArticleAppApprovalsInput = {
    apps: Array<Scalars['String']>;
    articleId: Scalars['String'];
    language: Language;
    readingLevel: ReadingLevel;
};

export type CreateArticleAppApprovalsResponse = MutationResponse & {
    __typename?: 'CreateArticleAppApprovalsResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateArticleApprovalResponse = MutationResponse & {
    __typename?: 'CreateArticleApprovalResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ArticleApproval>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateArticleCacheResponse = MutationResponse & {
    __typename?: 'CreateArticleCacheResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ArticleCache>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateArticleCategoryResponse = MutationResponse & {
    __typename?: 'CreateArticleCategoryResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ArticleCategory>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateArticleEncodedResponse = MutationResponse & {
    __typename?: 'CreateArticleEncodedResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Article>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateArticleFeedResponse = MutationResponse & {
    __typename?: 'CreateArticleFeedResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ArticleFeed>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateArticleGroupResponse = MutationResponse & {
    __typename?: 'CreateArticleGroupResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ArticleGroup>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateArticlePromotionResponse = MutationResponse & {
    __typename?: 'CreateArticlePromotionResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ArticlePromotion>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateArticleResponse = MutationResponse & {
    __typename?: 'CreateArticleResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Article>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateAsyncJobResponse = MutationResponse & {
    __typename?: 'CreateAsyncJobResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AsyncJob>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateBatchTaskResponse = MutationResponse & {
    __typename?: 'CreateBatchTaskResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<BatchTask>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateBioScreenResponse = MutationResponse & {
    __typename?: 'CreateBioScreenResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<BioScreen>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateCalendarResponse = MutationResponse & {
    __typename?: 'CreateCalendarResponse';
    message: Scalars['String'];
    result?: Maybe<Calendar>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateCarePlanResponse = MutationResponse & {
    __typename?: 'CreateCarePlanResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<CarePlan>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateCareTeamContactResponse = MutationResponse & {
    __typename?: 'CreateCareTeamContactResponse';
    careTeamContact?: Maybe<CareTeamContact>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateCareTeamMemberResponse = MutationResponse & {
    __typename?: 'CreateCareTeamMemberResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<CareTeamMember>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateCareTeamMemberTypeResponse = MutationResponse & {
    __typename?: 'CreateCareTeamMemberTypeResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<CareTeamMemberType>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateCareTeamResponse = MutationResponse & {
    __typename?: 'CreateCareTeamResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<CareTeam>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateChatConversationResponse = MutationResponse & {
    __typename?: 'CreateChatConversationResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ChatConversation>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateChatMessageForAppUserInput = {
    attachments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    bundleId?: InputMaybe<Scalars['String']>;
    careTeamMemberTypeId?: InputMaybe<Scalars['String']>;
    text?: InputMaybe<Scalars['String']>;
};

export type CreateChatMessageForAppUserResponse = MutationResponse & {
    __typename?: 'CreateChatMessageForAppUserResponse';
    chatMessage?: Maybe<ChatMessage>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateChatMessageResponse = MutationResponse & {
    __typename?: 'CreateChatMessageResponse';
    chatMessage?: Maybe<ChatMessage>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateChatRoomResponse = MutationResponse & {
    __typename?: 'CreateChatRoomResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ChatRoom>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateClaimsMeta = {
    __typename?: 'CreateClaimsMeta';
    handledPatientsId?: Maybe<Array<Maybe<Scalars['String']>>>;
    message?: Maybe<Scalars['String']>;
};

export type CreateClinicalSummaryResponse = MutationResponse & {
    __typename?: 'CreateClinicalSummaryResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ClinicalSummary>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateCompoundQuestionResponse = MutationResponse & {
    __typename?: 'CreateCompoundQuestionResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<CompoundQuestion>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateConditionResponse = MutationResponse & {
    __typename?: 'CreateConditionResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Condition>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateConditionTypeResponse = MutationResponse & {
    __typename?: 'CreateConditionTypeResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ConditionType>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateConfigExpressionResponse = MutationResponse & {
    __typename?: 'CreateConfigExpressionResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ConfigExpression>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateConfigValueDefResponse = MutationResponse & {
    __typename?: 'CreateConfigValueDefResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ConfigValueDef>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateConfigValueResponse = MutationResponse & {
    __typename?: 'CreateConfigValueResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ConfigValue>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateContentDirectoryResponse = MutationResponse & {
    __typename?: 'CreateContentDirectoryResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ContentDirectory>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateDateTriggerResponse = MutationResponse & {
    __typename?: 'CreateDateTriggerResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<DateTrigger>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateDependentUserInput = {
    birthDate: Scalars['String'];
    firstName: Scalars['String'];
    isTestData?: InputMaybe<Scalars['Boolean']>;
    lastName: Scalars['String'];
};

export type CreateDependentUserMutationResponse = MutationResponse & {
    __typename?: 'CreateDependentUserMutationResponse';
    data?: Maybe<AppUserResponseData>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateDeviceResponse = MutationResponse & {
    __typename?: 'CreateDeviceResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Device>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateDirectoryCacheResponse = MutationResponse & {
    __typename?: 'CreateDirectoryCacheResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<DirectoryCache>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateEligibilityMatchResponse = MutationResponse & {
    __typename?: 'CreateEligibilityMatchResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<EligibilityMatch>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateEpisodeResponse = MutationResponse & {
    __typename?: 'CreateEpisodeResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Episode>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateEventTriggerResponse = MutationResponse & {
    __typename?: 'CreateEventTriggerResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<EventTrigger>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateHealthPlanResponse = MutationResponse & {
    __typename?: 'CreateHealthPlanResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<HealthPlan>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateHighlightFeedResponse = MutationResponse & {
    __typename?: 'CreateHighlightFeedResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<HighlightFeed>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateHighlightResponse = MutationResponse & {
    __typename?: 'CreateHighlightResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Highlight>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateInterventionResponse = MutationResponse & {
    __typename?: 'CreateInterventionResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Intervention>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyAdvocateNotificationsInput = {
    data: Array<AdvocateNotificationInput>;
};

export type CreateManyAdvocateNotificationsResponse = MutationResponse & {
    __typename?: 'CreateManyAdvocateNotificationsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualAdvocateNotificationResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyAdvocateTaskTemplatesInput = {
    data: Array<AdvocateTaskTemplateInput>;
};

export type CreateManyAdvocateTaskTemplatesResponse = MutationResponse & {
    __typename?: 'CreateManyAdvocateTaskTemplatesResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualAdvocateTaskTemplateResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyAdvocateTaskTypesInput = {
    data: Array<AdvocateTaskTypeInput>;
};

export type CreateManyAdvocateTaskTypesResponse = MutationResponse & {
    __typename?: 'CreateManyAdvocateTaskTypesResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualAdvocateTaskTypeResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyAdvocateTasksInput = {
    data: Array<AdvocateTaskInput>;
};

export type CreateManyAdvocateTasksResponse = MutationResponse & {
    __typename?: 'CreateManyAdvocateTasksResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualAdvocateTaskResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyAffiliateClaimsInput = {
    data: Array<AffiliateClaimInput>;
};

export type CreateManyAffiliateClaimsResponse = MutationResponse & {
    __typename?: 'CreateManyAffiliateClaimsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualAffiliateClaimResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyAffiliateInvoicesInput = {
    data: Array<AffiliateInvoiceInput>;
};

export type CreateManyAffiliateInvoicesResponse = MutationResponse & {
    __typename?: 'CreateManyAffiliateInvoicesResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualAffiliateInvoiceResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyAppUserArticlesInput = {
    data: Array<AppUserArticleInput>;
};

export type CreateManyAppUserArticlesResponse = MutationResponse & {
    __typename?: 'CreateManyAppUserArticlesResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualAppUserArticleResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyAppUserEventTriggersInput = {
    data: Array<AppUserEventTriggerInput>;
};

export type CreateManyAppUserEventTriggersResponse = MutationResponse & {
    __typename?: 'CreateManyAppUserEventTriggersResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualAppUserEventTriggerResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyAppUserTelemetrysInput = {
    data: Array<AppUserTelemetryInput>;
};

export type CreateManyAppUserTelemetrysResponse = MutationResponse & {
    __typename?: 'CreateManyAppUserTelemetrysResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualAppUserTelemetryResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyAppUserTrendTriggersInput = {
    data: Array<AppUserTrendTriggerInput>;
};

export type CreateManyAppUserTrendTriggersResponse = MutationResponse & {
    __typename?: 'CreateManyAppUserTrendTriggersResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualAppUserTrendTriggerResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyAppUsersInput = {
    data: Array<AppUserRecordInput>;
};

export type CreateManyAppUsersResponse = MutationResponse & {
    __typename?: 'CreateManyAppUsersResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualAppUserResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyApplicationsInput = {
    data: Array<ApplicationInput>;
};

export type CreateManyApplicationsResponse = MutationResponse & {
    __typename?: 'CreateManyApplicationsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualApplicationResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyAppointmentsInput = {
    data: Array<AppointmentInput>;
};

export type CreateManyAppointmentsResponse = MutationResponse & {
    __typename?: 'CreateManyAppointmentsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualAppointmentResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyArticleApprovalsInput = {
    data: Array<ArticleApprovalInput>;
};

export type CreateManyArticleApprovalsResponse = MutationResponse & {
    __typename?: 'CreateManyArticleApprovalsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualArticleApprovalResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyArticleCachesInput = {
    data: Array<ArticleCacheInput>;
};

export type CreateManyArticleCachesResponse = MutationResponse & {
    __typename?: 'CreateManyArticleCachesResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualArticleCacheResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyArticleCategorysInput = {
    data: Array<ArticleCategoryInput>;
};

export type CreateManyArticleCategorysResponse = MutationResponse & {
    __typename?: 'CreateManyArticleCategorysResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualArticleCategoryResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyArticleFeedsInput = {
    data: Array<ArticleFeedInput>;
};

export type CreateManyArticleFeedsResponse = MutationResponse & {
    __typename?: 'CreateManyArticleFeedsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualArticleFeedResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyArticleGroupsInput = {
    data: Array<ArticleGroupInput>;
};

export type CreateManyArticleGroupsResponse = MutationResponse & {
    __typename?: 'CreateManyArticleGroupsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualArticleGroupResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyArticlePromotionsInput = {
    data: Array<ArticlePromotionInput>;
};

export type CreateManyArticlePromotionsResponse = MutationResponse & {
    __typename?: 'CreateManyArticlePromotionsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualArticlePromotionResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyArticlesInput = {
    data: Array<ArticleInput>;
};

export type CreateManyArticlesResponse = MutationResponse & {
    __typename?: 'CreateManyArticlesResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualArticleResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyAsyncJobsInput = {
    data: Array<AsyncJobInput>;
};

export type CreateManyAsyncJobsResponse = MutationResponse & {
    __typename?: 'CreateManyAsyncJobsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualAsyncJobResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyBatchTasksInput = {
    data: Array<BatchTaskInput>;
};

export type CreateManyBatchTasksResponse = MutationResponse & {
    __typename?: 'CreateManyBatchTasksResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualBatchTaskResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyBioScreensInput = {
    data: Array<BioScreenInput>;
};

export type CreateManyBioScreensResponse = MutationResponse & {
    __typename?: 'CreateManyBioScreensResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualBioScreenResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyCalendarsInput = {
    data: Array<CalendarInput>;
};

export type CreateManyCalendarsResponse = MutationResponse & {
    __typename?: 'CreateManyCalendarsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualCalendarResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyCarePlansInput = {
    data: Array<CarePlanInput>;
};

export type CreateManyCarePlansResponse = MutationResponse & {
    __typename?: 'CreateManyCarePlansResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualCarePlanResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyCareTeamContactsInput = {
    data: Array<CareTeamContactInput>;
};

export type CreateManyCareTeamContactsResponse = MutationResponse & {
    __typename?: 'CreateManyCareTeamContactsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualCareTeamContactResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyCareTeamMemberTypesInput = {
    data: Array<CareTeamMemberTypeInput>;
};

export type CreateManyCareTeamMemberTypesResponse = MutationResponse & {
    __typename?: 'CreateManyCareTeamMemberTypesResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualCareTeamMemberTypeResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyCareTeamMembersInput = {
    data: Array<CareTeamMemberInput>;
};

export type CreateManyCareTeamMembersResponse = MutationResponse & {
    __typename?: 'CreateManyCareTeamMembersResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualCareTeamMemberResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyCareTeamsInput = {
    data: Array<CareTeamInput>;
};

export type CreateManyCareTeamsResponse = MutationResponse & {
    __typename?: 'CreateManyCareTeamsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualCareTeamResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyChatConversationsInput = {
    data: Array<ChatConversationInput>;
};

export type CreateManyChatConversationsResponse = MutationResponse & {
    __typename?: 'CreateManyChatConversationsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualChatConversationResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyChatMessagesInput = {
    data: Array<ChatMessageInput>;
};

export type CreateManyChatMessagesResponse = MutationResponse & {
    __typename?: 'CreateManyChatMessagesResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualChatMessageResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyChatRoomsInput = {
    data: Array<ChatRoomInput>;
};

export type CreateManyChatRoomsResponse = MutationResponse & {
    __typename?: 'CreateManyChatRoomsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualChatRoomResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyClaimSubmittedIndividual = {
    __typename?: 'CreateManyClaimSubmittedIndividual';
    ClaimRef?: Maybe<Scalars['String']>;
    PatFName?: Maybe<Scalars['String']>;
    PatLName?: Maybe<Scalars['String']>;
    ServiceFrom_1?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['String']>;
};

export type CreateManyClaimsByPatientIdsInput = {
    data: Array<ClaimCreatePatientInput>;
};

export type CreateManyClinicalSummarysInput = {
    data: Array<ClinicalSummaryInput>;
};

export type CreateManyClinicalSummarysResponse = MutationResponse & {
    __typename?: 'CreateManyClinicalSummarysResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualClinicalSummaryResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyCompoundQuestionsInput = {
    data: Array<CompoundQuestionInput>;
};

export type CreateManyCompoundQuestionsResponse = MutationResponse & {
    __typename?: 'CreateManyCompoundQuestionsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualCompoundQuestionResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyConditionTypesInput = {
    data: Array<ConditionTypeInput>;
};

export type CreateManyConditionTypesResponse = MutationResponse & {
    __typename?: 'CreateManyConditionTypesResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualConditionTypeResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyConditionsInput = {
    data: Array<ConditionInput>;
};

export type CreateManyConditionsResponse = MutationResponse & {
    __typename?: 'CreateManyConditionsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualConditionResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyConfigExpressionsInput = {
    data: Array<ConfigExpressionInput>;
};

export type CreateManyConfigExpressionsResponse = MutationResponse & {
    __typename?: 'CreateManyConfigExpressionsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualConfigExpressionResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyConfigValueDefsInput = {
    data: Array<ConfigValueDefInput>;
};

export type CreateManyConfigValueDefsResponse = MutationResponse & {
    __typename?: 'CreateManyConfigValueDefsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualConfigValueDefResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyConfigValuesInput = {
    data: Array<ConfigValueInput>;
};

export type CreateManyConfigValuesResponse = MutationResponse & {
    __typename?: 'CreateManyConfigValuesResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualConfigValueResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyContentDirectorysInput = {
    data: Array<ContentDirectoryInput>;
};

export type CreateManyContentDirectorysResponse = MutationResponse & {
    __typename?: 'CreateManyContentDirectorysResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualContentDirectoryResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyDateTriggersInput = {
    data: Array<DateTriggerInput>;
};

export type CreateManyDateTriggersResponse = MutationResponse & {
    __typename?: 'CreateManyDateTriggersResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualDateTriggerResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyDevicesInput = {
    data: Array<DeviceInput>;
};

export type CreateManyDevicesResponse = MutationResponse & {
    __typename?: 'CreateManyDevicesResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualDeviceResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyDirectoryCachesInput = {
    data: Array<DirectoryCacheInput>;
};

export type CreateManyDirectoryCachesResponse = MutationResponse & {
    __typename?: 'CreateManyDirectoryCachesResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualDirectoryCacheResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyEligibilityMatchsInput = {
    data: Array<EligibilityMatchInput>;
};

export type CreateManyEligibilityMatchsResponse = MutationResponse & {
    __typename?: 'CreateManyEligibilityMatchsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualEligibilityMatchResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyEpisodesInput = {
    data: Array<EpisodeInput>;
};

export type CreateManyEpisodesResponse = MutationResponse & {
    __typename?: 'CreateManyEpisodesResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualEpisodeResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyEventTriggersInput = {
    data: Array<EventTriggerInput>;
};

export type CreateManyEventTriggersResponse = MutationResponse & {
    __typename?: 'CreateManyEventTriggersResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualEventTriggerResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyHealthPlansInput = {
    data: Array<HealthPlanInput>;
};

export type CreateManyHealthPlansResponse = MutationResponse & {
    __typename?: 'CreateManyHealthPlansResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualHealthPlanResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyHighlightFeedsInput = {
    data: Array<HighlightFeedUpdateInput>;
};

export type CreateManyHighlightFeedsResponse = MutationResponse & {
    __typename?: 'CreateManyHighlightFeedsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualHighlightFeedResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyHighlightsInput = {
    data: Array<HighlightInput>;
};

export type CreateManyHighlightsResponse = MutationResponse & {
    __typename?: 'CreateManyHighlightsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualHighlightResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualAdvocateNotificationResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualAdvocateNotificationResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AdvocateNotification>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualAdvocateTaskResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualAdvocateTaskResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AdvocateTask>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualAdvocateTaskTemplateResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualAdvocateTaskTemplateResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AdvocateTaskTemplate>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualAdvocateTaskTypeResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualAdvocateTaskTypeResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AdvocateTaskType>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualAffiliateClaimResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualAffiliateClaimResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AffiliateClaim>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualAffiliateInvoiceResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualAffiliateInvoiceResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AffiliateInvoice>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualAppUserArticleResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualAppUserArticleResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AppUserArticle>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualAppUserEventTriggerResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualAppUserEventTriggerResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AppUserEventTrigger>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualAppUserResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualAppUserResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AppUser>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualAppUserTelemetryResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualAppUserTelemetryResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AppUserTelemetry>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualAppUserTrendTriggerResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualAppUserTrendTriggerResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AppUserTrendTrigger>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualApplicationResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualApplicationResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Application>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualAppointmentResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualAppointmentResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Appointment>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualArticleApprovalResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualArticleApprovalResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ArticleApproval>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualArticleCacheResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualArticleCacheResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ArticleCache>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualArticleCategoryResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualArticleCategoryResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ArticleCategory>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualArticleFeedResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualArticleFeedResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ArticleFeed>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualArticleGroupResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualArticleGroupResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ArticleGroup>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualArticlePromotionResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualArticlePromotionResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ArticlePromotion>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualArticleResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualArticleResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Article>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualAsyncJobResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualAsyncJobResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<AsyncJob>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualBatchTaskResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualBatchTaskResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<BatchTask>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualBioScreenResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualBioScreenResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<BioScreen>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualCalendarResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualCalendarResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Calendar>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualCarePlanResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualCarePlanResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<CarePlan>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualCareTeamContactResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualCareTeamContactResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<CareTeamContact>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualCareTeamMemberResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualCareTeamMemberResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<CareTeamMember>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualCareTeamMemberTypeResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualCareTeamMemberTypeResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<CareTeamMemberType>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualCareTeamResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualCareTeamResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<CareTeam>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualChatConversationResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualChatConversationResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ChatConversation>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualChatMessageResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualChatMessageResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ChatMessage>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualChatRoomResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualChatRoomResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ChatRoom>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualClinicalSummaryResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualClinicalSummaryResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ClinicalSummary>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualCompoundQuestionResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualCompoundQuestionResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<CompoundQuestion>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualConditionResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualConditionResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Condition>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualConditionTypeResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualConditionTypeResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ConditionType>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualConfigExpressionResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualConfigExpressionResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ConfigExpression>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualConfigValueDefResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualConfigValueDefResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ConfigValueDef>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualConfigValueResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualConfigValueResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ConfigValue>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualContentDirectoryResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualContentDirectoryResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ContentDirectory>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualDateTriggerResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualDateTriggerResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<DateTrigger>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualDeviceResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualDeviceResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Device>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualDirectoryCacheResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualDirectoryCacheResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<DirectoryCache>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualEligibilityMatchResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualEligibilityMatchResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<EligibilityMatch>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualEpisodeResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualEpisodeResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Episode>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualEventTriggerResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualEventTriggerResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<EventTrigger>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualHealthPlanResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualHealthPlanResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<HealthPlan>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualHighlightFeedResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualHighlightFeedResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<HighlightFeed>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualHighlightResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualHighlightResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Highlight>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualInterventionResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualInterventionResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Intervention>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualMassUpdatePatientResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualMassUpdatePatientResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<MassUpdatePatients>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualMassUpdateTaskResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualMassUpdateTaskResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<MassUpdateTask>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualMeasurementResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualMeasurementResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Measurement>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualMessageCenterTemplateResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualMessageCenterTemplateResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<MessageCenterTemplate>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualMessageTemplateResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualMessageTemplateResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<MessageTemplate>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualOrderResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualOrderResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Order>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualOrganizationResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualOrganizationResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Organization>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualPatientActionResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualPatientActionResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<PatientAction>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualPatientActionTypeResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualPatientActionTypeResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<PatientActionType>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualPatientCallListResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualPatientCallListResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<PatientCallList>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualPatientHeaderResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualPatientHeaderResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<PatientHeader>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualPatientNoteTagResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualPatientNoteTagResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<PatientNoteTag>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualPatientNotesResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualPatientNotesResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<PatientNotes>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualPatientResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualPatientResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Patient>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualPotentialUserResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualPotentialUserResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<PotentialUser>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualProfileKeyResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualProfileKeyResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ProfileKey>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualProfileValueHistoryResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualProfileValueHistoryResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ProfileValueHistory>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualProfileValueOriginResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualProfileValueOriginResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ProfileValueOrigin>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualProfileValueResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualProfileValueResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ProfileValue>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualProviderResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualProviderResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Provider>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualQListResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualQListResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<QList>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualQuestionResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualQuestionResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Question>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualReferenceLinkCategoryResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualReferenceLinkCategoryResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ReferenceLinkCategory>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualReferenceLinkResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualReferenceLinkResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ReferenceLink>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualReportingCategoryResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualReportingCategoryResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ReportingCategory>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualReportingJobResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualReportingJobResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ReportingJob>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualRoleResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualRoleResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Role>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualSentMessageResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualSentMessageResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<SentMessage>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualSimpleContentResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualSimpleContentResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<SimpleContent>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualSlaResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualSlaResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Sla>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualSlaTimerResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualSlaTimerResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<SlaTimer>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualSubmittedClaimResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualSubmittedClaimResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ClaimSubmitted>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualSurveyDefResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualSurveyDefResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<SurveyDef>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualSurveyResponseResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualSurveyResponseResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<SurveyResponse>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualSystemTokenResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualSystemTokenResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<SystemToken>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualTagResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualTagResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Tag>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualTodoTaskResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualTodoTaskResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<TodoTask>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualTokenResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualTokenResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Token>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualTrackerHistoryResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualTrackerHistoryResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<TrackerHistory>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualTrackerResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualTrackerResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Tracker>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualTrendTriggerResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualTrendTriggerResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<TrendTrigger>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualUserProfileDefResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualUserProfileDefResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<UserProfileDef>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualUserResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualUserResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<User>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualVcZipCodeToLocationResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualVCZipCodeToLocationResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<VcZipCodeToLocation>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualVirtualCareAffiliatesResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualVirtualCareAffiliatesResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<VirtualCareAffiliatesEntity>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualVirtualCareEligibilityWhitelistResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualVirtualCareEligibilityWhitelistResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<VirtualCareEligibilityWhitelist>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualVirtualCareFormResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualVirtualCareFormResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<VirtualCareForm>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualWhatsNewTimelineResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualWhatsNewTimelineResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<WhatsNewTimeline>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyIndividualWhoToCallResponse = MutationResponse & {
    __typename?: 'CreateManyIndividualWhoToCallResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<WhoToCall>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyInterventionsInput = {
    data: Array<InterventionInput>;
};

export type CreateManyInterventionsResponse = MutationResponse & {
    __typename?: 'CreateManyInterventionsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualInterventionResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyMassUpdatePatientsInput = {
    data: Array<MassUpdatePatientsInput>;
};

export type CreateManyMassUpdatePatientsResponse = MutationResponse & {
    __typename?: 'CreateManyMassUpdatePatientsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualMassUpdatePatientResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyMassUpdateTasksInput = {
    data: Array<MassUpdateTaskInput>;
};

export type CreateManyMassUpdateTasksResponse = MutationResponse & {
    __typename?: 'CreateManyMassUpdateTasksResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualMassUpdateTaskResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyMeasurementsInput = {
    data: Array<MeasurementInput>;
};

export type CreateManyMeasurementsResponse = MutationResponse & {
    __typename?: 'CreateManyMeasurementsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualMeasurementResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyMessageCenterTemplatesInput = {
    data: Array<MessageCenterTemplateInput>;
};

export type CreateManyMessageCenterTemplatesResponse = MutationResponse & {
    __typename?: 'CreateManyMessageCenterTemplatesResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualMessageCenterTemplateResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyMessageTemplatesInput = {
    data: Array<MessageTemplateInput>;
};

export type CreateManyMessageTemplatesResponse = MutationResponse & {
    __typename?: 'CreateManyMessageTemplatesResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualMessageTemplateResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyOrdersInput = {
    data: Array<OrderInput>;
};

export type CreateManyOrdersResponse = MutationResponse & {
    __typename?: 'CreateManyOrdersResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualOrderResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyOrganizationsInput = {
    data: Array<OrganizationInput>;
};

export type CreateManyOrganizationsResponse = MutationResponse & {
    __typename?: 'CreateManyOrganizationsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualOrganizationResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyPatientActionTypesInput = {
    data: Array<PatientActionTypeInput>;
};

export type CreateManyPatientActionTypesResponse = MutationResponse & {
    __typename?: 'CreateManyPatientActionTypesResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualPatientActionTypeResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyPatientActionsInput = {
    data: Array<PatientActionInput>;
};

export type CreateManyPatientActionsResponse = MutationResponse & {
    __typename?: 'CreateManyPatientActionsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualPatientActionResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyPatientCallListsInput = {
    data: Array<PatientCallListInput>;
};

export type CreateManyPatientCallListsResponse = MutationResponse & {
    __typename?: 'CreateManyPatientCallListsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualPatientCallListResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyPatientHeadersInput = {
    data: Array<PatientHeaderInput>;
};

export type CreateManyPatientHeadersResponse = MutationResponse & {
    __typename?: 'CreateManyPatientHeadersResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualPatientHeaderResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyPatientNoteTagsInput = {
    data: Array<PatientNoteTagInput>;
};

export type CreateManyPatientNoteTagsResponse = MutationResponse & {
    __typename?: 'CreateManyPatientNoteTagsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualPatientNoteTagResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyPatientNotessInput = {
    data: Array<PatientNotesInput>;
};

export type CreateManyPatientNotessResponse = MutationResponse & {
    __typename?: 'CreateManyPatientNotessResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualPatientNotesResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyPatientsInput = {
    data: Array<PatientInput>;
};

export type CreateManyPatientsResponse = MutationResponse & {
    __typename?: 'CreateManyPatientsResponse';
    message: Scalars['String'];
    resourcesCreated: Array<CreateManyIndividualPatientResponse>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyPotentialUsersInput = {
    data: Array<PotentialUserInput>;
};

export type CreateManyPotentialUsersResponse = MutationResponse & {
    __typename?: 'CreateManyPotentialUsersResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualPotentialUserResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyProfileKeysInput = {
    data: Array<ProfileKeyInput>;
};

export type CreateManyProfileKeysResponse = MutationResponse & {
    __typename?: 'CreateManyProfileKeysResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualProfileKeyResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyProfileValueHistorysInput = {
    data: Array<ProfileValueHistoryInput>;
};

export type CreateManyProfileValueHistorysResponse = MutationResponse & {
    __typename?: 'CreateManyProfileValueHistorysResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualProfileValueHistoryResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyProfileValueOriginsInput = {
    data: Array<ProfileValueOriginInput>;
};

export type CreateManyProfileValueOriginsResponse = MutationResponse & {
    __typename?: 'CreateManyProfileValueOriginsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualProfileValueOriginResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyProfileValuesInput = {
    data: Array<ProfileValueInput>;
};

export type CreateManyProfileValuesResponse = MutationResponse & {
    __typename?: 'CreateManyProfileValuesResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualProfileValueResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyProvidersInput = {
    data: Array<ProviderInput>;
};

export type CreateManyProvidersResponse = MutationResponse & {
    __typename?: 'CreateManyProvidersResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualProviderResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyQListsInput = {
    data: Array<QListInput>;
};

export type CreateManyQListsResponse = MutationResponse & {
    __typename?: 'CreateManyQListsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualQListResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyQuestionsInput = {
    data: Array<QuestionInput>;
};

export type CreateManyQuestionsResponse = MutationResponse & {
    __typename?: 'CreateManyQuestionsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualQuestionResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyReferenceLinkCategorysInput = {
    data: Array<ReferenceLinkCategoryInput>;
};

export type CreateManyReferenceLinkCategorysResponse = MutationResponse & {
    __typename?: 'CreateManyReferenceLinkCategorysResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualReferenceLinkCategoryResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyReferenceLinksInput = {
    data: Array<ReferenceLinkInput>;
};

export type CreateManyReferenceLinksResponse = MutationResponse & {
    __typename?: 'CreateManyReferenceLinksResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualReferenceLinkResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyReportingCategorysInput = {
    data: Array<ReportingCategoryInput>;
};

export type CreateManyReportingCategorysResponse = MutationResponse & {
    __typename?: 'CreateManyReportingCategorysResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualReportingCategoryResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyReportingJobsInput = {
    data: Array<ReportingJobInput>;
};

export type CreateManyReportingJobsResponse = MutationResponse & {
    __typename?: 'CreateManyReportingJobsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualReportingJobResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyRolesInput = {
    data: Array<RoleInput>;
};

export type CreateManyRolesResponse = MutationResponse & {
    __typename?: 'CreateManyRolesResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualRoleResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManySentMessagesInput = {
    data: Array<SentMessageInput>;
};

export type CreateManySentMessagesResponse = MutationResponse & {
    __typename?: 'CreateManySentMessagesResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualSentMessageResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManySimpleContentsInput = {
    data: Array<SimpleContentInput>;
};

export type CreateManySimpleContentsResponse = MutationResponse & {
    __typename?: 'CreateManySimpleContentsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualSimpleContentResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManySlaTimersInput = {
    data: Array<SlaTimerInput>;
};

export type CreateManySlaTimersResponse = MutationResponse & {
    __typename?: 'CreateManySlaTimersResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualSlaTimerResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManySlasInput = {
    data: Array<SlaInput>;
};

export type CreateManySlasResponse = MutationResponse & {
    __typename?: 'CreateManySlasResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualSlaResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManySubmittedClaimsInput = {
    data: Array<ClaimSubmittedInput>;
};

export type CreateManySubmittedClaimsResponse = MutationResponse & {
    __typename?: 'CreateManySubmittedClaimsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualSubmittedClaimResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManySubmittedClaimsViaFileInput = {
    file?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    organizationId?: InputMaybe<Scalars['String']>;
};

export type CreateManySubmittedClaimsViaFileResponse = MutationResponse & {
    __typename?: 'CreateManySubmittedClaimsViaFileResponse';
    message: Scalars['String'];
    resourcesCreated: Array<CreateSubmittedClaimViaFileResponse>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManySurveyDefsInput = {
    data: Array<SurveyDefInput>;
};

export type CreateManySurveyDefsResponse = MutationResponse & {
    __typename?: 'CreateManySurveyDefsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualSurveyDefResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManySurveyResponsesInput = {
    data: Array<SurveyResponseInput>;
};

export type CreateManySurveyResponsesResponse = MutationResponse & {
    __typename?: 'CreateManySurveyResponsesResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualSurveyResponseResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManySystemTokensInput = {
    data: Array<SystemTokenInput>;
};

export type CreateManySystemTokensResponse = MutationResponse & {
    __typename?: 'CreateManySystemTokensResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualSystemTokenResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyTagsInput = {
    data: Array<TagInput>;
};

export type CreateManyTagsResponse = MutationResponse & {
    __typename?: 'CreateManyTagsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualTagResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyTodoTasksInput = {
    data: Array<TodoTaskInput>;
};

export type CreateManyTodoTasksResponse = MutationResponse & {
    __typename?: 'CreateManyTodoTasksResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualTodoTaskResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyTokensInput = {
    data: Array<TokenInput>;
};

export type CreateManyTokensResponse = MutationResponse & {
    __typename?: 'CreateManyTokensResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualTokenResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyTrackerHistorysInput = {
    data: Array<TrackerHistoryInput>;
};

export type CreateManyTrackerHistorysResponse = MutationResponse & {
    __typename?: 'CreateManyTrackerHistorysResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualTrackerHistoryResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyTrackersInput = {
    data: Array<TrackerInput>;
};

export type CreateManyTrackersResponse = MutationResponse & {
    __typename?: 'CreateManyTrackersResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualTrackerResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyTrendTriggersInput = {
    data: Array<TrendTriggerInput>;
};

export type CreateManyTrendTriggersResponse = MutationResponse & {
    __typename?: 'CreateManyTrendTriggersResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualTrendTriggerResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyUserProfileDefsInput = {
    data: Array<UserProfileDefInput>;
};

export type CreateManyUserProfileDefsResponse = MutationResponse & {
    __typename?: 'CreateManyUserProfileDefsResponse';
    message: Scalars['String'];
    resourcesCreated: Array<CreateManyIndividualUserProfileDefResponse>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyUsersInput = {
    data: Array<UserInput>;
};

export type CreateManyUsersResponse = MutationResponse & {
    __typename?: 'CreateManyUsersResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualUserResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyVcZipCodeToLocationsInput = {
    data: Array<AddVcZipCodeToLocationInput>;
};

export type CreateManyVcZipCodeToLocationsResponse = MutationResponse & {
    __typename?: 'CreateManyVCZipCodeToLocationsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualVcZipCodeToLocationResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyVirtualCareAffiliatessInput = {
    data: Array<CreateVirtualCareAffiliatesInput>;
};

export type CreateManyVirtualCareAffiliatessResponse = MutationResponse & {
    __typename?: 'CreateManyVirtualCareAffiliatessResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualVirtualCareAffiliatesResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyVirtualCareEligibilityWhitelistsInput = {
    data: Array<VirtualCareEligibilityWhitelistInput>;
};

export type CreateManyVirtualCareEligibilityWhitelistsResponse = MutationResponse & {
    __typename?: 'CreateManyVirtualCareEligibilityWhitelistsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<
        Array<Maybe<CreateManyIndividualVirtualCareEligibilityWhitelistResponse>>
    >;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyVirtualCareFormsInput = {
    data: Array<VirtualCareFormInput>;
};

export type CreateManyVirtualCareFormsResponse = MutationResponse & {
    __typename?: 'CreateManyVirtualCareFormsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualVirtualCareFormResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyWhatsNewTimelinesInput = {
    data: Array<WhatsNewTimelineInput>;
};

export type CreateManyWhatsNewTimelinesResponse = MutationResponse & {
    __typename?: 'CreateManyWhatsNewTimelinesResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualWhatsNewTimelineResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateManyWhoToCallsInput = {
    data: Array<WhoToCallInput>;
};

export type CreateManyWhoToCallsResponse = MutationResponse & {
    __typename?: 'CreateManyWhoToCallsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<CreateManyIndividualWhoToCallResponse>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateMassUpdatePatientResponse = MutationResponse & {
    __typename?: 'CreateMassUpdatePatientResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<MassUpdatePatients>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateMassUpdateTaskResponse = MutationResponse & {
    __typename?: 'CreateMassUpdateTaskResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<MassUpdateTask>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateMeasurementResponse = MutationResponse & {
    __typename?: 'CreateMeasurementResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Measurement>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateMessageCenterTemplateResponse = MutationResponse & {
    __typename?: 'CreateMessageCenterTemplateResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<MessageCenterTemplate>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateMessageTemplateResponse = MutationResponse & {
    __typename?: 'CreateMessageTemplateResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<MessageTemplate>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateOrUpdateAppUserMutationResponse = MutationResponse & {
    __typename?: 'CreateOrUpdateAppUserMutationResponse';
    data?: Maybe<CreateOrUpdateAppUserResponseData>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateOrUpdateAppUserResponseData = {
    __typename?: 'CreateOrUpdateAppUserResponseData';
    appBundleId?: Maybe<Scalars['String']>;
    appUserId?: Maybe<Scalars['ObjectId']>;
    email?: Maybe<Scalars['String']>;
    familyId?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    patientId?: Maybe<Scalars['String']>;
    phoneNumber?: Maybe<Scalars['String']>;
};

export type CreateOrUpdateAppointmentsFromExternalSourceInput = {
    appointments: Array<ExternalAppointmentInput>;
};

export type CreateOrUpdateAppointmentsInput = {
    appointments: Array<AppointmentInputWithPatientExternalId>;
    redoxEhrId?: InputMaybe<Scalars['String']>;
    redoxSourceId?: InputMaybe<Scalars['String']>;
};

export type CreateOrUpdateAppointmentsResponse = MutationResponse & {
    __typename?: 'CreateOrUpdateAppointmentsResponse';
    appointments?: Maybe<Array<Maybe<Appointment>>>;
    message: Scalars['String'];
    meta: Array<AppointmentResponseMeta>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateOrUpdatePatientsWithDeviceOrdersInput = {
    patients: Array<PatientInputForEligibilityFilesProcessing>;
};

export type CreateOrUpdatePatientsWithDeviceOrdersResponse = MutationResponse & {
    __typename?: 'CreateOrUpdatePatientsWithDeviceOrdersResponse';
    message: Scalars['String'];
    patientsCreatedOrUpdated?: Maybe<Array<Maybe<Patient>>>;
    patientsFailedToImport?: Maybe<Array<Maybe<PatientDataFromFailedImport>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateOrUpdateTodoTaskInput = {
    appUserId?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    dueDate: Scalars['DateTime'];
    id?: InputMaybe<Scalars['ObjectId']>;
    title: Scalars['String'];
};

export type CreateOrUpdateVirtualCareFormResponse = MutationResponse & {
    __typename?: 'CreateOrUpdateVirtualCareFormResponse';
    data?: Maybe<VirtualCareForm>;
    message: Scalars['String'];
    redirectUrl?: Maybe<Scalars['String']>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
    successMessage?: Maybe<Scalars['String']>;
};

export type CreateOrUpdateWhatsNewTimelineResponse = MutationResponse & {
    __typename?: 'CreateOrUpdateWhatsNewTimelineResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
    timeline?: Maybe<WhatsNewTimeline>;
};

export type CreateOrderResponse = MutationResponse & {
    __typename?: 'CreateOrderResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Order>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateOrganizationResponse = MutationResponse & {
    __typename?: 'CreateOrganizationResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Organization>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreatePatientActionResponse = MutationResponse & {
    __typename?: 'CreatePatientActionResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<PatientAction>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreatePatientActionTypeResponse = MutationResponse & {
    __typename?: 'CreatePatientActionTypeResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<PatientActionType>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreatePatientActionsResponse = MutationResponse & {
    __typename?: 'CreatePatientActionsResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<PatientAction>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreatePatientCallListResponse = MutationResponse & {
    __typename?: 'CreatePatientCallListResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<PatientCallList>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreatePatientForAppUserResponse = MutationResponse & {
    __typename?: 'CreatePatientForAppUserResponse';
    message: Scalars['String'];
    patient?: Maybe<Patient>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreatePatientForSelfSignUpInput = {
    birthDate: Scalars['DateTime'];
    email: Scalars['String'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    phoneNumber: Scalars['String'];
    practiceId: Scalars['ObjectId'];
    preferredContact: Scalars['String'];
    signUpPracticeCode: Scalars['String'];
};

export type CreatePatientForSelfSignUpResponse = MutationResponse & {
    __typename?: 'CreatePatientForSelfSignUpResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreatePatientHeaderResponse = MutationResponse & {
    __typename?: 'CreatePatientHeaderResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<PatientHeader>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreatePatientNoteTagResponse = MutationResponse & {
    __typename?: 'CreatePatientNoteTagResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<PatientNoteTag>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreatePatientNotesResponse = MutationResponse & {
    __typename?: 'CreatePatientNotesResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<PatientNotes>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreatePatientResponse = MutationResponse & {
    __typename?: 'CreatePatientResponse';
    message: Scalars['String'];
    patient?: Maybe<Patient>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreatePotentialUserResponse = MutationResponse & {
    __typename?: 'CreatePotentialUserResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<PotentialUser>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateProfileKeyResponse = MutationResponse & {
    __typename?: 'CreateProfileKeyResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ProfileKey>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateProfileValueHistoryResponse = MutationResponse & {
    __typename?: 'CreateProfileValueHistoryResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ProfileValueHistory>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateProfileValueOriginResponse = MutationResponse & {
    __typename?: 'CreateProfileValueOriginResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ProfileValueOrigin>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateProfileValueResponse = MutationResponse & {
    __typename?: 'CreateProfileValueResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ProfileValue>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateProviderResponse = MutationResponse & {
    __typename?: 'CreateProviderResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Provider>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateQListResponse = MutationResponse & {
    __typename?: 'CreateQListResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<QList>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateQuestionResponse = MutationResponse & {
    __typename?: 'CreateQuestionResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Question>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateReferenceLinkCategoryResponse = MutationResponse & {
    __typename?: 'CreateReferenceLinkCategoryResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ReferenceLinkCategory>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateReferenceLinkResponse = MutationResponse & {
    __typename?: 'CreateReferenceLinkResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ReferenceLink>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateReportingCategoryResponse = MutationResponse & {
    __typename?: 'CreateReportingCategoryResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ReportingCategory>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateReportingJobResponse = MutationResponse & {
    __typename?: 'CreateReportingJobResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ReportingJob>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateRoleResponse = MutationResponse & {
    __typename?: 'CreateRoleResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Role>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateSentMessageResponse = MutationResponse & {
    __typename?: 'CreateSentMessageResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<SentMessage>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateSimpleContentResponse = MutationResponse & {
    __typename?: 'CreateSimpleContentResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<SimpleContent>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateSlaResponse = MutationResponse & {
    __typename?: 'CreateSlaResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Sla>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateSlaTimerForAdvocateTaskInput = {
    slaId: Scalars['ObjectId'];
    taskId: Scalars['ObjectId'];
};

export type CreateSlaTimerForAdvocateTaskResponse = MutationResponse & {
    __typename?: 'CreateSlaTimerForAdvocateTaskResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<SlaTimer>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateSlaTimerResponse = MutationResponse & {
    __typename?: 'CreateSlaTimerResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<SlaTimer>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateSubmittedClaimResponse = MutationResponse & {
    __typename?: 'CreateSubmittedClaimResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ClaimSubmitted>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateSubmittedClaimViaFileResponse = MutationResponse & {
    __typename?: 'CreateSubmittedClaimViaFileResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<CreateManyClaimSubmittedIndividual>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateSurveyDefResponse = MutationResponse & {
    __typename?: 'CreateSurveyDefResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<SurveyDef>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateSurveyResponse = MutationResponse & {
    __typename?: 'CreateSurveyResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<SurveyResponse>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateSystemTokenResponse = MutationResponse & {
    __typename?: 'CreateSystemTokenResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<SystemToken>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateTagResponse = MutationResponse & {
    __typename?: 'CreateTagResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Tag>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateTasksForAppointmentResponse = MutationResponse & {
    __typename?: 'CreateTasksForAppointmentResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateTestAppUserInput = {
    appBundleId: Scalars['String'];
    birthDate: Scalars['String'];
    email: Scalars['String'];
    firstName: Scalars['String'];
    isCreatedByPortal?: InputMaybe<Scalars['Boolean']>;
    isTestData?: InputMaybe<Scalars['Boolean']>;
    lastName: Scalars['String'];
    password?: InputMaybe<Scalars['String']>;
    patientBuildList?: InputMaybe<PatientBuildListInput>;
};

export type CreateTestUserMutationResponse = MutationResponse & {
    __typename?: 'CreateTestUserMutationResponse';
    data?: Maybe<TestUserResponseData>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateTodoTaskResponse = MutationResponse & {
    __typename?: 'CreateTodoTaskResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<TodoTask>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateTokenResponse = MutationResponse & {
    __typename?: 'CreateTokenResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Token>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateTrackerHistoryResponse = MutationResponse & {
    __typename?: 'CreateTrackerHistoryResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<TrackerHistory>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateTrackerResponse = MutationResponse & {
    __typename?: 'CreateTrackerResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<Tracker>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateTrendTriggerResponse = MutationResponse & {
    __typename?: 'CreateTrendTriggerResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<TrendTrigger>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateUserProfileDefResponse = MutationResponse & {
    __typename?: 'CreateUserProfileDefResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<UserProfileDef>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateUserResponse = MutationResponse & {
    __typename?: 'CreateUserResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
    user?: Maybe<User>;
};

export type CreateVcZipCodeToLocationResponse = MutationResponse & {
    __typename?: 'CreateVCZipCodeToLocationResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<VcZipCodeToLocation>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateVirtualCareAffiliateInput = {
    data: CreateVirtualCareAffiliatesInput;
};

export type CreateVirtualCareAffiliatesInput = {
    BillDotComAddress1?: InputMaybe<Scalars['String']>;
    BillDotComAddress2?: InputMaybe<Scalars['String']>;
    BillDotComAddressCity?: InputMaybe<Scalars['String']>;
    BillDotComAddressState?: InputMaybe<Scalars['String']>;
    BillDotComAddressZip?: InputMaybe<Scalars['String']>;
    BillDotComNameOnCheck?: InputMaybe<Scalars['String']>;
    BillDotComVendorId?: InputMaybe<Scalars['String']>;
    contactEmail?: InputMaybe<Scalars['String']>;
    contactName?: InputMaybe<Scalars['String']>;
    contactPhoneNumber?: InputMaybe<Scalars['String']>;
    /** Text on successful eligibility */
    customSuccessText?: InputMaybe<Scalars['String']>;
    emailAddressForScheduling?: InputMaybe<Scalars['String']>;
    externalName: Scalars['String'];
    internalName: Scalars['String'];
    inviteAffiliate?: InputMaybe<Scalars['Boolean']>;
    notificationEmail?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    notifyCompanyWhenPatientPassesEligibility?: InputMaybe<Scalars['Boolean']>;
    /** Possible values are ROUTE_DIRECTLY (“Route Directly To URL”) or ROUTE_TO_LANDING (“Route To A Landing Page”) */
    onSuccessfulEligibility?: InputMaybe<Scalars['String']>;
    onSuccessfulZipCodeMatch?: InputMaybe<OnSuccessfulZipCodeMatch>;
    phoneNumberForScheduling?: InputMaybe<Scalars['String']>;
    routingURL?: InputMaybe<Scalars['String']>;
    supportMethodType?: InputMaybe<SupportMethodType>;
    supportMethodValue?: InputMaybe<Scalars['String']>;
    website?: InputMaybe<Scalars['String']>;
    zipCodeMatchCustomSuccessString?: InputMaybe<Scalars['String']>;
    zipCodeMatchRoutingURL?: InputMaybe<Scalars['String']>;
};

export type CreateVirtualCareAffiliatesResponse = MutationResponse & {
    __typename?: 'CreateVirtualCareAffiliatesResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<VirtualCareAffiliatesEntity>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateVirtualCareEligibilityWhitelistResponse = MutationResponse & {
    __typename?: 'CreateVirtualCareEligibilityWhitelistResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<VirtualCareEligibilityWhitelist>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateVirtualCareFormResponse = MutationResponse & {
    __typename?: 'CreateVirtualCareFormResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<VirtualCareForm>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreateWhoToCallResponse = MutationResponse & {
    __typename?: 'CreateWhoToCallResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<WhoToCall>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type CreatedTodoTasksInput = {
    currentDate: Scalars['DateTime'];
    isComplete: Scalars['Boolean'];
};

export type CycleLengthInput = {
    isSkipped?: InputMaybe<Scalars['Boolean']>;
    value?: InputMaybe<Scalars['Float']>;
};

export type CycleLengthType = {
    __typename?: 'CycleLengthType';
    isSkipped?: Maybe<Scalars['Boolean']>;
    value?: Maybe<Scalars['Float']>;
};

export type DailyMeasurement = {
    __typename?: 'DailyMeasurement';
    glucose?: Maybe<Scalars['Float']>;
    moodLevel?: Maybe<Scalars['Float']>;
    notes?: Maybe<Scalars['String']>;
    painLevel?: Maybe<Scalars['Float']>;
    sleepEnds?: Maybe<Scalars['String']>;
    sleepStarts?: Maybe<Scalars['String']>;
    steps?: Maybe<Scalars['Float']>;
    temperature?: Maybe<Scalars['Float']>;
};

export type DailyMeasurementInput = {
    glucose?: InputMaybe<Scalars['Float']>;
    moodLevel?: InputMaybe<Scalars['Float']>;
    notes?: InputMaybe<Scalars['String']>;
    painLevel?: InputMaybe<Scalars['Float']>;
    sleepEnds?: InputMaybe<Scalars['String']>;
    sleepStarts?: InputMaybe<Scalars['String']>;
    steps?: InputMaybe<Scalars['Float']>;
    temperature?: InputMaybe<Scalars['Float']>;
};

export type DateTrigger = BaseEntity & {
    __typename?: 'DateTrigger';
    createdAt: Scalars['DateTime'];
    gracePeriod?: Maybe<Scalars['Float']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    offsetDays?: Maybe<Scalars['Float']>;
    offsetMonths?: Maybe<Scalars['Float']>;
    offsetSequence?: Maybe<Scalars['Float']>;
    offsetYears?: Maybe<Scalars['Float']>;
    tagIds?: Maybe<Array<Scalars['ObjectId']>>;
    timeline?: Maybe<WhatsNewTimeline>;
    timelineId: Scalars['ObjectId'];
    updatedAt: Scalars['DateTime'];
};

export type DateTriggerFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<DateTriggerUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type DateTriggerInput = {
    gracePeriod?: InputMaybe<Scalars['Float']>;
    name: Scalars['String'];
    offsetDays?: InputMaybe<Scalars['Float']>;
    offsetMonths?: InputMaybe<Scalars['Float']>;
    offsetSequence?: InputMaybe<Scalars['Float']>;
    offsetYears?: InputMaybe<Scalars['Float']>;
    tagIds?: InputMaybe<Array<Scalars['ObjectId']>>;
    timelineId: Scalars['ObjectId'];
};

export type DateTriggerListFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<DateTriggerUpdateInputForV2Query>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type DateTriggerListInput = {
    filter?: InputMaybe<DateTriggerListFilterInput>;
    orderBy?: InputMaybe<DateTriggerListOrderByInput>;
    pagination?: InputMaybe<DateTriggerListPaginationInput>;
};

export type DateTriggerListOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type DateTriggerListPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type DateTriggerListResponse = PaginationResponse & {
    __typename?: 'DateTriggerListResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<DateTrigger>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type DateTriggerOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type DateTriggerPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type DateTriggerPaginationQueryResponse = PaginationResponse & {
    __typename?: 'DateTriggerPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<DateTrigger>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type DateTriggerQueryInput = {
    filter?: InputMaybe<DateTriggerFilterInput>;
    orderBy?: InputMaybe<DateTriggerOrderByInput>;
    pagination?: InputMaybe<DateTriggerPaginationInput>;
};

export type DateTriggerUpdateInput = {
    gracePeriod?: InputMaybe<Scalars['Float']>;
    name?: InputMaybe<Scalars['String']>;
    offsetDays?: InputMaybe<Scalars['Float']>;
    offsetMonths?: InputMaybe<Scalars['Float']>;
    offsetSequence?: InputMaybe<Scalars['Float']>;
    offsetYears?: InputMaybe<Scalars['Float']>;
    tagIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    timelineId?: InputMaybe<Scalars['ObjectId']>;
};

export type DateTriggerUpdateInputForV2Query = {
    articleName?: InputMaybe<Scalars['String']>;
    gracePeriod?: InputMaybe<Scalars['Float']>;
    name?: InputMaybe<Scalars['String']>;
    offsetDays?: InputMaybe<Scalars['Float']>;
    offsetMonths?: InputMaybe<Scalars['Float']>;
    offsetSequence?: InputMaybe<Scalars['Float']>;
    offsetYears?: InputMaybe<Scalars['Float']>;
    tagIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    timelineId?: InputMaybe<Scalars['ObjectId']>;
};

export type DateTriggersForTimelineInput = {
    limit?: InputMaybe<Scalars['Float']>;
    skip?: InputMaybe<Scalars['Float']>;
    timelineId?: InputMaybe<Scalars['String']>;
};

export type DeleteAdvocateNotificationInput = {
    id: Scalars['String'];
};

export type DeleteAdvocateNotificationResponse = MutationResponse & {
    __typename?: 'DeleteAdvocateNotificationResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteAdvocateTaskInput = {
    id: Scalars['String'];
};

export type DeleteAdvocateTaskResponse = MutationResponse & {
    __typename?: 'DeleteAdvocateTaskResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteAdvocateTaskTemplateMutationResponse = MutationResponse & {
    __typename?: 'DeleteAdvocateTaskTemplateMutationResponse';
    id: Scalars['String'];
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteAdvocateTaskTypeInput = {
    id: Scalars['String'];
};

export type DeleteAdvocateTaskTypeResponse = MutationResponse & {
    __typename?: 'DeleteAdvocateTaskTypeResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteAffiliateClaimInput = {
    id: Scalars['String'];
};

export type DeleteAffiliateClaimResponse = MutationResponse & {
    __typename?: 'DeleteAffiliateClaimResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteAffiliateInvoiceInput = {
    id: Scalars['String'];
};

export type DeleteAffiliateInvoiceResponse = MutationResponse & {
    __typename?: 'DeleteAffiliateInvoiceResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteAllProfileVariablesMutationResponse = MutationResponse & {
    __typename?: 'DeleteAllProfileVariablesMutationResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteAndReplacePatientActionTypeInput = {
    id?: InputMaybe<Scalars['String']>;
    replaceWithId?: InputMaybe<Scalars['String']>;
};

export type DeleteAppUserArticleInput = {
    id: Scalars['String'];
};

export type DeleteAppUserArticleResponse = MutationResponse & {
    __typename?: 'DeleteAppUserArticleResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteAppUserEventTriggerInput = {
    id: Scalars['String'];
};

export type DeleteAppUserEventTriggerResponse = MutationResponse & {
    __typename?: 'DeleteAppUserEventTriggerResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteAppUserInput = {
    appUserId?: InputMaybe<Scalars['String']>;
};

export type DeleteAppUserMutationResponse = MutationResponse & {
    __typename?: 'DeleteAppUserMutationResponse';
    data?: Maybe<AppUserResponseData>;
    deletedCollections?: Maybe<Array<DeletedCollection>>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteAppUserResponse = MutationResponse & {
    __typename?: 'DeleteAppUserResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteAppUserTelemetryInput = {
    id: Scalars['String'];
};

export type DeleteAppUserTelemetryResponse = MutationResponse & {
    __typename?: 'DeleteAppUserTelemetryResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteAppUserTrendTriggerInput = {
    id: Scalars['String'];
};

export type DeleteAppUserTrendTriggerResponse = MutationResponse & {
    __typename?: 'DeleteAppUserTrendTriggerResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteApplicationInput = {
    id: Scalars['String'];
};

export type DeleteApplicationResponse = MutationResponse & {
    __typename?: 'DeleteApplicationResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteAppointmentInput = {
    id: Scalars['String'];
};

export type DeleteAppointmentResponse = MutationResponse & {
    __typename?: 'DeleteAppointmentResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteArticleApprovalInput = {
    id: Scalars['String'];
};

export type DeleteArticleApprovalResponse = MutationResponse & {
    __typename?: 'DeleteArticleApprovalResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteArticleCacheInput = {
    id: Scalars['String'];
};

export type DeleteArticleCacheResponse = MutationResponse & {
    __typename?: 'DeleteArticleCacheResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteArticleCategoryMutationResponse = MutationResponse & {
    __typename?: 'DeleteArticleCategoryMutationResponse';
    id: Scalars['String'];
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteArticleFeedInput = {
    id: Scalars['String'];
};

export type DeleteArticleFeedResponse = MutationResponse & {
    __typename?: 'DeleteArticleFeedResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteArticleGroupInput = {
    id: Scalars['String'];
};

export type DeleteArticleGroupResponse = MutationResponse & {
    __typename?: 'DeleteArticleGroupResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteArticleMutationResponse = MutationResponse & {
    __typename?: 'DeleteArticleMutationResponse';
    id: Scalars['String'];
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteArticlePromotionInput = {
    id: Scalars['String'];
};

export type DeleteArticlePromotionResponse = MutationResponse & {
    __typename?: 'DeleteArticlePromotionResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteAsyncJobInput = {
    id: Scalars['String'];
};

export type DeleteAsyncJobResponse = MutationResponse & {
    __typename?: 'DeleteAsyncJobResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteBatchTaskInput = {
    id: Scalars['String'];
};

export type DeleteBatchTaskResponse = MutationResponse & {
    __typename?: 'DeleteBatchTaskResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteBioScreenInput = {
    id: Scalars['String'];
};

export type DeleteBioScreenResponse = MutationResponse & {
    __typename?: 'DeleteBioScreenResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteCdResponse = MutationResponse & {
    __typename?: 'DeleteCDResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteCalendarInput = {
    id: Scalars['String'];
};

export type DeleteCalendarResponse = MutationResponse & {
    __typename?: 'DeleteCalendarResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteCarePlanInput = {
    id: Scalars['ObjectId'];
};

export type DeleteCarePlanResponse = MutationResponse & {
    __typename?: 'DeleteCarePlanResponse';
    id?: Maybe<Scalars['ObjectId']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteCareTeamContactInput = {
    id: Scalars['String'];
};

export type DeleteCareTeamContactResponse = MutationResponse & {
    __typename?: 'DeleteCareTeamContactResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteCareTeamInput = {
    id: Scalars['String'];
};

export type DeleteCareTeamMemberResponse = MutationResponse & {
    __typename?: 'DeleteCareTeamMemberResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteCareTeamMemberTypeMutationResponse = MutationResponse & {
    __typename?: 'DeleteCareTeamMemberTypeMutationResponse';
    id: Scalars['String'];
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteCareTeamResponse = MutationResponse & {
    __typename?: 'DeleteCareTeamResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteChatConversationInput = {
    id: Scalars['String'];
};

export type DeleteChatConversationResponse = MutationResponse & {
    __typename?: 'DeleteChatConversationResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteChatMessageInput = {
    id: Scalars['String'];
};

export type DeleteChatMessageResponse = MutationResponse & {
    __typename?: 'DeleteChatMessageResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteChatRoomInput = {
    id: Scalars['String'];
};

export type DeleteChatRoomResponse = MutationResponse & {
    __typename?: 'DeleteChatRoomResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteClinicalSummaryInput = {
    id: Scalars['String'];
};

export type DeleteClinicalSummaryResponse = MutationResponse & {
    __typename?: 'DeleteClinicalSummaryResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteCompoundQuestionInput = {
    id: Scalars['String'];
};

export type DeleteCompoundQuestionResponse = MutationResponse & {
    __typename?: 'DeleteCompoundQuestionResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteConditionInput = {
    id: Scalars['String'];
};

export type DeleteConditionResponse = MutationResponse & {
    __typename?: 'DeleteConditionResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteConditionTypeMutationResponse = MutationResponse & {
    __typename?: 'DeleteConditionTypeMutationResponse';
    id: Scalars['String'];
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteConfigExpressionInput = {
    id: Scalars['String'];
};

export type DeleteConfigExpressionResponse = MutationResponse & {
    __typename?: 'DeleteConfigExpressionResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteConfigValueByValueDefIdInput = {
    valueDefId: Scalars['ObjectId'];
};

export type DeleteConfigValueByValueDefIdResponse = MutationResponse & {
    __typename?: 'DeleteConfigValueByValueDefIdResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteConfigValueDefInput = {
    id: Scalars['String'];
};

export type DeleteConfigValueDefResponse = MutationResponse & {
    __typename?: 'DeleteConfigValueDefResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteConfigValueInput = {
    id: Scalars['String'];
};

export type DeleteConfigValueResponse = MutationResponse & {
    __typename?: 'DeleteConfigValueResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteDateTriggerMutationResponse = MutationResponse & {
    __typename?: 'DeleteDateTriggerMutationResponse';
    id: Scalars['String'];
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteDeviceInput = {
    id: Scalars['String'];
};

export type DeleteDeviceResponse = MutationResponse & {
    __typename?: 'DeleteDeviceResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteDirectoryCacheInput = {
    id: Scalars['String'];
};

export type DeleteDirectoryCacheResponse = MutationResponse & {
    __typename?: 'DeleteDirectoryCacheResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteEligibilityMatchInput = {
    id: Scalars['String'];
};

export type DeleteEligibilityMatchResponse = MutationResponse & {
    __typename?: 'DeleteEligibilityMatchResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteEpisodeInput = {
    id: Scalars['String'];
};

export type DeleteEpisodeResponse = MutationResponse & {
    __typename?: 'DeleteEpisodeResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteEventTriggerMutationResponse = MutationResponse & {
    __typename?: 'DeleteEventTriggerMutationResponse';
    id: Scalars['String'];
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteExpressionMutationResponse = MutationResponse & {
    __typename?: 'DeleteExpressionMutationResponse';
    id: Scalars['String'];
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteHealthPlanInput = {
    id: Scalars['String'];
};

export type DeleteHealthPlanResponse = MutationResponse & {
    __typename?: 'DeleteHealthPlanResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteHighlightFeedInput = {
    id: Scalars['String'];
};

export type DeleteHighlightFeedResponse = MutationResponse & {
    __typename?: 'DeleteHighlightFeedResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteHighlightInput = {
    id: Scalars['String'];
};

export type DeleteHighlightResponse = MutationResponse & {
    __typename?: 'DeleteHighlightResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteInterventionInput = {
    id: Scalars['String'];
};

export type DeleteInterventionResponse = MutationResponse & {
    __typename?: 'DeleteInterventionResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteManyProfileVariablesInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    conditionId?: InputMaybe<Scalars['ObjectId']>;
    episodeId?: InputMaybe<Scalars['ObjectId']>;
    familyId?: InputMaybe<Scalars['String']>;
    keyId?: InputMaybe<Scalars['ObjectId']>;
    patientId?: InputMaybe<Scalars['String']>;
    profileVariableDefId?: InputMaybe<Scalars['String']>;
    surveyDefId?: InputMaybe<Scalars['String']>;
};

export type DeleteMassUpdatePatientInput = {
    id: Scalars['String'];
};

export type DeleteMassUpdatePatientListResponse = MutationResponse & {
    __typename?: 'DeleteMassUpdatePatientListResponse';
    id: Scalars['String'];
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteMassUpdatePatientResponse = MutationResponse & {
    __typename?: 'DeleteMassUpdatePatientResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteMassUpdateTaskInput = {
    id: Scalars['String'];
};

export type DeleteMassUpdateTaskResponse = MutationResponse & {
    __typename?: 'DeleteMassUpdateTaskResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteMeasurementInput = {
    id: Scalars['String'];
};

export type DeleteMeasurementResponse = MutationResponse & {
    __typename?: 'DeleteMeasurementResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteMessageCenterTemplateMutationResponse = MutationResponse & {
    __typename?: 'DeleteMessageCenterTemplateMutationResponse';
    id: Scalars['String'];
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteMessageTemplateInput = {
    id: Scalars['String'];
};

export type DeleteMessageTemplateResponse = MutationResponse & {
    __typename?: 'DeleteMessageTemplateResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteNotificationTokenInput = {
    key: Scalars['String'];
};

export type DeleteOrderInput = {
    id: Scalars['String'];
};

export type DeleteOrderResponse = MutationResponse & {
    __typename?: 'DeleteOrderResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteOrganizationInput = {
    id: Scalars['String'];
};

export type DeleteOrganizationInviteCodeInput = {
    inviteCode: Scalars['String'];
    orgId: Scalars['ObjectId'];
};

export type DeleteOrganizationInviteCodeResponse = MutationResponse & {
    __typename?: 'DeleteOrganizationInviteCodeResponse';
    message: Scalars['String'];
    organization?: Maybe<Organization>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteOrganizationResponse = MutationResponse & {
    __typename?: 'DeleteOrganizationResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeletePatientActionByStepInput = {
    stepId: Scalars['ObjectId'];
};

export type DeletePatientActionByStepResponse = MutationResponse & {
    __typename?: 'DeletePatientActionByStepResponse';
    message: Scalars['String'];
    resourceDeleted?: Maybe<PatientAction>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeletePatientActionInput = {
    id: Scalars['String'];
};

export type DeletePatientActionResponse = MutationResponse & {
    __typename?: 'DeletePatientActionResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeletePatientActionTypeResponse = MutationResponse & {
    __typename?: 'DeletePatientActionTypeResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeletePatientAppUserDownstreamDataInput = {
    id: Scalars['ObjectId'];
    stopOnFailure?: InputMaybe<Scalars['Boolean']>;
};

export type DeletePatientCallListInput = {
    id: Scalars['String'];
};

export type DeletePatientCallListResponse = MutationResponse & {
    __typename?: 'DeletePatientCallListResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeletePatientHeaderInput = {
    id: Scalars['String'];
};

export type DeletePatientHeaderResponse = MutationResponse & {
    __typename?: 'DeletePatientHeaderResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeletePatientInput = {
    id: Scalars['ObjectId'];
};

export type DeletePatientInvitationInput = {
    id: Scalars['ObjectId'];
};

export type DeletePatientInvitationResponse = MutationResponse & {
    __typename?: 'DeletePatientInvitationResponse';
    message: Scalars['String'];
    patient?: Maybe<Patient>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeletePatientNoteTagInput = {
    id: Scalars['ObjectId'];
};

export type DeletePatientNoteTagResponse = MutationResponse & {
    __typename?: 'DeletePatientNoteTagResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeletePatientNotesInput = {
    id: Scalars['String'];
};

export type DeletePatientNotesResponse = MutationResponse & {
    __typename?: 'DeletePatientNotesResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeletePatientResponse = MutationResponse & {
    __typename?: 'DeletePatientResponse';
    id?: Maybe<Scalars['ObjectId']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeletePatientResponseV2 = MutationResponse & {
    __typename?: 'DeletePatientResponseV2';
    deletedCollections?: Maybe<Array<DeletedCollection>>;
    message: Scalars['String'];
    patientId?: Maybe<Scalars['ObjectId']>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeletePatientTodoResponse = MutationResponse & {
    __typename?: 'DeletePatientTodoResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeletePatientsByAppResponse = MutationResponse & {
    __typename?: 'DeletePatientsByAppResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeletePotentialUserInput = {
    id: Scalars['String'];
};

export type DeletePotentialUserResponse = MutationResponse & {
    __typename?: 'DeletePotentialUserResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteProfileKeyInput = {
    id: Scalars['String'];
};

export type DeleteProfileKeyResponse = MutationResponse & {
    __typename?: 'DeleteProfileKeyResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteProfileValueHistoryInput = {
    id: Scalars['String'];
};

export type DeleteProfileValueHistoryResponse = MutationResponse & {
    __typename?: 'DeleteProfileValueHistoryResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteProfileValueInput = {
    id: Scalars['String'];
};

export type DeleteProfileValueOriginInput = {
    id: Scalars['String'];
};

export type DeleteProfileValueOriginResponse = MutationResponse & {
    __typename?: 'DeleteProfileValueOriginResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteProfileValueResponse = MutationResponse & {
    __typename?: 'DeleteProfileValueResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteProfileVariableInput = {
    conditionId?: InputMaybe<Scalars['ObjectId']>;
    episodeId?: InputMaybe<Scalars['ObjectId']>;
    familyId?: InputMaybe<Scalars['String']>;
    keyId?: InputMaybe<Scalars['ObjectId']>;
    patientId?: InputMaybe<Scalars['String']>;
    profileVariableDefId?: InputMaybe<Scalars['String']>;
    surveyDefId?: InputMaybe<Scalars['String']>;
};

export type DeleteProviderInput = {
    id: Scalars['String'];
};

export type DeleteProviderResponse = MutationResponse & {
    __typename?: 'DeleteProviderResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteQListInput = {
    id: Scalars['String'];
};

export type DeleteQListResponse = MutationResponse & {
    __typename?: 'DeleteQListResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteQuestionInput = {
    id: Scalars['String'];
};

export type DeleteQuestionResponse = MutationResponse & {
    __typename?: 'DeleteQuestionResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteReferenceLinkCategoryInput = {
    id: Scalars['ObjectId'];
};

export type DeleteReferenceLinkCategoryResponse = MutationResponse & {
    __typename?: 'DeleteReferenceLinkCategoryResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteReferenceLinkInput = {
    id: Scalars['String'];
};

export type DeleteReferenceLinkResponse = MutationResponse & {
    __typename?: 'DeleteReferenceLinkResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteReportingCategoryMutationResponse = MutationResponse & {
    __typename?: 'DeleteReportingCategoryMutationResponse';
    id: Scalars['String'];
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteReportingJobInput = {
    id: Scalars['String'];
};

export type DeleteReportingJobResponse = MutationResponse & {
    __typename?: 'DeleteReportingJobResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteRoleMutationResponse = MutationResponse & {
    __typename?: 'DeleteRoleMutationResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteSentMessageInput = {
    id: Scalars['String'];
};

export type DeleteSentMessageResponse = MutationResponse & {
    __typename?: 'DeleteSentMessageResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteSimpleContentMutationResponse = MutationResponse & {
    __typename?: 'DeleteSimpleContentMutationResponse';
    id: Scalars['String'];
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteSlaInput = {
    id: Scalars['String'];
};

export type DeleteSlaResponse = MutationResponse & {
    __typename?: 'DeleteSlaResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteSlaTimerInput = {
    id: Scalars['String'];
};

export type DeleteSlaTimerResponse = MutationResponse & {
    __typename?: 'DeleteSlaTimerResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteSubmittedClaimInput = {
    id: Scalars['String'];
};

export type DeleteSubmittedClaimResponse = MutationResponse & {
    __typename?: 'DeleteSubmittedClaimResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteSurveyDefMutationResponse = MutationResponse & {
    __typename?: 'DeleteSurveyDefMutationResponse';
    id: Scalars['String'];
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteSurveyResponseInput = {
    id: Scalars['String'];
};

export type DeleteSurveyResponseResponse = MutationResponse & {
    __typename?: 'DeleteSurveyResponseResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteSystemTokenInput = {
    id: Scalars['String'];
};

export type DeleteSystemTokenResponse = MutationResponse & {
    __typename?: 'DeleteSystemTokenResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteTagInput = {
    id?: InputMaybe<Scalars['String']>;
    isTrigger?: InputMaybe<Scalars['Boolean']>;
};

export type DeleteTagMutationResponse = MutationResponse & {
    __typename?: 'DeleteTagMutationResponse';
    id: Scalars['String'];
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteTasksForAppointmentResponse = MutationResponse & {
    __typename?: 'DeleteTasksForAppointmentResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteTimelineResponse = MutationResponse & {
    __typename?: 'DeleteTimelineResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteTodoTaskInput = {
    id: Scalars['String'];
};

export type DeleteTodoTaskResponse = MutationResponse & {
    __typename?: 'DeleteTodoTaskResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteTodoTasksInput = {
    carePlanId?: InputMaybe<Scalars['String']>;
    carePlanIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Scalars['String']>;
    groupIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Scalars['String']>;
    ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    isComplete: Scalars['Boolean'];
    templateId?: InputMaybe<Scalars['String']>;
    templateIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DeleteTodoTasksResponse = MutationResponse & {
    __typename?: 'DeleteTodoTasksResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteTokenInput = {
    id: Scalars['String'];
};

export type DeleteTokenResponse = MutationResponse & {
    __typename?: 'DeleteTokenResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteTrackerHistoryInput = {
    id: Scalars['String'];
};

export type DeleteTrackerHistoryResponse = MutationResponse & {
    __typename?: 'DeleteTrackerHistoryResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteTrackerInput = {
    id: Scalars['String'];
};

export type DeleteTrackerResponse = MutationResponse & {
    __typename?: 'DeleteTrackerResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteTrendTriggerMutationResponse = MutationResponse & {
    __typename?: 'DeleteTrendTriggerMutationResponse';
    id: Scalars['String'];
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteUserDataInput = {
    bundleId: Scalars['String'];
    email: Scalars['String'];
};

export type DeleteUserDataResponse = MutationResponse & {
    __typename?: 'DeleteUserDataResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteUserInput = {
    id: Scalars['ObjectId'];
};

export type DeleteUserProfileDefResponse = MutationResponse & {
    __typename?: 'DeleteUserProfileDefResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteUserResponse = MutationResponse & {
    __typename?: 'DeleteUserResponse';
    id?: Maybe<Scalars['ObjectId']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteVcZipCodeToLocationInput = {
    id: Scalars['String'];
};

export type DeleteVcZipCodeToLocationResponse = MutationResponse & {
    __typename?: 'DeleteVCZipCodeToLocationResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteVariantInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    articleId?: InputMaybe<Scalars['String']>;
    language?: InputMaybe<Scalars['String']>;
    readingLevel?: InputMaybe<Scalars['String']>;
};

export type DeleteVariantMutationResponse = MutationResponse & {
    __typename?: 'DeleteVariantMutationResponse';
    article?: Maybe<Article>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteVirtualCareAffiliateResponse = MutationResponse & {
    __typename?: 'DeleteVirtualCareAffiliateResponse';
    id: Scalars['String'];
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteVirtualCareAffiliatesInput = {
    id: Scalars['String'];
};

export type DeleteVirtualCareAffiliatesResponse = MutationResponse & {
    __typename?: 'DeleteVirtualCareAffiliatesResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteVirtualCareEligibilityWhitelistInput = {
    id: Scalars['String'];
};

export type DeleteVirtualCareEligibilityWhitelistResponse = MutationResponse & {
    __typename?: 'DeleteVirtualCareEligibilityWhitelistResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteVirtualCareFormInput = {
    id: Scalars['String'];
};

export type DeleteVirtualCareFormResponse = MutationResponse & {
    __typename?: 'DeleteVirtualCareFormResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteWhatsNewTimelineInput = {
    id: Scalars['String'];
};

export type DeleteWhatsNewTimelineResponse = MutationResponse & {
    __typename?: 'DeleteWhatsNewTimelineResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeleteWhoToCallInput = {
    id: Scalars['String'];
};

export type DeleteWhoToCallResponse = MutationResponse & {
    __typename?: 'DeleteWhoToCallResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type DeletedCollection = {
    __typename?: 'DeletedCollection';
    collection: Scalars['String'];
    deletedCount: Scalars['Float'];
    error?: Maybe<Scalars['String']>;
    isDeleted: Scalars['Boolean'];
};

export type Demographics = {
    __typename?: 'Demographics';
    /** A code describing the patient's ethnicity */
    ethnicGroupCodes?: Maybe<Array<RedoxNameAndCodeDetails>>;
    /** One of the following: Hispanic or latino, Not hispanic or latino, Refused to report, Unknown */
    ethnicity?: Maybe<Scalars['String']>;
    /** Patient's primary spoken language. */
    language?: Maybe<Scalars['String']>;
    /** Patient's race */
    race?: Maybe<Scalars['String']>;
    /** A code describing the patient's race */
    raceCodes?: Maybe<Array<RedoxNameAndCodeDetails>>;
};

export type DemographicsInput = {
    /** A code describing the patient's ethnicity */
    ethnicGroupCodes?: InputMaybe<Array<RedoxNameAndCodeDetailsInput>>;
    /** One of the following: Hispanic or latino, Not hispanic or latino, Refused to report, Unknown */
    ethnicity?: InputMaybe<Scalars['String']>;
    /** Patient's primary spoken language. */
    language?: InputMaybe<Scalars['String']>;
    /** Patient's race */
    race?: InputMaybe<Scalars['String']>;
    /** A code describing the patient's race */
    raceCodes?: InputMaybe<Array<RedoxNameAndCodeDetailsInput>>;
};

export type DependentEntity = {
    __typename?: 'DependentEntity';
    blocksDelete?: Maybe<Scalars['Boolean']>;
    entityType: WhereUsedEntity;
    entries?: Maybe<Array<Maybe<DependentEntityEntry>>>;
    total?: Maybe<Scalars['Float']>;
};

export type DependentEntityEntry = {
    __typename?: 'DependentEntityEntry';
    id: Scalars['String'];
    name: Scalars['String'];
};

/** Target of for virtual care form. */
export enum DestinationNames {
    Affiliate = 'Affiliate',
    Ouma = 'Ouma',
    Pacify = 'Pacify',
    RouteToSuccessScreen = 'RouteToSuccessScreen',
    SimpliFed = 'SimpliFed',
    WIldflower = 'WIldflower',
}

export type Device = BaseEntity & {
    __typename?: 'Device';
    createdAt: Scalars['DateTime'];
    /** Documentation Link */
    docLink?: Maybe<LocalizedString>;
    externalId?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    /** Name in the locale selected in context */
    localizedDocLink?: Maybe<Scalars['String']>;
    /** Name in the locale selected in context */
    localizedName?: Maybe<Scalars['String']>;
    name: LocalizedString;
    photo?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
};

export type DeviceFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<DeviceUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type DeviceInput = {
    /** Documentation Link */
    docLink?: InputMaybe<LocalizedStringInput>;
    externalId?: InputMaybe<Scalars['String']>;
    name: LocalizedStringInput;
    photo?: InputMaybe<Scalars['String']>;
};

export type DeviceOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type DevicePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type DevicePaginationQueryResponse = PaginationResponse & {
    __typename?: 'DevicePaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Device>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type DeviceQueryInput = {
    filter?: InputMaybe<DeviceFilterInput>;
    orderBy?: InputMaybe<DeviceOrderByInput>;
    pagination?: InputMaybe<DevicePaginationInput>;
};

export type DeviceUpdateInput = {
    /** Documentation Link */
    docLink?: InputMaybe<LocalizedStringInput>;
    externalId?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<LocalizedStringInput>;
    photo?: InputMaybe<Scalars['String']>;
};

export type Diagnoses = {
    __typename?: 'Diagnoses';
    code?: Maybe<Scalars['String']>;
    codeset?: Maybe<Scalars['String']>;
    documentedDateTime?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
};

export type DiagnosesInput = {
    code?: InputMaybe<Scalars['String']>;
    codeset?: InputMaybe<Scalars['String']>;
    documentedDateTime?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
};

export type DirectoryArticle = {
    __typename?: 'DirectoryArticle';
    id: Scalars['ObjectId'];
};

export type DirectoryArticleInput = {
    id: Scalars['ObjectId'];
};

export type DirectoryCache = BaseEntity & {
    __typename?: 'DirectoryCache';
    appBundleId: Scalars['String'];
    contentDirectoryId: Scalars['ObjectId'];
    createdAt: Scalars['DateTime'];
    description?: Maybe<Scalars['String']>;
    displayStyle?: Maybe<DirectoryDisplayStyle>;
    entries: Array<DirectoryEntry>;
    icon?: Maybe<ContentIcon>;
    id: Scalars['ObjectId'];
    image?: Maybe<Scalars['String']>;
    isTestData?: Maybe<Scalars['Boolean']>;
    label: Scalars['String'];
    language: Language;
    simpleContentIds?: Maybe<Array<Scalars['ObjectId']>>;
    subDirIds?: Maybe<Array<Scalars['ObjectId']>>;
    surveyIds?: Maybe<Array<Scalars['ObjectId']>>;
    tagIds?: Maybe<Array<Scalars['ObjectId']>>;
    taggedImages?: Maybe<Array<DirectoryTaggedImage>>;
    updatedAt: Scalars['DateTime'];
};

export type DirectoryCacheFieldInListFilterInput = {
    simpleContentIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
};

export type DirectoryCacheFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<DirectoryCacheUpdateInput>;
    fieldsInList?: InputMaybe<DirectoryCacheFieldInListFilterInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type DirectoryCacheInput = {
    appBundleId: Scalars['String'];
    contentDirectoryId: Scalars['ObjectId'];
    description?: InputMaybe<Scalars['String']>;
    displayStyle?: InputMaybe<DirectoryDisplayStyle>;
    entries?: InputMaybe<Array<InputMaybe<DirectoryEntryInput>>>;
    icon?: InputMaybe<ContentIcon>;
    image?: InputMaybe<Scalars['String']>;
    label: Scalars['String'];
    language: Language;
    simpleContentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
    subDirIds?: InputMaybe<Array<Scalars['ObjectId']>>;
    surveyIds?: InputMaybe<Array<Scalars['ObjectId']>>;
    tagIds?: InputMaybe<Array<Scalars['ObjectId']>>;
    taggedImages?: InputMaybe<Array<InputMaybe<DirectoryTaggedImageInput>>>;
};

export type DirectoryCacheOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type DirectoryCachePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type DirectoryCachePaginationQueryResponse = PaginationResponse & {
    __typename?: 'DirectoryCachePaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<DirectoryCache>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type DirectoryCacheQueryInput = {
    filter?: InputMaybe<DirectoryCacheFilterInput>;
    orderBy?: InputMaybe<DirectoryCacheOrderByInput>;
    pagination?: InputMaybe<DirectoryCachePaginationInput>;
};

export type DirectoryCacheUpdateInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    contentDirectoryId?: InputMaybe<Scalars['ObjectId']>;
    description?: InputMaybe<Scalars['String']>;
    displayStyle?: InputMaybe<DirectoryDisplayStyle>;
    entries?: InputMaybe<Array<InputMaybe<DirectoryEntryInput>>>;
    icon?: InputMaybe<ContentIcon>;
    image?: InputMaybe<Scalars['String']>;
    label?: InputMaybe<Scalars['String']>;
    language?: InputMaybe<Language>;
    simpleContentIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    subDirIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    surveyIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    tagIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    taggedImages?: InputMaybe<Array<InputMaybe<DirectoryTaggedImageInput>>>;
};

/** How Directory Items Should Display. */
export enum DirectoryDisplayStyle {
    Listwithicon = 'listwithicon',
    Listwithimage = 'listwithimage',
    Simplelist = 'simplelist',
    Tiledimage = 'tiledimage',
}

export type DirectoryEntry = {
    __typename?: 'DirectoryEntry';
    article?: Maybe<DirectoryArticle>;
    icon?: Maybe<ContentIcon>;
    internalLink?: Maybe<DirectoryInternalLink>;
    phone?: Maybe<DirectoryPhone>;
    subDirectory?: Maybe<SubDirectory>;
    survey?: Maybe<DirectorySurvey>;
    tagList?: Maybe<Array<Scalars['ObjectId']>>;
    tracker?: Maybe<DirectoryTracker>;
    type: DirectoryEntryType;
};

export type DirectoryEntryInput = {
    article?: InputMaybe<DirectoryArticleInput>;
    icon?: InputMaybe<ContentIcon>;
    internalLink?: InputMaybe<DirectoryInternalLinkInput>;
    phone?: InputMaybe<DirectoryPhoneInput>;
    subDirectory?: InputMaybe<SubDirectoryInput>;
    survey?: InputMaybe<DirectorySurveyInput>;
    tagList?: InputMaybe<Array<Scalars['ObjectId']>>;
    tracker?: InputMaybe<DirectoryTrackerInput>;
    type: DirectoryEntryType;
};

/** Directory Entry Type */
export enum DirectoryEntryType {
    Article = 'article',
    InternalLink = 'internalLink',
    Phone = 'phone',
    Subdirectory = 'subdirectory',
    Survey = 'survey',
    Tracker = 'tracker',
}

export type DirectoryInternalLink = {
    __typename?: 'DirectoryInternalLink';
    description?: Maybe<Scalars['String']>;
    label: Scalars['String'];
    link: Scalars['String'];
};

export type DirectoryInternalLinkInput = {
    description?: InputMaybe<Scalars['String']>;
    label: Scalars['String'];
    link: Scalars['String'];
};

export type DirectoryItem = {
    __typename?: 'DirectoryItem';
    article?: Maybe<Article>;
    articleId?: Maybe<Scalars['ObjectId']>;
    excludeApps?: Maybe<Array<Scalars['String']>>;
    id: Scalars['ObjectId'];
    includeApps?: Maybe<Array<Scalars['String']>>;
    simpleContent?: Maybe<SimpleContent>;
    simpleContentId?: Maybe<Scalars['ObjectId']>;
    subDirectoryId?: Maybe<Scalars['ObjectId']>;
    subdirectory?: Maybe<ContentDirectory>;
    survey?: Maybe<SurveyDef>;
    surveyId?: Maybe<Scalars['ObjectId']>;
    tagList?: Maybe<Array<Scalars['ObjectId']>>;
    type: DirectoryItemType;
};

export type DirectoryItemInput = {
    articleId?: InputMaybe<Scalars['ObjectId']>;
    excludeApps?: InputMaybe<Array<Scalars['String']>>;
    id: Scalars['ObjectId'];
    includeApps?: InputMaybe<Array<Scalars['String']>>;
    simpleContentId?: InputMaybe<Scalars['ObjectId']>;
    subDirectoryId?: InputMaybe<Scalars['ObjectId']>;
    surveyId?: InputMaybe<Scalars['ObjectId']>;
    tagList?: InputMaybe<Array<Scalars['ObjectId']>>;
    type: DirectoryItemType;
};

/** The type of the content directory item. */
export enum DirectoryItemType {
    Article = 'Article',
    SimpleContent = 'SimpleContent',
    SubDirectory = 'SubDirectory',
    Survey = 'Survey',
}

export type DirectoryPhone = {
    __typename?: 'DirectoryPhone';
    description?: Maybe<Scalars['String']>;
    label: Scalars['String'];
    number: Scalars['String'];
};

export type DirectoryPhoneInput = {
    description?: InputMaybe<Scalars['String']>;
    label: Scalars['String'];
    number: Scalars['String'];
};

export type DirectorySurvey = {
    __typename?: 'DirectorySurvey';
    id: Scalars['ObjectId'];
    image?: Maybe<Scalars['String']>;
    summary: Scalars['String'];
    title: Scalars['String'];
};

export type DirectorySurveyInput = {
    id: Scalars['ObjectId'];
    image?: InputMaybe<Scalars['String']>;
    summary: Scalars['String'];
    title: Scalars['String'];
};

export type DirectoryTaggedImage = {
    __typename?: 'DirectoryTaggedImage';
    imageURL: Scalars['String'];
    tagId: Scalars['ObjectId'];
};

export type DirectoryTaggedImageInput = {
    imageURL: Scalars['String'];
    tagId: Scalars['ObjectId'];
};

export type DirectoryTracker = {
    __typename?: 'DirectoryTracker';
    description?: Maybe<Scalars['String']>;
    label: Scalars['String'];
    tracker: Scalars['String'];
};

export type DirectoryTrackerInput = {
    description?: InputMaybe<Scalars['String']>;
    label: Scalars['String'];
    tracker: Scalars['String'];
};

export type DownloadFamilyDataInput = {
    dbname: Scalars['String'];
};

export type DownloadFamilyDataResponse = MutationResponse & {
    __typename?: 'DownloadFamilyDataResponse';
    familyData?: Maybe<Scalars['JSON']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type EditHaTodoTaskResponse = MutationResponse & {
    __typename?: 'EditHATodoTaskResponse';
    message: Scalars['String'];
    resourceCreated?: Maybe<ProxiedTodoTask>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type EhrIds = {
    __typename?: 'EhrIds';
    id: Scalars['String'];
    type: Scalars['String'];
};

export type EhrIdsInput = {
    id: Scalars['String'];
    type: Scalars['String'];
};

export type EligibilityAccessCodeFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<AccessCodeQuery>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type EligibilityAccessCodeInput = {
    filter?: InputMaybe<EligibilityAccessCodeFilterInput>;
    orderBy?: InputMaybe<EligibilityAccessCodeOrderByInput>;
    pagination?: InputMaybe<EligibilityAccessCodePaginationInput>;
    unitName?: InputMaybe<Scalars['String']>;
};

export type EligibilityAccessCodeOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type EligibilityAccessCodePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type EligibilityAccessCodesResponse = PaginationResponse & {
    __typename?: 'EligibilityAccessCodesResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results?: Maybe<Array<Maybe<AccessCode>>>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type EligibilityBulkInput = {
    deviceId: Scalars['String'];
    payloads?: InputMaybe<Array<InputMaybe<EligibilityDataMany>>>;
    unitName: Scalars['String'];
};

export type EligibilityBulkRecheckResponse = MutationResponse & {
    __typename?: 'EligibilityBulkRecheckResponse';
    message: Scalars['String'];
    responseData: EligibilityResponseDataMany;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type EligibilityConfigurationInput = {
    bundleId: Scalars['String'];
    healthPlan?: InputMaybe<Scalars['String']>;
};

export type EligibilityConfigurationType = {
    __typename?: 'EligibilityConfigurationType';
    additionalFields?: Maybe<Scalars['JSON']>;
    additionalInfo?: Maybe<LocalizedString>;
    eligibilityFields?: Maybe<Scalars['JSON']>;
    eligibilityHelpView?: Maybe<Scalars['JSON']>;
    eligibilityProfileDefinitions?: Maybe<Scalars['JSON']>;
    mychart?: Maybe<Scalars['Boolean']>;
    pathway?: Maybe<Scalars['String']>;
    screenHeader?: Maybe<LocalizedString>;
    sso?: Maybe<Scalars['Boolean']>;
    subtext?: Maybe<LocalizedString>;
};

export type EligibilityCreateAccessCodeResponse = MutationResponse & {
    __typename?: 'EligibilityCreateAccessCodeResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type EligibilityData = {
    birthDate?: InputMaybe<Scalars['String']>;
    dateOfBirth?: InputMaybe<Scalars['String']>;
    dob?: InputMaybe<Scalars['String']>;
    firstName?: InputMaybe<Scalars['String']>;
    healthPlanCode?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
    lobAppId?: InputMaybe<Scalars['String']>;
    memberId?: InputMaybe<Scalars['String']>;
    memberNumber?: InputMaybe<Scalars['String']>;
    policy?: InputMaybe<Scalars['String']>;
    ssn?: InputMaybe<Scalars['String']>;
    zip?: InputMaybe<Scalars['String']>;
};

export type EligibilityDataMany = {
    birthDate?: InputMaybe<Scalars['String']>;
    dateOfBirth?: InputMaybe<Scalars['String']>;
    dob?: InputMaybe<Scalars['String']>;
    eligiStatus?: InputMaybe<Scalars['Boolean']>;
    firstName?: InputMaybe<Scalars['String']>;
    healthPlanCode?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
    lobAppId?: InputMaybe<Scalars['String']>;
    memberId?: InputMaybe<Scalars['String']>;
    memberNumber?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['String']>;
    policy?: InputMaybe<Scalars['String']>;
    prevStatus?: InputMaybe<Scalars['Boolean']>;
    ssn?: InputMaybe<Scalars['String']>;
    zip?: InputMaybe<Scalars['String']>;
};

export type EligibilityInput = {
    appUserId?: InputMaybe<Scalars['ObjectId']>;
    deviceId: Scalars['String'];
    eligibilityData?: InputMaybe<EligibilityData>;
    unitName: Scalars['String'];
};

export type EligibilityMatch = BaseEntity & {
    __typename?: 'EligibilityMatch';
    appId?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    firstNameMatch?: Maybe<Scalars['String']>;
    hasMatch?: Maybe<Scalars['Boolean']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    lastNameMatch?: Maybe<Scalars['String']>;
    matchDateTime?: Maybe<Scalars['DateTime']>;
    matchUserId?: Maybe<Scalars['ObjectId']>;
    patientDOB?: Maybe<Scalars['String']>;
    patientFirstName?: Maybe<Scalars['String']>;
    patientId?: Maybe<Scalars['ObjectId']>;
    patientLastName?: Maybe<Scalars['String']>;
    potentialMatches?: Maybe<Array<Maybe<PotentialMatch>>>;
    potentialMatchesHash?: Maybe<Scalars['String']>;
    toBeReviewed?: Maybe<Scalars['Boolean']>;
    updatedAt: Scalars['DateTime'];
};

export type EligibilityMatchFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<EligibilityMatchUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type EligibilityMatchInput = {
    appId?: InputMaybe<Scalars['String']>;
    firstNameMatch?: InputMaybe<Scalars['String']>;
    hasMatch?: InputMaybe<Scalars['Boolean']>;
    lastNameMatch?: InputMaybe<Scalars['String']>;
    matchDateTime?: InputMaybe<Scalars['DateTime']>;
    matchUserId?: InputMaybe<Scalars['ObjectId']>;
    patientDOB?: InputMaybe<Scalars['String']>;
    patientFirstName?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    patientLastName?: InputMaybe<Scalars['String']>;
    potentialMatches?: InputMaybe<Array<InputMaybe<PotentialMatchInput>>>;
    potentialMatchesHash?: InputMaybe<Scalars['String']>;
    toBeReviewed?: InputMaybe<Scalars['Boolean']>;
};

export type EligibilityMatchMetaData = {
    __typename?: 'EligibilityMatchMetaData';
    nextMatchId?: Maybe<Scalars['ObjectId']>;
    remainingCount?: Maybe<Scalars['Float']>;
};

export type EligibilityMatchMetaDataInput = {
    id?: InputMaybe<Scalars['ObjectId']>;
};

export type EligibilityMatchOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type EligibilityMatchPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type EligibilityMatchPaginationQueryResponse = PaginationResponse & {
    __typename?: 'EligibilityMatchPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<EligibilityMatch>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type EligibilityMatchQueryInput = {
    filter?: InputMaybe<EligibilityMatchFilterInput>;
    orderBy?: InputMaybe<EligibilityMatchOrderByInput>;
    pagination?: InputMaybe<EligibilityMatchPaginationInput>;
};

export type EligibilityMatchStats = {
    __typename?: 'EligibilityMatchStats';
    inQueue?: Maybe<Scalars['Float']>;
    matches?: Maybe<Scalars['Float']>;
    noMatches?: Maybe<Scalars['Float']>;
};

export type EligibilityMatchUpdateInput = {
    appId?: InputMaybe<Scalars['String']>;
    firstNameMatch?: InputMaybe<Scalars['String']>;
    hasMatch?: InputMaybe<Scalars['Boolean']>;
    lastNameMatch?: InputMaybe<Scalars['String']>;
    matchDateTime?: InputMaybe<Scalars['DateTime']>;
    matchUserId?: InputMaybe<Scalars['ObjectId']>;
    patientDOB?: InputMaybe<Scalars['String']>;
    patientFirstName?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    patientLastName?: InputMaybe<Scalars['String']>;
    potentialMatches?: InputMaybe<Array<InputMaybe<PotentialMatchInput>>>;
    potentialMatchesHash?: InputMaybe<Scalars['String']>;
    toBeReviewed?: InputMaybe<Scalars['Boolean']>;
};

export type EligibilityResponse = MutationResponse & {
    __typename?: 'EligibilityResponse';
    message: Scalars['String'];
    responseData: EligibilityResponseData;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type EligibilityResponseData = {
    __typename?: 'EligibilityResponseData';
    extendedCode?: Maybe<Scalars['Float']>;
    extra?: Maybe<Scalars['JSON']>;
    isEligible?: Maybe<Scalars['Boolean']>;
    message?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
};

export type EligibilityResponseDataArray = {
    __typename?: 'EligibilityResponseDataArray';
    eligiStatus?: Maybe<Scalars['Boolean']>;
    extendedCode?: Maybe<Scalars['Float']>;
    extra?: Maybe<Scalars['JSON']>;
    message?: Maybe<Scalars['String']>;
    patientId?: Maybe<Scalars['String']>;
    prevStatus?: Maybe<Scalars['Boolean']>;
    status?: Maybe<Scalars['String']>;
};

export type EligibilityResponseDataMany = {
    __typename?: 'EligibilityResponseDataMany';
    response?: Maybe<Array<Maybe<EligibilityResponseDataArray>>>;
};

/** Type of Eligibility */
export enum EligibilityType {
    Dorsata = 'DORSATA',
    WfhInvitationCode = 'WFH_INVITATION_CODE',
}

export type EligibilityUnitNamesResponse = {
    __typename?: 'EligibilityUnitNamesResponse';
    results: Array<Scalars['String']>;
};

export type EligibilityUpdateAccessCodeInput = {
    data?: InputMaybe<AccessCodeInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type EligibilityUpdateAccessCodeResponse = MutationResponse & {
    __typename?: 'EligibilityUpdateAccessCodeResponse';
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type EmailFromAppUserIdResponseData = {
    __typename?: 'EmailFromAppUserIdResponseData';
    email: Scalars['String'];
};

export type EngageProviderData = {
    __typename?: 'EngageProviderData';
    dob: Scalars['String'];
    email?: Maybe<Scalars['String']>;
    firstName: Scalars['String'];
    hcid?: Maybe<Scalars['String']>;
    lastName: Scalars['String'];
};

export type EngageProviderDataInput = {
    dob: Scalars['String'];
    email?: InputMaybe<Scalars['String']>;
    firstName: Scalars['String'];
    hcid?: InputMaybe<Scalars['String']>;
    lastName: Scalars['String'];
};

export type EntityWhereUsedResponse = {
    __typename?: 'EntityWhereUsedResponse';
    dependencies?: Maybe<Array<DependentEntity>>;
};

export type Episode = BaseEntity & {
    __typename?: 'Episode';
    beginDate?: Maybe<Scalars['DateTime']>;
    condition?: Maybe<Condition>;
    conditionId?: Maybe<Scalars['ObjectId']>;
    conditionTypeId: Scalars['ObjectId'];
    createdAt: Scalars['DateTime'];
    endDate?: Maybe<Scalars['DateTime']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    organizationId?: Maybe<Scalars['ObjectId']>;
    patientId: Scalars['ObjectId'];
    updatedAt: Scalars['DateTime'];
};

export type EpisodeFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<EpisodeUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type EpisodeInput = {
    beginDate?: InputMaybe<Scalars['DateTime']>;
    conditionId?: InputMaybe<Scalars['ObjectId']>;
    conditionTypeId?: InputMaybe<Scalars['ObjectId']>;
    endDate?: InputMaybe<Scalars['DateTime']>;
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
};

export type EpisodeOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type EpisodePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type EpisodePaginationQueryResponse = PaginationResponse & {
    __typename?: 'EpisodePaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Episode>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type EpisodeQueryInput = {
    filter?: InputMaybe<EpisodeFilterInput>;
    orderBy?: InputMaybe<EpisodeOrderByInput>;
    pagination?: InputMaybe<EpisodePaginationInput>;
};

export type EpisodeUpdateInput = {
    beginDate?: InputMaybe<Scalars['DateTime']>;
    conditionId?: InputMaybe<Scalars['ObjectId']>;
    conditionTypeId?: InputMaybe<Scalars['ObjectId']>;
    endDate?: InputMaybe<Scalars['DateTime']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
};

/** Event Tracker Type. */
export enum EventTrackerType {
    BloodPressure = 'bloodPressure',
    GlucoseAny = 'glucoseAny',
    GlucoseFasting = 'glucoseFasting',
    GlucosePostBreakfast = 'glucosePostBreakfast',
    GlucosePostDinner = 'glucosePostDinner',
    GlucosePostLunch = 'glucosePostLunch',
}

export type EventTrigger = BaseEntity & {
    __typename?: 'EventTrigger';
    canRepeat: Scalars['Boolean'];
    createdAt: Scalars['DateTime'];
    expression?: Maybe<ConfigExpression>;
    expressionId: Scalars['ObjectId'];
    id: Scalars['ObjectId'];
    ignoreSameValue: Scalars['Boolean'];
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    profileDef?: Maybe<UserProfileDef>;
    profileDefId?: Maybe<Scalars['ObjectId']>;
    trackerType?: Maybe<EventTrackerType>;
    updatedAt: Scalars['DateTime'];
};

export type EventTriggerFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<EventTriggerUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type EventTriggerInput = {
    canRepeat: Scalars['Boolean'];
    expressionId: Scalars['ObjectId'];
    ignoreSameValue: Scalars['Boolean'];
    name: Scalars['String'];
    profileDefId?: InputMaybe<Scalars['ObjectId']>;
    trackerType?: InputMaybe<EventTrackerType>;
};

export type EventTriggerOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type EventTriggerPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type EventTriggerPaginationQueryResponse = PaginationResponse & {
    __typename?: 'EventTriggerPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<EventTrigger>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type EventTriggerQueryInput = {
    filter?: InputMaybe<EventTriggerFilterInput>;
    orderBy?: InputMaybe<EventTriggerOrderByInput>;
    pagination?: InputMaybe<EventTriggerPaginationInput>;
};

export type EventTriggerUpdateInput = {
    canRepeat?: InputMaybe<Scalars['Boolean']>;
    expressionId?: InputMaybe<Scalars['ObjectId']>;
    ignoreSameValue?: InputMaybe<Scalars['Boolean']>;
    name?: InputMaybe<Scalars['String']>;
    profileDefId?: InputMaybe<Scalars['ObjectId']>;
    trackerType?: InputMaybe<EventTrackerType>;
};

export type ExportPatientsByFilterInput = {
    /** List of patient properties to exclude from resulting file */
    exclude?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filter?: InputMaybe<PatientFilterFilterInput>;
    filterOr?: InputMaybe<PatientUpdateInput>;
    filterProfile?: InputMaybe<Array<InputMaybe<GetPatientsByFilterProfileFilterInput>>>;
    filterTestData?: InputMaybe<Scalars['Boolean']>;
    orderBy?: InputMaybe<GetPatientsByFilterOrderByInput>;
    pagination?: InputMaybe<PatientFilterPaginationInput>;
    values?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['String']>>>>>;
};

export type ExportToCsvCallListFilterInput = {
    filter?: InputMaybe<ExportToCsvCallListsFilterInput>;
    orderBy?: InputMaybe<ExportToCsvCallListsOrderByInput>;
    pagination?: InputMaybe<ExportToCsvCallListsPaginationInput>;
};

export type ExportToCsvCallListPreviewInput = {
    callListId?: InputMaybe<Scalars['ObjectId']>;
    patientsInput?: InputMaybe<GetPatientsByFilterInput>;
};

export type ExportToCsvCallListsFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<PatientCallListUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ExportToCsvCallListsOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ExportToCsvCallListsPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ExpressionOverride = {
    __typename?: 'ExpressionOverride';
    expression?: Maybe<ConfigExpression>;
    value: ConfigValueDictionary;
};

export type ExpressionOverrideInput = {
    expressionId: Scalars['String'];
    value: ConfigValueDictionaryInput;
};

/** Type Expression can be used with */
export enum ExpressionUseType {
    Surveys = 'Surveys',
    Tags = 'Tags',
}

export type ExternalAppointmentInput = {
    deleted?: InputMaybe<Scalars['Boolean']>;
    description?: InputMaybe<Scalars['String']>;
    /** Positive Integer */
    durationMinutes?: InputMaybe<Scalars['Float']>;
    externalId: Scalars['String'];
    externalProviderId?: InputMaybe<Scalars['String']>;
    isVirtualVisit?: InputMaybe<Scalars['Boolean']>;
    /** For Appointment Notes from Lifeline */
    note?: InputMaybe<Scalars['String']>;
    patientId: Scalars['ObjectId'];
    /** For phone created appointments */
    physicalLocation?: InputMaybe<Scalars['String']>;
    practiceId?: InputMaybe<Scalars['ObjectId']>;
    reportKey?: InputMaybe<Scalars['String']>;
    source: AppointmentSource;
    staffId?: InputMaybe<Scalars['ObjectId']>;
    /** For phone created appointments */
    staffName?: InputMaybe<Scalars['String']>;
    startDate: Scalars['DateTime'];
    status?: InputMaybe<Scalars['String']>;
    timezoneName?: InputMaybe<Scalars['String']>;
    virtualLink?: InputMaybe<Scalars['String']>;
    visitLocationDepartment?: InputMaybe<Scalars['String']>;
    visitLocationFacility?: InputMaybe<Scalars['String']>;
    visitProviderEmail?: InputMaybe<Scalars['String']>;
    visitProviderFirstName?: InputMaybe<Scalars['String']>;
    visitProviderLastName?: InputMaybe<Scalars['String']>;
    visitProviderLocationAddress?: InputMaybe<Scalars['String']>;
    visitProviderLocationCity?: InputMaybe<Scalars['String']>;
    visitProviderLocationState?: InputMaybe<Scalars['String']>;
    visitProviderLocationZip?: InputMaybe<Scalars['String']>;
    visitProviderPhoneNumber?: InputMaybe<Scalars['String']>;
    /** For content promotion? */
    visitType?: InputMaybe<Scalars['String']>;
};

export type FamilyMember = {
    __typename?: 'FamilyMember';
    appUserId?: Maybe<Scalars['String']>;
    familyId?: Maybe<Scalars['String']>;
    familyRole?: Maybe<FamilyRole>;
    patientId?: Maybe<Scalars['String']>;
};

/** Designates whether this is the account creator (primary), invited user (secondary), or child (dependent). */
export enum FamilyRole {
    Dependent = 'Dependent',
    Primary = 'Primary',
    Secondary = 'Secondary',
}

export type FetchChatRoomForAppUserInput = {
    careTeamMemberTypeId?: InputMaybe<Scalars['ObjectId']>;
};

export type FetchChatRoomForPortalUserInput = {
    careTeamMemberTypeId?: InputMaybe<Scalars['ObjectId']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
};

export type FilteredAdvocateTasksResponse = PaginationResponse & {
    __typename?: 'FilteredAdvocateTasksResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<AdvocateTask>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type FilteredArticleApprovalResponse = {
    __typename?: 'FilteredArticleApprovalResponse';
    results: Array<ArticleApproval>;
    total: Scalars['Float'];
};

export type FilteredBioScreen = BaseEntity & {
    __typename?: 'FilteredBioScreen';
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    /** Description */
    label: LocalizedString;
    shortcuts: Array<BioScreenShortcut>;
    surveyGroups: Array<BioScreenSurveyGroup>;
    target: BioScreenTargetType;
    updatedAt: Scalars['DateTime'];
    yourHealthInfo: BioScreenShortcut;
};

export type FilteredBioScreenInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    appUserId?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['String']>;
};

export type FindUserByEmailDataInput = {
    email: Scalars['String'];
    isSimilarChecked?: InputMaybe<Scalars['Boolean']>;
};

export type FindUserByEmailDataResponse = MutationResponse & {
    __typename?: 'FindUserByEmailDataResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
    users?: Maybe<Scalars['String']>;
};

export type FollowAllPatientsByOrgInput = {
    orgId?: InputMaybe<Scalars['ObjectId']>;
    userId?: InputMaybe<Scalars['ObjectId']>;
};

export type FollowAllPatientsByOrgResponse = MutationResponse & {
    __typename?: 'FollowAllPatientsByOrgResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type FollowAllUsersInput = {
    patientId?: InputMaybe<Scalars['ObjectId']>;
    practicesIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
};

export type FollowAllUsersResponse = MutationResponse & {
    __typename?: 'FollowAllUsersResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type ForceClaimUploadInput = {
    id?: InputMaybe<Scalars['String']>;
};

export type ForceClaimUploadResponse = MutationResponse & {
    __typename?: 'ForceClaimUploadResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type FullArticle = {
    __typename?: 'FullArticle';
    body?: Maybe<Scalars['String']>;
    bookmarked?: Maybe<Scalars['Boolean']>;
    category?: Maybe<Scalars['String']>;
    contentBeingCreated?: Maybe<Scalars['Boolean']>;
    ctas?: Maybe<Array<Maybe<MappedCta>>>;
    icon?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
    summary?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    username?: Maybe<Scalars['String']>;
    viewed?: Maybe<Scalars['Boolean']>;
};

export type FullInfoAdvocateNotification = BaseEntity & {
    __typename?: 'FullInfoAdvocateNotification';
    advocateTask?: Maybe<AdvocateTask>;
    advocateTaskId: Scalars['ObjectId'];
    createdAt: Scalars['DateTime'];
    displayDate?: Maybe<Scalars['DateTime']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    patient?: Maybe<Patient>;
    type: AdvocateNotificationType;
    updatedAt: Scalars['DateTime'];
    userId: Scalars['ObjectId'];
    viewed: Scalars['Boolean'];
};

export type GetAmsAppByBundleId = {
    bundleId: Scalars['String'];
};

export type GetAmsAppInput = {
    id: Scalars['String'];
};

export type GetAmsClientInput = {
    id: Scalars['String'];
};

export type GetAmsTagInput = {
    id: Scalars['String'];
};

export type GetAmsUnitInput = {
    name: Scalars['String'];
};

export type GetAdvocateNotificationInput = {
    fields?: InputMaybe<AdvocateNotificationUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetAdvocateTaskInput = {
    fields?: InputMaybe<AdvocateTaskUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetAdvocateTaskTemplateInput = {
    fields?: InputMaybe<AdvocateTaskTemplateUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetAdvocateTaskTypeInput = {
    fields?: InputMaybe<AdvocateTaskTypeUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetAffiliateClaimInput = {
    fields?: InputMaybe<AffiliateClaimFilteringInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetAffiliateInvoiceInput = {
    fields?: InputMaybe<AffiliateInvoiceUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetAppUserArticleInput = {
    fields?: InputMaybe<AppUserArticleUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetAppUserEventTriggerInput = {
    fields?: InputMaybe<AppUserEventTriggerUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetAppUserTelemetryInput = {
    fields?: InputMaybe<AppUserTelemetryInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetAppUserTrendTriggerInput = {
    fields?: InputMaybe<AppUserTrendTriggerUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetAppUsersChunkedInput = {
    /** default is 100 */
    chunkSize?: InputMaybe<Scalars['Float']>;
    filter?: InputMaybe<AppUserRecordInput>;
    previousChunkLastId?: InputMaybe<Scalars['String']>;
};

export type GetApplicationInput = {
    fields?: InputMaybe<ApplicationUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetAppointmentInput = {
    fields?: InputMaybe<AppointmentUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetArticleApprovalInput = {
    fields?: InputMaybe<ArticleApprovalUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetArticleCacheInput = {
    fields?: InputMaybe<ArticleCacheUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetArticleCategoryInput = {
    fields?: InputMaybe<ArticleCategoryUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetArticleGroupInput = {
    fields?: InputMaybe<ArticleGroupUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetArticleInput = {
    fields?: InputMaybe<ArticleUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetArticlePromotionInput = {
    fields?: InputMaybe<ArticlePromotionUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetAsyncJobInput = {
    fields?: InputMaybe<AsyncJobUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetBatchTaskInput = {
    fields?: InputMaybe<BatchTaskUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetBioScreenInput = {
    fields?: InputMaybe<BioScreenUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetCalendarInput = {
    fields?: InputMaybe<CalendarUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetCarePlanInput = {
    fields?: InputMaybe<CarePlanUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetCareTeamContactInput = {
    fields?: InputMaybe<CareTeamContactUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetCareTeamInput = {
    fields?: InputMaybe<CareTeamUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetCareTeamMemberInput = {
    fields?: InputMaybe<CareTeamMemberUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetCareTeamMemberTypeInput = {
    fields?: InputMaybe<CareTeamMemberTypeUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetChatConversationInput = {
    fields?: InputMaybe<ChatConversationUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetChatMessageInput = {
    fields?: InputMaybe<ChatMessageUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetChatRoomInput = {
    fields?: InputMaybe<ChatRoomUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetClaimSubmittedGlobalSearchInput = {
    assignedTo?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<ClaimSubmittedGlobalSearchInputFilterInput>;
    orderBy?: InputMaybe<ClaimSubmittedGlobalSearchInputOrderByInput>;
    pagination?: InputMaybe<ClaimSubmittedGlobalSearchInputPaginationInput>;
    search?: InputMaybe<Scalars['String']>;
    tagIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type GetClinicalSummaryByLogIdInput = {
    logId: Scalars['String'];
};

export type GetClinicalSummaryInput = {
    fields?: InputMaybe<ClinicalSummaryUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetClinicalSummaryLogByIdResponse = {
    __typename?: 'GetClinicalSummaryLogByIdResponse';
    raw?: Maybe<Scalars['String']>;
};

export type GetCompoundQuestionInput = {
    fields?: InputMaybe<CompoundQuestionUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetConditionInput = {
    fields?: InputMaybe<ConditionUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetConditionTypeInput = {
    fields?: InputMaybe<ConditionTypeUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetConfigExpressionInput = {
    fields?: InputMaybe<ConfigExpressionUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetConfigValueDefInput = {
    fields?: InputMaybe<ConfigValueDefUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetConfigValueInput = {
    fields?: InputMaybe<ConfigValueUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetContentDirectoryInput = {
    fields?: InputMaybe<ContentDirectoryUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetDateTriggerInput = {
    fields?: InputMaybe<DateTriggerUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetDeviceInput = {
    fields?: InputMaybe<DeviceUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetDirectoryCacheInput = {
    fields?: InputMaybe<DirectoryCacheUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetEligibilityMatchInput = {
    fields?: InputMaybe<EligibilityMatchUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetEligibilityResultInput = {
    filter?: InputMaybe<VcEligibilityResultsV2FilterInput>;
    orderBy?: InputMaybe<VcEligibilityResultsV2OrderByInput>;
    pagination?: InputMaybe<VcEligibilityResultsV2PaginationInput>;
};

export type GetEmailsExistancy = {
    __typename?: 'GetEmailsExistancy';
    patientEmails?: Maybe<Scalars['JSON']>;
};

export type GetEpisodeInput = {
    fields?: InputMaybe<EpisodeUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetEventTriggerInput = {
    fields?: InputMaybe<EventTriggerUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetFamilyInput = {
    appUserId?: InputMaybe<Scalars['String']>;
};

export type GetFamilyResponse = MutationResponse & {
    __typename?: 'GetFamilyResponse';
    data?: Maybe<Array<Maybe<FamilyMember>>>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type GetFilteredAdvocateTasksInput = {
    customFilters?: InputMaybe<AdvocateTasksCustomFiltersInput>;
    filter?: InputMaybe<AdvocateTasksExtendedFilteringFilterInput>;
    orderBy?: InputMaybe<AdvocateTasksExtendedFilteringOrderByInput>;
    pagination?: InputMaybe<AdvocateTasksExtendedFilteringPaginationInput>;
};

export type GetHealthPlanInput = {
    fields?: InputMaybe<HealthPlanUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetHighlightInput = {
    fields?: InputMaybe<HighlightUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetInterventionInput = {
    fields?: InputMaybe<InterventionUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetInvoicesPaymentStatusInput = {
    assignedTo?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<GetInvoicesPaymentStatusInputFilterInput>;
    orderBy?: InputMaybe<GetInvoicesPaymentStatusInputOrderByInput>;
    pagination?: InputMaybe<GetInvoicesPaymentStatusInputPaginationInput>;
    tagIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type GetInvoicesPaymentStatusInputFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<AffiliateInvoiceUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type GetInvoicesPaymentStatusInputOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type GetInvoicesPaymentStatusInputPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type GetManyHtcPatientsInput = {
    practiceIds: Array<Scalars['String']>;
};

export type GetMassUpdatePatientInput = {
    fields?: InputMaybe<MassUpdatePatientsUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetMassUpdateTaskInput = {
    fields?: InputMaybe<MassUpdateTaskUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetMeasurementInput = {
    fields?: InputMaybe<MeasurementUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetMessageCenterTemplateInput = {
    fields?: InputMaybe<MessageCenterTemplateUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetMessageTemplateInput = {
    fields?: InputMaybe<MessageTemplateUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetNumberOfPatientUnreadMessages = {
    chatRoomId: Scalars['ObjectId'];
};

export type GetOrderInput = {
    fields?: InputMaybe<OrdersInputUpdate>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetOrganizationInput = {
    fields?: InputMaybe<OrganizationUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetPatientActionInput = {
    fields?: InputMaybe<PatientActionUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetPatientActionTypeInput = {
    fields?: InputMaybe<PatientActionTypeUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetPatientByInvitationCode = {
    invitationCode: Scalars['String'];
};

export type GetPatientByRedoxSourceAndIdInput = {
    redoxEhrId: Array<Scalars['String']>;
    redoxSourceId: Array<Scalars['String']>;
};

export type GetPatientCacheInput = {
    fields?: InputMaybe<PatientCacheUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetPatientCallListInput = {
    fields?: InputMaybe<PatientCallListUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetPatientHeaderInput = {
    fields?: InputMaybe<PatientHeaderUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetPatientInput = {
    fields?: InputMaybe<PatientUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetPatientNoteTagInput = {
    fields?: InputMaybe<PatientNoteTagUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetPatientNotesInput = {
    fields?: InputMaybe<PatientNotesUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetPatientsByFilterInput = {
    fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filter?: InputMaybe<PatientFilterFilterInput>;
    filterOr?: InputMaybe<PatientUpdateInput>;
    filterProfile?: InputMaybe<Array<InputMaybe<GetPatientsByFilterProfileFilterInput>>>;
    filterTestData?: InputMaybe<Scalars['Boolean']>;
    orderBy?: InputMaybe<GetPatientsByFilterOrderByInput>;
    pagination?: InputMaybe<PatientFilterPaginationInput>;
    values?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['String']>>>>>;
};

export type GetPatientsByFilterOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type GetPatientsByFilterProfileFilterInput = {
    comparison: ProfileValueFilterComparison;
    profileDefId: Scalars['String'];
    valueBool?: InputMaybe<Scalars['Boolean']>;
    valueDate?: InputMaybe<Scalars['DateTime']>;
    valueId?: InputMaybe<Scalars['ObjectId']>;
    valueNum?: InputMaybe<Scalars['Float']>;
    valueStr?: InputMaybe<Scalars['String']>;
    valueType: ProfileValueFilterType;
};

export type GetPotentialUserInput = {
    fields?: InputMaybe<PotentialUserUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetProfileKeyInput = {
    fields?: InputMaybe<ProfileKeyUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetProfileValueHistoryInput = {
    fields?: InputMaybe<PatientValueHistoryUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetProfileValueInput = {
    fields?: InputMaybe<ProfileValueUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetProfileValueOriginInput = {
    fields?: InputMaybe<ProfileValueOriginUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetProviderInput = {
    fields?: InputMaybe<ProviderUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetQListInput = {
    fields?: InputMaybe<QListUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetQuestionInput = {
    fields?: InputMaybe<QuestionUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetReferenceLinkCategoryInput = {
    fields?: InputMaybe<ReferenceLinkCategoryUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetReferenceLinkInput = {
    fields?: InputMaybe<ReferenceLinkUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetReferenceLinksForPageInput = {
    page: Scalars['String'];
};

export type GetReferenceLinksForPageResponse = {
    __typename?: 'GetReferenceLinksForPageResponse';
    category?: Maybe<Scalars['String']>;
    data?: Maybe<Array<Maybe<LinkDataType>>>;
};

export type GetReportingCategoryInput = {
    fields?: InputMaybe<ReportingCategoryUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetReportingJobInput = {
    fields?: InputMaybe<ReportingJobUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetRoleInput = {
    fields?: InputMaybe<RoleUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetSentMessageInput = {
    fields?: InputMaybe<SentMessageUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetSimpleContentInput = {
    fields?: InputMaybe<SimpleContentUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetSlaInput = {
    fields?: InputMaybe<SlaUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetSlaTimerInput = {
    fields?: InputMaybe<SlaTimerUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetSlaTimersByFilterInput = {
    filter?: InputMaybe<SlaTimerFilterFilterInput>;
    orderBy?: InputMaybe<SlaTimerFilterOrderByInput>;
    pagination?: InputMaybe<SlaTimerFilterPaginationInput>;
};

export type GetSubmittedClaimInput = {
    fields?: InputMaybe<ClaimSubmittedUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetSurveyDefInput = {
    fields?: InputMaybe<SurveyDefUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetSurveyResponseInput = {
    fields?: InputMaybe<SurveyResponseUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetSystemTokenInput = {
    fields?: InputMaybe<SystemTokenUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetTagInput = {
    fields?: InputMaybe<TagUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetTodoTaskInput = {
    fields?: InputMaybe<TodoTaskUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetTokenInput = {
    fields?: InputMaybe<TokenUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetTrackerHistoryInput = {
    fields?: InputMaybe<TrackerHistoryUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetTrackerInput = {
    fields?: InputMaybe<TrackerUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetTrendTriggerInput = {
    fields?: InputMaybe<TrendTriggerUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetUserInput = {
    fields?: InputMaybe<UserUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetUserProfileDefInput = {
    fields?: InputMaybe<UserProfileDefUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetVcAffiliatePatientsInput = {
    affiliateId?: InputMaybe<Scalars['String']>;
    assignedTo?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<GetVcAffiliatePatientsInputFilterInput>;
    onlyName?: InputMaybe<Scalars['Boolean']>;
    orderBy?: InputMaybe<GetVcAffiliatePatientsInputOrderByInput>;
    pagination?: InputMaybe<GetVcAffiliatePatientsInputPaginationInput>;
    search?: InputMaybe<Scalars['String']>;
    tagIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type GetVcAffiliatePatientsInputFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<PatientUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type GetVcAffiliatePatientsInputOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type GetVcAffiliatePatientsInputPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type GetVcZipCodeToLocationInput = {
    fields?: InputMaybe<VcZipCodeToLocationUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetVendorByIdInput = {
    id: Scalars['String'];
};

export type GetVirtualCareAffiliateByAffiliateIdInput = {
    affiliateId: Scalars['String'];
};

export type GetVirtualCareAffiliateInput = {
    id: Scalars['String'];
};

export type GetVirtualCareAffiliatesFilterInput = {
    affiliateId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GetVirtualCareAffiliatesInput = {
    assignedTo?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<GetVirtualCareAffiliatesInputFilterInput>;
    orderBy?: InputMaybe<GetVirtualCareAffiliatesInputOrderByInput>;
    pagination?: InputMaybe<GetVirtualCareAffiliatesInputPaginationInput>;
    search?: InputMaybe<Scalars['String']>;
    tagIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type GetVirtualCareAffiliatesInputFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<VirtualCareAffiliatesUpdateInput>;
    fieldsInList?: InputMaybe<GetVirtualCareAffiliatesFilterInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type GetVirtualCareAffiliatesInputOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type GetVirtualCareAffiliatesInputPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type GetVirtualCareEligibilityWhitelistInput = {
    fields?: InputMaybe<VirtualCareEligibilityWhitelistUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetVirtualCareFormInput = {
    fields?: InputMaybe<VirtualCareFormUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetWhatsNewTimelineInput = {
    fields?: InputMaybe<WhatsNewTimelineUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GetWhoToCallInput = {
    fields?: InputMaybe<WhoToCallUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type GlobalAlert = {
    __typename?: 'GlobalAlert';
    message: Scalars['String'];
    open: Scalars['Boolean'];
    severity: AlertSeverity;
};

export type GlobalBanner = {
    __typename?: 'GlobalBanner';
    actionText: Scalars['String'];
    message: Scalars['String'];
    open: Scalars['Boolean'];
    severity: AlertSeverity;
};

export type GlobalConfirm = {
    __typename?: 'GlobalConfirm';
    description?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    open: Scalars['Boolean'];
};

export type GlobalImagePreview = {
    __typename?: 'GlobalImagePreview';
    imageSrc: Scalars['String'];
    open: Scalars['Boolean'];
};

export type GlobalImageUpload = {
    __typename?: 'GlobalImageUpload';
    isImage: Scalars['Boolean'];
    open: Scalars['Boolean'];
};

export type GlobalLoading = {
    __typename?: 'GlobalLoading';
    message: Scalars['String'];
    open: Scalars['Boolean'];
};

export type GroupByTodoTasks = {
    __typename?: 'GroupByTodoTasks';
    groupId?: Maybe<Scalars['String']>;
    groupName?: Maybe<LocalizedString>;
};

/** What an HA is allowed to do with this profile definition */
export enum HaPermission {
    Display = 'Display',
    Edit = 'Edit',
    Hide = 'Hide',
}

export type HeaderProfileDef = {
    __typename?: 'HeaderProfileDef';
    disabled?: Maybe<Scalars['Boolean']>;
    isParentField?: Maybe<Scalars['Boolean']>;
    label?: Maybe<Scalars['String']>;
    profileDefId: Scalars['ObjectId'];
    sortField?: Maybe<Scalars['String']>;
};

export type HeaderProfileDefInput = {
    disabled?: InputMaybe<Scalars['Boolean']>;
    isParentField?: InputMaybe<Scalars['Boolean']>;
    label?: InputMaybe<Scalars['String']>;
    profileDefId: Scalars['ObjectId'];
    sortField?: InputMaybe<Scalars['String']>;
};

export type HealthPlan = BaseEntity & {
    __typename?: 'HealthPlan';
    brandingName?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    eligibilityType?: Maybe<EligibilityType>;
    externalId?: Maybe<Scalars['String']>;
    healthPlanCode?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    name?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
    surveys: Array<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
};

export type HealthPlanFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<HealthPlanUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type HealthPlanInput = {
    brandingName?: InputMaybe<Scalars['String']>;
    eligibilityType?: InputMaybe<EligibilityType>;
    externalId?: InputMaybe<Scalars['String']>;
    healthPlanCode?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    state?: InputMaybe<Scalars['String']>;
    surveys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type HealthPlanOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type HealthPlanPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type HealthPlanPaginationQueryResponse = PaginationResponse & {
    __typename?: 'HealthPlanPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<HealthPlan>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type HealthPlanQueryInput = {
    filter?: InputMaybe<HealthPlanFilterInput>;
    orderBy?: InputMaybe<HealthPlanOrderByInput>;
    pagination?: InputMaybe<HealthPlanPaginationInput>;
};

export type HealthPlanUpdateInput = {
    brandingName?: InputMaybe<Scalars['String']>;
    eligibilityType?: InputMaybe<EligibilityType>;
    externalId?: InputMaybe<Scalars['String']>;
    healthPlanCode?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    state?: InputMaybe<Scalars['String']>;
    surveys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Highlight = BaseEntity & {
    __typename?: 'Highlight';
    bodyMarkup?: Maybe<LocalizedStringLoose>;
    clockType: ClockType;
    clockTypeName?: Maybe<Scalars['String']>;
    closeable: Scalars['Boolean'];
    createdAt: Scalars['DateTime'];
    displayAsModal?: Maybe<Scalars['Boolean']>;
    displayAsModalButtonText?: Maybe<LocalizedStringLoose>;
    duration: Scalars['Float'];
    durationTimeUnit: TimeUnit;
    endDate?: Maybe<Scalars['DateTime']>;
    excludeApps?: Maybe<Array<Scalars['String']>>;
    headerMarkup?: Maybe<LocalizedStringLoose>;
    highlightTypeName?: Maybe<Scalars['String']>;
    highlighted: Scalars['Boolean'];
    icon: HighlightIcon;
    id: Scalars['ObjectId'];
    imageContentId?: Maybe<Scalars['ObjectId']>;
    includeApps?: Maybe<Array<Scalars['String']>>;
    isTestData?: Maybe<Scalars['Boolean']>;
    linkText?: Maybe<LocalizedStringLoose>;
    name: Scalars['String'];
    notificationText?: Maybe<LocalizedStringLoose>;
    offsetDays?: Maybe<Scalars['Float']>;
    offsetMonths?: Maybe<Scalars['Float']>;
    offsetSequence?: Maybe<Scalars['Float']>;
    offsetYears?: Maybe<Scalars['Float']>;
    priority: Scalars['Float'];
    shouldSendNotification: Scalars['Boolean'];
    simpleContentId?: Maybe<Scalars['ObjectId']>;
    startDate?: Maybe<Scalars['DateTime']>;
    surveyId?: Maybe<Scalars['ObjectId']>;
    tags?: Maybe<Array<Scalars['String']>>;
    timeline?: Maybe<WhatsNewTimeline>;
    timelineId?: Maybe<Scalars['ObjectId']>;
    titleMarkup?: Maybe<LocalizedStringLoose>;
    type: HighlightType;
    typeName?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
};

export type HighlightFeed = BaseEntity & {
    __typename?: 'HighlightFeed';
    appBundleId: Scalars['String'];
    appUserId: Scalars['ObjectId'];
    appointmentId?: Maybe<Scalars['ObjectId']>;
    bodyMarkup?: Maybe<Scalars['String']>;
    closeable: Scalars['Boolean'];
    closed: Scalars['Boolean'];
    createdAt: Scalars['DateTime'];
    displayAsModal?: Maybe<Scalars['Boolean']>;
    displayAsModalButtonText?: Maybe<Scalars['String']>;
    effectiveDate: Scalars['DateTime'];
    expiryDate: Scalars['DateTime'];
    headerMarkup?: Maybe<Scalars['String']>;
    highlightId: Scalars['ObjectId'];
    highlightName?: Maybe<Scalars['String']>;
    highlighted: Scalars['Boolean'];
    icon: HighlightIcon;
    id: Scalars['ObjectId'];
    image?: Maybe<Scalars['String']>;
    imageContentId?: Maybe<Scalars['ObjectId']>;
    isTestData?: Maybe<Scalars['Boolean']>;
    language: Language;
    linkMarkup?: Maybe<Scalars['String']>;
    notificationText?: Maybe<Scalars['String']>;
    organizationId?: Maybe<Scalars['ObjectId']>;
    patientId: Scalars['ObjectId'];
    priority: Scalars['Float'];
    sendNotification: Scalars['Boolean'];
    simpleContentId?: Maybe<Scalars['ObjectId']>;
    surveyId?: Maybe<Scalars['ObjectId']>;
    tags?: Maybe<Array<Scalars['ObjectId']>>;
    timelineId?: Maybe<Scalars['ObjectId']>;
    titleMarkup?: Maybe<Scalars['String']>;
    type: HighlightType;
    updatedAt: Scalars['DateTime'];
};

export type HighlightFeedFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<HighlightFeedUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type HighlightFeedItem = {
    __typename?: 'HighlightFeedItem';
    appointmentId?: Maybe<Scalars['String']>;
    availableFrom?: Maybe<Scalars['DateTime']>;
    availableUntil?: Maybe<Scalars['DateTime']>;
    body?: Maybe<Scalars['String']>;
    closeable?: Maybe<Scalars['Boolean']>;
    displayAsModal?: Maybe<Scalars['Boolean']>;
    displayAsModalButtonText?: Maybe<Scalars['String']>;
    header?: Maybe<Scalars['String']>;
    highlightId?: Maybe<Scalars['String']>;
    highlightName?: Maybe<Scalars['String']>;
    highlighted?: Maybe<Scalars['Boolean']>;
    icon?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    imageURL?: Maybe<Scalars['String']>;
    priority?: Maybe<Scalars['Float']>;
    surveyId?: Maybe<Scalars['String']>;
    target?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
};

export type HighlightFeedNotification = {
    __typename?: 'HighlightFeedNotification';
    id?: Maybe<Scalars['String']>;
    text?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export type HighlightFeedOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type HighlightFeedPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type HighlightFeedPaginationQueryResponse = PaginationResponse & {
    __typename?: 'HighlightFeedPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<HighlightFeed>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type HighlightFeedQueryInput = {
    filter?: InputMaybe<HighlightFeedFilterInput>;
    orderBy?: InputMaybe<HighlightFeedOrderByInput>;
    pagination?: InputMaybe<HighlightFeedPaginationInput>;
};

export type HighlightFeedResult = {
    __typename?: 'HighlightFeedResult';
    itemcount?: Maybe<Scalars['Float']>;
    items?: Maybe<Array<Maybe<HighlightFeedItem>>>;
};

export type HighlightFeedTimelinesNotFoundInput = {
    appUserId?: InputMaybe<Scalars['String']>;
    timelines?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type HighlightFeedUpdateInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    appUserId?: InputMaybe<Scalars['ObjectId']>;
    appointmentId?: InputMaybe<Scalars['ObjectId']>;
    bodyMarkup?: InputMaybe<Scalars['String']>;
    closeable?: InputMaybe<Scalars['Boolean']>;
    closed?: InputMaybe<Scalars['Boolean']>;
    displayAsModal?: InputMaybe<Scalars['Boolean']>;
    displayAsModalButtonText?: InputMaybe<Scalars['String']>;
    effectiveDate?: InputMaybe<Scalars['DateTime']>;
    expiryDate?: InputMaybe<Scalars['DateTime']>;
    headerMarkup?: InputMaybe<Scalars['String']>;
    highlightId?: InputMaybe<Scalars['ObjectId']>;
    highlightName?: InputMaybe<Scalars['String']>;
    highlighted?: InputMaybe<Scalars['Boolean']>;
    icon?: InputMaybe<HighlightIcon>;
    image?: InputMaybe<Scalars['String']>;
    imageContentId?: InputMaybe<Scalars['ObjectId']>;
    language?: InputMaybe<Language>;
    linkMarkup?: InputMaybe<Scalars['String']>;
    notificationText?: InputMaybe<Scalars['String']>;
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    priority?: InputMaybe<Scalars['Float']>;
    sendNotification?: InputMaybe<Scalars['Boolean']>;
    simpleContentId?: InputMaybe<Scalars['ObjectId']>;
    surveyId?: InputMaybe<Scalars['ObjectId']>;
    tags?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    timelineId?: InputMaybe<Scalars['ObjectId']>;
    titleMarkup?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<HighlightType>;
};

export type HighlightFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<HighlightUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

/** Highlight icon */
export enum HighlightIcon {
    AppUpdate = 'appUpdate',
    Appointment = 'appointment',
    Message = 'message',
    None = 'none',
    Personalize = 'personalize',
    Reminder = 'reminder',
    Resource = 'resource',
    Survey = 'survey',
    ToDo = 'toDo',
    WhoToCall = 'whoToCall',
}

export type HighlightInput = {
    bodyMarkup?: InputMaybe<LocalizedStringLooseInput>;
    clockType: ClockType;
    closeable: Scalars['Boolean'];
    displayAsModal?: InputMaybe<Scalars['Boolean']>;
    displayAsModalButtonText?: InputMaybe<LocalizedStringLooseInput>;
    duration: Scalars['Float'];
    durationTimeUnit: TimeUnit;
    endDate?: InputMaybe<Scalars['DateTime']>;
    excludeApps?: InputMaybe<Array<Scalars['String']>>;
    headerMarkup?: InputMaybe<LocalizedStringLooseInput>;
    highlighted: Scalars['Boolean'];
    icon: HighlightIcon;
    imageContentId?: InputMaybe<Scalars['ObjectId']>;
    includeApps?: InputMaybe<Array<Scalars['String']>>;
    linkText?: InputMaybe<LocalizedStringLooseInput>;
    name: Scalars['String'];
    notificationText?: InputMaybe<LocalizedStringLooseInput>;
    offsetDays?: InputMaybe<Scalars['Float']>;
    offsetMonths?: InputMaybe<Scalars['Float']>;
    offsetSequence?: InputMaybe<Scalars['Float']>;
    offsetYears?: InputMaybe<Scalars['Float']>;
    priority: Scalars['Float'];
    shouldSendNotification: Scalars['Boolean'];
    simpleContentId?: InputMaybe<Scalars['ObjectId']>;
    startDate?: InputMaybe<Scalars['DateTime']>;
    surveyId?: InputMaybe<Scalars['ObjectId']>;
    tags?: InputMaybe<Array<Scalars['String']>>;
    timelineId?: InputMaybe<Scalars['ObjectId']>;
    titleMarkup?: InputMaybe<LocalizedStringLooseInput>;
    type: HighlightType;
};

export type HighlightListFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<HighlightUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type HighlightListInput = {
    filter?: InputMaybe<HighlightListFilterInput>;
    orderBy?: InputMaybe<HighlightListOrderByInput>;
    pagination?: InputMaybe<HighlightListPaginationInput>;
};

export type HighlightListOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type HighlightListPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type HighlightListResponse = PaginationResponse & {
    __typename?: 'HighlightListResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Highlight>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type HighlightOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type HighlightPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type HighlightQueryInput = {
    filter?: InputMaybe<HighlightFilterInput>;
    orderBy?: InputMaybe<HighlightOrderByInput>;
    pagination?: InputMaybe<HighlightPaginationInput>;
};

/** Highlight content type */
export enum HighlightType {
    Alert = 'alert',
    AppLink = 'appLink',
    AppointmentTemplate = 'appointmentTemplate',
    QuestionCard = 'questionCard',
}

export type HighlightUpdateInput = {
    bodyMarkup?: InputMaybe<LocalizedStringLooseInput>;
    clockType?: InputMaybe<ClockType>;
    closeable?: InputMaybe<Scalars['Boolean']>;
    displayAsModal?: InputMaybe<Scalars['Boolean']>;
    displayAsModalButtonText?: InputMaybe<LocalizedStringLooseInput>;
    duration?: InputMaybe<Scalars['Float']>;
    durationTimeUnit?: InputMaybe<TimeUnit>;
    endDate?: InputMaybe<Scalars['DateTime']>;
    excludeApps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    headerMarkup?: InputMaybe<LocalizedStringLooseInput>;
    highlighted?: InputMaybe<Scalars['Boolean']>;
    icon?: InputMaybe<HighlightIcon>;
    imageContentId?: InputMaybe<Scalars['ObjectId']>;
    includeApps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    linkText?: InputMaybe<LocalizedStringLooseInput>;
    name?: InputMaybe<Scalars['String']>;
    notificationText?: InputMaybe<LocalizedStringLooseInput>;
    offsetDays?: InputMaybe<Scalars['Float']>;
    offsetMonths?: InputMaybe<Scalars['Float']>;
    offsetYears?: InputMaybe<Scalars['Float']>;
    priority?: InputMaybe<Scalars['Float']>;
    shouldSendNotification?: InputMaybe<Scalars['Boolean']>;
    simpleContentId?: InputMaybe<Scalars['ObjectId']>;
    startDate?: InputMaybe<Scalars['DateTime']>;
    surveyId?: InputMaybe<Scalars['ObjectId']>;
    tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    timelineId?: InputMaybe<Scalars['ObjectId']>;
    titleMarkup?: InputMaybe<LocalizedStringLooseInput>;
    type?: InputMaybe<HighlightType>;
};

export type HighlightsForTimelineInput = {
    limit?: InputMaybe<Scalars['Float']>;
    skip?: InputMaybe<Scalars['Float']>;
    timelineId?: InputMaybe<Scalars['String']>;
};

/** Identity providers */
export enum Idp_Names {
    Engage = 'ENGAGE',
    Progyny = 'PROGYNY',
    Sydney = 'SYDNEY',
}

export type ImportFamilyDataInput = {
    dbname: Scalars['String'];
    familyData: Scalars['String'];
};

export type ImportFamilyDataResponse = MutationResponse & {
    __typename?: 'ImportFamilyDataResponse';
    familyData?: Maybe<Scalars['JSON']>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type IncrementBatchTaskInput = {
    completedRecords?: InputMaybe<Scalars['Float']>;
    id: Scalars['String'];
};

export type Indication = {
    __typename?: 'Indication';
    /** Alternate or translation codes for the reason the medication was given */
    altCodes?: Maybe<Array<RedoxNameAndCodeDetails>>;
    code?: Maybe<Scalars['String']>;
    /** ID of the coding system to which the code belongs in OID format. */
    codeSystem?: Maybe<Scalars['String']>;
    /** Coding system to which the code belongs (e.g. SNOMED CT, LOINC, RxNorm, CVX, ICD9, ICD10). */
    codeSystemName?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
};

export type IndicationInput = {
    /** Alternate or translation codes for the reason the medication was given */
    altCodes?: InputMaybe<Array<RedoxNameAndCodeDetailsInput>>;
    code?: InputMaybe<Scalars['String']>;
    /** ID of the coding system to which the code belongs in OID format. */
    codeSystem?: InputMaybe<Scalars['String']>;
    /** Coding system to which the code belongs (e.g. SNOMED CT, LOINC, RxNorm, CVX, ICD9, ICD10). */
    codeSystemName?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type Internals = {
    __typename?: 'Internals';
    schemaV2?: Maybe<Scalars['Boolean']>;
};

export type Intervention = BaseEntity & {
    __typename?: 'Intervention';
    advocateTaskTemplate?: Maybe<AdvocateTaskTemplate>;
    advocateTaskTemplateId?: Maybe<Scalars['ObjectId']>;
    choicesToUpdate?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
    conditionType?: Maybe<ConditionType>;
    conditionTypeId?: Maybe<Scalars['ObjectId']>;
    conditionTypes?: Maybe<ConditionType>;
    createdAt: Scalars['DateTime'];
    dateTrigger?: Maybe<DateTrigger>;
    dateTriggerId?: Maybe<Scalars['ObjectId']>;
    dateType?: Maybe<InterventionDateType>;
    eventTrigger?: Maybe<EventTrigger>;
    eventTriggerId?: Maybe<Scalars['ObjectId']>;
    excludeApps?: Maybe<Array<Scalars['String']>>;
    id: Scalars['ObjectId'];
    includeApps?: Maybe<Array<Scalars['String']>>;
    isTestData?: Maybe<Scalars['Boolean']>;
    messageCenterTemplateId?: Maybe<Scalars['ObjectId']>;
    messageTemplate?: Maybe<MessageTemplate>;
    messageTemplateId?: Maybe<Scalars['ObjectId']>;
    priority?: Maybe<Scalars['Float']>;
    profileDef?: Maybe<UserProfileDef>;
    profileDefId?: Maybe<Scalars['ObjectId']>;
    profileValue?: Maybe<InterventionProfileValue>;
    tag?: Maybe<Tag>;
    tagId?: Maybe<Scalars['ObjectId']>;
    trendTrigger?: Maybe<TrendTrigger>;
    trendTriggerId?: Maybe<Scalars['ObjectId']>;
    type: InterventionType;
    updatedAt: Scalars['DateTime'];
    valueType?: Maybe<InterventionValueType>;
};

/** Intervention ProfileDef date type. */
export enum InterventionDateType {
    Fixed = 'Fixed',
    NotApplicable = 'NotApplicable',
    Today = 'Today',
}

export type InterventionFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<InterventionUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type InterventionInput = {
    advocateTaskTemplateId?: InputMaybe<Scalars['ObjectId']>;
    choicesToUpdate?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    conditionTypeId?: InputMaybe<Scalars['ObjectId']>;
    dateTriggerId?: InputMaybe<Scalars['ObjectId']>;
    dateType?: InputMaybe<InterventionDateType>;
    eventTriggerId?: InputMaybe<Scalars['ObjectId']>;
    excludeApps?: InputMaybe<Array<Scalars['String']>>;
    includeApps?: InputMaybe<Array<Scalars['String']>>;
    messageCenterTemplateId?: InputMaybe<Scalars['ObjectId']>;
    messageTemplateId?: InputMaybe<Scalars['ObjectId']>;
    priority?: InputMaybe<Scalars['Float']>;
    profileDefId?: InputMaybe<Scalars['ObjectId']>;
    profileValue?: InputMaybe<InterventionProfileValueInput>;
    tagId?: InputMaybe<Scalars['ObjectId']>;
    trendTriggerId?: InputMaybe<Scalars['ObjectId']>;
    type: InterventionType;
    valueType?: InputMaybe<InterventionValueType>;
};

export type InterventionOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type InterventionPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type InterventionPaginationQueryResponse = PaginationResponse & {
    __typename?: 'InterventionPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Intervention>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type InterventionProfileValue = {
    __typename?: 'InterventionProfileValue';
    bool?: Maybe<Scalars['Boolean']>;
    choiceId?: Maybe<Scalars['ObjectId']>;
    choices?: Maybe<Array<Maybe<Scalars['String']>>>;
    date?: Maybe<Scalars['DateTime']>;
    num?: Maybe<Scalars['Float']>;
    str?: Maybe<Scalars['String']>;
};

export type InterventionProfileValueInput = {
    bool?: InputMaybe<Scalars['Boolean']>;
    choiceId?: InputMaybe<Scalars['ObjectId']>;
    choices?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    date?: InputMaybe<Scalars['DateTime']>;
    num?: InputMaybe<Scalars['Float']>;
    str?: InputMaybe<Scalars['String']>;
};

export type InterventionQueryInput = {
    filter?: InputMaybe<InterventionFilterInput>;
    orderBy?: InputMaybe<InterventionOrderByInput>;
    pagination?: InputMaybe<InterventionPaginationInput>;
};

/** Which action to invoke. */
export enum InterventionType {
    AdvocateTaskTemplate = 'AdvocateTaskTemplate',
    AfterHoursAlert = 'AfterHoursAlert',
    AfterHoursAlertEmail = 'AfterHoursAlertEmail',
    ClearProfileChoice = 'ClearProfileChoice',
    ClearProfileValue = 'ClearProfileValue',
    EndEpisode = 'EndEpisode',
    MessagePatient = 'MessagePatient',
    SendMessageCenterTemplate = 'SendMessageCenterTemplate',
    SetProfileChoice = 'SetProfileChoice',
    SetProfileValue = 'SetProfileValue',
    StartEpisode = 'StartEpisode',
}

export type InterventionUpdateInput = {
    advocateTaskTemplateId?: InputMaybe<Scalars['ObjectId']>;
    choicesToUpdate?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    conditionTypeId?: InputMaybe<Scalars['ObjectId']>;
    dateTriggerId?: InputMaybe<Scalars['ObjectId']>;
    dateType?: InputMaybe<InterventionDateType>;
    eventTriggerId?: InputMaybe<Scalars['ObjectId']>;
    excludeApps?: InputMaybe<Array<Scalars['String']>>;
    includeApps?: InputMaybe<Array<Scalars['String']>>;
    messageCenterTemplateId?: InputMaybe<Scalars['ObjectId']>;
    messageTemplateId?: InputMaybe<Scalars['ObjectId']>;
    priority?: InputMaybe<Scalars['Float']>;
    profileDefId?: InputMaybe<Scalars['ObjectId']>;
    profileValue?: InputMaybe<InterventionProfileValueInput>;
    tagId?: InputMaybe<Scalars['ObjectId']>;
    trendTriggerId?: InputMaybe<Scalars['ObjectId']>;
    type?: InputMaybe<InterventionType>;
    valueType?: InputMaybe<InterventionValueType>;
};

/** Intervention value type. */
export enum InterventionValueType {
    Diastolic = 'Diastolic',
    EpdsHarmingYourself = 'EPDSHarmingYourself',
    Fasting = 'Fasting',
    None = 'None',
    PostBreakfast = 'PostBreakfast',
    PostDinner = 'PostDinner',
    PostLunch = 'PostLunch',
    Systolic = 'Systolic',
    TrackerGlucose = 'TrackerGlucose',
}

export type InviteUserInput = {
    birthDate: Scalars['String'];
    email: Scalars['String'];
    firstName: Scalars['String'];
    isTestData?: InputMaybe<Scalars['Boolean']>;
    lastName: Scalars['String'];
};

export type InviteUserMutationResponse = MutationResponse & {
    __typename?: 'InviteUserMutationResponse';
    data?: Maybe<AppUserResponseData>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type InvoicesPaymentStatusResponse = PaginationResponse & {
    __typename?: 'InvoicesPaymentStatusResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<AffiliateInvoice>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type JiraInfo = {
    __typename?: 'JiraInfo';
    assignee?: Maybe<Scalars['String']>;
    dateCompleted?: Maybe<Scalars['String']>;
    dateExpected?: Maybe<Scalars['String']>;
    dateRequested: Scalars['String'];
    requestStatus: Scalars['String'];
    requestor: Scalars['String'];
};

export type JiraInfoInput = {
    url: Scalars['String'];
};

/** Language. */
export enum Language {
    En = 'en',
    Es = 'es',
}

export type LatesTrackersByTypesInput = {
    patientId?: InputMaybe<Scalars['String']>;
    trackerTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LineItem = {
    __typename?: 'LineItem';
    amount?: Maybe<Scalars['String']>;
    claimId: Scalars['ObjectId'];
    description?: Maybe<Scalars['String']>;
};

export type LinkDataType = {
    __typename?: 'LinkDataType';
    label?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

export type LocalizedString = {
    __typename?: 'LocalizedString';
    en: Scalars['String'];
    es?: Maybe<Scalars['String']>;
};

export type LocalizedStringInput = {
    en: Scalars['String'];
    es?: InputMaybe<Scalars['String']>;
};

export type LocalizedStringLoose = {
    __typename?: 'LocalizedStringLoose';
    en?: Maybe<Scalars['String']>;
    es?: Maybe<Scalars['String']>;
};

export type LocalizedStringLooseInput = {
    en?: InputMaybe<Scalars['String']>;
    es?: InputMaybe<Scalars['String']>;
};

export type LoginAppUserInput = {
    appBundleId: Scalars['String'];
    email: Scalars['String'];
    password: Scalars['String'];
};

export type LoginAuthMethodInput = {
    email?: InputMaybe<Scalars['String']>;
};

export type LoginAuthMethodResponse = {
    __typename?: 'LoginAuthMethodResponse';
    samlRequest?: Maybe<Scalars['String']>;
    samlUrl?: Maybe<Scalars['String']>;
    samlUrlBase?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
};

export type LoginUserInput = {
    email: Scalars['String'];
    /** Used to update password on first login or password reset */
    newPassword?: InputMaybe<Scalars['String']>;
    /** Password field is optional because in some cases the password is supplied through the password token field */
    password?: InputMaybe<Scalars['String']>;
    /** Token containing tempPassword, used for ResetPassword & AcceptInvite User Flows */
    passwordToken?: InputMaybe<Scalars['String']>;
};

export type LoginUserResponse = MutationResponse & {
    __typename?: 'LoginUserResponse';
    message: Scalars['String'];
    newPasswordRequired?: Maybe<Scalars['Boolean']>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
    tempPasswordToken?: Maybe<Scalars['String']>;
    tokens?: Maybe<CognitoTokens>;
};

export type LogoutMutationResponse = MutationResponse & {
    __typename?: 'LogoutMutationResponse';
    data?: Maybe<AppUserResponseData>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type MarkupElement = {
    __typename?: 'MarkupElement';
    dateEdited?: Maybe<Scalars['DateTime']>;
    editedBy?: Maybe<Scalars['String']>;
    markup: Scalars['String'];
    revisionNumber: Scalars['Float'];
};

export type MarkupElementInput = {
    markup: Scalars['String'];
    revisionNumber: Scalars['Float'];
};

export type MassUpdateError = {
    __typename?: 'MassUpdateError';
    errorText: Scalars['String'];
    patientId: Scalars['ObjectId'];
};

export type MassUpdateErrorInput = {
    errorText: Scalars['String'];
    patientId: Scalars['ObjectId'];
};

export type MassUpdatePatientFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<MassUpdatePatientsUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type MassUpdatePatientOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type MassUpdatePatientPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type MassUpdatePatientPaginationQueryResponse = PaginationResponse & {
    __typename?: 'MassUpdatePatientPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<MassUpdatePatients>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type MassUpdatePatientQueryInput = {
    filter?: InputMaybe<MassUpdatePatientFilterInput>;
    orderBy?: InputMaybe<MassUpdatePatientOrderByInput>;
    pagination?: InputMaybe<MassUpdatePatientPaginationInput>;
};

export type MassUpdatePatients = BaseEntity & {
    __typename?: 'MassUpdatePatients';
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    patients?: Maybe<Array<Scalars['ObjectId']>>;
    updatedAt: Scalars['DateTime'];
};

export type MassUpdatePatientsInput = {
    name: Scalars['String'];
    patients?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type MassUpdatePatientsUpdateInput = {
    name?: InputMaybe<Scalars['String']>;
    patients?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type MassUpdateTask = BaseEntity & {
    __typename?: 'MassUpdateTask';
    conditionTypeId?: Maybe<Scalars['ObjectId']>;
    createdAt: Scalars['DateTime'];
    errors?: Maybe<Array<MassUpdateError>>;
    finishedAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    massUpdatePatientsId: Scalars['ObjectId'];
    patientIds?: Maybe<Array<Scalars['String']>>;
    postToSlack: Scalars['Boolean'];
    processType: MassUpdateTaskProcess;
    profileValues: Array<MassUpdateTaskPv>;
    sendEmail: Scalars['Boolean'];
    startedAt?: Maybe<Scalars['DateTime']>;
    status: MassUpdateTaskStatus;
    submittedByEmail?: Maybe<Scalars['String']>;
    submittedByName?: Maybe<Scalars['String']>;
    tagIds?: Maybe<Array<Scalars['String']>>;
    updatedAt: Scalars['DateTime'];
};

export type MassUpdateTaskFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<MassUpdateTaskUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type MassUpdateTaskInput = {
    conditionTypeId?: InputMaybe<Scalars['ObjectId']>;
    errors?: InputMaybe<Array<MassUpdateErrorInput>>;
    finishedAt?: InputMaybe<Scalars['DateTime']>;
    massUpdatePatientsId: Scalars['ObjectId'];
    patientIds?: InputMaybe<Array<Scalars['String']>>;
    postToSlack: Scalars['Boolean'];
    processType: MassUpdateTaskProcess;
    profileValues?: InputMaybe<Array<MassUpdateTaskPvInput>>;
    sendEmail: Scalars['Boolean'];
    startedAt?: InputMaybe<Scalars['DateTime']>;
    status: MassUpdateTaskStatus;
    submittedByEmail?: InputMaybe<Scalars['String']>;
    submittedByName?: InputMaybe<Scalars['String']>;
    tagIds?: InputMaybe<Array<Scalars['String']>>;
};

export type MassUpdateTaskListFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<MassUpdateTaskUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type MassUpdateTaskListInput = {
    filter?: InputMaybe<MassUpdateTaskListFilterInput>;
    orderBy?: InputMaybe<MassUpdateTaskListOrderByInput>;
    pagination?: InputMaybe<MassUpdateTaskListPaginationInput>;
};

export type MassUpdateTaskListOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type MassUpdateTaskListPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type MassUpdateTaskListResponse = PaginationResponse & {
    __typename?: 'MassUpdateTaskListResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<MassUpdateTask>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type MassUpdateTaskOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type MassUpdateTaskPv = {
    __typename?: 'MassUpdateTaskPV';
    clear?: Maybe<Scalars['Boolean']>;
    profileVariableDefId: Scalars['ObjectId'];
    pvName: Scalars['String'];
    type: Scalars['String'];
    valueBool?: Maybe<Scalars['Boolean']>;
    valueDate?: Maybe<Scalars['DateTime']>;
    valueId?: Maybe<Scalars['ObjectId']>;
    valueIds?: Maybe<Array<Scalars['ObjectId']>>;
    valueNum?: Maybe<Scalars['Float']>;
    valueStr?: Maybe<Scalars['String']>;
};

export type MassUpdateTaskPvInput = {
    clear?: InputMaybe<Scalars['Boolean']>;
    profileVariableDefId: Scalars['ObjectId'];
    pvName: Scalars['String'];
    type: Scalars['String'];
    valueBool?: InputMaybe<Scalars['Boolean']>;
    valueDate?: InputMaybe<Scalars['DateTime']>;
    valueId?: InputMaybe<Scalars['ObjectId']>;
    valueIds?: InputMaybe<Array<Scalars['ObjectId']>>;
    valueNum?: InputMaybe<Scalars['Float']>;
    valueStr?: InputMaybe<Scalars['String']>;
};

export type MassUpdateTaskPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

/** Type of processing for the Mass Update. */
export enum MassUpdateTaskProcess {
    DeletePatient = 'deletePatient',
    EndEpisode = 'endEpisode',
    ProfileValue = 'profileValue',
    RebuildCache = 'rebuildCache',
    RecalcTags = 'recalcTags',
}

export type MassUpdateTaskQueryInput = {
    filter?: InputMaybe<MassUpdateTaskFilterInput>;
    orderBy?: InputMaybe<MassUpdateTaskOrderByInput>;
    pagination?: InputMaybe<MassUpdateTaskPaginationInput>;
};

/** Mass Update Task status. */
export enum MassUpdateTaskStatus {
    Completed = 'completed',
    Error = 'error',
    InSetup = 'inSetup',
    Running = 'running',
    Submitted = 'submitted',
}

export type MassUpdateTaskUpdateInput = {
    conditionTypeId?: InputMaybe<Scalars['ObjectId']>;
    errors?: InputMaybe<Array<MassUpdateErrorInput>>;
    finishedAt?: InputMaybe<Scalars['DateTime']>;
    massUpdatePatientsId?: InputMaybe<Scalars['ObjectId']>;
    patientIds?: InputMaybe<Array<Scalars['String']>>;
    postToSlack?: InputMaybe<Scalars['Boolean']>;
    processType?: InputMaybe<MassUpdateTaskProcess>;
    profileValues?: InputMaybe<Array<MassUpdateTaskPvInput>>;
    sendEmail?: InputMaybe<Scalars['Boolean']>;
    startedAt?: InputMaybe<Scalars['DateTime']>;
    status?: InputMaybe<MassUpdateTaskStatus>;
    submittedByEmail?: InputMaybe<Scalars['String']>;
    submittedByName?: InputMaybe<Scalars['String']>;
    tagIds?: InputMaybe<Array<Scalars['String']>>;
};

export type Measurement = BaseEntity & {
    __typename?: 'Measurement';
    comment?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    device?: Maybe<Device>;
    externalId?: Maybe<Scalars['String']>;
    flagged?: Maybe<Scalars['Boolean']>;
    hasHistory?: Maybe<Scalars['Boolean']>;
    id: Scalars['ObjectId'];
    inputType: MeasurementInputSource;
    isTestData?: Maybe<Scalars['Boolean']>;
    organizationId?: Maybe<Scalars['ObjectId']>;
    patient?: Maybe<Patient>;
    reviewed?: Maybe<Scalars['Boolean']>;
    takenDate?: Maybe<Scalars['DateTime']>;
    timezoneName?: Maybe<Scalars['String']>;
    type?: Maybe<MeasurementType>;
    updatedAt: Scalars['DateTime'];
    value: MeasurementValue;
};

export type MeasurementFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<MeasurementUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type MeasurementInput = {
    comment?: InputMaybe<Scalars['String']>;
    deviceId?: InputMaybe<Scalars['ObjectId']>;
    externalId?: InputMaybe<Scalars['String']>;
    inputType: MeasurementInputSource;
    patientId: Scalars['ObjectId'];
    reviewed?: InputMaybe<Scalars['Boolean']>;
    takenDate?: InputMaybe<Scalars['DateTime']>;
    timezoneName?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<MeasurementType>;
    value: MeasurementValueInput;
};

/** Source of where the measurement value came from */
export enum MeasurementInputSource {
    Bluetooth = 'bluetooth',
    Manual = 'manual',
}

export type MeasurementOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type MeasurementPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type MeasurementPaginationQueryResponse = PaginationResponse & {
    __typename?: 'MeasurementPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Measurement>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type MeasurementQueryInput = {
    filter?: InputMaybe<MeasurementFilterInput>;
    orderBy?: InputMaybe<MeasurementOrderByInput>;
    pagination?: InputMaybe<MeasurementPaginationInput>;
};

/** Type of measurement: bloodPressure, weight etc */
export enum MeasurementType {
    BloodGlucose = 'bloodGlucose',
    BloodPressure = 'bloodPressure',
    DailyMeasurement = 'dailyMeasurement',
    Movement = 'movement',
    Weight = 'weight',
}

export type MeasurementUpdateInput = {
    comment?: InputMaybe<Scalars['String']>;
    deviceId?: InputMaybe<Scalars['ObjectId']>;
    externalId?: InputMaybe<Scalars['String']>;
    inputType?: InputMaybe<MeasurementInputSource>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    reviewed?: InputMaybe<Scalars['Boolean']>;
    takenDate?: InputMaybe<Scalars['DateTime']>;
    type?: InputMaybe<MeasurementType>;
    value?: InputMaybe<MeasurementValueInput>;
};

export type MeasurementValue = {
    __typename?: 'MeasurementValue';
    bloodGlucose?: Maybe<BloodGlucoseMeasurement>;
    bloodPressure?: Maybe<BloodPressureMeasurement>;
    dailyMeasurement?: Maybe<DailyMeasurement>;
    movement?: Maybe<MovementMeasurement>;
    weight?: Maybe<WeightMeasurement>;
};

export type MeasurementValueInput = {
    bloodGlucose?: InputMaybe<BloodGlucoseMeasurementInput>;
    bloodPressure?: InputMaybe<BloodPressureMeasurementInput>;
    dailyMeasurement?: InputMaybe<DailyMeasurementInput>;
    movement?: InputMaybe<MovementMeasurementInput>;
    weight?: InputMaybe<WeightMeasurementInput>;
};

export type Medication = {
    __typename?: 'Medication';
    dose?: Maybe<MedicationDose>;
    endDate?: Maybe<Scalars['DateTime']>;
    /** Free text instructions for the medication. Typically instructing patient on the proper means and timing for the use of the medication */
    freeTextSig?: Maybe<Scalars['String']>;
    frequency?: Maybe<MedicationFrequency>;
    indications?: Maybe<Array<Maybe<Indication>>>;
    /** Whether the medication is to be taken on an as-needed basis */
    isPRN?: Maybe<Scalars['Boolean']>;
    /** Whether the medication is a prescription. */
    prescription?: Maybe<Scalars['Boolean']>;
    product?: Maybe<RedoxNameAndCodeDetails>;
    rate?: Maybe<MedicationDose>;
    /** The method by which the medication is delivered. */
    route?: Maybe<RedoxNameAndCodeDetails>;
    startDate?: Maybe<Scalars['DateTime']>;
    /** The status of the medication. */
    status?: Maybe<Scalars['String']>;
};

export type MedicationDose = {
    __typename?: 'MedicationDose';
    /** The size of the dose for pills, capsules, etc. */
    quantity?: Maybe<Scalars['String']>;
    /** The units of the dose. UCUM Units of Measure */
    units?: Maybe<Scalars['String']>;
};

export type MedicationDoseInput = {
    /** The size of the dose for pills, capsules, etc. */
    quantity?: InputMaybe<Scalars['String']>;
    /** The units of the dose. UCUM Units of Measure */
    units?: InputMaybe<Scalars['String']>;
};

export type MedicationFrequency = {
    __typename?: 'MedicationFrequency';
    /** How often the patient should be taking the medication */
    period?: Maybe<Scalars['String']>;
    /** How often the patient should be taking the medication at a maximum */
    periodMax?: Maybe<Scalars['String']>;
    /** Units for how often the patient should be taking the medication. UCUM Units of Measure */
    unit?: Maybe<Scalars['String']>;
};

export type MedicationFrequencyInput = {
    /** How often the patient should be taking the medication */
    period?: InputMaybe<Scalars['String']>;
    /** How often the patient should be taking the medication at a maximum */
    periodMax?: InputMaybe<Scalars['String']>;
    /** Units for how often the patient should be taking the medication. UCUM Units of Measure */
    unit?: InputMaybe<Scalars['String']>;
};

export type MedicationInput = {
    dose?: InputMaybe<MedicationDoseInput>;
    endDate?: InputMaybe<Scalars['DateTime']>;
    /** Free text instructions for the medication. Typically instructing patient on the proper means and timing for the use of the medication */
    freeTextSig?: InputMaybe<Scalars['String']>;
    frequency?: InputMaybe<MedicationFrequencyInput>;
    indications?: InputMaybe<Array<InputMaybe<IndicationInput>>>;
    /** Whether the medication is to be taken on an as-needed basis */
    isPRN?: InputMaybe<Scalars['Boolean']>;
    /** Whether the medication is a prescription. */
    prescription?: InputMaybe<Scalars['Boolean']>;
    product?: InputMaybe<RedoxNameAndCodeDetailsInput>;
    rate?: InputMaybe<MedicationDoseInput>;
    /** The method by which the medication is delivered. */
    route?: InputMaybe<RedoxNameAndCodeDetailsInput>;
    startDate?: InputMaybe<Scalars['DateTime']>;
    /** The status of the medication. */
    status?: InputMaybe<Scalars['String']>;
};

export type MessageCenterTemplate = BaseEntity & {
    __typename?: 'MessageCenterTemplate';
    /** Message Template Content */
    content: LocalizedString;
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    /** Name in the locale selected in context */
    localizedDocLink?: Maybe<Scalars['String']>;
    /** Name in the locale selected in context */
    localizedName?: Maybe<Scalars['String']>;
    /** Message Template Title */
    title?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
};

export type MessageCenterTemplateFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<MessageCenterTemplateUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type MessageCenterTemplateInput = {
    /** Message Template Content */
    content: LocalizedStringInput;
    /** Message Template Title */
    title?: InputMaybe<Scalars['String']>;
};

export type MessageCenterTemplateOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type MessageCenterTemplatePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type MessageCenterTemplatePaginationQueryResponse = PaginationResponse & {
    __typename?: 'MessageCenterTemplatePaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<MessageCenterTemplate>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type MessageCenterTemplateQueryInput = {
    filter?: InputMaybe<MessageCenterTemplateFilterInput>;
    orderBy?: InputMaybe<MessageCenterTemplateOrderByInput>;
    pagination?: InputMaybe<MessageCenterTemplatePaginationInput>;
};

export type MessageCenterTemplateUpdateInput = {
    /** Message Template Content */
    content?: InputMaybe<LocalizedStringInput>;
    /** Message Template Title */
    title?: InputMaybe<Scalars['String']>;
};

/** Type of related entry. */
export enum MessageEntryType {
    Appointment = 'appointment',
}

/** Status of the sent message. */
export enum MessageSendStatus {
    Error = 'error',
    MsgOverLimit = 'msgOverLimit',
    NoPhone = 'noPhone',
    OptOut = 'optOut',
    Pending = 'pending',
    Sent = 'sent',
}

export type MessageSubstitutionItem = {
    __typename?: 'MessageSubstitutionItem';
    configValueDefId?: Maybe<Scalars['ObjectId']>;
    failIfMissing?: Maybe<Scalars['Boolean']>;
    missingValueText: Scalars['String'];
    name: Scalars['String'];
    profileVariableDefId?: Maybe<Scalars['ObjectId']>;
    substitutionId?: Maybe<Scalars['Float']>;
    systemTokenId?: Maybe<Scalars['ObjectId']>;
};

export type MessageSubstitutionItemInput = {
    configValueDefId?: InputMaybe<Scalars['ObjectId']>;
    failIfMissing?: InputMaybe<Scalars['Boolean']>;
    missingValueText: Scalars['String'];
    name: Scalars['String'];
    profileVariableDefId?: InputMaybe<Scalars['ObjectId']>;
    substitutionId?: InputMaybe<Scalars['Float']>;
    systemTokenId?: InputMaybe<Scalars['ObjectId']>;
};

export type MessageTemplate = BaseEntity & {
    __typename?: 'MessageTemplate';
    createdAt: Scalars['DateTime'];
    emailBody?: Maybe<LocalizedStringLoose>;
    emailTitle?: Maybe<LocalizedStringLoose>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    notificationBody?: Maybe<LocalizedStringLoose>;
    substitutions?: Maybe<Array<MessageSubstitutionItem>>;
    textBody?: Maybe<LocalizedStringLoose>;
    updatedAt: Scalars['DateTime'];
};

export type MessageTemplateFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<MessageTemplateUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type MessageTemplateInput = {
    emailBody?: InputMaybe<LocalizedStringLooseInput>;
    emailTitle?: InputMaybe<LocalizedStringLooseInput>;
    name: Scalars['String'];
    notificationBody?: InputMaybe<LocalizedStringLooseInput>;
    substitutions?: InputMaybe<Array<MessageSubstitutionItemInput>>;
    textBody?: InputMaybe<LocalizedStringLooseInput>;
};

export type MessageTemplateListFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<MessageTemplateUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type MessageTemplateListInput = {
    filter?: InputMaybe<MessageTemplateListFilterInput>;
    orderBy?: InputMaybe<MessageTemplateListOrderByInput>;
    pagination?: InputMaybe<MessageTemplateListPaginationInput>;
};

export type MessageTemplateListOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type MessageTemplateListPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type MessageTemplateListResponse = PaginationResponse & {
    __typename?: 'MessageTemplateListResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<MessageTemplate>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type MessageTemplateOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type MessageTemplatePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type MessageTemplateQueryInput = {
    filter?: InputMaybe<MessageTemplateFilterInput>;
    orderBy?: InputMaybe<MessageTemplateOrderByInput>;
    pagination?: InputMaybe<MessageTemplatePaginationInput>;
};

export type MessageTemplateUpdateInput = {
    emailBody?: InputMaybe<LocalizedStringLooseInput>;
    emailTitle?: InputMaybe<LocalizedStringLooseInput>;
    name?: InputMaybe<Scalars['String']>;
    notificationBody?: InputMaybe<LocalizedStringLooseInput>;
    substitutions?: InputMaybe<Array<InputMaybe<MessageSubstitutionItemInput>>>;
    textBody?: InputMaybe<LocalizedStringLooseInput>;
};

/** How the message was sent. */
export enum MessageTransport {
    Email = 'email',
    Notification = 'notification',
    Text = 'text',
}

export type MoveMessagesInChatConversationInput = {
    chatRoomId?: InputMaybe<Scalars['ObjectId']>;
    messageIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    newConversationId?: InputMaybe<Scalars['ObjectId']>;
    startConversationId?: InputMaybe<Scalars['ObjectId']>;
    updatedStartedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MoveMessagesInChatConversationResponse = MutationResponse & {
    __typename?: 'MoveMessagesInChatConversationResponse';
    chatConversations?: Maybe<Array<Maybe<ChatConversation>>>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type MovementMeasurement = {
    __typename?: 'MovementMeasurement';
    /** Duration is an elapsed time value in string format hh:mm:ss */
    duration?: Maybe<Scalars['String']>;
};

export type MovementMeasurementInput = {
    /** Duration is an elapsed time value in string format hh:mm:ss */
    duration?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
    __typename?: 'Mutation';
    CreateOrUpdateAppointments?: Maybe<CreateOrUpdateAppointmentsResponse>;
    OAuthAPIPostSurveyAnswers?: Maybe<OAuthApiPostSurveyAnswers>;
    OAuthSignIn?: Maybe<OAuthSignIn>;
    UpdateMeasurementReviewed?: Maybe<UpdateMeasurementReviewedResponse>;
    acceptInvite?: Maybe<AppUserTokenResponse>;
    addApprovalsForApp?: Maybe<AddApprovalsForAppMutationResponse>;
    addNoteFromConsultant?: Maybe<AddNoteFromConsultantResponse>;
    addOrganizationInviteCode?: Maybe<AddOrganizationInviteCodeResponse>;
    addRedirectUrlToTTPatient?: Maybe<AddRedirectUrlToTtPatientResponse>;
    addToPatientList?: Maybe<AddToPatientListResponse>;
    approveAllForApp?: Maybe<ApproveAllForAppResponse>;
    articleCacheDeleteByArticleId?: Maybe<MutationResponse>;
    articleDeleteExpired?: Maybe<MutationResponse>;
    articleFeedCreateMany?: Maybe<MutationResponse>;
    articleFeedDeleteByAppUser?: Maybe<MutationResponse>;
    articleFeedDeleteByArticleId?: Maybe<MutationResponse>;
    articleFeedDeleteByPromotionId?: Maybe<MutationResponse>;
    articleFeedDeleteByTimelineId?: Maybe<MutationResponse>;
    articleFeedTimelinesNotFoundDelete?: Maybe<MutationResponse>;
    articleFeedUpsertMany?: Maybe<MutationResponse>;
    bulkUpdateProfileValues?: Maybe<BulkUpdateResponse>;
    changeAppUserPassword?: Maybe<AppUserTokenResponse>;
    changePatientOrganization?: Maybe<ChangePatientOrgResponse>;
    checkEligibility?: Maybe<EligibilityBulkRecheckResponse>;
    checkVCFEligibility?: Maybe<CheckEligibilityFormResponse>;
    clearPatientProfileKeyValues?: Maybe<PatientProfileValueResponse>;
    clearPatientProfileValue?: Maybe<PatientProfileValueResponse>;
    cloneArticle?: Maybe<CloneArticleMutationResponse>;
    cloneBioScreen?: Maybe<CloneBioScreenResponse>;
    cloneVariant?: Maybe<CloneVariantMutationResponse>;
    /** @deprecated Use the closeHighlightFeed instead. */
    closeAppTask?: Maybe<CloseAppTaskResponse>;
    closeHighlightFeed?: Maybe<CloseHighlightFeedResponse>;
    /** Complete all todo tasks by received ids. */
    completeAllTodoTasks?: Maybe<CompleteTodoTasksResponse>;
    completePotentialUserFlow?: Maybe<CompletePotentialUserFlowMutationResponse>;
    createAccessCode?: Maybe<EligibilityCreateAccessCodeResponse>;
    createAdvocateNotification?: Maybe<CreateAdvocateNotificationResponse>;
    createAdvocateTask?: Maybe<CreateAdvocateTaskResponse>;
    createAdvocateTaskEncoded?: Maybe<CreateAdvocateTaskResponse>;
    createAdvocateTaskTemplate?: Maybe<CreateAdvocateTaskTemplateResponse>;
    createAdvocateTaskTemplateEncoded?: Maybe<CreateAdvocateTaskTemplateEncodedResponse>;
    createAdvocateTaskType?: Maybe<CreateAdvocateTaskTypeResponse>;
    createAdvocateTasksForAppointment?: Maybe<CreateTasksForAppointmentResponse>;
    createAffiliateClaim?: Maybe<AffiliateClaimResponse>;
    createAffiliateClaimFromBuilder?: Maybe<AffiliateClaimResponse>;
    createAffiliateInvoice?: Maybe<CreateAffiliateInvoiceResponse>;
    createAppUser?: Maybe<CreateAppUserMutationResponse>;
    createAppUserArticle?: Maybe<CreateAppUserArticleResponse>;
    createAppUserEventTrigger?: Maybe<CreateAppUserEventTriggerResponse>;
    createAppUserForPatient?: Maybe<CreateAppUserForPatientResponse>;
    createAppUserForVCPatient: CreateAppUserForVcPatientResponse;
    createAppUserSSO?: Maybe<AppUserTokenResponse>;
    createAppUserTelemetry?: Maybe<CreateAppUserTelemetryResponse>;
    createAppUserTrendTrigger?: Maybe<CreateAppUserTrendTriggerResponse>;
    createApplication?: Maybe<CreateApplicationResponse>;
    createAppointment?: Maybe<CreateAppointmentResponse>;
    createArticle?: Maybe<CreateArticleResponse>;
    createArticleAppApprovals?: Maybe<CreateArticleAppApprovalsResponse>;
    createArticleApproval?: Maybe<CreateArticleApprovalResponse>;
    createArticleCache?: Maybe<CreateArticleCacheResponse>;
    createArticleCategory?: Maybe<CreateArticleCategoryResponse>;
    createArticleEncoded?: Maybe<CreateArticleEncodedResponse>;
    createArticleFeed?: Maybe<CreateArticleFeedResponse>;
    createArticleGroup?: Maybe<CreateArticleGroupResponse>;
    createArticlePromotion?: Maybe<CreateArticlePromotionResponse>;
    createAsyncJob?: Maybe<CreateAsyncJobResponse>;
    createBatchTask?: Maybe<CreateBatchTaskResponse>;
    createBioScreen?: Maybe<CreateBioScreenResponse>;
    createCalendar?: Maybe<CreateCalendarResponse>;
    createCarePlan?: Maybe<CreateCarePlanResponse>;
    createCareTeam?: Maybe<CreateCareTeamResponse>;
    createCareTeamContact?: Maybe<CreateCareTeamContactResponse>;
    createCareTeamMember?: Maybe<CreateCareTeamMemberResponse>;
    createCareTeamMemberType?: Maybe<CreateCareTeamMemberTypeResponse>;
    createChatConversation?: Maybe<CreateChatConversationResponse>;
    createChatMessage?: Maybe<CreateChatMessageResponse>;
    createChatMessageEncoded?: Maybe<CreateChatMessageResponse>;
    createChatMessageForAppUser?: Maybe<CreateChatMessageForAppUserResponse>;
    createChatRoom?: Maybe<CreateChatRoomResponse>;
    createClinicalSummary?: Maybe<CreateClinicalSummaryResponse>;
    createCompoundQuestion?: Maybe<CreateCompoundQuestionResponse>;
    createCondition?: Maybe<CreateConditionResponse>;
    createConditionType?: Maybe<CreateConditionTypeResponse>;
    createConfigExpression?: Maybe<CreateConfigExpressionResponse>;
    createConfigValue?: Maybe<CreateConfigValueResponse>;
    createConfigValueDef?: Maybe<CreateConfigValueDefResponse>;
    createContentDirectory?: Maybe<CreateContentDirectoryResponse>;
    createDateTrigger?: Maybe<CreateDateTriggerResponse>;
    createDependentUser?: Maybe<CreateDependentUserMutationResponse>;
    createDevice?: Maybe<CreateDeviceResponse>;
    createDirectoryCache?: Maybe<CreateDirectoryCacheResponse>;
    createEligibilityMatch?: Maybe<CreateEligibilityMatchResponse>;
    createEpisode?: Maybe<CreateEpisodeResponse>;
    createEventTrigger?: Maybe<CreateEventTriggerResponse>;
    createHACreatedTodoTask: AddHaTodoTaskResponse;
    createHealthPlan?: Maybe<CreateHealthPlanResponse>;
    createHighlight?: Maybe<CreateHighlightResponse>;
    createHighlightFeed?: Maybe<CreateHighlightFeedResponse>;
    createIntervention?: Maybe<CreateInterventionResponse>;
    createManyAdvocateNotifications?: Maybe<CreateManyAdvocateNotificationsResponse>;
    createManyAdvocateTaskTemplates?: Maybe<CreateManyAdvocateTaskTemplatesResponse>;
    createManyAdvocateTaskTypes?: Maybe<CreateManyAdvocateTaskTypesResponse>;
    createManyAdvocateTasks?: Maybe<CreateManyAdvocateTasksResponse>;
    createManyAffiliateClaims?: Maybe<CreateManyAffiliateClaimsResponse>;
    createManyAffiliateInvoices?: Maybe<CreateManyAffiliateInvoicesResponse>;
    createManyAppUserArticles?: Maybe<CreateManyAppUserArticlesResponse>;
    createManyAppUserEventTriggers?: Maybe<CreateManyAppUserEventTriggersResponse>;
    createManyAppUserTelemetrys?: Maybe<CreateManyAppUserTelemetrysResponse>;
    createManyAppUserTrendTriggers?: Maybe<CreateManyAppUserTrendTriggersResponse>;
    createManyAppUsers?: Maybe<CreateManyAppUsersResponse>;
    createManyApplications?: Maybe<CreateManyApplicationsResponse>;
    createManyAppointments?: Maybe<CreateManyAppointmentsResponse>;
    createManyArticleApprovals?: Maybe<CreateManyArticleApprovalsResponse>;
    createManyArticleCaches?: Maybe<CreateManyArticleCachesResponse>;
    createManyArticleCategorys?: Maybe<CreateManyArticleCategorysResponse>;
    createManyArticleFeeds?: Maybe<CreateManyArticleFeedsResponse>;
    createManyArticleGroups?: Maybe<CreateManyArticleGroupsResponse>;
    createManyArticlePromotions?: Maybe<CreateManyArticlePromotionsResponse>;
    createManyArticles?: Maybe<CreateManyArticlesResponse>;
    createManyAsyncJobs?: Maybe<CreateManyAsyncJobsResponse>;
    createManyBatchTasks?: Maybe<CreateManyBatchTasksResponse>;
    createManyBioScreens?: Maybe<CreateManyBioScreensResponse>;
    createManyCalendars?: Maybe<CreateManyCalendarsResponse>;
    createManyCarePlans?: Maybe<CreateManyCarePlansResponse>;
    createManyCareTeamContacts?: Maybe<CreateManyCareTeamContactsResponse>;
    createManyCareTeamMemberTypes?: Maybe<CreateManyCareTeamMemberTypesResponse>;
    createManyCareTeamMembers?: Maybe<CreateManyCareTeamMembersResponse>;
    createManyCareTeams?: Maybe<CreateManyCareTeamsResponse>;
    createManyChatConversations?: Maybe<CreateManyChatConversationsResponse>;
    createManyChatMessages?: Maybe<CreateManyChatMessagesResponse>;
    createManyChatRooms?: Maybe<CreateManyChatRoomsResponse>;
    createManyClaimsByPatientIds?: Maybe<CreatManyClaimsByPatientIdsResponse>;
    createManyClinicalSummarys?: Maybe<CreateManyClinicalSummarysResponse>;
    createManyCompoundQuestions?: Maybe<CreateManyCompoundQuestionsResponse>;
    createManyConditionTypes?: Maybe<CreateManyConditionTypesResponse>;
    createManyConditions?: Maybe<CreateManyConditionsResponse>;
    createManyConfigExpressions?: Maybe<CreateManyConfigExpressionsResponse>;
    createManyConfigValueDefs?: Maybe<CreateManyConfigValueDefsResponse>;
    createManyConfigValues?: Maybe<CreateManyConfigValuesResponse>;
    createManyContentDirectorys?: Maybe<CreateManyContentDirectorysResponse>;
    createManyDateTriggers?: Maybe<CreateManyDateTriggersResponse>;
    createManyDevices?: Maybe<CreateManyDevicesResponse>;
    createManyDirectoryCaches?: Maybe<CreateManyDirectoryCachesResponse>;
    createManyEligibilityMatchs?: Maybe<CreateManyEligibilityMatchsResponse>;
    createManyEpisodes?: Maybe<CreateManyEpisodesResponse>;
    createManyEventTriggers?: Maybe<CreateManyEventTriggersResponse>;
    createManyHealthPlans?: Maybe<CreateManyHealthPlansResponse>;
    createManyHighlightFeeds?: Maybe<CreateManyHighlightFeedsResponse>;
    createManyHighlights?: Maybe<CreateManyHighlightsResponse>;
    createManyInterventions?: Maybe<CreateManyInterventionsResponse>;
    createManyMassUpdatePatients?: Maybe<CreateManyMassUpdatePatientsResponse>;
    createManyMassUpdateTasks?: Maybe<CreateManyMassUpdateTasksResponse>;
    createManyMeasurements?: Maybe<CreateManyMeasurementsResponse>;
    createManyMessageCenterTemplates?: Maybe<CreateManyMessageCenterTemplatesResponse>;
    createManyMessageTemplates?: Maybe<CreateManyMessageTemplatesResponse>;
    createManyOrders?: Maybe<CreateManyOrdersResponse>;
    createManyOrganizations?: Maybe<CreateManyOrganizationsResponse>;
    createManyPatientActionTypes?: Maybe<CreateManyPatientActionTypesResponse>;
    createManyPatientActions?: Maybe<CreateManyPatientActionsResponse>;
    createManyPatientCallLists?: Maybe<CreateManyPatientCallListsResponse>;
    createManyPatientHeaders?: Maybe<CreateManyPatientHeadersResponse>;
    createManyPatientNoteTags?: Maybe<CreateManyPatientNoteTagsResponse>;
    createManyPatientNotess?: Maybe<CreateManyPatientNotessResponse>;
    createManyPatients?: Maybe<CreateManyPatientsResponse>;
    createManyPotentialUsers?: Maybe<CreateManyPotentialUsersResponse>;
    createManyProfileKeys?: Maybe<CreateManyProfileKeysResponse>;
    createManyProfileValueHistorys?: Maybe<CreateManyProfileValueHistorysResponse>;
    createManyProfileValueOrigins?: Maybe<CreateManyProfileValueOriginsResponse>;
    createManyProfileValues?: Maybe<CreateManyProfileValuesResponse>;
    createManyProviders?: Maybe<CreateManyProvidersResponse>;
    createManyQLists?: Maybe<CreateManyQListsResponse>;
    createManyQuestions?: Maybe<CreateManyQuestionsResponse>;
    createManyReferenceLinkCategorys?: Maybe<CreateManyReferenceLinkCategorysResponse>;
    createManyReferenceLinks?: Maybe<CreateManyReferenceLinksResponse>;
    createManyReportingCategorys?: Maybe<CreateManyReportingCategorysResponse>;
    createManyReportingJobs?: Maybe<CreateManyReportingJobsResponse>;
    createManyRoles?: Maybe<CreateManyRolesResponse>;
    createManySentMessages?: Maybe<CreateManySentMessagesResponse>;
    createManySimpleContents?: Maybe<CreateManySimpleContentsResponse>;
    createManySlaTimers?: Maybe<CreateManySlaTimersResponse>;
    createManySlas?: Maybe<CreateManySlasResponse>;
    createManySubmittedClaims?: Maybe<CreateManySubmittedClaimsResponse>;
    createManySubmittedClaimsViaFile?: Maybe<CreateManySubmittedClaimsViaFileResponse>;
    createManySurveyDefs?: Maybe<CreateManySurveyDefsResponse>;
    createManySurveyResponses?: Maybe<CreateManySurveyResponsesResponse>;
    createManySystemTokens?: Maybe<CreateManySystemTokensResponse>;
    createManyTags?: Maybe<CreateManyTagsResponse>;
    createManyTodoTasks?: Maybe<CreateManyTodoTasksResponse>;
    createManyTokens?: Maybe<CreateManyTokensResponse>;
    createManyTrackerHistorys?: Maybe<CreateManyTrackerHistorysResponse>;
    createManyTrackers?: Maybe<CreateManyTrackersResponse>;
    createManyTrendTriggers?: Maybe<CreateManyTrendTriggersResponse>;
    createManyUserProfileDefs?: Maybe<CreateManyUserProfileDefsResponse>;
    createManyUsers?: Maybe<CreateManyUsersResponse>;
    createManyVCZipCodeToLocations?: Maybe<CreateManyVcZipCodeToLocationsResponse>;
    createManyVirtualCareAffiliatess?: Maybe<CreateManyVirtualCareAffiliatessResponse>;
    createManyVirtualCareEligibilityWhitelists?: Maybe<CreateManyVirtualCareEligibilityWhitelistsResponse>;
    createManyVirtualCareForms?: Maybe<CreateManyVirtualCareFormsResponse>;
    createManyWhatsNewTimelines?: Maybe<CreateManyWhatsNewTimelinesResponse>;
    createManyWhoToCalls?: Maybe<CreateManyWhoToCallsResponse>;
    createMassUpdatePatient?: Maybe<CreateMassUpdatePatientResponse>;
    createMassUpdateTask?: Maybe<CreateMassUpdateTaskResponse>;
    createMeasurement?: Maybe<CreateMeasurementResponse>;
    createMessageCenterTemplate?: Maybe<CreateMessageCenterTemplateResponse>;
    createMessageTemplate?: Maybe<CreateMessageTemplateResponse>;
    createOrUpdateAppUser?: Maybe<CreateOrUpdateAppUserMutationResponse>;
    createOrUpdateManyUserProfileVariables?: Maybe<ProfileVariableMutationResponse>;
    createOrUpdateOneQList?: Maybe<QListMutationResponse>;
    createOrUpdateOneTracker?: Maybe<TrackerMutationResponse>;
    createOrUpdateOneUserProfileVariable?: Maybe<ProfileVariableMutationResponse>;
    createOrUpdateOneWhoToCall?: Maybe<WhoToCallMutationResponse>;
    createOrUpdatePatientsWithDeviceOrders?: Maybe<CreateOrUpdatePatientsWithDeviceOrdersResponse>;
    /** Create or update todo task */
    createOrUpdateTodoTask?: Maybe<TodoTask>;
    createOrUpdateVirtualCareForm?: Maybe<CreateOrUpdateVirtualCareFormResponse>;
    createOrder?: Maybe<CreateOrderResponse>;
    createOrganization?: Maybe<CreateOrganizationResponse>;
    createPatient?: Maybe<CreatePatientResponse>;
    createPatientAction?: Maybe<CreatePatientActionResponse>;
    createPatientActionType?: Maybe<CreatePatientActionTypeResponse>;
    createPatientActions?: Maybe<CreatePatientActionsResponse>;
    createPatientCallList?: Maybe<CreatePatientCallListResponse>;
    createPatientForAppUser?: Maybe<CreatePatientForAppUserResponse>;
    createPatientForSelfSignUp?: Maybe<CreatePatientForSelfSignUpResponse>;
    createPatientHeader?: Maybe<CreatePatientHeaderResponse>;
    createPatientNoteTag?: Maybe<CreatePatientNoteTagResponse>;
    createPatientNotes?: Maybe<CreatePatientNotesResponse>;
    createPotentialUser?: Maybe<CreatePotentialUserResponse>;
    createProfileKey?: Maybe<CreateProfileKeyResponse>;
    createProfileValue?: Maybe<CreateProfileValueResponse>;
    createProfileValueHistory?: Maybe<CreateProfileValueHistoryResponse>;
    createProfileValueOrigin?: Maybe<CreateProfileValueOriginResponse>;
    createProvider?: Maybe<CreateProviderResponse>;
    createQList?: Maybe<CreateQListResponse>;
    createQuestion?: Maybe<CreateQuestionResponse>;
    createReferenceLink?: Maybe<CreateReferenceLinkResponse>;
    createReferenceLinkCategory?: Maybe<CreateReferenceLinkCategoryResponse>;
    createReportingCategory?: Maybe<CreateReportingCategoryResponse>;
    createReportingJob?: Maybe<CreateReportingJobResponse>;
    createRole?: Maybe<CreateRoleResponse>;
    createSentMessage?: Maybe<CreateSentMessageResponse>;
    createSimpleContent?: Maybe<CreateSimpleContentResponse>;
    createSla?: Maybe<CreateSlaResponse>;
    createSlaTimer?: Maybe<CreateSlaTimerResponse>;
    createSlaTimerForAdvocateTask?: Maybe<CreateSlaTimerForAdvocateTaskResponse>;
    createSubmittedClaim?: Maybe<CreateSubmittedClaimResponse>;
    createSurveyDef?: Maybe<CreateSurveyDefResponse>;
    createSurveyResponse?: Maybe<CreateSurveyResponse>;
    createSystemToken?: Maybe<CreateSystemTokenResponse>;
    createTag?: Maybe<CreateTagResponse>;
    createTestAppUserMutation?: Maybe<ProxiedCreateTestUserMutationResponse>;
    createTestUser?: Maybe<CreateTestUserMutationResponse>;
    createTodoTask?: Maybe<CreateTodoTaskResponse>;
    createToken?: Maybe<CreateTokenResponse>;
    createTracker?: Maybe<CreateTrackerResponse>;
    createTrackerHistory?: Maybe<CreateTrackerHistoryResponse>;
    createTrendTrigger?: Maybe<CreateTrendTriggerResponse>;
    createUser?: Maybe<CreateUserResponse>;
    createUserProfileDef?: Maybe<CreateUserProfileDefResponse>;
    createVCZipCodeToLocation?: Maybe<CreateVcZipCodeToLocationResponse>;
    createVirtualCareAffiliate?: Maybe<VirtualCareAffiliatesMutationResponse>;
    createVirtualCareAffiliates?: Maybe<CreateVirtualCareAffiliatesResponse>;
    createVirtualCareEligibilityWhitelist?: Maybe<CreateVirtualCareEligibilityWhitelistResponse>;
    createVirtualCareForm?: Maybe<CreateVirtualCareFormResponse>;
    createWhatsNewTimeline?: Maybe<CreateOrUpdateWhatsNewTimelineResponse>;
    createWhoToCall?: Maybe<CreateWhoToCallResponse>;
    deleteAdvocateNotification?: Maybe<DeleteAdvocateNotificationResponse>;
    deleteAdvocateTask?: Maybe<DeleteAdvocateTaskResponse>;
    deleteAdvocateTaskTemplate?: Maybe<DeleteAdvocateTaskTemplateMutationResponse>;
    deleteAdvocateTaskType?: Maybe<DeleteAdvocateTaskTypeResponse>;
    deleteAdvocateTasksForAppointment?: Maybe<DeleteTasksForAppointmentResponse>;
    deleteAffiliateClaim?: Maybe<DeleteAffiliateClaimResponse>;
    deleteAffiliateInvoice?: Maybe<DeleteAffiliateInvoiceResponse>;
    deleteAllUserProfileVariables?: Maybe<MutationResponse>;
    deleteAndReplacePatientActionType?: Maybe<DeletePatientActionTypeResponse>;
    deleteAppUser?: Maybe<DeleteAppUserMutationResponse>;
    deleteAppUserArticle?: Maybe<DeleteAppUserArticleResponse>;
    deleteAppUserEventTrigger?: Maybe<DeleteAppUserEventTriggerResponse>;
    deleteAppUserTelemetry?: Maybe<DeleteAppUserTelemetryResponse>;
    deleteAppUserTrendTrigger?: Maybe<DeleteAppUserTrendTriggerResponse>;
    deleteAppUserV2?: Maybe<DeleteAppUserMutationResponse>;
    deleteApplication?: Maybe<DeleteApplicationResponse>;
    deleteAppointment?: Maybe<DeleteAppointmentResponse>;
    deleteArticle?: Maybe<DeleteArticleMutationResponse>;
    deleteArticleApproval?: Maybe<DeleteArticleApprovalResponse>;
    deleteArticleCache?: Maybe<DeleteArticleCacheResponse>;
    deleteArticleCategory?: Maybe<DeleteArticleCategoryMutationResponse>;
    deleteArticleFeed?: Maybe<DeleteArticleFeedResponse>;
    deleteArticleGroup?: Maybe<DeleteArticleGroupResponse>;
    deleteArticlePromotion?: Maybe<DeleteArticlePromotionResponse>;
    deleteAsyncJob?: Maybe<DeleteAsyncJobResponse>;
    deleteBatchTask?: Maybe<DeleteBatchTaskResponse>;
    deleteBioScreen?: Maybe<DeleteBioScreenResponse>;
    deleteCalendar?: Maybe<DeleteCalendarResponse>;
    deleteCarePlan?: Maybe<DeleteCarePlanResponse>;
    deleteCareTeam?: Maybe<DeleteCareTeamResponse>;
    deleteCareTeamContact?: Maybe<DeleteCareTeamContactResponse>;
    deleteCareTeamMember?: Maybe<DeleteCareTeamMemberResponse>;
    deleteCareTeamMemberType?: Maybe<DeleteCareTeamMemberTypeMutationResponse>;
    deleteChatConversation?: Maybe<DeleteChatConversationResponse>;
    deleteChatMessage?: Maybe<DeleteChatMessageResponse>;
    deleteChatRoom?: Maybe<DeleteChatRoomResponse>;
    deleteClinicalSummary?: Maybe<DeleteClinicalSummaryResponse>;
    deleteCompoundQuestion?: Maybe<DeleteCompoundQuestionResponse>;
    deleteCondition?: Maybe<DeleteConditionResponse>;
    deleteConditionType?: Maybe<DeleteConditionTypeMutationResponse>;
    deleteConfigExpression?: Maybe<DeleteConfigExpressionResponse>;
    deleteConfigValue?: Maybe<DeleteConfigValueResponse>;
    deleteConfigValueByValueDefId?: Maybe<DeleteConfigValueByValueDefIdResponse>;
    deleteConfigValueDef?: Maybe<DeleteConfigValueDefResponse>;
    deleteContentDirectory?: Maybe<DeleteCdResponse>;
    deleteDateTrigger?: Maybe<DeleteDateTriggerMutationResponse>;
    deleteDevice?: Maybe<DeleteDeviceResponse>;
    deleteDirectoryCache?: Maybe<DeleteDirectoryCacheResponse>;
    deleteDirectoryCacheForCD?: Maybe<MutationResponse>;
    deleteEligibilityMatch?: Maybe<DeleteEligibilityMatchResponse>;
    deleteEpisode?: Maybe<DeleteEpisodeResponse>;
    deleteEventTrigger?: Maybe<DeleteEventTriggerMutationResponse>;
    deleteExpression?: Maybe<DeleteExpressionMutationResponse>;
    deleteHealthPlan?: Maybe<DeleteHealthPlanResponse>;
    deleteHighlight?: Maybe<DeleteHighlightResponse>;
    deleteHighlightFeed?: Maybe<DeleteHighlightFeedResponse>;
    deleteIntervention?: Maybe<DeleteInterventionResponse>;
    deleteMassUpdatePatient?: Maybe<DeleteMassUpdatePatientResponse>;
    deleteMassUpdatePatientList?: Maybe<DeleteMassUpdatePatientListResponse>;
    deleteMassUpdateTask?: Maybe<DeleteMassUpdateTaskResponse>;
    deleteMeasurement?: Maybe<DeleteMeasurementResponse>;
    deleteMessageCenterTemplate?: Maybe<DeleteMessageCenterTemplateMutationResponse>;
    deleteMessageTemplate?: Maybe<DeleteMessageTemplateResponse>;
    deleteNotificationToken?: Maybe<NotificationTokenMutationResponse>;
    deleteOneUserProfileVariable?: Maybe<ProfileVariableMutationResponse>;
    deleteOrder?: Maybe<DeleteOrderResponse>;
    deleteOrganization?: Maybe<DeleteOrganizationResponse>;
    deleteOrganizationInviteCode?: Maybe<DeleteOrganizationInviteCodeResponse>;
    deletePatient?: Maybe<DeletePatientResponse>;
    deletePatientAction?: Maybe<DeletePatientActionResponse>;
    deletePatientActionByStep?: Maybe<DeletePatientActionByStepResponse>;
    deletePatientActionType?: Maybe<DeletePatientActionTypeResponse>;
    deletePatientAppUserDownstreamData?: Maybe<DeletePatientResponseV2>;
    deletePatientCallList?: Maybe<DeletePatientCallListResponse>;
    deletePatientHeader?: Maybe<DeletePatientHeaderResponse>;
    deletePatientInvitation?: Maybe<DeletePatientInvitationResponse>;
    deletePatientNoteTag?: Maybe<DeletePatientNoteTagResponse>;
    deletePatientNotes?: Maybe<DeletePatientNotesResponse>;
    deletePatientsForApp?: Maybe<DeletePatientsByAppResponse>;
    deletePotentialUser?: Maybe<DeletePotentialUserResponse>;
    deleteProfileKey?: Maybe<DeleteProfileKeyResponse>;
    deleteProfileValue?: Maybe<DeleteProfileValueResponse>;
    deleteProfileValueHistory?: Maybe<DeleteProfileValueHistoryResponse>;
    deleteProfileValueOrigin?: Maybe<DeleteProfileValueOriginResponse>;
    deleteProvider?: Maybe<DeleteProviderResponse>;
    deleteProxiedTodoTask?: Maybe<DeletePatientTodoResponse>;
    deleteQList?: Maybe<DeleteQListResponse>;
    deleteQuestion?: Maybe<DeleteQuestionResponse>;
    deleteReferenceLink?: Maybe<DeleteReferenceLinkResponse>;
    deleteReferenceLinkCategory?: Maybe<DeleteReferenceLinkCategoryResponse>;
    deleteReportingCategory?: Maybe<DeleteReportingCategoryMutationResponse>;
    deleteReportingJob?: Maybe<DeleteReportingJobResponse>;
    deleteRole?: Maybe<DeleteRoleMutationResponse>;
    deleteSentMessage?: Maybe<DeleteSentMessageResponse>;
    deleteSimpleContent?: Maybe<DeleteSimpleContentMutationResponse>;
    deleteSla?: Maybe<DeleteSlaResponse>;
    deleteSlaTimer?: Maybe<DeleteSlaTimerResponse>;
    deleteSubmittedClaim?: Maybe<DeleteSubmittedClaimResponse>;
    deleteSurveyDef?: Maybe<DeleteSurveyDefMutationResponse>;
    deleteSurveyResponse?: Maybe<DeleteSurveyResponseResponse>;
    deleteSystemToken?: Maybe<DeleteSystemTokenResponse>;
    deleteTag?: Maybe<DeleteTagMutationResponse>;
    deleteTimeline?: Maybe<DeleteTimelineResponse>;
    deleteTodoTask?: Maybe<DeleteTodoTaskResponse>;
    deleteTodoTasks?: Maybe<DeleteTodoTasksResponse>;
    deleteToken?: Maybe<DeleteTokenResponse>;
    deleteTracker?: Maybe<DeleteTrackerResponse>;
    deleteTrackerHistory?: Maybe<DeleteTrackerHistoryResponse>;
    deleteTrendTrigger?: Maybe<DeleteTrendTriggerMutationResponse>;
    deleteUser?: Maybe<DeleteUserResponse>;
    deleteUserProfileDef?: Maybe<DeleteUserProfileDefResponse>;
    deleteVCZipCodeToLocation?: Maybe<DeleteVcZipCodeToLocationResponse>;
    deleteVariant?: Maybe<DeleteVariantMutationResponse>;
    deleteVirtualCareAffiliate?: Maybe<DeleteVirtualCareAffiliateResponse>;
    deleteVirtualCareAffiliates?: Maybe<DeleteVirtualCareAffiliatesResponse>;
    deleteVirtualCareEligibilityWhitelist?: Maybe<DeleteVirtualCareEligibilityWhitelistResponse>;
    deleteVirtualCareForm?: Maybe<DeleteVirtualCareFormResponse>;
    deleteWhatsNewTimeline?: Maybe<DeleteWhatsNewTimelineResponse>;
    deleteWhoToCall?: Maybe<DeleteWhoToCallResponse>;
    eligibilityVerification?: Maybe<EligibilityResponse>;
    eligibilityVerificationMany?: Maybe<EligibilityBulkRecheckResponse>;
    followAllPatientsByOrg?: Maybe<FollowAllPatientsByOrgResponse>;
    followAllUsers?: Maybe<FollowAllUsersResponse>;
    forceClaimUpload?: Maybe<ForceClaimUploadResponse>;
    getNextBatchTask?: Maybe<BatchTask>;
    highlightDeleteExpired?: Maybe<MutationResponse>;
    highlightFeedCreateMany?: Maybe<MutationResponse>;
    highlightFeedDeleteByAppUser?: Maybe<MutationResponse>;
    highlightFeedDeleteByHighlightForApp?: Maybe<MutationResponse>;
    highlightFeedDeleteByHighlightId?: Maybe<MutationResponse>;
    highlightFeedDeleteByTimelineId?: Maybe<MutationResponse>;
    highlightFeedTimelinesNotFoundDelete?: Maybe<MutationResponse>;
    highlightFeedUpdatePriorityClosed?: Maybe<MutationResponse>;
    highlightFeedUpsertMany?: Maybe<MutationResponse>;
    incrementBatchTaskCompletedRecords?: Maybe<BatchTask>;
    inviteUser?: Maybe<InviteUserMutationResponse>;
    loginAppUser?: Maybe<AppUserTokenResponse>;
    loginUser?: Maybe<LoginUserResponse>;
    logoutAppUser?: Maybe<LogoutMutationResponse>;
    manageAppointmentsFromExternalSource?: Maybe<CreateOrUpdateAppointmentsResponse>;
    moveMessagesInChatConversation?: Maybe<MoveMessagesInChatConversationResponse>;
    postMessageCenterEvent?: Maybe<PostMessageCenterMutationResponse>;
    postProfileValueChangedEvent?: Maybe<PostProfileValueChangedMutationResponse>;
    postSurveyCompletedEvent?: Maybe<PostSurveyCompletedMutationResponse>;
    postTrackerChangedEvent?: Maybe<PostTrackerChangedMutationResponse>;
    publishAllArticles?: Maybe<PublishAllArticlesMutationResponse>;
    publishArticle?: Maybe<PublishArticleMutationResponse>;
    recheckEligibility?: Maybe<RecheckEligibilityResponse>;
    refreshAppUserToken?: Maybe<AppUserTokenResponse>;
    registerAppUserWithoutAcceptingInviteCode?: Maybe<AppUserTokenResponse>;
    /** App Users can reset their password by requesting a new invite code from the portal by the Resend Code flow. */
    resendAppUserInviteCode?: Maybe<ResendAppUserInviteCodeResponse>;
    resendPatientCodeOnResetPassword?: Maybe<ResendPatientCodeOnResetPasswordResponse>;
    resendPatientInvitation?: Maybe<ResendPatientInvitationResponse>;
    resendPatientInvitationByEmailIfExists?: Maybe<ResendPatientInvitationByEmailIfExistsResponse>;
    resendPatientInvitationFromPortal?: Maybe<ResendPatientInvitationFromPortalResponse>;
    /** App Users can reset their password by requesting a new invite code from the portal. */
    resetAppUserPassword?: Maybe<ResetAppUserPasswordResponse>;
    resetPassword?: Maybe<ResetPasswordResponse>;
    resetPatientPasswordFromPortal?: Maybe<ResetPatientPasswordFromPortalResponse>;
    resolveChatConversation?: Maybe<ResolveChatConversationResponse>;
    saveHighlight?: Maybe<SaveHighlightResponse>;
    /** Letter with invite code which user enters on the "Enter Your Invite Code" screen */
    sendInvitationCode?: Maybe<SendInvitationCodeResponse>;
    /** Letter with invite code which user enters on the "Enter Your Invite Code" screen */
    sendInvitationCodeOnResetPassword?: Maybe<SendInvitationCodeOnResetPasswordResponse>;
    /** Letter with invite code which is sent from Portal on patient creation or by "Resend Invite" button */
    sendInvitationFromPortalCode?: Maybe<SendInvitationFromPortalCodeResponse>;
    sendMessage?: Maybe<SendMessageMutationResponse>;
    sendMessageCenterNotification?: Maybe<SendNotificationsResponse>;
    sendMfaCode?: Maybe<AppUserTokenResponse>;
    sendNotificationsForAppUser?: Maybe<SendNotificationsResponse>;
    /** Letter with invite code which user enters on the "forgot password" screen */
    sendResetPasswordInvitation?: Maybe<ResetAppUserPasswordResponse>;
    setAppUserContentCreated?: Maybe<SetContentCreatedResponse>;
    setAppUserContentStatus?: Maybe<SetContentCreatedResponse>;
    setAppUsersOrgId?: Maybe<MutationResponse>;
    setCareTeamPracticeByAppointment?: Maybe<SetCareTeamValueResponse>;
    setDemographicsPVs?: Maybe<PatientProfileValueResponse>;
    setManyPVByExternalId?: Maybe<PatientProfileValueResponse>;
    setNotificationToken?: Maybe<NotificationTokenMutationResponse>;
    setPatientAppUser?: Maybe<SetPatientAppUserResponse>;
    setPatientProfileValue?: Maybe<PatientProfileValueResponse>;
    setPatientProfileValues?: Maybe<PatientProfileValueResponse>;
    setPayerMemberNumPv: PayerMemberNumResponseResponse;
    setTasksTestData?: Maybe<SetTasksTestDataResponse>;
    submitMassUpdateTask?: Maybe<SubmitTaskMutationResponse>;
    testMutationAuthorizationRoleApp?: Maybe<TestAuthorization>;
    testMutationAuthorizationRoleService?: Maybe<TestAuthorization>;
    textOptOut?: Maybe<TextOptOutMutationResponse>;
    unsubscribePatientEmail?: Maybe<UnsubscribePatientResponse>;
    unsubscribePatientText?: Maybe<UnsubscribePatientResponse>;
    updateAccessCode?: Maybe<EligibilityUpdateAccessCodeResponse>;
    updateAdvocateNotification?: Maybe<UpdateAdvocateNotificationResponse>;
    updateAdvocateTask?: Maybe<UpdateAdvocateTaskResponse>;
    updateAdvocateTaskEncoded?: Maybe<UpdateAdvocateTaskEncodedResponse>;
    updateAdvocateTaskTemplate?: Maybe<UpdateAdvocateTaskTemplateResponse>;
    updateAdvocateTaskTemplateEncoded?: Maybe<UpdateAdvocateTaskTemplateEncodedResponse>;
    updateAdvocateTaskType?: Maybe<UpdateAdvocateTaskTypeResponse>;
    updateAffiliateClaim?: Maybe<UpdateAffiliateClaimResponse>;
    updateAffiliateInvoice?: Maybe<UpdateAffiliateInvoiceResponse>;
    updateAffiliateInvoicesPaymentStatus?: Maybe<UpdateAffiliateInvoicesPaymentStatusResponse>;
    updateAppUser?: Maybe<UpdateAppUserMutationResponse>;
    updateAppUserArticle?: Maybe<UpdateAppUserArticleResponse>;
    updateAppUserEmail?: Maybe<UpdateAppUserEmailResponse>;
    updateAppUserEventTrigger?: Maybe<UpdateAppUserEventTriggerResponse>;
    updateAppUserLanguage?: Maybe<UpdateAppUserLanguageResponse>;
    updateAppUserTelemetry?: Maybe<UpdateAppUserTelemetryResponse>;
    updateAppUserTrendTrigger?: Maybe<UpdateAppUserTrendTriggerResponse>;
    updateApplication?: Maybe<UpdateApplicationResponse>;
    updateAppointment?: Maybe<UpdateAppointmentResponse>;
    updateAppointmentReportKey?: Maybe<UpdateReportKeyResponse>;
    updateApprovalName?: Maybe<UpdateApproveNameResponse>;
    updateArticle?: Maybe<UpdateArticleResponse>;
    updateArticleApproval?: Maybe<UpdateArticleApprovalResponse>;
    updateArticleCache?: Maybe<UpdateArticleCacheResponse>;
    updateArticleCategory?: Maybe<UpdateArticleCategoryResponse>;
    updateArticleEncoded?: Maybe<UpdateArticleEncodedResponse>;
    updateArticleFeed?: Maybe<UpdateArticleFeedResponse>;
    updateArticleGroup?: Maybe<UpdateArticleGroupResponse>;
    updateArticlePromotion?: Maybe<UpdateArticlePromotionResponse>;
    updateAsyncJob?: Maybe<UpdateAsyncJobResponse>;
    updateBatchClaimsStatus?: Maybe<UpdateBatchClaimsStatusResponse>;
    updateBatchTask?: Maybe<UpdateBatchTaskResponse>;
    updateBioScreen?: Maybe<UpdateBioScreenResponse>;
    updateCalendar?: Maybe<UpdateCalendarResponse>;
    updateCarePlan?: Maybe<UpdateCarePlanResponse>;
    updateCarePlanOffsets?: Maybe<UpdateCarePlanResponse>;
    updateCareTeam?: Maybe<UpdateCareTeamResponse>;
    updateCareTeamContact?: Maybe<UpdateCareTeamContactResponse>;
    updateCareTeamMember?: Maybe<UpdateCareTeamMemberResponse>;
    updateCareTeamMemberType?: Maybe<UpdateCareTeamMemberTypeResponse>;
    updateChatConversation?: Maybe<UpdateChatConversationResponse>;
    updateChatMessage?: Maybe<UpdateChatMessageResponse>;
    updateChatRoom?: Maybe<UpdateChatRoomResponse>;
    updateClinicalSummary?: Maybe<UpdateClinicalSummaryResponse>;
    updateCompoundQuestion?: Maybe<UpdateCompoundQuestionResponse>;
    updateCondition?: Maybe<UpdateConditionResponse>;
    updateConditionType?: Maybe<UpdateConditionTypeResponse>;
    updateConfigExpression?: Maybe<UpdateConfigExpressionResponse>;
    updateConfigValue?: Maybe<UpdateConfigValueResponse>;
    updateConfigValueDef?: Maybe<UpdateConfigValueDefResponse>;
    updateContentDirectory?: Maybe<UpdateContentDirectoryResponse>;
    updateDateTrigger?: Maybe<UpdateDateTriggerResponse>;
    updateDevice?: Maybe<UpdateDeviceResponse>;
    updateDirectoryCache?: Maybe<UpdateDirectoryCacheResponse>;
    updateEligibilityMatch?: Maybe<UpdateEligibilityMatchResponse>;
    updateEpisode?: Maybe<UpdateEpisodeResponse>;
    updateEventTrigger?: Maybe<UpdateEventTriggerResponse>;
    updateHealthPlan?: Maybe<UpdateHealthPlanResponse>;
    updateHighlight?: Maybe<UpdateHighlightResponse>;
    updateHighlightFeed?: Maybe<UpdateHighlightFeedResponse>;
    updateIntervention?: Maybe<UpdateInterventionResponse>;
    updateMassUpdatePatient?: Maybe<UpdateMassUpdatePatientResponse>;
    updateMassUpdateTask?: Maybe<UpdateMassUpdateTaskResponse>;
    updateMeasurement?: Maybe<UpdateMeasurementResponse>;
    updateMessageCenterTemplate?: Maybe<UpdateMessageCenterTemplateResponse>;
    updateMessageTemplate?: Maybe<UpdateMessageTemplateResponse>;
    updateOrder?: Maybe<UpdateOrderResponse>;
    updateOrganization?: Maybe<UpdateOrganizationResponse>;
    updateParticipants?: Maybe<UpdateParticipantsForChatRoomResponse>;
    updatePatient?: Maybe<UpdatePatientResponse>;
    updatePatientAction?: Maybe<UpdatePatientActionResponse>;
    updatePatientActionType?: Maybe<UpdatePatientActionTypeResponse>;
    updatePatientCallList?: Maybe<UpdatePatientCallListResponse>;
    updatePatientCallListCallStatus?: Maybe<UpdatePatientCallListResponse>;
    updatePatientHeader?: Maybe<UpdatePatientHeaderResponse>;
    updatePatientNoteTag?: Maybe<UpdatePatientNoteTagResponse>;
    updatePatientNotes?: Maybe<UpdatePatientNotesResponse>;
    updatePatientProfileVariable?: Maybe<PatientProfileVariable>;
    updatePotentialUser?: Maybe<UpdatePotentialUserResponse>;
    updateProfileKey?: Maybe<UpdateProfileKeyResponse>;
    updateProfileValue?: Maybe<UpdateProfileValueResponse>;
    updateProfileValueHistory?: Maybe<UpdateProfileValueHistoryResponse>;
    updateProfileValueOrigin?: Maybe<UpdateProfileValueOriginResponse>;
    updateProfileVariableFromPatientPage?: Maybe<ProfileVariableForPatientPage>;
    updateProvider?: Maybe<UpdateProviderResponse>;
    updateProxiedTodoTask: EditHaTodoTaskResponse;
    updateQList?: Maybe<UpdateQListResponse>;
    updateQuestion?: Maybe<UpdateQuestionResponse>;
    updateReadMessages?: Maybe<UpdateReadeMessagesForChatRoomResponse>;
    updateReferenceLink?: Maybe<UpdateReferenceLinkResponse>;
    updateReferenceLinkCategory?: Maybe<UpdateReferenceLinkCategoryResponse>;
    updateReportingCategory?: Maybe<UpdateReportingCategoryResponse>;
    updateReportingJob?: Maybe<UpdateReportingJobResponse>;
    updateRole?: Maybe<UpdateRoleResponse>;
    updateSentMessage?: Maybe<UpdateSentMessageResponse>;
    updateSimpleContent?: Maybe<UpdateSimpleContentResponse>;
    updateSla?: Maybe<UpdateSlaResponse>;
    updateSlaTimer?: Maybe<UpdateSlaTimerResponse>;
    updateSubmittedClaim?: Maybe<UpdateSubmittedClaimResponse>;
    updateSurveyDef?: Maybe<UpdateSurveyDefResponse>;
    updateSurveyResponse?: Maybe<UpdateSurveyResponseResponse>;
    updateSurveyResponseAnswersWithNoDataTrail?: Maybe<UpdateSurveyResponseAnswersWithNoDataTrailResponse>;
    updateSystemToken?: Maybe<UpdateSystemTokenResponse>;
    updateTag?: Maybe<UpdateTagResponse>;
    updateTodoTask?: Maybe<UpdateTodoTaskResponse>;
    /** Update todo tasks. */
    updateTodoTasks?: Maybe<UpdateTodoTasksResponse>;
    updateToken?: Maybe<UpdateTokenResponse>;
    updateTracker?: Maybe<UpdateTrackerResponse>;
    updateTrackerHistory?: Maybe<UpdateTrackerHistoryResponse>;
    updateTrendTrigger?: Maybe<UpdateTrendTriggerResponse>;
    updateUser?: Maybe<UpdateUserResponse>;
    updateUserProfileDef?: Maybe<UpdateUserProfileDefResponse>;
    updateVCZipCodeToLocation?: Maybe<UpdateVcZipCodeToLocationResponse>;
    updateVirtualCareAffiliate?: Maybe<UpdateVirtualCareAffiliateResponse>;
    updateVirtualCareAffiliates?: Maybe<UpdateVirtualCareAffiliatesResponse>;
    updateVirtualCareEligibilityWhitelist?: Maybe<UpdateVirtualCareEligibilityWhitelistResponse>;
    updateVirtualCareForm?: Maybe<UpdateVirtualCareFormResponse>;
    updateWhatsNewTimeline?: Maybe<CreateOrUpdateWhatsNewTimelineResponse>;
    updateWhoToCall?: Maybe<UpdateWhoToCallResponse>;
    upsertArticleVariant?: Maybe<UpsertVariantResponse>;
    upsertConfigValueByValueDefId?: Maybe<UpsertConfigValueByValueDefIdResponse>;
    userArticleCacheDeleteByArticleId?: Maybe<MutationResponse>;
    userManagementDeleteUser?: Maybe<DeleteUserDataResponse>;
    userManagementImportFamilyData?: Maybe<ImportFamilyDataResponse>;
    userManagementResetUserPassword?: Maybe<ResetUserPasswordDataResponse>;
    validateMfa?: Maybe<AppUserTokenResponse>;
};

export type MutationCreateOrUpdateAppointmentsArgs = {
    input: CreateOrUpdateAppointmentsInput;
};

export type MutationOAuthApiPostSurveyAnswersArgs = {
    input: OAuthApiPostSurveyAnswersInput;
};

export type MutationOAuthSignInArgs = {
    input: OAuthSignInInput;
};

export type MutationUpdateMeasurementReviewedArgs = {
    input: UpdateMeasurementReviewedInput;
};

export type MutationAcceptInviteArgs = {
    input: AppUserAcceptInviteInput;
};

export type MutationAddApprovalsForAppArgs = {
    appBundleId: Scalars['String'];
};

export type MutationAddNoteFromConsultantArgs = {
    input?: InputMaybe<AddNoteFromConsultantInput>;
};

export type MutationAddOrganizationInviteCodeArgs = {
    input: AddOrganizationInviteCodeInput;
};

export type MutationAddRedirectUrlToTtPatientArgs = {
    input?: InputMaybe<AddRedirectUrlToTtPatientInput>;
};

export type MutationAddToPatientListArgs = {
    input: AddToPatientListInput;
};

export type MutationApproveAllForAppArgs = {
    input: ApproveAllForAppInput;
};

export type MutationArticleCacheDeleteByArticleIdArgs = {
    articleId?: InputMaybe<Scalars['String']>;
};

export type MutationArticleFeedCreateManyArgs = {
    docs?: InputMaybe<Array<InputMaybe<ArticleFeedInput>>>;
};

export type MutationArticleFeedDeleteByAppUserArgs = {
    appUserId?: InputMaybe<Scalars['String']>;
};

export type MutationArticleFeedDeleteByArticleIdArgs = {
    articleId?: InputMaybe<Scalars['String']>;
};

export type MutationArticleFeedDeleteByPromotionIdArgs = {
    promotionId?: InputMaybe<Scalars['String']>;
};

export type MutationArticleFeedDeleteByTimelineIdArgs = {
    appUserId?: InputMaybe<Scalars['String']>;
    timelineId?: InputMaybe<Scalars['String']>;
};

export type MutationArticleFeedTimelinesNotFoundDeleteArgs = {
    input?: InputMaybe<ArticleFeedTimelinesNotFoundInput>;
};

export type MutationArticleFeedUpsertManyArgs = {
    docs?: InputMaybe<Array<InputMaybe<ArticleFeedInput>>>;
};

export type MutationBulkUpdateProfileValuesArgs = {
    input: BulkUpdateProfileValuesInput;
};

export type MutationChangeAppUserPasswordArgs = {
    input: ChangeAppUserPasswordInput;
};

export type MutationChangePatientOrganizationArgs = {
    input?: InputMaybe<ChangePatientOrganizationInput>;
};

export type MutationCheckEligibilityArgs = {
    input: CheckEligibilityInput;
};

export type MutationCheckVcfEligibilityArgs = {
    input?: InputMaybe<CheckEligibilityFormInput>;
};

export type MutationClearPatientProfileKeyValuesArgs = {
    input: ClearPatientProfileKeyValuesInput;
};

export type MutationClearPatientProfileValueArgs = {
    input: ClearPatientProfileValueInput;
};

export type MutationCloneArticleArgs = {
    input: CloneArticleInput;
};

export type MutationCloneBioScreenArgs = {
    input: CloneBioScreenInput;
};

export type MutationCloneVariantArgs = {
    input: CloneVariantInput;
};

export type MutationCloseAppTaskArgs = {
    input: CloseAppTaskInput;
};

export type MutationCloseHighlightFeedArgs = {
    highlightFeedId?: InputMaybe<Scalars['String']>;
};

export type MutationCompleteAllTodoTasksArgs = {
    input: CompleteAllTodoTasksInput;
};

export type MutationCompletePotentialUserFlowArgs = {
    input: PotentialUserUpdateInput;
};

export type MutationCreateAccessCodeArgs = {
    input: AccessCodeInput;
};

export type MutationCreateAdvocateNotificationArgs = {
    input: AdvocateNotificationInput;
};

export type MutationCreateAdvocateTaskArgs = {
    input: AdvocateTaskInput;
};

export type MutationCreateAdvocateTaskEncodedArgs = {
    input: AdvocateTaskInput;
};

export type MutationCreateAdvocateTaskTemplateArgs = {
    input: AdvocateTaskTemplateInput;
};

export type MutationCreateAdvocateTaskTemplateEncodedArgs = {
    input: AdvocateTaskTemplateInput;
};

export type MutationCreateAdvocateTaskTypeArgs = {
    input: AdvocateTaskTypeInput;
};

export type MutationCreateAdvocateTasksForAppointmentArgs = {
    id: Scalars['String'];
};

export type MutationCreateAffiliateClaimArgs = {
    input?: InputMaybe<AffiliateClaimInput>;
};

export type MutationCreateAffiliateClaimFromBuilderArgs = {
    input?: InputMaybe<AffiliateClaimFromBuilderInput>;
};

export type MutationCreateAffiliateInvoiceArgs = {
    input: AffiliateInvoiceInput;
};

export type MutationCreateAppUserArgs = {
    input: CreateAppUserInput;
};

export type MutationCreateAppUserArticleArgs = {
    input: AppUserArticleInput;
};

export type MutationCreateAppUserEventTriggerArgs = {
    input: AppUserEventTriggerInput;
};

export type MutationCreateAppUserForPatientArgs = {
    input: CreateAppUserForPatientInput;
};

export type MutationCreateAppUserForVcPatientArgs = {
    input: CreateAppUserForVcPatientInput;
};

export type MutationCreateAppUserSsoArgs = {
    input: CreateAppUserSsoInput;
};

export type MutationCreateAppUserTelemetryArgs = {
    input: AppUserTelemetryInput;
};

export type MutationCreateAppUserTrendTriggerArgs = {
    input: AppUserTrendTriggerInput;
};

export type MutationCreateApplicationArgs = {
    input: ApplicationInput;
};

export type MutationCreateAppointmentArgs = {
    input: AppointmentInput;
};

export type MutationCreateArticleArgs = {
    input: ArticleInput;
};

export type MutationCreateArticleAppApprovalsArgs = {
    input: CreateArticleAppApprovalsInput;
};

export type MutationCreateArticleApprovalArgs = {
    input: ArticleApprovalInput;
};

export type MutationCreateArticleCacheArgs = {
    input: ArticleCacheInput;
};

export type MutationCreateArticleCategoryArgs = {
    input: ArticleCategoryInput;
};

export type MutationCreateArticleEncodedArgs = {
    input: ArticleInput;
};

export type MutationCreateArticleFeedArgs = {
    input: ArticleFeedInput;
};

export type MutationCreateArticleGroupArgs = {
    input: ArticleGroupInput;
};

export type MutationCreateArticlePromotionArgs = {
    input: ArticlePromotionInput;
};

export type MutationCreateAsyncJobArgs = {
    input: AsyncJobInput;
};

export type MutationCreateBatchTaskArgs = {
    input: BatchTaskInput;
};

export type MutationCreateBioScreenArgs = {
    input: BioScreenInput;
};

export type MutationCreateCalendarArgs = {
    input: CalendarInput;
};

export type MutationCreateCarePlanArgs = {
    input: CarePlanInput;
};

export type MutationCreateCareTeamArgs = {
    input: CareTeamInput;
};

export type MutationCreateCareTeamContactArgs = {
    input: CareTeamContactInput;
};

export type MutationCreateCareTeamMemberArgs = {
    input: CareTeamMemberInput;
};

export type MutationCreateCareTeamMemberTypeArgs = {
    input: CareTeamMemberTypeUpdateInput;
};

export type MutationCreateChatConversationArgs = {
    input: ChatConversationInput;
};

export type MutationCreateChatMessageArgs = {
    input: ChatMessageInput;
};

export type MutationCreateChatMessageEncodedArgs = {
    input: ChatMessageInput;
};

export type MutationCreateChatMessageForAppUserArgs = {
    input: CreateChatMessageForAppUserInput;
};

export type MutationCreateChatRoomArgs = {
    input: ChatRoomInput;
};

export type MutationCreateClinicalSummaryArgs = {
    input: ClinicalSummaryInput;
};

export type MutationCreateCompoundQuestionArgs = {
    input: CompoundQuestionInput;
};

export type MutationCreateConditionArgs = {
    input: ConditionInput;
};

export type MutationCreateConditionTypeArgs = {
    input: ConditionTypeInput;
};

export type MutationCreateConfigExpressionArgs = {
    input: ConfigExpressionInput;
};

export type MutationCreateConfigValueArgs = {
    input: ConfigValueInput;
};

export type MutationCreateConfigValueDefArgs = {
    input: ConfigValueDefInput;
};

export type MutationCreateContentDirectoryArgs = {
    input: ContentDirectoryInput;
};

export type MutationCreateDateTriggerArgs = {
    input: DateTriggerInput;
};

export type MutationCreateDependentUserArgs = {
    input: CreateDependentUserInput;
};

export type MutationCreateDeviceArgs = {
    input: DeviceInput;
};

export type MutationCreateDirectoryCacheArgs = {
    input: DirectoryCacheInput;
};

export type MutationCreateEligibilityMatchArgs = {
    input: EligibilityMatchInput;
};

export type MutationCreateEpisodeArgs = {
    input: EpisodeInput;
};

export type MutationCreateEventTriggerArgs = {
    input: EventTriggerInput;
};

export type MutationCreateHaCreatedTodoTaskArgs = {
    input: TodoCreateInput;
};

export type MutationCreateHealthPlanArgs = {
    input: HealthPlanInput;
};

export type MutationCreateHighlightArgs = {
    input: HighlightInput;
};

export type MutationCreateHighlightFeedArgs = {
    input: HighlightFeedUpdateInput;
};

export type MutationCreateInterventionArgs = {
    input: InterventionInput;
};

export type MutationCreateManyAdvocateNotificationsArgs = {
    input: CreateManyAdvocateNotificationsInput;
};

export type MutationCreateManyAdvocateTaskTemplatesArgs = {
    input: CreateManyAdvocateTaskTemplatesInput;
};

export type MutationCreateManyAdvocateTaskTypesArgs = {
    input: CreateManyAdvocateTaskTypesInput;
};

export type MutationCreateManyAdvocateTasksArgs = {
    input: CreateManyAdvocateTasksInput;
};

export type MutationCreateManyAffiliateClaimsArgs = {
    input: CreateManyAffiliateClaimsInput;
};

export type MutationCreateManyAffiliateInvoicesArgs = {
    input: CreateManyAffiliateInvoicesInput;
};

export type MutationCreateManyAppUserArticlesArgs = {
    input: CreateManyAppUserArticlesInput;
};

export type MutationCreateManyAppUserEventTriggersArgs = {
    input: CreateManyAppUserEventTriggersInput;
};

export type MutationCreateManyAppUserTelemetrysArgs = {
    input: CreateManyAppUserTelemetrysInput;
};

export type MutationCreateManyAppUserTrendTriggersArgs = {
    input: CreateManyAppUserTrendTriggersInput;
};

export type MutationCreateManyAppUsersArgs = {
    input: CreateManyAppUsersInput;
};

export type MutationCreateManyApplicationsArgs = {
    input: CreateManyApplicationsInput;
};

export type MutationCreateManyAppointmentsArgs = {
    input: CreateManyAppointmentsInput;
};

export type MutationCreateManyArticleApprovalsArgs = {
    input: CreateManyArticleApprovalsInput;
};

export type MutationCreateManyArticleCachesArgs = {
    input: CreateManyArticleCachesInput;
};

export type MutationCreateManyArticleCategorysArgs = {
    input: CreateManyArticleCategorysInput;
};

export type MutationCreateManyArticleFeedsArgs = {
    input: CreateManyArticleFeedsInput;
};

export type MutationCreateManyArticleGroupsArgs = {
    input: CreateManyArticleGroupsInput;
};

export type MutationCreateManyArticlePromotionsArgs = {
    input: CreateManyArticlePromotionsInput;
};

export type MutationCreateManyArticlesArgs = {
    input: CreateManyArticlesInput;
};

export type MutationCreateManyAsyncJobsArgs = {
    input: CreateManyAsyncJobsInput;
};

export type MutationCreateManyBatchTasksArgs = {
    input: CreateManyBatchTasksInput;
};

export type MutationCreateManyBioScreensArgs = {
    input: CreateManyBioScreensInput;
};

export type MutationCreateManyCalendarsArgs = {
    input: CreateManyCalendarsInput;
};

export type MutationCreateManyCarePlansArgs = {
    input: CreateManyCarePlansInput;
};

export type MutationCreateManyCareTeamContactsArgs = {
    input: CreateManyCareTeamContactsInput;
};

export type MutationCreateManyCareTeamMemberTypesArgs = {
    input: CreateManyCareTeamMemberTypesInput;
};

export type MutationCreateManyCareTeamMembersArgs = {
    input: CreateManyCareTeamMembersInput;
};

export type MutationCreateManyCareTeamsArgs = {
    input: CreateManyCareTeamsInput;
};

export type MutationCreateManyChatConversationsArgs = {
    input: CreateManyChatConversationsInput;
};

export type MutationCreateManyChatMessagesArgs = {
    input: CreateManyChatMessagesInput;
};

export type MutationCreateManyChatRoomsArgs = {
    input: CreateManyChatRoomsInput;
};

export type MutationCreateManyClaimsByPatientIdsArgs = {
    input: CreateManyClaimsByPatientIdsInput;
};

export type MutationCreateManyClinicalSummarysArgs = {
    input: CreateManyClinicalSummarysInput;
};

export type MutationCreateManyCompoundQuestionsArgs = {
    input: CreateManyCompoundQuestionsInput;
};

export type MutationCreateManyConditionTypesArgs = {
    input: CreateManyConditionTypesInput;
};

export type MutationCreateManyConditionsArgs = {
    input: CreateManyConditionsInput;
};

export type MutationCreateManyConfigExpressionsArgs = {
    input: CreateManyConfigExpressionsInput;
};

export type MutationCreateManyConfigValueDefsArgs = {
    input: CreateManyConfigValueDefsInput;
};

export type MutationCreateManyConfigValuesArgs = {
    input: CreateManyConfigValuesInput;
};

export type MutationCreateManyContentDirectorysArgs = {
    input: CreateManyContentDirectorysInput;
};

export type MutationCreateManyDateTriggersArgs = {
    input: CreateManyDateTriggersInput;
};

export type MutationCreateManyDevicesArgs = {
    input: CreateManyDevicesInput;
};

export type MutationCreateManyDirectoryCachesArgs = {
    input: CreateManyDirectoryCachesInput;
};

export type MutationCreateManyEligibilityMatchsArgs = {
    input: CreateManyEligibilityMatchsInput;
};

export type MutationCreateManyEpisodesArgs = {
    input: CreateManyEpisodesInput;
};

export type MutationCreateManyEventTriggersArgs = {
    input: CreateManyEventTriggersInput;
};

export type MutationCreateManyHealthPlansArgs = {
    input: CreateManyHealthPlansInput;
};

export type MutationCreateManyHighlightFeedsArgs = {
    input: CreateManyHighlightFeedsInput;
};

export type MutationCreateManyHighlightsArgs = {
    input: CreateManyHighlightsInput;
};

export type MutationCreateManyInterventionsArgs = {
    input: CreateManyInterventionsInput;
};

export type MutationCreateManyMassUpdatePatientsArgs = {
    input: CreateManyMassUpdatePatientsInput;
};

export type MutationCreateManyMassUpdateTasksArgs = {
    input: CreateManyMassUpdateTasksInput;
};

export type MutationCreateManyMeasurementsArgs = {
    input: CreateManyMeasurementsInput;
};

export type MutationCreateManyMessageCenterTemplatesArgs = {
    input: CreateManyMessageCenterTemplatesInput;
};

export type MutationCreateManyMessageTemplatesArgs = {
    input: CreateManyMessageTemplatesInput;
};

export type MutationCreateManyOrdersArgs = {
    input: CreateManyOrdersInput;
};

export type MutationCreateManyOrganizationsArgs = {
    input: CreateManyOrganizationsInput;
};

export type MutationCreateManyPatientActionTypesArgs = {
    input: CreateManyPatientActionTypesInput;
};

export type MutationCreateManyPatientActionsArgs = {
    input: CreateManyPatientActionsInput;
};

export type MutationCreateManyPatientCallListsArgs = {
    input: CreateManyPatientCallListsInput;
};

export type MutationCreateManyPatientHeadersArgs = {
    input: CreateManyPatientHeadersInput;
};

export type MutationCreateManyPatientNoteTagsArgs = {
    input: CreateManyPatientNoteTagsInput;
};

export type MutationCreateManyPatientNotessArgs = {
    input: CreateManyPatientNotessInput;
};

export type MutationCreateManyPatientsArgs = {
    input: CreateManyPatientsInput;
};

export type MutationCreateManyPotentialUsersArgs = {
    input: CreateManyPotentialUsersInput;
};

export type MutationCreateManyProfileKeysArgs = {
    input: CreateManyProfileKeysInput;
};

export type MutationCreateManyProfileValueHistorysArgs = {
    input: CreateManyProfileValueHistorysInput;
};

export type MutationCreateManyProfileValueOriginsArgs = {
    input: CreateManyProfileValueOriginsInput;
};

export type MutationCreateManyProfileValuesArgs = {
    input: CreateManyProfileValuesInput;
};

export type MutationCreateManyProvidersArgs = {
    input: CreateManyProvidersInput;
};

export type MutationCreateManyQListsArgs = {
    input: CreateManyQListsInput;
};

export type MutationCreateManyQuestionsArgs = {
    input: CreateManyQuestionsInput;
};

export type MutationCreateManyReferenceLinkCategorysArgs = {
    input: CreateManyReferenceLinkCategorysInput;
};

export type MutationCreateManyReferenceLinksArgs = {
    input: CreateManyReferenceLinksInput;
};

export type MutationCreateManyReportingCategorysArgs = {
    input: CreateManyReportingCategorysInput;
};

export type MutationCreateManyReportingJobsArgs = {
    input: CreateManyReportingJobsInput;
};

export type MutationCreateManyRolesArgs = {
    input: CreateManyRolesInput;
};

export type MutationCreateManySentMessagesArgs = {
    input: CreateManySentMessagesInput;
};

export type MutationCreateManySimpleContentsArgs = {
    input: CreateManySimpleContentsInput;
};

export type MutationCreateManySlaTimersArgs = {
    input: CreateManySlaTimersInput;
};

export type MutationCreateManySlasArgs = {
    input: CreateManySlasInput;
};

export type MutationCreateManySubmittedClaimsArgs = {
    input: CreateManySubmittedClaimsInput;
};

export type MutationCreateManySubmittedClaimsViaFileArgs = {
    input: CreateManySubmittedClaimsViaFileInput;
};

export type MutationCreateManySurveyDefsArgs = {
    input: CreateManySurveyDefsInput;
};

export type MutationCreateManySurveyResponsesArgs = {
    input: CreateManySurveyResponsesInput;
};

export type MutationCreateManySystemTokensArgs = {
    input: CreateManySystemTokensInput;
};

export type MutationCreateManyTagsArgs = {
    input: CreateManyTagsInput;
};

export type MutationCreateManyTodoTasksArgs = {
    input: CreateManyTodoTasksInput;
};

export type MutationCreateManyTokensArgs = {
    input: CreateManyTokensInput;
};

export type MutationCreateManyTrackerHistorysArgs = {
    input: CreateManyTrackerHistorysInput;
};

export type MutationCreateManyTrackersArgs = {
    input: CreateManyTrackersInput;
};

export type MutationCreateManyTrendTriggersArgs = {
    input: CreateManyTrendTriggersInput;
};

export type MutationCreateManyUserProfileDefsArgs = {
    input: CreateManyUserProfileDefsInput;
};

export type MutationCreateManyUsersArgs = {
    input: CreateManyUsersInput;
};

export type MutationCreateManyVcZipCodeToLocationsArgs = {
    input: CreateManyVcZipCodeToLocationsInput;
};

export type MutationCreateManyVirtualCareAffiliatessArgs = {
    input: CreateManyVirtualCareAffiliatessInput;
};

export type MutationCreateManyVirtualCareEligibilityWhitelistsArgs = {
    input: CreateManyVirtualCareEligibilityWhitelistsInput;
};

export type MutationCreateManyVirtualCareFormsArgs = {
    input: CreateManyVirtualCareFormsInput;
};

export type MutationCreateManyWhatsNewTimelinesArgs = {
    input: CreateManyWhatsNewTimelinesInput;
};

export type MutationCreateManyWhoToCallsArgs = {
    input: CreateManyWhoToCallsInput;
};

export type MutationCreateMassUpdatePatientArgs = {
    input: MassUpdatePatientsInput;
};

export type MutationCreateMassUpdateTaskArgs = {
    input: MassUpdateTaskInput;
};

export type MutationCreateMeasurementArgs = {
    input: MeasurementInput;
};

export type MutationCreateMessageCenterTemplateArgs = {
    input: MessageCenterTemplateInput;
};

export type MutationCreateMessageTemplateArgs = {
    input: MessageTemplateInput;
};

export type MutationCreateOrUpdateAppUserArgs = {
    input: CreateAppUserInput;
};

export type MutationCreateOrUpdateManyUserProfileVariablesArgs = {
    input: Array<ProfileVariableInput>;
};

export type MutationCreateOrUpdateOneQListArgs = {
    input: QListUpdateInput;
};

export type MutationCreateOrUpdateOneTrackerArgs = {
    input: TrackerUpdateInput;
};

export type MutationCreateOrUpdateOneUserProfileVariableArgs = {
    input: ProfileVariableInput;
};

export type MutationCreateOrUpdateOneWhoToCallArgs = {
    input: WhoToCallUpdateInput;
};

export type MutationCreateOrUpdatePatientsWithDeviceOrdersArgs = {
    input: CreateOrUpdatePatientsWithDeviceOrdersInput;
};

export type MutationCreateOrUpdateTodoTaskArgs = {
    input: CreateOrUpdateTodoTaskInput;
};

export type MutationCreateOrUpdateVirtualCareFormArgs = {
    input?: InputMaybe<VirtualCareFormInput>;
};

export type MutationCreateOrderArgs = {
    input: OrderInput;
};

export type MutationCreateOrganizationArgs = {
    input: OrganizationInput;
};

export type MutationCreatePatientArgs = {
    input: PatientInput;
};

export type MutationCreatePatientActionArgs = {
    input: PatientActionInput;
};

export type MutationCreatePatientActionTypeArgs = {
    input: PatientActionTypeInput;
};

export type MutationCreatePatientActionsArgs = {
    input: PatientActionsInput;
};

export type MutationCreatePatientCallListArgs = {
    input?: InputMaybe<PatientCallListInput>;
};

export type MutationCreatePatientForAppUserArgs = {
    input: PatientInputForAppUser;
};

export type MutationCreatePatientForSelfSignUpArgs = {
    input: CreatePatientForSelfSignUpInput;
};

export type MutationCreatePatientHeaderArgs = {
    input: PatientHeaderInput;
};

export type MutationCreatePatientNoteTagArgs = {
    input: PatientNoteTagInput;
};

export type MutationCreatePatientNotesArgs = {
    input: PatientNotesInput;
};

export type MutationCreatePotentialUserArgs = {
    input: PotentialUserInput;
};

export type MutationCreateProfileKeyArgs = {
    input: ProfileKeyInput;
};

export type MutationCreateProfileValueArgs = {
    input: ProfileValueInput;
};

export type MutationCreateProfileValueHistoryArgs = {
    input: ProfileValueHistoryInput;
};

export type MutationCreateProfileValueOriginArgs = {
    input: ProfileValueOriginInput;
};

export type MutationCreateProviderArgs = {
    input: ProviderInput;
};

export type MutationCreateQListArgs = {
    input: QListInput;
};

export type MutationCreateQuestionArgs = {
    input: QuestionInput;
};

export type MutationCreateReferenceLinkArgs = {
    input: ReferenceLinkInput;
};

export type MutationCreateReferenceLinkCategoryArgs = {
    input: ReferenceLinkCategoryInput;
};

export type MutationCreateReportingCategoryArgs = {
    input: ReportingCategoryInput;
};

export type MutationCreateReportingJobArgs = {
    input: ReportingJobInput;
};

export type MutationCreateRoleArgs = {
    input: RoleInput;
};

export type MutationCreateSentMessageArgs = {
    input: SentMessageInput;
};

export type MutationCreateSimpleContentArgs = {
    input: SimpleContentInput;
};

export type MutationCreateSlaArgs = {
    input: SlaInput;
};

export type MutationCreateSlaTimerArgs = {
    input: SlaTimerInput;
};

export type MutationCreateSlaTimerForAdvocateTaskArgs = {
    input: CreateSlaTimerForAdvocateTaskInput;
};

export type MutationCreateSubmittedClaimArgs = {
    input: ClaimSubmittedInput;
};

export type MutationCreateSurveyDefArgs = {
    input: SurveyDefInput;
};

export type MutationCreateSurveyResponseArgs = {
    input: SurveyResponseInput;
};

export type MutationCreateSystemTokenArgs = {
    input: SystemTokenInput;
};

export type MutationCreateTagArgs = {
    input: TagInput;
};

export type MutationCreateTestAppUserMutationArgs = {
    input?: InputMaybe<CreateTestAppUserInput>;
};

export type MutationCreateTestUserArgs = {
    input: CreateTestAppUserInput;
};

export type MutationCreateTodoTaskArgs = {
    input: TodoTaskInput;
};

export type MutationCreateTokenArgs = {
    input: TokenInput;
};

export type MutationCreateTrackerArgs = {
    input: TrackerInput;
};

export type MutationCreateTrackerHistoryArgs = {
    input: TrackerHistoryInput;
};

export type MutationCreateTrendTriggerArgs = {
    input: TrendTriggerInput;
};

export type MutationCreateUserArgs = {
    input: UserInput;
};

export type MutationCreateUserProfileDefArgs = {
    input: UserProfileDefInput;
};

export type MutationCreateVcZipCodeToLocationArgs = {
    input: AddVcZipCodeToLocationInput;
};

export type MutationCreateVirtualCareAffiliateArgs = {
    input?: InputMaybe<CreateVirtualCareAffiliateInput>;
};

export type MutationCreateVirtualCareAffiliatesArgs = {
    input: CreateVirtualCareAffiliatesInput;
};

export type MutationCreateVirtualCareEligibilityWhitelistArgs = {
    input: VirtualCareEligibilityWhitelistInput;
};

export type MutationCreateVirtualCareFormArgs = {
    input: VirtualCareFormInput;
};

export type MutationCreateWhatsNewTimelineArgs = {
    input: WhatsNewTimelineInput;
};

export type MutationCreateWhoToCallArgs = {
    input: WhoToCallInput;
};

export type MutationDeleteAdvocateNotificationArgs = {
    input: DeleteAdvocateNotificationInput;
};

export type MutationDeleteAdvocateTaskArgs = {
    input: DeleteAdvocateTaskInput;
};

export type MutationDeleteAdvocateTaskTemplateArgs = {
    id: Scalars['String'];
};

export type MutationDeleteAdvocateTaskTypeArgs = {
    input: DeleteAdvocateTaskTypeInput;
};

export type MutationDeleteAdvocateTasksForAppointmentArgs = {
    id: Scalars['String'];
};

export type MutationDeleteAffiliateClaimArgs = {
    input: DeleteAffiliateClaimInput;
};

export type MutationDeleteAffiliateInvoiceArgs = {
    input: DeleteAffiliateInvoiceInput;
};

export type MutationDeleteAllUserProfileVariablesArgs = {
    input: DeleteManyProfileVariablesInput;
};

export type MutationDeleteAndReplacePatientActionTypeArgs = {
    input?: InputMaybe<DeleteAndReplacePatientActionTypeInput>;
};

export type MutationDeleteAppUserArgs = {
    input: DeleteAppUserInput;
};

export type MutationDeleteAppUserArticleArgs = {
    input: DeleteAppUserArticleInput;
};

export type MutationDeleteAppUserEventTriggerArgs = {
    input: DeleteAppUserEventTriggerInput;
};

export type MutationDeleteAppUserTelemetryArgs = {
    input: DeleteAppUserTelemetryInput;
};

export type MutationDeleteAppUserTrendTriggerArgs = {
    input: DeleteAppUserTrendTriggerInput;
};

export type MutationDeleteAppUserV2Args = {
    input: DeleteAppUserInput;
};

export type MutationDeleteApplicationArgs = {
    input: DeleteApplicationInput;
};

export type MutationDeleteAppointmentArgs = {
    input: DeleteAppointmentInput;
};

export type MutationDeleteArticleArgs = {
    input: Scalars['String'];
};

export type MutationDeleteArticleApprovalArgs = {
    input: DeleteArticleApprovalInput;
};

export type MutationDeleteArticleCacheArgs = {
    input: DeleteArticleCacheInput;
};

export type MutationDeleteArticleCategoryArgs = {
    id: Scalars['String'];
};

export type MutationDeleteArticleFeedArgs = {
    input: DeleteArticleFeedInput;
};

export type MutationDeleteArticleGroupArgs = {
    input: DeleteArticleGroupInput;
};

export type MutationDeleteArticlePromotionArgs = {
    input: DeleteArticlePromotionInput;
};

export type MutationDeleteAsyncJobArgs = {
    input: DeleteAsyncJobInput;
};

export type MutationDeleteBatchTaskArgs = {
    input: DeleteBatchTaskInput;
};

export type MutationDeleteBioScreenArgs = {
    input: DeleteBioScreenInput;
};

export type MutationDeleteCalendarArgs = {
    input: DeleteCalendarInput;
};

export type MutationDeleteCarePlanArgs = {
    input: DeleteCarePlanInput;
};

export type MutationDeleteCareTeamArgs = {
    input: DeleteCareTeamInput;
};

export type MutationDeleteCareTeamContactArgs = {
    input: DeleteCareTeamContactInput;
};

export type MutationDeleteCareTeamMemberArgs = {
    id: Scalars['String'];
};

export type MutationDeleteCareTeamMemberTypeArgs = {
    id: Scalars['String'];
};

export type MutationDeleteChatConversationArgs = {
    input: DeleteChatConversationInput;
};

export type MutationDeleteChatMessageArgs = {
    input: DeleteChatMessageInput;
};

export type MutationDeleteChatRoomArgs = {
    input: DeleteChatRoomInput;
};

export type MutationDeleteClinicalSummaryArgs = {
    input: DeleteClinicalSummaryInput;
};

export type MutationDeleteCompoundQuestionArgs = {
    input: DeleteCompoundQuestionInput;
};

export type MutationDeleteConditionArgs = {
    input: DeleteConditionInput;
};

export type MutationDeleteConditionTypeArgs = {
    id: Scalars['String'];
};

export type MutationDeleteConfigExpressionArgs = {
    input: DeleteConfigExpressionInput;
};

export type MutationDeleteConfigValueArgs = {
    input: DeleteConfigValueInput;
};

export type MutationDeleteConfigValueByValueDefIdArgs = {
    input: DeleteConfigValueByValueDefIdInput;
};

export type MutationDeleteConfigValueDefArgs = {
    input: DeleteConfigValueDefInput;
};

export type MutationDeleteContentDirectoryArgs = {
    id: Scalars['String'];
};

export type MutationDeleteDateTriggerArgs = {
    id: Scalars['String'];
};

export type MutationDeleteDeviceArgs = {
    input: DeleteDeviceInput;
};

export type MutationDeleteDirectoryCacheArgs = {
    input: DeleteDirectoryCacheInput;
};

export type MutationDeleteDirectoryCacheForCdArgs = {
    contentDirectoryId?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteEligibilityMatchArgs = {
    input: DeleteEligibilityMatchInput;
};

export type MutationDeleteEpisodeArgs = {
    input: DeleteEpisodeInput;
};

export type MutationDeleteEventTriggerArgs = {
    id: Scalars['String'];
};

export type MutationDeleteExpressionArgs = {
    id: Scalars['String'];
};

export type MutationDeleteHealthPlanArgs = {
    input: DeleteHealthPlanInput;
};

export type MutationDeleteHighlightArgs = {
    input: DeleteHighlightInput;
};

export type MutationDeleteHighlightFeedArgs = {
    input: DeleteHighlightFeedInput;
};

export type MutationDeleteInterventionArgs = {
    input: DeleteInterventionInput;
};

export type MutationDeleteMassUpdatePatientArgs = {
    input: DeleteMassUpdatePatientInput;
};

export type MutationDeleteMassUpdatePatientListArgs = {
    id: Scalars['String'];
};

export type MutationDeleteMassUpdateTaskArgs = {
    input: DeleteMassUpdateTaskInput;
};

export type MutationDeleteMeasurementArgs = {
    input: DeleteMeasurementInput;
};

export type MutationDeleteMessageCenterTemplateArgs = {
    id: Scalars['String'];
};

export type MutationDeleteMessageTemplateArgs = {
    input: DeleteMessageTemplateInput;
};

export type MutationDeleteNotificationTokenArgs = {
    input: DeleteNotificationTokenInput;
};

export type MutationDeleteOneUserProfileVariableArgs = {
    input: DeleteProfileVariableInput;
};

export type MutationDeleteOrderArgs = {
    input: DeleteOrderInput;
};

export type MutationDeleteOrganizationArgs = {
    input: DeleteOrganizationInput;
};

export type MutationDeleteOrganizationInviteCodeArgs = {
    input: DeleteOrganizationInviteCodeInput;
};

export type MutationDeletePatientArgs = {
    input: DeletePatientInput;
};

export type MutationDeletePatientActionArgs = {
    input: DeletePatientActionInput;
};

export type MutationDeletePatientActionByStepArgs = {
    input: DeletePatientActionByStepInput;
};

export type MutationDeletePatientActionTypeArgs = {
    id?: InputMaybe<Scalars['String']>;
};

export type MutationDeletePatientAppUserDownstreamDataArgs = {
    input: DeletePatientAppUserDownstreamDataInput;
};

export type MutationDeletePatientCallListArgs = {
    input: DeletePatientCallListInput;
};

export type MutationDeletePatientHeaderArgs = {
    input: DeletePatientHeaderInput;
};

export type MutationDeletePatientInvitationArgs = {
    input: DeletePatientInvitationInput;
};

export type MutationDeletePatientNoteTagArgs = {
    input: DeletePatientNoteTagInput;
};

export type MutationDeletePatientNotesArgs = {
    input: DeletePatientNotesInput;
};

export type MutationDeletePatientsForAppArgs = {
    appBundleId: Scalars['String'];
};

export type MutationDeletePotentialUserArgs = {
    input: DeletePotentialUserInput;
};

export type MutationDeleteProfileKeyArgs = {
    input: DeleteProfileKeyInput;
};

export type MutationDeleteProfileValueArgs = {
    input: DeleteProfileValueInput;
};

export type MutationDeleteProfileValueHistoryArgs = {
    input: DeleteProfileValueHistoryInput;
};

export type MutationDeleteProfileValueOriginArgs = {
    input: DeleteProfileValueOriginInput;
};

export type MutationDeleteProviderArgs = {
    input: DeleteProviderInput;
};

export type MutationDeleteProxiedTodoTaskArgs = {
    input?: InputMaybe<TodoDeleteInput>;
};

export type MutationDeleteQListArgs = {
    input: DeleteQListInput;
};

export type MutationDeleteQuestionArgs = {
    input: DeleteQuestionInput;
};

export type MutationDeleteReferenceLinkArgs = {
    input: DeleteReferenceLinkInput;
};

export type MutationDeleteReferenceLinkCategoryArgs = {
    input: DeleteReferenceLinkCategoryInput;
};

export type MutationDeleteReportingCategoryArgs = {
    id: Scalars['String'];
};

export type MutationDeleteReportingJobArgs = {
    input: DeleteReportingJobInput;
};

export type MutationDeleteRoleArgs = {
    input: Scalars['String'];
};

export type MutationDeleteSentMessageArgs = {
    input: DeleteSentMessageInput;
};

export type MutationDeleteSimpleContentArgs = {
    input: ContentDeleteInput;
};

export type MutationDeleteSlaArgs = {
    input: DeleteSlaInput;
};

export type MutationDeleteSlaTimerArgs = {
    input: DeleteSlaTimerInput;
};

export type MutationDeleteSubmittedClaimArgs = {
    input: DeleteSubmittedClaimInput;
};

export type MutationDeleteSurveyDefArgs = {
    id: Scalars['String'];
};

export type MutationDeleteSurveyResponseArgs = {
    input: DeleteSurveyResponseInput;
};

export type MutationDeleteSystemTokenArgs = {
    input: DeleteSystemTokenInput;
};

export type MutationDeleteTagArgs = {
    input: DeleteTagInput;
};

export type MutationDeleteTimelineArgs = {
    id: Scalars['String'];
};

export type MutationDeleteTodoTaskArgs = {
    input: DeleteTodoTaskInput;
};

export type MutationDeleteTodoTasksArgs = {
    input: DeleteTodoTasksInput;
};

export type MutationDeleteTokenArgs = {
    input: DeleteTokenInput;
};

export type MutationDeleteTrackerArgs = {
    input: DeleteTrackerInput;
};

export type MutationDeleteTrackerHistoryArgs = {
    input: DeleteTrackerHistoryInput;
};

export type MutationDeleteTrendTriggerArgs = {
    id: Scalars['String'];
};

export type MutationDeleteUserArgs = {
    input: DeleteUserInput;
};

export type MutationDeleteUserProfileDefArgs = {
    id: Scalars['String'];
};

export type MutationDeleteVcZipCodeToLocationArgs = {
    input: DeleteVcZipCodeToLocationInput;
};

export type MutationDeleteVariantArgs = {
    input: DeleteVariantInput;
};

export type MutationDeleteVirtualCareAffiliateArgs = {
    input?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteVirtualCareAffiliatesArgs = {
    input: DeleteVirtualCareAffiliatesInput;
};

export type MutationDeleteVirtualCareEligibilityWhitelistArgs = {
    input: DeleteVirtualCareEligibilityWhitelistInput;
};

export type MutationDeleteVirtualCareFormArgs = {
    input: DeleteVirtualCareFormInput;
};

export type MutationDeleteWhatsNewTimelineArgs = {
    input: DeleteWhatsNewTimelineInput;
};

export type MutationDeleteWhoToCallArgs = {
    input: DeleteWhoToCallInput;
};

export type MutationEligibilityVerificationArgs = {
    input: EligibilityInput;
};

export type MutationEligibilityVerificationManyArgs = {
    input: EligibilityBulkInput;
};

export type MutationFollowAllPatientsByOrgArgs = {
    input?: InputMaybe<FollowAllPatientsByOrgInput>;
};

export type MutationFollowAllUsersArgs = {
    input?: InputMaybe<FollowAllUsersInput>;
};

export type MutationForceClaimUploadArgs = {
    input: ForceClaimUploadInput;
};

export type MutationHighlightFeedCreateManyArgs = {
    docs?: InputMaybe<Array<InputMaybe<HighlightFeedUpdateInput>>>;
};

export type MutationHighlightFeedDeleteByAppUserArgs = {
    appUserId?: InputMaybe<Scalars['String']>;
};

export type MutationHighlightFeedDeleteByHighlightForAppArgs = {
    appBundleId?: InputMaybe<Scalars['String']>;
    highlightId?: InputMaybe<Scalars['String']>;
};

export type MutationHighlightFeedDeleteByHighlightIdArgs = {
    highlightId?: InputMaybe<Scalars['String']>;
};

export type MutationHighlightFeedDeleteByTimelineIdArgs = {
    appUserId?: InputMaybe<Scalars['String']>;
    timelineId?: InputMaybe<Scalars['String']>;
};

export type MutationHighlightFeedTimelinesNotFoundDeleteArgs = {
    input?: InputMaybe<HighlightFeedTimelinesNotFoundInput>;
};

export type MutationHighlightFeedUpdatePriorityClosedArgs = {
    input?: InputMaybe<PatientHighlightUpdateInput>;
};

export type MutationHighlightFeedUpsertManyArgs = {
    docs?: InputMaybe<Array<InputMaybe<HighlightFeedUpdateInput>>>;
};

export type MutationIncrementBatchTaskCompletedRecordsArgs = {
    input: IncrementBatchTaskInput;
};

export type MutationInviteUserArgs = {
    input: InviteUserInput;
};

export type MutationLoginAppUserArgs = {
    input: LoginAppUserInput;
};

export type MutationLoginUserArgs = {
    input: LoginUserInput;
};

export type MutationManageAppointmentsFromExternalSourceArgs = {
    input: CreateOrUpdateAppointmentsFromExternalSourceInput;
};

export type MutationMoveMessagesInChatConversationArgs = {
    input?: InputMaybe<MoveMessagesInChatConversationInput>;
};

export type MutationPostMessageCenterEventArgs = {
    input: PostMessageCenterInput;
};

export type MutationPostProfileValueChangedEventArgs = {
    input: PostProfileValueChangedInput;
};

export type MutationPostSurveyCompletedEventArgs = {
    input: PostSurveyCompletedInput;
};

export type MutationPostTrackerChangedEventArgs = {
    input: PostTrackerChangedInput;
};

export type MutationPublishArticleArgs = {
    input: Scalars['String'];
};

export type MutationRecheckEligibilityArgs = {
    input: RecheckEligibilityInput;
};

export type MutationRegisterAppUserWithoutAcceptingInviteCodeArgs = {
    input: RegisterAppUserWithoutAcceptingInviteCodeInput;
};

export type MutationResendAppUserInviteCodeArgs = {
    input: ResendAppUserInviteCodeInput;
};

export type MutationResendPatientCodeOnResetPasswordArgs = {
    input: ResendPatientCodeOnResetPasswordInput;
};

export type MutationResendPatientInvitationArgs = {
    input: ResendPatientInvitationInput;
};

export type MutationResendPatientInvitationByEmailIfExistsArgs = {
    input: ResendPatientInvitationByEmailIfExistsInput;
};

export type MutationResendPatientInvitationFromPortalArgs = {
    input: ResendPatientInvitationFromPortalInput;
};

export type MutationResetAppUserPasswordArgs = {
    input: ResetAppUserPasswordInput;
};

export type MutationResetPasswordArgs = {
    input: ResetPasswordInput;
};

export type MutationResetPatientPasswordFromPortalArgs = {
    input: ResetPatientPasswordFromPortalInput;
};

export type MutationResolveChatConversationArgs = {
    input?: InputMaybe<ResolveChatConversationInput>;
};

export type MutationSaveHighlightArgs = {
    input: SaveHighlightInput;
};

export type MutationSendInvitationCodeArgs = {
    input: SendInvitationCodeInput;
};

export type MutationSendInvitationCodeOnResetPasswordArgs = {
    input: SendInvitationCodeOnResetPasswordInput;
};

export type MutationSendInvitationFromPortalCodeArgs = {
    input: SendInvitationFromPortalCodeInput;
};

export type MutationSendMessageArgs = {
    input?: InputMaybe<SendMessageInput>;
};

export type MutationSendMessageCenterNotificationArgs = {
    patientId: Scalars['String'];
};

export type MutationSendMfaCodeArgs = {
    input: SendMfaCodeInput;
};

export type MutationSendNotificationsForAppUserArgs = {
    input: SendNotificationInput;
};

export type MutationSendResetPasswordInvitationArgs = {
    input: SendResetPasswordInvitationInput;
};

export type MutationSetAppUserContentCreatedArgs = {
    appUserId?: InputMaybe<Scalars['String']>;
};

export type MutationSetAppUserContentStatusArgs = {
    input?: InputMaybe<AppUserSetContentStatusInput>;
};

export type MutationSetCareTeamPracticeByAppointmentArgs = {
    input: SetCareTeamValueInput;
};

export type MutationSetDemographicsPVsArgs = {
    input: SetDemographicsPVsInput;
};

export type MutationSetManyPvByExternalIdArgs = {
    input: Array<SetPvByExternalIdInput>;
};

export type MutationSetNotificationTokenArgs = {
    input: SetNotificationTokenInput;
};

export type MutationSetPatientAppUserArgs = {
    input: SetPatientAppUserInput;
};

export type MutationSetPatientProfileValueArgs = {
    input: SetPatientProfileValueInput;
};

export type MutationSetPatientProfileValuesArgs = {
    input: SetPatientProfileValuesInput;
};

export type MutationSetPayerMemberNumPvArgs = {
    input: PayerMemberNumInput;
};

export type MutationSetTasksTestDataArgs = {
    isTestData: Scalars['Boolean'];
    patientId: Scalars['String'];
};

export type MutationSubmitMassUpdateTaskArgs = {
    taskId: Scalars['String'];
};

export type MutationTextOptOutArgs = {
    input?: InputMaybe<TextOptOutInput>;
};

export type MutationUnsubscribePatientEmailArgs = {
    id: Scalars['String'];
    setting: Scalars['Boolean'];
};

export type MutationUnsubscribePatientTextArgs = {
    id: Scalars['String'];
    setting: Scalars['Boolean'];
};

export type MutationUpdateAccessCodeArgs = {
    input: EligibilityUpdateAccessCodeInput;
};

export type MutationUpdateAdvocateNotificationArgs = {
    input: UpdateAdvocateNotificationInput;
};

export type MutationUpdateAdvocateTaskArgs = {
    input: UpdateAdvocateTaskInput;
};

export type MutationUpdateAdvocateTaskEncodedArgs = {
    input?: InputMaybe<UpdateAdvocateTaskEncodedInput>;
};

export type MutationUpdateAdvocateTaskTemplateArgs = {
    input: UpdateAdvocateTaskTemplateInput;
};

export type MutationUpdateAdvocateTaskTemplateEncodedArgs = {
    input: UpdateAdvocateTaskTemplateEncodedInput;
};

export type MutationUpdateAdvocateTaskTypeArgs = {
    input: UpdateAdvocateTaskTypeInput;
};

export type MutationUpdateAffiliateClaimArgs = {
    input: UpdateAffiliateClaimInput;
};

export type MutationUpdateAffiliateInvoiceArgs = {
    input: UpdateAffiliateInvoiceInput;
};

export type MutationUpdateAffiliateInvoicesPaymentStatusArgs = {
    input?: InputMaybe<UpdateAffiliateInvoicesPaymentStatusInput>;
};

export type MutationUpdateAppUserArgs = {
    input: UpdateAppUserInput;
};

export type MutationUpdateAppUserArticleArgs = {
    input: UpdateAppUserArticleInput;
};

export type MutationUpdateAppUserEmailArgs = {
    input?: InputMaybe<UpdateAppUserEmailInput>;
};

export type MutationUpdateAppUserEventTriggerArgs = {
    input: UpdateAppUserEventTriggerInput;
};

export type MutationUpdateAppUserLanguageArgs = {
    input: UpdateAppUserLanguageInput;
};

export type MutationUpdateAppUserTelemetryArgs = {
    input: UpdateAppUserTelemetryInput;
};

export type MutationUpdateAppUserTrendTriggerArgs = {
    input: UpdateAppUserTrendTriggerInput;
};

export type MutationUpdateApplicationArgs = {
    input: UpdateApplicationInput;
};

export type MutationUpdateAppointmentArgs = {
    input: UpdateAppointmentInput;
};

export type MutationUpdateAppointmentReportKeyArgs = {
    input: UpdateReportKeyInput;
};

export type MutationUpdateApprovalNameArgs = {
    input: UpdateApprovalNameInput;
};

export type MutationUpdateArticleArgs = {
    input: UpdateArticleInput;
};

export type MutationUpdateArticleApprovalArgs = {
    input: UpdateArticleApprovalInput;
};

export type MutationUpdateArticleCacheArgs = {
    input: UpdateArticleCacheInput;
};

export type MutationUpdateArticleCategoryArgs = {
    input: UpdateArticleCategoryInput;
};

export type MutationUpdateArticleEncodedArgs = {
    input?: InputMaybe<UpdateArticleEncodedInput>;
};

export type MutationUpdateArticleFeedArgs = {
    input: UpdateArticleFeedInput;
};

export type MutationUpdateArticleGroupArgs = {
    input: UpdateArticleGroupInput;
};

export type MutationUpdateArticlePromotionArgs = {
    input: UpdateArticlePromotionInput;
};

export type MutationUpdateAsyncJobArgs = {
    input: UpdateAsyncJobInput;
};

export type MutationUpdateBatchClaimsStatusArgs = {
    input: Array<UpdateBatchClaimStatusInput>;
};

export type MutationUpdateBatchTaskArgs = {
    input: UpdateBatchTaskInput;
};

export type MutationUpdateBioScreenArgs = {
    input: UpdateBioScreenInput;
};

export type MutationUpdateCalendarArgs = {
    input: CalendarUpdateInput;
};

export type MutationUpdateCarePlanArgs = {
    input: UpdateCarePlanInput;
};

export type MutationUpdateCarePlanOffsetsArgs = {
    input?: InputMaybe<UpdateCarePlanOffsetsInput>;
};

export type MutationUpdateCareTeamArgs = {
    input: UpdateCareTeamInput;
};

export type MutationUpdateCareTeamContactArgs = {
    input: UpdateCareTeamContactInput;
};

export type MutationUpdateCareTeamMemberArgs = {
    input: UpdateCareTeamMemberInput;
};

export type MutationUpdateCareTeamMemberTypeArgs = {
    input: UpdateCareTeamMemberTypeInput;
};

export type MutationUpdateChatConversationArgs = {
    input: UpdateChatConversationInput;
};

export type MutationUpdateChatMessageArgs = {
    input: UpdateChatMessageInput;
};

export type MutationUpdateChatRoomArgs = {
    input: UpdateChatRoomInput;
};

export type MutationUpdateClinicalSummaryArgs = {
    input: UpdateClinicalSummaryInput;
};

export type MutationUpdateCompoundQuestionArgs = {
    input: UpdateCompoundQuestionInput;
};

export type MutationUpdateConditionArgs = {
    input: UpdateConditionInput;
};

export type MutationUpdateConditionTypeArgs = {
    input: UpdateConditionTypeInput;
};

export type MutationUpdateConfigExpressionArgs = {
    input: UpdateConfigExpressionInput;
};

export type MutationUpdateConfigValueArgs = {
    input: UpdateConfigValueInput;
};

export type MutationUpdateConfigValueDefArgs = {
    input: UpdateConfigValueDefInput;
};

export type MutationUpdateContentDirectoryArgs = {
    input: UpdateContentDirectoryInput;
};

export type MutationUpdateDateTriggerArgs = {
    input: UpdateDateTriggerInput;
};

export type MutationUpdateDeviceArgs = {
    input: UpdateDeviceInput;
};

export type MutationUpdateDirectoryCacheArgs = {
    input: UpdateDirectoryCacheInput;
};

export type MutationUpdateEligibilityMatchArgs = {
    input: UpdateEligibilityMatchInput;
};

export type MutationUpdateEpisodeArgs = {
    input: UpdateEpisodeInput;
};

export type MutationUpdateEventTriggerArgs = {
    input: UpdateEventTriggerInput;
};

export type MutationUpdateHealthPlanArgs = {
    input: UpdateHealthPlanInput;
};

export type MutationUpdateHighlightArgs = {
    input: UpdateHighlightInput;
};

export type MutationUpdateHighlightFeedArgs = {
    input: UpdateHighlightFeedInput;
};

export type MutationUpdateInterventionArgs = {
    input: UpdateInterventionInput;
};

export type MutationUpdateMassUpdatePatientArgs = {
    input: UpdateMassUpdatePatientInput;
};

export type MutationUpdateMassUpdateTaskArgs = {
    input: UpdateMassUpdateTaskInput;
};

export type MutationUpdateMeasurementArgs = {
    input: UpdateMeasurementInput;
};

export type MutationUpdateMessageCenterTemplateArgs = {
    input: UpdateMessageCenterTemplateInput;
};

export type MutationUpdateMessageTemplateArgs = {
    input: UpdateMessageTemplateInput;
};

export type MutationUpdateOrderArgs = {
    input: UpdateOrderInput;
};

export type MutationUpdateOrganizationArgs = {
    input: UpdateOrganizationInput;
};

export type MutationUpdateParticipantsArgs = {
    input?: InputMaybe<UpdateParticipantsForChatRoomInput>;
};

export type MutationUpdatePatientArgs = {
    input: UpdatePatientInput;
};

export type MutationUpdatePatientActionArgs = {
    input: UpdatePatientActionInput;
};

export type MutationUpdatePatientActionTypeArgs = {
    input: UpdatePatientActionTypeInput;
};

export type MutationUpdatePatientCallListArgs = {
    input?: InputMaybe<PatientCallListUpdateInput>;
};

export type MutationUpdatePatientCallListCallStatusArgs = {
    input?: InputMaybe<UpdatePatientCallListCallStatusInput>;
};

export type MutationUpdatePatientHeaderArgs = {
    input: UpdatePatientHeaderInput;
};

export type MutationUpdatePatientNoteTagArgs = {
    input: UpdatePatientNoteTagInput;
};

export type MutationUpdatePatientNotesArgs = {
    input: UpdatePatientNotesInput;
};

export type MutationUpdatePatientProfileVariableArgs = {
    input: PatientProfileVariableInput;
};

export type MutationUpdatePotentialUserArgs = {
    input: UpdatePotentialUserInput;
};

export type MutationUpdateProfileKeyArgs = {
    input: UpdateProfileKeyInput;
};

export type MutationUpdateProfileValueArgs = {
    input: UpdateProfileValueInput;
};

export type MutationUpdateProfileValueHistoryArgs = {
    input: UpdateProfileValueHistoryInput;
};

export type MutationUpdateProfileValueOriginArgs = {
    input: UpdateProfileValueOriginInput;
};

export type MutationUpdateProfileVariableFromPatientPageArgs = {
    input: UpdateProfileVariableFromPatientPageInput;
};

export type MutationUpdateProviderArgs = {
    input: UpdateProviderInput;
};

export type MutationUpdateProxiedTodoTaskArgs = {
    input?: InputMaybe<TodoUpdateInput>;
};

export type MutationUpdateQListArgs = {
    input: UpdateQListInput;
};

export type MutationUpdateQuestionArgs = {
    input: UpdateQuestionInput;
};

export type MutationUpdateReadMessagesArgs = {
    input?: InputMaybe<UpdateReadeMessagesForChatRoomInput>;
};

export type MutationUpdateReferenceLinkArgs = {
    input: UpdateReferenceLinkInput;
};

export type MutationUpdateReferenceLinkCategoryArgs = {
    input: UpdateReferenceLinkCategoryInput;
};

export type MutationUpdateReportingCategoryArgs = {
    input: UpdateReportingCategoryInput;
};

export type MutationUpdateReportingJobArgs = {
    input: UpdateReportingJobInput;
};

export type MutationUpdateRoleArgs = {
    input: UpdateRoleInput;
};

export type MutationUpdateSentMessageArgs = {
    input: UpdateSentMessageInput;
};

export type MutationUpdateSimpleContentArgs = {
    input: UpdateSimpleContentInput;
};

export type MutationUpdateSlaArgs = {
    input: UpdateSlaInput;
};

export type MutationUpdateSlaTimerArgs = {
    input: UpdateSlaTimerInput;
};

export type MutationUpdateSubmittedClaimArgs = {
    input: UpdateSubmittedClaimInput;
};

export type MutationUpdateSurveyDefArgs = {
    input: UpdateSurveyDefInput;
};

export type MutationUpdateSurveyResponseArgs = {
    input: UpdateSurveyResponseInput;
};

export type MutationUpdateSurveyResponseAnswersWithNoDataTrailArgs = {
    input: SurveyResponseUpdateInput;
};

export type MutationUpdateSystemTokenArgs = {
    input: UpdateSystemTokenInput;
};

export type MutationUpdateTagArgs = {
    input: UpdateTagInput;
};

export type MutationUpdateTodoTaskArgs = {
    input: UpdateTodoTaskInput;
};

export type MutationUpdateTodoTasksArgs = {
    input: UpdateTodoTasksInput;
};

export type MutationUpdateTokenArgs = {
    input: UpdateTokenInput;
};

export type MutationUpdateTrackerArgs = {
    input: UpdateTrackerInput;
};

export type MutationUpdateTrackerHistoryArgs = {
    input: UpdateTrackerHistoryInput;
};

export type MutationUpdateTrendTriggerArgs = {
    input: UpdateTrendTriggerInput;
};

export type MutationUpdateUserArgs = {
    input: UpdateUserInput;
};

export type MutationUpdateUserProfileDefArgs = {
    input: UpdateUserProfileDefInput;
};

export type MutationUpdateVcZipCodeToLocationArgs = {
    input: UpdateVcZipCodeToLocationInput;
};

export type MutationUpdateVirtualCareAffiliateArgs = {
    input?: InputMaybe<UpdateVirtualCareAffiliateInput>;
};

export type MutationUpdateVirtualCareAffiliatesArgs = {
    input: UpdateVirtualCareAffiliatesInput;
};

export type MutationUpdateVirtualCareEligibilityWhitelistArgs = {
    input: UpdateVirtualCareEligibilityWhitelistInput;
};

export type MutationUpdateVirtualCareFormArgs = {
    input: UpdateVirtualCareFormInput;
};

export type MutationUpdateWhatsNewTimelineArgs = {
    input: UpdateWhatsNewTimelineInput;
};

export type MutationUpdateWhoToCallArgs = {
    input: UpdateWhoToCallInput;
};

export type MutationUpsertArticleVariantArgs = {
    articleId: Scalars['String'];
    encoded?: InputMaybe<Scalars['Boolean']>;
    variant: ContentVariantInput;
};

export type MutationUpsertConfigValueByValueDefIdArgs = {
    input: UpsertConfigValueByValueDefIdInput;
};

export type MutationUserArticleCacheDeleteByArticleIdArgs = {
    articleId?: InputMaybe<Scalars['String']>;
};

export type MutationUserManagementDeleteUserArgs = {
    input: DeleteUserDataInput;
};

export type MutationUserManagementImportFamilyDataArgs = {
    input: ImportFamilyDataInput;
};

export type MutationUserManagementResetUserPasswordArgs = {
    input: ResetUserPasswordDataInput;
};

export type MutationValidateMfaArgs = {
    input: ValidateMfaInput;
};

export type MutationResponse = {
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type NameInput = {
    en?: InputMaybe<Scalars['String']>;
    es?: InputMaybe<Scalars['String']>;
};

export type NameType = {
    __typename?: 'NameType';
    en?: Maybe<Scalars['String']>;
    es?: Maybe<Scalars['String']>;
};

export type NestedClass = {
    __typename?: 'NestedClass';
    isVisibleToRoleApp?: Maybe<Scalars['Boolean']>;
    isVisibleToRoleService?: Maybe<Scalars['Boolean']>;
};

export type NewMessageInput = {
    chatRoomId: Scalars['ObjectId'];
};

export type Notification = {
    text: Scalars['String'];
    title: Scalars['String'];
    type: Scalars['String'];
    url: Scalars['String'];
};

export type NotificationData = {
    __typename?: 'NotificationData';
    en?: Maybe<Scalars['String']>;
    es?: Maybe<Scalars['String']>;
    shouldSendNotification: Scalars['Boolean'];
};

export type NotificationInput = {
    en?: InputMaybe<Scalars['String']>;
    es?: InputMaybe<Scalars['String']>;
    shouldSendNotification: Scalars['Boolean'];
};

export type NotificationResponse = {
    __typename?: 'NotificationResponse';
    appUserId?: Maybe<Scalars['String']>;
    notifications?: Maybe<Array<Maybe<HighlightFeedNotification>>>;
};

export type NotificationTokenMutationResponse = MutationResponse & {
    __typename?: 'NotificationTokenMutationResponse';
    data?: Maybe<NotificationTokensResponseData>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type NotificationTokensResponseData = {
    __typename?: 'NotificationTokensResponseData';
    appBundleId?: Maybe<Scalars['String']>;
    appUserId?: Maybe<Scalars['ObjectId']>;
    familyId?: Maybe<Scalars['String']>;
    notificationTokens?: Maybe<Scalars['JSON']>;
    patientId?: Maybe<Scalars['String']>;
};

export type OAuthApiGetIncentives = {
    __typename?: 'OAuthAPIGetIncentives';
    data?: Maybe<OAuthIncentives>;
    error?: Maybe<OAuthError>;
};

export type OAuthApiGetIncentivesInput = {
    provider: OAuthProviderEnum;
};

export type OAuthApiPostSurveyAnswers = {
    __typename?: 'OAuthAPIPostSurveyAnswers';
    error?: Maybe<OAuthError>;
    success: Scalars['Boolean'];
};

export type OAuthApiPostSurveyAnswersInput = {
    answers: Scalars['JSON'];
    provider: OAuthProviderEnum;
    surveyId: Scalars['String'];
};

export type OAuthCignaProviderIncentive = {
    __typename?: 'OAuthCignaProviderIncentive';
    id: Scalars['String'];
    rewardValue: Scalars['Float'];
};

export type OAuthCignaProviderUserData = {
    __typename?: 'OAuthCignaProviderUserData';
    birthDate: Scalars['String'];
    clientUserId: Scalars['String'];
    email?: Maybe<Scalars['String']>;
    employer?: Maybe<Scalars['String']>;
    employerId: Scalars['String'];
    firstName: Scalars['String'];
    gender: Scalars['String'];
    healthPlan: Scalars['String'];
    lastName: Scalars['String'];
    memberId: Scalars['String'];
    planSponsorName: Scalars['String'];
    updatedAt: Scalars['String'];
};

/** List of supported clients. */
export enum OAuthClientEnum {
    Cigna = 'Cigna',
    Whusa = 'WHUSA',
}

export type OAuthError = {
    __typename?: 'OAuthError';
    message?: Maybe<Scalars['String']>;
    name?: Maybe<OAuthServiceErrorName>;
};

export type OAuthIncentives = {
    __typename?: 'OAuthIncentives';
    cigna?: Maybe<Array<Maybe<OAuthCignaProviderIncentive>>>;
};

/** OAuth Provider. */
export enum OAuthProviderEnum {
    Cigna = 'Cigna',
}

export type OAuthProviderUserData = {
    __typename?: 'OAuthProviderUserData';
    cigna?: Maybe<OAuthCignaProviderUserData>;
};

/** OAuthService Error Names */
export enum OAuthServiceErrorName {
    OauthServiceDocQueryError = 'OAUTH_SERVICE_DOC_QUERY_ERROR',
    OauthServiceExchangeAccessTokenError = 'OAUTH_SERVICE_EXCHANGE_ACCESS_TOKEN_ERROR',
    OauthServiceIncorrectApiResponseError = 'OAUTH_SERVICE_INCORRECT_API_RESPONSE_ERROR',
    OauthServiceRequestUserDataError = 'OAUTH_SERVICE_REQUEST_USER_DATA_ERROR',
    OauthServiceTokensAreInvalidError = 'OAUTH_SERVICE_TOKENS_ARE_INVALID_ERROR',
    OauthServiceUnableToRefreshTokensError = 'OAUTH_SERVICE_UNABLE_TO_REFRESH_TOKENS_ERROR',
    OauthServiceUnknownError = 'OAUTH_SERVICE_UNKNOWN_ERROR',
}

export type OAuthSignIn = {
    __typename?: 'OAuthSignIn';
    code?: Maybe<Scalars['String']>;
    data?: Maybe<OAuthProviderUserData>;
    error?: Maybe<OAuthError>;
};

export type OAuthSignInInput = {
    client: OAuthClientEnum;
    code: Scalars['String'];
    provider: OAuthProviderEnum;
    state: Scalars['String'];
};

export enum OnSuccessfulZipCodeMatch {
    ToSuccessScreen = 'ToSuccessScreen',
    ToUrl = 'ToUrl',
}

export type Order = BaseEntity & {
    __typename?: 'Order';
    clinicalInfo?: Maybe<Array<ClinicalInfo>>;
    comments?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    diagnoses?: Maybe<Array<Diagnoses>>;
    id: Scalars['ObjectId'];
    isDeviceOrder?: Maybe<Scalars['Boolean']>;
    isTestData?: Maybe<Scalars['Boolean']>;
    notes?: Maybe<Array<Maybe<Scalars['String']>>>;
    orderId?: Maybe<Scalars['String']>;
    patient?: Maybe<Patient>;
    patientFullName: Scalars['String'];
    patientId: Scalars['ObjectId'];
    practiceId?: Maybe<Scalars['ObjectId']>;
    /** Procedure code */
    procedure?: Maybe<Scalars['String']>;
    /** Procedure description */
    procedureDescription?: Maybe<Scalars['String']>;
    providerFirstName?: Maybe<Scalars['String']>;
    providerLastName?: Maybe<Scalars['String']>;
    /** NPI of the ordering provider */
    providerNPI?: Maybe<Scalars['String']>;
    redoxEhrId?: Maybe<Scalars['String']>;
    redoxSourceId?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    transactionDateTime?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
    visitId?: Maybe<Scalars['String']>;
};

/** Whether to order query by ASC | DESC */
export enum OrderByDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC',
}

export type OrderFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<OrdersInputUpdate>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type OrderInput = {
    clinicalInfo?: InputMaybe<Array<ClinicalInfoInput>>;
    comments?: InputMaybe<Scalars['String']>;
    diagnoses?: InputMaybe<Array<DiagnosesInput>>;
    isDeviceOrder?: InputMaybe<Scalars['Boolean']>;
    notes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    orderId?: InputMaybe<Scalars['String']>;
    patientId: Scalars['ObjectId'];
    practiceId?: InputMaybe<Scalars['ObjectId']>;
    /** Procedure code */
    procedure?: InputMaybe<Scalars['String']>;
    /** Procedure description */
    procedureDescription?: InputMaybe<Scalars['String']>;
    providerFirstName?: InputMaybe<Scalars['String']>;
    providerLastName?: InputMaybe<Scalars['String']>;
    /** NPI of the ordering provider */
    providerNPI?: InputMaybe<Scalars['String']>;
    redoxEhrId?: InputMaybe<Scalars['String']>;
    redoxSourceId?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    transactionDateTime?: InputMaybe<Scalars['String']>;
    visitId?: InputMaybe<Scalars['String']>;
};

export type OrderOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type OrderPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type OrderQueryInput = {
    filter?: InputMaybe<OrderFilterInput>;
    orderBy?: InputMaybe<OrderOrderByInput>;
    pagination?: InputMaybe<OrderPaginationInput>;
};

export type OrdersInputUpdate = {
    clinicalInfo?: InputMaybe<Array<ClinicalInfoInput>>;
    comments?: InputMaybe<Scalars['String']>;
    diagnoses?: InputMaybe<Array<DiagnosesInput>>;
    isDeviceOrder?: InputMaybe<Scalars['Boolean']>;
    notes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    orderId?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    practiceId?: InputMaybe<Scalars['ObjectId']>;
    /** Procedure code */
    procedure?: InputMaybe<Scalars['String']>;
    /** Procedure description */
    procedureDescription?: InputMaybe<Scalars['String']>;
    providerFirstName?: InputMaybe<Scalars['String']>;
    providerLastName?: InputMaybe<Scalars['String']>;
    /** NPI of the ordering provider */
    providerNPI?: InputMaybe<Scalars['String']>;
    redoxEhrId?: InputMaybe<Scalars['String']>;
    redoxSourceId?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    transactionDateTime?: InputMaybe<Scalars['String']>;
    visitId?: InputMaybe<Scalars['String']>;
};

export type OrdersV2ByFilterFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<OrdersInputUpdate>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type OrdersV2ByFilterInput = {
    filter?: InputMaybe<OrdersV2ByFilterFilterInput>;
    orderBy?: InputMaybe<OrdersV2ByFilterOrderByInput>;
    pagination?: InputMaybe<OrdersV2ByFilterPaginationInput>;
};

export type OrdersV2ByFilterOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type OrdersV2ByFilterPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type OrdersV2Response = PaginationResponse & {
    __typename?: 'OrdersV2Response';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Order>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type OrgDescendentResponse = {
    __typename?: 'OrgDescendentResponse';
    orgIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Organization = BaseEntity & {
    __typename?: 'Organization';
    address?: Maybe<Address>;
    allowPatientCreation?: Maybe<Scalars['Boolean']>;
    ancestors?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
    /** The app store bundle ID app identifier that this org and any sub orgs are used for. */
    appBundleId?: Maybe<Scalars['String']>;
    /** Designates which App Platform this org and any sub orgs are used for. */
    appPlatform?: Maybe<Scalars['String']>;
    /** Branding Name (This is the name that will appear in the app) */
    brandingName: Scalars['String'];
    /** Name of Virtual Visit App */
    conferencingAppName?: Maybe<Scalars['String']>;
    config?: Maybe<OrganizationConfig>;
    configOptions?: Maybe<OrganizationConfigOptions>;
    createdAt: Scalars['DateTime'];
    dataInterfaces?: Maybe<OrganizationDataInterfaces>;
    eligibilityType?: Maybe<EligibilityType>;
    email?: Maybe<Scalars['String']>;
    externalId?: Maybe<Scalars['String']>;
    /** Health Advocate Title */
    healthAdvocateTitle?: Maybe<Scalars['String']>;
    healthPlans?: Maybe<Array<OrganizationPlan>>;
    id: Scalars['ObjectId'];
    inviteCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    isTestData?: Maybe<Scalars['Boolean']>;
    links?: Maybe<OrganizationLinks>;
    logo?: Maybe<Scalars['String']>;
    /** Name of Practice */
    name: Scalars['String'];
    organizationMeasurementConfig?: Maybe<OrganizationMeasurementConfig>;
    parent?: Maybe<Organization>;
    parentId?: Maybe<Scalars['ObjectId']>;
    phoneNumbers?: Maybe<OrganizationPhoneNumbers>;
    providers?: Maybe<Array<OrganizationProvider>>;
    services?: Maybe<Array<Scalars['String']>>;
    surveys?: Maybe<OrganizationSurveys>;
    timezone?: Maybe<Scalars['String']>;
    unreadMessagesCount?: Maybe<Scalars['Float']>;
    updatedAt: Scalars['DateTime'];
};

export type OrganizationBloodPressure = {
    __typename?: 'OrganizationBloodPressure';
    diastolic?: Maybe<Ranges>;
    pulse?: Maybe<Ranges>;
    systolic?: Maybe<Ranges>;
};

export type OrganizationBloodPressureInput = {
    diastolic?: InputMaybe<RangesInput>;
    pulse?: InputMaybe<RangesInput>;
    systolic?: InputMaybe<RangesInput>;
};

export type OrganizationByInviteCodeInput = {
    inviteCode: Scalars['String'];
};

export type OrganizationConfig = {
    __typename?: 'OrganizationConfig';
    /** Resources listed inside app */
    resources?: Maybe<OrganizationConfigResource>;
};

export type OrganizationConfigInput = {
    /** Resources listed inside app */
    resources?: InputMaybe<OrganizationConfigResourceInput>;
};

export type OrganizationConfigOptions = {
    __typename?: 'OrganizationConfigOptions';
    riskScoreEnabled: Scalars['Boolean'];
};

export type OrganizationConfigOptionsInput = {
    riskScoreEnabled: Scalars['Boolean'];
};

export type OrganizationConfigResource = {
    __typename?: 'OrganizationConfigResource';
    devices?: Maybe<Scalars['Boolean']>;
    downloadConfApp: Scalars['Boolean'];
    prepareForVirtualVisit: Scalars['Boolean'];
    scheduleNextVisit: Scalars['Boolean'];
};

export type OrganizationConfigResourceInput = {
    devices?: InputMaybe<Scalars['Boolean']>;
    downloadConfApp: Scalars['Boolean'];
    prepareForVirtualVisit: Scalars['Boolean'];
    scheduleNextVisit: Scalars['Boolean'];
};

export type OrganizationDailyMeasurement = {
    __typename?: 'OrganizationDailyMeasurement';
    glucose?: Maybe<Ranges>;
    moodLevel?: Maybe<Ranges>;
    painLevel?: Maybe<Ranges>;
    /** 24hr time formatted hh:mm ie: 22:50 */
    sleepEnds?: Maybe<RangesString>;
    /** 24hr time formatted hh:mm ie: 22:50 */
    sleepStarts?: Maybe<RangesString>;
    steps?: Maybe<Ranges>;
    temperature?: Maybe<Ranges>;
};

export type OrganizationDailyMeasurementInput = {
    glucose?: InputMaybe<RangesInput>;
    moodLevel?: InputMaybe<RangesInput>;
    painLevel?: InputMaybe<RangesInput>;
    /** 24hr time formatted hh:mm ie: 22:50 */
    sleepEnds?: InputMaybe<RangesStringInput>;
    /** 24hr time formatted hh:mm ie: 22:50 */
    sleepStarts?: InputMaybe<RangesStringInput>;
    steps?: InputMaybe<RangesInput>;
    temperature?: InputMaybe<RangesInput>;
};

export type OrganizationDataInterfaces = {
    __typename?: 'OrganizationDataInterfaces';
    appointment: RecordType;
    deviceMeasurement: RecordType;
    surveyResponse: RecordType;
};

export type OrganizationDataInterfacesInput = {
    appointment: RecordType;
    deviceMeasurement: RecordType;
    surveyResponse: RecordType;
};

export type OrganizationFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<OrganizationUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type OrganizationInput = {
    address?: InputMaybe<AddressInput>;
    allowPatientCreation?: InputMaybe<Scalars['Boolean']>;
    /** The app store bundle ID app identifier that this org and any sub orgs are used for. */
    appBundleId?: InputMaybe<Scalars['String']>;
    /** Designates which App Platform this org and any sub orgs are used for. */
    appPlatform?: InputMaybe<Scalars['String']>;
    /** Branding Name (This is the name that will appear in the app) */
    brandingName?: InputMaybe<Scalars['String']>;
    /** Name of Virtual Visit App */
    conferencingAppName?: InputMaybe<Scalars['String']>;
    config?: InputMaybe<OrganizationConfigInput>;
    configOptions?: InputMaybe<OrganizationConfigOptionsInput>;
    dataInterfaces?: InputMaybe<OrganizationDataInterfacesInput>;
    eligibilityType?: InputMaybe<EligibilityType>;
    email?: InputMaybe<Scalars['String']>;
    externalId?: InputMaybe<Scalars['String']>;
    healthAdvocateTitle?: InputMaybe<Scalars['String']>;
    healthPlans?: InputMaybe<Array<OrganizationPlanInput>>;
    links?: InputMaybe<OrganizationLinksInput>;
    logo?: InputMaybe<Scalars['String']>;
    /** Name of Practice */
    name: Scalars['String'];
    organizationMeasurementConfig?: InputMaybe<OrganizationMeasurementConfigInput>;
    parentId?: InputMaybe<Scalars['ObjectId']>;
    phoneNumbers?: InputMaybe<OrganizationPhoneNumbersInput>;
    providers?: InputMaybe<Array<OrganizationProviderInput>>;
    services?: InputMaybe<Array<Scalars['String']>>;
    surveys?: InputMaybe<OrganizationSurveysInput>;
    timezone?: InputMaybe<Scalars['String']>;
};

export type OrganizationLinks = {
    __typename?: 'OrganizationLinks';
    breastfeedingSupport?: Maybe<Scalars['String']>;
    /** Virtual Visit App - Google Play */
    conferencingAppAndroid?: Maybe<Scalars['String']>;
    /** Virtual Visit App - iTunes */
    conferencingAppIos?: Maybe<Scalars['String']>;
    covid19?: Maybe<Scalars['String']>;
    /** Provider Directory */
    directory?: Maybe<Scalars['String']>;
    /** Website */
    home?: Maybe<Scalars['String']>;
    newMomSupport?: Maybe<Scalars['String']>;
    patientPortal?: Maybe<Scalars['String']>;
    prenatalBooklet?: Maybe<Scalars['String']>;
    /** Online Scheduling */
    scheduling?: Maybe<Scalars['String']>;
    virtualVisitLink?: Maybe<Scalars['String']>;
};

export type OrganizationLinksInput = {
    breastfeedingSupport?: InputMaybe<Scalars['String']>;
    /** Virtual Visit App - Google Play */
    conferencingAppAndroid?: InputMaybe<Scalars['String']>;
    /** Virtual Visit App - iTunes */
    conferencingAppIos?: InputMaybe<Scalars['String']>;
    covid19?: InputMaybe<Scalars['String']>;
    /** Provider Directory */
    directory?: InputMaybe<Scalars['String']>;
    /** Website */
    home?: InputMaybe<Scalars['String']>;
    newMomSupport?: InputMaybe<Scalars['String']>;
    patientPortal?: InputMaybe<Scalars['String']>;
    prenatalBooklet?: InputMaybe<Scalars['String']>;
    /** Online Scheduling */
    scheduling?: InputMaybe<Scalars['String']>;
    virtualVisitLink?: InputMaybe<Scalars['String']>;
};

export type OrganizationMeasurementConfig = {
    __typename?: 'OrganizationMeasurementConfig';
    bloodPressure?: Maybe<OrganizationBloodPressure>;
    dailyMeasurement?: Maybe<OrganizationDailyMeasurement>;
    movement?: Maybe<OrganizationMovement>;
    weight?: Maybe<OrganizationWeight>;
};

export type OrganizationMeasurementConfigInput = {
    bloodPressure?: InputMaybe<OrganizationBloodPressureInput>;
    dailyMeasurement?: InputMaybe<OrganizationDailyMeasurementInput>;
    movement?: InputMaybe<OrganizationMovementInput>;
    weight?: InputMaybe<OrganizationWeightInput>;
};

export type OrganizationMovement = {
    __typename?: 'OrganizationMovement';
    /** Duration is an elapsed time value in string format hh:mm:ss */
    duration?: Maybe<RangesString>;
};

export type OrganizationMovementInput = {
    /** Duration is an elapsed time value in string format hh:mm:ss */
    duration?: InputMaybe<RangesStringInput>;
};

export type OrganizationOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type OrganizationPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type OrganizationPaginationQueryResponse = PaginationResponse & {
    __typename?: 'OrganizationPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Organization>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type OrganizationPhoneNumbers = {
    __typename?: 'OrganizationPhoneNumbers';
    lactationConsultant?: Maybe<Scalars['String']>;
    main?: Maybe<Scalars['String']>;
    nurseLine?: Maybe<Scalars['String']>;
};

export type OrganizationPhoneNumbersInput = {
    lactationConsultant?: InputMaybe<Scalars['String']>;
    main?: InputMaybe<Scalars['String']>;
    nurseLine?: InputMaybe<Scalars['String']>;
};

export type OrganizationPlan = {
    __typename?: 'OrganizationPlan';
    checkEligibility?: Maybe<Scalars['Boolean']>;
    plan?: Maybe<HealthPlan>;
    planId: Scalars['ObjectId'];
    supportedDeviceIds: Array<Scalars['ObjectId']>;
    supportedDevices: Array<Device>;
};

export type OrganizationPlanInput = {
    checkEligibility?: InputMaybe<Scalars['Boolean']>;
    planId: Scalars['ObjectId'];
    supportedDeviceIds: Array<Scalars['ObjectId']>;
};

export type OrganizationProvider = {
    __typename?: 'OrganizationProvider';
    provider: Provider;
};

export type OrganizationProviderInput = {
    providerId: Scalars['ObjectId'];
};

export type OrganizationQueryInput = {
    filter?: InputMaybe<OrganizationFilterInput>;
    orderBy?: InputMaybe<OrganizationOrderByInput>;
    pagination?: InputMaybe<OrganizationPaginationInput>;
};

export type OrganizationSurvey = {
    __typename?: 'OrganizationSurvey';
    displayName?: Maybe<Scalars['String']>;
    surveyActive?: Maybe<Scalars['Boolean']>;
    surveyId?: Maybe<Scalars['String']>;
};

export type OrganizationSurveyInput = {
    displayName?: InputMaybe<Scalars['String']>;
    surveyActive?: InputMaybe<Scalars['Boolean']>;
    surveyId?: InputMaybe<Scalars['String']>;
};

export type OrganizationSurveys = {
    __typename?: 'OrganizationSurveys';
    birthPlan?: Maybe<OrganizationSurvey>;
    edps?: Maybe<OrganizationSurvey>;
    newPatientPreAppointment?: Maybe<OrganizationSurvey>;
    repeatPatientPreAppointment?: Maybe<OrganizationSurvey>;
    satisfaction?: Maybe<OrganizationSurvey>;
};

export type OrganizationSurveysInput = {
    birthPlan?: InputMaybe<OrganizationSurveyInput>;
    edps?: InputMaybe<OrganizationSurveyInput>;
    newPatientPreAppointment?: InputMaybe<OrganizationSurveyInput>;
    repeatPatientPreAppointment?: InputMaybe<OrganizationSurveyInput>;
    satisfaction?: InputMaybe<OrganizationSurveyInput>;
};

export type OrganizationUpdateInput = {
    address?: InputMaybe<AddressInput>;
    allowPatientCreation?: InputMaybe<Scalars['Boolean']>;
    /** The app store bundle ID app identifier that this org and any sub orgs are used for. */
    appBundleId?: InputMaybe<Scalars['String']>;
    /** Designates which App Platform this org and any sub orgs are used for. */
    appPlatform?: InputMaybe<AppPlatform>;
    /** Branding Name (This is the name that will appear in the app) */
    brandingName?: InputMaybe<Scalars['String']>;
    /** Name of Virtual Visit App */
    conferencingAppName?: InputMaybe<Scalars['String']>;
    config?: InputMaybe<OrganizationConfigInput>;
    configOptions?: InputMaybe<OrganizationConfigOptionsInput>;
    dataInterfaces?: InputMaybe<OrganizationDataInterfacesInput>;
    eligibilityType?: InputMaybe<EligibilityType>;
    email?: InputMaybe<Scalars['String']>;
    externalId?: InputMaybe<Scalars['String']>;
    healthAdvocateTitle?: InputMaybe<Scalars['String']>;
    healthPlans?: InputMaybe<Array<InputMaybe<OrganizationPlanInput>>>;
    links?: InputMaybe<OrganizationLinksInput>;
    logo?: InputMaybe<Scalars['String']>;
    /** Name of Practice */
    name?: InputMaybe<Scalars['String']>;
    organizationMeasurementConfig?: InputMaybe<OrganizationMeasurementConfigInput>;
    parentId?: InputMaybe<Scalars['ObjectId']>;
    phoneNumbers?: InputMaybe<OrganizationPhoneNumbersInput>;
    providers?: InputMaybe<Array<InputMaybe<OrganizationProviderInput>>>;
    services?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    surveys?: InputMaybe<OrganizationSurveysInput>;
    timezone?: InputMaybe<Scalars['String']>;
};

export type OrganizationWeight = {
    __typename?: 'OrganizationWeight';
    measure?: Maybe<Ranges>;
    units?: Maybe<Scalars['String']>;
};

export type OrganizationWeightInput = {
    measure?: InputMaybe<RangesInput>;
    units?: InputMaybe<Scalars['String']>;
};

export type OverdueTodoTasksInput = {
    appUserId?: InputMaybe<Scalars['String']>;
    currentDate: Scalars['DateTime'];
};

export type PvValue = {
    __typename?: 'PVValue';
    id?: Maybe<Scalars['String']>;
    label?: Maybe<LocalizedStringLoose>;
};

export type PaginatedAffiliateClaimInput = {
    filter?: InputMaybe<AffiliateClaimFilteringFilterInput>;
    orderBy?: InputMaybe<AffiliateClaimFilteringOrderByInput>;
    pagination?: InputMaybe<AffiliateClaimFilteringPaginationInput>;
};

export type PaginatedAffiliateClaimResponse = PaginationResponse & {
    __typename?: 'PaginatedAffiliateClaimResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<AffiliateClaim>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type PaginationResponse = {
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type Patient = BaseEntity & {
    __typename?: 'Patient';
    TimeTapLinkAtHomeNewPatientVirtualConsult?: Maybe<Scalars['String']>;
    TimeTapLinkVirtualConsult?: Maybe<Scalars['String']>;
    affiliateIds?: Maybe<Array<Maybe<Scalars['String']>>>;
    appBundleId?: Maybe<Scalars['String']>;
    appUserId?: Maybe<Scalars['ObjectId']>;
    appUsers?: Maybe<Array<PatientAppUser>>;
    appointments: Array<Appointment>;
    assignedHealthAdvocate?: Maybe<Scalars['ObjectId']>;
    attachments?: Maybe<Array<Maybe<Attachment>>>;
    birthDate: Scalars['DateTime'];
    chatRoom: ChatRoom;
    chatStats: Array<UserChatStats>;
    createdAt: Scalars['DateTime'];
    deliveryDate?: Maybe<Scalars['DateTime']>;
    dueDate?: Maybe<Scalars['DateTime']>;
    ehrIds: Array<EhrIds>;
    ehrIntegration?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    eulaPpAcceptance?: Maybe<Scalars['Boolean']>;
    externalId?: Maybe<Scalars['String']>;
    familyId?: Maybe<Scalars['String']>;
    firstName: Scalars['String'];
    fullName: Scalars['String'];
    gender?: Maybe<Scalars['String']>;
    groupNumber?: Maybe<Scalars['String']>;
    hgPatientId?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    insuranceGroupNumber?: Maybe<Scalars['String']>;
    insuranceId?: Maybe<Scalars['String']>;
    insuranceName?: Maybe<Scalars['String']>;
    insuredAddress?: Maybe<Address>;
    insuredFirstName?: Maybe<Scalars['String']>;
    insuredLastName?: Maybe<Scalars['String']>;
    insuredPhoneNumber?: Maybe<Scalars['String']>;
    invitationCode?: Maybe<Scalars['String']>;
    /** Sourced from user-dispatch.Contact.createdAt */
    invitationCreatedAt?: Maybe<Scalars['DateTime']>;
    invitationDate?: Maybe<Scalars['DateTime']>;
    invitationExpiration?: Maybe<Scalars['DateTime']>;
    invitationId?: Maybe<Scalars['String']>;
    invitationMode?: Maybe<PatientInvitation>;
    /** Sourced from user-dispatch.Contact.updatedAt */
    invitationUpdatedAt?: Maybe<Scalars['DateTime']>;
    isPregnant?: Maybe<Scalars['Boolean']>;
    isTestData?: Maybe<Scalars['Boolean']>;
    lastName: Scalars['String'];
    latestMeasurements: PatientLatestMeasurements;
    mailingAddress?: Maybe<Address>;
    measurements: Array<Measurement>;
    memberId?: Maybe<Scalars['String']>;
    notes: Array<PatientNotes>;
    /** Sourced from user-dispatch.Contact.onboardingComplete */
    onboardingComplete?: Maybe<Scalars['Boolean']>;
    onboardingCompleteDate?: Maybe<Scalars['DateTime']>;
    /** Sourced from user-dispatch.Contact.onboardingDate */
    onboardingDate?: Maybe<Scalars['DateTime']>;
    openAdvocateTaskCount?: Maybe<Scalars['Float']>;
    openCriticalAdvocateTaskCount?: Maybe<Scalars['Float']>;
    patientBuildList?: Maybe<PatientBuildList>;
    patientCallStatus?: Maybe<Scalars['String']>;
    patientHeaderData?: Maybe<Array<PatientHeaderData>>;
    patientRelationshipToInsured?: Maybe<Scalars['String']>;
    phoneNumber?: Maybe<Scalars['String']>;
    practice: Organization;
    profileValues: Array<PatientProfileValue>;
    questions: Array<CombinedQuestion>;
    race?: Maybe<Scalars['String']>;
    redoxEhrId?: Maybe<Scalars['String']>;
    redoxSourceId?: Maybe<Scalars['String']>;
    riskIndicators?: Maybe<PatientRiskIndicators>;
    riskScore?: Maybe<RiskScoreResponse>;
    signUpPracticeCode?: Maybe<Scalars['String']>;
    subscriberBirthDate?: Maybe<Scalars['DateTime']>;
    subscriberSex?: Maybe<Scalars['String']>;
    surveyResponses: Array<SurveyResponse>;
    tagIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
    tags?: Maybe<Array<Maybe<Tag>>>;
    timeTapClientId?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
};

export type PatientAppointmentsArgs = {
    input?: InputMaybe<PatientAppointmentsQueryInput>;
};

export type PatientMeasurementsArgs = {
    input?: InputMaybe<PatientMeasurementsQueryInput>;
};

export type PatientNotesArgs = {
    input?: InputMaybe<PatientPatientNotesQueryInput>;
};

export type PatientSurveyResponsesArgs = {
    input?: InputMaybe<PatientSurveyResponsesQueryInput>;
};

export type PatientAction = BaseEntity & {
    __typename?: 'PatientAction';
    actor?: Maybe<User>;
    actorId?: Maybe<Scalars['ObjectId']>;
    completedAt?: Maybe<Scalars['DateTime']>;
    createdAt: Scalars['DateTime'];
    description?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    interventionId?: Maybe<Scalars['ObjectId']>;
    isTestData?: Maybe<Scalars['Boolean']>;
    organizationId?: Maybe<Scalars['ObjectId']>;
    patient?: Maybe<Patient>;
    patientId?: Maybe<Scalars['ObjectId']>;
    source: ActionSource;
    stepId?: Maybe<Scalars['ObjectId']>;
    taskId?: Maybe<Scalars['ObjectId']>;
    type?: Maybe<PatientActionType>;
    typeId?: Maybe<Scalars['ObjectId']>;
    updatedAt: Scalars['DateTime'];
};

export type PatientActionFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<PatientActionUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type PatientActionInput = {
    actorId?: InputMaybe<Scalars['ObjectId']>;
    completedAt?: InputMaybe<Scalars['DateTime']>;
    description?: InputMaybe<Scalars['String']>;
    interventionId?: InputMaybe<Scalars['ObjectId']>;
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    patientId: Scalars['ObjectId'];
    source: ActionSource;
    stepId?: InputMaybe<Scalars['ObjectId']>;
    taskId?: InputMaybe<Scalars['ObjectId']>;
    typeId: Scalars['ObjectId'];
};

export type PatientActionOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type PatientActionPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type PatientActionPaginationQueryResponse = PaginationResponse & {
    __typename?: 'PatientActionPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<PatientAction>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type PatientActionQueryInput = {
    filter?: InputMaybe<PatientActionFilterInput>;
    orderBy?: InputMaybe<PatientActionOrderByInput>;
    pagination?: InputMaybe<PatientActionPaginationInput>;
};

export type PatientActionType = BaseEntity & {
    __typename?: 'PatientActionType';
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    label: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type PatientActionTypeFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<PatientActionTypeUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type PatientActionTypeInput = {
    label: Scalars['String'];
};

export type PatientActionTypeOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type PatientActionTypePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type PatientActionTypePaginationQueryResponse = PaginationResponse & {
    __typename?: 'PatientActionTypePaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<PatientActionType>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type PatientActionTypeQueryInput = {
    filter?: InputMaybe<PatientActionTypeFilterInput>;
    orderBy?: InputMaybe<PatientActionTypeOrderByInput>;
    pagination?: InputMaybe<PatientActionTypePaginationInput>;
};

export type PatientActionTypeUpdateInput = {
    label?: InputMaybe<Scalars['String']>;
};

export type PatientActionUpdateInput = {
    actorId?: InputMaybe<Scalars['ObjectId']>;
    completedAt?: InputMaybe<Scalars['DateTime']>;
    description?: InputMaybe<Scalars['String']>;
    interventionId?: InputMaybe<Scalars['ObjectId']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    source?: InputMaybe<ActionSource>;
    taskId?: InputMaybe<Scalars['ObjectId']>;
    typeId?: InputMaybe<Scalars['ObjectId']>;
};

export type PatientActionsInput = {
    patientActions?: InputMaybe<Array<InputMaybe<PatientActionInput>>>;
    patientId: Scalars['ObjectId'];
};

export type PatientAppUser = {
    __typename?: 'PatientAppUser';
    appBundleId: Scalars['String'];
    appUserId: Scalars['String'];
    familyId: Scalars['String'];
};

export type PatientAppointmentsFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<AppointmentUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type PatientAppointmentsOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type PatientAppointmentsPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type PatientAppointmentsQueryInput = {
    excludePatientCreated?: InputMaybe<Scalars['Boolean']>;
    filter?: InputMaybe<PatientAppointmentsFilterInput>;
    orderBy?: InputMaybe<PatientAppointmentsOrderByInput>;
    pagination?: InputMaybe<PatientAppointmentsPaginationInput>;
};

export type PatientBuildList = {
    __typename?: 'PatientBuildList';
    disableArticleFeed?: Maybe<Scalars['Boolean']>;
    disableTodos?: Maybe<Scalars['Boolean']>;
};

export type PatientBuildListInput = {
    disableArticleFeed?: InputMaybe<Scalars['Boolean']>;
    disableTodos?: InputMaybe<Scalars['Boolean']>;
};

export type PatientCache = {
    __typename?: 'PatientCache';
    _id: Scalars['ObjectId'];
    appUserId?: Maybe<Scalars['ObjectId']>;
    familyId: Scalars['String'];
    id: Scalars['ObjectId'];
    organizationId?: Maybe<Scalars['ObjectId']>;
    portalTags?: Maybe<Array<Maybe<PatientCacheTag>>>;
    tags: Array<PatientCacheTag>;
    timelines: Array<PatientCacheTimeline>;
};

export type PatientCacheFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<PatientCacheUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type PatientCacheOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type PatientCachePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type PatientCachePaginationQueryResponse = PaginationResponse & {
    __typename?: 'PatientCachePaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<PatientCache>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type PatientCacheQueryInput = {
    filter?: InputMaybe<PatientCacheFilterInput>;
    orderBy?: InputMaybe<PatientCacheOrderByInput>;
    pagination?: InputMaybe<PatientCachePaginationInput>;
};

export type PatientCacheTag = {
    __typename?: 'PatientCacheTag';
    dateSet: Scalars['DateTime'];
    state: Scalars['Boolean'];
    tagId: Scalars['ObjectId'];
};

export type PatientCacheTagWithUserId = {
    __typename?: 'PatientCacheTagWithUserId';
    dateSet: Scalars['DateTime'];
    state: Scalars['Boolean'];
    tagId: Scalars['ObjectId'];
    userId: Scalars['String'];
};

export type PatientCacheTagsAndTimelines = {
    __typename?: 'PatientCacheTagsAndTimelines';
    tags: Array<PatientCacheTagWithUserId>;
    timelines: Array<PatientCacheTimelineWithUserId>;
};

export type PatientCacheTimeline = {
    __typename?: 'PatientCacheTimeline';
    name: Scalars['String'];
    targetDate: Scalars['DateTime'];
    timelineId: Scalars['String'];
};

export type PatientCacheTimelineWithUserId = {
    __typename?: 'PatientCacheTimelineWithUserId';
    name: Scalars['String'];
    targetDate: Scalars['DateTime'];
    timelineId: Scalars['String'];
    userId: Scalars['String'];
};

export type PatientCacheUpdateInput = {
    id?: InputMaybe<Scalars['ObjectId']>;
};

export type PatientCallList = BaseEntity & {
    __typename?: 'PatientCallList';
    createdAt: Scalars['DateTime'];
    createdBy?: Maybe<Scalars['ObjectId']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    patientRefs?: Maybe<Array<Maybe<PatientRef>>>;
    patientsToCall?: Maybe<PatientToCallResponse>;
    tagIds: Array<Scalars['ObjectId']>;
    tags?: Maybe<Array<Tag>>;
    updatedAt: Scalars['DateTime'];
    user?: Maybe<User>;
};

export type PatientCallListPatientsToCallArgs = {
    input?: InputMaybe<GetPatientsByFilterInput>;
};

export type PatientCallListExportResponse = {
    __typename?: 'PatientCallListExportResponse';
    data?: Maybe<Array<Maybe<Scalars['String']>>>;
    fileName: Scalars['String'];
};

export type PatientCallListFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<PatientCallListUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type PatientCallListInput = {
    createdBy?: InputMaybe<Scalars['ObjectId']>;
    name?: InputMaybe<Scalars['String']>;
    tagIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
};

export type PatientCallListOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type PatientCallListPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type PatientCallListPaginationQueryResponse = PaginationResponse & {
    __typename?: 'PatientCallListPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<PatientCallList>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type PatientCallListQueryInput = {
    filter?: InputMaybe<PatientCallListFilterInput>;
    orderBy?: InputMaybe<PatientCallListOrderByInput>;
    pagination?: InputMaybe<PatientCallListPaginationInput>;
};

export type PatientCallListUpdateInput = {
    createdBy?: InputMaybe<Scalars['ObjectId']>;
    id?: InputMaybe<Scalars['ObjectId']>;
    name?: InputMaybe<Scalars['String']>;
    tagIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
};

export type PatientDataFromFailedImport = {
    __typename?: 'PatientDataFromFailedImport';
    email?: Maybe<Scalars['String']>;
    externalId?: Maybe<Scalars['String']>;
};

export type PatientFieldInListFilterInput = {
    _id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PatientFilterFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<PatientUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type PatientFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<PatientUpdateInput>;
    fieldsInList?: InputMaybe<PatientFieldInListFilterInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type PatientFilterPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type PatientHeader = BaseEntity & {
    __typename?: 'PatientHeader';
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    organizationId?: Maybe<Scalars['ObjectId']>;
    profileDefs?: Maybe<Array<Maybe<HeaderProfileDef>>>;
    updatedAt: Scalars['DateTime'];
};

export type PatientHeaderData = {
    __typename?: 'PatientHeaderData';
    label?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

export type PatientHeaderFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<PatientHeaderUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type PatientHeaderInput = {
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    profileDefs?: InputMaybe<Array<InputMaybe<HeaderProfileDefInput>>>;
};

export type PatientHeaderOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type PatientHeaderPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type PatientHeaderPaginationQueryResponse = PaginationResponse & {
    __typename?: 'PatientHeaderPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<PatientHeader>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type PatientHeaderQueryInput = {
    filter?: InputMaybe<PatientHeaderFilterInput>;
    orderBy?: InputMaybe<PatientHeaderOrderByInput>;
    pagination?: InputMaybe<PatientHeaderPaginationInput>;
};

export type PatientHeaderUpdateInput = {
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    profileDefs?: InputMaybe<Array<InputMaybe<HeaderProfileDefInput>>>;
};

export type PatientHighlightUpdateInput = {
    closed?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['String']>;
    priority?: InputMaybe<Scalars['Float']>;
};

export type PatientHighlightsItem = {
    __typename?: 'PatientHighlightsItem';
    availableFrom?: Maybe<Scalars['DateTime']>;
    availableUntil?: Maybe<Scalars['DateTime']>;
    body?: Maybe<Scalars['String']>;
    closeable?: Maybe<Scalars['Boolean']>;
    closed?: Maybe<Scalars['Boolean']>;
    header?: Maybe<Scalars['String']>;
    highlightId?: Maybe<Scalars['String']>;
    highlightName?: Maybe<Scalars['String']>;
    highlighted?: Maybe<Scalars['Boolean']>;
    icon?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    imageURL?: Maybe<Scalars['String']>;
    priority?: Maybe<Scalars['Float']>;
    surveyId?: Maybe<Scalars['String']>;
    target?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    type?: Maybe<HighlightType>;
};

export type PatientHighlightsResult = {
    __typename?: 'PatientHighlightsResult';
    futureitemcount?: Maybe<Scalars['Float']>;
    futureitems?: Maybe<Array<Maybe<PatientHighlightsItem>>>;
    todayitemcount?: Maybe<Scalars['Float']>;
    todayitems?: Maybe<Array<Maybe<PatientHighlightsItem>>>;
};

export type PatientInput = {
    TimeTapLinkAtHomeNewPatientVirtualConsult?: InputMaybe<Scalars['String']>;
    TimeTapLinkVirtualConsult?: InputMaybe<Scalars['String']>;
    affiliateIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    appBundleId?: InputMaybe<Scalars['String']>;
    appUserId?: InputMaybe<Scalars['ObjectId']>;
    attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
    birthDate: Scalars['DateTime'];
    ehrIds?: InputMaybe<Array<InputMaybe<EhrIdsInput>>>;
    ehrIntegration?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    eulaPpAcceptance?: InputMaybe<Scalars['Boolean']>;
    externalId?: InputMaybe<Scalars['String']>;
    familyId?: InputMaybe<Scalars['String']>;
    firstName: Scalars['String'];
    gender?: InputMaybe<Scalars['String']>;
    groupNumber?: InputMaybe<Scalars['String']>;
    insuranceGroupNumber?: InputMaybe<Scalars['String']>;
    insuranceId?: InputMaybe<Scalars['String']>;
    insuranceName?: InputMaybe<Scalars['String']>;
    insuredAddress?: InputMaybe<AddressInput>;
    insuredFirstName?: InputMaybe<Scalars['String']>;
    insuredLastName?: InputMaybe<Scalars['String']>;
    insuredPhoneNumber?: InputMaybe<Scalars['String']>;
    invitationMode?: InputMaybe<PatientInvitation>;
    isTestData?: InputMaybe<Scalars['Boolean']>;
    lastName: Scalars['String'];
    mailingAddress?: InputMaybe<AddressInput>;
    memberId?: InputMaybe<Scalars['String']>;
    patientBuildList?: InputMaybe<PatientBuildListInput>;
    patientRelationshipToInsured?: InputMaybe<Scalars['String']>;
    phoneNumber?: InputMaybe<Scalars['String']>;
    practiceId: Scalars['ObjectId'];
    race?: InputMaybe<Scalars['String']>;
    redoxEhrId?: InputMaybe<Scalars['String']>;
    redoxSourceId?: InputMaybe<Scalars['String']>;
    riskIndicators?: InputMaybe<PatientRiskIndicatorsInput>;
    signUpPracticeCode?: InputMaybe<Scalars['String']>;
    subscriberBirthDate?: InputMaybe<Scalars['DateTime']>;
    subscriberSex?: InputMaybe<Scalars['String']>;
    tagIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    timeTapClientId?: InputMaybe<Scalars['String']>;
};

export type PatientInputForAppUser = {
    TimeTapLinkAtHomeNewPatientVirtualConsult?: InputMaybe<Scalars['String']>;
    TimeTapLinkVirtualConsult?: InputMaybe<Scalars['String']>;
    affiliateIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    appBundleId?: InputMaybe<Scalars['String']>;
    appUserId?: InputMaybe<Scalars['ObjectId']>;
    attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
    birthDate: Scalars['DateTime'];
    bundleId: Scalars['String'];
    ehrIds?: InputMaybe<Array<InputMaybe<EhrIdsInput>>>;
    ehrIntegration?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    eulaPpAcceptance?: InputMaybe<Scalars['Boolean']>;
    externalId?: InputMaybe<Scalars['String']>;
    familyId?: InputMaybe<Scalars['String']>;
    firstName: Scalars['String'];
    gender?: InputMaybe<Scalars['String']>;
    groupNumber?: InputMaybe<Scalars['String']>;
    insuranceGroupNumber?: InputMaybe<Scalars['String']>;
    insuranceId?: InputMaybe<Scalars['String']>;
    insuranceName?: InputMaybe<Scalars['String']>;
    insuredAddress?: InputMaybe<AddressInput>;
    insuredFirstName?: InputMaybe<Scalars['String']>;
    insuredLastName?: InputMaybe<Scalars['String']>;
    insuredPhoneNumber?: InputMaybe<Scalars['String']>;
    invitationMode?: InputMaybe<PatientInvitation>;
    isCreatedByPortal?: InputMaybe<Scalars['Boolean']>;
    isTestData?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Language>;
    lastName: Scalars['String'];
    mailingAddress?: InputMaybe<AddressInput>;
    memberId?: InputMaybe<Scalars['String']>;
    patientBuildList?: InputMaybe<PatientBuildListInput>;
    patientRelationshipToInsured?: InputMaybe<Scalars['String']>;
    phoneNumber?: InputMaybe<Scalars['String']>;
    practiceId?: InputMaybe<Scalars['ObjectId']>;
    race?: InputMaybe<Scalars['String']>;
    redoxEhrId?: InputMaybe<Scalars['String']>;
    redoxSourceId?: InputMaybe<Scalars['String']>;
    riskIndicators?: InputMaybe<PatientRiskIndicatorsInput>;
    signUpPracticeCode?: InputMaybe<Scalars['String']>;
    subscriberBirthDate?: InputMaybe<Scalars['DateTime']>;
    subscriberSex?: InputMaybe<Scalars['String']>;
    tagIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    timeTapClientId?: InputMaybe<Scalars['String']>;
};

export type PatientInputForEligibilityFilesProcessing = {
    TimeTapLinkAtHomeNewPatientVirtualConsult?: InputMaybe<Scalars['String']>;
    TimeTapLinkVirtualConsult?: InputMaybe<Scalars['String']>;
    affiliateIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    appBundleId?: InputMaybe<Scalars['String']>;
    appUserId?: InputMaybe<Scalars['ObjectId']>;
    attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
    birthDate: Scalars['DateTime'];
    ehrIntegration?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    eulaPpAcceptance?: InputMaybe<Scalars['Boolean']>;
    externalId?: InputMaybe<Scalars['String']>;
    familyId?: InputMaybe<Scalars['String']>;
    firstName: Scalars['String'];
    gender?: InputMaybe<Scalars['String']>;
    groupNumber?: InputMaybe<Scalars['String']>;
    insuranceGroupNumber?: InputMaybe<Scalars['String']>;
    insuranceId?: InputMaybe<Scalars['String']>;
    insuranceName?: InputMaybe<Scalars['String']>;
    insuredAddress?: InputMaybe<AddressInput>;
    insuredFirstName?: InputMaybe<Scalars['String']>;
    insuredLastName?: InputMaybe<Scalars['String']>;
    insuredPhoneNumber?: InputMaybe<Scalars['String']>;
    invitationMode?: InputMaybe<PatientInvitation>;
    isTestData?: InputMaybe<Scalars['Boolean']>;
    lastName: Scalars['String'];
    mailingAddress?: InputMaybe<AddressInput>;
    memberId?: InputMaybe<Scalars['String']>;
    patientBuildList?: InputMaybe<PatientBuildListInput>;
    patientCreatedByExternalDataSource?: InputMaybe<Scalars['Boolean']>;
    patientRelationshipToInsured?: InputMaybe<Scalars['String']>;
    phoneNumber?: InputMaybe<Scalars['String']>;
    race?: InputMaybe<Scalars['String']>;
    redoxEhrId?: InputMaybe<Scalars['String']>;
    redoxSourceId?: InputMaybe<Scalars['String']>;
    riskIndicators?: InputMaybe<PatientRiskIndicatorsInput>;
    signUpPracticeCode?: InputMaybe<Scalars['String']>;
    subscriberBirthDate?: InputMaybe<Scalars['DateTime']>;
    subscriberSex?: InputMaybe<Scalars['String']>;
    tagIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    timeTapClientId?: InputMaybe<Scalars['String']>;
};

/** Patient Invitation Modes */
export enum PatientInvitation {
    EmailCode = 'EMAIL_CODE',
    GenerateCode = 'GENERATE_CODE',
    None = 'NONE',
}

export type PatientLatestMeasurements = {
    __typename?: 'PatientLatestMeasurements';
    bloodPressure?: Maybe<Measurement>;
    movement?: Maybe<Measurement>;
    weight?: Maybe<Measurement>;
};

export type PatientMeasurementsFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<MeasurementUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type PatientMeasurementsOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type PatientMeasurementsPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type PatientMeasurementsQueryInput = {
    filter?: InputMaybe<PatientMeasurementsFilterInput>;
    orderBy?: InputMaybe<PatientMeasurementsOrderByInput>;
    pagination?: InputMaybe<PatientMeasurementsPaginationInput>;
};

export type PatientNoteTag = BaseEntity & {
    __typename?: 'PatientNoteTag';
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type PatientNoteTagFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<PatientNoteTagUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type PatientNoteTagInput = {
    name: Scalars['String'];
};

export type PatientNoteTagOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type PatientNoteTagPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type PatientNoteTagPaginationQueryResponse = PaginationResponse & {
    __typename?: 'PatientNoteTagPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<PatientNoteTag>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type PatientNoteTagQueryInput = {
    filter?: InputMaybe<PatientNoteTagFilterInput>;
    orderBy?: InputMaybe<PatientNoteTagOrderByInput>;
    pagination?: InputMaybe<PatientNoteTagPaginationInput>;
};

export type PatientNoteTagUpdateInput = {
    name: Scalars['String'];
};

export type PatientNotes = BaseEntity & {
    __typename?: 'PatientNotes';
    NPI?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    date?: Maybe<Scalars['DateTime']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    noteSource?: Maybe<Scalars['String']>;
    noteType?: Maybe<Array<Maybe<Scalars['String']>>>;
    organizationId?: Maybe<Scalars['ObjectId']>;
    patient?: Maybe<Patient>;
    patientId: Scalars['ObjectId'];
    staffName?: Maybe<Scalars['String']>;
    text?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
};

export type PatientNotesFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<PatientNotesUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type PatientNotesInput = {
    NPI?: InputMaybe<Scalars['String']>;
    date?: InputMaybe<Scalars['DateTime']>;
    noteSource?: InputMaybe<Scalars['String']>;
    noteType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    patientId: Scalars['ObjectId'];
    staffName?: InputMaybe<Scalars['String']>;
    text?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
};

export type PatientNotesOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type PatientNotesPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type PatientNotesPaginationQueryResponse = PaginationResponse & {
    __typename?: 'PatientNotesPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<PatientNotes>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type PatientNotesQueryInput = {
    filter?: InputMaybe<PatientNotesFilterInput>;
    orderBy?: InputMaybe<PatientNotesOrderByInput>;
    pagination?: InputMaybe<PatientNotesPaginationInput>;
};

export type PatientNotesUpdateInput = {
    NPI?: InputMaybe<Scalars['String']>;
    date?: InputMaybe<Scalars['DateTime']>;
    noteSource?: InputMaybe<Scalars['String']>;
    noteType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    staffName?: InputMaybe<Scalars['String']>;
    text?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
};

export type PatientOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type PatientPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type PatientPaginationQueryResponse = PaginationResponse & {
    __typename?: 'PatientPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Patient>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type PatientPatientNotesFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<PatientNotesUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type PatientPatientNotesOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type PatientPatientNotesPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type PatientPatientNotesQueryInput = {
    filter?: InputMaybe<PatientPatientNotesFilterInput>;
    orderBy?: InputMaybe<PatientPatientNotesOrderByInput>;
    pagination?: InputMaybe<PatientPatientNotesPaginationInput>;
};

export type PatientProfileValue = {
    __typename?: 'PatientProfileValue';
    keyId?: Maybe<Scalars['ObjectId']>;
    profileVariableDefId?: Maybe<Scalars['ObjectId']>;
    setAt?: Maybe<Scalars['DateTime']>;
    surveySessionId?: Maybe<Scalars['String']>;
    timeZone?: Maybe<Scalars['String']>;
    type?: Maybe<ProfileValueType>;
    utcOffset?: Maybe<Scalars['Float']>;
    valueBool?: Maybe<Scalars['Boolean']>;
    valueDate?: Maybe<Scalars['DateTime']>;
    valueId?: Maybe<Scalars['ObjectId']>;
    valueIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
    valueNum?: Maybe<Scalars['Float']>;
    valueStr?: Maybe<Scalars['String']>;
};

export type PatientProfileValueInput = {
    keyId?: InputMaybe<Scalars['ObjectId']>;
    profileVariableDefId?: InputMaybe<Scalars['ObjectId']>;
    setAt?: InputMaybe<Scalars['DateTime']>;
    surveySessionId?: InputMaybe<Scalars['String']>;
    timeZone?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<ProfileValueType>;
    utcOffset?: InputMaybe<Scalars['Float']>;
    valueBool?: InputMaybe<Scalars['Boolean']>;
    valueDate?: InputMaybe<Scalars['DateTime']>;
    valueId?: InputMaybe<Scalars['ObjectId']>;
    valueIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    valueNum?: InputMaybe<Scalars['Float']>;
    valueStr?: InputMaybe<Scalars['String']>;
};

export type PatientProfileValueResponse = MutationResponse & {
    __typename?: 'PatientProfileValueResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type PatientProfileVariable = {
    __typename?: 'PatientProfileVariable';
    categoryName: Scalars['String'];
    conditionId?: Maybe<Scalars['String']>;
    conditionName?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    episodeStartDate?: Maybe<Scalars['DateTime']>;
    haPermission?: Maybe<HaPermission>;
    name: Scalars['String'];
    notes?: Maybe<Scalars['String']>;
    originId?: Maybe<Scalars['String']>;
    profileVariableDefId: Scalars['String'];
    sourceType?: Maybe<Scalars['String']>;
    stringifiedValue: Scalars['String'];
    systemGenerated?: Maybe<Scalars['Boolean']>;
    value: UserProfileValueSchema;
    valueType: Scalars['String'];
};

export type PatientProfileVariableInput = {
    notes?: InputMaybe<Scalars['String']>;
    originId?: InputMaybe<Scalars['String']>;
    portalPatientId: Scalars['String'];
    profileVariableDefId: Scalars['String'];
    value: UserProfileValueSchemaInput;
};

export type PatientQueryInput = {
    filter?: InputMaybe<PatientFilterInput>;
    orderBy?: InputMaybe<PatientOrderByInput>;
    pagination?: InputMaybe<PatientPaginationInput>;
};

export type PatientRef = {
    __typename?: 'PatientRef';
    called?: Maybe<Scalars['Boolean']>;
    patientId?: Maybe<Scalars['ObjectId']>;
};

export type PatientRiskIndicator = {
    __typename?: 'PatientRiskIndicator';
    comment?: Maybe<Scalars['String']>;
    flag: Scalars['Boolean'];
};

export type PatientRiskIndicatorInput = {
    comment?: InputMaybe<Scalars['String']>;
    flag: Scalars['Boolean'];
};

export type PatientRiskIndicators = {
    __typename?: 'PatientRiskIndicators';
    chronicHypertension?: Maybe<PatientRiskIndicator>;
    obesity?: Maybe<PatientRiskIndicator>;
    other?: Maybe<PatientRiskIndicator>;
    preterm?: Maybe<PatientRiskIndicator>;
};

export type PatientRiskIndicatorsInput = {
    chronicHypertension?: InputMaybe<PatientRiskIndicatorInput>;
    obesity?: InputMaybe<PatientRiskIndicatorInput>;
    other?: InputMaybe<PatientRiskIndicatorInput>;
    preterm?: InputMaybe<PatientRiskIndicatorInput>;
};

export type PatientSurveyResponsesFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<SurveyResponseUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type PatientSurveyResponsesOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type PatientSurveyResponsesPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type PatientSurveyResponsesQueryInput = {
    filter?: InputMaybe<PatientSurveyResponsesFilterInput>;
    orderBy?: InputMaybe<PatientSurveyResponsesOrderByInput>;
    pagination?: InputMaybe<PatientSurveyResponsesPaginationInput>;
};

export type PatientToCall = {
    __typename?: 'PatientToCall';
    called?: Maybe<Scalars['Boolean']>;
    patient?: Maybe<Patient>;
};

export type PatientToCallResponse = PaginationResponse & {
    __typename?: 'PatientToCallResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<PatientToCall>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type PatientUpdateInput = {
    affiliateIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    appBundleId?: InputMaybe<Scalars['String']>;
    assignedHealthAdvocate?: InputMaybe<Scalars['ObjectId']>;
    attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
    birthDate?: InputMaybe<Scalars['DateTime']>;
    deliveryDate?: InputMaybe<Scalars['DateTime']>;
    dueDate?: InputMaybe<Scalars['DateTime']>;
    ehrIds?: InputMaybe<Array<InputMaybe<EhrIdsInput>>>;
    ehrIntegration?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    externalId?: InputMaybe<Scalars['String']>;
    firstName?: InputMaybe<Scalars['String']>;
    gender?: InputMaybe<Scalars['String']>;
    groupNumber?: InputMaybe<Scalars['String']>;
    hgPatientId?: InputMaybe<Scalars['String']>;
    insuranceGroupNumber?: InputMaybe<Scalars['String']>;
    insuranceId?: InputMaybe<Scalars['String']>;
    insuranceName?: InputMaybe<Scalars['String']>;
    insuredAddress?: InputMaybe<AddressInput>;
    insuredFirstName?: InputMaybe<Scalars['String']>;
    insuredLastName?: InputMaybe<Scalars['String']>;
    insuredPhoneNumber?: InputMaybe<Scalars['String']>;
    invitationCode?: InputMaybe<Scalars['String']>;
    invitationMode?: InputMaybe<PatientInvitation>;
    isPregnant?: InputMaybe<Scalars['Boolean']>;
    isTestData?: InputMaybe<Scalars['Boolean']>;
    lastName?: InputMaybe<Scalars['String']>;
    mailingAddress?: InputMaybe<AddressInput>;
    memberId?: InputMaybe<Scalars['String']>;
    onboardingCompleteDate?: InputMaybe<Scalars['DateTime']>;
    patientBuildList?: InputMaybe<PatientBuildListInput>;
    patientRelationshipToInsured?: InputMaybe<Scalars['String']>;
    phoneNumber?: InputMaybe<Scalars['String']>;
    practiceId?: InputMaybe<Scalars['ObjectId']>;
    race?: InputMaybe<Scalars['String']>;
    redoxEhrId?: InputMaybe<Scalars['String']>;
    redoxSourceId?: InputMaybe<Scalars['String']>;
    riskIndicators?: InputMaybe<PatientRiskIndicatorsInput>;
    signUpPracticeCode?: InputMaybe<Scalars['String']>;
    subscriberBirthDate?: InputMaybe<Scalars['DateTime']>;
    subscriberSex?: InputMaybe<Scalars['String']>;
    tagIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    timeTapClientId?: InputMaybe<Scalars['String']>;
};

export type PatientUserSelector = {
    __typename?: 'PatientUserSelector';
    familyId?: Maybe<Scalars['String']>;
    userId?: Maybe<Scalars['String']>;
};

export type PatientValueHistoryUpdateInput = {
    id?: InputMaybe<Scalars['ObjectId']>;
    keyId?: InputMaybe<Scalars['ObjectId']>;
};

export type PatientsByDobRangesInput = {
    /** the the birthday day, 1 - 31, to return values for, use 0 for all days */
    day?: InputMaybe<Scalars['Float']>;
    /** the the birthday month, 1 - 12, to return values for, use 0 for all months */
    month?: InputMaybe<Scalars['Float']>;
};

export type PatientsExportResponse = {
    __typename?: 'PatientsExportResponse';
    data?: Maybe<Array<Maybe<Scalars['String']>>>;
    fileName: Scalars['String'];
};

export type PayerMemberNumInput = {
    familyId?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['String']>;
    source?: InputMaybe<PayerMemberNumSource>;
    value?: InputMaybe<Scalars['String']>;
};

export type PayerMemberNumResponseResponse = MutationResponse & {
    __typename?: 'PayerMemberNumResponseResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

/** Source of member payer num value */
export enum PayerMemberNumSource {
    Ehr = 'EHR',
    Eligibility = 'Eligibility',
}

export type PaymentStatusUpdate = {
    id?: InputMaybe<Scalars['ObjectId']>;
    paymentStatus?: InputMaybe<AffiliateInvoicePaymentStatus>;
};

export type PeriodDateInput = {
    isSkipped?: InputMaybe<Scalars['Boolean']>;
    value?: InputMaybe<Scalars['DateTime']>;
};

export type PeriodDateType = {
    __typename?: 'PeriodDateType';
    isSkipped?: Maybe<Scalars['Boolean']>;
    value?: Maybe<Scalars['DateTime']>;
};

export type PeriodLengthInput = {
    isSkipped?: InputMaybe<Scalars['Boolean']>;
    value?: InputMaybe<Scalars['Float']>;
};

export type PeriodLengthType = {
    __typename?: 'PeriodLengthType';
    isSkipped?: Maybe<Scalars['Boolean']>;
    value?: Maybe<Scalars['Float']>;
};

/** Permission Level for a given Role Target */
export enum Permission {
    Delete = 'Delete',
    Edit = 'Edit',
    None = 'None',
    View = 'View',
}

export type Permissions = {
    __typename?: 'Permissions';
    canExport?: Maybe<Scalars['Boolean']>;
    permission?: Maybe<Permission>;
    target?: Maybe<Target>;
};

export type PermissionsInput = {
    canExport?: InputMaybe<Scalars['Boolean']>;
    permission?: InputMaybe<Permission>;
    target?: InputMaybe<Target>;
};

export type PostMessageCenterInput = {
    messageId: Scalars['String'];
    senderId: Scalars['String'];
};

export type PostMessageCenterMutationResponse = MutationResponse & {
    __typename?: 'PostMessageCenterMutationResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type PostProfileValueChangedInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    patientId: Scalars['String'];
    profileVariableDefId?: InputMaybe<Scalars['String']>;
    val?: InputMaybe<ProfileValueDataInput>;
};

export type PostProfileValueChangedMutationResponse = MutationResponse & {
    __typename?: 'PostProfileValueChangedMutationResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type PostSurveyCompletedAnswers = {
    answerId?: InputMaybe<Scalars['String']>;
    answerText?: InputMaybe<Scalars['String']>;
    clientQuestionId?: InputMaybe<Scalars['String']>;
    questionId?: InputMaybe<Scalars['String']>;
    questionText?: InputMaybe<Scalars['String']>;
    questionTypeId?: InputMaybe<Scalars['String']>;
    subsectionName?: InputMaybe<Scalars['String']>;
};

export type PostSurveyCompletedInput = {
    answers?: InputMaybe<Array<InputMaybe<PostSurveyCompletedAnswers>>>;
    appBundleId?: InputMaybe<Scalars['String']>;
    clientSurveyId?: InputMaybe<Scalars['String']>;
    displayName?: InputMaybe<Scalars['String']>;
    patientId: Scalars['String'];
    practiceId?: InputMaybe<Scalars['String']>;
    responseDate?: InputMaybe<Scalars['String']>;
    reviewed?: InputMaybe<Scalars['Boolean']>;
    surveyScore?: InputMaybe<Scalars['Float']>;
    surveyType?: InputMaybe<Scalars['String']>;
};

export type PostSurveyCompletedMutationResponse = MutationResponse & {
    __typename?: 'PostSurveyCompletedMutationResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type PostTrackerChangedInput = {
    data?: InputMaybe<TrackerInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type PostTrackerChangedMutationResponse = MutationResponse & {
    __typename?: 'PostTrackerChangedMutationResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type PotentialMatch = {
    __typename?: 'PotentialMatch';
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
};

export type PotentialMatchInput = {
    firstName?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
};

export type PotentialUser = BaseEntity & {
    __typename?: 'PotentialUser';
    appUserId?: Maybe<Scalars['ObjectId']>;
    createdAt: Scalars['DateTime'];
    data: PotentialUserData;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    /** temporary token used to query potential user info from app */
    potentialUserToken?: Maybe<Scalars['String']>;
    provider: Idp_Names;
    /** unique identifier among customer system */
    proxyId: Scalars['String'];
    salt: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type PotentialUserByAppUserIdResponseData = {
    __typename?: 'PotentialUserByAppUserIdResponseData';
    id?: Maybe<Scalars['ObjectId']>;
};

export type PotentialUserData = {
    __typename?: 'PotentialUserData';
    engage?: Maybe<EngageProviderData>;
    progyny?: Maybe<ProgynyProviderData>;
    sydney?: Maybe<SydneyProviderData>;
};

export type PotentialUserDateInput = {
    engage?: InputMaybe<EngageProviderDataInput>;
    progyny?: InputMaybe<ProgynyProviderDataInput>;
    sydney?: InputMaybe<SydneyProviderDataInput>;
};

export type PotentialUserFieldInListInput = {
    appUserId?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
};

export type PotentialUserFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<PotentialUserUpdateInput>;
    fieldsInList?: InputMaybe<PotentialUserFieldInListInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type PotentialUserIdByAppUserIdInput = {
    appUserId: Scalars['ObjectId'];
};

export type PotentialUserInput = {
    appUserId?: InputMaybe<Scalars['ObjectId']>;
    data: PotentialUserDateInput;
    /** temporary token used to query potential user info from app */
    potentialUserToken?: InputMaybe<Scalars['String']>;
    provider: Idp_Names;
    /** unique identifier among customer system */
    proxyId: Scalars['String'];
    salt: Scalars['String'];
};

export type PotentialUserOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type PotentialUserPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type PotentialUserPaginationQueryResponse = PaginationResponse & {
    __typename?: 'PotentialUserPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<PotentialUser>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type PotentialUserQueryInput = {
    filter?: InputMaybe<PotentialUserFilterInput>;
    orderBy?: InputMaybe<PotentialUserOrderByInput>;
    pagination?: InputMaybe<PotentialUserPaginationInput>;
};

export type PotentialUserUpdateInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    brandId?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    employerId?: InputMaybe<Scalars['String']>;
};

export type Problem = {
    __typename?: 'Problem';
    /** Alternate or translation codes for the actual problem */
    altCodes?: Maybe<Array<RedoxNameAndCodeDetails>>;
    /** The type of problem this is (complaint, diagnosis, symptom, etc.) */
    category?: Maybe<RedoxNameAndCodeDetails>;
    code?: Maybe<Scalars['String']>;
    /** ID of the coding system to which the code belongs in OID format. */
    codeSystem?: Maybe<Scalars['String']>;
    /** Coding system to which the code belongs (e.g. SNOMED CT, LOINC, RxNorm, CVX, ICD9, ICD10). */
    codeSystemName?: Maybe<Scalars['String']>;
    /** Free text comment about the problem. This field is slated to be deprecated in favor of comments[].text */
    comment?: Maybe<Scalars['String']>;
    /** Free text comments about the problem. */
    comments?: Maybe<Array<ProblemComment>>;
    /** When the problem stopped (if it is not current). */
    endDate?: Maybe<Scalars['DateTime']>;
    name?: Maybe<Scalars['String']>;
    /** When the problem was noticed. */
    startDate?: Maybe<Scalars['DateTime']>;
    /** A code describing the current state of the problem (active, inactive, resolved). HITSPProblemStatus */
    status?: Maybe<RedoxNameAndCodeDetails>;
};

export type ProblemComment = {
    __typename?: 'ProblemComment';
    text?: Maybe<Scalars['String']>;
};

export type ProblemCommentInput = {
    text?: InputMaybe<Scalars['String']>;
};

export type ProblemInput = {
    /** Alternate or translation codes for the actual problem */
    altCodes?: InputMaybe<Array<RedoxNameAndCodeDetailsInput>>;
    /** The type of problem this is (complaint, diagnosis, symptom, etc.) */
    category?: InputMaybe<RedoxNameAndCodeDetailsInput>;
    code?: InputMaybe<Scalars['String']>;
    /** ID of the coding system to which the code belongs in OID format. */
    codeSystem?: InputMaybe<Scalars['String']>;
    /** Coding system to which the code belongs (e.g. SNOMED CT, LOINC, RxNorm, CVX, ICD9, ICD10). */
    codeSystemName?: InputMaybe<Scalars['String']>;
    /** Free text comment about the problem. This field is slated to be deprecated in favor of comments[].text */
    comment?: InputMaybe<Scalars['String']>;
    /** Free text comments about the problem. */
    comments?: InputMaybe<Array<ProblemCommentInput>>;
    /** When the problem stopped (if it is not current). */
    endDate?: InputMaybe<Scalars['DateTime']>;
    name?: InputMaybe<Scalars['String']>;
    /** When the problem was noticed. */
    startDate?: InputMaybe<Scalars['DateTime']>;
    /** A code describing the current state of the problem (active, inactive, resolved). HITSPProblemStatus */
    status?: InputMaybe<RedoxNameAndCodeDetailsInput>;
};

export type ProfileKey = BaseEntity & {
    __typename?: 'ProfileKey';
    condition?: Maybe<Condition>;
    conditionId?: Maybe<Scalars['ObjectId']>;
    createdAt: Scalars['DateTime'];
    episode?: Maybe<Episode>;
    episodeId?: Maybe<Scalars['ObjectId']>;
    familyId?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    organizationId?: Maybe<Scalars['ObjectId']>;
    patientId?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
};

export type ProfileKeyFieldsInListInput = {
    _id?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
};

export type ProfileKeyFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ProfileKeyUpdateInput>;
    fieldsInList?: InputMaybe<ProfileKeyFieldsInListInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ProfileKeyInput = {
    conditionId?: InputMaybe<Scalars['ObjectId']>;
    episodeId?: InputMaybe<Scalars['ObjectId']>;
    familyId?: InputMaybe<Scalars['String']>;
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    patientId?: InputMaybe<Scalars['String']>;
};

export type ProfileKeyOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type ProfileKeyPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ProfileKeyPaginationQueryResponse = PaginationResponse & {
    __typename?: 'ProfileKeyPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ProfileKey>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ProfileKeyQueryInput = {
    filter?: InputMaybe<ProfileKeyFilterInput>;
    orderBy?: InputMaybe<ProfileKeyOrderByInput>;
    pagination?: InputMaybe<ProfileKeyPaginationInput>;
};

export type ProfileKeyUpdateInput = {
    conditionId?: InputMaybe<Scalars['ObjectId']>;
    episodeId?: InputMaybe<Scalars['ObjectId']>;
    familyId?: InputMaybe<Scalars['String']>;
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    patientId?: InputMaybe<Scalars['String']>;
};

export type ProfileSpecifiedValue = {
    __typename?: 'ProfileSpecifiedValue';
    bool?: Maybe<Scalars['Boolean']>;
    /** Date (and time in UTC) value */
    date?: Maybe<Scalars['DateTime']>;
    num?: Maybe<Scalars['Float']>;
    str?: Maybe<Scalars['String']>;
};

export type ProfileSpecifiedValueInput = {
    bool?: InputMaybe<Scalars['Boolean']>;
    /** Date (and time in UTC) value */
    date?: InputMaybe<Scalars['DateTime']>;
    num?: InputMaybe<Scalars['Float']>;
    str?: InputMaybe<Scalars['String']>;
};

/** The data type of a user specified field profile value. */
export enum ProfileSpecifiedValueType {
    Bool = 'bool',
    Date = 'date',
    Num = 'num',
    Str = 'str',
}

export type ProfileValue = BaseEntity & {
    __typename?: 'ProfileValue';
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    key?: Maybe<ProfileKey>;
    keyId?: Maybe<Scalars['ObjectId']>;
    profileVariableDefId?: Maybe<Scalars['String']>;
    source?: Maybe<SourceType>;
    surveyDefId?: Maybe<Scalars['String']>;
    type?: Maybe<ProfileValueType>;
    updatedAt: Scalars['DateTime'];
    val: ProfileValueData;
};

export type ProfileValueData = {
    __typename?: 'ProfileValueData';
    bool?: Maybe<Scalars['Boolean']>;
    bools?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
    choice?: Maybe<Scalars['String']>;
    choices?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Date (and time in UTC) value */
    date?: Maybe<Scalars['DateTime']>;
    /** The (optional) name of the local time zone for `date` or `dates` */
    dateTimeZone?: Maybe<Scalars['String']>;
    /** The (optional) difference in hours between the local time and UTC for `date` or `dates` */
    dateUtcOffset?: Maybe<Scalars['Float']>;
    dates?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
    localized?: Maybe<LocalizedString>;
    num?: Maybe<Scalars['Float']>;
    nums?: Maybe<Array<Maybe<Scalars['Float']>>>;
    specified?: Maybe<Array<SpecifiedValue>>;
    str?: Maybe<Scalars['String']>;
    strs?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProfileValueDataInput = {
    bool?: InputMaybe<Scalars['Boolean']>;
    bools?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
    choice?: InputMaybe<Scalars['String']>;
    choices?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Date (and time in UTC) value */
    date?: InputMaybe<Scalars['DateTime']>;
    /** The (optional) name of the local time zone for `date` or `dates` */
    dateTimeZone?: InputMaybe<Scalars['String']>;
    /** The (optional) difference in hours between the local time and UTC for `date` or `dates` */
    dateUtcOffset?: InputMaybe<Scalars['Float']>;
    dates?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
    localized?: InputMaybe<LocalizedStringInput>;
    num?: InputMaybe<Scalars['Float']>;
    nums?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
    specified?: InputMaybe<Array<SpecifiedValueInput>>;
    str?: InputMaybe<Scalars['String']>;
    strs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum ProfileValueFilterComparison {
    Equals = 'Equals',
    Greater = 'Greater',
    GreaterOrEquals = 'GreaterOrEquals',
    Less = 'Less',
    LessOrEquals = 'LessOrEquals',
    NotEquals = 'NotEquals',
}

export type ProfileValueFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ProfileValueUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export enum ProfileValueFilterType {
    Bool = 'bool',
    Date = 'date',
    Id = 'id',
    Num = 'num',
    Str = 'str',
}

export type ProfileValueHistory = BaseEntity & {
    __typename?: 'ProfileValueHistory';
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    key?: Maybe<ProfileKey>;
    keyId?: Maybe<Scalars['ObjectId']>;
    profileVariableDefId?: Maybe<Scalars['String']>;
    source?: Maybe<SourceType>;
    surveyDefId?: Maybe<Scalars['String']>;
    type?: Maybe<ProfileValueType>;
    updatedAt: Scalars['DateTime'];
    val: ProfileValueData;
};

export type ProfileValueHistoryFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<PatientValueHistoryUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ProfileValueHistoryInput = {
    keyId?: InputMaybe<Scalars['ObjectId']>;
    profileVariableDefId?: InputMaybe<Scalars['String']>;
    source?: InputMaybe<SourceTypeInput>;
    surveyDefId?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<ProfileValueType>;
    val: ProfileValueDataInput;
};

export type ProfileValueHistoryOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type ProfileValueHistoryPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ProfileValueHistoryPaginationQueryResponse = PaginationResponse & {
    __typename?: 'ProfileValueHistoryPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ProfileValueHistory>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ProfileValueHistoryQueryInput = {
    filter?: InputMaybe<ProfileValueHistoryFilterInput>;
    orderBy?: InputMaybe<ProfileValueHistoryOrderByInput>;
    pagination?: InputMaybe<ProfileValueHistoryPaginationInput>;
};

export type ProfileValueInput = {
    keyId?: InputMaybe<Scalars['ObjectId']>;
    profileVariableDefId?: InputMaybe<Scalars['String']>;
    source?: InputMaybe<SourceTypeInput>;
    surveyDefId?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<ProfileValueType>;
    val: ProfileValueDataInput;
};

export type ProfileValueOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type ProfileValueOrigin = BaseEntity & {
    __typename?: 'ProfileValueOrigin';
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    label: LocalizedString;
    updatedAt: Scalars['DateTime'];
};

export type ProfileValueOriginFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ProfileValueOriginUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ProfileValueOriginInput = {
    label: LocalizedStringInput;
};

export type ProfileValueOriginOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ProfileValueOriginPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ProfileValueOriginPaginationQueryResponse = PaginationResponse & {
    __typename?: 'ProfileValueOriginPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ProfileValueOrigin>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ProfileValueOriginQueryInput = {
    filter?: InputMaybe<ProfileValueOriginFilterInput>;
    orderBy?: InputMaybe<ProfileValueOriginOrderByInput>;
    pagination?: InputMaybe<ProfileValueOriginPaginationInput>;
};

export type ProfileValueOriginUpdateInput = {
    label?: InputMaybe<LocalizedStringInput>;
};

export type ProfileValuePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ProfileValuePaginationQueryResponse = PaginationResponse & {
    __typename?: 'ProfileValuePaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ProfileValue>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ProfileValueQueryInput = {
    filter?: InputMaybe<ProfileValueFilterInput>;
    orderBy?: InputMaybe<ProfileValueOrderByInput>;
    pagination?: InputMaybe<ProfileValuePaginationInput>;
};

/** The data type of the profile value. */
export enum ProfileValueType {
    Bool = 'bool',
    Bools = 'bools',
    Choice = 'choice',
    Choices = 'choices',
    Date = 'date',
    Dates = 'dates',
    Localized = 'localized',
    Num = 'num',
    Nums = 'nums',
    Str = 'str',
    Strs = 'strs',
}

export type ProfileValueUpdateInput = {
    keyId?: InputMaybe<Scalars['ObjectId']>;
    profileVariableDefId?: InputMaybe<Scalars['String']>;
    source?: InputMaybe<SourceTypeInput>;
    surveyDefId?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<ProfileValueType>;
    val: ProfileValueDataInput;
};

export type ProfileValuesByProfileDefIdInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    profileVariableDefId: Scalars['String'];
};

export type ProfileVariable = {
    __typename?: 'ProfileVariable';
    conditionId?: Maybe<Scalars['ObjectId']>;
    episodeEndDate?: Maybe<Scalars['DateTime']>;
    episodeId?: Maybe<Scalars['ObjectId']>;
    familyId?: Maybe<Scalars['String']>;
    keyId?: Maybe<Scalars['ObjectId']>;
    notes?: Maybe<Scalars['String']>;
    originId?: Maybe<Scalars['ObjectId']>;
    patientId?: Maybe<Scalars['String']>;
    profileVariableDefId: Scalars['String'];
    sourceType?: Maybe<Scalars['String']>;
    surveyDefId?: Maybe<Scalars['String']>;
    surveyId?: Maybe<Scalars['ObjectId']>;
    surveySessionId?: Maybe<Scalars['String']>;
    type?: Maybe<ProfileValueType>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    value?: Maybe<ProfileValueData>;
};

export type ProfileVariableForPatientPage = {
    __typename?: 'ProfileVariableForPatientPage';
    conditionId?: Maybe<Scalars['ObjectId']>;
    episode?: Maybe<Episode>;
    notes?: Maybe<Scalars['String']>;
    originId?: Maybe<Scalars['ObjectId']>;
    profileVariableDefId: Scalars['String'];
    sourceType?: Maybe<Scalars['String']>;
    value: ProfileValueData;
};

export type ProfileVariableInput = {
    conditionId?: InputMaybe<Scalars['ObjectId']>;
    episodeId?: InputMaybe<Scalars['ObjectId']>;
    familyId?: InputMaybe<Scalars['String']>;
    keyId?: InputMaybe<Scalars['ObjectId']>;
    notes?: InputMaybe<Scalars['String']>;
    originId?: InputMaybe<Scalars['ObjectId']>;
    patientId?: InputMaybe<Scalars['String']>;
    portalUserId?: InputMaybe<Scalars['String']>;
    profileVariableDefId: Scalars['String'];
    sourceType?: InputMaybe<Scalars['String']>;
    surveyDefId?: InputMaybe<Scalars['String']>;
    surveyId?: InputMaybe<Scalars['ObjectId']>;
    surveySessionId?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<ProfileValueType>;
    updatedAt?: InputMaybe<Scalars['DateTime']>;
    value?: InputMaybe<ProfileValueDataInput>;
};

export type ProfileVariableMutationResponse = MutationResponse & {
    __typename?: 'ProfileVariableMutationResponse';
    message: Scalars['String'];
    resource?: Maybe<ProfileVariable>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type ProfileVariablesMutationResponse = MutationResponse & {
    __typename?: 'ProfileVariablesMutationResponse';
    message: Scalars['String'];
    resources?: Maybe<Array<Maybe<ProfileVariable>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type ProgynyProviderData = {
    __typename?: 'ProgynyProviderData';
    deliveryDate?: Maybe<Scalars['String']>;
    dob: Scalars['String'];
    dueDate?: Maybe<Scalars['String']>;
    eligibilityStatus?: Maybe<Scalars['String']>;
    email: Scalars['String'];
    firstName: Scalars['String'];
    journey?: Maybe<Scalars['String']>;
    lastName: Scalars['String'];
    proxyId: Scalars['String'];
};

export type ProgynyProviderDataInput = {
    deliveryDate?: InputMaybe<Scalars['String']>;
    dob: Scalars['String'];
    dueDate?: InputMaybe<Scalars['String']>;
    eligibilityStatus?: InputMaybe<Scalars['String']>;
    email: Scalars['String'];
    firstName: Scalars['String'];
    journey?: InputMaybe<Scalars['String']>;
    lastName: Scalars['String'];
    proxyId: Scalars['String'];
};

export type Provider = BaseEntity & {
    __typename?: 'Provider';
    createdAt: Scalars['DateTime'];
    externalId?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    /** Name in the locale selected in context */
    localizedName: Scalars['String'];
    /** Object of all localized values */
    name: LocalizedString;
    updatedAt: Scalars['DateTime'];
};

export type ProviderFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ProviderUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ProviderInput = {
    externalId?: InputMaybe<Scalars['String']>;
    name: LocalizedStringInput;
};

export type ProviderOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ProviderPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ProviderPaginationQueryResponse = PaginationResponse & {
    __typename?: 'ProviderPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Provider>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ProviderQueryInput = {
    filter?: InputMaybe<ProviderFilterInput>;
    orderBy?: InputMaybe<ProviderOrderByInput>;
    pagination?: InputMaybe<ProviderPaginationInput>;
};

export type ProviderUpdateInput = {
    externalId?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<LocalizedStringInput>;
};

export type ProxiedCreateTestUserMutationResponse = {
    __typename?: 'ProxiedCreateTestUserMutationResponse';
    password?: Maybe<Scalars['String']>;
    patientId?: Maybe<Scalars['String']>;
};

export type ProxiedEpisode = {
    __typename?: 'ProxiedEpisode';
    beginDate?: Maybe<Scalars['DateTime']>;
    endDate?: Maybe<Scalars['DateTime']>;
};

export type ProxiedSpecifiedValue = {
    __typename?: 'ProxiedSpecifiedValue';
    choiceId?: Maybe<Scalars['String']>;
    type?: Maybe<UserProfileSpecifiedValueType>;
    val?: Maybe<UserProfileSpecifiedValueSchema>;
};

export type ProxiedSpecifiedValueInput = {
    choiceId?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<UserProfileSpecifiedValueType>;
    val?: InputMaybe<UserProfileSpecifiedValueSchemaInput>;
};

export type ProxiedTodoTask = {
    __typename?: 'ProxiedTodoTask';
    carePlan?: Maybe<RelatedCarePlan>;
    createdBy?: Maybe<Scalars['String']>;
    dateCompleted?: Maybe<Scalars['String']>;
    description?: Maybe<LocalizedStringLoose>;
    dueDate?: Maybe<Scalars['String']>;
    effectiveDate?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    isComplete: Scalars['Boolean'];
    isEssential?: Maybe<Scalars['Boolean']>;
    priority?: Maybe<Scalars['Float']>;
    repeatType?: Maybe<Scalars['String']>;
    title: LocalizedStringLoose;
};

export type PublishAllArticlesMutationResponse = MutationResponse & {
    __typename?: 'PublishAllArticlesMutationResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type PublishArticleMutationResponse = MutationResponse & {
    __typename?: 'PublishArticleMutationResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type QList = BaseEntity & {
    __typename?: 'QList';
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isDone: Scalars['Boolean'];
    isTestData?: Maybe<Scalars['Boolean']>;
    loggerVersion: Scalars['String'];
    organizationId?: Maybe<Scalars['ObjectId']>;
    patientId: Scalars['ObjectId'];
    softly_deleted?: Maybe<Scalars['Boolean']>;
    text: Scalars['String'];
    timestamp: Scalars['String'];
    type?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
    uuid?: Maybe<Scalars['String']>;
};

export type QListFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<QListUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type QListInput = {
    createdAt: Scalars['DateTime'];
    isDone: Scalars['Boolean'];
    loggerVersion: Scalars['String'];
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    patientId: Scalars['ObjectId'];
    softly_deleted?: InputMaybe<Scalars['Boolean']>;
    text: Scalars['String'];
    timestamp: Scalars['String'];
    type?: InputMaybe<Scalars['String']>;
    uuid?: InputMaybe<Scalars['String']>;
};

export type QListMutationResponse = MutationResponse & {
    __typename?: 'QListMutationResponse';
    message: Scalars['String'];
    resource?: Maybe<QList>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type QListOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type QListPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type QListPaginationQueryResponse = PaginationResponse & {
    __typename?: 'QListPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<QList>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type QListQueryInput = {
    filter?: InputMaybe<QListFilterInput>;
    orderBy?: InputMaybe<QListOrderByInput>;
    pagination?: InputMaybe<QListPaginationInput>;
};

export type QListUpdateInput = {
    createdAt?: InputMaybe<Scalars['DateTime']>;
    isDone?: InputMaybe<Scalars['Boolean']>;
    loggerVersion?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    softly_deleted?: InputMaybe<Scalars['Boolean']>;
    text?: InputMaybe<Scalars['String']>;
    timestamp?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    uuid?: InputMaybe<Scalars['String']>;
};

export type QListsByPatientIdsInput = {
    patientIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
};

export type Query = {
    __typename?: 'Query';
    AppUserArticleFeed?: Maybe<AppUserArticleFeedResult>;
    /** Fetch an app-config value, if not set, get the default value from the app-config-def */
    ConfigValueOrDefault?: Maybe<ConfigValueDictionary>;
    /** A tag filtered view of the Bioscreen for a specific AppUser/Patient, queryable by appUserId or (patientId + appBundleId) */
    FilteredBioScreen?: Maybe<FilteredBioScreen>;
    GlobalAlert: GlobalAlert;
    GlobalBanner: GlobalBanner;
    GlobalConfirm: GlobalConfirm;
    GlobalImagePreview: GlobalImagePreview;
    GlobalImageUpload: GlobalImageUpload;
    GlobalLoading: GlobalLoading;
    OAuthAPIGetIncentives?: Maybe<OAuthApiGetIncentives>;
    PatientCallListPreviewExportToCSV?: Maybe<PatientCallListExportResponse>;
    PatientCallListsExportToCSV?: Maybe<PatientCallListExportResponse>;
    VCFSettings?: Maybe<VcfSettings>;
    VCZipCodeToLocationSearch?: Maybe<VcZipCodeToLocationSearchResponse>;
    accessCodes?: Maybe<EligibilityAccessCodesResponse>;
    activeEpisode?: Maybe<Episode>;
    /** Additional fields when creating or inviting a patient */
    additionalPatientFieldsProfileDefs: AdditionalPatientFieldsResponse;
    /** Advocate created todo tasks */
    advocateCreatedTodoTasks?: Maybe<Array<Maybe<TodoTask>>>;
    advocateNotification?: Maybe<AdvocateNotification>;
    /** @deprecated Use the advocateNotificationsV2 query instead. */
    advocateNotifications: Array<AdvocateNotification>;
    advocateNotificationsByUser?: Maybe<Array<Maybe<FullInfoAdvocateNotification>>>;
    advocateNotificationsV2: AdvocateNotificationPaginationQueryResponse;
    advocateTask?: Maybe<AdvocateTask>;
    advocateTaskTemplate?: Maybe<AdvocateTaskTemplate>;
    advocateTaskTemplateWhereUsed?: Maybe<EntityWhereUsedResponse>;
    /** @deprecated Use the advocateTaskTemplatesV2 query instead. */
    advocateTaskTemplates: Array<AdvocateTaskTemplate>;
    advocateTaskTemplatesV2: AdvocateTaskTemplatePaginationQueryResponse;
    advocateTaskTimelineViewerFetch: Array<AdvocateTaskTemplate>;
    advocateTaskType?: Maybe<AdvocateTaskType>;
    /** @deprecated Use the advocateTaskTypesV2 query instead. */
    advocateTaskTypes: Array<AdvocateTaskType>;
    advocateTaskTypesV2: AdvocateTaskTypePaginationQueryResponse;
    /** @deprecated Use the advocateTasksV2 query instead. */
    advocateTasks: Array<AdvocateTask>;
    advocateTasksV2: AdvocateTaskPaginationQueryResponse;
    affiliateClaim?: Maybe<AffiliateClaim>;
    affiliateClaimSettings?: Maybe<AffiliateClaimSettingsResponse>;
    /** @deprecated Use the affiliateClaimsV2 query instead. */
    affiliateClaims: Array<AffiliateClaim>;
    affiliateClaimsV2: AffiliateClaimPaginationQueryResponse;
    affiliateInvoice?: Maybe<AffiliateInvoice>;
    /** @deprecated Use the affiliateInvoicesV2 query instead. */
    affiliateInvoices: Array<AffiliateInvoice>;
    affiliateInvoicesV2: AffiliateInvoicePaginationQueryResponse;
    amsApp: AmsApp;
    amsAppByBundleId: AmsApp;
    amsApps: Array<AmsApp>;
    amsClient: AmsClient;
    amsClients: Array<AmsClient>;
    amsTag: AmsTag;
    amsTagCategories: Array<AmsTagCategory>;
    amsTags: Array<AmsTag>;
    amsUnit: AmsUnit;
    amsUnits: Array<AmsUnit>;
    appUser?: Maybe<AppUser>;
    appUserArticle?: Maybe<AppUserArticle>;
    appUserArticles: AppUserArticlePaginationQueryResponse;
    /** All app user created todo tasks */
    appUserCreatedTodoTasks?: Maybe<Array<Maybe<TodoTask>>>;
    appUserDataByAuthToken?: Maybe<AppUserLoggedIn>;
    appUserEmailFromId?: Maybe<EmailFromAppUserIdResponseData>;
    appUserEventTrigger?: Maybe<AppUserEventTrigger>;
    appUserEventTriggerUsed?: Maybe<Scalars['Boolean']>;
    appUserEventTriggers: AppUserEventTriggerPaginationQueryResponse;
    appUserIdFromEmail?: Maybe<AppUserIdFromEmailResponseData>;
    appUserTelemetry?: Maybe<AppUserTelemetry>;
    appUserTelemetrys?: Maybe<AppUserTelemetryResult>;
    appUserTrendTrigger?: Maybe<AppUserTrendTrigger>;
    appUserTrendTriggers: AppUserTrendTriggerPaginationQueryResponse;
    appUsers: AppUserPaginationQueryResponse;
    appUsersByPatientsIds?: Maybe<AppUsersByPatientsIds>;
    appUsersFromEmail?: Maybe<Array<Maybe<AppUser>>>;
    application?: Maybe<Application>;
    /** @deprecated Use the applicationsV2 query instead. */
    applications: Array<Application>;
    applicationsV2: ApplicationPaginationQueryResponse;
    appointment?: Maybe<Appointment>;
    appointmentTemplateHighlight?: Maybe<Highlight>;
    /** @deprecated Use the appointmentsV2 query instead. */
    appointments: Array<Appointment>;
    appointmentsV2: AppointmentPaginationQueryResponse;
    article?: Maybe<Article>;
    articleApproval?: Maybe<ArticleApproval>;
    /** @deprecated Use the articleApprovalsV2 query instead. */
    articleApprovals: Array<ArticleApproval>;
    articleApprovalsV2: ArticleApprovalPaginationQueryResponse;
    articleCache?: Maybe<ArticleCache>;
    articleCacheForByContentId?: Maybe<Array<Maybe<ArticleCache>>>;
    articleCaches: ArticleCachePaginationQueryResponse;
    articleCategory?: Maybe<ArticleCategory>;
    articleCategoryWhereUsed?: Maybe<EntityWhereUsedResponse>;
    /** @deprecated Use the articleCategorysV2 query instead. */
    articleCategorys: Array<ArticleCategory>;
    articleCategorysV2: ArticleCategoryPaginationQueryResponse;
    articleFeed?: Maybe<ArticleFeedResult>;
    articleFeeds: ArticleFeedPaginationQueryResponse;
    articleGetMany?: Maybe<Array<Maybe<FullArticle>>>;
    articleGetOne?: Maybe<FullArticle>;
    articleGroup?: Maybe<ArticleGroup>;
    articleGroupWhereUsed?: Maybe<EntityWhereUsedResponse>;
    /** @deprecated Use the articleGroupsV2 query instead. */
    articleGroups: Array<ArticleGroup>;
    articleGroupsV2: ArticleGroupPaginationQueryResponse;
    articlePickerFetch: ArticlePickerFetchResponse;
    articlePromotion?: Maybe<ArticlePromotion>;
    /** @deprecated Use the articlePromotionsV2 query instead. */
    articlePromotions: Array<ArticlePromotion>;
    articlePromotionsV2: ArticlePromotionListResponse;
    articleSearch?: Maybe<SearchResult>;
    articleWhereUsed?: Maybe<EntityWhereUsedResponse>;
    /** @deprecated Use the articlesV2 query instead. */
    articles: Array<Article>;
    articlesForSimpleContentId: ArticlePickerFetchResponse;
    articlesV2: ArticleListResponse;
    asyncJob?: Maybe<AsyncJob>;
    /** @deprecated Use the asyncJobsV2 query instead. */
    asyncJobs: Array<AsyncJob>;
    asyncJobsV2: AsyncJobPaginationQueryResponse;
    batchTask?: Maybe<BatchTask>;
    /** @deprecated Use the batchTasksV2 query instead. */
    batchTasks: Array<BatchTask>;
    batchTasksV2: BatchTaskPaginationQueryResponse;
    bioScreen?: Maybe<BioScreen>;
    /** @deprecated Use the bioScreensV2 query instead. */
    bioScreens: Array<BioScreen>;
    bioScreensV2: BioScreenPaginationQueryResponse;
    bundleIdForPatient?: Maybe<BundleIdForPatientResponseData>;
    calendar?: Maybe<Calendar>;
    /** @deprecated Use the calendarsV2 query instead. */
    calendars: Array<Calendar>;
    calendarsV2: CalendarPaginationQueryResponse;
    carePlan?: Maybe<CarePlan>;
    /** All care plans and groups */
    carePlanList?: Maybe<Array<Maybe<CarePlanByTodoTasks>>>;
    carePlanTimelineViewerFetch?: Maybe<Array<Maybe<CarePlanTimelineData>>>;
    carePlanTodoGroupById?: Maybe<CarePlanTodoGroup>;
    /** @deprecated Use the carePlansV2 query instead. */
    carePlans: Array<CarePlan>;
    carePlansByIds?: Maybe<Array<Maybe<CarePlan>>>;
    carePlansV2: CarePlanPaginationQueryResponse;
    careTeam?: Maybe<CareTeam>;
    careTeamContact?: Maybe<CareTeamContact>;
    /** @deprecated Use the careTeamContactsV2 query instead. */
    careTeamContacts: Array<CareTeamContact>;
    careTeamContactsV2: CareTeamContactsResponse;
    careTeamMember?: Maybe<CareTeamMember>;
    careTeamMemberType?: Maybe<CareTeamMemberType>;
    careTeamMemberTypeWhereUsed?: Maybe<EntityWhereUsedResponse>;
    /** @deprecated Use the careTeamMemberTypesV2 query instead. */
    careTeamMemberTypes: Array<CareTeamMemberType>;
    careTeamMemberTypesV2: CareTeamMemberTypePaginationQueryResponse;
    /** @deprecated Use the careTeamMembersV2 query instead. */
    careTeamMembers: Array<CareTeamMember>;
    careTeamMembersByTypesNames: Array<CareTeamMembersByType>;
    careTeamMembersV2: CareTeamMemberPaginationQueryResponse;
    careTeamTypes?: Maybe<Array<Maybe<CareTeamTypeWithMembers>>>;
    /** @deprecated Use the careTeamsV2 query instead. */
    careTeams: Array<CareTeam>;
    careTeamsV2: CareTeamPaginationQueryResponse;
    chatConversation?: Maybe<ChatConversation>;
    /** @deprecated Use the chatConversationsV2 query instead. */
    chatConversations: Array<ChatConversation>;
    chatConversationsV2: ChatConversationPaginationQueryResponse;
    chatMessage?: Maybe<ChatMessage>;
    /** @deprecated Use the chatMessagesV2 query instead. */
    chatMessages: Array<ChatMessage>;
    chatMessagesV2: ChatMessagePaginationQueryResponse;
    chatRoom?: Maybe<ChatRoom>;
    /** @deprecated Use the chatRoomsV2 query instead. */
    chatRooms: Array<ChatRoom>;
    chatRoomsV2: ChatRoomPaginationQueryResponse;
    checkPatientsExistenceByEmails?: Maybe<GetEmailsExistancy>;
    claimSubmittedGlobalSearch?: Maybe<ClaimSubmittedGlobalSearchResponse>;
    clinicalSummary?: Maybe<ClinicalSummary>;
    /** @deprecated Use the clinicalSummarysV2 query instead. */
    clinicalSummarys: Array<ClinicalSummary>;
    clinicalSummarysV2: ClinicalSummaryPaginationQueryResponse;
    /** Completed todo tasks */
    completedTodoTasks?: Maybe<Array<Maybe<TodoTask>>>;
    compoundQuestion?: Maybe<CompoundQuestion>;
    /** @deprecated Use the compoundQuestionsV2 query instead. */
    compoundQuestions: Array<CompoundQuestion>;
    compoundQuestionsV2: CompoundQuestionsResponse;
    condition?: Maybe<Condition>;
    conditionType?: Maybe<ConditionType>;
    conditionTypeWhereUsed?: Maybe<EntityWhereUsedResponse>;
    /** @deprecated Use the conditionTypesV2 query instead. */
    conditionTypes: Array<ConditionType>;
    conditionTypesV2: ConditionTypePaginationQueryResponse;
    conditions: ConditionPaginationQueryResponse;
    configExpression?: Maybe<ConfigExpression>;
    /** @deprecated Use the configExpressionsV2 query instead. */
    configExpressions: Array<ConfigExpression>;
    configExpressionsV2: ConfigExpressionResponse;
    configValue?: Maybe<ConfigValue>;
    configValueDef?: Maybe<ConfigValueDef>;
    /** @deprecated Use the configValueDefsV2 query instead. */
    configValueDefs: Array<ConfigValueDef>;
    configValueDefsV2: ConfigValueDefPaginationQueryResponse;
    /** @deprecated Use the configValuesV2 query instead. */
    configValues: Array<ConfigValue>;
    configValuesV2: ConfigValuePaginationQueryResponse;
    contentDirectory?: Maybe<ContentDirectory>;
    contentDirectoryGetBySubId?: Maybe<Array<Maybe<DirectoryCache>>>;
    contentDirectoryGetOneById?: Maybe<CacheContentDirectory>;
    contentDirectoryGetOneByName?: Maybe<CacheContentDirectory>;
    contentDirectoryPickerFetch: ContentDirectoryPickerFetchResponse;
    contentDirectoryWhereUsed?: Maybe<EntityWhereUsedResponse>;
    /** @deprecated Use the contentDirectorysV2 query instead. */
    contentDirectorys: Array<ContentDirectory>;
    contentDirectorysV2: ContentDirectoryListResponse;
    contentPickerFetch: ContentPickerFetchResponse;
    countAppUsers: Scalars['Float'];
    currentUser?: Maybe<User>;
    customFilteredAdvocateTasks: FilteredAdvocateTasksResponse;
    customFilteredArticleApprovals: FilteredArticleApprovalResponse;
    customFilteredArticleApprovalsV2: FilteredArticleApprovalResponse;
    dateTrigger?: Maybe<DateTrigger>;
    dateTriggerV2: DateTriggerListResponse;
    /** @deprecated Use the dateTriggersV2 query instead. */
    dateTriggers: Array<DateTrigger>;
    dateTriggersForTimeline: DateTriggerListResponse;
    dateTriggersV2: DateTriggerPaginationQueryResponse;
    device?: Maybe<Device>;
    /** @deprecated Use the devicesV2 query instead. */
    devices: Array<Device>;
    devicesV2: DevicePaginationQueryResponse;
    directoryCache?: Maybe<DirectoryCache>;
    directoryCaches: DirectoryCachePaginationQueryResponse;
    eligibilityConfiguration?: Maybe<EligibilityConfigurationType>;
    eligibilityMatch?: Maybe<EligibilityMatch>;
    eligibilityMatchQueueMetaData?: Maybe<EligibilityMatchMetaData>;
    eligibilityMatchStats?: Maybe<EligibilityMatchStats>;
    /** @deprecated Use the eligibilityMatchsV2 query instead. */
    eligibilityMatchs: Array<EligibilityMatch>;
    eligibilityMatchsV2: EligibilityMatchPaginationQueryResponse;
    eligibilityResults?: Maybe<VcEligibilityResults>;
    episode?: Maybe<Episode>;
    episodes: EpisodePaginationQueryResponse;
    episodesByConditionId?: Maybe<Array<Maybe<Episode>>>;
    episodesByPatientId?: Maybe<Array<Maybe<Episode>>>;
    /** @deprecated What is userId? */
    episodesByUserId?: Maybe<Array<Maybe<Episode>>>;
    eventTrigger?: Maybe<EventTrigger>;
    eventTriggerForTrackers?: Maybe<Array<Maybe<EventTrigger>>>;
    /** @deprecated Use the eventTriggersV2 query instead. */
    eventTriggers: Array<EventTrigger>;
    eventTriggersV2: EventTriggerPaginationQueryResponse;
    expressionWhereUsed?: Maybe<EntityWhereUsedResponse>;
    familyProfileVariablesFromActiveEpisode?: Maybe<Array<Maybe<ProfileVariable>>>;
    fetchChatRoomForAppUser?: Maybe<ChatRoom>;
    fetchChatRoomForPortalUser?: Maybe<ChatRoom>;
    fetchChatRoomsForAppUser?: Maybe<Array<Maybe<ChatRoom>>>;
    generateReportByReportType?: Maybe<ReportDataResponse>;
    getAffiliateClaimCSVByKey?: Maybe<ReportBufferByKeyResponse>;
    getAgeProfileValuesByPatientIds: Array<ProfileValue>;
    getAppUserWithPVs?: Maybe<AppUserWithPVs>;
    getAppUsersGroupedByBundleId?: Maybe<AppUsersGroupedByBundleIdResponse>;
    getAppUsersInChunks?: Maybe<Array<Maybe<AppUser>>>;
    getArticleFeedByPromoId?: Maybe<ArticleFeed>;
    getClinicalSummaryLogById?: Maybe<GetClinicalSummaryLogByIdResponse>;
    getFamily?: Maybe<GetFamilyResponse>;
    getHTCPatientsByPracticeIds?: Maybe<Array<Maybe<Patient>>>;
    getHighlightCount?: Maybe<Scalars['Float']>;
    getHighlightFeedByHighlightId?: Maybe<HighlightFeed>;
    getHighlightFeedBySCId?: Maybe<Array<Maybe<HighlightFeed>>>;
    getHighlightFeedNotifications?: Maybe<Array<Maybe<NotificationResponse>>>;
    /** Get latest Tracker records by given types with division by mealOption for Blood Glucose tracker */
    getLatesTrackersByTypesWithMealOption?: Maybe<Array<Maybe<Tracker>>>;
    getOrganizationReportByKey?: Maybe<ReportBufferByKeyResponse>;
    getPagedAppUsersWithPVs?: Maybe<Array<Maybe<AppUserWithPVs>>>;
    getPatientByInvitationCode: Patient;
    getPatientByRedoxSourceAndId?: Maybe<Patient>;
    getPatientProfileVariablesByPVDefIds?: Maybe<Array<Maybe<ProfileVariable>>>;
    getPatientReportByKey?: Maybe<ReportBufferByKeyResponse>;
    getPatientUserSelector?: Maybe<PatientUserSelector>;
    getPatientsByDOBRanges: Array<Patient>;
    getPatientsByFilter?: Maybe<Array<Maybe<Patient>>>;
    getPrimaryAppUserId?: Maybe<Scalars['String']>;
    getProfileDefIdsForKey: Array<Scalars['String']>;
    getProfileValueHistoriesByProfileKeys?: Maybe<Array<Maybe<ProfileValueHistory>>>;
    getProfileValuesByProfileDefIdInput: Array<ProfileValue>;
    /** Get QList records for the list of patientIds */
    getQListsByPatientIds?: Maybe<Array<Maybe<QList>>>;
    getReferenceLinksForPage?: Maybe<Array<Maybe<GetReferenceLinksForPageResponse>>>;
    getSurveyDefsByCompoundQuestionId?: Maybe<Array<Maybe<SurveyDef>>>;
    getTagListByUsageTypes: Array<Tag>;
    getTagsForEphemeralEvent: Array<Tag>;
    getTrackerHistoryByRecordId?: Maybe<Array<Maybe<TrackerHistory>>>;
    /** Get Tracker records for the list of patientIds */
    getTrackersByPatientIds?: Maybe<Array<Maybe<Tracker>>>;
    /** Get Tracker records for the list of patientIds and filter out unused measurement types - paginated */
    getTrackersByPatientIdsForMeasurementsPage?: Maybe<TrackersByPatientIdsForMeasurementsPageResponse>;
    /** Get Tracker records for the list of patientIds - paginated */
    getTrackersByPatientIdsV2?: Maybe<TrackersByPatientIdsResponse>;
    getUnPaidInvoicesPaymentStatus?: Maybe<InvoicesPaymentStatusResponse>;
    getUsersByPermission?: Maybe<Array<Maybe<User>>>;
    getVendorById?: Maybe<VendorByIdResponse>;
    /** Get WhoToCall records for the list of patientIds */
    getWhoToCallsByPatientIds?: Maybe<Array<Maybe<WhoToCall>>>;
    healthPlan?: Maybe<HealthPlan>;
    /** @deprecated Use the healthPlansV2 query instead. */
    healthPlans: Array<HealthPlan>;
    healthPlansV2: HealthPlanPaginationQueryResponse;
    highlight?: Maybe<Highlight>;
    highlightFeed?: Maybe<HighlightFeedResult>;
    highlightFeeds: HighlightFeedPaginationQueryResponse;
    /** @deprecated Use the highlightsV2 query instead. */
    highlights: Array<Highlight>;
    highlightsForTimeline: HighlightListResponse;
    highlightsV2: HighlightListResponse;
    internals: Internals;
    intervention?: Maybe<Intervention>;
    /** @deprecated Use the interventionsV2 query instead. */
    interventions: Array<Intervention>;
    interventionsV2: InterventionPaginationQueryResponse;
    /** Checks if app user has todo tasks created by advocate */
    isAdvocateCreatedTodoTasksExist?: Maybe<Scalars['Boolean']>;
    jiraInfoByUrl: JiraInfo;
    loginAuthMethod?: Maybe<LoginAuthMethodResponse>;
    massUpdatePatient?: Maybe<MassUpdatePatients>;
    /** @deprecated Use the massUpdatePatientsV2 query instead. */
    massUpdatePatients: Array<MassUpdatePatients>;
    massUpdatePatientsV2: MassUpdatePatientPaginationQueryResponse;
    massUpdateTask?: Maybe<MassUpdateTask>;
    /** @deprecated Use the massUpdateTasksV2 query instead. */
    massUpdateTasks: Array<MassUpdateTask>;
    massUpdateTasksV2: MassUpdateTaskListResponse;
    measurement?: Maybe<Measurement>;
    /** @deprecated Use the measurementsV2 query instead. */
    measurements: Array<Measurement>;
    measurementsV2: MeasurementPaginationQueryResponse;
    messageCenterTemplate?: Maybe<MessageCenterTemplate>;
    messageCenterTemplateWhereUsed?: Maybe<EntityWhereUsedResponse>;
    /** @deprecated Use the messageCenterTemplatesV2 query instead. */
    messageCenterTemplates: Array<MessageCenterTemplate>;
    messageCenterTemplatesV2: MessageCenterTemplatePaginationQueryResponse;
    messageTemplate?: Maybe<MessageTemplate>;
    /** @deprecated Use the messageTemplatesV2 query instead. */
    messageTemplates: Array<MessageTemplate>;
    messageTemplatesV2: MessageTemplateListResponse;
    numberOfPatientUnreadMessages?: Maybe<Scalars['Float']>;
    order?: Maybe<Order>;
    /** @deprecated Use the ordersV2 query instead. */
    orders: Array<Order>;
    ordersV2?: Maybe<OrdersV2Response>;
    orgByInviteCode?: Maybe<Organization>;
    orgDescendents?: Maybe<OrgDescendentResponse>;
    organization?: Maybe<Organization>;
    /** @deprecated Use the organizationsV2 query instead. */
    organizations: Array<Organization>;
    organizationsV2: OrganizationPaginationQueryResponse;
    /** Overdue todo tasks */
    overdueTodoTasks?: Maybe<Array<Maybe<TodoTask>>>;
    paginatedAffiliateClaim?: Maybe<PaginatedAffiliateClaimResponse>;
    parentSurveysDefs: Array<SurveyDef>;
    patient?: Maybe<Patient>;
    patientAction?: Maybe<PatientAction>;
    patientActionType?: Maybe<PatientActionType>;
    patientActionTypeWhereUsed?: Maybe<EntityWhereUsedResponse>;
    /** @deprecated Use the patientActionTypesV2 query instead. */
    patientActionTypes: Array<PatientActionType>;
    patientActionTypesV2: PatientActionTypePaginationQueryResponse;
    /** @deprecated Use the patientActionsV2 query instead. */
    patientActions: Array<PatientAction>;
    patientActionsV2: PatientActionPaginationQueryResponse;
    patientCache?: Maybe<PatientCache>;
    patientCacheByAppUser?: Maybe<PatientCache>;
    patientCaches: PatientCachePaginationQueryResponse;
    patientCallList?: Maybe<PatientCallList>;
    /** @deprecated Use the patientCallListsV2 query instead. */
    patientCallLists: Array<PatientCallList>;
    patientCallListsV2: PatientCallListPaginationQueryResponse;
    patientHeader?: Maybe<PatientHeader>;
    /** @deprecated Use the patientHeadersV2 query instead. */
    patientHeaders: Array<PatientHeader>;
    patientHeadersV2: PatientHeaderPaginationQueryResponse;
    patientHighlights?: Maybe<PatientHighlightsResult>;
    patientNoteTag?: Maybe<PatientNoteTag>;
    /** @deprecated Use the patientNoteTagsV2 query instead. */
    patientNoteTags: Array<PatientNoteTag>;
    patientNoteTagsV2: PatientNoteTagPaginationQueryResponse;
    patientNotes?: Maybe<PatientNotes>;
    /** @deprecated Use the patientNotessV2 query instead. */
    patientNotess: Array<PatientNotes>;
    patientNotessV2: PatientNotesPaginationQueryResponse;
    patientProfileVariables: Array<PatientProfileVariable>;
    patientProxiedTodoTask?: Maybe<ProxiedTodoTask>;
    patientProxiedTodosItems: Array<ProxiedTodoTask>;
    patientTags?: Maybe<Array<Maybe<Scalars['String']>>>;
    patientTagsAndTimelines?: Maybe<PatientCacheTagsAndTimelines>;
    /** @deprecated Use the patientsV2 query instead. */
    patients: Array<Patient>;
    patientsV2: PatientPaginationQueryResponse;
    performanceTest?: Maybe<Scalars['Boolean']>;
    potentialUser?: Maybe<PotentialUser>;
    potentialUserByToken?: Maybe<Array<Maybe<PotentialUser>>>;
    potentialUserIdByAppUserId?: Maybe<PotentialUserByAppUserIdResponseData>;
    potentialUsers: PotentialUserPaginationQueryResponse;
    profileDefWhereUsed?: Maybe<EntityWhereUsedResponse>;
    profileKey?: Maybe<ProfileKey>;
    profileKeys: ProfileKeyPaginationQueryResponse;
    profileValue?: Maybe<ProfileValue>;
    profileValueHistory?: Maybe<ProfileValueHistory>;
    profileValueHistorys: ProfileValueHistoryPaginationQueryResponse;
    profileValueOrigin?: Maybe<ProfileValueOrigin>;
    /** @deprecated Use the profileValueOriginsV2 query instead. */
    profileValueOrigins: Array<ProfileValueOrigin>;
    profileValueOriginsV2: ProfileValueOriginPaginationQueryResponse;
    profileValues: ProfileValuePaginationQueryResponse;
    profileVariable?: Maybe<ProfileVariable>;
    profileVariables?: Maybe<Array<Maybe<ProfileVariable>>>;
    profileVariablesBy?: Maybe<Array<Maybe<ProfileVariableForPatientPage>>>;
    profileVariablesByPVDefIds?: Maybe<Array<Maybe<ProfileVariable>>>;
    /** This resolver is used for getting PVs for patients w/o episodes */
    profileVariablesByPatientIds?: Maybe<Array<Maybe<ProfileVariable>>>;
    profileVariablesFromHistoryBySurveySessionId?: Maybe<Array<Maybe<ProfileVariable>>>;
    provider?: Maybe<Provider>;
    /** @deprecated Use the providersV2 query instead. */
    providers: Array<Provider>;
    providersV2: ProviderPaginationQueryResponse;
    proxiedEpisodesByConditionId: Array<ProxiedEpisode>;
    qList?: Maybe<QList>;
    qLists: QListPaginationQueryResponse;
    question?: Maybe<Question>;
    /** @deprecated Use the questionsV2 query instead. */
    questions: Array<Question>;
    questionsV2: QuestionPaginationQueryResponse;
    referenceLink?: Maybe<ReferenceLink>;
    referenceLinkCategory?: Maybe<ReferenceLinkCategory>;
    /** @deprecated Use the referenceLinkCategorysV2 query instead. */
    referenceLinkCategorys: Array<ReferenceLinkCategory>;
    referenceLinkCategorysV2: ReferenceLinkCategoryPaginationQueryResponse;
    /** @deprecated Use the referenceLinksV2 query instead. */
    referenceLinks: Array<ReferenceLink>;
    referenceLinksV2: ReferenceLinkPaginationQueryResponse;
    reportingCategory?: Maybe<ReportingCategory>;
    reportingCategoryWhereUsed?: Maybe<EntityWhereUsedResponse>;
    /** @deprecated Use the reportingCategorysV2 query instead. */
    reportingCategorys: Array<ReportingCategory>;
    reportingCategorysV2: ReportingCategoryPaginationQueryResponse;
    reportingJob?: Maybe<ReportingJob>;
    /** @deprecated Use the reportingJobsV2 query instead. */
    reportingJobs: Array<ReportingJob>;
    reportingJobsV2: ReportingJobPaginationQueryResponse;
    role?: Maybe<Role>;
    roleOfCurrentUser: Role;
    roleWhereUsed: EntityWhereUsedResponse;
    roles: Array<Role>;
    rolesV2: RolePaginationQueryResponse;
    sentMessage?: Maybe<SentMessage>;
    sentMessages: SentMessagePaginationQueryResponse;
    settings: UiSettings;
    simpleContent?: Maybe<SimpleContent>;
    simpleContentWhereUsed?: Maybe<EntityWhereUsedResponse>;
    /** @deprecated Use the simpleContentsV2 query instead. */
    simpleContents: Array<SimpleContent>;
    simpleContentsV2: SimpleContentListResponse;
    sla?: Maybe<Sla>;
    slaTimer?: Maybe<SlaTimer>;
    /** @deprecated Use the slaTimersV2 query instead. */
    slaTimers: Array<SlaTimer>;
    slaTimersV2?: Maybe<SlaTimersResponse>;
    /** @deprecated Use the slasV2 query instead. */
    slas: Array<Sla>;
    slasV2: SlaPaginationQueryResponse;
    submittedClaim?: Maybe<ClaimSubmitted>;
    /** @deprecated Use the submittedClaimsV2 query instead. */
    submittedClaims: Array<ClaimSubmitted>;
    submittedClaimsV2: SubmittedClaimPaginationQueryResponse;
    surveyDef?: Maybe<SurveyDef>;
    surveyDefViewer?: Maybe<SurveyDefViewerResponse>;
    surveyDefWhereUsed?: Maybe<EntityWhereUsedResponse>;
    /** @deprecated Use the surveyDefsV2 query instead. */
    surveyDefs: Array<SurveyDef>;
    surveyDefsV2: SurveyDefPaginationQueryResponse;
    surveyDefsWithoutSubSurveys: Array<SurveyDef>;
    surveyResponse?: Maybe<SurveyResponse>;
    /** @deprecated Use the surveyResponsesV2 query instead. */
    surveyResponses: Array<SurveyResponse>;
    surveyResponsesV2: SurveyResponsePaginationQueryResponse;
    systemToken?: Maybe<SystemToken>;
    /** @deprecated Use the systemTokensV2 query instead. */
    systemTokens: Array<SystemToken>;
    systemTokensV2: SystemTokenPaginationQueryResponse;
    tag?: Maybe<Tag>;
    tagWhereUsed?: Maybe<EntityWhereUsedResponse>;
    /** @deprecated Use the tagsV2 query instead. */
    tags: Array<Tag>;
    tagsV2: TagPaginationQueryResponse;
    testQueryAuthorization?: Maybe<Array<Maybe<TestAuthorization>>>;
    timelineViewerFetch: TimelineViewerFetchResponse;
    timelineWhereUsed?: Maybe<EntityWhereUsedResponse>;
    /** All todo tasks where effective date is today */
    todaysTodoTasksNotifications?: Maybe<Array<Maybe<TodoTaskNotification>>>;
    todoTask?: Maybe<TodoTask>;
    todoTasks: TodoTaskPaginationQueryResponse;
    /** Todo tasks queried by carePlanId and/or groupId */
    todoTasksByCarePlanAndGroup?: Maybe<Array<Maybe<TodoTask>>>;
    /** All todo tasks where effective date is today or in the past */
    todoTasksEffective?: Maybe<Array<Maybe<TodoTask>>>;
    token?: Maybe<Token>;
    /** @deprecated Use the tokensV2 query instead. */
    tokens: Array<Token>;
    tokensV2: TokenPaginationQueryResponse;
    tracker?: Maybe<Tracker>;
    trackerHistory?: Maybe<TrackerHistory>;
    trackerHistorys: TrackerHistoryPaginationQueryResponse;
    trackers: TrackerPaginationQueryResponse;
    trendTrigger?: Maybe<TrendTrigger>;
    /** @deprecated Use the trendTriggersV2 query instead. */
    trendTriggers: Array<TrendTrigger>;
    trendTriggersV2: TrendTriggerPaginationQueryResponse;
    unitNames?: Maybe<EligibilityUnitNamesResponse>;
    usStateList: Array<UsState>;
    user?: Maybe<User>;
    userManagementDownloadFamilyData?: Maybe<DownloadFamilyDataResponse>;
    userManagementFindUserByEmail?: Maybe<FindUserByEmailDataResponse>;
    userProfileDef?: Maybe<UserProfileDef>;
    /** @deprecated Use the userProfileDefsV2 query instead. */
    userProfileDefs: Array<UserProfileDef>;
    /** @deprecated use UserProfileDefsV2 input->filter->fieldsInList instead */
    userProfileDefsByIds?: Maybe<Array<Maybe<UserProfileDef>>>;
    /** @deprecated use UserProfileDefsV2 input->filter->fieldsInList instead */
    userProfileDefsByNames?: Maybe<Array<Maybe<UserProfileDef>>>;
    userProfileDefsV2: UserProfileDefPaginationQueryResponse;
    /** @deprecated Use the usersV2 query instead. */
    users: Array<User>;
    usersV2: UserPaginationQueryResponse;
    vCZipCodeToLocation?: Maybe<VcZipCodeToLocation>;
    /** @deprecated Use the vCZipCodeToLocationsV2 query instead. */
    vCZipCodeToLocations: Array<VcZipCodeToLocation>;
    vCZipCodeToLocationsV2: VcZipCodeToLocationPaginationQueryResponse;
    /** Validates an AppUser token, if valid returns portal patientId */
    validateAppUserToken?: Maybe<ValidateAppUserTokenResponse>;
    virtualCareAffiliateByAffiliateId?: Maybe<VirtualCareAffiliateByAffiliateIdResponse>;
    virtualCareAffiliateById?: Maybe<VirtualCareAffiliateByIdResponse>;
    virtualCareAffiliatePatients?: Maybe<VcAffiliatePatientsResponse>;
    virtualCareAffiliates?: Maybe<VirtualCareAffiliatesResponse>;
    /** @deprecated Use the virtualCareAffiliatessV2 query instead. */
    virtualCareAffiliatess: Array<VirtualCareAffiliatesEntity>;
    virtualCareAffiliatessV2: VirtualCareAffiliatesPaginationQueryResponse;
    virtualCareEligibilityWhitelist?: Maybe<VirtualCareEligibilityWhitelist>;
    /** @deprecated Use the virtualCareEligibilityWhitelistsV2 query instead. */
    virtualCareEligibilityWhitelists: Array<VirtualCareEligibilityWhitelist>;
    virtualCareEligibilityWhitelistsV2: VirtualCareEligibilityWhitelistPaginationQueryResponse;
    virtualCareForm?: Maybe<VirtualCareForm>;
    /** @deprecated Use the virtualCareFormsV2 query instead. */
    virtualCareForms: Array<VirtualCareForm>;
    virtualCareFormsV2: VirtualCareFormPaginationQueryResponse;
    whatsNewTimeline?: Maybe<WhatsNewTimeline>;
    /** @deprecated Use the whatsNewTimelinesV2 query instead. */
    whatsNewTimelines: Array<WhatsNewTimeline>;
    whatsNewTimelinesV2: WhatsNewTimelinePaginationQueryResponse;
    whoToCall?: Maybe<WhoToCall>;
    whoToCalls: WhoToCallPaginationQueryResponse;
};

export type QueryAppUserArticleFeedArgs = {
    appUserId?: InputMaybe<Scalars['String']>;
};

export type QueryConfigValueOrDefaultArgs = {
    input?: InputMaybe<ConfigValueOrDefaultInput>;
};

export type QueryFilteredBioScreenArgs = {
    input?: InputMaybe<FilteredBioScreenInput>;
};

export type QueryOAuthApiGetIncentivesArgs = {
    input: OAuthApiGetIncentivesInput;
};

export type QueryPatientCallListPreviewExportToCsvArgs = {
    input?: InputMaybe<ExportToCsvCallListPreviewInput>;
};

export type QueryPatientCallListsExportToCsvArgs = {
    input?: InputMaybe<ExportToCsvCallListFilterInput>;
};

export type QueryVcZipCodeToLocationSearchArgs = {
    input?: InputMaybe<VcZipCodeToLocationSearchInput>;
};

export type QueryAccessCodesArgs = {
    input: EligibilityAccessCodeInput;
};

export type QueryActiveEpisodeArgs = {
    input: ActiveEpisodeInput;
};

export type QueryAdditionalPatientFieldsProfileDefsArgs = {
    input?: InputMaybe<AdditionalPatientFieldsInput>;
};

export type QueryAdvocateCreatedTodoTasksArgs = {
    input: AdvocateCreatedTodoTasksInput;
};

export type QueryAdvocateNotificationArgs = {
    input: GetAdvocateNotificationInput;
};

export type QueryAdvocateNotificationsArgs = {
    input?: InputMaybe<AdvocateNotificationQueryInput>;
};

export type QueryAdvocateNotificationsByUserArgs = {
    input: AadvocateNotificationsByUserInput;
};

export type QueryAdvocateNotificationsV2Args = {
    input?: InputMaybe<AdvocateNotificationQueryInput>;
};

export type QueryAdvocateTaskArgs = {
    input: GetAdvocateTaskInput;
};

export type QueryAdvocateTaskTemplateArgs = {
    input: GetAdvocateTaskTemplateInput;
};

export type QueryAdvocateTaskTemplateWhereUsedArgs = {
    id: Scalars['String'];
};

export type QueryAdvocateTaskTemplatesArgs = {
    input?: InputMaybe<AdvocateTaskTemplateQueryInput>;
};

export type QueryAdvocateTaskTemplatesV2Args = {
    input?: InputMaybe<AdvocateTaskTemplateQueryInput>;
};

export type QueryAdvocateTaskTimelineViewerFetchArgs = {
    input: AdvocateTaskTimelineViewerFetchInput;
};

export type QueryAdvocateTaskTypeArgs = {
    input: GetAdvocateTaskTypeInput;
};

export type QueryAdvocateTaskTypesArgs = {
    input?: InputMaybe<AdvocateTaskTypeQueryInput>;
};

export type QueryAdvocateTaskTypesV2Args = {
    input?: InputMaybe<AdvocateTaskTypeQueryInput>;
};

export type QueryAdvocateTasksArgs = {
    input?: InputMaybe<AdvocateTaskQueryInput>;
};

export type QueryAdvocateTasksV2Args = {
    input?: InputMaybe<AdvocateTaskQueryInput>;
};

export type QueryAffiliateClaimArgs = {
    input: GetAffiliateClaimInput;
};

export type QueryAffiliateClaimsArgs = {
    input?: InputMaybe<AffiliateClaimQueryInput>;
};

export type QueryAffiliateClaimsV2Args = {
    input?: InputMaybe<AffiliateClaimQueryInput>;
};

export type QueryAffiliateInvoiceArgs = {
    input: GetAffiliateInvoiceInput;
};

export type QueryAffiliateInvoicesArgs = {
    input?: InputMaybe<AffiliateInvoiceQueryInput>;
};

export type QueryAffiliateInvoicesV2Args = {
    input?: InputMaybe<AffiliateInvoiceQueryInput>;
};

export type QueryAmsAppArgs = {
    input: GetAmsAppInput;
};

export type QueryAmsAppByBundleIdArgs = {
    input: GetAmsAppByBundleId;
};

export type QueryAmsClientArgs = {
    input: GetAmsClientInput;
};

export type QueryAmsTagArgs = {
    input: GetAmsTagInput;
};

export type QueryAmsUnitArgs = {
    input: GetAmsUnitInput;
};

export type QueryAppUserArgs = {
    input: AppUserInput;
};

export type QueryAppUserArticleArgs = {
    input: GetAppUserArticleInput;
};

export type QueryAppUserArticlesArgs = {
    input?: InputMaybe<AppUserArticleQueryInput>;
};

export type QueryAppUserCreatedTodoTasksArgs = {
    input: CreatedTodoTasksInput;
};

export type QueryAppUserEmailFromIdArgs = {
    appUserId?: InputMaybe<Scalars['String']>;
};

export type QueryAppUserEventTriggerArgs = {
    input: GetAppUserEventTriggerInput;
};

export type QueryAppUserEventTriggerUsedArgs = {
    input?: InputMaybe<AppUserEventTriggerUsedInput>;
};

export type QueryAppUserEventTriggersArgs = {
    input?: InputMaybe<AppUserEventTriggerQueryInput>;
};

export type QueryAppUserIdFromEmailArgs = {
    email?: InputMaybe<Scalars['String']>;
};

export type QueryAppUserTelemetryArgs = {
    input: GetAppUserTelemetryInput;
};

export type QueryAppUserTelemetrysArgs = {
    input?: InputMaybe<AppUserTelemetryPaginationInput>;
};

export type QueryAppUserTrendTriggerArgs = {
    input: GetAppUserTrendTriggerInput;
};

export type QueryAppUserTrendTriggersArgs = {
    input?: InputMaybe<AppUserTrendTriggerQueryInput>;
};

export type QueryAppUsersArgs = {
    input?: InputMaybe<AppUserQueryInput>;
};

export type QueryAppUsersByPatientsIdsArgs = {
    input: AppUsersFieldsInListInput;
};

export type QueryAppUsersFromEmailArgs = {
    input: AppUserByEmailInput;
};

export type QueryApplicationArgs = {
    input: GetApplicationInput;
};

export type QueryApplicationsArgs = {
    input?: InputMaybe<ApplicationQueryInput>;
};

export type QueryApplicationsV2Args = {
    input?: InputMaybe<ApplicationQueryInput>;
};

export type QueryAppointmentArgs = {
    input: GetAppointmentInput;
};

export type QueryAppointmentsArgs = {
    input?: InputMaybe<AppointmentQueryInput>;
};

export type QueryAppointmentsV2Args = {
    input?: InputMaybe<AppointmentQueryInput>;
};

export type QueryArticleArgs = {
    input: GetArticleInput;
};

export type QueryArticleApprovalArgs = {
    input: GetArticleApprovalInput;
};

export type QueryArticleApprovalsArgs = {
    input?: InputMaybe<ArticleApprovalQueryInput>;
};

export type QueryArticleApprovalsV2Args = {
    input?: InputMaybe<ArticleApprovalQueryInput>;
};

export type QueryArticleCacheArgs = {
    input: GetArticleCacheInput;
};

export type QueryArticleCacheForByContentIdArgs = {
    simpleContentId?: InputMaybe<Scalars['String']>;
};

export type QueryArticleCachesArgs = {
    input?: InputMaybe<ArticleCacheQueryInput>;
};

export type QueryArticleCategoryArgs = {
    input: GetArticleCategoryInput;
};

export type QueryArticleCategoryWhereUsedArgs = {
    id: Scalars['String'];
};

export type QueryArticleCategorysArgs = {
    input?: InputMaybe<ArticleCategoryQueryInput>;
};

export type QueryArticleCategorysV2Args = {
    input?: InputMaybe<ArticleCategoryQueryInput>;
};

export type QueryArticleFeedArgs = {
    appUserId?: InputMaybe<Scalars['String']>;
};

export type QueryArticleFeedsArgs = {
    input?: InputMaybe<ArticleFeedQueryInput>;
};

export type QueryArticleGetManyArgs = {
    input?: InputMaybe<ArticleGetManyInput>;
};

export type QueryArticleGetOneArgs = {
    appUserId?: InputMaybe<Scalars['String']>;
    articleId?: InputMaybe<Scalars['String']>;
};

export type QueryArticleGroupArgs = {
    input: GetArticleGroupInput;
};

export type QueryArticleGroupWhereUsedArgs = {
    id: Scalars['String'];
};

export type QueryArticleGroupsArgs = {
    input?: InputMaybe<ArticleGroupQueryInput>;
};

export type QueryArticleGroupsV2Args = {
    input?: InputMaybe<ArticleGroupQueryInput>;
};

export type QueryArticlePickerFetchArgs = {
    input: ArticlePickerFetchInput;
};

export type QueryArticlePromotionArgs = {
    input: GetArticlePromotionInput;
};

export type QueryArticlePromotionsArgs = {
    input?: InputMaybe<ArticlePromotionQueryInput>;
};

export type QueryArticlePromotionsV2Args = {
    input?: InputMaybe<ArticlePromotionListInput>;
};

export type QueryArticleSearchArgs = {
    appUserId?: InputMaybe<Scalars['String']>;
    searchTerm?: InputMaybe<Scalars['String']>;
};

export type QueryArticleWhereUsedArgs = {
    articleId: Scalars['String'];
};

export type QueryArticlesArgs = {
    input?: InputMaybe<ArticleQueryInput>;
};

export type QueryArticlesForSimpleContentIdArgs = {
    input?: InputMaybe<ArticlesForSimpleContentInput>;
};

export type QueryArticlesV2Args = {
    input?: InputMaybe<ArticleListInput>;
};

export type QueryAsyncJobArgs = {
    input: GetAsyncJobInput;
};

export type QueryAsyncJobsArgs = {
    input?: InputMaybe<AsyncJobQueryInput>;
};

export type QueryAsyncJobsV2Args = {
    input?: InputMaybe<AsyncJobQueryInput>;
};

export type QueryBatchTaskArgs = {
    input: GetBatchTaskInput;
};

export type QueryBatchTasksArgs = {
    input?: InputMaybe<BatchTaskQueryInput>;
};

export type QueryBatchTasksV2Args = {
    input?: InputMaybe<BatchTaskQueryInput>;
};

export type QueryBioScreenArgs = {
    input: GetBioScreenInput;
};

export type QueryBioScreensArgs = {
    input?: InputMaybe<BioScreenQueryInput>;
};

export type QueryBioScreensV2Args = {
    input?: InputMaybe<BioScreenQueryInput>;
};

export type QueryBundleIdForPatientArgs = {
    patientId?: InputMaybe<Scalars['String']>;
};

export type QueryCalendarArgs = {
    input: GetCalendarInput;
};

export type QueryCalendarsArgs = {
    input?: InputMaybe<CalendarQueryInput>;
};

export type QueryCalendarsV2Args = {
    input?: InputMaybe<CalendarQueryInput>;
};

export type QueryCarePlanArgs = {
    input: GetCarePlanInput;
};

export type QueryCarePlanListArgs = {
    input?: InputMaybe<CarePlansListInput>;
};

export type QueryCarePlanTimelineViewerFetchArgs = {
    input: CarePlanTimelineViewerFetchInput;
};

export type QueryCarePlanTodoGroupByIdArgs = {
    carePlanId?: InputMaybe<Scalars['String']>;
    groupId?: InputMaybe<Scalars['String']>;
};

export type QueryCarePlansArgs = {
    input?: InputMaybe<CarePlanQueryInput>;
};

export type QueryCarePlansByIdsArgs = {
    carePlansIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryCarePlansV2Args = {
    input?: InputMaybe<CarePlanQueryInput>;
};

export type QueryCareTeamArgs = {
    input: GetCareTeamInput;
};

export type QueryCareTeamContactArgs = {
    input: GetCareTeamContactInput;
};

export type QueryCareTeamContactsArgs = {
    input?: InputMaybe<CareTeamContactQueryInput>;
};

export type QueryCareTeamContactsV2Args = {
    input?: InputMaybe<CareTeamContactsListInput>;
};

export type QueryCareTeamMemberArgs = {
    input: GetCareTeamMemberInput;
};

export type QueryCareTeamMemberTypeArgs = {
    input: GetCareTeamMemberTypeInput;
};

export type QueryCareTeamMemberTypeWhereUsedArgs = {
    id: Scalars['String'];
};

export type QueryCareTeamMemberTypesArgs = {
    input?: InputMaybe<CareTeamMemberTypeQueryInput>;
};

export type QueryCareTeamMemberTypesV2Args = {
    input?: InputMaybe<CareTeamMemberTypeQueryInput>;
};

export type QueryCareTeamMembersArgs = {
    input?: InputMaybe<CareTeamMemberQueryInput>;
};

export type QueryCareTeamMembersByTypesNamesArgs = {
    input: CareTeamMembersByTypeNamesInput;
};

export type QueryCareTeamMembersV2Args = {
    input?: InputMaybe<CareTeamMemberQueryInput>;
};

export type QueryCareTeamTypesArgs = {
    input: CareTeamTypesInput;
};

export type QueryCareTeamsArgs = {
    input?: InputMaybe<CareTeamQueryInput>;
};

export type QueryCareTeamsV2Args = {
    input?: InputMaybe<CareTeamQueryInput>;
};

export type QueryChatConversationArgs = {
    input: GetChatConversationInput;
};

export type QueryChatConversationsArgs = {
    input?: InputMaybe<ChatConversationQueryInput>;
};

export type QueryChatConversationsV2Args = {
    input?: InputMaybe<ChatConversationQueryInput>;
};

export type QueryChatMessageArgs = {
    input: GetChatMessageInput;
};

export type QueryChatMessagesArgs = {
    input?: InputMaybe<ChatMessageQueryInput>;
};

export type QueryChatMessagesV2Args = {
    input?: InputMaybe<ChatMessageQueryInput>;
};

export type QueryChatRoomArgs = {
    input: GetChatRoomInput;
};

export type QueryChatRoomsArgs = {
    input?: InputMaybe<ChatRoomQueryInput>;
};

export type QueryChatRoomsV2Args = {
    input?: InputMaybe<ChatRoomQueryInput>;
};

export type QueryCheckPatientsExistenceByEmailsArgs = {
    input: Array<Scalars['String']>;
};

export type QueryClaimSubmittedGlobalSearchArgs = {
    input?: InputMaybe<GetClaimSubmittedGlobalSearchInput>;
};

export type QueryClinicalSummaryArgs = {
    input: GetClinicalSummaryInput;
};

export type QueryClinicalSummarysArgs = {
    input?: InputMaybe<ClinicalSummaryQueryInput>;
};

export type QueryClinicalSummarysV2Args = {
    input?: InputMaybe<ClinicalSummaryQueryInput>;
};

export type QueryCompletedTodoTasksArgs = {
    input?: InputMaybe<CompletedTodoInput>;
};

export type QueryCompoundQuestionArgs = {
    input: GetCompoundQuestionInput;
};

export type QueryCompoundQuestionsArgs = {
    input?: InputMaybe<CompoundQuestionQueryInput>;
};

export type QueryCompoundQuestionsV2Args = {
    input?: InputMaybe<CompoundQuestionsListInput>;
};

export type QueryConditionArgs = {
    input: GetConditionInput;
};

export type QueryConditionTypeArgs = {
    input: GetConditionTypeInput;
};

export type QueryConditionTypeWhereUsedArgs = {
    id: Scalars['String'];
};

export type QueryConditionTypesArgs = {
    input?: InputMaybe<ConditionTypeQueryInput>;
};

export type QueryConditionTypesV2Args = {
    input?: InputMaybe<ConditionTypeQueryInput>;
};

export type QueryConditionsArgs = {
    input?: InputMaybe<ConditionQueryInput>;
};

export type QueryConfigExpressionArgs = {
    input: GetConfigExpressionInput;
};

export type QueryConfigExpressionsArgs = {
    input?: InputMaybe<ConfigExpressionQueryInput>;
};

export type QueryConfigExpressionsV2Args = {
    input?: InputMaybe<ConfigExpressionListInput>;
};

export type QueryConfigValueArgs = {
    input: GetConfigValueInput;
};

export type QueryConfigValueDefArgs = {
    input: GetConfigValueDefInput;
};

export type QueryConfigValueDefsArgs = {
    input?: InputMaybe<ConfigValueDefQueryInput>;
};

export type QueryConfigValueDefsV2Args = {
    input?: InputMaybe<ConfigValueDefQueryInput>;
};

export type QueryConfigValuesArgs = {
    input?: InputMaybe<ConfigValueQueryInput>;
};

export type QueryConfigValuesV2Args = {
    input?: InputMaybe<ConfigValueQueryInput>;
};

export type QueryContentDirectoryArgs = {
    input: GetContentDirectoryInput;
};

export type QueryContentDirectoryGetBySubIdArgs = {
    contentDirectoryId?: InputMaybe<Scalars['String']>;
};

export type QueryContentDirectoryGetOneByIdArgs = {
    appUserId?: InputMaybe<Scalars['String']>;
    contentDirectoryId?: InputMaybe<Scalars['String']>;
};

export type QueryContentDirectoryGetOneByNameArgs = {
    appUserId?: InputMaybe<Scalars['String']>;
    contentDirectoryName?: InputMaybe<Scalars['String']>;
};

export type QueryContentDirectoryPickerFetchArgs = {
    input: ContentDirectoryPickerFetchInput;
};

export type QueryContentDirectoryWhereUsedArgs = {
    id: Scalars['String'];
};

export type QueryContentDirectorysArgs = {
    input?: InputMaybe<ContentDirectoryQueryInput>;
};

export type QueryContentDirectorysV2Args = {
    input?: InputMaybe<ContentDirectoryListInput>;
};

export type QueryContentPickerFetchArgs = {
    input: ContentPickerFetchInput;
};

export type QueryCountAppUsersArgs = {
    input?: InputMaybe<CountAppUsersInput>;
};

export type QueryCustomFilteredAdvocateTasksArgs = {
    input?: InputMaybe<GetFilteredAdvocateTasksInput>;
};

export type QueryCustomFilteredArticleApprovalsArgs = {
    input?: InputMaybe<ArticleApprovalListInput>;
};

export type QueryCustomFilteredArticleApprovalsV2Args = {
    input?: InputMaybe<ArticleApprovalListInput>;
};

export type QueryDateTriggerArgs = {
    input: GetDateTriggerInput;
};

export type QueryDateTriggerV2Args = {
    input?: InputMaybe<DateTriggerListInput>;
};

export type QueryDateTriggersArgs = {
    input?: InputMaybe<DateTriggerQueryInput>;
};

export type QueryDateTriggersForTimelineArgs = {
    input?: InputMaybe<DateTriggersForTimelineInput>;
};

export type QueryDateTriggersV2Args = {
    input?: InputMaybe<DateTriggerQueryInput>;
};

export type QueryDeviceArgs = {
    input: GetDeviceInput;
};

export type QueryDevicesArgs = {
    input?: InputMaybe<DeviceQueryInput>;
};

export type QueryDevicesV2Args = {
    input?: InputMaybe<DeviceQueryInput>;
};

export type QueryDirectoryCacheArgs = {
    input: GetDirectoryCacheInput;
};

export type QueryDirectoryCachesArgs = {
    input?: InputMaybe<DirectoryCacheQueryInput>;
};

export type QueryEligibilityConfigurationArgs = {
    input: EligibilityConfigurationInput;
};

export type QueryEligibilityMatchArgs = {
    input: GetEligibilityMatchInput;
};

export type QueryEligibilityMatchQueueMetaDataArgs = {
    input?: InputMaybe<EligibilityMatchMetaDataInput>;
};

export type QueryEligibilityMatchsArgs = {
    input?: InputMaybe<EligibilityMatchQueryInput>;
};

export type QueryEligibilityMatchsV2Args = {
    input?: InputMaybe<EligibilityMatchQueryInput>;
};

export type QueryEligibilityResultsArgs = {
    input?: InputMaybe<GetEligibilityResultInput>;
};

export type QueryEpisodeArgs = {
    input: GetEpisodeInput;
};

export type QueryEpisodesArgs = {
    input?: InputMaybe<EpisodeQueryInput>;
};

export type QueryEpisodesByConditionIdArgs = {
    conditionId: Scalars['String'];
};

export type QueryEpisodesByPatientIdArgs = {
    onlyActive?: InputMaybe<Scalars['Boolean']>;
    patientId: Scalars['String'];
};

export type QueryEpisodesByUserIdArgs = {
    userId: Scalars['String'];
};

export type QueryEventTriggerArgs = {
    input: GetEventTriggerInput;
};

export type QueryEventTriggerForTrackersArgs = {
    trackerTypes: Array<EventTrackerType>;
};

export type QueryEventTriggersArgs = {
    input?: InputMaybe<EventTriggerQueryInput>;
};

export type QueryEventTriggersV2Args = {
    input?: InputMaybe<EventTriggerQueryInput>;
};

export type QueryExpressionWhereUsedArgs = {
    id: Scalars['String'];
};

export type QueryFamilyProfileVariablesFromActiveEpisodeArgs = {
    familyId?: InputMaybe<Scalars['String']>;
};

export type QueryFetchChatRoomForAppUserArgs = {
    input?: InputMaybe<FetchChatRoomForAppUserInput>;
};

export type QueryFetchChatRoomForPortalUserArgs = {
    input?: InputMaybe<FetchChatRoomForPortalUserInput>;
};

export type QueryGenerateReportByReportTypeArgs = {
    input: ReportDataInput;
};

export type QueryGetAffiliateClaimCsvByKeyArgs = {
    input: S3GetObjectInput;
};

export type QueryGetAgeProfileValuesByPatientIdsArgs = {
    input: AgeProfileValueSelector;
};

export type QueryGetAppUserWithPVsArgs = {
    appUserId?: InputMaybe<Scalars['String']>;
};

export type QueryGetAppUsersInChunksArgs = {
    input?: InputMaybe<GetAppUsersChunkedInput>;
};

export type QueryGetArticleFeedByPromoIdArgs = {
    appUserId?: InputMaybe<Scalars['String']>;
    promotionId?: InputMaybe<Scalars['String']>;
};

export type QueryGetClinicalSummaryLogByIdArgs = {
    input: GetClinicalSummaryByLogIdInput;
};

export type QueryGetFamilyArgs = {
    input?: InputMaybe<GetFamilyInput>;
};

export type QueryGetHtcPatientsByPracticeIdsArgs = {
    input: GetManyHtcPatientsInput;
};

export type QueryGetHighlightCountArgs = {
    appUserId?: InputMaybe<Scalars['String']>;
};

export type QueryGetHighlightFeedByHighlightIdArgs = {
    appUserId?: InputMaybe<Scalars['String']>;
    highlightId?: InputMaybe<Scalars['String']>;
};

export type QueryGetHighlightFeedByScIdArgs = {
    simpleContentId?: InputMaybe<Scalars['String']>;
};

export type QueryGetLatesTrackersByTypesWithMealOptionArgs = {
    input: LatesTrackersByTypesInput;
};

export type QueryGetOrganizationReportByKeyArgs = {
    input: S3GetObjectInput;
};

export type QueryGetPagedAppUsersWithPVsArgs = {
    input?: InputMaybe<AppUserWithPVsInput>;
};

export type QueryGetPatientByInvitationCodeArgs = {
    input: GetPatientByInvitationCode;
};

export type QueryGetPatientByRedoxSourceAndIdArgs = {
    input: GetPatientByRedoxSourceAndIdInput;
};

export type QueryGetPatientProfileVariablesByPvDefIdsArgs = {
    patientId?: InputMaybe<Scalars['String']>;
    profileDefsIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetPatientReportByKeyArgs = {
    input: S3GetObjectInput;
};

export type QueryGetPatientUserSelectorArgs = {
    portalPatientId?: InputMaybe<Scalars['String']>;
};

export type QueryGetPatientsByDobRangesArgs = {
    input: PatientsByDobRangesInput;
};

export type QueryGetPatientsByFilterArgs = {
    input: GetPatientsByFilterInput;
};

export type QueryGetPrimaryAppUserIdArgs = {
    familyId?: InputMaybe<Scalars['String']>;
};

export type QueryGetProfileDefIdsForKeyArgs = {
    keyId: Scalars['String'];
};

export type QueryGetProfileValueHistoriesByProfileKeysArgs = {
    keyIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    profileDefIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetProfileValuesByProfileDefIdInputArgs = {
    input: ProfileValuesByProfileDefIdInput;
};

export type QueryGetQListsByPatientIdsArgs = {
    input: QListsByPatientIdsInput;
};

export type QueryGetReferenceLinksForPageArgs = {
    input: GetReferenceLinksForPageInput;
};

export type QueryGetSurveyDefsByCompoundQuestionIdArgs = {
    compoundQuestionId: Scalars['String'];
};

export type QueryGetTagListByUsageTypesArgs = {
    input?: InputMaybe<Array<TagUsageType>>;
};

export type QueryGetTagsForEphemeralEventArgs = {
    input: Array<Scalars['String']>;
};

export type QueryGetTrackerHistoryByRecordIdArgs = {
    uuid?: InputMaybe<Scalars['String']>;
};

export type QueryGetTrackersByPatientIdsArgs = {
    input: TrackersByPatientIdsInput;
};

export type QueryGetTrackersByPatientIdsForMeasurementsPageArgs = {
    input: TrackersByPatientIdsForMeasurementsPageInput;
};

export type QueryGetTrackersByPatientIdsV2Args = {
    input: TrackersByPatientIdsV2Input;
};

export type QueryGetUnPaidInvoicesPaymentStatusArgs = {
    input?: InputMaybe<GetInvoicesPaymentStatusInput>;
};

export type QueryGetUsersByPermissionArgs = {
    permission?: InputMaybe<Scalars['String']>;
};

export type QueryGetVendorByIdArgs = {
    input?: InputMaybe<GetVendorByIdInput>;
};

export type QueryGetWhoToCallsByPatientIdsArgs = {
    input: WhoToCallsByPatientIdsInput;
};

export type QueryHealthPlanArgs = {
    input: GetHealthPlanInput;
};

export type QueryHealthPlansArgs = {
    input?: InputMaybe<HealthPlanQueryInput>;
};

export type QueryHealthPlansV2Args = {
    input?: InputMaybe<HealthPlanQueryInput>;
};

export type QueryHighlightArgs = {
    input: GetHighlightInput;
};

export type QueryHighlightFeedArgs = {
    appUserId?: InputMaybe<Scalars['String']>;
    includeReadMore?: InputMaybe<Scalars['Boolean']>;
};

export type QueryHighlightFeedsArgs = {
    input?: InputMaybe<HighlightFeedQueryInput>;
};

export type QueryHighlightsArgs = {
    input?: InputMaybe<HighlightQueryInput>;
};

export type QueryHighlightsForTimelineArgs = {
    input?: InputMaybe<HighlightsForTimelineInput>;
};

export type QueryHighlightsV2Args = {
    input?: InputMaybe<HighlightListInput>;
};

export type QueryInterventionArgs = {
    input: GetInterventionInput;
};

export type QueryInterventionsArgs = {
    input?: InputMaybe<InterventionQueryInput>;
};

export type QueryInterventionsV2Args = {
    input?: InputMaybe<InterventionQueryInput>;
};

export type QueryJiraInfoByUrlArgs = {
    input: JiraInfoInput;
};

export type QueryLoginAuthMethodArgs = {
    input?: InputMaybe<LoginAuthMethodInput>;
};

export type QueryMassUpdatePatientArgs = {
    input: GetMassUpdatePatientInput;
};

export type QueryMassUpdatePatientsArgs = {
    input?: InputMaybe<MassUpdatePatientQueryInput>;
};

export type QueryMassUpdatePatientsV2Args = {
    input?: InputMaybe<MassUpdatePatientQueryInput>;
};

export type QueryMassUpdateTaskArgs = {
    input: GetMassUpdateTaskInput;
};

export type QueryMassUpdateTasksArgs = {
    input?: InputMaybe<MassUpdateTaskQueryInput>;
};

export type QueryMassUpdateTasksV2Args = {
    input?: InputMaybe<MassUpdateTaskListInput>;
    massUpdatePatientsId?: InputMaybe<Scalars['String']>;
};

export type QueryMeasurementArgs = {
    input: GetMeasurementInput;
};

export type QueryMeasurementsArgs = {
    input?: InputMaybe<MeasurementQueryInput>;
};

export type QueryMeasurementsV2Args = {
    input?: InputMaybe<MeasurementQueryInput>;
};

export type QueryMessageCenterTemplateArgs = {
    input: GetMessageCenterTemplateInput;
};

export type QueryMessageCenterTemplateWhereUsedArgs = {
    id: Scalars['String'];
};

export type QueryMessageCenterTemplatesArgs = {
    input?: InputMaybe<MessageCenterTemplateQueryInput>;
};

export type QueryMessageCenterTemplatesV2Args = {
    input?: InputMaybe<MessageCenterTemplateQueryInput>;
};

export type QueryMessageTemplateArgs = {
    input: GetMessageTemplateInput;
};

export type QueryMessageTemplatesArgs = {
    input?: InputMaybe<MessageTemplateQueryInput>;
};

export type QueryMessageTemplatesV2Args = {
    input?: InputMaybe<MessageTemplateListInput>;
};

export type QueryNumberOfPatientUnreadMessagesArgs = {
    input?: InputMaybe<GetNumberOfPatientUnreadMessages>;
};

export type QueryOrderArgs = {
    input: GetOrderInput;
};

export type QueryOrdersArgs = {
    input?: InputMaybe<OrderQueryInput>;
};

export type QueryOrdersV2Args = {
    input: OrdersV2ByFilterInput;
};

export type QueryOrgByInviteCodeArgs = {
    input: OrganizationByInviteCodeInput;
};

export type QueryOrgDescendentsArgs = {
    id: Scalars['String'];
};

export type QueryOrganizationArgs = {
    input: GetOrganizationInput;
};

export type QueryOrganizationsArgs = {
    input?: InputMaybe<OrganizationQueryInput>;
};

export type QueryOrganizationsV2Args = {
    input?: InputMaybe<OrganizationQueryInput>;
};

export type QueryOverdueTodoTasksArgs = {
    input: OverdueTodoTasksInput;
};

export type QueryPaginatedAffiliateClaimArgs = {
    input?: InputMaybe<PaginatedAffiliateClaimInput>;
};

export type QueryParentSurveysDefsArgs = {
    id: Scalars['String'];
};

export type QueryPatientArgs = {
    input: GetPatientInput;
};

export type QueryPatientActionArgs = {
    input: GetPatientActionInput;
};

export type QueryPatientActionTypeArgs = {
    input: GetPatientActionTypeInput;
};

export type QueryPatientActionTypeWhereUsedArgs = {
    id?: InputMaybe<Scalars['String']>;
};

export type QueryPatientActionTypesArgs = {
    input?: InputMaybe<PatientActionTypeQueryInput>;
};

export type QueryPatientActionTypesV2Args = {
    input?: InputMaybe<PatientActionTypeQueryInput>;
};

export type QueryPatientActionsArgs = {
    input?: InputMaybe<PatientActionQueryInput>;
};

export type QueryPatientActionsV2Args = {
    input?: InputMaybe<PatientActionQueryInput>;
};

export type QueryPatientCacheArgs = {
    input: GetPatientCacheInput;
};

export type QueryPatientCacheByAppUserArgs = {
    appUserId?: InputMaybe<Scalars['String']>;
};

export type QueryPatientCachesArgs = {
    input?: InputMaybe<PatientCacheQueryInput>;
};

export type QueryPatientCallListArgs = {
    input: GetPatientCallListInput;
};

export type QueryPatientCallListsArgs = {
    input?: InputMaybe<PatientCallListQueryInput>;
};

export type QueryPatientCallListsV2Args = {
    input?: InputMaybe<PatientCallListQueryInput>;
};

export type QueryPatientHeaderArgs = {
    input: GetPatientHeaderInput;
};

export type QueryPatientHeadersArgs = {
    input?: InputMaybe<PatientHeaderQueryInput>;
};

export type QueryPatientHeadersV2Args = {
    input?: InputMaybe<PatientHeaderQueryInput>;
};

export type QueryPatientHighlightsArgs = {
    appBundleId?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['String']>;
};

export type QueryPatientNoteTagArgs = {
    input: GetPatientNoteTagInput;
};

export type QueryPatientNoteTagsArgs = {
    input?: InputMaybe<PatientNoteTagQueryInput>;
};

export type QueryPatientNoteTagsV2Args = {
    input?: InputMaybe<PatientNoteTagQueryInput>;
};

export type QueryPatientNotesArgs = {
    input: GetPatientNotesInput;
};

export type QueryPatientNotessArgs = {
    input?: InputMaybe<PatientNotesQueryInput>;
};

export type QueryPatientNotessV2Args = {
    input?: InputMaybe<PatientNotesQueryInput>;
};

export type QueryPatientProfileVariablesArgs = {
    names?: InputMaybe<Array<Scalars['String']>>;
    patientId: Scalars['String'];
    profileVariableDefIds?: InputMaybe<Array<Scalars['String']>>;
    systemGenerated?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPatientProxiedTodoTaskArgs = {
    id: Scalars['String'];
};

export type QueryPatientProxiedTodosItemsArgs = {
    patientId: Scalars['String'];
};

export type QueryPatientTagsArgs = {
    portalPatientId?: InputMaybe<Scalars['String']>;
};

export type QueryPatientsArgs = {
    input?: InputMaybe<PatientQueryInput>;
};

export type QueryPatientsV2Args = {
    input?: InputMaybe<PatientQueryInput>;
};

export type QueryPotentialUserArgs = {
    input: GetPotentialUserInput;
};

export type QueryPotentialUserIdByAppUserIdArgs = {
    input: PotentialUserIdByAppUserIdInput;
};

export type QueryPotentialUsersArgs = {
    input?: InputMaybe<PotentialUserQueryInput>;
};

export type QueryProfileDefWhereUsedArgs = {
    id: Scalars['String'];
};

export type QueryProfileKeyArgs = {
    input: GetProfileKeyInput;
};

export type QueryProfileKeysArgs = {
    input?: InputMaybe<ProfileKeyQueryInput>;
};

export type QueryProfileValueArgs = {
    input: GetProfileValueInput;
};

export type QueryProfileValueHistoryArgs = {
    input: GetProfileValueHistoryInput;
};

export type QueryProfileValueHistorysArgs = {
    input?: InputMaybe<ProfileValueHistoryQueryInput>;
};

export type QueryProfileValueOriginArgs = {
    input: GetProfileValueOriginInput;
};

export type QueryProfileValueOriginsArgs = {
    input?: InputMaybe<ProfileValueOriginQueryInput>;
};

export type QueryProfileValueOriginsV2Args = {
    input?: InputMaybe<ProfileValueOriginQueryInput>;
};

export type QueryProfileValuesArgs = {
    input?: InputMaybe<ProfileValueQueryInput>;
};

export type QueryProfileVariableArgs = {
    conditionId?: InputMaybe<Scalars['String']>;
    episodeId?: InputMaybe<Scalars['String']>;
    familyId?: InputMaybe<Scalars['String']>;
    keyId?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['String']>;
    profileVariableDefId?: InputMaybe<Scalars['String']>;
};

export type QueryProfileVariablesArgs = {
    conditionId?: InputMaybe<Scalars['String']>;
    episodeId?: InputMaybe<Scalars['String']>;
    familyId?: InputMaybe<Scalars['String']>;
    keyId?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['String']>;
};

export type QueryProfileVariablesByArgs = {
    portalPatientId: Scalars['String'];
};

export type QueryProfileVariablesByPvDefIdsArgs = {
    familyId?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['String']>;
    profileDefsIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryProfileVariablesByPatientIdsArgs = {
    portalPatientIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryProfileVariablesFromHistoryBySurveySessionIdArgs = {
    familyId?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['String']>;
    profileDefsIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    surveySessionId?: InputMaybe<Scalars['String']>;
};

export type QueryProviderArgs = {
    input: GetProviderInput;
};

export type QueryProvidersArgs = {
    input?: InputMaybe<ProviderQueryInput>;
};

export type QueryProvidersV2Args = {
    input?: InputMaybe<ProviderQueryInput>;
};

export type QueryProxiedEpisodesByConditionIdArgs = {
    conditionId: Scalars['String'];
};

export type QueryQListArgs = {
    input: GetQListInput;
};

export type QueryQListsArgs = {
    input?: InputMaybe<QListQueryInput>;
};

export type QueryQuestionArgs = {
    input: GetQuestionInput;
};

export type QueryQuestionsArgs = {
    input?: InputMaybe<QuestionQueryInput>;
};

export type QueryQuestionsV2Args = {
    input?: InputMaybe<QuestionQueryInput>;
};

export type QueryReferenceLinkArgs = {
    input: GetReferenceLinkInput;
};

export type QueryReferenceLinkCategoryArgs = {
    input: GetReferenceLinkCategoryInput;
};

export type QueryReferenceLinkCategorysArgs = {
    input?: InputMaybe<ReferenceLinkCategoryQueryInput>;
};

export type QueryReferenceLinkCategorysV2Args = {
    input?: InputMaybe<ReferenceLinkCategoryQueryInput>;
};

export type QueryReferenceLinksArgs = {
    input?: InputMaybe<ReferenceLinkQueryInput>;
};

export type QueryReferenceLinksV2Args = {
    input?: InputMaybe<ReferenceLinkQueryInput>;
};

export type QueryReportingCategoryArgs = {
    input: GetReportingCategoryInput;
};

export type QueryReportingCategoryWhereUsedArgs = {
    id: Scalars['String'];
};

export type QueryReportingCategorysArgs = {
    input?: InputMaybe<ReportingCategoryQueryInput>;
};

export type QueryReportingCategorysV2Args = {
    input?: InputMaybe<ReportingCategoryQueryInput>;
};

export type QueryReportingJobArgs = {
    input: GetReportingJobInput;
};

export type QueryReportingJobsArgs = {
    input?: InputMaybe<ReportingJobQueryInput>;
};

export type QueryReportingJobsV2Args = {
    input?: InputMaybe<ReportingJobQueryInput>;
};

export type QueryRoleArgs = {
    input: GetRoleInput;
};

export type QueryRoleWhereUsedArgs = {
    roleId: Scalars['String'];
};

export type QueryRolesV2Args = {
    input?: InputMaybe<RoleQueryInput>;
};

export type QuerySentMessageArgs = {
    input: GetSentMessageInput;
};

export type QuerySentMessagesArgs = {
    input?: InputMaybe<SentMessageQueryInput>;
};

export type QuerySimpleContentArgs = {
    input: GetSimpleContentInput;
};

export type QuerySimpleContentWhereUsedArgs = {
    input: ContentWhereUsedFetchInput;
};

export type QuerySimpleContentsArgs = {
    input?: InputMaybe<SimpleContentQueryInput>;
};

export type QuerySimpleContentsV2Args = {
    input?: InputMaybe<SimpleContentListInput>;
};

export type QuerySlaArgs = {
    input: GetSlaInput;
};

export type QuerySlaTimerArgs = {
    input: GetSlaTimerInput;
};

export type QuerySlaTimersArgs = {
    input?: InputMaybe<SlaTimerQueryInput>;
};

export type QuerySlaTimersV2Args = {
    input?: InputMaybe<GetSlaTimersByFilterInput>;
};

export type QuerySlasArgs = {
    input?: InputMaybe<SlaQueryInput>;
};

export type QuerySlasV2Args = {
    input?: InputMaybe<SlaQueryInput>;
};

export type QuerySubmittedClaimArgs = {
    input: GetSubmittedClaimInput;
};

export type QuerySubmittedClaimsArgs = {
    input?: InputMaybe<SubmittedClaimQueryInput>;
};

export type QuerySubmittedClaimsV2Args = {
    input?: InputMaybe<SubmittedClaimQueryInput>;
};

export type QuerySurveyDefArgs = {
    input: GetSurveyDefInput;
};

export type QuerySurveyDefViewerArgs = {
    surveyId: Scalars['String'];
};

export type QuerySurveyDefWhereUsedArgs = {
    id: Scalars['String'];
};

export type QuerySurveyDefsArgs = {
    input?: InputMaybe<SurveyDefQueryInput>;
};

export type QuerySurveyDefsV2Args = {
    input?: InputMaybe<SurveyDefQueryInput>;
};

export type QuerySurveyResponseArgs = {
    input: GetSurveyResponseInput;
};

export type QuerySurveyResponsesArgs = {
    input?: InputMaybe<SurveyResponseQueryInput>;
};

export type QuerySurveyResponsesV2Args = {
    input?: InputMaybe<SurveyResponseQueryInput>;
};

export type QuerySystemTokenArgs = {
    input: GetSystemTokenInput;
};

export type QuerySystemTokensArgs = {
    input?: InputMaybe<SystemTokenQueryInput>;
};

export type QuerySystemTokensV2Args = {
    input?: InputMaybe<SystemTokenQueryInput>;
};

export type QueryTagArgs = {
    input: GetTagInput;
};

export type QueryTagWhereUsedArgs = {
    input: TagWhereUsedFetchInput;
};

export type QueryTagsArgs = {
    input?: InputMaybe<TagQueryInput>;
};

export type QueryTagsV2Args = {
    input?: InputMaybe<TagQueryInput>;
};

export type QueryTimelineViewerFetchArgs = {
    input: TimelineViewerFetchInput;
};

export type QueryTimelineWhereUsedArgs = {
    id: Scalars['String'];
};

export type QueryTodoTaskArgs = {
    input: GetTodoTaskInput;
};

export type QueryTodoTasksArgs = {
    input?: InputMaybe<TodoTaskQueryInput>;
};

export type QueryTodoTasksByCarePlanAndGroupArgs = {
    input: TodoTasksByCarePlanAndGroupInput;
};

export type QueryTodoTasksEffectiveArgs = {
    input: TodoTasksEffectiveInput;
};

export type QueryTokenArgs = {
    input: GetTokenInput;
};

export type QueryTokensArgs = {
    input?: InputMaybe<TokenQueryInput>;
};

export type QueryTokensV2Args = {
    input?: InputMaybe<TokenQueryInput>;
};

export type QueryTrackerArgs = {
    input: GetTrackerInput;
};

export type QueryTrackerHistoryArgs = {
    input: GetTrackerHistoryInput;
};

export type QueryTrackerHistorysArgs = {
    input?: InputMaybe<TrackerHistoryQueryInput>;
};

export type QueryTrackersArgs = {
    input?: InputMaybe<TrackerQueryInput>;
};

export type QueryTrendTriggerArgs = {
    input: GetTrendTriggerInput;
};

export type QueryTrendTriggersArgs = {
    input?: InputMaybe<TrendTriggerQueryInput>;
};

export type QueryTrendTriggersV2Args = {
    input?: InputMaybe<TrendTriggerQueryInput>;
};

export type QueryUserArgs = {
    input: GetUserInput;
};

export type QueryUserManagementDownloadFamilyDataArgs = {
    input: DownloadFamilyDataInput;
};

export type QueryUserManagementFindUserByEmailArgs = {
    input: FindUserByEmailDataInput;
};

export type QueryUserProfileDefArgs = {
    input: GetUserProfileDefInput;
};

export type QueryUserProfileDefsArgs = {
    input?: InputMaybe<UserProfileDefQueryInput>;
};

export type QueryUserProfileDefsByIdsArgs = {
    profileDefids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryUserProfileDefsByNamesArgs = {
    profileDefNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryUserProfileDefsV2Args = {
    input?: InputMaybe<UserProfileDefQueryInput>;
};

export type QueryUsersArgs = {
    input?: InputMaybe<UserQueryInput>;
};

export type QueryUsersV2Args = {
    input?: InputMaybe<UserQueryInput>;
};

export type QueryVcZipCodeToLocationArgs = {
    input: GetVcZipCodeToLocationInput;
};

export type QueryVcZipCodeToLocationsArgs = {
    input?: InputMaybe<VcZipCodeToLocationQueryInput>;
};

export type QueryVcZipCodeToLocationsV2Args = {
    input?: InputMaybe<VcZipCodeToLocationQueryInput>;
};

export type QueryValidateAppUserTokenArgs = {
    input: ValidateAppUserTokenInput;
};

export type QueryVirtualCareAffiliateByAffiliateIdArgs = {
    input?: InputMaybe<GetVirtualCareAffiliateByAffiliateIdInput>;
};

export type QueryVirtualCareAffiliateByIdArgs = {
    input?: InputMaybe<GetVirtualCareAffiliateInput>;
};

export type QueryVirtualCareAffiliatePatientsArgs = {
    input?: InputMaybe<GetVcAffiliatePatientsInput>;
};

export type QueryVirtualCareAffiliatesArgs = {
    input?: InputMaybe<GetVirtualCareAffiliatesInput>;
};

export type QueryVirtualCareAffiliatessArgs = {
    input?: InputMaybe<VirtualCareAffiliatesQueryInput>;
};

export type QueryVirtualCareAffiliatessV2Args = {
    input?: InputMaybe<VirtualCareAffiliatesQueryInput>;
};

export type QueryVirtualCareEligibilityWhitelistArgs = {
    input: GetVirtualCareEligibilityWhitelistInput;
};

export type QueryVirtualCareEligibilityWhitelistsArgs = {
    input?: InputMaybe<VirtualCareEligibilityWhitelistQueryInput>;
};

export type QueryVirtualCareEligibilityWhitelistsV2Args = {
    input?: InputMaybe<VirtualCareEligibilityWhitelistQueryInput>;
};

export type QueryVirtualCareFormArgs = {
    input: GetVirtualCareFormInput;
};

export type QueryVirtualCareFormsArgs = {
    input?: InputMaybe<VirtualCareFormQueryInput>;
};

export type QueryVirtualCareFormsV2Args = {
    input?: InputMaybe<VirtualCareFormQueryInput>;
};

export type QueryWhatsNewTimelineArgs = {
    input: GetWhatsNewTimelineInput;
};

export type QueryWhatsNewTimelinesArgs = {
    input?: InputMaybe<WhatsNewTimelineQueryInput>;
};

export type QueryWhatsNewTimelinesV2Args = {
    input?: InputMaybe<WhatsNewTimelineQueryInput>;
};

export type QueryWhoToCallArgs = {
    input: GetWhoToCallInput;
};

export type QueryWhoToCallsArgs = {
    input?: InputMaybe<WhoToCallQueryInput>;
};

export type Question = BaseEntity & {
    __typename?: 'Question';
    createdAt: Scalars['DateTime'];
    /** To help with syncing of isDone field */
    externalId?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    isDone: Scalars['Boolean'];
    isTestData?: Maybe<Scalars['Boolean']>;
    organizationId?: Maybe<Scalars['ObjectId']>;
    patient: Patient;
    /** Date question was submitted on app */
    submittedAt: Scalars['DateTime'];
    text: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type QuestionFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<QuestionUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionInput = {
    /** To help with syncing of isDone field */
    externalId: Scalars['String'];
    isDone: Scalars['Boolean'];
    patientId: Scalars['ObjectId'];
    /** Date question was submitted on app */
    submittedAt: Scalars['DateTime'];
    text: Scalars['String'];
};

export type QuestionOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type QuestionPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type QuestionPaginationQueryResponse = PaginationResponse & {
    __typename?: 'QuestionPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Question>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type QuestionQueryInput = {
    filter?: InputMaybe<QuestionFilterInput>;
    orderBy?: InputMaybe<QuestionOrderByInput>;
    pagination?: InputMaybe<QuestionPaginationInput>;
};

export type QuestionUpdateInput = {
    /** To help with syncing of isDone field */
    externalId?: InputMaybe<Scalars['String']>;
    isDone?: InputMaybe<Scalars['Boolean']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    /** Date question was submitted on app */
    submittedAt?: InputMaybe<Scalars['DateTime']>;
    text?: InputMaybe<Scalars['String']>;
};

export type Ranges = {
    __typename?: 'Ranges';
    max?: Maybe<Scalars['Float']>;
    min?: Maybe<Scalars['Float']>;
};

export type RangesInput = {
    max?: InputMaybe<Scalars['Float']>;
    min?: InputMaybe<Scalars['Float']>;
};

export type RangesString = {
    __typename?: 'RangesString';
    max?: Maybe<Scalars['String']>;
    min?: Maybe<Scalars['String']>;
};

export type RangesStringInput = {
    max?: InputMaybe<Scalars['String']>;
    min?: InputMaybe<Scalars['String']>;
};

/** Reading Level. */
export enum ReadingLevel {
    Eighth = 'eighth',
    Fifth = 'fifth',
}

export type RecheckEligibilityInput = {
    bundleId: Scalars['String'];
};

export type RecheckEligibilityResponse = MutationResponse & {
    __typename?: 'RecheckEligibilityResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

/** Record type */
export enum RecordType {
    Dorsata = 'DORSATA',
    Redox = 'REDOX',
    WfhPortal = 'WFH_PORTAL',
}

export type RedoxNameAndCodeDetails = {
    __typename?: 'RedoxNameAndCodeDetails';
    code?: Maybe<Scalars['String']>;
    /** ID of the coding system to which the code belongs in OID format. */
    codeSystem?: Maybe<Scalars['String']>;
    /** Coding system to which the code belongs (e.g. SNOMED CT, LOINC, RxNorm, CVX, ICD9, ICD10). */
    codeSystemName?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
};

export type RedoxNameAndCodeDetailsInput = {
    code?: InputMaybe<Scalars['String']>;
    /** ID of the coding system to which the code belongs in OID format. */
    codeSystem?: InputMaybe<Scalars['String']>;
    /** Coding system to which the code belongs (e.g. SNOMED CT, LOINC, RxNorm, CVX, ICD9, ICD10). */
    codeSystemName?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type ReferenceLink = BaseEntity & {
    __typename?: 'ReferenceLink';
    categoryId?: Maybe<Scalars['ObjectId']>;
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    label: Scalars['String'];
    organizationId?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
    pages?: Maybe<Array<Maybe<ReferencePages>>>;
    roleId?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
    sortField?: Maybe<Scalars['Float']>;
    updatedAt: Scalars['DateTime'];
    url: Scalars['String'];
};

export type ReferenceLinkCategory = BaseEntity & {
    __typename?: 'ReferenceLinkCategory';
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type ReferenceLinkCategoryFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ReferenceLinkCategoryUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ReferenceLinkCategoryInput = {
    name: Scalars['String'];
};

export type ReferenceLinkCategoryOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ReferenceLinkCategoryPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ReferenceLinkCategoryPaginationQueryResponse = PaginationResponse & {
    __typename?: 'ReferenceLinkCategoryPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ReferenceLinkCategory>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ReferenceLinkCategoryQueryInput = {
    filter?: InputMaybe<ReferenceLinkCategoryFilterInput>;
    orderBy?: InputMaybe<ReferenceLinkCategoryOrderByInput>;
    pagination?: InputMaybe<ReferenceLinkCategoryPaginationInput>;
};

export type ReferenceLinkCategoryUpdateInput = {
    name: Scalars['String'];
};

export type ReferenceLinkFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ReferenceLinkUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ReferenceLinkInput = {
    categoryId?: InputMaybe<Scalars['ObjectId']>;
    label: Scalars['String'];
    organizationId?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    pages?: InputMaybe<Array<InputMaybe<ReferencePages>>>;
    roleId?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    sortField?: InputMaybe<Scalars['Float']>;
    url: Scalars['String'];
};

export type ReferenceLinkOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ReferenceLinkPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ReferenceLinkPaginationQueryResponse = PaginationResponse & {
    __typename?: 'ReferenceLinkPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ReferenceLink>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ReferenceLinkQueryInput = {
    filter?: InputMaybe<ReferenceLinkFilterInput>;
    orderBy?: InputMaybe<ReferenceLinkOrderByInput>;
    pagination?: InputMaybe<ReferenceLinkPaginationInput>;
};

export type ReferenceLinkUpdateInput = {
    categoryId?: InputMaybe<Scalars['ObjectId']>;
    label?: InputMaybe<Scalars['String']>;
    organizationId?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    pages?: InputMaybe<Array<InputMaybe<ReferencePages>>>;
    roleId?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    sortField?: InputMaybe<Scalars['Float']>;
    url?: InputMaybe<Scalars['String']>;
};

/** Reference Pages. */
export enum ReferencePages {
    CareTeams = 'CareTeams',
    HaTask = 'HaTask',
    HaTaskList = 'HaTaskList',
    Measurements = 'Measurements',
    MessageCenter = 'MessageCenter',
    Orders = 'Orders',
    PatientClinicalSummaries = 'PatientClinicalSummaries',
    PatientHaTasks = 'PatientHATasks',
    PatientMeasurments = 'PatientMeasurments',
    PatientMessageCenter = 'PatientMessageCenter',
    PatientNotes = 'PatientNotes',
    PatientOrders = 'PatientOrders',
    PatientSurveyResponses = 'PatientSurveyResponses',
    SurveyResponses = 'SurveyResponses',
}

export type RegisterAppUserWithoutAcceptingInviteCodeInput = {
    appBundleId: Scalars['String'];
    birthDate: Scalars['String'];
    email: Scalars['String'];
    externalId?: InputMaybe<Scalars['String']>;
    firstName: Scalars['String'];
    language?: InputMaybe<Language>;
    lastName: Scalars['String'];
    password: Scalars['String'];
    phoneNumber?: InputMaybe<Scalars['String']>;
    profileVariables?: InputMaybe<Array<InputMaybe<ProfileVariableInput>>>;
};

export type RelatedCarePlan = {
    __typename?: 'RelatedCarePlan';
    group?: Maybe<RelatedGroup>;
    id?: Maybe<Scalars['ObjectId']>;
    label?: Maybe<LocalizedStringLoose>;
    template?: Maybe<RelatedTemplate>;
};

export type RelatedGroup = {
    __typename?: 'RelatedGroup';
    id?: Maybe<Scalars['ObjectId']>;
    label?: Maybe<LocalizedStringLoose>;
};

export type RelatedTemplate = {
    __typename?: 'RelatedTemplate';
    id?: Maybe<Scalars['ObjectId']>;
    tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReportBufferByKeyResponse = {
    __typename?: 'ReportBufferByKeyResponse';
    data?: Maybe<Array<Maybe<Scalars['String']>>>;
    filename?: Maybe<Scalars['String']>;
};

export type ReportDataInput = {
    autoSendToClient?: InputMaybe<Scalars['Boolean']>;
    finishReportDate?: InputMaybe<Scalars['DateTime']>;
    organizationId?: InputMaybe<Scalars['String']>;
    reportName: ReportingJobReportNameType;
    startReportDate?: InputMaybe<Scalars['DateTime']>;
};

export type ReportDataResponse = MutationResponse & {
    __typename?: 'ReportDataResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type ReportingCategory = BaseEntity & {
    __typename?: 'ReportingCategory';
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type ReportingCategoryFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ReportingCategoryUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ReportingCategoryInput = {
    name: Scalars['String'];
};

export type ReportingCategoryOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ReportingCategoryPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ReportingCategoryPaginationQueryResponse = PaginationResponse & {
    __typename?: 'ReportingCategoryPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ReportingCategory>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ReportingCategoryQueryInput = {
    filter?: InputMaybe<ReportingCategoryFilterInput>;
    orderBy?: InputMaybe<ReportingCategoryOrderByInput>;
    pagination?: InputMaybe<ReportingCategoryPaginationInput>;
};

export type ReportingCategoryUpdateInput = {
    name: Scalars['String'];
};

export type ReportingJob = BaseEntity & {
    __typename?: 'ReportingJob';
    autoSendToClient?: Maybe<Scalars['Boolean']>;
    bhfMonthlyReport?: Maybe<BhfMonthlyReportData>;
    createdAt: Scalars['DateTime'];
    errorText?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    organizationId?: Maybe<Scalars['String']>;
    reportName?: Maybe<ReportingJobReportNameType>;
    s3key?: Maybe<Scalars['String']>;
    startedById?: Maybe<Scalars['ObjectId']>;
    startedByName?: Maybe<Scalars['String']>;
    status?: Maybe<ReportingJobStatus>;
    updatedAt: Scalars['DateTime'];
};

export type ReportingJobFieldInListFilterInput = {
    reportName?: InputMaybe<Array<InputMaybe<ReportingJobReportNameType>>>;
};

export type ReportingJobFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ReportingJobUpdateInput>;
    fieldsInList?: InputMaybe<ReportingJobFieldInListFilterInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type ReportingJobInput = {
    autoSendToClient?: InputMaybe<Scalars['Boolean']>;
    bhfMonthlyReport?: InputMaybe<BhfMonthlyReportDataInput>;
    errorText?: InputMaybe<Scalars['String']>;
    organizationId?: InputMaybe<Scalars['String']>;
    reportName?: InputMaybe<ReportingJobReportNameType>;
    s3key?: InputMaybe<Scalars['String']>;
    startedById?: InputMaybe<Scalars['ObjectId']>;
    startedByName?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<ReportingJobStatus>;
};

export type ReportingJobOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type ReportingJobPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ReportingJobPaginationQueryResponse = PaginationResponse & {
    __typename?: 'ReportingJobPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ReportingJob>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type ReportingJobQueryInput = {
    filter?: InputMaybe<ReportingJobFilterInput>;
    orderBy?: InputMaybe<ReportingJobOrderByInput>;
    pagination?: InputMaybe<ReportingJobPaginationInput>;
};

/** Reporting Job type. */
export enum ReportingJobReportNameType {
    AllPatientList = 'allPatientList',
    BhfMonthlyInvoicing = 'bhfMonthlyInvoicing',
    CignaChatDataReport = 'cignaChatDataReport',
    HighRiskEpdsReport = 'highRiskEPDSReport',
    HtcBillingDataReport = 'htcBillingDataReport',
    HtcGenericDataReport = 'htcGenericDataReport',
    IntakeSurveyResults = 'intakeSurveyResults',
    LifelineReport = 'lifelineReport',
    PatientListExport = 'patientListExport',
    PatientListExportForAxia = 'patientListExportForAxia',
    PregnancyPatientList = 'pregnancyPatientList',
    ProgenyBillingReport = 'progenyBillingReport',
    SshpCaseReport = 'sshpCaseReport',
    StPetersBillingReport = 'stPetersBillingReport',
    StandardizedHmReport = 'standardizedHMReport',
    WhasnHpnBillingReport = 'whasnHpnBillingReport',
}

/** Reporting Job type status. */
export enum ReportingJobStatus {
    Completed = 'completed',
    Error = 'error',
    InProgress = 'inProgress',
}

export type ReportingJobUpdateInput = {
    id?: InputMaybe<Scalars['String']>;
    organizationId?: InputMaybe<Scalars['String']>;
    reportName?: InputMaybe<ReportingJobReportNameType>;
    s3key?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<ReportingJobStatus>;
};

export type ResendAppUserInviteCodeInput = {
    appBundleId: Scalars['String'];
    email: Scalars['String'];
};

export type ResendAppUserInviteCodeResponse = MutationResponse & {
    __typename?: 'ResendAppUserInviteCodeResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type ResendPatientCodeOnResetPasswordInput = {
    id: Scalars['ObjectId'];
};

export type ResendPatientCodeOnResetPasswordResponse = MutationResponse & {
    __typename?: 'ResendPatientCodeOnResetPasswordResponse';
    message: Scalars['String'];
    patient?: Maybe<Patient>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type ResendPatientInvitationByEmailIfExistsInput = {
    appBundleId: Scalars['String'];
    email: Scalars['String'];
};

export type ResendPatientInvitationByEmailIfExistsResponse = MutationResponse & {
    __typename?: 'ResendPatientInvitationByEmailIfExistsResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type ResendPatientInvitationFromPortalInput = {
    id: Scalars['ObjectId'];
};

export type ResendPatientInvitationFromPortalResponse = MutationResponse & {
    __typename?: 'ResendPatientInvitationFromPortalResponse';
    message: Scalars['String'];
    patient?: Maybe<Patient>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type ResendPatientInvitationInput = {
    id: Scalars['ObjectId'];
};

export type ResendPatientInvitationResponse = MutationResponse & {
    __typename?: 'ResendPatientInvitationResponse';
    message: Scalars['String'];
    patient?: Maybe<Patient>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type ResetAppUserPasswordInput = {
    appBundleId: Scalars['String'];
    email: Scalars['String'];
};

export type ResetAppUserPasswordResponse = MutationResponse & {
    __typename?: 'ResetAppUserPasswordResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type ResetPasswordInput = {
    email: Scalars['String'];
};

export type ResetPasswordResponse = MutationResponse & {
    __typename?: 'ResetPasswordResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type ResetPatientPasswordFromPortalInput = {
    id: Scalars['ObjectId'];
};

export type ResetPatientPasswordFromPortalResponse = MutationResponse & {
    __typename?: 'ResetPatientPasswordFromPortalResponse';
    message: Scalars['String'];
    patient?: Maybe<Patient>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type ResetUserPasswordDataInput = {
    bundleId: Scalars['String'];
    email: Scalars['String'];
};

export type ResetUserPasswordDataResponse = MutationResponse & {
    __typename?: 'ResetUserPasswordDataResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type ResolveChatConversationInput = {
    chatRoomId?: InputMaybe<Scalars['ObjectId']>;
    id?: InputMaybe<Scalars['ObjectId']>;
};

export type ResolveChatConversationResponse = MutationResponse & {
    __typename?: 'ResolveChatConversationResponse';
    chatConversations?: Maybe<Array<Maybe<ChatConversation>>>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type ResolvedProblem = {
    __typename?: 'ResolvedProblem';
    /** Alternate or translation codes for the actual problem */
    altCodes?: Maybe<Array<RedoxNameAndCodeDetails>>;
    /** The type of problem this is (complaint, diagnosis, symptom, etc.) */
    category?: Maybe<RedoxNameAndCodeDetails>;
    code?: Maybe<Scalars['String']>;
    /** ID of the coding system to which the code belongs in OID format. */
    codeSystem?: Maybe<Scalars['String']>;
    /** Coding system to which the code belongs (e.g. SNOMED CT, LOINC, RxNorm, CVX, ICD9, ICD10). */
    codeSystemName?: Maybe<Scalars['String']>;
    /** Free text comment about the problem. This field is slated to be deprecated in favor of comments[].text */
    comment?: Maybe<Scalars['String']>;
    /** Free text comments about the problem. */
    comments?: Maybe<Array<ProblemComment>>;
    /** When the problem stopped (if it is not current). */
    endDate?: Maybe<Scalars['DateTime']>;
    name?: Maybe<Scalars['String']>;
    /** When the problem was noticed. */
    startDate?: Maybe<Scalars['DateTime']>;
    /** A code describing the current state of the problem (active, inactive, resolved). HITSPProblemStatus */
    status?: Maybe<RedoxNameAndCodeDetails>;
};

export type ResolvedProblemInput = {
    /** Alternate or translation codes for the actual problem */
    altCodes?: InputMaybe<Array<RedoxNameAndCodeDetailsInput>>;
    /** The type of problem this is (complaint, diagnosis, symptom, etc.) */
    category?: InputMaybe<RedoxNameAndCodeDetailsInput>;
    code?: InputMaybe<Scalars['String']>;
    /** ID of the coding system to which the code belongs in OID format. */
    codeSystem?: InputMaybe<Scalars['String']>;
    /** Coding system to which the code belongs (e.g. SNOMED CT, LOINC, RxNorm, CVX, ICD9, ICD10). */
    codeSystemName?: InputMaybe<Scalars['String']>;
    /** Free text comment about the problem. This field is slated to be deprecated in favor of comments[].text */
    comment?: InputMaybe<Scalars['String']>;
    /** Free text comments about the problem. */
    comments?: InputMaybe<Array<ProblemCommentInput>>;
    /** When the problem stopped (if it is not current). */
    endDate?: InputMaybe<Scalars['DateTime']>;
    name?: InputMaybe<Scalars['String']>;
    /** When the problem was noticed. */
    startDate?: InputMaybe<Scalars['DateTime']>;
    /** A code describing the current state of the problem (active, inactive, resolved). HITSPProblemStatus */
    status?: InputMaybe<RedoxNameAndCodeDetailsInput>;
};

/** Response Calculation type */
export enum ResponseCalculationEnum {
    ClinicHours = 'ClinicHours',
    TwentyFourSeven = 'TwentyFourSeven',
}

export type RiskScoreResponse = {
    __typename?: 'RiskScoreResponse';
    tierName?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['Float']>;
};

export type Role = BaseEntity & {
    __typename?: 'Role';
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTemplate?: Maybe<Scalars['Boolean']>;
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    organizationId?: Maybe<Scalars['ObjectId']>;
    permissions?: Maybe<Array<Maybe<Permissions>>>;
    updatedAt: Scalars['DateTime'];
};

export type RoleFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<RoleUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type RoleInput = {
    isTemplate?: InputMaybe<Scalars['Boolean']>;
    name: Scalars['String'];
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    permissions?: InputMaybe<Array<InputMaybe<PermissionsInput>>>;
};

export type RoleOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type RolePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type RolePaginationQueryResponse = PaginationResponse & {
    __typename?: 'RolePaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Role>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type RoleQueryInput = {
    filter?: InputMaybe<RoleFilterInput>;
    orderBy?: InputMaybe<RoleOrderByInput>;
    pagination?: InputMaybe<RolePaginationInput>;
};

export type RoleUpdateInput = {
    isTemplate?: InputMaybe<Scalars['Boolean']>;
    name?: InputMaybe<Scalars['String']>;
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    permissions?: InputMaybe<Array<InputMaybe<PermissionsInput>>>;
};

export enum RoutingMethod {
    Affiliate = 'Affiliate',
    TimeTap = 'TimeTap',
}

export type S3GetObjectInput = {
    key: Scalars['String'];
};

export type SaveHighlightInput = {
    data?: InputMaybe<HighlightUpdateInput>;
    id?: InputMaybe<Scalars['String']>;
};

export type SaveHighlightResponse = MutationResponse & {
    __typename?: 'SaveHighlightResponse';
    message: Scalars['String'];
    resource?: Maybe<Highlight>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type SearchItem = {
    __typename?: 'SearchItem';
    bookmarked?: Maybe<Scalars['Boolean']>;
    icon?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
    summary?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    viewed?: Maybe<Scalars['Boolean']>;
};

export type SearchResult = {
    __typename?: 'SearchResult';
    contentBeingCreated?: Maybe<Scalars['Boolean']>;
    itemcount?: Maybe<Scalars['Float']>;
    items?: Maybe<Array<Maybe<SearchItem>>>;
};

export type SendInvitationCodeInput = {
    appBundleId: Scalars['String'];
    appLink: Scalars['String'];
    appName: Scalars['String'];
    email: Scalars['String'];
    firstName?: InputMaybe<Scalars['String']>;
    fullOrgName: Scalars['String'];
    invitationCode: Scalars['String'];
    language?: InputMaybe<Language>;
    lastName?: InputMaybe<Scalars['String']>;
    shortOrgName: Scalars['String'];
    supportEmail: Scalars['String'];
};

export type SendInvitationCodeOnResetPasswordInput = {
    appBundleId: Scalars['String'];
    appName: Scalars['String'];
    email: Scalars['String'];
    invitationCode: Scalars['String'];
    supportEmail: Scalars['String'];
};

export type SendInvitationCodeOnResetPasswordResponse = MutationResponse & {
    __typename?: 'SendInvitationCodeOnResetPasswordResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type SendInvitationCodeResponse = MutationResponse & {
    __typename?: 'SendInvitationCodeResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type SendInvitationFromPortalCodeInput = {
    appBundleId: Scalars['String'];
    appLink: Scalars['String'];
    appName: Scalars['String'];
    email: Scalars['String'];
    firstName?: InputMaybe<Scalars['String']>;
    fullOrgName: Scalars['String'];
    invitationCode: Scalars['String'];
    language?: InputMaybe<Language>;
    lastName?: InputMaybe<Scalars['String']>;
    shortOrgName: Scalars['String'];
    supportEmail: Scalars['String'];
};

export type SendInvitationFromPortalCodeResponse = MutationResponse & {
    __typename?: 'SendInvitationFromPortalCodeResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type SendMessageInput = {
    messageTemplateId: Scalars['String'];
    patientId: Scalars['String'];
};

export type SendMessageMutationResponse = MutationResponse & {
    __typename?: 'SendMessageMutationResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type SendMfaCodeInput = {
    channelEmail: Scalars['Boolean'];
    channelSms: Scalars['Boolean'];
};

export type SendMfaCodeResponse = MutationResponse & {
    __typename?: 'SendMfaCodeResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type SendNotificationInput = {
    appUserId: Scalars['String'];
    notifications: Array<Notification>;
};

export type SendNotificationsResponse = MutationResponse & {
    __typename?: 'SendNotificationsResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type SendResetPasswordInvitationInput = {
    appBundleId: Scalars['String'];
    appName: Scalars['String'];
    email: Scalars['String'];
    invitationCode: Scalars['String'];
    supportEmail: Scalars['String'];
};

export type SendStatus = {
    __typename?: 'SendStatus';
    sentVia: MessageTransport;
    status: MessageSendStatus;
    target?: Maybe<Scalars['String']>;
};

export type SendStatusInput = {
    sentVia: MessageTransport;
    status: MessageSendStatus;
    target?: InputMaybe<Scalars['String']>;
};

export type SentMessage = BaseEntity & {
    __typename?: 'SentMessage';
    appBundleId: Scalars['String'];
    appUserId: Scalars['ObjectId'];
    createdAt: Scalars['DateTime'];
    emailBody?: Maybe<Scalars['String']>;
    emailTitle?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    notificationBody?: Maybe<Scalars['String']>;
    organizationId: Scalars['ObjectId'];
    patientId: Scalars['ObjectId'];
    sendStatus: Array<SendStatus>;
    sentDate: Scalars['DateTime'];
    textBody?: Maybe<Scalars['String']>;
    tokens: Array<UsedToken>;
    updatedAt: Scalars['DateTime'];
    urlMapping?: Maybe<Array<UrlMap>>;
};

export type SentMessageFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<SentMessageUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type SentMessageInput = {
    appBundleId: Scalars['String'];
    appUserId: Scalars['ObjectId'];
    emailBody?: InputMaybe<Scalars['String']>;
    emailTitle?: InputMaybe<Scalars['String']>;
    notificationBody?: InputMaybe<Scalars['String']>;
    organizationId: Scalars['ObjectId'];
    patientId: Scalars['ObjectId'];
    sendStatus?: InputMaybe<Array<InputMaybe<SendStatusInput>>>;
    sentDate: Scalars['DateTime'];
    textBody?: InputMaybe<Scalars['String']>;
    tokens: Array<UsedTokenInput>;
    urlMapping?: InputMaybe<Array<UrlMapInput>>;
};

export type SentMessageOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type SentMessagePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type SentMessagePaginationQueryResponse = PaginationResponse & {
    __typename?: 'SentMessagePaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<SentMessage>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type SentMessageQueryInput = {
    filter?: InputMaybe<SentMessageFilterInput>;
    orderBy?: InputMaybe<SentMessageOrderByInput>;
    pagination?: InputMaybe<SentMessagePaginationInput>;
};

export type SentMessageUpdateInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    appUserId?: InputMaybe<Scalars['ObjectId']>;
    emailBody?: InputMaybe<Scalars['String']>;
    emailTitle?: InputMaybe<Scalars['String']>;
    notificationBody?: InputMaybe<Scalars['String']>;
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    sendStatus?: InputMaybe<Array<InputMaybe<SendStatusInput>>>;
    textBody?: InputMaybe<Scalars['String']>;
    tokens?: InputMaybe<Array<InputMaybe<UsedTokenInput>>>;
    urlMapping?: InputMaybe<Array<InputMaybe<UrlMapInput>>>;
};

export type SetCareTeamValueInput = {
    description: Scalars['String'];
    patientExternalId: Scalars['String'];
    visitLocationDepartment: Scalars['String'];
    visitLocationFacility: Scalars['String'];
    visitType: Scalars['String'];
};

export type SetCareTeamValueResponse = MutationResponse & {
    __typename?: 'SetCareTeamValueResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type SetContentCreatedResponse = MutationResponse & {
    __typename?: 'SetContentCreatedResponse';
    data?: Maybe<AppUser>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type SetDemographicsPVsInput = {
    isHispanic?: InputMaybe<Scalars['String']>;
    language?: InputMaybe<Scalars['String']>;
    patientId: Scalars['ObjectId'];
    race?: InputMaybe<Scalars['String']>;
};

export type SetNotificationTokenInput = {
    key: Scalars['String'];
    value: Scalars['String'];
};

export type SetPvByExternalIdInput = {
    externalId: Scalars['String'];
    onlyCreate?: InputMaybe<Scalars['Boolean']>;
    profileVariableDefId: Scalars['String'];
    type?: InputMaybe<Scalars['String']>;
    value?: InputMaybe<Scalars['String']>;
};

export type SetPatientAppUserInput = {
    appBundleId: Scalars['String'];
    appUserId: Scalars['String'];
    familyId: Scalars['String'];
    patientId: Scalars['String'];
};

export type SetPatientAppUserResponse = MutationResponse & {
    __typename?: 'SetPatientAppUserResponse';
    message: Scalars['String'];
    patient?: Maybe<Patient>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type SetPatientProfileValueInput = {
    patientId: Scalars['ObjectId'];
    profileValue: PatientProfileValueInput;
};

export type SetPatientProfileValuesInput = {
    patientId: Scalars['ObjectId'];
    profileValues: Array<PatientProfileValueInput>;
    replaceAll?: InputMaybe<Scalars['Boolean']>;
};

export type SetTasksTestDataResponse = MutationResponse & {
    __typename?: 'SetTasksTestDataResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type SimpleContent = BaseEntity & {
    __typename?: 'SimpleContent';
    articleId?: Maybe<Scalars['ObjectId']>;
    content?: Maybe<Scalars['String']>;
    contentDirectoryId?: Maybe<Scalars['ObjectId']>;
    contentType: ContentType;
    contentTypeName?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    description?: Maybe<LocalizedStringLoose>;
    icon?: Maybe<ContentIcon>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    label?: Maybe<LocalizedStringLoose>;
    name: Scalars['String'];
    surveyId?: Maybe<Scalars['ObjectId']>;
    trackerType?: Maybe<TrackerType>;
    updatedAt: Scalars['DateTime'];
};

export type SimpleContentFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<SimpleContentUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type SimpleContentInput = {
    articleId?: InputMaybe<Scalars['ObjectId']>;
    content?: InputMaybe<Scalars['String']>;
    contentDirectoryId?: InputMaybe<Scalars['ObjectId']>;
    contentType: ContentType;
    description?: InputMaybe<LocalizedStringLooseInput>;
    icon?: InputMaybe<ContentIcon>;
    label?: InputMaybe<LocalizedStringLooseInput>;
    name: Scalars['String'];
    surveyId?: InputMaybe<Scalars['ObjectId']>;
    trackerType?: InputMaybe<TrackerType>;
};

export type SimpleContentListFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<SimpleContentUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type SimpleContentListInput = {
    filter?: InputMaybe<SimpleContentListFilterInput>;
    orderBy?: InputMaybe<SimpleContentListOrderByInput>;
    pagination?: InputMaybe<SimpleContentListPaginationInput>;
};

export type SimpleContentListOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type SimpleContentListPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type SimpleContentListResponse = PaginationResponse & {
    __typename?: 'SimpleContentListResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<SimpleContent>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type SimpleContentOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type SimpleContentPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type SimpleContentQueryInput = {
    filter?: InputMaybe<SimpleContentFilterInput>;
    orderBy?: InputMaybe<SimpleContentOrderByInput>;
    pagination?: InputMaybe<SimpleContentPaginationInput>;
};

export type SimpleContentRef = {
    __typename?: 'SimpleContentRef';
    contentId: Scalars['String'];
    defaultText?: Maybe<Scalars['Boolean']>;
    markupType?: Maybe<Scalars['String']>;
    type: Scalars['String'];
};

export type SimpleContentRefInput = {
    contentId: Scalars['String'];
    defaultText?: InputMaybe<Scalars['Boolean']>;
    markupType?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
};

export type SimpleContentUpdateInput = {
    articleId?: InputMaybe<Scalars['ObjectId']>;
    content?: InputMaybe<Scalars['String']>;
    contentDirectoryId?: InputMaybe<Scalars['ObjectId']>;
    contentType?: InputMaybe<ContentType>;
    icon?: InputMaybe<ContentIcon>;
    name?: InputMaybe<Scalars['String']>;
    surveyId?: InputMaybe<Scalars['ObjectId']>;
    trackerType?: InputMaybe<TrackerType>;
};

export type Sla = BaseEntity & {
    __typename?: 'Sla';
    calendar?: Maybe<Calendar>;
    calendarId: Scalars['ObjectId'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    /** Array of notifications to send before the timer is completed. Measured in milliseconds */
    notifyBefore?: Maybe<Array<Scalars['Float']>>;
    /** How the countdown is calculated. */
    responseCalculation: ResponseCalculationEnum;
    /** Time to complete based on timerType. */
    timeToComplete: Scalars['Float'];
    timerType: SlaTimerTypeEnum;
    type: SlaTypeEnum;
    updatedAt: Scalars['DateTime'];
};

export type SlaFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<SlaUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type SlaInput = {
    calendarId: Scalars['ObjectId'];
    name: Scalars['String'];
    /** Array of notifications to send before the timer is completed. Measured in milliseconds */
    notifyBefore?: InputMaybe<Array<Scalars['Float']>>;
    /** How the countdown is calculated. */
    responseCalculation: ResponseCalculationEnum;
    /** Time to complete based on timerType. */
    timeToComplete: Scalars['Float'];
    timerType: SlaTimerTypeEnum;
    type: SlaTypeEnum;
};

export type SlaOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type SlaPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type SlaPaginationQueryResponse = PaginationResponse & {
    __typename?: 'SlaPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Sla>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type SlaQueryInput = {
    filter?: InputMaybe<SlaFilterInput>;
    orderBy?: InputMaybe<SlaOrderByInput>;
    pagination?: InputMaybe<SlaPaginationInput>;
};

export type SlaTimer = BaseEntity & {
    __typename?: 'SlaTimer';
    completeBy: Scalars['DateTime'];
    completedAt?: Maybe<Scalars['DateTime']>;
    completedBy?: Maybe<Scalars['ObjectId']>;
    completedByUser?: Maybe<User>;
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    organization?: Maybe<Organization>;
    organizationId: Scalars['ObjectId'];
    sla?: Maybe<Sla>;
    slaId: Scalars['ObjectId'];
    task?: Maybe<AdvocateTask>;
    taskId?: Maybe<Scalars['ObjectId']>;
    updatedAt: Scalars['DateTime'];
};

export type SlaTimerFilterFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<SlaTimerUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type SlaTimerFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<SlaTimerUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type SlaTimerFilterOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type SlaTimerFilterPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type SlaTimerInput = {
    completeBy: Scalars['DateTime'];
    completedBy?: InputMaybe<Scalars['ObjectId']>;
    organizationId: Scalars['ObjectId'];
    slaId: Scalars['ObjectId'];
    taskId?: InputMaybe<Scalars['ObjectId']>;
};

export type SlaTimerOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type SlaTimerPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type SlaTimerQueryInput = {
    filter?: InputMaybe<SlaTimerFilterInput>;
    orderBy?: InputMaybe<SlaTimerOrderByInput>;
    pagination?: InputMaybe<SlaTimerPaginationInput>;
};

/** Sla timer type */
export enum SlaTimerTypeEnum {
    Days = 'Days',
    Hours = 'Hours',
}

export type SlaTimerUpdateInput = {
    completeBy?: InputMaybe<Scalars['DateTime']>;
    completedAt?: InputMaybe<Scalars['DateTime']>;
    completedBy?: InputMaybe<Scalars['ObjectId']>;
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    slaId?: InputMaybe<Scalars['ObjectId']>;
    taskId?: InputMaybe<Scalars['ObjectId']>;
};

export type SlaTimersResponse = PaginationResponse & {
    __typename?: 'SlaTimersResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<SlaTimer>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

/** Sla type */
export enum SlaTypeEnum {
    HealthAdvocateTask = 'HealthAdvocateTask',
}

export type SlaUpdateInput = {
    calendarId?: InputMaybe<Scalars['ObjectId']>;
    name?: InputMaybe<Scalars['String']>;
    notifyBefore?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
    responseCalculation?: InputMaybe<ResponseCalculationEnum>;
    timeToComplete?: InputMaybe<Scalars['Float']>;
    timerType?: InputMaybe<SlaTimerTypeEnum>;
    type?: InputMaybe<SlaTypeEnum>;
};

export type SourceType = {
    __typename?: 'SourceType';
    appUserId?: Maybe<Scalars['ObjectId']>;
    notes?: Maybe<Scalars['String']>;
    originId?: Maybe<Scalars['ObjectId']>;
    portalUserId?: Maybe<Scalars['ObjectId']>;
    surveyId?: Maybe<Scalars['ObjectId']>;
    surveySessionId?: Maybe<Scalars['String']>;
    type?: Maybe<SourceTypeEnum>;
};

/** The data type of the source where the value came from */
export enum SourceTypeEnum {
    AppUser = 'AppUser',
    PortalUser = 'PortalUser',
    System = 'System',
}

export type SourceTypeInput = {
    appUserId?: InputMaybe<Scalars['ObjectId']>;
    notes?: InputMaybe<Scalars['String']>;
    originId?: InputMaybe<Scalars['ObjectId']>;
    portalUserId?: InputMaybe<Scalars['ObjectId']>;
    surveyId?: InputMaybe<Scalars['ObjectId']>;
    surveySessionId?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<SourceTypeEnum>;
};

export type SpecifiedValue = {
    __typename?: 'SpecifiedValue';
    choiceId: Scalars['String'];
    type: ProfileSpecifiedValueType;
    val: ProfileSpecifiedValue;
};

export type SpecifiedValueInput = {
    choiceId: Scalars['String'];
    type: ProfileSpecifiedValueType;
    val: ProfileSpecifiedValueInput;
};

export type SubDirectory = {
    __typename?: 'SubDirectory';
    id: Scalars['ObjectId'];
};

export type SubDirectoryInput = {
    id: Scalars['ObjectId'];
};

export type SubmitTaskMutationResponse = MutationResponse & {
    __typename?: 'SubmitTaskMutationResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type SubmittedClaimFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ClaimSubmittedUpdateInput>;
    fieldsInList?: InputMaybe<ClaimSubmittedFieldInListFilterInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type SubmittedClaimOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type SubmittedClaimPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type SubmittedClaimPaginationQueryResponse = PaginationResponse & {
    __typename?: 'SubmittedClaimPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<ClaimSubmitted>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type SubmittedClaimQueryInput = {
    filter?: InputMaybe<SubmittedClaimFilterInput>;
    orderBy?: InputMaybe<SubmittedClaimOrderByInput>;
    pagination?: InputMaybe<SubmittedClaimPaginationInput>;
};

export type Subscription = {
    __typename?: 'Subscription';
    currentUserSubscription?: Maybe<User>;
    newAppUserMessage?: Maybe<ChatMessage>;
    newMessage?: Maybe<ChatMessage>;
};

export type SubscriptionNewMessageArgs = {
    input: NewMessageInput;
};

export enum SupportMethodType {
    Email = 'Email',
    Phone = 'Phone',
    Url = 'URL',
}

export type SurveyDef = BaseEntity & {
    __typename?: 'SurveyDef';
    buttons: SurveyDefButtons;
    completedAtProfileDef: UserProfileDef;
    completedAtProfileDefId: Scalars['ObjectId'];
    createdAt: Scalars['DateTime'];
    disableRestart?: Maybe<Scalars['Boolean']>;
    divisions: Array<SurveyDefDivision>;
    icon?: Maybe<ContentIcon>;
    id: Scalars['ObjectId'];
    imageContent?: Maybe<SimpleContent>;
    imageContentId?: Maybe<Scalars['ObjectId']>;
    isTestData?: Maybe<Scalars['Boolean']>;
    /** Survey Label */
    label: LocalizedString;
    name?: Maybe<Scalars['String']>;
    organization: Organization;
    organizationId: Scalars['ObjectId'];
    requiresAdvocateReview?: Maybe<Scalars['Boolean']>;
    restartFromBeginning?: Maybe<Scalars['Boolean']>;
    scoreProfileDef: UserProfileDef;
    scoreProfileDefId: Scalars['ObjectId'];
    shouldScore?: Maybe<Scalars['Boolean']>;
    startedAtProfileDef: UserProfileDef;
    startedAtProfileDefId: Scalars['ObjectId'];
    statusProfileDef: UserProfileDef;
    statusProfileDefId: Scalars['ObjectId'];
    summary?: Maybe<Scalars['Boolean']>;
    summaryText: SurveyDefText;
    surveyType: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type SurveyDefButton = {
    __typename?: 'SurveyDefButton';
    /** Button Label */
    label?: Maybe<LocalizedString>;
};

export type SurveyDefButtonInput = {
    /** Button Label */
    label?: InputMaybe<LocalizedStringInput>;
};

export type SurveyDefButtons = {
    __typename?: 'SurveyDefButtons';
    end: SurveyDefButton;
    next: SurveyDefButton;
    previous: SurveyDefButton;
    saveSurvey: SurveyDefButton;
};

export type SurveyDefButtonsInput = {
    end: SurveyDefButtonInput;
    next: SurveyDefButtonInput;
    previous: SurveyDefButtonInput;
    saveSurvey: SurveyDefButtonInput;
};

export type SurveyDefChoiceOverride = {
    __typename?: 'SurveyDefChoiceOverride';
    choiceId: Scalars['ObjectId'];
    /** Quest Label Override */
    labelOverride?: Maybe<LocalizedString>;
};

export type SurveyDefChoiceOverrideInput = {
    choiceId: Scalars['ObjectId'];
    /** Quest Label Override */
    labelOverride?: InputMaybe<LocalizedStringInput>;
};

export type SurveyDefCompoundQuestion = {
    __typename?: 'SurveyDefCompoundQuestion';
    answerRequired?: Maybe<Scalars['Boolean']>;
    compoundQuestion?: Maybe<CompoundQuestion>;
    compoundQuestionId: Scalars['ObjectId'];
    displayType: SurveyDefQuestionDisplayType;
    hidePreviousAnswer?: Maybe<Scalars['Boolean']>;
    /** Survey Compound Question Label */
    label: LocalizedString;
};

export type SurveyDefCompoundQuestionInput = {
    answerRequired?: InputMaybe<Scalars['Boolean']>;
    compoundQuestionId: Scalars['ObjectId'];
    displayType: SurveyDefQuestionDisplayType;
    hidePreviousAnswer?: InputMaybe<Scalars['Boolean']>;
    /** Survey Compound Question Label */
    label: LocalizedStringInput;
};

export type SurveyDefContent = {
    __typename?: 'SurveyDefContent';
    articleId?: Maybe<Scalars['ObjectId']>;
    articleName?: Maybe<Scalars['String']>;
};

export type SurveyDefContentInput = {
    articleId?: InputMaybe<Scalars['ObjectId']>;
};

export type SurveyDefDivision = {
    __typename?: 'SurveyDefDivision';
    conditionExpressionIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
    conditionExpressions?: Maybe<Array<Maybe<ConfigExpression>>>;
    id: Scalars['String'];
    /** Survey Division Label */
    label: LocalizedString;
    sections: Array<SurveyDefSection>;
    subSurveyDef?: Maybe<SurveyDef>;
    subSurveyDefId?: Maybe<Scalars['ObjectId']>;
    summary?: Maybe<Scalars['Boolean']>;
};

export type SurveyDefDivisionInput = {
    conditionExpressionIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    id: Scalars['String'];
    /** Survey Division Label */
    label: LocalizedStringInput;
    sections: Array<SurveyDefSectionInput>;
    subSurveyDefId?: InputMaybe<Scalars['ObjectId']>;
    summary?: InputMaybe<Scalars['Boolean']>;
};

export type SurveyDefFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<SurveyDefUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type SurveyDefInput = {
    buttons: SurveyDefButtonsInput;
    disableRestart?: InputMaybe<Scalars['Boolean']>;
    divisions: Array<SurveyDefDivisionInput>;
    icon?: InputMaybe<ContentIcon>;
    imageContentId?: InputMaybe<Scalars['ObjectId']>;
    /** Survey Label */
    label: LocalizedStringInput;
    name?: InputMaybe<Scalars['String']>;
    organizationId: Scalars['ObjectId'];
    requiresAdvocateReview?: InputMaybe<Scalars['Boolean']>;
    restartFromBeginning?: InputMaybe<Scalars['Boolean']>;
    shouldScore?: InputMaybe<Scalars['Boolean']>;
    summary?: InputMaybe<Scalars['Boolean']>;
    summaryText: SurveyDefTextInput;
    surveyType: Scalars['String'];
};

export type SurveyDefOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type SurveyDefPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type SurveyDefPaginationQueryResponse = PaginationResponse & {
    __typename?: 'SurveyDefPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<SurveyDef>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type SurveyDefPiece = {
    __typename?: 'SurveyDefPiece';
    conditionExpressionIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
    conditionExpressions?: Maybe<Array<Maybe<ConfigExpression>>>;
    content?: Maybe<SurveyDefContent>;
    id: Scalars['String'];
    question?: Maybe<SurveyDefQuestion>;
    surveyDefCompoundQuestion?: Maybe<SurveyDefCompoundQuestion>;
    text?: Maybe<SurveyDefText>;
    tutorialContent?: Maybe<SurveyDefTutorialContent>;
    type: SurveyDefPieceType;
};

export type SurveyDefPieceInput = {
    conditionExpressionIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    content?: InputMaybe<SurveyDefContentInput>;
    id: Scalars['String'];
    question?: InputMaybe<SurveyDefQuestionInput>;
    surveyDefCompoundQuestion?: InputMaybe<SurveyDefCompoundQuestionInput>;
    text?: InputMaybe<SurveyDefTextInput>;
    tutorialContent?: InputMaybe<SurveyDefTutorialContentInput>;
    type: SurveyDefPieceType;
};

/** The type of piece stored in the survey section. */
export enum SurveyDefPieceType {
    CompoundQuestion = 'compoundQuestion',
    Content = 'content',
    Question = 'question',
    Text = 'text',
    TutorialContent = 'tutorialContent',
}

export type SurveyDefQueryInput = {
    filter?: InputMaybe<SurveyDefFilterInput>;
    orderBy?: InputMaybe<SurveyDefOrderByInput>;
    pagination?: InputMaybe<SurveyDefPaginationInput>;
};

export type SurveyDefQuestion = {
    __typename?: 'SurveyDefQuestion';
    answerRequired?: Maybe<Scalars['Boolean']>;
    choiceOverrides?: Maybe<Array<Maybe<SurveyDefChoiceOverride>>>;
    displayType: SurveyDefQuestionDisplayType;
    hidePreviousAnswer?: Maybe<Scalars['Boolean']>;
    /** Quest Label Override */
    labelOverride?: Maybe<LocalizedStringLoose>;
    questionProfileDef?: Maybe<UserProfileDef>;
    questionProfileDefId: Scalars['ObjectId'];
};

/** The visual representation of the question. */
export enum SurveyDefQuestionDisplayType {
    Avatar = 'avatar',
    BabyWeight = 'babyWeight',
    ButtonGroup = 'buttonGroup',
    CheckBox = 'checkBox',
    CheckBoxes = 'checkBoxes',
    Date = 'date',
    Date13YearsInThePast = 'date13YearsInThePast',
    Date18YearsInThePast = 'date18YearsInThePast',
    DateInTheFuture = 'dateInTheFuture',
    DateInThePast = 'dateInThePast',
    DateTime = 'dateTime',
    DropDown = 'dropDown',
    DropDownMultipleSelect = 'dropDownMultipleSelect',
    DueDate = 'dueDate',
    Email = 'email',
    Height = 'height',
    LineInput = 'lineInput',
    Numeric = 'numeric',
    PhoneNumber = 'phoneNumber',
    RadioButtons = 'radioButtons',
    TextInput = 'textInput',
    Toggle = 'toggle',
    Weight = 'weight',
    Year = 'year',
}

export type SurveyDefQuestionInput = {
    answerRequired?: InputMaybe<Scalars['Boolean']>;
    choiceOverrides?: InputMaybe<Array<InputMaybe<SurveyDefChoiceOverrideInput>>>;
    displayType: SurveyDefQuestionDisplayType;
    hidePreviousAnswer?: InputMaybe<Scalars['Boolean']>;
    /** Quest Label Override */
    labelOverride?: InputMaybe<LocalizedStringLooseInput>;
    questionProfileDefId: Scalars['ObjectId'];
};

export type SurveyDefSection = {
    __typename?: 'SurveyDefSection';
    conditionExpressionIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
    conditionExpressions?: Maybe<Array<Maybe<ConfigExpression>>>;
    id: Scalars['String'];
    /** Survey Section Label */
    label: LocalizedString;
    pieces: Array<SurveyDefPiece>;
};

export type SurveyDefSectionInput = {
    conditionExpressionIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    id: Scalars['String'];
    /** Survey Section Label */
    label: LocalizedStringInput;
    pieces: Array<SurveyDefPieceInput>;
};

export type SurveyDefText = {
    __typename?: 'SurveyDefText';
    /** Survey Piece Body Text */
    body: LocalizedString;
    /** Survey Piece Title */
    title: LocalizedString;
};

export type SurveyDefTextInput = {
    /** Survey Piece Body Text */
    body: LocalizedStringInput;
    /** Survey Piece Title */
    title: LocalizedStringInput;
};

export type SurveyDefTutorialContent = {
    __typename?: 'SurveyDefTutorialContent';
    videoContent?: Maybe<SimpleContent>;
    videoId?: Maybe<Scalars['ObjectId']>;
    videoName?: Maybe<Scalars['String']>;
};

export type SurveyDefTutorialContentInput = {
    videoId?: InputMaybe<Scalars['ObjectId']>;
};

export type SurveyDefUpdateInput = {
    buttons?: InputMaybe<SurveyDefButtonsInput>;
    completedAtProfileDefId?: InputMaybe<Scalars['ObjectId']>;
    disableRestart?: InputMaybe<Scalars['Boolean']>;
    divisions?: InputMaybe<Array<InputMaybe<SurveyDefDivisionInput>>>;
    icon?: InputMaybe<ContentIcon>;
    imageContentId?: InputMaybe<Scalars['ObjectId']>;
    label?: InputMaybe<LocalizedStringInput>;
    name?: InputMaybe<Scalars['String']>;
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    requiresAdvocateReview?: InputMaybe<Scalars['Boolean']>;
    restartFromBeginning?: InputMaybe<Scalars['Boolean']>;
    scoreProfileDefId?: InputMaybe<Scalars['ObjectId']>;
    shouldScore?: InputMaybe<Scalars['Boolean']>;
    startedAtProfileDefId?: InputMaybe<Scalars['ObjectId']>;
    statusProfileDefId?: InputMaybe<Scalars['ObjectId']>;
    summary?: InputMaybe<Scalars['Boolean']>;
    summaryText?: InputMaybe<SurveyDefTextInput>;
    surveyType?: InputMaybe<Scalars['String']>;
};

export type SurveyDefViewerResponse = {
    __typename?: 'SurveyDefViewerResponse';
    raw?: Maybe<Scalars['String']>;
};

export type SurveyPatientFilterInput = {
    dueDate?: InputMaybe<Scalars['String']>;
    firstName?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
};

export type SurveyResponse = BaseEntity & {
    __typename?: 'SurveyResponse';
    answers: Array<SurveyResponseAnswer>;
    clientSurveyId?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    displayName?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    isCoreSurvey?: Maybe<Scalars['Boolean']>;
    isTestData?: Maybe<Scalars['Boolean']>;
    patient?: Maybe<Patient>;
    patientId?: Maybe<Scalars['String']>;
    practice?: Maybe<Organization>;
    responseDate?: Maybe<Scalars['DateTime']>;
    reviewed?: Maybe<Scalars['Boolean']>;
    surveyScore?: Maybe<Scalars['Float']>;
    surveyType?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
};

export type SurveyResponseAnswer = {
    __typename?: 'SurveyResponseAnswer';
    answerId?: Maybe<Scalars['JSON']>;
    answerText?: Maybe<Scalars['JSON']>;
    choiceId?: Maybe<Scalars['ObjectId']>;
    choiceIdList?: Maybe<Array<Scalars['ObjectId']>>;
    clientQuestionId?: Maybe<Scalars['String']>;
    pieceId?: Maybe<Scalars['ObjectId']>;
    questionId?: Maybe<Scalars['String']>;
    questionOrder?: Maybe<Scalars['Float']>;
    questionText?: Maybe<Scalars['String']>;
    questionTypeId?: Maybe<Scalars['String']>;
    responseScore?: Maybe<Scalars['Float']>;
    subsectionName?: Maybe<Scalars['String']>;
};

export type SurveyResponseAnswerInput = {
    answerId?: InputMaybe<Scalars['JSON']>;
    answerText?: InputMaybe<Scalars['JSON']>;
    choiceId?: InputMaybe<Scalars['ObjectId']>;
    choiceIdList?: InputMaybe<Array<Scalars['ObjectId']>>;
    clientQuestionId?: InputMaybe<Scalars['String']>;
    pieceId?: InputMaybe<Scalars['ObjectId']>;
    questionId?: InputMaybe<Scalars['String']>;
    questionOrder?: InputMaybe<Scalars['Float']>;
    questionText?: InputMaybe<Scalars['String']>;
    questionTypeId?: InputMaybe<Scalars['String']>;
    responseScore?: InputMaybe<Scalars['Float']>;
    subsectionName?: InputMaybe<Scalars['String']>;
};

export type SurveyResponseFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<SurveyResponseUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type SurveyResponseInput = {
    answers: Array<SurveyResponseAnswerInput>;
    appBundleId: Scalars['String'];
    clientSurveyId?: InputMaybe<Scalars['String']>;
    displayName?: InputMaybe<Scalars['String']>;
    isCoreSurvey?: InputMaybe<Scalars['Boolean']>;
    patientId: Scalars['ObjectId'];
    practiceId: Scalars['ObjectId'];
    responseDate?: InputMaybe<Scalars['DateTime']>;
    reviewed?: InputMaybe<Scalars['Boolean']>;
    surveyScore?: InputMaybe<Scalars['Float']>;
    surveyType?: InputMaybe<Scalars['String']>;
};

export type SurveyResponseOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type SurveyResponsePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type SurveyResponsePaginationQueryResponse = PaginationResponse & {
    __typename?: 'SurveyResponsePaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<SurveyResponse>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type SurveyResponseQueryInput = {
    filter?: InputMaybe<SurveyResponseFilterInput>;
    orderBy?: InputMaybe<SurveyResponseOrderByInput>;
    pagination?: InputMaybe<SurveyResponsePaginationInput>;
};

export type SurveyResponseUpdateInput = {
    answers?: InputMaybe<Array<InputMaybe<SurveyResponseAnswerInput>>>;
    clientSurveyId?: InputMaybe<Scalars['String']>;
    displayName?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    practiceId?: InputMaybe<Scalars['ObjectId']>;
    responseDate?: InputMaybe<Scalars['DateTime']>;
    reviewed?: InputMaybe<Scalars['Boolean']>;
    surveyScore?: InputMaybe<Scalars['Float']>;
    surveyType?: InputMaybe<Scalars['String']>;
};

export type SydneyProviderData = {
    __typename?: 'SydneyProviderData';
    dob: Scalars['String'];
    email?: Maybe<Scalars['String']>;
    firstName: Scalars['String'];
    hcid: Scalars['String'];
    lastName: Scalars['String'];
};

export type SydneyProviderDataInput = {
    dob: Scalars['String'];
    email?: InputMaybe<Scalars['String']>;
    firstName: Scalars['String'];
    hcid: Scalars['String'];
    lastName: Scalars['String'];
};

export type SystemToken = BaseEntity & {
    __typename?: 'SystemToken';
    createdAt: Scalars['DateTime'];
    description: Scalars['String'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    techNotes?: Maybe<Scalars['String']>;
    type: SystemTokenType;
    updatedAt: Scalars['DateTime'];
};

export type SystemTokenFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<SystemTokenUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type SystemTokenInput = {
    description: Scalars['String'];
    name: Scalars['String'];
    techNotes?: InputMaybe<Scalars['String']>;
    type: SystemTokenType;
};

export type SystemTokenOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type SystemTokenPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type SystemTokenPaginationQueryResponse = PaginationResponse & {
    __typename?: 'SystemTokenPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<SystemToken>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type SystemTokenQueryInput = {
    filter?: InputMaybe<SystemTokenFilterInput>;
    orderBy?: InputMaybe<SystemTokenOrderByInput>;
    pagination?: InputMaybe<SystemTokenPaginationInput>;
};

/** System Token Type. */
export enum SystemTokenType {
    EmailLogo = 'emailLogo',
    HaName = 'haName',
    Unsubscribe = 'unsubscribe',
}

export type SystemTokenUpdateInput = {
    description?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    techNotes?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<SystemTokenType>;
};

export type Tag = BaseEntity & {
    __typename?: 'Tag';
    appInfo?: Maybe<Scalars['String']>;
    /** For Triggers, only process interventions if a profile value was changed */
    changedProfileDefIds?: Maybe<Array<Scalars['ObjectId']>>;
    createdAt: Scalars['DateTime'];
    excludeApps?: Maybe<Array<Scalars['String']>>;
    expression?: Maybe<ConfigExpression>;
    expressionId?: Maybe<Scalars['ObjectId']>;
    id: Scalars['ObjectId'];
    includeApps?: Maybe<Array<Scalars['String']>>;
    isPatientFilter?: Maybe<Scalars['Boolean']>;
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    patientRiskScorePoints?: Maybe<Scalars['Float']>;
    updatedAt: Scalars['DateTime'];
    usageType: TagUsageType;
};

export type TagFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<TagUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type TagInput = {
    /** For Triggers, only process interventions if a profile value was changed */
    changedProfileDefIds?: InputMaybe<Array<Scalars['ObjectId']>>;
    excludeApps?: InputMaybe<Array<Scalars['String']>>;
    expressionId?: InputMaybe<Scalars['ObjectId']>;
    includeApps?: InputMaybe<Array<Scalars['String']>>;
    isPatientFilter?: InputMaybe<Scalars['Boolean']>;
    name: Scalars['String'];
    patientRiskScorePoints?: InputMaybe<Scalars['Float']>;
    usageType: TagUsageType;
};

export type TagOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type TagPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type TagPaginationQueryResponse = PaginationResponse & {
    __typename?: 'TagPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Tag>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type TagQueryInput = {
    filter?: InputMaybe<TagFilterInput>;
    orderBy?: InputMaybe<TagOrderByInput>;
    pagination?: InputMaybe<TagPaginationInput>;
};

export type TagUpdateInput = {
    changedProfileDefIds?: InputMaybe<Array<Scalars['ObjectId']>>;
    excludeApps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    expressionId?: InputMaybe<Scalars['ObjectId']>;
    includeApps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    isPatientFilter?: InputMaybe<Scalars['Boolean']>;
    name?: InputMaybe<Scalars['String']>;
    patientRiskScorePoints?: InputMaybe<Scalars['Float']>;
    usageType?: InputMaybe<TagUsageType>;
};

/** How the tag will be used. */
export enum TagUsageType {
    Filtering = 'filtering',
    PatientRiskScore = 'patientRiskScore',
    Trigger = 'trigger',
}

export type TagWhereUsedFetchInput = {
    id?: InputMaybe<Scalars['String']>;
    isTrigger?: InputMaybe<Scalars['Boolean']>;
};

export type TaggedImageInput = {
    imageContentId: Scalars['ObjectId'];
    imageName: Scalars['String'];
    tagId: Scalars['ObjectId'];
    tagName: Scalars['String'];
};

export type TaggedImageShared = {
    __typename?: 'TaggedImageShared';
    imageContentId: Scalars['ObjectId'];
    imageName: Scalars['String'];
    tagId: Scalars['ObjectId'];
    tagName: Scalars['String'];
};

/** Target for Permission */
export enum Target {
    AddPatient = 'AddPatient',
    AdminTimelineViewer = 'AdminTimelineViewer',
    AdvocateTaskList = 'AdvocateTaskList',
    AdvocateTaskTemplates = 'AdvocateTaskTemplates',
    AffiliateCareClaimsSubmission = 'AffiliateCareClaimsSubmission',
    AffiliateCareInvoices = 'AffiliateCareInvoices',
    AffiliateClaims = 'AffiliateClaims',
    AffiliateInvoices = 'AffiliateInvoices',
    AppDefaults = 'AppDefaults',
    AppEvents = 'AppEvents',
    Appointments = 'Appointments',
    Apps = 'Apps',
    ArticleApproval = 'ArticleApproval',
    ArticleCategory = 'ArticleCategory',
    ArticleGroup = 'ArticleGroup',
    ArticlePromotions = 'ArticlePromotions',
    Articles = 'Articles',
    Calendars = 'Calendars',
    CarePlans = 'CarePlans',
    CareTeamContacts = 'CareTeamContacts',
    CareTeamMemberTypes = 'CareTeamMemberTypes',
    CareTeamMembers = 'CareTeamMembers',
    CareTeams = 'CareTeams',
    CompoundQuestions = 'CompoundQuestions',
    ConditionTypes = 'ConditionTypes',
    ContentDirectory = 'ContentDirectory',
    DateTriggers = 'DateTriggers',
    DeviceOrders = 'DeviceOrders',
    Devices = 'Devices',
    EligibilityMatch = 'EligibilityMatch',
    EmailTemplates = 'EmailTemplates',
    EventTriggers = 'EventTriggers',
    Expressions = 'Expressions',
    FrontDesk = 'FrontDesk',
    HaTasks = 'HaTasks',
    HealthAdvocate = 'HealthAdvocate',
    HealthAdvocateSupervisor = 'HealthAdvocateSupervisor',
    HealthPlans = 'HealthPlans',
    HgPatientAccess = 'HgPatientAccess',
    Highlights = 'Highlights',
    IsHealthAdvocate = 'IsHealthAdvocate',
    MassUpdate = 'MassUpdate',
    Measurements = 'Measurements',
    MessageCenter = 'MessageCenter',
    MessageCenterTemplates = 'MessageCenterTemplates',
    NoteTags = 'NoteTags',
    Orders = 'Orders',
    OrgConfig = 'OrgConfig',
    PatientActionTypes = 'PatientActionTypes',
    PatientActions = 'PatientActions',
    PatientAppEvents = 'PatientAppEvents',
    PatientArticleFeed = 'PatientArticleFeed',
    PatientAttachments = 'PatientAttachments',
    PatientCallLists = 'PatientCallLists',
    PatientCareTeam = 'PatientCareTeam',
    PatientChart = 'PatientChart',
    PatientClinicalSummaries = 'PatientClinicalSummaries',
    PatientDetails = 'PatientDetails',
    PatientHighlights = 'PatientHighlights',
    PatientList = 'PatientList',
    PatientNotes = 'PatientNotes',
    PatientProfileVariables = 'PatientProfileVariables',
    PatientQuestions = 'PatientQuestions',
    PatientRiskIndicators = 'PatientRiskIndicators',
    PatientRiskScore = 'PatientRiskScore',
    PatientTags = 'PatientTags',
    PatientTodos = 'PatientTodos',
    PatientWhatsNew = 'PatientWhatsNew',
    Practices = 'Practices',
    ProfileDefs = 'ProfileDefs',
    ProfileScreens = 'ProfileScreens',
    ProfileValueJobs = 'ProfileValueJobs',
    ProfileValueOrigin = 'ProfileValueOrigin',
    Providers = 'Providers',
    ReferenceLinkCategories = 'ReferenceLinkCategories',
    ReferenceLinks = 'ReferenceLinks',
    ReportingCategory = 'ReportingCategory',
    Roles = 'Roles',
    SimpleContent = 'SimpleContent',
    Sla = 'Sla',
    SlaTimer = 'SlaTimer',
    Staff = 'Staff',
    SurveyDefs = 'SurveyDefs',
    SurveyResponses = 'SurveyResponses',
    Tags = 'Tags',
    Tokens = 'Tokens',
    TrendTriggers = 'TrendTriggers',
    Triggers = 'Triggers',
    VirtualCareAffiliates = 'VirtualCareAffiliates',
    VirtualCareClaims = 'VirtualCareClaims',
    VirtualCareEligibilityWhitelist = 'VirtualCareEligibilityWhitelist',
    VirtualCarePatients = 'VirtualCarePatients',
    VirtualCareZipCodeMap = 'VirtualCareZipCodeMap',
    WhatsNewInstances = 'WhatsNewInstances',
    WhatsNewTemplates = 'WhatsNewTemplates',
    WhatsNewTimelines = 'WhatsNewTimelines',
}

export type TestAuthorization = BaseEntity & {
    __typename?: 'TestAuthorization';
    canMutateRoleApp?: Maybe<Scalars['Boolean']>;
    canMutateRoleService?: Maybe<Scalars['Boolean']>;
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    isVisibleAuthorizedDecoratorEmpty?: Maybe<Scalars['Boolean']>;
    isVisibleNoAuthorizedDecorator?: Maybe<Scalars['Boolean']>;
    isVisibleToMultipleRoles?: Maybe<Scalars['Boolean']>;
    isVisibleToRoleApp?: Maybe<Scalars['Boolean']>;
    isVisibleToRoleService?: Maybe<Scalars['Boolean']>;
    nestedMultipleRoles?: Maybe<NestedClass>;
    nestedNoAuthorizedDecorator?: Maybe<NestedClass>;
    nestedRoleAppAuthorized?: Maybe<NestedClass>;
    nestedRoleServiceAuthorized?: Maybe<NestedClass>;
    testFieldResolverAuthorizationRoleApp?: Maybe<Scalars['Boolean']>;
    testFieldResolverAuthorizationRoleService?: Maybe<Scalars['Boolean']>;
    updatedAt: Scalars['DateTime'];
};

export type TestUserResponseData = {
    __typename?: 'TestUserResponseData';
    appUserId: Scalars['String'];
    familyId: Scalars['String'];
    password: Scalars['String'];
    patientId: Scalars['String'];
};

export type TextOptOutInput = {
    appBundleId: Scalars['String'];
    phoneNumber: Scalars['String'];
};

export type TextOptOutMutationResponse = MutationResponse & {
    __typename?: 'TextOptOutMutationResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

/** Unit of Time. */
export enum TimeUnit {
    Days = 'Days',
    Months = 'Months',
    Years = 'Years',
}

/** Type timeline viewer entry. */
export enum TimelineViewerEntryType {
    Hatask = 'hatask',
    Highlight = 'highlight',
    Promotion = 'promotion',
    Todo = 'todo',
}

export type TimelineViewerFetchData = {
    __typename?: 'TimelineViewerFetchData';
    contentDescription?: Maybe<Scalars['String']>;
    duration?: Maybe<Scalars['Float']>;
    durationTimeUnit?: Maybe<Scalars['String']>;
    effectiveDate?: Maybe<Scalars['DateTime']>;
    excludeApps?: Maybe<Array<Maybe<Scalars['String']>>>;
    expiryDate?: Maybe<Scalars['DateTime']>;
    groupId?: Maybe<Scalars['ObjectId']>;
    includeApps?: Maybe<Array<Maybe<Scalars['String']>>>;
    itemId?: Maybe<Scalars['ObjectId']>;
    notificationText?: Maybe<Scalars['String']>;
    offsetDays?: Maybe<Scalars['Float']>;
    offsetMonths?: Maybe<Scalars['Float']>;
    offsetSequence?: Maybe<Scalars['Float']>;
    offsetYears?: Maybe<Scalars['Float']>;
    priority?: Maybe<Scalars['Float']>;
    tags?: Maybe<Array<Maybe<Scalars['String']>>>;
    templateId?: Maybe<Scalars['ObjectId']>;
    timelineId?: Maybe<Scalars['ObjectId']>;
    type?: Maybe<TimelineViewerEntryType>;
};

export type TimelineViewerFetchInput = {
    appBundleId?: InputMaybe<Scalars['String']>;
    limit?: InputMaybe<Scalars['Float']>;
    tags?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    timelines?: InputMaybe<Array<InputMaybe<TimelineViewerFetchInputTimeline>>>;
    types?: InputMaybe<Array<InputMaybe<TimelineViewerEntryType>>>;
};

export type TimelineViewerFetchInputTimeline = {
    offset?: InputMaybe<Scalars['Float']>;
    timeline?: InputMaybe<Scalars['ObjectId']>;
    zeroDate?: InputMaybe<Scalars['DateTime']>;
};

export type TimelineViewerFetchResponse = {
    __typename?: 'TimelineViewerFetchResponse';
    results: Array<TimelineViewerFetchData>;
    total: Scalars['Float'];
};

export type TodoCreateInput = {
    createdBy: Scalars['String'];
    description?: InputMaybe<LocalizedStringInput>;
    dueDate?: InputMaybe<Scalars['String']>;
    effectiveDate?: InputMaybe<Scalars['String']>;
    isComplete?: InputMaybe<Scalars['Boolean']>;
    isEssential?: InputMaybe<Scalars['Boolean']>;
    patientId: Scalars['ObjectId'];
    priority?: InputMaybe<Scalars['Float']>;
    repeatType?: InputMaybe<Scalars['String']>;
    title: LocalizedStringInput;
};

export type TodoDeleteInput = {
    id: Scalars['ObjectId'];
};

export type TodoTask = BaseEntity & {
    __typename?: 'TodoTask';
    appUserId: Scalars['ObjectId'];
    articleId?: Maybe<Scalars['ObjectId']>;
    carePlanId?: Maybe<Scalars['ObjectId']>;
    childName?: Maybe<Scalars['String']>;
    content?: Maybe<TodoTaskContent>;
    createdAt: Scalars['DateTime'];
    createdBy: TodoTaskCreatedByType;
    dateCompleted?: Maybe<Scalars['DateTime']>;
    description?: Maybe<LocalizedString>;
    dueDate?: Maybe<Scalars['DateTime']>;
    effectiveDate: Scalars['DateTime'];
    groupId?: Maybe<Scalars['ObjectId']>;
    id: Scalars['ObjectId'];
    isComplete: Scalars['Boolean'];
    isEssential: Scalars['Boolean'];
    isTestData?: Maybe<Scalars['Boolean']>;
    organizationId?: Maybe<Scalars['ObjectId']>;
    priority: Scalars['Float'];
    repeatType?: Maybe<TodoTaskRepeatType>;
    templateId?: Maybe<Scalars['ObjectId']>;
    title: LocalizedString;
    updatedAt: Scalars['DateTime'];
};

export type TodoTaskContent = {
    __typename?: 'TodoTaskContent';
    body?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

/** Todo task created by type */
export enum TodoTaskCreatedByType {
    Advocate = 'advocate',
    AppUser = 'appUser',
    System = 'system',
}

export type TodoTaskFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<TodoTaskUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type TodoTaskInput = {
    appUserId: Scalars['ObjectId'];
    articleId?: InputMaybe<Scalars['ObjectId']>;
    carePlanId?: InputMaybe<Scalars['ObjectId']>;
    createdBy: TodoTaskCreatedByType;
    dateCompleted?: InputMaybe<Scalars['DateTime']>;
    description?: InputMaybe<LocalizedStringInput>;
    dueDate?: InputMaybe<Scalars['DateTime']>;
    effectiveDate: Scalars['DateTime'];
    groupId?: InputMaybe<Scalars['ObjectId']>;
    isComplete: Scalars['Boolean'];
    isEssential: Scalars['Boolean'];
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    priority: Scalars['Float'];
    repeatType?: InputMaybe<TodoTaskRepeatType>;
    templateId?: InputMaybe<Scalars['ObjectId']>;
    title: LocalizedStringInput;
};

export type TodoTaskNotification = {
    __typename?: 'TodoTaskNotification';
    appUserId?: Maybe<Scalars['String']>;
    notifications?: Maybe<Array<Maybe<TodoTaskNotificationType>>>;
};

export type TodoTaskNotificationType = {
    __typename?: 'TodoTaskNotificationType';
    text?: Maybe<Scalars['String']>;
    todoId?: Maybe<Scalars['String']>;
};

export type TodoTaskOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type TodoTaskPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type TodoTaskPaginationQueryResponse = PaginationResponse & {
    __typename?: 'TodoTaskPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<TodoTask>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type TodoTaskQueryInput = {
    filter?: InputMaybe<TodoTaskFilterInput>;
    orderBy?: InputMaybe<TodoTaskOrderByInput>;
    pagination?: InputMaybe<TodoTaskPaginationInput>;
};

/** ToDos repeating type */
export enum TodoTaskRepeatType {
    Daily = 'DAILY',
    Monthly = 'MONTHLY',
    OneTime = 'ONE_TIME',
    Weekly = 'WEEKLY',
}

export type TodoTaskUpdateInput = {
    appUserId?: InputMaybe<Scalars['ObjectId']>;
    articleId?: InputMaybe<Scalars['ObjectId']>;
    carePlanId?: InputMaybe<Scalars['ObjectId']>;
    createdBy?: InputMaybe<TodoTaskCreatedByType>;
    dateCompleted?: InputMaybe<Scalars['DateTime']>;
    description?: InputMaybe<LocalizedStringInput>;
    dueDate?: InputMaybe<Scalars['DateTime']>;
    effectiveDate?: InputMaybe<Scalars['DateTime']>;
    groupId?: InputMaybe<Scalars['ObjectId']>;
    isComplete?: InputMaybe<Scalars['Boolean']>;
    isEssential?: InputMaybe<Scalars['Boolean']>;
    priority?: InputMaybe<Scalars['Float']>;
    templateId?: InputMaybe<Scalars['ObjectId']>;
    title?: InputMaybe<LocalizedStringInput>;
};

export type TodoTasksByCarePlanAndGroupInput = {
    appUserId?: InputMaybe<Scalars['String']>;
    carePlanId: Scalars['ObjectId'];
    currentDate: Scalars['DateTime'];
    groupId?: InputMaybe<Scalars['ObjectId']>;
    isComplete: Scalars['Boolean'];
};

export type TodoTasksEffectiveInput = {
    appUserId?: InputMaybe<Scalars['String']>;
    currentDate: Scalars['DateTime'];
};

export type TodoUpdateInput = {
    description?: InputMaybe<LocalizedStringInput>;
    dueDate?: InputMaybe<Scalars['String']>;
    effectiveDate?: InputMaybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    isComplete?: InputMaybe<Scalars['Boolean']>;
    isEssential?: InputMaybe<Scalars['Boolean']>;
    priority?: InputMaybe<Scalars['Float']>;
    title?: InputMaybe<LocalizedStringInput>;
};

export type Token = BaseEntity & {
    __typename?: 'Token';
    createdAt: Scalars['DateTime'];
    description?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    replacements: Array<Scalars['JSON']>;
    updatedAt: Scalars['DateTime'];
};

export type TokenFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<TokenUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type TokenInput = {
    description?: InputMaybe<Scalars['String']>;
    name: Scalars['String'];
    replacements: Array<Scalars['JSON']>;
};

export type TokenOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type TokenPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type TokenPaginationQueryResponse = PaginationResponse & {
    __typename?: 'TokenPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Token>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type TokenQueryInput = {
    filter?: InputMaybe<TokenFilterInput>;
    orderBy?: InputMaybe<TokenOrderByInput>;
    pagination?: InputMaybe<TokenPaginationInput>;
};

export type TokenUpdateInput = {
    description?: InputMaybe<Scalars['String']>;
    name: Scalars['String'];
    replacements: Array<Scalars['JSON']>;
};

export type Tracker = BaseEntity & {
    __typename?: 'Tracker';
    activeSymptoms?: Maybe<Array<Scalars['String']>>;
    activityOption?: Maybe<Scalars['String']>;
    alexaData?: Maybe<AlexaDataType>;
    bmi?: Maybe<Scalars['Float']>;
    breastFeeding?: Maybe<BreastFeedingType>;
    circumference?: Maybe<Scalars['Float']>;
    color?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    createdBy?: Maybe<Scalars['String']>;
    currentWeight?: Maybe<Scalars['Float']>;
    cycleLength?: Maybe<CycleLengthType>;
    date?: Maybe<Scalars['DateTime']>;
    dateTime?: Maybe<Scalars['DateTime']>;
    diaperContents?: Maybe<Scalars['String']>;
    diastolicBP?: Maybe<Scalars['Float']>;
    endDate?: Maybe<Scalars['DateTime']>;
    glucose?: Maybe<Scalars['Float']>;
    hasMultipleBabies?: Maybe<Scalars['Boolean']>;
    height?: Maybe<Scalars['Float']>;
    id: Scalars['ObjectId'];
    isBluetoothRelated?: Maybe<Scalars['Boolean']>;
    isCalculated?: Maybe<Scalars['Boolean']>;
    isHealthKitRelated?: Maybe<Scalars['Boolean']>;
    isMigrated?: Maybe<Scalars['Boolean']>;
    isOnboardingFinished?: Maybe<Scalars['Boolean']>;
    isOptedOut?: Maybe<Scalars['Boolean']>;
    isTestData?: Maybe<Scalars['Boolean']>;
    isWeightSwitcherChecked?: Maybe<Scalars['Boolean']>;
    length?: Maybe<Scalars['Float']>;
    loggerVersion: Scalars['String'];
    meal?: Maybe<Scalars['String']>;
    mealOption?: Maybe<Scalars['String']>;
    mealTime?: Maybe<Scalars['DateTime']>;
    moodLevel?: Maybe<Scalars['Float']>;
    movement?: Maybe<Scalars['String']>;
    name?: Maybe<NameType>;
    note?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    organizationId?: Maybe<Scalars['ObjectId']>;
    ovulationTest?: Maybe<Scalars['String']>;
    painLevel?: Maybe<Scalars['Float']>;
    patientId: Scalars['ObjectId'];
    periodDate?: Maybe<PeriodDateType>;
    periodLength?: Maybe<PeriodLengthType>;
    prePregnancyWeight?: Maybe<Scalars['Float']>;
    pulse?: Maybe<Scalars['Float']>;
    reviewed?: Maybe<Scalars['Boolean']>;
    sexualActivity?: Maybe<Scalars['String']>;
    sleepEnd?: Maybe<Scalars['String']>;
    sleepStart?: Maybe<Scalars['String']>;
    softly_deleted?: Maybe<Scalars['Boolean']>;
    stack?: Maybe<Scalars['Float']>;
    startDate?: Maybe<Scalars['DateTime']>;
    steps?: Maybe<Scalars['Float']>;
    symptomsOption?: Maybe<Scalars['String']>;
    systolicBP?: Maybe<Scalars['Float']>;
    takenDate?: Maybe<Scalars['DateTime']>;
    temperature?: Maybe<Scalars['Float']>;
    timestamp: Scalars['String'];
    timezoneName?: Maybe<Scalars['String']>;
    timezoneOffset?: Maybe<Scalars['Float']>;
    totalKicks?: Maybe<Scalars['Float']>;
    totalTime?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
    uuid: Scalars['String'];
    value?: Maybe<Scalars['Float']>;
    volumeFeeding?: Maybe<VolumeFeedingType>;
    weight?: Maybe<Scalars['Float']>;
    weightGainHighEnd?: Maybe<Scalars['Float']>;
    weightGainLowEnd?: Maybe<Scalars['Float']>;
};

export type TrackerFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<TrackerUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type TrackerHistory = BaseEntity & {
    __typename?: 'TrackerHistory';
    activeSymptoms?: Maybe<Array<Scalars['String']>>;
    activityOption?: Maybe<Scalars['String']>;
    alexaData?: Maybe<AlexaDataType>;
    bmi?: Maybe<Scalars['Float']>;
    breastFeeding?: Maybe<BreastFeedingType>;
    circumference?: Maybe<Scalars['Float']>;
    color?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    createdBy?: Maybe<Scalars['String']>;
    currentWeight?: Maybe<Scalars['Float']>;
    cycleLength?: Maybe<CycleLengthType>;
    date?: Maybe<Scalars['DateTime']>;
    dateTime?: Maybe<Scalars['DateTime']>;
    diaperContents?: Maybe<Scalars['String']>;
    diastolicBP?: Maybe<Scalars['Float']>;
    endDate?: Maybe<Scalars['DateTime']>;
    glucose?: Maybe<Scalars['Float']>;
    hasMultipleBabies?: Maybe<Scalars['Boolean']>;
    height?: Maybe<Scalars['Float']>;
    id: Scalars['ObjectId'];
    isBluetoothRelated?: Maybe<Scalars['Boolean']>;
    isCalculated?: Maybe<Scalars['Boolean']>;
    isHealthKitRelated?: Maybe<Scalars['Boolean']>;
    isMigrated?: Maybe<Scalars['Boolean']>;
    isOnboardingFinished?: Maybe<Scalars['Boolean']>;
    isOptedOut?: Maybe<Scalars['Boolean']>;
    isTestData?: Maybe<Scalars['Boolean']>;
    isWeightSwitcherChecked?: Maybe<Scalars['Boolean']>;
    length?: Maybe<Scalars['Float']>;
    loggerVersion: Scalars['String'];
    meal?: Maybe<Scalars['String']>;
    mealOption?: Maybe<Scalars['String']>;
    mealTime?: Maybe<Scalars['DateTime']>;
    moodLevel?: Maybe<Scalars['Float']>;
    movement?: Maybe<Scalars['String']>;
    name?: Maybe<NameType>;
    note?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    organizationId?: Maybe<Scalars['ObjectId']>;
    ovulationTest?: Maybe<Scalars['String']>;
    painLevel?: Maybe<Scalars['Float']>;
    patientId: Scalars['ObjectId'];
    periodDate?: Maybe<PeriodDateType>;
    periodLength?: Maybe<PeriodLengthType>;
    prePregnancyWeight?: Maybe<Scalars['Float']>;
    pulse?: Maybe<Scalars['Float']>;
    reviewed?: Maybe<Scalars['Boolean']>;
    sexualActivity?: Maybe<Scalars['String']>;
    sleepEnd?: Maybe<Scalars['String']>;
    sleepStart?: Maybe<Scalars['String']>;
    softly_deleted?: Maybe<Scalars['Boolean']>;
    stack?: Maybe<Scalars['Float']>;
    startDate?: Maybe<Scalars['DateTime']>;
    steps?: Maybe<Scalars['Float']>;
    symptomsOption?: Maybe<Scalars['String']>;
    systolicBP?: Maybe<Scalars['Float']>;
    takenDate?: Maybe<Scalars['DateTime']>;
    temperature?: Maybe<Scalars['Float']>;
    timestamp: Scalars['String'];
    timezoneName?: Maybe<Scalars['String']>;
    timezoneOffset?: Maybe<Scalars['Float']>;
    totalKicks?: Maybe<Scalars['Float']>;
    totalTime?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
    uuid: Scalars['String'];
    value?: Maybe<Scalars['Float']>;
    volumeFeeding?: Maybe<VolumeFeedingType>;
    weight?: Maybe<Scalars['Float']>;
    weightGainHighEnd?: Maybe<Scalars['Float']>;
    weightGainLowEnd?: Maybe<Scalars['Float']>;
};

export type TrackerHistoryFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<TrackerHistoryUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type TrackerHistoryInput = {
    activeSymptoms?: InputMaybe<Array<Scalars['String']>>;
    activityOption?: InputMaybe<Scalars['String']>;
    alexaData?: InputMaybe<AlexaDataInput>;
    bmi?: InputMaybe<Scalars['Float']>;
    breastFeeding?: InputMaybe<BreastFeedingInput>;
    circumference?: InputMaybe<Scalars['Float']>;
    color?: InputMaybe<Scalars['String']>;
    createdBy?: InputMaybe<Scalars['String']>;
    currentWeight?: InputMaybe<Scalars['Float']>;
    cycleLength?: InputMaybe<CycleLengthInput>;
    date?: InputMaybe<Scalars['DateTime']>;
    dateTime?: InputMaybe<Scalars['DateTime']>;
    diaperContents?: InputMaybe<Scalars['String']>;
    diastolicBP?: InputMaybe<Scalars['Float']>;
    endDate?: InputMaybe<Scalars['DateTime']>;
    glucose?: InputMaybe<Scalars['Float']>;
    hasMultipleBabies?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Scalars['Float']>;
    isBluetoothRelated?: InputMaybe<Scalars['Boolean']>;
    isCalculated?: InputMaybe<Scalars['Boolean']>;
    isHealthKitRelated?: InputMaybe<Scalars['Boolean']>;
    isMigrated?: InputMaybe<Scalars['Boolean']>;
    isOnboardingFinished?: InputMaybe<Scalars['Boolean']>;
    isOptedOut?: InputMaybe<Scalars['Boolean']>;
    isWeightSwitcherChecked?: InputMaybe<Scalars['Boolean']>;
    length?: InputMaybe<Scalars['Float']>;
    loggerVersion: Scalars['String'];
    meal?: InputMaybe<Scalars['String']>;
    mealOption?: InputMaybe<Scalars['String']>;
    mealTime?: InputMaybe<Scalars['DateTime']>;
    moodLevel?: InputMaybe<Scalars['Float']>;
    movement?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<NameInput>;
    note?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    ovulationTest?: InputMaybe<Scalars['String']>;
    painLevel?: InputMaybe<Scalars['Float']>;
    patientId: Scalars['ObjectId'];
    periodDate?: InputMaybe<PeriodDateInput>;
    periodLength?: InputMaybe<PeriodLengthInput>;
    prePregnancyWeight?: InputMaybe<Scalars['Float']>;
    pulse?: InputMaybe<Scalars['Float']>;
    reviewed?: InputMaybe<Scalars['Boolean']>;
    sexualActivity?: InputMaybe<Scalars['String']>;
    sleepEnd?: InputMaybe<Scalars['String']>;
    sleepStart?: InputMaybe<Scalars['String']>;
    softly_deleted?: InputMaybe<Scalars['Boolean']>;
    stack?: InputMaybe<Scalars['Float']>;
    startDate?: InputMaybe<Scalars['DateTime']>;
    steps?: InputMaybe<Scalars['Float']>;
    symptomsOption?: InputMaybe<Scalars['String']>;
    systolicBP?: InputMaybe<Scalars['Float']>;
    takenDate?: InputMaybe<Scalars['DateTime']>;
    temperature?: InputMaybe<Scalars['Float']>;
    timestamp: Scalars['String'];
    timezoneName?: InputMaybe<Scalars['String']>;
    timezoneOffset?: InputMaybe<Scalars['Float']>;
    totalKicks?: InputMaybe<Scalars['Float']>;
    totalTime?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    uuid: Scalars['String'];
    value?: InputMaybe<Scalars['Float']>;
    volumeFeeding?: InputMaybe<VolumeFeedingInput>;
    weight?: InputMaybe<Scalars['Float']>;
    weightGainHighEnd?: InputMaybe<Scalars['Float']>;
    weightGainLowEnd?: InputMaybe<Scalars['Float']>;
};

export type TrackerHistoryOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type TrackerHistoryPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type TrackerHistoryPaginationQueryResponse = PaginationResponse & {
    __typename?: 'TrackerHistoryPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<TrackerHistory>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type TrackerHistoryQueryInput = {
    filter?: InputMaybe<TrackerHistoryFilterInput>;
    orderBy?: InputMaybe<TrackerHistoryOrderByInput>;
    pagination?: InputMaybe<TrackerHistoryPaginationInput>;
};

export type TrackerHistoryUpdateInput = {
    id?: InputMaybe<Scalars['ObjectId']>;
    keyId?: InputMaybe<Scalars['ObjectId']>;
};

export type TrackerInput = {
    activeSymptoms?: InputMaybe<Array<Scalars['String']>>;
    activityOption?: InputMaybe<Scalars['String']>;
    alexaData?: InputMaybe<AlexaDataInput>;
    bmi?: InputMaybe<Scalars['Float']>;
    breastFeeding?: InputMaybe<BreastFeedingInput>;
    circumference?: InputMaybe<Scalars['Float']>;
    color?: InputMaybe<Scalars['String']>;
    createdBy?: InputMaybe<Scalars['String']>;
    currentWeight?: InputMaybe<Scalars['Float']>;
    cycleLength?: InputMaybe<CycleLengthInput>;
    date?: InputMaybe<Scalars['DateTime']>;
    dateTime?: InputMaybe<Scalars['DateTime']>;
    diaperContents?: InputMaybe<Scalars['String']>;
    diastolicBP?: InputMaybe<Scalars['Float']>;
    endDate?: InputMaybe<Scalars['DateTime']>;
    glucose?: InputMaybe<Scalars['Float']>;
    hasMultipleBabies?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Scalars['Float']>;
    isBluetoothRelated?: InputMaybe<Scalars['Boolean']>;
    isCalculated?: InputMaybe<Scalars['Boolean']>;
    isHealthKitRelated?: InputMaybe<Scalars['Boolean']>;
    isMigrated?: InputMaybe<Scalars['Boolean']>;
    isOnboardingFinished?: InputMaybe<Scalars['Boolean']>;
    isOptedOut?: InputMaybe<Scalars['Boolean']>;
    isWeightSwitcherChecked?: InputMaybe<Scalars['Boolean']>;
    length?: InputMaybe<Scalars['Float']>;
    loggerVersion: Scalars['String'];
    meal?: InputMaybe<Scalars['String']>;
    mealOption?: InputMaybe<Scalars['String']>;
    mealTime?: InputMaybe<Scalars['DateTime']>;
    moodLevel?: InputMaybe<Scalars['Float']>;
    movement?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<NameInput>;
    note?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    ovulationTest?: InputMaybe<Scalars['String']>;
    painLevel?: InputMaybe<Scalars['Float']>;
    patientId: Scalars['ObjectId'];
    periodDate?: InputMaybe<PeriodDateInput>;
    periodLength?: InputMaybe<PeriodLengthInput>;
    prePregnancyWeight?: InputMaybe<Scalars['Float']>;
    pulse?: InputMaybe<Scalars['Float']>;
    reviewed?: InputMaybe<Scalars['Boolean']>;
    sexualActivity?: InputMaybe<Scalars['String']>;
    sleepEnd?: InputMaybe<Scalars['String']>;
    sleepStart?: InputMaybe<Scalars['String']>;
    softly_deleted?: InputMaybe<Scalars['Boolean']>;
    stack?: InputMaybe<Scalars['Float']>;
    startDate?: InputMaybe<Scalars['DateTime']>;
    steps?: InputMaybe<Scalars['Float']>;
    symptomsOption?: InputMaybe<Scalars['String']>;
    systolicBP?: InputMaybe<Scalars['Float']>;
    takenDate?: InputMaybe<Scalars['DateTime']>;
    temperature?: InputMaybe<Scalars['Float']>;
    timestamp: Scalars['String'];
    timezoneName?: InputMaybe<Scalars['String']>;
    timezoneOffset?: InputMaybe<Scalars['Float']>;
    totalKicks?: InputMaybe<Scalars['Float']>;
    totalTime?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    uuid: Scalars['String'];
    value?: InputMaybe<Scalars['Float']>;
    volumeFeeding?: InputMaybe<VolumeFeedingInput>;
    weight?: InputMaybe<Scalars['Float']>;
    weightGainHighEnd?: InputMaybe<Scalars['Float']>;
    weightGainLowEnd?: InputMaybe<Scalars['Float']>;
};

export type TrackerMutationResponse = MutationResponse & {
    __typename?: 'TrackerMutationResponse';
    message: Scalars['String'];
    resource?: Maybe<Tracker>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type TrackerOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type TrackerPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type TrackerPaginationQueryResponse = PaginationResponse & {
    __typename?: 'TrackerPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Tracker>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type TrackerQueryInput = {
    filter?: InputMaybe<TrackerFilterInput>;
    orderBy?: InputMaybe<TrackerOrderByInput>;
    pagination?: InputMaybe<TrackerPaginationInput>;
};

/** Tracker Type. */
export enum TrackerType {
    BabyBoost = 'BabyBoost',
    BloodPressure = 'BloodPressure',
    Daily = 'Daily',
    Diaper = 'Diaper',
    Feeding = 'Feeding',
    FetalMovement = 'FetalMovement',
    Glucose = 'Glucose',
    Growth = 'Growth',
    Height = 'Height',
    MoodBoost = 'MoodBoost',
    Ovulation = 'Ovulation',
    PregnancyWeight = 'PregnancyWeight',
    Vaccination = 'Vaccination',
    Weight = 'Weight',
}

export type TrackerUpdateInput = {
    activeSymptoms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    activityOption?: InputMaybe<Scalars['String']>;
    alexaData?: InputMaybe<AlexaDataInput>;
    bmi?: InputMaybe<Scalars['Float']>;
    breastFeeding?: InputMaybe<BreastFeedingInput>;
    circumference?: InputMaybe<Scalars['Float']>;
    color?: InputMaybe<Scalars['String']>;
    createdBy?: InputMaybe<Scalars['String']>;
    currentWeight?: InputMaybe<Scalars['Float']>;
    cycleLength?: InputMaybe<CycleLengthInput>;
    date?: InputMaybe<Scalars['DateTime']>;
    dateTime?: InputMaybe<Scalars['DateTime']>;
    diaperContents?: InputMaybe<Scalars['String']>;
    diastolicBP?: InputMaybe<Scalars['Float']>;
    endDate?: InputMaybe<Scalars['DateTime']>;
    familyId?: InputMaybe<Scalars['String']>;
    glucose?: InputMaybe<Scalars['Float']>;
    hasMultipleBabies?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Scalars['Float']>;
    isBluetoothRelated?: InputMaybe<Scalars['Boolean']>;
    isCalculated?: InputMaybe<Scalars['Boolean']>;
    isHealthKitRelated?: InputMaybe<Scalars['Boolean']>;
    isMigrated?: InputMaybe<Scalars['Boolean']>;
    isOnboardingFinished?: InputMaybe<Scalars['Boolean']>;
    isOptedOut?: InputMaybe<Scalars['Boolean']>;
    isWeightSwitcherChecked?: InputMaybe<Scalars['Boolean']>;
    length?: InputMaybe<Scalars['Float']>;
    loggerVersion?: InputMaybe<Scalars['String']>;
    meal?: InputMaybe<Scalars['String']>;
    mealOption?: InputMaybe<Scalars['String']>;
    mealTime?: InputMaybe<Scalars['DateTime']>;
    moodLevel?: InputMaybe<Scalars['Float']>;
    movement?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<NameInput>;
    note?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    ovulationTest?: InputMaybe<Scalars['String']>;
    painLevel?: InputMaybe<Scalars['Float']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    periodDate?: InputMaybe<PeriodDateInput>;
    periodLength?: InputMaybe<PeriodLengthInput>;
    prePregnancyWeight?: InputMaybe<Scalars['Float']>;
    pulse?: InputMaybe<Scalars['Float']>;
    reviewed?: InputMaybe<Scalars['Boolean']>;
    sexualActivity?: InputMaybe<Scalars['String']>;
    sleepEnd?: InputMaybe<Scalars['String']>;
    sleepStart?: InputMaybe<Scalars['String']>;
    softly_deleted?: InputMaybe<Scalars['Boolean']>;
    stack?: InputMaybe<Scalars['Float']>;
    startDate?: InputMaybe<Scalars['DateTime']>;
    steps?: InputMaybe<Scalars['Float']>;
    symptomsOption?: InputMaybe<Scalars['String']>;
    systolicBP?: InputMaybe<Scalars['Float']>;
    takenDate?: InputMaybe<Scalars['DateTime']>;
    temperature?: InputMaybe<Scalars['Float']>;
    timestamp?: InputMaybe<Scalars['String']>;
    timezoneName?: InputMaybe<Scalars['String']>;
    timezoneOffset?: InputMaybe<Scalars['Float']>;
    totalKicks?: InputMaybe<Scalars['Float']>;
    totalTime?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    uuid?: InputMaybe<Scalars['String']>;
    value?: InputMaybe<Scalars['Float']>;
    volumeFeeding?: InputMaybe<VolumeFeedingInput>;
    weight?: InputMaybe<Scalars['Float']>;
    weightGainHighEnd?: InputMaybe<Scalars['Float']>;
    weightGainLowEnd?: InputMaybe<Scalars['Float']>;
};

export type TrackersByPatientIdsForMeasurementsPageInput = {
    filter?: InputMaybe<TrackersListForMeasurementsPageFilterInput>;
    orderBy?: InputMaybe<TrackersListForMeasurementsPageOrderByInput>;
    pagination?: InputMaybe<TrackersListForMeasurementsPagePaginationInput>;
    patientIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TrackersByPatientIdsForMeasurementsPageResponse = PaginationResponse & {
    __typename?: 'TrackersByPatientIdsForMeasurementsPageResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results?: Maybe<Array<Maybe<Tracker>>>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type TrackersByPatientIdsInput = {
    patientIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trackerTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
};

export type TrackersByPatientIdsResponse = PaginationResponse & {
    __typename?: 'TrackersByPatientIdsResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results?: Maybe<Array<Maybe<Tracker>>>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type TrackersByPatientIdsV2Input = {
    filter?: InputMaybe<TrackersListFilterInput>;
    orderBy?: InputMaybe<TrackersListOrderByInput>;
    pagination?: InputMaybe<TrackersListPaginationInput>;
    patientIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TrackersListFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<TrackerUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type TrackersListForMeasurementsPageFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<TrackerUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type TrackersListForMeasurementsPageOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type TrackersListForMeasurementsPagePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type TrackersListOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type TrackersListPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type TrendData = {
    __typename?: 'TrendData';
    dateSet?: Maybe<Scalars['DateTime']>;
    interventionsRun?: Maybe<Scalars['Boolean']>;
    state?: Maybe<Scalars['Boolean']>;
};

export type TrendDataInput = {
    dateSet?: InputMaybe<Scalars['DateTime']>;
    interventionsRun?: InputMaybe<Scalars['Boolean']>;
    state?: InputMaybe<Scalars['Boolean']>;
};

export type TrendTrigger = BaseEntity & {
    __typename?: 'TrendTrigger';
    contiguous: Scalars['Boolean'];
    createdAt: Scalars['DateTime'];
    eventTrigger?: Maybe<EventTrigger>;
    eventTriggerId?: Maybe<Scalars['ObjectId']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    period: Scalars['Float'];
    resetOnRun: Scalars['Boolean'];
    tag?: Maybe<Tag>;
    tagId?: Maybe<Scalars['ObjectId']>;
    trueCount: Scalars['Float'];
    updatedAt: Scalars['DateTime'];
};

export type TrendTriggerFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<TrendTriggerUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type TrendTriggerInput = {
    contiguous: Scalars['Boolean'];
    eventTriggerId?: InputMaybe<Scalars['ObjectId']>;
    name: Scalars['String'];
    period: Scalars['Float'];
    resetOnRun: Scalars['Boolean'];
    tagId?: InputMaybe<Scalars['ObjectId']>;
    trueCount: Scalars['Float'];
};

export type TrendTriggerOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type TrendTriggerPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type TrendTriggerPaginationQueryResponse = PaginationResponse & {
    __typename?: 'TrendTriggerPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<TrendTrigger>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type TrendTriggerQueryInput = {
    filter?: InputMaybe<TrendTriggerFilterInput>;
    orderBy?: InputMaybe<TrendTriggerOrderByInput>;
    pagination?: InputMaybe<TrendTriggerPaginationInput>;
};

export type TrendTriggerUpdateInput = {
    contiguous?: InputMaybe<Scalars['Boolean']>;
    eventTriggerId?: InputMaybe<Scalars['ObjectId']>;
    name?: InputMaybe<Scalars['String']>;
    period?: InputMaybe<Scalars['Float']>;
    resetOnRun?: InputMaybe<Scalars['Boolean']>;
    tagId?: InputMaybe<Scalars['ObjectId']>;
    trueCount?: InputMaybe<Scalars['Float']>;
};

export type UrlMap = {
    __typename?: 'URLMap';
    fullURL: Scalars['String'];
    shortURL: Scalars['String'];
};

export type UrlMapInput = {
    fullURL: Scalars['String'];
    shortURL: Scalars['String'];
};

export type UsState = {
    __typename?: 'USState';
    abbreviation?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
};

export type UiSettings = {
    __typename?: 'UiSettings';
    awsRegion?: Maybe<Scalars['String']>;
    cognitoClientId?: Maybe<Scalars['String']>;
    cognitoPoolId?: Maybe<Scalars['String']>;
    env?: Maybe<Scalars['String']>;
    ts?: Maybe<Scalars['String']>;
    uploadCarePublicKey?: Maybe<Scalars['String']>;
};

export type UnsubscribePatientResponse = MutationResponse & {
    __typename?: 'UnsubscribePatientResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateAdvocateNotificationInput = {
    data: AdvocateNotificationUpdateInput;
    id: Scalars['String'];
};

export type UpdateAdvocateNotificationResponse = MutationResponse & {
    __typename?: 'UpdateAdvocateNotificationResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<AdvocateNotification>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateAdvocateTaskEncodedInput = {
    data: AdvocateTaskUpdateInput;
    id: Scalars['String'];
};

export type UpdateAdvocateTaskEncodedResponse = MutationResponse & {
    __typename?: 'UpdateAdvocateTaskEncodedResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<AdvocateTask>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateAdvocateTaskInput = {
    data: AdvocateTaskUpdateInput;
    id: Scalars['String'];
};

export type UpdateAdvocateTaskResponse = MutationResponse & {
    __typename?: 'UpdateAdvocateTaskResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<AdvocateTask>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateAdvocateTaskTemplateEncodedInput = {
    data: AdvocateTaskTemplateUpdateInput;
    id: Scalars['String'];
};

export type UpdateAdvocateTaskTemplateEncodedResponse = MutationResponse & {
    __typename?: 'UpdateAdvocateTaskTemplateEncodedResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<AdvocateTaskTemplate>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateAdvocateTaskTemplateInput = {
    data: AdvocateTaskTemplateUpdateInput;
    id: Scalars['String'];
};

export type UpdateAdvocateTaskTemplateResponse = MutationResponse & {
    __typename?: 'UpdateAdvocateTaskTemplateResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<AdvocateTaskTemplate>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateAdvocateTaskTypeInput = {
    data: AdvocateTaskTypeUpdateInput;
    id: Scalars['String'];
};

export type UpdateAdvocateTaskTypeResponse = MutationResponse & {
    __typename?: 'UpdateAdvocateTaskTypeResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<AdvocateTaskType>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateAffiliateClaimInput = {
    data: AffiliateClaimFilteringInput;
    id: Scalars['String'];
};

export type UpdateAffiliateClaimResponse = MutationResponse & {
    __typename?: 'UpdateAffiliateClaimResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<AffiliateClaim>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateAffiliateInvoiceInput = {
    data: AffiliateInvoiceUpdateInput;
    id: Scalars['String'];
};

export type UpdateAffiliateInvoiceResponse = MutationResponse & {
    __typename?: 'UpdateAffiliateInvoiceResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<AffiliateInvoice>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateAffiliateInvoicesPaymentStatusInput = {
    update: Array<PaymentStatusUpdate>;
};

export type UpdateAffiliateInvoicesPaymentStatusResponse = MutationResponse & {
    __typename?: 'UpdateAffiliateInvoicesPaymentStatusResponse';
    message: Scalars['String'];
    resourcesCreated?: Maybe<Array<Maybe<AffiliateInvoice>>>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateAppUserArticleInput = {
    data: AppUserArticleUpdateInput;
    id: Scalars['String'];
};

export type UpdateAppUserArticleResponse = MutationResponse & {
    __typename?: 'UpdateAppUserArticleResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<AppUserArticle>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateAppUserEmailInput = {
    email?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['String']>;
};

export type UpdateAppUserEmailResponse = MutationResponse & {
    __typename?: 'UpdateAppUserEmailResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateAppUserEventTriggerInput = {
    data: AppUserEventTriggerUpdateInput;
    id: Scalars['String'];
};

export type UpdateAppUserEventTriggerResponse = MutationResponse & {
    __typename?: 'UpdateAppUserEventTriggerResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<AppUserEventTrigger>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateAppUserInput = {
    appBundleId: Scalars['String'];
    birthDate: Scalars['String'];
    ehrIds?: InputMaybe<Array<InputMaybe<EhrIdsInput>>>;
    ehrIntegration?: InputMaybe<Scalars['String']>;
    email: Scalars['String'];
    externalId?: InputMaybe<Scalars['String']>;
    firstName: Scalars['String'];
    gender?: InputMaybe<Scalars['String']>;
    groupNumber?: InputMaybe<Scalars['String']>;
    healthPlan?: InputMaybe<Scalars['String']>;
    insuranceId?: InputMaybe<Scalars['String']>;
    insuranceName?: InputMaybe<Scalars['String']>;
    insuredAddress?: InputMaybe<AddressInput>;
    insuredFirstName?: InputMaybe<Scalars['String']>;
    insuredLastName?: InputMaybe<Scalars['String']>;
    insuredPhoneNumber?: InputMaybe<Scalars['String']>;
    invitationMode?: InputMaybe<PatientInvitation>;
    isCreatedByPortal?: InputMaybe<Scalars['Boolean']>;
    isTestData?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Language>;
    lastName: Scalars['String'];
    lob?: InputMaybe<Scalars['String']>;
    mailingAddress?: InputMaybe<AddressInput>;
    memberId?: InputMaybe<Scalars['String']>;
    passwordSalt?: InputMaybe<Scalars['String']>;
    patientBuildList?: InputMaybe<PatientBuildListInput>;
    patientCreatedByExternalDataSource?: InputMaybe<Scalars['Boolean']>;
    patientId?: InputMaybe<Scalars['String']>;
    patientRelationshipToInsured?: InputMaybe<Scalars['String']>;
    phoneNumber?: InputMaybe<Scalars['String']>;
    race?: InputMaybe<Scalars['String']>;
    redoxEhrId?: InputMaybe<Scalars['String']>;
    redoxSourceId?: InputMaybe<Scalars['String']>;
};

export type UpdateAppUserLanguageInput = {
    appUserId?: InputMaybe<Scalars['String']>;
    language?: InputMaybe<Language>;
};

export type UpdateAppUserLanguageResponse = MutationResponse & {
    __typename?: 'UpdateAppUserLanguageResponse';
    data?: Maybe<AppUser>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateAppUserMutationResponse = MutationResponse & {
    __typename?: 'UpdateAppUserMutationResponse';
    data?: Maybe<AppUserResponseData>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateAppUserResponse = MutationResponse & {
    __typename?: 'UpdateAppUserResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<AppUser>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateAppUserTelemetryInput = {
    data: AppUserTelemetryInput;
    id: Scalars['String'];
};

export type UpdateAppUserTelemetryResponse = MutationResponse & {
    __typename?: 'UpdateAppUserTelemetryResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<AppUserTelemetry>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateAppUserTrendTriggerInput = {
    data: AppUserTrendTriggerUpdateInput;
    id: Scalars['String'];
};

export type UpdateAppUserTrendTriggerResponse = MutationResponse & {
    __typename?: 'UpdateAppUserTrendTriggerResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<AppUserTrendTrigger>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateApplicationInput = {
    data: ApplicationUpdateInput;
    id: Scalars['String'];
};

export type UpdateApplicationResponse = MutationResponse & {
    __typename?: 'UpdateApplicationResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Application>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateAppointmentInput = {
    data: AppointmentUpdateInput;
    id: Scalars['String'];
};

export type UpdateAppointmentResponse = MutationResponse & {
    __typename?: 'UpdateAppointmentResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Appointment>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateApprovalNameInput = {
    articleId: Scalars['String'];
    newName: Scalars['String'];
};

export type UpdateApproveNameResponse = MutationResponse & {
    __typename?: 'UpdateApproveNameResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateArticleApprovalInput = {
    data: ArticleApprovalInput;
    id: Scalars['String'];
};

export type UpdateArticleApprovalResponse = MutationResponse & {
    __typename?: 'UpdateArticleApprovalResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ArticleApproval>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateArticleCacheInput = {
    data: ArticleCacheUpdateInput;
    id: Scalars['String'];
};

export type UpdateArticleCacheResponse = MutationResponse & {
    __typename?: 'UpdateArticleCacheResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ArticleCache>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateArticleCategoryInput = {
    data: ArticleCategoryUpdateInput;
    id: Scalars['String'];
};

export type UpdateArticleCategoryResponse = MutationResponse & {
    __typename?: 'UpdateArticleCategoryResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ArticleCategory>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateArticleEncodedInput = {
    data: ArticleUpdateInput;
    id: Scalars['String'];
};

export type UpdateArticleEncodedResponse = MutationResponse & {
    __typename?: 'UpdateArticleEncodedResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Article>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateArticleFeedInput = {
    data: ArticleFeedUpdateInput;
    id: Scalars['String'];
};

export type UpdateArticleFeedResponse = MutationResponse & {
    __typename?: 'UpdateArticleFeedResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ArticleFeed>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateArticleGroupInput = {
    data: ArticleGroupUpdateInput;
    id: Scalars['String'];
};

export type UpdateArticleGroupResponse = MutationResponse & {
    __typename?: 'UpdateArticleGroupResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ArticleGroup>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateArticleInput = {
    data: ArticleUpdateInput;
    id: Scalars['String'];
};

export type UpdateArticlePromotionInput = {
    data: ArticlePromotionUpdateInput;
    id: Scalars['String'];
};

export type UpdateArticlePromotionResponse = MutationResponse & {
    __typename?: 'UpdateArticlePromotionResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ArticlePromotion>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateArticleResponse = MutationResponse & {
    __typename?: 'UpdateArticleResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Article>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateAsyncJobInput = {
    data: AsyncJobInput;
    id: Scalars['String'];
};

export type UpdateAsyncJobResponse = MutationResponse & {
    __typename?: 'UpdateAsyncJobResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<AsyncJob>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateBatchClaimStatusInput = {
    affiliateInvoiceId?: InputMaybe<Scalars['ObjectId']>;
    errorText?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['String']>;
    reportClaimId?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<ClaimSubmittedStatus>;
    uploaded?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateBatchClaimsStatusResponse = MutationResponse & {
    __typename?: 'UpdateBatchClaimsStatusResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
    updated: Array<ClaimSubmitted>;
};

export type UpdateBatchTaskInput = {
    data: BatchTaskUpdateInput;
    id: Scalars['String'];
};

export type UpdateBatchTaskResponse = MutationResponse & {
    __typename?: 'UpdateBatchTaskResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<BatchTask>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateBioScreenInput = {
    data: BioScreenUpdateInput;
    id: Scalars['String'];
};

export type UpdateBioScreenResponse = MutationResponse & {
    __typename?: 'UpdateBioScreenResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<BioScreen>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateCalendarResponse = MutationResponse & {
    __typename?: 'UpdateCalendarResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
    updated?: Maybe<Calendar>;
};

export type UpdateCarePlanInput = {
    data: CarePlanInput;
    id: Scalars['String'];
};

export type UpdateCarePlanOffsetsInput = {
    durationMinutes?: InputMaybe<Scalars['Float']>;
    groupId: Scalars['String'];
    id: Scalars['String'];
    offsetDays?: InputMaybe<Scalars['Float']>;
    offsetMonths?: InputMaybe<Scalars['Float']>;
    offsetYears?: InputMaybe<Scalars['Float']>;
    templateId: Scalars['String'];
};

export type UpdateCarePlanResponse = MutationResponse & {
    __typename?: 'UpdateCarePlanResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<CarePlan>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateCareTeamContactInput = {
    data: CareTeamContactUpdateInput;
    id: Scalars['String'];
};

export type UpdateCareTeamContactResponse = MutationResponse & {
    __typename?: 'UpdateCareTeamContactResponse';
    careTeamContact?: Maybe<CareTeamContact>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateCareTeamInput = {
    data: CareTeamUpdateInput;
    id: Scalars['String'];
};

export type UpdateCareTeamMemberInput = {
    data: CareTeamMemberUpdateInput;
    id: Scalars['String'];
};

export type UpdateCareTeamMemberResponse = MutationResponse & {
    __typename?: 'UpdateCareTeamMemberResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<CareTeamMember>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateCareTeamMemberTypeInput = {
    data: CareTeamMemberTypeUpdateInput;
    id: Scalars['String'];
};

export type UpdateCareTeamMemberTypeResponse = MutationResponse & {
    __typename?: 'UpdateCareTeamMemberTypeResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<CareTeamMemberType>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateCareTeamResponse = MutationResponse & {
    __typename?: 'UpdateCareTeamResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<CareTeam>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateChatConversationInput = {
    data: ChatConversationUpdateInput;
    id: Scalars['String'];
};

export type UpdateChatConversationResponse = MutationResponse & {
    __typename?: 'UpdateChatConversationResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ChatConversation>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateChatMessageInput = {
    data: ChatMessageUpdateInput;
    id: Scalars['String'];
};

export type UpdateChatMessageResponse = MutationResponse & {
    __typename?: 'UpdateChatMessageResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ChatMessage>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateChatRoomInput = {
    data: ChatRoomUpdateInput;
    id: Scalars['String'];
};

export type UpdateChatRoomResponse = MutationResponse & {
    __typename?: 'UpdateChatRoomResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ChatRoom>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateClinicalSummaryInput = {
    data: ClinicalSummaryUpdateInput;
    id: Scalars['ObjectId'];
};

export type UpdateClinicalSummaryResponse = MutationResponse & {
    __typename?: 'UpdateClinicalSummaryResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ClinicalSummary>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateCompoundQuestionInput = {
    data: CompoundQuestionUpdateInput;
    id: Scalars['String'];
};

export type UpdateCompoundQuestionResponse = MutationResponse & {
    __typename?: 'UpdateCompoundQuestionResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<CompoundQuestion>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateConditionInput = {
    data: ConditionUpdateInput;
    id: Scalars['String'];
};

export type UpdateConditionResponse = MutationResponse & {
    __typename?: 'UpdateConditionResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Condition>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateConditionTypeInput = {
    data: ConditionTypeUpdateInput;
    id: Scalars['String'];
};

export type UpdateConditionTypeResponse = MutationResponse & {
    __typename?: 'UpdateConditionTypeResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ConditionType>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateConfigExpressionInput = {
    data: ConfigExpressionUpdateInput;
    id: Scalars['String'];
};

export type UpdateConfigExpressionResponse = MutationResponse & {
    __typename?: 'UpdateConfigExpressionResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ConfigExpression>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateConfigValueDefInput = {
    data: ConfigValueDefUpdateInput;
    id: Scalars['String'];
};

export type UpdateConfigValueDefResponse = MutationResponse & {
    __typename?: 'UpdateConfigValueDefResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ConfigValueDef>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateConfigValueInput = {
    data: ConfigValueUpdateInput;
    id: Scalars['String'];
};

export type UpdateConfigValueResponse = MutationResponse & {
    __typename?: 'UpdateConfigValueResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ConfigValue>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateContentDirectoryInput = {
    data: ContentDirectoryUpdateInput;
    id: Scalars['String'];
};

export type UpdateContentDirectoryResponse = MutationResponse & {
    __typename?: 'UpdateContentDirectoryResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ContentDirectory>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateDateTriggerInput = {
    data: DateTriggerUpdateInput;
    id: Scalars['String'];
};

export type UpdateDateTriggerResponse = MutationResponse & {
    __typename?: 'UpdateDateTriggerResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<DateTrigger>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateDeviceInput = {
    data: DeviceUpdateInput;
    id: Scalars['String'];
};

export type UpdateDeviceResponse = MutationResponse & {
    __typename?: 'UpdateDeviceResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Device>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateDirectoryCacheInput = {
    data: DirectoryCacheUpdateInput;
    id: Scalars['String'];
};

export type UpdateDirectoryCacheResponse = MutationResponse & {
    __typename?: 'UpdateDirectoryCacheResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<DirectoryCache>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateEligibilityMatchInput = {
    data: EligibilityMatchUpdateInput;
    id: Scalars['String'];
};

export type UpdateEligibilityMatchResponse = MutationResponse & {
    __typename?: 'UpdateEligibilityMatchResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<EligibilityMatch>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateEpisodeInput = {
    data: EpisodeUpdateInput;
    id: Scalars['String'];
};

export type UpdateEpisodeResponse = MutationResponse & {
    __typename?: 'UpdateEpisodeResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Episode>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateEventTriggerInput = {
    data: EventTriggerUpdateInput;
    id: Scalars['String'];
};

export type UpdateEventTriggerResponse = MutationResponse & {
    __typename?: 'UpdateEventTriggerResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<EventTrigger>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateHealthPlanInput = {
    data: HealthPlanUpdateInput;
    id: Scalars['String'];
};

export type UpdateHealthPlanResponse = MutationResponse & {
    __typename?: 'UpdateHealthPlanResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<HealthPlan>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateHighlightFeedInput = {
    data: HighlightFeedUpdateInput;
    id: Scalars['String'];
};

export type UpdateHighlightFeedResponse = MutationResponse & {
    __typename?: 'UpdateHighlightFeedResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<HighlightFeed>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateHighlightInput = {
    data: HighlightUpdateInput;
    id: Scalars['String'];
};

export type UpdateHighlightResponse = MutationResponse & {
    __typename?: 'UpdateHighlightResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Highlight>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateInterventionInput = {
    data: InterventionUpdateInput;
    id: Scalars['String'];
};

export type UpdateInterventionResponse = MutationResponse & {
    __typename?: 'UpdateInterventionResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Intervention>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateMassUpdatePatientInput = {
    data: MassUpdatePatientsUpdateInput;
    id: Scalars['String'];
};

export type UpdateMassUpdatePatientResponse = MutationResponse & {
    __typename?: 'UpdateMassUpdatePatientResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<MassUpdatePatients>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateMassUpdateTaskInput = {
    data: MassUpdateTaskUpdateInput;
    id: Scalars['String'];
};

export type UpdateMassUpdateTaskResponse = MutationResponse & {
    __typename?: 'UpdateMassUpdateTaskResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<MassUpdateTask>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateMeasurementInput = {
    data: MeasurementUpdateInput;
    id: Scalars['String'];
};

export type UpdateMeasurementResponse = MutationResponse & {
    __typename?: 'UpdateMeasurementResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Measurement>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateMeasurementReviewedInput = {
    id: Scalars['String'];
    reviewed?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateMeasurementReviewedResponse = MutationResponse & {
    __typename?: 'UpdateMeasurementReviewedResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Measurement>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateMessageCenterTemplateInput = {
    data: MessageCenterTemplateUpdateInput;
    id: Scalars['String'];
};

export type UpdateMessageCenterTemplateResponse = MutationResponse & {
    __typename?: 'UpdateMessageCenterTemplateResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<MessageCenterTemplate>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateMessageTemplateInput = {
    data: MessageTemplateUpdateInput;
    id: Scalars['String'];
};

export type UpdateMessageTemplateResponse = MutationResponse & {
    __typename?: 'UpdateMessageTemplateResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<MessageTemplate>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateOrderInput = {
    data: OrdersInputUpdate;
    id: Scalars['ObjectId'];
};

export type UpdateOrderResponse = MutationResponse & {
    __typename?: 'UpdateOrderResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Order>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateOrganizationInput = {
    data: OrganizationUpdateInput;
    id: Scalars['String'];
};

export type UpdateOrganizationResponse = MutationResponse & {
    __typename?: 'UpdateOrganizationResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Organization>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateParticipantsForChatRoomInput = {
    chatRoomId?: InputMaybe<Scalars['ObjectId']>;
    isIntendToFollow?: InputMaybe<Scalars['Boolean']>;
    participantId?: InputMaybe<Scalars['ObjectId']>;
};

export type UpdateParticipantsForChatRoomResponse = MutationResponse & {
    __typename?: 'UpdateParticipantsForChatRoomResponse';
    chatRoom?: Maybe<ChatRoom>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdatePatientActionInput = {
    data: PatientActionUpdateInput;
    id: Scalars['String'];
};

export type UpdatePatientActionResponse = MutationResponse & {
    __typename?: 'UpdatePatientActionResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<PatientAction>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdatePatientActionTypeInput = {
    data: PatientActionTypeUpdateInput;
    id: Scalars['String'];
};

export type UpdatePatientActionTypeResponse = MutationResponse & {
    __typename?: 'UpdatePatientActionTypeResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<PatientActionType>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdatePatientCallListCallStatusInput = {
    called?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['ObjectId']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
};

export type UpdatePatientCallListResponse = MutationResponse & {
    __typename?: 'UpdatePatientCallListResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<PatientCallList>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdatePatientHeaderInput = {
    data: PatientHeaderInput;
    id: Scalars['String'];
};

export type UpdatePatientHeaderResponse = MutationResponse & {
    __typename?: 'UpdatePatientHeaderResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<PatientHeader>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdatePatientInput = {
    data: PatientUpdateInput;
    id: Scalars['String'];
};

export type UpdatePatientNoteTagInput = {
    data: PatientNoteTagUpdateInput;
    id: Scalars['String'];
};

export type UpdatePatientNoteTagResponse = MutationResponse & {
    __typename?: 'UpdatePatientNoteTagResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<PatientNoteTag>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdatePatientNotesInput = {
    data: PatientNotesUpdateInput;
    id: Scalars['String'];
};

export type UpdatePatientNotesResponse = MutationResponse & {
    __typename?: 'UpdatePatientNotesResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<PatientNotes>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdatePatientResponse = MutationResponse & {
    __typename?: 'UpdatePatientResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Patient>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdatePotentialUserInput = {
    data: PotentialUserUpdateInput;
    id: Scalars['String'];
};

export type UpdatePotentialUserResponse = MutationResponse & {
    __typename?: 'UpdatePotentialUserResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<PotentialUser>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateProfileKeyInput = {
    data: ProfileKeyUpdateInput;
    id: Scalars['String'];
};

export type UpdateProfileKeyResponse = MutationResponse & {
    __typename?: 'UpdateProfileKeyResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ProfileKey>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateProfileValueHistoryInput = {
    data: PatientValueHistoryUpdateInput;
    id: Scalars['String'];
};

export type UpdateProfileValueHistoryResponse = MutationResponse & {
    __typename?: 'UpdateProfileValueHistoryResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ProfileValueHistory>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateProfileValueInput = {
    data: ProfileValueUpdateInput;
    id: Scalars['String'];
};

export type UpdateProfileValueOriginInput = {
    data: ProfileValueOriginUpdateInput;
    id: Scalars['String'];
};

export type UpdateProfileValueOriginResponse = MutationResponse & {
    __typename?: 'UpdateProfileValueOriginResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ProfileValueOrigin>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateProfileValueResponse = MutationResponse & {
    __typename?: 'UpdateProfileValueResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ProfileValue>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateProfileVariableFromPatientPageInput = {
    notes?: InputMaybe<Scalars['String']>;
    originId?: InputMaybe<Scalars['ObjectId']>;
    portalPatientId?: InputMaybe<Scalars['String']>;
    portalUserId?: InputMaybe<Scalars['String']>;
    profileVariableDefId: Scalars['String'];
    sourceType?: InputMaybe<Scalars['String']>;
    value: ProfileValueDataInput;
};

export type UpdateProviderInput = {
    data: ProviderUpdateInput;
    id: Scalars['String'];
};

export type UpdateProviderResponse = MutationResponse & {
    __typename?: 'UpdateProviderResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Provider>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateQListInput = {
    data: QListUpdateInput;
    id: Scalars['String'];
};

export type UpdateQListResponse = MutationResponse & {
    __typename?: 'UpdateQListResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<QList>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateQuestionInput = {
    data: QuestionUpdateInput;
    id: Scalars['String'];
};

export type UpdateQuestionResponse = MutationResponse & {
    __typename?: 'UpdateQuestionResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Question>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateReadeMessagesForChatRoomInput = {
    chatRoomId?: InputMaybe<Scalars['ObjectId']>;
};

export type UpdateReadeMessagesForChatRoomResponse = MutationResponse & {
    __typename?: 'UpdateReadeMessagesForChatRoomResponse';
    chatRoom?: Maybe<ChatRoom>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateReferenceLinkCategoryInput = {
    data: ReferenceLinkCategoryUpdateInput;
    id: Scalars['String'];
};

export type UpdateReferenceLinkCategoryResponse = MutationResponse & {
    __typename?: 'UpdateReferenceLinkCategoryResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ReferenceLinkCategory>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateReferenceLinkInput = {
    data: ReferenceLinkUpdateInput;
    id: Scalars['String'];
};

export type UpdateReferenceLinkResponse = MutationResponse & {
    __typename?: 'UpdateReferenceLinkResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ReferenceLink>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateReportKeyInput = {
    id: Scalars['ObjectId'];
    reportKey?: InputMaybe<Scalars['String']>;
};

export type UpdateReportKeyResponse = MutationResponse & {
    __typename?: 'UpdateReportKeyResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Appointment>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateReportingCategoryInput = {
    data: ReportingCategoryUpdateInput;
    id: Scalars['String'];
};

export type UpdateReportingCategoryResponse = MutationResponse & {
    __typename?: 'UpdateReportingCategoryResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ReportingCategory>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateReportingJobInput = {
    data: ReportingJobUpdateInput;
    id: Scalars['String'];
};

export type UpdateReportingJobResponse = MutationResponse & {
    __typename?: 'UpdateReportingJobResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ReportingJob>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateRoleInput = {
    data: RoleUpdateInput;
    id: Scalars['String'];
};

export type UpdateRoleResponse = MutationResponse & {
    __typename?: 'UpdateRoleResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Role>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateSentMessageInput = {
    data: SentMessageUpdateInput;
    id: Scalars['String'];
};

export type UpdateSentMessageResponse = MutationResponse & {
    __typename?: 'UpdateSentMessageResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<SentMessage>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateSimpleContentInput = {
    data: SimpleContentInput;
    id: Scalars['String'];
};

export type UpdateSimpleContentResponse = MutationResponse & {
    __typename?: 'UpdateSimpleContentResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<SimpleContent>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateSlaInput = {
    data: SlaUpdateInput;
    id: Scalars['String'];
};

export type UpdateSlaResponse = MutationResponse & {
    __typename?: 'UpdateSlaResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Sla>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateSlaTimerInput = {
    data: SlaTimerUpdateInput;
    id: Scalars['String'];
};

export type UpdateSlaTimerResponse = MutationResponse & {
    __typename?: 'UpdateSlaTimerResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<SlaTimer>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateSubmittedClaimInput = {
    data: ClaimSubmittedUpdateInput;
    id: Scalars['String'];
};

export type UpdateSubmittedClaimResponse = MutationResponse & {
    __typename?: 'UpdateSubmittedClaimResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<ClaimSubmitted>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateSurveyDefInput = {
    data: SurveyDefUpdateInput;
    id: Scalars['String'];
};

export type UpdateSurveyDefResponse = MutationResponse & {
    __typename?: 'UpdateSurveyDefResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<SurveyDef>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateSurveyResponseAnswersWithNoDataTrailResponse = MutationResponse & {
    __typename?: 'UpdateSurveyResponseAnswersWithNoDataTrailResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateSurveyResponseInput = {
    data: SurveyResponseUpdateInput;
    id: Scalars['String'];
};

export type UpdateSurveyResponseResponse = MutationResponse & {
    __typename?: 'UpdateSurveyResponseResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<SurveyResponse>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateSystemTokenInput = {
    data: SystemTokenUpdateInput;
    id: Scalars['String'];
};

export type UpdateSystemTokenResponse = MutationResponse & {
    __typename?: 'UpdateSystemTokenResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<SystemToken>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateTagInput = {
    data: TagUpdateInput;
    id: Scalars['String'];
};

export type UpdateTagResponse = MutationResponse & {
    __typename?: 'UpdateTagResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Tag>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateTodoTaskInput = {
    data: TodoTaskUpdateInput;
    id: Scalars['String'];
};

export type UpdateTodoTaskResponse = MutationResponse & {
    __typename?: 'UpdateTodoTaskResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<TodoTask>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateTodoTasksInput = {
    updatedTodoTasks: Array<UpdatedTodoTask>;
};

export type UpdateTodoTasksResponse = MutationResponse & {
    __typename?: 'UpdateTodoTasksResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateTokenInput = {
    data: TokenUpdateInput;
    id: Scalars['String'];
};

export type UpdateTokenResponse = MutationResponse & {
    __typename?: 'UpdateTokenResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Token>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateTrackerHistoryInput = {
    data: TrackerHistoryUpdateInput;
    id: Scalars['String'];
};

export type UpdateTrackerHistoryResponse = MutationResponse & {
    __typename?: 'UpdateTrackerHistoryResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<TrackerHistory>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateTrackerInput = {
    data: TrackerUpdateInput;
    id: Scalars['String'];
};

export type UpdateTrackerResponse = MutationResponse & {
    __typename?: 'UpdateTrackerResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<Tracker>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateTrendTriggerInput = {
    data: TrendTriggerUpdateInput;
    id: Scalars['String'];
};

export type UpdateTrendTriggerResponse = MutationResponse & {
    __typename?: 'UpdateTrendTriggerResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<TrendTrigger>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateUserInput = {
    data: UserUpdateInput;
    id: Scalars['String'];
};

export type UpdateUserProfileDefInput = {
    data: UserProfileDefUpdateInput;
    id: Scalars['String'];
};

export type UpdateUserProfileDefResponse = MutationResponse & {
    __typename?: 'UpdateUserProfileDefResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<UserProfileDef>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateUserResponse = MutationResponse & {
    __typename?: 'UpdateUserResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<User>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateVcZipCodeToLocationInput = {
    data: VcZipCodeToLocationUpdateInput;
    id: Scalars['String'];
};

export type UpdateVcZipCodeToLocationResponse = MutationResponse & {
    __typename?: 'UpdateVCZipCodeToLocationResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<VcZipCodeToLocation>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateVirtualCareAffiliateInput = {
    data?: InputMaybe<VirtualCareAffiliatesUpdateInput>;
    id: Scalars['String'];
};

export type UpdateVirtualCareAffiliateResponse = MutationResponse & {
    __typename?: 'UpdateVirtualCareAffiliateResponse';
    data?: Maybe<VirtualCareAffiliatesEntity>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateVirtualCareAffiliatesInput = {
    data: VirtualCareAffiliatesUpdateInput;
    id: Scalars['String'];
};

export type UpdateVirtualCareAffiliatesResponse = MutationResponse & {
    __typename?: 'UpdateVirtualCareAffiliatesResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<VirtualCareAffiliatesEntity>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateVirtualCareEligibilityWhitelistInput = {
    data: VirtualCareEligibilityWhitelistUpdateInput;
    id: Scalars['String'];
};

export type UpdateVirtualCareEligibilityWhitelistResponse = MutationResponse & {
    __typename?: 'UpdateVirtualCareEligibilityWhitelistResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<VirtualCareEligibilityWhitelist>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateVirtualCareFormInput = {
    data: VirtualCareFormUpdateInput;
    id: Scalars['String'];
};

export type UpdateVirtualCareFormResponse = MutationResponse & {
    __typename?: 'UpdateVirtualCareFormResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<VirtualCareForm>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdateWhatsNewTimelineInput = {
    data: WhatsNewTimelineUpdateInput;
    id?: InputMaybe<Scalars['ObjectId']>;
};

export type UpdateWhoToCallInput = {
    data: WhoToCallUpdateInput;
    id: Scalars['String'];
};

export type UpdateWhoToCallResponse = MutationResponse & {
    __typename?: 'UpdateWhoToCallResponse';
    message: Scalars['String'];
    resourceUpdated?: Maybe<WhoToCall>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpdatedTodoTask = {
    _id: Scalars['ObjectId'];
    appUserId: Scalars['ObjectId'];
    articleId?: InputMaybe<Scalars['ObjectId']>;
    carePlanId?: InputMaybe<Scalars['ObjectId']>;
    createdBy: TodoTaskCreatedByType;
    dateCompleted?: InputMaybe<Scalars['DateTime']>;
    description?: InputMaybe<LocalizedStringInput>;
    dueDate?: InputMaybe<Scalars['DateTime']>;
    effectiveDate: Scalars['DateTime'];
    groupId?: InputMaybe<Scalars['ObjectId']>;
    isComplete: Scalars['Boolean'];
    isEssential: Scalars['Boolean'];
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    priority: Scalars['Float'];
    repeatType?: InputMaybe<TodoTaskRepeatType>;
    templateId?: InputMaybe<Scalars['ObjectId']>;
    title: LocalizedStringInput;
};

export type UpsertConfigValueByValueDefIdInput = {
    data: ConfigValueInput;
};

export type UpsertConfigValueByValueDefIdResponse = MutationResponse & {
    __typename?: 'UpsertConfigValueByValueDefIdResponse';
    configValue?: Maybe<ConfigValue>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type UpsertVariantResponse = MutationResponse & {
    __typename?: 'UpsertVariantResponse';
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
    updatedArticle?: Maybe<Article>;
};

export type UsedToken = {
    __typename?: 'UsedToken';
    name: Scalars['String'];
    value?: Maybe<Scalars['String']>;
};

export type UsedTokenInput = {
    name: Scalars['String'];
    value?: InputMaybe<Scalars['String']>;
};

export type User = BaseEntity & {
    __typename?: 'User';
    advocateNotifications: Array<FullInfoAdvocateNotification>;
    affiliate?: Maybe<VirtualCareAffiliateByIdResponse>;
    affiliateId?: Maybe<Scalars['ObjectId']>;
    appointments: Array<Appointment>;
    appointmentsV2: UserAppointmentsResponse;
    chatRooms: Array<ChatRoom>;
    /** Top Org below WF - the client */
    clientOrgId?: Maybe<Scalars['ObjectId']>;
    createdAt: Scalars['DateTime'];
    /** Current Org selected in UI */
    currentOrg: Organization;
    currentOrganizations: Array<Organization>;
    email: Scalars['String'];
    exportPatients: PatientsExportResponse;
    hgUserId?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    isActive?: Maybe<Scalars['Boolean']>;
    /** Currently represents whether a user is sent an invite email @_@ */
    isStaff?: Maybe<Scalars['Boolean']>;
    isTestData?: Maybe<Scalars['Boolean']>;
    lastLogin?: Maybe<Scalars['DateTime']>;
    measurements: Array<Measurement>;
    measurementsV2: UserMeasurementsResponse;
    name: Scalars['String'];
    newTaskNotificationCount?: Maybe<Scalars['Float']>;
    numberOfUnreadChatMessages?: Maybe<Scalars['Float']>;
    openConversationsCount?: Maybe<Scalars['Float']>;
    organizations: Array<Organization>;
    /** @deprecated this query is likely to cause an out of memory error */
    patients: Array<Patient>;
    patientsV2: UserPatientsResponse;
    photo?: Maybe<Scalars['String']>;
    role?: Maybe<Role>;
    roleId?: Maybe<Scalars['ObjectId']>;
    rootOrg: Organization;
    rootOrgId?: Maybe<Scalars['String']>;
    smsNumber?: Maybe<Scalars['String']>;
    surveyResponses: Array<SurveyResponse>;
    surveyResponsesV2: UserSurveyResponsesResponse;
    title?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
    users: Array<User>;
};

export type UserAppointmentsArgs = {
    input?: InputMaybe<UserAppointmentsInput>;
};

export type UserAppointmentsV2Args = {
    input?: InputMaybe<UserAppointmentsInput>;
};

export type UserChatRoomsArgs = {
    input?: InputMaybe<UserChatRoomsInput>;
};

export type UserExportPatientsArgs = {
    input?: InputMaybe<ExportPatientsByFilterInput>;
};

export type UserMeasurementsV2Args = {
    input?: InputMaybe<UserMeasurementsInput>;
};

export type UserPatientsV2Args = {
    input?: InputMaybe<GetPatientsByFilterInput>;
};

export type UserSurveyResponsesV2Args = {
    input?: InputMaybe<UserSurveyResponsesInput>;
};

export type UserAppointmentsInput = {
    assignedTo?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<UserAppointmentsFilterInput>;
    filterTestData?: InputMaybe<Scalars['Boolean']>;
    orderBy?: InputMaybe<UserAppointmentsOrderByInput>;
    pagination?: InputMaybe<UserAppointmentsPaginationInput>;
    tagIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type UserAppointmentsResponse = PaginationResponse & {
    __typename?: 'UserAppointmentsResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Appointment>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type UserChatRoomsInput = {
    assignedTo?: InputMaybe<Scalars['String']>;
    careTeamMemberTypeId?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<UserChatRoomsFilterInput>;
    filterFollowedChatRooms?: InputMaybe<Scalars['Boolean']>;
    filterOpenConversations?: InputMaybe<Scalars['Boolean']>;
    filterTestData?: InputMaybe<Scalars['Boolean']>;
    orderBy?: InputMaybe<UserChatRoomsOrderByInput>;
    pagination?: InputMaybe<UserChatRoomsPaginationInput>;
    tagIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type UserChatStats = {
    __typename?: 'UserChatStats';
    /** count of read messages from patients for `userId` for the current patient */
    patientReadCount: Scalars['Float'];
    /** date for the latest read message from patients for `userId` for the current patient */
    patientReadDate: Scalars['DateTime'];
    /** count of unread messages from patients for `userId` for the current patient */
    patientUnreadCount: Scalars['Float'];
    /** date for the latest unread message from patients for `userId` for the current patient */
    patientUnreadDate: Scalars['DateTime'];
    /** count of read messages from patients or users for `userId` for the current patient */
    personReadCount: Scalars['Float'];
    /** date for the latest read message from patients or users for `userId` for the current patient */
    personReadDate: Scalars['DateTime'];
    /** count of unread messages from patients or users for `userId` for the current patient */
    personUnreadCount: Scalars['Float'];
    /** date for the latest unread message from patients or users for `userId` for the current patient */
    personUnreadDate: Scalars['DateTime'];
    /** total count of read messages for `userId` for the current patient */
    readCount: Scalars['Float'];
    /** date for the latest read message for `userId` for the current patient */
    readDate: Scalars['DateTime'];
    /** count of read messages from the system for `userId` for the current patient */
    systemReadCount: Scalars['Float'];
    /** date for the latest read message from the system for `userId` for the current patient */
    systemReadDate: Scalars['DateTime'];
    /** count of unread messages from the system for `userId` for the current patient */
    systemUnreadCount: Scalars['Float'];
    /** date for the latest unread message from the system for `userId` for the current patient */
    systemUnreadDate: Scalars['DateTime'];
    /** total count of unread messages for `userId` for the current patient */
    unreadCount: Scalars['Float'];
    /** date for the latest unread message for `userId` for the current patient */
    unreadDate: Scalars['DateTime'];
    userId: Scalars['ObjectId'];
    /** count of read messages from users for `userId` for the current patient */
    userReadCount: Scalars['Float'];
    /** date for the latest read message from users for `userId` for the current patient */
    userReadDate: Scalars['DateTime'];
    /** count of unread messages from users for `userId` for the current patient */
    userUnreadCount: Scalars['Float'];
    /** date for the latest unread message from users for `userId` for the current patient */
    userUnreadDate: Scalars['DateTime'];
};

export type UserFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<UserUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type UserInput = {
    affiliateId?: InputMaybe<Scalars['ObjectId']>;
    /** Top Org below WF - the client */
    clientOrgId?: InputMaybe<Scalars['ObjectId']>;
    /** Defaults to root org */
    currentOrg?: InputMaybe<Scalars['ObjectId']>;
    email: Scalars['String'];
    isActive?: InputMaybe<Scalars['Boolean']>;
    /** Currently represents whether a user is sent an invite email @_@ */
    isStaff?: InputMaybe<Scalars['Boolean']>;
    isTestData?: InputMaybe<Scalars['Boolean']>;
    name: Scalars['String'];
    photo?: InputMaybe<Scalars['String']>;
    roleId?: InputMaybe<Scalars['ObjectId']>;
    rootOrg: Scalars['ObjectId'];
    smsNumber?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
};

export type UserMeasurementsInput = {
    assignedTo?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<UserMeasurementsFilterInput>;
    filterTestData?: InputMaybe<Scalars['Boolean']>;
    orderBy?: InputMaybe<UserMeasurementsOrderByInput>;
    pagination?: InputMaybe<UserMeasurementsPaginationInput>;
    tagIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type UserMeasurementsResponse = PaginationResponse & {
    __typename?: 'UserMeasurementsResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Measurement>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type UserOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type UserPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type UserPaginationQueryResponse = PaginationResponse & {
    __typename?: 'UserPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<User>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type UserPatientsResponse = PaginationResponse & {
    __typename?: 'UserPatientsResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Patient>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type UserProfileDef = BaseEntity & {
    __typename?: 'UserProfileDef';
    category: Scalars['String'];
    choiceValueType?: Maybe<UserProfileValueType>;
    choices?: Maybe<Array<Maybe<UserProfileDefChoice>>>;
    claimsField?: Maybe<Scalars['String']>;
    clientSpecificDisplayName?: Maybe<Scalars['String']>;
    conditionTypeId?: Maybe<Scalars['ObjectId']>;
    createdAt: Scalars['DateTime'];
    dataSourceNotes?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    dwExternalReports?: Maybe<Scalars['String']>;
    dwInternalReports?: Maybe<Scalars['String']>;
    dwLocation?: Maybe<Scalars['String']>;
    dwMetrics?: Maybe<Scalars['String']>;
    dwObject?: Maybe<Scalars['String']>;
    episodeRequired?: Maybe<Scalars['Boolean']>;
    haPermission?: Maybe<HaPermission>;
    history?: Maybe<Array<Maybe<UserProfileDefHistoryItem>>>;
    icd10DiagnosisCodes?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    inboundNotes?: Maybe<Scalars['String']>;
    isClientSpecific?: Maybe<Scalars['Boolean']>;
    isTestData?: Maybe<Scalars['Boolean']>;
    /** Label */
    label?: Maybe<LocalizedString>;
    linkToJIRA?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    procedureCodes?: Maybe<Scalars['String']>;
    purposeAppPersonalization?: Maybe<Scalars['Boolean']>;
    purposeDetails?: Maybe<Scalars['String']>;
    purposeHumanServices?: Maybe<Scalars['Boolean']>;
    /** Question Label */
    questionLabel?: Maybe<LocalizedStringLoose>;
    reportingNotes?: Maybe<Scalars['String']>;
    surveyQuestionDisabled?: Maybe<Scalars['Boolean']>;
    systemGenerated?: Maybe<Scalars['Boolean']>;
    /** @deprecated not used */
    tagId?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
    valueType: UserProfileValueType;
};

export type UserProfileDefChoice = {
    __typename?: 'UserProfileDefChoice';
    /** Choice Label (as a survey question answer) */
    answerLabel?: Maybe<LocalizedStringLoose>;
    id: Scalars['ObjectId'];
    /** Choice Label */
    label?: Maybe<LocalizedString>;
    name?: Maybe<Scalars['String']>;
    specifyValue: Scalars['Boolean'];
    value?: Maybe<UserProfileDefChoiceValue>;
};

export type UserProfileDefChoiceInput = {
    /** Choice Label (as a survey question answer) */
    answerLabel?: InputMaybe<LocalizedStringLooseInput>;
    id: Scalars['ObjectId'];
    /** Choice Label */
    label?: InputMaybe<LocalizedStringInput>;
    name?: InputMaybe<Scalars['String']>;
    specifyValue: Scalars['Boolean'];
    value?: InputMaybe<UserProfileDefChoiceValueInput>;
};

export type UserProfileDefChoiceValue = {
    __typename?: 'UserProfileDefChoiceValue';
    bool?: Maybe<Scalars['Boolean']>;
    date?: Maybe<Scalars['DateTime']>;
    num?: Maybe<Scalars['Float']>;
    str?: Maybe<Scalars['String']>;
};

export type UserProfileDefChoiceValueInput = {
    bool?: InputMaybe<Scalars['Boolean']>;
    date?: InputMaybe<Scalars['DateTime']>;
    num?: InputMaybe<Scalars['Float']>;
    str?: InputMaybe<Scalars['String']>;
};

export type UserProfileDefFieldInListFilterInput = {
    _id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UserProfileDefFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<UserProfileDefUpdateInput>;
    fieldsInList?: InputMaybe<UserProfileDefFieldInListFilterInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type UserProfileDefHistoryItem = {
    __typename?: 'UserProfileDefHistoryItem';
    afterState?: Maybe<Scalars['String']>;
    beforeState?: Maybe<Scalars['String']>;
    datetime: Scalars['DateTime'];
    name: Scalars['String'];
    type: Scalars['String'];
    userId: Scalars['String'];
    username: Scalars['String'];
};

export type UserProfileDefHistoryItemInput = {
    afterState?: InputMaybe<Scalars['String']>;
    beforeState?: InputMaybe<Scalars['String']>;
    datetime: Scalars['DateTime'];
    name: Scalars['String'];
    type: Scalars['String'];
    userId: Scalars['String'];
    username: Scalars['String'];
};

export type UserProfileDefInput = {
    category: Scalars['String'];
    choiceValueType?: InputMaybe<UserProfileValueType>;
    choices?: InputMaybe<Array<InputMaybe<UserProfileDefChoiceInput>>>;
    claimsField?: InputMaybe<Scalars['String']>;
    clientSpecificDisplayName?: InputMaybe<Scalars['String']>;
    conditionTypeId?: InputMaybe<Scalars['ObjectId']>;
    dataSourceNotes?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    dwExternalReports?: InputMaybe<Scalars['String']>;
    dwInternalReports?: InputMaybe<Scalars['String']>;
    dwLocation?: InputMaybe<Scalars['String']>;
    dwMetrics?: InputMaybe<Scalars['String']>;
    dwObject?: InputMaybe<Scalars['String']>;
    episodeRequired?: InputMaybe<Scalars['Boolean']>;
    haPermission?: InputMaybe<HaPermission>;
    history?: InputMaybe<Array<InputMaybe<UserProfileDefHistoryItemInput>>>;
    icd10DiagnosisCodes?: InputMaybe<Scalars['String']>;
    inboundNotes?: InputMaybe<Scalars['String']>;
    isClientSpecific?: InputMaybe<Scalars['Boolean']>;
    /** Label */
    label?: InputMaybe<LocalizedStringInput>;
    linkToJIRA?: InputMaybe<Scalars['String']>;
    name: Scalars['String'];
    procedureCodes?: InputMaybe<Scalars['String']>;
    purposeAppPersonalization?: InputMaybe<Scalars['Boolean']>;
    purposeDetails?: InputMaybe<Scalars['String']>;
    purposeHumanServices?: InputMaybe<Scalars['Boolean']>;
    /** Question Label */
    questionLabel?: InputMaybe<LocalizedStringLooseInput>;
    reportingNotes?: InputMaybe<Scalars['String']>;
    surveyQuestionDisabled?: InputMaybe<Scalars['Boolean']>;
    systemGenerated?: InputMaybe<Scalars['Boolean']>;
    tagId?: InputMaybe<Scalars['String']>;
    valueType: UserProfileValueType;
};

export type UserProfileDefOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type UserProfileDefPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type UserProfileDefPaginationQueryResponse = PaginationResponse & {
    __typename?: 'UserProfileDefPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<UserProfileDef>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type UserProfileDefQueryInput = {
    filter?: InputMaybe<UserProfileDefFilterInput>;
    orderBy?: InputMaybe<UserProfileDefOrderByInput>;
    pagination?: InputMaybe<UserProfileDefPaginationInput>;
};

export type UserProfileDefUpdateInput = {
    category?: InputMaybe<Scalars['String']>;
    choiceValueType?: InputMaybe<UserProfileValueType>;
    choices?: InputMaybe<Array<InputMaybe<UserProfileDefChoiceInput>>>;
    claimsField?: InputMaybe<Scalars['String']>;
    clientSpecificDisplayName?: InputMaybe<Scalars['String']>;
    conditionTypeId?: InputMaybe<Scalars['ObjectId']>;
    dataSourceNotes?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    dwExternalReports?: InputMaybe<Scalars['String']>;
    dwInternalReports?: InputMaybe<Scalars['String']>;
    dwLocation?: InputMaybe<Scalars['String']>;
    dwMetrics?: InputMaybe<Scalars['String']>;
    dwObject?: InputMaybe<Scalars['String']>;
    episodeRequired?: InputMaybe<Scalars['Boolean']>;
    haPermission?: InputMaybe<HaPermission>;
    history?: InputMaybe<Array<InputMaybe<UserProfileDefHistoryItemInput>>>;
    icd10DiagnosisCodes?: InputMaybe<Scalars['String']>;
    inboundNotes?: InputMaybe<Scalars['String']>;
    isClientSpecific?: InputMaybe<Scalars['Boolean']>;
    /** Label */
    label?: InputMaybe<LocalizedStringInput>;
    linkToJIRA?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    procedureCodes?: InputMaybe<Scalars['String']>;
    purposeAppPersonalization?: InputMaybe<Scalars['Boolean']>;
    purposeDetails?: InputMaybe<Scalars['String']>;
    purposeHumanServices?: InputMaybe<Scalars['Boolean']>;
    /** Label (as a survey question) */
    questionLabel?: InputMaybe<LocalizedStringLooseInput>;
    reportingNotes?: InputMaybe<Scalars['String']>;
    surveyQuestionDisabled?: InputMaybe<Scalars['Boolean']>;
    systemGenerated?: InputMaybe<Scalars['Boolean']>;
    valueType?: InputMaybe<UserProfileValueType>;
};

export type UserProfileSpecifiedValueSchema = {
    __typename?: 'UserProfileSpecifiedValueSchema';
    bool?: Maybe<Scalars['Boolean']>;
    date?: Maybe<Scalars['DateTime']>;
    num?: Maybe<Scalars['Float']>;
    str?: Maybe<Scalars['String']>;
};

export type UserProfileSpecifiedValueSchemaInput = {
    bool?: InputMaybe<Scalars['Boolean']>;
    date?: InputMaybe<Scalars['DateTime']>;
    num?: InputMaybe<Scalars['Float']>;
    str?: InputMaybe<Scalars['String']>;
};

/** The data type of a user specified field profile value. */
export enum UserProfileSpecifiedValueType {
    Bool = 'bool',
    Date = 'date',
    Num = 'num',
    Str = 'str',
}

export type UserProfileValueSchema = {
    __typename?: 'UserProfileValueSchema';
    bool?: Maybe<Scalars['Boolean']>;
    bools?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
    choice?: Maybe<Scalars['String']>;
    choices?: Maybe<Array<Maybe<Scalars['String']>>>;
    date?: Maybe<Scalars['DateTime']>;
    /** The (optional) name of the local time zone for `date` or `dates` */
    dateTimeZone?: Maybe<Scalars['String']>;
    /** The (optional) difference in hours between the local time and UTC for `date` or `dates` */
    dateUtcOffset?: Maybe<Scalars['Float']>;
    dates?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
    localized?: Maybe<LocalizedString>;
    num?: Maybe<Scalars['Float']>;
    nums?: Maybe<Array<Maybe<Scalars['Float']>>>;
    specified?: Maybe<Array<Maybe<ProxiedSpecifiedValue>>>;
    str?: Maybe<Scalars['String']>;
    strs?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserProfileValueSchemaInput = {
    bool?: InputMaybe<Scalars['Boolean']>;
    bools?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
    choice?: InputMaybe<Scalars['String']>;
    choices?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    date?: InputMaybe<Scalars['DateTime']>;
    /** The (optional) name of the local time zone for `date` or `dates` */
    dateTimeZone?: InputMaybe<Scalars['String']>;
    /** The (optional) difference in hours between the local time and UTC for `date` or `dates` */
    dateUtcOffset?: InputMaybe<Scalars['Float']>;
    dates?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
    localized?: InputMaybe<LocalizedStringInput>;
    num?: InputMaybe<Scalars['Float']>;
    nums?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
    specified?: InputMaybe<Array<InputMaybe<ProxiedSpecifiedValueInput>>>;
    str?: InputMaybe<Scalars['String']>;
    strs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** The type of value that will be stored for this user profile definition */
export enum UserProfileValueType {
    Bool = 'bool',
    Bools = 'bools',
    Choice = 'choice',
    Choices = 'choices',
    Date = 'date',
    Dates = 'dates',
    Localized = 'localized',
    Num = 'num',
    Nums = 'nums',
    Str = 'str',
    Strs = 'strs',
}

export type UserQueryInput = {
    filter?: InputMaybe<UserFilterInput>;
    orderBy?: InputMaybe<UserOrderByInput>;
    pagination?: InputMaybe<UserPaginationInput>;
};

export type UserSurveyResponsesInput = {
    assignedTo?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<UserSurveyResponsesFilterInput>;
    filterPatient?: InputMaybe<SurveyPatientFilterInput>;
    filterTestData?: InputMaybe<Scalars['Boolean']>;
    orderBy?: InputMaybe<UserSurveyResponsesOrderByInput>;
    pagination?: InputMaybe<UserSurveyResponsesPaginationInput>;
    tagIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type UserSurveyResponsesResponse = PaginationResponse & {
    __typename?: 'UserSurveyResponsesResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<SurveyResponse>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type UserUpdateInput = {
    affiliateId?: InputMaybe<Scalars['ObjectId']>;
    clientOrgId?: InputMaybe<Scalars['ObjectId']>;
    /** Defaults to root org */
    currentOrg?: InputMaybe<Scalars['ObjectId']>;
    email?: InputMaybe<Scalars['String']>;
    hgUserId?: InputMaybe<Scalars['String']>;
    /** Currently represents whether a user is sent an invite email @_@ */
    isStaff?: InputMaybe<Scalars['Boolean']>;
    isTestData?: InputMaybe<Scalars['Boolean']>;
    name?: InputMaybe<Scalars['String']>;
    photo?: InputMaybe<Scalars['String']>;
    roleId?: InputMaybe<Scalars['ObjectId']>;
    rootOrg?: InputMaybe<Scalars['ObjectId']>;
    smsNumber?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
};

export type VcAffiliatePatientsResponse = PaginationResponse & {
    __typename?: 'VCAffiliatePatientsResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<Patient>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type VcEligibilityResults = PaginationResponse & {
    __typename?: 'VCEligibilityResults';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<VirtualCareEligibilityResult>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type VcEligibilityResultsV2FilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<VirtualCareEligibilityResultUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type VcEligibilityResultsV2OrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type VcEligibilityResultsV2PaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type VcfSettings = {
    __typename?: 'VCFSettings';
    showOnlyAtHomeZipCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type VcZipCodeToLocation = BaseEntity & {
    __typename?: 'VCZipCodeToLocation';
    affiliate?: Maybe<VirtualCareAffiliatesEntity>;
    affiliateId?: Maybe<Scalars['ObjectId']>;
    createdAt: Scalars['DateTime'];
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    locationId?: Maybe<Scalars['String']>;
    locationName: Scalars['String'];
    routingMethod: RoutingMethod;
    updatedAt: Scalars['DateTime'];
    zipCode: Scalars['String'];
};

export type VcZipCodeToLocationFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<VcZipCodeToLocationUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type VcZipCodeToLocationOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type VcZipCodeToLocationPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type VcZipCodeToLocationPaginationQueryResponse = PaginationResponse & {
    __typename?: 'VCZipCodeToLocationPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<VcZipCodeToLocation>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type VcZipCodeToLocationQueryInput = {
    filter?: InputMaybe<VcZipCodeToLocationFilterInput>;
    orderBy?: InputMaybe<VcZipCodeToLocationOrderByInput>;
    pagination?: InputMaybe<VcZipCodeToLocationPaginationInput>;
};

export type VcZipCodeToLocationSearchFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<VcZipCodeToLocationUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type VcZipCodeToLocationSearchInput = {
    filter?: InputMaybe<VcZipCodeToLocationSearchFilterInput>;
    orderBy?: InputMaybe<VcZipCodeToLocationSearchOrderByInput>;
    pagination?: InputMaybe<VcZipCodeToLocationSearchPaginationInput>;
    search?: InputMaybe<Scalars['String']>;
};

export type VcZipCodeToLocationSearchOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type VcZipCodeToLocationSearchPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type VcZipCodeToLocationSearchResponse = PaginationResponse & {
    __typename?: 'VCZipCodeToLocationSearchResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<VcZipCodeToLocation>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type VcZipCodeToLocationUpdateInput = {
    affiliateId?: InputMaybe<Scalars['ObjectId']>;
    locationId?: InputMaybe<Scalars['String']>;
    locationName?: InputMaybe<Scalars['String']>;
    routingMethod?: InputMaybe<RoutingMethod>;
    zipCode?: InputMaybe<Scalars['String']>;
};

export type ValidateAppUserTokenInput = {
    appUserToken: Scalars['String'];
};

export type ValidateAppUserTokenResponse = MutationResponse & {
    __typename?: 'ValidateAppUserTokenResponse';
    data?: Maybe<AppUserResponseData>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type ValidateMfaInput = {
    mfaCode: Scalars['String'];
};

/** Value of the field "origin" which indicates whether a form is a self check form */
export enum VcFormOrigin {
    SelfCheck = 'SelfCheck',
    VanityUrl = 'VanityUrl',
}

export type VendorByIdResponse = {
    __typename?: 'VendorByIdResponse';
    address1?: Maybe<Scalars['String']>;
    address2?: Maybe<Scalars['String']>;
    addressCity?: Maybe<Scalars['String']>;
    addressState?: Maybe<Scalars['String']>;
    addressZip?: Maybe<Scalars['String']>;
    nameOnCheck?: Maybe<Scalars['String']>;
    vendorId?: Maybe<Scalars['String']>;
};

export type VirtualCareAffiliateByAffiliateIdResponse = {
    __typename?: 'VirtualCareAffiliateByAffiliateIdResponse';
    data?: Maybe<VirtualCareAffiliatesEntity>;
};

export type VirtualCareAffiliateByIdResponse = {
    __typename?: 'VirtualCareAffiliateByIdResponse';
    affiliateSelfCheckURL?: Maybe<Scalars['String']>;
    affiliateURL?: Maybe<Scalars['String']>;
    data?: Maybe<VirtualCareAffiliatesEntity>;
};

export type VirtualCareAffiliatesEntity = BaseEntity & {
    __typename?: 'VirtualCareAffiliatesEntity';
    BillDotComVendorId?: Maybe<Scalars['String']>;
    affiliateId?: Maybe<Scalars['String']>;
    contactEmail?: Maybe<Scalars['String']>;
    contactName?: Maybe<Scalars['String']>;
    contactPhoneNumber?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    /** Text on successful eligibility */
    customSuccessText?: Maybe<Scalars['String']>;
    emailAddressForScheduling?: Maybe<Scalars['String']>;
    externalName: Scalars['String'];
    id: Scalars['ObjectId'];
    internalName: Scalars['String'];
    inviteAffiliate?: Maybe<Scalars['Boolean']>;
    isTestData?: Maybe<Scalars['Boolean']>;
    notificationEmail?: Maybe<Array<Maybe<Scalars['String']>>>;
    notifyCompanyWhenPatientPassesEligibility?: Maybe<Scalars['Boolean']>;
    /** Possible values are ROUTE_DIRECTLY (“Route Directly To URL”) or ROUTE_TO_LANDING (“Route To A Landing Page”) */
    onSuccessfulEligibility?: Maybe<Scalars['String']>;
    onSuccessfulZipCodeMatch?: Maybe<OnSuccessfulZipCodeMatch>;
    phoneNumberForScheduling?: Maybe<Scalars['String']>;
    routingURL?: Maybe<Scalars['String']>;
    supportMethodType?: Maybe<SupportMethodType>;
    supportMethodValue?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
    website?: Maybe<Scalars['String']>;
    zipCodeMatchCustomSuccessString?: Maybe<Scalars['String']>;
    /** @deprecated Removed from ui */
    zipCodeMatchRoutingURL?: Maybe<Scalars['String']>;
};

export type VirtualCareAffiliatesFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<VirtualCareAffiliatesUpdateInput>;
    fieldsInList?: InputMaybe<GetVirtualCareAffiliatesFilterInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type VirtualCareAffiliatesMutationResponse = MutationResponse & {
    __typename?: 'VirtualCareAffiliatesMutationResponse';
    data?: Maybe<VirtualCareAffiliatesEntity>;
    message: Scalars['String'];
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type VirtualCareAffiliatesOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type VirtualCareAffiliatesPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type VirtualCareAffiliatesPaginationQueryResponse = PaginationResponse & {
    __typename?: 'VirtualCareAffiliatesPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<VirtualCareAffiliatesEntity>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type VirtualCareAffiliatesQueryInput = {
    filter?: InputMaybe<VirtualCareAffiliatesFilterInput>;
    orderBy?: InputMaybe<VirtualCareAffiliatesOrderByInput>;
    pagination?: InputMaybe<VirtualCareAffiliatesPaginationInput>;
};

export type VirtualCareAffiliatesResponse = PaginationResponse & {
    __typename?: 'VirtualCareAffiliatesResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<VirtualCareAffiliatesEntity>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type VirtualCareAffiliatesUpdateInput = {
    BillDotComAddress1?: InputMaybe<Scalars['String']>;
    BillDotComAddress2?: InputMaybe<Scalars['String']>;
    BillDotComAddressCity?: InputMaybe<Scalars['String']>;
    BillDotComAddressState?: InputMaybe<Scalars['String']>;
    BillDotComAddressZip?: InputMaybe<Scalars['String']>;
    BillDotComNameOnCheck?: InputMaybe<Scalars['String']>;
    BillDotComVendorId?: InputMaybe<Scalars['String']>;
    contactEmail?: InputMaybe<Scalars['String']>;
    contactName?: InputMaybe<Scalars['String']>;
    contactPhoneNumber?: InputMaybe<Scalars['String']>;
    /** Text on successful eligibility */
    customSuccessText?: InputMaybe<Scalars['String']>;
    emailAddressForScheduling?: InputMaybe<Scalars['String']>;
    externalName: Scalars['String'];
    internalName: Scalars['String'];
    inviteAffiliate?: InputMaybe<Scalars['Boolean']>;
    notificationEmail?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    notifyCompanyWhenPatientPassesEligibility?: InputMaybe<Scalars['Boolean']>;
    /** Possible values are ROUTE_DIRECTLY (“Route Directly To URL”) or ROUTE_TO_LANDING (“Route To A Landing Page”) */
    onSuccessfulEligibility?: InputMaybe<Scalars['String']>;
    onSuccessfulZipCodeMatch?: InputMaybe<OnSuccessfulZipCodeMatch>;
    phoneNumberForScheduling?: InputMaybe<Scalars['String']>;
    routingURL?: InputMaybe<Scalars['String']>;
    supportMethodType?: InputMaybe<SupportMethodType>;
    supportMethodValue?: InputMaybe<Scalars['String']>;
    website?: InputMaybe<Scalars['String']>;
    zipCodeMatchCustomSuccessString?: InputMaybe<Scalars['String']>;
    zipCodeMatchRoutingURL?: InputMaybe<Scalars['String']>;
};

export type VirtualCareEligibilityResult = BaseEntity & {
    __typename?: 'VirtualCareEligibilityResult';
    createdAt: Scalars['DateTime'];
    data?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    error?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    lastName?: Maybe<Scalars['String']>;
    passed?: Maybe<Scalars['Boolean']>;
    phoneNumber?: Maybe<Scalars['String']>;
    response?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
};

export type VirtualCareEligibilityResultUpdateInput = {
    _id?: InputMaybe<Scalars['ObjectId']>;
    passed?: InputMaybe<Scalars['Boolean']>;
};

export type VirtualCareEligibilityWhitelist = BaseEntity & {
    __typename?: 'VirtualCareEligibilityWhitelist';
    createdAt: Scalars['DateTime'];
    firstName?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    lastName?: Maybe<Scalars['String']>;
    memberId?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
};

export type VirtualCareEligibilityWhitelistFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<VirtualCareEligibilityWhitelistUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type VirtualCareEligibilityWhitelistInput = {
    firstName?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
    memberId?: InputMaybe<Scalars['String']>;
};

export type VirtualCareEligibilityWhitelistOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type VirtualCareEligibilityWhitelistPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type VirtualCareEligibilityWhitelistPaginationQueryResponse = PaginationResponse & {
    __typename?: 'VirtualCareEligibilityWhitelistPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<VirtualCareEligibilityWhitelist>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type VirtualCareEligibilityWhitelistQueryInput = {
    filter?: InputMaybe<VirtualCareEligibilityWhitelistFilterInput>;
    orderBy?: InputMaybe<VirtualCareEligibilityWhitelistOrderByInput>;
    pagination?: InputMaybe<VirtualCareEligibilityWhitelistPaginationInput>;
};

export type VirtualCareEligibilityWhitelistUpdateInput = {
    firstName?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
    memberId?: InputMaybe<Scalars['String']>;
};

export type VirtualCareForm = BaseEntity & {
    __typename?: 'VirtualCareForm';
    Destination?: Maybe<Scalars['String']>;
    address?: Maybe<Address>;
    affiliateId?: Maybe<Scalars['String']>;
    affiliateZipCodeFlow?: Maybe<Scalars['Boolean']>;
    appointmentMethod?: Maybe<AppointmentMethod>;
    birthDate?: Maybe<Scalars['DateTime']>;
    createdAt: Scalars['DateTime'];
    eligibilityRequestId?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    id: Scalars['ObjectId'];
    insuranceGroupNumber?: Maybe<Scalars['String']>;
    insuranceId?: Maybe<Scalars['String']>;
    insuredAddress?: Maybe<Address>;
    insuredFirstName?: Maybe<Scalars['String']>;
    insuredLastName?: Maybe<Scalars['String']>;
    insuredPhoneNumber?: Maybe<Scalars['String']>;
    isTestData?: Maybe<Scalars['Boolean']>;
    lastName?: Maybe<Scalars['String']>;
    organizationId?: Maybe<Scalars['ObjectId']>;
    origin?: Maybe<VcFormOrigin>;
    patientId?: Maybe<Scalars['ObjectId']>;
    patientRelationshipToInsured?: Maybe<Scalars['String']>;
    phoneNumber?: Maybe<Scalars['String']>;
    redirectUrl?: Maybe<Scalars['String']>;
    selfCheck?: Maybe<Scalars['Boolean']>;
    sex?: Maybe<Scalars['String']>;
    subscriberBirthDate?: Maybe<Scalars['DateTime']>;
    subscriberSex?: Maybe<Scalars['String']>;
    successMessage?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
};

export type VirtualCareFormFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<VirtualCareFormUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type VirtualCareFormInput = {
    Destination?: InputMaybe<Scalars['String']>;
    address?: InputMaybe<AddressInput>;
    affiliateId?: InputMaybe<Scalars['String']>;
    affiliateZipCodeFlow?: InputMaybe<Scalars['Boolean']>;
    appointmentMethod?: InputMaybe<AppointmentMethod>;
    birthDate?: InputMaybe<Scalars['DateTime']>;
    eligibilityRequestId?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    firstName?: InputMaybe<Scalars['String']>;
    insuranceGroupNumber?: InputMaybe<Scalars['String']>;
    insuranceId?: InputMaybe<Scalars['String']>;
    insuredAddress?: InputMaybe<AddressInput>;
    insuredFirstName?: InputMaybe<Scalars['String']>;
    insuredLastName?: InputMaybe<Scalars['String']>;
    insuredPhoneNumber?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    origin?: InputMaybe<VcFormOrigin>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    patientRelationshipToInsured?: InputMaybe<Scalars['String']>;
    phoneNumber?: InputMaybe<Scalars['String']>;
    redirectUrl?: InputMaybe<Scalars['String']>;
    selfCheck?: InputMaybe<Scalars['Boolean']>;
    sex?: InputMaybe<Scalars['String']>;
    subscriberBirthDate?: InputMaybe<Scalars['DateTime']>;
    subscriberSex?: InputMaybe<Scalars['String']>;
    successMessage?: InputMaybe<Scalars['String']>;
};

export type VirtualCareFormOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type VirtualCareFormPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type VirtualCareFormPaginationQueryResponse = PaginationResponse & {
    __typename?: 'VirtualCareFormPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<VirtualCareForm>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type VirtualCareFormQueryInput = {
    filter?: InputMaybe<VirtualCareFormFilterInput>;
    orderBy?: InputMaybe<VirtualCareFormOrderByInput>;
    pagination?: InputMaybe<VirtualCareFormPaginationInput>;
};

export type VirtualCareFormUpdateInput = {
    _id?: InputMaybe<Scalars['ObjectId']>;
    address?: InputMaybe<AddressInput>;
    affiliateId?: InputMaybe<Scalars['String']>;
    appointmentMethod?: InputMaybe<AppointmentMethod>;
    birthDate?: InputMaybe<Scalars['DateTime']>;
    email?: InputMaybe<Scalars['String']>;
    firstName?: InputMaybe<Scalars['String']>;
    insuranceGroupNumber?: InputMaybe<Scalars['String']>;
    insuranceId?: InputMaybe<Scalars['String']>;
    insuredAddress?: InputMaybe<AddressInput>;
    insuredFirstName?: InputMaybe<Scalars['String']>;
    insuredLastName?: InputMaybe<Scalars['String']>;
    insuredPhoneNumber?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    patientRelationshipToInsured?: InputMaybe<Scalars['String']>;
    phoneNumber?: InputMaybe<Scalars['String']>;
    sex?: InputMaybe<Scalars['String']>;
    subscriberBirthDate?: InputMaybe<Scalars['DateTime']>;
    subscriberSex?: InputMaybe<Scalars['String']>;
};

export type VolumeFeedingInput = {
    alexaData?: InputMaybe<AlexaDataInput>;
    createdAt?: InputMaybe<Scalars['DateTime']>;
    createdBy?: InputMaybe<Scalars['String']>;
    datetime?: InputMaybe<Scalars['DateTime']>;
    id?: InputMaybe<Scalars['String']>;
    note?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    unit?: InputMaybe<Scalars['String']>;
    volume?: InputMaybe<Scalars['Float']>;
};

export type VolumeFeedingType = {
    __typename?: 'VolumeFeedingType';
    alexaData?: Maybe<AlexaDataType>;
    createdAt?: Maybe<Scalars['DateTime']>;
    createdBy?: Maybe<Scalars['String']>;
    datetime?: Maybe<Scalars['DateTime']>;
    id?: Maybe<Scalars['String']>;
    note?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    unit?: Maybe<Scalars['String']>;
    volume?: Maybe<Scalars['Float']>;
};

export type WeightMeasurement = {
    __typename?: 'WeightMeasurement';
    measure?: Maybe<Scalars['Float']>;
    units?: Maybe<Scalars['String']>;
};

export type WeightMeasurementInput = {
    measure?: InputMaybe<Scalars['Float']>;
    units?: InputMaybe<Scalars['String']>;
};

export type WhatsNewTimeline = BaseEntity & {
    __typename?: 'WhatsNewTimeline';
    createdAt: Scalars['DateTime'];
    /** Description */
    description?: Maybe<LocalizedString>;
    fixedTargetDate?: Maybe<Scalars['DateTime']>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    /** Label */
    label: LocalizedString;
    targetDateProfileDef?: Maybe<UserProfileDef>;
    targetDateProfileDefId?: Maybe<Scalars['ObjectId']>;
    type: WhatsNewTimelineType;
    updatedAt: Scalars['DateTime'];
};

export type WhatsNewTimelineFieldInListFilterInput = {
    targetDateProfileDefId?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
    type?: InputMaybe<Array<InputMaybe<WhatsNewTimelineType>>>;
};

export type WhatsNewTimelineFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<WhatsNewTimelineUpdateInput>;
    fieldsInList?: InputMaybe<WhatsNewTimelineFieldInListFilterInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type WhatsNewTimelineInput = {
    /** Description */
    description?: InputMaybe<LocalizedStringInput>;
    fixedTargetDate?: InputMaybe<Scalars['DateTime']>;
    /** Label */
    label: LocalizedStringInput;
    targetDateProfileDefId?: InputMaybe<Scalars['ObjectId']>;
    type: WhatsNewTimelineType;
};

export type WhatsNewTimelineOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type WhatsNewTimelinePaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type WhatsNewTimelinePaginationQueryResponse = PaginationResponse & {
    __typename?: 'WhatsNewTimelinePaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<WhatsNewTimeline>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type WhatsNewTimelineQueryInput = {
    filter?: InputMaybe<WhatsNewTimelineFilterInput>;
    orderBy?: InputMaybe<WhatsNewTimelineOrderByInput>;
    pagination?: InputMaybe<WhatsNewTimelinePaginationInput>;
};

/** What's new timeline type */
export enum WhatsNewTimelineType {
    Appointment = 'appointment',
    Fixed = 'fixed',
    Profile = 'profile',
}

export type WhatsNewTimelineUpdateInput = {
    description?: InputMaybe<LocalizedStringInput>;
    fixedTargetDate?: InputMaybe<Scalars['DateTime']>;
    label?: InputMaybe<LocalizedStringInput>;
    targetDateProfileDefId?: InputMaybe<Scalars['ObjectId']>;
    type?: InputMaybe<WhatsNewTimelineType>;
};

/** The type of entity in the where used data. */
export enum WhereUsedEntity {
    Advocatetaskchecklist = 'advocatetaskchecklist',
    Advocatetasktemplate = 'advocatetasktemplate',
    Article = 'article',
    Articlepromotion = 'articlepromotion',
    Bioscreen = 'bioscreen',
    Careplan = 'careplan',
    Careteamcontact = 'careteamcontact',
    Careteammember = 'careteammember',
    Careteammembertype = 'careteammembertype',
    Configvalue = 'configvalue',
    Configvaluedef = 'configvaluedef',
    Contentdirectory = 'contentdirectory',
    Datetrigger = 'datetrigger',
    Eventtrigger = 'eventtrigger',
    Expression = 'expression',
    Highlight = 'highlight',
    Intervention = 'intervention',
    Patientaction = 'patientaction',
    Profiledef = 'profiledef',
    Simplecontent = 'simplecontent',
    Survey = 'survey',
    Tag = 'tag',
    Timeline = 'timeline',
    User = 'user',
    Whatsnewinstance = 'whatsnewinstance',
}

export type WhoToCall = BaseEntity & {
    __typename?: 'WhoToCall';
    createdAt: Scalars['DateTime'];
    entries?: Maybe<Array<Maybe<WhoToCallEntriesType>>>;
    id: Scalars['ObjectId'];
    isTestData?: Maybe<Scalars['Boolean']>;
    loggerVersion: Scalars['String'];
    organizationId?: Maybe<Scalars['ObjectId']>;
    patientId: Scalars['ObjectId'];
    softly_deleted?: Maybe<Scalars['Boolean']>;
    timestamp: Scalars['String'];
    type?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
    uuid?: Maybe<Scalars['String']>;
};

export type WhoToCallEntriesInput = {
    aptOrSuiteOrBldg?: InputMaybe<Scalars['String']>;
    city?: InputMaybe<Scalars['String']>;
    company?: InputMaybe<Scalars['String']>;
    createdAt?: InputMaybe<Scalars['DateTime']>;
    id?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    phoneNumber?: InputMaybe<Scalars['String']>;
    street?: InputMaybe<Scalars['String']>;
    usState?: InputMaybe<Scalars['String']>;
    zip?: InputMaybe<Scalars['String']>;
};

export type WhoToCallEntriesType = {
    __typename?: 'WhoToCallEntriesType';
    aptOrSuiteOrBldg?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    company?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    id?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    phoneNumber?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
    usState?: Maybe<Scalars['String']>;
    zip?: Maybe<Scalars['String']>;
};

export type WhoToCallFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<WhoToCallUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type WhoToCallInput = {
    entries?: InputMaybe<Array<InputMaybe<WhoToCallEntriesInput>>>;
    loggerVersion: Scalars['String'];
    organizationId?: InputMaybe<Scalars['ObjectId']>;
    patientId: Scalars['ObjectId'];
    softly_deleted?: InputMaybe<Scalars['Boolean']>;
    timestamp: Scalars['String'];
    type?: InputMaybe<Scalars['String']>;
    uuid?: InputMaybe<Scalars['String']>;
};

export type WhoToCallMutationResponse = MutationResponse & {
    __typename?: 'WhoToCallMutationResponse';
    message: Scalars['String'];
    resource?: Maybe<WhoToCall>;
    status: Scalars['Int'];
    success: Scalars['Boolean'];
};

export type WhoToCallOrderByInput = {
    field: Scalars['String'];
    order: OrderByDirectionEnum;
};

export type WhoToCallPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type WhoToCallPaginationQueryResponse = PaginationResponse & {
    __typename?: 'WhoToCallPaginationQueryResponse';
    hasMore?: Maybe<Scalars['Boolean']>;
    hasPrevious?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Float']>;
    orderBy?: Maybe<Scalars['String']>;
    results: Array<WhoToCall>;
    skip?: Maybe<Scalars['Float']>;
    total: Scalars['Float'];
};

export type WhoToCallQueryInput = {
    filter?: InputMaybe<WhoToCallFilterInput>;
    orderBy?: InputMaybe<WhoToCallOrderByInput>;
    pagination?: InputMaybe<WhoToCallPaginationInput>;
};

export type WhoToCallUpdateInput = {
    entries?: InputMaybe<Array<InputMaybe<WhoToCallEntriesInput>>>;
    loggerVersion?: InputMaybe<Scalars['String']>;
    patientId?: InputMaybe<Scalars['ObjectId']>;
    softly_deleted?: InputMaybe<Scalars['Boolean']>;
    timestamp?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    uuid?: InputMaybe<Scalars['String']>;
};

export type WhoToCallsByPatientIdsInput = {
    patientIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
};

export type MappedCta = {
    __typename?: 'mappedCTA';
    action?: Maybe<Scalars['String']>;
    buttonText?: Maybe<Scalars['String']>;
    link?: Maybe<Scalars['String']>;
    message?: Maybe<Scalars['String']>;
    phoneNumber?: Maybe<Scalars['String']>;
    qListText?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export type UserAppointmentsFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<AppointmentUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type UserAppointmentsOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type UserAppointmentsPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type UserChatRoomsFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<ChatRoomUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type UserChatRoomsOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type UserChatRoomsPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type UserMeasurementsFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<MeasurementUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type UserMeasurementsOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type UserMeasurementsPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type UserSurveyResponsesFilterInput = {
    createdAfter?: InputMaybe<Scalars['DateTime']>;
    createdBefore?: InputMaybe<Scalars['DateTime']>;
    fields?: InputMaybe<SurveyResponseUpdateInput>;
    updatedAfter?: InputMaybe<Scalars['DateTime']>;
    updatedBefore?: InputMaybe<Scalars['DateTime']>;
};

export type UserSurveyResponsesOrderByInput = {
    field: Scalars['String'];
    fieldIsProfileDefId?: InputMaybe<Scalars['Boolean']>;
    order: OrderByDirectionEnum;
};

export type UserSurveyResponsesPaginationInput = {
    limit: Scalars['Int'];
    skip: Scalars['Int'];
};

export type ArticleByIdForArticlePickerQueryVariables = Exact<{
    input: GetArticleInput;
}>;

export type ArticleByIdForArticlePickerQuery = {
    __typename?: 'Query';
    article?: { __typename?: 'Article'; name: string; id: any } | null;
};

export type ArticlesByCriteriaQueryVariables = Exact<{
    input: ArticlePickerFetchInput;
}>;

export type ArticlesByCriteriaQuery = {
    __typename?: 'Query';
    articlePickerFetch: {
        __typename?: 'ArticlePickerFetchResponse';
        total?: number | null;
        data?: Array<{ __typename?: 'Article'; name: string; id: any } | null> | null;
    };
};

export type ArticleCategoriesForArticlePickerQueryVariables = Exact<{ [key: string]: never }>;

export type ArticleCategoriesForArticlePickerQuery = {
    __typename?: 'Query';
    articleCategorysV2: {
        __typename?: 'ArticleCategoryPaginationQueryResponse';
        results: Array<{ __typename?: 'ArticleCategory'; id: any; name: string }>;
    };
};

export type ArticleGroupsForArticlePickerQueryVariables = Exact<{ [key: string]: never }>;

export type ArticleGroupsForArticlePickerQuery = {
    __typename?: 'Query';
    articleGroupsV2: {
        __typename?: 'ArticleGroupPaginationQueryResponse';
        results: Array<{ __typename?: 'ArticleGroup'; id: any; name: string }>;
    };
};

export type CdPickerFetchQueryVariables = Exact<{
    input: ContentDirectoryPickerFetchInput;
}>;

export type CdPickerFetchQuery = {
    __typename?: 'Query';
    contentDirectoryPickerFetch: {
        __typename?: 'ContentDirectoryPickerFetchResponse';
        total?: number | null;
        data?: Array<{ __typename?: 'ContentDirectory'; name: string; id: any } | null> | null;
    };
};

export type SettingsForEnvLabelQueryVariables = Exact<{ [key: string]: never }>;

export type SettingsForEnvLabelQuery = {
    __typename?: 'Query';
    settings: { __typename?: 'UiSettings'; env?: string | null };
};

export type ImageContentByIdForImageContentPickerQueryVariables = Exact<{
    input: GetSimpleContentInput;
}>;

export type ImageContentByIdForImageContentPickerQuery = {
    __typename?: 'Query';
    simpleContent?: {
        __typename?: 'SimpleContent';
        name: string;
        id: any;
        contentType: ContentType;
        content?: string | null;
    } | null;
};

export type ImageContentByTextFilterQueryVariables = Exact<{
    input: ContentPickerFetchInput;
}>;

export type ImageContentByTextFilterQuery = {
    __typename?: 'Query';
    contentPickerFetch: {
        __typename?: 'ContentPickerFetchResponse';
        total?: number | null;
        data?: Array<{
            __typename?: 'SimpleContent';
            name: string;
            id: any;
            contentType: ContentType;
            content?: string | null;
        } | null> | null;
    };
};

export type FetchCurrentUserLoginQueryVariables = Exact<{ [key: string]: never }>;

export type FetchCurrentUserLoginQuery = {
    __typename?: 'Query';
    currentUser?: { __typename?: 'User'; id: any; name: string; email: string } | null;
};

export type LoginUserForLoginPageMutationVariables = Exact<{
    input: LoginUserInput;
}>;

export type LoginUserForLoginPageMutation = {
    __typename?: 'Mutation';
    loginUser?: {
        __typename?: 'LoginUserResponse';
        success: boolean;
        message: string;
        newPasswordRequired?: boolean | null;
        tempPasswordToken?: string | null;
        tokens?: { __typename?: 'CognitoTokens'; idToken: string; accessToken: string } | null;
    } | null;
};

export type ResetPasswordLoginPageMutationVariables = Exact<{
    input: ResetPasswordInput;
}>;

export type ResetPasswordLoginPageMutation = {
    __typename?: 'Mutation';
    resetPassword?: {
        __typename?: 'ResetPasswordResponse';
        success: boolean;
        message: string;
    } | null;
};

export type LoginAuthMethodQueryVariables = Exact<{
    input?: InputMaybe<LoginAuthMethodInput>;
}>;

export type LoginAuthMethodQuery = {
    __typename?: 'Query';
    loginAuthMethod?: {
        __typename?: 'LoginAuthMethodResponse';
        type?: string | null;
        samlRequest?: string | null;
        samlUrl?: string | null;
        samlUrlBase?: string | null;
    } | null;
};

export type ContentByIdForContentPickerQueryVariables = Exact<{
    input: GetSimpleContentInput;
}>;

export type ContentByIdForContentPickerQuery = {
    __typename?: 'Query';
    simpleContent?: {
        __typename?: 'SimpleContent';
        name: string;
        id: any;
        contentType: ContentType;
    } | null;
};

export type ContentByCriteriaQueryVariables = Exact<{
    input: ContentPickerFetchInput;
}>;

export type ContentByCriteriaQuery = {
    __typename?: 'Query';
    contentPickerFetch: {
        __typename?: 'ContentPickerFetchResponse';
        total?: number | null;
        data?: Array<{
            __typename?: 'SimpleContent';
            name: string;
            id: any;
            contentType: ContentType;
        } | null> | null;
    };
};

export type FetchPatientsForSearchModalQueryVariables = Exact<{
    input: GetPatientsByFilterInput;
}>;

export type FetchPatientsForSearchModalQuery = {
    __typename?: 'Query';
    currentUser?: {
        __typename?: 'User';
        patientsV2: {
            __typename?: 'UserPatientsResponse';
            orderBy?: string | null;
            skip?: number | null;
            limit?: number | null;
            total: number;
            hasMore?: boolean | null;
            hasPrevious?: boolean | null;
            results: Array<{
                __typename?: 'Patient';
                id: any;
                createdAt: any;
                externalId?: string | null;
                memberId?: string | null;
                fullName: string;
                firstName: string;
                lastName: string;
                onboardingDate?: any | null;
                email?: string | null;
                birthDate: any;
                phoneNumber?: string | null;
                invitationMode?: PatientInvitation | null;
                invitationCode?: string | null;
                signUpPracticeCode?: string | null;
                isTestData?: boolean | null;
                assignedHealthAdvocate?: any | null;
                openAdvocateTaskCount?: number | null;
                openCriticalAdvocateTaskCount?: number | null;
                onboardingCompleteDate?: any | null;
                isPregnant?: boolean | null;
                dueDate?: any | null;
                deliveryDate?: any | null;
                patientCallStatus?: string | null;
                appUserId?: any | null;
                appBundleId?: string | null;
                attachments?: Array<{
                    __typename?: 'Attachment';
                    uuid: string;
                    label: string;
                } | null> | null;
                riskIndicators?: {
                    __typename?: 'PatientRiskIndicators';
                    obesity?: {
                        __typename?: 'PatientRiskIndicator';
                        flag: boolean;
                        comment?: string | null;
                    } | null;
                    preterm?: {
                        __typename?: 'PatientRiskIndicator';
                        flag: boolean;
                        comment?: string | null;
                    } | null;
                    chronicHypertension?: {
                        __typename?: 'PatientRiskIndicator';
                        flag: boolean;
                        comment?: string | null;
                    } | null;
                    other?: {
                        __typename?: 'PatientRiskIndicator';
                        flag: boolean;
                        comment?: string | null;
                    } | null;
                } | null;
                mailingAddress?: {
                    __typename?: 'Address';
                    street1?: string | null;
                    street2?: string | null;
                    city?: string | null;
                    state?: string | null;
                    code?: string | null;
                } | null;
                practice: {
                    __typename?: 'Organization';
                    id: any;
                    name: string;
                    timezone?: string | null;
                    providers?: Array<{
                        __typename?: 'OrganizationProvider';
                        provider: {
                            __typename?: 'Provider';
                            id: any;
                            localizedName: string;
                            createdAt: any;
                        };
                    }> | null;
                };
                questions: Array<{
                    __typename?: 'CombinedQuestion';
                    id: string;
                    createdAt: any;
                    updatedAt: any;
                    submittedAt: any;
                    text: string;
                    isDone: boolean;
                }>;
                notes: Array<{
                    __typename?: 'PatientNotes';
                    id: any;
                    text?: string | null;
                    title?: string | null;
                    staffName?: string | null;
                    createdAt: any;
                }>;
                chatRoom: {
                    __typename?: 'ChatRoom';
                    id: any;
                    numberOfUnreadMessages?: number | null;
                };
                profileValues: Array<{
                    __typename?: 'PatientProfileValue';
                    profileVariableDefId?: any | null;
                    type?: ProfileValueType | null;
                    valueBool?: boolean | null;
                    valueNum?: number | null;
                    valueStr?: string | null;
                    valueId?: any | null;
                    valueIds?: Array<any | null> | null;
                    valueDate?: any | null;
                    timeZone?: string | null;
                    utcOffset?: number | null;
                }>;
                riskScore?: {
                    __typename?: 'RiskScoreResponse';
                    value?: number | null;
                    tierName?: string | null;
                } | null;
            }>;
        };
    } | null;
};

export type FetchPatientByIdForSearchModalQueryVariables = Exact<{
    input: GetPatientInput;
}>;

export type FetchPatientByIdForSearchModalQuery = {
    __typename?: 'Query';
    patient?: {
        __typename?: 'Patient';
        id: any;
        createdAt: any;
        externalId?: string | null;
        memberId?: string | null;
        fullName: string;
        firstName: string;
        lastName: string;
        onboardingDate?: any | null;
        email?: string | null;
        birthDate: any;
        phoneNumber?: string | null;
        invitationMode?: PatientInvitation | null;
        invitationCode?: string | null;
        signUpPracticeCode?: string | null;
        isTestData?: boolean | null;
        assignedHealthAdvocate?: any | null;
        openAdvocateTaskCount?: number | null;
        openCriticalAdvocateTaskCount?: number | null;
        onboardingCompleteDate?: any | null;
        isPregnant?: boolean | null;
        dueDate?: any | null;
        deliveryDate?: any | null;
        patientCallStatus?: string | null;
        appUserId?: any | null;
        appBundleId?: string | null;
        attachments?: Array<{
            __typename?: 'Attachment';
            uuid: string;
            label: string;
        } | null> | null;
        riskIndicators?: {
            __typename?: 'PatientRiskIndicators';
            obesity?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            preterm?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            chronicHypertension?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            other?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
        } | null;
        mailingAddress?: {
            __typename?: 'Address';
            street1?: string | null;
            street2?: string | null;
            city?: string | null;
            state?: string | null;
            code?: string | null;
        } | null;
        practice: {
            __typename?: 'Organization';
            id: any;
            name: string;
            timezone?: string | null;
            providers?: Array<{
                __typename?: 'OrganizationProvider';
                provider: {
                    __typename?: 'Provider';
                    id: any;
                    localizedName: string;
                    createdAt: any;
                };
            }> | null;
        };
        questions: Array<{
            __typename?: 'CombinedQuestion';
            id: string;
            createdAt: any;
            updatedAt: any;
            submittedAt: any;
            text: string;
            isDone: boolean;
        }>;
        notes: Array<{
            __typename?: 'PatientNotes';
            id: any;
            text?: string | null;
            title?: string | null;
            staffName?: string | null;
            createdAt: any;
        }>;
        chatRoom: { __typename?: 'ChatRoom'; id: any; numberOfUnreadMessages?: number | null };
        profileValues: Array<{
            __typename?: 'PatientProfileValue';
            profileVariableDefId?: any | null;
            type?: ProfileValueType | null;
            valueBool?: boolean | null;
            valueNum?: number | null;
            valueStr?: string | null;
            valueId?: any | null;
            valueIds?: Array<any | null> | null;
            valueDate?: any | null;
            timeZone?: string | null;
            utcOffset?: number | null;
        }>;
        riskScore?: {
            __typename?: 'RiskScoreResponse';
            value?: number | null;
            tierName?: string | null;
        } | null;
    } | null;
};

export type UpdateCurrentOrgInNavMutationVariables = Exact<{
    input: UpdateUserInput;
}>;

export type UpdateCurrentOrgInNavMutation = {
    __typename?: 'Mutation';
    updateUser?: {
        __typename?: 'UpdateUserResponse';
        success: boolean;
        message: string;
        resourceUpdated?: { __typename?: 'User'; id: any; name: string; email: string } | null;
    } | null;
};

export type CurrentUserSubscriptionSubscriptionVariables = Exact<{ [key: string]: never }>;

export type CurrentUserSubscriptionSubscription = {
    __typename?: 'Subscription';
    currentUserSubscription?: {
        __typename?: 'User';
        id: any;
        name: string;
        email: string;
        title?: string | null;
        smsNumber?: string | null;
        clientOrgId?: any | null;
        numberOfUnreadChatMessages?: number | null;
        newTaskNotificationCount?: number | null;
        isTestData?: boolean | null;
        roleId?: any | null;
        currentOrg: {
            __typename?: 'Organization';
            id: any;
            parentId?: any | null;
            brandingName: string;
            timezone?: string | null;
            appPlatform?: string | null;
            appBundleId?: string | null;
            unreadMessagesCount?: number | null;
        };
        rootOrg: {
            __typename?: 'Organization';
            id: any;
            parentId?: any | null;
            brandingName: string;
        };
        organizations: Array<{
            __typename?: 'Organization';
            id: any;
            parentId?: any | null;
            brandingName: string;
            logo?: string | null;
            ancestors?: Array<any | null> | null;
            appBundleId?: string | null;
            unreadMessagesCount?: number | null;
        }>;
        currentOrganizations: Array<{
            __typename?: 'Organization';
            id: any;
            parentId?: any | null;
            brandingName: string;
            logo?: string | null;
        }>;
        role?: {
            __typename?: 'Role';
            id: any;
            name: string;
            permissions?: Array<{
                __typename?: 'Permissions';
                target?: Target | null;
                permission?: Permission | null;
                canExport?: boolean | null;
            } | null> | null;
        } | null;
    } | null;
};

export type AdvocateNotificationsByUserQueryVariables = Exact<{
    input: AadvocateNotificationsByUserInput;
}>;

export type AdvocateNotificationsByUserQuery = {
    __typename?: 'Query';
    advocateNotificationsByUser?: Array<{
        __typename?: 'FullInfoAdvocateNotification';
        createdAt: any;
        id: any;
        isTestData?: boolean | null;
        updatedAt: any;
        userId: any;
        viewed: boolean;
        advocateTask?: {
            __typename?: 'AdvocateTask';
            id: any;
            label: string;
            priority: number;
        } | null;
        patient?: { __typename?: 'Patient'; fullName: string; id: any } | null;
    } | null> | null;
};

export type UpdateUserMutationVariables = Exact<{
    input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
    __typename?: 'Mutation';
    updateUser?: {
        __typename?: 'UpdateUserResponse';
        resourceUpdated?: { __typename?: 'User'; id: any } | null;
    } | null;
};

export type UpdateAdvocateNotificationMutationVariables = Exact<{
    input: UpdateAdvocateNotificationInput;
}>;

export type UpdateAdvocateNotificationMutation = {
    __typename?: 'Mutation';
    updateAdvocateNotification?: {
        __typename?: 'UpdateAdvocateNotificationResponse';
        success: boolean;
        message: string;
    } | null;
};

export type SimpleContentsForRichTextEditorMenuBarImageDialogQueryVariables = Exact<{
    input?: InputMaybe<SimpleContentListInput>;
}>;

export type SimpleContentsForRichTextEditorMenuBarImageDialogQuery = {
    __typename?: 'Query';
    simpleContentsV2: {
        __typename?: 'SimpleContentListResponse';
        results: Array<{
            __typename?: 'SimpleContent';
            id: any;
            name: string;
            contentType: ContentType;
            content?: string | null;
            articleId?: any | null;
            trackerType?: TrackerType | null;
            surveyId?: any | null;
            contentDirectoryId?: any | null;
            label?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
        }>;
    };
};

export type ContentDirectorysV2ForRichTextEditorQueryVariables = Exact<{
    input?: InputMaybe<ContentDirectoryListInput>;
}>;

export type ContentDirectorysV2ForRichTextEditorQuery = {
    __typename?: 'Query';
    contentDirectorysV2: {
        __typename?: 'ContentDirectoryListResponse';
        results: Array<{
            __typename?: 'ContentDirectory';
            id: any;
            displayStyle?: DirectoryDisplayStyle | null;
        }>;
    };
};

export type FetchProfileDefsForRichTextEditorSubstTokenQueryVariables = Exact<{
    [key: string]: never;
}>;

export type FetchProfileDefsForRichTextEditorSubstTokenQuery = {
    __typename?: 'Query';
    userProfileDefs: Array<{ __typename?: 'UserProfileDef'; id: any; name: string }>;
};

export type FetchConfigValueDefsForRichTextEditorSubstTokenQueryVariables = Exact<{
    [key: string]: never;
}>;

export type FetchConfigValueDefsForRichTextEditorSubstTokenQuery = {
    __typename?: 'Query';
    configValueDefs: Array<{ __typename?: 'ConfigValueDef'; id: any; name: string }>;
};

export type FetchSystemTokensForRichTextEditorSubstTokenQueryVariables = Exact<{
    [key: string]: never;
}>;

export type FetchSystemTokensForRichTextEditorSubstTokenQuery = {
    __typename?: 'Query';
    systemTokens: Array<{ __typename?: 'SystemToken'; id: any; name: string }>;
};

export type FetchUploadCareSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchUploadCareSettingsQuery = {
    __typename?: 'Query';
    settings: { __typename?: 'UiSettings'; uploadCarePublicKey?: string | null };
};

export type AdvocateTaskFragmentFragment = {
    __typename?: 'AdvocateTask';
    id: any;
    label: string;
    description: string;
    createdBy: any;
    assignedTo?: any | null;
    watchedBy: Array<any>;
    status: AdvocateTaskTypeStatus;
    priority: number;
    dueDate: any;
    displayDate: any;
    createdAt: any;
    updatedAt: any;
    completedAt?: any | null;
    patientId?: any | null;
    slaTimerId?: any | null;
    typeId: any;
    watchers: Array<{ __typename?: 'User'; id: any; name: string }>;
    patient?: {
        __typename?: 'Patient';
        id: any;
        fullName: string;
        birthDate: any;
        phoneNumber?: string | null;
        practice: { __typename?: 'Organization'; id: any; brandingName: string };
        chatRoom: {
            __typename?: 'ChatRoom';
            id: any;
            participants: Array<{ __typename?: 'User'; id: any }>;
        };
        patientHeaderData?: Array<{
            __typename?: 'PatientHeaderData';
            label?: string | null;
            value?: string | null;
        }> | null;
    } | null;
    checklist: Array<{
        __typename?: 'AdvocateTaskChecklistItem';
        id: any;
        label: string;
        description?: string | null;
        completedAt?: any | null;
        completedBy?: any | null;
        actionTypeIds: Array<any>;
        endSlaTimerOnComplete?: boolean | null;
    }>;
    comments: Array<{
        __typename?: 'AdvocateTaskComment';
        authorId: any;
        message: string;
        when: any;
    }>;
    commentAuthors: Array<{ __typename?: 'User'; id: any; name: string }>;
    taskCreator: { __typename?: 'User'; name: string };
    types: Array<{ __typename?: 'AdvocateTaskType'; id: any; label: string }>;
    usersToAssign: Array<{ __typename?: 'User'; id: any; name: string }>;
    history: Array<{
        __typename?: 'AdvocateTaskHistoryItem';
        id: any;
        whoId: any;
        what: string;
        when: any;
    }>;
    historyFigures: Array<{ __typename?: 'User'; id: any; name: string }>;
    actionTypes: Array<{ __typename?: 'PatientActionType'; id: any; label: string }>;
    patientActions: Array<{
        __typename?: 'PatientAction';
        stepId?: any | null;
        updatedAt: any;
        description?: string | null;
        patientId?: any | null;
        taskId?: any | null;
        typeId?: any | null;
        id: any;
        source: ActionSource;
        completedAt?: any | null;
    }>;
};

export type AdvocateTaskTemplateFragmentFragment = {
    __typename?: 'AdvocateTaskTemplate';
    label: string;
    description: string;
    priority: number;
    createdAt: any;
    updatedAt: any;
    id: any;
    whenType: AdvocateTaskTemplateWhenType;
    typeId: any;
    interventionDurationMinutes?: number | null;
    shouldNotify?: boolean | null;
    slaId?: any | null;
    checklist: Array<{
        __typename?: 'AdvocateTaskChecklistItem';
        id: any;
        label: string;
        description?: string | null;
        completedAt?: any | null;
        completedBy?: any | null;
        actionTypeIds: Array<any>;
        endSlaTimerOnComplete?: boolean | null;
    }>;
    whenTimeline?: {
        __typename?: 'AdvocateTaskTemplateTimeline';
        timelineId: any;
        offsetDays: number;
        offsetMonths: number;
        offsetYears: number;
        durationMinutes: number;
        includeApps?: Array<string> | null;
        excludeApps?: Array<string> | null;
        tags?: Array<string> | null;
    } | null;
};

export type AdvocateTaskTypeFragmentFragment = {
    __typename?: 'AdvocateTaskType';
    label: string;
    createdAt: any;
    updatedAt: any;
    id: any;
};

export type AppointmentFragmentOnApptPageFragment = {
    __typename?: 'Appointment';
    id: any;
    externalId?: string | null;
    startDate: any;
    durationMinutes?: number | null;
    staffName?: string | null;
    physicalLocation?: string | null;
    description?: string | null;
    isVirtualVisit?: boolean | null;
    virtualLink?: string | null;
    visitType?: string | null;
    source: AppointmentSource;
    note?: string | null;
    reportKey?: string | null;
    timezoneName?: string | null;
    patient: {
        __typename?: 'Patient';
        id: any;
        fullName: string;
        firstName: string;
        lastName: string;
        birthDate: any;
    };
    practice?: {
        __typename?: 'Organization';
        id: any;
        name: string;
        timezone?: string | null;
    } | null;
    staff?: { __typename?: 'User'; id: any; name: string } | null;
};

export type ArticleFragmentFragment = {
    __typename?: 'Article';
    id: any;
    name: string;
    description?: string | null;
    keywords?: string | null;
    references?: string | null;
    articleCategoryId: any;
    relatedArticleIds?: Array<any> | null;
    icon?: ContentIcon | null;
    stackId?: number | null;
    imageContentId?: any | null;
    groups?: Array<any> | null;
    reportingCategorys?: Array<any> | null;
    changesToPublish?: boolean | null;
    updatedAt: any;
    imageContent?: { __typename?: 'SimpleContent'; id: any; name: string } | null;
    articleCategory?: { __typename?: 'ArticleCategory'; id: any; name: string } | null;
    callToActions?: Array<{
        __typename?: 'CallToAction';
        id?: any | null;
        type: CtaType;
        simpleContentId?: any | null;
        tagList?: Array<any> | null;
        defaultButtonText?: boolean | null;
        buttonText?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        parameter1?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        parameter2?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        simpleContent?: { __typename?: 'SimpleContent'; name: string } | null;
    }> | null;
    variants?: Array<{
        __typename?: 'ContentVariant';
        language: Language;
        readingLevel: ReadingLevel;
        appBundleId?: string | null;
        imageContentId?: any | null;
        datePublished?: any | null;
        dateEdited?: any | null;
        editedBy?: string | null;
        titleMarkup?: string | null;
        summaryMarkup?: string | null;
        bodyMarkup?: string | null;
        callToActions?: Array<{
            __typename?: 'CallToAction';
            id?: any | null;
            type: CtaType;
            simpleContentId?: any | null;
            tagList?: Array<any> | null;
            defaultButtonText?: boolean | null;
            buttonText?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            parameter1?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            parameter2?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            simpleContent?: { __typename?: 'SimpleContent'; name: string } | null;
        }> | null;
    }> | null;
};

export type ArticleGroupFragFragment = {
    __typename?: 'ArticleGroup';
    id: any;
    name: string;
    includeApps?: Array<string> | null;
    excludeApps?: Array<string> | null;
    createdAt: any;
    updatedAt: any;
};

export type ArticlePromotionFragmentFragment = {
    __typename?: 'ArticlePromotion';
    name?: string | null;
    title?: string | null;
    titleSp?: string | null;
    articleId: any;
    priority: number;
    tagIds?: Array<any> | null;
    type?: ArticlePromotionType | null;
    typeName?: string | null;
    startDate?: any | null;
    endDate?: any | null;
    createdAt: any;
    updatedAt: any;
    isTestData?: boolean | null;
    id: any;
    timelineId?: any | null;
    offsetDays?: number | null;
    offsetMonths?: number | null;
    offsetYears?: number | null;
    duration?: number | null;
    durationTimeUnit?: TimeUnit | null;
    includeApps?: Array<string> | null;
    excludeApps?: Array<string> | null;
    timeline?: {
        __typename?: 'WhatsNewTimeline';
        id: any;
        type: WhatsNewTimelineType;
        fixedTargetDate?: any | null;
        targetDateProfileDefId?: any | null;
        label: { __typename?: 'LocalizedString'; en: string };
        targetDateProfileDef?: {
            __typename?: 'UserProfileDef';
            id: any;
            label?: { __typename?: 'LocalizedString'; en: string } | null;
        } | null;
    } | null;
    article?: { __typename?: 'Article'; id: any; name: string } | null;
};

export type BioScreenForBioScreenBuilderFragment = {
    __typename?: 'BioScreen';
    id: any;
    createdAt: any;
    updatedAt: any;
    label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    yourHealthInfo: {
        __typename?: 'BioScreenShortcut';
        icon: BioScreenShortcutIcon;
        label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        instructions: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    };
    surveyGroups: Array<{
        __typename?: 'BioScreenSurveyGroup';
        id: string;
        tags?: Array<string | null> | null;
        label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        surveys: Array<{
            __typename?: 'BioScreenSurvey';
            surveyId: any;
            tags?: Array<string | null> | null;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        }>;
    }>;
    shortcuts: Array<{
        __typename?: 'BioScreenShortcut';
        type: BioScreenShortcutType;
        icon: BioScreenShortcutIcon;
        surveyId?: any | null;
        surveyGroupId?: string | null;
        tags?: Array<string | null> | null;
        label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        instructions: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    }>;
};

export type CarePlanFragmentFragment = {
    __typename?: 'CarePlan';
    type: CarePlanType;
    baseCarePlanId?: any | null;
    timelineId?: any | null;
    includeApps?: Array<string> | null;
    excludeApps?: Array<string> | null;
    tags?: Array<string> | null;
    viewLimitMinutes?: number | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    portalLabel?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
    appLabel?: { __typename?: 'LocalizedString'; es?: string | null; en: string } | null;
    groups: Array<{
        __typename?: 'CarePlanTodoGroup';
        id: any;
        suppressedTemplates?: Array<any | null> | null;
        label?: { __typename?: 'LocalizedString'; es?: string | null; en: string } | null;
        description?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        templates: Array<{
            __typename?: 'CarePlanTodoTemplate';
            articleId: any;
            id: any;
            priority: number;
            tags?: Array<string> | null;
            type: CarePlanTodoTemplateClockType;
            offsetDays?: number | null;
            offsetMonths?: number | null;
            offsetYears?: number | null;
            targetDate?: any | null;
            durationMinutes?: number | null;
            isRepeating: boolean;
            repeatIntervalMinutes?: number | null;
            repeatCount?: number | null;
            isEssential: boolean;
            title?: { __typename?: 'LocalizedString'; es?: string | null; en: string } | null;
            description?: { __typename?: 'LocalizedString'; es?: string | null; en: string } | null;
            notificationData?: {
                __typename?: 'NotificationData';
                shouldSendNotification: boolean;
                en?: string | null;
                es?: string | null;
            } | null;
        }>;
    }>;
    timeline?: {
        __typename?: 'WhatsNewTimeline';
        id: any;
        label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    } | null;
};

export type CareTeamMemberFragmentFragment = {
    __typename?: 'CareTeamMember';
    id: any;
    careTeamMemberTypeId?: any | null;
    nameWithType: string;
    careTeamMemberContactId?: any | null;
    careTeamMemberTIN?: string | null;
    careTeamMemberNPI?: string | null;
    practiceExternalId?: string | null;
    practiceCode?: string | null;
    prerequisiteTeamMembers?: Array<any> | null;
    eligibilityCheck?: string | null;
    eligibilityRecheckDays?: number | null;
    consentRequired?: boolean | null;
    consentAgreement?: string | null;
    appBundleId: string;
    usState?: string | null;
    zipCode?: string | null;
    updatedAt: any;
    createdAt: any;
    isActive?: boolean | null;
    userId?: any | null;
    appDefault?: boolean | null;
    careTeamMemberType?: {
        __typename?: 'CareTeamMemberType';
        name: string;
        profileDefId: any;
        createdAt: any;
        updatedAt: any;
        id: any;
    } | null;
    overridenCareTeamMemberContact?: {
        __typename?: 'CareTeamContactForCareTeamMember';
        careTeamMemberTypeId: any;
        name: string;
        phoneNumber: string;
        emailAddress: string;
        url: string;
        address: {
            __typename?: 'Address';
            street1?: string | null;
            street2?: string | null;
            city?: string | null;
            state?: string | null;
            code?: string | null;
        };
    } | null;
    label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
};

export type ChatMessageForMessageCenterFragment = {
    __typename?: 'ChatMessage';
    id: any;
    createdAt: any;
    updatedAt: any;
    read?: Array<string | null> | null;
    text?: string | null;
    attachments?: Array<string | null> | null;
    senderId?: string | null;
    chatRoomId: any;
    chatConversationId: any;
};

export type CompoundQuestionFragmentFragment = {
    __typename?: 'CompoundQuestion';
    id: any;
    profileValueType: UserProfileValueType;
    label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    questionProfileDefs: Array<{
        __typename?: 'CompoundQuestionProfileDef';
        questionProfileDefId: any;
        questionProfileDef?: {
            __typename?: 'UserProfileDef';
            id: any;
            name: string;
            label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        } | null;
        labelOverride?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        value?: { __typename?: 'UserProfileDefChoiceValue'; bool?: boolean | null } | null;
    }>;
};

export type ConfigExpressionFragment = {
    __typename?: 'ConfigExpression';
    id: any;
    createdAt: any;
    updatedAt: any;
    name: string;
    description?: string | null;
    rules: any;
    profileDefIds?: Array<any | null> | null;
    appConfigIds?: Array<any | null> | null;
    useType: ExpressionUseType;
};

export type ConfigValueDefPageFragment = {
    __typename?: 'ConfigValueDef';
    id: any;
    createdAt: any;
    updatedAt: any;
    name: string;
    description?: string | null;
    valueType: ConfigValueType;
    category: string;
    validationPattern?: string | null;
    potentialValues: Array<{
        __typename?: 'ConfigValueDictionary';
        str?: string | null;
        strArr?: Array<string | null> | null;
        bool?: boolean | null;
        boolArr?: Array<boolean | null> | null;
        num?: number | null;
        numArr?: Array<number | null> | null;
        localizedStr?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        localizedStrArr?: Array<{
            __typename?: 'LocalizedString';
            en: string;
            es?: string | null;
        } | null> | null;
    }>;
    defaultValue: {
        __typename?: 'ConfigValueDictionary';
        str?: string | null;
        strArr?: Array<string | null> | null;
        bool?: boolean | null;
        boolArr?: Array<boolean | null> | null;
        num?: number | null;
        numArr?: Array<number | null> | null;
        localizedStr?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        localizedStrArr?: Array<{
            __typename?: 'LocalizedString';
            en: string;
            es?: string | null;
        } | null> | null;
    };
};

export type ConfigValuePageFragment = {
    __typename?: 'ConfigValue';
    id: any;
    createdAt: any;
    updatedAt: any;
    name: string;
    valueDef: { __typename?: 'ConfigValueDef'; id: any; name: string };
    value: {
        __typename?: 'ConfigValueDictionary';
        str?: string | null;
        strArr?: Array<string | null> | null;
        bool?: boolean | null;
        boolArr?: Array<boolean | null> | null;
        num?: number | null;
        numArr?: Array<number | null> | null;
        localizedStr?: { __typename?: 'LocalizedString'; en: string } | null;
        localizedStrArr?: Array<{ __typename?: 'LocalizedString'; en: string } | null> | null;
    };
    overrides?: Array<{
        __typename?: 'ExpressionOverride';
        value: {
            __typename?: 'ConfigValueDictionary';
            str?: string | null;
            strArr?: Array<string | null> | null;
            bool?: boolean | null;
            boolArr?: Array<boolean | null> | null;
            num?: number | null;
            numArr?: Array<number | null> | null;
            localizedStr?: { __typename?: 'LocalizedString'; en: string } | null;
            localizedStrArr?: Array<{ __typename?: 'LocalizedString'; en: string } | null> | null;
        };
        expression?: {
            __typename?: 'ConfigExpression';
            id: any;
            createdAt: any;
            updatedAt: any;
            name: string;
            description?: string | null;
        } | null;
    } | null> | null;
};

export type ContentDirectoryFragFragment = {
    __typename?: 'ContentDirectory';
    id: any;
    name: string;
    description?: string | null;
    keywords?: string | null;
    icon?: ContentIcon | null;
    imageContentId?: any | null;
    displayStyle?: DirectoryDisplayStyle | null;
    reportingCategorys?: Array<any> | null;
    imageContent?: { __typename?: 'SimpleContent'; name: string } | null;
    label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    appDescription?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
    taggedImages?: Array<{
        __typename?: 'TaggedImageShared';
        imageContentId: any;
        imageName: string;
        tagId: any;
        tagName: string;
    }> | null;
    items?: Array<{
        __typename?: 'DirectoryItem';
        id: any;
        type: DirectoryItemType;
        simpleContentId?: any | null;
        articleId?: any | null;
        surveyId?: any | null;
        subDirectoryId?: any | null;
        tagList?: Array<any> | null;
        excludeApps?: Array<string> | null;
        includeApps?: Array<string> | null;
        article?: { __typename?: 'Article'; name: string } | null;
        survey?: { __typename?: 'SurveyDef'; name?: string | null } | null;
        simpleContent?: { __typename?: 'SimpleContent'; name: string } | null;
        subdirectory?: { __typename?: 'ContentDirectory'; name: string } | null;
    }> | null;
};

export type DeviceForDevicesPageFragment = {
    __typename?: 'Device';
    id: any;
    createdAt: any;
    updatedAt: any;
    externalId?: string | null;
    photo?: string | null;
    localizedName?: string | null;
    localizedDocLink?: string | null;
    docLink?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
    name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
};

export type HealthPlanFragment = {
    __typename?: 'HealthPlan';
    id: any;
    name?: string | null;
    brandingName?: string | null;
    externalId?: string | null;
    healthPlanCode?: string | null;
    eligibilityType?: EligibilityType | null;
    state?: string | null;
    surveys: Array<string>;
};

export type InterventionFragmentFragment = {
    __typename?: 'Intervention';
    tagId?: any | null;
    type: InterventionType;
    conditionTypeId?: any | null;
    profileDefId?: any | null;
    messageCenterTemplateId?: any | null;
    choicesToUpdate?: Array<any | null> | null;
    createdAt: any;
    updatedAt: any;
    id: any;
    includeApps?: Array<string> | null;
    excludeApps?: Array<string> | null;
    dateType?: InterventionDateType | null;
    advocateTaskTemplateId?: any | null;
    priority?: number | null;
    messageTemplateId?: any | null;
    profileValue?: {
        __typename?: 'InterventionProfileValue';
        choiceId?: any | null;
        str?: string | null;
        num?: number | null;
        bool?: boolean | null;
        date?: any | null;
        choices?: Array<string | null> | null;
    } | null;
    tag?: {
        __typename?: 'Tag';
        id: any;
        name: string;
        expressionId?: any | null;
        changedProfileDefIds?: Array<any> | null;
        includeApps?: Array<string> | null;
        excludeApps?: Array<string> | null;
        createdAt: any;
        updatedAt: any;
        appInfo?: string | null;
        usageType: TagUsageType;
        isPatientFilter?: boolean | null;
        patientRiskScorePoints?: number | null;
        expression?: { __typename?: 'ConfigExpression'; id: any; name: string } | null;
    } | null;
    conditionTypes?: {
        __typename?: 'ConditionType';
        createdAt: any;
        updatedAt: any;
        id: any;
        name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    } | null;
    profileDef?: {
        __typename?: 'UserProfileDef';
        id: any;
        name: string;
        description?: string | null;
        category: string;
        valueType: UserProfileValueType;
        choiceValueType?: UserProfileValueType | null;
        surveyQuestionDisabled?: boolean | null;
        systemGenerated?: boolean | null;
        conditionTypeId?: any | null;
        episodeRequired?: boolean | null;
        reportingNotes?: string | null;
        haPermission?: HaPermission | null;
        inboundNotes?: string | null;
        isClientSpecific?: boolean | null;
        linkToJIRA?: string | null;
        purposeAppPersonalization?: boolean | null;
        purposeHumanServices?: boolean | null;
        clientSpecificDisplayName?: string | null;
        dataSourceNotes?: string | null;
        purposeDetails?: string | null;
        dwLocation?: string | null;
        dwObject?: string | null;
        dwMetrics?: string | null;
        dwInternalReports?: string | null;
        dwExternalReports?: string | null;
        claimsField?: string | null;
        icd10DiagnosisCodes?: string | null;
        procedureCodes?: string | null;
        label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        questionLabel?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        choices?: Array<{
            __typename?: 'UserProfileDefChoice';
            specifyValue: boolean;
            id: any;
            name?: string | null;
            answerLabel?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            value?: {
                __typename?: 'UserProfileDefChoiceValue';
                bool?: boolean | null;
                date?: any | null;
                num?: number | null;
                str?: string | null;
            } | null;
        } | null> | null;
    } | null;
    conditionType?: {
        __typename?: 'ConditionType';
        createdAt: any;
        updatedAt: any;
        id: any;
        name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    } | null;
    advocateTaskTemplate?: { __typename?: 'AdvocateTaskTemplate'; label: string } | null;
    messageTemplate?: { __typename?: 'MessageTemplate'; name: string } | null;
};

export type MeasurementForMeasurementsPageFragment = {
    __typename?: 'Measurement';
    id: any;
    flagged?: boolean | null;
    externalId?: string | null;
    updatedAt: any;
    createdAt: any;
    type?: MeasurementType | null;
    takenDate?: any | null;
    timezoneName?: string | null;
    reviewed?: boolean | null;
    inputType: MeasurementInputSource;
    comment?: string | null;
    hasHistory?: boolean | null;
    value: {
        __typename?: 'MeasurementValue';
        movement?: { __typename?: 'MovementMeasurement'; duration?: string | null } | null;
        weight?: {
            __typename?: 'WeightMeasurement';
            measure?: number | null;
            units?: string | null;
        } | null;
        bloodPressure?: {
            __typename?: 'BloodPressureMeasurement';
            pulse?: number | null;
            diastolic?: number | null;
            systolic?: number | null;
        } | null;
        dailyMeasurement?: {
            __typename?: 'DailyMeasurement';
            glucose?: number | null;
            moodLevel?: number | null;
            painLevel?: number | null;
            steps?: number | null;
            temperature?: number | null;
            sleepStarts?: string | null;
            sleepEnds?: string | null;
            notes?: string | null;
        } | null;
        bloodGlucose?: {
            __typename?: 'BloodGlucoseMeasurement';
            dateTime?: any | null;
            activityOption?: string | null;
            glucose?: number | null;
            meal?: string | null;
            mealOption?: string | null;
            mealTime?: any | null;
            movement?: string | null;
            notes?: string | null;
        } | null;
    };
    patient?: {
        __typename?: 'Patient';
        id: any;
        externalId?: string | null;
        firstName: string;
        lastName: string;
        fullName: string;
        birthDate: any;
        tagIds?: Array<any | null> | null;
        assignedHealthAdvocate?: any | null;
        practice: { __typename?: 'Organization'; timezone?: string | null };
    } | null;
    device?: { __typename?: 'Device'; id: any; localizedName?: string | null } | null;
};

export type OrgForOrgPageFragment = {
    __typename?: 'Organization';
    id: any;
    createdAt: any;
    updatedAt: any;
    name: string;
    parentId?: any | null;
    brandingName: string;
    healthAdvocateTitle?: string | null;
    externalId?: string | null;
    allowPatientCreation?: boolean | null;
    logo?: string | null;
    email?: string | null;
    timezone?: string | null;
    conferencingAppName?: string | null;
    appBundleId?: string | null;
    appPlatform?: string | null;
    eligibilityType?: EligibilityType | null;
    inviteCodes?: Array<string | null> | null;
    configOptions?: { __typename?: 'OrganizationConfigOptions'; riskScoreEnabled: boolean } | null;
};

export type OrgConfigForOrgFragment = {
    __typename?: 'Organization';
    id: any;
    createdAt: any;
    updatedAt: any;
    name: string;
    brandingName: string;
    healthAdvocateTitle?: string | null;
    config?: {
        __typename?: 'OrganizationConfig';
        resources?: {
            __typename?: 'OrganizationConfigResource';
            downloadConfApp: boolean;
            prepareForVirtualVisit: boolean;
            scheduleNextVisit: boolean;
            devices?: boolean | null;
        } | null;
    } | null;
};

export type OrgListFragment = {
    __typename?: 'Organization';
    id: any;
    parentId?: any | null;
    brandingName: string;
    ancestors?: Array<any | null> | null;
    appBundleId?: string | null;
    logo?: string | null;
};

export type PatientActionTypeFragmentFragment = {
    __typename?: 'PatientActionType';
    label: string;
    createdAt: any;
    updatedAt: any;
    id: any;
};

export type PatientForPatientsPageFragment = {
    __typename?: 'Patient';
    id: any;
    createdAt: any;
    externalId?: string | null;
    memberId?: string | null;
    fullName: string;
    firstName: string;
    lastName: string;
    onboardingDate?: any | null;
    email?: string | null;
    birthDate: any;
    phoneNumber?: string | null;
    invitationMode?: PatientInvitation | null;
    invitationCode?: string | null;
    signUpPracticeCode?: string | null;
    isTestData?: boolean | null;
    assignedHealthAdvocate?: any | null;
    openAdvocateTaskCount?: number | null;
    openCriticalAdvocateTaskCount?: number | null;
    onboardingCompleteDate?: any | null;
    isPregnant?: boolean | null;
    dueDate?: any | null;
    deliveryDate?: any | null;
    patientCallStatus?: string | null;
    appUserId?: any | null;
    appBundleId?: string | null;
    attachments?: Array<{ __typename?: 'Attachment'; uuid: string; label: string } | null> | null;
    riskIndicators?: {
        __typename?: 'PatientRiskIndicators';
        obesity?: {
            __typename?: 'PatientRiskIndicator';
            flag: boolean;
            comment?: string | null;
        } | null;
        preterm?: {
            __typename?: 'PatientRiskIndicator';
            flag: boolean;
            comment?: string | null;
        } | null;
        chronicHypertension?: {
            __typename?: 'PatientRiskIndicator';
            flag: boolean;
            comment?: string | null;
        } | null;
        other?: {
            __typename?: 'PatientRiskIndicator';
            flag: boolean;
            comment?: string | null;
        } | null;
    } | null;
    mailingAddress?: {
        __typename?: 'Address';
        street1?: string | null;
        street2?: string | null;
        city?: string | null;
        state?: string | null;
        code?: string | null;
    } | null;
    practice: {
        __typename?: 'Organization';
        id: any;
        name: string;
        timezone?: string | null;
        providers?: Array<{
            __typename?: 'OrganizationProvider';
            provider: { __typename?: 'Provider'; id: any; localizedName: string; createdAt: any };
        }> | null;
    };
    questions: Array<{
        __typename?: 'CombinedQuestion';
        id: string;
        createdAt: any;
        updatedAt: any;
        submittedAt: any;
        text: string;
        isDone: boolean;
    }>;
    notes: Array<{
        __typename?: 'PatientNotes';
        id: any;
        text?: string | null;
        title?: string | null;
        staffName?: string | null;
        createdAt: any;
    }>;
    chatRoom: { __typename?: 'ChatRoom'; id: any; numberOfUnreadMessages?: number | null };
    profileValues: Array<{
        __typename?: 'PatientProfileValue';
        profileVariableDefId?: any | null;
        type?: ProfileValueType | null;
        valueBool?: boolean | null;
        valueNum?: number | null;
        valueStr?: string | null;
        valueId?: any | null;
        valueIds?: Array<any | null> | null;
        valueDate?: any | null;
        timeZone?: string | null;
        utcOffset?: number | null;
    }>;
    riskScore?: {
        __typename?: 'RiskScoreResponse';
        value?: number | null;
        tierName?: string | null;
    } | null;
};

export type ProviderForProvidersPageFragment = {
    __typename?: 'Provider';
    id: any;
    createdAt: any;
    updatedAt: any;
    externalId?: string | null;
    localizedName: string;
    name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
};

export type RoleForRolePageFragment = {
    __typename?: 'Role';
    id: any;
    name: string;
    organizationId?: any | null;
    createdAt: any;
    updatedAt: any;
    permissions?: Array<{
        __typename?: 'Permissions';
        target?: Target | null;
        permission?: Permission | null;
        canExport?: boolean | null;
    } | null> | null;
};

export type SurveyDefForSurveyBuilderFragment = {
    __typename?: 'SurveyDef';
    id: any;
    name?: string | null;
    surveyType: string;
    statusProfileDefId: any;
    startedAtProfileDefId: any;
    completedAtProfileDefId: any;
    requiresAdvocateReview?: boolean | null;
    disableRestart?: boolean | null;
    restartFromBeginning?: boolean | null;
    shouldScore?: boolean | null;
    organizationId: any;
    icon?: ContentIcon | null;
    imageContentId?: any | null;
    createdAt: any;
    updatedAt: any;
    summary?: boolean | null;
    label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    imageContent?: { __typename?: 'SimpleContent'; id: any; name: string } | null;
    summaryText: {
        __typename?: 'SurveyDefText';
        title: { __typename?: 'LocalizedString'; es?: string | null; en: string };
        body: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    };
    statusProfileDef: { __typename?: 'UserProfileDef'; id: any; name: string };
    startedAtProfileDef: { __typename?: 'UserProfileDef'; id: any; name: string };
    completedAtProfileDef: { __typename?: 'UserProfileDef'; id: any; name: string };
    organization: { __typename?: 'Organization'; id: any; brandingName: string };
    divisions: Array<{
        __typename?: 'SurveyDefDivision';
        id: string;
        conditionExpressionIds?: Array<any | null> | null;
        subSurveyDefId?: any | null;
        label: { __typename?: 'LocalizedString'; es?: string | null; en: string };
        sections: Array<{
            __typename?: 'SurveyDefSection';
            id: string;
            conditionExpressionIds?: Array<any | null> | null;
            label: { __typename?: 'LocalizedString'; es?: string | null; en: string };
            pieces: Array<{
                __typename?: 'SurveyDefPiece';
                id: string;
                conditionExpressionIds?: Array<any | null> | null;
                type: SurveyDefPieceType;
                content?: {
                    __typename?: 'SurveyDefContent';
                    articleId?: any | null;
                    articleName?: string | null;
                } | null;
                text?: {
                    __typename?: 'SurveyDefText';
                    title: { __typename?: 'LocalizedString'; es?: string | null; en: string };
                    body: { __typename?: 'LocalizedString'; en: string; es?: string | null };
                } | null;
                question?: {
                    __typename?: 'SurveyDefQuestion';
                    displayType: SurveyDefQuestionDisplayType;
                    questionProfileDefId: any;
                    answerRequired?: boolean | null;
                    hidePreviousAnswer?: boolean | null;
                    labelOverride?: {
                        __typename?: 'LocalizedStringLoose';
                        en?: string | null;
                        es?: string | null;
                    } | null;
                    choiceOverrides?: Array<{
                        __typename?: 'SurveyDefChoiceOverride';
                        choiceId: any;
                        labelOverride?: {
                            __typename?: 'LocalizedString';
                            en: string;
                            es?: string | null;
                        } | null;
                    } | null> | null;
                } | null;
                surveyDefCompoundQuestion?: {
                    __typename?: 'SurveyDefCompoundQuestion';
                    compoundQuestionId: any;
                    displayType: SurveyDefQuestionDisplayType;
                    answerRequired?: boolean | null;
                    hidePreviousAnswer?: boolean | null;
                    label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
                } | null;
                tutorialContent?: {
                    __typename?: 'SurveyDefTutorialContent';
                    videoId?: any | null;
                    videoName?: string | null;
                } | null;
            }>;
        }>;
        subSurveyDef?: { __typename?: 'SurveyDef'; id: any; name?: string | null } | null;
    }>;
    buttons: {
        __typename?: 'SurveyDefButtons';
        saveSurvey: {
            __typename?: 'SurveyDefButton';
            label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        };
        end: {
            __typename?: 'SurveyDefButton';
            label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        };
        next: {
            __typename?: 'SurveyDefButton';
            label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        };
        previous: {
            __typename?: 'SurveyDefButton';
            label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        };
    };
};

export type SurveyResponseForSurveyPageFragment = {
    __typename?: 'SurveyResponse';
    surveyType?: string | null;
    clientSurveyId?: string | null;
    responseDate?: any | null;
    surveyScore?: number | null;
    displayName?: string | null;
    id: any;
    createdAt: any;
    updatedAt: any;
    reviewed?: boolean | null;
    isCoreSurvey?: boolean | null;
    answers: Array<{
        __typename?: 'SurveyResponseAnswer';
        subsectionName?: string | null;
        questionTypeId?: string | null;
        clientQuestionId?: string | null;
        questionId?: string | null;
        questionText?: string | null;
        answerId?: any | null;
        answerText?: any | null;
        responseScore?: number | null;
        questionOrder?: number | null;
    }>;
    practice?: {
        __typename?: 'Organization';
        id: any;
        brandingName: string;
        timezone?: string | null;
    } | null;
    patient?: {
        __typename?: 'Patient';
        id: any;
        externalId?: string | null;
        fullName: string;
        firstName: string;
        lastName: string;
        birthDate: any;
        dueDate?: any | null;
    } | null;
};

export type SurveyResponseForPatientSurveyPageFragment = {
    __typename?: 'SurveyResponse';
    surveyType?: string | null;
    clientSurveyId?: string | null;
    responseDate?: any | null;
    surveyScore?: number | null;
    displayName?: string | null;
    id: any;
    createdAt: any;
    updatedAt: any;
    isCoreSurvey?: boolean | null;
    answers: Array<{
        __typename?: 'SurveyResponseAnswer';
        subsectionName?: string | null;
        questionTypeId?: string | null;
        clientQuestionId?: string | null;
        questionId?: string | null;
        questionText?: string | null;
        answerId?: any | null;
        answerText?: any | null;
        responseScore?: number | null;
        questionOrder?: number | null;
    }>;
    practice?: {
        __typename?: 'Organization';
        id: any;
        brandingName: string;
        timezone?: string | null;
    } | null;
};

export type TagForTagBuilderFragment = {
    __typename?: 'Tag';
    id: any;
    name: string;
    expressionId?: any | null;
    changedProfileDefIds?: Array<any> | null;
    includeApps?: Array<string> | null;
    excludeApps?: Array<string> | null;
    createdAt: any;
    updatedAt: any;
    appInfo?: string | null;
    usageType: TagUsageType;
    isPatientFilter?: boolean | null;
    patientRiskScorePoints?: number | null;
    expression?: { __typename?: 'ConfigExpression'; id: any; name: string } | null;
};

export type CurrentUserFragment = {
    __typename?: 'User';
    id: any;
    name: string;
    email: string;
    title?: string | null;
    smsNumber?: string | null;
    clientOrgId?: any | null;
    numberOfUnreadChatMessages?: number | null;
    newTaskNotificationCount?: number | null;
    isTestData?: boolean | null;
    roleId?: any | null;
    currentOrg: {
        __typename?: 'Organization';
        id: any;
        parentId?: any | null;
        brandingName: string;
        timezone?: string | null;
        appPlatform?: string | null;
        appBundleId?: string | null;
        unreadMessagesCount?: number | null;
    };
    rootOrg: { __typename?: 'Organization'; id: any; parentId?: any | null; brandingName: string };
    organizations: Array<{
        __typename?: 'Organization';
        id: any;
        parentId?: any | null;
        brandingName: string;
        logo?: string | null;
        ancestors?: Array<any | null> | null;
        appBundleId?: string | null;
        unreadMessagesCount?: number | null;
    }>;
    currentOrganizations: Array<{
        __typename?: 'Organization';
        id: any;
        parentId?: any | null;
        brandingName: string;
        logo?: string | null;
    }>;
    role?: {
        __typename?: 'Role';
        id: any;
        name: string;
        permissions?: Array<{
            __typename?: 'Permissions';
            target?: Target | null;
            permission?: Permission | null;
            canExport?: boolean | null;
        } | null> | null;
    } | null;
};

export type UserStaffOnStaffPageFragment = {
    __typename?: 'User';
    id: any;
    name: string;
    email: string;
    photo?: string | null;
    clientOrgId?: any | null;
    smsNumber?: string | null;
    title?: string | null;
    isStaff?: boolean | null;
    isTestData?: boolean | null;
    roleId?: any | null;
    lastLogin?: any | null;
    isActive?: boolean | null;
    rootOrg: { __typename?: 'Organization'; id: any; name: string; brandingName: string };
    role?: {
        __typename?: 'Role';
        id: any;
        name: string;
        permissions?: Array<{
            __typename?: 'Permissions';
            target?: Target | null;
            permission?: Permission | null;
            canExport?: boolean | null;
        } | null> | null;
    } | null;
};

export type UserProfileDefFragmentFragment = {
    __typename?: 'UserProfileDef';
    id: any;
    name: string;
    description?: string | null;
    category: string;
    valueType: UserProfileValueType;
    choiceValueType?: UserProfileValueType | null;
    surveyQuestionDisabled?: boolean | null;
    systemGenerated?: boolean | null;
    conditionTypeId?: any | null;
    episodeRequired?: boolean | null;
    reportingNotes?: string | null;
    haPermission?: HaPermission | null;
    inboundNotes?: string | null;
    isClientSpecific?: boolean | null;
    linkToJIRA?: string | null;
    purposeAppPersonalization?: boolean | null;
    purposeHumanServices?: boolean | null;
    clientSpecificDisplayName?: string | null;
    dataSourceNotes?: string | null;
    purposeDetails?: string | null;
    dwLocation?: string | null;
    dwObject?: string | null;
    dwMetrics?: string | null;
    dwInternalReports?: string | null;
    dwExternalReports?: string | null;
    claimsField?: string | null;
    icd10DiagnosisCodes?: string | null;
    procedureCodes?: string | null;
    label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
    questionLabel?: {
        __typename?: 'LocalizedStringLoose';
        en?: string | null;
        es?: string | null;
    } | null;
    choices?: Array<{
        __typename?: 'UserProfileDefChoice';
        specifyValue: boolean;
        id: any;
        name?: string | null;
        answerLabel?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        value?: {
            __typename?: 'UserProfileDefChoiceValue';
            bool?: boolean | null;
            date?: any | null;
            num?: number | null;
            str?: string | null;
        } | null;
    } | null> | null;
};

export type WhatsNewTimelineForWhatsNewTimelineBuilderFragment = {
    __typename?: 'WhatsNewTimeline';
    id: any;
    type: WhatsNewTimelineType;
    fixedTargetDate?: any | null;
    targetDateProfileDefId?: any | null;
    label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    description?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
    targetDateProfileDef?: {
        __typename?: 'UserProfileDef';
        id: any;
        label?: { __typename?: 'LocalizedString'; en: string } | null;
    } | null;
};

export type FetchCareTeamMembersForCareTeamMembersHookQueryVariables = Exact<{
    input: CareTeamMembersByTypeNamesInput;
}>;

export type FetchCareTeamMembersForCareTeamMembersHookQuery = {
    __typename?: 'Query';
    careTeamMembersByTypesNames: Array<{
        __typename?: 'CareTeamMembersByType';
        careTeamType: { __typename?: 'CareTeamMemberType'; name: string; profileDefId: any };
        careTeamMembers: Array<{
            __typename?: 'CareTeamMember';
            id: any;
            isActive?: boolean | null;
            label: { __typename?: 'LocalizedString'; en: string };
        }>;
    }>;
};

export type FetchCurrentUserForUseUserHookQueryVariables = Exact<{ [key: string]: never }>;

export type FetchRolesForCurrentUserPermissionsHookQueryVariables = Exact<{ [key: string]: never }>;

export type FetchRolesForCurrentUserPermissionsHookQuery = {
    __typename?: 'Query';
    roleOfCurrentUser: {
        __typename?: 'Role';
        name: string;
        id: any;
        permissions?: Array<{
            __typename?: 'Permissions';
            canExport?: boolean | null;
            permission?: Permission | null;
            target?: Target | null;
        } | null> | null;
    };
};

export type FetchCurrentUserForUseUserHookQuery = {
    __typename?: 'Query';
    currentUser?: {
        __typename?: 'User';
        id: any;
        name: string;
        email: string;
        title?: string | null;
        smsNumber?: string | null;
        clientOrgId?: any | null;
        numberOfUnreadChatMessages?: number | null;
        newTaskNotificationCount?: number | null;
        openConversationsCount?: number | null;
        isTestData?: boolean | null;
        roleId?: any | null;
        currentOrganizations: Array<{ __typename?: 'Organization'; id: any }>;
        currentOrg: {
            __typename?: 'Organization';
            id: any;
            parentId?: any | null;
            brandingName: string;
            timezone?: string | null;
            appPlatform?: string | null;
            appBundleId?: string | null;
            unreadMessagesCount?: number | null;
        };
        rootOrg: {
            __typename?: 'Organization';
            id: any;
            parentId?: any | null;
            brandingName: string;
        };
        role?: {
            __typename?: 'Role';
            id: any;
            name: string;
            permissions?: Array<{
                __typename?: 'Permissions';
                target?: Target | null;
                permission?: Permission | null;
                canExport?: boolean | null;
            } | null> | null;
        } | null;
        affiliate?: {
            __typename?: 'VirtualCareAffiliateByIdResponse';
            affiliateURL?: string | null;
            data?: {
                __typename?: 'VirtualCareAffiliatesEntity';
                id: any;
                externalName: string;
                affiliateId?: string | null;
            } | null;
        } | null;
    } | null;
};

export type FetchBannerStateQueryVariables = Exact<{ [key: string]: never }>;

export type FetchBannerStateQuery = {
    __typename?: 'Query';
    GlobalBanner: {
        __typename?: 'GlobalBanner';
        open: boolean;
        message: string;
        actionText: string;
        severity: AlertSeverity;
    };
};

export type FetchAlertStateQueryVariables = Exact<{ [key: string]: never }>;

export type FetchAlertStateQuery = {
    __typename?: 'Query';
    GlobalAlert: {
        __typename?: 'GlobalAlert';
        message: string;
        open: boolean;
        severity: AlertSeverity;
    };
    GlobalConfirm: {
        __typename?: 'GlobalConfirm';
        open: boolean;
        message: string;
        description?: string | null;
    };
    GlobalImageUpload: { __typename?: 'GlobalImageUpload'; open: boolean; isImage: boolean };
    GlobalImagePreview: { __typename?: 'GlobalImagePreview'; open: boolean; imageSrc: string };
};

export type FetchSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchSettingsQuery = {
    __typename?: 'Query';
    settings: {
        __typename?: 'UiSettings';
        cognitoPoolId?: string | null;
        cognitoClientId?: string | null;
    };
};

export type LoginUserForAcceptInvitePageMutationVariables = Exact<{
    input: LoginUserInput;
}>;

export type LoginUserForAcceptInvitePageMutation = {
    __typename?: 'Mutation';
    loginUser?: {
        __typename?: 'LoginUserResponse';
        success: boolean;
        message: string;
        newPasswordRequired?: boolean | null;
        tempPasswordToken?: string | null;
        tokens?: { __typename?: 'CognitoTokens'; idToken: string; accessToken: string } | null;
    } | null;
};

export type FetchCurrentUserAcceptInviteLoginQueryVariables = Exact<{ [key: string]: never }>;

export type FetchCurrentUserAcceptInviteLoginQuery = {
    __typename?: 'Query';
    currentUser?: { __typename?: 'User'; id: any; name: string } | null;
};

export type AddNoteFromConsultantMutationVariables = Exact<{
    input?: InputMaybe<AddNoteFromConsultantInput>;
}>;

export type AddNoteFromConsultantMutation = {
    __typename?: 'Mutation';
    addNoteFromConsultant?: {
        __typename?: 'AddNoteFromConsultantResponse';
        message: string;
        patientId?: string | null;
        status: number;
        success: boolean;
    } | null;
};

export type AffiliateCarePatientsSubmittedClaimsV2QueryVariables = Exact<{
    input?: InputMaybe<GetClaimSubmittedGlobalSearchInput>;
}>;

export type AffiliateCarePatientsSubmittedClaimsV2Query = {
    __typename?: 'Query';
    claimSubmittedGlobalSearch?: {
        __typename?: 'ClaimSubmittedGlobalSearchResponse';
        hasMore?: boolean | null;
        hasPrevious?: boolean | null;
        limit?: number | null;
        orderBy?: string | null;
        skip?: number | null;
        total: number;
        results: Array<{
            __typename?: 'ClaimSubmitted';
            affiliateId?: string | null;
            ServiceFrom_1?: string | null;
            id: any;
            createdAt: any;
            service_type?: string | null;
            affiliateInvoice?: {
                __typename?: 'AffiliateInvoice';
                paymentStatus: AffiliateInvoicePaymentStatus;
                paymentTotal?: string | null;
                createdAt: any;
                id: any;
            } | null;
            patient: { __typename?: 'Patient'; lastName: string; firstName: string; id: any };
        }>;
    } | null;
};

export type ClaimsBuilderAffiliatePatientsQueryVariables = Exact<{
    input?: InputMaybe<GetVcAffiliatePatientsInput>;
}>;

export type ClaimsBuilderAffiliatePatientsQuery = {
    __typename?: 'Query';
    virtualCareAffiliatePatients?: {
        __typename?: 'VCAffiliatePatientsResponse';
        total: number;
        results: Array<{
            __typename?: 'Patient';
            fullName: string;
            firstName: string;
            email?: string | null;
            id: any;
            lastName: string;
            birthDate: any;
        }>;
    } | null;
};

export type ClaimsBuilderCreateAffiliateClaimMutationVariables = Exact<{
    input?: InputMaybe<AffiliateClaimFromBuilderInput>;
}>;

export type ClaimsBuilderCreateAffiliateClaimMutation = {
    __typename?: 'Mutation';
    createAffiliateClaimFromBuilder?: {
        __typename?: 'AffiliateClaimResponse';
        message: string;
        status: number;
        success: boolean;
        error?: {
            __typename?: 'AffiliateClaimResponseError';
            data?: Array<string> | null;
            message?: string | null;
        } | null;
    } | null;
};

export type AffiliateCareClaimsInvoicesV2QueryVariables = Exact<{
    input?: InputMaybe<SubmittedClaimQueryInput>;
}>;

export type AffiliateCareClaimsInvoicesV2Query = {
    __typename?: 'Query';
    submittedClaimsV2: {
        __typename?: 'SubmittedClaimPaginationQueryResponse';
        total: number;
        results: Array<{
            __typename?: 'ClaimSubmitted';
            id: any;
            ServiceFrom_1?: string | null;
            createdAt: any;
            batchId?: number | null;
            service_type?: string | null;
            patient: {
                __typename?: 'Patient';
                id: any;
                fullName: string;
                firstName: string;
                lastName: string;
            };
            affiliateInvoice?: {
                __typename?: 'AffiliateInvoice';
                paymentTotal?: string | null;
                id: any;
                createdAt: any;
                paymentStatus: AffiliateInvoicePaymentStatus;
            } | null;
        }>;
    };
};

export type AffiliateCareInvoicesV2QueryVariables = Exact<{
    input?: InputMaybe<AffiliateInvoiceQueryInput>;
}>;

export type AffiliateCareInvoicesV2Query = {
    __typename?: 'Query';
    affiliateInvoicesV2: {
        __typename?: 'AffiliateInvoicePaginationQueryResponse';
        hasMore?: boolean | null;
        hasPrevious?: boolean | null;
        limit?: number | null;
        orderBy?: string | null;
        total: number;
        skip?: number | null;
        results: Array<{
            __typename?: 'AffiliateInvoice';
            AffiliateClaims: Array<any>;
            AffiliateId: any;
            apiError?: string | null;
            billId?: string | null;
            createdAt: any;
            id: any;
            paymentStatus: AffiliateInvoicePaymentStatus;
            paymentTotal?: string | null;
            vendorId: string;
            batchClaimId?: number | null;
            affiliate: {
                __typename?: 'VirtualCareAffiliatesEntity';
                id: any;
                internalName: string;
            };
        }>;
    };
};

export type AffiliateInvoicesCreateBatchTaskMutationVariables = Exact<{
    input: BatchTaskInput;
}>;

export type AffiliateInvoicesCreateBatchTaskMutation = {
    __typename?: 'Mutation';
    createBatchTask?: {
        __typename?: 'CreateBatchTaskResponse';
        message: string;
        status: number;
        success: boolean;
    } | null;
};

export type AffiliateCarePatientQueryVariables = Exact<{
    input: GetPatientInput;
}>;

export type AffiliateCarePatientQuery = {
    __typename?: 'Query';
    patient?: {
        __typename?: 'Patient';
        email?: string | null;
        firstName: string;
        fullName: string;
        id: any;
        lastName: string;
        birthDate: any;
        createdAt: any;
        phoneNumber?: string | null;
    } | null;
};

export type AffiliateCareSubmittedClaimsV2QueryVariables = Exact<{
    input?: InputMaybe<SubmittedClaimQueryInput>;
}>;

export type AffiliateCareSubmittedClaimsV2Query = {
    __typename?: 'Query';
    submittedClaimsV2: {
        __typename?: 'SubmittedClaimPaginationQueryResponse';
        orderBy?: string | null;
        limit?: number | null;
        hasPrevious?: boolean | null;
        hasMore?: boolean | null;
        skip?: number | null;
        total: number;
        results: Array<{
            __typename?: 'ClaimSubmitted';
            status?: ClaimSubmittedStatus | null;
            patientId?: any | null;
            id: any;
            createdAt: any;
            affiliateInvoiceId?: any | null;
            affiliateId?: string | null;
            ServiceFrom_1?: string | null;
            service_type?: string | null;
        }>;
    };
};

export type VirtualCareAffiliatePatientsQueryVariables = Exact<{
    input?: InputMaybe<GetVcAffiliatePatientsInput>;
}>;

export type VirtualCareAffiliatePatientsQuery = {
    __typename?: 'Query';
    virtualCareAffiliatePatients?: {
        __typename?: 'VCAffiliatePatientsResponse';
        total: number;
        skip?: number | null;
        results: Array<{
            __typename?: 'Patient';
            birthDate: any;
            createdAt: any;
            email?: string | null;
            firstName: string;
            fullName: string;
            id: any;
            lastName: string;
            phoneNumber?: string | null;
        }>;
    } | null;
};

export type UploadClaimFileMutationVariables = Exact<{
    input: AffiliateClaimInput;
}>;

export type UploadClaimFileMutation = {
    __typename?: 'Mutation';
    createAffiliateClaim?: {
        __typename?: 'AffiliateClaimResponse';
        success: boolean;
        message: string;
        error?: {
            __typename?: 'AffiliateClaimResponseError';
            data?: Array<string> | null;
            message?: string | null;
        } | null;
        claim?: { __typename?: 'AffiliateClaim'; id: any } | null;
    } | null;
};

export type UpdateAdvocateTaskForAdvocateTaskPageMutationVariables = Exact<{
    input: UpdateAdvocateTaskEncodedInput;
}>;

export type UpdateAdvocateTaskForAdvocateTaskPageMutation = {
    __typename?: 'Mutation';
    updateAdvocateTaskEncoded?: {
        __typename?: 'UpdateAdvocateTaskEncodedResponse';
        success: boolean;
        message: string;
        resourceUpdated?: {
            __typename?: 'AdvocateTask';
            id: any;
            label: string;
            description: string;
            createdBy: any;
            assignedTo?: any | null;
            watchedBy: Array<any>;
            status: AdvocateTaskTypeStatus;
            priority: number;
            dueDate: any;
            displayDate: any;
            createdAt: any;
            updatedAt: any;
            completedAt?: any | null;
            patientId?: any | null;
            slaTimerId?: any | null;
            typeId: any;
            watchers: Array<{ __typename?: 'User'; id: any; name: string }>;
            patient?: {
                __typename?: 'Patient';
                id: any;
                fullName: string;
                birthDate: any;
                phoneNumber?: string | null;
                practice: { __typename?: 'Organization'; id: any; brandingName: string };
                chatRoom: {
                    __typename?: 'ChatRoom';
                    id: any;
                    participants: Array<{ __typename?: 'User'; id: any }>;
                };
                patientHeaderData?: Array<{
                    __typename?: 'PatientHeaderData';
                    label?: string | null;
                    value?: string | null;
                }> | null;
            } | null;
            checklist: Array<{
                __typename?: 'AdvocateTaskChecklistItem';
                id: any;
                label: string;
                description?: string | null;
                completedAt?: any | null;
                completedBy?: any | null;
                actionTypeIds: Array<any>;
                endSlaTimerOnComplete?: boolean | null;
            }>;
            comments: Array<{
                __typename?: 'AdvocateTaskComment';
                authorId: any;
                message: string;
                when: any;
            }>;
            commentAuthors: Array<{ __typename?: 'User'; id: any; name: string }>;
            taskCreator: { __typename?: 'User'; name: string };
            types: Array<{ __typename?: 'AdvocateTaskType'; id: any; label: string }>;
            usersToAssign: Array<{ __typename?: 'User'; id: any; name: string }>;
            history: Array<{
                __typename?: 'AdvocateTaskHistoryItem';
                id: any;
                whoId: any;
                what: string;
                when: any;
            }>;
            historyFigures: Array<{ __typename?: 'User'; id: any; name: string }>;
            actionTypes: Array<{ __typename?: 'PatientActionType'; id: any; label: string }>;
            patientActions: Array<{
                __typename?: 'PatientAction';
                stepId?: any | null;
                updatedAt: any;
                description?: string | null;
                patientId?: any | null;
                taskId?: any | null;
                typeId?: any | null;
                id: any;
                source: ActionSource;
                completedAt?: any | null;
            }>;
        } | null;
    } | null;
};

export type FetchAdvocateTaskForAdvocateTaskPageQueryVariables = Exact<{
    input: GetAdvocateTaskInput;
}>;

export type FetchAdvocateTaskForAdvocateTaskPageQuery = {
    __typename?: 'Query';
    advocateTask?: {
        __typename?: 'AdvocateTask';
        id: any;
        label: string;
        description: string;
        createdBy: any;
        assignedTo?: any | null;
        watchedBy: Array<any>;
        status: AdvocateTaskTypeStatus;
        priority: number;
        dueDate: any;
        displayDate: any;
        createdAt: any;
        updatedAt: any;
        completedAt?: any | null;
        patientId?: any | null;
        slaTimerId?: any | null;
        typeId: any;
        usersToAssign: Array<{
            __typename?: 'User';
            id: any;
            name: string;
            role?: {
                __typename?: 'Role';
                permissions?: Array<{
                    __typename?: 'Permissions';
                    target?: Target | null;
                } | null> | null;
            } | null;
        }>;
        watchers: Array<{ __typename?: 'User'; id: any; name: string }>;
        patient?: {
            __typename?: 'Patient';
            id: any;
            fullName: string;
            birthDate: any;
            phoneNumber?: string | null;
            practice: { __typename?: 'Organization'; id: any; brandingName: string };
            chatRoom: {
                __typename?: 'ChatRoom';
                id: any;
                participants: Array<{ __typename?: 'User'; id: any }>;
            };
            patientHeaderData?: Array<{
                __typename?: 'PatientHeaderData';
                label?: string | null;
                value?: string | null;
            }> | null;
        } | null;
        checklist: Array<{
            __typename?: 'AdvocateTaskChecklistItem';
            id: any;
            label: string;
            description?: string | null;
            completedAt?: any | null;
            completedBy?: any | null;
            actionTypeIds: Array<any>;
            endSlaTimerOnComplete?: boolean | null;
        }>;
        comments: Array<{
            __typename?: 'AdvocateTaskComment';
            authorId: any;
            message: string;
            when: any;
        }>;
        commentAuthors: Array<{ __typename?: 'User'; id: any; name: string }>;
        taskCreator: { __typename?: 'User'; name: string };
        types: Array<{ __typename?: 'AdvocateTaskType'; id: any; label: string }>;
        history: Array<{
            __typename?: 'AdvocateTaskHistoryItem';
            id: any;
            whoId: any;
            what: string;
            when: any;
        }>;
        historyFigures: Array<{ __typename?: 'User'; id: any; name: string }>;
        actionTypes: Array<{ __typename?: 'PatientActionType'; id: any; label: string }>;
        patientActions: Array<{
            __typename?: 'PatientAction';
            stepId?: any | null;
            updatedAt: any;
            description?: string | null;
            patientId?: any | null;
            taskId?: any | null;
            typeId?: any | null;
            id: any;
            source: ActionSource;
            completedAt?: any | null;
        }>;
    } | null;
};

export type FetchCurrentUserForAdvocateTaskPageQueryVariables = Exact<{ [key: string]: never }>;

export type FetchCurrentUserForAdvocateTaskPageQuery = {
    __typename?: 'Query';
    currentUser?: {
        __typename?: 'User';
        id: any;
        currentOrg: { __typename?: 'Organization'; brandingName: string };
    } | null;
};

export type FetchReferenceLinksForAdvocateTaskPageQueryVariables = Exact<{
    input: GetReferenceLinksForPageInput;
}>;

export type FetchReferenceLinksForAdvocateTaskPageQuery = {
    __typename?: 'Query';
    getReferenceLinksForPage?: Array<{
        __typename?: 'GetReferenceLinksForPageResponse';
        category?: string | null;
        data?: Array<{
            __typename?: 'LinkDataType';
            label?: string | null;
            url?: string | null;
        } | null> | null;
    } | null> | null;
};

export type DeletePatientActionForAdvocateTaskPageMutationVariables = Exact<{
    input: DeletePatientActionInput;
}>;

export type DeletePatientActionForAdvocateTaskPageMutation = {
    __typename?: 'Mutation';
    deletePatientAction?: {
        __typename?: 'DeletePatientActionResponse';
        success: boolean;
        message: string;
        status: number;
        id?: string | null;
    } | null;
};

export type DeletePatientActionByStepForAdvocateTaskMutationVariables = Exact<{
    input: DeletePatientActionByStepInput;
}>;

export type DeletePatientActionByStepForAdvocateTaskMutation = {
    __typename?: 'Mutation';
    deletePatientActionByStep?: {
        __typename?: 'DeletePatientActionByStepResponse';
        success: boolean;
        message: string;
        resourceDeleted?: { __typename?: 'PatientAction'; typeId?: any | null } | null;
    } | null;
};

export type UpdatePatientActionForAdvocateTaskPageMutationVariables = Exact<{
    input: UpdatePatientActionInput;
}>;

export type UpdatePatientActionForAdvocateTaskPageMutation = {
    __typename?: 'Mutation';
    updatePatientAction?: {
        __typename?: 'UpdatePatientActionResponse';
        status: number;
        message: string;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'PatientAction';
            id: any;
            updatedAt: any;
            description?: string | null;
            taskId?: any | null;
            typeId?: any | null;
            patientId?: any | null;
            source: ActionSource;
            completedAt?: any | null;
        } | null;
    } | null;
};

export type CreatePatientActionForAdvocateTaskPageMutationVariables = Exact<{
    input: PatientActionInput;
}>;

export type CreatePatientActionForAdvocateTaskPageMutation = {
    __typename?: 'Mutation';
    createPatientAction?: {
        __typename?: 'CreatePatientActionResponse';
        status: number;
        message: string;
        success: boolean;
        resourceCreated?: {
            __typename?: 'PatientAction';
            updatedAt: any;
            completedAt?: any | null;
            description?: string | null;
            taskId?: any | null;
            typeId?: any | null;
        } | null;
    } | null;
};

export type CreatePatientActionsForAdvocateTaskPageMutationVariables = Exact<{
    input: PatientActionsInput;
}>;

export type CreatePatientActionsForAdvocateTaskPageMutation = {
    __typename?: 'Mutation';
    createPatientActions?: {
        __typename?: 'CreatePatientActionsResponse';
        status: number;
        message: string;
        success: boolean;
        resourcesCreated?: Array<{
            __typename?: 'PatientAction';
            updatedAt: any;
            completedAt?: any | null;
            description?: string | null;
            taskId?: any | null;
            typeId?: any | null;
        } | null> | null;
    } | null;
};

export type PatientActionByIdQueryVariables = Exact<{
    input: GetPatientActionInput;
}>;

export type PatientActionByIdQuery = {
    __typename?: 'Query';
    patientAction?: {
        __typename?: 'PatientAction';
        id: any;
        updatedAt: any;
        description?: string | null;
        taskId?: any | null;
        typeId?: any | null;
        completedAt?: any | null;
    } | null;
};

export type PatientActionPageListQueryVariables = Exact<{ [key: string]: never }>;

export type PatientActionPageListQuery = {
    __typename?: 'Query';
    patientActionsV2: {
        __typename?: 'PatientActionPaginationQueryResponse';
        results: Array<{
            __typename?: 'PatientAction';
            id: any;
            updatedAt: any;
            description?: string | null;
            taskId?: any | null;
            typeId?: any | null;
            completedAt?: any | null;
        }>;
    };
};

export type UpdateChatRoomForAdvocateTaskPageMutationVariables = Exact<{
    input: UpdateChatRoomInput;
}>;

export type UpdateChatRoomForAdvocateTaskPageMutation = {
    __typename?: 'Mutation';
    updateChatRoom?: {
        __typename?: 'UpdateChatRoomResponse';
        success: boolean;
        message: string;
    } | null;
};

export type CreateChatRoomForAdvocateTaskPageMutationVariables = Exact<{
    input: ChatRoomInput;
}>;

export type CreateChatRoomForAdvocateTaskPageMutation = {
    __typename?: 'Mutation';
    createChatRoom?: {
        __typename?: 'CreateChatRoomResponse';
        resourceCreated?: { __typename?: 'ChatRoom'; id: any } | null;
    } | null;
};

export type CreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutationVariables = Exact<{
    input: AdvocateTaskTemplateInput;
}>;

export type CreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation = {
    __typename?: 'Mutation';
    createAdvocateTaskTemplateEncoded?: {
        __typename?: 'CreateAdvocateTaskTemplateEncodedResponse';
        success: boolean;
        message: string;
        resourceCreated?: {
            __typename?: 'AdvocateTaskTemplate';
            label: string;
            description: string;
            priority: number;
            createdAt: any;
            updatedAt: any;
            id: any;
            whenType: AdvocateTaskTemplateWhenType;
            typeId: any;
            interventionDurationMinutes?: number | null;
            shouldNotify?: boolean | null;
            slaId?: any | null;
            checklist: Array<{
                __typename?: 'AdvocateTaskChecklistItem';
                id: any;
                label: string;
                description?: string | null;
                completedAt?: any | null;
                completedBy?: any | null;
                actionTypeIds: Array<any>;
                endSlaTimerOnComplete?: boolean | null;
            }>;
            whenTimeline?: {
                __typename?: 'AdvocateTaskTemplateTimeline';
                timelineId: any;
                offsetDays: number;
                offsetMonths: number;
                offsetYears: number;
                durationMinutes: number;
                includeApps?: Array<string> | null;
                excludeApps?: Array<string> | null;
                tags?: Array<string> | null;
            } | null;
        } | null;
    } | null;
};

export type CreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutationVariables = Exact<{
    input: AdvocateTaskTypeInput;
}>;

export type CreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutation = {
    __typename?: 'Mutation';
    createAdvocateTaskType?: {
        __typename?: 'CreateAdvocateTaskTypeResponse';
        success: boolean;
        message: string;
        resourceCreated?: {
            __typename?: 'AdvocateTaskType';
            label: string;
            createdAt: any;
            updatedAt: any;
            id: any;
        } | null;
    } | null;
};

export type UpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutationVariables = Exact<{
    input: UpdateAdvocateTaskTemplateEncodedInput;
}>;

export type UpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation = {
    __typename?: 'Mutation';
    updateAdvocateTaskTemplateEncoded?: {
        __typename?: 'UpdateAdvocateTaskTemplateEncodedResponse';
        success: boolean;
        message: string;
        resourceUpdated?: {
            __typename?: 'AdvocateTaskTemplate';
            label: string;
            description: string;
            priority: number;
            createdAt: any;
            updatedAt: any;
            id: any;
            whenType: AdvocateTaskTemplateWhenType;
            typeId: any;
            interventionDurationMinutes?: number | null;
            shouldNotify?: boolean | null;
            slaId?: any | null;
            checklist: Array<{
                __typename?: 'AdvocateTaskChecklistItem';
                id: any;
                label: string;
                description?: string | null;
                completedAt?: any | null;
                completedBy?: any | null;
                actionTypeIds: Array<any>;
                endSlaTimerOnComplete?: boolean | null;
            }>;
            whenTimeline?: {
                __typename?: 'AdvocateTaskTemplateTimeline';
                timelineId: any;
                offsetDays: number;
                offsetMonths: number;
                offsetYears: number;
                durationMinutes: number;
                includeApps?: Array<string> | null;
                excludeApps?: Array<string> | null;
                tags?: Array<string> | null;
            } | null;
        } | null;
    } | null;
};

export type AdvocateTaskTemplateForAdvocateTaskTemplatePageQueryVariables = Exact<{
    input: GetAdvocateTaskTemplateInput;
}>;

export type AdvocateTaskTemplateForAdvocateTaskTemplatePageQuery = {
    __typename?: 'Query';
    advocateTaskTemplate?: {
        __typename?: 'AdvocateTaskTemplate';
        label: string;
        description: string;
        priority: number;
        createdAt: any;
        updatedAt: any;
        id: any;
        whenType: AdvocateTaskTemplateWhenType;
        typeId: any;
        interventionDurationMinutes?: number | null;
        shouldNotify?: boolean | null;
        slaId?: any | null;
        checklist: Array<{
            __typename?: 'AdvocateTaskChecklistItem';
            id: any;
            label: string;
            description?: string | null;
            completedAt?: any | null;
            completedBy?: any | null;
            actionTypeIds: Array<any>;
            endSlaTimerOnComplete?: boolean | null;
        }>;
        whenTimeline?: {
            __typename?: 'AdvocateTaskTemplateTimeline';
            timelineId: any;
            offsetDays: number;
            offsetMonths: number;
            offsetYears: number;
            durationMinutes: number;
            includeApps?: Array<string> | null;
            excludeApps?: Array<string> | null;
            tags?: Array<string> | null;
        } | null;
    } | null;
};

export type FetchAdvocateTaskTypesForAdvocateTaskTemplatePageQueryVariables = Exact<{
    [key: string]: never;
}>;

export type FetchAdvocateTaskTypesForAdvocateTaskTemplatePageQuery = {
    __typename?: 'Query';
    advocateTaskTypesV2: {
        __typename?: 'AdvocateTaskTypePaginationQueryResponse';
        results: Array<{
            __typename?: 'AdvocateTaskType';
            label: string;
            createdAt: any;
            updatedAt: any;
            id: any;
        }>;
    };
};

export type FetchTimelinesForAdvocateTaskTemplatePageQueryVariables = Exact<{
    [key: string]: never;
}>;

export type FetchTimelinesForAdvocateTaskTemplatePageQuery = {
    __typename?: 'Query';
    whatsNewTimelinesV2: {
        __typename?: 'WhatsNewTimelinePaginationQueryResponse';
        results: Array<{
            __typename?: 'WhatsNewTimeline';
            type: WhatsNewTimelineType;
            id: any;
            label: { __typename?: 'LocalizedString'; en: string };
        }>;
    };
};

export type FetchTagsForAdvocateTaskTemplatePageQueryVariables = Exact<{ [key: string]: never }>;

export type FetchTagsForAdvocateTaskTemplatePageQuery = {
    __typename?: 'Query';
    tags: Array<{ __typename?: 'Tag'; id: any; name: string }>;
};

export type FetchAmsAppsForAdvocateTaskTemplatePageQueryVariables = Exact<{ [key: string]: never }>;

export type FetchAmsAppsForAdvocateTaskTemplatePageQuery = {
    __typename?: 'Query';
    amsApps: Array<{ __typename?: 'AMSApp'; id: string; bundleId: string }>;
};

export type FetchSlasV2ForAdvocateTaskTemplatePageQueryVariables = Exact<{
    input?: InputMaybe<SlaQueryInput>;
}>;

export type FetchSlasV2ForAdvocateTaskTemplatePageQuery = {
    __typename?: 'Query';
    slasV2: {
        __typename?: 'SlaPaginationQueryResponse';
        results: Array<{ __typename?: 'Sla'; id: any; name: string }>;
    };
};

export type CreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutationVariables = Exact<{
    input: AdvocateTaskTemplateInput;
}>;

export type CreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutation = {
    __typename?: 'Mutation';
    createAdvocateTaskTemplate?: {
        __typename?: 'CreateAdvocateTaskTemplateResponse';
        success: boolean;
        message: string;
        resourceCreated?: {
            __typename?: 'AdvocateTaskTemplate';
            label: string;
            description: string;
            priority: number;
            createdAt: any;
            updatedAt: any;
            id: any;
            whenType: AdvocateTaskTemplateWhenType;
            typeId: any;
            interventionDurationMinutes?: number | null;
            shouldNotify?: boolean | null;
            slaId?: any | null;
            checklist: Array<{
                __typename?: 'AdvocateTaskChecklistItem';
                id: any;
                label: string;
                description?: string | null;
                completedAt?: any | null;
                completedBy?: any | null;
                actionTypeIds: Array<any>;
                endSlaTimerOnComplete?: boolean | null;
            }>;
            whenTimeline?: {
                __typename?: 'AdvocateTaskTemplateTimeline';
                timelineId: any;
                offsetDays: number;
                offsetMonths: number;
                offsetYears: number;
                durationMinutes: number;
                includeApps?: Array<string> | null;
                excludeApps?: Array<string> | null;
                tags?: Array<string> | null;
            } | null;
        } | null;
    } | null;
};

export type AdvocateTaskTemplateForAdvocateTaskTemplatesPageQueryVariables = Exact<{
    advocateTaskTemplateInput: GetAdvocateTaskTemplateInput;
}>;

export type AdvocateTaskTemplateForAdvocateTaskTemplatesPageQuery = {
    __typename?: 'Query';
    advocateTaskTemplate?: {
        __typename?: 'AdvocateTaskTemplate';
        label: string;
        description: string;
        priority: number;
        createdAt: any;
        updatedAt: any;
        id: any;
        whenType: AdvocateTaskTemplateWhenType;
        typeId: any;
        interventionDurationMinutes?: number | null;
        shouldNotify?: boolean | null;
        slaId?: any | null;
        checklist: Array<{
            __typename?: 'AdvocateTaskChecklistItem';
            id: any;
            label: string;
            description?: string | null;
            completedAt?: any | null;
            completedBy?: any | null;
            actionTypeIds: Array<any>;
            endSlaTimerOnComplete?: boolean | null;
        }>;
        whenTimeline?: {
            __typename?: 'AdvocateTaskTemplateTimeline';
            timelineId: any;
            offsetDays: number;
            offsetMonths: number;
            offsetYears: number;
            durationMinutes: number;
            includeApps?: Array<string> | null;
            excludeApps?: Array<string> | null;
            tags?: Array<string> | null;
        } | null;
    } | null;
};

export type AdvocateTaskTemplatesForAdvocateTaskTemplatesPageQueryVariables = Exact<{
    [key: string]: never;
}>;

export type AdvocateTaskTemplatesForAdvocateTaskTemplatesPageQuery = {
    __typename?: 'Query';
    advocateTaskTemplatesV2: {
        __typename?: 'AdvocateTaskTemplatePaginationQueryResponse';
        results: Array<{
            __typename?: 'AdvocateTaskTemplate';
            label: string;
            priority: number;
            description: string;
            id: any;
            whenType: AdvocateTaskTemplateWhenType;
            typeId: any;
            checklist: Array<{
                __typename?: 'AdvocateTaskChecklistItem';
                id: any;
                label: string;
                description?: string | null;
                completedAt?: any | null;
                completedBy?: any | null;
                actionTypeIds: Array<any>;
            }>;
            whenTimeline?: {
                __typename?: 'AdvocateTaskTemplateTimeline';
                timelineId: any;
                offsetDays: number;
                offsetMonths: number;
                offsetYears: number;
                durationMinutes: number;
                includeApps?: Array<string> | null;
                excludeApps?: Array<string> | null;
                tags?: Array<string> | null;
            } | null;
        }>;
    };
};

export type PrefetchDataForAdvocateTaskTemplatesPageQueryVariables = Exact<{
    [key: string]: never;
}>;

export type PrefetchDataForAdvocateTaskTemplatesPageQuery = {
    __typename?: 'Query';
    whatsNewTimelines: Array<{
        __typename?: 'WhatsNewTimeline';
        id: any;
        label: { __typename?: 'LocalizedString'; en: string };
    }>;
    amsApps: Array<{ __typename?: 'AMSApp'; id: string; bundleId: string }>;
    tags: Array<{ __typename?: 'Tag'; id: any; name: string }>;
};

export type DeleteAdvocateTaskTemplateMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteAdvocateTaskTemplateMutation = {
    __typename?: 'Mutation';
    deleteAdvocateTaskTemplate?: {
        __typename?: 'DeleteAdvocateTaskTemplateMutationResponse';
        success: boolean;
        message: string;
        status: number;
        id: string;
    } | null;
};

export type AdvocateTaskTemplateWhereUsedQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type AdvocateTaskTemplateWhereUsedQuery = {
    __typename?: 'Query';
    advocateTaskTemplateWhereUsed?: {
        __typename?: 'EntityWhereUsedResponse';
        dependencies?: Array<{
            __typename?: 'DependentEntity';
            entityType: WhereUsedEntity;
            blocksDelete?: boolean | null;
            total?: number | null;
            entries?: Array<{
                __typename?: 'DependentEntityEntry';
                id: string;
                name: string;
            } | null> | null;
        }> | null;
    } | null;
};

export type CreateAdvocateTaskForNewAdvocateTaskPageMutationVariables = Exact<{
    input: AdvocateTaskInput;
}>;

export type CreateAdvocateTaskForNewAdvocateTaskPageMutation = {
    __typename?: 'Mutation';
    createAdvocateTaskEncoded?: {
        __typename?: 'CreateAdvocateTaskResponse';
        success: boolean;
        message: string;
        resourceCreated?: {
            __typename?: 'AdvocateTask';
            id: any;
            label: string;
            description: string;
            createdBy: any;
            assignedTo?: any | null;
            watchedBy: Array<any>;
            status: AdvocateTaskTypeStatus;
            priority: number;
            dueDate: any;
            displayDate: any;
            createdAt: any;
            updatedAt: any;
            completedAt?: any | null;
            patientId?: any | null;
            slaTimerId?: any | null;
            typeId: any;
            watchers: Array<{ __typename?: 'User'; id: any; name: string }>;
            patient?: {
                __typename?: 'Patient';
                id: any;
                fullName: string;
                birthDate: any;
                phoneNumber?: string | null;
                practice: { __typename?: 'Organization'; id: any; brandingName: string };
                chatRoom: {
                    __typename?: 'ChatRoom';
                    id: any;
                    participants: Array<{ __typename?: 'User'; id: any }>;
                };
                patientHeaderData?: Array<{
                    __typename?: 'PatientHeaderData';
                    label?: string | null;
                    value?: string | null;
                }> | null;
            } | null;
            checklist: Array<{
                __typename?: 'AdvocateTaskChecklistItem';
                id: any;
                label: string;
                description?: string | null;
                completedAt?: any | null;
                completedBy?: any | null;
                actionTypeIds: Array<any>;
                endSlaTimerOnComplete?: boolean | null;
            }>;
            comments: Array<{
                __typename?: 'AdvocateTaskComment';
                authorId: any;
                message: string;
                when: any;
            }>;
            commentAuthors: Array<{ __typename?: 'User'; id: any; name: string }>;
            taskCreator: { __typename?: 'User'; name: string };
            types: Array<{ __typename?: 'AdvocateTaskType'; id: any; label: string }>;
            usersToAssign: Array<{ __typename?: 'User'; id: any; name: string }>;
            history: Array<{
                __typename?: 'AdvocateTaskHistoryItem';
                id: any;
                whoId: any;
                what: string;
                when: any;
            }>;
            historyFigures: Array<{ __typename?: 'User'; id: any; name: string }>;
            actionTypes: Array<{ __typename?: 'PatientActionType'; id: any; label: string }>;
            patientActions: Array<{
                __typename?: 'PatientAction';
                stepId?: any | null;
                updatedAt: any;
                description?: string | null;
                patientId?: any | null;
                taskId?: any | null;
                typeId?: any | null;
                id: any;
                source: ActionSource;
                completedAt?: any | null;
            }>;
        } | null;
    } | null;
};

export type CreateAdvocateTaskTypeForNewAdvocateTaskPageMutationVariables = Exact<{
    input: AdvocateTaskTypeInput;
}>;

export type CreateAdvocateTaskTypeForNewAdvocateTaskPageMutation = {
    __typename?: 'Mutation';
    createAdvocateTaskType?: {
        __typename?: 'CreateAdvocateTaskTypeResponse';
        success: boolean;
        message: string;
        resourceCreated?: {
            __typename?: 'AdvocateTaskType';
            label: string;
            createdAt: any;
            updatedAt: any;
            id: any;
        } | null;
    } | null;
};

export type AdvocateTaskForAdvocateTaskPageQueryVariables = Exact<{
    input: GetAdvocateTaskInput;
}>;

export type AdvocateTaskForAdvocateTaskPageQuery = {
    __typename?: 'Query';
    advocateTask?: {
        __typename?: 'AdvocateTask';
        id: any;
        label: string;
        description: string;
        createdBy: any;
        assignedTo?: any | null;
        watchedBy: Array<any>;
        status: AdvocateTaskTypeStatus;
        priority: number;
        dueDate: any;
        displayDate: any;
        createdAt: any;
        updatedAt: any;
        completedAt?: any | null;
        patientId?: any | null;
        slaTimerId?: any | null;
        typeId: any;
        watchers: Array<{ __typename?: 'User'; id: any; name: string }>;
        patient?: {
            __typename?: 'Patient';
            id: any;
            fullName: string;
            birthDate: any;
            phoneNumber?: string | null;
            practice: { __typename?: 'Organization'; id: any; brandingName: string };
            chatRoom: {
                __typename?: 'ChatRoom';
                id: any;
                participants: Array<{ __typename?: 'User'; id: any }>;
            };
            patientHeaderData?: Array<{
                __typename?: 'PatientHeaderData';
                label?: string | null;
                value?: string | null;
            }> | null;
        } | null;
        checklist: Array<{
            __typename?: 'AdvocateTaskChecklistItem';
            id: any;
            label: string;
            description?: string | null;
            completedAt?: any | null;
            completedBy?: any | null;
            actionTypeIds: Array<any>;
            endSlaTimerOnComplete?: boolean | null;
        }>;
        comments: Array<{
            __typename?: 'AdvocateTaskComment';
            authorId: any;
            message: string;
            when: any;
        }>;
        commentAuthors: Array<{ __typename?: 'User'; id: any; name: string }>;
        taskCreator: { __typename?: 'User'; name: string };
        types: Array<{ __typename?: 'AdvocateTaskType'; id: any; label: string }>;
        usersToAssign: Array<{ __typename?: 'User'; id: any; name: string }>;
        history: Array<{
            __typename?: 'AdvocateTaskHistoryItem';
            id: any;
            whoId: any;
            what: string;
            when: any;
        }>;
        historyFigures: Array<{ __typename?: 'User'; id: any; name: string }>;
        actionTypes: Array<{ __typename?: 'PatientActionType'; id: any; label: string }>;
        patientActions: Array<{
            __typename?: 'PatientAction';
            stepId?: any | null;
            updatedAt: any;
            description?: string | null;
            patientId?: any | null;
            taskId?: any | null;
            typeId?: any | null;
            id: any;
            source: ActionSource;
            completedAt?: any | null;
        }>;
    } | null;
};

export type FetchAdvocateTaskTypesForAdvocateTaskPageQueryVariables = Exact<{
    [key: string]: never;
}>;

export type FetchAdvocateTaskTypesForAdvocateTaskPageQuery = {
    __typename?: 'Query';
    advocateTaskTypesV2: {
        __typename?: 'AdvocateTaskTypePaginationQueryResponse';
        results: Array<{ __typename?: 'AdvocateTaskType'; label: string; id: any }>;
    };
};

export type FetchCurrentUserForCreateAdvocateTaskPageQueryVariables = Exact<{
    [key: string]: never;
}>;

export type FetchCurrentUserForCreateAdvocateTaskPageQuery = {
    __typename?: 'Query';
    currentUser?: {
        __typename?: 'User';
        id: any;
        name: string;
        patients: Array<{
            __typename?: 'Patient';
            fullName: string;
            id: any;
            assignedHealthAdvocate?: any | null;
            isTestData?: boolean | null;
        }>;
    } | null;
};

export type FetchAdvocatesForAdvocateTaskPageQueryQueryVariables = Exact<{
    permission?: InputMaybe<Scalars['String']>;
}>;

export type FetchAdvocatesForAdvocateTaskPageQueryQuery = {
    __typename?: 'Query';
    getUsersByPermission?: Array<{ __typename?: 'User'; id: any; name: string } | null> | null;
};

export type FetchPatientsForAdvocateTaskPageQueryVariables = Exact<{
    input: GetPatientsByFilterInput;
}>;

export type FetchPatientsForAdvocateTaskPageQuery = {
    __typename?: 'Query';
    currentUser?: {
        __typename?: 'User';
        id: any;
        name: string;
        patientsV2: {
            __typename?: 'UserPatientsResponse';
            orderBy?: string | null;
            skip?: number | null;
            limit?: number | null;
            total: number;
            hasMore?: boolean | null;
            hasPrevious?: boolean | null;
            results: Array<{
                __typename?: 'Patient';
                fullName: string;
                id: any;
                assignedHealthAdvocate?: any | null;
                isTestData?: boolean | null;
            }>;
        };
    } | null;
};

export type FetchPatientForAdvocateTaskPageQueryVariables = Exact<{
    input: GetPatientInput;
}>;

export type FetchPatientForAdvocateTaskPageQuery = {
    __typename?: 'Query';
    patient?: { __typename?: 'Patient'; fullName: string; isTestData?: boolean | null } | null;
};

export type CreatePatientActionTypeForChecklistModalMutationVariables = Exact<{
    input: PatientActionTypeInput;
}>;

export type CreatePatientActionTypeForChecklistModalMutation = {
    __typename?: 'Mutation';
    createPatientActionType?: {
        __typename?: 'CreatePatientActionTypeResponse';
        success: boolean;
        message: string;
        resourceCreated?: {
            __typename?: 'PatientActionType';
            label: string;
            createdAt: any;
            updatedAt: any;
            id: any;
        } | null;
    } | null;
};

export type FetchPatientActionTypesForChecklistModalPageQueryVariables = Exact<{
    [key: string]: never;
}>;

export type FetchPatientActionTypesForChecklistModalPageQuery = {
    __typename?: 'Query';
    patientActionTypesV2: {
        __typename?: 'PatientActionTypePaginationQueryResponse';
        results: Array<{
            __typename?: 'PatientActionType';
            label: string;
            createdAt: any;
            updatedAt: any;
            id: any;
        }>;
    };
};

export type ArticleApprovalFragmentFragment = {
    __typename?: 'ArticleApproval';
    id: any;
    appBundleId: string;
    articleId: any;
    language: Language;
    readingLevel: ReadingLevel;
    status?: ApprovalStatus | null;
    approvedBy?: string | null;
    dateApproved?: any | null;
    articleName: string;
};

export type ArticleApprovalByIdQueryVariables = Exact<{
    input: GetArticleApprovalInput;
}>;

export type ArticleApprovalByIdQuery = {
    __typename?: 'Query';
    articleApproval?: {
        __typename?: 'ArticleApproval';
        id: any;
        appBundleId: string;
        articleId: any;
        language: Language;
        readingLevel: ReadingLevel;
        status?: ApprovalStatus | null;
        approvedBy?: string | null;
        dateApproved?: any | null;
        articleName: string;
    } | null;
};

export type CreateArticleApprovalMutationVariables = Exact<{
    input: ArticleApprovalInput;
}>;

export type CreateArticleApprovalMutation = {
    __typename?: 'Mutation';
    createArticleApproval?: {
        __typename?: 'CreateArticleApprovalResponse';
        status: number;
        message: string;
        success: boolean;
        resourceCreated?: {
            __typename?: 'ArticleApproval';
            id: any;
            appBundleId: string;
            articleId: any;
            language: Language;
            readingLevel: ReadingLevel;
            status?: ApprovalStatus | null;
            approvedBy?: string | null;
            dateApproved?: any | null;
            articleName: string;
        } | null;
    } | null;
};

export type CreateArticleAppApprovalsMutationVariables = Exact<{
    input: CreateArticleAppApprovalsInput;
}>;

export type CreateArticleAppApprovalsMutation = {
    __typename?: 'Mutation';
    createArticleAppApprovals?: {
        __typename?: 'CreateArticleAppApprovalsResponse';
        status: number;
        message: string;
        success: boolean;
    } | null;
};

export type UpdateArticleApprovalMutationVariables = Exact<{
    updateArticleApprovalInput: UpdateArticleApprovalInput;
}>;

export type UpdateArticleApprovalMutation = {
    __typename?: 'Mutation';
    updateArticleApproval?: {
        __typename?: 'UpdateArticleApprovalResponse';
        status: number;
        message: string;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'ArticleApproval';
            id: any;
            appBundleId: string;
            articleId: any;
            language: Language;
            readingLevel: ReadingLevel;
            status?: ApprovalStatus | null;
            approvedBy?: string | null;
            dateApproved?: any | null;
            articleName: string;
        } | null;
    } | null;
};

export type ArticleApprovalListQueryVariables = Exact<{
    input?: InputMaybe<ArticleApprovalListInput>;
}>;

export type ArticleApprovalListQuery = {
    __typename?: 'Query';
    customFilteredArticleApprovals: {
        __typename?: 'FilteredArticleApprovalResponse';
        total: number;
        results: Array<{
            __typename?: 'ArticleApproval';
            id: any;
            appBundleId: string;
            articleId: any;
            language: Language;
            readingLevel: ReadingLevel;
            status?: ApprovalStatus | null;
            approvedBy?: string | null;
            dateApproved?: any | null;
            articleName: string;
        }>;
    };
};

export type ArticleApprovalListV2QueryVariables = Exact<{
    input?: InputMaybe<ArticleApprovalListInput>;
}>;

export type ArticleApprovalListV2Query = {
    __typename?: 'Query';
    customFilteredArticleApprovalsV2: {
        __typename?: 'FilteredArticleApprovalResponse';
        total: number;
        results: Array<{
            __typename?: 'ArticleApproval';
            id: any;
            appBundleId: string;
            articleId: any;
            language: Language;
            readingLevel: ReadingLevel;
            status?: ApprovalStatus | null;
            approvedBy?: string | null;
            dateApproved?: any | null;
            articleName: string;
        }>;
    };
};

export type ArticlesV2ForArticleApprovalPageQueryVariables = Exact<{
    input?: InputMaybe<ArticleListInput>;
}>;

export type ArticlesV2ForArticleApprovalPageQuery = {
    __typename?: 'Query';
    articlesV2: {
        __typename?: 'ArticleListResponse';
        results: Array<{ __typename?: 'Article'; id: any; name: string }>;
    };
};

export type ArticleGroupsForArticleApprovalPageQueryVariables = Exact<{ [key: string]: never }>;

export type ArticleGroupsForArticleApprovalPageQuery = {
    __typename?: 'Query';
    articleGroupsV2: {
        __typename?: 'ArticleGroupPaginationQueryResponse';
        results: Array<{ __typename?: 'ArticleGroup'; id: any; name: string }>;
    };
};

export type ArticleCategoryByIdQueryVariables = Exact<{
    input: GetArticleCategoryInput;
}>;

export type ArticleCategoryByIdQuery = {
    __typename?: 'Query';
    articleCategory?: { __typename?: 'ArticleCategory'; id: any; name: string } | null;
};

export type CreateArticleCategoryMutationVariables = Exact<{
    input: ArticleCategoryInput;
}>;

export type CreateArticleCategoryMutation = {
    __typename?: 'Mutation';
    createArticleCategory?: {
        __typename?: 'CreateArticleCategoryResponse';
        status: number;
        message: string;
        success: boolean;
        resourceCreated?: { __typename?: 'ArticleCategory'; id: any; name: string } | null;
    } | null;
};

export type ArticleCategoryListQueryVariables = Exact<{ [key: string]: never }>;

export type ArticleCategoryListQuery = {
    __typename?: 'Query';
    articleCategorys: Array<{ __typename?: 'ArticleCategory'; id: any; name: string }>;
};

export type UpdateArticleCategoryMutationVariables = Exact<{
    updateArticleCategoryInput: UpdateArticleCategoryInput;
}>;

export type UpdateArticleCategoryMutation = {
    __typename?: 'Mutation';
    updateArticleCategory?: {
        __typename?: 'UpdateArticleCategoryResponse';
        status: number;
        message: string;
        success: boolean;
        resourceUpdated?: { __typename?: 'ArticleCategory'; id: any; name: string } | null;
    } | null;
};

export type DeleteArticleCategoryMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteArticleCategoryMutation = {
    __typename?: 'Mutation';
    deleteArticleCategory?: {
        __typename?: 'DeleteArticleCategoryMutationResponse';
        success: boolean;
        message: string;
        status: number;
        id: string;
    } | null;
};

export type ArticleCategoryWhereUsedQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type ArticleCategoryWhereUsedQuery = {
    __typename?: 'Query';
    articleCategoryWhereUsed?: {
        __typename?: 'EntityWhereUsedResponse';
        dependencies?: Array<{
            __typename?: 'DependentEntity';
            entityType: WhereUsedEntity;
            blocksDelete?: boolean | null;
            total?: number | null;
            entries?: Array<{
                __typename?: 'DependentEntityEntry';
                id: string;
                name: string;
            } | null> | null;
        }> | null;
    } | null;
};

export type FetchArticleGroupQueryVariables = Exact<{
    articleGroupInput: GetArticleGroupInput;
}>;

export type FetchArticleGroupQuery = {
    __typename?: 'Query';
    articleGroup?: {
        __typename?: 'ArticleGroup';
        id: any;
        name: string;
        includeApps?: Array<string> | null;
        excludeApps?: Array<string> | null;
        createdAt: any;
        updatedAt: any;
    } | null;
};

export type UpdateArticleGroupMutationVariables = Exact<{
    updateArticleGroupInput: UpdateArticleGroupInput;
}>;

export type UpdateArticleGroupMutation = {
    __typename?: 'Mutation';
    updateArticleGroup?: {
        __typename?: 'UpdateArticleGroupResponse';
        success: boolean;
        message: string;
        resourceUpdated?: {
            __typename?: 'ArticleGroup';
            id: any;
            name: string;
            includeApps?: Array<string> | null;
            excludeApps?: Array<string> | null;
            createdAt: any;
            updatedAt: any;
        } | null;
    } | null;
};

export type CreateArticleGroupMutationVariables = Exact<{
    createArticleGroupInput: ArticleGroupInput;
}>;

export type CreateArticleGroupMutation = {
    __typename?: 'Mutation';
    createArticleGroup?: {
        __typename?: 'CreateArticleGroupResponse';
        success: boolean;
        message: string;
        resourceCreated?: {
            __typename?: 'ArticleGroup';
            id: any;
            name: string;
            includeApps?: Array<string> | null;
            excludeApps?: Array<string> | null;
            createdAt: any;
            updatedAt: any;
        } | null;
    } | null;
};

export type ArticleGroupListQueryVariables = Exact<{
    articleGroupListInput?: InputMaybe<ArticleGroupQueryInput>;
}>;

export type ArticleGroupListQuery = {
    __typename?: 'Query';
    articleGroups: Array<{
        __typename?: 'ArticleGroup';
        id: any;
        name: string;
        includeApps?: Array<string> | null;
        excludeApps?: Array<string> | null;
        createdAt: any;
        updatedAt: any;
    }>;
};

export type DeleteArticleGroupMutationVariables = Exact<{
    input: DeleteArticleGroupInput;
}>;

export type DeleteArticleGroupMutation = {
    __typename?: 'Mutation';
    deleteArticleGroup?: {
        __typename?: 'DeleteArticleGroupResponse';
        success: boolean;
        message: string;
        status: number;
        id?: string | null;
    } | null;
};

export type ArticleGroupWhereUsedQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type ArticleGroupWhereUsedQuery = {
    __typename?: 'Query';
    articleGroupWhereUsed?: {
        __typename?: 'EntityWhereUsedResponse';
        dependencies?: Array<{
            __typename?: 'DependentEntity';
            entityType: WhereUsedEntity;
            blocksDelete?: boolean | null;
            total?: number | null;
            entries?: Array<{
                __typename?: 'DependentEntityEntry';
                id: string;
                name: string;
            } | null> | null;
        }> | null;
    } | null;
};

export type CreateArticlePromotionForArticlePromotionPageMutationVariables = Exact<{
    input: ArticlePromotionInput;
}>;

export type CreateArticlePromotionForArticlePromotionPageMutation = {
    __typename?: 'Mutation';
    createArticlePromotion?: {
        __typename?: 'CreateArticlePromotionResponse';
        success: boolean;
        message: string;
        resourceCreated?: {
            __typename?: 'ArticlePromotion';
            name?: string | null;
            title?: string | null;
            titleSp?: string | null;
            articleId: any;
            priority: number;
            tagIds?: Array<any> | null;
            type?: ArticlePromotionType | null;
            typeName?: string | null;
            startDate?: any | null;
            endDate?: any | null;
            createdAt: any;
            updatedAt: any;
            isTestData?: boolean | null;
            id: any;
            timelineId?: any | null;
            offsetDays?: number | null;
            offsetMonths?: number | null;
            offsetYears?: number | null;
            duration?: number | null;
            durationTimeUnit?: TimeUnit | null;
            includeApps?: Array<string> | null;
            excludeApps?: Array<string> | null;
            timeline?: {
                __typename?: 'WhatsNewTimeline';
                id: any;
                type: WhatsNewTimelineType;
                fixedTargetDate?: any | null;
                targetDateProfileDefId?: any | null;
                label: { __typename?: 'LocalizedString'; en: string };
                targetDateProfileDef?: {
                    __typename?: 'UserProfileDef';
                    id: any;
                    label?: { __typename?: 'LocalizedString'; en: string } | null;
                } | null;
            } | null;
            article?: { __typename?: 'Article'; id: any; name: string } | null;
        } | null;
    } | null;
};

export type UpdateArticlePromotionForArticlePromotionPageMutationVariables = Exact<{
    input: UpdateArticlePromotionInput;
}>;

export type UpdateArticlePromotionForArticlePromotionPageMutation = {
    __typename?: 'Mutation';
    updateArticlePromotion?: {
        __typename?: 'UpdateArticlePromotionResponse';
        success: boolean;
        message: string;
        resourceUpdated?: {
            __typename?: 'ArticlePromotion';
            name?: string | null;
            title?: string | null;
            titleSp?: string | null;
            articleId: any;
            priority: number;
            tagIds?: Array<any> | null;
            type?: ArticlePromotionType | null;
            typeName?: string | null;
            startDate?: any | null;
            endDate?: any | null;
            createdAt: any;
            updatedAt: any;
            isTestData?: boolean | null;
            id: any;
            timelineId?: any | null;
            offsetDays?: number | null;
            offsetMonths?: number | null;
            offsetYears?: number | null;
            duration?: number | null;
            durationTimeUnit?: TimeUnit | null;
            includeApps?: Array<string> | null;
            excludeApps?: Array<string> | null;
            timeline?: {
                __typename?: 'WhatsNewTimeline';
                id: any;
                type: WhatsNewTimelineType;
                fixedTargetDate?: any | null;
                targetDateProfileDefId?: any | null;
                label: { __typename?: 'LocalizedString'; en: string };
                targetDateProfileDef?: {
                    __typename?: 'UserProfileDef';
                    id: any;
                    label?: { __typename?: 'LocalizedString'; en: string } | null;
                } | null;
            } | null;
            article?: { __typename?: 'Article'; id: any; name: string } | null;
        } | null;
    } | null;
};

export type ArticlePromotionForArticlePromotionPageQueryVariables = Exact<{
    input: GetArticlePromotionInput;
}>;

export type ArticlePromotionForArticlePromotionPageQuery = {
    __typename?: 'Query';
    articlePromotion?: {
        __typename?: 'ArticlePromotion';
        name?: string | null;
        title?: string | null;
        titleSp?: string | null;
        articleId: any;
        priority: number;
        tagIds?: Array<any> | null;
        type?: ArticlePromotionType | null;
        typeName?: string | null;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        updatedAt: any;
        isTestData?: boolean | null;
        id: any;
        timelineId?: any | null;
        offsetDays?: number | null;
        offsetMonths?: number | null;
        offsetYears?: number | null;
        duration?: number | null;
        durationTimeUnit?: TimeUnit | null;
        includeApps?: Array<string> | null;
        excludeApps?: Array<string> | null;
        timeline?: {
            __typename?: 'WhatsNewTimeline';
            id: any;
            type: WhatsNewTimelineType;
            fixedTargetDate?: any | null;
            targetDateProfileDefId?: any | null;
            label: { __typename?: 'LocalizedString'; en: string };
            targetDateProfileDef?: {
                __typename?: 'UserProfileDef';
                id: any;
                label?: { __typename?: 'LocalizedString'; en: string } | null;
            } | null;
        } | null;
        article?: { __typename?: 'Article'; id: any; name: string } | null;
    } | null;
};

export type PreFetchAppsTagsTimelinesForArticlePromotionPageQueryVariables = Exact<{
    [key: string]: never;
}>;

export type PreFetchAppsTagsTimelinesForArticlePromotionPageQuery = {
    __typename?: 'Query';
    whatsNewTimelines: Array<{
        __typename?: 'WhatsNewTimeline';
        id: any;
        label: { __typename?: 'LocalizedString'; en: string };
    }>;
    amsApps: Array<{
        __typename?: 'AMSApp';
        id: string;
        bundleId: string;
        productConst?: AmsProduct | null;
    }>;
    tags: Array<{ __typename?: 'Tag'; id: any; name: string }>;
};

export type FetchTimelinesForArticlePromotionPageQueryVariables = Exact<{ [key: string]: never }>;

export type FetchTimelinesForArticlePromotionPageQuery = {
    __typename?: 'Query';
    whatsNewTimelinesV2: {
        __typename?: 'WhatsNewTimelinePaginationQueryResponse';
        results: Array<{
            __typename?: 'WhatsNewTimeline';
            id: any;
            type: WhatsNewTimelineType;
            label: { __typename?: 'LocalizedString'; en: string };
        }>;
    };
};

export type DeleteArticlePromotionMutationVariables = Exact<{
    input: DeleteArticlePromotionInput;
}>;

export type DeleteArticlePromotionMutation = {
    __typename?: 'Mutation';
    deleteArticlePromotion?: {
        __typename?: 'DeleteArticlePromotionResponse';
        success: boolean;
        message: string;
        status: number;
        id?: string | null;
    } | null;
};

export type ArticlePromotionsForArticlePromotionListPageQueryVariables = Exact<{
    input: ArticlePromotionListInput;
}>;

export type ArticlePromotionsForArticlePromotionListPageQuery = {
    __typename?: 'Query';
    articlePromotionsV2: {
        __typename?: 'ArticlePromotionListResponse';
        total: number;
        results: Array<{
            __typename?: 'ArticlePromotion';
            name?: string | null;
            title?: string | null;
            titleSp?: string | null;
            articleId: any;
            priority: number;
            tagIds?: Array<any> | null;
            type?: ArticlePromotionType | null;
            typeName?: string | null;
            startDate?: any | null;
            endDate?: any | null;
            createdAt: any;
            updatedAt: any;
            isTestData?: boolean | null;
            id: any;
            timelineId?: any | null;
            offsetDays?: number | null;
            offsetMonths?: number | null;
            offsetYears?: number | null;
            duration?: number | null;
            durationTimeUnit?: TimeUnit | null;
            includeApps?: Array<string> | null;
            excludeApps?: Array<string> | null;
            timeline?: {
                __typename?: 'WhatsNewTimeline';
                id: any;
                type: WhatsNewTimelineType;
                fixedTargetDate?: any | null;
                targetDateProfileDefId?: any | null;
                label: { __typename?: 'LocalizedString'; en: string };
                targetDateProfileDef?: {
                    __typename?: 'UserProfileDef';
                    id: any;
                    label?: { __typename?: 'LocalizedString'; en: string } | null;
                } | null;
            } | null;
            article?: { __typename?: 'Article'; id: any; name: string } | null;
        }>;
    };
};

export type ArticleListQueryVariables = Exact<{
    input: ArticleListInput;
}>;

export type ArticleListQuery = {
    __typename?: 'Query';
    articlesV2: {
        __typename?: 'ArticleListResponse';
        total: number;
        results: Array<{
            __typename?: 'Article';
            name: string;
            id: any;
            description?: string | null;
            keywords?: string | null;
            articleCategory?: { __typename?: 'ArticleCategory'; id: any; name: string } | null;
        }>;
    };
};

export type ArticleByIdQueryVariables = Exact<{
    input: GetArticleInput;
}>;

export type ArticleByIdQuery = {
    __typename?: 'Query';
    article?: {
        __typename?: 'Article';
        id: any;
        name: string;
        description?: string | null;
        keywords?: string | null;
        references?: string | null;
        articleCategoryId: any;
        relatedArticleIds?: Array<any> | null;
        icon?: ContentIcon | null;
        stackId?: number | null;
        imageContentId?: any | null;
        groups?: Array<any> | null;
        reportingCategorys?: Array<any> | null;
        changesToPublish?: boolean | null;
        updatedAt: any;
        imageContent?: { __typename?: 'SimpleContent'; id: any; name: string } | null;
        articleCategory?: { __typename?: 'ArticleCategory'; id: any; name: string } | null;
        callToActions?: Array<{
            __typename?: 'CallToAction';
            id?: any | null;
            type: CtaType;
            simpleContentId?: any | null;
            tagList?: Array<any> | null;
            defaultButtonText?: boolean | null;
            buttonText?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            parameter1?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            parameter2?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            simpleContent?: { __typename?: 'SimpleContent'; name: string } | null;
        }> | null;
        variants?: Array<{
            __typename?: 'ContentVariant';
            language: Language;
            readingLevel: ReadingLevel;
            appBundleId?: string | null;
            imageContentId?: any | null;
            datePublished?: any | null;
            dateEdited?: any | null;
            editedBy?: string | null;
            titleMarkup?: string | null;
            summaryMarkup?: string | null;
            bodyMarkup?: string | null;
            callToActions?: Array<{
                __typename?: 'CallToAction';
                id?: any | null;
                type: CtaType;
                simpleContentId?: any | null;
                tagList?: Array<any> | null;
                defaultButtonText?: boolean | null;
                buttonText?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                parameter1?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                parameter2?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                simpleContent?: { __typename?: 'SimpleContent'; name: string } | null;
            }> | null;
        }> | null;
    } | null;
};

export type CreateArticleMutationVariables = Exact<{
    input: ArticleInput;
}>;

export type CreateArticleMutation = {
    __typename?: 'Mutation';
    createArticleEncoded?: {
        __typename?: 'CreateArticleEncodedResponse';
        status: number;
        message: string;
        success: boolean;
        resourceCreated?: {
            __typename?: 'Article';
            id: any;
            name: string;
            description?: string | null;
            keywords?: string | null;
            references?: string | null;
            articleCategoryId: any;
            relatedArticleIds?: Array<any> | null;
            icon?: ContentIcon | null;
            stackId?: number | null;
            imageContentId?: any | null;
            groups?: Array<any> | null;
            reportingCategorys?: Array<any> | null;
            changesToPublish?: boolean | null;
            updatedAt: any;
            imageContent?: { __typename?: 'SimpleContent'; id: any; name: string } | null;
            articleCategory?: { __typename?: 'ArticleCategory'; id: any; name: string } | null;
            callToActions?: Array<{
                __typename?: 'CallToAction';
                id?: any | null;
                type: CtaType;
                simpleContentId?: any | null;
                tagList?: Array<any> | null;
                defaultButtonText?: boolean | null;
                buttonText?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                parameter1?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                parameter2?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                simpleContent?: { __typename?: 'SimpleContent'; name: string } | null;
            }> | null;
            variants?: Array<{
                __typename?: 'ContentVariant';
                language: Language;
                readingLevel: ReadingLevel;
                appBundleId?: string | null;
                imageContentId?: any | null;
                datePublished?: any | null;
                dateEdited?: any | null;
                editedBy?: string | null;
                titleMarkup?: string | null;
                summaryMarkup?: string | null;
                bodyMarkup?: string | null;
                callToActions?: Array<{
                    __typename?: 'CallToAction';
                    id?: any | null;
                    type: CtaType;
                    simpleContentId?: any | null;
                    tagList?: Array<any> | null;
                    defaultButtonText?: boolean | null;
                    buttonText?: {
                        __typename?: 'LocalizedStringLoose';
                        en?: string | null;
                        es?: string | null;
                    } | null;
                    parameter1?: {
                        __typename?: 'LocalizedStringLoose';
                        en?: string | null;
                        es?: string | null;
                    } | null;
                    parameter2?: {
                        __typename?: 'LocalizedStringLoose';
                        en?: string | null;
                        es?: string | null;
                    } | null;
                    simpleContent?: { __typename?: 'SimpleContent'; name: string } | null;
                }> | null;
            }> | null;
        } | null;
    } | null;
};

export type UpdateArticleMutationVariables = Exact<{
    updateArticleInput: UpdateArticleEncodedInput;
}>;

export type UpdateArticleMutation = {
    __typename?: 'Mutation';
    updateArticleEncoded?: {
        __typename?: 'UpdateArticleEncodedResponse';
        status: number;
        message: string;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'Article';
            id: any;
            name: string;
            description?: string | null;
            keywords?: string | null;
            references?: string | null;
            articleCategoryId: any;
            relatedArticleIds?: Array<any> | null;
            icon?: ContentIcon | null;
            stackId?: number | null;
            imageContentId?: any | null;
            groups?: Array<any> | null;
            reportingCategorys?: Array<any> | null;
            changesToPublish?: boolean | null;
            updatedAt: any;
            imageContent?: { __typename?: 'SimpleContent'; id: any; name: string } | null;
            articleCategory?: { __typename?: 'ArticleCategory'; id: any; name: string } | null;
            callToActions?: Array<{
                __typename?: 'CallToAction';
                id?: any | null;
                type: CtaType;
                simpleContentId?: any | null;
                tagList?: Array<any> | null;
                defaultButtonText?: boolean | null;
                buttonText?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                parameter1?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                parameter2?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                simpleContent?: { __typename?: 'SimpleContent'; name: string } | null;
            }> | null;
            variants?: Array<{
                __typename?: 'ContentVariant';
                language: Language;
                readingLevel: ReadingLevel;
                appBundleId?: string | null;
                imageContentId?: any | null;
                datePublished?: any | null;
                dateEdited?: any | null;
                editedBy?: string | null;
                titleMarkup?: string | null;
                summaryMarkup?: string | null;
                bodyMarkup?: string | null;
                callToActions?: Array<{
                    __typename?: 'CallToAction';
                    id?: any | null;
                    type: CtaType;
                    simpleContentId?: any | null;
                    tagList?: Array<any> | null;
                    defaultButtonText?: boolean | null;
                    buttonText?: {
                        __typename?: 'LocalizedStringLoose';
                        en?: string | null;
                        es?: string | null;
                    } | null;
                    parameter1?: {
                        __typename?: 'LocalizedStringLoose';
                        en?: string | null;
                        es?: string | null;
                    } | null;
                    parameter2?: {
                        __typename?: 'LocalizedStringLoose';
                        en?: string | null;
                        es?: string | null;
                    } | null;
                    simpleContent?: { __typename?: 'SimpleContent'; name: string } | null;
                }> | null;
            }> | null;
        } | null;
    } | null;
};

export type UpsertArticleVariantMutationVariables = Exact<{
    articleId: Scalars['String'];
    variant: ContentVariantInput;
}>;

export type UpsertArticleVariantMutation = {
    __typename?: 'Mutation';
    upsertArticleVariant?: {
        __typename?: 'UpsertVariantResponse';
        status: number;
        message: string;
        success: boolean;
        updatedArticle?: {
            __typename?: 'Article';
            id: any;
            name: string;
            description?: string | null;
            keywords?: string | null;
            references?: string | null;
            articleCategoryId: any;
            relatedArticleIds?: Array<any> | null;
            icon?: ContentIcon | null;
            stackId?: number | null;
            imageContentId?: any | null;
            groups?: Array<any> | null;
            reportingCategorys?: Array<any> | null;
            changesToPublish?: boolean | null;
            updatedAt: any;
            imageContent?: { __typename?: 'SimpleContent'; id: any; name: string } | null;
            articleCategory?: { __typename?: 'ArticleCategory'; id: any; name: string } | null;
            callToActions?: Array<{
                __typename?: 'CallToAction';
                id?: any | null;
                type: CtaType;
                simpleContentId?: any | null;
                tagList?: Array<any> | null;
                defaultButtonText?: boolean | null;
                buttonText?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                parameter1?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                parameter2?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                simpleContent?: { __typename?: 'SimpleContent'; name: string } | null;
            }> | null;
            variants?: Array<{
                __typename?: 'ContentVariant';
                language: Language;
                readingLevel: ReadingLevel;
                appBundleId?: string | null;
                imageContentId?: any | null;
                datePublished?: any | null;
                dateEdited?: any | null;
                editedBy?: string | null;
                titleMarkup?: string | null;
                summaryMarkup?: string | null;
                bodyMarkup?: string | null;
                callToActions?: Array<{
                    __typename?: 'CallToAction';
                    id?: any | null;
                    type: CtaType;
                    simpleContentId?: any | null;
                    tagList?: Array<any> | null;
                    defaultButtonText?: boolean | null;
                    buttonText?: {
                        __typename?: 'LocalizedStringLoose';
                        en?: string | null;
                        es?: string | null;
                    } | null;
                    parameter1?: {
                        __typename?: 'LocalizedStringLoose';
                        en?: string | null;
                        es?: string | null;
                    } | null;
                    parameter2?: {
                        __typename?: 'LocalizedStringLoose';
                        en?: string | null;
                        es?: string | null;
                    } | null;
                    simpleContent?: { __typename?: 'SimpleContent'; name: string } | null;
                }> | null;
            }> | null;
        } | null;
    } | null;
};

export type PublishArticleMutationVariables = Exact<{
    input: Scalars['String'];
}>;

export type PublishArticleMutation = {
    __typename?: 'Mutation';
    publishArticle?: {
        __typename?: 'PublishArticleMutationResponse';
        status: number;
        message: string;
        success: boolean;
    } | null;
};

export type CloneVariantMutationVariables = Exact<{
    input: CloneVariantInput;
}>;

export type CloneVariantMutation = {
    __typename?: 'Mutation';
    cloneVariant?: {
        __typename?: 'CloneVariantMutationResponse';
        status: number;
        message: string;
        success: boolean;
        language?: string | null;
        readingLevel?: string | null;
        appBundleId?: string | null;
        article?: {
            __typename?: 'Article';
            id: any;
            name: string;
            description?: string | null;
            keywords?: string | null;
            references?: string | null;
            articleCategoryId: any;
            relatedArticleIds?: Array<any> | null;
            icon?: ContentIcon | null;
            stackId?: number | null;
            imageContentId?: any | null;
            groups?: Array<any> | null;
            reportingCategorys?: Array<any> | null;
            changesToPublish?: boolean | null;
            updatedAt: any;
            imageContent?: { __typename?: 'SimpleContent'; id: any; name: string } | null;
            articleCategory?: { __typename?: 'ArticleCategory'; id: any; name: string } | null;
            callToActions?: Array<{
                __typename?: 'CallToAction';
                id?: any | null;
                type: CtaType;
                simpleContentId?: any | null;
                tagList?: Array<any> | null;
                defaultButtonText?: boolean | null;
                buttonText?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                parameter1?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                parameter2?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                simpleContent?: { __typename?: 'SimpleContent'; name: string } | null;
            }> | null;
            variants?: Array<{
                __typename?: 'ContentVariant';
                language: Language;
                readingLevel: ReadingLevel;
                appBundleId?: string | null;
                imageContentId?: any | null;
                datePublished?: any | null;
                dateEdited?: any | null;
                editedBy?: string | null;
                titleMarkup?: string | null;
                summaryMarkup?: string | null;
                bodyMarkup?: string | null;
                callToActions?: Array<{
                    __typename?: 'CallToAction';
                    id?: any | null;
                    type: CtaType;
                    simpleContentId?: any | null;
                    tagList?: Array<any> | null;
                    defaultButtonText?: boolean | null;
                    buttonText?: {
                        __typename?: 'LocalizedStringLoose';
                        en?: string | null;
                        es?: string | null;
                    } | null;
                    parameter1?: {
                        __typename?: 'LocalizedStringLoose';
                        en?: string | null;
                        es?: string | null;
                    } | null;
                    parameter2?: {
                        __typename?: 'LocalizedStringLoose';
                        en?: string | null;
                        es?: string | null;
                    } | null;
                    simpleContent?: { __typename?: 'SimpleContent'; name: string } | null;
                }> | null;
            }> | null;
        } | null;
    } | null;
};

export type DeleteVariantMutationVariables = Exact<{
    input: DeleteVariantInput;
}>;

export type DeleteVariantMutation = {
    __typename?: 'Mutation';
    deleteVariant?: {
        __typename?: 'DeleteVariantMutationResponse';
        status: number;
        message: string;
        success: boolean;
        article?: {
            __typename?: 'Article';
            id: any;
            name: string;
            description?: string | null;
            keywords?: string | null;
            references?: string | null;
            articleCategoryId: any;
            relatedArticleIds?: Array<any> | null;
            icon?: ContentIcon | null;
            stackId?: number | null;
            imageContentId?: any | null;
            groups?: Array<any> | null;
            reportingCategorys?: Array<any> | null;
            changesToPublish?: boolean | null;
            updatedAt: any;
            imageContent?: { __typename?: 'SimpleContent'; id: any; name: string } | null;
            articleCategory?: { __typename?: 'ArticleCategory'; id: any; name: string } | null;
            callToActions?: Array<{
                __typename?: 'CallToAction';
                id?: any | null;
                type: CtaType;
                simpleContentId?: any | null;
                tagList?: Array<any> | null;
                defaultButtonText?: boolean | null;
                buttonText?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                parameter1?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                parameter2?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                simpleContent?: { __typename?: 'SimpleContent'; name: string } | null;
            }> | null;
            variants?: Array<{
                __typename?: 'ContentVariant';
                language: Language;
                readingLevel: ReadingLevel;
                appBundleId?: string | null;
                imageContentId?: any | null;
                datePublished?: any | null;
                dateEdited?: any | null;
                editedBy?: string | null;
                titleMarkup?: string | null;
                summaryMarkup?: string | null;
                bodyMarkup?: string | null;
                callToActions?: Array<{
                    __typename?: 'CallToAction';
                    id?: any | null;
                    type: CtaType;
                    simpleContentId?: any | null;
                    tagList?: Array<any> | null;
                    defaultButtonText?: boolean | null;
                    buttonText?: {
                        __typename?: 'LocalizedStringLoose';
                        en?: string | null;
                        es?: string | null;
                    } | null;
                    parameter1?: {
                        __typename?: 'LocalizedStringLoose';
                        en?: string | null;
                        es?: string | null;
                    } | null;
                    parameter2?: {
                        __typename?: 'LocalizedStringLoose';
                        en?: string | null;
                        es?: string | null;
                    } | null;
                    simpleContent?: { __typename?: 'SimpleContent'; name: string } | null;
                }> | null;
            }> | null;
        } | null;
    } | null;
};

export type DeleteArticleMutationVariables = Exact<{
    input: Scalars['String'];
}>;

export type DeleteArticleMutation = {
    __typename?: 'Mutation';
    deleteArticle?: {
        __typename?: 'DeleteArticleMutationResponse';
        status: number;
        message: string;
        success: boolean;
        id: string;
    } | null;
};

export type ArticleListSimpleQueryVariables = Exact<{ [key: string]: never }>;

export type ArticleListSimpleQuery = {
    __typename?: 'Query';
    articlesV2: {
        __typename?: 'ArticleListResponse';
        results: Array<{
            __typename?: 'Article';
            name: string;
            id: any;
            articleCategory?: { __typename?: 'ArticleCategory'; id: any } | null;
        }>;
    };
};

export type ArticleApprovalsQueryVariables = Exact<{
    input: ArticleApprovalQueryInput;
}>;

export type ArticleApprovalsQuery = {
    __typename?: 'Query';
    articleApprovalsV2: {
        __typename?: 'ArticleApprovalPaginationQueryResponse';
        results: Array<{
            __typename?: 'ArticleApproval';
            id: any;
            appBundleId: string;
            articleId: any;
            language: Language;
            readingLevel: ReadingLevel;
            status?: ApprovalStatus | null;
            approvedBy?: string | null;
            dateApproved?: any | null;
            articleName: string;
        }>;
    };
};

export type CreateBatchTaskMutationVariables = Exact<{
    input: BatchTaskInput;
}>;

export type CreateBatchTaskMutation = {
    __typename?: 'Mutation';
    createBatchTask?: {
        __typename?: 'CreateBatchTaskResponse';
        status: number;
        message: string;
        success: boolean;
    } | null;
};

export type ArticleWhereUsedQueryVariables = Exact<{
    articleId: Scalars['String'];
}>;

export type ArticleWhereUsedQuery = {
    __typename?: 'Query';
    articleWhereUsed?: {
        __typename?: 'EntityWhereUsedResponse';
        dependencies?: Array<{
            __typename?: 'DependentEntity';
            entityType: WhereUsedEntity;
            blocksDelete?: boolean | null;
            total?: number | null;
            entries?: Array<{
                __typename?: 'DependentEntityEntry';
                id: string;
                name: string;
            } | null> | null;
        }> | null;
    } | null;
};

export type CloneArticleMutationVariables = Exact<{
    input: CloneArticleInput;
}>;

export type CloneArticleMutation = {
    __typename?: 'Mutation';
    cloneArticle?: {
        __typename?: 'CloneArticleMutationResponse';
        status: number;
        message: string;
        success: boolean;
        article?: { __typename?: 'Article'; id: any } | null;
    } | null;
};

export type DeleteBioScreenForBioScreenBuilderMutationVariables = Exact<{
    deleteBioScreenInput: DeleteBioScreenInput;
}>;

export type DeleteBioScreenForBioScreenBuilderMutation = {
    __typename?: 'Mutation';
    deleteBioScreen?: {
        __typename?: 'DeleteBioScreenResponse';
        success: boolean;
        message: string;
        id?: string | null;
    } | null;
};

export type CloneBioScreenForBioScreenCloneModalMutationVariables = Exact<{
    input: CloneBioScreenInput;
}>;

export type CloneBioScreenForBioScreenCloneModalMutation = {
    __typename?: 'Mutation';
    cloneBioScreen?: {
        __typename?: 'CloneBioScreenResponse';
        success: boolean;
        message: string;
        bioScreen?: {
            __typename?: 'BioScreen';
            id: any;
            createdAt: any;
            updatedAt: any;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            yourHealthInfo: {
                __typename?: 'BioScreenShortcut';
                icon: BioScreenShortcutIcon;
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
                instructions: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            };
            surveyGroups: Array<{
                __typename?: 'BioScreenSurveyGroup';
                id: string;
                tags?: Array<string | null> | null;
                label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
                surveys: Array<{
                    __typename?: 'BioScreenSurvey';
                    surveyId: any;
                    tags?: Array<string | null> | null;
                    label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
                }>;
            }>;
            shortcuts: Array<{
                __typename?: 'BioScreenShortcut';
                type: BioScreenShortcutType;
                icon: BioScreenShortcutIcon;
                surveyId?: any | null;
                surveyGroupId?: string | null;
                tags?: Array<string | null> | null;
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
                instructions: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            }>;
        } | null;
    } | null;
};

export type BioScreensForProfileScreensPageQueryVariables = Exact<{ [key: string]: never }>;

export type BioScreensForProfileScreensPageQuery = {
    __typename?: 'Query';
    bioScreensV2: {
        __typename?: 'BioScreenPaginationQueryResponse';
        results: Array<{
            __typename?: 'BioScreen';
            id: any;
            createdAt: any;
            updatedAt: any;
            yourHealthInfo: { __typename?: 'BioScreenShortcut'; icon: BioScreenShortcutIcon };
            label: { __typename?: 'LocalizedString'; en: string };
        }>;
    };
};

export type UpdateBioScreenForBioScreenBuilderMutationVariables = Exact<{
    bioScreenUpdateInput: UpdateBioScreenInput;
}>;

export type UpdateBioScreenForBioScreenBuilderMutation = {
    __typename?: 'Mutation';
    updateBioScreen?: {
        __typename?: 'UpdateBioScreenResponse';
        success: boolean;
        message: string;
        resourceUpdated?: {
            __typename?: 'BioScreen';
            id: any;
            createdAt: any;
            updatedAt: any;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            yourHealthInfo: {
                __typename?: 'BioScreenShortcut';
                icon: BioScreenShortcutIcon;
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
                instructions: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            };
            surveyGroups: Array<{
                __typename?: 'BioScreenSurveyGroup';
                id: string;
                tags?: Array<string | null> | null;
                label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
                surveys: Array<{
                    __typename?: 'BioScreenSurvey';
                    surveyId: any;
                    tags?: Array<string | null> | null;
                    label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
                }>;
            }>;
            shortcuts: Array<{
                __typename?: 'BioScreenShortcut';
                type: BioScreenShortcutType;
                icon: BioScreenShortcutIcon;
                surveyId?: any | null;
                surveyGroupId?: string | null;
                tags?: Array<string | null> | null;
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
                instructions: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            }>;
        } | null;
    } | null;
};

export type CreateBioScreenForBioScreenBuilderMutationVariables = Exact<{
    createBioScreenInput: BioScreenInput;
}>;

export type CreateBioScreenForBioScreenBuilderMutation = {
    __typename?: 'Mutation';
    createBioScreen?: {
        __typename?: 'CreateBioScreenResponse';
        success: boolean;
        message: string;
        resourceCreated?: {
            __typename?: 'BioScreen';
            id: any;
            createdAt: any;
            updatedAt: any;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            yourHealthInfo: {
                __typename?: 'BioScreenShortcut';
                icon: BioScreenShortcutIcon;
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
                instructions: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            };
            surveyGroups: Array<{
                __typename?: 'BioScreenSurveyGroup';
                id: string;
                tags?: Array<string | null> | null;
                label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
                surveys: Array<{
                    __typename?: 'BioScreenSurvey';
                    surveyId: any;
                    tags?: Array<string | null> | null;
                    label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
                }>;
            }>;
            shortcuts: Array<{
                __typename?: 'BioScreenShortcut';
                type: BioScreenShortcutType;
                icon: BioScreenShortcutIcon;
                surveyId?: any | null;
                surveyGroupId?: string | null;
                tags?: Array<string | null> | null;
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
                instructions: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            }>;
        } | null;
    } | null;
};

export type FetchBioScreenForBioScreenBuilderQueryVariables = Exact<{
    bioScreenInput: GetBioScreenInput;
}>;

export type FetchBioScreenForBioScreenBuilderQuery = {
    __typename?: 'Query';
    bioScreen?: {
        __typename?: 'BioScreen';
        id: any;
        createdAt: any;
        updatedAt: any;
        label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        yourHealthInfo: {
            __typename?: 'BioScreenShortcut';
            icon: BioScreenShortcutIcon;
            label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            instructions: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        };
        surveyGroups: Array<{
            __typename?: 'BioScreenSurveyGroup';
            id: string;
            tags?: Array<string | null> | null;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            surveys: Array<{
                __typename?: 'BioScreenSurvey';
                surveyId: any;
                tags?: Array<string | null> | null;
                label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            }>;
        }>;
        shortcuts: Array<{
            __typename?: 'BioScreenShortcut';
            type: BioScreenShortcutType;
            icon: BioScreenShortcutIcon;
            surveyId?: any | null;
            surveyGroupId?: string | null;
            tags?: Array<string | null> | null;
            label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            instructions: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        }>;
    } | null;
};

export type FetchTagsForBioScreensBuilderQueryVariables = Exact<{ [key: string]: never }>;

export type FetchTagsForBioScreensBuilderQuery = {
    __typename?: 'Query';
    tagsV2: {
        __typename?: 'TagPaginationQueryResponse';
        results: Array<{ __typename?: 'Tag'; name: string; id: any }>;
    };
};

export type FetchSurveyDefsForBioScreenBuilderQueryVariables = Exact<{ [key: string]: never }>;

export type FetchSurveyDefsForBioScreenBuilderQuery = {
    __typename?: 'Query';
    surveyDefs: Array<{
        __typename?: 'SurveyDef';
        id: any;
        name?: string | null;
        label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    }>;
};

export type CreateCalendarForCalendarModalMutationVariables = Exact<{
    input: CalendarInput;
}>;

export type CreateCalendarForCalendarModalMutation = {
    __typename?: 'Mutation';
    createCalendar?: {
        __typename?: 'CreateCalendarResponse';
        message: string;
        status: number;
        success: boolean;
        result?: {
            __typename?: 'Calendar';
            id: any;
            name: string;
            startDate: any;
            endDate: any;
            offDays?: Array<any> | null;
            startTime: any;
            endTime: any;
            days: Array<{
                __typename?: 'CalendarDay';
                date: any;
                endOfWorkDay?: any | null;
                startOfWorkDay?: any | null;
                workDay: boolean;
            }>;
        } | null;
    } | null;
};

export type UpdateCalendarForCalendarModalMutationVariables = Exact<{
    input: CalendarUpdateInput;
}>;

export type UpdateCalendarForCalendarModalMutation = {
    __typename?: 'Mutation';
    updateCalendar?: {
        __typename?: 'UpdateCalendarResponse';
        message: string;
        status: number;
        success: boolean;
        updated?: {
            __typename?: 'Calendar';
            id: any;
            name: string;
            startDate: any;
            endDate: any;
            offDays?: Array<any> | null;
            startTime: any;
            endTime: any;
            days: Array<{
                __typename?: 'CalendarDay';
                date: any;
                startOfWorkDay?: any | null;
                endOfWorkDay?: any | null;
                workDay: boolean;
            }>;
        } | null;
    } | null;
};

export type FetchCalendarsV2ForCalendarsPageQueryVariables = Exact<{
    input?: InputMaybe<CalendarQueryInput>;
}>;

export type FetchCalendarsV2ForCalendarsPageQuery = {
    __typename?: 'Query';
    calendarsV2: {
        __typename?: 'CalendarPaginationQueryResponse';
        results: Array<{
            __typename?: 'Calendar';
            id: any;
            name: string;
            startDate: any;
            endDate: any;
            offDays?: Array<any> | null;
            startTime: any;
            endTime: any;
            isTestData?: boolean | null;
            days: Array<{
                __typename?: 'CalendarDay';
                date: any;
                endOfWorkDay?: any | null;
                startOfWorkDay?: any | null;
                workDay: boolean;
            }>;
        }>;
    };
};

export type CreateCarePlanForNewCarePlanPageMutationVariables = Exact<{
    input: CarePlanInput;
}>;

export type CreateCarePlanForNewCarePlanPageMutation = {
    __typename?: 'Mutation';
    createCarePlan?: {
        __typename?: 'CreateCarePlanResponse';
        success: boolean;
        message: string;
        resourceCreated?: {
            __typename?: 'CarePlan';
            type: CarePlanType;
            baseCarePlanId?: any | null;
            timelineId?: any | null;
            includeApps?: Array<string> | null;
            excludeApps?: Array<string> | null;
            tags?: Array<string> | null;
            viewLimitMinutes?: number | null;
            createdAt: any;
            updatedAt: any;
            id: any;
            baseCarePlan?: {
                __typename?: 'CarePlan';
                portalLabel?: { __typename?: 'LocalizedString'; en: string } | null;
            } | null;
            portalLabel?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            appLabel?: { __typename?: 'LocalizedString'; es?: string | null; en: string } | null;
            groups: Array<{
                __typename?: 'CarePlanTodoGroup';
                id: any;
                suppressedTemplates?: Array<any | null> | null;
                label?: { __typename?: 'LocalizedString'; es?: string | null; en: string } | null;
                description?: {
                    __typename?: 'LocalizedString';
                    en: string;
                    es?: string | null;
                } | null;
                templates: Array<{
                    __typename?: 'CarePlanTodoTemplate';
                    articleId: any;
                    id: any;
                    priority: number;
                    tags?: Array<string> | null;
                    type: CarePlanTodoTemplateClockType;
                    offsetDays?: number | null;
                    offsetMonths?: number | null;
                    offsetYears?: number | null;
                    targetDate?: any | null;
                    durationMinutes?: number | null;
                    isRepeating: boolean;
                    repeatIntervalMinutes?: number | null;
                    repeatCount?: number | null;
                    isEssential: boolean;
                    title?: {
                        __typename?: 'LocalizedString';
                        es?: string | null;
                        en: string;
                    } | null;
                    description?: {
                        __typename?: 'LocalizedString';
                        es?: string | null;
                        en: string;
                    } | null;
                    notificationData?: {
                        __typename?: 'NotificationData';
                        shouldSendNotification: boolean;
                        en?: string | null;
                        es?: string | null;
                    } | null;
                }>;
            }>;
            timeline?: {
                __typename?: 'WhatsNewTimeline';
                id: any;
                label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            } | null;
        } | null;
    } | null;
};

export type DeleteCarePlanEditCarePlanPageMutationVariables = Exact<{
    input: DeleteCarePlanInput;
}>;

export type DeleteCarePlanEditCarePlanPageMutation = {
    __typename?: 'Mutation';
    deleteCarePlan?: {
        __typename?: 'DeleteCarePlanResponse';
        success: boolean;
        message: string;
        id?: any | null;
    } | null;
};

export type UpdateCarePlanForCarePlanPageMutationVariables = Exact<{
    input: UpdateCarePlanInput;
}>;

export type UpdateCarePlanForCarePlanPageMutation = {
    __typename?: 'Mutation';
    updateCarePlan?: {
        __typename?: 'UpdateCarePlanResponse';
        success: boolean;
        message: string;
        resourceUpdated?: {
            __typename?: 'CarePlan';
            type: CarePlanType;
            baseCarePlanId?: any | null;
            timelineId?: any | null;
            includeApps?: Array<string> | null;
            excludeApps?: Array<string> | null;
            tags?: Array<string> | null;
            viewLimitMinutes?: number | null;
            createdAt: any;
            updatedAt: any;
            id: any;
            portalLabel?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            appLabel?: { __typename?: 'LocalizedString'; es?: string | null; en: string } | null;
            groups: Array<{
                __typename?: 'CarePlanTodoGroup';
                id: any;
                suppressedTemplates?: Array<any | null> | null;
                label?: { __typename?: 'LocalizedString'; es?: string | null; en: string } | null;
                description?: {
                    __typename?: 'LocalizedString';
                    en: string;
                    es?: string | null;
                } | null;
                templates: Array<{
                    __typename?: 'CarePlanTodoTemplate';
                    articleId: any;
                    id: any;
                    priority: number;
                    tags?: Array<string> | null;
                    type: CarePlanTodoTemplateClockType;
                    offsetDays?: number | null;
                    offsetMonths?: number | null;
                    offsetYears?: number | null;
                    targetDate?: any | null;
                    durationMinutes?: number | null;
                    isRepeating: boolean;
                    repeatIntervalMinutes?: number | null;
                    repeatCount?: number | null;
                    isEssential: boolean;
                    title?: {
                        __typename?: 'LocalizedString';
                        es?: string | null;
                        en: string;
                    } | null;
                    description?: {
                        __typename?: 'LocalizedString';
                        es?: string | null;
                        en: string;
                    } | null;
                    notificationData?: {
                        __typename?: 'NotificationData';
                        shouldSendNotification: boolean;
                        en?: string | null;
                        es?: string | null;
                    } | null;
                }>;
            }>;
            timeline?: {
                __typename?: 'WhatsNewTimeline';
                id: any;
                label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            } | null;
        } | null;
    } | null;
};

export type CarePlanForCarePlanPageQueryVariables = Exact<{
    carePlanInput: GetCarePlanInput;
}>;

export type CarePlanForCarePlanPageQuery = {
    __typename?: 'Query';
    carePlan?: {
        __typename?: 'CarePlan';
        type: CarePlanType;
        baseCarePlanId?: any | null;
        timelineId?: any | null;
        includeApps?: Array<string> | null;
        excludeApps?: Array<string> | null;
        tags?: Array<string> | null;
        viewLimitMinutes?: number | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        baseCarePlan?: {
            __typename?: 'CarePlan';
            type: CarePlanType;
            baseCarePlanId?: any | null;
            timelineId?: any | null;
            includeApps?: Array<string> | null;
            excludeApps?: Array<string> | null;
            tags?: Array<string> | null;
            viewLimitMinutes?: number | null;
            createdAt: any;
            updatedAt: any;
            id: any;
            portalLabel?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            appLabel?: { __typename?: 'LocalizedString'; es?: string | null; en: string } | null;
            groups: Array<{
                __typename?: 'CarePlanTodoGroup';
                id: any;
                suppressedTemplates?: Array<any | null> | null;
                label?: { __typename?: 'LocalizedString'; es?: string | null; en: string } | null;
                description?: {
                    __typename?: 'LocalizedString';
                    en: string;
                    es?: string | null;
                } | null;
                templates: Array<{
                    __typename?: 'CarePlanTodoTemplate';
                    articleId: any;
                    id: any;
                    priority: number;
                    tags?: Array<string> | null;
                    type: CarePlanTodoTemplateClockType;
                    offsetDays?: number | null;
                    offsetMonths?: number | null;
                    offsetYears?: number | null;
                    targetDate?: any | null;
                    durationMinutes?: number | null;
                    isRepeating: boolean;
                    repeatIntervalMinutes?: number | null;
                    repeatCount?: number | null;
                    isEssential: boolean;
                    title?: {
                        __typename?: 'LocalizedString';
                        es?: string | null;
                        en: string;
                    } | null;
                    description?: {
                        __typename?: 'LocalizedString';
                        es?: string | null;
                        en: string;
                    } | null;
                    notificationData?: {
                        __typename?: 'NotificationData';
                        shouldSendNotification: boolean;
                        en?: string | null;
                        es?: string | null;
                    } | null;
                }>;
            }>;
            timeline?: {
                __typename?: 'WhatsNewTimeline';
                id: any;
                label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            } | null;
        } | null;
        portalLabel?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        appLabel?: { __typename?: 'LocalizedString'; es?: string | null; en: string } | null;
        groups: Array<{
            __typename?: 'CarePlanTodoGroup';
            id: any;
            suppressedTemplates?: Array<any | null> | null;
            label?: { __typename?: 'LocalizedString'; es?: string | null; en: string } | null;
            description?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            templates: Array<{
                __typename?: 'CarePlanTodoTemplate';
                articleId: any;
                id: any;
                priority: number;
                tags?: Array<string> | null;
                type: CarePlanTodoTemplateClockType;
                offsetDays?: number | null;
                offsetMonths?: number | null;
                offsetYears?: number | null;
                targetDate?: any | null;
                durationMinutes?: number | null;
                isRepeating: boolean;
                repeatIntervalMinutes?: number | null;
                repeatCount?: number | null;
                isEssential: boolean;
                title?: { __typename?: 'LocalizedString'; es?: string | null; en: string } | null;
                description?: {
                    __typename?: 'LocalizedString';
                    es?: string | null;
                    en: string;
                } | null;
                notificationData?: {
                    __typename?: 'NotificationData';
                    shouldSendNotification: boolean;
                    en?: string | null;
                    es?: string | null;
                } | null;
            }>;
        }>;
        timeline?: {
            __typename?: 'WhatsNewTimeline';
            id: any;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        } | null;
    } | null;
};

export type PreFetchDataForCarePlanPageQueryVariables = Exact<{ [key: string]: never }>;

export type PreFetchDataForCarePlanPageQuery = {
    __typename?: 'Query';
    whatsNewTimelines: Array<{
        __typename?: 'WhatsNewTimeline';
        id: any;
        type: WhatsNewTimelineType;
        label: { __typename?: 'LocalizedString'; en: string };
    }>;
    amsApps: Array<{ __typename?: 'AMSApp'; id: string; bundleId: string }>;
    tags: Array<{ __typename?: 'Tag'; id: any; name: string }>;
};

export type CarePlansForCarePlansPageQueryVariables = Exact<{ [key: string]: never }>;

export type CarePlansForCarePlansPageQuery = {
    __typename?: 'Query';
    carePlansV2: {
        __typename?: 'CarePlanPaginationQueryResponse';
        results: Array<{
            __typename?: 'CarePlan';
            id: any;
            type: CarePlanType;
            portalLabel?: { __typename?: 'LocalizedString'; en: string } | null;
            groups: Array<{
                __typename?: 'CarePlanTodoGroup';
                label?: { __typename?: 'LocalizedString'; en: string } | null;
            }>;
            timeline?: {
                __typename?: 'WhatsNewTimeline';
                label: { __typename?: 'LocalizedString'; en: string };
            } | null;
            baseCarePlan?: {
                __typename?: 'CarePlan';
                portalLabel?: { __typename?: 'LocalizedString'; en: string } | null;
            } | null;
        }>;
    };
};

export type CarePlansForAddPageQueryVariables = Exact<{
    input: CarePlanQueryInput;
}>;

export type CarePlansForAddPageQuery = {
    __typename?: 'Query';
    carePlansV2: {
        __typename?: 'CarePlanPaginationQueryResponse';
        results: Array<{
            __typename?: 'CarePlan';
            id: any;
            type: CarePlanType;
            portalLabel?: { __typename?: 'LocalizedString'; en: string } | null;
            groups: Array<{
                __typename?: 'CarePlanTodoGroup';
                label?: { __typename?: 'LocalizedString'; en: string } | null;
            }>;
            timeline?: {
                __typename?: 'WhatsNewTimeline';
                label: { __typename?: 'LocalizedString'; en: string };
            } | null;
            baseCarePlan?: {
                __typename?: 'CarePlan';
                portalLabel?: { __typename?: 'LocalizedString'; en: string } | null;
            } | null;
        }>;
    };
};

export type CareTeamMemberTypesQueryVariables = Exact<{
    input?: InputMaybe<CareTeamMemberTypeQueryInput>;
}>;

export type CareTeamMemberTypesQuery = {
    __typename?: 'Query';
    careTeamMemberTypesV2: {
        __typename?: 'CareTeamMemberTypePaginationQueryResponse';
        results: Array<{ __typename?: 'CareTeamMemberType'; id: any; name: string }>;
    };
};

export type CreateCareTeamContactMutationVariables = Exact<{
    input: CareTeamContactInput;
}>;

export type CreateCareTeamContactMutation = {
    __typename?: 'Mutation';
    createCareTeamContact?: {
        __typename?: 'CreateCareTeamContactResponse';
        message: string;
        status: number;
        success: boolean;
    } | null;
};

export type UpdateCareTeamContactMutationVariables = Exact<{
    input: UpdateCareTeamContactInput;
}>;

export type UpdateCareTeamContactMutation = {
    __typename?: 'Mutation';
    updateCareTeamContact?: {
        __typename?: 'UpdateCareTeamContactResponse';
        message: string;
        status: number;
        success: boolean;
    } | null;
};

export type CareTeamContactQueryVariables = Exact<{
    input: GetCareTeamContactInput;
}>;

export type CareTeamContactQuery = {
    __typename?: 'Query';
    careTeamContact?: {
        __typename?: 'CareTeamContact';
        careTeamMemberTypeId: any;
        emailAddress: string;
        id: any;
        name: string;
        phoneNumber: string;
        updatedAt: any;
        url: string;
        address: {
            __typename?: 'Address';
            city?: string | null;
            code?: string | null;
            state?: string | null;
            street1?: string | null;
            street2?: string | null;
        };
    } | null;
};

export type FetchCareTeamContactsQueryVariables = Exact<{
    input?: InputMaybe<CareTeamContactsListInput>;
}>;

export type FetchCareTeamContactsQuery = {
    __typename?: 'Query';
    careTeamContactsV2: {
        __typename?: 'CareTeamContactsResponse';
        skip?: number | null;
        limit?: number | null;
        total: number;
        results: Array<{
            __typename?: 'CareTeamContact';
            name: string;
            isTestData?: boolean | null;
            id: any;
            emailAddress: string;
            careTeamMemberTypeId: any;
            phoneNumber: string;
            url: string;
            careTeamMemberType?: { __typename?: 'CareTeamMemberType'; name: string } | null;
            address: {
                __typename?: 'Address';
                state?: string | null;
                city?: string | null;
                code?: string | null;
                street1?: string | null;
                street2?: string | null;
            };
        }>;
    };
};

export type DeleteCareTeamContactMutationVariables = Exact<{
    input: DeleteCareTeamContactInput;
}>;

export type DeleteCareTeamContactMutation = {
    __typename?: 'Mutation';
    deleteCareTeamContact?: {
        __typename?: 'DeleteCareTeamContactResponse';
        message: string;
        status: number;
        success: boolean;
        id?: string | null;
    } | null;
};

export type CreateCareTeamMemberTypeMutationVariables = Exact<{
    input: CareTeamMemberTypeUpdateInput;
}>;

export type CreateCareTeamMemberTypeMutation = {
    __typename?: 'Mutation';
    createCareTeamMemberType?: {
        __typename?: 'CreateCareTeamMemberTypeResponse';
        status: number;
        message: string;
        success: boolean;
        resourceCreated?: {
            __typename?: 'CareTeamMemberType';
            id: any;
            name: string;
            isJob?: boolean | null;
            profileDefId: any;
            createdAt: any;
            updatedAt: any;
        } | null;
    } | null;
};

export type UpdateCareTeamMemberTypeMutationVariables = Exact<{
    input: UpdateCareTeamMemberTypeInput;
}>;

export type UpdateCareTeamMemberTypeMutation = {
    __typename?: 'Mutation';
    updateCareTeamMemberType?: {
        __typename?: 'UpdateCareTeamMemberTypeResponse';
        message: string;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'CareTeamMemberType';
            id: any;
            isTestData?: boolean | null;
            name: string;
            isJob?: boolean | null;
            profileDefId: any;
            updatedAt: any;
            createdAt: any;
        } | null;
    } | null;
};

export type FetchCareTeamMemberTypesForConfigPageQueryVariables = Exact<{
    input?: InputMaybe<CareTeamMemberTypeQueryInput>;
}>;

export type FetchCareTeamMemberTypesForConfigPageQuery = {
    __typename?: 'Query';
    careTeamMemberTypesV2: {
        __typename?: 'CareTeamMemberTypePaginationQueryResponse';
        total: number;
        results: Array<{
            __typename?: 'CareTeamMemberType';
            id: any;
            name: string;
            isJob?: boolean | null;
            profileDefId: any;
            createdAt: any;
            updatedAt: any;
            profileDef?: {
                __typename?: 'UserProfileDef';
                name: string;
                createdAt: any;
                id: any;
                updatedAt: any;
                category: string;
                valueType: UserProfileValueType;
            } | null;
        }>;
    };
};

export type DeleteCareTeamMemberTypeMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteCareTeamMemberTypeMutation = {
    __typename?: 'Mutation';
    deleteCareTeamMemberType?: {
        __typename?: 'DeleteCareTeamMemberTypeMutationResponse';
        success: boolean;
        message: string;
        status: number;
        id: string;
    } | null;
};

export type CareTeamMemberTypeWhereUsedQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type CareTeamMemberTypeWhereUsedQuery = {
    __typename?: 'Query';
    careTeamMemberTypeWhereUsed?: {
        __typename?: 'EntityWhereUsedResponse';
        dependencies?: Array<{
            __typename?: 'DependentEntity';
            entityType: WhereUsedEntity;
            blocksDelete?: boolean | null;
            total?: number | null;
            entries?: Array<{
                __typename?: 'DependentEntityEntry';
                id: string;
                name: string;
            } | null> | null;
        }> | null;
    } | null;
};

export type FetchCareTeamMembersForCareTeamMembersPageQueryVariables = Exact<{
    [key: string]: never;
}>;

export type FetchCareTeamMembersForCareTeamMembersPageQuery = {
    __typename?: 'Query';
    careTeamMembersV2: {
        __typename?: 'CareTeamMemberPaginationQueryResponse';
        results: Array<{
            __typename?: 'CareTeamMember';
            id: any;
            careTeamMemberTypeId?: any | null;
            nameWithType: string;
            careTeamMemberContactId?: any | null;
            careTeamMemberTIN?: string | null;
            careTeamMemberNPI?: string | null;
            practiceExternalId?: string | null;
            practiceCode?: string | null;
            prerequisiteTeamMembers?: Array<any> | null;
            eligibilityCheck?: string | null;
            eligibilityRecheckDays?: number | null;
            consentRequired?: boolean | null;
            consentAgreement?: string | null;
            appBundleId: string;
            usState?: string | null;
            zipCode?: string | null;
            updatedAt: any;
            createdAt: any;
            isActive?: boolean | null;
            userId?: any | null;
            appDefault?: boolean | null;
            careTeamMemberType?: {
                __typename?: 'CareTeamMemberType';
                name: string;
                profileDefId: any;
                createdAt: any;
                updatedAt: any;
                id: any;
            } | null;
            overridenCareTeamMemberContact?: {
                __typename?: 'CareTeamContactForCareTeamMember';
                careTeamMemberTypeId: any;
                name: string;
                phoneNumber: string;
                emailAddress: string;
                url: string;
                address: {
                    __typename?: 'Address';
                    street1?: string | null;
                    street2?: string | null;
                    city?: string | null;
                    state?: string | null;
                    code?: string | null;
                };
            } | null;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        }>;
    };
};

export type UsStateListQueryVariables = Exact<{ [key: string]: never }>;

export type UsStateListQuery = {
    __typename?: 'Query';
    usStateList: Array<{
        __typename?: 'USState';
        abbreviation?: string | null;
        name?: string | null;
    }>;
};

export type FetchCareTeamMemberByIdQueryVariables = Exact<{
    input: GetCareTeamMemberInput;
}>;

export type FetchCareTeamMemberByIdQuery = {
    __typename?: 'Query';
    careTeamMember?: {
        __typename?: 'CareTeamMember';
        id: any;
        careTeamMemberTypeId?: any | null;
        nameWithType: string;
        careTeamMemberContactId?: any | null;
        careTeamMemberTIN?: string | null;
        careTeamMemberNPI?: string | null;
        practiceExternalId?: string | null;
        practiceCode?: string | null;
        prerequisiteTeamMembers?: Array<any> | null;
        eligibilityCheck?: string | null;
        eligibilityRecheckDays?: number | null;
        consentRequired?: boolean | null;
        consentAgreement?: string | null;
        appBundleId: string;
        usState?: string | null;
        zipCode?: string | null;
        updatedAt: any;
        createdAt: any;
        isActive?: boolean | null;
        userId?: any | null;
        appDefault?: boolean | null;
        careTeamMemberType?: {
            __typename?: 'CareTeamMemberType';
            name: string;
            profileDefId: any;
            createdAt: any;
            updatedAt: any;
            id: any;
        } | null;
        overridenCareTeamMemberContact?: {
            __typename?: 'CareTeamContactForCareTeamMember';
            careTeamMemberTypeId: any;
            name: string;
            phoneNumber: string;
            emailAddress: string;
            url: string;
            address: {
                __typename?: 'Address';
                street1?: string | null;
                street2?: string | null;
                city?: string | null;
                state?: string | null;
                code?: string | null;
            };
        } | null;
        label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    } | null;
};

export type UpdateCareTeamMemberFromModalMutationVariables = Exact<{
    input: UpdateCareTeamMemberInput;
}>;

export type UpdateCareTeamMemberFromModalMutation = {
    __typename?: 'Mutation';
    updateCareTeamMember?: {
        __typename?: 'UpdateCareTeamMemberResponse';
        message: string;
        status: number;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'CareTeamMember';
            id: any;
            careTeamMemberTypeId?: any | null;
            nameWithType: string;
            careTeamMemberContactId?: any | null;
            careTeamMemberTIN?: string | null;
            careTeamMemberNPI?: string | null;
            practiceExternalId?: string | null;
            practiceCode?: string | null;
            prerequisiteTeamMembers?: Array<any> | null;
            eligibilityCheck?: string | null;
            eligibilityRecheckDays?: number | null;
            consentRequired?: boolean | null;
            consentAgreement?: string | null;
            appBundleId: string;
            usState?: string | null;
            zipCode?: string | null;
            updatedAt: any;
            createdAt: any;
            isActive?: boolean | null;
            userId?: any | null;
            appDefault?: boolean | null;
            careTeamMemberType?: {
                __typename?: 'CareTeamMemberType';
                name: string;
                profileDefId: any;
                createdAt: any;
                updatedAt: any;
                id: any;
            } | null;
            overridenCareTeamMemberContact?: {
                __typename?: 'CareTeamContactForCareTeamMember';
                careTeamMemberTypeId: any;
                name: string;
                phoneNumber: string;
                emailAddress: string;
                url: string;
                address: {
                    __typename?: 'Address';
                    street1?: string | null;
                    street2?: string | null;
                    city?: string | null;
                    state?: string | null;
                    code?: string | null;
                };
            } | null;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        } | null;
    } | null;
};

export type CreateCareTeamMemberMutationVariables = Exact<{
    input: CareTeamMemberInput;
}>;

export type CreateCareTeamMemberMutation = {
    __typename?: 'Mutation';
    createCareTeamMember?: {
        __typename?: 'CreateCareTeamMemberResponse';
        message: string;
        status: number;
        success: boolean;
        resourceCreated?: {
            __typename?: 'CareTeamMember';
            id: any;
            careTeamMemberTypeId?: any | null;
            nameWithType: string;
            careTeamMemberContactId?: any | null;
            careTeamMemberTIN?: string | null;
            careTeamMemberNPI?: string | null;
            practiceExternalId?: string | null;
            practiceCode?: string | null;
            prerequisiteTeamMembers?: Array<any> | null;
            eligibilityCheck?: string | null;
            eligibilityRecheckDays?: number | null;
            consentRequired?: boolean | null;
            consentAgreement?: string | null;
            appBundleId: string;
            usState?: string | null;
            zipCode?: string | null;
            updatedAt: any;
            createdAt: any;
            isActive?: boolean | null;
            userId?: any | null;
            appDefault?: boolean | null;
            careTeamMemberType?: {
                __typename?: 'CareTeamMemberType';
                name: string;
                profileDefId: any;
                createdAt: any;
                updatedAt: any;
                id: any;
            } | null;
            overridenCareTeamMemberContact?: {
                __typename?: 'CareTeamContactForCareTeamMember';
                careTeamMemberTypeId: any;
                name: string;
                phoneNumber: string;
                emailAddress: string;
                url: string;
                address: {
                    __typename?: 'Address';
                    street1?: string | null;
                    street2?: string | null;
                    city?: string | null;
                    state?: string | null;
                    code?: string | null;
                };
            } | null;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        } | null;
    } | null;
};

export type CareTeamMemberTypesForCareTeamMemberEditModalQueryVariables = Exact<{
    [key: string]: never;
}>;

export type CareTeamMemberTypesForCareTeamMemberEditModalQuery = {
    __typename?: 'Query';
    careTeamMemberTypesV2: {
        __typename?: 'CareTeamMemberTypePaginationQueryResponse';
        results: Array<{
            __typename?: 'CareTeamMemberType';
            id: any;
            name: string;
            isJob?: boolean | null;
        }>;
    };
};

export type CareTeamContactsForCareTeamMemberEditModalQueryVariables = Exact<{
    [key: string]: never;
}>;

export type CareTeamContactsForCareTeamMemberEditModalQuery = {
    __typename?: 'Query';
    careTeamContactsV2: {
        __typename?: 'CareTeamContactsResponse';
        results: Array<{
            __typename?: 'CareTeamContact';
            careTeamMemberTypeId: any;
            name: string;
            phoneNumber: string;
            emailAddress: string;
            url: string;
            id: any;
            address: {
                __typename?: 'Address';
                street1?: string | null;
                street2?: string | null;
                city?: string | null;
                state?: string | null;
                code?: string | null;
            };
        }>;
    };
};

export type CareTeamMembersForCareTeammemberModalQueryVariables = Exact<{ [key: string]: never }>;

export type CareTeamMembersForCareTeammemberModalQuery = {
    __typename?: 'Query';
    careTeamMembersV2: {
        __typename?: 'CareTeamMemberPaginationQueryResponse';
        results: Array<{
            __typename?: 'CareTeamMember';
            id: any;
            nameWithType: string;
            label: { __typename?: 'LocalizedString'; en: string };
        }>;
    };
};

export type UsersForCareTeamMemberModalQueryVariables = Exact<{ [key: string]: never }>;

export type UsersForCareTeamMemberModalQuery = {
    __typename?: 'Query';
    usersV2: {
        __typename?: 'UserPaginationQueryResponse';
        results: Array<{ __typename?: 'User'; id: any; name: string }>;
    };
};

export type PreFetchCareTeamMemberTypesForCareTeamBuilderQueryVariables = Exact<{
    [key: string]: never;
}>;

export type PreFetchCareTeamMemberTypesForCareTeamBuilderQuery = {
    __typename?: 'Query';
    careTeamMemberTypesV2: {
        __typename?: 'CareTeamMemberTypePaginationQueryResponse';
        results: Array<{
            __typename?: 'CareTeamMemberType';
            id: any;
            name: string;
            profileDefId: any;
            createdAt: any;
            updatedAt: any;
        }>;
    };
};

export type PreFetchAppsForCareTeamBuilderQueryVariables = Exact<{ [key: string]: never }>;

export type PreFetchAppsForCareTeamBuilderQuery = {
    __typename?: 'Query';
    amsApps: Array<{
        __typename?: 'AMSApp';
        id: string;
        bundleId: string;
        productConst?: AmsProduct | null;
    }>;
};

export type CareTeamByIdForCareTeamBuilderQueryVariables = Exact<{
    input: GetCareTeamInput;
}>;

export type CareTeamByIdForCareTeamBuilderQuery = {
    __typename?: 'Query';
    careTeam?: {
        __typename?: 'CareTeam';
        name: string;
        appBundleId: string;
        memberTypes: Array<{
            __typename?: 'CareTeamType';
            id: any;
            hiddenToPhone: boolean;
            readOnly: boolean;
            isRequired: boolean;
            activeMembersCount?: number | null;
            label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        }>;
    } | null;
};

export type CreateCareTeamForCareTeamBuilderMutationVariables = Exact<{
    createCareTeamInput: CareTeamInput;
}>;

export type CreateCareTeamForCareTeamBuilderMutation = {
    __typename?: 'Mutation';
    createCareTeam?: {
        __typename?: 'CreateCareTeamResponse';
        success: boolean;
        message: string;
        resourceCreated?: {
            __typename?: 'CareTeam';
            id: any;
            name: string;
            appBundleId: string;
            memberTypes: Array<{
                __typename?: 'CareTeamType';
                id: any;
                hiddenToPhone: boolean;
                readOnly: boolean;
                isRequired: boolean;
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            }>;
        } | null;
    } | null;
};

export type UpdateCareTeamForCareTeamBuilderMutationVariables = Exact<{
    updateCareTeamInput: UpdateCareTeamInput;
}>;

export type UpdateCareTeamForCareTeamBuilderMutation = {
    __typename?: 'Mutation';
    updateCareTeam?: {
        __typename?: 'UpdateCareTeamResponse';
        success: boolean;
        message: string;
        resourceUpdated?: {
            __typename?: 'CareTeam';
            id: any;
            name: string;
            appBundleId: string;
            memberTypes: Array<{
                __typename?: 'CareTeamType';
                id: any;
                hiddenToPhone: boolean;
                readOnly: boolean;
                isRequired: boolean;
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            }>;
        } | null;
    } | null;
};

export type CareTeamsForCareTeamsPageQueryVariables = Exact<{ [key: string]: never }>;

export type CareTeamsForCareTeamsPageQuery = {
    __typename?: 'Query';
    careTeamsV2: {
        __typename?: 'CareTeamPaginationQueryResponse';
        results: Array<{ __typename?: 'CareTeam'; id: any; name: string; appBundleId: string }>;
    };
};

export type FetchReferenceLinksForCareTeamsPageQueryVariables = Exact<{
    input: GetReferenceLinksForPageInput;
}>;

export type FetchReferenceLinksForCareTeamsPageQuery = {
    __typename?: 'Query';
    getReferenceLinksForPage?: Array<{
        __typename?: 'GetReferenceLinksForPageResponse';
        category?: string | null;
        data?: Array<{
            __typename?: 'LinkDataType';
            label?: string | null;
            url?: string | null;
        } | null> | null;
    } | null> | null;
};

export type CompoundQuestionsV2QueryVariables = Exact<{
    input?: InputMaybe<CompoundQuestionsListInput>;
}>;

export type CompoundQuestionsV2Query = {
    __typename?: 'Query';
    compoundQuestionsV2: {
        __typename?: 'CompoundQuestionsResponse';
        total: number;
        skip?: number | null;
        results: Array<{
            __typename?: 'CompoundQuestion';
            id: any;
            profileValueType: UserProfileValueType;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            questionProfileDefs: Array<{
                __typename?: 'CompoundQuestionProfileDef';
                questionProfileDefId: any;
                questionProfileDef?: {
                    __typename?: 'UserProfileDef';
                    id: any;
                    name: string;
                    label?: {
                        __typename?: 'LocalizedString';
                        en: string;
                        es?: string | null;
                    } | null;
                } | null;
                labelOverride?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                value?: { __typename?: 'UserProfileDefChoiceValue'; bool?: boolean | null } | null;
            }>;
        }>;
    };
};

export type FetchProfileDefsForCompoundQuestionsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchProfileDefsForCompoundQuestionsQuery = {
    __typename?: 'Query';
    userProfileDefs: Array<{
        __typename?: 'UserProfileDef';
        id: any;
        name: string;
        description?: string | null;
        category: string;
        valueType: UserProfileValueType;
        choiceValueType?: UserProfileValueType | null;
        surveyQuestionDisabled?: boolean | null;
        systemGenerated?: boolean | null;
        conditionTypeId?: any | null;
        episodeRequired?: boolean | null;
        reportingNotes?: string | null;
        haPermission?: HaPermission | null;
        inboundNotes?: string | null;
        isClientSpecific?: boolean | null;
        linkToJIRA?: string | null;
        purposeAppPersonalization?: boolean | null;
        purposeHumanServices?: boolean | null;
        clientSpecificDisplayName?: string | null;
        dataSourceNotes?: string | null;
        purposeDetails?: string | null;
        dwLocation?: string | null;
        dwObject?: string | null;
        dwMetrics?: string | null;
        dwInternalReports?: string | null;
        dwExternalReports?: string | null;
        claimsField?: string | null;
        icd10DiagnosisCodes?: string | null;
        procedureCodes?: string | null;
        label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        questionLabel?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        choices?: Array<{
            __typename?: 'UserProfileDefChoice';
            specifyValue: boolean;
            id: any;
            name?: string | null;
            answerLabel?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            value?: {
                __typename?: 'UserProfileDefChoiceValue';
                bool?: boolean | null;
                date?: any | null;
                num?: number | null;
                str?: string | null;
            } | null;
        } | null> | null;
    }>;
};

export type CreateCompoundQuestionMutationVariables = Exact<{
    createCompoundQuestionInput: CompoundQuestionInput;
}>;

export type CreateCompoundQuestionMutation = {
    __typename?: 'Mutation';
    createCompoundQuestion?: {
        __typename?: 'CreateCompoundQuestionResponse';
        success: boolean;
        message: string;
        resourceCreated?: {
            __typename?: 'CompoundQuestion';
            id: any;
            profileValueType: UserProfileValueType;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            questionProfileDefs: Array<{
                __typename?: 'CompoundQuestionProfileDef';
                questionProfileDefId: any;
                questionProfileDef?: {
                    __typename?: 'UserProfileDef';
                    id: any;
                    name: string;
                    label?: {
                        __typename?: 'LocalizedString';
                        en: string;
                        es?: string | null;
                    } | null;
                } | null;
                labelOverride?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                value?: { __typename?: 'UserProfileDefChoiceValue'; bool?: boolean | null } | null;
            }>;
        } | null;
    } | null;
};

export type UpdateCompoundQuestionMutationVariables = Exact<{
    updateCompoundQuestionInput: UpdateCompoundQuestionInput;
}>;

export type UpdateCompoundQuestionMutation = {
    __typename?: 'Mutation';
    updateCompoundQuestion?: {
        __typename?: 'UpdateCompoundQuestionResponse';
        success: boolean;
        message: string;
        resourceUpdated?: {
            __typename?: 'CompoundQuestion';
            id: any;
            profileValueType: UserProfileValueType;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            questionProfileDefs: Array<{
                __typename?: 'CompoundQuestionProfileDef';
                questionProfileDefId: any;
                questionProfileDef?: {
                    __typename?: 'UserProfileDef';
                    id: any;
                    name: string;
                    label?: {
                        __typename?: 'LocalizedString';
                        en: string;
                        es?: string | null;
                    } | null;
                } | null;
                labelOverride?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                value?: { __typename?: 'UserProfileDefChoiceValue'; bool?: boolean | null } | null;
            }>;
        } | null;
    } | null;
};

export type SurveyDefListForCompoundQuestionQueryVariables = Exact<{
    input: GetSurveyDefInput;
}>;

export type SurveyDefListForCompoundQuestionQuery = {
    __typename?: 'Query';
    surveyDef?: { __typename?: 'SurveyDef'; id: any; name?: string | null } | null;
};

export type GetSurveyDefsByCompoundQuestionIdQueryVariables = Exact<{
    compoundQuestionId: Scalars['String'];
}>;

export type GetSurveyDefsByCompoundQuestionIdQuery = {
    __typename?: 'Query';
    getSurveyDefsByCompoundQuestionId?: Array<{
        __typename?: 'SurveyDef';
        name?: string | null;
        id: any;
    } | null> | null;
};

export type DeleteCompoundQuestionMutationVariables = Exact<{
    input: DeleteCompoundQuestionInput;
}>;

export type DeleteCompoundQuestionMutation = {
    __typename?: 'Mutation';
    deleteCompoundQuestion?: {
        __typename?: 'DeleteCompoundQuestionResponse';
        status: number;
        message: string;
        success: boolean;
        id?: string | null;
    } | null;
};

export type CompoundQuestionQueryVariables = Exact<{
    input: GetCompoundQuestionInput;
}>;

export type CompoundQuestionQuery = {
    __typename?: 'Query';
    compoundQuestion?: {
        __typename?: 'CompoundQuestion';
        id: any;
        profileValueType: UserProfileValueType;
        label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        questionProfileDefs: Array<{
            __typename?: 'CompoundQuestionProfileDef';
            questionProfileDefId: any;
            questionProfileDef?: {
                __typename?: 'UserProfileDef';
                id: any;
                name: string;
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            } | null;
            labelOverride?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            value?: { __typename?: 'UserProfileDefChoiceValue'; bool?: boolean | null } | null;
        }>;
    } | null;
};

export type ConditionTypeByIdQueryVariables = Exact<{
    input: GetConditionTypeInput;
}>;

export type ConditionTypeByIdQuery = {
    __typename?: 'Query';
    conditionType?: {
        __typename?: 'ConditionType';
        id: any;
        name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    } | null;
};

export type ConditionTypePageListQueryVariables = Exact<{ [key: string]: never }>;

export type ConditionTypePageListQuery = {
    __typename?: 'Query';
    conditionTypes: Array<{
        __typename?: 'ConditionType';
        id: any;
        name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    }>;
};

export type CreateConditionTypeMutationVariables = Exact<{
    input: ConditionTypeInput;
}>;

export type CreateConditionTypeMutation = {
    __typename?: 'Mutation';
    createConditionType?: {
        __typename?: 'CreateConditionTypeResponse';
        status: number;
        message: string;
        success: boolean;
        resourceCreated?: {
            __typename?: 'ConditionType';
            id: any;
            name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        } | null;
    } | null;
};

export type UpdateConditionTypeMutationVariables = Exact<{
    updateConditionTypeInput: UpdateConditionTypeInput;
}>;

export type UpdateConditionTypeMutation = {
    __typename?: 'Mutation';
    updateConditionType?: {
        __typename?: 'UpdateConditionTypeResponse';
        status: number;
        message: string;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'ConditionType';
            id: any;
            name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        } | null;
    } | null;
};

export type DeleteConditionTypeMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteConditionTypeMutation = {
    __typename?: 'Mutation';
    deleteConditionType?: {
        __typename?: 'DeleteConditionTypeMutationResponse';
        success: boolean;
        message: string;
        status: number;
        id: string;
    } | null;
};

export type ConditionTypeWhereUsedQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type ConditionTypeWhereUsedQuery = {
    __typename?: 'Query';
    conditionTypeWhereUsed?: {
        __typename?: 'EntityWhereUsedResponse';
        dependencies?: Array<{
            __typename?: 'DependentEntity';
            entityType: WhereUsedEntity;
            blocksDelete?: boolean | null;
            total?: number | null;
            entries?: Array<{
                __typename?: 'DependentEntityEntry';
                id: string;
                name: string;
            } | null> | null;
        }> | null;
    } | null;
};

export type FetchConfigAppForConfigAppSummaryPageQueryVariables = Exact<{
    input: GetAmsAppByBundleId;
}>;

export type FetchConfigAppForConfigAppSummaryPageQuery = {
    __typename?: 'Query';
    amsAppByBundleId: {
        __typename?: 'AMSApp';
        id: string;
        name: string;
        fullName: string;
        logo?: string | null;
        bundleId: string;
        config: any;
        values: Array<{
            __typename?: 'ConfigValue';
            id: any;
            createdAt: any;
            updatedAt: any;
            name: string;
            valueDef: { __typename?: 'ConfigValueDef'; id: any; name: string };
            value: {
                __typename?: 'ConfigValueDictionary';
                str?: string | null;
                strArr?: Array<string | null> | null;
                bool?: boolean | null;
                boolArr?: Array<boolean | null> | null;
                num?: number | null;
                numArr?: Array<number | null> | null;
                localizedStr?: { __typename?: 'LocalizedString'; en: string } | null;
                localizedStrArr?: Array<{
                    __typename?: 'LocalizedString';
                    en: string;
                } | null> | null;
            };
            overrides?: Array<{
                __typename?: 'ExpressionOverride';
                value: {
                    __typename?: 'ConfigValueDictionary';
                    str?: string | null;
                    strArr?: Array<string | null> | null;
                    bool?: boolean | null;
                    boolArr?: Array<boolean | null> | null;
                    num?: number | null;
                    numArr?: Array<number | null> | null;
                    localizedStr?: { __typename?: 'LocalizedString'; en: string } | null;
                    localizedStrArr?: Array<{
                        __typename?: 'LocalizedString';
                        en: string;
                    } | null> | null;
                };
                expression?: {
                    __typename?: 'ConfigExpression';
                    id: any;
                    createdAt: any;
                    updatedAt: any;
                    name: string;
                    description?: string | null;
                } | null;
            } | null> | null;
        }>;
        defaultValues: Array<{
            __typename?: 'ConfigValueDef';
            id: any;
            createdAt: any;
            updatedAt: any;
            name: string;
            description?: string | null;
            valueType: ConfigValueType;
            category: string;
            validationPattern?: string | null;
            potentialValues: Array<{
                __typename?: 'ConfigValueDictionary';
                str?: string | null;
                strArr?: Array<string | null> | null;
                bool?: boolean | null;
                boolArr?: Array<boolean | null> | null;
                num?: number | null;
                numArr?: Array<number | null> | null;
                localizedStr?: {
                    __typename?: 'LocalizedString';
                    en: string;
                    es?: string | null;
                } | null;
                localizedStrArr?: Array<{
                    __typename?: 'LocalizedString';
                    en: string;
                    es?: string | null;
                } | null> | null;
            }>;
            defaultValue: {
                __typename?: 'ConfigValueDictionary';
                str?: string | null;
                strArr?: Array<string | null> | null;
                bool?: boolean | null;
                boolArr?: Array<boolean | null> | null;
                num?: number | null;
                numArr?: Array<number | null> | null;
                localizedStr?: {
                    __typename?: 'LocalizedString';
                    en: string;
                    es?: string | null;
                } | null;
                localizedStrArr?: Array<{
                    __typename?: 'LocalizedString';
                    en: string;
                    es?: string | null;
                } | null> | null;
            };
        }>;
    };
};

export type SurveyScreensForOnboardingPageQueryVariables = Exact<{ [key: string]: never }>;

export type SurveyScreensForOnboardingPageQuery = {
    __typename?: 'Query';
    surveyDefs: Array<{
        __typename?: 'SurveyDef';
        id: any;
        name?: string | null;
        label: { __typename?: 'LocalizedString'; en: string };
        organization: { __typename?: 'Organization'; id: any; brandingName: string };
    }>;
};

export type FetchConfigAppForOnboardingScreensPageQueryVariables = Exact<{
    input: GetAmsAppByBundleId;
}>;

export type FetchConfigAppForOnboardingScreensPageQuery = {
    __typename?: 'Query';
    amsAppByBundleId: {
        __typename?: 'AMSApp';
        values: Array<{
            __typename?: 'ConfigValue';
            id: any;
            createdAt: any;
            updatedAt: any;
            name: string;
            valueDef: { __typename?: 'ConfigValueDef'; id: any; name: string };
            value: {
                __typename?: 'ConfigValueDictionary';
                str?: string | null;
                strArr?: Array<string | null> | null;
                bool?: boolean | null;
                boolArr?: Array<boolean | null> | null;
                num?: number | null;
                numArr?: Array<number | null> | null;
                localizedStr?: { __typename?: 'LocalizedString'; en: string } | null;
                localizedStrArr?: Array<{
                    __typename?: 'LocalizedString';
                    en: string;
                } | null> | null;
            };
            overrides?: Array<{
                __typename?: 'ExpressionOverride';
                value: {
                    __typename?: 'ConfigValueDictionary';
                    str?: string | null;
                    strArr?: Array<string | null> | null;
                    bool?: boolean | null;
                    boolArr?: Array<boolean | null> | null;
                    num?: number | null;
                    numArr?: Array<number | null> | null;
                    localizedStr?: { __typename?: 'LocalizedString'; en: string } | null;
                    localizedStrArr?: Array<{
                        __typename?: 'LocalizedString';
                        en: string;
                    } | null> | null;
                };
                expression?: {
                    __typename?: 'ConfigExpression';
                    id: any;
                    createdAt: any;
                    updatedAt: any;
                    name: string;
                    description?: string | null;
                } | null;
            } | null> | null;
        }>;
    };
};

export type UpsertAppValueForOnboardingAppDefMutationVariables = Exact<{
    input: UpsertConfigValueByValueDefIdInput;
}>;

export type UpsertAppValueForOnboardingAppDefMutation = {
    __typename?: 'Mutation';
    upsertConfigValueByValueDefId?: {
        __typename?: 'UpsertConfigValueByValueDefIdResponse';
        success: boolean;
        message: string;
        status: number;
        configValue?: {
            __typename?: 'ConfigValue';
            id: any;
            createdAt: any;
            updatedAt: any;
            name: string;
            valueDef: { __typename?: 'ConfigValueDef'; id: any; name: string };
            value: {
                __typename?: 'ConfigValueDictionary';
                str?: string | null;
                strArr?: Array<string | null> | null;
                bool?: boolean | null;
                boolArr?: Array<boolean | null> | null;
                num?: number | null;
                numArr?: Array<number | null> | null;
                localizedStr?: { __typename?: 'LocalizedString'; en: string } | null;
                localizedStrArr?: Array<{
                    __typename?: 'LocalizedString';
                    en: string;
                } | null> | null;
            };
            overrides?: Array<{
                __typename?: 'ExpressionOverride';
                value: {
                    __typename?: 'ConfigValueDictionary';
                    str?: string | null;
                    strArr?: Array<string | null> | null;
                    bool?: boolean | null;
                    boolArr?: Array<boolean | null> | null;
                    num?: number | null;
                    numArr?: Array<number | null> | null;
                    localizedStr?: { __typename?: 'LocalizedString'; en: string } | null;
                    localizedStrArr?: Array<{
                        __typename?: 'LocalizedString';
                        en: string;
                    } | null> | null;
                };
                expression?: {
                    __typename?: 'ConfigExpression';
                    id: any;
                    createdAt: any;
                    updatedAt: any;
                    name: string;
                    description?: string | null;
                } | null;
            } | null> | null;
        } | null;
    } | null;
};

export type FetchExpressionForAppValuesModalQueryVariables = Exact<{ [key: string]: never }>;

export type FetchExpressionForAppValuesModalQuery = {
    __typename?: 'Query';
    configExpressions: Array<{
        __typename?: 'ConfigExpression';
        id: any;
        createdAt: any;
        updatedAt: any;
        name: string;
        description?: string | null;
        rules: any;
        profileDefIds?: Array<any | null> | null;
        appConfigIds?: Array<any | null> | null;
        useType: ExpressionUseType;
    }>;
};

export type UpsertAppValueForAppDefMutationVariables = Exact<{
    input: UpsertConfigValueByValueDefIdInput;
}>;

export type UpsertAppValueForAppDefMutation = {
    __typename?: 'Mutation';
    upsertConfigValueByValueDefId?: {
        __typename?: 'UpsertConfigValueByValueDefIdResponse';
        success: boolean;
        message: string;
        status: number;
        configValue?: {
            __typename?: 'ConfigValue';
            id: any;
            createdAt: any;
            updatedAt: any;
            name: string;
            valueDef: { __typename?: 'ConfigValueDef'; id: any; name: string };
            value: {
                __typename?: 'ConfigValueDictionary';
                str?: string | null;
                strArr?: Array<string | null> | null;
                bool?: boolean | null;
                boolArr?: Array<boolean | null> | null;
                num?: number | null;
                numArr?: Array<number | null> | null;
                localizedStr?: { __typename?: 'LocalizedString'; en: string } | null;
                localizedStrArr?: Array<{
                    __typename?: 'LocalizedString';
                    en: string;
                } | null> | null;
            };
            overrides?: Array<{
                __typename?: 'ExpressionOverride';
                value: {
                    __typename?: 'ConfigValueDictionary';
                    str?: string | null;
                    strArr?: Array<string | null> | null;
                    bool?: boolean | null;
                    boolArr?: Array<boolean | null> | null;
                    num?: number | null;
                    numArr?: Array<number | null> | null;
                    localizedStr?: { __typename?: 'LocalizedString'; en: string } | null;
                    localizedStrArr?: Array<{
                        __typename?: 'LocalizedString';
                        en: string;
                    } | null> | null;
                };
                expression?: {
                    __typename?: 'ConfigExpression';
                    id: any;
                    createdAt: any;
                    updatedAt: any;
                    name: string;
                    description?: string | null;
                } | null;
            } | null> | null;
        } | null;
    } | null;
};

export type FetchConfigAppsForConfigAppsPageQueryVariables = Exact<{ [key: string]: never }>;

export type FetchConfigAppsForConfigAppsPageQuery = {
    __typename?: 'Query';
    amsApps: Array<{
        __typename?: 'AMSApp';
        bundleId: string;
        id: string;
        name: string;
        fullName: string;
        logo?: string | null;
    }>;
};

export type FetchConfigExpressionListQueryVariables = Exact<{ [key: string]: never }>;

export type FetchConfigExpressionListQuery = {
    __typename?: 'Query';
    configExpressions: Array<{
        __typename?: 'ConfigExpression';
        id: any;
        createdAt: any;
        updatedAt: any;
        name: string;
        description?: string | null;
        rules: any;
        profileDefIds?: Array<any | null> | null;
        appConfigIds?: Array<any | null> | null;
        useType: ExpressionUseType;
    }>;
};

export type FetchConfigExpressionListV2QueryVariables = Exact<{
    input?: InputMaybe<ConfigExpressionListInput>;
}>;

export type FetchConfigExpressionListV2Query = {
    __typename?: 'Query';
    configExpressionsV2: {
        __typename?: 'ConfigExpressionResponse';
        skip?: number | null;
        limit?: number | null;
        total: number;
        hasMore?: boolean | null;
        hasPrevious?: boolean | null;
        results: Array<{
            __typename?: 'ConfigExpression';
            id: any;
            name: string;
            description?: string | null;
            useType: ExpressionUseType;
            profileDefIds?: Array<any | null> | null;
        }>;
    };
};

export type CreateConfigExpressionMutationVariables = Exact<{
    input: ConfigExpressionInput;
}>;

export type CreateConfigExpressionMutation = {
    __typename?: 'Mutation';
    createConfigExpression?: {
        __typename?: 'CreateConfigExpressionResponse';
        message: string;
        success: boolean;
        resourceCreated?: {
            __typename?: 'ConfigExpression';
            id: any;
            createdAt: any;
            updatedAt: any;
            name: string;
            description?: string | null;
            rules: any;
            profileDefIds?: Array<any | null> | null;
            appConfigIds?: Array<any | null> | null;
            useType: ExpressionUseType;
        } | null;
    } | null;
};

export type FetchConfigExpressionQueryVariables = Exact<{
    input: GetConfigExpressionInput;
}>;

export type FetchConfigExpressionQuery = {
    __typename?: 'Query';
    configExpression?: {
        __typename?: 'ConfigExpression';
        id: any;
        createdAt: any;
        updatedAt: any;
        name: string;
        description?: string | null;
        rules: any;
        profileDefIds?: Array<any | null> | null;
        appConfigIds?: Array<any | null> | null;
        useType: ExpressionUseType;
    } | null;
};

export type FetchConfigValueDefsForExpressionPageQueryVariables = Exact<{ [key: string]: never }>;

export type FetchConfigValueDefsForExpressionPageQuery = {
    __typename?: 'Query';
    configValueDefs: Array<{
        __typename?: 'ConfigValueDef';
        id: any;
        createdAt: any;
        updatedAt: any;
        name: string;
        description?: string | null;
        valueType: ConfigValueType;
        category: string;
        validationPattern?: string | null;
        potentialValues: Array<{
            __typename?: 'ConfigValueDictionary';
            str?: string | null;
            strArr?: Array<string | null> | null;
            bool?: boolean | null;
            boolArr?: Array<boolean | null> | null;
            num?: number | null;
            numArr?: Array<number | null> | null;
            localizedStr?: {
                __typename?: 'LocalizedString';
                en: string;
                es?: string | null;
            } | null;
            localizedStrArr?: Array<{
                __typename?: 'LocalizedString';
                en: string;
                es?: string | null;
            } | null> | null;
        }>;
        defaultValue: {
            __typename?: 'ConfigValueDictionary';
            str?: string | null;
            strArr?: Array<string | null> | null;
            bool?: boolean | null;
            boolArr?: Array<boolean | null> | null;
            num?: number | null;
            numArr?: Array<number | null> | null;
            localizedStr?: {
                __typename?: 'LocalizedString';
                en: string;
                es?: string | null;
            } | null;
            localizedStrArr?: Array<{
                __typename?: 'LocalizedString';
                en: string;
                es?: string | null;
            } | null> | null;
        };
    }>;
};

export type FetchProfileDefsForExpressionPageQueryVariables = Exact<{ [key: string]: never }>;

export type FetchProfileDefsForExpressionPageQuery = {
    __typename?: 'Query';
    userProfileDefs: Array<{
        __typename?: 'UserProfileDef';
        id: any;
        name: string;
        description?: string | null;
        category: string;
        valueType: UserProfileValueType;
        choiceValueType?: UserProfileValueType | null;
        surveyQuestionDisabled?: boolean | null;
        systemGenerated?: boolean | null;
        conditionTypeId?: any | null;
        episodeRequired?: boolean | null;
        reportingNotes?: string | null;
        haPermission?: HaPermission | null;
        inboundNotes?: string | null;
        isClientSpecific?: boolean | null;
        linkToJIRA?: string | null;
        purposeAppPersonalization?: boolean | null;
        purposeHumanServices?: boolean | null;
        clientSpecificDisplayName?: string | null;
        dataSourceNotes?: string | null;
        purposeDetails?: string | null;
        dwLocation?: string | null;
        dwObject?: string | null;
        dwMetrics?: string | null;
        dwInternalReports?: string | null;
        dwExternalReports?: string | null;
        claimsField?: string | null;
        icd10DiagnosisCodes?: string | null;
        procedureCodes?: string | null;
        label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        questionLabel?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        choices?: Array<{
            __typename?: 'UserProfileDefChoice';
            specifyValue: boolean;
            id: any;
            name?: string | null;
            answerLabel?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            value?: {
                __typename?: 'UserProfileDefChoiceValue';
                bool?: boolean | null;
                date?: any | null;
                num?: number | null;
                str?: string | null;
            } | null;
        } | null> | null;
    }>;
};

export type UpdateConfigValueExpressionMutationVariables = Exact<{
    input: UpdateConfigExpressionInput;
}>;

export type UpdateConfigValueExpressionMutation = {
    __typename?: 'Mutation';
    updateConfigExpression?: {
        __typename?: 'UpdateConfigExpressionResponse';
        message: string;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'ConfigExpression';
            id: any;
            createdAt: any;
            updatedAt: any;
            name: string;
            description?: string | null;
            rules: any;
            profileDefIds?: Array<any | null> | null;
            appConfigIds?: Array<any | null> | null;
            useType: ExpressionUseType;
        } | null;
    } | null;
};

export type ExpressionWhereUsedQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type ExpressionWhereUsedQuery = {
    __typename?: 'Query';
    expressionWhereUsed?: {
        __typename?: 'EntityWhereUsedResponse';
        dependencies?: Array<{
            __typename?: 'DependentEntity';
            entityType: WhereUsedEntity;
            blocksDelete?: boolean | null;
            total?: number | null;
            entries?: Array<{
                __typename?: 'DependentEntityEntry';
                id: string;
                name: string;
            } | null> | null;
        }> | null;
    } | null;
};

export type DeleteExpressionMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteExpressionMutation = {
    __typename?: 'Mutation';
    deleteExpression?: {
        __typename?: 'DeleteExpressionMutationResponse';
        status: number;
        message: string;
        success: boolean;
        id: string;
    } | null;
};

export type FetchConfigValueDefsForConfigValuesPageQueryVariables = Exact<{ [key: string]: never }>;

export type FetchConfigValueDefsForConfigValuesPageQuery = {
    __typename?: 'Query';
    configValueDefs: Array<{
        __typename?: 'ConfigValueDef';
        id: any;
        createdAt: any;
        updatedAt: any;
        name: string;
        description?: string | null;
        valueType: ConfigValueType;
        category: string;
        validationPattern?: string | null;
        potentialValues: Array<{
            __typename?: 'ConfigValueDictionary';
            str?: string | null;
            strArr?: Array<string | null> | null;
            bool?: boolean | null;
            boolArr?: Array<boolean | null> | null;
            num?: number | null;
            numArr?: Array<number | null> | null;
            localizedStr?: {
                __typename?: 'LocalizedString';
                en: string;
                es?: string | null;
            } | null;
            localizedStrArr?: Array<{
                __typename?: 'LocalizedString';
                en: string;
                es?: string | null;
            } | null> | null;
        }>;
        defaultValue: {
            __typename?: 'ConfigValueDictionary';
            str?: string | null;
            strArr?: Array<string | null> | null;
            bool?: boolean | null;
            boolArr?: Array<boolean | null> | null;
            num?: number | null;
            numArr?: Array<number | null> | null;
            localizedStr?: {
                __typename?: 'LocalizedString';
                en: string;
                es?: string | null;
            } | null;
            localizedStrArr?: Array<{
                __typename?: 'LocalizedString';
                en: string;
                es?: string | null;
            } | null> | null;
        };
    }>;
};

export type FetchConfigValueDefaultsByIdForValueDefsModalQueryVariables = Exact<{
    input: GetConfigValueDefInput;
}>;

export type FetchConfigValueDefaultsByIdForValueDefsModalQuery = {
    __typename?: 'Query';
    configValueDef?: {
        __typename?: 'ConfigValueDef';
        id: any;
        createdAt: any;
        updatedAt: any;
        name: string;
        description?: string | null;
        valueType: ConfigValueType;
        category: string;
        validationPattern?: string | null;
        potentialValues: Array<{
            __typename?: 'ConfigValueDictionary';
            str?: string | null;
            strArr?: Array<string | null> | null;
            bool?: boolean | null;
            boolArr?: Array<boolean | null> | null;
            num?: number | null;
            numArr?: Array<number | null> | null;
            localizedStr?: {
                __typename?: 'LocalizedString';
                en: string;
                es?: string | null;
            } | null;
            localizedStrArr?: Array<{
                __typename?: 'LocalizedString';
                en: string;
                es?: string | null;
            } | null> | null;
        }>;
        defaultValue: {
            __typename?: 'ConfigValueDictionary';
            str?: string | null;
            strArr?: Array<string | null> | null;
            bool?: boolean | null;
            boolArr?: Array<boolean | null> | null;
            num?: number | null;
            numArr?: Array<number | null> | null;
            localizedStr?: {
                __typename?: 'LocalizedString';
                en: string;
                es?: string | null;
            } | null;
            localizedStrArr?: Array<{
                __typename?: 'LocalizedString';
                en: string;
                es?: string | null;
            } | null> | null;
        };
    } | null;
};

export type CreateConfigValueDefaultsFromValueDefPageMutationVariables = Exact<{
    input: ConfigValueDefInput;
}>;

export type CreateConfigValueDefaultsFromValueDefPageMutation = {
    __typename?: 'Mutation';
    createConfigValueDef?: {
        __typename?: 'CreateConfigValueDefResponse';
        success: boolean;
        message: string;
        status: number;
        resourceCreated?: {
            __typename?: 'ConfigValueDef';
            id: any;
            createdAt: any;
            updatedAt: any;
            name: string;
            description?: string | null;
            valueType: ConfigValueType;
            category: string;
            validationPattern?: string | null;
            potentialValues: Array<{
                __typename?: 'ConfigValueDictionary';
                str?: string | null;
                strArr?: Array<string | null> | null;
                bool?: boolean | null;
                boolArr?: Array<boolean | null> | null;
                num?: number | null;
                numArr?: Array<number | null> | null;
                localizedStr?: {
                    __typename?: 'LocalizedString';
                    en: string;
                    es?: string | null;
                } | null;
                localizedStrArr?: Array<{
                    __typename?: 'LocalizedString';
                    en: string;
                    es?: string | null;
                } | null> | null;
            }>;
            defaultValue: {
                __typename?: 'ConfigValueDictionary';
                str?: string | null;
                strArr?: Array<string | null> | null;
                bool?: boolean | null;
                boolArr?: Array<boolean | null> | null;
                num?: number | null;
                numArr?: Array<number | null> | null;
                localizedStr?: {
                    __typename?: 'LocalizedString';
                    en: string;
                    es?: string | null;
                } | null;
                localizedStrArr?: Array<{
                    __typename?: 'LocalizedString';
                    en: string;
                    es?: string | null;
                } | null> | null;
            };
        } | null;
    } | null;
};

export type UpdateConfigValueDefaultsFromValueDefPageMutationVariables = Exact<{
    input: UpdateConfigValueDefInput;
}>;

export type UpdateConfigValueDefaultsFromValueDefPageMutation = {
    __typename?: 'Mutation';
    updateConfigValueDef?: {
        __typename?: 'UpdateConfigValueDefResponse';
        success: boolean;
        message: string;
        status: number;
        resourceUpdated?: {
            __typename?: 'ConfigValueDef';
            id: any;
            createdAt: any;
            updatedAt: any;
            name: string;
            description?: string | null;
            valueType: ConfigValueType;
            category: string;
            validationPattern?: string | null;
            potentialValues: Array<{
                __typename?: 'ConfigValueDictionary';
                str?: string | null;
                strArr?: Array<string | null> | null;
                bool?: boolean | null;
                boolArr?: Array<boolean | null> | null;
                num?: number | null;
                numArr?: Array<number | null> | null;
                localizedStr?: {
                    __typename?: 'LocalizedString';
                    en: string;
                    es?: string | null;
                } | null;
                localizedStrArr?: Array<{
                    __typename?: 'LocalizedString';
                    en: string;
                    es?: string | null;
                } | null> | null;
            }>;
            defaultValue: {
                __typename?: 'ConfigValueDictionary';
                str?: string | null;
                strArr?: Array<string | null> | null;
                bool?: boolean | null;
                boolArr?: Array<boolean | null> | null;
                num?: number | null;
                numArr?: Array<number | null> | null;
                localizedStr?: {
                    __typename?: 'LocalizedString';
                    en: string;
                    es?: string | null;
                } | null;
                localizedStrArr?: Array<{
                    __typename?: 'LocalizedString';
                    en: string;
                    es?: string | null;
                } | null> | null;
            };
        } | null;
    } | null;
};

export type FetchConfigValuesForConfigValuesPageQueryVariables = Exact<{ [key: string]: never }>;

export type FetchConfigValuesForConfigValuesPageQuery = {
    __typename?: 'Query';
    configValues: Array<{
        __typename?: 'ConfigValue';
        id: any;
        createdAt: any;
        updatedAt: any;
        name: string;
        valueDef: { __typename?: 'ConfigValueDef'; id: any; name: string };
        value: {
            __typename?: 'ConfigValueDictionary';
            str?: string | null;
            strArr?: Array<string | null> | null;
            bool?: boolean | null;
            boolArr?: Array<boolean | null> | null;
            num?: number | null;
            numArr?: Array<number | null> | null;
            localizedStr?: { __typename?: 'LocalizedString'; en: string } | null;
            localizedStrArr?: Array<{ __typename?: 'LocalizedString'; en: string } | null> | null;
        };
        overrides?: Array<{
            __typename?: 'ExpressionOverride';
            value: {
                __typename?: 'ConfigValueDictionary';
                str?: string | null;
                strArr?: Array<string | null> | null;
                bool?: boolean | null;
                boolArr?: Array<boolean | null> | null;
                num?: number | null;
                numArr?: Array<number | null> | null;
                localizedStr?: { __typename?: 'LocalizedString'; en: string } | null;
                localizedStrArr?: Array<{
                    __typename?: 'LocalizedString';
                    en: string;
                } | null> | null;
            };
            expression?: {
                __typename?: 'ConfigExpression';
                id: any;
                createdAt: any;
                updatedAt: any;
                name: string;
                description?: string | null;
            } | null;
        } | null> | null;
    }>;
};

export type ContentDirectoryListQueryVariables = Exact<{
    input: ContentDirectoryListInput;
}>;

export type ContentDirectoryListQuery = {
    __typename?: 'Query';
    contentDirectorysV2: {
        __typename?: 'ContentDirectoryListResponse';
        total: number;
        results: Array<{
            __typename?: 'ContentDirectory';
            id: any;
            name: string;
            description?: string | null;
            keywords?: string | null;
            icon?: ContentIcon | null;
            imageContentId?: any | null;
            displayStyle?: DirectoryDisplayStyle | null;
            reportingCategorys?: Array<any> | null;
            imageContent?: { __typename?: 'SimpleContent'; name: string } | null;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            appDescription?: {
                __typename?: 'LocalizedString';
                en: string;
                es?: string | null;
            } | null;
            taggedImages?: Array<{
                __typename?: 'TaggedImageShared';
                imageContentId: any;
                imageName: string;
                tagId: any;
                tagName: string;
            }> | null;
            items?: Array<{
                __typename?: 'DirectoryItem';
                id: any;
                type: DirectoryItemType;
                simpleContentId?: any | null;
                articleId?: any | null;
                surveyId?: any | null;
                subDirectoryId?: any | null;
                tagList?: Array<any> | null;
                excludeApps?: Array<string> | null;
                includeApps?: Array<string> | null;
                article?: { __typename?: 'Article'; name: string } | null;
                survey?: { __typename?: 'SurveyDef'; name?: string | null } | null;
                simpleContent?: { __typename?: 'SimpleContent'; name: string } | null;
                subdirectory?: { __typename?: 'ContentDirectory'; name: string } | null;
            }> | null;
        }>;
    };
};

export type ContentDirectoryByIdQueryVariables = Exact<{
    input: GetContentDirectoryInput;
}>;

export type ContentDirectoryByIdQuery = {
    __typename?: 'Query';
    contentDirectory?: {
        __typename?: 'ContentDirectory';
        id: any;
        name: string;
        description?: string | null;
        keywords?: string | null;
        icon?: ContentIcon | null;
        imageContentId?: any | null;
        displayStyle?: DirectoryDisplayStyle | null;
        reportingCategorys?: Array<any> | null;
        imageContent?: { __typename?: 'SimpleContent'; name: string } | null;
        label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        appDescription?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        taggedImages?: Array<{
            __typename?: 'TaggedImageShared';
            imageContentId: any;
            imageName: string;
            tagId: any;
            tagName: string;
        }> | null;
        items?: Array<{
            __typename?: 'DirectoryItem';
            id: any;
            type: DirectoryItemType;
            simpleContentId?: any | null;
            articleId?: any | null;
            surveyId?: any | null;
            subDirectoryId?: any | null;
            tagList?: Array<any> | null;
            excludeApps?: Array<string> | null;
            includeApps?: Array<string> | null;
            article?: { __typename?: 'Article'; name: string } | null;
            survey?: { __typename?: 'SurveyDef'; name?: string | null } | null;
            simpleContent?: { __typename?: 'SimpleContent'; name: string } | null;
            subdirectory?: { __typename?: 'ContentDirectory'; name: string } | null;
        }> | null;
    } | null;
};

export type CreateContentDirectoryMutationVariables = Exact<{
    input: ContentDirectoryInput;
}>;

export type CreateContentDirectoryMutation = {
    __typename?: 'Mutation';
    createContentDirectory?: {
        __typename?: 'CreateContentDirectoryResponse';
        status: number;
        message: string;
        success: boolean;
        resourceCreated?: {
            __typename?: 'ContentDirectory';
            id: any;
            name: string;
            description?: string | null;
            keywords?: string | null;
            icon?: ContentIcon | null;
            imageContentId?: any | null;
            displayStyle?: DirectoryDisplayStyle | null;
            reportingCategorys?: Array<any> | null;
            imageContent?: { __typename?: 'SimpleContent'; name: string } | null;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            appDescription?: {
                __typename?: 'LocalizedString';
                en: string;
                es?: string | null;
            } | null;
            taggedImages?: Array<{
                __typename?: 'TaggedImageShared';
                imageContentId: any;
                imageName: string;
                tagId: any;
                tagName: string;
            }> | null;
            items?: Array<{
                __typename?: 'DirectoryItem';
                id: any;
                type: DirectoryItemType;
                simpleContentId?: any | null;
                articleId?: any | null;
                surveyId?: any | null;
                subDirectoryId?: any | null;
                tagList?: Array<any> | null;
                excludeApps?: Array<string> | null;
                includeApps?: Array<string> | null;
                article?: { __typename?: 'Article'; name: string } | null;
                survey?: { __typename?: 'SurveyDef'; name?: string | null } | null;
                simpleContent?: { __typename?: 'SimpleContent'; name: string } | null;
                subdirectory?: { __typename?: 'ContentDirectory'; name: string } | null;
            }> | null;
        } | null;
    } | null;
};

export type UpdateContentDirectoryMutationVariables = Exact<{
    updateContentDirectoryInput: UpdateContentDirectoryInput;
}>;

export type UpdateContentDirectoryMutation = {
    __typename?: 'Mutation';
    updateContentDirectory?: {
        __typename?: 'UpdateContentDirectoryResponse';
        status: number;
        message: string;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'ContentDirectory';
            id: any;
            name: string;
            description?: string | null;
            keywords?: string | null;
            icon?: ContentIcon | null;
            imageContentId?: any | null;
            displayStyle?: DirectoryDisplayStyle | null;
            reportingCategorys?: Array<any> | null;
            imageContent?: { __typename?: 'SimpleContent'; name: string } | null;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            appDescription?: {
                __typename?: 'LocalizedString';
                en: string;
                es?: string | null;
            } | null;
            taggedImages?: Array<{
                __typename?: 'TaggedImageShared';
                imageContentId: any;
                imageName: string;
                tagId: any;
                tagName: string;
            }> | null;
            items?: Array<{
                __typename?: 'DirectoryItem';
                id: any;
                type: DirectoryItemType;
                simpleContentId?: any | null;
                articleId?: any | null;
                surveyId?: any | null;
                subDirectoryId?: any | null;
                tagList?: Array<any> | null;
                excludeApps?: Array<string> | null;
                includeApps?: Array<string> | null;
                article?: { __typename?: 'Article'; name: string } | null;
                survey?: { __typename?: 'SurveyDef'; name?: string | null } | null;
                simpleContent?: { __typename?: 'SimpleContent'; name: string } | null;
                subdirectory?: { __typename?: 'ContentDirectory'; name: string } | null;
            }> | null;
        } | null;
    } | null;
};

export type DeleteContentDirectoryMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteContentDirectoryMutation = {
    __typename?: 'Mutation';
    deleteContentDirectory?: {
        __typename?: 'DeleteCDResponse';
        success: boolean;
        message: string;
        status: number;
        id?: string | null;
    } | null;
};

export type ContentDirectoryWhereUsedQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type ContentDirectoryWhereUsedQuery = {
    __typename?: 'Query';
    contentDirectoryWhereUsed?: {
        __typename?: 'EntityWhereUsedResponse';
        dependencies?: Array<{
            __typename?: 'DependentEntity';
            entityType: WhereUsedEntity;
            blocksDelete?: boolean | null;
            total?: number | null;
            entries?: Array<{
                __typename?: 'DependentEntityEntry';
                id: string;
                name: string;
            } | null> | null;
        }> | null;
    } | null;
};

export type EligibilityMatchStatsQueryVariables = Exact<{ [key: string]: never }>;

export type EligibilityMatchStatsQuery = {
    __typename?: 'Query';
    eligibilityMatchStats?: {
        __typename?: 'EligibilityMatchStats';
        inQueue?: number | null;
        matches?: number | null;
        noMatches?: number | null;
    } | null;
};

export type EligibilityMatchsV2QueryVariables = Exact<{
    input?: InputMaybe<EligibilityMatchQueryInput>;
}>;

export type EligibilityMatchsV2Query = {
    __typename?: 'Query';
    eligibilityMatchsV2: {
        __typename?: 'EligibilityMatchPaginationQueryResponse';
        hasMore?: boolean | null;
        hasPrevious?: boolean | null;
        limit?: number | null;
        orderBy?: string | null;
        skip?: number | null;
        total: number;
        results: Array<{
            __typename?: 'EligibilityMatch';
            appId?: string | null;
            patientId?: any | null;
            patientFirstName?: string | null;
            patientLastName?: string | null;
            patientDOB?: string | null;
            potentialMatchesHash?: string | null;
            toBeReviewed?: boolean | null;
            hasMatch?: boolean | null;
            firstNameMatch?: string | null;
            lastNameMatch?: string | null;
            matchDateTime?: any | null;
            matchUserId?: any | null;
            updatedAt: any;
            isTestData?: boolean | null;
            id: any;
            potentialMatches?: Array<{
                __typename?: 'PotentialMatch';
                firstName?: string | null;
                lastName?: string | null;
            } | null> | null;
        }>;
    };
};

export type UpdateEligibilityMatchMutationVariables = Exact<{
    input: UpdateEligibilityMatchInput;
}>;

export type UpdateEligibilityMatchMutation = {
    __typename?: 'Mutation';
    updateEligibilityMatch?: {
        __typename?: 'UpdateEligibilityMatchResponse';
        message: string;
        status: number;
        success: boolean;
        resourceUpdated?: { __typename?: 'EligibilityMatch'; id: any } | null;
    } | null;
};

export type EligibilityMatchQueryVariables = Exact<{
    input: GetEligibilityMatchInput;
}>;

export type EligibilityMatchQuery = {
    __typename?: 'Query';
    eligibilityMatch?: {
        __typename?: 'EligibilityMatch';
        appId?: string | null;
        patientId?: any | null;
        patientFirstName?: string | null;
        patientLastName?: string | null;
        patientDOB?: string | null;
        potentialMatchesHash?: string | null;
        toBeReviewed?: boolean | null;
        hasMatch?: boolean | null;
        firstNameMatch?: string | null;
        lastNameMatch?: string | null;
        matchDateTime?: any | null;
        matchUserId?: any | null;
        createdAt: any;
        updatedAt: any;
        isTestData?: boolean | null;
        id: any;
        potentialMatches?: Array<{
            __typename?: 'PotentialMatch';
            firstName?: string | null;
            lastName?: string | null;
        } | null> | null;
    } | null;
};

export type EligibilityMatchQueueMetaDataQueryVariables = Exact<{
    input?: InputMaybe<EligibilityMatchMetaDataInput>;
}>;

export type EligibilityMatchQueueMetaDataQuery = {
    __typename?: 'Query';
    eligibilityMatchQueueMetaData?: {
        __typename?: 'EligibilityMatchMetaData';
        nextMatchId?: any | null;
        remainingCount?: number | null;
    } | null;
};

export type EligibilityMatchsV2IdsQueryVariables = Exact<{
    input?: InputMaybe<EligibilityMatchQueryInput>;
}>;

export type EligibilityMatchsV2IdsQuery = {
    __typename?: 'Query';
    eligibilityMatchsV2: {
        __typename?: 'EligibilityMatchPaginationQueryResponse';
        hasMore?: boolean | null;
        hasPrevious?: boolean | null;
        limit?: number | null;
        orderBy?: string | null;
        skip?: number | null;
        total: number;
        results: Array<{ __typename?: 'EligibilityMatch'; id: any }>;
    };
};

export type AccessCodesQueryVariables = Exact<{
    input: EligibilityAccessCodeInput;
}>;

export type AccessCodesQuery = {
    __typename?: 'Query';
    accessCodes?: {
        __typename?: 'EligibilityAccessCodesResponse';
        limit?: number | null;
        total: number;
        skip?: number | null;
        results?: Array<{
            __typename?: 'AccessCode';
            _id: string;
            birthDate: string;
            firstName: string;
            lastName: string;
            unitName: string;
        } | null> | null;
    } | null;
};

export type UnitNamesQueryVariables = Exact<{ [key: string]: never }>;

export type UnitNamesQuery = {
    __typename?: 'Query';
    unitNames?: { __typename?: 'EligibilityUnitNamesResponse'; results: Array<string> } | null;
};

export type EligibilityCreateAccessCodeMutationVariables = Exact<{
    input: AccessCodeInput;
}>;

export type EligibilityCreateAccessCodeMutation = {
    __typename?: 'Mutation';
    createAccessCode?: {
        __typename?: 'EligibilityCreateAccessCodeResponse';
        success: boolean;
        message: string;
        status: number;
        id?: string | null;
    } | null;
};

export type EligibilityUpdateAccessCodeMutationVariables = Exact<{
    input: EligibilityUpdateAccessCodeInput;
}>;

export type EligibilityUpdateAccessCodeMutation = {
    __typename?: 'Mutation';
    updateAccessCode?: {
        __typename?: 'EligibilityUpdateAccessCodeResponse';
        success: boolean;
        message: string;
        status: number;
        id?: string | null;
    } | null;
};

export type HighlightFragmentFragment = {
    __typename?: 'Highlight';
    id: any;
    name: string;
    type: HighlightType;
    clockType: ClockType;
    startDate?: any | null;
    endDate?: any | null;
    timelineId?: any | null;
    offsetDays?: number | null;
    offsetMonths?: number | null;
    offsetYears?: number | null;
    duration: number;
    durationTimeUnit: TimeUnit;
    priority: number;
    includeApps?: Array<string> | null;
    excludeApps?: Array<string> | null;
    tags?: Array<string> | null;
    icon: HighlightIcon;
    imageContentId?: any | null;
    surveyId?: any | null;
    simpleContentId?: any | null;
    closeable: boolean;
    highlighted: boolean;
    offsetSequence?: number | null;
    shouldSendNotification: boolean;
    clockTypeName?: string | null;
    highlightTypeName?: string | null;
    displayAsModal?: boolean | null;
    titleMarkup?: {
        __typename?: 'LocalizedStringLoose';
        en?: string | null;
        es?: string | null;
    } | null;
    headerMarkup?: {
        __typename?: 'LocalizedStringLoose';
        en?: string | null;
        es?: string | null;
    } | null;
    bodyMarkup?: {
        __typename?: 'LocalizedStringLoose';
        en?: string | null;
        es?: string | null;
    } | null;
    linkText?: {
        __typename?: 'LocalizedStringLoose';
        en?: string | null;
        es?: string | null;
    } | null;
    notificationText?: {
        __typename?: 'LocalizedStringLoose';
        en?: string | null;
        es?: string | null;
    } | null;
    timeline?: {
        __typename?: 'WhatsNewTimeline';
        id: any;
        label: { __typename?: 'LocalizedString'; en: string };
    } | null;
    displayAsModalButtonText?: {
        __typename?: 'LocalizedStringLoose';
        en?: string | null;
        es?: string | null;
    } | null;
};

export type HighlightsForListPageQueryVariables = Exact<{
    input?: InputMaybe<HighlightListInput>;
}>;

export type HighlightsForListPageQuery = {
    __typename?: 'Query';
    highlightsV2: {
        __typename?: 'HighlightListResponse';
        total: number;
        results: Array<{
            __typename?: 'Highlight';
            id: any;
            name: string;
            type: HighlightType;
            clockType: ClockType;
            startDate?: any | null;
            endDate?: any | null;
            timelineId?: any | null;
            offsetDays?: number | null;
            offsetMonths?: number | null;
            offsetYears?: number | null;
            duration: number;
            durationTimeUnit: TimeUnit;
            priority: number;
            includeApps?: Array<string> | null;
            excludeApps?: Array<string> | null;
            tags?: Array<string> | null;
            icon: HighlightIcon;
            imageContentId?: any | null;
            surveyId?: any | null;
            simpleContentId?: any | null;
            closeable: boolean;
            highlighted: boolean;
            offsetSequence?: number | null;
            shouldSendNotification: boolean;
            clockTypeName?: string | null;
            highlightTypeName?: string | null;
            displayAsModal?: boolean | null;
            titleMarkup?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            headerMarkup?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            bodyMarkup?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            linkText?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            notificationText?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            timeline?: {
                __typename?: 'WhatsNewTimeline';
                id: any;
                label: { __typename?: 'LocalizedString'; en: string };
            } | null;
            displayAsModalButtonText?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
        }>;
    };
};

export type GetHighlightQueryVariables = Exact<{
    input: GetHighlightInput;
}>;

export type GetHighlightQuery = {
    __typename?: 'Query';
    highlight?: {
        __typename?: 'Highlight';
        id: any;
        name: string;
        type: HighlightType;
        clockType: ClockType;
        startDate?: any | null;
        endDate?: any | null;
        timelineId?: any | null;
        offsetDays?: number | null;
        offsetMonths?: number | null;
        offsetYears?: number | null;
        duration: number;
        durationTimeUnit: TimeUnit;
        priority: number;
        includeApps?: Array<string> | null;
        excludeApps?: Array<string> | null;
        tags?: Array<string> | null;
        icon: HighlightIcon;
        imageContentId?: any | null;
        surveyId?: any | null;
        simpleContentId?: any | null;
        closeable: boolean;
        highlighted: boolean;
        offsetSequence?: number | null;
        shouldSendNotification: boolean;
        clockTypeName?: string | null;
        highlightTypeName?: string | null;
        displayAsModal?: boolean | null;
        titleMarkup?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        headerMarkup?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        bodyMarkup?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        linkText?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        notificationText?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        timeline?: {
            __typename?: 'WhatsNewTimeline';
            id: any;
            label: { __typename?: 'LocalizedString'; en: string };
        } | null;
        displayAsModalButtonText?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
    } | null;
};

export type GetAppointmentTemplateHighlightQueryVariables = Exact<{ [key: string]: never }>;

export type GetAppointmentTemplateHighlightQuery = {
    __typename?: 'Query';
    appointmentTemplateHighlight?: {
        __typename?: 'Highlight';
        id: any;
        name: string;
        type: HighlightType;
        clockType: ClockType;
        startDate?: any | null;
        endDate?: any | null;
        timelineId?: any | null;
        offsetDays?: number | null;
        offsetMonths?: number | null;
        offsetYears?: number | null;
        duration: number;
        durationTimeUnit: TimeUnit;
        priority: number;
        includeApps?: Array<string> | null;
        excludeApps?: Array<string> | null;
        tags?: Array<string> | null;
        icon: HighlightIcon;
        imageContentId?: any | null;
        surveyId?: any | null;
        simpleContentId?: any | null;
        closeable: boolean;
        highlighted: boolean;
        offsetSequence?: number | null;
        shouldSendNotification: boolean;
        clockTypeName?: string | null;
        highlightTypeName?: string | null;
        displayAsModal?: boolean | null;
        titleMarkup?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        headerMarkup?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        bodyMarkup?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        linkText?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        notificationText?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        timeline?: {
            __typename?: 'WhatsNewTimeline';
            id: any;
            label: { __typename?: 'LocalizedString'; en: string };
        } | null;
        displayAsModalButtonText?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
    } | null;
};

export type SaveHighlightMutationVariables = Exact<{
    input: SaveHighlightInput;
}>;

export type SaveHighlightMutation = {
    __typename?: 'Mutation';
    saveHighlight?: {
        __typename?: 'SaveHighlightResponse';
        status: number;
        message: string;
        success: boolean;
        resource?: {
            __typename?: 'Highlight';
            id: any;
            name: string;
            type: HighlightType;
            clockType: ClockType;
            startDate?: any | null;
            endDate?: any | null;
            timelineId?: any | null;
            offsetDays?: number | null;
            offsetMonths?: number | null;
            offsetYears?: number | null;
            duration: number;
            durationTimeUnit: TimeUnit;
            priority: number;
            includeApps?: Array<string> | null;
            excludeApps?: Array<string> | null;
            tags?: Array<string> | null;
            icon: HighlightIcon;
            imageContentId?: any | null;
            surveyId?: any | null;
            simpleContentId?: any | null;
            closeable: boolean;
            highlighted: boolean;
            offsetSequence?: number | null;
            shouldSendNotification: boolean;
            clockTypeName?: string | null;
            highlightTypeName?: string | null;
            displayAsModal?: boolean | null;
            titleMarkup?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            headerMarkup?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            bodyMarkup?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            linkText?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            notificationText?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            timeline?: {
                __typename?: 'WhatsNewTimeline';
                id: any;
                label: { __typename?: 'LocalizedString'; en: string };
            } | null;
            displayAsModalButtonText?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
        } | null;
    } | null;
};

export type DeleteHighlightMutationVariables = Exact<{
    input: DeleteHighlightInput;
}>;

export type DeleteHighlightMutation = {
    __typename?: 'Mutation';
    deleteHighlight?: {
        __typename?: 'DeleteHighlightResponse';
        status: number;
        message: string;
        success: boolean;
        id?: string | null;
    } | null;
};

export type AppsTagsTimelinesForHighlightsPageQueryVariables = Exact<{ [key: string]: never }>;

export type AppsTagsTimelinesForHighlightsPageQuery = {
    __typename?: 'Query';
    whatsNewTimelines: Array<{
        __typename?: 'WhatsNewTimeline';
        id: any;
        type: WhatsNewTimelineType;
        label: { __typename?: 'LocalizedString'; en: string };
    }>;
    amsApps: Array<{
        __typename?: 'AMSApp';
        id: string;
        bundleId: string;
        productConst?: AmsProduct | null;
    }>;
    tags: Array<{ __typename?: 'Tag'; id: any; name: string }>;
    surveyDefs: Array<{ __typename?: 'SurveyDef'; id: any; name?: string | null }>;
};

export type MessageCenterTemplateFragmentFragment = {
    __typename?: 'MessageCenterTemplate';
    id: any;
    title?: string | null;
    content: { __typename?: 'LocalizedString'; en: string; es?: string | null };
};

export type AllMessageCenterTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type AllMessageCenterTemplatesQuery = {
    __typename?: 'Query';
    messageCenterTemplates: Array<{
        __typename?: 'MessageCenterTemplate';
        id: any;
        title?: string | null;
        content: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    }>;
};

export type MessageCenterTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type MessageCenterTemplatesQuery = {
    __typename?: 'Query';
    messageCenterTemplates: Array<{
        __typename?: 'MessageCenterTemplate';
        id: any;
        title?: string | null;
        createdAt: any;
        content: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    }>;
};

export type MessageCenterTemplateQueryVariables = Exact<{
    input: GetMessageCenterTemplateInput;
}>;

export type MessageCenterTemplateQuery = {
    __typename?: 'Query';
    messageCenterTemplate?: {
        __typename?: 'MessageCenterTemplate';
        id: any;
        title?: string | null;
        content: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    } | null;
};

export type CreateMessageCenterTemplateMutationVariables = Exact<{
    input: MessageCenterTemplateInput;
}>;

export type CreateMessageCenterTemplateMutation = {
    __typename?: 'Mutation';
    createMessageCenterTemplate?: {
        __typename?: 'CreateMessageCenterTemplateResponse';
        success: boolean;
        message: string;
        resourceCreated?: {
            __typename?: 'MessageCenterTemplate';
            id: any;
            title?: string | null;
            content: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        } | null;
    } | null;
};

export type UpdateMessageCenterTemplateMutationVariables = Exact<{
    input: UpdateMessageCenterTemplateInput;
}>;

export type UpdateMessageCenterTemplateMutation = {
    __typename?: 'Mutation';
    updateMessageCenterTemplate?: {
        __typename?: 'UpdateMessageCenterTemplateResponse';
        success: boolean;
        message: string;
        resourceUpdated?: {
            __typename?: 'MessageCenterTemplate';
            id: any;
            title?: string | null;
            content: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        } | null;
    } | null;
};

export type DeleteMessageCenterTemplateMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteMessageCenterTemplateMutation = {
    __typename?: 'Mutation';
    deleteMessageCenterTemplate?: {
        __typename?: 'DeleteMessageCenterTemplateMutationResponse';
        success: boolean;
        message: string;
        status: number;
        id: string;
    } | null;
};

export type MessageCenterTemplateWhereUsedQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type MessageCenterTemplateWhereUsedQuery = {
    __typename?: 'Query';
    messageCenterTemplateWhereUsed?: {
        __typename?: 'EntityWhereUsedResponse';
        dependencies?: Array<{
            __typename?: 'DependentEntity';
            entityType: WhereUsedEntity;
            blocksDelete?: boolean | null;
            total?: number | null;
            entries?: Array<{
                __typename?: 'DependentEntityEntry';
                id: string;
                name: string;
            } | null> | null;
        }> | null;
    } | null;
};

export type CreateMessageTemplateMutationVariables = Exact<{
    input: MessageTemplateInput;
}>;

export type CreateMessageTemplateMutation = {
    __typename?: 'Mutation';
    createMessageTemplate?: {
        __typename?: 'CreateMessageTemplateResponse';
        status: number;
        message: string;
        success: boolean;
        resourceCreated?: {
            __typename?: 'MessageTemplate';
            id: any;
            name: string;
            emailTitle?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            emailBody?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            substitutions?: Array<{
                __typename?: 'MessageSubstitutionItem';
                configValueDefId?: any | null;
                failIfMissing?: boolean | null;
                missingValueText: string;
                profileVariableDefId?: any | null;
                substitutionId?: number | null;
                systemTokenId?: any | null;
                name: string;
            }> | null;
        } | null;
    } | null;
};

export type UpdateMessageTemplateMutationVariables = Exact<{
    input: UpdateMessageTemplateInput;
}>;

export type UpdateMessageTemplateMutation = {
    __typename?: 'Mutation';
    updateMessageTemplate?: {
        __typename?: 'UpdateMessageTemplateResponse';
        status: number;
        message: string;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'MessageTemplate';
            id: any;
            name: string;
            emailTitle?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            emailBody?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            substitutions?: Array<{
                __typename?: 'MessageSubstitutionItem';
                configValueDefId?: any | null;
                failIfMissing?: boolean | null;
                missingValueText: string;
                profileVariableDefId?: any | null;
                substitutionId?: number | null;
                systemTokenId?: any | null;
                name: string;
            }> | null;
        } | null;
    } | null;
};

export type MessageTemplatesListQueryVariables = Exact<{
    input: MessageTemplateListInput;
}>;

export type MessageTemplatesListQuery = {
    __typename?: 'Query';
    messageTemplatesV2: {
        __typename?: 'MessageTemplateListResponse';
        total: number;
        results: Array<{
            __typename?: 'MessageTemplate';
            id: any;
            name: string;
            emailTitle?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            emailBody?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            textBody?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
        }>;
    };
};

export type TemplateByIdQueryVariables = Exact<{
    input: GetMessageTemplateInput;
}>;

export type TemplateByIdQuery = {
    __typename?: 'Query';
    messageTemplate?: {
        __typename?: 'MessageTemplate';
        id: any;
        name: string;
        emailTitle?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        emailBody?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        textBody?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        substitutions?: Array<{
            __typename?: 'MessageSubstitutionItem';
            configValueDefId?: any | null;
            failIfMissing?: boolean | null;
            missingValueText: string;
            profileVariableDefId?: any | null;
            substitutionId?: number | null;
            systemTokenId?: any | null;
            name: string;
        }> | null;
    } | null;
};

export type FetchSubmittingClaimsQueryVariables = Exact<{
    input: SubmittedClaimQueryInput;
}>;

export type FetchSubmittingClaimsQuery = {
    __typename?: 'Query';
    submittedClaimsV2: {
        __typename?: 'SubmittedClaimPaginationQueryResponse';
        total: number;
        skip?: number | null;
        limit?: number | null;
        hasPrevious?: boolean | null;
        hasMore?: boolean | null;
        results: Array<{
            __typename?: 'ClaimSubmitted';
            PatLName?: string | null;
            PatFName?: string | null;
            ClaimRef: any;
            createdAt: any;
            ServiceFrom_1?: string | null;
            status?: ClaimSubmittedStatus | null;
            errorText?: string | null;
            uploaded?: boolean | null;
            updatedAt: any;
        }>;
    };
};

export type CreateClaimsViaFileMutationVariables = Exact<{
    input: CreateManySubmittedClaimsViaFileInput;
}>;

export type CreateClaimsViaFileMutation = {
    __typename?: 'Mutation';
    createManySubmittedClaimsViaFile?: {
        __typename?: 'CreateManySubmittedClaimsViaFileResponse';
        success: boolean;
        status: number;
        message: string;
        resourcesCreated: Array<{
            __typename?: 'CreateSubmittedClaimViaFileResponse';
            success: boolean;
            resourceCreated?: {
                __typename?: 'CreateManyClaimSubmittedIndividual';
                createdAt?: string | null;
                ClaimRef?: string | null;
                PatFName?: string | null;
                PatLName?: string | null;
                ServiceFrom_1?: string | null;
            } | null;
        }>;
    } | null;
};

export type FetchSubmittedClaimQueryVariables = Exact<{
    input: GetSubmittedClaimInput;
}>;

export type FetchSubmittedClaimQuery = {
    __typename?: 'Query';
    submittedClaim?: {
        __typename?: 'ClaimSubmitted';
        PatAddr?: string | null;
        PatBirthdate?: string | null;
        PatCity?: string | null;
        PatFName?: string | null;
        PatLName?: string | null;
        PatSex?: string | null;
        PatState?: string | null;
        PatZip?: string | null;
        SbrAddr?: string | null;
        SbrBirthdate?: string | null;
        SbrCity?: string | null;
        SbrFName?: string | null;
        SbrID?: string | null;
        SbrLName?: string | null;
        SbrSex?: string | null;
        SbrState?: string | null;
        SbrZip?: string | null;
        status?: ClaimSubmittedStatus | null;
        errorText?: string | null;
    } | null;
};

export type ForceClaimUploadMutationVariables = Exact<{
    input: ForceClaimUploadInput;
}>;

export type ForceClaimUploadMutation = {
    __typename?: 'Mutation';
    forceClaimUpload?: {
        __typename?: 'ForceClaimUploadResponse';
        message: string;
        status: number;
        success: boolean;
    } | null;
};

export type FetchPracticeResourceConfigForPracticeQueryVariables = Exact<{
    input: GetOrganizationInput;
}>;

export type FetchPracticeResourceConfigForPracticeQuery = {
    __typename?: 'Query';
    organization?: {
        __typename?: 'Organization';
        id: any;
        createdAt: any;
        updatedAt: any;
        name: string;
        brandingName: string;
        healthAdvocateTitle?: string | null;
        config?: {
            __typename?: 'OrganizationConfig';
            resources?: {
                __typename?: 'OrganizationConfigResource';
                downloadConfApp: boolean;
                prepareForVirtualVisit: boolean;
                scheduleNextVisit: boolean;
                devices?: boolean | null;
            } | null;
        } | null;
    } | null;
};

export type UpdatePracticeResourceConfigForPracticeMutationVariables = Exact<{
    input: UpdateOrganizationInput;
}>;

export type UpdatePracticeResourceConfigForPracticeMutation = {
    __typename?: 'Mutation';
    updateOrganization?: {
        __typename?: 'UpdateOrganizationResponse';
        success: boolean;
        message: string;
        resourceUpdated?: {
            __typename?: 'Organization';
            id: any;
            createdAt: any;
            updatedAt: any;
            name: string;
            brandingName: string;
            healthAdvocateTitle?: string | null;
            config?: {
                __typename?: 'OrganizationConfig';
                resources?: {
                    __typename?: 'OrganizationConfigResource';
                    downloadConfApp: boolean;
                    prepareForVirtualVisit: boolean;
                    scheduleNextVisit: boolean;
                    devices?: boolean | null;
                } | null;
            } | null;
        } | null;
    } | null;
};

export type FetchPracticeConfigSummaryForPracticeQueryVariables = Exact<{
    input: GetOrganizationInput;
}>;

export type FetchPracticeConfigSummaryForPracticeQuery = {
    __typename?: 'Query';
    organization?: {
        __typename?: 'Organization';
        id: any;
        createdAt: any;
        updatedAt: any;
        name: string;
        brandingName: string;
        healthAdvocateTitle?: string | null;
        config?: {
            __typename?: 'OrganizationConfig';
            resources?: {
                __typename?: 'OrganizationConfigResource';
                downloadConfApp: boolean;
                prepareForVirtualVisit: boolean;
                scheduleNextVisit: boolean;
                devices?: boolean | null;
            } | null;
        } | null;
    } | null;
};

export type UpdatePracticeConfigOptionsForPracticeMutationVariables = Exact<{
    input: UpdateOrganizationInput;
}>;

export type UpdatePracticeConfigOptionsForPracticeMutation = {
    __typename?: 'Mutation';
    updateOrganization?: {
        __typename?: 'UpdateOrganizationResponse';
        success: boolean;
        message: string;
        resourceUpdated?: {
            __typename?: 'Organization';
            id: any;
            createdAt: any;
            updatedAt: any;
            name: string;
            parentId?: any | null;
            brandingName: string;
            healthAdvocateTitle?: string | null;
            externalId?: string | null;
            allowPatientCreation?: boolean | null;
            logo?: string | null;
            email?: string | null;
            timezone?: string | null;
            conferencingAppName?: string | null;
            appBundleId?: string | null;
            appPlatform?: string | null;
            eligibilityType?: EligibilityType | null;
            inviteCodes?: Array<string | null> | null;
            configOptions?: {
                __typename?: 'OrganizationConfigOptions';
                riskScoreEnabled: boolean;
            } | null;
        } | null;
    } | null;
};

export type AddInviteCodeOnOrgPageMutationVariables = Exact<{
    input: AddOrganizationInviteCodeInput;
}>;

export type AddInviteCodeOnOrgPageMutation = {
    __typename?: 'Mutation';
    addOrganizationInviteCode?: {
        __typename?: 'AddOrganizationInviteCodeResponse';
        success: boolean;
        message: string;
        status: number;
        organization?: {
            __typename?: 'Organization';
            id: any;
            createdAt: any;
            updatedAt: any;
            name: string;
            parentId?: any | null;
            brandingName: string;
            healthAdvocateTitle?: string | null;
            externalId?: string | null;
            allowPatientCreation?: boolean | null;
            logo?: string | null;
            email?: string | null;
            timezone?: string | null;
            conferencingAppName?: string | null;
            appBundleId?: string | null;
            appPlatform?: string | null;
            eligibilityType?: EligibilityType | null;
            inviteCodes?: Array<string | null> | null;
            configOptions?: {
                __typename?: 'OrganizationConfigOptions';
                riskScoreEnabled: boolean;
            } | null;
        } | null;
    } | null;
};

export type DeleteInviteCodeOnOrgPageMutationVariables = Exact<{
    input: DeleteOrganizationInviteCodeInput;
}>;

export type DeleteInviteCodeOnOrgPageMutation = {
    __typename?: 'Mutation';
    deleteOrganizationInviteCode?: {
        __typename?: 'DeleteOrganizationInviteCodeResponse';
        success: boolean;
        message: string;
        status: number;
        organization?: {
            __typename?: 'Organization';
            id: any;
            createdAt: any;
            updatedAt: any;
            name: string;
            parentId?: any | null;
            brandingName: string;
            healthAdvocateTitle?: string | null;
            externalId?: string | null;
            allowPatientCreation?: boolean | null;
            logo?: string | null;
            email?: string | null;
            timezone?: string | null;
            conferencingAppName?: string | null;
            appBundleId?: string | null;
            appPlatform?: string | null;
            eligibilityType?: EligibilityType | null;
            inviteCodes?: Array<string | null> | null;
            configOptions?: {
                __typename?: 'OrganizationConfigOptions';
                riskScoreEnabled: boolean;
            } | null;
        } | null;
    } | null;
};

export type FetchOrgDetailsQueryVariables = Exact<{
    input: GetOrganizationInput;
}>;

export type FetchOrgDetailsQuery = {
    __typename?: 'Query';
    organization?: {
        __typename?: 'Organization';
        id: any;
        createdAt: any;
        updatedAt: any;
        name: string;
        parentId?: any | null;
        brandingName: string;
        healthAdvocateTitle?: string | null;
        externalId?: string | null;
        allowPatientCreation?: boolean | null;
        logo?: string | null;
        email?: string | null;
        timezone?: string | null;
        conferencingAppName?: string | null;
        appBundleId?: string | null;
        appPlatform?: string | null;
        eligibilityType?: EligibilityType | null;
        inviteCodes?: Array<string | null> | null;
        configOptions?: {
            __typename?: 'OrganizationConfigOptions';
            riskScoreEnabled: boolean;
        } | null;
    } | null;
};

export type FetchPatientHeaderQueryVariables = Exact<{
    input: GetPatientHeaderInput;
}>;

export type FetchPatientHeaderQuery = {
    __typename?: 'Query';
    patientHeader?: {
        __typename?: 'PatientHeader';
        updatedAt: any;
        organizationId?: any | null;
        isTestData?: boolean | null;
        id: any;
        createdAt: any;
        profileDefs?: Array<{
            __typename?: 'HeaderProfileDef';
            disabled?: boolean | null;
            label?: string | null;
            profileDefId: any;
            sortField?: string | null;
            isParentField?: boolean | null;
        } | null> | null;
    } | null;
};

export type CreatePatientHeaderMutationVariables = Exact<{
    input: PatientHeaderInput;
}>;

export type CreatePatientHeaderMutation = {
    __typename?: 'Mutation';
    createPatientHeader?: {
        __typename?: 'CreatePatientHeaderResponse';
        success: boolean;
        status: number;
        resourceCreated?: {
            __typename?: 'PatientHeader';
            createdAt: any;
            id: any;
            isTestData?: boolean | null;
            organizationId?: any | null;
            updatedAt: any;
            profileDefs?: Array<{
                __typename?: 'HeaderProfileDef';
                sortField?: string | null;
                profileDefId: any;
                label?: string | null;
                disabled?: boolean | null;
                isParentField?: boolean | null;
            } | null> | null;
        } | null;
    } | null;
};

export type UpdatePatientHeaderMutationVariables = Exact<{
    input: UpdatePatientHeaderInput;
}>;

export type UpdatePatientHeaderMutation = {
    __typename?: 'Mutation';
    updatePatientHeader?: {
        __typename?: 'UpdatePatientHeaderResponse';
        success: boolean;
        status: number;
        message: string;
        resourceUpdated?: {
            __typename?: 'PatientHeader';
            createdAt: any;
            id: any;
            isTestData?: boolean | null;
            organizationId?: any | null;
            updatedAt: any;
            profileDefs?: Array<{
                __typename?: 'HeaderProfileDef';
                sortField?: string | null;
                profileDefId: any;
                label?: string | null;
                disabled?: boolean | null;
                isParentField?: boolean | null;
            } | null> | null;
        } | null;
    } | null;
};

export type DeletePatientHeaderMutationVariables = Exact<{
    input: DeletePatientHeaderInput;
}>;

export type DeletePatientHeaderMutation = {
    __typename?: 'Mutation';
    deletePatientHeader?: {
        __typename?: 'DeletePatientHeaderResponse';
        success: boolean;
        message: string;
        status: number;
        id?: string | null;
    } | null;
};

export type FetchProfileDefsForProfileHeaderPageQueryVariables = Exact<{ [key: string]: never }>;

export type FetchProfileDefsForProfileHeaderPageQuery = {
    __typename?: 'Query';
    userProfileDefs: Array<{
        __typename?: 'UserProfileDef';
        id: any;
        name: string;
        description?: string | null;
        category: string;
        valueType: UserProfileValueType;
        choiceValueType?: UserProfileValueType | null;
        surveyQuestionDisabled?: boolean | null;
        systemGenerated?: boolean | null;
        conditionTypeId?: any | null;
        episodeRequired?: boolean | null;
        reportingNotes?: string | null;
        haPermission?: HaPermission | null;
        inboundNotes?: string | null;
        isClientSpecific?: boolean | null;
        linkToJIRA?: string | null;
        purposeAppPersonalization?: boolean | null;
        purposeHumanServices?: boolean | null;
        clientSpecificDisplayName?: string | null;
        dataSourceNotes?: string | null;
        purposeDetails?: string | null;
        dwLocation?: string | null;
        dwObject?: string | null;
        dwMetrics?: string | null;
        dwInternalReports?: string | null;
        dwExternalReports?: string | null;
        claimsField?: string | null;
        icd10DiagnosisCodes?: string | null;
        procedureCodes?: string | null;
        label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        questionLabel?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        choices?: Array<{
            __typename?: 'UserProfileDefChoice';
            specifyValue: boolean;
            id: any;
            name?: string | null;
            answerLabel?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            value?: {
                __typename?: 'UserProfileDefChoiceValue';
                bool?: boolean | null;
                date?: any | null;
                num?: number | null;
                str?: string | null;
            } | null;
        } | null> | null;
    }>;
};

export type FetchReportingJobsForReportingJobsPageQueryVariables = Exact<{
    input?: InputMaybe<ReportingJobQueryInput>;
}>;

export type FetchReportingJobsForReportingJobsPageQuery = {
    __typename?: 'Query';
    reportingJobsV2: {
        __typename?: 'ReportingJobPaginationQueryResponse';
        results: Array<{
            __typename?: 'ReportingJob';
            reportName?: ReportingJobReportNameType | null;
            status?: ReportingJobStatus | null;
            startedByName?: string | null;
            s3key?: string | null;
            createdAt: any;
            updatedAt: any;
            isTestData?: boolean | null;
            id: any;
        }>;
    };
};

export type FetchOrganizationReportByKeyForReportingJobsPageQueryVariables = Exact<{
    input: S3GetObjectInput;
}>;

export type FetchOrganizationReportByKeyForReportingJobsPageQuery = {
    __typename?: 'Query';
    getOrganizationReportByKey?: {
        __typename?: 'ReportBufferByKeyResponse';
        data?: Array<string | null> | null;
        filename?: string | null;
    } | null;
};

export type FetchPatientsForOrganizationReportingQueryVariables = Exact<{
    input: GetPatientsByFilterInput;
}>;

export type FetchPatientsForOrganizationReportingQuery = {
    __typename?: 'Query';
    currentUser?: {
        __typename?: 'User';
        id: any;
        name: string;
        email: string;
        organizations: Array<{
            __typename?: 'Organization';
            id: any;
            parentId?: any | null;
            brandingName: string;
        }>;
        currentOrg: {
            __typename?: 'Organization';
            id: any;
            parentId?: any | null;
            brandingName: string;
            allowPatientCreation?: boolean | null;
            appPlatform?: string | null;
            appBundleId?: string | null;
        };
        patientsV2: {
            __typename?: 'UserPatientsResponse';
            orderBy?: string | null;
            skip?: number | null;
            limit?: number | null;
            total: number;
            hasMore?: boolean | null;
            hasPrevious?: boolean | null;
            results: Array<{
                __typename?: 'Patient';
                tagIds?: Array<any | null> | null;
                id: any;
                createdAt: any;
                externalId?: string | null;
                memberId?: string | null;
                fullName: string;
                firstName: string;
                lastName: string;
                onboardingDate?: any | null;
                email?: string | null;
                birthDate: any;
                phoneNumber?: string | null;
                invitationMode?: PatientInvitation | null;
                invitationCode?: string | null;
                signUpPracticeCode?: string | null;
                isTestData?: boolean | null;
                assignedHealthAdvocate?: any | null;
                openAdvocateTaskCount?: number | null;
                openCriticalAdvocateTaskCount?: number | null;
                onboardingCompleteDate?: any | null;
                isPregnant?: boolean | null;
                dueDate?: any | null;
                deliveryDate?: any | null;
                patientCallStatus?: string | null;
                appUserId?: any | null;
                appBundleId?: string | null;
                surveyResponses: Array<{
                    __typename?: 'SurveyResponse';
                    surveyType?: string | null;
                    clientSurveyId?: string | null;
                    responseDate?: any | null;
                    surveyScore?: number | null;
                    displayName?: string | null;
                    id: any;
                    createdAt: any;
                    updatedAt: any;
                    reviewed?: boolean | null;
                    isCoreSurvey?: boolean | null;
                    answers: Array<{
                        __typename?: 'SurveyResponseAnswer';
                        subsectionName?: string | null;
                        questionTypeId?: string | null;
                        clientQuestionId?: string | null;
                        questionId?: string | null;
                        questionText?: string | null;
                        answerId?: any | null;
                        answerText?: any | null;
                        responseScore?: number | null;
                        questionOrder?: number | null;
                    }>;
                    practice?: {
                        __typename?: 'Organization';
                        id: any;
                        brandingName: string;
                        timezone?: string | null;
                    } | null;
                    patient?: {
                        __typename?: 'Patient';
                        id: any;
                        externalId?: string | null;
                        fullName: string;
                        firstName: string;
                        lastName: string;
                        birthDate: any;
                        dueDate?: any | null;
                    } | null;
                }>;
                attachments?: Array<{
                    __typename?: 'Attachment';
                    uuid: string;
                    label: string;
                } | null> | null;
                riskIndicators?: {
                    __typename?: 'PatientRiskIndicators';
                    obesity?: {
                        __typename?: 'PatientRiskIndicator';
                        flag: boolean;
                        comment?: string | null;
                    } | null;
                    preterm?: {
                        __typename?: 'PatientRiskIndicator';
                        flag: boolean;
                        comment?: string | null;
                    } | null;
                    chronicHypertension?: {
                        __typename?: 'PatientRiskIndicator';
                        flag: boolean;
                        comment?: string | null;
                    } | null;
                    other?: {
                        __typename?: 'PatientRiskIndicator';
                        flag: boolean;
                        comment?: string | null;
                    } | null;
                } | null;
                mailingAddress?: {
                    __typename?: 'Address';
                    street1?: string | null;
                    street2?: string | null;
                    city?: string | null;
                    state?: string | null;
                    code?: string | null;
                } | null;
                practice: {
                    __typename?: 'Organization';
                    id: any;
                    name: string;
                    timezone?: string | null;
                    providers?: Array<{
                        __typename?: 'OrganizationProvider';
                        provider: {
                            __typename?: 'Provider';
                            id: any;
                            localizedName: string;
                            createdAt: any;
                        };
                    }> | null;
                };
                questions: Array<{
                    __typename?: 'CombinedQuestion';
                    id: string;
                    createdAt: any;
                    updatedAt: any;
                    submittedAt: any;
                    text: string;
                    isDone: boolean;
                }>;
                notes: Array<{
                    __typename?: 'PatientNotes';
                    id: any;
                    text?: string | null;
                    title?: string | null;
                    staffName?: string | null;
                    createdAt: any;
                }>;
                chatRoom: {
                    __typename?: 'ChatRoom';
                    id: any;
                    numberOfUnreadMessages?: number | null;
                };
                profileValues: Array<{
                    __typename?: 'PatientProfileValue';
                    profileVariableDefId?: any | null;
                    type?: ProfileValueType | null;
                    valueBool?: boolean | null;
                    valueNum?: number | null;
                    valueStr?: string | null;
                    valueId?: any | null;
                    valueIds?: Array<any | null> | null;
                    valueDate?: any | null;
                    timeZone?: string | null;
                    utcOffset?: number | null;
                }>;
                riskScore?: {
                    __typename?: 'RiskScoreResponse';
                    value?: number | null;
                    tierName?: string | null;
                } | null;
            }>;
        };
    } | null;
};

export type FetchProfileVariablesForOrganizationReportingQueryQueryVariables = Exact<{
    patientId?: InputMaybe<Scalars['String']>;
    profileDefsIds?: InputMaybe<
        Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
    >;
}>;

export type FetchProfileVariablesForOrganizationReportingQueryQuery = {
    __typename?: 'Query';
    profileVariablesByPVDefIds?: Array<{
        __typename?: 'ProfileVariable';
        profileVariableDefId: string;
        patientId?: string | null;
        value?: {
            __typename?: 'ProfileValueData';
            str?: string | null;
            num?: number | null;
            bool?: boolean | null;
            date?: any | null;
            strs?: Array<string | null> | null;
            nums?: Array<number | null> | null;
            bools?: Array<boolean | null> | null;
            dates?: Array<any | null> | null;
            choice?: string | null;
            choices?: Array<string | null> | null;
            specified?: Array<{
                __typename?: 'SpecifiedValue';
                choiceId: string;
                type: ProfileSpecifiedValueType;
                val: {
                    __typename?: 'ProfileSpecifiedValue';
                    str?: string | null;
                    num?: number | null;
                    bool?: boolean | null;
                    date?: any | null;
                };
            }> | null;
            localized?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        } | null;
    } | null> | null;
};

export type FetchIntakeSurveysQueryVariables = Exact<{
    input: GetConfigValueInput;
}>;

export type FetchIntakeSurveysQuery = {
    __typename?: 'Query';
    configValue?: {
        __typename?: 'ConfigValue';
        value: { __typename?: 'ConfigValueDictionary'; strArr?: Array<string | null> | null };
    } | null;
};

export type FetchCareTeamMembersForOrganizationReportingCsvPageQueryVariables = Exact<{
    input?: InputMaybe<CareTeamMemberQueryInput>;
}>;

export type FetchCareTeamMembersForOrganizationReportingCsvPageQuery = {
    __typename?: 'Query';
    careTeamMembersV2: {
        __typename?: 'CareTeamMemberPaginationQueryResponse';
        results: Array<{ __typename?: 'CareTeamMember'; id: any }>;
    };
};

export type CreateOrUpdateOneUserProfileVariableForOrganizationPageMutationVariables = Exact<{
    input: PatientProfileVariableInput;
}>;

export type CreateOrUpdateOneUserProfileVariableForOrganizationPageMutation = {
    __typename?: 'Mutation';
    updatePatientProfileVariable?: {
        __typename?: 'PatientProfileVariable';
        profileVariableDefId: string;
    } | null;
};

export type GenerateReportByReportTypeQueryVariables = Exact<{
    input: ReportDataInput;
}>;

export type GenerateReportByReportTypeQuery = {
    __typename?: 'Query';
    generateReportByReportType?: {
        __typename?: 'ReportDataResponse';
        success: boolean;
        message: string;
        status: number;
    } | null;
};

export type OrganizationVcEligibilityEligibilityResultsQueryVariables = Exact<{
    input?: InputMaybe<GetEligibilityResultInput>;
}>;

export type OrganizationVcEligibilityEligibilityResultsQuery = {
    __typename?: 'Query';
    eligibilityResults?: {
        __typename?: 'VCEligibilityResults';
        total: number;
        results: Array<{
            __typename?: 'VirtualCareEligibilityResult';
            id: any;
            firstName?: string | null;
            lastName?: string | null;
            passed?: boolean | null;
            createdAt: any;
        }>;
    } | null;
};

export type OrganizationVcEligibilityEligibilityResultsByIdQueryVariables = Exact<{
    input?: InputMaybe<GetEligibilityResultInput>;
}>;

export type OrganizationVcEligibilityEligibilityResultsByIdQuery = {
    __typename?: 'Query';
    eligibilityResults?: {
        __typename?: 'VCEligibilityResults';
        total: number;
        results: Array<{
            __typename?: 'VirtualCareEligibilityResult';
            id: any;
            firstName?: string | null;
            lastName?: string | null;
            passed?: boolean | null;
            createdAt: any;
            email?: string | null;
            error?: string | null;
            phoneNumber?: string | null;
            response?: string | null;
            data?: string | null;
        }>;
    } | null;
};

export type OrganizationVcfResultsQueryVariables = Exact<{
    input?: InputMaybe<VirtualCareFormQueryInput>;
}>;

export type OrganizationVcfResultsQuery = {
    __typename?: 'Query';
    virtualCareFormsV2: {
        __typename?: 'VirtualCareFormPaginationQueryResponse';
        hasMore?: boolean | null;
        hasPrevious?: boolean | null;
        total: number;
        results: Array<{
            __typename?: 'VirtualCareForm';
            Destination?: string | null;
            id: any;
            firstName?: string | null;
            lastName?: string | null;
            appointmentMethod?: AppointmentMethod | null;
            createdAt: any;
            phoneNumber?: string | null;
            insuranceId?: string | null;
        }>;
    };
};

export type OrganizationVirtualCareFormQueryVariables = Exact<{
    input: GetVirtualCareFormInput;
}>;

export type OrganizationVirtualCareFormQuery = {
    __typename?: 'Query';
    virtualCareForm?: {
        __typename?: 'VirtualCareForm';
        Destination?: string | null;
        appointmentMethod?: AppointmentMethod | null;
        birthDate?: any | null;
        createdAt: any;
        email?: string | null;
        firstName?: string | null;
        id: any;
        lastName?: string | null;
        patientId?: any | null;
        phoneNumber?: string | null;
    } | null;
};

export type FetchOrgDetailsForModalQueryVariables = Exact<{
    input: GetOrganizationInput;
}>;

export type FetchOrgDetailsForModalQuery = {
    __typename?: 'Query';
    organization?: {
        __typename?: 'Organization';
        id: any;
        createdAt: any;
        updatedAt: any;
        name: string;
        parentId?: any | null;
        brandingName: string;
        healthAdvocateTitle?: string | null;
        externalId?: string | null;
        allowPatientCreation?: boolean | null;
        logo?: string | null;
        email?: string | null;
        timezone?: string | null;
        conferencingAppName?: string | null;
        appBundleId?: string | null;
        appPlatform?: string | null;
        eligibilityType?: EligibilityType | null;
        inviteCodes?: Array<string | null> | null;
        configOptions?: {
            __typename?: 'OrganizationConfigOptions';
            riskScoreEnabled: boolean;
        } | null;
    } | null;
};

export type FetchDevicesHealthPlansProvidersForOrgModalQueryVariables = Exact<{
    [key: string]: never;
}>;

export type FetchDevicesHealthPlansProvidersForOrgModalQuery = {
    __typename?: 'Query';
    healthPlans: Array<{
        __typename?: 'HealthPlan';
        id: any;
        brandingName?: string | null;
        name?: string | null;
    }>;
    devices: Array<{ __typename?: 'Device'; localizedName?: string | null; id: any }>;
    providers: Array<{ __typename?: 'Provider'; id: any; localizedName: string }>;
};

export type UpdateOrgForModalMutationVariables = Exact<{
    input: UpdateOrganizationInput;
}>;

export type UpdateOrgForModalMutation = {
    __typename?: 'Mutation';
    updateOrganization?: {
        __typename?: 'UpdateOrganizationResponse';
        message: string;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'Organization';
            id: any;
            createdAt: any;
            updatedAt: any;
            name: string;
            parentId?: any | null;
            brandingName: string;
            healthAdvocateTitle?: string | null;
            externalId?: string | null;
            allowPatientCreation?: boolean | null;
            logo?: string | null;
            email?: string | null;
            timezone?: string | null;
            conferencingAppName?: string | null;
            appBundleId?: string | null;
            appPlatform?: string | null;
            eligibilityType?: EligibilityType | null;
            inviteCodes?: Array<string | null> | null;
            configOptions?: {
                __typename?: 'OrganizationConfigOptions';
                riskScoreEnabled: boolean;
            } | null;
        } | null;
    } | null;
};

export type CreateOrgForModalMutationVariables = Exact<{
    input: OrganizationInput;
}>;

export type CreateOrgForModalMutation = {
    __typename?: 'Mutation';
    createOrganization?: {
        __typename?: 'CreateOrganizationResponse';
        message: string;
        success: boolean;
        resourceCreated?: {
            __typename?: 'Organization';
            id: any;
            createdAt: any;
            updatedAt: any;
            name: string;
            parentId?: any | null;
            brandingName: string;
            healthAdvocateTitle?: string | null;
            externalId?: string | null;
            allowPatientCreation?: boolean | null;
            logo?: string | null;
            email?: string | null;
            timezone?: string | null;
            conferencingAppName?: string | null;
            appBundleId?: string | null;
            appPlatform?: string | null;
            eligibilityType?: EligibilityType | null;
            inviteCodes?: Array<string | null> | null;
            configOptions?: {
                __typename?: 'OrganizationConfigOptions';
                riskScoreEnabled: boolean;
            } | null;
        } | null;
    } | null;
};

export type FetchOrganizationsFromCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type FetchOrganizationsFromCurrentUserQuery = {
    __typename?: 'Query';
    currentUser?: {
        __typename?: 'User';
        organizations: Array<{
            __typename?: 'Organization';
            id: any;
            parentId?: any | null;
            brandingName: string;
            logo?: string | null;
            ancestors?: Array<any | null> | null;
            appBundleId?: string | null;
            unreadMessagesCount?: number | null;
        }>;
        currentOrg: { __typename?: 'Organization'; id: any };
    } | null;
};

export type PatientActionTypesForConfigPortalQueryVariables = Exact<{ [key: string]: never }>;

export type PatientActionTypesForConfigPortalQuery = {
    __typename?: 'Query';
    patientActionTypesV2: {
        __typename?: 'PatientActionTypePaginationQueryResponse';
        hasMore?: boolean | null;
        hasPrevious?: boolean | null;
        limit?: number | null;
        orderBy?: string | null;
        skip?: number | null;
        total: number;
        results: Array<{
            __typename?: 'PatientActionType';
            id: any;
            label: string;
            updatedAt: any;
            createdAt: any;
            isTestData?: boolean | null;
        }>;
    };
};

export type DeletePatientActionTypeMutationVariables = Exact<{
    deletePatientActionTypeId?: InputMaybe<Scalars['String']>;
}>;

export type DeletePatientActionTypeMutation = {
    __typename?: 'Mutation';
    deletePatientActionType?: {
        __typename?: 'DeletePatientActionTypeResponse';
        id?: string | null;
        message: string;
        status: number;
        success: boolean;
    } | null;
};

export type DeleteAndReplacePatientActionTypeMutationVariables = Exact<{
    input?: InputMaybe<DeleteAndReplacePatientActionTypeInput>;
}>;

export type DeleteAndReplacePatientActionTypeMutation = {
    __typename?: 'Mutation';
    deleteAndReplacePatientActionType?: {
        __typename?: 'DeletePatientActionTypeResponse';
        id?: string | null;
        message: string;
        status: number;
        success: boolean;
    } | null;
};

export type PatientActionTypeWhereUsedQueryVariables = Exact<{
    patientActionTypeWhereUsedId?: InputMaybe<Scalars['String']>;
}>;

export type PatientActionTypeWhereUsedQuery = {
    __typename?: 'Query';
    patientActionTypeWhereUsed?: {
        __typename?: 'EntityWhereUsedResponse';
        dependencies?: Array<{
            __typename?: 'DependentEntity';
            total?: number | null;
            entityType: WhereUsedEntity;
            blocksDelete?: boolean | null;
            entries?: Array<{
                __typename?: 'DependentEntityEntry';
                name: string;
                id: string;
            } | null> | null;
        }> | null;
    } | null;
};

export type CreatePatientActionTypeForConfigPortalMutationVariables = Exact<{
    input: PatientActionTypeInput;
}>;

export type CreatePatientActionTypeForConfigPortalMutation = {
    __typename?: 'Mutation';
    createPatientActionType?: {
        __typename?: 'CreatePatientActionTypeResponse';
        success: boolean;
        message: string;
        resourceCreated?: {
            __typename?: 'PatientActionType';
            label: string;
            createdAt: any;
            updatedAt: any;
            id: any;
        } | null;
    } | null;
};

export type UpdatePatientActionTypeForConfigPortalMutationVariables = Exact<{
    input: UpdatePatientActionTypeInput;
}>;

export type UpdatePatientActionTypeForConfigPortalMutation = {
    __typename?: 'Mutation';
    updatePatientActionType?: {
        __typename?: 'UpdatePatientActionTypeResponse';
        message: string;
        status: number;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'PatientActionType';
            label: string;
            createdAt: any;
            updatedAt: any;
            id: any;
        } | null;
    } | null;
};

export type FetchPatientNoteTagQueryVariables = Exact<{
    patientNoteTagInput: GetPatientNoteTagInput;
}>;

export type FetchPatientNoteTagQuery = {
    __typename?: 'Query';
    patientNoteTag?: { __typename?: 'PatientNoteTag'; id: any; name: string } | null;
};

export type UpdatePatientNoteTagMutationVariables = Exact<{
    updatePatientNoteTagInput: UpdatePatientNoteTagInput;
}>;

export type UpdatePatientNoteTagMutation = {
    __typename?: 'Mutation';
    updatePatientNoteTag?: {
        __typename?: 'UpdatePatientNoteTagResponse';
        success: boolean;
        message: string;
        resourceUpdated?: { __typename?: 'PatientNoteTag'; id: any; name: string } | null;
    } | null;
};

export type CreatePatientNoteTagMutationVariables = Exact<{
    createPatientNoteTagInput: PatientNoteTagInput;
}>;

export type CreatePatientNoteTagMutation = {
    __typename?: 'Mutation';
    createPatientNoteTag?: {
        __typename?: 'CreatePatientNoteTagResponse';
        success: boolean;
        message: string;
        resourceCreated?: { __typename?: 'PatientNoteTag'; id: any; name: string } | null;
    } | null;
};

export type PatientNoteTagListQueryVariables = Exact<{ [key: string]: never }>;

export type PatientNoteTagListQuery = {
    __typename?: 'Query';
    patientNoteTagsV2: {
        __typename?: 'PatientNoteTagPaginationQueryResponse';
        results: Array<{ __typename?: 'PatientNoteTag'; id: any; name: string }>;
    };
};

export type DeletePatientNoteTagMutationVariables = Exact<{
    input: DeletePatientNoteTagInput;
}>;

export type DeletePatientNoteTagMutation = {
    __typename?: 'Mutation';
    deletePatientNoteTag?: {
        __typename?: 'DeletePatientNoteTagResponse';
        message: string;
        status: number;
        success: boolean;
    } | null;
};

export type FetchUserProfileDefinitionByIdQueryVariables = Exact<{
    input: GetUserProfileDefInput;
}>;

export type FetchUserProfileDefinitionByIdQuery = {
    __typename?: 'Query';
    userProfileDef?: {
        __typename?: 'UserProfileDef';
        id: any;
        name: string;
        description?: string | null;
        category: string;
        valueType: UserProfileValueType;
        choiceValueType?: UserProfileValueType | null;
        surveyQuestionDisabled?: boolean | null;
        systemGenerated?: boolean | null;
        conditionTypeId?: any | null;
        episodeRequired?: boolean | null;
        reportingNotes?: string | null;
        haPermission?: HaPermission | null;
        inboundNotes?: string | null;
        isClientSpecific?: boolean | null;
        linkToJIRA?: string | null;
        purposeAppPersonalization?: boolean | null;
        purposeHumanServices?: boolean | null;
        clientSpecificDisplayName?: string | null;
        dataSourceNotes?: string | null;
        purposeDetails?: string | null;
        dwLocation?: string | null;
        dwObject?: string | null;
        dwMetrics?: string | null;
        dwInternalReports?: string | null;
        dwExternalReports?: string | null;
        claimsField?: string | null;
        icd10DiagnosisCodes?: string | null;
        procedureCodes?: string | null;
        history?: Array<{
            __typename?: 'UserProfileDefHistoryItem';
            username: string;
            userId: string;
            type: string;
            name: string;
            beforeState?: string | null;
            afterState?: string | null;
            datetime: any;
        } | null> | null;
        label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        questionLabel?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        choices?: Array<{
            __typename?: 'UserProfileDefChoice';
            specifyValue: boolean;
            id: any;
            name?: string | null;
            answerLabel?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            value?: {
                __typename?: 'UserProfileDefChoiceValue';
                bool?: boolean | null;
                date?: any | null;
                num?: number | null;
                str?: string | null;
            } | null;
        } | null> | null;
    } | null;
};

export type UpdateUserProfileDefinitionFromModalMutationVariables = Exact<{
    input: UpdateUserProfileDefInput;
}>;

export type UpdateUserProfileDefinitionFromModalMutation = {
    __typename?: 'Mutation';
    updateUserProfileDef?: {
        __typename?: 'UpdateUserProfileDefResponse';
        message: string;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'UserProfileDef';
            id: any;
            name: string;
            description?: string | null;
            category: string;
            valueType: UserProfileValueType;
            choiceValueType?: UserProfileValueType | null;
            surveyQuestionDisabled?: boolean | null;
            systemGenerated?: boolean | null;
            conditionTypeId?: any | null;
            episodeRequired?: boolean | null;
            reportingNotes?: string | null;
            haPermission?: HaPermission | null;
            inboundNotes?: string | null;
            isClientSpecific?: boolean | null;
            linkToJIRA?: string | null;
            purposeAppPersonalization?: boolean | null;
            purposeHumanServices?: boolean | null;
            clientSpecificDisplayName?: string | null;
            dataSourceNotes?: string | null;
            purposeDetails?: string | null;
            dwLocation?: string | null;
            dwObject?: string | null;
            dwMetrics?: string | null;
            dwInternalReports?: string | null;
            dwExternalReports?: string | null;
            claimsField?: string | null;
            icd10DiagnosisCodes?: string | null;
            procedureCodes?: string | null;
            history?: Array<{
                __typename?: 'UserProfileDefHistoryItem';
                username: string;
                userId: string;
                type: string;
                name: string;
                beforeState?: string | null;
                afterState?: string | null;
                datetime: any;
            } | null> | null;
            label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            questionLabel?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            choices?: Array<{
                __typename?: 'UserProfileDefChoice';
                specifyValue: boolean;
                id: any;
                name?: string | null;
                answerLabel?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
                value?: {
                    __typename?: 'UserProfileDefChoiceValue';
                    bool?: boolean | null;
                    date?: any | null;
                    num?: number | null;
                    str?: string | null;
                } | null;
            } | null> | null;
        } | null;
    } | null;
};

export type CreateUserProfileDefinitionFromModalMutationVariables = Exact<{
    input: UserProfileDefInput;
}>;

export type CreateUserProfileDefinitionFromModalMutation = {
    __typename?: 'Mutation';
    createUserProfileDef?: {
        __typename?: 'CreateUserProfileDefResponse';
        message: string;
        success: boolean;
        resourceCreated?: {
            __typename?: 'UserProfileDef';
            id: any;
            name: string;
            description?: string | null;
            category: string;
            valueType: UserProfileValueType;
            choiceValueType?: UserProfileValueType | null;
            surveyQuestionDisabled?: boolean | null;
            systemGenerated?: boolean | null;
            conditionTypeId?: any | null;
            episodeRequired?: boolean | null;
            reportingNotes?: string | null;
            haPermission?: HaPermission | null;
            inboundNotes?: string | null;
            isClientSpecific?: boolean | null;
            linkToJIRA?: string | null;
            purposeAppPersonalization?: boolean | null;
            purposeHumanServices?: boolean | null;
            clientSpecificDisplayName?: string | null;
            dataSourceNotes?: string | null;
            purposeDetails?: string | null;
            dwLocation?: string | null;
            dwObject?: string | null;
            dwMetrics?: string | null;
            dwInternalReports?: string | null;
            dwExternalReports?: string | null;
            claimsField?: string | null;
            icd10DiagnosisCodes?: string | null;
            procedureCodes?: string | null;
            history?: Array<{
                __typename?: 'UserProfileDefHistoryItem';
                username: string;
                userId: string;
                type: string;
                name: string;
                beforeState?: string | null;
                afterState?: string | null;
                datetime: any;
            } | null> | null;
            label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            questionLabel?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            choices?: Array<{
                __typename?: 'UserProfileDefChoice';
                specifyValue: boolean;
                id: any;
                name?: string | null;
                answerLabel?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
                value?: {
                    __typename?: 'UserProfileDefChoiceValue';
                    bool?: boolean | null;
                    date?: any | null;
                    num?: number | null;
                    str?: string | null;
                } | null;
            } | null> | null;
        } | null;
    } | null;
};

export type PreFetchAppFullNamesQueryVariables = Exact<{ [key: string]: never }>;

export type PreFetchAppFullNamesQuery = {
    __typename?: 'Query';
    amsApps: Array<{ __typename?: 'AMSApp'; id: string; bundleId: string; fullName: string }>;
};

export type JiraInfoByUrlQueryVariables = Exact<{
    input: JiraInfoInput;
}>;

export type JiraInfoByUrlQuery = {
    __typename?: 'Query';
    jiraInfoByUrl: {
        __typename?: 'JiraInfo';
        dateRequested: string;
        requestStatus: string;
        requestor: string;
        assignee?: string | null;
        dateExpected?: string | null;
        dateCompleted?: string | null;
    };
};

export type ConditionTypeListQueryVariables = Exact<{ [key: string]: never }>;

export type ConditionTypeListQuery = {
    __typename?: 'Query';
    conditionTypes: Array<{
        __typename?: 'ConditionType';
        id: any;
        name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    }>;
};

export type ProfileValueOriginListQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileValueOriginListQuery = {
    __typename?: 'Query';
    profileValueOrigins: Array<{
        __typename?: 'ProfileValueOrigin';
        id: any;
        label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    }>;
};

export type FetchProfileDefsForProfileDefsPageQeryQueryVariables = Exact<{ [key: string]: never }>;

export type FetchProfileDefsForProfileDefsPageQeryQuery = {
    __typename?: 'Query';
    userProfileDefs: Array<{
        __typename?: 'UserProfileDef';
        id: any;
        name: string;
        description?: string | null;
        category: string;
        valueType: UserProfileValueType;
        choiceValueType?: UserProfileValueType | null;
        surveyQuestionDisabled?: boolean | null;
        systemGenerated?: boolean | null;
        conditionTypeId?: any | null;
        episodeRequired?: boolean | null;
        reportingNotes?: string | null;
        haPermission?: HaPermission | null;
        inboundNotes?: string | null;
        isClientSpecific?: boolean | null;
        linkToJIRA?: string | null;
        purposeAppPersonalization?: boolean | null;
        purposeHumanServices?: boolean | null;
        clientSpecificDisplayName?: string | null;
        dataSourceNotes?: string | null;
        purposeDetails?: string | null;
        dwLocation?: string | null;
        dwObject?: string | null;
        dwMetrics?: string | null;
        dwInternalReports?: string | null;
        dwExternalReports?: string | null;
        claimsField?: string | null;
        icd10DiagnosisCodes?: string | null;
        procedureCodes?: string | null;
        label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        questionLabel?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        choices?: Array<{
            __typename?: 'UserProfileDefChoice';
            specifyValue: boolean;
            id: any;
            name?: string | null;
            answerLabel?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            value?: {
                __typename?: 'UserProfileDefChoiceValue';
                bool?: boolean | null;
                date?: any | null;
                num?: number | null;
                str?: string | null;
            } | null;
        } | null> | null;
    }>;
};

export type ProfileDefWhereUsedQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type ProfileDefWhereUsedQuery = {
    __typename?: 'Query';
    profileDefWhereUsed?: {
        __typename?: 'EntityWhereUsedResponse';
        dependencies?: Array<{
            __typename?: 'DependentEntity';
            entityType: WhereUsedEntity;
            blocksDelete?: boolean | null;
            total?: number | null;
            entries?: Array<{
                __typename?: 'DependentEntityEntry';
                id: string;
                name: string;
            } | null> | null;
        }> | null;
    } | null;
};

export type ImportUserProfileDefsMutationVariables = Exact<{
    input: CreateManyUserProfileDefsInput;
}>;

export type ImportUserProfileDefsMutation = {
    __typename?: 'Mutation';
    createManyUserProfileDefs?: {
        __typename?: 'CreateManyUserProfileDefsResponse';
        success: boolean;
        message: string;
        resourcesCreated: Array<{
            __typename?: 'CreateManyIndividualUserProfileDefResponse';
            status: number;
            message: string;
            success: boolean;
            resourceCreated?: {
                __typename?: 'UserProfileDef';
                id: any;
                name: string;
                description?: string | null;
                category: string;
                valueType: UserProfileValueType;
                choiceValueType?: UserProfileValueType | null;
                systemGenerated?: boolean | null;
                surveyQuestionDisabled?: boolean | null;
                conditionTypeId?: any | null;
                episodeRequired?: boolean | null;
                reportingNotes?: string | null;
                inboundNotes?: string | null;
                haPermission?: HaPermission | null;
                questionLabel?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
                choices?: Array<{
                    __typename?: 'UserProfileDefChoice';
                    id: any;
                    specifyValue: boolean;
                    name?: string | null;
                    label?: {
                        __typename?: 'LocalizedString';
                        en: string;
                        es?: string | null;
                    } | null;
                    answerLabel?: {
                        __typename?: 'LocalizedStringLoose';
                        en?: string | null;
                        es?: string | null;
                    } | null;
                    value?: {
                        __typename?: 'UserProfileDefChoiceValue';
                        str?: string | null;
                        num?: number | null;
                        date?: any | null;
                        bool?: boolean | null;
                    } | null;
                } | null> | null;
            } | null;
        }>;
    } | null;
};

export type FetchUserProfileDefHistoryByIdQueryVariables = Exact<{
    input: GetUserProfileDefInput;
}>;

export type FetchUserProfileDefHistoryByIdQuery = {
    __typename?: 'Query';
    userProfileDef?: {
        __typename?: 'UserProfileDef';
        id: any;
        name: string;
        history?: Array<{
            __typename?: 'UserProfileDefHistoryItem';
            username: string;
            type: string;
            name: string;
            beforeState?: string | null;
            afterState?: string | null;
            datetime: any;
        } | null> | null;
    } | null;
};

export type ProfileValueOriginByIdQueryVariables = Exact<{
    input: GetProfileValueOriginInput;
}>;

export type ProfileValueOriginByIdQuery = {
    __typename?: 'Query';
    profileValueOrigin?: {
        __typename?: 'ProfileValueOrigin';
        id: any;
        label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    } | null;
};

export type CreateProfileValueOriginMutationVariables = Exact<{
    input: ProfileValueOriginInput;
}>;

export type CreateProfileValueOriginMutation = {
    __typename?: 'Mutation';
    createProfileValueOrigin?: {
        __typename?: 'CreateProfileValueOriginResponse';
        status: number;
        message: string;
        success: boolean;
        resourceCreated?: {
            __typename?: 'ProfileValueOrigin';
            id: any;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        } | null;
    } | null;
};

export type ProfileValueOriginPageListQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileValueOriginPageListQuery = {
    __typename?: 'Query';
    profileValueOrigins: Array<{
        __typename?: 'ProfileValueOrigin';
        id: any;
        label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    }>;
};

export type UpdateProfileValueOriginMutationVariables = Exact<{
    updateProfileValueOriginInput: UpdateProfileValueOriginInput;
}>;

export type UpdateProfileValueOriginMutation = {
    __typename?: 'Mutation';
    updateProfileValueOrigin?: {
        __typename?: 'UpdateProfileValueOriginResponse';
        status: number;
        message: string;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'ProfileValueOrigin';
            id: any;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        } | null;
    } | null;
};

export type ReferenceLinkCategoriesListQueryVariables = Exact<{ [key: string]: never }>;

export type ReferenceLinkCategoriesListQuery = {
    __typename?: 'Query';
    referenceLinkCategorysV2: {
        __typename?: 'ReferenceLinkCategoryPaginationQueryResponse';
        results: Array<{ __typename?: 'ReferenceLinkCategory'; id: any; name: string }>;
    };
};

export type FetchReferenceLinkCategoryQueryVariables = Exact<{
    input: GetReferenceLinkCategoryInput;
}>;

export type FetchReferenceLinkCategoryQuery = {
    __typename?: 'Query';
    referenceLinkCategory?: { __typename?: 'ReferenceLinkCategory'; id: any; name: string } | null;
};

export type CreateReferenceLinkCategoryMutationVariables = Exact<{
    input: ReferenceLinkCategoryInput;
}>;

export type CreateReferenceLinkCategoryMutation = {
    __typename?: 'Mutation';
    createReferenceLinkCategory?: {
        __typename?: 'CreateReferenceLinkCategoryResponse';
        success: boolean;
        message: string;
        resourceCreated?: { __typename?: 'ReferenceLinkCategory'; id: any; name: string } | null;
    } | null;
};

export type UpdateReferenceLinkCategoryMutationVariables = Exact<{
    input: UpdateReferenceLinkCategoryInput;
}>;

export type UpdateReferenceLinkCategoryMutation = {
    __typename?: 'Mutation';
    updateReferenceLinkCategory?: {
        __typename?: 'UpdateReferenceLinkCategoryResponse';
        success: boolean;
        message: string;
        resourceUpdated?: { __typename?: 'ReferenceLinkCategory'; id: any; name: string } | null;
    } | null;
};

export type DeleteReferenceLinkCategoryMutationVariables = Exact<{
    input: DeleteReferenceLinkCategoryInput;
}>;

export type DeleteReferenceLinkCategoryMutation = {
    __typename?: 'Mutation';
    deleteReferenceLinkCategory?: {
        __typename?: 'DeleteReferenceLinkCategoryResponse';
        message: string;
        status: number;
        success: boolean;
    } | null;
};

export type ReferenceLinksListQueryVariables = Exact<{ [key: string]: never }>;

export type ReferenceLinksListQuery = {
    __typename?: 'Query';
    referenceLinksV2: {
        __typename?: 'ReferenceLinkPaginationQueryResponse';
        results: Array<{
            __typename?: 'ReferenceLink';
            id: any;
            organizationId?: Array<any | null> | null;
            categoryId?: any | null;
            label: string;
            url: string;
        }>;
    };
};

export type FetchReferenceLinkQueryVariables = Exact<{
    input: GetReferenceLinkInput;
}>;

export type FetchReferenceLinkQuery = {
    __typename?: 'Query';
    referenceLink?: {
        __typename?: 'ReferenceLink';
        id: any;
        categoryId?: any | null;
        label: string;
        organizationId?: Array<any | null> | null;
        pages?: Array<ReferencePages | null> | null;
        roleId?: Array<any | null> | null;
        sortField?: number | null;
        url: string;
    } | null;
};

export type CreateReferenceLinkMutationVariables = Exact<{
    input: ReferenceLinkInput;
}>;

export type CreateReferenceLinkMutation = {
    __typename?: 'Mutation';
    createReferenceLink?: {
        __typename?: 'CreateReferenceLinkResponse';
        message: string;
        status: number;
        success: boolean;
        resourceCreated?: {
            __typename?: 'ReferenceLink';
            id: any;
            categoryId?: any | null;
            label: string;
            organizationId?: Array<any | null> | null;
            pages?: Array<ReferencePages | null> | null;
            roleId?: Array<any | null> | null;
            sortField?: number | null;
            url: string;
        } | null;
    } | null;
};

export type UpdateReferenceLinkMutationVariables = Exact<{
    input: UpdateReferenceLinkInput;
}>;

export type UpdateReferenceLinkMutation = {
    __typename?: 'Mutation';
    updateReferenceLink?: {
        __typename?: 'UpdateReferenceLinkResponse';
        message: string;
        status: number;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'ReferenceLink';
            id: any;
            categoryId?: any | null;
            label: string;
            organizationId?: Array<any | null> | null;
            pages?: Array<ReferencePages | null> | null;
            roleId?: Array<any | null> | null;
            sortField?: number | null;
            url: string;
        } | null;
    } | null;
};

export type DeleteReferenceLinkMutationVariables = Exact<{
    input: DeleteReferenceLinkInput;
}>;

export type DeleteReferenceLinkMutation = {
    __typename?: 'Mutation';
    deleteReferenceLink?: {
        __typename?: 'DeleteReferenceLinkResponse';
        message: string;
        status: number;
        success: boolean;
    } | null;
};

export type FetchOrgListForReferenceLinkPageQueryVariables = Exact<{ [key: string]: never }>;

export type FetchOrgListForReferenceLinkPageQuery = {
    __typename?: 'Query';
    organizations: Array<{ __typename?: 'Organization'; id: any; name: string }>;
};

export type FetchRolesForReferenceLinkPageQueryVariables = Exact<{ [key: string]: never }>;

export type FetchRolesForReferenceLinkPageQuery = {
    __typename?: 'Query';
    roles: Array<{ __typename?: 'Role'; id: any; name: string; organizationId?: any | null }>;
};

export type FetchReferenceLinkCategoriesForReferenceLinkPageQueryVariables = Exact<{
    [key: string]: never;
}>;

export type FetchReferenceLinkCategoriesForReferenceLinkPageQuery = {
    __typename?: 'Query';
    referenceLinkCategorys: Array<{ __typename?: 'ReferenceLinkCategory'; id: any; name: string }>;
};

export type ReportingCategoryByIdQueryVariables = Exact<{
    input: GetReportingCategoryInput;
}>;

export type ReportingCategoryByIdQuery = {
    __typename?: 'Query';
    reportingCategory?: { __typename?: 'ReportingCategory'; id: any; name: string } | null;
};

export type CreateReportingCategoryMutationVariables = Exact<{
    input: ReportingCategoryInput;
}>;

export type CreateReportingCategoryMutation = {
    __typename?: 'Mutation';
    createReportingCategory?: {
        __typename?: 'CreateReportingCategoryResponse';
        status: number;
        message: string;
        success: boolean;
        resourceCreated?: { __typename?: 'ReportingCategory'; id: any; name: string } | null;
    } | null;
};

export type ReportingCategoryListQueryVariables = Exact<{ [key: string]: never }>;

export type ReportingCategoryListQuery = {
    __typename?: 'Query';
    reportingCategorys: Array<{ __typename?: 'ReportingCategory'; id: any; name: string }>;
};

export type UpdateReportingCategoryMutationVariables = Exact<{
    updateReportingCategoryInput: UpdateReportingCategoryInput;
}>;

export type UpdateReportingCategoryMutation = {
    __typename?: 'Mutation';
    updateReportingCategory?: {
        __typename?: 'UpdateReportingCategoryResponse';
        status: number;
        message: string;
        success: boolean;
        resourceUpdated?: { __typename?: 'ReportingCategory'; id: any; name: string } | null;
    } | null;
};

export type DeleteReportingCategoryMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteReportingCategoryMutation = {
    __typename?: 'Mutation';
    deleteReportingCategory?: {
        __typename?: 'DeleteReportingCategoryMutationResponse';
        success: boolean;
        message: string;
        status: number;
        id: string;
    } | null;
};

export type ReportingCategoryWhereUsedQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type ReportingCategoryWhereUsedQuery = {
    __typename?: 'Query';
    reportingCategoryWhereUsed?: {
        __typename?: 'EntityWhereUsedResponse';
        dependencies?: Array<{
            __typename?: 'DependentEntity';
            entityType: WhereUsedEntity;
            blocksDelete?: boolean | null;
            total?: number | null;
            entries?: Array<{
                __typename?: 'DependentEntityEntry';
                id: string;
                name: string;
            } | null> | null;
        }> | null;
    } | null;
};

export type FetchRoleByIdForRoleModalQueryVariables = Exact<{
    input: GetRoleInput;
}>;

export type FetchRoleByIdForRoleModalQuery = {
    __typename?: 'Query';
    role?: {
        __typename?: 'Role';
        id: any;
        name: string;
        organizationId?: any | null;
        createdAt: any;
        updatedAt: any;
        permissions?: Array<{
            __typename?: 'Permissions';
            target?: Target | null;
            permission?: Permission | null;
            canExport?: boolean | null;
        } | null> | null;
    } | null;
};

export type CreateRoleForRoleModalMutationVariables = Exact<{
    input: RoleInput;
}>;

export type CreateRoleForRoleModalMutation = {
    __typename?: 'Mutation';
    createRole?: {
        __typename?: 'CreateRoleResponse';
        message: string;
        success: boolean;
        resourceCreated?: {
            __typename?: 'Role';
            id: any;
            name: string;
            organizationId?: any | null;
            createdAt: any;
            updatedAt: any;
            permissions?: Array<{
                __typename?: 'Permissions';
                target?: Target | null;
                permission?: Permission | null;
                canExport?: boolean | null;
            } | null> | null;
        } | null;
    } | null;
};

export type UpdateRoleForRoleModalMutationVariables = Exact<{
    input: UpdateRoleInput;
}>;

export type UpdateRoleForRoleModalMutation = {
    __typename?: 'Mutation';
    updateRole?: {
        __typename?: 'UpdateRoleResponse';
        message: string;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'Role';
            id: any;
            name: string;
            organizationId?: any | null;
            createdAt: any;
            updatedAt: any;
            permissions?: Array<{
                __typename?: 'Permissions';
                target?: Target | null;
                permission?: Permission | null;
                canExport?: boolean | null;
            } | null> | null;
        } | null;
    } | null;
};

export type FetchRolesForRolesPageQueryQueryVariables = Exact<{ [key: string]: never }>;

export type FetchRolesForRolesPageQueryQuery = {
    __typename?: 'Query';
    roles: Array<{
        __typename?: 'Role';
        id: any;
        name: string;
        organizationId?: any | null;
        createdAt: any;
        updatedAt: any;
        permissions?: Array<{
            __typename?: 'Permissions';
            target?: Target | null;
            permission?: Permission | null;
            canExport?: boolean | null;
        } | null> | null;
    }>;
};

export type OrgListQueryVariables = Exact<{ [key: string]: never }>;

export type OrgListQuery = {
    __typename?: 'Query';
    organizations: Array<{
        __typename?: 'Organization';
        id: any;
        parentId?: any | null;
        brandingName: string;
        ancestors?: Array<any | null> | null;
        appBundleId?: string | null;
        logo?: string | null;
    }>;
};

export type RoleWhereUsedQueryVariables = Exact<{
    roleId: Scalars['String'];
}>;

export type RoleWhereUsedQuery = {
    __typename?: 'Query';
    roleWhereUsed: {
        __typename?: 'EntityWhereUsedResponse';
        dependencies?: Array<{
            __typename?: 'DependentEntity';
            entityType: WhereUsedEntity;
            blocksDelete?: boolean | null;
            total?: number | null;
            entries?: Array<{
                __typename?: 'DependentEntityEntry';
                id: string;
                name: string;
            } | null> | null;
        }> | null;
    };
};

export type DeleteRoleMutationVariables = Exact<{
    input: Scalars['String'];
}>;

export type DeleteRoleMutation = {
    __typename?: 'Mutation';
    deleteRole?: {
        __typename?: 'DeleteRoleMutationResponse';
        success: boolean;
        message: string;
    } | null;
};

export type CreateSlaForSlaModalMutationVariables = Exact<{
    input: SlaInput;
}>;

export type CreateSlaForSlaModalMutation = {
    __typename?: 'Mutation';
    createSla?: {
        __typename?: 'CreateSlaResponse';
        success: boolean;
        status: number;
        message: string;
        resourceCreated?: {
            __typename?: 'Sla';
            id: any;
            name: string;
            notifyBefore?: Array<number> | null;
            responseCalculation: ResponseCalculationEnum;
            timeToComplete: number;
            timerType: SlaTimerTypeEnum;
            type: SlaTypeEnum;
            updatedAt: any;
            createdAt: any;
            calendarId: any;
            calendar?: { __typename?: 'Calendar'; id: any; name: string } | null;
        } | null;
    } | null;
};

export type UpdateSlaForSlaModalMutationVariables = Exact<{
    input: UpdateSlaInput;
}>;

export type UpdateSlaForSlaModalMutation = {
    __typename?: 'Mutation';
    updateSla?: {
        __typename?: 'UpdateSlaResponse';
        success: boolean;
        status: number;
        message: string;
        resourceUpdated?: {
            __typename?: 'Sla';
            id: any;
            name: string;
            notifyBefore?: Array<number> | null;
            responseCalculation: ResponseCalculationEnum;
            timeToComplete: number;
            timerType: SlaTimerTypeEnum;
            type: SlaTypeEnum;
            updatedAt: any;
            createdAt: any;
            calendarId: any;
            calendar?: { __typename?: 'Calendar'; id: any; name: string } | null;
        } | null;
    } | null;
};

export type CalendarsV2ForSlaModalQueryVariables = Exact<{
    input?: InputMaybe<CalendarQueryInput>;
}>;

export type CalendarsV2ForSlaModalQuery = {
    __typename?: 'Query';
    calendarsV2: {
        __typename?: 'CalendarPaginationQueryResponse';
        results: Array<{ __typename?: 'Calendar'; id: any; name: string }>;
    };
};

export type SlasV2ForSlAsPageQueryVariables = Exact<{
    input?: InputMaybe<SlaQueryInput>;
}>;

export type SlasV2ForSlAsPageQuery = {
    __typename?: 'Query';
    slasV2: {
        __typename?: 'SlaPaginationQueryResponse';
        results: Array<{
            __typename?: 'Sla';
            id: any;
            name: string;
            calendarId: any;
            responseCalculation: ResponseCalculationEnum;
            timeToComplete: number;
            timerType: SlaTimerTypeEnum;
            type: SlaTypeEnum;
            notifyBefore?: Array<number> | null;
            createdAt: any;
            updatedAt: any;
            calendar?: { __typename?: 'Calendar'; id: any; name: string } | null;
        }>;
    };
};

export type SimpleContentFragFragment = {
    __typename?: 'SimpleContent';
    id: any;
    name: string;
    content?: string | null;
    contentType: ContentType;
    contentTypeName?: string | null;
    icon?: ContentIcon | null;
    trackerType?: TrackerType | null;
    articleId?: any | null;
    surveyId?: any | null;
    contentDirectoryId?: any | null;
    label?: { __typename?: 'LocalizedStringLoose'; en?: string | null; es?: string | null } | null;
    description?: {
        __typename?: 'LocalizedStringLoose';
        en?: string | null;
        es?: string | null;
    } | null;
};

export type SimpleContentByIdQueryVariables = Exact<{
    input: GetSimpleContentInput;
}>;

export type SimpleContentByIdQuery = {
    __typename?: 'Query';
    simpleContent?: {
        __typename?: 'SimpleContent';
        id: any;
        name: string;
        content?: string | null;
        contentType: ContentType;
        contentTypeName?: string | null;
        icon?: ContentIcon | null;
        trackerType?: TrackerType | null;
        articleId?: any | null;
        surveyId?: any | null;
        contentDirectoryId?: any | null;
        label?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        description?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
    } | null;
};

export type CreateSimpleContentMutationVariables = Exact<{
    input: SimpleContentInput;
}>;

export type CreateSimpleContentMutation = {
    __typename?: 'Mutation';
    createSimpleContent?: {
        __typename?: 'CreateSimpleContentResponse';
        status: number;
        message: string;
        success: boolean;
        resourceCreated?: {
            __typename?: 'SimpleContent';
            id: any;
            name: string;
            content?: string | null;
            contentType: ContentType;
            contentTypeName?: string | null;
            icon?: ContentIcon | null;
            trackerType?: TrackerType | null;
            articleId?: any | null;
            surveyId?: any | null;
            contentDirectoryId?: any | null;
            label?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            description?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
        } | null;
    } | null;
};

export type SimpleContentListQueryVariables = Exact<{
    input: SimpleContentListInput;
}>;

export type SimpleContentListQuery = {
    __typename?: 'Query';
    simpleContentsV2: {
        __typename?: 'SimpleContentListResponse';
        total: number;
        results: Array<{
            __typename?: 'SimpleContent';
            id: any;
            name: string;
            content?: string | null;
            contentType: ContentType;
            contentTypeName?: string | null;
            icon?: ContentIcon | null;
            trackerType?: TrackerType | null;
            articleId?: any | null;
            surveyId?: any | null;
            contentDirectoryId?: any | null;
            label?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            description?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
        }>;
    };
};

export type UpdateSimpleContentMutationVariables = Exact<{
    updateSimpleContentInput: UpdateSimpleContentInput;
}>;

export type UpdateSimpleContentMutation = {
    __typename?: 'Mutation';
    updateSimpleContent?: {
        __typename?: 'UpdateSimpleContentResponse';
        status: number;
        message: string;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'SimpleContent';
            id: any;
            name: string;
            content?: string | null;
            contentType: ContentType;
            contentTypeName?: string | null;
            icon?: ContentIcon | null;
            trackerType?: TrackerType | null;
            articleId?: any | null;
            surveyId?: any | null;
            contentDirectoryId?: any | null;
            label?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            description?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
        } | null;
    } | null;
};

export type DeleteSimpleContentMutationVariables = Exact<{
    input: ContentDeleteInput;
}>;

export type DeleteSimpleContentMutation = {
    __typename?: 'Mutation';
    deleteSimpleContent?: {
        __typename?: 'DeleteSimpleContentMutationResponse';
        status: number;
        message: string;
        success: boolean;
        id: string;
    } | null;
};

export type SimpleContentWhereUsedQueryVariables = Exact<{
    input: ContentWhereUsedFetchInput;
}>;

export type SimpleContentWhereUsedQuery = {
    __typename?: 'Query';
    simpleContentWhereUsed?: {
        __typename?: 'EntityWhereUsedResponse';
        dependencies?: Array<{
            __typename?: 'DependentEntity';
            entityType: WhereUsedEntity;
            blocksDelete?: boolean | null;
            total?: number | null;
            entries?: Array<{
                __typename?: 'DependentEntityEntry';
                id: string;
                name: string;
            } | null> | null;
        }> | null;
    } | null;
};

export type SelectListsQueryVariables = Exact<{ [key: string]: never }>;

export type SelectListsQuery = {
    __typename?: 'Query';
    contentDirectorys: Array<{ __typename?: 'ContentDirectory'; id: any; name: string }>;
    surveyDefs: Array<{ __typename?: 'SurveyDef'; id: any; name?: string | null }>;
};

export type FetchUsersForStaffPageQueryQueryVariables = Exact<{ [key: string]: never }>;

export type FetchUsersForStaffPageQueryQuery = {
    __typename?: 'Query';
    currentUser?: {
        __typename?: 'User';
        id: any;
        name: string;
        email: string;
        users: Array<{
            __typename?: 'User';
            id: any;
            name: string;
            email: string;
            photo?: string | null;
            clientOrgId?: any | null;
            smsNumber?: string | null;
            title?: string | null;
            isStaff?: boolean | null;
            isTestData?: boolean | null;
            roleId?: any | null;
            lastLogin?: any | null;
            isActive?: boolean | null;
            rootOrg: { __typename?: 'Organization'; id: any; name: string; brandingName: string };
            role?: {
                __typename?: 'Role';
                id: any;
                name: string;
                permissions?: Array<{
                    __typename?: 'Permissions';
                    target?: Target | null;
                    permission?: Permission | null;
                    canExport?: boolean | null;
                } | null> | null;
            } | null;
        }>;
    } | null;
};

export type DeleteUserForStaffPageMutationVariables = Exact<{
    input: DeleteUserInput;
}>;

export type DeleteUserForStaffPageMutation = {
    __typename?: 'Mutation';
    deleteUser?: {
        __typename?: 'DeleteUserResponse';
        message: string;
        success: boolean;
        id?: any | null;
    } | null;
};

export type FetchStaffByIdForStaffModalQueryVariables = Exact<{
    input: GetUserInput;
}>;

export type FetchStaffByIdForStaffModalQuery = {
    __typename?: 'Query';
    user?: {
        __typename?: 'User';
        affiliateId?: any | null;
        id: any;
        name: string;
        email: string;
        photo?: string | null;
        clientOrgId?: any | null;
        smsNumber?: string | null;
        title?: string | null;
        isStaff?: boolean | null;
        isTestData?: boolean | null;
        roleId?: any | null;
        lastLogin?: any | null;
        isActive?: boolean | null;
        rootOrg: { __typename?: 'Organization'; id: any; name: string; brandingName: string };
        role?: {
            __typename?: 'Role';
            id: any;
            name: string;
            permissions?: Array<{
                __typename?: 'Permissions';
                target?: Target | null;
                permission?: Permission | null;
                canExport?: boolean | null;
            } | null> | null;
        } | null;
    } | null;
};

export type FetchCurrentUserForStaffModalQueryVariables = Exact<{ [key: string]: never }>;

export type FetchCurrentUserForStaffModalQuery = {
    __typename?: 'Query';
    currentUser?: {
        __typename?: 'User';
        id: any;
        name: string;
        email: string;
        title?: string | null;
        smsNumber?: string | null;
        clientOrgId?: any | null;
        numberOfUnreadChatMessages?: number | null;
        isTestData?: boolean | null;
        currentOrg: {
            __typename?: 'Organization';
            id: any;
            parentId?: any | null;
            brandingName: string;
        };
        currentOrganizations: Array<{
            __typename?: 'Organization';
            id: any;
            parentId?: any | null;
            brandingName: string;
        }>;
    } | null;
};

export type FetchRolesForStaffModalQueryQueryVariables = Exact<{ [key: string]: never }>;

export type FetchRolesForStaffModalQueryQuery = {
    __typename?: 'Query';
    roles: Array<{ __typename?: 'Role'; id: any; organizationId?: any | null; name: string }>;
};

export type UpdateStaffInStaffModalMutationVariables = Exact<{
    input: UpdateUserInput;
}>;

export type UpdateStaffInStaffModalMutation = {
    __typename?: 'Mutation';
    updateUser?: {
        __typename?: 'UpdateUserResponse';
        success: boolean;
        message: string;
        resourceUpdated?: {
            __typename?: 'User';
            id: any;
            name: string;
            email: string;
            photo?: string | null;
            clientOrgId?: any | null;
            smsNumber?: string | null;
            title?: string | null;
            isStaff?: boolean | null;
            isTestData?: boolean | null;
            roleId?: any | null;
            lastLogin?: any | null;
            isActive?: boolean | null;
            rootOrg: { __typename?: 'Organization'; id: any; name: string; brandingName: string };
            role?: {
                __typename?: 'Role';
                id: any;
                name: string;
                permissions?: Array<{
                    __typename?: 'Permissions';
                    target?: Target | null;
                    permission?: Permission | null;
                    canExport?: boolean | null;
                } | null> | null;
            } | null;
        } | null;
    } | null;
};

export type CreateStaffInStaffModalMutationVariables = Exact<{
    input: UserInput;
}>;

export type CreateStaffInStaffModalMutation = {
    __typename?: 'Mutation';
    createUser?: {
        __typename?: 'CreateUserResponse';
        success: boolean;
        message: string;
        user?: {
            __typename?: 'User';
            id: any;
            name: string;
            email: string;
            photo?: string | null;
            clientOrgId?: any | null;
            smsNumber?: string | null;
            title?: string | null;
            isStaff?: boolean | null;
            isTestData?: boolean | null;
            roleId?: any | null;
            lastLogin?: any | null;
            isActive?: boolean | null;
            rootOrg: { __typename?: 'Organization'; id: any; name: string; brandingName: string };
            role?: {
                __typename?: 'Role';
                id: any;
                name: string;
                permissions?: Array<{
                    __typename?: 'Permissions';
                    target?: Target | null;
                    permission?: Permission | null;
                    canExport?: boolean | null;
                } | null> | null;
            } | null;
        } | null;
    } | null;
};

export type StaffVirtualCareAffiliatessV2QueryVariables = Exact<{
    input?: InputMaybe<VirtualCareAffiliatesQueryInput>;
}>;

export type StaffVirtualCareAffiliatessV2Query = {
    __typename?: 'Query';
    virtualCareAffiliatessV2: {
        __typename?: 'VirtualCareAffiliatesPaginationQueryResponse';
        total: number;
        results: Array<{
            __typename?: 'VirtualCareAffiliatesEntity';
            affiliateId?: string | null;
            externalName: string;
            id: any;
        }>;
    };
};

export type SurveyDefListQueryVariables = Exact<{ [key: string]: never }>;

export type SurveyDefListQuery = {
    __typename?: 'Query';
    surveyDefs: Array<{
        __typename?: 'SurveyDef';
        id: any;
        name?: string | null;
        createdAt: any;
        updatedAt: any;
        surveyType: string;
        disableRestart?: boolean | null;
        restartFromBeginning?: boolean | null;
        requiresAdvocateReview?: boolean | null;
        shouldScore?: boolean | null;
        label: { __typename?: 'LocalizedString'; en: string };
        divisions: Array<{ __typename?: 'SurveyDefDivision'; id: string }>;
        organization: { __typename?: 'Organization'; id: any; brandingName: string };
    }>;
};

export type FetchSurveyDefForSurveyBuilderQueryVariables = Exact<{
    surveyDefInput: GetSurveyDefInput;
}>;

export type FetchSurveyDefForSurveyBuilderQuery = {
    __typename?: 'Query';
    surveyDef?: {
        __typename?: 'SurveyDef';
        id: any;
        name?: string | null;
        surveyType: string;
        statusProfileDefId: any;
        startedAtProfileDefId: any;
        completedAtProfileDefId: any;
        requiresAdvocateReview?: boolean | null;
        disableRestart?: boolean | null;
        restartFromBeginning?: boolean | null;
        shouldScore?: boolean | null;
        organizationId: any;
        icon?: ContentIcon | null;
        imageContentId?: any | null;
        createdAt: any;
        updatedAt: any;
        summary?: boolean | null;
        label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        imageContent?: { __typename?: 'SimpleContent'; id: any; name: string } | null;
        summaryText: {
            __typename?: 'SurveyDefText';
            title: { __typename?: 'LocalizedString'; es?: string | null; en: string };
            body: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        };
        statusProfileDef: { __typename?: 'UserProfileDef'; id: any; name: string };
        startedAtProfileDef: { __typename?: 'UserProfileDef'; id: any; name: string };
        completedAtProfileDef: { __typename?: 'UserProfileDef'; id: any; name: string };
        organization: { __typename?: 'Organization'; id: any; brandingName: string };
        divisions: Array<{
            __typename?: 'SurveyDefDivision';
            id: string;
            conditionExpressionIds?: Array<any | null> | null;
            subSurveyDefId?: any | null;
            label: { __typename?: 'LocalizedString'; es?: string | null; en: string };
            sections: Array<{
                __typename?: 'SurveyDefSection';
                id: string;
                conditionExpressionIds?: Array<any | null> | null;
                label: { __typename?: 'LocalizedString'; es?: string | null; en: string };
                pieces: Array<{
                    __typename?: 'SurveyDefPiece';
                    id: string;
                    conditionExpressionIds?: Array<any | null> | null;
                    type: SurveyDefPieceType;
                    content?: {
                        __typename?: 'SurveyDefContent';
                        articleId?: any | null;
                        articleName?: string | null;
                    } | null;
                    text?: {
                        __typename?: 'SurveyDefText';
                        title: { __typename?: 'LocalizedString'; es?: string | null; en: string };
                        body: { __typename?: 'LocalizedString'; en: string; es?: string | null };
                    } | null;
                    question?: {
                        __typename?: 'SurveyDefQuestion';
                        displayType: SurveyDefQuestionDisplayType;
                        questionProfileDefId: any;
                        answerRequired?: boolean | null;
                        hidePreviousAnswer?: boolean | null;
                        labelOverride?: {
                            __typename?: 'LocalizedStringLoose';
                            en?: string | null;
                            es?: string | null;
                        } | null;
                        choiceOverrides?: Array<{
                            __typename?: 'SurveyDefChoiceOverride';
                            choiceId: any;
                            labelOverride?: {
                                __typename?: 'LocalizedString';
                                en: string;
                                es?: string | null;
                            } | null;
                        } | null> | null;
                    } | null;
                    surveyDefCompoundQuestion?: {
                        __typename?: 'SurveyDefCompoundQuestion';
                        compoundQuestionId: any;
                        displayType: SurveyDefQuestionDisplayType;
                        answerRequired?: boolean | null;
                        hidePreviousAnswer?: boolean | null;
                        label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
                    } | null;
                    tutorialContent?: {
                        __typename?: 'SurveyDefTutorialContent';
                        videoId?: any | null;
                        videoName?: string | null;
                    } | null;
                }>;
            }>;
            subSurveyDef?: { __typename?: 'SurveyDef'; id: any; name?: string | null } | null;
        }>;
        buttons: {
            __typename?: 'SurveyDefButtons';
            saveSurvey: {
                __typename?: 'SurveyDefButton';
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            };
            end: {
                __typename?: 'SurveyDefButton';
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            };
            next: {
                __typename?: 'SurveyDefButton';
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            };
            previous: {
                __typename?: 'SurveyDefButton';
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            };
        };
    } | null;
};

export type UpdateSurveyDefForSurveyBuilderMutationVariables = Exact<{
    surveyDefUpdateInput: UpdateSurveyDefInput;
}>;

export type UpdateSurveyDefForSurveyBuilderMutation = {
    __typename?: 'Mutation';
    updateSurveyDef?: {
        __typename?: 'UpdateSurveyDefResponse';
        success: boolean;
        message: string;
        resourceUpdated?: {
            __typename?: 'SurveyDef';
            id: any;
            name?: string | null;
            surveyType: string;
            statusProfileDefId: any;
            startedAtProfileDefId: any;
            completedAtProfileDefId: any;
            requiresAdvocateReview?: boolean | null;
            disableRestart?: boolean | null;
            restartFromBeginning?: boolean | null;
            shouldScore?: boolean | null;
            organizationId: any;
            icon?: ContentIcon | null;
            imageContentId?: any | null;
            createdAt: any;
            updatedAt: any;
            summary?: boolean | null;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            imageContent?: { __typename?: 'SimpleContent'; id: any; name: string } | null;
            summaryText: {
                __typename?: 'SurveyDefText';
                title: { __typename?: 'LocalizedString'; es?: string | null; en: string };
                body: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            };
            statusProfileDef: { __typename?: 'UserProfileDef'; id: any; name: string };
            startedAtProfileDef: { __typename?: 'UserProfileDef'; id: any; name: string };
            completedAtProfileDef: { __typename?: 'UserProfileDef'; id: any; name: string };
            organization: { __typename?: 'Organization'; id: any; brandingName: string };
            divisions: Array<{
                __typename?: 'SurveyDefDivision';
                id: string;
                conditionExpressionIds?: Array<any | null> | null;
                subSurveyDefId?: any | null;
                label: { __typename?: 'LocalizedString'; es?: string | null; en: string };
                sections: Array<{
                    __typename?: 'SurveyDefSection';
                    id: string;
                    conditionExpressionIds?: Array<any | null> | null;
                    label: { __typename?: 'LocalizedString'; es?: string | null; en: string };
                    pieces: Array<{
                        __typename?: 'SurveyDefPiece';
                        id: string;
                        conditionExpressionIds?: Array<any | null> | null;
                        type: SurveyDefPieceType;
                        content?: {
                            __typename?: 'SurveyDefContent';
                            articleId?: any | null;
                            articleName?: string | null;
                        } | null;
                        text?: {
                            __typename?: 'SurveyDefText';
                            title: {
                                __typename?: 'LocalizedString';
                                es?: string | null;
                                en: string;
                            };
                            body: {
                                __typename?: 'LocalizedString';
                                en: string;
                                es?: string | null;
                            };
                        } | null;
                        question?: {
                            __typename?: 'SurveyDefQuestion';
                            displayType: SurveyDefQuestionDisplayType;
                            questionProfileDefId: any;
                            answerRequired?: boolean | null;
                            hidePreviousAnswer?: boolean | null;
                            labelOverride?: {
                                __typename?: 'LocalizedStringLoose';
                                en?: string | null;
                                es?: string | null;
                            } | null;
                            choiceOverrides?: Array<{
                                __typename?: 'SurveyDefChoiceOverride';
                                choiceId: any;
                                labelOverride?: {
                                    __typename?: 'LocalizedString';
                                    en: string;
                                    es?: string | null;
                                } | null;
                            } | null> | null;
                        } | null;
                        surveyDefCompoundQuestion?: {
                            __typename?: 'SurveyDefCompoundQuestion';
                            compoundQuestionId: any;
                            displayType: SurveyDefQuestionDisplayType;
                            answerRequired?: boolean | null;
                            hidePreviousAnswer?: boolean | null;
                            label: {
                                __typename?: 'LocalizedString';
                                en: string;
                                es?: string | null;
                            };
                        } | null;
                        tutorialContent?: {
                            __typename?: 'SurveyDefTutorialContent';
                            videoId?: any | null;
                            videoName?: string | null;
                        } | null;
                    }>;
                }>;
                subSurveyDef?: { __typename?: 'SurveyDef'; id: any; name?: string | null } | null;
            }>;
            buttons: {
                __typename?: 'SurveyDefButtons';
                saveSurvey: {
                    __typename?: 'SurveyDefButton';
                    label?: {
                        __typename?: 'LocalizedString';
                        en: string;
                        es?: string | null;
                    } | null;
                };
                end: {
                    __typename?: 'SurveyDefButton';
                    label?: {
                        __typename?: 'LocalizedString';
                        en: string;
                        es?: string | null;
                    } | null;
                };
                next: {
                    __typename?: 'SurveyDefButton';
                    label?: {
                        __typename?: 'LocalizedString';
                        en: string;
                        es?: string | null;
                    } | null;
                };
                previous: {
                    __typename?: 'SurveyDefButton';
                    label?: {
                        __typename?: 'LocalizedString';
                        en: string;
                        es?: string | null;
                    } | null;
                };
            };
        } | null;
    } | null;
};

export type CreateSurveyDefForSurveyBuilderMutationVariables = Exact<{
    createSurveyDefInput: SurveyDefInput;
}>;

export type CreateSurveyDefForSurveyBuilderMutation = {
    __typename?: 'Mutation';
    createSurveyDef?: {
        __typename?: 'CreateSurveyDefResponse';
        success: boolean;
        message: string;
        resourceCreated?: {
            __typename?: 'SurveyDef';
            id: any;
            name?: string | null;
            surveyType: string;
            statusProfileDefId: any;
            startedAtProfileDefId: any;
            completedAtProfileDefId: any;
            requiresAdvocateReview?: boolean | null;
            disableRestart?: boolean | null;
            restartFromBeginning?: boolean | null;
            shouldScore?: boolean | null;
            organizationId: any;
            icon?: ContentIcon | null;
            imageContentId?: any | null;
            createdAt: any;
            updatedAt: any;
            summary?: boolean | null;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            imageContent?: { __typename?: 'SimpleContent'; id: any; name: string } | null;
            summaryText: {
                __typename?: 'SurveyDefText';
                title: { __typename?: 'LocalizedString'; es?: string | null; en: string };
                body: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            };
            statusProfileDef: { __typename?: 'UserProfileDef'; id: any; name: string };
            startedAtProfileDef: { __typename?: 'UserProfileDef'; id: any; name: string };
            completedAtProfileDef: { __typename?: 'UserProfileDef'; id: any; name: string };
            organization: { __typename?: 'Organization'; id: any; brandingName: string };
            divisions: Array<{
                __typename?: 'SurveyDefDivision';
                id: string;
                conditionExpressionIds?: Array<any | null> | null;
                subSurveyDefId?: any | null;
                label: { __typename?: 'LocalizedString'; es?: string | null; en: string };
                sections: Array<{
                    __typename?: 'SurveyDefSection';
                    id: string;
                    conditionExpressionIds?: Array<any | null> | null;
                    label: { __typename?: 'LocalizedString'; es?: string | null; en: string };
                    pieces: Array<{
                        __typename?: 'SurveyDefPiece';
                        id: string;
                        conditionExpressionIds?: Array<any | null> | null;
                        type: SurveyDefPieceType;
                        content?: {
                            __typename?: 'SurveyDefContent';
                            articleId?: any | null;
                            articleName?: string | null;
                        } | null;
                        text?: {
                            __typename?: 'SurveyDefText';
                            title: {
                                __typename?: 'LocalizedString';
                                es?: string | null;
                                en: string;
                            };
                            body: {
                                __typename?: 'LocalizedString';
                                en: string;
                                es?: string | null;
                            };
                        } | null;
                        question?: {
                            __typename?: 'SurveyDefQuestion';
                            displayType: SurveyDefQuestionDisplayType;
                            questionProfileDefId: any;
                            answerRequired?: boolean | null;
                            hidePreviousAnswer?: boolean | null;
                            labelOverride?: {
                                __typename?: 'LocalizedStringLoose';
                                en?: string | null;
                                es?: string | null;
                            } | null;
                            choiceOverrides?: Array<{
                                __typename?: 'SurveyDefChoiceOverride';
                                choiceId: any;
                                labelOverride?: {
                                    __typename?: 'LocalizedString';
                                    en: string;
                                    es?: string | null;
                                } | null;
                            } | null> | null;
                        } | null;
                        surveyDefCompoundQuestion?: {
                            __typename?: 'SurveyDefCompoundQuestion';
                            compoundQuestionId: any;
                            displayType: SurveyDefQuestionDisplayType;
                            answerRequired?: boolean | null;
                            hidePreviousAnswer?: boolean | null;
                            label: {
                                __typename?: 'LocalizedString';
                                en: string;
                                es?: string | null;
                            };
                        } | null;
                        tutorialContent?: {
                            __typename?: 'SurveyDefTutorialContent';
                            videoId?: any | null;
                            videoName?: string | null;
                        } | null;
                    }>;
                }>;
                subSurveyDef?: { __typename?: 'SurveyDef'; id: any; name?: string | null } | null;
            }>;
            buttons: {
                __typename?: 'SurveyDefButtons';
                saveSurvey: {
                    __typename?: 'SurveyDefButton';
                    label?: {
                        __typename?: 'LocalizedString';
                        en: string;
                        es?: string | null;
                    } | null;
                };
                end: {
                    __typename?: 'SurveyDefButton';
                    label?: {
                        __typename?: 'LocalizedString';
                        en: string;
                        es?: string | null;
                    } | null;
                };
                next: {
                    __typename?: 'SurveyDefButton';
                    label?: {
                        __typename?: 'LocalizedString';
                        en: string;
                        es?: string | null;
                    } | null;
                };
                previous: {
                    __typename?: 'SurveyDefButton';
                    label?: {
                        __typename?: 'LocalizedString';
                        en: string;
                        es?: string | null;
                    } | null;
                };
            };
        } | null;
    } | null;
};

export type PreFetchOrgsProfileDefsForSurveyBuilderQueryVariables = Exact<{ [key: string]: never }>;

export type PreFetchOrgsProfileDefsForSurveyBuilderQuery = {
    __typename?: 'Query';
    userProfileDefs: Array<{
        __typename?: 'UserProfileDef';
        id: any;
        name: string;
        valueType: UserProfileValueType;
        surveyQuestionDisabled?: boolean | null;
        label?: { __typename?: 'LocalizedString'; en: string } | null;
        questionLabel?: { __typename?: 'LocalizedStringLoose'; en?: string | null } | null;
        choices?: Array<{
            __typename?: 'UserProfileDefChoice';
            id: any;
            label?: { __typename?: 'LocalizedString'; en: string } | null;
            answerLabel?: { __typename?: 'LocalizedStringLoose'; en?: string | null } | null;
        } | null> | null;
    }>;
    organizations: Array<{ __typename?: 'Organization'; id: any; brandingName: string }>;
};

export type DeleteSurveyDefMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteSurveyDefMutation = {
    __typename?: 'Mutation';
    deleteSurveyDef?: {
        __typename?: 'DeleteSurveyDefMutationResponse';
        success: boolean;
        message: string;
        status: number;
        id: string;
    } | null;
};

export type SurveyDefWhereUsedQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type SurveyDefWhereUsedQuery = {
    __typename?: 'Query';
    surveyDefWhereUsed?: {
        __typename?: 'EntityWhereUsedResponse';
        dependencies?: Array<{
            __typename?: 'DependentEntity';
            entityType: WhereUsedEntity;
            blocksDelete?: boolean | null;
            total?: number | null;
            entries?: Array<{
                __typename?: 'DependentEntityEntry';
                id: string;
                name: string;
            } | null> | null;
        }> | null;
    } | null;
};

export type SurveyDefViewerQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type SurveyDefViewerQuery = {
    __typename?: 'Query';
    surveyDefViewer?: { __typename?: 'SurveyDefViewerResponse'; raw?: string | null } | null;
};

export type SurveyDefsWithoutSubSurveysQueryVariables = Exact<{ [key: string]: never }>;

export type SurveyDefsWithoutSubSurveysQuery = {
    __typename?: 'Query';
    surveyDefsWithoutSubSurveys: Array<{ __typename?: 'SurveyDef'; id: any; name?: string | null }>;
};

export type ParentSurveysDefsQueryVariables = Exact<{
    surveyId: Scalars['String'];
}>;

export type ParentSurveysDefsQuery = {
    __typename?: 'Query';
    parentSurveysDefs: Array<{ __typename?: 'SurveyDef'; id: any; name?: string | null }>;
};

export type CompoundQuestionsV2ForSurveyQueryVariables = Exact<{
    input?: InputMaybe<CompoundQuestionsListInput>;
}>;

export type CompoundQuestionsV2ForSurveyQuery = {
    __typename?: 'Query';
    compoundQuestionsV2: {
        __typename?: 'CompoundQuestionsResponse';
        results: Array<{
            __typename?: 'CompoundQuestion';
            id: any;
            profileValueType: UserProfileValueType;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            questionProfileDefs: Array<{
                __typename?: 'CompoundQuestionProfileDef';
                questionProfileDefId: any;
                questionProfileDef?: {
                    __typename?: 'UserProfileDef';
                    id: any;
                    name: string;
                    label?: {
                        __typename?: 'LocalizedString';
                        en: string;
                        es?: string | null;
                    } | null;
                } | null;
                labelOverride?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                value?: { __typename?: 'UserProfileDefChoiceValue'; bool?: boolean | null } | null;
            }>;
        }>;
    };
};

export type FetchTagQueryVariables = Exact<{
    tagInput: GetTagInput;
}>;

export type FetchTagQuery = {
    __typename?: 'Query';
    tag?: {
        __typename?: 'Tag';
        id: any;
        name: string;
        expressionId?: any | null;
        changedProfileDefIds?: Array<any> | null;
        includeApps?: Array<string> | null;
        excludeApps?: Array<string> | null;
        createdAt: any;
        updatedAt: any;
        appInfo?: string | null;
        usageType: TagUsageType;
        isPatientFilter?: boolean | null;
        patientRiskScorePoints?: number | null;
        expression?: { __typename?: 'ConfigExpression'; id: any; name: string } | null;
    } | null;
};

export type UpdateTagMutationVariables = Exact<{
    updateTagInput: UpdateTagInput;
}>;

export type UpdateTagMutation = {
    __typename?: 'Mutation';
    updateTag?: {
        __typename?: 'UpdateTagResponse';
        success: boolean;
        message: string;
        resourceUpdated?: {
            __typename?: 'Tag';
            id: any;
            name: string;
            expressionId?: any | null;
            changedProfileDefIds?: Array<any> | null;
            includeApps?: Array<string> | null;
            excludeApps?: Array<string> | null;
            createdAt: any;
            updatedAt: any;
            appInfo?: string | null;
            usageType: TagUsageType;
            isPatientFilter?: boolean | null;
            patientRiskScorePoints?: number | null;
            expression?: { __typename?: 'ConfigExpression'; id: any; name: string } | null;
        } | null;
    } | null;
};

export type CreateTagMutationVariables = Exact<{
    createTagInput: TagInput;
}>;

export type CreateTagMutation = {
    __typename?: 'Mutation';
    createTag?: {
        __typename?: 'CreateTagResponse';
        success: boolean;
        message: string;
        resourceCreated?: {
            __typename?: 'Tag';
            id: any;
            name: string;
            expressionId?: any | null;
            changedProfileDefIds?: Array<any> | null;
            includeApps?: Array<string> | null;
            excludeApps?: Array<string> | null;
            createdAt: any;
            updatedAt: any;
            appInfo?: string | null;
            usageType: TagUsageType;
            isPatientFilter?: boolean | null;
            patientRiskScorePoints?: number | null;
            expression?: { __typename?: 'ConfigExpression'; id: any; name: string } | null;
        } | null;
    } | null;
};

export type TagListQueryVariables = Exact<{
    tagListInput?: InputMaybe<Array<TagUsageType> | TagUsageType>;
}>;

export type TagListQuery = {
    __typename?: 'Query';
    getTagListByUsageTypes: Array<{
        __typename?: 'Tag';
        id: any;
        createdAt: any;
        updatedAt: any;
        name: string;
        usageType: TagUsageType;
        includeApps?: Array<string> | null;
        excludeApps?: Array<string> | null;
        appInfo?: string | null;
        isPatientFilter?: boolean | null;
        patientRiskScorePoints?: number | null;
        expression?: { __typename?: 'ConfigExpression'; id: any; name: string } | null;
    }>;
};

export type PreFetchAppsQueryVariables = Exact<{ [key: string]: never }>;

export type PreFetchAppsQuery = {
    __typename?: 'Query';
    amsApps: Array<{
        __typename?: 'AMSApp';
        id: string;
        bundleId: string;
        productConst?: AmsProduct | null;
    }>;
};

export type TagWhereUsedQueryVariables = Exact<{
    input: TagWhereUsedFetchInput;
}>;

export type TagWhereUsedQuery = {
    __typename?: 'Query';
    tagWhereUsed?: {
        __typename?: 'EntityWhereUsedResponse';
        dependencies?: Array<{
            __typename?: 'DependentEntity';
            entityType: WhereUsedEntity;
            blocksDelete?: boolean | null;
            total?: number | null;
            entries?: Array<{
                __typename?: 'DependentEntityEntry';
                id: string;
                name: string;
            } | null> | null;
        }> | null;
    } | null;
};

export type TimelineViewerFetchQueryVariables = Exact<{
    input: TimelineViewerFetchInput;
}>;

export type TimelineViewerFetchQuery = {
    __typename?: 'Query';
    timelineViewerFetch: {
        __typename?: 'TimelineViewerFetchResponse';
        total: number;
        results: Array<{
            __typename?: 'TimelineViewerFetchData';
            timelineId?: any | null;
            type?: TimelineViewerEntryType | null;
            itemId?: any | null;
            groupId?: any | null;
            templateId?: any | null;
            offsetDays?: number | null;
            offsetMonths?: number | null;
            offsetYears?: number | null;
            duration?: number | null;
            durationTimeUnit?: string | null;
            offsetSequence?: number | null;
            contentDescription?: string | null;
            includeApps?: Array<string | null> | null;
            excludeApps?: Array<string | null> | null;
            tags?: Array<string | null> | null;
            effectiveDate?: any | null;
            expiryDate?: any | null;
            priority?: number | null;
            notificationText?: string | null;
        }>;
    };
};

export type UpdateCarePlanOffsetsMutationVariables = Exact<{
    input: UpdateCarePlanOffsetsInput;
}>;

export type UpdateCarePlanOffsetsMutation = {
    __typename?: 'Mutation';
    updateCarePlanOffsets?: {
        __typename?: 'UpdateCarePlanResponse';
        success: boolean;
        message: string;
        resourceUpdated?: {
            __typename?: 'CarePlan';
            type: CarePlanType;
            baseCarePlanId?: any | null;
            timelineId?: any | null;
            includeApps?: Array<string> | null;
            excludeApps?: Array<string> | null;
            tags?: Array<string> | null;
            viewLimitMinutes?: number | null;
            createdAt: any;
            updatedAt: any;
            id: any;
            portalLabel?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            appLabel?: { __typename?: 'LocalizedString'; es?: string | null; en: string } | null;
            groups: Array<{
                __typename?: 'CarePlanTodoGroup';
                id: any;
                suppressedTemplates?: Array<any | null> | null;
                label?: { __typename?: 'LocalizedString'; es?: string | null; en: string } | null;
                description?: {
                    __typename?: 'LocalizedString';
                    en: string;
                    es?: string | null;
                } | null;
                templates: Array<{
                    __typename?: 'CarePlanTodoTemplate';
                    articleId: any;
                    id: any;
                    priority: number;
                    tags?: Array<string> | null;
                    type: CarePlanTodoTemplateClockType;
                    offsetDays?: number | null;
                    offsetMonths?: number | null;
                    offsetYears?: number | null;
                    targetDate?: any | null;
                    durationMinutes?: number | null;
                    isRepeating: boolean;
                    repeatIntervalMinutes?: number | null;
                    repeatCount?: number | null;
                    isEssential: boolean;
                    title?: {
                        __typename?: 'LocalizedString';
                        es?: string | null;
                        en: string;
                    } | null;
                    description?: {
                        __typename?: 'LocalizedString';
                        es?: string | null;
                        en: string;
                    } | null;
                    notificationData?: {
                        __typename?: 'NotificationData';
                        shouldSendNotification: boolean;
                        en?: string | null;
                        es?: string | null;
                    } | null;
                }>;
            }>;
            timeline?: {
                __typename?: 'WhatsNewTimeline';
                id: any;
                label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            } | null;
        } | null;
    } | null;
};

export type PreFetchProfileDefsForWhatsNewTimelineBuilderQueryVariables = Exact<{
    input: UserProfileDefQueryInput;
}>;

export type PreFetchProfileDefsForWhatsNewTimelineBuilderQuery = {
    __typename?: 'Query';
    userProfileDefsV2: {
        __typename?: 'UserProfileDefPaginationQueryResponse';
        results: Array<{
            __typename?: 'UserProfileDef';
            id: any;
            label?: { __typename?: 'LocalizedString'; en: string } | null;
        }>;
    };
};

export type FetchWhatsNewTimelineForWhatsNewTimelineBuilderQueryVariables = Exact<{
    whatsNewTimelineInput: GetWhatsNewTimelineInput;
}>;

export type FetchWhatsNewTimelineForWhatsNewTimelineBuilderQuery = {
    __typename?: 'Query';
    whatsNewTimeline?: {
        __typename?: 'WhatsNewTimeline';
        id: any;
        type: WhatsNewTimelineType;
        fixedTargetDate?: any | null;
        targetDateProfileDefId?: any | null;
        label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        description?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        targetDateProfileDef?: {
            __typename?: 'UserProfileDef';
            id: any;
            label?: { __typename?: 'LocalizedString'; en: string } | null;
        } | null;
    } | null;
};

export type UpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutationVariables = Exact<{
    whatsNewTimelineUpdateInput: UpdateWhatsNewTimelineInput;
}>;

export type UpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutation = {
    __typename?: 'Mutation';
    updateWhatsNewTimeline?: {
        __typename?: 'CreateOrUpdateWhatsNewTimelineResponse';
        success: boolean;
        message: string;
        timeline?: {
            __typename?: 'WhatsNewTimeline';
            id: any;
            type: WhatsNewTimelineType;
            fixedTargetDate?: any | null;
            targetDateProfileDefId?: any | null;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            description?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            targetDateProfileDef?: {
                __typename?: 'UserProfileDef';
                id: any;
                label?: { __typename?: 'LocalizedString'; en: string } | null;
            } | null;
        } | null;
    } | null;
};

export type CreateWhatsNewTimelineForWhatsNewTimelineBuilderMutationVariables = Exact<{
    createWhatsNewTimelineInput: WhatsNewTimelineInput;
}>;

export type CreateWhatsNewTimelineForWhatsNewTimelineBuilderMutation = {
    __typename?: 'Mutation';
    createWhatsNewTimeline?: {
        __typename?: 'CreateOrUpdateWhatsNewTimelineResponse';
        success: boolean;
        message: string;
        timeline?: {
            __typename?: 'WhatsNewTimeline';
            id: any;
            type: WhatsNewTimelineType;
            fixedTargetDate?: any | null;
            targetDateProfileDefId?: any | null;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            description?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            targetDateProfileDef?: {
                __typename?: 'UserProfileDef';
                id: any;
                label?: { __typename?: 'LocalizedString'; en: string } | null;
            } | null;
        } | null;
    } | null;
};

export type WhatsNewTimelinesForWhatsNewTimelinesPageQueryVariables = Exact<{
    [key: string]: never;
}>;

export type WhatsNewTimelinesForWhatsNewTimelinesPageQuery = {
    __typename?: 'Query';
    whatsNewTimelinesV2: {
        __typename?: 'WhatsNewTimelinePaginationQueryResponse';
        results: Array<{
            __typename?: 'WhatsNewTimeline';
            id: any;
            type: WhatsNewTimelineType;
            label: { __typename?: 'LocalizedString'; en: string };
        }>;
    };
};

export type DeleteTimelineMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteTimelineMutation = {
    __typename?: 'Mutation';
    deleteTimeline?: {
        __typename?: 'DeleteTimelineResponse';
        message: string;
        success: boolean;
        id?: string | null;
    } | null;
};

export type TimelineWhereUsedQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type TimelineWhereUsedQuery = {
    __typename?: 'Query';
    timelineWhereUsed?: {
        __typename?: 'EntityWhereUsedResponse';
        dependencies?: Array<{
            __typename?: 'DependentEntity';
            entityType: WhereUsedEntity;
            blocksDelete?: boolean | null;
            total?: number | null;
            entries?: Array<{
                __typename?: 'DependentEntityEntry';
                id: string;
                name: string;
            } | null> | null;
        }> | null;
    } | null;
};

export type TokenByIdQueryVariables = Exact<{
    input: GetTokenInput;
}>;

export type TokenByIdQuery = {
    __typename?: 'Query';
    token?: {
        __typename?: 'Token';
        id: any;
        name: string;
        description?: string | null;
        replacements: Array<any>;
    } | null;
};

export type CreateTokenMutationVariables = Exact<{
    input: TokenInput;
}>;

export type CreateTokenMutation = {
    __typename?: 'Mutation';
    createToken?: {
        __typename?: 'CreateTokenResponse';
        status: number;
        message: string;
        success: boolean;
        resourceCreated?: {
            __typename?: 'Token';
            id: any;
            name: string;
            description?: string | null;
            replacements: Array<any>;
        } | null;
    } | null;
};

export type TokenListQueryVariables = Exact<{ [key: string]: never }>;

export type TokenListQuery = {
    __typename?: 'Query';
    tokens: Array<{
        __typename?: 'Token';
        id: any;
        name: string;
        description?: string | null;
        replacements: Array<any>;
    }>;
};

export type UpdateTokenMutationVariables = Exact<{
    updateTokenInput: UpdateTokenInput;
}>;

export type UpdateTokenMutation = {
    __typename?: 'Mutation';
    updateToken?: {
        __typename?: 'UpdateTokenResponse';
        status: number;
        message: string;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'Token';
            id: any;
            name: string;
            description?: string | null;
            replacements: Array<any>;
        } | null;
    } | null;
};

export type DeleteTagMutationVariables = Exact<{
    input: DeleteTagInput;
}>;

export type DeleteTagMutation = {
    __typename?: 'Mutation';
    deleteTag?: {
        __typename?: 'DeleteTagMutationResponse';
        success: boolean;
        message: string;
        status: number;
        id: string;
    } | null;
};

export type PreFetchInterventionDataQueryVariables = Exact<{ [key: string]: never }>;

export type PreFetchInterventionDataQuery = {
    __typename?: 'Query';
    amsApps: Array<{
        __typename?: 'AMSApp';
        id: string;
        bundleId: string;
        productConst?: AmsProduct | null;
    }>;
    userProfileDefs: Array<{
        __typename?: 'UserProfileDef';
        id: any;
        name: string;
        description?: string | null;
        category: string;
        valueType: UserProfileValueType;
        choiceValueType?: UserProfileValueType | null;
        surveyQuestionDisabled?: boolean | null;
        systemGenerated?: boolean | null;
        conditionTypeId?: any | null;
        episodeRequired?: boolean | null;
        reportingNotes?: string | null;
        haPermission?: HaPermission | null;
        inboundNotes?: string | null;
        isClientSpecific?: boolean | null;
        linkToJIRA?: string | null;
        purposeAppPersonalization?: boolean | null;
        purposeHumanServices?: boolean | null;
        clientSpecificDisplayName?: string | null;
        dataSourceNotes?: string | null;
        purposeDetails?: string | null;
        dwLocation?: string | null;
        dwObject?: string | null;
        dwMetrics?: string | null;
        dwInternalReports?: string | null;
        dwExternalReports?: string | null;
        claimsField?: string | null;
        icd10DiagnosisCodes?: string | null;
        procedureCodes?: string | null;
        label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        questionLabel?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        choices?: Array<{
            __typename?: 'UserProfileDefChoice';
            specifyValue: boolean;
            id: any;
            name?: string | null;
            answerLabel?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            value?: {
                __typename?: 'UserProfileDefChoiceValue';
                bool?: boolean | null;
                date?: any | null;
                num?: number | null;
                str?: string | null;
            } | null;
        } | null> | null;
    }>;
    conditionTypes: Array<{
        __typename?: 'ConditionType';
        id: any;
        name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    }>;
    messageCenterTemplates: Array<{
        __typename?: 'MessageCenterTemplate';
        id: any;
        title?: string | null;
    }>;
    messageTemplatesV2: {
        __typename?: 'MessageTemplateListResponse';
        results: Array<{ __typename?: 'MessageTemplate'; name: string; id: any }>;
    };
};

export type ProfileDefsListQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileDefsListQuery = {
    __typename?: 'Query';
    userProfileDefs: Array<{
        __typename?: 'UserProfileDef';
        id: any;
        name: string;
        description?: string | null;
        category: string;
        valueType: UserProfileValueType;
        choiceValueType?: UserProfileValueType | null;
        surveyQuestionDisabled?: boolean | null;
        systemGenerated?: boolean | null;
        conditionTypeId?: any | null;
        episodeRequired?: boolean | null;
        reportingNotes?: string | null;
        haPermission?: HaPermission | null;
        inboundNotes?: string | null;
        isClientSpecific?: boolean | null;
        linkToJIRA?: string | null;
        purposeAppPersonalization?: boolean | null;
        purposeHumanServices?: boolean | null;
        clientSpecificDisplayName?: string | null;
        dataSourceNotes?: string | null;
        purposeDetails?: string | null;
        dwLocation?: string | null;
        dwObject?: string | null;
        dwMetrics?: string | null;
        dwInternalReports?: string | null;
        dwExternalReports?: string | null;
        claimsField?: string | null;
        icd10DiagnosisCodes?: string | null;
        procedureCodes?: string | null;
        label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        questionLabel?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        choices?: Array<{
            __typename?: 'UserProfileDefChoice';
            specifyValue: boolean;
            id: any;
            name?: string | null;
            answerLabel?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            value?: {
                __typename?: 'UserProfileDefChoiceValue';
                bool?: boolean | null;
                date?: any | null;
                num?: number | null;
                str?: string | null;
            } | null;
        } | null> | null;
    }>;
};

export type FetchInterventionQueryVariables = Exact<{
    interventionInput: GetInterventionInput;
}>;

export type FetchInterventionQuery = {
    __typename?: 'Query';
    intervention?: {
        __typename?: 'Intervention';
        tagId?: any | null;
        type: InterventionType;
        conditionTypeId?: any | null;
        profileDefId?: any | null;
        messageCenterTemplateId?: any | null;
        choicesToUpdate?: Array<any | null> | null;
        createdAt: any;
        updatedAt: any;
        id: any;
        includeApps?: Array<string> | null;
        excludeApps?: Array<string> | null;
        dateType?: InterventionDateType | null;
        advocateTaskTemplateId?: any | null;
        priority?: number | null;
        messageTemplateId?: any | null;
        profileValue?: {
            __typename?: 'InterventionProfileValue';
            choiceId?: any | null;
            str?: string | null;
            num?: number | null;
            bool?: boolean | null;
            date?: any | null;
            choices?: Array<string | null> | null;
        } | null;
        tag?: {
            __typename?: 'Tag';
            id: any;
            name: string;
            expressionId?: any | null;
            changedProfileDefIds?: Array<any> | null;
            includeApps?: Array<string> | null;
            excludeApps?: Array<string> | null;
            createdAt: any;
            updatedAt: any;
            appInfo?: string | null;
            usageType: TagUsageType;
            isPatientFilter?: boolean | null;
            patientRiskScorePoints?: number | null;
            expression?: { __typename?: 'ConfigExpression'; id: any; name: string } | null;
        } | null;
        conditionTypes?: {
            __typename?: 'ConditionType';
            createdAt: any;
            updatedAt: any;
            id: any;
            name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        } | null;
        profileDef?: {
            __typename?: 'UserProfileDef';
            id: any;
            name: string;
            description?: string | null;
            category: string;
            valueType: UserProfileValueType;
            choiceValueType?: UserProfileValueType | null;
            surveyQuestionDisabled?: boolean | null;
            systemGenerated?: boolean | null;
            conditionTypeId?: any | null;
            episodeRequired?: boolean | null;
            reportingNotes?: string | null;
            haPermission?: HaPermission | null;
            inboundNotes?: string | null;
            isClientSpecific?: boolean | null;
            linkToJIRA?: string | null;
            purposeAppPersonalization?: boolean | null;
            purposeHumanServices?: boolean | null;
            clientSpecificDisplayName?: string | null;
            dataSourceNotes?: string | null;
            purposeDetails?: string | null;
            dwLocation?: string | null;
            dwObject?: string | null;
            dwMetrics?: string | null;
            dwInternalReports?: string | null;
            dwExternalReports?: string | null;
            claimsField?: string | null;
            icd10DiagnosisCodes?: string | null;
            procedureCodes?: string | null;
            label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            questionLabel?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            choices?: Array<{
                __typename?: 'UserProfileDefChoice';
                specifyValue: boolean;
                id: any;
                name?: string | null;
                answerLabel?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
                value?: {
                    __typename?: 'UserProfileDefChoiceValue';
                    bool?: boolean | null;
                    date?: any | null;
                    num?: number | null;
                    str?: string | null;
                } | null;
            } | null> | null;
        } | null;
        conditionType?: {
            __typename?: 'ConditionType';
            createdAt: any;
            updatedAt: any;
            id: any;
            name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        } | null;
        advocateTaskTemplate?: { __typename?: 'AdvocateTaskTemplate'; label: string } | null;
        messageTemplate?: { __typename?: 'MessageTemplate'; name: string } | null;
    } | null;
};

export type UpdateInterventionMutationVariables = Exact<{
    updateInterventionInput: UpdateInterventionInput;
}>;

export type UpdateInterventionMutation = {
    __typename?: 'Mutation';
    updateIntervention?: {
        __typename?: 'UpdateInterventionResponse';
        success: boolean;
        message: string;
        resourceUpdated?: {
            __typename?: 'Intervention';
            tagId?: any | null;
            type: InterventionType;
            conditionTypeId?: any | null;
            profileDefId?: any | null;
            messageCenterTemplateId?: any | null;
            choicesToUpdate?: Array<any | null> | null;
            createdAt: any;
            updatedAt: any;
            id: any;
            includeApps?: Array<string> | null;
            excludeApps?: Array<string> | null;
            dateType?: InterventionDateType | null;
            advocateTaskTemplateId?: any | null;
            priority?: number | null;
            messageTemplateId?: any | null;
            profileValue?: {
                __typename?: 'InterventionProfileValue';
                choiceId?: any | null;
                str?: string | null;
                num?: number | null;
                bool?: boolean | null;
                date?: any | null;
                choices?: Array<string | null> | null;
            } | null;
            tag?: {
                __typename?: 'Tag';
                id: any;
                name: string;
                expressionId?: any | null;
                changedProfileDefIds?: Array<any> | null;
                includeApps?: Array<string> | null;
                excludeApps?: Array<string> | null;
                createdAt: any;
                updatedAt: any;
                appInfo?: string | null;
                usageType: TagUsageType;
                isPatientFilter?: boolean | null;
                patientRiskScorePoints?: number | null;
                expression?: { __typename?: 'ConfigExpression'; id: any; name: string } | null;
            } | null;
            conditionTypes?: {
                __typename?: 'ConditionType';
                createdAt: any;
                updatedAt: any;
                id: any;
                name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            } | null;
            profileDef?: {
                __typename?: 'UserProfileDef';
                id: any;
                name: string;
                description?: string | null;
                category: string;
                valueType: UserProfileValueType;
                choiceValueType?: UserProfileValueType | null;
                surveyQuestionDisabled?: boolean | null;
                systemGenerated?: boolean | null;
                conditionTypeId?: any | null;
                episodeRequired?: boolean | null;
                reportingNotes?: string | null;
                haPermission?: HaPermission | null;
                inboundNotes?: string | null;
                isClientSpecific?: boolean | null;
                linkToJIRA?: string | null;
                purposeAppPersonalization?: boolean | null;
                purposeHumanServices?: boolean | null;
                clientSpecificDisplayName?: string | null;
                dataSourceNotes?: string | null;
                purposeDetails?: string | null;
                dwLocation?: string | null;
                dwObject?: string | null;
                dwMetrics?: string | null;
                dwInternalReports?: string | null;
                dwExternalReports?: string | null;
                claimsField?: string | null;
                icd10DiagnosisCodes?: string | null;
                procedureCodes?: string | null;
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
                questionLabel?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                choices?: Array<{
                    __typename?: 'UserProfileDefChoice';
                    specifyValue: boolean;
                    id: any;
                    name?: string | null;
                    answerLabel?: {
                        __typename?: 'LocalizedStringLoose';
                        en?: string | null;
                        es?: string | null;
                    } | null;
                    label?: {
                        __typename?: 'LocalizedString';
                        en: string;
                        es?: string | null;
                    } | null;
                    value?: {
                        __typename?: 'UserProfileDefChoiceValue';
                        bool?: boolean | null;
                        date?: any | null;
                        num?: number | null;
                        str?: string | null;
                    } | null;
                } | null> | null;
            } | null;
            conditionType?: {
                __typename?: 'ConditionType';
                createdAt: any;
                updatedAt: any;
                id: any;
                name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            } | null;
            advocateTaskTemplate?: { __typename?: 'AdvocateTaskTemplate'; label: string } | null;
            messageTemplate?: { __typename?: 'MessageTemplate'; name: string } | null;
        } | null;
    } | null;
};

export type CreateInterventionMutationVariables = Exact<{
    createInterventionInput: InterventionInput;
}>;

export type CreateInterventionMutation = {
    __typename?: 'Mutation';
    createIntervention?: {
        __typename?: 'CreateInterventionResponse';
        status: number;
        message: string;
        success: boolean;
        resourceCreated?: {
            __typename?: 'Intervention';
            tagId?: any | null;
            type: InterventionType;
            conditionTypeId?: any | null;
            profileDefId?: any | null;
            messageCenterTemplateId?: any | null;
            choicesToUpdate?: Array<any | null> | null;
            createdAt: any;
            updatedAt: any;
            id: any;
            includeApps?: Array<string> | null;
            excludeApps?: Array<string> | null;
            dateType?: InterventionDateType | null;
            advocateTaskTemplateId?: any | null;
            priority?: number | null;
            messageTemplateId?: any | null;
            profileValue?: {
                __typename?: 'InterventionProfileValue';
                choiceId?: any | null;
                str?: string | null;
                num?: number | null;
                bool?: boolean | null;
                date?: any | null;
                choices?: Array<string | null> | null;
            } | null;
            tag?: {
                __typename?: 'Tag';
                id: any;
                name: string;
                expressionId?: any | null;
                changedProfileDefIds?: Array<any> | null;
                includeApps?: Array<string> | null;
                excludeApps?: Array<string> | null;
                createdAt: any;
                updatedAt: any;
                appInfo?: string | null;
                usageType: TagUsageType;
                isPatientFilter?: boolean | null;
                patientRiskScorePoints?: number | null;
                expression?: { __typename?: 'ConfigExpression'; id: any; name: string } | null;
            } | null;
            conditionTypes?: {
                __typename?: 'ConditionType';
                createdAt: any;
                updatedAt: any;
                id: any;
                name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            } | null;
            profileDef?: {
                __typename?: 'UserProfileDef';
                id: any;
                name: string;
                description?: string | null;
                category: string;
                valueType: UserProfileValueType;
                choiceValueType?: UserProfileValueType | null;
                surveyQuestionDisabled?: boolean | null;
                systemGenerated?: boolean | null;
                conditionTypeId?: any | null;
                episodeRequired?: boolean | null;
                reportingNotes?: string | null;
                haPermission?: HaPermission | null;
                inboundNotes?: string | null;
                isClientSpecific?: boolean | null;
                linkToJIRA?: string | null;
                purposeAppPersonalization?: boolean | null;
                purposeHumanServices?: boolean | null;
                clientSpecificDisplayName?: string | null;
                dataSourceNotes?: string | null;
                purposeDetails?: string | null;
                dwLocation?: string | null;
                dwObject?: string | null;
                dwMetrics?: string | null;
                dwInternalReports?: string | null;
                dwExternalReports?: string | null;
                claimsField?: string | null;
                icd10DiagnosisCodes?: string | null;
                procedureCodes?: string | null;
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
                questionLabel?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                choices?: Array<{
                    __typename?: 'UserProfileDefChoice';
                    specifyValue: boolean;
                    id: any;
                    name?: string | null;
                    answerLabel?: {
                        __typename?: 'LocalizedStringLoose';
                        en?: string | null;
                        es?: string | null;
                    } | null;
                    label?: {
                        __typename?: 'LocalizedString';
                        en: string;
                        es?: string | null;
                    } | null;
                    value?: {
                        __typename?: 'UserProfileDefChoiceValue';
                        bool?: boolean | null;
                        date?: any | null;
                        num?: number | null;
                        str?: string | null;
                    } | null;
                } | null> | null;
            } | null;
            conditionType?: {
                __typename?: 'ConditionType';
                createdAt: any;
                updatedAt: any;
                id: any;
                name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            } | null;
            advocateTaskTemplate?: { __typename?: 'AdvocateTaskTemplate'; label: string } | null;
            messageTemplate?: { __typename?: 'MessageTemplate'; name: string } | null;
        } | null;
    } | null;
};

export type InterventionListQueryVariables = Exact<{
    interventionListInput: InterventionQueryInput;
}>;

export type InterventionListQuery = {
    __typename?: 'Query';
    interventionsV2: {
        __typename?: 'InterventionPaginationQueryResponse';
        orderBy?: string | null;
        total: number;
        skip?: number | null;
        limit?: number | null;
        hasMore?: boolean | null;
        hasPrevious?: boolean | null;
        results: Array<{
            __typename?: 'Intervention';
            tagId?: any | null;
            type: InterventionType;
            conditionTypeId?: any | null;
            profileDefId?: any | null;
            messageCenterTemplateId?: any | null;
            choicesToUpdate?: Array<any | null> | null;
            createdAt: any;
            updatedAt: any;
            id: any;
            includeApps?: Array<string> | null;
            excludeApps?: Array<string> | null;
            dateType?: InterventionDateType | null;
            advocateTaskTemplateId?: any | null;
            priority?: number | null;
            messageTemplateId?: any | null;
            profileValue?: {
                __typename?: 'InterventionProfileValue';
                choiceId?: any | null;
                str?: string | null;
                num?: number | null;
                bool?: boolean | null;
                date?: any | null;
                choices?: Array<string | null> | null;
            } | null;
            tag?: {
                __typename?: 'Tag';
                id: any;
                name: string;
                expressionId?: any | null;
                changedProfileDefIds?: Array<any> | null;
                includeApps?: Array<string> | null;
                excludeApps?: Array<string> | null;
                createdAt: any;
                updatedAt: any;
                appInfo?: string | null;
                usageType: TagUsageType;
                isPatientFilter?: boolean | null;
                patientRiskScorePoints?: number | null;
                expression?: { __typename?: 'ConfigExpression'; id: any; name: string } | null;
            } | null;
            conditionTypes?: {
                __typename?: 'ConditionType';
                createdAt: any;
                updatedAt: any;
                id: any;
                name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            } | null;
            profileDef?: {
                __typename?: 'UserProfileDef';
                id: any;
                name: string;
                description?: string | null;
                category: string;
                valueType: UserProfileValueType;
                choiceValueType?: UserProfileValueType | null;
                surveyQuestionDisabled?: boolean | null;
                systemGenerated?: boolean | null;
                conditionTypeId?: any | null;
                episodeRequired?: boolean | null;
                reportingNotes?: string | null;
                haPermission?: HaPermission | null;
                inboundNotes?: string | null;
                isClientSpecific?: boolean | null;
                linkToJIRA?: string | null;
                purposeAppPersonalization?: boolean | null;
                purposeHumanServices?: boolean | null;
                clientSpecificDisplayName?: string | null;
                dataSourceNotes?: string | null;
                purposeDetails?: string | null;
                dwLocation?: string | null;
                dwObject?: string | null;
                dwMetrics?: string | null;
                dwInternalReports?: string | null;
                dwExternalReports?: string | null;
                claimsField?: string | null;
                icd10DiagnosisCodes?: string | null;
                procedureCodes?: string | null;
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
                questionLabel?: {
                    __typename?: 'LocalizedStringLoose';
                    en?: string | null;
                    es?: string | null;
                } | null;
                choices?: Array<{
                    __typename?: 'UserProfileDefChoice';
                    specifyValue: boolean;
                    id: any;
                    name?: string | null;
                    answerLabel?: {
                        __typename?: 'LocalizedStringLoose';
                        en?: string | null;
                        es?: string | null;
                    } | null;
                    label?: {
                        __typename?: 'LocalizedString';
                        en: string;
                        es?: string | null;
                    } | null;
                    value?: {
                        __typename?: 'UserProfileDefChoiceValue';
                        bool?: boolean | null;
                        date?: any | null;
                        num?: number | null;
                        str?: string | null;
                    } | null;
                } | null> | null;
            } | null;
            conditionType?: {
                __typename?: 'ConditionType';
                createdAt: any;
                updatedAt: any;
                id: any;
                name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
            } | null;
            advocateTaskTemplate?: { __typename?: 'AdvocateTaskTemplate'; label: string } | null;
            messageTemplate?: { __typename?: 'MessageTemplate'; name: string } | null;
        }>;
    };
};

export type DeleteInterventionMutationVariables = Exact<{
    input: DeleteInterventionInput;
}>;

export type DeleteInterventionMutation = {
    __typename?: 'Mutation';
    deleteIntervention?: {
        __typename?: 'DeleteInterventionResponse';
        success: boolean;
        message: string;
        status: number;
        id?: string | null;
    } | null;
};

export type FetchAdvocateTaskTemplatesForInterventionQueryVariables = Exact<{
    input: AdvocateTaskTemplateQueryInput;
}>;

export type FetchAdvocateTaskTemplatesForInterventionQuery = {
    __typename?: 'Query';
    advocateTaskTemplatesV2: {
        __typename?: 'AdvocateTaskTemplatePaginationQueryResponse';
        results: Array<{ __typename?: 'AdvocateTaskTemplate'; id: any; label: string }>;
    };
};

export type FetchMessageCenterTemplatesForInterventionQueryVariables = Exact<{
    [key: string]: never;
}>;

export type FetchMessageCenterTemplatesForInterventionQuery = {
    __typename?: 'Query';
    messageCenterTemplates: Array<{
        __typename?: 'MessageCenterTemplate';
        id: any;
        title?: string | null;
    }>;
};

export type FetchDateTriggerQueryVariables = Exact<{
    input: GetDateTriggerInput;
}>;

export type FetchDateTriggerQuery = {
    __typename?: 'Query';
    dateTrigger?: {
        __typename?: 'DateTrigger';
        id: any;
        name: string;
        offsetDays?: number | null;
        offsetMonths?: number | null;
        offsetSequence?: number | null;
        offsetYears?: number | null;
        gracePeriod?: number | null;
        tagIds?: Array<any> | null;
        timelineId: any;
        createdAt: any;
        updatedAt: any;
    } | null;
};

export type UpdateDateTriggerMutationVariables = Exact<{
    input: UpdateDateTriggerInput;
}>;

export type UpdateDateTriggerMutation = {
    __typename?: 'Mutation';
    updateDateTrigger?: {
        __typename?: 'UpdateDateTriggerResponse';
        success: boolean;
        message: string;
        resourceUpdated?: {
            __typename?: 'DateTrigger';
            id: any;
            name: string;
            offsetDays?: number | null;
            offsetMonths?: number | null;
            offsetSequence?: number | null;
            offsetYears?: number | null;
            gracePeriod?: number | null;
            tagIds?: Array<any> | null;
            timelineId: any;
            createdAt: any;
            updatedAt: any;
        } | null;
    } | null;
};

export type CreateDateTriggerMutationVariables = Exact<{
    input: DateTriggerInput;
}>;

export type CreateDateTriggerMutation = {
    __typename?: 'Mutation';
    createDateTrigger?: {
        __typename?: 'CreateDateTriggerResponse';
        success: boolean;
        message: string;
        resourceCreated?: {
            __typename?: 'DateTrigger';
            id: any;
            name: string;
            offsetDays?: number | null;
            offsetMonths?: number | null;
            offsetSequence?: number | null;
            offsetYears?: number | null;
            gracePeriod?: number | null;
            tagIds?: Array<any> | null;
            timelineId: any;
            createdAt: any;
            updatedAt: any;
        } | null;
    } | null;
};

export type DateTriggerListQueryVariables = Exact<{
    input?: InputMaybe<DateTriggerQueryInput>;
}>;

export type DateTriggerListQuery = {
    __typename?: 'Query';
    dateTriggersV2: {
        __typename?: 'DateTriggerPaginationQueryResponse';
        results: Array<{
            __typename?: 'DateTrigger';
            id: any;
            createdAt: any;
            updatedAt: any;
            name: string;
            offsetDays?: number | null;
            offsetMonths?: number | null;
            offsetSequence?: number | null;
            offsetYears?: number | null;
            gracePeriod?: number | null;
            tagIds?: Array<any> | null;
            timelineId: any;
        }>;
    };
};

export type DeleteDateTriggerMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteDateTriggerMutation = {
    __typename?: 'Mutation';
    deleteDateTrigger?: {
        __typename?: 'DeleteDateTriggerMutationResponse';
        success: boolean;
        message: string;
        status: number;
        id: string;
    } | null;
};

export type PreFetchAppsTagsQueryVariables = Exact<{ [key: string]: never }>;

export type PreFetchAppsTagsQuery = {
    __typename?: 'Query';
    amsApps: Array<{
        __typename?: 'AMSApp';
        id: string;
        bundleId: string;
        productConst?: AmsProduct | null;
    }>;
    configExpressions: Array<{ __typename?: 'ConfigExpression'; id: any; name: string }>;
    tags: Array<{ __typename?: 'Tag'; id: any; name: string }>;
    whatsNewTimelines: Array<{
        __typename?: 'WhatsNewTimeline';
        id: any;
        type: WhatsNewTimelineType;
        label: { __typename?: 'LocalizedString'; en: string };
    }>;
};

export type FetchEventTriggerQueryVariables = Exact<{
    input: GetEventTriggerInput;
}>;

export type FetchEventTriggerQuery = {
    __typename?: 'Query';
    eventTrigger?: {
        __typename?: 'EventTrigger';
        id: any;
        name: string;
        profileDefId?: any | null;
        trackerType?: EventTrackerType | null;
        expressionId: any;
        canRepeat: boolean;
        ignoreSameValue: boolean;
        createdAt: any;
        updatedAt: any;
    } | null;
};

export type UpdateEventTriggerMutationVariables = Exact<{
    input: UpdateEventTriggerInput;
}>;

export type UpdateEventTriggerMutation = {
    __typename?: 'Mutation';
    updateEventTrigger?: {
        __typename?: 'UpdateEventTriggerResponse';
        success: boolean;
        message: string;
        resourceUpdated?: {
            __typename?: 'EventTrigger';
            id: any;
            name: string;
            profileDefId?: any | null;
            trackerType?: EventTrackerType | null;
            expressionId: any;
            canRepeat: boolean;
            ignoreSameValue: boolean;
            createdAt: any;
            updatedAt: any;
        } | null;
    } | null;
};

export type CreateEventTriggerMutationVariables = Exact<{
    input: EventTriggerInput;
}>;

export type CreateEventTriggerMutation = {
    __typename?: 'Mutation';
    createEventTrigger?: {
        __typename?: 'CreateEventTriggerResponse';
        success: boolean;
        message: string;
        resourceCreated?: {
            __typename?: 'EventTrigger';
            id: any;
            name: string;
            profileDefId?: any | null;
            trackerType?: EventTrackerType | null;
            expressionId: any;
            canRepeat: boolean;
            ignoreSameValue: boolean;
            createdAt: any;
            updatedAt: any;
        } | null;
    } | null;
};

export type EventTriggerListQueryVariables = Exact<{
    input?: InputMaybe<EventTriggerQueryInput>;
}>;

export type EventTriggerListQuery = {
    __typename?: 'Query';
    eventTriggersV2: {
        __typename?: 'EventTriggerPaginationQueryResponse';
        results: Array<{
            __typename?: 'EventTrigger';
            id: any;
            name: string;
            profileDefId?: any | null;
            trackerType?: EventTrackerType | null;
            expressionId: any;
            canRepeat: boolean;
            ignoreSameValue: boolean;
            createdAt: any;
            updatedAt: any;
        }>;
    };
};

export type DeleteEventTriggerMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteEventTriggerMutation = {
    __typename?: 'Mutation';
    deleteEventTrigger?: {
        __typename?: 'DeleteEventTriggerMutationResponse';
        success: boolean;
        message: string;
        status: number;
        id: string;
    } | null;
};

export type FetchTrendTriggerQueryVariables = Exact<{
    input: GetTrendTriggerInput;
}>;

export type FetchTrendTriggerQuery = {
    __typename?: 'Query';
    trendTrigger?: {
        __typename?: 'TrendTrigger';
        id: any;
        name: string;
        eventTriggerId?: any | null;
        tagId?: any | null;
        trueCount: number;
        period: number;
        contiguous: boolean;
        resetOnRun: boolean;
        createdAt: any;
        updatedAt: any;
    } | null;
};

export type UpdateTrendTriggerMutationVariables = Exact<{
    input: UpdateTrendTriggerInput;
}>;

export type UpdateTrendTriggerMutation = {
    __typename?: 'Mutation';
    updateTrendTrigger?: {
        __typename?: 'UpdateTrendTriggerResponse';
        success: boolean;
        message: string;
        resourceUpdated?: {
            __typename?: 'TrendTrigger';
            id: any;
            name: string;
            eventTriggerId?: any | null;
            tagId?: any | null;
            trueCount: number;
            period: number;
            contiguous: boolean;
            resetOnRun: boolean;
            createdAt: any;
            updatedAt: any;
        } | null;
    } | null;
};

export type CreateTrendTriggerMutationVariables = Exact<{
    input: TrendTriggerInput;
}>;

export type CreateTrendTriggerMutation = {
    __typename?: 'Mutation';
    createTrendTrigger?: {
        __typename?: 'CreateTrendTriggerResponse';
        success: boolean;
        message: string;
        resourceCreated?: {
            __typename?: 'TrendTrigger';
            id: any;
            name: string;
            eventTriggerId?: any | null;
            tagId?: any | null;
            trueCount: number;
            period: number;
            contiguous: boolean;
            resetOnRun: boolean;
            createdAt: any;
            updatedAt: any;
        } | null;
    } | null;
};

export type TrendTriggerListQueryVariables = Exact<{
    input?: InputMaybe<TrendTriggerQueryInput>;
}>;

export type TrendTriggerListQuery = {
    __typename?: 'Query';
    trendTriggersV2: {
        __typename?: 'TrendTriggerPaginationQueryResponse';
        results: Array<{
            __typename?: 'TrendTrigger';
            id: any;
            name: string;
            eventTriggerId?: any | null;
            tagId?: any | null;
            trueCount: number;
            period: number;
            contiguous: boolean;
            resetOnRun: boolean;
            createdAt: any;
            updatedAt: any;
        }>;
    };
};

export type DeleteTrendTriggerMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteTrendTriggerMutation = {
    __typename?: 'Mutation';
    deleteTrendTrigger?: {
        __typename?: 'DeleteTrendTriggerMutationResponse';
        success: boolean;
        message: string;
        status: number;
        id: string;
    } | null;
};

export type FetchTagsForTrendTriggerPageQueryVariables = Exact<{ [key: string]: never }>;

export type FetchTagsForTrendTriggerPageQuery = {
    __typename?: 'Query';
    tagsV2: {
        __typename?: 'TagPaginationQueryResponse';
        results: Array<{ __typename?: 'Tag'; name: string; id: any }>;
    };
};

export type FetchAffiliateClaimSubmissionsQueryVariables = Exact<{
    input: PaginatedAffiliateClaimInput;
}>;

export type FetchAffiliateClaimSubmissionsQuery = {
    __typename?: 'Query';
    paginatedAffiliateClaim?: {
        __typename?: 'PaginatedAffiliateClaimResponse';
        total: number;
        hasMore?: boolean | null;
        hasPrevious?: boolean | null;
        orderBy?: string | null;
        results: Array<{
            __typename?: 'AffiliateClaim';
            id: any;
            affiliateId: string;
            dateTime: any;
            status: AffiliateClaimStatus;
            location?: string | null;
            fileName: string;
            errorText?: string | null;
            affiliate?: {
                __typename?: 'VirtualCareAffiliatesEntity';
                id: any;
                internalName: string;
            } | null;
        }>;
    } | null;
};

export type FetchAffiliateClaimCsvQueryVariables = Exact<{
    input: S3GetObjectInput;
}>;

export type FetchAffiliateClaimCsvQuery = {
    __typename?: 'Query';
    getAffiliateClaimCSVByKey?: {
        __typename?: 'ReportBufferByKeyResponse';
        data?: Array<string | null> | null;
        filename?: string | null;
    } | null;
};

export type CreateVirtualCareAffiliateMutationVariables = Exact<{
    input: CreateVirtualCareAffiliateInput;
}>;

export type CreateVirtualCareAffiliateMutation = {
    __typename?: 'Mutation';
    createVirtualCareAffiliate?: {
        __typename?: 'VirtualCareAffiliatesMutationResponse';
        status: number;
        message: string;
        success: boolean;
        data?: { __typename?: 'VirtualCareAffiliatesEntity'; id: any } | null;
    } | null;
};

export type UpdateVirtualCareAffiliatesMutationVariables = Exact<{
    input: UpdateVirtualCareAffiliateInput;
}>;

export type UpdateVirtualCareAffiliatesMutation = {
    __typename?: 'Mutation';
    updateVirtualCareAffiliate?: {
        __typename?: 'UpdateVirtualCareAffiliateResponse';
        message: string;
        success: boolean;
    } | null;
};

export type DeleteVirtualCareAffiliateMutationVariables = Exact<{
    input?: InputMaybe<Scalars['String']>;
}>;

export type DeleteVirtualCareAffiliateMutation = {
    __typename?: 'Mutation';
    deleteVirtualCareAffiliate?: {
        __typename?: 'DeleteVirtualCareAffiliateResponse';
        message: string;
        success: boolean;
    } | null;
};

export type FetchVirtualCareAffiliateByIdQueryVariables = Exact<{
    input: GetVirtualCareAffiliateInput;
}>;

export type FetchVirtualCareAffiliateByIdQuery = {
    __typename?: 'Query';
    virtualCareAffiliateById?: {
        __typename?: 'VirtualCareAffiliateByIdResponse';
        affiliateURL?: string | null;
        affiliateSelfCheckURL?: string | null;
        data?: {
            __typename?: 'VirtualCareAffiliatesEntity';
            id: any;
            affiliateId?: string | null;
            internalName: string;
            contactEmail?: string | null;
            contactName?: string | null;
            contactPhoneNumber?: string | null;
            supportMethodType?: SupportMethodType | null;
            supportMethodValue?: string | null;
            customSuccessText?: string | null;
            emailAddressForScheduling?: string | null;
            externalName: string;
            notificationEmail?: Array<string | null> | null;
            onSuccessfulEligibility?: string | null;
            phoneNumberForScheduling?: string | null;
            routingURL?: string | null;
            notifyCompanyWhenPatientPassesEligibility?: boolean | null;
            onSuccessfulZipCodeMatch?: OnSuccessfulZipCodeMatch | null;
            zipCodeMatchCustomSuccessString?: string | null;
            BillDotComVendorId?: string | null;
            website?: string | null;
            inviteAffiliate?: boolean | null;
        } | null;
    } | null;
};

export type FetchVirtualCareAffiliatesQueryVariables = Exact<{
    input: GetVirtualCareAffiliatesInput;
}>;

export type FetchVirtualCareAffiliatesQuery = {
    __typename?: 'Query';
    virtualCareAffiliates?: {
        __typename?: 'VirtualCareAffiliatesResponse';
        total: number;
        hasMore?: boolean | null;
        hasPrevious?: boolean | null;
        orderBy?: string | null;
        results: Array<{
            __typename?: 'VirtualCareAffiliatesEntity';
            id: any;
            affiliateId?: string | null;
            internalName: string;
            externalName: string;
        }>;
    } | null;
};

export type GetVendorByIdFromExternalServiceQueryVariables = Exact<{
    input?: InputMaybe<GetVendorByIdInput>;
}>;

export type GetVendorByIdFromExternalServiceQuery = {
    __typename?: 'Query';
    getVendorById?: {
        __typename?: 'VendorByIdResponse';
        address1?: string | null;
        address2?: string | null;
        addressCity?: string | null;
        addressState?: string | null;
        addressZip?: string | null;
        nameOnCheck?: string | null;
        vendorId?: string | null;
    } | null;
};

export type VirtualCareEligibilityWhitelistsV2QueryVariables = Exact<{
    input?: InputMaybe<VirtualCareEligibilityWhitelistQueryInput>;
}>;

export type VirtualCareEligibilityWhitelistsV2Query = {
    __typename?: 'Query';
    virtualCareEligibilityWhitelistsV2: {
        __typename?: 'VirtualCareEligibilityWhitelistPaginationQueryResponse';
        hasMore?: boolean | null;
        limit?: number | null;
        orderBy?: string | null;
        results: Array<{
            __typename?: 'VirtualCareEligibilityWhitelist';
            firstName?: string | null;
            id: any;
            lastName?: string | null;
            memberId?: string | null;
            updatedAt: any;
        }>;
    };
};

export type VirtualCareEligibilityWhitelistQueryVariables = Exact<{
    input: GetVirtualCareEligibilityWhitelistInput;
}>;

export type VirtualCareEligibilityWhitelistQuery = {
    __typename?: 'Query';
    virtualCareEligibilityWhitelist?: {
        __typename?: 'VirtualCareEligibilityWhitelist';
        createdAt: any;
        firstName?: string | null;
        id: any;
        isTestData?: boolean | null;
        lastName?: string | null;
        memberId?: string | null;
        updatedAt: any;
    } | null;
};

export type CreateVirtualCareEligibilityWhitelistMutationVariables = Exact<{
    input: VirtualCareEligibilityWhitelistInput;
}>;

export type CreateVirtualCareEligibilityWhitelistMutation = {
    __typename?: 'Mutation';
    createVirtualCareEligibilityWhitelist?: {
        __typename?: 'CreateVirtualCareEligibilityWhitelistResponse';
        status: number;
        success: boolean;
        message: string;
        resourceCreated?: {
            __typename?: 'VirtualCareEligibilityWhitelist';
            createdAt: any;
            firstName?: string | null;
            id: any;
            lastName?: string | null;
            memberId?: string | null;
            updatedAt: any;
        } | null;
    } | null;
};

export type UpdateVirtualCareEligibilityWhitelistMutationVariables = Exact<{
    input: UpdateVirtualCareEligibilityWhitelistInput;
}>;

export type UpdateVirtualCareEligibilityWhitelistMutation = {
    __typename?: 'Mutation';
    updateVirtualCareEligibilityWhitelist?: {
        __typename?: 'UpdateVirtualCareEligibilityWhitelistResponse';
        message: string;
        resourceUpdated?: {
            __typename?: 'VirtualCareEligibilityWhitelist';
            createdAt: any;
            firstName?: string | null;
            id: any;
            lastName?: string | null;
            memberId?: string | null;
            updatedAt: any;
        } | null;
    } | null;
};

export type DeleteVirtualCareEligibilityWhitelistMutationVariables = Exact<{
    input: DeleteVirtualCareEligibilityWhitelistInput;
}>;

export type DeleteVirtualCareEligibilityWhitelistMutation = {
    __typename?: 'Mutation';
    deleteVirtualCareEligibilityWhitelist?: {
        __typename?: 'DeleteVirtualCareEligibilityWhitelistResponse';
        id?: string | null;
        message: string;
        status: number;
        success: boolean;
    } | null;
};

export type CreateVcZipCodeToLocationMutationVariables = Exact<{
    input: AddVcZipCodeToLocationInput;
}>;

export type CreateVcZipCodeToLocationMutation = {
    __typename?: 'Mutation';
    createVCZipCodeToLocation?: {
        __typename?: 'CreateVCZipCodeToLocationResponse';
        message: string;
        status: number;
        success: boolean;
        resourceCreated?: {
            __typename?: 'VCZipCodeToLocation';
            id: any;
            locationId?: string | null;
            locationName: string;
            zipCode: string;
            createdAt: any;
            routingMethod: RoutingMethod;
            affiliateId?: any | null;
        } | null;
    } | null;
};

export type UpdateVcZipCodeToLocationMutationVariables = Exact<{
    input: UpdateVcZipCodeToLocationInput;
}>;

export type UpdateVcZipCodeToLocationMutation = {
    __typename?: 'Mutation';
    updateVCZipCodeToLocation?: {
        __typename?: 'UpdateVCZipCodeToLocationResponse';
        status: number;
        message: string;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'VCZipCodeToLocation';
            id: any;
            locationId?: string | null;
            locationName: string;
            zipCode: string;
            createdAt: any;
        } | null;
    } | null;
};

export type VcZipCodeToLocationsV2QueryVariables = Exact<{
    input?: InputMaybe<VcZipCodeToLocationSearchInput>;
}>;

export type VcZipCodeToLocationsV2Query = {
    __typename?: 'Query';
    VCZipCodeToLocationSearch?: {
        __typename?: 'VCZipCodeToLocationSearchResponse';
        hasMore?: boolean | null;
        skip?: number | null;
        total: number;
        limit?: number | null;
        orderBy?: string | null;
        results: Array<{
            __typename?: 'VCZipCodeToLocation';
            id: any;
            locationId?: string | null;
            locationName: string;
            zipCode: string;
            createdAt: any;
            routingMethod: RoutingMethod;
            affiliateId?: any | null;
            updatedAt: any;
            affiliate?: {
                __typename?: 'VirtualCareAffiliatesEntity';
                internalName: string;
                affiliateId?: string | null;
                id: any;
            } | null;
        }>;
    } | null;
};

export type VcZipCodeToLocationQueryVariables = Exact<{
    input: GetVcZipCodeToLocationInput;
}>;

export type VcZipCodeToLocationQuery = {
    __typename?: 'Query';
    vCZipCodeToLocation?: {
        __typename?: 'VCZipCodeToLocation';
        createdAt: any;
        id: any;
        locationId?: string | null;
        locationName: string;
        updatedAt: any;
        zipCode: string;
        affiliateId?: any | null;
        routingMethod: RoutingMethod;
    } | null;
};

export type DeleteVcZipCodeToLocationMutationVariables = Exact<{
    input: DeleteVcZipCodeToLocationInput;
}>;

export type DeleteVcZipCodeToLocationMutation = {
    __typename?: 'Mutation';
    deleteVCZipCodeToLocation?: {
        __typename?: 'DeleteVCZipCodeToLocationResponse';
        id?: string | null;
        message: string;
        status: number;
        success: boolean;
    } | null;
};

export type VcZipCodesAffiliatessListQueryVariables = Exact<{ [key: string]: never }>;

export type VcZipCodesAffiliatessListQuery = {
    __typename?: 'Query';
    virtualCareAffiliatessV2: {
        __typename?: 'VirtualCareAffiliatesPaginationQueryResponse';
        results: Array<{
            __typename?: 'VirtualCareAffiliatesEntity';
            id: any;
            internalName: string;
            affiliateId?: string | null;
            onSuccessfulZipCodeMatch?: OnSuccessfulZipCodeMatch | null;
        }>;
    };
};

export type CreateManyVcZipCodeToLocationsMutationVariables = Exact<{
    input: CreateManyVcZipCodeToLocationsInput;
}>;

export type CreateManyVcZipCodeToLocationsMutation = {
    __typename?: 'Mutation';
    createManyVCZipCodeToLocations?: {
        __typename?: 'CreateManyVCZipCodeToLocationsResponse';
        message: string;
        resourcesCreated?: Array<{
            __typename?: 'CreateManyIndividualVCZipCodeToLocationResponse';
            status: number;
            success: boolean;
            message: string;
            resourceCreated?: {
                __typename?: 'VCZipCodeToLocation';
                id: any;
                locationName: string;
                locationId?: string | null;
                affiliateId?: any | null;
                routingMethod: RoutingMethod;
                zipCode: string;
            } | null;
        } | null> | null;
    } | null;
};

export type AdvocateTasksForAdvocateTaskListPageQueryVariables = Exact<{
    input?: InputMaybe<GetFilteredAdvocateTasksInput>;
}>;

export type AdvocateTasksForAdvocateTaskListPageQuery = {
    __typename?: 'Query';
    customFilteredAdvocateTasks: {
        __typename?: 'FilteredAdvocateTasksResponse';
        orderBy?: string | null;
        skip?: number | null;
        limit?: number | null;
        total: number;
        hasMore?: boolean | null;
        hasPrevious?: boolean | null;
        results: Array<{
            __typename?: 'AdvocateTask';
            id: any;
            label: string;
            description: string;
            dueDate: any;
            createdAt: any;
            completedAt?: any | null;
            overdue?: boolean | null;
            priority: number;
            status: AdvocateTaskTypeStatus;
            patient?: {
                __typename?: 'Patient';
                id: any;
                fullName: string;
                practice: { __typename?: 'Organization'; id: any; brandingName: string };
            } | null;
        }>;
    };
};

export type FetchCurrentUserForAdvocateTaskListPageQueryVariables = Exact<{ [key: string]: never }>;

export type FetchCurrentUserForAdvocateTaskListPageQuery = {
    __typename?: 'Query';
    currentUser?: {
        __typename?: 'User';
        id: any;
        name: string;
        patients: Array<{
            __typename?: 'Patient';
            fullName: string;
            id: any;
            isTestData?: boolean | null;
            practice: { __typename?: 'Organization'; id: any };
        }>;
        currentOrganizations: Array<{ __typename?: 'Organization'; id: any }>;
    } | null;
};

export type FetchAdvocateTaskTypesQueryQueryVariables = Exact<{ [key: string]: never }>;

export type FetchAdvocateTaskTypesQueryQuery = {
    __typename?: 'Query';
    advocateTaskTypesV2: {
        __typename?: 'AdvocateTaskTypePaginationQueryResponse';
        results: Array<{ __typename?: 'AdvocateTaskType'; label: string; id: any }>;
    };
};

export type FetchAdvocatesForAdvocateTaskListPageQueryQueryVariables = Exact<{
    permission?: InputMaybe<Scalars['String']>;
}>;

export type FetchAdvocatesForAdvocateTaskListPageQueryQuery = {
    __typename?: 'Query';
    getUsersByPermission?: Array<{ __typename?: 'User'; id: any; name: string } | null> | null;
};

export type FetchUsersForAdvocateTaskListPageQueryQueryVariables = Exact<{ [key: string]: never }>;

export type FetchUsersForAdvocateTaskListPageQueryQuery = {
    __typename?: 'Query';
    usersV2: {
        __typename?: 'UserPaginationQueryResponse';
        results: Array<{ __typename?: 'User'; id: any; name: string }>;
    };
};

export type FetchReferenceLinksForAdvocateTasksListPageQueryVariables = Exact<{
    input: GetReferenceLinksForPageInput;
}>;

export type FetchReferenceLinksForAdvocateTasksListPageQuery = {
    __typename?: 'Query';
    getReferenceLinksForPage?: Array<{
        __typename?: 'GetReferenceLinksForPageResponse';
        category?: string | null;
        data?: Array<{
            __typename?: 'LinkDataType';
            label?: string | null;
            url?: string | null;
        } | null> | null;
    } | null> | null;
};

export type FetchApptByIdForApptModalQueryVariables = Exact<{
    input: GetAppointmentInput;
}>;

export type FetchApptByIdForApptModalQuery = {
    __typename?: 'Query';
    appointment?: {
        __typename?: 'Appointment';
        id: any;
        externalId?: string | null;
        startDate: any;
        durationMinutes?: number | null;
        staffName?: string | null;
        physicalLocation?: string | null;
        description?: string | null;
        isVirtualVisit?: boolean | null;
        virtualLink?: string | null;
        visitType?: string | null;
        source: AppointmentSource;
        note?: string | null;
        reportKey?: string | null;
        timezoneName?: string | null;
        patient: {
            __typename?: 'Patient';
            id: any;
            fullName: string;
            firstName: string;
            lastName: string;
            birthDate: any;
        };
        practice?: {
            __typename?: 'Organization';
            id: any;
            name: string;
            timezone?: string | null;
        } | null;
        staff?: { __typename?: 'User'; id: any; name: string } | null;
    } | null;
};

export type FetchCurrentUserForApptModalQueryVariables = Exact<{ [key: string]: never }>;

export type FetchCurrentUserForApptModalQuery = {
    __typename?: 'Query';
    currentUser?: {
        __typename?: 'User';
        id: any;
        name: string;
        email: string;
        currentOrg: {
            __typename?: 'Organization';
            id: any;
            parentId?: any | null;
            brandingName: string;
            timezone?: string | null;
        };
        currentOrganizations: Array<{
            __typename?: 'Organization';
            id: any;
            parentId?: any | null;
            brandingName: string;
        }>;
        patients: Array<{ __typename?: 'Patient'; id: any; firstName: string; lastName: string }>;
        users: Array<{ __typename?: 'User'; id: any; name: string }>;
    } | null;
};

export type CreateNewApptFromApptModalMutationVariables = Exact<{
    input: AppointmentInput;
}>;

export type CreateNewApptFromApptModalMutation = {
    __typename?: 'Mutation';
    createAppointment?: {
        __typename?: 'CreateAppointmentResponse';
        message: string;
        success: boolean;
        resourceCreated?: {
            __typename?: 'Appointment';
            id: any;
            externalId?: string | null;
            startDate: any;
            durationMinutes?: number | null;
            staffName?: string | null;
            physicalLocation?: string | null;
            description?: string | null;
            isVirtualVisit?: boolean | null;
            virtualLink?: string | null;
            visitType?: string | null;
            source: AppointmentSource;
            note?: string | null;
            reportKey?: string | null;
            timezoneName?: string | null;
            patient: {
                __typename?: 'Patient';
                id: any;
                fullName: string;
                firstName: string;
                lastName: string;
                birthDate: any;
            };
            practice?: {
                __typename?: 'Organization';
                id: any;
                name: string;
                timezone?: string | null;
            } | null;
            staff?: { __typename?: 'User'; id: any; name: string } | null;
        } | null;
    } | null;
};

export type UpdateNewApptFromApptModalMutationVariables = Exact<{
    input: UpdateAppointmentInput;
}>;

export type UpdateNewApptFromApptModalMutation = {
    __typename?: 'Mutation';
    updateAppointment?: {
        __typename?: 'UpdateAppointmentResponse';
        message: string;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'Appointment';
            id: any;
            externalId?: string | null;
            startDate: any;
            durationMinutes?: number | null;
            staffName?: string | null;
            physicalLocation?: string | null;
            description?: string | null;
            isVirtualVisit?: boolean | null;
            virtualLink?: string | null;
            visitType?: string | null;
            source: AppointmentSource;
            note?: string | null;
            reportKey?: string | null;
            timezoneName?: string | null;
            patient: {
                __typename?: 'Patient';
                id: any;
                fullName: string;
                firstName: string;
                lastName: string;
                birthDate: any;
            };
            practice?: {
                __typename?: 'Organization';
                id: any;
                name: string;
                timezone?: string | null;
            } | null;
            staff?: { __typename?: 'User'; id: any; name: string } | null;
        } | null;
    } | null;
};

export type FetchApptsForApptsPageQueryQueryVariables = Exact<{
    input?: InputMaybe<UserAppointmentsInput>;
}>;

export type FetchApptsForApptsPageQueryQuery = {
    __typename?: 'Query';
    currentUser?: {
        __typename?: 'User';
        id: any;
        currentOrg: { __typename?: 'Organization'; id: any; brandingName: string };
        appointmentsV2: {
            __typename?: 'UserAppointmentsResponse';
            hasMore?: boolean | null;
            hasPrevious?: boolean | null;
            limit?: number | null;
            orderBy?: string | null;
            skip?: number | null;
            total: number;
            results: Array<{
                __typename?: 'Appointment';
                id: any;
                externalId?: string | null;
                startDate: any;
                durationMinutes?: number | null;
                staffName?: string | null;
                physicalLocation?: string | null;
                description?: string | null;
                isVirtualVisit?: boolean | null;
                virtualLink?: string | null;
                visitType?: string | null;
                source: AppointmentSource;
                note?: string | null;
                reportKey?: string | null;
                timezoneName?: string | null;
                patient: {
                    __typename?: 'Patient';
                    id: any;
                    fullName: string;
                    firstName: string;
                    lastName: string;
                    birthDate: any;
                };
                practice?: {
                    __typename?: 'Organization';
                    id: any;
                    name: string;
                    timezone?: string | null;
                } | null;
                staff?: { __typename?: 'User'; id: any; name: string } | null;
            }>;
        };
    } | null;
};

export type DeleteAppointmentByIdMutationVariables = Exact<{
    input: DeleteAppointmentInput;
}>;

export type DeleteAppointmentByIdMutation = {
    __typename?: 'Mutation';
    deleteAppointment?: {
        __typename?: 'DeleteAppointmentResponse';
        success: boolean;
        message: string;
        status: number;
        id?: string | null;
    } | null;
};

export type GetPatientReportByKeyQueryVariables = Exact<{
    input: S3GetObjectInput;
}>;

export type GetPatientReportByKeyQuery = {
    __typename?: 'Query';
    getPatientReportByKey?: {
        __typename?: 'ReportBufferByKeyResponse';
        data?: Array<string | null> | null;
        filename?: string | null;
    } | null;
};

export type FetchLoadingStateQueryVariables = Exact<{ [key: string]: never }>;

export type FetchLoadingStateQuery = {
    __typename?: 'Query';
    GlobalLoading: { __typename?: 'GlobalLoading'; open: boolean; message: string };
};

export type FetchTagsForFilterModalPageQueryVariables = Exact<{
    input?: InputMaybe<TagQueryInput>;
}>;

export type FetchTagsForFilterModalPageQuery = {
    __typename?: 'Query';
    tagsV2: {
        __typename?: 'TagPaginationQueryResponse';
        results: Array<{
            __typename?: 'Tag';
            id: any;
            name: string;
            isPatientFilter?: boolean | null;
        }>;
    };
};

export type GetUsersByPermissionForFilterModalPageQueryVariables = Exact<{
    permission?: InputMaybe<Scalars['String']>;
}>;

export type GetUsersByPermissionForFilterModalPageQuery = {
    __typename?: 'Query';
    getUsersByPermission?: Array<{ __typename?: 'User'; id: any; name: string } | null> | null;
};

export type UpdateMeasurementReviewedMutationVariables = Exact<{
    input: UpdateMeasurementReviewedInput;
}>;

export type UpdateMeasurementReviewedMutation = {
    __typename?: 'Mutation';
    UpdateMeasurementReviewed?: {
        __typename?: 'UpdateMeasurementReviewedResponse';
        status: number;
        message: string;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'Measurement';
            id: any;
            flagged?: boolean | null;
            externalId?: string | null;
            updatedAt: any;
            createdAt: any;
            type?: MeasurementType | null;
            takenDate?: any | null;
            timezoneName?: string | null;
            reviewed?: boolean | null;
            inputType: MeasurementInputSource;
            comment?: string | null;
            hasHistory?: boolean | null;
            value: {
                __typename?: 'MeasurementValue';
                movement?: { __typename?: 'MovementMeasurement'; duration?: string | null } | null;
                weight?: {
                    __typename?: 'WeightMeasurement';
                    measure?: number | null;
                    units?: string | null;
                } | null;
                bloodPressure?: {
                    __typename?: 'BloodPressureMeasurement';
                    pulse?: number | null;
                    diastolic?: number | null;
                    systolic?: number | null;
                } | null;
                dailyMeasurement?: {
                    __typename?: 'DailyMeasurement';
                    glucose?: number | null;
                    moodLevel?: number | null;
                    painLevel?: number | null;
                    steps?: number | null;
                    temperature?: number | null;
                    sleepStarts?: string | null;
                    sleepEnds?: string | null;
                    notes?: string | null;
                } | null;
                bloodGlucose?: {
                    __typename?: 'BloodGlucoseMeasurement';
                    dateTime?: any | null;
                    activityOption?: string | null;
                    glucose?: number | null;
                    meal?: string | null;
                    mealOption?: string | null;
                    mealTime?: any | null;
                    movement?: string | null;
                    notes?: string | null;
                } | null;
            };
            patient?: {
                __typename?: 'Patient';
                id: any;
                externalId?: string | null;
                firstName: string;
                lastName: string;
                fullName: string;
                birthDate: any;
                tagIds?: Array<any | null> | null;
                assignedHealthAdvocate?: any | null;
                practice: { __typename?: 'Organization'; timezone?: string | null };
            } | null;
            device?: { __typename?: 'Device'; id: any; localizedName?: string | null } | null;
        } | null;
    } | null;
};

export type FetchMeasurementsForMeasurementsPageQueryQueryVariables = Exact<{
    input: UserMeasurementsInput;
}>;

export type FetchMeasurementsForMeasurementsPageQueryQuery = {
    __typename?: 'Query';
    currentUser?: {
        __typename?: 'User';
        measurementsV2: {
            __typename?: 'UserMeasurementsResponse';
            orderBy?: string | null;
            skip?: number | null;
            limit?: number | null;
            total: number;
            hasMore?: boolean | null;
            hasPrevious?: boolean | null;
            results: Array<{
                __typename?: 'Measurement';
                id: any;
                flagged?: boolean | null;
                externalId?: string | null;
                updatedAt: any;
                createdAt: any;
                type?: MeasurementType | null;
                takenDate?: any | null;
                timezoneName?: string | null;
                reviewed?: boolean | null;
                inputType: MeasurementInputSource;
                comment?: string | null;
                hasHistory?: boolean | null;
                value: {
                    __typename?: 'MeasurementValue';
                    movement?: {
                        __typename?: 'MovementMeasurement';
                        duration?: string | null;
                    } | null;
                    weight?: {
                        __typename?: 'WeightMeasurement';
                        measure?: number | null;
                        units?: string | null;
                    } | null;
                    bloodPressure?: {
                        __typename?: 'BloodPressureMeasurement';
                        pulse?: number | null;
                        diastolic?: number | null;
                        systolic?: number | null;
                    } | null;
                    dailyMeasurement?: {
                        __typename?: 'DailyMeasurement';
                        glucose?: number | null;
                        moodLevel?: number | null;
                        painLevel?: number | null;
                        steps?: number | null;
                        temperature?: number | null;
                        sleepStarts?: string | null;
                        sleepEnds?: string | null;
                        notes?: string | null;
                    } | null;
                    bloodGlucose?: {
                        __typename?: 'BloodGlucoseMeasurement';
                        dateTime?: any | null;
                        activityOption?: string | null;
                        glucose?: number | null;
                        meal?: string | null;
                        mealOption?: string | null;
                        mealTime?: any | null;
                        movement?: string | null;
                        notes?: string | null;
                    } | null;
                };
                patient?: {
                    __typename?: 'Patient';
                    id: any;
                    externalId?: string | null;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    birthDate: any;
                    tagIds?: Array<any | null> | null;
                    assignedHealthAdvocate?: any | null;
                    practice: { __typename?: 'Organization'; timezone?: string | null };
                } | null;
                device?: { __typename?: 'Device'; id: any; localizedName?: string | null } | null;
            }>;
        };
    } | null;
};

export type FetchReferenceLinksForMeasurmentsPageQueryVariables = Exact<{
    input: GetReferenceLinksForPageInput;
}>;

export type FetchReferenceLinksForMeasurmentsPageQuery = {
    __typename?: 'Query';
    getReferenceLinksForPage?: Array<{
        __typename?: 'GetReferenceLinksForPageResponse';
        category?: string | null;
        data?: Array<{
            __typename?: 'LinkDataType';
            label?: string | null;
            url?: string | null;
        } | null> | null;
    } | null> | null;
};

export type FetchTrackerHistoryByRecordIdQueryVariables = Exact<{
    uuid?: InputMaybe<Scalars['String']>;
}>;

export type FetchTrackerHistoryByRecordIdQuery = {
    __typename?: 'Query';
    getTrackerHistoryByRecordId?: Array<{
        __typename?: 'TrackerHistory';
        updatedAt: any;
        createdAt: any;
        movement?: string | null;
        startDate?: any | null;
        endDate?: any | null;
        weight?: number | null;
        pulse?: number | null;
        diastolicBP?: number | null;
        systolicBP?: number | null;
        glucose?: number | null;
        moodLevel?: number | null;
        painLevel?: number | null;
        steps?: number | null;
        temperature?: number | null;
        sleepStart?: string | null;
        sleepEnd?: string | null;
        notes?: string | null;
        type?: string | null;
        timezoneName?: string | null;
    } | null> | null;
};

export type ChatConversationsV2ForMessageCenterQueryVariables = Exact<{
    input?: InputMaybe<ChatConversationQueryInput>;
}>;

export type ChatConversationsV2ForMessageCenterQuery = {
    __typename?: 'Query';
    chatConversationsV2: {
        __typename?: 'ChatConversationPaginationQueryResponse';
        results: Array<{
            __typename?: 'ChatConversation';
            id: any;
            startedAt?: any | null;
            resolvedAt?: any | null;
            chatRoomId: any;
            resolutionTime?: number | null;
            createdAt: any;
            messages?: Array<{
                __typename?: 'ChatMessage';
                id: any;
                text?: string | null;
                attachments?: Array<string | null> | null;
                senderId?: string | null;
                read?: Array<string | null> | null;
                chatRoomId: any;
                chatConversationId: any;
                createdAt: any;
                updatedAt: any;
            }> | null;
        }>;
    };
};

export type ResolveChatConversationForMessageCenterMutationVariables = Exact<{
    input?: InputMaybe<ResolveChatConversationInput>;
}>;

export type ResolveChatConversationForMessageCenterMutation = {
    __typename?: 'Mutation';
    resolveChatConversation?: {
        __typename?: 'ResolveChatConversationResponse';
        status: number;
        message: string;
        success: boolean;
        chatConversations?: Array<{
            __typename?: 'ChatConversation';
            id: any;
            startedAt?: any | null;
            resolvedAt?: any | null;
            chatRoomId: any;
            resolutionTime?: number | null;
            createdAt: any;
            messages?: Array<{
                __typename?: 'ChatMessage';
                id: any;
                text?: string | null;
                attachments?: Array<string | null> | null;
                senderId?: string | null;
                read?: Array<string | null> | null;
                chatRoomId: any;
                chatConversationId: any;
                createdAt: any;
                updatedAt: any;
            }> | null;
        } | null> | null;
    } | null;
};

export type MoveMessagesInChatConversationForMessageCenterMutationVariables = Exact<{
    input?: InputMaybe<MoveMessagesInChatConversationInput>;
}>;

export type MoveMessagesInChatConversationForMessageCenterMutation = {
    __typename?: 'Mutation';
    moveMessagesInChatConversation?: {
        __typename?: 'MoveMessagesInChatConversationResponse';
        status: number;
        message: string;
        success: boolean;
        chatConversations?: Array<{
            __typename?: 'ChatConversation';
            id: any;
            startedAt?: any | null;
            resolvedAt?: any | null;
            chatRoomId: any;
            resolutionTime?: number | null;
            createdAt: any;
            messages?: Array<{
                __typename?: 'ChatMessage';
                id: any;
                text?: string | null;
                attachments?: Array<string | null> | null;
                senderId?: string | null;
                read?: Array<string | null> | null;
                chatRoomId: any;
                chatConversationId: any;
                createdAt: any;
                updatedAt: any;
            }> | null;
        } | null> | null;
    } | null;
};

export type FetchChatRoomMessagesForMessageCenterQueryVariables = Exact<{
    input: GetChatRoomInput;
}>;

export type FetchChatRoomMessagesForMessageCenterQuery = {
    __typename?: 'Query';
    chatRoom?: {
        __typename?: 'ChatRoom';
        id: any;
        numberOfUnreadMessages?: number | null;
        careTeamMemberTypeId: any;
        careTeamMemberType: { __typename?: 'CareTeamMemberType'; name: string };
        patient: {
            __typename?: 'Patient';
            id: any;
            firstName: string;
            lastName: string;
            practice: { __typename?: 'Organization'; name: string };
        };
        participants: Array<{ __typename?: 'User'; id: any; name: string }>;
        messages: Array<{
            __typename?: 'ChatMessage';
            id: any;
            createdAt: any;
            updatedAt: any;
            read?: Array<string | null> | null;
            text?: string | null;
            attachments?: Array<string | null> | null;
            senderId?: string | null;
            chatRoomId: any;
            chatConversationId: any;
        }>;
    } | null;
};

export type UpdateParticipantsForChatRoomMutationVariables = Exact<{
    input: UpdateParticipantsForChatRoomInput;
}>;

export type UpdateParticipantsForChatRoomMutation = {
    __typename?: 'Mutation';
    updateParticipants?: {
        __typename?: 'UpdateParticipantsForChatRoomResponse';
        success: boolean;
        chatRoom?: {
            __typename?: 'ChatRoom';
            participants: Array<{ __typename?: 'User'; id: any; name: string }>;
        } | null;
    } | null;
};

export type UpdateReadMessagesForChatRoomMutationVariables = Exact<{
    input: UpdateReadeMessagesForChatRoomInput;
}>;

export type UpdateReadMessagesForChatRoomMutation = {
    __typename?: 'Mutation';
    updateReadMessages?: {
        __typename?: 'UpdateReadeMessagesForChatRoomResponse';
        message: string;
        success: boolean;
        chatRoom?: {
            __typename?: 'ChatRoom';
            id: any;
            numberOfUnreadMessages?: number | null;
            careTeamMemberTypeId: any;
            patient: { __typename?: 'Patient'; id: any; firstName: string; lastName: string };
            participants: Array<{ __typename?: 'User'; id: any; name: string }>;
            messages: Array<{
                __typename?: 'ChatMessage';
                id: any;
                createdAt: any;
                updatedAt: any;
                read?: Array<string | null> | null;
                text?: string | null;
                attachments?: Array<string | null> | null;
                senderId?: string | null;
                chatRoomId: any;
                chatConversationId: any;
            }>;
        } | null;
    } | null;
};

export type SubscribeToNewChatMessagesForChatRoomSubscriptionVariables = Exact<{
    input: NewMessageInput;
}>;

export type SubscribeToNewChatMessagesForChatRoomSubscription = {
    __typename?: 'Subscription';
    newMessage?: {
        __typename?: 'ChatMessage';
        id: any;
        createdAt: any;
        updatedAt: any;
        read?: Array<string | null> | null;
        text?: string | null;
        attachments?: Array<string | null> | null;
        senderId?: string | null;
        chatRoomId: any;
        chatConversationId: any;
    } | null;
};

export type CreateChatMessageForMessageCenterMutationVariables = Exact<{
    input: ChatMessageInput;
}>;

export type CreateChatMessageForMessageCenterMutation = {
    __typename?: 'Mutation';
    createChatMessageEncoded?: {
        __typename?: 'CreateChatMessageResponse';
        message: string;
        success: boolean;
        chatMessage?: {
            __typename?: 'ChatMessage';
            id: any;
            createdAt: any;
            updatedAt: any;
            read?: Array<string | null> | null;
            text?: string | null;
            attachments?: Array<string | null> | null;
            senderId?: string | null;
            chatRoomId: any;
            chatConversationId: any;
        } | null;
    } | null;
};

export type FetchCurrentUserForChatMessagesQueryVariables = Exact<{ [key: string]: never }>;

export type FetchCurrentUserForChatMessagesQuery = {
    __typename?: 'Query';
    currentUser?: { __typename?: 'User'; id: any; name: string } | null;
};

export type FetchChatRoomsForMessageCenterQueryVariables = Exact<{
    input?: InputMaybe<UserChatRoomsInput>;
}>;

export type FetchChatRoomsForMessageCenterQuery = {
    __typename?: 'Query';
    currentUser?: {
        __typename?: 'User';
        id: any;
        name: string;
        email: string;
        currentOrg: {
            __typename?: 'Organization';
            id: any;
            parentId?: any | null;
            brandingName: string;
        };
        chatRooms: Array<{
            __typename?: 'ChatRoom';
            id: any;
            numberOfUnreadMessages?: number | null;
            careTeamMemberTypeId: any;
            careTeamMemberType: { __typename?: 'CareTeamMemberType'; name: string };
            patient: {
                __typename?: 'Patient';
                id: any;
                firstName: string;
                lastName: string;
                fullName: string;
                birthDate: any;
                email?: string | null;
                phoneNumber?: string | null;
                mailingAddress?: { __typename?: 'Address'; code?: string | null } | null;
                practice: { __typename?: 'Organization'; name: string };
            };
            participants: Array<{ __typename?: 'User'; id: any; name: string }>;
            latestMessage?: {
                __typename?: 'ChatMessage';
                id: any;
                createdAt: any;
                updatedAt: any;
                read?: Array<string | null> | null;
                text?: string | null;
                attachments?: Array<string | null> | null;
                senderId?: string | null;
                chatRoomId: any;
                chatConversationId: any;
            } | null;
        }>;
    } | null;
};

export type FetchChatMessagesForMessageCenterQueryVariables = Exact<{
    input?: InputMaybe<ChatMessageQueryInput>;
}>;

export type FetchChatMessagesForMessageCenterQuery = {
    __typename?: 'Query';
    chatMessagesV2: {
        __typename?: 'ChatMessagePaginationQueryResponse';
        results: Array<{
            __typename?: 'ChatMessage';
            text?: string | null;
            senderId?: string | null;
            createdAt: any;
            chatRoomId: any;
            attachments?: Array<string | null> | null;
            read?: Array<string | null> | null;
            updatedAt: any;
            id: any;
            cacheId?: string | null;
        }>;
    };
};

export type FetchReferenceLinksForMessageCenterPageQueryVariables = Exact<{
    input: GetReferenceLinksForPageInput;
}>;

export type FetchReferenceLinksForMessageCenterPageQuery = {
    __typename?: 'Query';
    getReferenceLinksForPage?: Array<{
        __typename?: 'GetReferenceLinksForPageResponse';
        category?: string | null;
        data?: Array<{
            __typename?: 'LinkDataType';
            label?: string | null;
            url?: string | null;
        } | null> | null;
    } | null> | null;
};

export type CreateNewOrderMutationVariables = Exact<{
    input: OrderInput;
}>;

export type CreateNewOrderMutation = {
    __typename?: 'Mutation';
    createOrder?: {
        __typename?: 'CreateOrderResponse';
        success: boolean;
        status: number;
        message: string;
        resourceCreated?: {
            __typename?: 'Order';
            visitId?: string | null;
            updatedAt: any;
            transactionDateTime?: string | null;
            createdAt: any;
            isTestData?: boolean | null;
            id: any;
            redoxEhrId?: string | null;
            redoxSourceId?: string | null;
            patientId: any;
            practiceId?: any | null;
            isDeviceOrder?: boolean | null;
            orderId?: string | null;
            status?: string | null;
            procedure?: string | null;
            procedureDescription?: string | null;
            providerNPI?: string | null;
            providerFirstName?: string | null;
            providerLastName?: string | null;
            comments?: string | null;
            notes?: Array<string | null> | null;
            patientFullName: string;
            diagnoses?: Array<{
                __typename?: 'Diagnoses';
                code?: string | null;
                codeset?: string | null;
                name?: string | null;
                type?: string | null;
                documentedDateTime?: string | null;
            }> | null;
            clinicalInfo?: Array<{
                __typename?: 'ClinicalInfo';
                code?: string | null;
                codeset?: string | null;
                description?: string | null;
                value?: string | null;
                units?: string | null;
                abbreviation?: string | null;
                notes?: Array<string | null> | null;
            }> | null;
        } | null;
    } | null;
};

export type OrdersForOrdersPageQueryVariables = Exact<{
    input: OrdersV2ByFilterInput;
}>;

export type OrdersForOrdersPageQuery = {
    __typename?: 'Query';
    ordersV2?: {
        __typename?: 'OrdersV2Response';
        orderBy?: string | null;
        skip?: number | null;
        limit?: number | null;
        total: number;
        hasMore?: boolean | null;
        hasPrevious?: boolean | null;
        results: Array<{
            __typename?: 'Order';
            isTestData?: boolean | null;
            id: any;
            redoxEhrId?: string | null;
            patientId: any;
            patientFullName: string;
            isDeviceOrder?: boolean | null;
            transactionDateTime?: string | null;
            procedure?: string | null;
            procedureDescription?: string | null;
            status?: string | null;
        }>;
    } | null;
};

export type OrderForOrderModalQueryVariables = Exact<{
    input: GetOrderInput;
}>;

export type OrderForOrderModalQuery = {
    __typename?: 'Query';
    order?: {
        __typename?: 'Order';
        createdAt: any;
        updatedAt: any;
        isTestData?: boolean | null;
        id: any;
        redoxEhrId?: string | null;
        redoxSourceId?: string | null;
        patientId: any;
        patientFullName: string;
        isDeviceOrder?: boolean | null;
        orderId?: string | null;
        visitId?: string | null;
        status?: string | null;
        transactionDateTime?: string | null;
        procedure?: string | null;
        procedureDescription?: string | null;
        providerNPI?: string | null;
        providerFirstName?: string | null;
        providerLastName?: string | null;
        comments?: string | null;
        notes?: Array<string | null> | null;
        diagnoses?: Array<{
            __typename?: 'Diagnoses';
            code?: string | null;
            codeset?: string | null;
            name?: string | null;
            type?: string | null;
            documentedDateTime?: string | null;
        }> | null;
        clinicalInfo?: Array<{
            __typename?: 'ClinicalInfo';
            code?: string | null;
            codeset?: string | null;
            description?: string | null;
            value?: string | null;
            units?: string | null;
            abbreviation?: string | null;
            notes?: Array<string | null> | null;
        }> | null;
    } | null;
};

export type DeleteOrderForOrdersPageMutationVariables = Exact<{
    input: DeleteOrderInput;
}>;

export type DeleteOrderForOrdersPageMutation = {
    __typename?: 'Mutation';
    deleteOrder?: {
        __typename?: 'DeleteOrderResponse';
        success: boolean;
        message: string;
        status: number;
        id?: string | null;
    } | null;
};

export type FetchReferenceLinksForOrdersPageQueryVariables = Exact<{
    input: GetReferenceLinksForPageInput;
}>;

export type FetchReferenceLinksForOrdersPageQuery = {
    __typename?: 'Query';
    getReferenceLinksForPage?: Array<{
        __typename?: 'GetReferenceLinksForPageResponse';
        category?: string | null;
        data?: Array<{
            __typename?: 'LinkDataType';
            label?: string | null;
            url?: string | null;
        } | null> | null;
    } | null> | null;
};

export type FetchPatientCallListsForDashboardQueryVariables = Exact<{
    input?: InputMaybe<PatientCallListQueryInput>;
}>;

export type FetchPatientCallListsForDashboardQuery = {
    __typename?: 'Query';
    patientCallListsV2: {
        __typename?: 'PatientCallListPaginationQueryResponse';
        total: number;
        results: Array<{
            __typename?: 'PatientCallList';
            id: any;
            name: string;
            createdAt: any;
            user?: { __typename?: 'User'; name: string } | null;
            tags?: Array<{ __typename?: 'Tag'; id: any; name: string }> | null;
        }>;
    };
};

export type FetchPatientCallListsExportToCsvQueryVariables = Exact<{
    input?: InputMaybe<ExportToCsvCallListFilterInput>;
}>;

export type FetchPatientCallListsExportToCsvQuery = {
    __typename?: 'Query';
    PatientCallListsExportToCSV?: {
        __typename?: 'PatientCallListExportResponse';
        data?: Array<string | null> | null;
        fileName: string;
    } | null;
};

export type DeletePatientCallListForDashboardMutationVariables = Exact<{
    input: DeletePatientCallListInput;
}>;

export type DeletePatientCallListForDashboardMutation = {
    __typename?: 'Mutation';
    deletePatientCallList?: {
        __typename?: 'DeletePatientCallListResponse';
        status: number;
        message: string;
        success: boolean;
        id?: string | null;
    } | null;
};

export type PreFetchTagsPatientCallListQueryVariables = Exact<{ [key: string]: never }>;

export type PreFetchTagsPatientCallListQuery = {
    __typename?: 'Query';
    tags: Array<{ __typename?: 'Tag'; id: any; name: string }>;
};

export type CreatePatientCallListMutationVariables = Exact<{
    input: PatientCallListInput;
}>;

export type CreatePatientCallListMutation = {
    __typename?: 'Mutation';
    createPatientCallList?: {
        __typename?: 'CreatePatientCallListResponse';
        status: number;
        message: string;
        success: boolean;
    } | null;
};

export type UpdatePatientCallListMutationVariables = Exact<{
    input: PatientCallListUpdateInput;
}>;

export type UpdatePatientCallListMutation = {
    __typename?: 'Mutation';
    updatePatientCallList?: {
        __typename?: 'UpdatePatientCallListResponse';
        status: number;
        message: string;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'PatientCallList';
            id: any;
            name: string;
            createdAt: any;
            user?: { __typename?: 'User'; name: string } | null;
            tags?: Array<{ __typename?: 'Tag'; id: any; name: string }> | null;
        } | null;
    } | null;
};

export type DeletePatientCallListMutationVariables = Exact<{
    input: DeletePatientCallListInput;
}>;

export type DeletePatientCallListMutation = {
    __typename?: 'Mutation';
    deletePatientCallList?: {
        __typename?: 'DeletePatientCallListResponse';
        status: number;
        message: string;
        success: boolean;
    } | null;
};

export type FetchPatientCallListForPreviewQueryVariables = Exact<{
    input: GetPatientCallListInput;
    patientsToCallInput2?: InputMaybe<GetPatientsByFilterInput>;
}>;

export type FetchPatientCallListForPreviewQuery = {
    __typename?: 'Query';
    patientCallList?: {
        __typename?: 'PatientCallList';
        name: string;
        patientsToCall?: {
            __typename?: 'PatientToCallResponse';
            limit?: number | null;
            skip?: number | null;
            total: number;
            results: Array<{
                __typename?: 'PatientToCall';
                called?: boolean | null;
                patient?: {
                    __typename?: 'Patient';
                    id: any;
                    createdAt: any;
                    invitationCode?: string | null;
                    externalId?: string | null;
                    firstName: string;
                    lastName: string;
                    assignedHealthAdvocate?: any | null;
                    openAdvocateTaskCount?: number | null;
                    openCriticalAdvocateTaskCount?: number | null;
                    onboardingDate?: any | null;
                    patientCallStatus?: string | null;
                    birthDate: any;
                    onboardingCompleteDate?: any | null;
                    isPregnant?: boolean | null;
                    appBundleId?: string | null;
                    dueDate?: any | null;
                    deliveryDate?: any | null;
                    email?: string | null;
                    practice: { __typename?: 'Organization'; name: string };
                } | null;
            }>;
        } | null;
    } | null;
};

export type FetchPatientCallListPreviewExportToCsvQueryVariables = Exact<{
    input?: InputMaybe<ExportToCsvCallListPreviewInput>;
}>;

export type FetchPatientCallListPreviewExportToCsvQuery = {
    __typename?: 'Query';
    PatientCallListPreviewExportToCSV?: {
        __typename?: 'PatientCallListExportResponse';
        data?: Array<string | null> | null;
        fileName: string;
    } | null;
};

export type UpdatePatientCallListCallStatusForPreviewMutationVariables = Exact<{
    input?: InputMaybe<UpdatePatientCallListCallStatusInput>;
}>;

export type UpdatePatientCallListCallStatusForPreviewMutation = {
    __typename?: 'Mutation';
    updatePatientCallListCallStatus?: {
        __typename?: 'UpdatePatientCallListResponse';
        status: number;
        message: string;
        success: boolean;
    } | null;
};

export type FetchTagsForFilterPatientsModalQueryVariables = Exact<{
    input?: InputMaybe<TagQueryInput>;
}>;

export type FetchTagsForFilterPatientsModalQuery = {
    __typename?: 'Query';
    tagsV2: {
        __typename?: 'TagPaginationQueryResponse';
        results: Array<{
            __typename?: 'Tag';
            id: any;
            name: string;
            isPatientFilter?: boolean | null;
        }>;
    };
};

export type GetUsersByPermissionForFilterPatientsModalQueryVariables = Exact<{
    permission?: InputMaybe<Scalars['String']>;
}>;

export type GetUsersByPermissionForFilterPatientsModalQuery = {
    __typename?: 'Query';
    getUsersByPermission?: Array<{ __typename?: 'User'; id: any; name: string } | null> | null;
};

export type FetchPatientForPatientSummaryPageQueryQueryVariables = Exact<{
    input: GetPatientInput;
}>;

export type FetchPatientForPatientSummaryPageQueryQuery = {
    __typename?: 'Query';
    patient?: {
        __typename?: 'Patient';
        id: any;
        email?: string | null;
        fullName: string;
        firstName: string;
        lastName: string;
        assignedHealthAdvocate?: any | null;
        patientHeaderData?: Array<{
            __typename?: 'PatientHeaderData';
            label?: string | null;
            value?: string | null;
        }> | null;
        practice: { __typename?: 'Organization'; id: any };
    } | null;
};

export type FetchActionsForPatientActionsPageQueryQueryVariables = Exact<{
    actionInput: PatientActionQueryInput;
}>;

export type FetchActionsForPatientActionsPageQueryQuery = {
    __typename?: 'Query';
    patientActionsV2: {
        __typename?: 'PatientActionPaginationQueryResponse';
        results: Array<{
            __typename?: 'PatientAction';
            id: any;
            updatedAt: any;
            description?: string | null;
            taskId?: any | null;
            stepId?: any | null;
            patientId?: any | null;
            source: ActionSource;
            completedAt?: any | null;
            typeId?: any | null;
            type?: { __typename?: 'PatientActionType'; id: any; label: string } | null;
        }>;
    };
};

export type FetchPatientActionTypesForPatientActionsPageQueryVariables = Exact<{
    [key: string]: never;
}>;

export type FetchPatientActionTypesForPatientActionsPageQuery = {
    __typename?: 'Query';
    patientActionTypesV2: {
        __typename?: 'PatientActionTypePaginationQueryResponse';
        results: Array<{
            __typename?: 'PatientActionType';
            label: string;
            updatedAt: any;
            createdAt: any;
            id: any;
        }>;
    };
};

export type CreatePatientActionForPatientActionPageMutationVariables = Exact<{
    input: PatientActionInput;
}>;

export type CreatePatientActionForPatientActionPageMutation = {
    __typename?: 'Mutation';
    createPatientAction?: {
        __typename?: 'CreatePatientActionResponse';
        status: number;
        message: string;
        success: boolean;
        resourceCreated?: {
            __typename?: 'PatientAction';
            updatedAt: any;
            description?: string | null;
            taskId?: any | null;
            typeId?: any | null;
        } | null;
    } | null;
};

export type UpdatePatientActionForPatientActionPageMutationVariables = Exact<{
    input: UpdatePatientActionInput;
}>;

export type UpdatePatientActionForPatientActionPageMutation = {
    __typename?: 'Mutation';
    updatePatientAction?: {
        __typename?: 'UpdatePatientActionResponse';
        status: number;
        message: string;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'PatientAction';
            id: any;
            updatedAt: any;
            description?: string | null;
            taskId?: any | null;
            typeId?: any | null;
            patientId?: any | null;
            source: ActionSource;
        } | null;
    } | null;
};

export type DeletePatientActionForPatientActionPageMutationVariables = Exact<{
    input: DeletePatientActionInput;
}>;

export type DeletePatientActionForPatientActionPageMutation = {
    __typename?: 'Mutation';
    deletePatientAction?: {
        __typename?: 'DeletePatientActionResponse';
        success: boolean;
        message: string;
        status: number;
        id?: string | null;
    } | null;
};

export type FetchAppUserTelemetryQueryVariables = Exact<{
    input: AppUserTelemetryPaginationInput;
}>;

export type FetchAppUserTelemetryQuery = {
    __typename?: 'Query';
    appUserTelemetrys?: {
        __typename?: 'AppUserTelemetryResult';
        itemcount?: number | null;
        eventTypes?: Array<string | null> | null;
        items?: Array<{
            __typename?: 'AppUserTelemetry';
            eventType: string;
            appUserId?: any | null;
            data?: any | null;
            eventDate: any;
        } | null> | null;
    } | null;
};

export type FetchApptsForPatientApptsPageQueryQueryVariables = Exact<{
    patientInput: GetPatientInput;
    appointmentInput: PatientAppointmentsQueryInput;
}>;

export type FetchApptsForPatientApptsPageQueryQuery = {
    __typename?: 'Query';
    patient?: {
        __typename?: 'Patient';
        id: any;
        createdAt: any;
        externalId?: string | null;
        memberId?: string | null;
        fullName: string;
        firstName: string;
        lastName: string;
        onboardingDate?: any | null;
        email?: string | null;
        birthDate: any;
        phoneNumber?: string | null;
        invitationMode?: PatientInvitation | null;
        invitationCode?: string | null;
        signUpPracticeCode?: string | null;
        isTestData?: boolean | null;
        assignedHealthAdvocate?: any | null;
        openAdvocateTaskCount?: number | null;
        openCriticalAdvocateTaskCount?: number | null;
        onboardingCompleteDate?: any | null;
        isPregnant?: boolean | null;
        dueDate?: any | null;
        deliveryDate?: any | null;
        patientCallStatus?: string | null;
        appUserId?: any | null;
        appBundleId?: string | null;
        appointments: Array<{
            __typename?: 'Appointment';
            id: any;
            externalId?: string | null;
            startDate: any;
            durationMinutes?: number | null;
            staffName?: string | null;
            physicalLocation?: string | null;
            description?: string | null;
            isVirtualVisit?: boolean | null;
            virtualLink?: string | null;
            visitType?: string | null;
            source: AppointmentSource;
            note?: string | null;
            reportKey?: string | null;
            timezoneName?: string | null;
            patient: {
                __typename?: 'Patient';
                id: any;
                fullName: string;
                firstName: string;
                lastName: string;
                birthDate: any;
            };
            practice?: {
                __typename?: 'Organization';
                id: any;
                name: string;
                timezone?: string | null;
            } | null;
            staff?: { __typename?: 'User'; id: any; name: string } | null;
        }>;
        attachments?: Array<{
            __typename?: 'Attachment';
            uuid: string;
            label: string;
        } | null> | null;
        riskIndicators?: {
            __typename?: 'PatientRiskIndicators';
            obesity?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            preterm?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            chronicHypertension?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            other?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
        } | null;
        mailingAddress?: {
            __typename?: 'Address';
            street1?: string | null;
            street2?: string | null;
            city?: string | null;
            state?: string | null;
            code?: string | null;
        } | null;
        practice: {
            __typename?: 'Organization';
            id: any;
            name: string;
            timezone?: string | null;
            providers?: Array<{
                __typename?: 'OrganizationProvider';
                provider: {
                    __typename?: 'Provider';
                    id: any;
                    localizedName: string;
                    createdAt: any;
                };
            }> | null;
        };
        questions: Array<{
            __typename?: 'CombinedQuestion';
            id: string;
            createdAt: any;
            updatedAt: any;
            submittedAt: any;
            text: string;
            isDone: boolean;
        }>;
        notes: Array<{
            __typename?: 'PatientNotes';
            id: any;
            text?: string | null;
            title?: string | null;
            staffName?: string | null;
            createdAt: any;
        }>;
        chatRoom: { __typename?: 'ChatRoom'; id: any; numberOfUnreadMessages?: number | null };
        profileValues: Array<{
            __typename?: 'PatientProfileValue';
            profileVariableDefId?: any | null;
            type?: ProfileValueType | null;
            valueBool?: boolean | null;
            valueNum?: number | null;
            valueStr?: string | null;
            valueId?: any | null;
            valueIds?: Array<any | null> | null;
            valueDate?: any | null;
            timeZone?: string | null;
            utcOffset?: number | null;
        }>;
        riskScore?: {
            __typename?: 'RiskScoreResponse';
            value?: number | null;
            tierName?: string | null;
        } | null;
    } | null;
};

export type DeletePatientAppointmentByIdMutationVariables = Exact<{
    input: DeleteAppointmentInput;
}>;

export type DeletePatientAppointmentByIdMutation = {
    __typename?: 'Mutation';
    deleteAppointment?: {
        __typename?: 'DeleteAppointmentResponse';
        success: boolean;
        message: string;
        status: number;
        id?: string | null;
    } | null;
};

export type FetchPatientArticleFeedQueryVariables = Exact<{
    appUserId?: InputMaybe<Scalars['String']>;
}>;

export type FetchPatientArticleFeedQuery = {
    __typename?: 'Query';
    AppUserArticleFeed?: {
        __typename?: 'AppUserArticleFeedResult';
        items: Array<{
            __typename?: 'AppUserArticleFeed';
            id: string;
            title: string;
            summary: string;
            availableUntil: any;
        }>;
    } | null;
};

export type FetchPatientAppUsersForArticleFeedQueryVariables = Exact<{
    input: GetPatientInput;
}>;

export type FetchPatientAppUsersForArticleFeedQuery = {
    __typename?: 'Query';
    patient?: {
        __typename?: 'Patient';
        id: any;
        appUserId?: any | null;
        appBundleId?: string | null;
    } | null;
};

export type ProfileVariableForPatientCareTeamQueryVariables = Exact<{
    patientId?: InputMaybe<Scalars['String']>;
    profileVariableDefId?: InputMaybe<Scalars['String']>;
}>;

export type ProfileVariableForPatientCareTeamQuery = {
    __typename?: 'Query';
    profileVariable?: {
        __typename?: 'ProfileVariable';
        familyId?: string | null;
        keyId?: any | null;
        patientId?: string | null;
        profileVariableDefId: string;
        value?: { __typename?: 'ProfileValueData'; str?: string | null } | null;
    } | null;
};

export type UsersV2ForPatientCareTeamQueryVariables = Exact<{ [key: string]: never }>;

export type UsersV2ForPatientCareTeamQuery = {
    __typename?: 'Query';
    usersV2: {
        __typename?: 'UserPaginationQueryResponse';
        results: Array<{
            __typename?: 'User';
            id: any;
            name: string;
            role?: {
                __typename?: 'Role';
                permissions?: Array<{
                    __typename?: 'Permissions';
                    permission?: Permission | null;
                    target?: Target | null;
                    canExport?: boolean | null;
                } | null> | null;
            } | null;
        }>;
    };
};

export type CareTeamMemberTypesV2ForPatientCareTeamQueryVariables = Exact<{ [key: string]: never }>;

export type CareTeamMemberTypesV2ForPatientCareTeamQuery = {
    __typename?: 'Query';
    careTeamMemberTypesV2: {
        __typename?: 'CareTeamMemberTypePaginationQueryResponse';
        total: number;
        results: Array<{
            __typename?: 'CareTeamMemberType';
            id: any;
            name: string;
            profileDefId: any;
            createdAt: any;
            updatedAt: any;
            profileDef?: {
                __typename?: 'UserProfileDef';
                name: string;
                createdAt: any;
                id: any;
                updatedAt: any;
                category: string;
                valueType: UserProfileValueType;
            } | null;
        }>;
    };
};

export type CareTeamMembersV2ForPatientCareTeamQueryVariables = Exact<{
    input?: InputMaybe<CareTeamMemberQueryInput>;
}>;

export type CareTeamMembersV2ForPatientCareTeamQuery = {
    __typename?: 'Query';
    careTeamMembersV2: {
        __typename?: 'CareTeamMemberPaginationQueryResponse';
        total: number;
        results: Array<{
            __typename?: 'CareTeamMember';
            id: any;
            careTeamMemberTypeId?: any | null;
            nameWithType: string;
            careTeamMemberContactId?: any | null;
            careTeamMemberTIN?: string | null;
            careTeamMemberNPI?: string | null;
            practiceExternalId?: string | null;
            practiceCode?: string | null;
            prerequisiteTeamMembers?: Array<any> | null;
            eligibilityCheck?: string | null;
            eligibilityRecheckDays?: number | null;
            consentRequired?: boolean | null;
            consentAgreement?: string | null;
            appBundleId: string;
            usState?: string | null;
            zipCode?: string | null;
            updatedAt: any;
            createdAt: any;
            isActive?: boolean | null;
            userId?: any | null;
            appDefault?: boolean | null;
            careTeamMemberType?: {
                __typename?: 'CareTeamMemberType';
                name: string;
                profileDefId: any;
                createdAt: any;
                updatedAt: any;
                id: any;
            } | null;
            overridenCareTeamMemberContact?: {
                __typename?: 'CareTeamContactForCareTeamMember';
                careTeamMemberTypeId: any;
                name: string;
                phoneNumber: string;
                emailAddress: string;
                url: string;
                address: {
                    __typename?: 'Address';
                    street1?: string | null;
                    street2?: string | null;
                    city?: string | null;
                    state?: string | null;
                    code?: string | null;
                };
            } | null;
            label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        }>;
    };
};

export type PatientProfileVariablesForPatientCareTeamPageQueryVariables = Exact<{
    patientId: Scalars['String'];
    profileVariableDefIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type PatientProfileVariablesForPatientCareTeamPageQuery = {
    __typename?: 'Query';
    patientProfileVariables: Array<{
        __typename?: 'PatientProfileVariable';
        profileVariableDefId: string;
        name: string;
        valueType: string;
        categoryName: string;
        conditionId?: string | null;
        conditionName?: string | null;
        episodeStartDate?: any | null;
        description?: string | null;
        systemGenerated?: boolean | null;
        haPermission?: HaPermission | null;
        stringifiedValue: string;
        originId?: string | null;
        notes?: string | null;
        sourceType?: string | null;
        value: {
            __typename?: 'UserProfileValueSchema';
            date?: any | null;
            str?: string | null;
            bool?: boolean | null;
            num?: number | null;
            strs?: Array<string | null> | null;
            nums?: Array<number | null> | null;
            dates?: Array<any | null> | null;
            bools?: Array<boolean | null> | null;
            choices?: Array<string | null> | null;
            choice?: string | null;
            localized?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            specified?: Array<{
                __typename?: 'ProxiedSpecifiedValue';
                choiceId?: string | null;
                type?: UserProfileSpecifiedValueType | null;
                val?: {
                    __typename?: 'UserProfileSpecifiedValueSchema';
                    str?: string | null;
                    num?: number | null;
                    bool?: boolean | null;
                    date?: any | null;
                } | null;
            } | null> | null;
        };
    }>;
};

export type UpdatePatientProfileVariableForCareTeamMutationVariables = Exact<{
    input: PatientProfileVariableInput;
}>;

export type UpdatePatientProfileVariableForCareTeamMutation = {
    __typename?: 'Mutation';
    updatePatientProfileVariable?: {
        __typename?: 'PatientProfileVariable';
        profileVariableDefId: string;
        value: { __typename?: 'UserProfileValueSchema'; str?: string | null };
    } | null;
};

export type FetchChatRoomForPortalUserForPatientChatPageQueryVariables = Exact<{
    input?: InputMaybe<FetchChatRoomForPortalUserInput>;
}>;

export type FetchChatRoomForPortalUserForPatientChatPageQuery = {
    __typename?: 'Query';
    fetchChatRoomForPortalUser?: {
        __typename?: 'ChatRoom';
        id: any;
        numberOfUnreadMessages?: number | null;
        careTeamMemberTypeId: any;
        careTeamMemberType: { __typename?: 'CareTeamMemberType'; name: string };
        patient: {
            __typename?: 'Patient';
            id: any;
            firstName: string;
            lastName: string;
            practice: { __typename?: 'Organization'; name: string };
        };
        participants: Array<{ __typename?: 'User'; id: any; name: string }>;
        messages: Array<{
            __typename?: 'ChatMessage';
            id: any;
            createdAt: any;
            updatedAt: any;
            read?: Array<string | null> | null;
            text?: string | null;
            attachments?: Array<string | null> | null;
            senderId?: string | null;
            chatRoomId: any;
            chatConversationId: any;
        }>;
    } | null;
};

export type CareTeamMemberTypesV2ForPatientChatQueryVariables = Exact<{
    input?: InputMaybe<CareTeamMemberTypeQueryInput>;
}>;

export type CareTeamMemberTypesV2ForPatientChatQuery = {
    __typename?: 'Query';
    careTeamMemberTypesV2: {
        __typename?: 'CareTeamMemberTypePaginationQueryResponse';
        results: Array<{
            __typename?: 'CareTeamMemberType';
            id: any;
            isJob?: boolean | null;
            name: string;
        }>;
    };
};

export type ClinicalSummarysV2QueryVariables = Exact<{
    input?: InputMaybe<ClinicalSummaryQueryInput>;
}>;

export type ClinicalSummarysV2Query = {
    __typename?: 'Query';
    clinicalSummarysV2: {
        __typename?: 'ClinicalSummaryPaginationQueryResponse';
        hasMore?: boolean | null;
        limit?: number | null;
        hasPrevious?: boolean | null;
        skip?: number | null;
        total: number;
        orderBy?: string | null;
        results: Array<{
            __typename?: 'ClinicalSummary';
            createdAt: any;
            updatedAt: any;
            isTestData?: boolean | null;
            id: any;
            logId?: string | null;
            patientId?: any | null;
            visitId?: string | null;
            redoxEhrId?: string | null;
        }>;
    };
};

export type GetClinicalSummaryLogByIdQueryVariables = Exact<{
    input: GetClinicalSummaryByLogIdInput;
}>;

export type GetClinicalSummaryLogByIdQuery = {
    __typename?: 'Query';
    getClinicalSummaryLogById?: {
        __typename?: 'GetClinicalSummaryLogByIdResponse';
        raw?: string | null;
    } | null;
};

export type FetchReferenceLinksForPatientClinicalSummariesPageQueryVariables = Exact<{
    input: GetReferenceLinksForPageInput;
}>;

export type FetchReferenceLinksForPatientClinicalSummariesPageQuery = {
    __typename?: 'Query';
    getReferenceLinksForPage?: Array<{
        __typename?: 'GetReferenceLinksForPageResponse';
        category?: string | null;
        data?: Array<{
            __typename?: 'LinkDataType';
            label?: string | null;
            url?: string | null;
        } | null> | null;
    } | null> | null;
};

export type ClinicalSummaryQueryVariables = Exact<{
    input: GetClinicalSummaryInput;
}>;

export type ClinicalSummaryQuery = {
    __typename?: 'Query';
    clinicalSummary?: {
        __typename?: 'ClinicalSummary';
        createdAt: any;
        updatedAt: any;
        isTestData?: boolean | null;
        id: any;
        logId?: string | null;
        redoxEhrId?: string | null;
        redoxSourceId?: string | null;
        patientId?: any | null;
        visitId?: string | null;
        problemsText?: string | null;
        resolvedProblemsText?: string | null;
        patient?: { __typename?: 'Patient'; fullName: string } | null;
        problems?: Array<{
            __typename?: 'Problem';
            name?: string | null;
            code?: string | null;
            codeSystem?: string | null;
            codeSystemName?: string | null;
            startDate?: any | null;
            endDate?: any | null;
            comment?: string | null;
            category?: {
                __typename?: 'RedoxNameAndCodeDetails';
                name?: string | null;
                code?: string | null;
                codeSystem?: string | null;
                codeSystemName?: string | null;
            } | null;
            status?: {
                __typename?: 'RedoxNameAndCodeDetails';
                name?: string | null;
                code?: string | null;
                codeSystem?: string | null;
                codeSystemName?: string | null;
            } | null;
            comments?: Array<{ __typename?: 'ProblemComment'; text?: string | null }> | null;
            altCodes?: Array<{
                __typename?: 'RedoxNameAndCodeDetails';
                name?: string | null;
                code?: string | null;
                codeSystem?: string | null;
                codeSystemName?: string | null;
            }> | null;
        }> | null;
        resolvedProblems?: Array<{
            __typename?: 'ResolvedProblem';
            name?: string | null;
            code?: string | null;
            codeSystem?: string | null;
            codeSystemName?: string | null;
            startDate?: any | null;
            endDate?: any | null;
            comment?: string | null;
            category?: {
                __typename?: 'RedoxNameAndCodeDetails';
                name?: string | null;
                code?: string | null;
                codeSystem?: string | null;
                codeSystemName?: string | null;
            } | null;
            status?: {
                __typename?: 'RedoxNameAndCodeDetails';
                name?: string | null;
                code?: string | null;
                codeSystem?: string | null;
                codeSystemName?: string | null;
            } | null;
            comments?: Array<{ __typename?: 'ProblemComment'; text?: string | null }> | null;
            altCodes?: Array<{
                __typename?: 'RedoxNameAndCodeDetails';
                name?: string | null;
                code?: string | null;
                codeSystem?: string | null;
                codeSystemName?: string | null;
            }> | null;
        }> | null;
        demographics?: {
            __typename?: 'Demographics';
            language?: string | null;
            race?: string | null;
            ethnicity?: string | null;
            raceCodes?: Array<{
                __typename?: 'RedoxNameAndCodeDetails';
                name?: string | null;
                code?: string | null;
                codeSystem?: string | null;
                codeSystemName?: string | null;
            }> | null;
            ethnicGroupCodes?: Array<{
                __typename?: 'RedoxNameAndCodeDetails';
                name?: string | null;
                code?: string | null;
                codeSystem?: string | null;
                codeSystemName?: string | null;
            }> | null;
        } | null;
        medications?: Array<{
            __typename?: 'Medication';
            prescription?: boolean | null;
            freeTextSig?: string | null;
            status?: string | null;
            startDate?: any | null;
            endDate?: any | null;
            isPRN?: boolean | null;
            dose?: {
                __typename?: 'MedicationDose';
                quantity?: string | null;
                units?: string | null;
            } | null;
            rate?: {
                __typename?: 'MedicationDose';
                quantity?: string | null;
                units?: string | null;
            } | null;
            route?: {
                __typename?: 'RedoxNameAndCodeDetails';
                name?: string | null;
                code?: string | null;
                codeSystem?: string | null;
                codeSystemName?: string | null;
            } | null;
            frequency?: {
                __typename?: 'MedicationFrequency';
                period?: string | null;
                periodMax?: string | null;
                unit?: string | null;
            } | null;
            product?: {
                __typename?: 'RedoxNameAndCodeDetails';
                name?: string | null;
                code?: string | null;
                codeSystem?: string | null;
                codeSystemName?: string | null;
            } | null;
            indications?: Array<{
                __typename?: 'Indication';
                name?: string | null;
                code?: string | null;
                codeSystem?: string | null;
                codeSystemName?: string | null;
                altCodes?: Array<{
                    __typename?: 'RedoxNameAndCodeDetails';
                    name?: string | null;
                    code?: string | null;
                    codeSystem?: string | null;
                    codeSystemName?: string | null;
                }> | null;
            } | null> | null;
        }> | null;
    } | null;
};

export type FetchPatientForPatientDetailsPageQueryVariables = Exact<{
    input: GetPatientInput;
}>;

export type FetchPatientForPatientDetailsPageQuery = {
    __typename?: 'Query';
    patient?: {
        __typename?: 'Patient';
        id: any;
        createdAt: any;
        externalId?: string | null;
        memberId?: string | null;
        fullName: string;
        firstName: string;
        lastName: string;
        onboardingDate?: any | null;
        email?: string | null;
        birthDate: any;
        phoneNumber?: string | null;
        invitationMode?: PatientInvitation | null;
        invitationCode?: string | null;
        signUpPracticeCode?: string | null;
        isTestData?: boolean | null;
        assignedHealthAdvocate?: any | null;
        openAdvocateTaskCount?: number | null;
        openCriticalAdvocateTaskCount?: number | null;
        onboardingCompleteDate?: any | null;
        isPregnant?: boolean | null;
        dueDate?: any | null;
        deliveryDate?: any | null;
        patientCallStatus?: string | null;
        appUserId?: any | null;
        appBundleId?: string | null;
        attachments?: Array<{
            __typename?: 'Attachment';
            uuid: string;
            label: string;
        } | null> | null;
        riskIndicators?: {
            __typename?: 'PatientRiskIndicators';
            obesity?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            preterm?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            chronicHypertension?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            other?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
        } | null;
        mailingAddress?: {
            __typename?: 'Address';
            street1?: string | null;
            street2?: string | null;
            city?: string | null;
            state?: string | null;
            code?: string | null;
        } | null;
        practice: {
            __typename?: 'Organization';
            id: any;
            name: string;
            timezone?: string | null;
            providers?: Array<{
                __typename?: 'OrganizationProvider';
                provider: {
                    __typename?: 'Provider';
                    id: any;
                    localizedName: string;
                    createdAt: any;
                };
            }> | null;
        };
        questions: Array<{
            __typename?: 'CombinedQuestion';
            id: string;
            createdAt: any;
            updatedAt: any;
            submittedAt: any;
            text: string;
            isDone: boolean;
        }>;
        notes: Array<{
            __typename?: 'PatientNotes';
            id: any;
            text?: string | null;
            title?: string | null;
            staffName?: string | null;
            createdAt: any;
        }>;
        chatRoom: { __typename?: 'ChatRoom'; id: any; numberOfUnreadMessages?: number | null };
        profileValues: Array<{
            __typename?: 'PatientProfileValue';
            profileVariableDefId?: any | null;
            type?: ProfileValueType | null;
            valueBool?: boolean | null;
            valueNum?: number | null;
            valueStr?: string | null;
            valueId?: any | null;
            valueIds?: Array<any | null> | null;
            valueDate?: any | null;
            timeZone?: string | null;
            utcOffset?: number | null;
        }>;
        riskScore?: {
            __typename?: 'RiskScoreResponse';
            value?: number | null;
            tierName?: string | null;
        } | null;
    } | null;
};

export type FetchPatientProfileVariablesForPatientDetailsPageQueryVariables = Exact<{
    patientId: Scalars['String'];
    profileVariableDefIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type FetchPatientProfileVariablesForPatientDetailsPageQuery = {
    __typename?: 'Query';
    patientProfileVariables: Array<{
        __typename?: 'PatientProfileVariable';
        profileVariableDefId: string;
        name: string;
        valueType: string;
        categoryName: string;
        conditionId?: string | null;
        conditionName?: string | null;
        episodeStartDate?: any | null;
        description?: string | null;
        systemGenerated?: boolean | null;
        haPermission?: HaPermission | null;
        stringifiedValue: string;
        originId?: string | null;
        notes?: string | null;
        sourceType?: string | null;
        value: {
            __typename?: 'UserProfileValueSchema';
            date?: any | null;
            str?: string | null;
            bool?: boolean | null;
            num?: number | null;
            strs?: Array<string | null> | null;
            nums?: Array<number | null> | null;
            dates?: Array<any | null> | null;
            bools?: Array<boolean | null> | null;
            choices?: Array<string | null> | null;
            choice?: string | null;
            localized?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            specified?: Array<{
                __typename?: 'ProxiedSpecifiedValue';
                choiceId?: string | null;
                type?: UserProfileSpecifiedValueType | null;
                val?: {
                    __typename?: 'UserProfileSpecifiedValueSchema';
                    str?: string | null;
                    num?: number | null;
                    bool?: boolean | null;
                    date?: any | null;
                } | null;
            } | null> | null;
        };
    }>;
};

export type AdvocateTasksForPatientHaTaskPageQueryVariables = Exact<{
    input?: InputMaybe<GetFilteredAdvocateTasksInput>;
}>;

export type AdvocateTasksForPatientHaTaskPageQuery = {
    __typename?: 'Query';
    customFilteredAdvocateTasks: {
        __typename?: 'FilteredAdvocateTasksResponse';
        orderBy?: string | null;
        skip?: number | null;
        limit?: number | null;
        total: number;
        hasMore?: boolean | null;
        hasPrevious?: boolean | null;
        results: Array<{
            __typename?: 'AdvocateTask';
            id: any;
            label: string;
            description: string;
            dueDate: any;
            createdAt: any;
            completedAt?: any | null;
            overdue?: boolean | null;
            priority: number;
            status: AdvocateTaskTypeStatus;
            assignedTo?: any | null;
            patient?: {
                __typename?: 'Patient';
                id: any;
                fullName: string;
                practice: { __typename?: 'Organization'; id: any; brandingName: string };
            } | null;
        }>;
    };
};

export type FetchAdvocatesForHaTaskPatientPageQueryVariables = Exact<{
    permission?: InputMaybe<Scalars['String']>;
}>;

export type FetchAdvocatesForHaTaskPatientPageQuery = {
    __typename?: 'Query';
    getUsersByPermission?: Array<{ __typename?: 'User'; id: any; name: string } | null> | null;
};

export type FetchReferenceLinksForPatientHaTaskPageQueryVariables = Exact<{
    input: GetReferenceLinksForPageInput;
}>;

export type FetchReferenceLinksForPatientHaTaskPageQuery = {
    __typename?: 'Query';
    getReferenceLinksForPage?: Array<{
        __typename?: 'GetReferenceLinksForPageResponse';
        category?: string | null;
        data?: Array<{
            __typename?: 'LinkDataType';
            label?: string | null;
            url?: string | null;
        } | null> | null;
    } | null> | null;
};

export type PatientHighlightFragmentFragment = {
    __typename?: 'PatientHighlightsItem';
    id?: string | null;
    type?: HighlightType | null;
    priority?: number | null;
    highlightId?: string | null;
    highlightName?: string | null;
    title?: string | null;
    header?: string | null;
    body?: string | null;
    target?: string | null;
    imageURL?: string | null;
    icon?: string | null;
    surveyId?: string | null;
    highlighted?: boolean | null;
    closeable?: boolean | null;
    availableFrom?: any | null;
    availableUntil?: any | null;
    closed?: boolean | null;
};

export type GetPatientHighlightsQueryVariables = Exact<{
    patientId?: InputMaybe<Scalars['String']>;
    appBundleId?: InputMaybe<Scalars['String']>;
}>;

export type GetPatientHighlightsQuery = {
    __typename?: 'Query';
    patientHighlights?: {
        __typename?: 'PatientHighlightsResult';
        todayitemcount?: number | null;
        futureitemcount?: number | null;
        todayitems?: Array<{
            __typename?: 'PatientHighlightsItem';
            id?: string | null;
            type?: HighlightType | null;
            priority?: number | null;
            highlightId?: string | null;
            highlightName?: string | null;
            title?: string | null;
            header?: string | null;
            body?: string | null;
            target?: string | null;
            imageURL?: string | null;
            icon?: string | null;
            surveyId?: string | null;
            highlighted?: boolean | null;
            closeable?: boolean | null;
            availableFrom?: any | null;
            availableUntil?: any | null;
            closed?: boolean | null;
        } | null> | null;
        futureitems?: Array<{
            __typename?: 'PatientHighlightsItem';
            id?: string | null;
            type?: HighlightType | null;
            priority?: number | null;
            highlightId?: string | null;
            highlightName?: string | null;
            title?: string | null;
            header?: string | null;
            body?: string | null;
            target?: string | null;
            imageURL?: string | null;
            icon?: string | null;
            surveyId?: string | null;
            highlighted?: boolean | null;
            closeable?: boolean | null;
            availableFrom?: any | null;
            availableUntil?: any | null;
            closed?: boolean | null;
        } | null> | null;
    } | null;
};

export type UpdateHighlightFeedItemMutationVariables = Exact<{
    input: PatientHighlightUpdateInput;
}>;

export type UpdateHighlightFeedItemMutation = {
    __typename?: 'Mutation';
    highlightFeedUpdatePriorityClosed?:
        | {
              __typename?: 'AddApprovalsForAppMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'AddHATodoTaskResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'AddNoteFromConsultantResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'AddOrganizationInviteCodeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'AddRedirectUrlToTTPatientResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'AddToPatientListResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'AffiliateClaimResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'AppUserTokenResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'ApproveAllForAppResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'ChangePatientOrgResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CheckEligibilityFormResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CloneArticleMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CloneBioScreenResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CloneVariantMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'CloseAppTaskResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'CloseHighlightFeedResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CompletePotentialUserFlowMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CompleteTodoTasksResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreatManyClaimsByPatientIdsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateAdvocateNotificationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateAdvocateTaskResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateAdvocateTaskTemplateEncodedResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateAdvocateTaskTemplateResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateAdvocateTaskTypeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateAffiliateInvoiceResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateAppUserArticleResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateAppUserEventTriggerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateAppUserForVCPatientResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateAppUserMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateAppUserResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateAppUserTelemetryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateAppUserTrendTriggerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateApplicationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateAppointmentResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateArticleAppApprovalsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateArticleApprovalResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateArticleCacheResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateArticleCategoryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateArticleEncodedResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateArticleFeedResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateArticleGroupResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateArticlePromotionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateArticleResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateAsyncJobResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateBatchTaskResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateBioScreenResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateCalendarResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateCarePlanResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateCareTeamContactResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateCareTeamMemberResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateCareTeamMemberTypeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateCareTeamResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateChatConversationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateChatMessageForAppUserResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateChatMessageResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateChatRoomResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateClinicalSummaryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateCompoundQuestionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateConditionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateConditionTypeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateConfigExpressionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateConfigValueDefResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateConfigValueResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateContentDirectoryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateDateTriggerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateDependentUserMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'CreateDeviceResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'CreateDirectoryCacheResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateEligibilityMatchResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateEpisodeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateEventTriggerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateHealthPlanResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateHighlightFeedResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateHighlightResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateInterventionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyAdvocateNotificationsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyAdvocateTaskTemplatesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyAdvocateTaskTypesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyAdvocateTasksResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyAffiliateClaimsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyAffiliateInvoicesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyAppUserArticlesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyAppUserEventTriggersResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyAppUserTelemetrysResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyAppUserTrendTriggersResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyAppUsersResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyApplicationsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyAppointmentsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyArticleApprovalsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyArticleCachesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyArticleCategorysResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyArticleFeedsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyArticleGroupsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyArticlePromotionsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyArticlesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyAsyncJobsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyBatchTasksResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyBioScreensResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyCalendarsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyCarePlansResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyCareTeamContactsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyCareTeamMemberTypesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyCareTeamMembersResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyCareTeamsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyChatConversationsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyChatMessagesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyChatRoomsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyClinicalSummarysResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyCompoundQuestionsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyConditionTypesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyConditionsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyConfigExpressionsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyConfigValueDefsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyConfigValuesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyContentDirectorysResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyDateTriggersResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyDevicesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyDirectoryCachesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyEligibilityMatchsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyEpisodesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyEventTriggersResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyHealthPlansResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyHighlightFeedsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyHighlightsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualAdvocateNotificationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualAdvocateTaskResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualAdvocateTaskTemplateResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualAdvocateTaskTypeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualAffiliateClaimResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualAffiliateInvoiceResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualAppUserArticleResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualAppUserEventTriggerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualAppUserResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualAppUserTelemetryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualAppUserTrendTriggerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualApplicationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualAppointmentResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualArticleApprovalResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualArticleCacheResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualArticleCategoryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualArticleFeedResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualArticleGroupResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualArticlePromotionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualArticleResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualAsyncJobResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualBatchTaskResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualBioScreenResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualCalendarResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualCarePlanResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualCareTeamContactResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualCareTeamMemberResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualCareTeamMemberTypeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualCareTeamResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualChatConversationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualChatMessageResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualChatRoomResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualClinicalSummaryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualCompoundQuestionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualConditionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualConditionTypeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualConfigExpressionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualConfigValueDefResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualConfigValueResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualContentDirectoryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualDateTriggerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualDeviceResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualDirectoryCacheResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualEligibilityMatchResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualEpisodeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualEventTriggerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualHealthPlanResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualHighlightFeedResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualHighlightResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualInterventionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualMassUpdatePatientResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualMassUpdateTaskResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualMeasurementResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualMessageCenterTemplateResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualMessageTemplateResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualOrderResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualOrganizationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualPatientActionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualPatientActionTypeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualPatientCallListResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualPatientHeaderResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualPatientNoteTagResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualPatientNotesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualPatientResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualPotentialUserResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualProfileKeyResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualProfileValueHistoryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualProfileValueOriginResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualProfileValueResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualProviderResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualQListResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualQuestionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualReferenceLinkCategoryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualReferenceLinkResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualReportingCategoryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualReportingJobResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualRoleResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualSentMessageResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualSimpleContentResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualSlaResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualSlaTimerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualSubmittedClaimResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualSurveyDefResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualSurveyResponseResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualSystemTokenResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualTagResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualTodoTaskResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualTokenResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualTrackerHistoryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualTrackerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualTrendTriggerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualUserProfileDefResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualUserResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualVCZipCodeToLocationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualVirtualCareAffiliatesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualVirtualCareEligibilityWhitelistResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualVirtualCareFormResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualWhatsNewTimelineResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyIndividualWhoToCallResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyInterventionsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyMassUpdatePatientsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyMassUpdateTasksResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyMeasurementsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyMessageCenterTemplatesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyMessageTemplatesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyOrdersResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyOrganizationsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyPatientActionTypesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyPatientActionsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyPatientCallListsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyPatientHeadersResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyPatientNoteTagsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyPatientNotessResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyPatientsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyPotentialUsersResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyProfileKeysResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyProfileValueHistorysResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyProfileValueOriginsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyProfileValuesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyProvidersResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyQListsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyQuestionsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyReferenceLinkCategorysResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyReferenceLinksResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyReportingCategorysResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyReportingJobsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyRolesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManySentMessagesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManySimpleContentsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManySlaTimersResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManySlasResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManySubmittedClaimsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManySubmittedClaimsViaFileResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManySurveyDefsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManySurveyResponsesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManySystemTokensResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyTagsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyTodoTasksResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyTokensResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyTrackerHistorysResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyTrackersResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyTrendTriggersResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyUserProfileDefsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyUsersResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyVCZipCodeToLocationsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyVirtualCareAffiliatessResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyVirtualCareEligibilityWhitelistsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyVirtualCareFormsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyWhatsNewTimelinesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateManyWhoToCallsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateMassUpdatePatientResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateMassUpdateTaskResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateMeasurementResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateMessageCenterTemplateResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateMessageTemplateResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateOrUpdateAppUserMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateOrUpdateAppointmentsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateOrUpdatePatientsWithDeviceOrdersResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateOrUpdateVirtualCareFormResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateOrUpdateWhatsNewTimelineResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'CreateOrderResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'CreateOrganizationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreatePatientActionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreatePatientActionTypeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreatePatientActionsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreatePatientCallListResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreatePatientForAppUserResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreatePatientForSelfSignUpResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreatePatientHeaderResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreatePatientNoteTagResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreatePatientNotesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreatePatientResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreatePotentialUserResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateProfileKeyResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateProfileValueHistoryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateProfileValueOriginResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateProfileValueResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateProviderResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'CreateQListResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'CreateQuestionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateReferenceLinkCategoryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateReferenceLinkResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateReportingCategoryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateReportingJobResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'CreateRoleResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'CreateSentMessageResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateSimpleContentResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'CreateSlaResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'CreateSlaTimerForAdvocateTaskResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateSlaTimerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateSubmittedClaimResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateSubmittedClaimViaFileResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateSurveyDefResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'CreateSurveyResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'CreateSystemTokenResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'CreateTagResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'CreateTasksForAppointmentResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateTestUserMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateTodoTaskResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'CreateTokenResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'CreateTrackerHistoryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateTrackerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateTrendTriggerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateUserProfileDefResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'CreateUserResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'CreateVCZipCodeToLocationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateVirtualCareAffiliatesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateVirtualCareEligibilityWhitelistResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateVirtualCareFormResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'CreateWhoToCallResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteAdvocateNotificationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteAdvocateTaskResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteAdvocateTaskTemplateMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteAdvocateTaskTypeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteAffiliateClaimResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteAffiliateInvoiceResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteAllProfileVariablesMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteAppUserArticleResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteAppUserEventTriggerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteAppUserMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteAppUserResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteAppUserTelemetryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteAppUserTrendTriggerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteApplicationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteAppointmentResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteArticleApprovalResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteArticleCacheResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteArticleCategoryMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteArticleFeedResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteArticleGroupResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteArticleMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteArticlePromotionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteAsyncJobResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteBatchTaskResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteBioScreenResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'DeleteCDResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'DeleteCalendarResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteCarePlanResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteCareTeamContactResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteCareTeamMemberResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteCareTeamMemberTypeMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteCareTeamResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteChatConversationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteChatMessageResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteChatRoomResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteClinicalSummaryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteCompoundQuestionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteConditionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteConditionTypeMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteConfigExpressionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteConfigValueByValueDefIdResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteConfigValueDefResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteConfigValueResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteDateTriggerMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'DeleteDeviceResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'DeleteDirectoryCacheResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteEligibilityMatchResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteEpisodeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteEventTriggerMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteExpressionMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteHealthPlanResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteHighlightFeedResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteHighlightResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteInterventionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteMassUpdatePatientListResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteMassUpdatePatientResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteMassUpdateTaskResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteMeasurementResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteMessageCenterTemplateMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteMessageTemplateResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'DeleteOrderResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'DeleteOrganizationInviteCodeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteOrganizationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeletePatientActionByStepResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeletePatientActionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeletePatientActionTypeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeletePatientCallListResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeletePatientHeaderResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeletePatientInvitationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeletePatientNoteTagResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeletePatientNotesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeletePatientResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeletePatientResponseV2';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeletePatientTodoResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeletePatientsByAppResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeletePotentialUserResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteProfileKeyResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteProfileValueHistoryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteProfileValueOriginResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteProfileValueResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteProviderResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'DeleteQListResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'DeleteQuestionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteReferenceLinkCategoryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteReferenceLinkResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteReportingCategoryMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteReportingJobResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteRoleMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteSentMessageResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteSimpleContentMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'DeleteSlaResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'DeleteSlaTimerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteSubmittedClaimResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteSurveyDefMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteSurveyResponseResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteSystemTokenResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteTagMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteTasksForAppointmentResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteTimelineResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteTodoTaskResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteTodoTasksResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'DeleteTokenResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'DeleteTrackerHistoryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteTrackerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteTrendTriggerMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteUserDataResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteUserProfileDefResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'DeleteUserResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'DeleteVCZipCodeToLocationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteVariantMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteVirtualCareAffiliateResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteVirtualCareAffiliatesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteVirtualCareEligibilityWhitelistResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteVirtualCareFormResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteWhatsNewTimelineResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DeleteWhoToCallResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'DownloadFamilyDataResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'EditHATodoTaskResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'EligibilityBulkRecheckResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'EligibilityCreateAccessCodeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'EligibilityResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'EligibilityUpdateAccessCodeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'FindUserByEmailDataResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'FollowAllPatientsByOrgResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'FollowAllUsersResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'ForceClaimUploadResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'GetFamilyResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'ImportFamilyDataResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'InviteUserMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'LoginUserResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'LogoutMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'MoveMessagesInChatConversationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'NotificationTokenMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'PatientProfileValueResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'PayerMemberNumResponseResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'PostMessageCenterMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'PostProfileValueChangedMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'PostSurveyCompletedMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'PostTrackerChangedMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'ProfileVariableMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'ProfileVariablesMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'PublishAllArticlesMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'PublishArticleMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'QListMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'RecheckEligibilityResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'ReportDataResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'ResendAppUserInviteCodeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'ResendPatientCodeOnResetPasswordResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'ResendPatientInvitationByEmailIfExistsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'ResendPatientInvitationFromPortalResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'ResendPatientInvitationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'ResetAppUserPasswordResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'ResetPasswordResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'ResetPatientPasswordFromPortalResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'ResetUserPasswordDataResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'ResolveChatConversationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'SaveHighlightResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'SendInvitationCodeOnResetPasswordResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'SendInvitationCodeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'SendInvitationFromPortalCodeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'SendMessageMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'SendMfaCodeResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'SendNotificationsResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'SetCareTeamValueResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'SetContentCreatedResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'SetPatientAppUserResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'SetTasksTestDataResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'SubmitTaskMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'TextOptOutMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'TrackerMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UnsubscribePatientResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateAdvocateNotificationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateAdvocateTaskEncodedResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateAdvocateTaskResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateAdvocateTaskTemplateEncodedResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateAdvocateTaskTemplateResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateAdvocateTaskTypeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateAffiliateClaimResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateAffiliateInvoiceResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateAffiliateInvoicesPaymentStatusResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateAppUserArticleResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateAppUserEmailResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateAppUserEventTriggerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateAppUserLanguageResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateAppUserMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateAppUserResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateAppUserTelemetryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateAppUserTrendTriggerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateApplicationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateAppointmentResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateApproveNameResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateArticleApprovalResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateArticleCacheResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateArticleCategoryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateArticleEncodedResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateArticleFeedResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateArticleGroupResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateArticlePromotionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateArticleResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateAsyncJobResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateBatchClaimsStatusResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateBatchTaskResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateBioScreenResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateCalendarResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateCarePlanResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateCareTeamContactResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateCareTeamMemberResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateCareTeamMemberTypeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateCareTeamResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateChatConversationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateChatMessageResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateChatRoomResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateClinicalSummaryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateCompoundQuestionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateConditionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateConditionTypeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateConfigExpressionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateConfigValueDefResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateConfigValueResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateContentDirectoryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateDateTriggerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'UpdateDeviceResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'UpdateDirectoryCacheResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateEligibilityMatchResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateEpisodeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateEventTriggerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateHealthPlanResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateHighlightFeedResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateHighlightResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateInterventionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateMassUpdatePatientResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateMassUpdateTaskResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateMeasurementResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateMeasurementReviewedResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateMessageCenterTemplateResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateMessageTemplateResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'UpdateOrderResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'UpdateOrganizationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateParticipantsForChatRoomResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdatePatientActionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdatePatientActionTypeResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdatePatientCallListResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdatePatientHeaderResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdatePatientNoteTagResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdatePatientNotesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdatePatientResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdatePotentialUserResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateProfileKeyResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateProfileValueHistoryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateProfileValueOriginResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateProfileValueResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateProviderResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'UpdateQListResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'UpdateQuestionResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateReadeMessagesForChatRoomResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateReferenceLinkCategoryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateReferenceLinkResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateReportKeyResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateReportingCategoryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateReportingJobResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'UpdateRoleResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'UpdateSentMessageResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateSimpleContentResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'UpdateSlaResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'UpdateSlaTimerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateSubmittedClaimResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateSurveyDefResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateSurveyResponseAnswersWithNoDataTrailResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateSurveyResponseResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateSystemTokenResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'UpdateTagResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'UpdateTodoTaskResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateTodoTasksResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'UpdateTokenResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'UpdateTrackerHistoryResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateTrackerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateTrendTriggerResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateUserProfileDefResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | { __typename?: 'UpdateUserResponse'; status: number; message: string; success: boolean }
        | {
              __typename?: 'UpdateVCZipCodeToLocationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateVirtualCareAffiliateResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateVirtualCareAffiliatesResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateVirtualCareEligibilityWhitelistResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateVirtualCareFormResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpdateWhoToCallResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpsertConfigValueByValueDefIdResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'UpsertVariantResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'ValidateAppUserTokenResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'VirtualCareAffiliatesMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | {
              __typename?: 'WhoToCallMutationResponse';
              status: number;
              message: string;
              success: boolean;
          }
        | null;
};

export type FetchMeasurementsForPatientMeasurementsPageQueryVariables = Exact<{
    patientInput: GetPatientInput;
    measurementsInput?: InputMaybe<PatientMeasurementsQueryInput>;
}>;

export type FetchMeasurementsForPatientMeasurementsPageQuery = {
    __typename?: 'Query';
    patient?: {
        __typename?: 'Patient';
        id: any;
        createdAt: any;
        externalId?: string | null;
        memberId?: string | null;
        fullName: string;
        firstName: string;
        lastName: string;
        onboardingDate?: any | null;
        email?: string | null;
        birthDate: any;
        phoneNumber?: string | null;
        invitationMode?: PatientInvitation | null;
        invitationCode?: string | null;
        signUpPracticeCode?: string | null;
        isTestData?: boolean | null;
        assignedHealthAdvocate?: any | null;
        openAdvocateTaskCount?: number | null;
        openCriticalAdvocateTaskCount?: number | null;
        onboardingCompleteDate?: any | null;
        isPregnant?: boolean | null;
        dueDate?: any | null;
        deliveryDate?: any | null;
        patientCallStatus?: string | null;
        appUserId?: any | null;
        appBundleId?: string | null;
        measurements: Array<{
            __typename?: 'Measurement';
            id: any;
            flagged?: boolean | null;
            externalId?: string | null;
            updatedAt: any;
            createdAt: any;
            type?: MeasurementType | null;
            takenDate?: any | null;
            timezoneName?: string | null;
            inputType: MeasurementInputSource;
            comment?: string | null;
            hasHistory?: boolean | null;
            value: {
                __typename?: 'MeasurementValue';
                movement?: { __typename?: 'MovementMeasurement'; duration?: string | null } | null;
                weight?: {
                    __typename?: 'WeightMeasurement';
                    measure?: number | null;
                    units?: string | null;
                } | null;
                bloodPressure?: {
                    __typename?: 'BloodPressureMeasurement';
                    pulse?: number | null;
                    diastolic?: number | null;
                    systolic?: number | null;
                    symptoms?: Array<string | null> | null;
                    symptomsOption?: string | null;
                } | null;
                dailyMeasurement?: {
                    __typename?: 'DailyMeasurement';
                    glucose?: number | null;
                    moodLevel?: number | null;
                    painLevel?: number | null;
                    steps?: number | null;
                    temperature?: number | null;
                    sleepStarts?: string | null;
                    sleepEnds?: string | null;
                    notes?: string | null;
                } | null;
                bloodGlucose?: {
                    __typename?: 'BloodGlucoseMeasurement';
                    dateTime?: any | null;
                    activityOption?: string | null;
                    glucose?: number | null;
                    meal?: string | null;
                    mealOption?: string | null;
                    mealTime?: any | null;
                    movement?: string | null;
                    notes?: string | null;
                } | null;
            };
            patient?: {
                __typename?: 'Patient';
                id: any;
                externalId?: string | null;
                firstName: string;
                lastName: string;
                fullName: string;
                birthDate: any;
                practice: { __typename?: 'Organization'; timezone?: string | null };
            } | null;
            device?: { __typename?: 'Device'; id: any; localizedName?: string | null } | null;
        }>;
        attachments?: Array<{
            __typename?: 'Attachment';
            uuid: string;
            label: string;
        } | null> | null;
        riskIndicators?: {
            __typename?: 'PatientRiskIndicators';
            obesity?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            preterm?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            chronicHypertension?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            other?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
        } | null;
        mailingAddress?: {
            __typename?: 'Address';
            street1?: string | null;
            street2?: string | null;
            city?: string | null;
            state?: string | null;
            code?: string | null;
        } | null;
        practice: {
            __typename?: 'Organization';
            id: any;
            name: string;
            timezone?: string | null;
            providers?: Array<{
                __typename?: 'OrganizationProvider';
                provider: {
                    __typename?: 'Provider';
                    id: any;
                    localizedName: string;
                    createdAt: any;
                };
            }> | null;
        };
        questions: Array<{
            __typename?: 'CombinedQuestion';
            id: string;
            createdAt: any;
            updatedAt: any;
            submittedAt: any;
            text: string;
            isDone: boolean;
        }>;
        notes: Array<{
            __typename?: 'PatientNotes';
            id: any;
            text?: string | null;
            title?: string | null;
            staffName?: string | null;
            createdAt: any;
        }>;
        chatRoom: { __typename?: 'ChatRoom'; id: any; numberOfUnreadMessages?: number | null };
        profileValues: Array<{
            __typename?: 'PatientProfileValue';
            profileVariableDefId?: any | null;
            type?: ProfileValueType | null;
            valueBool?: boolean | null;
            valueNum?: number | null;
            valueStr?: string | null;
            valueId?: any | null;
            valueIds?: Array<any | null> | null;
            valueDate?: any | null;
            timeZone?: string | null;
            utcOffset?: number | null;
        }>;
        riskScore?: {
            __typename?: 'RiskScoreResponse';
            value?: number | null;
            tierName?: string | null;
        } | null;
    } | null;
};

export type FetchReferenceLinksForPatientMeasurementsPageQueryVariables = Exact<{
    input: GetReferenceLinksForPageInput;
}>;

export type FetchReferenceLinksForPatientMeasurementsPageQuery = {
    __typename?: 'Query';
    getReferenceLinksForPage?: Array<{
        __typename?: 'GetReferenceLinksForPageResponse';
        category?: string | null;
        data?: Array<{
            __typename?: 'LinkDataType';
            label?: string | null;
            url?: string | null;
        } | null> | null;
    } | null> | null;
};

export type FetchPatientNotesForPatientNotesQueryVariables = Exact<{
    input: GetPatientInput;
}>;

export type FetchPatientNotesForPatientNotesQuery = {
    __typename?: 'Query';
    patient?: {
        __typename?: 'Patient';
        id: any;
        firstName: string;
        notes: Array<{
            __typename?: 'PatientNotes';
            id: any;
            createdAt: any;
            title?: string | null;
            date?: any | null;
            noteSource?: string | null;
            noteType?: Array<string | null> | null;
            staffName?: string | null;
            text?: string | null;
            patient?: { __typename?: 'Patient'; id: any } | null;
        }>;
    } | null;
};

export type NoteTagListForPatientNotesPageQueryVariables = Exact<{ [key: string]: never }>;

export type NoteTagListForPatientNotesPageQuery = {
    __typename?: 'Query';
    patientNoteTagsV2: {
        __typename?: 'PatientNoteTagPaginationQueryResponse';
        results: Array<{ __typename?: 'PatientNoteTag'; id: any; name: string }>;
    };
};

export type FetchReferenceLinksForPatientNotesPageQueryVariables = Exact<{
    input: GetReferenceLinksForPageInput;
}>;

export type FetchReferenceLinksForPatientNotesPageQuery = {
    __typename?: 'Query';
    getReferenceLinksForPage?: Array<{
        __typename?: 'GetReferenceLinksForPageResponse';
        category?: string | null;
        data?: Array<{
            __typename?: 'LinkDataType';
            label?: string | null;
            url?: string | null;
        } | null> | null;
    } | null> | null;
};

export type FetchReferenceLinksForPatientOrdersPageQueryVariables = Exact<{
    input: GetReferenceLinksForPageInput;
}>;

export type FetchReferenceLinksForPatientOrdersPageQuery = {
    __typename?: 'Query';
    getReferenceLinksForPage?: Array<{
        __typename?: 'GetReferenceLinksForPageResponse';
        category?: string | null;
        data?: Array<{
            __typename?: 'LinkDataType';
            label?: string | null;
            url?: string | null;
        } | null> | null;
    } | null> | null;
};

export type FetchPatientForPatientOverviewPageQueryVariables = Exact<{
    patientInput: GetPatientInput;
    appointmentInput?: InputMaybe<PatientAppointmentsQueryInput>;
}>;

export type FetchPatientForPatientOverviewPageQuery = {
    __typename?: 'Query';
    patient?: {
        __typename?: 'Patient';
        id: any;
        createdAt: any;
        externalId?: string | null;
        memberId?: string | null;
        fullName: string;
        firstName: string;
        lastName: string;
        onboardingDate?: any | null;
        email?: string | null;
        birthDate: any;
        phoneNumber?: string | null;
        invitationMode?: PatientInvitation | null;
        invitationCode?: string | null;
        signUpPracticeCode?: string | null;
        isTestData?: boolean | null;
        assignedHealthAdvocate?: any | null;
        openAdvocateTaskCount?: number | null;
        openCriticalAdvocateTaskCount?: number | null;
        onboardingCompleteDate?: any | null;
        isPregnant?: boolean | null;
        dueDate?: any | null;
        deliveryDate?: any | null;
        patientCallStatus?: string | null;
        appUserId?: any | null;
        appBundleId?: string | null;
        appointments: Array<{
            __typename?: 'Appointment';
            id: any;
            externalId?: string | null;
            startDate: any;
            durationMinutes?: number | null;
            staffName?: string | null;
            physicalLocation?: string | null;
            description?: string | null;
            isVirtualVisit?: boolean | null;
            virtualLink?: string | null;
            visitType?: string | null;
            source: AppointmentSource;
            note?: string | null;
            reportKey?: string | null;
            timezoneName?: string | null;
            patient: {
                __typename?: 'Patient';
                id: any;
                fullName: string;
                firstName: string;
                lastName: string;
                birthDate: any;
            };
            practice?: {
                __typename?: 'Organization';
                id: any;
                name: string;
                timezone?: string | null;
            } | null;
            staff?: { __typename?: 'User'; id: any; name: string } | null;
        }>;
        attachments?: Array<{
            __typename?: 'Attachment';
            uuid: string;
            label: string;
        } | null> | null;
        riskIndicators?: {
            __typename?: 'PatientRiskIndicators';
            obesity?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            preterm?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            chronicHypertension?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            other?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
        } | null;
        mailingAddress?: {
            __typename?: 'Address';
            street1?: string | null;
            street2?: string | null;
            city?: string | null;
            state?: string | null;
            code?: string | null;
        } | null;
        practice: {
            __typename?: 'Organization';
            id: any;
            name: string;
            timezone?: string | null;
            providers?: Array<{
                __typename?: 'OrganizationProvider';
                provider: {
                    __typename?: 'Provider';
                    id: any;
                    localizedName: string;
                    createdAt: any;
                };
            }> | null;
        };
        questions: Array<{
            __typename?: 'CombinedQuestion';
            id: string;
            createdAt: any;
            updatedAt: any;
            submittedAt: any;
            text: string;
            isDone: boolean;
        }>;
        notes: Array<{
            __typename?: 'PatientNotes';
            id: any;
            text?: string | null;
            title?: string | null;
            staffName?: string | null;
            createdAt: any;
        }>;
        chatRoom: { __typename?: 'ChatRoom'; id: any; numberOfUnreadMessages?: number | null };
        profileValues: Array<{
            __typename?: 'PatientProfileValue';
            profileVariableDefId?: any | null;
            type?: ProfileValueType | null;
            valueBool?: boolean | null;
            valueNum?: number | null;
            valueStr?: string | null;
            valueId?: any | null;
            valueIds?: Array<any | null> | null;
            valueDate?: any | null;
            timeZone?: string | null;
            utcOffset?: number | null;
        }>;
        riskScore?: {
            __typename?: 'RiskScoreResponse';
            value?: number | null;
            tierName?: string | null;
        } | null;
    } | null;
};

export type PatientProfileVariablesFragmentFragment = {
    __typename?: 'PatientProfileVariable';
    profileVariableDefId: string;
    name: string;
    valueType: string;
    categoryName: string;
    conditionId?: string | null;
    conditionName?: string | null;
    episodeStartDate?: any | null;
    description?: string | null;
    systemGenerated?: boolean | null;
    haPermission?: HaPermission | null;
    stringifiedValue: string;
    originId?: string | null;
    notes?: string | null;
    sourceType?: string | null;
    value: {
        __typename?: 'UserProfileValueSchema';
        date?: any | null;
        str?: string | null;
        bool?: boolean | null;
        num?: number | null;
        strs?: Array<string | null> | null;
        nums?: Array<number | null> | null;
        dates?: Array<any | null> | null;
        bools?: Array<boolean | null> | null;
        choices?: Array<string | null> | null;
        choice?: string | null;
        localized?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        specified?: Array<{
            __typename?: 'ProxiedSpecifiedValue';
            choiceId?: string | null;
            type?: UserProfileSpecifiedValueType | null;
            val?: {
                __typename?: 'UserProfileSpecifiedValueSchema';
                str?: string | null;
                num?: number | null;
                bool?: boolean | null;
                date?: any | null;
            } | null;
        } | null> | null;
    };
};

export type FetchPatientProfileVariablesQueryVariables = Exact<{
    patientId: Scalars['String'];
    systemGenerated?: InputMaybe<Scalars['Boolean']>;
}>;

export type FetchPatientProfileVariablesQuery = {
    __typename?: 'Query';
    patientProfileVariables: Array<{
        __typename?: 'PatientProfileVariable';
        profileVariableDefId: string;
        name: string;
        valueType: string;
        categoryName: string;
        conditionId?: string | null;
        conditionName?: string | null;
        episodeStartDate?: any | null;
        description?: string | null;
        systemGenerated?: boolean | null;
        haPermission?: HaPermission | null;
        stringifiedValue: string;
        originId?: string | null;
        notes?: string | null;
        sourceType?: string | null;
        value: {
            __typename?: 'UserProfileValueSchema';
            date?: any | null;
            str?: string | null;
            bool?: boolean | null;
            num?: number | null;
            strs?: Array<string | null> | null;
            nums?: Array<number | null> | null;
            dates?: Array<any | null> | null;
            bools?: Array<boolean | null> | null;
            choices?: Array<string | null> | null;
            choice?: string | null;
            localized?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            specified?: Array<{
                __typename?: 'ProxiedSpecifiedValue';
                choiceId?: string | null;
                type?: UserProfileSpecifiedValueType | null;
                val?: {
                    __typename?: 'UserProfileSpecifiedValueSchema';
                    str?: string | null;
                    num?: number | null;
                    bool?: boolean | null;
                    date?: any | null;
                } | null;
            } | null> | null;
        };
    }>;
};

export type FetchChoicesForProfileDefinitionByNameQueryVariables = Exact<{
    input: GetUserProfileDefInput;
}>;

export type FetchChoicesForProfileDefinitionByNameQuery = {
    __typename?: 'Query';
    userProfileDef?: {
        __typename?: 'UserProfileDef';
        choiceValueType?: UserProfileValueType | null;
        choices?: Array<{
            __typename?: 'UserProfileDefChoice';
            id: any;
            specifyValue: boolean;
            label?: { __typename?: 'LocalizedString'; en: string } | null;
            value?: {
                __typename?: 'UserProfileDefChoiceValue';
                str?: string | null;
                num?: number | null;
                bool?: boolean | null;
                date?: any | null;
            } | null;
        } | null> | null;
    } | null;
};

export type EpisodesByConditionIdQueryVariables = Exact<{
    conditionId: Scalars['String'];
}>;

export type EpisodesByConditionIdQuery = {
    __typename?: 'Query';
    proxiedEpisodesByConditionId: Array<{
        __typename?: 'ProxiedEpisode';
        beginDate?: any | null;
        endDate?: any | null;
    }>;
};

export type UpdatePatientProfileVariableMutationVariables = Exact<{
    input: PatientProfileVariableInput;
}>;

export type UpdatePatientProfileVariableMutation = {
    __typename?: 'Mutation';
    updatePatientProfileVariable?: {
        __typename?: 'PatientProfileVariable';
        profileVariableDefId: string;
        name: string;
        valueType: string;
        categoryName: string;
        conditionId?: string | null;
        conditionName?: string | null;
        episodeStartDate?: any | null;
        description?: string | null;
        systemGenerated?: boolean | null;
        haPermission?: HaPermission | null;
        stringifiedValue: string;
        originId?: string | null;
        notes?: string | null;
        sourceType?: string | null;
        value: {
            __typename?: 'UserProfileValueSchema';
            date?: any | null;
            str?: string | null;
            bool?: boolean | null;
            num?: number | null;
            strs?: Array<string | null> | null;
            nums?: Array<number | null> | null;
            dates?: Array<any | null> | null;
            bools?: Array<boolean | null> | null;
            choices?: Array<string | null> | null;
            choice?: string | null;
            localized?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            specified?: Array<{
                __typename?: 'ProxiedSpecifiedValue';
                choiceId?: string | null;
                type?: UserProfileSpecifiedValueType | null;
                val?: {
                    __typename?: 'UserProfileSpecifiedValueSchema';
                    str?: string | null;
                    num?: number | null;
                    bool?: boolean | null;
                    date?: any | null;
                } | null;
            } | null> | null;
        };
    } | null;
};

export type FetchProfileValueOriginListForPatientQueryVariables = Exact<{ [key: string]: never }>;

export type FetchProfileValueOriginListForPatientQuery = {
    __typename?: 'Query';
    profileValueOrigins: Array<{
        __typename?: 'ProfileValueOrigin';
        id: any;
        label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    }>;
};

export type ProfileKeysQueryVariables = Exact<{
    input: ProfileKeyQueryInput;
}>;

export type ProfileKeysQuery = {
    __typename?: 'Query';
    profileKeys: {
        __typename?: 'ProfileKeyPaginationQueryResponse';
        results: Array<{ __typename?: 'ProfileKey'; id: any }>;
    };
};

export type FetchProfileValueHistoriesByProfileKeysForPatientQueryVariables = Exact<{
    keyIds: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;

export type FetchProfileValueHistoriesByProfileKeysForPatientQuery = {
    __typename?: 'Query';
    getProfileValueHistoriesByProfileKeys?: Array<{
        __typename?: 'ProfileValueHistory';
        keyId?: any | null;
        profileVariableDefId?: string | null;
        surveyDefId?: string | null;
        type?: ProfileValueType | null;
        createdAt: any;
        updatedAt: any;
        isTestData?: boolean | null;
        id: any;
        val: {
            __typename?: 'ProfileValueData';
            str?: string | null;
            num?: number | null;
            bool?: boolean | null;
            date?: any | null;
            strs?: Array<string | null> | null;
            nums?: Array<number | null> | null;
            bools?: Array<boolean | null> | null;
            dates?: Array<any | null> | null;
            choice?: string | null;
            choices?: Array<string | null> | null;
            dateTimeZone?: string | null;
            specified?: Array<{
                __typename?: 'SpecifiedValue';
                choiceId: string;
                type: ProfileSpecifiedValueType;
                val: {
                    __typename?: 'ProfileSpecifiedValue';
                    str?: string | null;
                    num?: number | null;
                    bool?: boolean | null;
                    date?: any | null;
                };
            }> | null;
            localized?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        };
        source?: {
            __typename?: 'SourceType';
            type?: SourceTypeEnum | null;
            portalUserId?: any | null;
        } | null;
    } | null> | null;
};

export type UserProfileDefForHistoriesQueryVariables = Exact<{
    input: GetUserProfileDefInput;
}>;

export type UserProfileDefForHistoriesQuery = {
    __typename?: 'Query';
    userProfileDef?: {
        __typename?: 'UserProfileDef';
        valueType: UserProfileValueType;
        choiceValueType?: UserProfileValueType | null;
        id: any;
        name: string;
        choices?: Array<{
            __typename?: 'UserProfileDefChoice';
            id: any;
            specifyValue: boolean;
            name?: string | null;
            label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            answerLabel?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            value?: {
                __typename?: 'UserProfileDefChoiceValue';
                str?: string | null;
                num?: number | null;
                bool?: boolean | null;
                date?: any | null;
            } | null;
        } | null> | null;
        label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
    } | null;
};

export type FetchSurveyResponsesForPatientSurveyPageQueryVariables = Exact<{
    patientInput: GetPatientInput;
}>;

export type FetchSurveyResponsesForPatientSurveyPageQuery = {
    __typename?: 'Query';
    patient?: {
        __typename?: 'Patient';
        id: any;
        createdAt: any;
        externalId?: string | null;
        memberId?: string | null;
        fullName: string;
        firstName: string;
        lastName: string;
        onboardingDate?: any | null;
        email?: string | null;
        birthDate: any;
        phoneNumber?: string | null;
        invitationMode?: PatientInvitation | null;
        invitationCode?: string | null;
        signUpPracticeCode?: string | null;
        isTestData?: boolean | null;
        assignedHealthAdvocate?: any | null;
        openAdvocateTaskCount?: number | null;
        openCriticalAdvocateTaskCount?: number | null;
        onboardingCompleteDate?: any | null;
        isPregnant?: boolean | null;
        dueDate?: any | null;
        deliveryDate?: any | null;
        patientCallStatus?: string | null;
        appUserId?: any | null;
        appBundleId?: string | null;
        surveyResponses: Array<{
            __typename?: 'SurveyResponse';
            surveyType?: string | null;
            clientSurveyId?: string | null;
            responseDate?: any | null;
            surveyScore?: number | null;
            displayName?: string | null;
            id: any;
            createdAt: any;
            updatedAt: any;
            reviewed?: boolean | null;
            isCoreSurvey?: boolean | null;
            answers: Array<{
                __typename?: 'SurveyResponseAnswer';
                subsectionName?: string | null;
                questionTypeId?: string | null;
                clientQuestionId?: string | null;
                questionId?: string | null;
                questionText?: string | null;
                answerId?: any | null;
                answerText?: any | null;
                responseScore?: number | null;
                questionOrder?: number | null;
            }>;
            practice?: {
                __typename?: 'Organization';
                id: any;
                brandingName: string;
                timezone?: string | null;
            } | null;
            patient?: {
                __typename?: 'Patient';
                id: any;
                externalId?: string | null;
                fullName: string;
                firstName: string;
                lastName: string;
                birthDate: any;
                dueDate?: any | null;
            } | null;
        }>;
        attachments?: Array<{
            __typename?: 'Attachment';
            uuid: string;
            label: string;
        } | null> | null;
        riskIndicators?: {
            __typename?: 'PatientRiskIndicators';
            obesity?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            preterm?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            chronicHypertension?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            other?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
        } | null;
        mailingAddress?: {
            __typename?: 'Address';
            street1?: string | null;
            street2?: string | null;
            city?: string | null;
            state?: string | null;
            code?: string | null;
        } | null;
        practice: {
            __typename?: 'Organization';
            id: any;
            name: string;
            timezone?: string | null;
            providers?: Array<{
                __typename?: 'OrganizationProvider';
                provider: {
                    __typename?: 'Provider';
                    id: any;
                    localizedName: string;
                    createdAt: any;
                };
            }> | null;
        };
        questions: Array<{
            __typename?: 'CombinedQuestion';
            id: string;
            createdAt: any;
            updatedAt: any;
            submittedAt: any;
            text: string;
            isDone: boolean;
        }>;
        notes: Array<{
            __typename?: 'PatientNotes';
            id: any;
            text?: string | null;
            title?: string | null;
            staffName?: string | null;
            createdAt: any;
        }>;
        chatRoom: { __typename?: 'ChatRoom'; id: any; numberOfUnreadMessages?: number | null };
        profileValues: Array<{
            __typename?: 'PatientProfileValue';
            profileVariableDefId?: any | null;
            type?: ProfileValueType | null;
            valueBool?: boolean | null;
            valueNum?: number | null;
            valueStr?: string | null;
            valueId?: any | null;
            valueIds?: Array<any | null> | null;
            valueDate?: any | null;
            timeZone?: string | null;
            utcOffset?: number | null;
        }>;
        riskScore?: {
            __typename?: 'RiskScoreResponse';
            value?: number | null;
            tierName?: string | null;
        } | null;
    } | null;
};

export type FetchReferenceLinksForPatientSurveysPageQueryVariables = Exact<{
    input: GetReferenceLinksForPageInput;
}>;

export type FetchReferenceLinksForPatientSurveysPageQuery = {
    __typename?: 'Query';
    getReferenceLinksForPage?: Array<{
        __typename?: 'GetReferenceLinksForPageResponse';
        category?: string | null;
        data?: Array<{
            __typename?: 'LinkDataType';
            label?: string | null;
            url?: string | null;
        } | null> | null;
    } | null> | null;
};

export type FetchPatientTagsForPatientTagsQueryVariables = Exact<{
    input: GetPatientInput;
}>;

export type FetchPatientTagsForPatientTagsQuery = {
    __typename?: 'Query';
    patient?: {
        __typename?: 'Patient';
        id: any;
        firstName: string;
        tags?: Array<{ __typename?: 'Tag'; id: any; name: string } | null> | null;
    } | null;
};

export type CreatePatientTodoTaskMutationVariables = Exact<{
    input: TodoCreateInput;
}>;

export type CreatePatientTodoTaskMutation = {
    __typename?: 'Mutation';
    createHACreatedTodoTask: {
        __typename?: 'AddHATodoTaskResponse';
        status: number;
        message: string;
        success: boolean;
        resourceCreated?: {
            __typename?: 'ProxiedTodoTask';
            id: any;
            dueDate?: string | null;
            repeatType?: string | null;
            createdBy?: string | null;
            isComplete: boolean;
            priority?: number | null;
            effectiveDate?: string | null;
            isEssential?: boolean | null;
            dateCompleted?: string | null;
            title: { __typename?: 'LocalizedStringLoose'; en?: string | null; es?: string | null };
            description?: { __typename?: 'LocalizedStringLoose'; en?: string | null } | null;
            carePlan?: {
                __typename?: 'RelatedCarePlan';
                label?: { __typename?: 'LocalizedStringLoose'; en?: string | null } | null;
                group?: {
                    __typename?: 'RelatedGroup';
                    id?: any | null;
                    label?: { __typename?: 'LocalizedStringLoose'; en?: string | null } | null;
                } | null;
                template?: {
                    __typename?: 'RelatedTemplate';
                    id?: any | null;
                    tags?: Array<string | null> | null;
                } | null;
            } | null;
        } | null;
    };
};

export type UpdateTodoCpUpcomingMutationVariables = Exact<{
    input: TodoUpdateInput;
}>;

export type UpdateTodoCpUpcomingMutation = {
    __typename?: 'Mutation';
    updateProxiedTodoTask: {
        __typename?: 'EditHATodoTaskResponse';
        status: number;
        message: string;
        success: boolean;
        resourceCreated?: {
            __typename?: 'ProxiedTodoTask';
            id: any;
            dueDate?: string | null;
            effectiveDate?: string | null;
            isEssential?: boolean | null;
            priority?: number | null;
            isComplete: boolean;
            title: { __typename?: 'LocalizedStringLoose'; en?: string | null; es?: string | null };
            description?: { __typename?: 'LocalizedStringLoose'; en?: string | null } | null;
        } | null;
    };
};

export type DeleteTodoHaCreatedMutationVariables = Exact<{
    input?: InputMaybe<TodoDeleteInput>;
}>;

export type DeleteTodoHaCreatedMutation = {
    __typename?: 'Mutation';
    deleteProxiedTodoTask?: {
        __typename?: 'DeletePatientTodoResponse';
        status: number;
        message: string;
        success: boolean;
    } | null;
};

export type PatientTodosItemsQueryVariables = Exact<{
    patientId: Scalars['String'];
}>;

export type PatientTodosItemsQuery = {
    __typename?: 'Query';
    patientProxiedTodosItems: Array<{
        __typename?: 'ProxiedTodoTask';
        id: any;
        dueDate?: string | null;
        repeatType?: string | null;
        createdBy?: string | null;
        isComplete: boolean;
        priority?: number | null;
        dateCompleted?: string | null;
        title: { __typename?: 'LocalizedStringLoose'; en?: string | null; es?: string | null };
        carePlan?: {
            __typename?: 'RelatedCarePlan';
            label?: { __typename?: 'LocalizedStringLoose'; en?: string | null } | null;
            group?: {
                __typename?: 'RelatedGroup';
                label?: { __typename?: 'LocalizedStringLoose'; en?: string | null } | null;
            } | null;
        } | null;
    }>;
};

export type PatientTodoTaskQueryQueryVariables = Exact<{
    patientTodoTaskId: Scalars['String'];
}>;

export type PatientTodoTaskQueryQuery = {
    __typename?: 'Query';
    patientProxiedTodoTask?: {
        __typename?: 'ProxiedTodoTask';
        dueDate?: string | null;
        repeatType?: string | null;
        createdBy?: string | null;
        isComplete: boolean;
        priority?: number | null;
        effectiveDate?: string | null;
        isEssential?: boolean | null;
        dateCompleted?: string | null;
        title: { __typename?: 'LocalizedStringLoose'; en?: string | null; es?: string | null };
        carePlan?: {
            __typename?: 'RelatedCarePlan';
            id?: any | null;
            label?: { __typename?: 'LocalizedStringLoose'; en?: string | null } | null;
            group?: {
                __typename?: 'RelatedGroup';
                id?: any | null;
                label?: { __typename?: 'LocalizedStringLoose'; en?: string | null } | null;
            } | null;
            template?: {
                __typename?: 'RelatedTemplate';
                id?: any | null;
                tags?: Array<string | null> | null;
            } | null;
        } | null;
        description?: { __typename?: 'LocalizedStringLoose'; en?: string | null } | null;
    } | null;
};

export type FetchPatientByIdForPatientModalQueryVariables = Exact<{
    input: GetPatientInput;
}>;

export type FetchPatientByIdForPatientModalQuery = {
    __typename?: 'Query';
    patient?: {
        __typename?: 'Patient';
        id: any;
        createdAt: any;
        externalId?: string | null;
        memberId?: string | null;
        fullName: string;
        firstName: string;
        lastName: string;
        onboardingDate?: any | null;
        email?: string | null;
        birthDate: any;
        phoneNumber?: string | null;
        invitationMode?: PatientInvitation | null;
        invitationCode?: string | null;
        signUpPracticeCode?: string | null;
        isTestData?: boolean | null;
        assignedHealthAdvocate?: any | null;
        openAdvocateTaskCount?: number | null;
        openCriticalAdvocateTaskCount?: number | null;
        onboardingCompleteDate?: any | null;
        isPregnant?: boolean | null;
        dueDate?: any | null;
        deliveryDate?: any | null;
        patientCallStatus?: string | null;
        appUserId?: any | null;
        appBundleId?: string | null;
        attachments?: Array<{
            __typename?: 'Attachment';
            uuid: string;
            label: string;
        } | null> | null;
        riskIndicators?: {
            __typename?: 'PatientRiskIndicators';
            obesity?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            preterm?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            chronicHypertension?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            other?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
        } | null;
        mailingAddress?: {
            __typename?: 'Address';
            street1?: string | null;
            street2?: string | null;
            city?: string | null;
            state?: string | null;
            code?: string | null;
        } | null;
        practice: {
            __typename?: 'Organization';
            id: any;
            name: string;
            timezone?: string | null;
            providers?: Array<{
                __typename?: 'OrganizationProvider';
                provider: {
                    __typename?: 'Provider';
                    id: any;
                    localizedName: string;
                    createdAt: any;
                };
            }> | null;
        };
        questions: Array<{
            __typename?: 'CombinedQuestion';
            id: string;
            createdAt: any;
            updatedAt: any;
            submittedAt: any;
            text: string;
            isDone: boolean;
        }>;
        notes: Array<{
            __typename?: 'PatientNotes';
            id: any;
            text?: string | null;
            title?: string | null;
            staffName?: string | null;
            createdAt: any;
        }>;
        chatRoom: { __typename?: 'ChatRoom'; id: any; numberOfUnreadMessages?: number | null };
        profileValues: Array<{
            __typename?: 'PatientProfileValue';
            profileVariableDefId?: any | null;
            type?: ProfileValueType | null;
            valueBool?: boolean | null;
            valueNum?: number | null;
            valueStr?: string | null;
            valueId?: any | null;
            valueIds?: Array<any | null> | null;
            valueDate?: any | null;
            timeZone?: string | null;
            utcOffset?: number | null;
        }>;
        riskScore?: {
            __typename?: 'RiskScoreResponse';
            value?: number | null;
            tierName?: string | null;
        } | null;
    } | null;
};

export type UpdatePatientForPatientsPageMutationVariables = Exact<{
    input: UpdatePatientInput;
}>;

export type UpdatePatientForPatientsPageMutation = {
    __typename?: 'Mutation';
    updatePatient?: {
        __typename?: 'UpdatePatientResponse';
        success: boolean;
        status: number;
        message: string;
        resourceUpdated?: {
            __typename?: 'Patient';
            id: any;
            createdAt: any;
            externalId?: string | null;
            memberId?: string | null;
            fullName: string;
            firstName: string;
            lastName: string;
            onboardingDate?: any | null;
            email?: string | null;
            birthDate: any;
            phoneNumber?: string | null;
            invitationMode?: PatientInvitation | null;
            invitationCode?: string | null;
            signUpPracticeCode?: string | null;
            isTestData?: boolean | null;
            assignedHealthAdvocate?: any | null;
            openAdvocateTaskCount?: number | null;
            openCriticalAdvocateTaskCount?: number | null;
            onboardingCompleteDate?: any | null;
            isPregnant?: boolean | null;
            dueDate?: any | null;
            deliveryDate?: any | null;
            patientCallStatus?: string | null;
            appUserId?: any | null;
            appBundleId?: string | null;
            attachments?: Array<{
                __typename?: 'Attachment';
                uuid: string;
                label: string;
            } | null> | null;
            riskIndicators?: {
                __typename?: 'PatientRiskIndicators';
                obesity?: {
                    __typename?: 'PatientRiskIndicator';
                    flag: boolean;
                    comment?: string | null;
                } | null;
                preterm?: {
                    __typename?: 'PatientRiskIndicator';
                    flag: boolean;
                    comment?: string | null;
                } | null;
                chronicHypertension?: {
                    __typename?: 'PatientRiskIndicator';
                    flag: boolean;
                    comment?: string | null;
                } | null;
                other?: {
                    __typename?: 'PatientRiskIndicator';
                    flag: boolean;
                    comment?: string | null;
                } | null;
            } | null;
            mailingAddress?: {
                __typename?: 'Address';
                street1?: string | null;
                street2?: string | null;
                city?: string | null;
                state?: string | null;
                code?: string | null;
            } | null;
            practice: {
                __typename?: 'Organization';
                id: any;
                name: string;
                timezone?: string | null;
                providers?: Array<{
                    __typename?: 'OrganizationProvider';
                    provider: {
                        __typename?: 'Provider';
                        id: any;
                        localizedName: string;
                        createdAt: any;
                    };
                }> | null;
            };
            questions: Array<{
                __typename?: 'CombinedQuestion';
                id: string;
                createdAt: any;
                updatedAt: any;
                submittedAt: any;
                text: string;
                isDone: boolean;
            }>;
            notes: Array<{
                __typename?: 'PatientNotes';
                id: any;
                text?: string | null;
                title?: string | null;
                staffName?: string | null;
                createdAt: any;
            }>;
            chatRoom: { __typename?: 'ChatRoom'; id: any; numberOfUnreadMessages?: number | null };
            profileValues: Array<{
                __typename?: 'PatientProfileValue';
                profileVariableDefId?: any | null;
                type?: ProfileValueType | null;
                valueBool?: boolean | null;
                valueNum?: number | null;
                valueStr?: string | null;
                valueId?: any | null;
                valueIds?: Array<any | null> | null;
                valueDate?: any | null;
                timeZone?: string | null;
                utcOffset?: number | null;
            }>;
            riskScore?: {
                __typename?: 'RiskScoreResponse';
                value?: number | null;
                tierName?: string | null;
            } | null;
        } | null;
    } | null;
};

export type CreatePatientForPatientsPageMutationVariables = Exact<{
    input: PatientInput;
}>;

export type CreatePatientForPatientsPageMutation = {
    __typename?: 'Mutation';
    createPatient?: {
        __typename?: 'CreatePatientResponse';
        success: boolean;
        message: string;
        patient?: {
            __typename?: 'Patient';
            id: any;
            createdAt: any;
            externalId?: string | null;
            memberId?: string | null;
            fullName: string;
            firstName: string;
            lastName: string;
            onboardingDate?: any | null;
            email?: string | null;
            birthDate: any;
            phoneNumber?: string | null;
            invitationMode?: PatientInvitation | null;
            invitationCode?: string | null;
            signUpPracticeCode?: string | null;
            isTestData?: boolean | null;
            assignedHealthAdvocate?: any | null;
            openAdvocateTaskCount?: number | null;
            openCriticalAdvocateTaskCount?: number | null;
            onboardingCompleteDate?: any | null;
            isPregnant?: boolean | null;
            dueDate?: any | null;
            deliveryDate?: any | null;
            patientCallStatus?: string | null;
            appUserId?: any | null;
            appBundleId?: string | null;
            attachments?: Array<{
                __typename?: 'Attachment';
                uuid: string;
                label: string;
            } | null> | null;
            riskIndicators?: {
                __typename?: 'PatientRiskIndicators';
                obesity?: {
                    __typename?: 'PatientRiskIndicator';
                    flag: boolean;
                    comment?: string | null;
                } | null;
                preterm?: {
                    __typename?: 'PatientRiskIndicator';
                    flag: boolean;
                    comment?: string | null;
                } | null;
                chronicHypertension?: {
                    __typename?: 'PatientRiskIndicator';
                    flag: boolean;
                    comment?: string | null;
                } | null;
                other?: {
                    __typename?: 'PatientRiskIndicator';
                    flag: boolean;
                    comment?: string | null;
                } | null;
            } | null;
            mailingAddress?: {
                __typename?: 'Address';
                street1?: string | null;
                street2?: string | null;
                city?: string | null;
                state?: string | null;
                code?: string | null;
            } | null;
            practice: {
                __typename?: 'Organization';
                id: any;
                name: string;
                timezone?: string | null;
                providers?: Array<{
                    __typename?: 'OrganizationProvider';
                    provider: {
                        __typename?: 'Provider';
                        id: any;
                        localizedName: string;
                        createdAt: any;
                    };
                }> | null;
            };
            questions: Array<{
                __typename?: 'CombinedQuestion';
                id: string;
                createdAt: any;
                updatedAt: any;
                submittedAt: any;
                text: string;
                isDone: boolean;
            }>;
            notes: Array<{
                __typename?: 'PatientNotes';
                id: any;
                text?: string | null;
                title?: string | null;
                staffName?: string | null;
                createdAt: any;
            }>;
            chatRoom: { __typename?: 'ChatRoom'; id: any; numberOfUnreadMessages?: number | null };
            profileValues: Array<{
                __typename?: 'PatientProfileValue';
                profileVariableDefId?: any | null;
                type?: ProfileValueType | null;
                valueBool?: boolean | null;
                valueNum?: number | null;
                valueStr?: string | null;
                valueId?: any | null;
                valueIds?: Array<any | null> | null;
                valueDate?: any | null;
                timeZone?: string | null;
                utcOffset?: number | null;
            }>;
            riskScore?: {
                __typename?: 'RiskScoreResponse';
                value?: number | null;
                tierName?: string | null;
            } | null;
        } | null;
    } | null;
};

export type FetchCurrentUserForPatientModalQueryVariables = Exact<{ [key: string]: never }>;

export type FetchCurrentUserForPatientModalQuery = {
    __typename?: 'Query';
    currentUser?: {
        __typename?: 'User';
        id: any;
        name: string;
        email: string;
        currentOrg: { __typename?: 'Organization'; id: any };
        currentOrganizations: Array<{
            __typename?: 'Organization';
            id: any;
            parentId?: any | null;
            brandingName: string;
        }>;
    } | null;
};

export type FetchPatientNotesForPatientNotesModalQueryVariables = Exact<{
    input: GetPatientInput;
}>;

export type FetchPatientNotesForPatientNotesModalQuery = {
    __typename?: 'Query';
    patient?: {
        __typename?: 'Patient';
        id: any;
        firstName: string;
        notes: Array<{
            __typename?: 'PatientNotes';
            id: any;
            createdAt: any;
            title?: string | null;
            date?: any | null;
            noteSource?: string | null;
            noteType?: Array<string | null> | null;
            staffName?: string | null;
            text?: string | null;
            NPI?: string | null;
            patient?: { __typename?: 'Patient'; id: any } | null;
        }>;
    } | null;
};

export type CreateNewPatientNoteFromPatientNotesModalMutationVariables = Exact<{
    input: PatientNotesInput;
}>;

export type CreateNewPatientNoteFromPatientNotesModalMutation = {
    __typename?: 'Mutation';
    createPatientNotes?: {
        __typename?: 'CreatePatientNotesResponse';
        message: string;
        success: boolean;
        resourceCreated?: {
            __typename?: 'PatientNotes';
            id: any;
            createdAt: any;
            title?: string | null;
            date?: any | null;
            noteSource?: string | null;
            noteType?: Array<string | null> | null;
            staffName?: string | null;
            text?: string | null;
            patient?: {
                __typename?: 'Patient';
                id: any;
                firstName: string;
                notes: Array<{
                    __typename?: 'PatientNotes';
                    id: any;
                    createdAt: any;
                    title?: string | null;
                    date?: any | null;
                    noteSource?: string | null;
                    noteType?: Array<string | null> | null;
                    staffName?: string | null;
                    text?: string | null;
                    patient?: { __typename?: 'Patient'; id: any } | null;
                }>;
            } | null;
        } | null;
    } | null;
};

export type UpdatePatientNoteOnPatientsNotesModalMutationVariables = Exact<{
    input: UpdatePatientNotesInput;
}>;

export type UpdatePatientNoteOnPatientsNotesModalMutation = {
    __typename?: 'Mutation';
    updatePatientNotes?: {
        __typename?: 'UpdatePatientNotesResponse';
        success: boolean;
        message: string;
        resourceUpdated?: {
            __typename?: 'PatientNotes';
            id: any;
            createdAt: any;
            title?: string | null;
            date?: any | null;
            noteSource?: string | null;
            noteType?: Array<string | null> | null;
            staffName?: string | null;
            text?: string | null;
            patient?: { __typename?: 'Patient'; id: any } | null;
        } | null;
    } | null;
};

export type NoteTagListForPatientNotePageQueryVariables = Exact<{ [key: string]: never }>;

export type NoteTagListForPatientNotePageQuery = {
    __typename?: 'Query';
    patientNoteTagsV2: {
        __typename?: 'PatientNoteTagPaginationQueryResponse';
        results: Array<{ __typename?: 'PatientNoteTag'; id: any; name: string }>;
    };
};

export type FetchPatientsForPatientsPageQueryQueryVariables = Exact<{
    input: GetPatientsByFilterInput;
}>;

export type FetchPatientsForPatientsPageQueryQuery = {
    __typename?: 'Query';
    currentUser?: {
        __typename?: 'User';
        id: any;
        name: string;
        email: string;
        organizations: Array<{
            __typename?: 'Organization';
            id: any;
            parentId?: any | null;
            brandingName: string;
        }>;
        currentOrg: {
            __typename?: 'Organization';
            id: any;
            parentId?: any | null;
            brandingName: string;
            allowPatientCreation?: boolean | null;
            appPlatform?: string | null;
            appBundleId?: string | null;
            configOptions?: {
                __typename?: 'OrganizationConfigOptions';
                riskScoreEnabled: boolean;
            } | null;
        };
        patientsV2: {
            __typename?: 'UserPatientsResponse';
            orderBy?: string | null;
            skip?: number | null;
            limit?: number | null;
            total: number;
            hasMore?: boolean | null;
            hasPrevious?: boolean | null;
            results: Array<{
                __typename?: 'Patient';
                id: any;
                createdAt: any;
                externalId?: string | null;
                memberId?: string | null;
                fullName: string;
                firstName: string;
                lastName: string;
                onboardingDate?: any | null;
                email?: string | null;
                birthDate: any;
                phoneNumber?: string | null;
                invitationMode?: PatientInvitation | null;
                invitationCode?: string | null;
                signUpPracticeCode?: string | null;
                isTestData?: boolean | null;
                assignedHealthAdvocate?: any | null;
                openAdvocateTaskCount?: number | null;
                openCriticalAdvocateTaskCount?: number | null;
                onboardingCompleteDate?: any | null;
                isPregnant?: boolean | null;
                dueDate?: any | null;
                deliveryDate?: any | null;
                patientCallStatus?: string | null;
                appUserId?: any | null;
                appBundleId?: string | null;
                attachments?: Array<{
                    __typename?: 'Attachment';
                    uuid: string;
                    label: string;
                } | null> | null;
                riskIndicators?: {
                    __typename?: 'PatientRiskIndicators';
                    obesity?: {
                        __typename?: 'PatientRiskIndicator';
                        flag: boolean;
                        comment?: string | null;
                    } | null;
                    preterm?: {
                        __typename?: 'PatientRiskIndicator';
                        flag: boolean;
                        comment?: string | null;
                    } | null;
                    chronicHypertension?: {
                        __typename?: 'PatientRiskIndicator';
                        flag: boolean;
                        comment?: string | null;
                    } | null;
                    other?: {
                        __typename?: 'PatientRiskIndicator';
                        flag: boolean;
                        comment?: string | null;
                    } | null;
                } | null;
                mailingAddress?: {
                    __typename?: 'Address';
                    street1?: string | null;
                    street2?: string | null;
                    city?: string | null;
                    state?: string | null;
                    code?: string | null;
                } | null;
                practice: {
                    __typename?: 'Organization';
                    id: any;
                    name: string;
                    timezone?: string | null;
                    providers?: Array<{
                        __typename?: 'OrganizationProvider';
                        provider: {
                            __typename?: 'Provider';
                            id: any;
                            localizedName: string;
                            createdAt: any;
                        };
                    }> | null;
                };
                questions: Array<{
                    __typename?: 'CombinedQuestion';
                    id: string;
                    createdAt: any;
                    updatedAt: any;
                    submittedAt: any;
                    text: string;
                    isDone: boolean;
                }>;
                notes: Array<{
                    __typename?: 'PatientNotes';
                    id: any;
                    text?: string | null;
                    title?: string | null;
                    staffName?: string | null;
                    createdAt: any;
                }>;
                chatRoom: {
                    __typename?: 'ChatRoom';
                    id: any;
                    numberOfUnreadMessages?: number | null;
                };
                profileValues: Array<{
                    __typename?: 'PatientProfileValue';
                    profileVariableDefId?: any | null;
                    type?: ProfileValueType | null;
                    valueBool?: boolean | null;
                    valueNum?: number | null;
                    valueStr?: string | null;
                    valueId?: any | null;
                    valueIds?: Array<any | null> | null;
                    valueDate?: any | null;
                    timeZone?: string | null;
                    utcOffset?: number | null;
                }>;
                riskScore?: {
                    __typename?: 'RiskScoreResponse';
                    value?: number | null;
                    tierName?: string | null;
                } | null;
            }>;
        };
    } | null;
};

export type ResendPatientInviteOnPatientsPageMutationVariables = Exact<{
    input: ResendPatientInvitationFromPortalInput;
}>;

export type ResendPatientInviteOnPatientsPageMutation = {
    __typename?: 'Mutation';
    resendPatientInvitationFromPortal?: {
        __typename?: 'ResendPatientInvitationFromPortalResponse';
        success: boolean;
        message: string;
        patient?: {
            __typename?: 'Patient';
            id: any;
            createdAt: any;
            externalId?: string | null;
            memberId?: string | null;
            fullName: string;
            firstName: string;
            lastName: string;
            onboardingDate?: any | null;
            email?: string | null;
            birthDate: any;
            phoneNumber?: string | null;
            invitationMode?: PatientInvitation | null;
            invitationCode?: string | null;
            signUpPracticeCode?: string | null;
            isTestData?: boolean | null;
            assignedHealthAdvocate?: any | null;
            openAdvocateTaskCount?: number | null;
            openCriticalAdvocateTaskCount?: number | null;
            onboardingCompleteDate?: any | null;
            isPregnant?: boolean | null;
            dueDate?: any | null;
            deliveryDate?: any | null;
            patientCallStatus?: string | null;
            appUserId?: any | null;
            appBundleId?: string | null;
            attachments?: Array<{
                __typename?: 'Attachment';
                uuid: string;
                label: string;
            } | null> | null;
            riskIndicators?: {
                __typename?: 'PatientRiskIndicators';
                obesity?: {
                    __typename?: 'PatientRiskIndicator';
                    flag: boolean;
                    comment?: string | null;
                } | null;
                preterm?: {
                    __typename?: 'PatientRiskIndicator';
                    flag: boolean;
                    comment?: string | null;
                } | null;
                chronicHypertension?: {
                    __typename?: 'PatientRiskIndicator';
                    flag: boolean;
                    comment?: string | null;
                } | null;
                other?: {
                    __typename?: 'PatientRiskIndicator';
                    flag: boolean;
                    comment?: string | null;
                } | null;
            } | null;
            mailingAddress?: {
                __typename?: 'Address';
                street1?: string | null;
                street2?: string | null;
                city?: string | null;
                state?: string | null;
                code?: string | null;
            } | null;
            practice: {
                __typename?: 'Organization';
                id: any;
                name: string;
                timezone?: string | null;
                providers?: Array<{
                    __typename?: 'OrganizationProvider';
                    provider: {
                        __typename?: 'Provider';
                        id: any;
                        localizedName: string;
                        createdAt: any;
                    };
                }> | null;
            };
            questions: Array<{
                __typename?: 'CombinedQuestion';
                id: string;
                createdAt: any;
                updatedAt: any;
                submittedAt: any;
                text: string;
                isDone: boolean;
            }>;
            notes: Array<{
                __typename?: 'PatientNotes';
                id: any;
                text?: string | null;
                title?: string | null;
                staffName?: string | null;
                createdAt: any;
            }>;
            chatRoom: { __typename?: 'ChatRoom'; id: any; numberOfUnreadMessages?: number | null };
            profileValues: Array<{
                __typename?: 'PatientProfileValue';
                profileVariableDefId?: any | null;
                type?: ProfileValueType | null;
                valueBool?: boolean | null;
                valueNum?: number | null;
                valueStr?: string | null;
                valueId?: any | null;
                valueIds?: Array<any | null> | null;
                valueDate?: any | null;
                timeZone?: string | null;
                utcOffset?: number | null;
            }>;
            riskScore?: {
                __typename?: 'RiskScoreResponse';
                value?: number | null;
                tierName?: string | null;
            } | null;
        } | null;
    } | null;
};

export type ResetPatientPasswordOnPatientsPageMutationVariables = Exact<{
    input: ResetPatientPasswordFromPortalInput;
}>;

export type ResetPatientPasswordOnPatientsPageMutation = {
    __typename?: 'Mutation';
    resetPatientPasswordFromPortal?: {
        __typename?: 'ResetPatientPasswordFromPortalResponse';
        success: boolean;
        message: string;
        patient?: {
            __typename?: 'Patient';
            id: any;
            createdAt: any;
            externalId?: string | null;
            memberId?: string | null;
            fullName: string;
            firstName: string;
            lastName: string;
            onboardingDate?: any | null;
            email?: string | null;
            birthDate: any;
            phoneNumber?: string | null;
            invitationMode?: PatientInvitation | null;
            invitationCode?: string | null;
            signUpPracticeCode?: string | null;
            isTestData?: boolean | null;
            assignedHealthAdvocate?: any | null;
            openAdvocateTaskCount?: number | null;
            openCriticalAdvocateTaskCount?: number | null;
            onboardingCompleteDate?: any | null;
            isPregnant?: boolean | null;
            dueDate?: any | null;
            deliveryDate?: any | null;
            patientCallStatus?: string | null;
            appUserId?: any | null;
            appBundleId?: string | null;
            attachments?: Array<{
                __typename?: 'Attachment';
                uuid: string;
                label: string;
            } | null> | null;
            riskIndicators?: {
                __typename?: 'PatientRiskIndicators';
                obesity?: {
                    __typename?: 'PatientRiskIndicator';
                    flag: boolean;
                    comment?: string | null;
                } | null;
                preterm?: {
                    __typename?: 'PatientRiskIndicator';
                    flag: boolean;
                    comment?: string | null;
                } | null;
                chronicHypertension?: {
                    __typename?: 'PatientRiskIndicator';
                    flag: boolean;
                    comment?: string | null;
                } | null;
                other?: {
                    __typename?: 'PatientRiskIndicator';
                    flag: boolean;
                    comment?: string | null;
                } | null;
            } | null;
            mailingAddress?: {
                __typename?: 'Address';
                street1?: string | null;
                street2?: string | null;
                city?: string | null;
                state?: string | null;
                code?: string | null;
            } | null;
            practice: {
                __typename?: 'Organization';
                id: any;
                name: string;
                timezone?: string | null;
                providers?: Array<{
                    __typename?: 'OrganizationProvider';
                    provider: {
                        __typename?: 'Provider';
                        id: any;
                        localizedName: string;
                        createdAt: any;
                    };
                }> | null;
            };
            questions: Array<{
                __typename?: 'CombinedQuestion';
                id: string;
                createdAt: any;
                updatedAt: any;
                submittedAt: any;
                text: string;
                isDone: boolean;
            }>;
            notes: Array<{
                __typename?: 'PatientNotes';
                id: any;
                text?: string | null;
                title?: string | null;
                staffName?: string | null;
                createdAt: any;
            }>;
            chatRoom: { __typename?: 'ChatRoom'; id: any; numberOfUnreadMessages?: number | null };
            profileValues: Array<{
                __typename?: 'PatientProfileValue';
                profileVariableDefId?: any | null;
                type?: ProfileValueType | null;
                valueBool?: boolean | null;
                valueNum?: number | null;
                valueStr?: string | null;
                valueId?: any | null;
                valueIds?: Array<any | null> | null;
                valueDate?: any | null;
                timeZone?: string | null;
                utcOffset?: number | null;
            }>;
            riskScore?: {
                __typename?: 'RiskScoreResponse';
                value?: number | null;
                tierName?: string | null;
            } | null;
        } | null;
    } | null;
};

export type DeletePatientOnPatientsPageMutationVariables = Exact<{
    input: DeletePatientAppUserDownstreamDataInput;
}>;

export type DeletePatientOnPatientsPageMutation = {
    __typename?: 'Mutation';
    deletePatientAppUserDownstreamData?: {
        __typename?: 'DeletePatientResponseV2';
        status: number;
        message: string;
        success: boolean;
        patientId?: any | null;
        deletedCollections?: Array<{
            __typename?: 'DeletedCollection';
            collection: string;
            isDeleted: boolean;
            deletedCount: number;
            error?: string | null;
        }> | null;
    } | null;
};

export type UploadManyPatientsForImporterMutationVariables = Exact<{
    input: CreateManyPatientsInput;
}>;

export type UploadManyPatientsForImporterMutation = {
    __typename?: 'Mutation';
    createManyPatients?: {
        __typename?: 'CreateManyPatientsResponse';
        success: boolean;
        message: string;
        resourcesCreated: Array<{
            __typename?: 'CreateManyIndividualPatientResponse';
            success: boolean;
            message: string;
            resourceCreated?: {
                __typename?: 'Patient';
                id: any;
                createdAt: any;
                externalId?: string | null;
                memberId?: string | null;
                fullName: string;
                firstName: string;
                lastName: string;
                onboardingDate?: any | null;
                email?: string | null;
                birthDate: any;
                phoneNumber?: string | null;
                invitationMode?: PatientInvitation | null;
                invitationCode?: string | null;
                signUpPracticeCode?: string | null;
                isTestData?: boolean | null;
                assignedHealthAdvocate?: any | null;
                openAdvocateTaskCount?: number | null;
                openCriticalAdvocateTaskCount?: number | null;
                onboardingCompleteDate?: any | null;
                isPregnant?: boolean | null;
                dueDate?: any | null;
                deliveryDate?: any | null;
                patientCallStatus?: string | null;
                appUserId?: any | null;
                appBundleId?: string | null;
                attachments?: Array<{
                    __typename?: 'Attachment';
                    uuid: string;
                    label: string;
                } | null> | null;
                riskIndicators?: {
                    __typename?: 'PatientRiskIndicators';
                    obesity?: {
                        __typename?: 'PatientRiskIndicator';
                        flag: boolean;
                        comment?: string | null;
                    } | null;
                    preterm?: {
                        __typename?: 'PatientRiskIndicator';
                        flag: boolean;
                        comment?: string | null;
                    } | null;
                    chronicHypertension?: {
                        __typename?: 'PatientRiskIndicator';
                        flag: boolean;
                        comment?: string | null;
                    } | null;
                    other?: {
                        __typename?: 'PatientRiskIndicator';
                        flag: boolean;
                        comment?: string | null;
                    } | null;
                } | null;
                mailingAddress?: {
                    __typename?: 'Address';
                    street1?: string | null;
                    street2?: string | null;
                    city?: string | null;
                    state?: string | null;
                    code?: string | null;
                } | null;
                practice: {
                    __typename?: 'Organization';
                    id: any;
                    name: string;
                    timezone?: string | null;
                    providers?: Array<{
                        __typename?: 'OrganizationProvider';
                        provider: {
                            __typename?: 'Provider';
                            id: any;
                            localizedName: string;
                            createdAt: any;
                        };
                    }> | null;
                };
                questions: Array<{
                    __typename?: 'CombinedQuestion';
                    id: string;
                    createdAt: any;
                    updatedAt: any;
                    submittedAt: any;
                    text: string;
                    isDone: boolean;
                }>;
                notes: Array<{
                    __typename?: 'PatientNotes';
                    id: any;
                    text?: string | null;
                    title?: string | null;
                    staffName?: string | null;
                    createdAt: any;
                }>;
                chatRoom: {
                    __typename?: 'ChatRoom';
                    id: any;
                    numberOfUnreadMessages?: number | null;
                };
                profileValues: Array<{
                    __typename?: 'PatientProfileValue';
                    profileVariableDefId?: any | null;
                    type?: ProfileValueType | null;
                    valueBool?: boolean | null;
                    valueNum?: number | null;
                    valueStr?: string | null;
                    valueId?: any | null;
                    valueIds?: Array<any | null> | null;
                    valueDate?: any | null;
                    timeZone?: string | null;
                    utcOffset?: number | null;
                }>;
                riskScore?: {
                    __typename?: 'RiskScoreResponse';
                    value?: number | null;
                    tierName?: string | null;
                } | null;
            } | null;
        }>;
    } | null;
};

export type UsersV2ForPatientsPageQueryVariables = Exact<{ [key: string]: never }>;

export type UsersV2ForPatientsPageQuery = {
    __typename?: 'Query';
    usersV2: {
        __typename?: 'UserPaginationQueryResponse';
        results: Array<{
            __typename?: 'User';
            id: any;
            name: string;
            role?: {
                __typename?: 'Role';
                permissions?: Array<{
                    __typename?: 'Permissions';
                    permission?: Permission | null;
                    target?: Target | null;
                    canExport?: boolean | null;
                } | null> | null;
            } | null;
        }>;
    };
};

export type FetchPatientsExportQueryVariables = Exact<{
    input: ExportPatientsByFilterInput;
}>;

export type FetchPatientsExportQuery = {
    __typename?: 'Query';
    currentUser?: {
        __typename?: 'User';
        exportPatients: {
            __typename?: 'PatientsExportResponse';
            data?: Array<string | null> | null;
            fileName: string;
        };
    } | null;
};

export type FetchPatientByIdForVbcPatientModalQueryVariables = Exact<{
    input: GetPatientInput;
}>;

export type FetchPatientByIdForVbcPatientModalQuery = {
    __typename?: 'Query';
    patient?: {
        __typename?: 'Patient';
        id: any;
        createdAt: any;
        externalId?: string | null;
        memberId?: string | null;
        fullName: string;
        firstName: string;
        lastName: string;
        onboardingDate?: any | null;
        email?: string | null;
        birthDate: any;
        phoneNumber?: string | null;
        invitationMode?: PatientInvitation | null;
        invitationCode?: string | null;
        signUpPracticeCode?: string | null;
        isTestData?: boolean | null;
        assignedHealthAdvocate?: any | null;
        openAdvocateTaskCount?: number | null;
        openCriticalAdvocateTaskCount?: number | null;
        onboardingCompleteDate?: any | null;
        isPregnant?: boolean | null;
        dueDate?: any | null;
        deliveryDate?: any | null;
        patientCallStatus?: string | null;
        appUserId?: any | null;
        appBundleId?: string | null;
        attachments?: Array<{
            __typename?: 'Attachment';
            uuid: string;
            label: string;
        } | null> | null;
        riskIndicators?: {
            __typename?: 'PatientRiskIndicators';
            obesity?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            preterm?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            chronicHypertension?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
            other?: {
                __typename?: 'PatientRiskIndicator';
                flag: boolean;
                comment?: string | null;
            } | null;
        } | null;
        mailingAddress?: {
            __typename?: 'Address';
            street1?: string | null;
            street2?: string | null;
            city?: string | null;
            state?: string | null;
            code?: string | null;
        } | null;
        practice: {
            __typename?: 'Organization';
            id: any;
            name: string;
            timezone?: string | null;
            providers?: Array<{
                __typename?: 'OrganizationProvider';
                provider: {
                    __typename?: 'Provider';
                    id: any;
                    localizedName: string;
                    createdAt: any;
                };
            }> | null;
        };
        questions: Array<{
            __typename?: 'CombinedQuestion';
            id: string;
            createdAt: any;
            updatedAt: any;
            submittedAt: any;
            text: string;
            isDone: boolean;
        }>;
        notes: Array<{
            __typename?: 'PatientNotes';
            id: any;
            text?: string | null;
            title?: string | null;
            staffName?: string | null;
            createdAt: any;
        }>;
        chatRoom: { __typename?: 'ChatRoom'; id: any; numberOfUnreadMessages?: number | null };
        profileValues: Array<{
            __typename?: 'PatientProfileValue';
            profileVariableDefId?: any | null;
            type?: ProfileValueType | null;
            valueBool?: boolean | null;
            valueNum?: number | null;
            valueStr?: string | null;
            valueId?: any | null;
            valueIds?: Array<any | null> | null;
            valueDate?: any | null;
            timeZone?: string | null;
            utcOffset?: number | null;
        }>;
        riskScore?: {
            __typename?: 'RiskScoreResponse';
            value?: number | null;
            tierName?: string | null;
        } | null;
    } | null;
};

export type FetchProfileDefsForVbcPatientModalQueryVariables = Exact<{
    input: AdditionalPatientFieldsInput;
}>;

export type FetchProfileDefsForVbcPatientModalQuery = {
    __typename?: 'Query';
    additionalPatientFieldsProfileDefs: {
        __typename?: 'AdditionalPatientFieldsResponse';
        profileDefs: Array<{
            __typename?: 'UserProfileDef';
            name: string;
            choiceValueType?: UserProfileValueType | null;
            valueType: UserProfileValueType;
            category: string;
            id: any;
            label?: { __typename?: 'LocalizedString'; en: string } | null;
            choices?: Array<{
                __typename?: 'UserProfileDefChoice';
                id: any;
                specifyValue: boolean;
                label?: { __typename?: 'LocalizedString'; en: string } | null;
                value?: {
                    __typename?: 'UserProfileDefChoiceValue';
                    str?: string | null;
                    num?: number | null;
                    bool?: boolean | null;
                    date?: any | null;
                } | null;
            } | null> | null;
        }>;
        careTeamMembers: Array<{
            __typename?: 'CareTeamMembersByType';
            careTeamType: { __typename?: 'CareTeamMemberType'; name: string; profileDefId: any };
            careTeamMembers: Array<{
                __typename?: 'CareTeamMember';
                id: any;
                label: { __typename?: 'LocalizedString'; en: string };
            }>;
        }>;
    };
};

export type FetchUserProfileDefinitionsForVbcPatientModalQueryVariables = Exact<{
    input?: InputMaybe<UserProfileDefQueryInput>;
}>;

export type FetchUserProfileDefinitionsForVbcPatientModalQuery = {
    __typename?: 'Query';
    userProfileDefsV2: {
        __typename?: 'UserProfileDefPaginationQueryResponse';
        results: Array<{
            __typename?: 'UserProfileDef';
            name: string;
            choiceValueType?: UserProfileValueType | null;
            valueType: UserProfileValueType;
            category: string;
            id: any;
            label?: { __typename?: 'LocalizedString'; en: string } | null;
            choices?: Array<{
                __typename?: 'UserProfileDefChoice';
                id: any;
                specifyValue: boolean;
                label?: { __typename?: 'LocalizedString'; en: string } | null;
                value?: {
                    __typename?: 'UserProfileDefChoiceValue';
                    str?: string | null;
                    num?: number | null;
                    bool?: boolean | null;
                    date?: any | null;
                } | null;
            } | null> | null;
        }>;
    };
};

export type CreateAppUserForPatientMutationVariables = Exact<{
    input: CreateAppUserForPatientInput;
}>;

export type CreateAppUserForPatientMutation = {
    __typename?: 'Mutation';
    createAppUserForPatient?: {
        __typename?: 'CreateAppUserForPatientResponse';
        appUserId?: string | null;
        patientId?: string | null;
    } | null;
};

export type CreateOrUpdateManyUserProfileVariablesMutationVariables = Exact<{
    input: Array<ProfileVariableInput> | ProfileVariableInput;
}>;

export type CreateOrUpdateManyUserProfileVariablesMutation = {
    __typename?: 'Mutation';
    createOrUpdateManyUserProfileVariables?: {
        __typename?: 'ProfileVariableMutationResponse';
        success: boolean;
    } | null;
};

export type UpdatePatientOnPatientsPageMutationVariables = Exact<{
    input: UpdatePatientInput;
}>;

export type UpdatePatientOnPatientsPageMutation = {
    __typename?: 'Mutation';
    updatePatient?: {
        __typename?: 'UpdatePatientResponse';
        success: boolean;
        status: number;
        message: string;
        resourceUpdated?: {
            __typename?: 'Patient';
            id: any;
            firstName: string;
            lastName: string;
            email?: string | null;
            isTestData?: boolean | null;
        } | null;
    } | null;
};

export type FetchDevicesForDevicesPageQueryQueryVariables = Exact<{ [key: string]: never }>;

export type FetchDevicesForDevicesPageQueryQuery = {
    __typename?: 'Query';
    devices: Array<{
        __typename?: 'Device';
        id: any;
        createdAt: any;
        updatedAt: any;
        externalId?: string | null;
        photo?: string | null;
        localizedName?: string | null;
        localizedDocLink?: string | null;
        docLink?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    }>;
};

export type FetchDeviceByIdForDeviceModalQueryVariables = Exact<{
    input: GetDeviceInput;
}>;

export type FetchDeviceByIdForDeviceModalQuery = {
    __typename?: 'Query';
    device?: {
        __typename?: 'Device';
        id: any;
        createdAt: any;
        updatedAt: any;
        externalId?: string | null;
        photo?: string | null;
        localizedName?: string | null;
        localizedDocLink?: string | null;
        docLink?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    } | null;
};

export type CreateDeviceFromDevicesPageMutationVariables = Exact<{
    input: DeviceInput;
}>;

export type CreateDeviceFromDevicesPageMutation = {
    __typename?: 'Mutation';
    createDevice?: {
        __typename?: 'CreateDeviceResponse';
        success: boolean;
        message: string;
        status: number;
        resourceCreated?: {
            __typename?: 'Device';
            id: any;
            createdAt: any;
            updatedAt: any;
            externalId?: string | null;
            photo?: string | null;
            localizedName?: string | null;
            localizedDocLink?: string | null;
            docLink?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        } | null;
    } | null;
};

export type UpdateDeviceFromDevicesPageMutationVariables = Exact<{
    input: UpdateDeviceInput;
}>;

export type UpdateDeviceFromDevicesPageMutation = {
    __typename?: 'Mutation';
    updateDevice?: {
        __typename?: 'UpdateDeviceResponse';
        success: boolean;
        message: string;
        status: number;
        resourceUpdated?: {
            __typename?: 'Device';
            id: any;
            createdAt: any;
            updatedAt: any;
            externalId?: string | null;
            photo?: string | null;
            localizedName?: string | null;
            localizedDocLink?: string | null;
            docLink?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        } | null;
    } | null;
};

export type FetchHealthPlanByIdForHealthPlanModalQueryVariables = Exact<{
    input: GetHealthPlanInput;
}>;

export type FetchHealthPlanByIdForHealthPlanModalQuery = {
    __typename?: 'Query';
    healthPlan?: {
        __typename?: 'HealthPlan';
        id: any;
        name?: string | null;
        brandingName?: string | null;
        externalId?: string | null;
        healthPlanCode?: string | null;
        eligibilityType?: EligibilityType | null;
        state?: string | null;
        surveys: Array<string>;
    } | null;
};

export type UpdateHealthPlanForModalMutationVariables = Exact<{
    input: UpdateHealthPlanInput;
}>;

export type UpdateHealthPlanForModalMutation = {
    __typename?: 'Mutation';
    updateHealthPlan?: {
        __typename?: 'UpdateHealthPlanResponse';
        message: string;
        success: boolean;
        resourceUpdated?: {
            __typename?: 'HealthPlan';
            id: any;
            name?: string | null;
            brandingName?: string | null;
            externalId?: string | null;
            healthPlanCode?: string | null;
            eligibilityType?: EligibilityType | null;
            state?: string | null;
            surveys: Array<string>;
        } | null;
    } | null;
};

export type CreateHealthPlanForModalMutationVariables = Exact<{
    input: HealthPlanInput;
}>;

export type CreateHealthPlanForModalMutation = {
    __typename?: 'Mutation';
    createHealthPlan?: {
        __typename?: 'CreateHealthPlanResponse';
        message: string;
        success: boolean;
        resourceCreated?: {
            __typename?: 'HealthPlan';
            id: any;
            name?: string | null;
            brandingName?: string | null;
            externalId?: string | null;
            healthPlanCode?: string | null;
            eligibilityType?: EligibilityType | null;
            state?: string | null;
            surveys: Array<string>;
        } | null;
    } | null;
};

export type FetchHealthPlansForHealthPlansPageQueryQueryVariables = Exact<{ [key: string]: never }>;

export type FetchHealthPlansForHealthPlansPageQueryQuery = {
    __typename?: 'Query';
    healthPlans: Array<{
        __typename?: 'HealthPlan';
        id: any;
        name?: string | null;
        brandingName?: string | null;
        externalId?: string | null;
        healthPlanCode?: string | null;
        eligibilityType?: EligibilityType | null;
        state?: string | null;
        surveys: Array<string>;
    }>;
};

export type FetchProviderByIdForProviderModalQueryVariables = Exact<{
    input: GetProviderInput;
}>;

export type FetchProviderByIdForProviderModalQuery = {
    __typename?: 'Query';
    provider?: {
        __typename?: 'Provider';
        id: any;
        createdAt: any;
        updatedAt: any;
        externalId?: string | null;
        localizedName: string;
        name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    } | null;
};

export type CreateProviderFromProvidersPageMutationVariables = Exact<{
    input: ProviderInput;
}>;

export type CreateProviderFromProvidersPageMutation = {
    __typename?: 'Mutation';
    createProvider?: {
        __typename?: 'CreateProviderResponse';
        success: boolean;
        message: string;
        status: number;
        resourceCreated?: {
            __typename?: 'Provider';
            id: any;
            createdAt: any;
            updatedAt: any;
            externalId?: string | null;
            localizedName: string;
            name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        } | null;
    } | null;
};

export type UpdateProviderFromProvidersPageMutationVariables = Exact<{
    input: UpdateProviderInput;
}>;

export type UpdateProviderFromProvidersPageMutation = {
    __typename?: 'Mutation';
    updateProvider?: {
        __typename?: 'UpdateProviderResponse';
        success: boolean;
        message: string;
        status: number;
        resourceUpdated?: {
            __typename?: 'Provider';
            id: any;
            createdAt: any;
            updatedAt: any;
            externalId?: string | null;
            localizedName: string;
            name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        } | null;
    } | null;
};

export type FetchProvidersForProvidersPageQueryQueryVariables = Exact<{ [key: string]: never }>;

export type FetchProvidersForProvidersPageQueryQuery = {
    __typename?: 'Query';
    providers: Array<{
        __typename?: 'Provider';
        id: any;
        createdAt: any;
        updatedAt: any;
        externalId?: string | null;
        localizedName: string;
        name: { __typename?: 'LocalizedString'; en: string; es?: string | null };
    }>;
};

export type SlaTimersV2ForSlaTimersPageQueryVariables = Exact<{
    input?: InputMaybe<GetSlaTimersByFilterInput>;
}>;

export type SlaTimersV2ForSlaTimersPageQuery = {
    __typename?: 'Query';
    slaTimersV2?: {
        __typename?: 'SlaTimersResponse';
        total: number;
        skip?: number | null;
        orderBy?: string | null;
        limit?: number | null;
        hasPrevious?: boolean | null;
        hasMore?: boolean | null;
        results: Array<{
            __typename?: 'SlaTimer';
            id: any;
            createdAt: any;
            updatedAt: any;
            completeBy: any;
            completedAt?: any | null;
            isTestData?: boolean | null;
            taskId?: any | null;
            slaId: any;
            sla?: { __typename?: 'Sla'; id: any; name: string } | null;
            organization?: { __typename?: 'Organization'; id: any; name: string } | null;
            task?: { __typename?: 'AdvocateTask'; id: any; label: string } | null;
            completedByUser?: { __typename?: 'User'; id: any; name: string } | null;
        }>;
    } | null;
};

export type UpdateSurveyResponseForSurveyPageMutationVariables = Exact<{
    input: UpdateSurveyResponseInput;
}>;

export type UpdateSurveyResponseForSurveyPageMutation = {
    __typename?: 'Mutation';
    updateSurveyResponse?: {
        __typename?: 'UpdateSurveyResponseResponse';
        success: boolean;
        message: string;
        resourceUpdated?: {
            __typename?: 'SurveyResponse';
            surveyType?: string | null;
            clientSurveyId?: string | null;
            responseDate?: any | null;
            surveyScore?: number | null;
            displayName?: string | null;
            id: any;
            createdAt: any;
            updatedAt: any;
            reviewed?: boolean | null;
            isCoreSurvey?: boolean | null;
            answers: Array<{
                __typename?: 'SurveyResponseAnswer';
                subsectionName?: string | null;
                questionTypeId?: string | null;
                clientQuestionId?: string | null;
                questionId?: string | null;
                questionText?: string | null;
                answerId?: any | null;
                answerText?: any | null;
                responseScore?: number | null;
                questionOrder?: number | null;
            }>;
            practice?: {
                __typename?: 'Organization';
                id: any;
                brandingName: string;
                timezone?: string | null;
            } | null;
            patient?: {
                __typename?: 'Patient';
                id: any;
                externalId?: string | null;
                fullName: string;
                firstName: string;
                lastName: string;
                birthDate: any;
                dueDate?: any | null;
            } | null;
        } | null;
    } | null;
};

export type FetchSurveyResponsesForSurveyPageQueryQueryVariables = Exact<{
    input: UserSurveyResponsesInput;
}>;

export type FetchSurveyResponsesForSurveyPageQueryQuery = {
    __typename?: 'Query';
    currentUser?: {
        __typename?: 'User';
        surveyResponsesV2: {
            __typename?: 'UserSurveyResponsesResponse';
            orderBy?: string | null;
            skip?: number | null;
            limit?: number | null;
            total: number;
            hasMore?: boolean | null;
            hasPrevious?: boolean | null;
            results: Array<{
                __typename?: 'SurveyResponse';
                surveyType?: string | null;
                clientSurveyId?: string | null;
                responseDate?: any | null;
                surveyScore?: number | null;
                displayName?: string | null;
                id: any;
                createdAt: any;
                updatedAt: any;
                reviewed?: boolean | null;
                isCoreSurvey?: boolean | null;
                answers: Array<{
                    __typename?: 'SurveyResponseAnswer';
                    subsectionName?: string | null;
                    questionTypeId?: string | null;
                    clientQuestionId?: string | null;
                    questionId?: string | null;
                    questionText?: string | null;
                    answerId?: any | null;
                    answerText?: any | null;
                    responseScore?: number | null;
                    questionOrder?: number | null;
                }>;
                practice?: {
                    __typename?: 'Organization';
                    id: any;
                    brandingName: string;
                    timezone?: string | null;
                } | null;
                patient?: {
                    __typename?: 'Patient';
                    id: any;
                    externalId?: string | null;
                    fullName: string;
                    firstName: string;
                    lastName: string;
                    birthDate: any;
                    dueDate?: any | null;
                } | null;
            }>;
        };
    } | null;
};

export type FetchReferenceLinksForSurveysPageQueryVariables = Exact<{
    input: GetReferenceLinksForPageInput;
}>;

export type FetchReferenceLinksForSurveysPageQuery = {
    __typename?: 'Query';
    getReferenceLinksForPage?: Array<{
        __typename?: 'GetReferenceLinksForPageResponse';
        category?: string | null;
        data?: Array<{
            __typename?: 'LinkDataType';
            label?: string | null;
            url?: string | null;
        } | null> | null;
    } | null> | null;
};

export type FetchDeleteUserForFrontDeskPageMutationVariables = Exact<{
    input: DeleteUserDataInput;
}>;

export type FetchDeleteUserForFrontDeskPageMutation = {
    __typename?: 'Mutation';
    userManagementDeleteUser?: {
        __typename?: 'DeleteUserDataResponse';
        message: string;
        success: boolean;
    } | null;
};

export type FetchDownloadFamilyDataForFrontDeskPageQueryVariables = Exact<{
    input: DownloadFamilyDataInput;
}>;

export type FetchDownloadFamilyDataForFrontDeskPageQuery = {
    __typename?: 'Query';
    userManagementDownloadFamilyData?: {
        __typename?: 'DownloadFamilyDataResponse';
        message: string;
        success: boolean;
        familyData?: any | null;
    } | null;
};

export type FetchFindUserByEmailForFrontDeskPageQueryVariables = Exact<{
    input: FindUserByEmailDataInput;
}>;

export type FetchFindUserByEmailForFrontDeskPageQuery = {
    __typename?: 'Query';
    userManagementFindUserByEmail?: {
        __typename?: 'FindUserByEmailDataResponse';
        users?: string | null;
        success: boolean;
        message: string;
    } | null;
};

export type FetchImportFamilyDataForFrontDeskPageMutationVariables = Exact<{
    input: ImportFamilyDataInput;
}>;

export type FetchImportFamilyDataForFrontDeskPageMutation = {
    __typename?: 'Mutation';
    userManagementImportFamilyData?: {
        __typename?: 'ImportFamilyDataResponse';
        message: string;
        success: boolean;
    } | null;
};

export type FetchResetUserPasswordForFrontDeskPageMutationVariables = Exact<{
    input: ResetUserPasswordDataInput;
}>;

export type FetchResetUserPasswordForFrontDeskPageMutation = {
    __typename?: 'Mutation';
    userManagementResetUserPassword?: {
        __typename?: 'ResetUserPasswordDataResponse';
        message: string;
        success: boolean;
    } | null;
};

export type FetchOrgByInviteCodeForPatientInvitePageQueryVariables = Exact<{
    input: OrganizationByInviteCodeInput;
}>;

export type FetchOrgByInviteCodeForPatientInvitePageQuery = {
    __typename?: 'Query';
    orgByInviteCode?: {
        __typename?: 'Organization';
        id: any;
        brandingName: string;
        logo?: string | null;
        appPlatform?: string | null;
        appBundleId?: string | null;
    } | null;
};

export type CreatePatientForSelfSignUpPageMutationVariables = Exact<{
    input: CreatePatientForSelfSignUpInput;
}>;

export type CreatePatientForSelfSignUpPageMutation = {
    __typename?: 'Mutation';
    createPatientForSelfSignUp?: {
        __typename?: 'CreatePatientForSelfSignUpResponse';
        message: string;
        success: boolean;
    } | null;
};

export type ResendPatientInviteIfExistsForSelfSignUpPageMutationVariables = Exact<{
    input: ResendPatientInvitationByEmailIfExistsInput;
}>;

export type ResendPatientInviteIfExistsForSelfSignUpPageMutation = {
    __typename?: 'Mutation';
    resendPatientInvitationByEmailIfExists?: {
        __typename?: 'ResendPatientInvitationByEmailIfExistsResponse';
        message: string;
        success: boolean;
    } | null;
};

export type LoginUserForResetPasswordPageMutationVariables = Exact<{
    input: LoginUserInput;
}>;

export type LoginUserForResetPasswordPageMutation = {
    __typename?: 'Mutation';
    loginUser?: {
        __typename?: 'LoginUserResponse';
        success: boolean;
        message: string;
        newPasswordRequired?: boolean | null;
        tempPasswordToken?: string | null;
        tokens?: { __typename?: 'CognitoTokens'; idToken: string; accessToken: string } | null;
    } | null;
};

export type FetchCurrentUserResetPasswordQueryVariables = Exact<{ [key: string]: never }>;

export type FetchCurrentUserResetPasswordQuery = {
    __typename?: 'Query';
    currentUser?: { __typename?: 'User'; id: any; name: string } | null;
};

export type FetchSurveyQueryVariables = Exact<{
    surveyDefInput: GetSurveyDefInput;
}>;

export type FetchSurveyQuery = {
    __typename?: 'Query';
    surveyDef?: {
        __typename?: 'SurveyDef';
        id: any;
        name?: string | null;
        surveyType: string;
        statusProfileDefId: any;
        startedAtProfileDefId: any;
        completedAtProfileDefId: any;
        requiresAdvocateReview?: boolean | null;
        disableRestart?: boolean | null;
        restartFromBeginning?: boolean | null;
        shouldScore?: boolean | null;
        organizationId: any;
        icon?: ContentIcon | null;
        imageContentId?: any | null;
        createdAt: any;
        updatedAt: any;
        summary?: boolean | null;
        label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        imageContent?: { __typename?: 'SimpleContent'; id: any; name: string } | null;
        summaryText: {
            __typename?: 'SurveyDefText';
            title: { __typename?: 'LocalizedString'; es?: string | null; en: string };
            body: { __typename?: 'LocalizedString'; en: string; es?: string | null };
        };
        statusProfileDef: { __typename?: 'UserProfileDef'; id: any; name: string };
        startedAtProfileDef: { __typename?: 'UserProfileDef'; id: any; name: string };
        completedAtProfileDef: { __typename?: 'UserProfileDef'; id: any; name: string };
        organization: { __typename?: 'Organization'; id: any; brandingName: string };
        divisions: Array<{
            __typename?: 'SurveyDefDivision';
            id: string;
            conditionExpressionIds?: Array<any | null> | null;
            subSurveyDefId?: any | null;
            label: { __typename?: 'LocalizedString'; es?: string | null; en: string };
            sections: Array<{
                __typename?: 'SurveyDefSection';
                id: string;
                conditionExpressionIds?: Array<any | null> | null;
                label: { __typename?: 'LocalizedString'; es?: string | null; en: string };
                pieces: Array<{
                    __typename?: 'SurveyDefPiece';
                    id: string;
                    conditionExpressionIds?: Array<any | null> | null;
                    type: SurveyDefPieceType;
                    content?: {
                        __typename?: 'SurveyDefContent';
                        articleId?: any | null;
                        articleName?: string | null;
                    } | null;
                    text?: {
                        __typename?: 'SurveyDefText';
                        title: { __typename?: 'LocalizedString'; es?: string | null; en: string };
                        body: { __typename?: 'LocalizedString'; en: string; es?: string | null };
                    } | null;
                    question?: {
                        __typename?: 'SurveyDefQuestion';
                        displayType: SurveyDefQuestionDisplayType;
                        questionProfileDefId: any;
                        answerRequired?: boolean | null;
                        hidePreviousAnswer?: boolean | null;
                        labelOverride?: {
                            __typename?: 'LocalizedStringLoose';
                            en?: string | null;
                            es?: string | null;
                        } | null;
                        choiceOverrides?: Array<{
                            __typename?: 'SurveyDefChoiceOverride';
                            choiceId: any;
                            labelOverride?: {
                                __typename?: 'LocalizedString';
                                en: string;
                                es?: string | null;
                            } | null;
                        } | null> | null;
                    } | null;
                    surveyDefCompoundQuestion?: {
                        __typename?: 'SurveyDefCompoundQuestion';
                        compoundQuestionId: any;
                        displayType: SurveyDefQuestionDisplayType;
                        answerRequired?: boolean | null;
                        hidePreviousAnswer?: boolean | null;
                        label: { __typename?: 'LocalizedString'; en: string; es?: string | null };
                    } | null;
                    tutorialContent?: {
                        __typename?: 'SurveyDefTutorialContent';
                        videoId?: any | null;
                        videoName?: string | null;
                    } | null;
                }>;
            }>;
            subSurveyDef?: { __typename?: 'SurveyDef'; id: any; name?: string | null } | null;
        }>;
        buttons: {
            __typename?: 'SurveyDefButtons';
            saveSurvey: {
                __typename?: 'SurveyDefButton';
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            };
            end: {
                __typename?: 'SurveyDefButton';
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            };
            next: {
                __typename?: 'SurveyDefButton';
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            };
            previous: {
                __typename?: 'SurveyDefButton';
                label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            };
        };
    } | null;
};

export type FetchProfileDefsForSurveyTakerQueryVariables = Exact<{ [key: string]: never }>;

export type FetchProfileDefsForSurveyTakerQuery = {
    __typename?: 'Query';
    userProfileDefs: Array<{
        __typename?: 'UserProfileDef';
        id: any;
        name: string;
        description?: string | null;
        category: string;
        valueType: UserProfileValueType;
        choiceValueType?: UserProfileValueType | null;
        surveyQuestionDisabled?: boolean | null;
        systemGenerated?: boolean | null;
        conditionTypeId?: any | null;
        episodeRequired?: boolean | null;
        reportingNotes?: string | null;
        haPermission?: HaPermission | null;
        inboundNotes?: string | null;
        isClientSpecific?: boolean | null;
        linkToJIRA?: string | null;
        purposeAppPersonalization?: boolean | null;
        purposeHumanServices?: boolean | null;
        clientSpecificDisplayName?: string | null;
        dataSourceNotes?: string | null;
        purposeDetails?: string | null;
        dwLocation?: string | null;
        dwObject?: string | null;
        dwMetrics?: string | null;
        dwInternalReports?: string | null;
        dwExternalReports?: string | null;
        claimsField?: string | null;
        icd10DiagnosisCodes?: string | null;
        procedureCodes?: string | null;
        label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
        questionLabel?: {
            __typename?: 'LocalizedStringLoose';
            en?: string | null;
            es?: string | null;
        } | null;
        choices?: Array<{
            __typename?: 'UserProfileDefChoice';
            specifyValue: boolean;
            id: any;
            name?: string | null;
            answerLabel?: {
                __typename?: 'LocalizedStringLoose';
                en?: string | null;
                es?: string | null;
            } | null;
            label?: { __typename?: 'LocalizedString'; en: string; es?: string | null } | null;
            value?: {
                __typename?: 'UserProfileDefChoiceValue';
                bool?: boolean | null;
                date?: any | null;
                num?: number | null;
                str?: string | null;
            } | null;
        } | null> | null;
    }>;
};

export type FetchBatchTaskListQueryVariables = Exact<{ [key: string]: never }>;

export type FetchBatchTaskListQuery = {
    __typename?: 'Query';
    batchTasks: Array<{
        __typename?: 'BatchTask';
        id: any;
        type: BatchTaskType;
        status: BatchTaskStatus;
        addedBy: string;
        runAfter: any;
        startedAt?: any | null;
        completedAt?: any | null;
        priority: number;
        settings?: any | null;
        createdAt: any;
    }>;
};

export type DeleteBatchTaskMutationVariables = Exact<{
    input: DeleteBatchTaskInput;
}>;

export type DeleteBatchTaskMutation = {
    __typename?: 'Mutation';
    deleteBatchTask?: {
        __typename?: 'DeleteBatchTaskResponse';
        status: number;
        message: string;
        success: boolean;
        id?: string | null;
    } | null;
};

export type FetchAppsTagsForBatchTasksQueryVariables = Exact<{ [key: string]: never }>;

export type FetchAppsTagsForBatchTasksQuery = {
    __typename?: 'Query';
    amsApps: Array<{
        __typename?: 'AMSApp';
        id: string;
        bundleId: string;
        productConst?: AmsProduct | null;
    }>;
    tags: Array<{ __typename?: 'Tag'; id: any; name: string }>;
};

export type CreateTestAppUserForSystemAdminMutationMutationVariables = Exact<{
    createTestAppUserInput?: InputMaybe<CreateTestAppUserInput>;
}>;

export type CreateTestAppUserForSystemAdminMutationMutation = {
    __typename?: 'Mutation';
    createTestAppUserMutation?: {
        __typename?: 'ProxiedCreateTestUserMutationResponse';
        password?: string | null;
        patientId?: string | null;
    } | null;
};

export type GetPatientsForMassUpdateQueryVariables = Exact<{
    input: PatientQueryInput;
}>;

export type GetPatientsForMassUpdateQuery = {
    __typename?: 'Query';
    patientsV2: {
        __typename?: 'PatientPaginationQueryResponse';
        total: number;
        results: Array<{
            __typename?: 'Patient';
            id: any;
            firstName: string;
            lastName: string;
            practice: { __typename?: 'Organization'; name: string };
        }>;
    };
};

export type GetMassUpdateTasksQueryVariables = Exact<{
    massUpdatePatientsId: Scalars['String'];
    input?: InputMaybe<MassUpdateTaskListInput>;
}>;

export type GetMassUpdateTasksQuery = {
    __typename?: 'Query';
    massUpdateTasksV2: {
        __typename?: 'MassUpdateTaskListResponse';
        total: number;
        results: Array<{
            __typename?: 'MassUpdateTask';
            id: any;
            createdAt: any;
            updatedAt: any;
            submittedByName?: string | null;
            massUpdatePatientsId: any;
            startedAt?: any | null;
            finishedAt?: any | null;
            status: MassUpdateTaskStatus;
            processType: MassUpdateTaskProcess;
            patientIds?: Array<string> | null;
            errors?: Array<{
                __typename?: 'MassUpdateError';
                patientId: any;
                errorText: string;
            }> | null;
        }>;
    };
};

export type GetMassUpdateTaskQueryVariables = Exact<{
    input: GetMassUpdateTaskInput;
}>;

export type GetMassUpdateTaskQuery = {
    __typename?: 'Query';
    massUpdateTask?: {
        __typename?: 'MassUpdateTask';
        createdAt: any;
        updatedAt: any;
        id: any;
        massUpdatePatientsId: any;
        startedAt?: any | null;
        finishedAt?: any | null;
        status: MassUpdateTaskStatus;
        processType: MassUpdateTaskProcess;
        patientIds?: Array<string> | null;
        conditionTypeId?: any | null;
        tagIds?: Array<string> | null;
        sendEmail: boolean;
        postToSlack: boolean;
        errors?: Array<{
            __typename?: 'MassUpdateError';
            patientId: any;
            errorText: string;
        }> | null;
        profileValues: Array<{
            __typename?: 'MassUpdateTaskPV';
            profileVariableDefId: any;
            type: string;
            pvName: string;
            valueBool?: boolean | null;
            valueNum?: number | null;
            valueStr?: string | null;
            valueId?: any | null;
            valueIds?: Array<any> | null;
            valueDate?: any | null;
            clear?: boolean | null;
        }>;
    } | null;
};

export type CreateMassUpdateTaskMutationVariables = Exact<{
    input: MassUpdateTaskInput;
}>;

export type CreateMassUpdateTaskMutation = {
    __typename?: 'Mutation';
    createMassUpdateTask?: {
        __typename?: 'CreateMassUpdateTaskResponse';
        success: boolean;
        message: string;
        resourceCreated?: { __typename?: 'MassUpdateTask'; id: any } | null;
    } | null;
};

export type UpdateMassUpdateTaskMutationVariables = Exact<{
    input: UpdateMassUpdateTaskInput;
}>;

export type UpdateMassUpdateTaskMutation = {
    __typename?: 'Mutation';
    updateMassUpdateTask?: {
        __typename?: 'UpdateMassUpdateTaskResponse';
        success: boolean;
        message: string;
        resourceUpdated?: { __typename?: 'MassUpdateTask'; id: any } | null;
    } | null;
};

export type DeleteMassUpdateTaskMutationVariables = Exact<{
    input: DeleteMassUpdateTaskInput;
}>;

export type DeleteMassUpdateTaskMutation = {
    __typename?: 'Mutation';
    deleteMassUpdateTask?: {
        __typename?: 'DeleteMassUpdateTaskResponse';
        success: boolean;
        message: string;
        status: number;
        id?: string | null;
    } | null;
};

export type SubmitMassUpdateTaskMutationVariables = Exact<{
    taskId: Scalars['String'];
}>;

export type SubmitMassUpdateTaskMutation = {
    __typename?: 'Mutation';
    submitMassUpdateTask?: {
        __typename?: 'SubmitTaskMutationResponse';
        success: boolean;
        message: string;
    } | null;
};

export type GetMassUpdatePatientListsQueryVariables = Exact<{
    input?: InputMaybe<MassUpdatePatientQueryInput>;
}>;

export type GetMassUpdatePatientListsQuery = {
    __typename?: 'Query';
    massUpdatePatientsV2: {
        __typename?: 'MassUpdatePatientPaginationQueryResponse';
        total: number;
        results: Array<{
            __typename?: 'MassUpdatePatients';
            createdAt: any;
            updatedAt: any;
            id: any;
            name: string;
            patients?: Array<any> | null;
        }>;
    };
};

export type GetMassUpdatePatientListQueryVariables = Exact<{
    input: GetMassUpdatePatientInput;
}>;

export type GetMassUpdatePatientListQuery = {
    __typename?: 'Query';
    massUpdatePatient?: {
        __typename?: 'MassUpdatePatients';
        createdAt: any;
        updatedAt: any;
        id: any;
        name: string;
        patients?: Array<any> | null;
    } | null;
};

export type CreateMassUpdatePatientListMutationVariables = Exact<{
    input: MassUpdatePatientsInput;
}>;

export type CreateMassUpdatePatientListMutation = {
    __typename?: 'Mutation';
    createMassUpdatePatient?: {
        __typename?: 'CreateMassUpdatePatientResponse';
        success: boolean;
        message: string;
        resourceCreated?: { __typename?: 'MassUpdatePatients'; id: any } | null;
    } | null;
};

export type UpdateMassUpdatePatientListMutationVariables = Exact<{
    input: UpdateMassUpdatePatientInput;
}>;

export type UpdateMassUpdatePatientListMutation = {
    __typename?: 'Mutation';
    updateMassUpdatePatient?: {
        __typename?: 'UpdateMassUpdatePatientResponse';
        success: boolean;
        message: string;
        resourceUpdated?: { __typename?: 'MassUpdatePatients'; id: any } | null;
    } | null;
};

export type DeleteMassUpdatePatientListMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteMassUpdatePatientListMutation = {
    __typename?: 'Mutation';
    deleteMassUpdatePatientList?: {
        __typename?: 'DeleteMassUpdatePatientListResponse';
        success: boolean;
        message: string;
        status: number;
        id: string;
    } | null;
};

export type GetMassUpdateTaskSelectorsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMassUpdateTaskSelectorsQuery = {
    __typename?: 'Query';
    tags: Array<{ __typename?: 'Tag'; id: any; name: string }>;
    conditionTypes: Array<{
        __typename?: 'ConditionType';
        id: any;
        name: { __typename?: 'LocalizedString'; en: string };
    }>;
};

export type FetchPatientForAddPatientToListQueryVariables = Exact<{
    input: GetPatientInput;
}>;

export type FetchPatientForAddPatientToListQuery = {
    __typename?: 'Query';
    patient?: { __typename?: 'Patient'; id: any } | null;
};

export type AddToPatientListMutationVariables = Exact<{
    input: AddToPatientListInput;
}>;

export type AddToPatientListMutation = {
    __typename?: 'Mutation';
    addToPatientList?: {
        __typename?: 'AddToPatientListResponse';
        success: boolean;
        message: string;
        status: number;
        id: string;
    } | null;
};

export type FetchPatientByIdQueryVariables = Exact<{
    input: GetPatientInput;
}>;

export type FetchPatientByIdQuery = {
    __typename?: 'Query';
    patient?: {
        __typename?: 'Patient';
        id: any;
        appUserId?: any | null;
        appBundleId?: string | null;
        email?: string | null;
        practice: { __typename?: 'Organization'; id: any };
    } | null;
};

export type UnsubscribePatientEmailMutationVariables = Exact<{
    id: Scalars['String'];
    setting: Scalars['Boolean'];
}>;

export type UnsubscribePatientEmailMutation = {
    __typename?: 'Mutation';
    unsubscribePatientEmail?: { __typename?: 'UnsubscribePatientResponse'; status: number } | null;
};

export type UnsubscribePatientTextMutationVariables = Exact<{
    id: Scalars['String'];
    setting: Scalars['Boolean'];
}>;

export type UnsubscribePatientTextMutation = {
    __typename?: 'Mutation';
    unsubscribePatientText?: { __typename?: 'UnsubscribePatientResponse'; status: number } | null;
};

export type CreateOrUpdateVirtualCareFormMutationVariables = Exact<{
    input?: InputMaybe<VirtualCareFormInput>;
}>;

export type CreateOrUpdateVirtualCareFormMutation = {
    __typename?: 'Mutation';
    createOrUpdateVirtualCareForm?: {
        __typename?: 'CreateOrUpdateVirtualCareFormResponse';
        message: string;
        status: number;
        success: boolean;
        redirectUrl?: string | null;
        successMessage?: string | null;
        data?: {
            __typename?: 'VirtualCareForm';
            patientId?: any | null;
            firstName?: string | null;
            lastName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            Destination?: string | null;
            birthDate?: any | null;
            appointmentMethod?: AppointmentMethod | null;
        } | null;
    } | null;
};

export type SettingsForVirtualCareFormQueryVariables = Exact<{ [key: string]: never }>;

export type SettingsForVirtualCareFormQuery = {
    __typename?: 'Query';
    settings: { __typename?: 'UiSettings'; env?: string | null };
};

export type CheckEligibilityVirtualCareFormMutationVariables = Exact<{
    input?: InputMaybe<CheckEligibilityFormInput>;
}>;

export type CheckEligibilityVirtualCareFormMutation = {
    __typename?: 'Mutation';
    checkVCFEligibility?: {
        __typename?: 'CheckEligibilityFormResponse';
        isEligible: boolean;
        isMocked: boolean;
        message: string;
        status: number;
        success: boolean;
        resultId?: string | null;
    } | null;
};

export type VcfSettingsVirtualCareFormQueryVariables = Exact<{ [key: string]: never }>;

export type VcfSettingsVirtualCareFormQuery = {
    __typename?: 'Query';
    VCFSettings?: {
        __typename?: 'VCFSettings';
        showOnlyAtHomeZipCodes?: Array<string | null> | null;
    } | null;
};

export type FetchVirtualCareAffiliateByAffiliateIdQueryVariables = Exact<{
    input: GetVirtualCareAffiliateByAffiliateIdInput;
}>;

export type FetchVirtualCareAffiliateByAffiliateIdQuery = {
    __typename?: 'Query';
    virtualCareAffiliateByAffiliateId?: {
        __typename?: 'VirtualCareAffiliateByAffiliateIdResponse';
        data?: {
            __typename?: 'VirtualCareAffiliatesEntity';
            externalName: string;
            customSuccessText?: string | null;
            supportMethodType?: SupportMethodType | null;
            supportMethodValue?: string | null;
        } | null;
    } | null;
};

export const AdvocateTaskFragmentFragmentDoc = gql`
    fragment AdvocateTaskFragment on AdvocateTask {
        id
        label
        description
        createdBy
        assignedTo
        watchedBy
        watchers {
            id
            name
        }
        status
        priority
        dueDate
        displayDate
        createdAt
        updatedAt
        completedAt
        patientId
        slaTimerId
        patient {
            id
            fullName
            birthDate
            phoneNumber
            practice {
                id
                brandingName
            }
            chatRoom {
                id
                participants {
                    id
                }
            }
            patientHeaderData {
                label
                value
            }
        }
        typeId
        checklist {
            id
            label
            description
            completedAt
            completedBy
            actionTypeIds
            endSlaTimerOnComplete
        }
        comments {
            authorId
            message
            when
        }
        commentAuthors {
            id
            name
        }
        taskCreator {
            name
        }
        types {
            id
            label
        }
        usersToAssign {
            id
            name
        }
        history {
            id
            whoId
            what
            when
        }
        historyFigures {
            id
            name
        }
        actionTypes {
            id
            label
        }
        patientActions {
            stepId
            updatedAt
            description
            patientId
            taskId
            typeId
            id
            source
            completedAt
        }
    }
`;
export const AdvocateTaskTemplateFragmentFragmentDoc = gql`
    fragment AdvocateTaskTemplateFragment on AdvocateTaskTemplate {
        label
        description
        priority
        createdAt
        updatedAt
        id
        checklist {
            id
            label
            description
            completedAt
            completedBy
            actionTypeIds
            endSlaTimerOnComplete
        }
        whenType
        whenTimeline {
            timelineId
            offsetDays
            offsetMonths
            offsetYears
            durationMinutes
            includeApps
            excludeApps
            tags
        }
        typeId
        interventionDurationMinutes
        shouldNotify
        slaId
    }
`;
export const AdvocateTaskTypeFragmentFragmentDoc = gql`
    fragment AdvocateTaskTypeFragment on AdvocateTaskType {
        label
        createdAt
        updatedAt
        id
    }
`;
export const AppointmentFragmentOnApptPageFragmentDoc = gql`
    fragment AppointmentFragmentOnApptPage on Appointment {
        id
        externalId
        startDate
        durationMinutes
        staffName
        physicalLocation
        description
        isVirtualVisit
        virtualLink
        visitType
        source
        note
        reportKey
        timezoneName
        patient {
            id
            fullName
            firstName
            lastName
            birthDate
        }
        practice {
            id
            name
            timezone
        }
        staff {
            id
            name
        }
    }
`;
export const ArticleFragmentFragmentDoc = gql`
    fragment ArticleFragment on Article {
        id
        name
        description
        keywords
        references
        articleCategoryId
        relatedArticleIds
        icon
        stackId
        imageContentId
        groups
        reportingCategorys
        changesToPublish
        imageContent {
            id
            name
        }
        articleCategory {
            id
            name
        }
        updatedAt
        callToActions {
            id
            type
            buttonText {
                en
                es
            }
            parameter1 {
                en
                es
            }
            parameter2 {
                en
                es
            }
            simpleContentId
            simpleContent {
                name
            }
            tagList
            defaultButtonText
        }
        variants {
            language
            readingLevel
            appBundleId
            imageContentId
            datePublished
            dateEdited
            editedBy
            callToActions {
                id
                type
                buttonText {
                    en
                    es
                }
                parameter1 {
                    en
                    es
                }
                parameter2 {
                    en
                    es
                }
                simpleContentId
                simpleContent {
                    name
                }
                tagList
                defaultButtonText
            }
            titleMarkup
            summaryMarkup
            bodyMarkup
        }
    }
`;
export const ArticleGroupFragFragmentDoc = gql`
    fragment ArticleGroupFrag on ArticleGroup {
        id
        name
        includeApps
        excludeApps
        createdAt
        updatedAt
    }
`;
export const ArticlePromotionFragmentFragmentDoc = gql`
    fragment ArticlePromotionFragment on ArticlePromotion {
        name
        title
        titleSp
        articleId
        priority
        tagIds
        type
        typeName
        startDate
        endDate
        createdAt
        updatedAt
        isTestData
        id
        timelineId
        timeline {
            id
            label {
                en
            }
            type
            fixedTargetDate
            targetDateProfileDefId
            targetDateProfileDef {
                id
                label {
                    en
                }
            }
        }
        offsetDays
        offsetMonths
        offsetYears
        duration
        durationTimeUnit
        includeApps
        excludeApps
        article {
            id
            name
        }
    }
`;
export const BioScreenForBioScreenBuilderFragmentDoc = gql`
    fragment BioScreenForBioScreenBuilder on BioScreen {
        id
        createdAt
        updatedAt
        label {
            en
            es
        }
        yourHealthInfo {
            icon
            label {
                en
                es
            }
            instructions {
                en
                es
            }
        }
        surveyGroups {
            id
            tags
            label {
                en
                es
            }
            surveys {
                label {
                    en
                    es
                }
                surveyId
                tags
            }
        }
        shortcuts {
            type
            icon
            label {
                en
                es
            }
            instructions {
                en
                es
            }
            surveyId
            surveyGroupId
            tags
        }
    }
`;
export const CarePlanFragmentFragmentDoc = gql`
    fragment CarePlanFragment on CarePlan {
        type
        baseCarePlanId
        timelineId
        includeApps
        excludeApps
        tags
        viewLimitMinutes
        portalLabel {
            en
            es
        }
        appLabel {
            es
            en
        }
        createdAt
        updatedAt
        groups {
            id
            label {
                es
                en
            }
            description {
                en
                es
            }
            suppressedTemplates
            templates {
                articleId
                id
                title {
                    es
                    en
                }
                description {
                    es
                    en
                }
                priority
                tags
                type
                offsetDays
                offsetMonths
                offsetYears
                targetDate
                durationMinutes
                isRepeating
                repeatIntervalMinutes
                repeatCount
                isEssential
                notificationData {
                    shouldSendNotification
                    en
                    es
                }
            }
        }
        id
        timeline {
            label {
                en
                es
            }
            id
        }
    }
`;
export const CareTeamMemberFragmentFragmentDoc = gql`
    fragment CareTeamMemberFragment on CareTeamMember {
        id
        careTeamMemberTypeId
        nameWithType
        careTeamMemberType {
            name
            profileDefId
            createdAt
            updatedAt
            id
        }
        careTeamMemberContactId
        careTeamMemberTIN
        careTeamMemberNPI
        practiceExternalId
        practiceCode
        overridenCareTeamMemberContact {
            careTeamMemberTypeId
            name
            address {
                street1
                street2
                city
                state
                code
            }
            phoneNumber
            emailAddress
            url
        }
        prerequisiteTeamMembers
        eligibilityCheck
        eligibilityRecheckDays
        consentRequired
        consentAgreement
        appBundleId
        usState
        zipCode
        label {
            en
            es
        }
        updatedAt
        createdAt
        isActive
        userId
        appDefault
    }
`;
export const ChatMessageForMessageCenterFragmentDoc = gql`
    fragment ChatMessageForMessageCenter on ChatMessage {
        id
        createdAt
        updatedAt
        read
        text
        attachments
        senderId
        chatRoomId
        chatConversationId
    }
`;
export const CompoundQuestionFragmentFragmentDoc = gql`
    fragment CompoundQuestionFragment on CompoundQuestion {
        id
        label {
            en
            es
        }
        profileValueType
        questionProfileDefs {
            questionProfileDefId
            questionProfileDef {
                id
                name
                label {
                    en
                    es
                }
            }
            labelOverride {
                en
                es
            }
            value {
                bool
            }
        }
    }
`;
export const ConfigExpressionFragmentDoc = gql`
    fragment ConfigExpression on ConfigExpression {
        id
        createdAt
        updatedAt
        name
        description
        rules
        profileDefIds
        appConfigIds
        useType
    }
`;
export const ConfigValueDefPageFragmentDoc = gql`
    fragment ConfigValueDefPage on ConfigValueDef {
        id
        createdAt
        updatedAt
        name
        description
        valueType
        category
        validationPattern
        potentialValues {
            str
            strArr
            bool
            boolArr
            num
            numArr
            localizedStr {
                en
                es
            }
            localizedStrArr {
                en
                es
            }
        }
        defaultValue {
            str
            strArr
            bool
            boolArr
            num
            numArr
            localizedStr {
                en
                es
            }
            localizedStrArr {
                en
                es
            }
        }
    }
`;
export const ConfigValuePageFragmentDoc = gql`
    fragment ConfigValuePage on ConfigValue {
        id
        createdAt
        updatedAt
        name
        valueDef {
            id
            name
        }
        value {
            str
            strArr
            bool
            boolArr
            num
            numArr
            localizedStr {
                en
            }
            localizedStrArr {
                en
            }
        }
        overrides {
            value {
                str
                strArr
                bool
                boolArr
                num
                numArr
                localizedStr {
                    en
                }
                localizedStrArr {
                    en
                }
            }
            expression {
                id
                createdAt
                updatedAt
                name
                description
            }
        }
    }
`;
export const ContentDirectoryFragFragmentDoc = gql`
    fragment ContentDirectoryFrag on ContentDirectory {
        id
        name
        description
        keywords
        icon
        imageContentId
        displayStyle
        reportingCategorys
        imageContent {
            name
        }
        label {
            en
            es
        }
        appDescription {
            en
            es
        }
        taggedImages {
            imageContentId
            imageName
            tagId
            tagName
        }
        items {
            id
            type
            simpleContentId
            articleId
            surveyId
            subDirectoryId
            tagList
            excludeApps
            includeApps
            article {
                name
            }
            survey {
                name
            }
            simpleContent {
                name
            }
            subdirectory {
                name
            }
        }
    }
`;
export const DeviceForDevicesPageFragmentDoc = gql`
    fragment DeviceForDevicesPage on Device {
        id
        createdAt
        updatedAt
        externalId
        photo
        docLink {
            en
            es
        }
        name {
            en
            es
        }
        localizedName
        localizedDocLink
    }
`;
export const HealthPlanFragmentDoc = gql`
    fragment HealthPlan on HealthPlan {
        id
        name
        brandingName
        externalId
        healthPlanCode
        eligibilityType
        state
        surveys
    }
`;
export const TagForTagBuilderFragmentDoc = gql`
    fragment TagForTagBuilder on Tag {
        id
        name
        expressionId
        changedProfileDefIds
        expression {
            id
            name
        }
        includeApps
        excludeApps
        createdAt
        updatedAt
        appInfo
        usageType
        isPatientFilter
        patientRiskScorePoints
    }
`;
export const UserProfileDefFragmentFragmentDoc = gql`
    fragment UserProfileDefFragment on UserProfileDef {
        id
        name
        description
        category
        valueType
        choiceValueType
        surveyQuestionDisabled
        systemGenerated
        conditionTypeId
        episodeRequired
        reportingNotes
        haPermission
        inboundNotes
        isClientSpecific
        linkToJIRA
        purposeAppPersonalization
        purposeHumanServices
        clientSpecificDisplayName
        dataSourceNotes
        purposeDetails
        dwLocation
        dwObject
        dwMetrics
        dwInternalReports
        dwExternalReports
        claimsField
        icd10DiagnosisCodes
        procedureCodes
        label {
            en
            es
        }
        questionLabel {
            en
            es
        }
        choices {
            specifyValue
            answerLabel {
                en
                es
            }
            id
            label {
                en
                es
            }
            value {
                bool
                date
                num
                str
            }
            name
        }
    }
`;
export const InterventionFragmentFragmentDoc = gql`
    fragment InterventionFragment on Intervention {
        tagId
        type
        conditionTypeId
        profileDefId
        messageCenterTemplateId
        choicesToUpdate
        profileValue {
            choiceId
            str
            num
            bool
            date
            choices
        }
        createdAt
        updatedAt
        id
        includeApps
        excludeApps
        tag {
            ...TagForTagBuilder
        }
        conditionTypes {
            name {
                en
                es
            }
            createdAt
            updatedAt
            id
        }
        profileDef {
            ...UserProfileDefFragment
        }
        conditionType {
            name {
                en
                es
            }
            createdAt
            updatedAt
            id
        }
        dateType
        advocateTaskTemplateId
        advocateTaskTemplate {
            label
        }
        priority
        messageTemplateId
        messageTemplate {
            name
        }
    }
    ${TagForTagBuilderFragmentDoc}
    ${UserProfileDefFragmentFragmentDoc}
`;
export const MeasurementForMeasurementsPageFragmentDoc = gql`
    fragment MeasurementForMeasurementsPage on Measurement {
        id
        flagged
        externalId
        updatedAt
        createdAt
        value {
            movement {
                duration
            }
            weight {
                measure
                units
            }
            bloodPressure {
                pulse
                diastolic
                systolic
            }
            dailyMeasurement {
                glucose
                moodLevel
                painLevel
                steps
                temperature
                sleepStarts
                sleepEnds
                notes
            }
            bloodGlucose {
                dateTime
                activityOption
                glucose
                meal
                mealOption
                mealTime
                movement
                notes
            }
        }
        type
        takenDate
        timezoneName
        reviewed
        inputType
        comment
        patient {
            id
            externalId
            firstName
            lastName
            fullName
            birthDate
            practice {
                timezone
            }
            tagIds
            assignedHealthAdvocate
        }
        device {
            id
            localizedName
        }
        hasHistory
    }
`;
export const OrgForOrgPageFragmentDoc = gql`
    fragment OrgForOrgPage on Organization {
        id
        createdAt
        updatedAt
        name
        parentId
        brandingName
        healthAdvocateTitle
        externalId
        allowPatientCreation
        logo
        email
        timezone
        conferencingAppName
        appBundleId
        appPlatform
        eligibilityType
        inviteCodes
        configOptions {
            riskScoreEnabled
        }
    }
`;
export const OrgConfigForOrgFragmentDoc = gql`
    fragment OrgConfigForOrg on Organization {
        id
        createdAt
        updatedAt
        name
        brandingName
        healthAdvocateTitle
        config {
            resources {
                downloadConfApp
                prepareForVirtualVisit
                scheduleNextVisit
                devices
            }
        }
    }
`;
export const OrgListFragmentDoc = gql`
    fragment OrgList on Organization {
        id
        parentId
        brandingName
        ancestors
        appBundleId
        logo
    }
`;
export const PatientActionTypeFragmentFragmentDoc = gql`
    fragment PatientActionTypeFragment on PatientActionType {
        label
        createdAt
        updatedAt
        id
    }
`;
export const PatientForPatientsPageFragmentDoc = gql`
    fragment PatientForPatientsPage on Patient {
        id
        createdAt
        externalId
        memberId
        fullName
        firstName
        lastName
        onboardingDate
        email
        birthDate
        phoneNumber
        invitationMode
        invitationCode
        signUpPracticeCode
        isTestData
        assignedHealthAdvocate
        openAdvocateTaskCount
        openCriticalAdvocateTaskCount
        onboardingCompleteDate
        isPregnant
        dueDate
        deliveryDate
        patientCallStatus
        appUserId
        appBundleId
        attachments {
            uuid
            label
        }
        riskIndicators {
            obesity {
                flag
                comment
            }
            preterm {
                flag
                comment
            }
            chronicHypertension {
                flag
                comment
            }
            other {
                flag
                comment
            }
        }
        mailingAddress {
            street1
            street2
            city
            state
            code
        }
        practice {
            id
            name
            timezone
            providers {
                provider {
                    id
                    localizedName
                    createdAt
                }
            }
        }
        questions {
            id
            createdAt
            updatedAt
            submittedAt
            text
            isDone
        }
        notes {
            id
            text
            title
            staffName
            createdAt
        }
        chatRoom {
            id
            numberOfUnreadMessages
        }
        profileValues {
            profileVariableDefId
            type
            valueBool
            valueNum
            valueStr
            valueId
            valueIds
            valueDate
            timeZone
            utcOffset
        }
        riskScore {
            value
            tierName
        }
    }
`;
export const ProviderForProvidersPageFragmentDoc = gql`
    fragment ProviderForProvidersPage on Provider {
        id
        createdAt
        updatedAt
        externalId
        name {
            en
            es
        }
        localizedName
    }
`;
export const RoleForRolePageFragmentDoc = gql`
    fragment RoleForRolePage on Role {
        id
        name
        organizationId
        createdAt
        updatedAt
        permissions {
            target
            permission
            canExport
        }
    }
`;
export const SurveyDefForSurveyBuilderFragmentDoc = gql`
    fragment SurveyDefForSurveyBuilder on SurveyDef {
        id
        name
        label {
            en
            es
        }
        surveyType
        statusProfileDefId
        startedAtProfileDefId
        completedAtProfileDefId
        requiresAdvocateReview
        disableRestart
        restartFromBeginning
        shouldScore
        organizationId
        icon
        imageContentId
        imageContent {
            id
            name
        }
        createdAt
        updatedAt
        summary
        summaryText {
            title {
                es
                en
            }
            body {
                en
                es
            }
        }
        statusProfileDef {
            id
            name
        }
        startedAtProfileDef {
            id
            name
        }
        completedAtProfileDef {
            id
            name
        }
        organization {
            id
            brandingName
        }
        divisions {
            id
            conditionExpressionIds
            label {
                es
                en
            }
            sections {
                id
                conditionExpressionIds
                label {
                    es
                    en
                }
                pieces {
                    id
                    conditionExpressionIds
                    type
                    content {
                        articleId
                        articleName
                    }
                    text {
                        title {
                            es
                            en
                        }
                        body {
                            en
                            es
                        }
                    }
                    question {
                        displayType
                        labelOverride {
                            en
                            es
                        }
                        choiceOverrides {
                            choiceId
                            labelOverride {
                                en
                                es
                            }
                        }
                        questionProfileDefId
                        answerRequired
                        hidePreviousAnswer
                    }
                    surveyDefCompoundQuestion {
                        label {
                            en
                            es
                        }
                        compoundQuestionId
                        displayType
                        answerRequired
                        hidePreviousAnswer
                    }
                    tutorialContent {
                        videoId
                        videoName
                    }
                }
            }
            subSurveyDefId
            subSurveyDef {
                id
                name
            }
        }
        buttons {
            saveSurvey {
                label {
                    en
                    es
                }
            }
            end {
                label {
                    en
                    es
                }
            }
            next {
                label {
                    en
                    es
                }
            }
            previous {
                label {
                    en
                    es
                }
            }
        }
    }
`;
export const SurveyResponseForSurveyPageFragmentDoc = gql`
    fragment SurveyResponseForSurveyPage on SurveyResponse {
        surveyType
        clientSurveyId
        responseDate
        surveyScore
        displayName
        answers {
            subsectionName
            questionTypeId
            clientQuestionId
            questionId
            questionText
            answerId
            answerText
            responseScore
            questionOrder
        }
        id
        createdAt
        updatedAt
        practice {
            id
            brandingName
            timezone
        }
        patient {
            id
            externalId
            fullName
            firstName
            lastName
            birthDate
            dueDate
        }
        reviewed
        isCoreSurvey
    }
`;
export const SurveyResponseForPatientSurveyPageFragmentDoc = gql`
    fragment SurveyResponseForPatientSurveyPage on SurveyResponse {
        surveyType
        clientSurveyId
        responseDate
        surveyScore
        displayName
        answers {
            subsectionName
            questionTypeId
            clientQuestionId
            questionId
            questionText
            answerId
            answerText
            responseScore
            questionOrder
        }
        id
        createdAt
        updatedAt
        practice {
            id
            brandingName
            timezone
        }
        isCoreSurvey
    }
`;
export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on User {
        id
        name
        email
        title
        smsNumber
        clientOrgId
        numberOfUnreadChatMessages
        newTaskNotificationCount
        isTestData
        currentOrg {
            id
            parentId
            brandingName
            timezone
            appPlatform
            appBundleId
            unreadMessagesCount
        }
        rootOrg {
            id
            parentId
            brandingName
        }
        organizations {
            id
            parentId
            brandingName
            logo
            ancestors
            appBundleId
            unreadMessagesCount
        }
        currentOrganizations {
            id
            parentId
            brandingName
            logo
        }
        roleId
        role {
            id
            name
            permissions {
                target
                permission
                canExport
            }
        }
    }
`;
export const UserStaffOnStaffPageFragmentDoc = gql`
    fragment UserStaffOnStaffPage on User {
        id
        name
        email
        photo
        clientOrgId
        rootOrg {
            id
            name
            brandingName
        }
        smsNumber
        title
        isStaff
        isTestData
        roleId
        role {
            id
            name
            permissions {
                target
                permission
                canExport
            }
        }
        lastLogin
        isActive
    }
`;
export const WhatsNewTimelineForWhatsNewTimelineBuilderFragmentDoc = gql`
    fragment WhatsNewTimelineForWhatsNewTimelineBuilder on WhatsNewTimeline {
        id
        label {
            en
            es
        }
        description {
            en
            es
        }
        type
        fixedTargetDate
        targetDateProfileDefId
        targetDateProfileDef {
            id
            label {
                en
            }
        }
    }
`;
export const ArticleApprovalFragmentFragmentDoc = gql`
    fragment ArticleApprovalFragment on ArticleApproval {
        id
        appBundleId
        articleId
        language
        readingLevel
        status
        approvedBy
        dateApproved
        articleName
    }
`;
export const HighlightFragmentFragmentDoc = gql`
    fragment HighlightFragment on Highlight {
        id
        name
        type
        clockType
        startDate
        endDate
        timelineId
        offsetDays
        offsetMonths
        offsetYears
        duration
        durationTimeUnit
        priority
        includeApps
        excludeApps
        tags
        titleMarkup {
            en
            es
        }
        headerMarkup {
            en
            es
        }
        bodyMarkup {
            en
            es
        }
        icon
        imageContentId
        surveyId
        simpleContentId
        linkText {
            en
            es
        }
        closeable
        highlighted
        offsetSequence
        shouldSendNotification
        notificationText {
            en
            es
        }
        timeline {
            id
            label {
                en
            }
        }
        clockTypeName
        highlightTypeName
        displayAsModal
        displayAsModalButtonText {
            en
            es
        }
    }
`;
export const MessageCenterTemplateFragmentFragmentDoc = gql`
    fragment MessageCenterTemplateFragment on MessageCenterTemplate {
        id
        title
        content {
            en
            es
        }
    }
`;
export const SimpleContentFragFragmentDoc = gql`
    fragment SimpleContentFrag on SimpleContent {
        id
        name
        content
        contentType
        contentTypeName
        icon
        trackerType
        articleId
        surveyId
        contentDirectoryId
        label {
            en
            es
        }
        description {
            en
            es
        }
    }
`;
export const PatientHighlightFragmentFragmentDoc = gql`
    fragment PatientHighlightFragment on PatientHighlightsItem {
        id
        type
        priority
        highlightId
        highlightName
        title
        header
        body
        target
        imageURL
        icon
        surveyId
        highlighted
        closeable
        availableFrom
        availableUntil
        closed
    }
`;
export const PatientProfileVariablesFragmentFragmentDoc = gql`
    fragment PatientProfileVariablesFragment on PatientProfileVariable {
        profileVariableDefId
        name
        valueType
        categoryName
        conditionId
        conditionName
        episodeStartDate
        description
        systemGenerated
        haPermission
        value {
            date
            str
            bool
            num
            strs
            nums
            dates
            bools
            choices
            choice
            localized {
                en
                es
            }
            specified {
                choiceId
                type
                val {
                    str
                    num
                    bool
                    date
                }
            }
        }
        stringifiedValue
        originId
        notes
        sourceType
    }
`;
export const ArticleByIdForArticlePickerDocument = gql`
    query ArticleByIdForArticlePicker($input: GetArticleInput!) {
        article(input: $input) {
            name
            id
        }
    }
`;

/**
 * __useArticleByIdForArticlePickerQuery__
 *
 * To run a query within a React component, call `useArticleByIdForArticlePickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleByIdForArticlePickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleByIdForArticlePickerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticleByIdForArticlePickerQuery(
    baseOptions: Apollo.QueryHookOptions<
        ArticleByIdForArticlePickerQuery,
        ArticleByIdForArticlePickerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        ArticleByIdForArticlePickerQuery,
        ArticleByIdForArticlePickerQueryVariables
    >(ArticleByIdForArticlePickerDocument, options);
}
export function useArticleByIdForArticlePickerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ArticleByIdForArticlePickerQuery,
        ArticleByIdForArticlePickerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ArticleByIdForArticlePickerQuery,
        ArticleByIdForArticlePickerQueryVariables
    >(ArticleByIdForArticlePickerDocument, options);
}
export type ArticleByIdForArticlePickerQueryHookResult = ReturnType<
    typeof useArticleByIdForArticlePickerQuery
>;
export type ArticleByIdForArticlePickerLazyQueryHookResult = ReturnType<
    typeof useArticleByIdForArticlePickerLazyQuery
>;
export type ArticleByIdForArticlePickerQueryResult = Apollo.QueryResult<
    ArticleByIdForArticlePickerQuery,
    ArticleByIdForArticlePickerQueryVariables
>;
export const ArticlesByCriteriaDocument = gql`
    query ArticlesByCriteria($input: ArticlePickerFetchInput!) {
        articlePickerFetch(input: $input) {
            data {
                name
                id
            }
            total
        }
    }
`;

/**
 * __useArticlesByCriteriaQuery__
 *
 * To run a query within a React component, call `useArticlesByCriteriaQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesByCriteriaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesByCriteriaQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticlesByCriteriaQuery(
    baseOptions: Apollo.QueryHookOptions<ArticlesByCriteriaQuery, ArticlesByCriteriaQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ArticlesByCriteriaQuery, ArticlesByCriteriaQueryVariables>(
        ArticlesByCriteriaDocument,
        options,
    );
}
export function useArticlesByCriteriaLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ArticlesByCriteriaQuery,
        ArticlesByCriteriaQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ArticlesByCriteriaQuery, ArticlesByCriteriaQueryVariables>(
        ArticlesByCriteriaDocument,
        options,
    );
}
export type ArticlesByCriteriaQueryHookResult = ReturnType<typeof useArticlesByCriteriaQuery>;
export type ArticlesByCriteriaLazyQueryHookResult = ReturnType<
    typeof useArticlesByCriteriaLazyQuery
>;
export type ArticlesByCriteriaQueryResult = Apollo.QueryResult<
    ArticlesByCriteriaQuery,
    ArticlesByCriteriaQueryVariables
>;
export const ArticleCategoriesForArticlePickerDocument = gql`
    query ArticleCategoriesForArticlePicker {
        articleCategorysV2 {
            results {
                id
                name
            }
        }
    }
`;

/**
 * __useArticleCategoriesForArticlePickerQuery__
 *
 * To run a query within a React component, call `useArticleCategoriesForArticlePickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleCategoriesForArticlePickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleCategoriesForArticlePickerQuery({
 *   variables: {
 *   },
 * });
 */
export function useArticleCategoriesForArticlePickerQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ArticleCategoriesForArticlePickerQuery,
        ArticleCategoriesForArticlePickerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        ArticleCategoriesForArticlePickerQuery,
        ArticleCategoriesForArticlePickerQueryVariables
    >(ArticleCategoriesForArticlePickerDocument, options);
}
export function useArticleCategoriesForArticlePickerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ArticleCategoriesForArticlePickerQuery,
        ArticleCategoriesForArticlePickerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ArticleCategoriesForArticlePickerQuery,
        ArticleCategoriesForArticlePickerQueryVariables
    >(ArticleCategoriesForArticlePickerDocument, options);
}
export type ArticleCategoriesForArticlePickerQueryHookResult = ReturnType<
    typeof useArticleCategoriesForArticlePickerQuery
>;
export type ArticleCategoriesForArticlePickerLazyQueryHookResult = ReturnType<
    typeof useArticleCategoriesForArticlePickerLazyQuery
>;
export type ArticleCategoriesForArticlePickerQueryResult = Apollo.QueryResult<
    ArticleCategoriesForArticlePickerQuery,
    ArticleCategoriesForArticlePickerQueryVariables
>;
export const ArticleGroupsForArticlePickerDocument = gql`
    query ArticleGroupsForArticlePicker {
        articleGroupsV2 {
            results {
                id
                name
            }
        }
    }
`;

/**
 * __useArticleGroupsForArticlePickerQuery__
 *
 * To run a query within a React component, call `useArticleGroupsForArticlePickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleGroupsForArticlePickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleGroupsForArticlePickerQuery({
 *   variables: {
 *   },
 * });
 */
export function useArticleGroupsForArticlePickerQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ArticleGroupsForArticlePickerQuery,
        ArticleGroupsForArticlePickerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        ArticleGroupsForArticlePickerQuery,
        ArticleGroupsForArticlePickerQueryVariables
    >(ArticleGroupsForArticlePickerDocument, options);
}
export function useArticleGroupsForArticlePickerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ArticleGroupsForArticlePickerQuery,
        ArticleGroupsForArticlePickerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ArticleGroupsForArticlePickerQuery,
        ArticleGroupsForArticlePickerQueryVariables
    >(ArticleGroupsForArticlePickerDocument, options);
}
export type ArticleGroupsForArticlePickerQueryHookResult = ReturnType<
    typeof useArticleGroupsForArticlePickerQuery
>;
export type ArticleGroupsForArticlePickerLazyQueryHookResult = ReturnType<
    typeof useArticleGroupsForArticlePickerLazyQuery
>;
export type ArticleGroupsForArticlePickerQueryResult = Apollo.QueryResult<
    ArticleGroupsForArticlePickerQuery,
    ArticleGroupsForArticlePickerQueryVariables
>;
export const CdPickerFetchDocument = gql`
    query CDPickerFetch($input: ContentDirectoryPickerFetchInput!) {
        contentDirectoryPickerFetch(input: $input) {
            data {
                name
                id
            }
            total
        }
    }
`;

/**
 * __useCdPickerFetchQuery__
 *
 * To run a query within a React component, call `useCdPickerFetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCdPickerFetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCdPickerFetchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCdPickerFetchQuery(
    baseOptions: Apollo.QueryHookOptions<CdPickerFetchQuery, CdPickerFetchQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CdPickerFetchQuery, CdPickerFetchQueryVariables>(
        CdPickerFetchDocument,
        options,
    );
}
export function useCdPickerFetchLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CdPickerFetchQuery, CdPickerFetchQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CdPickerFetchQuery, CdPickerFetchQueryVariables>(
        CdPickerFetchDocument,
        options,
    );
}
export type CdPickerFetchQueryHookResult = ReturnType<typeof useCdPickerFetchQuery>;
export type CdPickerFetchLazyQueryHookResult = ReturnType<typeof useCdPickerFetchLazyQuery>;
export type CdPickerFetchQueryResult = Apollo.QueryResult<
    CdPickerFetchQuery,
    CdPickerFetchQueryVariables
>;
export const SettingsForEnvLabelDocument = gql`
    query SettingsForEnvLabel {
        settings {
            env
        }
    }
`;

/**
 * __useSettingsForEnvLabelQuery__
 *
 * To run a query within a React component, call `useSettingsForEnvLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsForEnvLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsForEnvLabelQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsForEnvLabelQuery(
    baseOptions?: Apollo.QueryHookOptions<
        SettingsForEnvLabelQuery,
        SettingsForEnvLabelQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SettingsForEnvLabelQuery, SettingsForEnvLabelQueryVariables>(
        SettingsForEnvLabelDocument,
        options,
    );
}
export function useSettingsForEnvLabelLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SettingsForEnvLabelQuery,
        SettingsForEnvLabelQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SettingsForEnvLabelQuery, SettingsForEnvLabelQueryVariables>(
        SettingsForEnvLabelDocument,
        options,
    );
}
export type SettingsForEnvLabelQueryHookResult = ReturnType<typeof useSettingsForEnvLabelQuery>;
export type SettingsForEnvLabelLazyQueryHookResult = ReturnType<
    typeof useSettingsForEnvLabelLazyQuery
>;
export type SettingsForEnvLabelQueryResult = Apollo.QueryResult<
    SettingsForEnvLabelQuery,
    SettingsForEnvLabelQueryVariables
>;
export const ImageContentByIdForImageContentPickerDocument = gql`
    query ImageContentByIdForImageContentPicker($input: GetSimpleContentInput!) {
        simpleContent(input: $input) {
            name
            id
            contentType
            content
        }
    }
`;

/**
 * __useImageContentByIdForImageContentPickerQuery__
 *
 * To run a query within a React component, call `useImageContentByIdForImageContentPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useImageContentByIdForImageContentPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImageContentByIdForImageContentPickerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImageContentByIdForImageContentPickerQuery(
    baseOptions: Apollo.QueryHookOptions<
        ImageContentByIdForImageContentPickerQuery,
        ImageContentByIdForImageContentPickerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        ImageContentByIdForImageContentPickerQuery,
        ImageContentByIdForImageContentPickerQueryVariables
    >(ImageContentByIdForImageContentPickerDocument, options);
}
export function useImageContentByIdForImageContentPickerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ImageContentByIdForImageContentPickerQuery,
        ImageContentByIdForImageContentPickerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ImageContentByIdForImageContentPickerQuery,
        ImageContentByIdForImageContentPickerQueryVariables
    >(ImageContentByIdForImageContentPickerDocument, options);
}
export type ImageContentByIdForImageContentPickerQueryHookResult = ReturnType<
    typeof useImageContentByIdForImageContentPickerQuery
>;
export type ImageContentByIdForImageContentPickerLazyQueryHookResult = ReturnType<
    typeof useImageContentByIdForImageContentPickerLazyQuery
>;
export type ImageContentByIdForImageContentPickerQueryResult = Apollo.QueryResult<
    ImageContentByIdForImageContentPickerQuery,
    ImageContentByIdForImageContentPickerQueryVariables
>;
export const ImageContentByTextFilterDocument = gql`
    query ImageContentByTextFilter($input: ContentPickerFetchInput!) {
        contentPickerFetch(input: $input) {
            data {
                name
                id
                contentType
                content
            }
            total
        }
    }
`;

/**
 * __useImageContentByTextFilterQuery__
 *
 * To run a query within a React component, call `useImageContentByTextFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useImageContentByTextFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImageContentByTextFilterQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImageContentByTextFilterQuery(
    baseOptions: Apollo.QueryHookOptions<
        ImageContentByTextFilterQuery,
        ImageContentByTextFilterQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ImageContentByTextFilterQuery, ImageContentByTextFilterQueryVariables>(
        ImageContentByTextFilterDocument,
        options,
    );
}
export function useImageContentByTextFilterLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ImageContentByTextFilterQuery,
        ImageContentByTextFilterQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ImageContentByTextFilterQuery,
        ImageContentByTextFilterQueryVariables
    >(ImageContentByTextFilterDocument, options);
}
export type ImageContentByTextFilterQueryHookResult = ReturnType<
    typeof useImageContentByTextFilterQuery
>;
export type ImageContentByTextFilterLazyQueryHookResult = ReturnType<
    typeof useImageContentByTextFilterLazyQuery
>;
export type ImageContentByTextFilterQueryResult = Apollo.QueryResult<
    ImageContentByTextFilterQuery,
    ImageContentByTextFilterQueryVariables
>;
export const FetchCurrentUserLoginDocument = gql`
    query FetchCurrentUserLogin {
        currentUser {
            id
            name
            email
        }
    }
`;

/**
 * __useFetchCurrentUserLoginQuery__
 *
 * To run a query within a React component, call `useFetchCurrentUserLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCurrentUserLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCurrentUserLoginQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCurrentUserLoginQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchCurrentUserLoginQuery,
        FetchCurrentUserLoginQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchCurrentUserLoginQuery, FetchCurrentUserLoginQueryVariables>(
        FetchCurrentUserLoginDocument,
        options,
    );
}
export function useFetchCurrentUserLoginLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchCurrentUserLoginQuery,
        FetchCurrentUserLoginQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchCurrentUserLoginQuery, FetchCurrentUserLoginQueryVariables>(
        FetchCurrentUserLoginDocument,
        options,
    );
}
export type FetchCurrentUserLoginQueryHookResult = ReturnType<typeof useFetchCurrentUserLoginQuery>;
export type FetchCurrentUserLoginLazyQueryHookResult = ReturnType<
    typeof useFetchCurrentUserLoginLazyQuery
>;
export type FetchCurrentUserLoginQueryResult = Apollo.QueryResult<
    FetchCurrentUserLoginQuery,
    FetchCurrentUserLoginQueryVariables
>;
export const LoginUserForLoginPageDocument = gql`
    mutation LoginUserForLoginPage($input: LoginUserInput!) {
        loginUser(input: $input) {
            success
            message
            tokens {
                idToken
                accessToken
            }
            newPasswordRequired
            tempPasswordToken
        }
    }
`;
export type LoginUserForLoginPageMutationFn = Apollo.MutationFunction<
    LoginUserForLoginPageMutation,
    LoginUserForLoginPageMutationVariables
>;

/**
 * __useLoginUserForLoginPageMutation__
 *
 * To run a mutation, you first call `useLoginUserForLoginPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserForLoginPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserForLoginPageMutation, { data, loading, error }] = useLoginUserForLoginPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserForLoginPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        LoginUserForLoginPageMutation,
        LoginUserForLoginPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        LoginUserForLoginPageMutation,
        LoginUserForLoginPageMutationVariables
    >(LoginUserForLoginPageDocument, options);
}
export type LoginUserForLoginPageMutationHookResult = ReturnType<
    typeof useLoginUserForLoginPageMutation
>;
export type LoginUserForLoginPageMutationResult =
    Apollo.MutationResult<LoginUserForLoginPageMutation>;
export type LoginUserForLoginPageMutationOptions = Apollo.BaseMutationOptions<
    LoginUserForLoginPageMutation,
    LoginUserForLoginPageMutationVariables
>;
export const ResetPasswordLoginPageDocument = gql`
    mutation ResetPasswordLoginPage($input: ResetPasswordInput!) {
        resetPassword(input: $input) {
            success
            message
        }
    }
`;
export type ResetPasswordLoginPageMutationFn = Apollo.MutationFunction<
    ResetPasswordLoginPageMutation,
    ResetPasswordLoginPageMutationVariables
>;

/**
 * __useResetPasswordLoginPageMutation__
 *
 * To run a mutation, you first call `useResetPasswordLoginPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordLoginPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordLoginPageMutation, { data, loading, error }] = useResetPasswordLoginPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordLoginPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ResetPasswordLoginPageMutation,
        ResetPasswordLoginPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        ResetPasswordLoginPageMutation,
        ResetPasswordLoginPageMutationVariables
    >(ResetPasswordLoginPageDocument, options);
}
export type ResetPasswordLoginPageMutationHookResult = ReturnType<
    typeof useResetPasswordLoginPageMutation
>;
export type ResetPasswordLoginPageMutationResult =
    Apollo.MutationResult<ResetPasswordLoginPageMutation>;
export type ResetPasswordLoginPageMutationOptions = Apollo.BaseMutationOptions<
    ResetPasswordLoginPageMutation,
    ResetPasswordLoginPageMutationVariables
>;
export const LoginAuthMethodDocument = gql`
    query LoginAuthMethod($input: LoginAuthMethodInput) {
        loginAuthMethod(input: $input) {
            type
            samlRequest
            samlUrl
            samlUrlBase
        }
    }
`;

/**
 * __useLoginAuthMethodQuery__
 *
 * To run a query within a React component, call `useLoginAuthMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginAuthMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginAuthMethodQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginAuthMethodQuery(
    baseOptions?: Apollo.QueryHookOptions<LoginAuthMethodQuery, LoginAuthMethodQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<LoginAuthMethodQuery, LoginAuthMethodQueryVariables>(
        LoginAuthMethodDocument,
        options,
    );
}
export function useLoginAuthMethodLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<LoginAuthMethodQuery, LoginAuthMethodQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<LoginAuthMethodQuery, LoginAuthMethodQueryVariables>(
        LoginAuthMethodDocument,
        options,
    );
}
export type LoginAuthMethodQueryHookResult = ReturnType<typeof useLoginAuthMethodQuery>;
export type LoginAuthMethodLazyQueryHookResult = ReturnType<typeof useLoginAuthMethodLazyQuery>;
export type LoginAuthMethodQueryResult = Apollo.QueryResult<
    LoginAuthMethodQuery,
    LoginAuthMethodQueryVariables
>;
export const ContentByIdForContentPickerDocument = gql`
    query ContentByIdForContentPicker($input: GetSimpleContentInput!) {
        simpleContent(input: $input) {
            name
            id
            contentType
        }
    }
`;

/**
 * __useContentByIdForContentPickerQuery__
 *
 * To run a query within a React component, call `useContentByIdForContentPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentByIdForContentPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentByIdForContentPickerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContentByIdForContentPickerQuery(
    baseOptions: Apollo.QueryHookOptions<
        ContentByIdForContentPickerQuery,
        ContentByIdForContentPickerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        ContentByIdForContentPickerQuery,
        ContentByIdForContentPickerQueryVariables
    >(ContentByIdForContentPickerDocument, options);
}
export function useContentByIdForContentPickerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ContentByIdForContentPickerQuery,
        ContentByIdForContentPickerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ContentByIdForContentPickerQuery,
        ContentByIdForContentPickerQueryVariables
    >(ContentByIdForContentPickerDocument, options);
}
export type ContentByIdForContentPickerQueryHookResult = ReturnType<
    typeof useContentByIdForContentPickerQuery
>;
export type ContentByIdForContentPickerLazyQueryHookResult = ReturnType<
    typeof useContentByIdForContentPickerLazyQuery
>;
export type ContentByIdForContentPickerQueryResult = Apollo.QueryResult<
    ContentByIdForContentPickerQuery,
    ContentByIdForContentPickerQueryVariables
>;
export const ContentByCriteriaDocument = gql`
    query ContentByCriteria($input: ContentPickerFetchInput!) {
        contentPickerFetch(input: $input) {
            data {
                name
                id
                contentType
            }
            total
        }
    }
`;

/**
 * __useContentByCriteriaQuery__
 *
 * To run a query within a React component, call `useContentByCriteriaQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentByCriteriaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentByCriteriaQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContentByCriteriaQuery(
    baseOptions: Apollo.QueryHookOptions<ContentByCriteriaQuery, ContentByCriteriaQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ContentByCriteriaQuery, ContentByCriteriaQueryVariables>(
        ContentByCriteriaDocument,
        options,
    );
}
export function useContentByCriteriaLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ContentByCriteriaQuery,
        ContentByCriteriaQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ContentByCriteriaQuery, ContentByCriteriaQueryVariables>(
        ContentByCriteriaDocument,
        options,
    );
}
export type ContentByCriteriaQueryHookResult = ReturnType<typeof useContentByCriteriaQuery>;
export type ContentByCriteriaLazyQueryHookResult = ReturnType<typeof useContentByCriteriaLazyQuery>;
export type ContentByCriteriaQueryResult = Apollo.QueryResult<
    ContentByCriteriaQuery,
    ContentByCriteriaQueryVariables
>;
export const FetchPatientsForSearchModalDocument = gql`
    query FetchPatientsForSearchModal($input: GetPatientsByFilterInput!) {
        currentUser {
            patientsV2(input: $input) {
                orderBy
                skip
                limit
                total
                hasMore
                hasPrevious
                results {
                    ...PatientForPatientsPage
                }
            }
        }
    }
    ${PatientForPatientsPageFragmentDoc}
`;

/**
 * __useFetchPatientsForSearchModalQuery__
 *
 * To run a query within a React component, call `useFetchPatientsForSearchModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientsForSearchModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientsForSearchModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPatientsForSearchModalQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPatientsForSearchModalQuery,
        FetchPatientsForSearchModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientsForSearchModalQuery,
        FetchPatientsForSearchModalQueryVariables
    >(FetchPatientsForSearchModalDocument, options);
}
export function useFetchPatientsForSearchModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientsForSearchModalQuery,
        FetchPatientsForSearchModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientsForSearchModalQuery,
        FetchPatientsForSearchModalQueryVariables
    >(FetchPatientsForSearchModalDocument, options);
}
export type FetchPatientsForSearchModalQueryHookResult = ReturnType<
    typeof useFetchPatientsForSearchModalQuery
>;
export type FetchPatientsForSearchModalLazyQueryHookResult = ReturnType<
    typeof useFetchPatientsForSearchModalLazyQuery
>;
export type FetchPatientsForSearchModalQueryResult = Apollo.QueryResult<
    FetchPatientsForSearchModalQuery,
    FetchPatientsForSearchModalQueryVariables
>;
export const FetchPatientByIdForSearchModalDocument = gql`
    query FetchPatientByIdForSearchModal($input: GetPatientInput!) {
        patient(input: $input) {
            ...PatientForPatientsPage
        }
    }
    ${PatientForPatientsPageFragmentDoc}
`;

/**
 * __useFetchPatientByIdForSearchModalQuery__
 *
 * To run a query within a React component, call `useFetchPatientByIdForSearchModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientByIdForSearchModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientByIdForSearchModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPatientByIdForSearchModalQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPatientByIdForSearchModalQuery,
        FetchPatientByIdForSearchModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientByIdForSearchModalQuery,
        FetchPatientByIdForSearchModalQueryVariables
    >(FetchPatientByIdForSearchModalDocument, options);
}
export function useFetchPatientByIdForSearchModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientByIdForSearchModalQuery,
        FetchPatientByIdForSearchModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientByIdForSearchModalQuery,
        FetchPatientByIdForSearchModalQueryVariables
    >(FetchPatientByIdForSearchModalDocument, options);
}
export type FetchPatientByIdForSearchModalQueryHookResult = ReturnType<
    typeof useFetchPatientByIdForSearchModalQuery
>;
export type FetchPatientByIdForSearchModalLazyQueryHookResult = ReturnType<
    typeof useFetchPatientByIdForSearchModalLazyQuery
>;
export type FetchPatientByIdForSearchModalQueryResult = Apollo.QueryResult<
    FetchPatientByIdForSearchModalQuery,
    FetchPatientByIdForSearchModalQueryVariables
>;
export const UpdateCurrentOrgInNavDocument = gql`
    mutation UpdateCurrentOrgInNav($input: UpdateUserInput!) {
        updateUser(input: $input) {
            success
            message
            resourceUpdated {
                id
                name
                email
            }
        }
    }
`;
export type UpdateCurrentOrgInNavMutationFn = Apollo.MutationFunction<
    UpdateCurrentOrgInNavMutation,
    UpdateCurrentOrgInNavMutationVariables
>;

/**
 * __useUpdateCurrentOrgInNavMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentOrgInNavMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentOrgInNavMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentOrgInNavMutation, { data, loading, error }] = useUpdateCurrentOrgInNavMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrentOrgInNavMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateCurrentOrgInNavMutation,
        UpdateCurrentOrgInNavMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateCurrentOrgInNavMutation,
        UpdateCurrentOrgInNavMutationVariables
    >(UpdateCurrentOrgInNavDocument, options);
}
export type UpdateCurrentOrgInNavMutationHookResult = ReturnType<
    typeof useUpdateCurrentOrgInNavMutation
>;
export type UpdateCurrentOrgInNavMutationResult =
    Apollo.MutationResult<UpdateCurrentOrgInNavMutation>;
export type UpdateCurrentOrgInNavMutationOptions = Apollo.BaseMutationOptions<
    UpdateCurrentOrgInNavMutation,
    UpdateCurrentOrgInNavMutationVariables
>;
export const CurrentUserSubscriptionDocument = gql`
    subscription CurrentUserSubscription {
        currentUserSubscription {
            ...CurrentUser
        }
    }
    ${CurrentUserFragmentDoc}
`;

/**
 * __useCurrentUserSubscriptionSubscription__
 *
 * To run a query within a React component, call `useCurrentUserSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserSubscriptionSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<
        CurrentUserSubscriptionSubscription,
        CurrentUserSubscriptionSubscriptionVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<
        CurrentUserSubscriptionSubscription,
        CurrentUserSubscriptionSubscriptionVariables
    >(CurrentUserSubscriptionDocument, options);
}
export type CurrentUserSubscriptionSubscriptionHookResult = ReturnType<
    typeof useCurrentUserSubscriptionSubscription
>;
export type CurrentUserSubscriptionSubscriptionResult =
    Apollo.SubscriptionResult<CurrentUserSubscriptionSubscription>;
export const AdvocateNotificationsByUserDocument = gql`
    query AdvocateNotificationsByUser($input: AadvocateNotificationsByUserInput!) {
        advocateNotificationsByUser(input: $input) {
            advocateTask {
                id
                label
                priority
            }
            createdAt
            id
            isTestData
            patient {
                fullName
                id
            }
            updatedAt
            userId
            viewed
        }
    }
`;

/**
 * __useAdvocateNotificationsByUserQuery__
 *
 * To run a query within a React component, call `useAdvocateNotificationsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvocateNotificationsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvocateNotificationsByUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdvocateNotificationsByUserQuery(
    baseOptions: Apollo.QueryHookOptions<
        AdvocateNotificationsByUserQuery,
        AdvocateNotificationsByUserQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        AdvocateNotificationsByUserQuery,
        AdvocateNotificationsByUserQueryVariables
    >(AdvocateNotificationsByUserDocument, options);
}
export function useAdvocateNotificationsByUserLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        AdvocateNotificationsByUserQuery,
        AdvocateNotificationsByUserQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        AdvocateNotificationsByUserQuery,
        AdvocateNotificationsByUserQueryVariables
    >(AdvocateNotificationsByUserDocument, options);
}
export type AdvocateNotificationsByUserQueryHookResult = ReturnType<
    typeof useAdvocateNotificationsByUserQuery
>;
export type AdvocateNotificationsByUserLazyQueryHookResult = ReturnType<
    typeof useAdvocateNotificationsByUserLazyQuery
>;
export type AdvocateNotificationsByUserQueryResult = Apollo.QueryResult<
    AdvocateNotificationsByUserQuery,
    AdvocateNotificationsByUserQueryVariables
>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
            resourceUpdated {
                id
            }
        }
    }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
    UpdateUserMutation,
    UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
        UpdateUserDocument,
        options,
    );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
>;
export const UpdateAdvocateNotificationDocument = gql`
    mutation UpdateAdvocateNotification($input: UpdateAdvocateNotificationInput!) {
        updateAdvocateNotification(input: $input) {
            success
            message
        }
    }
`;
export type UpdateAdvocateNotificationMutationFn = Apollo.MutationFunction<
    UpdateAdvocateNotificationMutation,
    UpdateAdvocateNotificationMutationVariables
>;

/**
 * __useUpdateAdvocateNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateAdvocateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdvocateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdvocateNotificationMutation, { data, loading, error }] = useUpdateAdvocateNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdvocateNotificationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateAdvocateNotificationMutation,
        UpdateAdvocateNotificationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateAdvocateNotificationMutation,
        UpdateAdvocateNotificationMutationVariables
    >(UpdateAdvocateNotificationDocument, options);
}
export type UpdateAdvocateNotificationMutationHookResult = ReturnType<
    typeof useUpdateAdvocateNotificationMutation
>;
export type UpdateAdvocateNotificationMutationResult =
    Apollo.MutationResult<UpdateAdvocateNotificationMutation>;
export type UpdateAdvocateNotificationMutationOptions = Apollo.BaseMutationOptions<
    UpdateAdvocateNotificationMutation,
    UpdateAdvocateNotificationMutationVariables
>;
export const SimpleContentsForRichTextEditorMenuBarImageDialogDocument = gql`
    query SimpleContentsForRichTextEditorMenuBarImageDialog($input: SimpleContentListInput) {
        simpleContentsV2(input: $input) {
            results {
                id
                name
                contentType
                content
                label {
                    en
                    es
                }
                articleId
                trackerType
                surveyId
                contentDirectoryId
            }
        }
    }
`;

/**
 * __useSimpleContentsForRichTextEditorMenuBarImageDialogQuery__
 *
 * To run a query within a React component, call `useSimpleContentsForRichTextEditorMenuBarImageDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleContentsForRichTextEditorMenuBarImageDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleContentsForRichTextEditorMenuBarImageDialogQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSimpleContentsForRichTextEditorMenuBarImageDialogQuery(
    baseOptions?: Apollo.QueryHookOptions<
        SimpleContentsForRichTextEditorMenuBarImageDialogQuery,
        SimpleContentsForRichTextEditorMenuBarImageDialogQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        SimpleContentsForRichTextEditorMenuBarImageDialogQuery,
        SimpleContentsForRichTextEditorMenuBarImageDialogQueryVariables
    >(SimpleContentsForRichTextEditorMenuBarImageDialogDocument, options);
}
export function useSimpleContentsForRichTextEditorMenuBarImageDialogLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SimpleContentsForRichTextEditorMenuBarImageDialogQuery,
        SimpleContentsForRichTextEditorMenuBarImageDialogQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        SimpleContentsForRichTextEditorMenuBarImageDialogQuery,
        SimpleContentsForRichTextEditorMenuBarImageDialogQueryVariables
    >(SimpleContentsForRichTextEditorMenuBarImageDialogDocument, options);
}
export type SimpleContentsForRichTextEditorMenuBarImageDialogQueryHookResult = ReturnType<
    typeof useSimpleContentsForRichTextEditorMenuBarImageDialogQuery
>;
export type SimpleContentsForRichTextEditorMenuBarImageDialogLazyQueryHookResult = ReturnType<
    typeof useSimpleContentsForRichTextEditorMenuBarImageDialogLazyQuery
>;
export type SimpleContentsForRichTextEditorMenuBarImageDialogQueryResult = Apollo.QueryResult<
    SimpleContentsForRichTextEditorMenuBarImageDialogQuery,
    SimpleContentsForRichTextEditorMenuBarImageDialogQueryVariables
>;
export const ContentDirectorysV2ForRichTextEditorDocument = gql`
    query ContentDirectorysV2ForRichTextEditor($input: ContentDirectoryListInput) {
        contentDirectorysV2(input: $input) {
            results {
                id
                displayStyle
            }
        }
    }
`;

/**
 * __useContentDirectorysV2ForRichTextEditorQuery__
 *
 * To run a query within a React component, call `useContentDirectorysV2ForRichTextEditorQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentDirectorysV2ForRichTextEditorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentDirectorysV2ForRichTextEditorQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContentDirectorysV2ForRichTextEditorQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ContentDirectorysV2ForRichTextEditorQuery,
        ContentDirectorysV2ForRichTextEditorQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        ContentDirectorysV2ForRichTextEditorQuery,
        ContentDirectorysV2ForRichTextEditorQueryVariables
    >(ContentDirectorysV2ForRichTextEditorDocument, options);
}
export function useContentDirectorysV2ForRichTextEditorLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ContentDirectorysV2ForRichTextEditorQuery,
        ContentDirectorysV2ForRichTextEditorQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ContentDirectorysV2ForRichTextEditorQuery,
        ContentDirectorysV2ForRichTextEditorQueryVariables
    >(ContentDirectorysV2ForRichTextEditorDocument, options);
}
export type ContentDirectorysV2ForRichTextEditorQueryHookResult = ReturnType<
    typeof useContentDirectorysV2ForRichTextEditorQuery
>;
export type ContentDirectorysV2ForRichTextEditorLazyQueryHookResult = ReturnType<
    typeof useContentDirectorysV2ForRichTextEditorLazyQuery
>;
export type ContentDirectorysV2ForRichTextEditorQueryResult = Apollo.QueryResult<
    ContentDirectorysV2ForRichTextEditorQuery,
    ContentDirectorysV2ForRichTextEditorQueryVariables
>;
export const FetchProfileDefsForRichTextEditorSubstTokenDocument = gql`
    query FetchProfileDefsForRichTextEditorSubstToken {
        userProfileDefs {
            id
            name
        }
    }
`;

/**
 * __useFetchProfileDefsForRichTextEditorSubstTokenQuery__
 *
 * To run a query within a React component, call `useFetchProfileDefsForRichTextEditorSubstTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProfileDefsForRichTextEditorSubstTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProfileDefsForRichTextEditorSubstTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchProfileDefsForRichTextEditorSubstTokenQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchProfileDefsForRichTextEditorSubstTokenQuery,
        FetchProfileDefsForRichTextEditorSubstTokenQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchProfileDefsForRichTextEditorSubstTokenQuery,
        FetchProfileDefsForRichTextEditorSubstTokenQueryVariables
    >(FetchProfileDefsForRichTextEditorSubstTokenDocument, options);
}
export function useFetchProfileDefsForRichTextEditorSubstTokenLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchProfileDefsForRichTextEditorSubstTokenQuery,
        FetchProfileDefsForRichTextEditorSubstTokenQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchProfileDefsForRichTextEditorSubstTokenQuery,
        FetchProfileDefsForRichTextEditorSubstTokenQueryVariables
    >(FetchProfileDefsForRichTextEditorSubstTokenDocument, options);
}
export type FetchProfileDefsForRichTextEditorSubstTokenQueryHookResult = ReturnType<
    typeof useFetchProfileDefsForRichTextEditorSubstTokenQuery
>;
export type FetchProfileDefsForRichTextEditorSubstTokenLazyQueryHookResult = ReturnType<
    typeof useFetchProfileDefsForRichTextEditorSubstTokenLazyQuery
>;
export type FetchProfileDefsForRichTextEditorSubstTokenQueryResult = Apollo.QueryResult<
    FetchProfileDefsForRichTextEditorSubstTokenQuery,
    FetchProfileDefsForRichTextEditorSubstTokenQueryVariables
>;
export const FetchConfigValueDefsForRichTextEditorSubstTokenDocument = gql`
    query FetchConfigValueDefsForRichTextEditorSubstToken {
        configValueDefs {
            id
            name
        }
    }
`;

/**
 * __useFetchConfigValueDefsForRichTextEditorSubstTokenQuery__
 *
 * To run a query within a React component, call `useFetchConfigValueDefsForRichTextEditorSubstTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchConfigValueDefsForRichTextEditorSubstTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchConfigValueDefsForRichTextEditorSubstTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchConfigValueDefsForRichTextEditorSubstTokenQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchConfigValueDefsForRichTextEditorSubstTokenQuery,
        FetchConfigValueDefsForRichTextEditorSubstTokenQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchConfigValueDefsForRichTextEditorSubstTokenQuery,
        FetchConfigValueDefsForRichTextEditorSubstTokenQueryVariables
    >(FetchConfigValueDefsForRichTextEditorSubstTokenDocument, options);
}
export function useFetchConfigValueDefsForRichTextEditorSubstTokenLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchConfigValueDefsForRichTextEditorSubstTokenQuery,
        FetchConfigValueDefsForRichTextEditorSubstTokenQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchConfigValueDefsForRichTextEditorSubstTokenQuery,
        FetchConfigValueDefsForRichTextEditorSubstTokenQueryVariables
    >(FetchConfigValueDefsForRichTextEditorSubstTokenDocument, options);
}
export type FetchConfigValueDefsForRichTextEditorSubstTokenQueryHookResult = ReturnType<
    typeof useFetchConfigValueDefsForRichTextEditorSubstTokenQuery
>;
export type FetchConfigValueDefsForRichTextEditorSubstTokenLazyQueryHookResult = ReturnType<
    typeof useFetchConfigValueDefsForRichTextEditorSubstTokenLazyQuery
>;
export type FetchConfigValueDefsForRichTextEditorSubstTokenQueryResult = Apollo.QueryResult<
    FetchConfigValueDefsForRichTextEditorSubstTokenQuery,
    FetchConfigValueDefsForRichTextEditorSubstTokenQueryVariables
>;
export const FetchSystemTokensForRichTextEditorSubstTokenDocument = gql`
    query FetchSystemTokensForRichTextEditorSubstToken {
        systemTokens {
            id
            name
        }
    }
`;

/**
 * __useFetchSystemTokensForRichTextEditorSubstTokenQuery__
 *
 * To run a query within a React component, call `useFetchSystemTokensForRichTextEditorSubstTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSystemTokensForRichTextEditorSubstTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSystemTokensForRichTextEditorSubstTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchSystemTokensForRichTextEditorSubstTokenQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchSystemTokensForRichTextEditorSubstTokenQuery,
        FetchSystemTokensForRichTextEditorSubstTokenQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchSystemTokensForRichTextEditorSubstTokenQuery,
        FetchSystemTokensForRichTextEditorSubstTokenQueryVariables
    >(FetchSystemTokensForRichTextEditorSubstTokenDocument, options);
}
export function useFetchSystemTokensForRichTextEditorSubstTokenLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchSystemTokensForRichTextEditorSubstTokenQuery,
        FetchSystemTokensForRichTextEditorSubstTokenQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchSystemTokensForRichTextEditorSubstTokenQuery,
        FetchSystemTokensForRichTextEditorSubstTokenQueryVariables
    >(FetchSystemTokensForRichTextEditorSubstTokenDocument, options);
}
export type FetchSystemTokensForRichTextEditorSubstTokenQueryHookResult = ReturnType<
    typeof useFetchSystemTokensForRichTextEditorSubstTokenQuery
>;
export type FetchSystemTokensForRichTextEditorSubstTokenLazyQueryHookResult = ReturnType<
    typeof useFetchSystemTokensForRichTextEditorSubstTokenLazyQuery
>;
export type FetchSystemTokensForRichTextEditorSubstTokenQueryResult = Apollo.QueryResult<
    FetchSystemTokensForRichTextEditorSubstTokenQuery,
    FetchSystemTokensForRichTextEditorSubstTokenQueryVariables
>;
export const FetchUploadCareSettingsDocument = gql`
    query FetchUploadCareSettings {
        settings {
            uploadCarePublicKey
        }
    }
`;

/**
 * __useFetchUploadCareSettingsQuery__
 *
 * To run a query within a React component, call `useFetchUploadCareSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUploadCareSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUploadCareSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchUploadCareSettingsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchUploadCareSettingsQuery,
        FetchUploadCareSettingsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchUploadCareSettingsQuery, FetchUploadCareSettingsQueryVariables>(
        FetchUploadCareSettingsDocument,
        options,
    );
}
export function useFetchUploadCareSettingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchUploadCareSettingsQuery,
        FetchUploadCareSettingsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchUploadCareSettingsQuery, FetchUploadCareSettingsQueryVariables>(
        FetchUploadCareSettingsDocument,
        options,
    );
}
export type FetchUploadCareSettingsQueryHookResult = ReturnType<
    typeof useFetchUploadCareSettingsQuery
>;
export type FetchUploadCareSettingsLazyQueryHookResult = ReturnType<
    typeof useFetchUploadCareSettingsLazyQuery
>;
export type FetchUploadCareSettingsQueryResult = Apollo.QueryResult<
    FetchUploadCareSettingsQuery,
    FetchUploadCareSettingsQueryVariables
>;
export const FetchCareTeamMembersForCareTeamMembersHookDocument = gql`
    query FetchCareTeamMembersForCareTeamMembersHook($input: CareTeamMembersByTypeNamesInput!) {
        careTeamMembersByTypesNames(input: $input) {
            careTeamType {
                name
                profileDefId
            }
            careTeamMembers {
                id
                label {
                    en
                }
                isActive
            }
        }
    }
`;

/**
 * __useFetchCareTeamMembersForCareTeamMembersHookQuery__
 *
 * To run a query within a React component, call `useFetchCareTeamMembersForCareTeamMembersHookQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCareTeamMembersForCareTeamMembersHookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCareTeamMembersForCareTeamMembersHookQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchCareTeamMembersForCareTeamMembersHookQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchCareTeamMembersForCareTeamMembersHookQuery,
        FetchCareTeamMembersForCareTeamMembersHookQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchCareTeamMembersForCareTeamMembersHookQuery,
        FetchCareTeamMembersForCareTeamMembersHookQueryVariables
    >(FetchCareTeamMembersForCareTeamMembersHookDocument, options);
}
export function useFetchCareTeamMembersForCareTeamMembersHookLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchCareTeamMembersForCareTeamMembersHookQuery,
        FetchCareTeamMembersForCareTeamMembersHookQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchCareTeamMembersForCareTeamMembersHookQuery,
        FetchCareTeamMembersForCareTeamMembersHookQueryVariables
    >(FetchCareTeamMembersForCareTeamMembersHookDocument, options);
}
export type FetchCareTeamMembersForCareTeamMembersHookQueryHookResult = ReturnType<
    typeof useFetchCareTeamMembersForCareTeamMembersHookQuery
>;
export type FetchCareTeamMembersForCareTeamMembersHookLazyQueryHookResult = ReturnType<
    typeof useFetchCareTeamMembersForCareTeamMembersHookLazyQuery
>;
export type FetchCareTeamMembersForCareTeamMembersHookQueryResult = Apollo.QueryResult<
    FetchCareTeamMembersForCareTeamMembersHookQuery,
    FetchCareTeamMembersForCareTeamMembersHookQueryVariables
>;
export const FetchCurrentUserForUseUserHookDocument = gql`
    query FetchCurrentUserForUseUserHook {
        currentUser {
            id
            name
            email
            title
            smsNumber
            clientOrgId
            numberOfUnreadChatMessages
            newTaskNotificationCount
            openConversationsCount
            isTestData
            currentOrganizations {
                id
            }
            currentOrg {
                id
                parentId
                brandingName
                timezone
                appPlatform
                appBundleId
                unreadMessagesCount
            }
            rootOrg {
                id
                parentId
                brandingName
            }
            roleId
            role {
                id
                name
                permissions {
                    target
                    permission
                    canExport
                }
            }
            affiliate {
                data {
                    id
                    externalName
                    affiliateId
                }
                affiliateURL
            }
        }
    }
`;

/**
 * __useFetchCurrentUserForUseUserHookQuery__
 *
 * To run a query within a React component, call `useFetchCurrentUserForUseUserHookQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCurrentUserForUseUserHookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCurrentUserForUseUserHookQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCurrentUserForUseUserHookQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchCurrentUserForUseUserHookQuery,
        FetchCurrentUserForUseUserHookQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchCurrentUserForUseUserHookQuery,
        FetchCurrentUserForUseUserHookQueryVariables
    >(FetchCurrentUserForUseUserHookDocument, options);
}
export function useFetchCurrentUserForUseUserHookLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchCurrentUserForUseUserHookQuery,
        FetchCurrentUserForUseUserHookQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchCurrentUserForUseUserHookQuery,
        FetchCurrentUserForUseUserHookQueryVariables
    >(FetchCurrentUserForUseUserHookDocument, options);
}
export type FetchCurrentUserForUseUserHookQueryHookResult = ReturnType<
    typeof useFetchCurrentUserForUseUserHookQuery
>;
export type FetchCurrentUserForUseUserHookLazyQueryHookResult = ReturnType<
    typeof useFetchCurrentUserForUseUserHookLazyQuery
>;
export type FetchCurrentUserForUseUserHookQueryResult = Apollo.QueryResult<
    FetchCurrentUserForUseUserHookQuery,
    FetchCurrentUserForUseUserHookQueryVariables
>;
export const FetchRolesForCurrentUserPermissionsHookDocument = gql`
    query FetchRolesForCurrentUserPermissionsHook {
        roleOfCurrentUser {
            permissions {
                canExport
                permission
                target
            }
            name
            id
        }
    }
`;

/**
 * __useFetchRolesForCurrentUserPermissionsHookQuery__
 *
 * To run a query within a React component, call `useFetchRolesForCurrentUserPermissionsHookQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRolesForCurrentUserPermissionsHookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRolesForCurrentUserPermissionsHookQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchRolesForCurrentUserPermissionsHookQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchRolesForCurrentUserPermissionsHookQuery,
        FetchRolesForCurrentUserPermissionsHookQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchRolesForCurrentUserPermissionsHookQuery,
        FetchRolesForCurrentUserPermissionsHookQueryVariables
    >(FetchRolesForCurrentUserPermissionsHookDocument, options);
}
export function useFetchRolesForCurrentUserPermissionsHookLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchRolesForCurrentUserPermissionsHookQuery,
        FetchRolesForCurrentUserPermissionsHookQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchRolesForCurrentUserPermissionsHookQuery,
        FetchRolesForCurrentUserPermissionsHookQueryVariables
    >(FetchRolesForCurrentUserPermissionsHookDocument, options);
}
export type FetchRolesForCurrentUserPermissionsHookQueryHookResult = ReturnType<
    typeof useFetchRolesForCurrentUserPermissionsHookQuery
>;
export type FetchRolesForCurrentUserPermissionsHookLazyQueryHookResult = ReturnType<
    typeof useFetchRolesForCurrentUserPermissionsHookLazyQuery
>;
export type FetchRolesForCurrentUserPermissionsHookQueryResult = Apollo.QueryResult<
    FetchRolesForCurrentUserPermissionsHookQuery,
    FetchRolesForCurrentUserPermissionsHookQueryVariables
>;
export const FetchBannerStateDocument = gql`
    query FetchBannerState {
        GlobalBanner @client {
            open
            message
            actionText
            severity
        }
    }
`;

/**
 * __useFetchBannerStateQuery__
 *
 * To run a query within a React component, call `useFetchBannerStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchBannerStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchBannerStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchBannerStateQuery(
    baseOptions?: Apollo.QueryHookOptions<FetchBannerStateQuery, FetchBannerStateQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchBannerStateQuery, FetchBannerStateQueryVariables>(
        FetchBannerStateDocument,
        options,
    );
}
export function useFetchBannerStateLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchBannerStateQuery,
        FetchBannerStateQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchBannerStateQuery, FetchBannerStateQueryVariables>(
        FetchBannerStateDocument,
        options,
    );
}
export type FetchBannerStateQueryHookResult = ReturnType<typeof useFetchBannerStateQuery>;
export type FetchBannerStateLazyQueryHookResult = ReturnType<typeof useFetchBannerStateLazyQuery>;
export type FetchBannerStateQueryResult = Apollo.QueryResult<
    FetchBannerStateQuery,
    FetchBannerStateQueryVariables
>;
export const FetchAlertStateDocument = gql`
    query FetchAlertState {
        GlobalAlert @client {
            message
            open
            severity
        }
        GlobalConfirm @client {
            open
            message
            description
        }
        GlobalImageUpload @client {
            open
            isImage
        }
        GlobalImagePreview @client {
            open
            imageSrc
        }
    }
`;

/**
 * __useFetchAlertStateQuery__
 *
 * To run a query within a React component, call `useFetchAlertStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAlertStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAlertStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAlertStateQuery(
    baseOptions?: Apollo.QueryHookOptions<FetchAlertStateQuery, FetchAlertStateQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchAlertStateQuery, FetchAlertStateQueryVariables>(
        FetchAlertStateDocument,
        options,
    );
}
export function useFetchAlertStateLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<FetchAlertStateQuery, FetchAlertStateQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchAlertStateQuery, FetchAlertStateQueryVariables>(
        FetchAlertStateDocument,
        options,
    );
}
export type FetchAlertStateQueryHookResult = ReturnType<typeof useFetchAlertStateQuery>;
export type FetchAlertStateLazyQueryHookResult = ReturnType<typeof useFetchAlertStateLazyQuery>;
export type FetchAlertStateQueryResult = Apollo.QueryResult<
    FetchAlertStateQuery,
    FetchAlertStateQueryVariables
>;
export const FetchSettingsDocument = gql`
    query FetchSettings {
        settings {
            cognitoPoolId
            cognitoClientId
        }
    }
`;

/**
 * __useFetchSettingsQuery__
 *
 * To run a query within a React component, call `useFetchSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchSettingsQuery(
    baseOptions?: Apollo.QueryHookOptions<FetchSettingsQuery, FetchSettingsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchSettingsQuery, FetchSettingsQueryVariables>(
        FetchSettingsDocument,
        options,
    );
}
export function useFetchSettingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<FetchSettingsQuery, FetchSettingsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchSettingsQuery, FetchSettingsQueryVariables>(
        FetchSettingsDocument,
        options,
    );
}
export type FetchSettingsQueryHookResult = ReturnType<typeof useFetchSettingsQuery>;
export type FetchSettingsLazyQueryHookResult = ReturnType<typeof useFetchSettingsLazyQuery>;
export type FetchSettingsQueryResult = Apollo.QueryResult<
    FetchSettingsQuery,
    FetchSettingsQueryVariables
>;
export const LoginUserForAcceptInvitePageDocument = gql`
    mutation LoginUserForAcceptInvitePage($input: LoginUserInput!) {
        loginUser(input: $input) {
            success
            message
            tokens {
                idToken
                accessToken
            }
            newPasswordRequired
            tempPasswordToken
        }
    }
`;
export type LoginUserForAcceptInvitePageMutationFn = Apollo.MutationFunction<
    LoginUserForAcceptInvitePageMutation,
    LoginUserForAcceptInvitePageMutationVariables
>;

/**
 * __useLoginUserForAcceptInvitePageMutation__
 *
 * To run a mutation, you first call `useLoginUserForAcceptInvitePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserForAcceptInvitePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserForAcceptInvitePageMutation, { data, loading, error }] = useLoginUserForAcceptInvitePageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserForAcceptInvitePageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        LoginUserForAcceptInvitePageMutation,
        LoginUserForAcceptInvitePageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        LoginUserForAcceptInvitePageMutation,
        LoginUserForAcceptInvitePageMutationVariables
    >(LoginUserForAcceptInvitePageDocument, options);
}
export type LoginUserForAcceptInvitePageMutationHookResult = ReturnType<
    typeof useLoginUserForAcceptInvitePageMutation
>;
export type LoginUserForAcceptInvitePageMutationResult =
    Apollo.MutationResult<LoginUserForAcceptInvitePageMutation>;
export type LoginUserForAcceptInvitePageMutationOptions = Apollo.BaseMutationOptions<
    LoginUserForAcceptInvitePageMutation,
    LoginUserForAcceptInvitePageMutationVariables
>;
export const FetchCurrentUserAcceptInviteLoginDocument = gql`
    query FetchCurrentUserAcceptInviteLogin {
        currentUser {
            id
            name
        }
    }
`;

/**
 * __useFetchCurrentUserAcceptInviteLoginQuery__
 *
 * To run a query within a React component, call `useFetchCurrentUserAcceptInviteLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCurrentUserAcceptInviteLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCurrentUserAcceptInviteLoginQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCurrentUserAcceptInviteLoginQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchCurrentUserAcceptInviteLoginQuery,
        FetchCurrentUserAcceptInviteLoginQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchCurrentUserAcceptInviteLoginQuery,
        FetchCurrentUserAcceptInviteLoginQueryVariables
    >(FetchCurrentUserAcceptInviteLoginDocument, options);
}
export function useFetchCurrentUserAcceptInviteLoginLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchCurrentUserAcceptInviteLoginQuery,
        FetchCurrentUserAcceptInviteLoginQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchCurrentUserAcceptInviteLoginQuery,
        FetchCurrentUserAcceptInviteLoginQueryVariables
    >(FetchCurrentUserAcceptInviteLoginDocument, options);
}
export type FetchCurrentUserAcceptInviteLoginQueryHookResult = ReturnType<
    typeof useFetchCurrentUserAcceptInviteLoginQuery
>;
export type FetchCurrentUserAcceptInviteLoginLazyQueryHookResult = ReturnType<
    typeof useFetchCurrentUserAcceptInviteLoginLazyQuery
>;
export type FetchCurrentUserAcceptInviteLoginQueryResult = Apollo.QueryResult<
    FetchCurrentUserAcceptInviteLoginQuery,
    FetchCurrentUserAcceptInviteLoginQueryVariables
>;
export const AddNoteFromConsultantDocument = gql`
    mutation AddNoteFromConsultant($input: AddNoteFromConsultantInput) {
        addNoteFromConsultant(input: $input) {
            message
            patientId
            status
            success
        }
    }
`;
export type AddNoteFromConsultantMutationFn = Apollo.MutationFunction<
    AddNoteFromConsultantMutation,
    AddNoteFromConsultantMutationVariables
>;

/**
 * __useAddNoteFromConsultantMutation__
 *
 * To run a mutation, you first call `useAddNoteFromConsultantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNoteFromConsultantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNoteFromConsultantMutation, { data, loading, error }] = useAddNoteFromConsultantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddNoteFromConsultantMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AddNoteFromConsultantMutation,
        AddNoteFromConsultantMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        AddNoteFromConsultantMutation,
        AddNoteFromConsultantMutationVariables
    >(AddNoteFromConsultantDocument, options);
}
export type AddNoteFromConsultantMutationHookResult = ReturnType<
    typeof useAddNoteFromConsultantMutation
>;
export type AddNoteFromConsultantMutationResult =
    Apollo.MutationResult<AddNoteFromConsultantMutation>;
export type AddNoteFromConsultantMutationOptions = Apollo.BaseMutationOptions<
    AddNoteFromConsultantMutation,
    AddNoteFromConsultantMutationVariables
>;
export const AffiliateCarePatientsSubmittedClaimsV2Document = gql`
    query AffiliateCarePatientsSubmittedClaimsV2($input: GetClaimSubmittedGlobalSearchInput) {
        claimSubmittedGlobalSearch(input: $input) {
            hasMore
            hasPrevious
            limit
            orderBy
            results {
                affiliateId
                affiliateInvoice {
                    paymentStatus
                    paymentTotal
                    createdAt
                    id
                }
                patient {
                    lastName
                    firstName
                    id
                }
                ServiceFrom_1
                id
                createdAt
                service_type
            }
            skip
            total
        }
    }
`;

/**
 * __useAffiliateCarePatientsSubmittedClaimsV2Query__
 *
 * To run a query within a React component, call `useAffiliateCarePatientsSubmittedClaimsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useAffiliateCarePatientsSubmittedClaimsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAffiliateCarePatientsSubmittedClaimsV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAffiliateCarePatientsSubmittedClaimsV2Query(
    baseOptions?: Apollo.QueryHookOptions<
        AffiliateCarePatientsSubmittedClaimsV2Query,
        AffiliateCarePatientsSubmittedClaimsV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        AffiliateCarePatientsSubmittedClaimsV2Query,
        AffiliateCarePatientsSubmittedClaimsV2QueryVariables
    >(AffiliateCarePatientsSubmittedClaimsV2Document, options);
}
export function useAffiliateCarePatientsSubmittedClaimsV2LazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        AffiliateCarePatientsSubmittedClaimsV2Query,
        AffiliateCarePatientsSubmittedClaimsV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        AffiliateCarePatientsSubmittedClaimsV2Query,
        AffiliateCarePatientsSubmittedClaimsV2QueryVariables
    >(AffiliateCarePatientsSubmittedClaimsV2Document, options);
}
export type AffiliateCarePatientsSubmittedClaimsV2QueryHookResult = ReturnType<
    typeof useAffiliateCarePatientsSubmittedClaimsV2Query
>;
export type AffiliateCarePatientsSubmittedClaimsV2LazyQueryHookResult = ReturnType<
    typeof useAffiliateCarePatientsSubmittedClaimsV2LazyQuery
>;
export type AffiliateCarePatientsSubmittedClaimsV2QueryResult = Apollo.QueryResult<
    AffiliateCarePatientsSubmittedClaimsV2Query,
    AffiliateCarePatientsSubmittedClaimsV2QueryVariables
>;
export const ClaimsBuilderAffiliatePatientsDocument = gql`
    query ClaimsBuilderAffiliatePatients($input: GetVCAffiliatePatientsInput) {
        virtualCareAffiliatePatients(input: $input) {
            total
            results {
                fullName
                firstName
                email
                id
                lastName
                birthDate
            }
        }
    }
`;

/**
 * __useClaimsBuilderAffiliatePatientsQuery__
 *
 * To run a query within a React component, call `useClaimsBuilderAffiliatePatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimsBuilderAffiliatePatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimsBuilderAffiliatePatientsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClaimsBuilderAffiliatePatientsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ClaimsBuilderAffiliatePatientsQuery,
        ClaimsBuilderAffiliatePatientsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        ClaimsBuilderAffiliatePatientsQuery,
        ClaimsBuilderAffiliatePatientsQueryVariables
    >(ClaimsBuilderAffiliatePatientsDocument, options);
}
export function useClaimsBuilderAffiliatePatientsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ClaimsBuilderAffiliatePatientsQuery,
        ClaimsBuilderAffiliatePatientsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ClaimsBuilderAffiliatePatientsQuery,
        ClaimsBuilderAffiliatePatientsQueryVariables
    >(ClaimsBuilderAffiliatePatientsDocument, options);
}
export type ClaimsBuilderAffiliatePatientsQueryHookResult = ReturnType<
    typeof useClaimsBuilderAffiliatePatientsQuery
>;
export type ClaimsBuilderAffiliatePatientsLazyQueryHookResult = ReturnType<
    typeof useClaimsBuilderAffiliatePatientsLazyQuery
>;
export type ClaimsBuilderAffiliatePatientsQueryResult = Apollo.QueryResult<
    ClaimsBuilderAffiliatePatientsQuery,
    ClaimsBuilderAffiliatePatientsQueryVariables
>;
export const ClaimsBuilderCreateAffiliateClaimDocument = gql`
    mutation ClaimsBuilderCreateAffiliateClaim($input: AffiliateClaimFromBuilderInput) {
        createAffiliateClaimFromBuilder(input: $input) {
            error {
                data
                message
            }
            message
            status
            success
        }
    }
`;
export type ClaimsBuilderCreateAffiliateClaimMutationFn = Apollo.MutationFunction<
    ClaimsBuilderCreateAffiliateClaimMutation,
    ClaimsBuilderCreateAffiliateClaimMutationVariables
>;

/**
 * __useClaimsBuilderCreateAffiliateClaimMutation__
 *
 * To run a mutation, you first call `useClaimsBuilderCreateAffiliateClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimsBuilderCreateAffiliateClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimsBuilderCreateAffiliateClaimMutation, { data, loading, error }] = useClaimsBuilderCreateAffiliateClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClaimsBuilderCreateAffiliateClaimMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ClaimsBuilderCreateAffiliateClaimMutation,
        ClaimsBuilderCreateAffiliateClaimMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        ClaimsBuilderCreateAffiliateClaimMutation,
        ClaimsBuilderCreateAffiliateClaimMutationVariables
    >(ClaimsBuilderCreateAffiliateClaimDocument, options);
}
export type ClaimsBuilderCreateAffiliateClaimMutationHookResult = ReturnType<
    typeof useClaimsBuilderCreateAffiliateClaimMutation
>;
export type ClaimsBuilderCreateAffiliateClaimMutationResult =
    Apollo.MutationResult<ClaimsBuilderCreateAffiliateClaimMutation>;
export type ClaimsBuilderCreateAffiliateClaimMutationOptions = Apollo.BaseMutationOptions<
    ClaimsBuilderCreateAffiliateClaimMutation,
    ClaimsBuilderCreateAffiliateClaimMutationVariables
>;
export const AffiliateCareClaimsInvoicesV2Document = gql`
    query AffiliateCareClaimsInvoicesV2($input: SubmittedClaimQueryInput) {
        submittedClaimsV2(input: $input) {
            total
            results {
                id
                ServiceFrom_1
                createdAt
                batchId
                patient {
                    id
                    fullName
                    firstName
                    lastName
                }
                affiliateInvoice {
                    paymentTotal
                    id
                    createdAt
                    paymentStatus
                }
                service_type
            }
        }
    }
`;

/**
 * __useAffiliateCareClaimsInvoicesV2Query__
 *
 * To run a query within a React component, call `useAffiliateCareClaimsInvoicesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useAffiliateCareClaimsInvoicesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAffiliateCareClaimsInvoicesV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAffiliateCareClaimsInvoicesV2Query(
    baseOptions?: Apollo.QueryHookOptions<
        AffiliateCareClaimsInvoicesV2Query,
        AffiliateCareClaimsInvoicesV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        AffiliateCareClaimsInvoicesV2Query,
        AffiliateCareClaimsInvoicesV2QueryVariables
    >(AffiliateCareClaimsInvoicesV2Document, options);
}
export function useAffiliateCareClaimsInvoicesV2LazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        AffiliateCareClaimsInvoicesV2Query,
        AffiliateCareClaimsInvoicesV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        AffiliateCareClaimsInvoicesV2Query,
        AffiliateCareClaimsInvoicesV2QueryVariables
    >(AffiliateCareClaimsInvoicesV2Document, options);
}
export type AffiliateCareClaimsInvoicesV2QueryHookResult = ReturnType<
    typeof useAffiliateCareClaimsInvoicesV2Query
>;
export type AffiliateCareClaimsInvoicesV2LazyQueryHookResult = ReturnType<
    typeof useAffiliateCareClaimsInvoicesV2LazyQuery
>;
export type AffiliateCareClaimsInvoicesV2QueryResult = Apollo.QueryResult<
    AffiliateCareClaimsInvoicesV2Query,
    AffiliateCareClaimsInvoicesV2QueryVariables
>;
export const AffiliateCareInvoicesV2Document = gql`
    query AffiliateCareInvoicesV2($input: AffiliateInvoiceQueryInput) {
        affiliateInvoicesV2(input: $input) {
            hasMore
            hasPrevious
            limit
            orderBy
            total
            skip
            results {
                AffiliateClaims
                AffiliateId
                apiError
                billId
                createdAt
                id
                paymentStatus
                paymentTotal
                vendorId
                batchClaimId
                affiliate {
                    id
                    internalName
                }
            }
        }
    }
`;

/**
 * __useAffiliateCareInvoicesV2Query__
 *
 * To run a query within a React component, call `useAffiliateCareInvoicesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useAffiliateCareInvoicesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAffiliateCareInvoicesV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAffiliateCareInvoicesV2Query(
    baseOptions?: Apollo.QueryHookOptions<
        AffiliateCareInvoicesV2Query,
        AffiliateCareInvoicesV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AffiliateCareInvoicesV2Query, AffiliateCareInvoicesV2QueryVariables>(
        AffiliateCareInvoicesV2Document,
        options,
    );
}
export function useAffiliateCareInvoicesV2LazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        AffiliateCareInvoicesV2Query,
        AffiliateCareInvoicesV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AffiliateCareInvoicesV2Query, AffiliateCareInvoicesV2QueryVariables>(
        AffiliateCareInvoicesV2Document,
        options,
    );
}
export type AffiliateCareInvoicesV2QueryHookResult = ReturnType<
    typeof useAffiliateCareInvoicesV2Query
>;
export type AffiliateCareInvoicesV2LazyQueryHookResult = ReturnType<
    typeof useAffiliateCareInvoicesV2LazyQuery
>;
export type AffiliateCareInvoicesV2QueryResult = Apollo.QueryResult<
    AffiliateCareInvoicesV2Query,
    AffiliateCareInvoicesV2QueryVariables
>;
export const AffiliateInvoicesCreateBatchTaskDocument = gql`
    mutation AffiliateInvoicesCreateBatchTask($input: BatchTaskInput!) {
        createBatchTask(input: $input) {
            message
            status
            success
        }
    }
`;
export type AffiliateInvoicesCreateBatchTaskMutationFn = Apollo.MutationFunction<
    AffiliateInvoicesCreateBatchTaskMutation,
    AffiliateInvoicesCreateBatchTaskMutationVariables
>;

/**
 * __useAffiliateInvoicesCreateBatchTaskMutation__
 *
 * To run a mutation, you first call `useAffiliateInvoicesCreateBatchTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAffiliateInvoicesCreateBatchTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [affiliateInvoicesCreateBatchTaskMutation, { data, loading, error }] = useAffiliateInvoicesCreateBatchTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAffiliateInvoicesCreateBatchTaskMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AffiliateInvoicesCreateBatchTaskMutation,
        AffiliateInvoicesCreateBatchTaskMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        AffiliateInvoicesCreateBatchTaskMutation,
        AffiliateInvoicesCreateBatchTaskMutationVariables
    >(AffiliateInvoicesCreateBatchTaskDocument, options);
}
export type AffiliateInvoicesCreateBatchTaskMutationHookResult = ReturnType<
    typeof useAffiliateInvoicesCreateBatchTaskMutation
>;
export type AffiliateInvoicesCreateBatchTaskMutationResult =
    Apollo.MutationResult<AffiliateInvoicesCreateBatchTaskMutation>;
export type AffiliateInvoicesCreateBatchTaskMutationOptions = Apollo.BaseMutationOptions<
    AffiliateInvoicesCreateBatchTaskMutation,
    AffiliateInvoicesCreateBatchTaskMutationVariables
>;
export const AffiliateCarePatientDocument = gql`
    query AffiliateCarePatient($input: GetPatientInput!) {
        patient(input: $input) {
            email
            firstName
            fullName
            id
            lastName
            birthDate
            createdAt
            phoneNumber
        }
    }
`;

/**
 * __useAffiliateCarePatientQuery__
 *
 * To run a query within a React component, call `useAffiliateCarePatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useAffiliateCarePatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAffiliateCarePatientQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAffiliateCarePatientQuery(
    baseOptions: Apollo.QueryHookOptions<
        AffiliateCarePatientQuery,
        AffiliateCarePatientQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AffiliateCarePatientQuery, AffiliateCarePatientQueryVariables>(
        AffiliateCarePatientDocument,
        options,
    );
}
export function useAffiliateCarePatientLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        AffiliateCarePatientQuery,
        AffiliateCarePatientQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AffiliateCarePatientQuery, AffiliateCarePatientQueryVariables>(
        AffiliateCarePatientDocument,
        options,
    );
}
export type AffiliateCarePatientQueryHookResult = ReturnType<typeof useAffiliateCarePatientQuery>;
export type AffiliateCarePatientLazyQueryHookResult = ReturnType<
    typeof useAffiliateCarePatientLazyQuery
>;
export type AffiliateCarePatientQueryResult = Apollo.QueryResult<
    AffiliateCarePatientQuery,
    AffiliateCarePatientQueryVariables
>;
export const AffiliateCareSubmittedClaimsV2Document = gql`
    query AffiliateCareSubmittedClaimsV2($input: SubmittedClaimQueryInput) {
        submittedClaimsV2(input: $input) {
            results {
                status
                patientId
                id
                createdAt
                affiliateInvoiceId
                affiliateId
                ServiceFrom_1
                service_type
            }
            orderBy
            limit
            hasPrevious
            hasMore
            skip
            total
        }
    }
`;

/**
 * __useAffiliateCareSubmittedClaimsV2Query__
 *
 * To run a query within a React component, call `useAffiliateCareSubmittedClaimsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useAffiliateCareSubmittedClaimsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAffiliateCareSubmittedClaimsV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAffiliateCareSubmittedClaimsV2Query(
    baseOptions?: Apollo.QueryHookOptions<
        AffiliateCareSubmittedClaimsV2Query,
        AffiliateCareSubmittedClaimsV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        AffiliateCareSubmittedClaimsV2Query,
        AffiliateCareSubmittedClaimsV2QueryVariables
    >(AffiliateCareSubmittedClaimsV2Document, options);
}
export function useAffiliateCareSubmittedClaimsV2LazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        AffiliateCareSubmittedClaimsV2Query,
        AffiliateCareSubmittedClaimsV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        AffiliateCareSubmittedClaimsV2Query,
        AffiliateCareSubmittedClaimsV2QueryVariables
    >(AffiliateCareSubmittedClaimsV2Document, options);
}
export type AffiliateCareSubmittedClaimsV2QueryHookResult = ReturnType<
    typeof useAffiliateCareSubmittedClaimsV2Query
>;
export type AffiliateCareSubmittedClaimsV2LazyQueryHookResult = ReturnType<
    typeof useAffiliateCareSubmittedClaimsV2LazyQuery
>;
export type AffiliateCareSubmittedClaimsV2QueryResult = Apollo.QueryResult<
    AffiliateCareSubmittedClaimsV2Query,
    AffiliateCareSubmittedClaimsV2QueryVariables
>;
export const VirtualCareAffiliatePatientsDocument = gql`
    query VirtualCareAffiliatePatients($input: GetVCAffiliatePatientsInput) {
        virtualCareAffiliatePatients(input: $input) {
            total
            skip
            results {
                birthDate
                createdAt
                email
                firstName
                fullName
                id
                lastName
                phoneNumber
            }
        }
    }
`;

/**
 * __useVirtualCareAffiliatePatientsQuery__
 *
 * To run a query within a React component, call `useVirtualCareAffiliatePatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVirtualCareAffiliatePatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualCareAffiliatePatientsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVirtualCareAffiliatePatientsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        VirtualCareAffiliatePatientsQuery,
        VirtualCareAffiliatePatientsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        VirtualCareAffiliatePatientsQuery,
        VirtualCareAffiliatePatientsQueryVariables
    >(VirtualCareAffiliatePatientsDocument, options);
}
export function useVirtualCareAffiliatePatientsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        VirtualCareAffiliatePatientsQuery,
        VirtualCareAffiliatePatientsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        VirtualCareAffiliatePatientsQuery,
        VirtualCareAffiliatePatientsQueryVariables
    >(VirtualCareAffiliatePatientsDocument, options);
}
export type VirtualCareAffiliatePatientsQueryHookResult = ReturnType<
    typeof useVirtualCareAffiliatePatientsQuery
>;
export type VirtualCareAffiliatePatientsLazyQueryHookResult = ReturnType<
    typeof useVirtualCareAffiliatePatientsLazyQuery
>;
export type VirtualCareAffiliatePatientsQueryResult = Apollo.QueryResult<
    VirtualCareAffiliatePatientsQuery,
    VirtualCareAffiliatePatientsQueryVariables
>;
export const UploadClaimFileDocument = gql`
    mutation UploadClaimFile($input: AffiliateClaimInput!) {
        createAffiliateClaim(input: $input) {
            success
            error {
                data
                message
            }
            message
            claim {
                id
            }
        }
    }
`;
export type UploadClaimFileMutationFn = Apollo.MutationFunction<
    UploadClaimFileMutation,
    UploadClaimFileMutationVariables
>;

/**
 * __useUploadClaimFileMutation__
 *
 * To run a mutation, you first call `useUploadClaimFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadClaimFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadClaimFileMutation, { data, loading, error }] = useUploadClaimFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadClaimFileMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UploadClaimFileMutation,
        UploadClaimFileMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UploadClaimFileMutation, UploadClaimFileMutationVariables>(
        UploadClaimFileDocument,
        options,
    );
}
export type UploadClaimFileMutationHookResult = ReturnType<typeof useUploadClaimFileMutation>;
export type UploadClaimFileMutationResult = Apollo.MutationResult<UploadClaimFileMutation>;
export type UploadClaimFileMutationOptions = Apollo.BaseMutationOptions<
    UploadClaimFileMutation,
    UploadClaimFileMutationVariables
>;
export const UpdateAdvocateTaskForAdvocateTaskPageDocument = gql`
    mutation UpdateAdvocateTaskForAdvocateTaskPage($input: UpdateAdvocateTaskEncodedInput!) {
        updateAdvocateTaskEncoded(input: $input) {
            success
            message
            resourceUpdated {
                ...AdvocateTaskFragment
            }
        }
    }
    ${AdvocateTaskFragmentFragmentDoc}
`;
export type UpdateAdvocateTaskForAdvocateTaskPageMutationFn = Apollo.MutationFunction<
    UpdateAdvocateTaskForAdvocateTaskPageMutation,
    UpdateAdvocateTaskForAdvocateTaskPageMutationVariables
>;

/**
 * __useUpdateAdvocateTaskForAdvocateTaskPageMutation__
 *
 * To run a mutation, you first call `useUpdateAdvocateTaskForAdvocateTaskPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdvocateTaskForAdvocateTaskPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdvocateTaskForAdvocateTaskPageMutation, { data, loading, error }] = useUpdateAdvocateTaskForAdvocateTaskPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdvocateTaskForAdvocateTaskPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateAdvocateTaskForAdvocateTaskPageMutation,
        UpdateAdvocateTaskForAdvocateTaskPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateAdvocateTaskForAdvocateTaskPageMutation,
        UpdateAdvocateTaskForAdvocateTaskPageMutationVariables
    >(UpdateAdvocateTaskForAdvocateTaskPageDocument, options);
}
export type UpdateAdvocateTaskForAdvocateTaskPageMutationHookResult = ReturnType<
    typeof useUpdateAdvocateTaskForAdvocateTaskPageMutation
>;
export type UpdateAdvocateTaskForAdvocateTaskPageMutationResult =
    Apollo.MutationResult<UpdateAdvocateTaskForAdvocateTaskPageMutation>;
export type UpdateAdvocateTaskForAdvocateTaskPageMutationOptions = Apollo.BaseMutationOptions<
    UpdateAdvocateTaskForAdvocateTaskPageMutation,
    UpdateAdvocateTaskForAdvocateTaskPageMutationVariables
>;
export const FetchAdvocateTaskForAdvocateTaskPageDocument = gql`
    query FetchAdvocateTaskForAdvocateTaskPage($input: GetAdvocateTaskInput!) {
        advocateTask(input: $input) {
            ...AdvocateTaskFragment
            usersToAssign {
                id
                name
                role {
                    permissions {
                        target
                    }
                }
            }
        }
    }
    ${AdvocateTaskFragmentFragmentDoc}
`;

/**
 * __useFetchAdvocateTaskForAdvocateTaskPageQuery__
 *
 * To run a query within a React component, call `useFetchAdvocateTaskForAdvocateTaskPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAdvocateTaskForAdvocateTaskPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAdvocateTaskForAdvocateTaskPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchAdvocateTaskForAdvocateTaskPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchAdvocateTaskForAdvocateTaskPageQuery,
        FetchAdvocateTaskForAdvocateTaskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchAdvocateTaskForAdvocateTaskPageQuery,
        FetchAdvocateTaskForAdvocateTaskPageQueryVariables
    >(FetchAdvocateTaskForAdvocateTaskPageDocument, options);
}
export function useFetchAdvocateTaskForAdvocateTaskPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchAdvocateTaskForAdvocateTaskPageQuery,
        FetchAdvocateTaskForAdvocateTaskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchAdvocateTaskForAdvocateTaskPageQuery,
        FetchAdvocateTaskForAdvocateTaskPageQueryVariables
    >(FetchAdvocateTaskForAdvocateTaskPageDocument, options);
}
export type FetchAdvocateTaskForAdvocateTaskPageQueryHookResult = ReturnType<
    typeof useFetchAdvocateTaskForAdvocateTaskPageQuery
>;
export type FetchAdvocateTaskForAdvocateTaskPageLazyQueryHookResult = ReturnType<
    typeof useFetchAdvocateTaskForAdvocateTaskPageLazyQuery
>;
export type FetchAdvocateTaskForAdvocateTaskPageQueryResult = Apollo.QueryResult<
    FetchAdvocateTaskForAdvocateTaskPageQuery,
    FetchAdvocateTaskForAdvocateTaskPageQueryVariables
>;
export const FetchCurrentUserForAdvocateTaskPageDocument = gql`
    query FetchCurrentUserForAdvocateTaskPage {
        currentUser {
            id
            currentOrg {
                brandingName
            }
        }
    }
`;

/**
 * __useFetchCurrentUserForAdvocateTaskPageQuery__
 *
 * To run a query within a React component, call `useFetchCurrentUserForAdvocateTaskPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCurrentUserForAdvocateTaskPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCurrentUserForAdvocateTaskPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCurrentUserForAdvocateTaskPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchCurrentUserForAdvocateTaskPageQuery,
        FetchCurrentUserForAdvocateTaskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchCurrentUserForAdvocateTaskPageQuery,
        FetchCurrentUserForAdvocateTaskPageQueryVariables
    >(FetchCurrentUserForAdvocateTaskPageDocument, options);
}
export function useFetchCurrentUserForAdvocateTaskPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchCurrentUserForAdvocateTaskPageQuery,
        FetchCurrentUserForAdvocateTaskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchCurrentUserForAdvocateTaskPageQuery,
        FetchCurrentUserForAdvocateTaskPageQueryVariables
    >(FetchCurrentUserForAdvocateTaskPageDocument, options);
}
export type FetchCurrentUserForAdvocateTaskPageQueryHookResult = ReturnType<
    typeof useFetchCurrentUserForAdvocateTaskPageQuery
>;
export type FetchCurrentUserForAdvocateTaskPageLazyQueryHookResult = ReturnType<
    typeof useFetchCurrentUserForAdvocateTaskPageLazyQuery
>;
export type FetchCurrentUserForAdvocateTaskPageQueryResult = Apollo.QueryResult<
    FetchCurrentUserForAdvocateTaskPageQuery,
    FetchCurrentUserForAdvocateTaskPageQueryVariables
>;
export const FetchReferenceLinksForAdvocateTaskPageDocument = gql`
    query FetchReferenceLinksForAdvocateTaskPage($input: GetReferenceLinksForPageInput!) {
        getReferenceLinksForPage(input: $input) {
            category
            data {
                label
                url
            }
        }
    }
`;

/**
 * __useFetchReferenceLinksForAdvocateTaskPageQuery__
 *
 * To run a query within a React component, call `useFetchReferenceLinksForAdvocateTaskPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchReferenceLinksForAdvocateTaskPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchReferenceLinksForAdvocateTaskPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchReferenceLinksForAdvocateTaskPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchReferenceLinksForAdvocateTaskPageQuery,
        FetchReferenceLinksForAdvocateTaskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchReferenceLinksForAdvocateTaskPageQuery,
        FetchReferenceLinksForAdvocateTaskPageQueryVariables
    >(FetchReferenceLinksForAdvocateTaskPageDocument, options);
}
export function useFetchReferenceLinksForAdvocateTaskPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchReferenceLinksForAdvocateTaskPageQuery,
        FetchReferenceLinksForAdvocateTaskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchReferenceLinksForAdvocateTaskPageQuery,
        FetchReferenceLinksForAdvocateTaskPageQueryVariables
    >(FetchReferenceLinksForAdvocateTaskPageDocument, options);
}
export type FetchReferenceLinksForAdvocateTaskPageQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForAdvocateTaskPageQuery
>;
export type FetchReferenceLinksForAdvocateTaskPageLazyQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForAdvocateTaskPageLazyQuery
>;
export type FetchReferenceLinksForAdvocateTaskPageQueryResult = Apollo.QueryResult<
    FetchReferenceLinksForAdvocateTaskPageQuery,
    FetchReferenceLinksForAdvocateTaskPageQueryVariables
>;
export const DeletePatientActionForAdvocateTaskPageDocument = gql`
    mutation DeletePatientActionForAdvocateTaskPage($input: DeletePatientActionInput!) {
        deletePatientAction(input: $input) {
            success
            message
            status
            id
        }
    }
`;
export type DeletePatientActionForAdvocateTaskPageMutationFn = Apollo.MutationFunction<
    DeletePatientActionForAdvocateTaskPageMutation,
    DeletePatientActionForAdvocateTaskPageMutationVariables
>;

/**
 * __useDeletePatientActionForAdvocateTaskPageMutation__
 *
 * To run a mutation, you first call `useDeletePatientActionForAdvocateTaskPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientActionForAdvocateTaskPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientActionForAdvocateTaskPageMutation, { data, loading, error }] = useDeletePatientActionForAdvocateTaskPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePatientActionForAdvocateTaskPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeletePatientActionForAdvocateTaskPageMutation,
        DeletePatientActionForAdvocateTaskPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeletePatientActionForAdvocateTaskPageMutation,
        DeletePatientActionForAdvocateTaskPageMutationVariables
    >(DeletePatientActionForAdvocateTaskPageDocument, options);
}
export type DeletePatientActionForAdvocateTaskPageMutationHookResult = ReturnType<
    typeof useDeletePatientActionForAdvocateTaskPageMutation
>;
export type DeletePatientActionForAdvocateTaskPageMutationResult =
    Apollo.MutationResult<DeletePatientActionForAdvocateTaskPageMutation>;
export type DeletePatientActionForAdvocateTaskPageMutationOptions = Apollo.BaseMutationOptions<
    DeletePatientActionForAdvocateTaskPageMutation,
    DeletePatientActionForAdvocateTaskPageMutationVariables
>;
export const DeletePatientActionByStepForAdvocateTaskDocument = gql`
    mutation DeletePatientActionByStepForAdvocateTask($input: DeletePatientActionByStepInput!) {
        deletePatientActionByStep(input: $input) {
            success
            message
            resourceDeleted {
                typeId
            }
        }
    }
`;
export type DeletePatientActionByStepForAdvocateTaskMutationFn = Apollo.MutationFunction<
    DeletePatientActionByStepForAdvocateTaskMutation,
    DeletePatientActionByStepForAdvocateTaskMutationVariables
>;

/**
 * __useDeletePatientActionByStepForAdvocateTaskMutation__
 *
 * To run a mutation, you first call `useDeletePatientActionByStepForAdvocateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientActionByStepForAdvocateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientActionByStepForAdvocateTaskMutation, { data, loading, error }] = useDeletePatientActionByStepForAdvocateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePatientActionByStepForAdvocateTaskMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeletePatientActionByStepForAdvocateTaskMutation,
        DeletePatientActionByStepForAdvocateTaskMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeletePatientActionByStepForAdvocateTaskMutation,
        DeletePatientActionByStepForAdvocateTaskMutationVariables
    >(DeletePatientActionByStepForAdvocateTaskDocument, options);
}
export type DeletePatientActionByStepForAdvocateTaskMutationHookResult = ReturnType<
    typeof useDeletePatientActionByStepForAdvocateTaskMutation
>;
export type DeletePatientActionByStepForAdvocateTaskMutationResult =
    Apollo.MutationResult<DeletePatientActionByStepForAdvocateTaskMutation>;
export type DeletePatientActionByStepForAdvocateTaskMutationOptions = Apollo.BaseMutationOptions<
    DeletePatientActionByStepForAdvocateTaskMutation,
    DeletePatientActionByStepForAdvocateTaskMutationVariables
>;
export const UpdatePatientActionForAdvocateTaskPageDocument = gql`
    mutation UpdatePatientActionForAdvocateTaskPage($input: UpdatePatientActionInput!) {
        updatePatientAction(input: $input) {
            status
            message
            success
            resourceUpdated {
                id
                updatedAt
                description
                taskId
                typeId
                patientId
                source
                completedAt
            }
        }
    }
`;
export type UpdatePatientActionForAdvocateTaskPageMutationFn = Apollo.MutationFunction<
    UpdatePatientActionForAdvocateTaskPageMutation,
    UpdatePatientActionForAdvocateTaskPageMutationVariables
>;

/**
 * __useUpdatePatientActionForAdvocateTaskPageMutation__
 *
 * To run a mutation, you first call `useUpdatePatientActionForAdvocateTaskPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientActionForAdvocateTaskPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientActionForAdvocateTaskPageMutation, { data, loading, error }] = useUpdatePatientActionForAdvocateTaskPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientActionForAdvocateTaskPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePatientActionForAdvocateTaskPageMutation,
        UpdatePatientActionForAdvocateTaskPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdatePatientActionForAdvocateTaskPageMutation,
        UpdatePatientActionForAdvocateTaskPageMutationVariables
    >(UpdatePatientActionForAdvocateTaskPageDocument, options);
}
export type UpdatePatientActionForAdvocateTaskPageMutationHookResult = ReturnType<
    typeof useUpdatePatientActionForAdvocateTaskPageMutation
>;
export type UpdatePatientActionForAdvocateTaskPageMutationResult =
    Apollo.MutationResult<UpdatePatientActionForAdvocateTaskPageMutation>;
export type UpdatePatientActionForAdvocateTaskPageMutationOptions = Apollo.BaseMutationOptions<
    UpdatePatientActionForAdvocateTaskPageMutation,
    UpdatePatientActionForAdvocateTaskPageMutationVariables
>;
export const CreatePatientActionForAdvocateTaskPageDocument = gql`
    mutation CreatePatientActionForAdvocateTaskPage($input: PatientActionInput!) {
        createPatientAction(input: $input) {
            status
            message
            success
            resourceCreated {
                updatedAt
                completedAt
                description
                taskId
                typeId
            }
        }
    }
`;
export type CreatePatientActionForAdvocateTaskPageMutationFn = Apollo.MutationFunction<
    CreatePatientActionForAdvocateTaskPageMutation,
    CreatePatientActionForAdvocateTaskPageMutationVariables
>;

/**
 * __useCreatePatientActionForAdvocateTaskPageMutation__
 *
 * To run a mutation, you first call `useCreatePatientActionForAdvocateTaskPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientActionForAdvocateTaskPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientActionForAdvocateTaskPageMutation, { data, loading, error }] = useCreatePatientActionForAdvocateTaskPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatientActionForAdvocateTaskPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreatePatientActionForAdvocateTaskPageMutation,
        CreatePatientActionForAdvocateTaskPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreatePatientActionForAdvocateTaskPageMutation,
        CreatePatientActionForAdvocateTaskPageMutationVariables
    >(CreatePatientActionForAdvocateTaskPageDocument, options);
}
export type CreatePatientActionForAdvocateTaskPageMutationHookResult = ReturnType<
    typeof useCreatePatientActionForAdvocateTaskPageMutation
>;
export type CreatePatientActionForAdvocateTaskPageMutationResult =
    Apollo.MutationResult<CreatePatientActionForAdvocateTaskPageMutation>;
export type CreatePatientActionForAdvocateTaskPageMutationOptions = Apollo.BaseMutationOptions<
    CreatePatientActionForAdvocateTaskPageMutation,
    CreatePatientActionForAdvocateTaskPageMutationVariables
>;
export const CreatePatientActionsForAdvocateTaskPageDocument = gql`
    mutation CreatePatientActionsForAdvocateTaskPage($input: PatientActionsInput!) {
        createPatientActions(input: $input) {
            status
            message
            success
            resourcesCreated {
                updatedAt
                completedAt
                description
                taskId
                typeId
            }
        }
    }
`;
export type CreatePatientActionsForAdvocateTaskPageMutationFn = Apollo.MutationFunction<
    CreatePatientActionsForAdvocateTaskPageMutation,
    CreatePatientActionsForAdvocateTaskPageMutationVariables
>;

/**
 * __useCreatePatientActionsForAdvocateTaskPageMutation__
 *
 * To run a mutation, you first call `useCreatePatientActionsForAdvocateTaskPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientActionsForAdvocateTaskPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientActionsForAdvocateTaskPageMutation, { data, loading, error }] = useCreatePatientActionsForAdvocateTaskPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatientActionsForAdvocateTaskPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreatePatientActionsForAdvocateTaskPageMutation,
        CreatePatientActionsForAdvocateTaskPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreatePatientActionsForAdvocateTaskPageMutation,
        CreatePatientActionsForAdvocateTaskPageMutationVariables
    >(CreatePatientActionsForAdvocateTaskPageDocument, options);
}
export type CreatePatientActionsForAdvocateTaskPageMutationHookResult = ReturnType<
    typeof useCreatePatientActionsForAdvocateTaskPageMutation
>;
export type CreatePatientActionsForAdvocateTaskPageMutationResult =
    Apollo.MutationResult<CreatePatientActionsForAdvocateTaskPageMutation>;
export type CreatePatientActionsForAdvocateTaskPageMutationOptions = Apollo.BaseMutationOptions<
    CreatePatientActionsForAdvocateTaskPageMutation,
    CreatePatientActionsForAdvocateTaskPageMutationVariables
>;
export const PatientActionByIdDocument = gql`
    query PatientActionById($input: GetPatientActionInput!) {
        patientAction(input: $input) {
            id
            updatedAt
            description
            taskId
            typeId
            completedAt
        }
    }
`;

/**
 * __usePatientActionByIdQuery__
 *
 * To run a query within a React component, call `usePatientActionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientActionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientActionByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatientActionByIdQuery(
    baseOptions: Apollo.QueryHookOptions<PatientActionByIdQuery, PatientActionByIdQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PatientActionByIdQuery, PatientActionByIdQueryVariables>(
        PatientActionByIdDocument,
        options,
    );
}
export function usePatientActionByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PatientActionByIdQuery,
        PatientActionByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PatientActionByIdQuery, PatientActionByIdQueryVariables>(
        PatientActionByIdDocument,
        options,
    );
}
export type PatientActionByIdQueryHookResult = ReturnType<typeof usePatientActionByIdQuery>;
export type PatientActionByIdLazyQueryHookResult = ReturnType<typeof usePatientActionByIdLazyQuery>;
export type PatientActionByIdQueryResult = Apollo.QueryResult<
    PatientActionByIdQuery,
    PatientActionByIdQueryVariables
>;
export const PatientActionPageListDocument = gql`
    query PatientActionPageList {
        patientActionsV2 {
            results {
                id
                updatedAt
                description
                taskId
                typeId
                completedAt
            }
        }
    }
`;

/**
 * __usePatientActionPageListQuery__
 *
 * To run a query within a React component, call `usePatientActionPageListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientActionPageListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientActionPageListQuery({
 *   variables: {
 *   },
 * });
 */
export function usePatientActionPageListQuery(
    baseOptions?: Apollo.QueryHookOptions<
        PatientActionPageListQuery,
        PatientActionPageListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PatientActionPageListQuery, PatientActionPageListQueryVariables>(
        PatientActionPageListDocument,
        options,
    );
}
export function usePatientActionPageListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PatientActionPageListQuery,
        PatientActionPageListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PatientActionPageListQuery, PatientActionPageListQueryVariables>(
        PatientActionPageListDocument,
        options,
    );
}
export type PatientActionPageListQueryHookResult = ReturnType<typeof usePatientActionPageListQuery>;
export type PatientActionPageListLazyQueryHookResult = ReturnType<
    typeof usePatientActionPageListLazyQuery
>;
export type PatientActionPageListQueryResult = Apollo.QueryResult<
    PatientActionPageListQuery,
    PatientActionPageListQueryVariables
>;
export const UpdateChatRoomForAdvocateTaskPageDocument = gql`
    mutation UpdateChatRoomForAdvocateTaskPage($input: UpdateChatRoomInput!) {
        updateChatRoom(input: $input) {
            success
            message
        }
    }
`;
export type UpdateChatRoomForAdvocateTaskPageMutationFn = Apollo.MutationFunction<
    UpdateChatRoomForAdvocateTaskPageMutation,
    UpdateChatRoomForAdvocateTaskPageMutationVariables
>;

/**
 * __useUpdateChatRoomForAdvocateTaskPageMutation__
 *
 * To run a mutation, you first call `useUpdateChatRoomForAdvocateTaskPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatRoomForAdvocateTaskPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatRoomForAdvocateTaskPageMutation, { data, loading, error }] = useUpdateChatRoomForAdvocateTaskPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChatRoomForAdvocateTaskPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateChatRoomForAdvocateTaskPageMutation,
        UpdateChatRoomForAdvocateTaskPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateChatRoomForAdvocateTaskPageMutation,
        UpdateChatRoomForAdvocateTaskPageMutationVariables
    >(UpdateChatRoomForAdvocateTaskPageDocument, options);
}
export type UpdateChatRoomForAdvocateTaskPageMutationHookResult = ReturnType<
    typeof useUpdateChatRoomForAdvocateTaskPageMutation
>;
export type UpdateChatRoomForAdvocateTaskPageMutationResult =
    Apollo.MutationResult<UpdateChatRoomForAdvocateTaskPageMutation>;
export type UpdateChatRoomForAdvocateTaskPageMutationOptions = Apollo.BaseMutationOptions<
    UpdateChatRoomForAdvocateTaskPageMutation,
    UpdateChatRoomForAdvocateTaskPageMutationVariables
>;
export const CreateChatRoomForAdvocateTaskPageDocument = gql`
    mutation CreateChatRoomForAdvocateTaskPage($input: ChatRoomInput!) {
        createChatRoom(input: $input) {
            resourceCreated {
                id
            }
        }
    }
`;
export type CreateChatRoomForAdvocateTaskPageMutationFn = Apollo.MutationFunction<
    CreateChatRoomForAdvocateTaskPageMutation,
    CreateChatRoomForAdvocateTaskPageMutationVariables
>;

/**
 * __useCreateChatRoomForAdvocateTaskPageMutation__
 *
 * To run a mutation, you first call `useCreateChatRoomForAdvocateTaskPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatRoomForAdvocateTaskPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatRoomForAdvocateTaskPageMutation, { data, loading, error }] = useCreateChatRoomForAdvocateTaskPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChatRoomForAdvocateTaskPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateChatRoomForAdvocateTaskPageMutation,
        CreateChatRoomForAdvocateTaskPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateChatRoomForAdvocateTaskPageMutation,
        CreateChatRoomForAdvocateTaskPageMutationVariables
    >(CreateChatRoomForAdvocateTaskPageDocument, options);
}
export type CreateChatRoomForAdvocateTaskPageMutationHookResult = ReturnType<
    typeof useCreateChatRoomForAdvocateTaskPageMutation
>;
export type CreateChatRoomForAdvocateTaskPageMutationResult =
    Apollo.MutationResult<CreateChatRoomForAdvocateTaskPageMutation>;
export type CreateChatRoomForAdvocateTaskPageMutationOptions = Apollo.BaseMutationOptions<
    CreateChatRoomForAdvocateTaskPageMutation,
    CreateChatRoomForAdvocateTaskPageMutationVariables
>;
export const CreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageDocument = gql`
    mutation CreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePage(
        $input: AdvocateTaskTemplateInput!
    ) {
        createAdvocateTaskTemplateEncoded(input: $input) {
            success
            message
            resourceCreated {
                ...AdvocateTaskTemplateFragment
            }
        }
    }
    ${AdvocateTaskTemplateFragmentFragmentDoc}
`;
export type CreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutationFn =
    Apollo.MutationFunction<
        CreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation,
        CreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutationVariables
    >;

/**
 * __useCreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation__
 *
 * To run a mutation, you first call `useCreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation, { data, loading, error }] = useCreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation,
        CreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation,
        CreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutationVariables
    >(CreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageDocument, options);
}
export type CreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutationHookResult = ReturnType<
    typeof useCreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation
>;
export type CreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutationResult =
    Apollo.MutationResult<CreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation>;
export type CreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutationOptions =
    Apollo.BaseMutationOptions<
        CreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation,
        CreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutationVariables
    >;
export const CreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageDocument = gql`
    mutation CreateAdvocateTaskTypeForNewAdvocateTaskTemplatePage($input: AdvocateTaskTypeInput!) {
        createAdvocateTaskType(input: $input) {
            success
            message
            resourceCreated {
                ...AdvocateTaskTypeFragment
            }
        }
    }
    ${AdvocateTaskTypeFragmentFragmentDoc}
`;
export type CreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutationFn =
    Apollo.MutationFunction<
        CreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutation,
        CreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutationVariables
    >;

/**
 * __useCreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutation__
 *
 * To run a mutation, you first call `useCreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutation, { data, loading, error }] = useCreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutation,
        CreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutation,
        CreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutationVariables
    >(CreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageDocument, options);
}
export type CreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutationHookResult = ReturnType<
    typeof useCreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutation
>;
export type CreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutationResult =
    Apollo.MutationResult<CreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutation>;
export type CreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutationOptions =
    Apollo.BaseMutationOptions<
        CreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutation,
        CreateAdvocateTaskTypeForNewAdvocateTaskTemplatePageMutationVariables
    >;
export const UpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageDocument = gql`
    mutation UpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePage(
        $input: UpdateAdvocateTaskTemplateEncodedInput!
    ) {
        updateAdvocateTaskTemplateEncoded(input: $input) {
            success
            message
            resourceUpdated {
                ...AdvocateTaskTemplateFragment
            }
        }
    }
    ${AdvocateTaskTemplateFragmentFragmentDoc}
`;
export type UpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutationFn =
    Apollo.MutationFunction<
        UpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation,
        UpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutationVariables
    >;

/**
 * __useUpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation__
 *
 * To run a mutation, you first call `useUpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation, { data, loading, error }] = useUpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation,
        UpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation,
        UpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutationVariables
    >(UpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageDocument, options);
}
export type UpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutationHookResult = ReturnType<
    typeof useUpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation
>;
export type UpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutationResult =
    Apollo.MutationResult<UpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation>;
export type UpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutationOptions =
    Apollo.BaseMutationOptions<
        UpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation,
        UpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutationVariables
    >;
export const AdvocateTaskTemplateForAdvocateTaskTemplatePageDocument = gql`
    query AdvocateTaskTemplateForAdvocateTaskTemplatePage($input: GetAdvocateTaskTemplateInput!) {
        advocateTaskTemplate(input: $input) {
            ...AdvocateTaskTemplateFragment
        }
    }
    ${AdvocateTaskTemplateFragmentFragmentDoc}
`;

/**
 * __useAdvocateTaskTemplateForAdvocateTaskTemplatePageQuery__
 *
 * To run a query within a React component, call `useAdvocateTaskTemplateForAdvocateTaskTemplatePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvocateTaskTemplateForAdvocateTaskTemplatePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvocateTaskTemplateForAdvocateTaskTemplatePageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdvocateTaskTemplateForAdvocateTaskTemplatePageQuery(
    baseOptions: Apollo.QueryHookOptions<
        AdvocateTaskTemplateForAdvocateTaskTemplatePageQuery,
        AdvocateTaskTemplateForAdvocateTaskTemplatePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        AdvocateTaskTemplateForAdvocateTaskTemplatePageQuery,
        AdvocateTaskTemplateForAdvocateTaskTemplatePageQueryVariables
    >(AdvocateTaskTemplateForAdvocateTaskTemplatePageDocument, options);
}
export function useAdvocateTaskTemplateForAdvocateTaskTemplatePageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        AdvocateTaskTemplateForAdvocateTaskTemplatePageQuery,
        AdvocateTaskTemplateForAdvocateTaskTemplatePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        AdvocateTaskTemplateForAdvocateTaskTemplatePageQuery,
        AdvocateTaskTemplateForAdvocateTaskTemplatePageQueryVariables
    >(AdvocateTaskTemplateForAdvocateTaskTemplatePageDocument, options);
}
export type AdvocateTaskTemplateForAdvocateTaskTemplatePageQueryHookResult = ReturnType<
    typeof useAdvocateTaskTemplateForAdvocateTaskTemplatePageQuery
>;
export type AdvocateTaskTemplateForAdvocateTaskTemplatePageLazyQueryHookResult = ReturnType<
    typeof useAdvocateTaskTemplateForAdvocateTaskTemplatePageLazyQuery
>;
export type AdvocateTaskTemplateForAdvocateTaskTemplatePageQueryResult = Apollo.QueryResult<
    AdvocateTaskTemplateForAdvocateTaskTemplatePageQuery,
    AdvocateTaskTemplateForAdvocateTaskTemplatePageQueryVariables
>;
export const FetchAdvocateTaskTypesForAdvocateTaskTemplatePageDocument = gql`
    query FetchAdvocateTaskTypesForAdvocateTaskTemplatePage {
        advocateTaskTypesV2 {
            results {
                label
                createdAt
                updatedAt
                id
            }
        }
    }
`;

/**
 * __useFetchAdvocateTaskTypesForAdvocateTaskTemplatePageQuery__
 *
 * To run a query within a React component, call `useFetchAdvocateTaskTypesForAdvocateTaskTemplatePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAdvocateTaskTypesForAdvocateTaskTemplatePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAdvocateTaskTypesForAdvocateTaskTemplatePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAdvocateTaskTypesForAdvocateTaskTemplatePageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchAdvocateTaskTypesForAdvocateTaskTemplatePageQuery,
        FetchAdvocateTaskTypesForAdvocateTaskTemplatePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchAdvocateTaskTypesForAdvocateTaskTemplatePageQuery,
        FetchAdvocateTaskTypesForAdvocateTaskTemplatePageQueryVariables
    >(FetchAdvocateTaskTypesForAdvocateTaskTemplatePageDocument, options);
}
export function useFetchAdvocateTaskTypesForAdvocateTaskTemplatePageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchAdvocateTaskTypesForAdvocateTaskTemplatePageQuery,
        FetchAdvocateTaskTypesForAdvocateTaskTemplatePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchAdvocateTaskTypesForAdvocateTaskTemplatePageQuery,
        FetchAdvocateTaskTypesForAdvocateTaskTemplatePageQueryVariables
    >(FetchAdvocateTaskTypesForAdvocateTaskTemplatePageDocument, options);
}
export type FetchAdvocateTaskTypesForAdvocateTaskTemplatePageQueryHookResult = ReturnType<
    typeof useFetchAdvocateTaskTypesForAdvocateTaskTemplatePageQuery
>;
export type FetchAdvocateTaskTypesForAdvocateTaskTemplatePageLazyQueryHookResult = ReturnType<
    typeof useFetchAdvocateTaskTypesForAdvocateTaskTemplatePageLazyQuery
>;
export type FetchAdvocateTaskTypesForAdvocateTaskTemplatePageQueryResult = Apollo.QueryResult<
    FetchAdvocateTaskTypesForAdvocateTaskTemplatePageQuery,
    FetchAdvocateTaskTypesForAdvocateTaskTemplatePageQueryVariables
>;
export const FetchTimelinesForAdvocateTaskTemplatePageDocument = gql`
    query FetchTimelinesForAdvocateTaskTemplatePage {
        whatsNewTimelinesV2 {
            results {
                label {
                    en
                }
                type
                id
            }
        }
    }
`;

/**
 * __useFetchTimelinesForAdvocateTaskTemplatePageQuery__
 *
 * To run a query within a React component, call `useFetchTimelinesForAdvocateTaskTemplatePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTimelinesForAdvocateTaskTemplatePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTimelinesForAdvocateTaskTemplatePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchTimelinesForAdvocateTaskTemplatePageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchTimelinesForAdvocateTaskTemplatePageQuery,
        FetchTimelinesForAdvocateTaskTemplatePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchTimelinesForAdvocateTaskTemplatePageQuery,
        FetchTimelinesForAdvocateTaskTemplatePageQueryVariables
    >(FetchTimelinesForAdvocateTaskTemplatePageDocument, options);
}
export function useFetchTimelinesForAdvocateTaskTemplatePageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchTimelinesForAdvocateTaskTemplatePageQuery,
        FetchTimelinesForAdvocateTaskTemplatePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchTimelinesForAdvocateTaskTemplatePageQuery,
        FetchTimelinesForAdvocateTaskTemplatePageQueryVariables
    >(FetchTimelinesForAdvocateTaskTemplatePageDocument, options);
}
export type FetchTimelinesForAdvocateTaskTemplatePageQueryHookResult = ReturnType<
    typeof useFetchTimelinesForAdvocateTaskTemplatePageQuery
>;
export type FetchTimelinesForAdvocateTaskTemplatePageLazyQueryHookResult = ReturnType<
    typeof useFetchTimelinesForAdvocateTaskTemplatePageLazyQuery
>;
export type FetchTimelinesForAdvocateTaskTemplatePageQueryResult = Apollo.QueryResult<
    FetchTimelinesForAdvocateTaskTemplatePageQuery,
    FetchTimelinesForAdvocateTaskTemplatePageQueryVariables
>;
export const FetchTagsForAdvocateTaskTemplatePageDocument = gql`
    query FetchTagsForAdvocateTaskTemplatePage {
        tags {
            id
            name
        }
    }
`;

/**
 * __useFetchTagsForAdvocateTaskTemplatePageQuery__
 *
 * To run a query within a React component, call `useFetchTagsForAdvocateTaskTemplatePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTagsForAdvocateTaskTemplatePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTagsForAdvocateTaskTemplatePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchTagsForAdvocateTaskTemplatePageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchTagsForAdvocateTaskTemplatePageQuery,
        FetchTagsForAdvocateTaskTemplatePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchTagsForAdvocateTaskTemplatePageQuery,
        FetchTagsForAdvocateTaskTemplatePageQueryVariables
    >(FetchTagsForAdvocateTaskTemplatePageDocument, options);
}
export function useFetchTagsForAdvocateTaskTemplatePageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchTagsForAdvocateTaskTemplatePageQuery,
        FetchTagsForAdvocateTaskTemplatePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchTagsForAdvocateTaskTemplatePageQuery,
        FetchTagsForAdvocateTaskTemplatePageQueryVariables
    >(FetchTagsForAdvocateTaskTemplatePageDocument, options);
}
export type FetchTagsForAdvocateTaskTemplatePageQueryHookResult = ReturnType<
    typeof useFetchTagsForAdvocateTaskTemplatePageQuery
>;
export type FetchTagsForAdvocateTaskTemplatePageLazyQueryHookResult = ReturnType<
    typeof useFetchTagsForAdvocateTaskTemplatePageLazyQuery
>;
export type FetchTagsForAdvocateTaskTemplatePageQueryResult = Apollo.QueryResult<
    FetchTagsForAdvocateTaskTemplatePageQuery,
    FetchTagsForAdvocateTaskTemplatePageQueryVariables
>;
export const FetchAmsAppsForAdvocateTaskTemplatePageDocument = gql`
    query FetchAmsAppsForAdvocateTaskTemplatePage {
        amsApps {
            id
            bundleId
        }
    }
`;

/**
 * __useFetchAmsAppsForAdvocateTaskTemplatePageQuery__
 *
 * To run a query within a React component, call `useFetchAmsAppsForAdvocateTaskTemplatePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAmsAppsForAdvocateTaskTemplatePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAmsAppsForAdvocateTaskTemplatePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAmsAppsForAdvocateTaskTemplatePageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchAmsAppsForAdvocateTaskTemplatePageQuery,
        FetchAmsAppsForAdvocateTaskTemplatePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchAmsAppsForAdvocateTaskTemplatePageQuery,
        FetchAmsAppsForAdvocateTaskTemplatePageQueryVariables
    >(FetchAmsAppsForAdvocateTaskTemplatePageDocument, options);
}
export function useFetchAmsAppsForAdvocateTaskTemplatePageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchAmsAppsForAdvocateTaskTemplatePageQuery,
        FetchAmsAppsForAdvocateTaskTemplatePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchAmsAppsForAdvocateTaskTemplatePageQuery,
        FetchAmsAppsForAdvocateTaskTemplatePageQueryVariables
    >(FetchAmsAppsForAdvocateTaskTemplatePageDocument, options);
}
export type FetchAmsAppsForAdvocateTaskTemplatePageQueryHookResult = ReturnType<
    typeof useFetchAmsAppsForAdvocateTaskTemplatePageQuery
>;
export type FetchAmsAppsForAdvocateTaskTemplatePageLazyQueryHookResult = ReturnType<
    typeof useFetchAmsAppsForAdvocateTaskTemplatePageLazyQuery
>;
export type FetchAmsAppsForAdvocateTaskTemplatePageQueryResult = Apollo.QueryResult<
    FetchAmsAppsForAdvocateTaskTemplatePageQuery,
    FetchAmsAppsForAdvocateTaskTemplatePageQueryVariables
>;
export const FetchSlasV2ForAdvocateTaskTemplatePageDocument = gql`
    query FetchSlasV2ForAdvocateTaskTemplatePage($input: SlaQueryInput) {
        slasV2(input: $input) {
            results {
                id
                name
            }
        }
    }
`;

/**
 * __useFetchSlasV2ForAdvocateTaskTemplatePageQuery__
 *
 * To run a query within a React component, call `useFetchSlasV2ForAdvocateTaskTemplatePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSlasV2ForAdvocateTaskTemplatePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSlasV2ForAdvocateTaskTemplatePageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchSlasV2ForAdvocateTaskTemplatePageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchSlasV2ForAdvocateTaskTemplatePageQuery,
        FetchSlasV2ForAdvocateTaskTemplatePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchSlasV2ForAdvocateTaskTemplatePageQuery,
        FetchSlasV2ForAdvocateTaskTemplatePageQueryVariables
    >(FetchSlasV2ForAdvocateTaskTemplatePageDocument, options);
}
export function useFetchSlasV2ForAdvocateTaskTemplatePageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchSlasV2ForAdvocateTaskTemplatePageQuery,
        FetchSlasV2ForAdvocateTaskTemplatePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchSlasV2ForAdvocateTaskTemplatePageQuery,
        FetchSlasV2ForAdvocateTaskTemplatePageQueryVariables
    >(FetchSlasV2ForAdvocateTaskTemplatePageDocument, options);
}
export type FetchSlasV2ForAdvocateTaskTemplatePageQueryHookResult = ReturnType<
    typeof useFetchSlasV2ForAdvocateTaskTemplatePageQuery
>;
export type FetchSlasV2ForAdvocateTaskTemplatePageLazyQueryHookResult = ReturnType<
    typeof useFetchSlasV2ForAdvocateTaskTemplatePageLazyQuery
>;
export type FetchSlasV2ForAdvocateTaskTemplatePageQueryResult = Apollo.QueryResult<
    FetchSlasV2ForAdvocateTaskTemplatePageQuery,
    FetchSlasV2ForAdvocateTaskTemplatePageQueryVariables
>;
export const CreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageDocument = gql`
    mutation CreateAdvocateTaskTemplateForAdvocateTaskTemplatesPage(
        $input: AdvocateTaskTemplateInput!
    ) {
        createAdvocateTaskTemplate(input: $input) {
            success
            message
            resourceCreated {
                ...AdvocateTaskTemplateFragment
            }
        }
    }
    ${AdvocateTaskTemplateFragmentFragmentDoc}
`;
export type CreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutationFn =
    Apollo.MutationFunction<
        CreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutation,
        CreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutationVariables
    >;

/**
 * __useCreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutation__
 *
 * To run a mutation, you first call `useCreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutation, { data, loading, error }] = useCreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutation,
        CreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutation,
        CreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutationVariables
    >(CreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageDocument, options);
}
export type CreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutationHookResult = ReturnType<
    typeof useCreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutation
>;
export type CreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutationResult =
    Apollo.MutationResult<CreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutation>;
export type CreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutationOptions =
    Apollo.BaseMutationOptions<
        CreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutation,
        CreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutationVariables
    >;
export const AdvocateTaskTemplateForAdvocateTaskTemplatesPageDocument = gql`
    query AdvocateTaskTemplateForAdvocateTaskTemplatesPage(
        $advocateTaskTemplateInput: GetAdvocateTaskTemplateInput!
    ) {
        advocateTaskTemplate(input: $advocateTaskTemplateInput) {
            ...AdvocateTaskTemplateFragment
        }
    }
    ${AdvocateTaskTemplateFragmentFragmentDoc}
`;

/**
 * __useAdvocateTaskTemplateForAdvocateTaskTemplatesPageQuery__
 *
 * To run a query within a React component, call `useAdvocateTaskTemplateForAdvocateTaskTemplatesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvocateTaskTemplateForAdvocateTaskTemplatesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvocateTaskTemplateForAdvocateTaskTemplatesPageQuery({
 *   variables: {
 *      advocateTaskTemplateInput: // value for 'advocateTaskTemplateInput'
 *   },
 * });
 */
export function useAdvocateTaskTemplateForAdvocateTaskTemplatesPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        AdvocateTaskTemplateForAdvocateTaskTemplatesPageQuery,
        AdvocateTaskTemplateForAdvocateTaskTemplatesPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        AdvocateTaskTemplateForAdvocateTaskTemplatesPageQuery,
        AdvocateTaskTemplateForAdvocateTaskTemplatesPageQueryVariables
    >(AdvocateTaskTemplateForAdvocateTaskTemplatesPageDocument, options);
}
export function useAdvocateTaskTemplateForAdvocateTaskTemplatesPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        AdvocateTaskTemplateForAdvocateTaskTemplatesPageQuery,
        AdvocateTaskTemplateForAdvocateTaskTemplatesPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        AdvocateTaskTemplateForAdvocateTaskTemplatesPageQuery,
        AdvocateTaskTemplateForAdvocateTaskTemplatesPageQueryVariables
    >(AdvocateTaskTemplateForAdvocateTaskTemplatesPageDocument, options);
}
export type AdvocateTaskTemplateForAdvocateTaskTemplatesPageQueryHookResult = ReturnType<
    typeof useAdvocateTaskTemplateForAdvocateTaskTemplatesPageQuery
>;
export type AdvocateTaskTemplateForAdvocateTaskTemplatesPageLazyQueryHookResult = ReturnType<
    typeof useAdvocateTaskTemplateForAdvocateTaskTemplatesPageLazyQuery
>;
export type AdvocateTaskTemplateForAdvocateTaskTemplatesPageQueryResult = Apollo.QueryResult<
    AdvocateTaskTemplateForAdvocateTaskTemplatesPageQuery,
    AdvocateTaskTemplateForAdvocateTaskTemplatesPageQueryVariables
>;
export const AdvocateTaskTemplatesForAdvocateTaskTemplatesPageDocument = gql`
    query AdvocateTaskTemplatesForAdvocateTaskTemplatesPage {
        advocateTaskTemplatesV2 {
            results {
                label
                priority
                description
                id
                checklist {
                    id
                    label
                    description
                    completedAt
                    completedBy
                    actionTypeIds
                }
                whenType
                whenTimeline {
                    timelineId
                    offsetDays
                    offsetMonths
                    offsetYears
                    durationMinutes
                    includeApps
                    excludeApps
                    tags
                }
                typeId
            }
        }
    }
`;

/**
 * __useAdvocateTaskTemplatesForAdvocateTaskTemplatesPageQuery__
 *
 * To run a query within a React component, call `useAdvocateTaskTemplatesForAdvocateTaskTemplatesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvocateTaskTemplatesForAdvocateTaskTemplatesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvocateTaskTemplatesForAdvocateTaskTemplatesPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdvocateTaskTemplatesForAdvocateTaskTemplatesPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        AdvocateTaskTemplatesForAdvocateTaskTemplatesPageQuery,
        AdvocateTaskTemplatesForAdvocateTaskTemplatesPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        AdvocateTaskTemplatesForAdvocateTaskTemplatesPageQuery,
        AdvocateTaskTemplatesForAdvocateTaskTemplatesPageQueryVariables
    >(AdvocateTaskTemplatesForAdvocateTaskTemplatesPageDocument, options);
}
export function useAdvocateTaskTemplatesForAdvocateTaskTemplatesPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        AdvocateTaskTemplatesForAdvocateTaskTemplatesPageQuery,
        AdvocateTaskTemplatesForAdvocateTaskTemplatesPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        AdvocateTaskTemplatesForAdvocateTaskTemplatesPageQuery,
        AdvocateTaskTemplatesForAdvocateTaskTemplatesPageQueryVariables
    >(AdvocateTaskTemplatesForAdvocateTaskTemplatesPageDocument, options);
}
export type AdvocateTaskTemplatesForAdvocateTaskTemplatesPageQueryHookResult = ReturnType<
    typeof useAdvocateTaskTemplatesForAdvocateTaskTemplatesPageQuery
>;
export type AdvocateTaskTemplatesForAdvocateTaskTemplatesPageLazyQueryHookResult = ReturnType<
    typeof useAdvocateTaskTemplatesForAdvocateTaskTemplatesPageLazyQuery
>;
export type AdvocateTaskTemplatesForAdvocateTaskTemplatesPageQueryResult = Apollo.QueryResult<
    AdvocateTaskTemplatesForAdvocateTaskTemplatesPageQuery,
    AdvocateTaskTemplatesForAdvocateTaskTemplatesPageQueryVariables
>;
export const PrefetchDataForAdvocateTaskTemplatesPageDocument = gql`
    query PrefetchDataForAdvocateTaskTemplatesPage {
        whatsNewTimelines {
            id
            label {
                en
            }
        }
        amsApps {
            id
            bundleId
        }
        tags {
            id
            name
        }
    }
`;

/**
 * __usePrefetchDataForAdvocateTaskTemplatesPageQuery__
 *
 * To run a query within a React component, call `usePrefetchDataForAdvocateTaskTemplatesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrefetchDataForAdvocateTaskTemplatesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrefetchDataForAdvocateTaskTemplatesPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrefetchDataForAdvocateTaskTemplatesPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        PrefetchDataForAdvocateTaskTemplatesPageQuery,
        PrefetchDataForAdvocateTaskTemplatesPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        PrefetchDataForAdvocateTaskTemplatesPageQuery,
        PrefetchDataForAdvocateTaskTemplatesPageQueryVariables
    >(PrefetchDataForAdvocateTaskTemplatesPageDocument, options);
}
export function usePrefetchDataForAdvocateTaskTemplatesPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PrefetchDataForAdvocateTaskTemplatesPageQuery,
        PrefetchDataForAdvocateTaskTemplatesPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        PrefetchDataForAdvocateTaskTemplatesPageQuery,
        PrefetchDataForAdvocateTaskTemplatesPageQueryVariables
    >(PrefetchDataForAdvocateTaskTemplatesPageDocument, options);
}
export type PrefetchDataForAdvocateTaskTemplatesPageQueryHookResult = ReturnType<
    typeof usePrefetchDataForAdvocateTaskTemplatesPageQuery
>;
export type PrefetchDataForAdvocateTaskTemplatesPageLazyQueryHookResult = ReturnType<
    typeof usePrefetchDataForAdvocateTaskTemplatesPageLazyQuery
>;
export type PrefetchDataForAdvocateTaskTemplatesPageQueryResult = Apollo.QueryResult<
    PrefetchDataForAdvocateTaskTemplatesPageQuery,
    PrefetchDataForAdvocateTaskTemplatesPageQueryVariables
>;
export const DeleteAdvocateTaskTemplateDocument = gql`
    mutation DeleteAdvocateTaskTemplate($id: String!) {
        deleteAdvocateTaskTemplate(id: $id) {
            success
            message
            status
            id
        }
    }
`;
export type DeleteAdvocateTaskTemplateMutationFn = Apollo.MutationFunction<
    DeleteAdvocateTaskTemplateMutation,
    DeleteAdvocateTaskTemplateMutationVariables
>;

/**
 * __useDeleteAdvocateTaskTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteAdvocateTaskTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdvocateTaskTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdvocateTaskTemplateMutation, { data, loading, error }] = useDeleteAdvocateTaskTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAdvocateTaskTemplateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteAdvocateTaskTemplateMutation,
        DeleteAdvocateTaskTemplateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteAdvocateTaskTemplateMutation,
        DeleteAdvocateTaskTemplateMutationVariables
    >(DeleteAdvocateTaskTemplateDocument, options);
}
export type DeleteAdvocateTaskTemplateMutationHookResult = ReturnType<
    typeof useDeleteAdvocateTaskTemplateMutation
>;
export type DeleteAdvocateTaskTemplateMutationResult =
    Apollo.MutationResult<DeleteAdvocateTaskTemplateMutation>;
export type DeleteAdvocateTaskTemplateMutationOptions = Apollo.BaseMutationOptions<
    DeleteAdvocateTaskTemplateMutation,
    DeleteAdvocateTaskTemplateMutationVariables
>;
export const AdvocateTaskTemplateWhereUsedDocument = gql`
    query AdvocateTaskTemplateWhereUsed($id: String!) {
        advocateTaskTemplateWhereUsed(id: $id) {
            dependencies {
                entityType
                blocksDelete
                total
                entries {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useAdvocateTaskTemplateWhereUsedQuery__
 *
 * To run a query within a React component, call `useAdvocateTaskTemplateWhereUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvocateTaskTemplateWhereUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvocateTaskTemplateWhereUsedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdvocateTaskTemplateWhereUsedQuery(
    baseOptions: Apollo.QueryHookOptions<
        AdvocateTaskTemplateWhereUsedQuery,
        AdvocateTaskTemplateWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        AdvocateTaskTemplateWhereUsedQuery,
        AdvocateTaskTemplateWhereUsedQueryVariables
    >(AdvocateTaskTemplateWhereUsedDocument, options);
}
export function useAdvocateTaskTemplateWhereUsedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        AdvocateTaskTemplateWhereUsedQuery,
        AdvocateTaskTemplateWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        AdvocateTaskTemplateWhereUsedQuery,
        AdvocateTaskTemplateWhereUsedQueryVariables
    >(AdvocateTaskTemplateWhereUsedDocument, options);
}
export type AdvocateTaskTemplateWhereUsedQueryHookResult = ReturnType<
    typeof useAdvocateTaskTemplateWhereUsedQuery
>;
export type AdvocateTaskTemplateWhereUsedLazyQueryHookResult = ReturnType<
    typeof useAdvocateTaskTemplateWhereUsedLazyQuery
>;
export type AdvocateTaskTemplateWhereUsedQueryResult = Apollo.QueryResult<
    AdvocateTaskTemplateWhereUsedQuery,
    AdvocateTaskTemplateWhereUsedQueryVariables
>;
export const CreateAdvocateTaskForNewAdvocateTaskPageDocument = gql`
    mutation CreateAdvocateTaskForNewAdvocateTaskPage($input: AdvocateTaskInput!) {
        createAdvocateTaskEncoded(input: $input) {
            success
            message
            resourceCreated {
                ...AdvocateTaskFragment
            }
        }
    }
    ${AdvocateTaskFragmentFragmentDoc}
`;
export type CreateAdvocateTaskForNewAdvocateTaskPageMutationFn = Apollo.MutationFunction<
    CreateAdvocateTaskForNewAdvocateTaskPageMutation,
    CreateAdvocateTaskForNewAdvocateTaskPageMutationVariables
>;

/**
 * __useCreateAdvocateTaskForNewAdvocateTaskPageMutation__
 *
 * To run a mutation, you first call `useCreateAdvocateTaskForNewAdvocateTaskPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdvocateTaskForNewAdvocateTaskPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdvocateTaskForNewAdvocateTaskPageMutation, { data, loading, error }] = useCreateAdvocateTaskForNewAdvocateTaskPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdvocateTaskForNewAdvocateTaskPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateAdvocateTaskForNewAdvocateTaskPageMutation,
        CreateAdvocateTaskForNewAdvocateTaskPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateAdvocateTaskForNewAdvocateTaskPageMutation,
        CreateAdvocateTaskForNewAdvocateTaskPageMutationVariables
    >(CreateAdvocateTaskForNewAdvocateTaskPageDocument, options);
}
export type CreateAdvocateTaskForNewAdvocateTaskPageMutationHookResult = ReturnType<
    typeof useCreateAdvocateTaskForNewAdvocateTaskPageMutation
>;
export type CreateAdvocateTaskForNewAdvocateTaskPageMutationResult =
    Apollo.MutationResult<CreateAdvocateTaskForNewAdvocateTaskPageMutation>;
export type CreateAdvocateTaskForNewAdvocateTaskPageMutationOptions = Apollo.BaseMutationOptions<
    CreateAdvocateTaskForNewAdvocateTaskPageMutation,
    CreateAdvocateTaskForNewAdvocateTaskPageMutationVariables
>;
export const CreateAdvocateTaskTypeForNewAdvocateTaskPageDocument = gql`
    mutation CreateAdvocateTaskTypeForNewAdvocateTaskPage($input: AdvocateTaskTypeInput!) {
        createAdvocateTaskType(input: $input) {
            success
            message
            resourceCreated {
                ...AdvocateTaskTypeFragment
            }
        }
    }
    ${AdvocateTaskTypeFragmentFragmentDoc}
`;
export type CreateAdvocateTaskTypeForNewAdvocateTaskPageMutationFn = Apollo.MutationFunction<
    CreateAdvocateTaskTypeForNewAdvocateTaskPageMutation,
    CreateAdvocateTaskTypeForNewAdvocateTaskPageMutationVariables
>;

/**
 * __useCreateAdvocateTaskTypeForNewAdvocateTaskPageMutation__
 *
 * To run a mutation, you first call `useCreateAdvocateTaskTypeForNewAdvocateTaskPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdvocateTaskTypeForNewAdvocateTaskPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdvocateTaskTypeForNewAdvocateTaskPageMutation, { data, loading, error }] = useCreateAdvocateTaskTypeForNewAdvocateTaskPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdvocateTaskTypeForNewAdvocateTaskPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateAdvocateTaskTypeForNewAdvocateTaskPageMutation,
        CreateAdvocateTaskTypeForNewAdvocateTaskPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateAdvocateTaskTypeForNewAdvocateTaskPageMutation,
        CreateAdvocateTaskTypeForNewAdvocateTaskPageMutationVariables
    >(CreateAdvocateTaskTypeForNewAdvocateTaskPageDocument, options);
}
export type CreateAdvocateTaskTypeForNewAdvocateTaskPageMutationHookResult = ReturnType<
    typeof useCreateAdvocateTaskTypeForNewAdvocateTaskPageMutation
>;
export type CreateAdvocateTaskTypeForNewAdvocateTaskPageMutationResult =
    Apollo.MutationResult<CreateAdvocateTaskTypeForNewAdvocateTaskPageMutation>;
export type CreateAdvocateTaskTypeForNewAdvocateTaskPageMutationOptions =
    Apollo.BaseMutationOptions<
        CreateAdvocateTaskTypeForNewAdvocateTaskPageMutation,
        CreateAdvocateTaskTypeForNewAdvocateTaskPageMutationVariables
    >;
export const AdvocateTaskForAdvocateTaskPageDocument = gql`
    query AdvocateTaskForAdvocateTaskPage($input: GetAdvocateTaskInput!) {
        advocateTask(input: $input) {
            ...AdvocateTaskFragment
        }
    }
    ${AdvocateTaskFragmentFragmentDoc}
`;

/**
 * __useAdvocateTaskForAdvocateTaskPageQuery__
 *
 * To run a query within a React component, call `useAdvocateTaskForAdvocateTaskPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvocateTaskForAdvocateTaskPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvocateTaskForAdvocateTaskPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdvocateTaskForAdvocateTaskPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        AdvocateTaskForAdvocateTaskPageQuery,
        AdvocateTaskForAdvocateTaskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        AdvocateTaskForAdvocateTaskPageQuery,
        AdvocateTaskForAdvocateTaskPageQueryVariables
    >(AdvocateTaskForAdvocateTaskPageDocument, options);
}
export function useAdvocateTaskForAdvocateTaskPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        AdvocateTaskForAdvocateTaskPageQuery,
        AdvocateTaskForAdvocateTaskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        AdvocateTaskForAdvocateTaskPageQuery,
        AdvocateTaskForAdvocateTaskPageQueryVariables
    >(AdvocateTaskForAdvocateTaskPageDocument, options);
}
export type AdvocateTaskForAdvocateTaskPageQueryHookResult = ReturnType<
    typeof useAdvocateTaskForAdvocateTaskPageQuery
>;
export type AdvocateTaskForAdvocateTaskPageLazyQueryHookResult = ReturnType<
    typeof useAdvocateTaskForAdvocateTaskPageLazyQuery
>;
export type AdvocateTaskForAdvocateTaskPageQueryResult = Apollo.QueryResult<
    AdvocateTaskForAdvocateTaskPageQuery,
    AdvocateTaskForAdvocateTaskPageQueryVariables
>;
export const FetchAdvocateTaskTypesForAdvocateTaskPageDocument = gql`
    query FetchAdvocateTaskTypesForAdvocateTaskPage {
        advocateTaskTypesV2 {
            results {
                label
                id
            }
        }
    }
`;

/**
 * __useFetchAdvocateTaskTypesForAdvocateTaskPageQuery__
 *
 * To run a query within a React component, call `useFetchAdvocateTaskTypesForAdvocateTaskPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAdvocateTaskTypesForAdvocateTaskPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAdvocateTaskTypesForAdvocateTaskPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAdvocateTaskTypesForAdvocateTaskPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchAdvocateTaskTypesForAdvocateTaskPageQuery,
        FetchAdvocateTaskTypesForAdvocateTaskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchAdvocateTaskTypesForAdvocateTaskPageQuery,
        FetchAdvocateTaskTypesForAdvocateTaskPageQueryVariables
    >(FetchAdvocateTaskTypesForAdvocateTaskPageDocument, options);
}
export function useFetchAdvocateTaskTypesForAdvocateTaskPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchAdvocateTaskTypesForAdvocateTaskPageQuery,
        FetchAdvocateTaskTypesForAdvocateTaskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchAdvocateTaskTypesForAdvocateTaskPageQuery,
        FetchAdvocateTaskTypesForAdvocateTaskPageQueryVariables
    >(FetchAdvocateTaskTypesForAdvocateTaskPageDocument, options);
}
export type FetchAdvocateTaskTypesForAdvocateTaskPageQueryHookResult = ReturnType<
    typeof useFetchAdvocateTaskTypesForAdvocateTaskPageQuery
>;
export type FetchAdvocateTaskTypesForAdvocateTaskPageLazyQueryHookResult = ReturnType<
    typeof useFetchAdvocateTaskTypesForAdvocateTaskPageLazyQuery
>;
export type FetchAdvocateTaskTypesForAdvocateTaskPageQueryResult = Apollo.QueryResult<
    FetchAdvocateTaskTypesForAdvocateTaskPageQuery,
    FetchAdvocateTaskTypesForAdvocateTaskPageQueryVariables
>;
export const FetchCurrentUserForCreateAdvocateTaskPageDocument = gql`
    query FetchCurrentUserForCreateAdvocateTaskPage {
        currentUser {
            id
            name
            patients {
                fullName
                id
                assignedHealthAdvocate
                isTestData
            }
        }
    }
`;

/**
 * __useFetchCurrentUserForCreateAdvocateTaskPageQuery__
 *
 * To run a query within a React component, call `useFetchCurrentUserForCreateAdvocateTaskPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCurrentUserForCreateAdvocateTaskPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCurrentUserForCreateAdvocateTaskPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCurrentUserForCreateAdvocateTaskPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchCurrentUserForCreateAdvocateTaskPageQuery,
        FetchCurrentUserForCreateAdvocateTaskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchCurrentUserForCreateAdvocateTaskPageQuery,
        FetchCurrentUserForCreateAdvocateTaskPageQueryVariables
    >(FetchCurrentUserForCreateAdvocateTaskPageDocument, options);
}
export function useFetchCurrentUserForCreateAdvocateTaskPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchCurrentUserForCreateAdvocateTaskPageQuery,
        FetchCurrentUserForCreateAdvocateTaskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchCurrentUserForCreateAdvocateTaskPageQuery,
        FetchCurrentUserForCreateAdvocateTaskPageQueryVariables
    >(FetchCurrentUserForCreateAdvocateTaskPageDocument, options);
}
export type FetchCurrentUserForCreateAdvocateTaskPageQueryHookResult = ReturnType<
    typeof useFetchCurrentUserForCreateAdvocateTaskPageQuery
>;
export type FetchCurrentUserForCreateAdvocateTaskPageLazyQueryHookResult = ReturnType<
    typeof useFetchCurrentUserForCreateAdvocateTaskPageLazyQuery
>;
export type FetchCurrentUserForCreateAdvocateTaskPageQueryResult = Apollo.QueryResult<
    FetchCurrentUserForCreateAdvocateTaskPageQuery,
    FetchCurrentUserForCreateAdvocateTaskPageQueryVariables
>;
export const FetchAdvocatesForAdvocateTaskPageQueryDocument = gql`
    query FetchAdvocatesForAdvocateTaskPageQuery($permission: String) {
        getUsersByPermission(permission: $permission) {
            id
            name
        }
    }
`;

/**
 * __useFetchAdvocatesForAdvocateTaskPageQueryQuery__
 *
 * To run a query within a React component, call `useFetchAdvocatesForAdvocateTaskPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAdvocatesForAdvocateTaskPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAdvocatesForAdvocateTaskPageQueryQuery({
 *   variables: {
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useFetchAdvocatesForAdvocateTaskPageQueryQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchAdvocatesForAdvocateTaskPageQueryQuery,
        FetchAdvocatesForAdvocateTaskPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchAdvocatesForAdvocateTaskPageQueryQuery,
        FetchAdvocatesForAdvocateTaskPageQueryQueryVariables
    >(FetchAdvocatesForAdvocateTaskPageQueryDocument, options);
}
export function useFetchAdvocatesForAdvocateTaskPageQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchAdvocatesForAdvocateTaskPageQueryQuery,
        FetchAdvocatesForAdvocateTaskPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchAdvocatesForAdvocateTaskPageQueryQuery,
        FetchAdvocatesForAdvocateTaskPageQueryQueryVariables
    >(FetchAdvocatesForAdvocateTaskPageQueryDocument, options);
}
export type FetchAdvocatesForAdvocateTaskPageQueryQueryHookResult = ReturnType<
    typeof useFetchAdvocatesForAdvocateTaskPageQueryQuery
>;
export type FetchAdvocatesForAdvocateTaskPageQueryLazyQueryHookResult = ReturnType<
    typeof useFetchAdvocatesForAdvocateTaskPageQueryLazyQuery
>;
export type FetchAdvocatesForAdvocateTaskPageQueryQueryResult = Apollo.QueryResult<
    FetchAdvocatesForAdvocateTaskPageQueryQuery,
    FetchAdvocatesForAdvocateTaskPageQueryQueryVariables
>;
export const FetchPatientsForAdvocateTaskPageDocument = gql`
    query FetchPatientsForAdvocateTaskPage($input: GetPatientsByFilterInput!) {
        currentUser {
            id
            name
            patientsV2(input: $input) {
                orderBy
                skip
                limit
                total
                hasMore
                hasPrevious
                results {
                    fullName
                    id
                    assignedHealthAdvocate
                    isTestData
                }
            }
        }
    }
`;

/**
 * __useFetchPatientsForAdvocateTaskPageQuery__
 *
 * To run a query within a React component, call `useFetchPatientsForAdvocateTaskPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientsForAdvocateTaskPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientsForAdvocateTaskPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPatientsForAdvocateTaskPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPatientsForAdvocateTaskPageQuery,
        FetchPatientsForAdvocateTaskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientsForAdvocateTaskPageQuery,
        FetchPatientsForAdvocateTaskPageQueryVariables
    >(FetchPatientsForAdvocateTaskPageDocument, options);
}
export function useFetchPatientsForAdvocateTaskPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientsForAdvocateTaskPageQuery,
        FetchPatientsForAdvocateTaskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientsForAdvocateTaskPageQuery,
        FetchPatientsForAdvocateTaskPageQueryVariables
    >(FetchPatientsForAdvocateTaskPageDocument, options);
}
export type FetchPatientsForAdvocateTaskPageQueryHookResult = ReturnType<
    typeof useFetchPatientsForAdvocateTaskPageQuery
>;
export type FetchPatientsForAdvocateTaskPageLazyQueryHookResult = ReturnType<
    typeof useFetchPatientsForAdvocateTaskPageLazyQuery
>;
export type FetchPatientsForAdvocateTaskPageQueryResult = Apollo.QueryResult<
    FetchPatientsForAdvocateTaskPageQuery,
    FetchPatientsForAdvocateTaskPageQueryVariables
>;
export const FetchPatientForAdvocateTaskPageDocument = gql`
    query FetchPatientForAdvocateTaskPage($input: GetPatientInput!) {
        patient(input: $input) {
            fullName
            isTestData
        }
    }
`;

/**
 * __useFetchPatientForAdvocateTaskPageQuery__
 *
 * To run a query within a React component, call `useFetchPatientForAdvocateTaskPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientForAdvocateTaskPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientForAdvocateTaskPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPatientForAdvocateTaskPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPatientForAdvocateTaskPageQuery,
        FetchPatientForAdvocateTaskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientForAdvocateTaskPageQuery,
        FetchPatientForAdvocateTaskPageQueryVariables
    >(FetchPatientForAdvocateTaskPageDocument, options);
}
export function useFetchPatientForAdvocateTaskPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientForAdvocateTaskPageQuery,
        FetchPatientForAdvocateTaskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientForAdvocateTaskPageQuery,
        FetchPatientForAdvocateTaskPageQueryVariables
    >(FetchPatientForAdvocateTaskPageDocument, options);
}
export type FetchPatientForAdvocateTaskPageQueryHookResult = ReturnType<
    typeof useFetchPatientForAdvocateTaskPageQuery
>;
export type FetchPatientForAdvocateTaskPageLazyQueryHookResult = ReturnType<
    typeof useFetchPatientForAdvocateTaskPageLazyQuery
>;
export type FetchPatientForAdvocateTaskPageQueryResult = Apollo.QueryResult<
    FetchPatientForAdvocateTaskPageQuery,
    FetchPatientForAdvocateTaskPageQueryVariables
>;
export const CreatePatientActionTypeForChecklistModalDocument = gql`
    mutation CreatePatientActionTypeForChecklistModal($input: PatientActionTypeInput!) {
        createPatientActionType(input: $input) {
            success
            message
            resourceCreated {
                ...PatientActionTypeFragment
            }
        }
    }
    ${PatientActionTypeFragmentFragmentDoc}
`;
export type CreatePatientActionTypeForChecklistModalMutationFn = Apollo.MutationFunction<
    CreatePatientActionTypeForChecklistModalMutation,
    CreatePatientActionTypeForChecklistModalMutationVariables
>;

/**
 * __useCreatePatientActionTypeForChecklistModalMutation__
 *
 * To run a mutation, you first call `useCreatePatientActionTypeForChecklistModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientActionTypeForChecklistModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientActionTypeForChecklistModalMutation, { data, loading, error }] = useCreatePatientActionTypeForChecklistModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatientActionTypeForChecklistModalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreatePatientActionTypeForChecklistModalMutation,
        CreatePatientActionTypeForChecklistModalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreatePatientActionTypeForChecklistModalMutation,
        CreatePatientActionTypeForChecklistModalMutationVariables
    >(CreatePatientActionTypeForChecklistModalDocument, options);
}
export type CreatePatientActionTypeForChecklistModalMutationHookResult = ReturnType<
    typeof useCreatePatientActionTypeForChecklistModalMutation
>;
export type CreatePatientActionTypeForChecklistModalMutationResult =
    Apollo.MutationResult<CreatePatientActionTypeForChecklistModalMutation>;
export type CreatePatientActionTypeForChecklistModalMutationOptions = Apollo.BaseMutationOptions<
    CreatePatientActionTypeForChecklistModalMutation,
    CreatePatientActionTypeForChecklistModalMutationVariables
>;
export const FetchPatientActionTypesForChecklistModalPageDocument = gql`
    query FetchPatientActionTypesForChecklistModalPage {
        patientActionTypesV2 {
            results {
                label
                createdAt
                updatedAt
                id
            }
        }
    }
`;

/**
 * __useFetchPatientActionTypesForChecklistModalPageQuery__
 *
 * To run a query within a React component, call `useFetchPatientActionTypesForChecklistModalPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientActionTypesForChecklistModalPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientActionTypesForChecklistModalPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchPatientActionTypesForChecklistModalPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchPatientActionTypesForChecklistModalPageQuery,
        FetchPatientActionTypesForChecklistModalPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientActionTypesForChecklistModalPageQuery,
        FetchPatientActionTypesForChecklistModalPageQueryVariables
    >(FetchPatientActionTypesForChecklistModalPageDocument, options);
}
export function useFetchPatientActionTypesForChecklistModalPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientActionTypesForChecklistModalPageQuery,
        FetchPatientActionTypesForChecklistModalPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientActionTypesForChecklistModalPageQuery,
        FetchPatientActionTypesForChecklistModalPageQueryVariables
    >(FetchPatientActionTypesForChecklistModalPageDocument, options);
}
export type FetchPatientActionTypesForChecklistModalPageQueryHookResult = ReturnType<
    typeof useFetchPatientActionTypesForChecklistModalPageQuery
>;
export type FetchPatientActionTypesForChecklistModalPageLazyQueryHookResult = ReturnType<
    typeof useFetchPatientActionTypesForChecklistModalPageLazyQuery
>;
export type FetchPatientActionTypesForChecklistModalPageQueryResult = Apollo.QueryResult<
    FetchPatientActionTypesForChecklistModalPageQuery,
    FetchPatientActionTypesForChecklistModalPageQueryVariables
>;
export const ArticleApprovalByIdDocument = gql`
    query ArticleApprovalById($input: GetArticleApprovalInput!) {
        articleApproval(input: $input) {
            ...ArticleApprovalFragment
        }
    }
    ${ArticleApprovalFragmentFragmentDoc}
`;

/**
 * __useArticleApprovalByIdQuery__
 *
 * To run a query within a React component, call `useArticleApprovalByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleApprovalByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleApprovalByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticleApprovalByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        ArticleApprovalByIdQuery,
        ArticleApprovalByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ArticleApprovalByIdQuery, ArticleApprovalByIdQueryVariables>(
        ArticleApprovalByIdDocument,
        options,
    );
}
export function useArticleApprovalByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ArticleApprovalByIdQuery,
        ArticleApprovalByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ArticleApprovalByIdQuery, ArticleApprovalByIdQueryVariables>(
        ArticleApprovalByIdDocument,
        options,
    );
}
export type ArticleApprovalByIdQueryHookResult = ReturnType<typeof useArticleApprovalByIdQuery>;
export type ArticleApprovalByIdLazyQueryHookResult = ReturnType<
    typeof useArticleApprovalByIdLazyQuery
>;
export type ArticleApprovalByIdQueryResult = Apollo.QueryResult<
    ArticleApprovalByIdQuery,
    ArticleApprovalByIdQueryVariables
>;
export const CreateArticleApprovalDocument = gql`
    mutation CreateArticleApproval($input: ArticleApprovalInput!) {
        createArticleApproval(input: $input) {
            status
            message
            success
            resourceCreated {
                ...ArticleApprovalFragment
            }
        }
    }
    ${ArticleApprovalFragmentFragmentDoc}
`;
export type CreateArticleApprovalMutationFn = Apollo.MutationFunction<
    CreateArticleApprovalMutation,
    CreateArticleApprovalMutationVariables
>;

/**
 * __useCreateArticleApprovalMutation__
 *
 * To run a mutation, you first call `useCreateArticleApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleApprovalMutation, { data, loading, error }] = useCreateArticleApprovalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArticleApprovalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateArticleApprovalMutation,
        CreateArticleApprovalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateArticleApprovalMutation,
        CreateArticleApprovalMutationVariables
    >(CreateArticleApprovalDocument, options);
}
export type CreateArticleApprovalMutationHookResult = ReturnType<
    typeof useCreateArticleApprovalMutation
>;
export type CreateArticleApprovalMutationResult =
    Apollo.MutationResult<CreateArticleApprovalMutation>;
export type CreateArticleApprovalMutationOptions = Apollo.BaseMutationOptions<
    CreateArticleApprovalMutation,
    CreateArticleApprovalMutationVariables
>;
export const CreateArticleAppApprovalsDocument = gql`
    mutation CreateArticleAppApprovals($input: CreateArticleAppApprovalsInput!) {
        createArticleAppApprovals(input: $input) {
            status
            message
            success
        }
    }
`;
export type CreateArticleAppApprovalsMutationFn = Apollo.MutationFunction<
    CreateArticleAppApprovalsMutation,
    CreateArticleAppApprovalsMutationVariables
>;

/**
 * __useCreateArticleAppApprovalsMutation__
 *
 * To run a mutation, you first call `useCreateArticleAppApprovalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleAppApprovalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleAppApprovalsMutation, { data, loading, error }] = useCreateArticleAppApprovalsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArticleAppApprovalsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateArticleAppApprovalsMutation,
        CreateArticleAppApprovalsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateArticleAppApprovalsMutation,
        CreateArticleAppApprovalsMutationVariables
    >(CreateArticleAppApprovalsDocument, options);
}
export type CreateArticleAppApprovalsMutationHookResult = ReturnType<
    typeof useCreateArticleAppApprovalsMutation
>;
export type CreateArticleAppApprovalsMutationResult =
    Apollo.MutationResult<CreateArticleAppApprovalsMutation>;
export type CreateArticleAppApprovalsMutationOptions = Apollo.BaseMutationOptions<
    CreateArticleAppApprovalsMutation,
    CreateArticleAppApprovalsMutationVariables
>;
export const UpdateArticleApprovalDocument = gql`
    mutation UpdateArticleApproval($updateArticleApprovalInput: UpdateArticleApprovalInput!) {
        updateArticleApproval(input: $updateArticleApprovalInput) {
            status
            message
            success
            resourceUpdated {
                ...ArticleApprovalFragment
            }
        }
    }
    ${ArticleApprovalFragmentFragmentDoc}
`;
export type UpdateArticleApprovalMutationFn = Apollo.MutationFunction<
    UpdateArticleApprovalMutation,
    UpdateArticleApprovalMutationVariables
>;

/**
 * __useUpdateArticleApprovalMutation__
 *
 * To run a mutation, you first call `useUpdateArticleApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleApprovalMutation, { data, loading, error }] = useUpdateArticleApprovalMutation({
 *   variables: {
 *      updateArticleApprovalInput: // value for 'updateArticleApprovalInput'
 *   },
 * });
 */
export function useUpdateArticleApprovalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateArticleApprovalMutation,
        UpdateArticleApprovalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateArticleApprovalMutation,
        UpdateArticleApprovalMutationVariables
    >(UpdateArticleApprovalDocument, options);
}
export type UpdateArticleApprovalMutationHookResult = ReturnType<
    typeof useUpdateArticleApprovalMutation
>;
export type UpdateArticleApprovalMutationResult =
    Apollo.MutationResult<UpdateArticleApprovalMutation>;
export type UpdateArticleApprovalMutationOptions = Apollo.BaseMutationOptions<
    UpdateArticleApprovalMutation,
    UpdateArticleApprovalMutationVariables
>;
export const ArticleApprovalListDocument = gql`
    query ArticleApprovalList($input: ArticleApprovalListInput) {
        customFilteredArticleApprovals(input: $input) {
            total
            results {
                ...ArticleApprovalFragment
            }
        }
    }
    ${ArticleApprovalFragmentFragmentDoc}
`;

/**
 * __useArticleApprovalListQuery__
 *
 * To run a query within a React component, call `useArticleApprovalListQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleApprovalListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleApprovalListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticleApprovalListQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ArticleApprovalListQuery,
        ArticleApprovalListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ArticleApprovalListQuery, ArticleApprovalListQueryVariables>(
        ArticleApprovalListDocument,
        options,
    );
}
export function useArticleApprovalListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ArticleApprovalListQuery,
        ArticleApprovalListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ArticleApprovalListQuery, ArticleApprovalListQueryVariables>(
        ArticleApprovalListDocument,
        options,
    );
}
export type ArticleApprovalListQueryHookResult = ReturnType<typeof useArticleApprovalListQuery>;
export type ArticleApprovalListLazyQueryHookResult = ReturnType<
    typeof useArticleApprovalListLazyQuery
>;
export type ArticleApprovalListQueryResult = Apollo.QueryResult<
    ArticleApprovalListQuery,
    ArticleApprovalListQueryVariables
>;
export const ArticleApprovalListV2Document = gql`
    query ArticleApprovalListV2($input: ArticleApprovalListInput) {
        customFilteredArticleApprovalsV2(input: $input) {
            total
            results {
                ...ArticleApprovalFragment
            }
        }
    }
    ${ArticleApprovalFragmentFragmentDoc}
`;

/**
 * __useArticleApprovalListV2Query__
 *
 * To run a query within a React component, call `useArticleApprovalListV2Query` and pass it any options that fit your needs.
 * When your component renders, `useArticleApprovalListV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleApprovalListV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticleApprovalListV2Query(
    baseOptions?: Apollo.QueryHookOptions<
        ArticleApprovalListV2Query,
        ArticleApprovalListV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ArticleApprovalListV2Query, ArticleApprovalListV2QueryVariables>(
        ArticleApprovalListV2Document,
        options,
    );
}
export function useArticleApprovalListV2LazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ArticleApprovalListV2Query,
        ArticleApprovalListV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ArticleApprovalListV2Query, ArticleApprovalListV2QueryVariables>(
        ArticleApprovalListV2Document,
        options,
    );
}
export type ArticleApprovalListV2QueryHookResult = ReturnType<typeof useArticleApprovalListV2Query>;
export type ArticleApprovalListV2LazyQueryHookResult = ReturnType<
    typeof useArticleApprovalListV2LazyQuery
>;
export type ArticleApprovalListV2QueryResult = Apollo.QueryResult<
    ArticleApprovalListV2Query,
    ArticleApprovalListV2QueryVariables
>;
export const ArticlesV2ForArticleApprovalPageDocument = gql`
    query ArticlesV2ForArticleApprovalPage($input: ArticleListInput) {
        articlesV2(input: $input) {
            results {
                id
                name
            }
        }
    }
`;

/**
 * __useArticlesV2ForArticleApprovalPageQuery__
 *
 * To run a query within a React component, call `useArticlesV2ForArticleApprovalPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesV2ForArticleApprovalPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesV2ForArticleApprovalPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticlesV2ForArticleApprovalPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ArticlesV2ForArticleApprovalPageQuery,
        ArticlesV2ForArticleApprovalPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        ArticlesV2ForArticleApprovalPageQuery,
        ArticlesV2ForArticleApprovalPageQueryVariables
    >(ArticlesV2ForArticleApprovalPageDocument, options);
}
export function useArticlesV2ForArticleApprovalPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ArticlesV2ForArticleApprovalPageQuery,
        ArticlesV2ForArticleApprovalPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ArticlesV2ForArticleApprovalPageQuery,
        ArticlesV2ForArticleApprovalPageQueryVariables
    >(ArticlesV2ForArticleApprovalPageDocument, options);
}
export type ArticlesV2ForArticleApprovalPageQueryHookResult = ReturnType<
    typeof useArticlesV2ForArticleApprovalPageQuery
>;
export type ArticlesV2ForArticleApprovalPageLazyQueryHookResult = ReturnType<
    typeof useArticlesV2ForArticleApprovalPageLazyQuery
>;
export type ArticlesV2ForArticleApprovalPageQueryResult = Apollo.QueryResult<
    ArticlesV2ForArticleApprovalPageQuery,
    ArticlesV2ForArticleApprovalPageQueryVariables
>;
export const ArticleGroupsForArticleApprovalPageDocument = gql`
    query ArticleGroupsForArticleApprovalPage {
        articleGroupsV2 {
            results {
                id
                name
            }
        }
    }
`;

/**
 * __useArticleGroupsForArticleApprovalPageQuery__
 *
 * To run a query within a React component, call `useArticleGroupsForArticleApprovalPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleGroupsForArticleApprovalPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleGroupsForArticleApprovalPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useArticleGroupsForArticleApprovalPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ArticleGroupsForArticleApprovalPageQuery,
        ArticleGroupsForArticleApprovalPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        ArticleGroupsForArticleApprovalPageQuery,
        ArticleGroupsForArticleApprovalPageQueryVariables
    >(ArticleGroupsForArticleApprovalPageDocument, options);
}
export function useArticleGroupsForArticleApprovalPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ArticleGroupsForArticleApprovalPageQuery,
        ArticleGroupsForArticleApprovalPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ArticleGroupsForArticleApprovalPageQuery,
        ArticleGroupsForArticleApprovalPageQueryVariables
    >(ArticleGroupsForArticleApprovalPageDocument, options);
}
export type ArticleGroupsForArticleApprovalPageQueryHookResult = ReturnType<
    typeof useArticleGroupsForArticleApprovalPageQuery
>;
export type ArticleGroupsForArticleApprovalPageLazyQueryHookResult = ReturnType<
    typeof useArticleGroupsForArticleApprovalPageLazyQuery
>;
export type ArticleGroupsForArticleApprovalPageQueryResult = Apollo.QueryResult<
    ArticleGroupsForArticleApprovalPageQuery,
    ArticleGroupsForArticleApprovalPageQueryVariables
>;
export const ArticleCategoryByIdDocument = gql`
    query ArticleCategoryById($input: GetArticleCategoryInput!) {
        articleCategory(input: $input) {
            id
            name
        }
    }
`;

/**
 * __useArticleCategoryByIdQuery__
 *
 * To run a query within a React component, call `useArticleCategoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleCategoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleCategoryByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticleCategoryByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        ArticleCategoryByIdQuery,
        ArticleCategoryByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ArticleCategoryByIdQuery, ArticleCategoryByIdQueryVariables>(
        ArticleCategoryByIdDocument,
        options,
    );
}
export function useArticleCategoryByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ArticleCategoryByIdQuery,
        ArticleCategoryByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ArticleCategoryByIdQuery, ArticleCategoryByIdQueryVariables>(
        ArticleCategoryByIdDocument,
        options,
    );
}
export type ArticleCategoryByIdQueryHookResult = ReturnType<typeof useArticleCategoryByIdQuery>;
export type ArticleCategoryByIdLazyQueryHookResult = ReturnType<
    typeof useArticleCategoryByIdLazyQuery
>;
export type ArticleCategoryByIdQueryResult = Apollo.QueryResult<
    ArticleCategoryByIdQuery,
    ArticleCategoryByIdQueryVariables
>;
export const CreateArticleCategoryDocument = gql`
    mutation CreateArticleCategory($input: ArticleCategoryInput!) {
        createArticleCategory(input: $input) {
            status
            message
            success
            resourceCreated {
                id
                name
            }
        }
    }
`;
export type CreateArticleCategoryMutationFn = Apollo.MutationFunction<
    CreateArticleCategoryMutation,
    CreateArticleCategoryMutationVariables
>;

/**
 * __useCreateArticleCategoryMutation__
 *
 * To run a mutation, you first call `useCreateArticleCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleCategoryMutation, { data, loading, error }] = useCreateArticleCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArticleCategoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateArticleCategoryMutation,
        CreateArticleCategoryMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateArticleCategoryMutation,
        CreateArticleCategoryMutationVariables
    >(CreateArticleCategoryDocument, options);
}
export type CreateArticleCategoryMutationHookResult = ReturnType<
    typeof useCreateArticleCategoryMutation
>;
export type CreateArticleCategoryMutationResult =
    Apollo.MutationResult<CreateArticleCategoryMutation>;
export type CreateArticleCategoryMutationOptions = Apollo.BaseMutationOptions<
    CreateArticleCategoryMutation,
    CreateArticleCategoryMutationVariables
>;
export const ArticleCategoryListDocument = gql`
    query ArticleCategoryList {
        articleCategorys {
            id
            name
        }
    }
`;

/**
 * __useArticleCategoryListQuery__
 *
 * To run a query within a React component, call `useArticleCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleCategoryListQuery({
 *   variables: {
 *   },
 * });
 */
export function useArticleCategoryListQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ArticleCategoryListQuery,
        ArticleCategoryListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ArticleCategoryListQuery, ArticleCategoryListQueryVariables>(
        ArticleCategoryListDocument,
        options,
    );
}
export function useArticleCategoryListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ArticleCategoryListQuery,
        ArticleCategoryListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ArticleCategoryListQuery, ArticleCategoryListQueryVariables>(
        ArticleCategoryListDocument,
        options,
    );
}
export type ArticleCategoryListQueryHookResult = ReturnType<typeof useArticleCategoryListQuery>;
export type ArticleCategoryListLazyQueryHookResult = ReturnType<
    typeof useArticleCategoryListLazyQuery
>;
export type ArticleCategoryListQueryResult = Apollo.QueryResult<
    ArticleCategoryListQuery,
    ArticleCategoryListQueryVariables
>;
export const UpdateArticleCategoryDocument = gql`
    mutation UpdateArticleCategory($updateArticleCategoryInput: UpdateArticleCategoryInput!) {
        updateArticleCategory(input: $updateArticleCategoryInput) {
            status
            message
            success
            resourceUpdated {
                id
                name
            }
        }
    }
`;
export type UpdateArticleCategoryMutationFn = Apollo.MutationFunction<
    UpdateArticleCategoryMutation,
    UpdateArticleCategoryMutationVariables
>;

/**
 * __useUpdateArticleCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateArticleCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleCategoryMutation, { data, loading, error }] = useUpdateArticleCategoryMutation({
 *   variables: {
 *      updateArticleCategoryInput: // value for 'updateArticleCategoryInput'
 *   },
 * });
 */
export function useUpdateArticleCategoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateArticleCategoryMutation,
        UpdateArticleCategoryMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateArticleCategoryMutation,
        UpdateArticleCategoryMutationVariables
    >(UpdateArticleCategoryDocument, options);
}
export type UpdateArticleCategoryMutationHookResult = ReturnType<
    typeof useUpdateArticleCategoryMutation
>;
export type UpdateArticleCategoryMutationResult =
    Apollo.MutationResult<UpdateArticleCategoryMutation>;
export type UpdateArticleCategoryMutationOptions = Apollo.BaseMutationOptions<
    UpdateArticleCategoryMutation,
    UpdateArticleCategoryMutationVariables
>;
export const DeleteArticleCategoryDocument = gql`
    mutation DeleteArticleCategory($id: String!) {
        deleteArticleCategory(id: $id) {
            success
            message
            status
            id
        }
    }
`;
export type DeleteArticleCategoryMutationFn = Apollo.MutationFunction<
    DeleteArticleCategoryMutation,
    DeleteArticleCategoryMutationVariables
>;

/**
 * __useDeleteArticleCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteArticleCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleCategoryMutation, { data, loading, error }] = useDeleteArticleCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArticleCategoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteArticleCategoryMutation,
        DeleteArticleCategoryMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteArticleCategoryMutation,
        DeleteArticleCategoryMutationVariables
    >(DeleteArticleCategoryDocument, options);
}
export type DeleteArticleCategoryMutationHookResult = ReturnType<
    typeof useDeleteArticleCategoryMutation
>;
export type DeleteArticleCategoryMutationResult =
    Apollo.MutationResult<DeleteArticleCategoryMutation>;
export type DeleteArticleCategoryMutationOptions = Apollo.BaseMutationOptions<
    DeleteArticleCategoryMutation,
    DeleteArticleCategoryMutationVariables
>;
export const ArticleCategoryWhereUsedDocument = gql`
    query ArticleCategoryWhereUsed($id: String!) {
        articleCategoryWhereUsed(id: $id) {
            dependencies {
                entityType
                blocksDelete
                total
                entries {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useArticleCategoryWhereUsedQuery__
 *
 * To run a query within a React component, call `useArticleCategoryWhereUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleCategoryWhereUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleCategoryWhereUsedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleCategoryWhereUsedQuery(
    baseOptions: Apollo.QueryHookOptions<
        ArticleCategoryWhereUsedQuery,
        ArticleCategoryWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ArticleCategoryWhereUsedQuery, ArticleCategoryWhereUsedQueryVariables>(
        ArticleCategoryWhereUsedDocument,
        options,
    );
}
export function useArticleCategoryWhereUsedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ArticleCategoryWhereUsedQuery,
        ArticleCategoryWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ArticleCategoryWhereUsedQuery,
        ArticleCategoryWhereUsedQueryVariables
    >(ArticleCategoryWhereUsedDocument, options);
}
export type ArticleCategoryWhereUsedQueryHookResult = ReturnType<
    typeof useArticleCategoryWhereUsedQuery
>;
export type ArticleCategoryWhereUsedLazyQueryHookResult = ReturnType<
    typeof useArticleCategoryWhereUsedLazyQuery
>;
export type ArticleCategoryWhereUsedQueryResult = Apollo.QueryResult<
    ArticleCategoryWhereUsedQuery,
    ArticleCategoryWhereUsedQueryVariables
>;
export const FetchArticleGroupDocument = gql`
    query FetchArticleGroup($articleGroupInput: GetArticleGroupInput!) {
        articleGroup(input: $articleGroupInput) {
            ...ArticleGroupFrag
        }
    }
    ${ArticleGroupFragFragmentDoc}
`;

/**
 * __useFetchArticleGroupQuery__
 *
 * To run a query within a React component, call `useFetchArticleGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchArticleGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchArticleGroupQuery({
 *   variables: {
 *      articleGroupInput: // value for 'articleGroupInput'
 *   },
 * });
 */
export function useFetchArticleGroupQuery(
    baseOptions: Apollo.QueryHookOptions<FetchArticleGroupQuery, FetchArticleGroupQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchArticleGroupQuery, FetchArticleGroupQueryVariables>(
        FetchArticleGroupDocument,
        options,
    );
}
export function useFetchArticleGroupLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchArticleGroupQuery,
        FetchArticleGroupQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchArticleGroupQuery, FetchArticleGroupQueryVariables>(
        FetchArticleGroupDocument,
        options,
    );
}
export type FetchArticleGroupQueryHookResult = ReturnType<typeof useFetchArticleGroupQuery>;
export type FetchArticleGroupLazyQueryHookResult = ReturnType<typeof useFetchArticleGroupLazyQuery>;
export type FetchArticleGroupQueryResult = Apollo.QueryResult<
    FetchArticleGroupQuery,
    FetchArticleGroupQueryVariables
>;
export const UpdateArticleGroupDocument = gql`
    mutation UpdateArticleGroup($updateArticleGroupInput: UpdateArticleGroupInput!) {
        updateArticleGroup(input: $updateArticleGroupInput) {
            success
            message
            resourceUpdated {
                ...ArticleGroupFrag
            }
        }
    }
    ${ArticleGroupFragFragmentDoc}
`;
export type UpdateArticleGroupMutationFn = Apollo.MutationFunction<
    UpdateArticleGroupMutation,
    UpdateArticleGroupMutationVariables
>;

/**
 * __useUpdateArticleGroupMutation__
 *
 * To run a mutation, you first call `useUpdateArticleGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleGroupMutation, { data, loading, error }] = useUpdateArticleGroupMutation({
 *   variables: {
 *      updateArticleGroupInput: // value for 'updateArticleGroupInput'
 *   },
 * });
 */
export function useUpdateArticleGroupMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateArticleGroupMutation,
        UpdateArticleGroupMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateArticleGroupMutation, UpdateArticleGroupMutationVariables>(
        UpdateArticleGroupDocument,
        options,
    );
}
export type UpdateArticleGroupMutationHookResult = ReturnType<typeof useUpdateArticleGroupMutation>;
export type UpdateArticleGroupMutationResult = Apollo.MutationResult<UpdateArticleGroupMutation>;
export type UpdateArticleGroupMutationOptions = Apollo.BaseMutationOptions<
    UpdateArticleGroupMutation,
    UpdateArticleGroupMutationVariables
>;
export const CreateArticleGroupDocument = gql`
    mutation CreateArticleGroup($createArticleGroupInput: ArticleGroupInput!) {
        createArticleGroup(input: $createArticleGroupInput) {
            success
            message
            resourceCreated {
                ...ArticleGroupFrag
            }
        }
    }
    ${ArticleGroupFragFragmentDoc}
`;
export type CreateArticleGroupMutationFn = Apollo.MutationFunction<
    CreateArticleGroupMutation,
    CreateArticleGroupMutationVariables
>;

/**
 * __useCreateArticleGroupMutation__
 *
 * To run a mutation, you first call `useCreateArticleGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleGroupMutation, { data, loading, error }] = useCreateArticleGroupMutation({
 *   variables: {
 *      createArticleGroupInput: // value for 'createArticleGroupInput'
 *   },
 * });
 */
export function useCreateArticleGroupMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateArticleGroupMutation,
        CreateArticleGroupMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateArticleGroupMutation, CreateArticleGroupMutationVariables>(
        CreateArticleGroupDocument,
        options,
    );
}
export type CreateArticleGroupMutationHookResult = ReturnType<typeof useCreateArticleGroupMutation>;
export type CreateArticleGroupMutationResult = Apollo.MutationResult<CreateArticleGroupMutation>;
export type CreateArticleGroupMutationOptions = Apollo.BaseMutationOptions<
    CreateArticleGroupMutation,
    CreateArticleGroupMutationVariables
>;
export const ArticleGroupListDocument = gql`
    query ArticleGroupList($articleGroupListInput: ArticleGroupQueryInput) {
        articleGroups(input: $articleGroupListInput) {
            ...ArticleGroupFrag
        }
    }
    ${ArticleGroupFragFragmentDoc}
`;

/**
 * __useArticleGroupListQuery__
 *
 * To run a query within a React component, call `useArticleGroupListQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleGroupListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleGroupListQuery({
 *   variables: {
 *      articleGroupListInput: // value for 'articleGroupListInput'
 *   },
 * });
 */
export function useArticleGroupListQuery(
    baseOptions?: Apollo.QueryHookOptions<ArticleGroupListQuery, ArticleGroupListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ArticleGroupListQuery, ArticleGroupListQueryVariables>(
        ArticleGroupListDocument,
        options,
    );
}
export function useArticleGroupListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ArticleGroupListQuery,
        ArticleGroupListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ArticleGroupListQuery, ArticleGroupListQueryVariables>(
        ArticleGroupListDocument,
        options,
    );
}
export type ArticleGroupListQueryHookResult = ReturnType<typeof useArticleGroupListQuery>;
export type ArticleGroupListLazyQueryHookResult = ReturnType<typeof useArticleGroupListLazyQuery>;
export type ArticleGroupListQueryResult = Apollo.QueryResult<
    ArticleGroupListQuery,
    ArticleGroupListQueryVariables
>;
export const DeleteArticleGroupDocument = gql`
    mutation DeleteArticleGroup($input: DeleteArticleGroupInput!) {
        deleteArticleGroup(input: $input) {
            success
            message
            status
            id
        }
    }
`;
export type DeleteArticleGroupMutationFn = Apollo.MutationFunction<
    DeleteArticleGroupMutation,
    DeleteArticleGroupMutationVariables
>;

/**
 * __useDeleteArticleGroupMutation__
 *
 * To run a mutation, you first call `useDeleteArticleGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleGroupMutation, { data, loading, error }] = useDeleteArticleGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteArticleGroupMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteArticleGroupMutation,
        DeleteArticleGroupMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteArticleGroupMutation, DeleteArticleGroupMutationVariables>(
        DeleteArticleGroupDocument,
        options,
    );
}
export type DeleteArticleGroupMutationHookResult = ReturnType<typeof useDeleteArticleGroupMutation>;
export type DeleteArticleGroupMutationResult = Apollo.MutationResult<DeleteArticleGroupMutation>;
export type DeleteArticleGroupMutationOptions = Apollo.BaseMutationOptions<
    DeleteArticleGroupMutation,
    DeleteArticleGroupMutationVariables
>;
export const ArticleGroupWhereUsedDocument = gql`
    query ArticleGroupWhereUsed($id: String!) {
        articleGroupWhereUsed(id: $id) {
            dependencies {
                entityType
                blocksDelete
                total
                entries {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useArticleGroupWhereUsedQuery__
 *
 * To run a query within a React component, call `useArticleGroupWhereUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleGroupWhereUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleGroupWhereUsedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleGroupWhereUsedQuery(
    baseOptions: Apollo.QueryHookOptions<
        ArticleGroupWhereUsedQuery,
        ArticleGroupWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ArticleGroupWhereUsedQuery, ArticleGroupWhereUsedQueryVariables>(
        ArticleGroupWhereUsedDocument,
        options,
    );
}
export function useArticleGroupWhereUsedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ArticleGroupWhereUsedQuery,
        ArticleGroupWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ArticleGroupWhereUsedQuery, ArticleGroupWhereUsedQueryVariables>(
        ArticleGroupWhereUsedDocument,
        options,
    );
}
export type ArticleGroupWhereUsedQueryHookResult = ReturnType<typeof useArticleGroupWhereUsedQuery>;
export type ArticleGroupWhereUsedLazyQueryHookResult = ReturnType<
    typeof useArticleGroupWhereUsedLazyQuery
>;
export type ArticleGroupWhereUsedQueryResult = Apollo.QueryResult<
    ArticleGroupWhereUsedQuery,
    ArticleGroupWhereUsedQueryVariables
>;
export const CreateArticlePromotionForArticlePromotionPageDocument = gql`
    mutation CreateArticlePromotionForArticlePromotionPage($input: ArticlePromotionInput!) {
        createArticlePromotion(input: $input) {
            success
            message
            resourceCreated {
                ...ArticlePromotionFragment
            }
        }
    }
    ${ArticlePromotionFragmentFragmentDoc}
`;
export type CreateArticlePromotionForArticlePromotionPageMutationFn = Apollo.MutationFunction<
    CreateArticlePromotionForArticlePromotionPageMutation,
    CreateArticlePromotionForArticlePromotionPageMutationVariables
>;

/**
 * __useCreateArticlePromotionForArticlePromotionPageMutation__
 *
 * To run a mutation, you first call `useCreateArticlePromotionForArticlePromotionPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticlePromotionForArticlePromotionPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticlePromotionForArticlePromotionPageMutation, { data, loading, error }] = useCreateArticlePromotionForArticlePromotionPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArticlePromotionForArticlePromotionPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateArticlePromotionForArticlePromotionPageMutation,
        CreateArticlePromotionForArticlePromotionPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateArticlePromotionForArticlePromotionPageMutation,
        CreateArticlePromotionForArticlePromotionPageMutationVariables
    >(CreateArticlePromotionForArticlePromotionPageDocument, options);
}
export type CreateArticlePromotionForArticlePromotionPageMutationHookResult = ReturnType<
    typeof useCreateArticlePromotionForArticlePromotionPageMutation
>;
export type CreateArticlePromotionForArticlePromotionPageMutationResult =
    Apollo.MutationResult<CreateArticlePromotionForArticlePromotionPageMutation>;
export type CreateArticlePromotionForArticlePromotionPageMutationOptions =
    Apollo.BaseMutationOptions<
        CreateArticlePromotionForArticlePromotionPageMutation,
        CreateArticlePromotionForArticlePromotionPageMutationVariables
    >;
export const UpdateArticlePromotionForArticlePromotionPageDocument = gql`
    mutation UpdateArticlePromotionForArticlePromotionPage($input: UpdateArticlePromotionInput!) {
        updateArticlePromotion(input: $input) {
            success
            message
            resourceUpdated {
                ...ArticlePromotionFragment
            }
        }
    }
    ${ArticlePromotionFragmentFragmentDoc}
`;
export type UpdateArticlePromotionForArticlePromotionPageMutationFn = Apollo.MutationFunction<
    UpdateArticlePromotionForArticlePromotionPageMutation,
    UpdateArticlePromotionForArticlePromotionPageMutationVariables
>;

/**
 * __useUpdateArticlePromotionForArticlePromotionPageMutation__
 *
 * To run a mutation, you first call `useUpdateArticlePromotionForArticlePromotionPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticlePromotionForArticlePromotionPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticlePromotionForArticlePromotionPageMutation, { data, loading, error }] = useUpdateArticlePromotionForArticlePromotionPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateArticlePromotionForArticlePromotionPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateArticlePromotionForArticlePromotionPageMutation,
        UpdateArticlePromotionForArticlePromotionPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateArticlePromotionForArticlePromotionPageMutation,
        UpdateArticlePromotionForArticlePromotionPageMutationVariables
    >(UpdateArticlePromotionForArticlePromotionPageDocument, options);
}
export type UpdateArticlePromotionForArticlePromotionPageMutationHookResult = ReturnType<
    typeof useUpdateArticlePromotionForArticlePromotionPageMutation
>;
export type UpdateArticlePromotionForArticlePromotionPageMutationResult =
    Apollo.MutationResult<UpdateArticlePromotionForArticlePromotionPageMutation>;
export type UpdateArticlePromotionForArticlePromotionPageMutationOptions =
    Apollo.BaseMutationOptions<
        UpdateArticlePromotionForArticlePromotionPageMutation,
        UpdateArticlePromotionForArticlePromotionPageMutationVariables
    >;
export const ArticlePromotionForArticlePromotionPageDocument = gql`
    query ArticlePromotionForArticlePromotionPage($input: GetArticlePromotionInput!) {
        articlePromotion(input: $input) {
            ...ArticlePromotionFragment
        }
    }
    ${ArticlePromotionFragmentFragmentDoc}
`;

/**
 * __useArticlePromotionForArticlePromotionPageQuery__
 *
 * To run a query within a React component, call `useArticlePromotionForArticlePromotionPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlePromotionForArticlePromotionPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlePromotionForArticlePromotionPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticlePromotionForArticlePromotionPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        ArticlePromotionForArticlePromotionPageQuery,
        ArticlePromotionForArticlePromotionPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        ArticlePromotionForArticlePromotionPageQuery,
        ArticlePromotionForArticlePromotionPageQueryVariables
    >(ArticlePromotionForArticlePromotionPageDocument, options);
}
export function useArticlePromotionForArticlePromotionPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ArticlePromotionForArticlePromotionPageQuery,
        ArticlePromotionForArticlePromotionPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ArticlePromotionForArticlePromotionPageQuery,
        ArticlePromotionForArticlePromotionPageQueryVariables
    >(ArticlePromotionForArticlePromotionPageDocument, options);
}
export type ArticlePromotionForArticlePromotionPageQueryHookResult = ReturnType<
    typeof useArticlePromotionForArticlePromotionPageQuery
>;
export type ArticlePromotionForArticlePromotionPageLazyQueryHookResult = ReturnType<
    typeof useArticlePromotionForArticlePromotionPageLazyQuery
>;
export type ArticlePromotionForArticlePromotionPageQueryResult = Apollo.QueryResult<
    ArticlePromotionForArticlePromotionPageQuery,
    ArticlePromotionForArticlePromotionPageQueryVariables
>;
export const PreFetchAppsTagsTimelinesForArticlePromotionPageDocument = gql`
    query PreFetchAppsTagsTimelinesForArticlePromotionPage {
        whatsNewTimelines {
            id
            label {
                en
            }
        }
        amsApps {
            id
            bundleId
            productConst
        }
        tags {
            id
            name
        }
    }
`;

/**
 * __usePreFetchAppsTagsTimelinesForArticlePromotionPageQuery__
 *
 * To run a query within a React component, call `usePreFetchAppsTagsTimelinesForArticlePromotionPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreFetchAppsTagsTimelinesForArticlePromotionPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreFetchAppsTagsTimelinesForArticlePromotionPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreFetchAppsTagsTimelinesForArticlePromotionPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        PreFetchAppsTagsTimelinesForArticlePromotionPageQuery,
        PreFetchAppsTagsTimelinesForArticlePromotionPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        PreFetchAppsTagsTimelinesForArticlePromotionPageQuery,
        PreFetchAppsTagsTimelinesForArticlePromotionPageQueryVariables
    >(PreFetchAppsTagsTimelinesForArticlePromotionPageDocument, options);
}
export function usePreFetchAppsTagsTimelinesForArticlePromotionPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PreFetchAppsTagsTimelinesForArticlePromotionPageQuery,
        PreFetchAppsTagsTimelinesForArticlePromotionPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        PreFetchAppsTagsTimelinesForArticlePromotionPageQuery,
        PreFetchAppsTagsTimelinesForArticlePromotionPageQueryVariables
    >(PreFetchAppsTagsTimelinesForArticlePromotionPageDocument, options);
}
export type PreFetchAppsTagsTimelinesForArticlePromotionPageQueryHookResult = ReturnType<
    typeof usePreFetchAppsTagsTimelinesForArticlePromotionPageQuery
>;
export type PreFetchAppsTagsTimelinesForArticlePromotionPageLazyQueryHookResult = ReturnType<
    typeof usePreFetchAppsTagsTimelinesForArticlePromotionPageLazyQuery
>;
export type PreFetchAppsTagsTimelinesForArticlePromotionPageQueryResult = Apollo.QueryResult<
    PreFetchAppsTagsTimelinesForArticlePromotionPageQuery,
    PreFetchAppsTagsTimelinesForArticlePromotionPageQueryVariables
>;
export const FetchTimelinesForArticlePromotionPageDocument = gql`
    query FetchTimelinesForArticlePromotionPage {
        whatsNewTimelinesV2 {
            results {
                label {
                    en
                }
                id
                type
            }
        }
    }
`;

/**
 * __useFetchTimelinesForArticlePromotionPageQuery__
 *
 * To run a query within a React component, call `useFetchTimelinesForArticlePromotionPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTimelinesForArticlePromotionPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTimelinesForArticlePromotionPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchTimelinesForArticlePromotionPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchTimelinesForArticlePromotionPageQuery,
        FetchTimelinesForArticlePromotionPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchTimelinesForArticlePromotionPageQuery,
        FetchTimelinesForArticlePromotionPageQueryVariables
    >(FetchTimelinesForArticlePromotionPageDocument, options);
}
export function useFetchTimelinesForArticlePromotionPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchTimelinesForArticlePromotionPageQuery,
        FetchTimelinesForArticlePromotionPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchTimelinesForArticlePromotionPageQuery,
        FetchTimelinesForArticlePromotionPageQueryVariables
    >(FetchTimelinesForArticlePromotionPageDocument, options);
}
export type FetchTimelinesForArticlePromotionPageQueryHookResult = ReturnType<
    typeof useFetchTimelinesForArticlePromotionPageQuery
>;
export type FetchTimelinesForArticlePromotionPageLazyQueryHookResult = ReturnType<
    typeof useFetchTimelinesForArticlePromotionPageLazyQuery
>;
export type FetchTimelinesForArticlePromotionPageQueryResult = Apollo.QueryResult<
    FetchTimelinesForArticlePromotionPageQuery,
    FetchTimelinesForArticlePromotionPageQueryVariables
>;
export const DeleteArticlePromotionDocument = gql`
    mutation DeleteArticlePromotion($input: DeleteArticlePromotionInput!) {
        deleteArticlePromotion(input: $input) {
            success
            message
            status
            id
        }
    }
`;
export type DeleteArticlePromotionMutationFn = Apollo.MutationFunction<
    DeleteArticlePromotionMutation,
    DeleteArticlePromotionMutationVariables
>;

/**
 * __useDeleteArticlePromotionMutation__
 *
 * To run a mutation, you first call `useDeleteArticlePromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticlePromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticlePromotionMutation, { data, loading, error }] = useDeleteArticlePromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteArticlePromotionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteArticlePromotionMutation,
        DeleteArticlePromotionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteArticlePromotionMutation,
        DeleteArticlePromotionMutationVariables
    >(DeleteArticlePromotionDocument, options);
}
export type DeleteArticlePromotionMutationHookResult = ReturnType<
    typeof useDeleteArticlePromotionMutation
>;
export type DeleteArticlePromotionMutationResult =
    Apollo.MutationResult<DeleteArticlePromotionMutation>;
export type DeleteArticlePromotionMutationOptions = Apollo.BaseMutationOptions<
    DeleteArticlePromotionMutation,
    DeleteArticlePromotionMutationVariables
>;
export const ArticlePromotionsForArticlePromotionListPageDocument = gql`
    query ArticlePromotionsForArticlePromotionListPage($input: ArticlePromotionListInput!) {
        articlePromotionsV2(input: $input) {
            total
            results {
                ...ArticlePromotionFragment
            }
        }
    }
    ${ArticlePromotionFragmentFragmentDoc}
`;

/**
 * __useArticlePromotionsForArticlePromotionListPageQuery__
 *
 * To run a query within a React component, call `useArticlePromotionsForArticlePromotionListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlePromotionsForArticlePromotionListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlePromotionsForArticlePromotionListPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticlePromotionsForArticlePromotionListPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        ArticlePromotionsForArticlePromotionListPageQuery,
        ArticlePromotionsForArticlePromotionListPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        ArticlePromotionsForArticlePromotionListPageQuery,
        ArticlePromotionsForArticlePromotionListPageQueryVariables
    >(ArticlePromotionsForArticlePromotionListPageDocument, options);
}
export function useArticlePromotionsForArticlePromotionListPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ArticlePromotionsForArticlePromotionListPageQuery,
        ArticlePromotionsForArticlePromotionListPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ArticlePromotionsForArticlePromotionListPageQuery,
        ArticlePromotionsForArticlePromotionListPageQueryVariables
    >(ArticlePromotionsForArticlePromotionListPageDocument, options);
}
export type ArticlePromotionsForArticlePromotionListPageQueryHookResult = ReturnType<
    typeof useArticlePromotionsForArticlePromotionListPageQuery
>;
export type ArticlePromotionsForArticlePromotionListPageLazyQueryHookResult = ReturnType<
    typeof useArticlePromotionsForArticlePromotionListPageLazyQuery
>;
export type ArticlePromotionsForArticlePromotionListPageQueryResult = Apollo.QueryResult<
    ArticlePromotionsForArticlePromotionListPageQuery,
    ArticlePromotionsForArticlePromotionListPageQueryVariables
>;
export const ArticleListDocument = gql`
    query ArticleList($input: ArticleListInput!) {
        articlesV2(input: $input) {
            total
            results {
                name
                id
                description
                keywords
                articleCategory {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useArticleListQuery__
 *
 * To run a query within a React component, call `useArticleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticleListQuery(
    baseOptions: Apollo.QueryHookOptions<ArticleListQuery, ArticleListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ArticleListQuery, ArticleListQueryVariables>(
        ArticleListDocument,
        options,
    );
}
export function useArticleListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ArticleListQuery, ArticleListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ArticleListQuery, ArticleListQueryVariables>(
        ArticleListDocument,
        options,
    );
}
export type ArticleListQueryHookResult = ReturnType<typeof useArticleListQuery>;
export type ArticleListLazyQueryHookResult = ReturnType<typeof useArticleListLazyQuery>;
export type ArticleListQueryResult = Apollo.QueryResult<
    ArticleListQuery,
    ArticleListQueryVariables
>;
export const ArticleByIdDocument = gql`
    query ArticleById($input: GetArticleInput!) {
        article(input: $input) {
            ...ArticleFragment
        }
    }
    ${ArticleFragmentFragmentDoc}
`;

/**
 * __useArticleByIdQuery__
 *
 * To run a query within a React component, call `useArticleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticleByIdQuery(
    baseOptions: Apollo.QueryHookOptions<ArticleByIdQuery, ArticleByIdQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ArticleByIdQuery, ArticleByIdQueryVariables>(
        ArticleByIdDocument,
        options,
    );
}
export function useArticleByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ArticleByIdQuery, ArticleByIdQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ArticleByIdQuery, ArticleByIdQueryVariables>(
        ArticleByIdDocument,
        options,
    );
}
export type ArticleByIdQueryHookResult = ReturnType<typeof useArticleByIdQuery>;
export type ArticleByIdLazyQueryHookResult = ReturnType<typeof useArticleByIdLazyQuery>;
export type ArticleByIdQueryResult = Apollo.QueryResult<
    ArticleByIdQuery,
    ArticleByIdQueryVariables
>;
export const CreateArticleDocument = gql`
    mutation CreateArticle($input: ArticleInput!) {
        createArticleEncoded(input: $input) {
            status
            message
            success
            resourceCreated {
                ...ArticleFragment
            }
        }
    }
    ${ArticleFragmentFragmentDoc}
`;
export type CreateArticleMutationFn = Apollo.MutationFunction<
    CreateArticleMutation,
    CreateArticleMutationVariables
>;

/**
 * __useCreateArticleMutation__
 *
 * To run a mutation, you first call `useCreateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleMutation, { data, loading, error }] = useCreateArticleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArticleMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateArticleMutation, CreateArticleMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateArticleMutation, CreateArticleMutationVariables>(
        CreateArticleDocument,
        options,
    );
}
export type CreateArticleMutationHookResult = ReturnType<typeof useCreateArticleMutation>;
export type CreateArticleMutationResult = Apollo.MutationResult<CreateArticleMutation>;
export type CreateArticleMutationOptions = Apollo.BaseMutationOptions<
    CreateArticleMutation,
    CreateArticleMutationVariables
>;
export const UpdateArticleDocument = gql`
    mutation UpdateArticle($updateArticleInput: UpdateArticleEncodedInput!) {
        updateArticleEncoded(input: $updateArticleInput) {
            status
            message
            success
            resourceUpdated {
                ...ArticleFragment
            }
        }
    }
    ${ArticleFragmentFragmentDoc}
`;
export type UpdateArticleMutationFn = Apollo.MutationFunction<
    UpdateArticleMutation,
    UpdateArticleMutationVariables
>;

/**
 * __useUpdateArticleMutation__
 *
 * To run a mutation, you first call `useUpdateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleMutation, { data, loading, error }] = useUpdateArticleMutation({
 *   variables: {
 *      updateArticleInput: // value for 'updateArticleInput'
 *   },
 * });
 */
export function useUpdateArticleMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateArticleMutation, UpdateArticleMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateArticleMutation, UpdateArticleMutationVariables>(
        UpdateArticleDocument,
        options,
    );
}
export type UpdateArticleMutationHookResult = ReturnType<typeof useUpdateArticleMutation>;
export type UpdateArticleMutationResult = Apollo.MutationResult<UpdateArticleMutation>;
export type UpdateArticleMutationOptions = Apollo.BaseMutationOptions<
    UpdateArticleMutation,
    UpdateArticleMutationVariables
>;
export const UpsertArticleVariantDocument = gql`
    mutation UpsertArticleVariant($articleId: String!, $variant: ContentVariantInput!) {
        upsertArticleVariant(articleId: $articleId, variant: $variant) {
            status
            message
            success
            updatedArticle {
                ...ArticleFragment
            }
        }
    }
    ${ArticleFragmentFragmentDoc}
`;
export type UpsertArticleVariantMutationFn = Apollo.MutationFunction<
    UpsertArticleVariantMutation,
    UpsertArticleVariantMutationVariables
>;

/**
 * __useUpsertArticleVariantMutation__
 *
 * To run a mutation, you first call `useUpsertArticleVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertArticleVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertArticleVariantMutation, { data, loading, error }] = useUpsertArticleVariantMutation({
 *   variables: {
 *      articleId: // value for 'articleId'
 *      variant: // value for 'variant'
 *   },
 * });
 */
export function useUpsertArticleVariantMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpsertArticleVariantMutation,
        UpsertArticleVariantMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpsertArticleVariantMutation, UpsertArticleVariantMutationVariables>(
        UpsertArticleVariantDocument,
        options,
    );
}
export type UpsertArticleVariantMutationHookResult = ReturnType<
    typeof useUpsertArticleVariantMutation
>;
export type UpsertArticleVariantMutationResult =
    Apollo.MutationResult<UpsertArticleVariantMutation>;
export type UpsertArticleVariantMutationOptions = Apollo.BaseMutationOptions<
    UpsertArticleVariantMutation,
    UpsertArticleVariantMutationVariables
>;
export const PublishArticleDocument = gql`
    mutation PublishArticle($input: String!) {
        publishArticle(input: $input) {
            status
            message
            success
        }
    }
`;
export type PublishArticleMutationFn = Apollo.MutationFunction<
    PublishArticleMutation,
    PublishArticleMutationVariables
>;

/**
 * __usePublishArticleMutation__
 *
 * To run a mutation, you first call `usePublishArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishArticleMutation, { data, loading, error }] = usePublishArticleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishArticleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        PublishArticleMutation,
        PublishArticleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<PublishArticleMutation, PublishArticleMutationVariables>(
        PublishArticleDocument,
        options,
    );
}
export type PublishArticleMutationHookResult = ReturnType<typeof usePublishArticleMutation>;
export type PublishArticleMutationResult = Apollo.MutationResult<PublishArticleMutation>;
export type PublishArticleMutationOptions = Apollo.BaseMutationOptions<
    PublishArticleMutation,
    PublishArticleMutationVariables
>;
export const CloneVariantDocument = gql`
    mutation CloneVariant($input: CloneVariantInput!) {
        cloneVariant(input: $input) {
            status
            message
            success
            language
            readingLevel
            appBundleId
            article {
                ...ArticleFragment
            }
        }
    }
    ${ArticleFragmentFragmentDoc}
`;
export type CloneVariantMutationFn = Apollo.MutationFunction<
    CloneVariantMutation,
    CloneVariantMutationVariables
>;

/**
 * __useCloneVariantMutation__
 *
 * To run a mutation, you first call `useCloneVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneVariantMutation, { data, loading, error }] = useCloneVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloneVariantMutation(
    baseOptions?: Apollo.MutationHookOptions<CloneVariantMutation, CloneVariantMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CloneVariantMutation, CloneVariantMutationVariables>(
        CloneVariantDocument,
        options,
    );
}
export type CloneVariantMutationHookResult = ReturnType<typeof useCloneVariantMutation>;
export type CloneVariantMutationResult = Apollo.MutationResult<CloneVariantMutation>;
export type CloneVariantMutationOptions = Apollo.BaseMutationOptions<
    CloneVariantMutation,
    CloneVariantMutationVariables
>;
export const DeleteVariantDocument = gql`
    mutation DeleteVariant($input: DeleteVariantInput!) {
        deleteVariant(input: $input) {
            status
            message
            success
            article {
                ...ArticleFragment
            }
        }
    }
    ${ArticleFragmentFragmentDoc}
`;
export type DeleteVariantMutationFn = Apollo.MutationFunction<
    DeleteVariantMutation,
    DeleteVariantMutationVariables
>;

/**
 * __useDeleteVariantMutation__
 *
 * To run a mutation, you first call `useDeleteVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVariantMutation, { data, loading, error }] = useDeleteVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVariantMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteVariantMutation, DeleteVariantMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteVariantMutation, DeleteVariantMutationVariables>(
        DeleteVariantDocument,
        options,
    );
}
export type DeleteVariantMutationHookResult = ReturnType<typeof useDeleteVariantMutation>;
export type DeleteVariantMutationResult = Apollo.MutationResult<DeleteVariantMutation>;
export type DeleteVariantMutationOptions = Apollo.BaseMutationOptions<
    DeleteVariantMutation,
    DeleteVariantMutationVariables
>;
export const DeleteArticleDocument = gql`
    mutation DeleteArticle($input: String!) {
        deleteArticle(input: $input) {
            status
            message
            success
            id
        }
    }
`;
export type DeleteArticleMutationFn = Apollo.MutationFunction<
    DeleteArticleMutation,
    DeleteArticleMutationVariables
>;

/**
 * __useDeleteArticleMutation__
 *
 * To run a mutation, you first call `useDeleteArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleMutation, { data, loading, error }] = useDeleteArticleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteArticleMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteArticleMutation, DeleteArticleMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteArticleMutation, DeleteArticleMutationVariables>(
        DeleteArticleDocument,
        options,
    );
}
export type DeleteArticleMutationHookResult = ReturnType<typeof useDeleteArticleMutation>;
export type DeleteArticleMutationResult = Apollo.MutationResult<DeleteArticleMutation>;
export type DeleteArticleMutationOptions = Apollo.BaseMutationOptions<
    DeleteArticleMutation,
    DeleteArticleMutationVariables
>;
export const ArticleListSimpleDocument = gql`
    query ArticleListSimple {
        articlesV2 {
            results {
                name
                id
                articleCategory {
                    id
                }
            }
        }
    }
`;

/**
 * __useArticleListSimpleQuery__
 *
 * To run a query within a React component, call `useArticleListSimpleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleListSimpleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleListSimpleQuery({
 *   variables: {
 *   },
 * });
 */
export function useArticleListSimpleQuery(
    baseOptions?: Apollo.QueryHookOptions<ArticleListSimpleQuery, ArticleListSimpleQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ArticleListSimpleQuery, ArticleListSimpleQueryVariables>(
        ArticleListSimpleDocument,
        options,
    );
}
export function useArticleListSimpleLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ArticleListSimpleQuery,
        ArticleListSimpleQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ArticleListSimpleQuery, ArticleListSimpleQueryVariables>(
        ArticleListSimpleDocument,
        options,
    );
}
export type ArticleListSimpleQueryHookResult = ReturnType<typeof useArticleListSimpleQuery>;
export type ArticleListSimpleLazyQueryHookResult = ReturnType<typeof useArticleListSimpleLazyQuery>;
export type ArticleListSimpleQueryResult = Apollo.QueryResult<
    ArticleListSimpleQuery,
    ArticleListSimpleQueryVariables
>;
export const ArticleApprovalsDocument = gql`
    query ArticleApprovals($input: ArticleApprovalQueryInput!) {
        articleApprovalsV2(input: $input) {
            results {
                ...ArticleApprovalFragment
            }
        }
    }
    ${ArticleApprovalFragmentFragmentDoc}
`;

/**
 * __useArticleApprovalsQuery__
 *
 * To run a query within a React component, call `useArticleApprovalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleApprovalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleApprovalsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticleApprovalsQuery(
    baseOptions: Apollo.QueryHookOptions<ArticleApprovalsQuery, ArticleApprovalsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ArticleApprovalsQuery, ArticleApprovalsQueryVariables>(
        ArticleApprovalsDocument,
        options,
    );
}
export function useArticleApprovalsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ArticleApprovalsQuery,
        ArticleApprovalsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ArticleApprovalsQuery, ArticleApprovalsQueryVariables>(
        ArticleApprovalsDocument,
        options,
    );
}
export type ArticleApprovalsQueryHookResult = ReturnType<typeof useArticleApprovalsQuery>;
export type ArticleApprovalsLazyQueryHookResult = ReturnType<typeof useArticleApprovalsLazyQuery>;
export type ArticleApprovalsQueryResult = Apollo.QueryResult<
    ArticleApprovalsQuery,
    ArticleApprovalsQueryVariables
>;
export const CreateBatchTaskDocument = gql`
    mutation CreateBatchTask($input: BatchTaskInput!) {
        createBatchTask(input: $input) {
            status
            message
            success
        }
    }
`;
export type CreateBatchTaskMutationFn = Apollo.MutationFunction<
    CreateBatchTaskMutation,
    CreateBatchTaskMutationVariables
>;

/**
 * __useCreateBatchTaskMutation__
 *
 * To run a mutation, you first call `useCreateBatchTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBatchTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBatchTaskMutation, { data, loading, error }] = useCreateBatchTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBatchTaskMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateBatchTaskMutation,
        CreateBatchTaskMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateBatchTaskMutation, CreateBatchTaskMutationVariables>(
        CreateBatchTaskDocument,
        options,
    );
}
export type CreateBatchTaskMutationHookResult = ReturnType<typeof useCreateBatchTaskMutation>;
export type CreateBatchTaskMutationResult = Apollo.MutationResult<CreateBatchTaskMutation>;
export type CreateBatchTaskMutationOptions = Apollo.BaseMutationOptions<
    CreateBatchTaskMutation,
    CreateBatchTaskMutationVariables
>;
export const ArticleWhereUsedDocument = gql`
    query ArticleWhereUsed($articleId: String!) {
        articleWhereUsed(articleId: $articleId) {
            dependencies {
                entityType
                blocksDelete
                total
                entries {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useArticleWhereUsedQuery__
 *
 * To run a query within a React component, call `useArticleWhereUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleWhereUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleWhereUsedQuery({
 *   variables: {
 *      articleId: // value for 'articleId'
 *   },
 * });
 */
export function useArticleWhereUsedQuery(
    baseOptions: Apollo.QueryHookOptions<ArticleWhereUsedQuery, ArticleWhereUsedQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ArticleWhereUsedQuery, ArticleWhereUsedQueryVariables>(
        ArticleWhereUsedDocument,
        options,
    );
}
export function useArticleWhereUsedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ArticleWhereUsedQuery,
        ArticleWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ArticleWhereUsedQuery, ArticleWhereUsedQueryVariables>(
        ArticleWhereUsedDocument,
        options,
    );
}
export type ArticleWhereUsedQueryHookResult = ReturnType<typeof useArticleWhereUsedQuery>;
export type ArticleWhereUsedLazyQueryHookResult = ReturnType<typeof useArticleWhereUsedLazyQuery>;
export type ArticleWhereUsedQueryResult = Apollo.QueryResult<
    ArticleWhereUsedQuery,
    ArticleWhereUsedQueryVariables
>;
export const CloneArticleDocument = gql`
    mutation CloneArticle($input: CloneArticleInput!) {
        cloneArticle(input: $input) {
            status
            message
            success
            article {
                id
            }
        }
    }
`;
export type CloneArticleMutationFn = Apollo.MutationFunction<
    CloneArticleMutation,
    CloneArticleMutationVariables
>;

/**
 * __useCloneArticleMutation__
 *
 * To run a mutation, you first call `useCloneArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneArticleMutation, { data, loading, error }] = useCloneArticleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloneArticleMutation(
    baseOptions?: Apollo.MutationHookOptions<CloneArticleMutation, CloneArticleMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CloneArticleMutation, CloneArticleMutationVariables>(
        CloneArticleDocument,
        options,
    );
}
export type CloneArticleMutationHookResult = ReturnType<typeof useCloneArticleMutation>;
export type CloneArticleMutationResult = Apollo.MutationResult<CloneArticleMutation>;
export type CloneArticleMutationOptions = Apollo.BaseMutationOptions<
    CloneArticleMutation,
    CloneArticleMutationVariables
>;
export const DeleteBioScreenForBioScreenBuilderDocument = gql`
    mutation DeleteBioScreenForBioScreenBuilder($deleteBioScreenInput: DeleteBioScreenInput!) {
        deleteBioScreen(input: $deleteBioScreenInput) {
            success
            message
            id
        }
    }
`;
export type DeleteBioScreenForBioScreenBuilderMutationFn = Apollo.MutationFunction<
    DeleteBioScreenForBioScreenBuilderMutation,
    DeleteBioScreenForBioScreenBuilderMutationVariables
>;

/**
 * __useDeleteBioScreenForBioScreenBuilderMutation__
 *
 * To run a mutation, you first call `useDeleteBioScreenForBioScreenBuilderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBioScreenForBioScreenBuilderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBioScreenForBioScreenBuilderMutation, { data, loading, error }] = useDeleteBioScreenForBioScreenBuilderMutation({
 *   variables: {
 *      deleteBioScreenInput: // value for 'deleteBioScreenInput'
 *   },
 * });
 */
export function useDeleteBioScreenForBioScreenBuilderMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteBioScreenForBioScreenBuilderMutation,
        DeleteBioScreenForBioScreenBuilderMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteBioScreenForBioScreenBuilderMutation,
        DeleteBioScreenForBioScreenBuilderMutationVariables
    >(DeleteBioScreenForBioScreenBuilderDocument, options);
}
export type DeleteBioScreenForBioScreenBuilderMutationHookResult = ReturnType<
    typeof useDeleteBioScreenForBioScreenBuilderMutation
>;
export type DeleteBioScreenForBioScreenBuilderMutationResult =
    Apollo.MutationResult<DeleteBioScreenForBioScreenBuilderMutation>;
export type DeleteBioScreenForBioScreenBuilderMutationOptions = Apollo.BaseMutationOptions<
    DeleteBioScreenForBioScreenBuilderMutation,
    DeleteBioScreenForBioScreenBuilderMutationVariables
>;
export const CloneBioScreenForBioScreenCloneModalDocument = gql`
    mutation CloneBioScreenForBioScreenCloneModal($input: CloneBioScreenInput!) {
        cloneBioScreen(input: $input) {
            success
            message
            bioScreen {
                ...BioScreenForBioScreenBuilder
            }
        }
    }
    ${BioScreenForBioScreenBuilderFragmentDoc}
`;
export type CloneBioScreenForBioScreenCloneModalMutationFn = Apollo.MutationFunction<
    CloneBioScreenForBioScreenCloneModalMutation,
    CloneBioScreenForBioScreenCloneModalMutationVariables
>;

/**
 * __useCloneBioScreenForBioScreenCloneModalMutation__
 *
 * To run a mutation, you first call `useCloneBioScreenForBioScreenCloneModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneBioScreenForBioScreenCloneModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneBioScreenForBioScreenCloneModalMutation, { data, loading, error }] = useCloneBioScreenForBioScreenCloneModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloneBioScreenForBioScreenCloneModalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CloneBioScreenForBioScreenCloneModalMutation,
        CloneBioScreenForBioScreenCloneModalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CloneBioScreenForBioScreenCloneModalMutation,
        CloneBioScreenForBioScreenCloneModalMutationVariables
    >(CloneBioScreenForBioScreenCloneModalDocument, options);
}
export type CloneBioScreenForBioScreenCloneModalMutationHookResult = ReturnType<
    typeof useCloneBioScreenForBioScreenCloneModalMutation
>;
export type CloneBioScreenForBioScreenCloneModalMutationResult =
    Apollo.MutationResult<CloneBioScreenForBioScreenCloneModalMutation>;
export type CloneBioScreenForBioScreenCloneModalMutationOptions = Apollo.BaseMutationOptions<
    CloneBioScreenForBioScreenCloneModalMutation,
    CloneBioScreenForBioScreenCloneModalMutationVariables
>;
export const BioScreensForProfileScreensPageDocument = gql`
    query BioScreensForProfileScreensPage {
        bioScreensV2 {
            results {
                id
                createdAt
                updatedAt
                yourHealthInfo {
                    icon
                }
                label {
                    en
                }
            }
        }
    }
`;

/**
 * __useBioScreensForProfileScreensPageQuery__
 *
 * To run a query within a React component, call `useBioScreensForProfileScreensPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBioScreensForProfileScreensPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBioScreensForProfileScreensPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useBioScreensForProfileScreensPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        BioScreensForProfileScreensPageQuery,
        BioScreensForProfileScreensPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        BioScreensForProfileScreensPageQuery,
        BioScreensForProfileScreensPageQueryVariables
    >(BioScreensForProfileScreensPageDocument, options);
}
export function useBioScreensForProfileScreensPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        BioScreensForProfileScreensPageQuery,
        BioScreensForProfileScreensPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        BioScreensForProfileScreensPageQuery,
        BioScreensForProfileScreensPageQueryVariables
    >(BioScreensForProfileScreensPageDocument, options);
}
export type BioScreensForProfileScreensPageQueryHookResult = ReturnType<
    typeof useBioScreensForProfileScreensPageQuery
>;
export type BioScreensForProfileScreensPageLazyQueryHookResult = ReturnType<
    typeof useBioScreensForProfileScreensPageLazyQuery
>;
export type BioScreensForProfileScreensPageQueryResult = Apollo.QueryResult<
    BioScreensForProfileScreensPageQuery,
    BioScreensForProfileScreensPageQueryVariables
>;
export const UpdateBioScreenForBioScreenBuilderDocument = gql`
    mutation UpdateBioScreenForBioScreenBuilder($bioScreenUpdateInput: UpdateBioScreenInput!) {
        updateBioScreen(input: $bioScreenUpdateInput) {
            success
            message
            resourceUpdated {
                ...BioScreenForBioScreenBuilder
            }
        }
    }
    ${BioScreenForBioScreenBuilderFragmentDoc}
`;
export type UpdateBioScreenForBioScreenBuilderMutationFn = Apollo.MutationFunction<
    UpdateBioScreenForBioScreenBuilderMutation,
    UpdateBioScreenForBioScreenBuilderMutationVariables
>;

/**
 * __useUpdateBioScreenForBioScreenBuilderMutation__
 *
 * To run a mutation, you first call `useUpdateBioScreenForBioScreenBuilderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBioScreenForBioScreenBuilderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBioScreenForBioScreenBuilderMutation, { data, loading, error }] = useUpdateBioScreenForBioScreenBuilderMutation({
 *   variables: {
 *      bioScreenUpdateInput: // value for 'bioScreenUpdateInput'
 *   },
 * });
 */
export function useUpdateBioScreenForBioScreenBuilderMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateBioScreenForBioScreenBuilderMutation,
        UpdateBioScreenForBioScreenBuilderMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateBioScreenForBioScreenBuilderMutation,
        UpdateBioScreenForBioScreenBuilderMutationVariables
    >(UpdateBioScreenForBioScreenBuilderDocument, options);
}
export type UpdateBioScreenForBioScreenBuilderMutationHookResult = ReturnType<
    typeof useUpdateBioScreenForBioScreenBuilderMutation
>;
export type UpdateBioScreenForBioScreenBuilderMutationResult =
    Apollo.MutationResult<UpdateBioScreenForBioScreenBuilderMutation>;
export type UpdateBioScreenForBioScreenBuilderMutationOptions = Apollo.BaseMutationOptions<
    UpdateBioScreenForBioScreenBuilderMutation,
    UpdateBioScreenForBioScreenBuilderMutationVariables
>;
export const CreateBioScreenForBioScreenBuilderDocument = gql`
    mutation CreateBioScreenForBioScreenBuilder($createBioScreenInput: BioScreenInput!) {
        createBioScreen(input: $createBioScreenInput) {
            success
            message
            resourceCreated {
                ...BioScreenForBioScreenBuilder
            }
        }
    }
    ${BioScreenForBioScreenBuilderFragmentDoc}
`;
export type CreateBioScreenForBioScreenBuilderMutationFn = Apollo.MutationFunction<
    CreateBioScreenForBioScreenBuilderMutation,
    CreateBioScreenForBioScreenBuilderMutationVariables
>;

/**
 * __useCreateBioScreenForBioScreenBuilderMutation__
 *
 * To run a mutation, you first call `useCreateBioScreenForBioScreenBuilderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBioScreenForBioScreenBuilderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBioScreenForBioScreenBuilderMutation, { data, loading, error }] = useCreateBioScreenForBioScreenBuilderMutation({
 *   variables: {
 *      createBioScreenInput: // value for 'createBioScreenInput'
 *   },
 * });
 */
export function useCreateBioScreenForBioScreenBuilderMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateBioScreenForBioScreenBuilderMutation,
        CreateBioScreenForBioScreenBuilderMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateBioScreenForBioScreenBuilderMutation,
        CreateBioScreenForBioScreenBuilderMutationVariables
    >(CreateBioScreenForBioScreenBuilderDocument, options);
}
export type CreateBioScreenForBioScreenBuilderMutationHookResult = ReturnType<
    typeof useCreateBioScreenForBioScreenBuilderMutation
>;
export type CreateBioScreenForBioScreenBuilderMutationResult =
    Apollo.MutationResult<CreateBioScreenForBioScreenBuilderMutation>;
export type CreateBioScreenForBioScreenBuilderMutationOptions = Apollo.BaseMutationOptions<
    CreateBioScreenForBioScreenBuilderMutation,
    CreateBioScreenForBioScreenBuilderMutationVariables
>;
export const FetchBioScreenForBioScreenBuilderDocument = gql`
    query FetchBioScreenForBioScreenBuilder($bioScreenInput: GetBioScreenInput!) {
        bioScreen(input: $bioScreenInput) {
            ...BioScreenForBioScreenBuilder
        }
    }
    ${BioScreenForBioScreenBuilderFragmentDoc}
`;

/**
 * __useFetchBioScreenForBioScreenBuilderQuery__
 *
 * To run a query within a React component, call `useFetchBioScreenForBioScreenBuilderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchBioScreenForBioScreenBuilderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchBioScreenForBioScreenBuilderQuery({
 *   variables: {
 *      bioScreenInput: // value for 'bioScreenInput'
 *   },
 * });
 */
export function useFetchBioScreenForBioScreenBuilderQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchBioScreenForBioScreenBuilderQuery,
        FetchBioScreenForBioScreenBuilderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchBioScreenForBioScreenBuilderQuery,
        FetchBioScreenForBioScreenBuilderQueryVariables
    >(FetchBioScreenForBioScreenBuilderDocument, options);
}
export function useFetchBioScreenForBioScreenBuilderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchBioScreenForBioScreenBuilderQuery,
        FetchBioScreenForBioScreenBuilderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchBioScreenForBioScreenBuilderQuery,
        FetchBioScreenForBioScreenBuilderQueryVariables
    >(FetchBioScreenForBioScreenBuilderDocument, options);
}
export type FetchBioScreenForBioScreenBuilderQueryHookResult = ReturnType<
    typeof useFetchBioScreenForBioScreenBuilderQuery
>;
export type FetchBioScreenForBioScreenBuilderLazyQueryHookResult = ReturnType<
    typeof useFetchBioScreenForBioScreenBuilderLazyQuery
>;
export type FetchBioScreenForBioScreenBuilderQueryResult = Apollo.QueryResult<
    FetchBioScreenForBioScreenBuilderQuery,
    FetchBioScreenForBioScreenBuilderQueryVariables
>;
export const FetchTagsForBioScreensBuilderDocument = gql`
    query FetchTagsForBioScreensBuilder {
        tagsV2 {
            results {
                name
                id
            }
        }
    }
`;

/**
 * __useFetchTagsForBioScreensBuilderQuery__
 *
 * To run a query within a React component, call `useFetchTagsForBioScreensBuilderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTagsForBioScreensBuilderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTagsForBioScreensBuilderQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchTagsForBioScreensBuilderQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchTagsForBioScreensBuilderQuery,
        FetchTagsForBioScreensBuilderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchTagsForBioScreensBuilderQuery,
        FetchTagsForBioScreensBuilderQueryVariables
    >(FetchTagsForBioScreensBuilderDocument, options);
}
export function useFetchTagsForBioScreensBuilderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchTagsForBioScreensBuilderQuery,
        FetchTagsForBioScreensBuilderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchTagsForBioScreensBuilderQuery,
        FetchTagsForBioScreensBuilderQueryVariables
    >(FetchTagsForBioScreensBuilderDocument, options);
}
export type FetchTagsForBioScreensBuilderQueryHookResult = ReturnType<
    typeof useFetchTagsForBioScreensBuilderQuery
>;
export type FetchTagsForBioScreensBuilderLazyQueryHookResult = ReturnType<
    typeof useFetchTagsForBioScreensBuilderLazyQuery
>;
export type FetchTagsForBioScreensBuilderQueryResult = Apollo.QueryResult<
    FetchTagsForBioScreensBuilderQuery,
    FetchTagsForBioScreensBuilderQueryVariables
>;
export const FetchSurveyDefsForBioScreenBuilderDocument = gql`
    query FetchSurveyDefsForBioScreenBuilder {
        surveyDefs {
            id
            name
            label {
                en
                es
            }
        }
    }
`;

/**
 * __useFetchSurveyDefsForBioScreenBuilderQuery__
 *
 * To run a query within a React component, call `useFetchSurveyDefsForBioScreenBuilderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSurveyDefsForBioScreenBuilderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSurveyDefsForBioScreenBuilderQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchSurveyDefsForBioScreenBuilderQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchSurveyDefsForBioScreenBuilderQuery,
        FetchSurveyDefsForBioScreenBuilderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchSurveyDefsForBioScreenBuilderQuery,
        FetchSurveyDefsForBioScreenBuilderQueryVariables
    >(FetchSurveyDefsForBioScreenBuilderDocument, options);
}
export function useFetchSurveyDefsForBioScreenBuilderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchSurveyDefsForBioScreenBuilderQuery,
        FetchSurveyDefsForBioScreenBuilderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchSurveyDefsForBioScreenBuilderQuery,
        FetchSurveyDefsForBioScreenBuilderQueryVariables
    >(FetchSurveyDefsForBioScreenBuilderDocument, options);
}
export type FetchSurveyDefsForBioScreenBuilderQueryHookResult = ReturnType<
    typeof useFetchSurveyDefsForBioScreenBuilderQuery
>;
export type FetchSurveyDefsForBioScreenBuilderLazyQueryHookResult = ReturnType<
    typeof useFetchSurveyDefsForBioScreenBuilderLazyQuery
>;
export type FetchSurveyDefsForBioScreenBuilderQueryResult = Apollo.QueryResult<
    FetchSurveyDefsForBioScreenBuilderQuery,
    FetchSurveyDefsForBioScreenBuilderQueryVariables
>;
export const CreateCalendarForCalendarModalDocument = gql`
    mutation CreateCalendarForCalendarModal($input: CalendarInput!) {
        createCalendar(input: $input) {
            message
            status
            success
            result {
                id
                name
                startDate
                endDate
                offDays
                startTime
                endTime
                days {
                    date
                    endOfWorkDay
                    startOfWorkDay
                    workDay
                }
            }
        }
    }
`;
export type CreateCalendarForCalendarModalMutationFn = Apollo.MutationFunction<
    CreateCalendarForCalendarModalMutation,
    CreateCalendarForCalendarModalMutationVariables
>;

/**
 * __useCreateCalendarForCalendarModalMutation__
 *
 * To run a mutation, you first call `useCreateCalendarForCalendarModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarForCalendarModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarForCalendarModalMutation, { data, loading, error }] = useCreateCalendarForCalendarModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCalendarForCalendarModalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateCalendarForCalendarModalMutation,
        CreateCalendarForCalendarModalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateCalendarForCalendarModalMutation,
        CreateCalendarForCalendarModalMutationVariables
    >(CreateCalendarForCalendarModalDocument, options);
}
export type CreateCalendarForCalendarModalMutationHookResult = ReturnType<
    typeof useCreateCalendarForCalendarModalMutation
>;
export type CreateCalendarForCalendarModalMutationResult =
    Apollo.MutationResult<CreateCalendarForCalendarModalMutation>;
export type CreateCalendarForCalendarModalMutationOptions = Apollo.BaseMutationOptions<
    CreateCalendarForCalendarModalMutation,
    CreateCalendarForCalendarModalMutationVariables
>;
export const UpdateCalendarForCalendarModalDocument = gql`
    mutation UpdateCalendarForCalendarModal($input: CalendarUpdateInput!) {
        updateCalendar(input: $input) {
            message
            status
            success
            updated {
                id
                name
                startDate
                endDate
                offDays
                startTime
                endTime
                days {
                    date
                    startOfWorkDay
                    endOfWorkDay
                    workDay
                }
            }
        }
    }
`;
export type UpdateCalendarForCalendarModalMutationFn = Apollo.MutationFunction<
    UpdateCalendarForCalendarModalMutation,
    UpdateCalendarForCalendarModalMutationVariables
>;

/**
 * __useUpdateCalendarForCalendarModalMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarForCalendarModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarForCalendarModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarForCalendarModalMutation, { data, loading, error }] = useUpdateCalendarForCalendarModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCalendarForCalendarModalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateCalendarForCalendarModalMutation,
        UpdateCalendarForCalendarModalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateCalendarForCalendarModalMutation,
        UpdateCalendarForCalendarModalMutationVariables
    >(UpdateCalendarForCalendarModalDocument, options);
}
export type UpdateCalendarForCalendarModalMutationHookResult = ReturnType<
    typeof useUpdateCalendarForCalendarModalMutation
>;
export type UpdateCalendarForCalendarModalMutationResult =
    Apollo.MutationResult<UpdateCalendarForCalendarModalMutation>;
export type UpdateCalendarForCalendarModalMutationOptions = Apollo.BaseMutationOptions<
    UpdateCalendarForCalendarModalMutation,
    UpdateCalendarForCalendarModalMutationVariables
>;
export const FetchCalendarsV2ForCalendarsPageDocument = gql`
    query FetchCalendarsV2ForCalendarsPage($input: CalendarQueryInput) {
        calendarsV2(input: $input) {
            results {
                id
                name
                startDate
                endDate
                offDays
                startTime
                endTime
                days {
                    date
                    endOfWorkDay
                    startOfWorkDay
                    workDay
                }
                isTestData
            }
        }
    }
`;

/**
 * __useFetchCalendarsV2ForCalendarsPageQuery__
 *
 * To run a query within a React component, call `useFetchCalendarsV2ForCalendarsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCalendarsV2ForCalendarsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCalendarsV2ForCalendarsPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchCalendarsV2ForCalendarsPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchCalendarsV2ForCalendarsPageQuery,
        FetchCalendarsV2ForCalendarsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchCalendarsV2ForCalendarsPageQuery,
        FetchCalendarsV2ForCalendarsPageQueryVariables
    >(FetchCalendarsV2ForCalendarsPageDocument, options);
}
export function useFetchCalendarsV2ForCalendarsPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchCalendarsV2ForCalendarsPageQuery,
        FetchCalendarsV2ForCalendarsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchCalendarsV2ForCalendarsPageQuery,
        FetchCalendarsV2ForCalendarsPageQueryVariables
    >(FetchCalendarsV2ForCalendarsPageDocument, options);
}
export type FetchCalendarsV2ForCalendarsPageQueryHookResult = ReturnType<
    typeof useFetchCalendarsV2ForCalendarsPageQuery
>;
export type FetchCalendarsV2ForCalendarsPageLazyQueryHookResult = ReturnType<
    typeof useFetchCalendarsV2ForCalendarsPageLazyQuery
>;
export type FetchCalendarsV2ForCalendarsPageQueryResult = Apollo.QueryResult<
    FetchCalendarsV2ForCalendarsPageQuery,
    FetchCalendarsV2ForCalendarsPageQueryVariables
>;
export const CreateCarePlanForNewCarePlanPageDocument = gql`
    mutation CreateCarePlanForNewCarePlanPage($input: CarePlanInput!) {
        createCarePlan(input: $input) {
            success
            message
            resourceCreated {
                ...CarePlanFragment
                baseCarePlan {
                    portalLabel {
                        en
                    }
                }
            }
        }
    }
    ${CarePlanFragmentFragmentDoc}
`;
export type CreateCarePlanForNewCarePlanPageMutationFn = Apollo.MutationFunction<
    CreateCarePlanForNewCarePlanPageMutation,
    CreateCarePlanForNewCarePlanPageMutationVariables
>;

/**
 * __useCreateCarePlanForNewCarePlanPageMutation__
 *
 * To run a mutation, you first call `useCreateCarePlanForNewCarePlanPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCarePlanForNewCarePlanPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCarePlanForNewCarePlanPageMutation, { data, loading, error }] = useCreateCarePlanForNewCarePlanPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCarePlanForNewCarePlanPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateCarePlanForNewCarePlanPageMutation,
        CreateCarePlanForNewCarePlanPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateCarePlanForNewCarePlanPageMutation,
        CreateCarePlanForNewCarePlanPageMutationVariables
    >(CreateCarePlanForNewCarePlanPageDocument, options);
}
export type CreateCarePlanForNewCarePlanPageMutationHookResult = ReturnType<
    typeof useCreateCarePlanForNewCarePlanPageMutation
>;
export type CreateCarePlanForNewCarePlanPageMutationResult =
    Apollo.MutationResult<CreateCarePlanForNewCarePlanPageMutation>;
export type CreateCarePlanForNewCarePlanPageMutationOptions = Apollo.BaseMutationOptions<
    CreateCarePlanForNewCarePlanPageMutation,
    CreateCarePlanForNewCarePlanPageMutationVariables
>;
export const DeleteCarePlanEditCarePlanPageDocument = gql`
    mutation DeleteCarePlanEditCarePlanPage($input: DeleteCarePlanInput!) {
        deleteCarePlan(input: $input) {
            success
            message
            id
        }
    }
`;
export type DeleteCarePlanEditCarePlanPageMutationFn = Apollo.MutationFunction<
    DeleteCarePlanEditCarePlanPageMutation,
    DeleteCarePlanEditCarePlanPageMutationVariables
>;

/**
 * __useDeleteCarePlanEditCarePlanPageMutation__
 *
 * To run a mutation, you first call `useDeleteCarePlanEditCarePlanPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCarePlanEditCarePlanPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCarePlanEditCarePlanPageMutation, { data, loading, error }] = useDeleteCarePlanEditCarePlanPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCarePlanEditCarePlanPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteCarePlanEditCarePlanPageMutation,
        DeleteCarePlanEditCarePlanPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteCarePlanEditCarePlanPageMutation,
        DeleteCarePlanEditCarePlanPageMutationVariables
    >(DeleteCarePlanEditCarePlanPageDocument, options);
}
export type DeleteCarePlanEditCarePlanPageMutationHookResult = ReturnType<
    typeof useDeleteCarePlanEditCarePlanPageMutation
>;
export type DeleteCarePlanEditCarePlanPageMutationResult =
    Apollo.MutationResult<DeleteCarePlanEditCarePlanPageMutation>;
export type DeleteCarePlanEditCarePlanPageMutationOptions = Apollo.BaseMutationOptions<
    DeleteCarePlanEditCarePlanPageMutation,
    DeleteCarePlanEditCarePlanPageMutationVariables
>;
export const UpdateCarePlanForCarePlanPageDocument = gql`
    mutation UpdateCarePlanForCarePlanPage($input: UpdateCarePlanInput!) {
        updateCarePlan(input: $input) {
            success
            message
            resourceUpdated {
                ...CarePlanFragment
            }
        }
    }
    ${CarePlanFragmentFragmentDoc}
`;
export type UpdateCarePlanForCarePlanPageMutationFn = Apollo.MutationFunction<
    UpdateCarePlanForCarePlanPageMutation,
    UpdateCarePlanForCarePlanPageMutationVariables
>;

/**
 * __useUpdateCarePlanForCarePlanPageMutation__
 *
 * To run a mutation, you first call `useUpdateCarePlanForCarePlanPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCarePlanForCarePlanPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCarePlanForCarePlanPageMutation, { data, loading, error }] = useUpdateCarePlanForCarePlanPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCarePlanForCarePlanPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateCarePlanForCarePlanPageMutation,
        UpdateCarePlanForCarePlanPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateCarePlanForCarePlanPageMutation,
        UpdateCarePlanForCarePlanPageMutationVariables
    >(UpdateCarePlanForCarePlanPageDocument, options);
}
export type UpdateCarePlanForCarePlanPageMutationHookResult = ReturnType<
    typeof useUpdateCarePlanForCarePlanPageMutation
>;
export type UpdateCarePlanForCarePlanPageMutationResult =
    Apollo.MutationResult<UpdateCarePlanForCarePlanPageMutation>;
export type UpdateCarePlanForCarePlanPageMutationOptions = Apollo.BaseMutationOptions<
    UpdateCarePlanForCarePlanPageMutation,
    UpdateCarePlanForCarePlanPageMutationVariables
>;
export const CarePlanForCarePlanPageDocument = gql`
    query CarePlanForCarePlanPage($carePlanInput: GetCarePlanInput!) {
        carePlan(input: $carePlanInput) {
            ...CarePlanFragment
            baseCarePlan {
                ...CarePlanFragment
            }
        }
    }
    ${CarePlanFragmentFragmentDoc}
`;

/**
 * __useCarePlanForCarePlanPageQuery__
 *
 * To run a query within a React component, call `useCarePlanForCarePlanPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarePlanForCarePlanPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarePlanForCarePlanPageQuery({
 *   variables: {
 *      carePlanInput: // value for 'carePlanInput'
 *   },
 * });
 */
export function useCarePlanForCarePlanPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        CarePlanForCarePlanPageQuery,
        CarePlanForCarePlanPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CarePlanForCarePlanPageQuery, CarePlanForCarePlanPageQueryVariables>(
        CarePlanForCarePlanPageDocument,
        options,
    );
}
export function useCarePlanForCarePlanPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CarePlanForCarePlanPageQuery,
        CarePlanForCarePlanPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CarePlanForCarePlanPageQuery, CarePlanForCarePlanPageQueryVariables>(
        CarePlanForCarePlanPageDocument,
        options,
    );
}
export type CarePlanForCarePlanPageQueryHookResult = ReturnType<
    typeof useCarePlanForCarePlanPageQuery
>;
export type CarePlanForCarePlanPageLazyQueryHookResult = ReturnType<
    typeof useCarePlanForCarePlanPageLazyQuery
>;
export type CarePlanForCarePlanPageQueryResult = Apollo.QueryResult<
    CarePlanForCarePlanPageQuery,
    CarePlanForCarePlanPageQueryVariables
>;
export const PreFetchDataForCarePlanPageDocument = gql`
    query PreFetchDataForCarePlanPage {
        whatsNewTimelines {
            id
            type
            label {
                en
            }
        }
        amsApps {
            id
            bundleId
        }
        tags {
            id
            name
        }
    }
`;

/**
 * __usePreFetchDataForCarePlanPageQuery__
 *
 * To run a query within a React component, call `usePreFetchDataForCarePlanPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreFetchDataForCarePlanPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreFetchDataForCarePlanPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreFetchDataForCarePlanPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        PreFetchDataForCarePlanPageQuery,
        PreFetchDataForCarePlanPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        PreFetchDataForCarePlanPageQuery,
        PreFetchDataForCarePlanPageQueryVariables
    >(PreFetchDataForCarePlanPageDocument, options);
}
export function usePreFetchDataForCarePlanPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PreFetchDataForCarePlanPageQuery,
        PreFetchDataForCarePlanPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        PreFetchDataForCarePlanPageQuery,
        PreFetchDataForCarePlanPageQueryVariables
    >(PreFetchDataForCarePlanPageDocument, options);
}
export type PreFetchDataForCarePlanPageQueryHookResult = ReturnType<
    typeof usePreFetchDataForCarePlanPageQuery
>;
export type PreFetchDataForCarePlanPageLazyQueryHookResult = ReturnType<
    typeof usePreFetchDataForCarePlanPageLazyQuery
>;
export type PreFetchDataForCarePlanPageQueryResult = Apollo.QueryResult<
    PreFetchDataForCarePlanPageQuery,
    PreFetchDataForCarePlanPageQueryVariables
>;
export const CarePlansForCarePlansPageDocument = gql`
    query CarePlansForCarePlansPage {
        carePlansV2 {
            results {
                id
                type
                portalLabel {
                    en
                }
                groups {
                    label {
                        en
                    }
                }
                timeline {
                    label {
                        en
                    }
                }
                baseCarePlan {
                    portalLabel {
                        en
                    }
                }
            }
        }
    }
`;

/**
 * __useCarePlansForCarePlansPageQuery__
 *
 * To run a query within a React component, call `useCarePlansForCarePlansPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarePlansForCarePlansPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarePlansForCarePlansPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCarePlansForCarePlansPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        CarePlansForCarePlansPageQuery,
        CarePlansForCarePlansPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CarePlansForCarePlansPageQuery, CarePlansForCarePlansPageQueryVariables>(
        CarePlansForCarePlansPageDocument,
        options,
    );
}
export function useCarePlansForCarePlansPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CarePlansForCarePlansPageQuery,
        CarePlansForCarePlansPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        CarePlansForCarePlansPageQuery,
        CarePlansForCarePlansPageQueryVariables
    >(CarePlansForCarePlansPageDocument, options);
}
export type CarePlansForCarePlansPageQueryHookResult = ReturnType<
    typeof useCarePlansForCarePlansPageQuery
>;
export type CarePlansForCarePlansPageLazyQueryHookResult = ReturnType<
    typeof useCarePlansForCarePlansPageLazyQuery
>;
export type CarePlansForCarePlansPageQueryResult = Apollo.QueryResult<
    CarePlansForCarePlansPageQuery,
    CarePlansForCarePlansPageQueryVariables
>;
export const CarePlansForAddPageDocument = gql`
    query CarePlansForAddPage($input: CarePlanQueryInput!) {
        carePlansV2(input: $input) {
            results {
                id
                type
                portalLabel {
                    en
                }
                groups {
                    label {
                        en
                    }
                }
                timeline {
                    label {
                        en
                    }
                }
                baseCarePlan {
                    portalLabel {
                        en
                    }
                }
            }
        }
    }
`;

/**
 * __useCarePlansForAddPageQuery__
 *
 * To run a query within a React component, call `useCarePlansForAddPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarePlansForAddPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarePlansForAddPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCarePlansForAddPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        CarePlansForAddPageQuery,
        CarePlansForAddPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CarePlansForAddPageQuery, CarePlansForAddPageQueryVariables>(
        CarePlansForAddPageDocument,
        options,
    );
}
export function useCarePlansForAddPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CarePlansForAddPageQuery,
        CarePlansForAddPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CarePlansForAddPageQuery, CarePlansForAddPageQueryVariables>(
        CarePlansForAddPageDocument,
        options,
    );
}
export type CarePlansForAddPageQueryHookResult = ReturnType<typeof useCarePlansForAddPageQuery>;
export type CarePlansForAddPageLazyQueryHookResult = ReturnType<
    typeof useCarePlansForAddPageLazyQuery
>;
export type CarePlansForAddPageQueryResult = Apollo.QueryResult<
    CarePlansForAddPageQuery,
    CarePlansForAddPageQueryVariables
>;
export const CareTeamMemberTypesDocument = gql`
    query CareTeamMemberTypes($input: CareTeamMemberTypeQueryInput) {
        careTeamMemberTypesV2(input: $input) {
            results {
                id
                name
            }
        }
    }
`;

/**
 * __useCareTeamMemberTypesQuery__
 *
 * To run a query within a React component, call `useCareTeamMemberTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareTeamMemberTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareTeamMemberTypesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCareTeamMemberTypesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        CareTeamMemberTypesQuery,
        CareTeamMemberTypesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CareTeamMemberTypesQuery, CareTeamMemberTypesQueryVariables>(
        CareTeamMemberTypesDocument,
        options,
    );
}
export function useCareTeamMemberTypesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CareTeamMemberTypesQuery,
        CareTeamMemberTypesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CareTeamMemberTypesQuery, CareTeamMemberTypesQueryVariables>(
        CareTeamMemberTypesDocument,
        options,
    );
}
export type CareTeamMemberTypesQueryHookResult = ReturnType<typeof useCareTeamMemberTypesQuery>;
export type CareTeamMemberTypesLazyQueryHookResult = ReturnType<
    typeof useCareTeamMemberTypesLazyQuery
>;
export type CareTeamMemberTypesQueryResult = Apollo.QueryResult<
    CareTeamMemberTypesQuery,
    CareTeamMemberTypesQueryVariables
>;
export const CreateCareTeamContactDocument = gql`
    mutation CreateCareTeamContact($input: CareTeamContactInput!) {
        createCareTeamContact(input: $input) {
            message
            status
            success
        }
    }
`;
export type CreateCareTeamContactMutationFn = Apollo.MutationFunction<
    CreateCareTeamContactMutation,
    CreateCareTeamContactMutationVariables
>;

/**
 * __useCreateCareTeamContactMutation__
 *
 * To run a mutation, you first call `useCreateCareTeamContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCareTeamContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCareTeamContactMutation, { data, loading, error }] = useCreateCareTeamContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCareTeamContactMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateCareTeamContactMutation,
        CreateCareTeamContactMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateCareTeamContactMutation,
        CreateCareTeamContactMutationVariables
    >(CreateCareTeamContactDocument, options);
}
export type CreateCareTeamContactMutationHookResult = ReturnType<
    typeof useCreateCareTeamContactMutation
>;
export type CreateCareTeamContactMutationResult =
    Apollo.MutationResult<CreateCareTeamContactMutation>;
export type CreateCareTeamContactMutationOptions = Apollo.BaseMutationOptions<
    CreateCareTeamContactMutation,
    CreateCareTeamContactMutationVariables
>;
export const UpdateCareTeamContactDocument = gql`
    mutation UpdateCareTeamContact($input: UpdateCareTeamContactInput!) {
        updateCareTeamContact(input: $input) {
            message
            status
            success
        }
    }
`;
export type UpdateCareTeamContactMutationFn = Apollo.MutationFunction<
    UpdateCareTeamContactMutation,
    UpdateCareTeamContactMutationVariables
>;

/**
 * __useUpdateCareTeamContactMutation__
 *
 * To run a mutation, you first call `useUpdateCareTeamContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCareTeamContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCareTeamContactMutation, { data, loading, error }] = useUpdateCareTeamContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCareTeamContactMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateCareTeamContactMutation,
        UpdateCareTeamContactMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateCareTeamContactMutation,
        UpdateCareTeamContactMutationVariables
    >(UpdateCareTeamContactDocument, options);
}
export type UpdateCareTeamContactMutationHookResult = ReturnType<
    typeof useUpdateCareTeamContactMutation
>;
export type UpdateCareTeamContactMutationResult =
    Apollo.MutationResult<UpdateCareTeamContactMutation>;
export type UpdateCareTeamContactMutationOptions = Apollo.BaseMutationOptions<
    UpdateCareTeamContactMutation,
    UpdateCareTeamContactMutationVariables
>;
export const CareTeamContactDocument = gql`
    query CareTeamContact($input: GetCareTeamContactInput!) {
        careTeamContact(input: $input) {
            address {
                city
                code
                state
                street1
                street2
            }
            careTeamMemberTypeId
            emailAddress
            id
            name
            phoneNumber
            updatedAt
            url
        }
    }
`;

/**
 * __useCareTeamContactQuery__
 *
 * To run a query within a React component, call `useCareTeamContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareTeamContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareTeamContactQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCareTeamContactQuery(
    baseOptions: Apollo.QueryHookOptions<CareTeamContactQuery, CareTeamContactQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CareTeamContactQuery, CareTeamContactQueryVariables>(
        CareTeamContactDocument,
        options,
    );
}
export function useCareTeamContactLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CareTeamContactQuery, CareTeamContactQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CareTeamContactQuery, CareTeamContactQueryVariables>(
        CareTeamContactDocument,
        options,
    );
}
export type CareTeamContactQueryHookResult = ReturnType<typeof useCareTeamContactQuery>;
export type CareTeamContactLazyQueryHookResult = ReturnType<typeof useCareTeamContactLazyQuery>;
export type CareTeamContactQueryResult = Apollo.QueryResult<
    CareTeamContactQuery,
    CareTeamContactQueryVariables
>;
export const FetchCareTeamContactsDocument = gql`
    query FetchCareTeamContacts($input: CareTeamContactsListInput) {
        careTeamContactsV2(input: $input) {
            skip
            limit
            total
            results {
                name
                isTestData
                id
                emailAddress
                careTeamMemberTypeId
                careTeamMemberType {
                    name
                }
                address {
                    state
                    city
                    code
                    street1
                    street2
                }
                phoneNumber
                url
            }
        }
    }
`;

/**
 * __useFetchCareTeamContactsQuery__
 *
 * To run a query within a React component, call `useFetchCareTeamContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCareTeamContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCareTeamContactsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchCareTeamContactsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchCareTeamContactsQuery,
        FetchCareTeamContactsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchCareTeamContactsQuery, FetchCareTeamContactsQueryVariables>(
        FetchCareTeamContactsDocument,
        options,
    );
}
export function useFetchCareTeamContactsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchCareTeamContactsQuery,
        FetchCareTeamContactsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchCareTeamContactsQuery, FetchCareTeamContactsQueryVariables>(
        FetchCareTeamContactsDocument,
        options,
    );
}
export type FetchCareTeamContactsQueryHookResult = ReturnType<typeof useFetchCareTeamContactsQuery>;
export type FetchCareTeamContactsLazyQueryHookResult = ReturnType<
    typeof useFetchCareTeamContactsLazyQuery
>;
export type FetchCareTeamContactsQueryResult = Apollo.QueryResult<
    FetchCareTeamContactsQuery,
    FetchCareTeamContactsQueryVariables
>;
export const DeleteCareTeamContactDocument = gql`
    mutation DeleteCareTeamContact($input: DeleteCareTeamContactInput!) {
        deleteCareTeamContact(input: $input) {
            message
            status
            success
            id
        }
    }
`;
export type DeleteCareTeamContactMutationFn = Apollo.MutationFunction<
    DeleteCareTeamContactMutation,
    DeleteCareTeamContactMutationVariables
>;

/**
 * __useDeleteCareTeamContactMutation__
 *
 * To run a mutation, you first call `useDeleteCareTeamContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCareTeamContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCareTeamContactMutation, { data, loading, error }] = useDeleteCareTeamContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCareTeamContactMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteCareTeamContactMutation,
        DeleteCareTeamContactMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteCareTeamContactMutation,
        DeleteCareTeamContactMutationVariables
    >(DeleteCareTeamContactDocument, options);
}
export type DeleteCareTeamContactMutationHookResult = ReturnType<
    typeof useDeleteCareTeamContactMutation
>;
export type DeleteCareTeamContactMutationResult =
    Apollo.MutationResult<DeleteCareTeamContactMutation>;
export type DeleteCareTeamContactMutationOptions = Apollo.BaseMutationOptions<
    DeleteCareTeamContactMutation,
    DeleteCareTeamContactMutationVariables
>;
export const CreateCareTeamMemberTypeDocument = gql`
    mutation CreateCareTeamMemberType($input: CareTeamMemberTypeUpdateInput!) {
        createCareTeamMemberType(input: $input) {
            resourceCreated {
                id
                name
                isJob
                profileDefId
                createdAt
                updatedAt
            }
            status
            message
            success
        }
    }
`;
export type CreateCareTeamMemberTypeMutationFn = Apollo.MutationFunction<
    CreateCareTeamMemberTypeMutation,
    CreateCareTeamMemberTypeMutationVariables
>;

/**
 * __useCreateCareTeamMemberTypeMutation__
 *
 * To run a mutation, you first call `useCreateCareTeamMemberTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCareTeamMemberTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCareTeamMemberTypeMutation, { data, loading, error }] = useCreateCareTeamMemberTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCareTeamMemberTypeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateCareTeamMemberTypeMutation,
        CreateCareTeamMemberTypeMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateCareTeamMemberTypeMutation,
        CreateCareTeamMemberTypeMutationVariables
    >(CreateCareTeamMemberTypeDocument, options);
}
export type CreateCareTeamMemberTypeMutationHookResult = ReturnType<
    typeof useCreateCareTeamMemberTypeMutation
>;
export type CreateCareTeamMemberTypeMutationResult =
    Apollo.MutationResult<CreateCareTeamMemberTypeMutation>;
export type CreateCareTeamMemberTypeMutationOptions = Apollo.BaseMutationOptions<
    CreateCareTeamMemberTypeMutation,
    CreateCareTeamMemberTypeMutationVariables
>;
export const UpdateCareTeamMemberTypeDocument = gql`
    mutation UpdateCareTeamMemberType($input: UpdateCareTeamMemberTypeInput!) {
        updateCareTeamMemberType(input: $input) {
            message
            success
            resourceUpdated {
                id
                isTestData
                name
                isJob
                profileDefId
                updatedAt
                createdAt
            }
        }
    }
`;
export type UpdateCareTeamMemberTypeMutationFn = Apollo.MutationFunction<
    UpdateCareTeamMemberTypeMutation,
    UpdateCareTeamMemberTypeMutationVariables
>;

/**
 * __useUpdateCareTeamMemberTypeMutation__
 *
 * To run a mutation, you first call `useUpdateCareTeamMemberTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCareTeamMemberTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCareTeamMemberTypeMutation, { data, loading, error }] = useUpdateCareTeamMemberTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCareTeamMemberTypeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateCareTeamMemberTypeMutation,
        UpdateCareTeamMemberTypeMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateCareTeamMemberTypeMutation,
        UpdateCareTeamMemberTypeMutationVariables
    >(UpdateCareTeamMemberTypeDocument, options);
}
export type UpdateCareTeamMemberTypeMutationHookResult = ReturnType<
    typeof useUpdateCareTeamMemberTypeMutation
>;
export type UpdateCareTeamMemberTypeMutationResult =
    Apollo.MutationResult<UpdateCareTeamMemberTypeMutation>;
export type UpdateCareTeamMemberTypeMutationOptions = Apollo.BaseMutationOptions<
    UpdateCareTeamMemberTypeMutation,
    UpdateCareTeamMemberTypeMutationVariables
>;
export const FetchCareTeamMemberTypesForConfigPageDocument = gql`
    query FetchCareTeamMemberTypesForConfigPage($input: CareTeamMemberTypeQueryInput) {
        careTeamMemberTypesV2(input: $input) {
            results {
                id
                name
                isJob
                profileDefId
                profileDef {
                    name
                    createdAt
                    id
                    updatedAt
                    category
                    valueType
                }
                createdAt
                updatedAt
            }
            total
        }
    }
`;

/**
 * __useFetchCareTeamMemberTypesForConfigPageQuery__
 *
 * To run a query within a React component, call `useFetchCareTeamMemberTypesForConfigPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCareTeamMemberTypesForConfigPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCareTeamMemberTypesForConfigPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchCareTeamMemberTypesForConfigPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchCareTeamMemberTypesForConfigPageQuery,
        FetchCareTeamMemberTypesForConfigPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchCareTeamMemberTypesForConfigPageQuery,
        FetchCareTeamMemberTypesForConfigPageQueryVariables
    >(FetchCareTeamMemberTypesForConfigPageDocument, options);
}
export function useFetchCareTeamMemberTypesForConfigPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchCareTeamMemberTypesForConfigPageQuery,
        FetchCareTeamMemberTypesForConfigPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchCareTeamMemberTypesForConfigPageQuery,
        FetchCareTeamMemberTypesForConfigPageQueryVariables
    >(FetchCareTeamMemberTypesForConfigPageDocument, options);
}
export type FetchCareTeamMemberTypesForConfigPageQueryHookResult = ReturnType<
    typeof useFetchCareTeamMemberTypesForConfigPageQuery
>;
export type FetchCareTeamMemberTypesForConfigPageLazyQueryHookResult = ReturnType<
    typeof useFetchCareTeamMemberTypesForConfigPageLazyQuery
>;
export type FetchCareTeamMemberTypesForConfigPageQueryResult = Apollo.QueryResult<
    FetchCareTeamMemberTypesForConfigPageQuery,
    FetchCareTeamMemberTypesForConfigPageQueryVariables
>;
export const DeleteCareTeamMemberTypeDocument = gql`
    mutation DeleteCareTeamMemberType($id: String!) {
        deleteCareTeamMemberType(id: $id) {
            success
            message
            status
            id
        }
    }
`;
export type DeleteCareTeamMemberTypeMutationFn = Apollo.MutationFunction<
    DeleteCareTeamMemberTypeMutation,
    DeleteCareTeamMemberTypeMutationVariables
>;

/**
 * __useDeleteCareTeamMemberTypeMutation__
 *
 * To run a mutation, you first call `useDeleteCareTeamMemberTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCareTeamMemberTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCareTeamMemberTypeMutation, { data, loading, error }] = useDeleteCareTeamMemberTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCareTeamMemberTypeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteCareTeamMemberTypeMutation,
        DeleteCareTeamMemberTypeMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteCareTeamMemberTypeMutation,
        DeleteCareTeamMemberTypeMutationVariables
    >(DeleteCareTeamMemberTypeDocument, options);
}
export type DeleteCareTeamMemberTypeMutationHookResult = ReturnType<
    typeof useDeleteCareTeamMemberTypeMutation
>;
export type DeleteCareTeamMemberTypeMutationResult =
    Apollo.MutationResult<DeleteCareTeamMemberTypeMutation>;
export type DeleteCareTeamMemberTypeMutationOptions = Apollo.BaseMutationOptions<
    DeleteCareTeamMemberTypeMutation,
    DeleteCareTeamMemberTypeMutationVariables
>;
export const CareTeamMemberTypeWhereUsedDocument = gql`
    query CareTeamMemberTypeWhereUsed($id: String!) {
        careTeamMemberTypeWhereUsed(id: $id) {
            dependencies {
                entityType
                blocksDelete
                total
                entries {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useCareTeamMemberTypeWhereUsedQuery__
 *
 * To run a query within a React component, call `useCareTeamMemberTypeWhereUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareTeamMemberTypeWhereUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareTeamMemberTypeWhereUsedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCareTeamMemberTypeWhereUsedQuery(
    baseOptions: Apollo.QueryHookOptions<
        CareTeamMemberTypeWhereUsedQuery,
        CareTeamMemberTypeWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        CareTeamMemberTypeWhereUsedQuery,
        CareTeamMemberTypeWhereUsedQueryVariables
    >(CareTeamMemberTypeWhereUsedDocument, options);
}
export function useCareTeamMemberTypeWhereUsedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CareTeamMemberTypeWhereUsedQuery,
        CareTeamMemberTypeWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        CareTeamMemberTypeWhereUsedQuery,
        CareTeamMemberTypeWhereUsedQueryVariables
    >(CareTeamMemberTypeWhereUsedDocument, options);
}
export type CareTeamMemberTypeWhereUsedQueryHookResult = ReturnType<
    typeof useCareTeamMemberTypeWhereUsedQuery
>;
export type CareTeamMemberTypeWhereUsedLazyQueryHookResult = ReturnType<
    typeof useCareTeamMemberTypeWhereUsedLazyQuery
>;
export type CareTeamMemberTypeWhereUsedQueryResult = Apollo.QueryResult<
    CareTeamMemberTypeWhereUsedQuery,
    CareTeamMemberTypeWhereUsedQueryVariables
>;
export const FetchCareTeamMembersForCareTeamMembersPageDocument = gql`
    query FetchCareTeamMembersForCareTeamMembersPage {
        careTeamMembersV2 {
            results {
                ...CareTeamMemberFragment
            }
        }
    }
    ${CareTeamMemberFragmentFragmentDoc}
`;

/**
 * __useFetchCareTeamMembersForCareTeamMembersPageQuery__
 *
 * To run a query within a React component, call `useFetchCareTeamMembersForCareTeamMembersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCareTeamMembersForCareTeamMembersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCareTeamMembersForCareTeamMembersPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCareTeamMembersForCareTeamMembersPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchCareTeamMembersForCareTeamMembersPageQuery,
        FetchCareTeamMembersForCareTeamMembersPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchCareTeamMembersForCareTeamMembersPageQuery,
        FetchCareTeamMembersForCareTeamMembersPageQueryVariables
    >(FetchCareTeamMembersForCareTeamMembersPageDocument, options);
}
export function useFetchCareTeamMembersForCareTeamMembersPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchCareTeamMembersForCareTeamMembersPageQuery,
        FetchCareTeamMembersForCareTeamMembersPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchCareTeamMembersForCareTeamMembersPageQuery,
        FetchCareTeamMembersForCareTeamMembersPageQueryVariables
    >(FetchCareTeamMembersForCareTeamMembersPageDocument, options);
}
export type FetchCareTeamMembersForCareTeamMembersPageQueryHookResult = ReturnType<
    typeof useFetchCareTeamMembersForCareTeamMembersPageQuery
>;
export type FetchCareTeamMembersForCareTeamMembersPageLazyQueryHookResult = ReturnType<
    typeof useFetchCareTeamMembersForCareTeamMembersPageLazyQuery
>;
export type FetchCareTeamMembersForCareTeamMembersPageQueryResult = Apollo.QueryResult<
    FetchCareTeamMembersForCareTeamMembersPageQuery,
    FetchCareTeamMembersForCareTeamMembersPageQueryVariables
>;
export const UsStateListDocument = gql`
    query USStateList {
        usStateList {
            abbreviation
            name
        }
    }
`;

/**
 * __useUsStateListQuery__
 *
 * To run a query within a React component, call `useUsStateListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsStateListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsStateListQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsStateListQuery(
    baseOptions?: Apollo.QueryHookOptions<UsStateListQuery, UsStateListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<UsStateListQuery, UsStateListQueryVariables>(
        UsStateListDocument,
        options,
    );
}
export function useUsStateListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<UsStateListQuery, UsStateListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<UsStateListQuery, UsStateListQueryVariables>(
        UsStateListDocument,
        options,
    );
}
export type UsStateListQueryHookResult = ReturnType<typeof useUsStateListQuery>;
export type UsStateListLazyQueryHookResult = ReturnType<typeof useUsStateListLazyQuery>;
export type UsStateListQueryResult = Apollo.QueryResult<
    UsStateListQuery,
    UsStateListQueryVariables
>;
export const FetchCareTeamMemberByIdDocument = gql`
    query FetchCareTeamMemberById($input: GetCareTeamMemberInput!) {
        careTeamMember(input: $input) {
            ...CareTeamMemberFragment
        }
    }
    ${CareTeamMemberFragmentFragmentDoc}
`;

/**
 * __useFetchCareTeamMemberByIdQuery__
 *
 * To run a query within a React component, call `useFetchCareTeamMemberByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCareTeamMemberByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCareTeamMemberByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchCareTeamMemberByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchCareTeamMemberByIdQuery,
        FetchCareTeamMemberByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchCareTeamMemberByIdQuery, FetchCareTeamMemberByIdQueryVariables>(
        FetchCareTeamMemberByIdDocument,
        options,
    );
}
export function useFetchCareTeamMemberByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchCareTeamMemberByIdQuery,
        FetchCareTeamMemberByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchCareTeamMemberByIdQuery, FetchCareTeamMemberByIdQueryVariables>(
        FetchCareTeamMemberByIdDocument,
        options,
    );
}
export type FetchCareTeamMemberByIdQueryHookResult = ReturnType<
    typeof useFetchCareTeamMemberByIdQuery
>;
export type FetchCareTeamMemberByIdLazyQueryHookResult = ReturnType<
    typeof useFetchCareTeamMemberByIdLazyQuery
>;
export type FetchCareTeamMemberByIdQueryResult = Apollo.QueryResult<
    FetchCareTeamMemberByIdQuery,
    FetchCareTeamMemberByIdQueryVariables
>;
export const UpdateCareTeamMemberFromModalDocument = gql`
    mutation UpdateCareTeamMemberFromModal($input: UpdateCareTeamMemberInput!) {
        updateCareTeamMember(input: $input) {
            message
            resourceUpdated {
                ...CareTeamMemberFragment
            }
            status
            success
        }
    }
    ${CareTeamMemberFragmentFragmentDoc}
`;
export type UpdateCareTeamMemberFromModalMutationFn = Apollo.MutationFunction<
    UpdateCareTeamMemberFromModalMutation,
    UpdateCareTeamMemberFromModalMutationVariables
>;

/**
 * __useUpdateCareTeamMemberFromModalMutation__
 *
 * To run a mutation, you first call `useUpdateCareTeamMemberFromModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCareTeamMemberFromModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCareTeamMemberFromModalMutation, { data, loading, error }] = useUpdateCareTeamMemberFromModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCareTeamMemberFromModalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateCareTeamMemberFromModalMutation,
        UpdateCareTeamMemberFromModalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateCareTeamMemberFromModalMutation,
        UpdateCareTeamMemberFromModalMutationVariables
    >(UpdateCareTeamMemberFromModalDocument, options);
}
export type UpdateCareTeamMemberFromModalMutationHookResult = ReturnType<
    typeof useUpdateCareTeamMemberFromModalMutation
>;
export type UpdateCareTeamMemberFromModalMutationResult =
    Apollo.MutationResult<UpdateCareTeamMemberFromModalMutation>;
export type UpdateCareTeamMemberFromModalMutationOptions = Apollo.BaseMutationOptions<
    UpdateCareTeamMemberFromModalMutation,
    UpdateCareTeamMemberFromModalMutationVariables
>;
export const CreateCareTeamMemberDocument = gql`
    mutation CreateCareTeamMember($input: CareTeamMemberInput!) {
        createCareTeamMember(input: $input) {
            message
            resourceCreated {
                ...CareTeamMemberFragment
            }
            status
            success
        }
    }
    ${CareTeamMemberFragmentFragmentDoc}
`;
export type CreateCareTeamMemberMutationFn = Apollo.MutationFunction<
    CreateCareTeamMemberMutation,
    CreateCareTeamMemberMutationVariables
>;

/**
 * __useCreateCareTeamMemberMutation__
 *
 * To run a mutation, you first call `useCreateCareTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCareTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCareTeamMemberMutation, { data, loading, error }] = useCreateCareTeamMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCareTeamMemberMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateCareTeamMemberMutation,
        CreateCareTeamMemberMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateCareTeamMemberMutation, CreateCareTeamMemberMutationVariables>(
        CreateCareTeamMemberDocument,
        options,
    );
}
export type CreateCareTeamMemberMutationHookResult = ReturnType<
    typeof useCreateCareTeamMemberMutation
>;
export type CreateCareTeamMemberMutationResult =
    Apollo.MutationResult<CreateCareTeamMemberMutation>;
export type CreateCareTeamMemberMutationOptions = Apollo.BaseMutationOptions<
    CreateCareTeamMemberMutation,
    CreateCareTeamMemberMutationVariables
>;
export const CareTeamMemberTypesForCareTeamMemberEditModalDocument = gql`
    query CareTeamMemberTypesForCareTeamMemberEditModal {
        careTeamMemberTypesV2 {
            results {
                id
                name
                isJob
            }
        }
    }
`;

/**
 * __useCareTeamMemberTypesForCareTeamMemberEditModalQuery__
 *
 * To run a query within a React component, call `useCareTeamMemberTypesForCareTeamMemberEditModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareTeamMemberTypesForCareTeamMemberEditModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareTeamMemberTypesForCareTeamMemberEditModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useCareTeamMemberTypesForCareTeamMemberEditModalQuery(
    baseOptions?: Apollo.QueryHookOptions<
        CareTeamMemberTypesForCareTeamMemberEditModalQuery,
        CareTeamMemberTypesForCareTeamMemberEditModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        CareTeamMemberTypesForCareTeamMemberEditModalQuery,
        CareTeamMemberTypesForCareTeamMemberEditModalQueryVariables
    >(CareTeamMemberTypesForCareTeamMemberEditModalDocument, options);
}
export function useCareTeamMemberTypesForCareTeamMemberEditModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CareTeamMemberTypesForCareTeamMemberEditModalQuery,
        CareTeamMemberTypesForCareTeamMemberEditModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        CareTeamMemberTypesForCareTeamMemberEditModalQuery,
        CareTeamMemberTypesForCareTeamMemberEditModalQueryVariables
    >(CareTeamMemberTypesForCareTeamMemberEditModalDocument, options);
}
export type CareTeamMemberTypesForCareTeamMemberEditModalQueryHookResult = ReturnType<
    typeof useCareTeamMemberTypesForCareTeamMemberEditModalQuery
>;
export type CareTeamMemberTypesForCareTeamMemberEditModalLazyQueryHookResult = ReturnType<
    typeof useCareTeamMemberTypesForCareTeamMemberEditModalLazyQuery
>;
export type CareTeamMemberTypesForCareTeamMemberEditModalQueryResult = Apollo.QueryResult<
    CareTeamMemberTypesForCareTeamMemberEditModalQuery,
    CareTeamMemberTypesForCareTeamMemberEditModalQueryVariables
>;
export const CareTeamContactsForCareTeamMemberEditModalDocument = gql`
    query CareTeamContactsForCareTeamMemberEditModal {
        careTeamContactsV2 {
            results {
                careTeamMemberTypeId
                name
                address {
                    street1
                    street2
                    city
                    state
                    code
                }
                phoneNumber
                emailAddress
                url
                id
            }
        }
    }
`;

/**
 * __useCareTeamContactsForCareTeamMemberEditModalQuery__
 *
 * To run a query within a React component, call `useCareTeamContactsForCareTeamMemberEditModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareTeamContactsForCareTeamMemberEditModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareTeamContactsForCareTeamMemberEditModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useCareTeamContactsForCareTeamMemberEditModalQuery(
    baseOptions?: Apollo.QueryHookOptions<
        CareTeamContactsForCareTeamMemberEditModalQuery,
        CareTeamContactsForCareTeamMemberEditModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        CareTeamContactsForCareTeamMemberEditModalQuery,
        CareTeamContactsForCareTeamMemberEditModalQueryVariables
    >(CareTeamContactsForCareTeamMemberEditModalDocument, options);
}
export function useCareTeamContactsForCareTeamMemberEditModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CareTeamContactsForCareTeamMemberEditModalQuery,
        CareTeamContactsForCareTeamMemberEditModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        CareTeamContactsForCareTeamMemberEditModalQuery,
        CareTeamContactsForCareTeamMemberEditModalQueryVariables
    >(CareTeamContactsForCareTeamMemberEditModalDocument, options);
}
export type CareTeamContactsForCareTeamMemberEditModalQueryHookResult = ReturnType<
    typeof useCareTeamContactsForCareTeamMemberEditModalQuery
>;
export type CareTeamContactsForCareTeamMemberEditModalLazyQueryHookResult = ReturnType<
    typeof useCareTeamContactsForCareTeamMemberEditModalLazyQuery
>;
export type CareTeamContactsForCareTeamMemberEditModalQueryResult = Apollo.QueryResult<
    CareTeamContactsForCareTeamMemberEditModalQuery,
    CareTeamContactsForCareTeamMemberEditModalQueryVariables
>;
export const CareTeamMembersForCareTeammemberModalDocument = gql`
    query careTeamMembersForCareTeammemberModal {
        careTeamMembersV2 {
            results {
                id
                nameWithType
                label {
                    en
                }
            }
        }
    }
`;

/**
 * __useCareTeamMembersForCareTeammemberModalQuery__
 *
 * To run a query within a React component, call `useCareTeamMembersForCareTeammemberModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareTeamMembersForCareTeammemberModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareTeamMembersForCareTeammemberModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useCareTeamMembersForCareTeammemberModalQuery(
    baseOptions?: Apollo.QueryHookOptions<
        CareTeamMembersForCareTeammemberModalQuery,
        CareTeamMembersForCareTeammemberModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        CareTeamMembersForCareTeammemberModalQuery,
        CareTeamMembersForCareTeammemberModalQueryVariables
    >(CareTeamMembersForCareTeammemberModalDocument, options);
}
export function useCareTeamMembersForCareTeammemberModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CareTeamMembersForCareTeammemberModalQuery,
        CareTeamMembersForCareTeammemberModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        CareTeamMembersForCareTeammemberModalQuery,
        CareTeamMembersForCareTeammemberModalQueryVariables
    >(CareTeamMembersForCareTeammemberModalDocument, options);
}
export type CareTeamMembersForCareTeammemberModalQueryHookResult = ReturnType<
    typeof useCareTeamMembersForCareTeammemberModalQuery
>;
export type CareTeamMembersForCareTeammemberModalLazyQueryHookResult = ReturnType<
    typeof useCareTeamMembersForCareTeammemberModalLazyQuery
>;
export type CareTeamMembersForCareTeammemberModalQueryResult = Apollo.QueryResult<
    CareTeamMembersForCareTeammemberModalQuery,
    CareTeamMembersForCareTeammemberModalQueryVariables
>;
export const UsersForCareTeamMemberModalDocument = gql`
    query UsersForCareTeamMemberModal {
        usersV2 {
            results {
                id
                name
            }
        }
    }
`;

/**
 * __useUsersForCareTeamMemberModalQuery__
 *
 * To run a query within a React component, call `useUsersForCareTeamMemberModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersForCareTeamMemberModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersForCareTeamMemberModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersForCareTeamMemberModalQuery(
    baseOptions?: Apollo.QueryHookOptions<
        UsersForCareTeamMemberModalQuery,
        UsersForCareTeamMemberModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        UsersForCareTeamMemberModalQuery,
        UsersForCareTeamMemberModalQueryVariables
    >(UsersForCareTeamMemberModalDocument, options);
}
export function useUsersForCareTeamMemberModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        UsersForCareTeamMemberModalQuery,
        UsersForCareTeamMemberModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        UsersForCareTeamMemberModalQuery,
        UsersForCareTeamMemberModalQueryVariables
    >(UsersForCareTeamMemberModalDocument, options);
}
export type UsersForCareTeamMemberModalQueryHookResult = ReturnType<
    typeof useUsersForCareTeamMemberModalQuery
>;
export type UsersForCareTeamMemberModalLazyQueryHookResult = ReturnType<
    typeof useUsersForCareTeamMemberModalLazyQuery
>;
export type UsersForCareTeamMemberModalQueryResult = Apollo.QueryResult<
    UsersForCareTeamMemberModalQuery,
    UsersForCareTeamMemberModalQueryVariables
>;
export const PreFetchCareTeamMemberTypesForCareTeamBuilderDocument = gql`
    query PreFetchCareTeamMemberTypesForCareTeamBuilder {
        careTeamMemberTypesV2 {
            results {
                id
                name
                profileDefId
                createdAt
                updatedAt
            }
        }
    }
`;

/**
 * __usePreFetchCareTeamMemberTypesForCareTeamBuilderQuery__
 *
 * To run a query within a React component, call `usePreFetchCareTeamMemberTypesForCareTeamBuilderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreFetchCareTeamMemberTypesForCareTeamBuilderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreFetchCareTeamMemberTypesForCareTeamBuilderQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreFetchCareTeamMemberTypesForCareTeamBuilderQuery(
    baseOptions?: Apollo.QueryHookOptions<
        PreFetchCareTeamMemberTypesForCareTeamBuilderQuery,
        PreFetchCareTeamMemberTypesForCareTeamBuilderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        PreFetchCareTeamMemberTypesForCareTeamBuilderQuery,
        PreFetchCareTeamMemberTypesForCareTeamBuilderQueryVariables
    >(PreFetchCareTeamMemberTypesForCareTeamBuilderDocument, options);
}
export function usePreFetchCareTeamMemberTypesForCareTeamBuilderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PreFetchCareTeamMemberTypesForCareTeamBuilderQuery,
        PreFetchCareTeamMemberTypesForCareTeamBuilderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        PreFetchCareTeamMemberTypesForCareTeamBuilderQuery,
        PreFetchCareTeamMemberTypesForCareTeamBuilderQueryVariables
    >(PreFetchCareTeamMemberTypesForCareTeamBuilderDocument, options);
}
export type PreFetchCareTeamMemberTypesForCareTeamBuilderQueryHookResult = ReturnType<
    typeof usePreFetchCareTeamMemberTypesForCareTeamBuilderQuery
>;
export type PreFetchCareTeamMemberTypesForCareTeamBuilderLazyQueryHookResult = ReturnType<
    typeof usePreFetchCareTeamMemberTypesForCareTeamBuilderLazyQuery
>;
export type PreFetchCareTeamMemberTypesForCareTeamBuilderQueryResult = Apollo.QueryResult<
    PreFetchCareTeamMemberTypesForCareTeamBuilderQuery,
    PreFetchCareTeamMemberTypesForCareTeamBuilderQueryVariables
>;
export const PreFetchAppsForCareTeamBuilderDocument = gql`
    query PreFetchAppsForCareTeamBuilder {
        amsApps {
            id
            bundleId
            productConst
        }
    }
`;

/**
 * __usePreFetchAppsForCareTeamBuilderQuery__
 *
 * To run a query within a React component, call `usePreFetchAppsForCareTeamBuilderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreFetchAppsForCareTeamBuilderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreFetchAppsForCareTeamBuilderQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreFetchAppsForCareTeamBuilderQuery(
    baseOptions?: Apollo.QueryHookOptions<
        PreFetchAppsForCareTeamBuilderQuery,
        PreFetchAppsForCareTeamBuilderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        PreFetchAppsForCareTeamBuilderQuery,
        PreFetchAppsForCareTeamBuilderQueryVariables
    >(PreFetchAppsForCareTeamBuilderDocument, options);
}
export function usePreFetchAppsForCareTeamBuilderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PreFetchAppsForCareTeamBuilderQuery,
        PreFetchAppsForCareTeamBuilderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        PreFetchAppsForCareTeamBuilderQuery,
        PreFetchAppsForCareTeamBuilderQueryVariables
    >(PreFetchAppsForCareTeamBuilderDocument, options);
}
export type PreFetchAppsForCareTeamBuilderQueryHookResult = ReturnType<
    typeof usePreFetchAppsForCareTeamBuilderQuery
>;
export type PreFetchAppsForCareTeamBuilderLazyQueryHookResult = ReturnType<
    typeof usePreFetchAppsForCareTeamBuilderLazyQuery
>;
export type PreFetchAppsForCareTeamBuilderQueryResult = Apollo.QueryResult<
    PreFetchAppsForCareTeamBuilderQuery,
    PreFetchAppsForCareTeamBuilderQueryVariables
>;
export const CareTeamByIdForCareTeamBuilderDocument = gql`
    query CareTeamByIdForCareTeamBuilder($input: GetCareTeamInput!) {
        careTeam(input: $input) {
            name
            appBundleId
            memberTypes {
                id
                label {
                    en
                    es
                }
                hiddenToPhone
                readOnly
                isRequired
                activeMembersCount
            }
        }
    }
`;

/**
 * __useCareTeamByIdForCareTeamBuilderQuery__
 *
 * To run a query within a React component, call `useCareTeamByIdForCareTeamBuilderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareTeamByIdForCareTeamBuilderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareTeamByIdForCareTeamBuilderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCareTeamByIdForCareTeamBuilderQuery(
    baseOptions: Apollo.QueryHookOptions<
        CareTeamByIdForCareTeamBuilderQuery,
        CareTeamByIdForCareTeamBuilderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        CareTeamByIdForCareTeamBuilderQuery,
        CareTeamByIdForCareTeamBuilderQueryVariables
    >(CareTeamByIdForCareTeamBuilderDocument, options);
}
export function useCareTeamByIdForCareTeamBuilderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CareTeamByIdForCareTeamBuilderQuery,
        CareTeamByIdForCareTeamBuilderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        CareTeamByIdForCareTeamBuilderQuery,
        CareTeamByIdForCareTeamBuilderQueryVariables
    >(CareTeamByIdForCareTeamBuilderDocument, options);
}
export type CareTeamByIdForCareTeamBuilderQueryHookResult = ReturnType<
    typeof useCareTeamByIdForCareTeamBuilderQuery
>;
export type CareTeamByIdForCareTeamBuilderLazyQueryHookResult = ReturnType<
    typeof useCareTeamByIdForCareTeamBuilderLazyQuery
>;
export type CareTeamByIdForCareTeamBuilderQueryResult = Apollo.QueryResult<
    CareTeamByIdForCareTeamBuilderQuery,
    CareTeamByIdForCareTeamBuilderQueryVariables
>;
export const CreateCareTeamForCareTeamBuilderDocument = gql`
    mutation CreateCareTeamForCareTeamBuilder($createCareTeamInput: CareTeamInput!) {
        createCareTeam(input: $createCareTeamInput) {
            success
            message
            resourceCreated {
                id
                name
                appBundleId
                memberTypes {
                    id
                    label {
                        en
                        es
                    }
                    hiddenToPhone
                    readOnly
                    isRequired
                }
            }
        }
    }
`;
export type CreateCareTeamForCareTeamBuilderMutationFn = Apollo.MutationFunction<
    CreateCareTeamForCareTeamBuilderMutation,
    CreateCareTeamForCareTeamBuilderMutationVariables
>;

/**
 * __useCreateCareTeamForCareTeamBuilderMutation__
 *
 * To run a mutation, you first call `useCreateCareTeamForCareTeamBuilderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCareTeamForCareTeamBuilderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCareTeamForCareTeamBuilderMutation, { data, loading, error }] = useCreateCareTeamForCareTeamBuilderMutation({
 *   variables: {
 *      createCareTeamInput: // value for 'createCareTeamInput'
 *   },
 * });
 */
export function useCreateCareTeamForCareTeamBuilderMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateCareTeamForCareTeamBuilderMutation,
        CreateCareTeamForCareTeamBuilderMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateCareTeamForCareTeamBuilderMutation,
        CreateCareTeamForCareTeamBuilderMutationVariables
    >(CreateCareTeamForCareTeamBuilderDocument, options);
}
export type CreateCareTeamForCareTeamBuilderMutationHookResult = ReturnType<
    typeof useCreateCareTeamForCareTeamBuilderMutation
>;
export type CreateCareTeamForCareTeamBuilderMutationResult =
    Apollo.MutationResult<CreateCareTeamForCareTeamBuilderMutation>;
export type CreateCareTeamForCareTeamBuilderMutationOptions = Apollo.BaseMutationOptions<
    CreateCareTeamForCareTeamBuilderMutation,
    CreateCareTeamForCareTeamBuilderMutationVariables
>;
export const UpdateCareTeamForCareTeamBuilderDocument = gql`
    mutation UpdateCareTeamForCareTeamBuilder($updateCareTeamInput: UpdateCareTeamInput!) {
        updateCareTeam(input: $updateCareTeamInput) {
            success
            message
            resourceUpdated {
                id
                name
                appBundleId
                memberTypes {
                    id
                    label {
                        en
                        es
                    }
                    hiddenToPhone
                    readOnly
                    isRequired
                }
            }
        }
    }
`;
export type UpdateCareTeamForCareTeamBuilderMutationFn = Apollo.MutationFunction<
    UpdateCareTeamForCareTeamBuilderMutation,
    UpdateCareTeamForCareTeamBuilderMutationVariables
>;

/**
 * __useUpdateCareTeamForCareTeamBuilderMutation__
 *
 * To run a mutation, you first call `useUpdateCareTeamForCareTeamBuilderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCareTeamForCareTeamBuilderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCareTeamForCareTeamBuilderMutation, { data, loading, error }] = useUpdateCareTeamForCareTeamBuilderMutation({
 *   variables: {
 *      updateCareTeamInput: // value for 'updateCareTeamInput'
 *   },
 * });
 */
export function useUpdateCareTeamForCareTeamBuilderMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateCareTeamForCareTeamBuilderMutation,
        UpdateCareTeamForCareTeamBuilderMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateCareTeamForCareTeamBuilderMutation,
        UpdateCareTeamForCareTeamBuilderMutationVariables
    >(UpdateCareTeamForCareTeamBuilderDocument, options);
}
export type UpdateCareTeamForCareTeamBuilderMutationHookResult = ReturnType<
    typeof useUpdateCareTeamForCareTeamBuilderMutation
>;
export type UpdateCareTeamForCareTeamBuilderMutationResult =
    Apollo.MutationResult<UpdateCareTeamForCareTeamBuilderMutation>;
export type UpdateCareTeamForCareTeamBuilderMutationOptions = Apollo.BaseMutationOptions<
    UpdateCareTeamForCareTeamBuilderMutation,
    UpdateCareTeamForCareTeamBuilderMutationVariables
>;
export const CareTeamsForCareTeamsPageDocument = gql`
    query CareTeamsForCareTeamsPage {
        careTeamsV2 {
            results {
                id
                name
                appBundleId
            }
        }
    }
`;

/**
 * __useCareTeamsForCareTeamsPageQuery__
 *
 * To run a query within a React component, call `useCareTeamsForCareTeamsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareTeamsForCareTeamsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareTeamsForCareTeamsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCareTeamsForCareTeamsPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        CareTeamsForCareTeamsPageQuery,
        CareTeamsForCareTeamsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CareTeamsForCareTeamsPageQuery, CareTeamsForCareTeamsPageQueryVariables>(
        CareTeamsForCareTeamsPageDocument,
        options,
    );
}
export function useCareTeamsForCareTeamsPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CareTeamsForCareTeamsPageQuery,
        CareTeamsForCareTeamsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        CareTeamsForCareTeamsPageQuery,
        CareTeamsForCareTeamsPageQueryVariables
    >(CareTeamsForCareTeamsPageDocument, options);
}
export type CareTeamsForCareTeamsPageQueryHookResult = ReturnType<
    typeof useCareTeamsForCareTeamsPageQuery
>;
export type CareTeamsForCareTeamsPageLazyQueryHookResult = ReturnType<
    typeof useCareTeamsForCareTeamsPageLazyQuery
>;
export type CareTeamsForCareTeamsPageQueryResult = Apollo.QueryResult<
    CareTeamsForCareTeamsPageQuery,
    CareTeamsForCareTeamsPageQueryVariables
>;
export const FetchReferenceLinksForCareTeamsPageDocument = gql`
    query FetchReferenceLinksForCareTeamsPage($input: GetReferenceLinksForPageInput!) {
        getReferenceLinksForPage(input: $input) {
            category
            data {
                label
                url
            }
        }
    }
`;

/**
 * __useFetchReferenceLinksForCareTeamsPageQuery__
 *
 * To run a query within a React component, call `useFetchReferenceLinksForCareTeamsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchReferenceLinksForCareTeamsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchReferenceLinksForCareTeamsPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchReferenceLinksForCareTeamsPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchReferenceLinksForCareTeamsPageQuery,
        FetchReferenceLinksForCareTeamsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchReferenceLinksForCareTeamsPageQuery,
        FetchReferenceLinksForCareTeamsPageQueryVariables
    >(FetchReferenceLinksForCareTeamsPageDocument, options);
}
export function useFetchReferenceLinksForCareTeamsPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchReferenceLinksForCareTeamsPageQuery,
        FetchReferenceLinksForCareTeamsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchReferenceLinksForCareTeamsPageQuery,
        FetchReferenceLinksForCareTeamsPageQueryVariables
    >(FetchReferenceLinksForCareTeamsPageDocument, options);
}
export type FetchReferenceLinksForCareTeamsPageQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForCareTeamsPageQuery
>;
export type FetchReferenceLinksForCareTeamsPageLazyQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForCareTeamsPageLazyQuery
>;
export type FetchReferenceLinksForCareTeamsPageQueryResult = Apollo.QueryResult<
    FetchReferenceLinksForCareTeamsPageQuery,
    FetchReferenceLinksForCareTeamsPageQueryVariables
>;
export const CompoundQuestionsV2Document = gql`
    query CompoundQuestionsV2($input: CompoundQuestionsListInput) {
        compoundQuestionsV2(input: $input) {
            total
            skip
            results {
                ...CompoundQuestionFragment
            }
        }
    }
    ${CompoundQuestionFragmentFragmentDoc}
`;

/**
 * __useCompoundQuestionsV2Query__
 *
 * To run a query within a React component, call `useCompoundQuestionsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useCompoundQuestionsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompoundQuestionsV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompoundQuestionsV2Query(
    baseOptions?: Apollo.QueryHookOptions<
        CompoundQuestionsV2Query,
        CompoundQuestionsV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CompoundQuestionsV2Query, CompoundQuestionsV2QueryVariables>(
        CompoundQuestionsV2Document,
        options,
    );
}
export function useCompoundQuestionsV2LazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CompoundQuestionsV2Query,
        CompoundQuestionsV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CompoundQuestionsV2Query, CompoundQuestionsV2QueryVariables>(
        CompoundQuestionsV2Document,
        options,
    );
}
export type CompoundQuestionsV2QueryHookResult = ReturnType<typeof useCompoundQuestionsV2Query>;
export type CompoundQuestionsV2LazyQueryHookResult = ReturnType<
    typeof useCompoundQuestionsV2LazyQuery
>;
export type CompoundQuestionsV2QueryResult = Apollo.QueryResult<
    CompoundQuestionsV2Query,
    CompoundQuestionsV2QueryVariables
>;
export const FetchProfileDefsForCompoundQuestionsDocument = gql`
    query FetchProfileDefsForCompoundQuestions {
        userProfileDefs {
            ...UserProfileDefFragment
        }
    }
    ${UserProfileDefFragmentFragmentDoc}
`;

/**
 * __useFetchProfileDefsForCompoundQuestionsQuery__
 *
 * To run a query within a React component, call `useFetchProfileDefsForCompoundQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProfileDefsForCompoundQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProfileDefsForCompoundQuestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchProfileDefsForCompoundQuestionsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchProfileDefsForCompoundQuestionsQuery,
        FetchProfileDefsForCompoundQuestionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchProfileDefsForCompoundQuestionsQuery,
        FetchProfileDefsForCompoundQuestionsQueryVariables
    >(FetchProfileDefsForCompoundQuestionsDocument, options);
}
export function useFetchProfileDefsForCompoundQuestionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchProfileDefsForCompoundQuestionsQuery,
        FetchProfileDefsForCompoundQuestionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchProfileDefsForCompoundQuestionsQuery,
        FetchProfileDefsForCompoundQuestionsQueryVariables
    >(FetchProfileDefsForCompoundQuestionsDocument, options);
}
export type FetchProfileDefsForCompoundQuestionsQueryHookResult = ReturnType<
    typeof useFetchProfileDefsForCompoundQuestionsQuery
>;
export type FetchProfileDefsForCompoundQuestionsLazyQueryHookResult = ReturnType<
    typeof useFetchProfileDefsForCompoundQuestionsLazyQuery
>;
export type FetchProfileDefsForCompoundQuestionsQueryResult = Apollo.QueryResult<
    FetchProfileDefsForCompoundQuestionsQuery,
    FetchProfileDefsForCompoundQuestionsQueryVariables
>;
export const CreateCompoundQuestionDocument = gql`
    mutation CreateCompoundQuestion($createCompoundQuestionInput: CompoundQuestionInput!) {
        createCompoundQuestion(input: $createCompoundQuestionInput) {
            success
            message
            resourceCreated {
                ...CompoundQuestionFragment
            }
        }
    }
    ${CompoundQuestionFragmentFragmentDoc}
`;
export type CreateCompoundQuestionMutationFn = Apollo.MutationFunction<
    CreateCompoundQuestionMutation,
    CreateCompoundQuestionMutationVariables
>;

/**
 * __useCreateCompoundQuestionMutation__
 *
 * To run a mutation, you first call `useCreateCompoundQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompoundQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompoundQuestionMutation, { data, loading, error }] = useCreateCompoundQuestionMutation({
 *   variables: {
 *      createCompoundQuestionInput: // value for 'createCompoundQuestionInput'
 *   },
 * });
 */
export function useCreateCompoundQuestionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateCompoundQuestionMutation,
        CreateCompoundQuestionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateCompoundQuestionMutation,
        CreateCompoundQuestionMutationVariables
    >(CreateCompoundQuestionDocument, options);
}
export type CreateCompoundQuestionMutationHookResult = ReturnType<
    typeof useCreateCompoundQuestionMutation
>;
export type CreateCompoundQuestionMutationResult =
    Apollo.MutationResult<CreateCompoundQuestionMutation>;
export type CreateCompoundQuestionMutationOptions = Apollo.BaseMutationOptions<
    CreateCompoundQuestionMutation,
    CreateCompoundQuestionMutationVariables
>;
export const UpdateCompoundQuestionDocument = gql`
    mutation UpdateCompoundQuestion($updateCompoundQuestionInput: UpdateCompoundQuestionInput!) {
        updateCompoundQuestion(input: $updateCompoundQuestionInput) {
            success
            message
            resourceUpdated {
                ...CompoundQuestionFragment
            }
        }
    }
    ${CompoundQuestionFragmentFragmentDoc}
`;
export type UpdateCompoundQuestionMutationFn = Apollo.MutationFunction<
    UpdateCompoundQuestionMutation,
    UpdateCompoundQuestionMutationVariables
>;

/**
 * __useUpdateCompoundQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateCompoundQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompoundQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompoundQuestionMutation, { data, loading, error }] = useUpdateCompoundQuestionMutation({
 *   variables: {
 *      updateCompoundQuestionInput: // value for 'updateCompoundQuestionInput'
 *   },
 * });
 */
export function useUpdateCompoundQuestionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateCompoundQuestionMutation,
        UpdateCompoundQuestionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateCompoundQuestionMutation,
        UpdateCompoundQuestionMutationVariables
    >(UpdateCompoundQuestionDocument, options);
}
export type UpdateCompoundQuestionMutationHookResult = ReturnType<
    typeof useUpdateCompoundQuestionMutation
>;
export type UpdateCompoundQuestionMutationResult =
    Apollo.MutationResult<UpdateCompoundQuestionMutation>;
export type UpdateCompoundQuestionMutationOptions = Apollo.BaseMutationOptions<
    UpdateCompoundQuestionMutation,
    UpdateCompoundQuestionMutationVariables
>;
export const SurveyDefListForCompoundQuestionDocument = gql`
    query SurveyDefListForCompoundQuestion($input: GetSurveyDefInput!) {
        surveyDef(input: $input) {
            id
            name
        }
    }
`;

/**
 * __useSurveyDefListForCompoundQuestionQuery__
 *
 * To run a query within a React component, call `useSurveyDefListForCompoundQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyDefListForCompoundQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyDefListForCompoundQuestionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSurveyDefListForCompoundQuestionQuery(
    baseOptions: Apollo.QueryHookOptions<
        SurveyDefListForCompoundQuestionQuery,
        SurveyDefListForCompoundQuestionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        SurveyDefListForCompoundQuestionQuery,
        SurveyDefListForCompoundQuestionQueryVariables
    >(SurveyDefListForCompoundQuestionDocument, options);
}
export function useSurveyDefListForCompoundQuestionLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SurveyDefListForCompoundQuestionQuery,
        SurveyDefListForCompoundQuestionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        SurveyDefListForCompoundQuestionQuery,
        SurveyDefListForCompoundQuestionQueryVariables
    >(SurveyDefListForCompoundQuestionDocument, options);
}
export type SurveyDefListForCompoundQuestionQueryHookResult = ReturnType<
    typeof useSurveyDefListForCompoundQuestionQuery
>;
export type SurveyDefListForCompoundQuestionLazyQueryHookResult = ReturnType<
    typeof useSurveyDefListForCompoundQuestionLazyQuery
>;
export type SurveyDefListForCompoundQuestionQueryResult = Apollo.QueryResult<
    SurveyDefListForCompoundQuestionQuery,
    SurveyDefListForCompoundQuestionQueryVariables
>;
export const GetSurveyDefsByCompoundQuestionIdDocument = gql`
    query GetSurveyDefsByCompoundQuestionId($compoundQuestionId: String!) {
        getSurveyDefsByCompoundQuestionId(compoundQuestionId: $compoundQuestionId) {
            name
            id
        }
    }
`;

/**
 * __useGetSurveyDefsByCompoundQuestionIdQuery__
 *
 * To run a query within a React component, call `useGetSurveyDefsByCompoundQuestionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyDefsByCompoundQuestionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyDefsByCompoundQuestionIdQuery({
 *   variables: {
 *      compoundQuestionId: // value for 'compoundQuestionId'
 *   },
 * });
 */
export function useGetSurveyDefsByCompoundQuestionIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetSurveyDefsByCompoundQuestionIdQuery,
        GetSurveyDefsByCompoundQuestionIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetSurveyDefsByCompoundQuestionIdQuery,
        GetSurveyDefsByCompoundQuestionIdQueryVariables
    >(GetSurveyDefsByCompoundQuestionIdDocument, options);
}
export function useGetSurveyDefsByCompoundQuestionIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetSurveyDefsByCompoundQuestionIdQuery,
        GetSurveyDefsByCompoundQuestionIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetSurveyDefsByCompoundQuestionIdQuery,
        GetSurveyDefsByCompoundQuestionIdQueryVariables
    >(GetSurveyDefsByCompoundQuestionIdDocument, options);
}
export type GetSurveyDefsByCompoundQuestionIdQueryHookResult = ReturnType<
    typeof useGetSurveyDefsByCompoundQuestionIdQuery
>;
export type GetSurveyDefsByCompoundQuestionIdLazyQueryHookResult = ReturnType<
    typeof useGetSurveyDefsByCompoundQuestionIdLazyQuery
>;
export type GetSurveyDefsByCompoundQuestionIdQueryResult = Apollo.QueryResult<
    GetSurveyDefsByCompoundQuestionIdQuery,
    GetSurveyDefsByCompoundQuestionIdQueryVariables
>;
export const DeleteCompoundQuestionDocument = gql`
    mutation DeleteCompoundQuestion($input: DeleteCompoundQuestionInput!) {
        deleteCompoundQuestion(input: $input) {
            status
            message
            success
            id
        }
    }
`;
export type DeleteCompoundQuestionMutationFn = Apollo.MutationFunction<
    DeleteCompoundQuestionMutation,
    DeleteCompoundQuestionMutationVariables
>;

/**
 * __useDeleteCompoundQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteCompoundQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompoundQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompoundQuestionMutation, { data, loading, error }] = useDeleteCompoundQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCompoundQuestionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteCompoundQuestionMutation,
        DeleteCompoundQuestionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteCompoundQuestionMutation,
        DeleteCompoundQuestionMutationVariables
    >(DeleteCompoundQuestionDocument, options);
}
export type DeleteCompoundQuestionMutationHookResult = ReturnType<
    typeof useDeleteCompoundQuestionMutation
>;
export type DeleteCompoundQuestionMutationResult =
    Apollo.MutationResult<DeleteCompoundQuestionMutation>;
export type DeleteCompoundQuestionMutationOptions = Apollo.BaseMutationOptions<
    DeleteCompoundQuestionMutation,
    DeleteCompoundQuestionMutationVariables
>;
export const CompoundQuestionDocument = gql`
    query CompoundQuestion($input: GetCompoundQuestionInput!) {
        compoundQuestion(input: $input) {
            ...CompoundQuestionFragment
        }
    }
    ${CompoundQuestionFragmentFragmentDoc}
`;

/**
 * __useCompoundQuestionQuery__
 *
 * To run a query within a React component, call `useCompoundQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompoundQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompoundQuestionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompoundQuestionQuery(
    baseOptions: Apollo.QueryHookOptions<CompoundQuestionQuery, CompoundQuestionQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CompoundQuestionQuery, CompoundQuestionQueryVariables>(
        CompoundQuestionDocument,
        options,
    );
}
export function useCompoundQuestionLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CompoundQuestionQuery,
        CompoundQuestionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CompoundQuestionQuery, CompoundQuestionQueryVariables>(
        CompoundQuestionDocument,
        options,
    );
}
export type CompoundQuestionQueryHookResult = ReturnType<typeof useCompoundQuestionQuery>;
export type CompoundQuestionLazyQueryHookResult = ReturnType<typeof useCompoundQuestionLazyQuery>;
export type CompoundQuestionQueryResult = Apollo.QueryResult<
    CompoundQuestionQuery,
    CompoundQuestionQueryVariables
>;
export const ConditionTypeByIdDocument = gql`
    query ConditionTypeById($input: GetConditionTypeInput!) {
        conditionType(input: $input) {
            id
            name {
                en
                es
            }
        }
    }
`;

/**
 * __useConditionTypeByIdQuery__
 *
 * To run a query within a React component, call `useConditionTypeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useConditionTypeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConditionTypeByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConditionTypeByIdQuery(
    baseOptions: Apollo.QueryHookOptions<ConditionTypeByIdQuery, ConditionTypeByIdQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ConditionTypeByIdQuery, ConditionTypeByIdQueryVariables>(
        ConditionTypeByIdDocument,
        options,
    );
}
export function useConditionTypeByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ConditionTypeByIdQuery,
        ConditionTypeByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ConditionTypeByIdQuery, ConditionTypeByIdQueryVariables>(
        ConditionTypeByIdDocument,
        options,
    );
}
export type ConditionTypeByIdQueryHookResult = ReturnType<typeof useConditionTypeByIdQuery>;
export type ConditionTypeByIdLazyQueryHookResult = ReturnType<typeof useConditionTypeByIdLazyQuery>;
export type ConditionTypeByIdQueryResult = Apollo.QueryResult<
    ConditionTypeByIdQuery,
    ConditionTypeByIdQueryVariables
>;
export const ConditionTypePageListDocument = gql`
    query ConditionTypePageList {
        conditionTypes {
            id
            name {
                en
                es
            }
        }
    }
`;

/**
 * __useConditionTypePageListQuery__
 *
 * To run a query within a React component, call `useConditionTypePageListQuery` and pass it any options that fit your needs.
 * When your component renders, `useConditionTypePageListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConditionTypePageListQuery({
 *   variables: {
 *   },
 * });
 */
export function useConditionTypePageListQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ConditionTypePageListQuery,
        ConditionTypePageListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ConditionTypePageListQuery, ConditionTypePageListQueryVariables>(
        ConditionTypePageListDocument,
        options,
    );
}
export function useConditionTypePageListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ConditionTypePageListQuery,
        ConditionTypePageListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ConditionTypePageListQuery, ConditionTypePageListQueryVariables>(
        ConditionTypePageListDocument,
        options,
    );
}
export type ConditionTypePageListQueryHookResult = ReturnType<typeof useConditionTypePageListQuery>;
export type ConditionTypePageListLazyQueryHookResult = ReturnType<
    typeof useConditionTypePageListLazyQuery
>;
export type ConditionTypePageListQueryResult = Apollo.QueryResult<
    ConditionTypePageListQuery,
    ConditionTypePageListQueryVariables
>;
export const CreateConditionTypeDocument = gql`
    mutation CreateConditionType($input: ConditionTypeInput!) {
        createConditionType(input: $input) {
            status
            message
            success
            resourceCreated {
                id
                name {
                    en
                    es
                }
            }
        }
    }
`;
export type CreateConditionTypeMutationFn = Apollo.MutationFunction<
    CreateConditionTypeMutation,
    CreateConditionTypeMutationVariables
>;

/**
 * __useCreateConditionTypeMutation__
 *
 * To run a mutation, you first call `useCreateConditionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConditionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConditionTypeMutation, { data, loading, error }] = useCreateConditionTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConditionTypeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateConditionTypeMutation,
        CreateConditionTypeMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateConditionTypeMutation, CreateConditionTypeMutationVariables>(
        CreateConditionTypeDocument,
        options,
    );
}
export type CreateConditionTypeMutationHookResult = ReturnType<
    typeof useCreateConditionTypeMutation
>;
export type CreateConditionTypeMutationResult = Apollo.MutationResult<CreateConditionTypeMutation>;
export type CreateConditionTypeMutationOptions = Apollo.BaseMutationOptions<
    CreateConditionTypeMutation,
    CreateConditionTypeMutationVariables
>;
export const UpdateConditionTypeDocument = gql`
    mutation UpdateConditionType($updateConditionTypeInput: UpdateConditionTypeInput!) {
        updateConditionType(input: $updateConditionTypeInput) {
            status
            message
            success
            resourceUpdated {
                id
                name {
                    en
                    es
                }
            }
        }
    }
`;
export type UpdateConditionTypeMutationFn = Apollo.MutationFunction<
    UpdateConditionTypeMutation,
    UpdateConditionTypeMutationVariables
>;

/**
 * __useUpdateConditionTypeMutation__
 *
 * To run a mutation, you first call `useUpdateConditionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConditionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConditionTypeMutation, { data, loading, error }] = useUpdateConditionTypeMutation({
 *   variables: {
 *      updateConditionTypeInput: // value for 'updateConditionTypeInput'
 *   },
 * });
 */
export function useUpdateConditionTypeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateConditionTypeMutation,
        UpdateConditionTypeMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateConditionTypeMutation, UpdateConditionTypeMutationVariables>(
        UpdateConditionTypeDocument,
        options,
    );
}
export type UpdateConditionTypeMutationHookResult = ReturnType<
    typeof useUpdateConditionTypeMutation
>;
export type UpdateConditionTypeMutationResult = Apollo.MutationResult<UpdateConditionTypeMutation>;
export type UpdateConditionTypeMutationOptions = Apollo.BaseMutationOptions<
    UpdateConditionTypeMutation,
    UpdateConditionTypeMutationVariables
>;
export const DeleteConditionTypeDocument = gql`
    mutation DeleteConditionType($id: String!) {
        deleteConditionType(id: $id) {
            success
            message
            status
            id
        }
    }
`;
export type DeleteConditionTypeMutationFn = Apollo.MutationFunction<
    DeleteConditionTypeMutation,
    DeleteConditionTypeMutationVariables
>;

/**
 * __useDeleteConditionTypeMutation__
 *
 * To run a mutation, you first call `useDeleteConditionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConditionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConditionTypeMutation, { data, loading, error }] = useDeleteConditionTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteConditionTypeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteConditionTypeMutation,
        DeleteConditionTypeMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteConditionTypeMutation, DeleteConditionTypeMutationVariables>(
        DeleteConditionTypeDocument,
        options,
    );
}
export type DeleteConditionTypeMutationHookResult = ReturnType<
    typeof useDeleteConditionTypeMutation
>;
export type DeleteConditionTypeMutationResult = Apollo.MutationResult<DeleteConditionTypeMutation>;
export type DeleteConditionTypeMutationOptions = Apollo.BaseMutationOptions<
    DeleteConditionTypeMutation,
    DeleteConditionTypeMutationVariables
>;
export const ConditionTypeWhereUsedDocument = gql`
    query ConditionTypeWhereUsed($id: String!) {
        conditionTypeWhereUsed(id: $id) {
            dependencies {
                entityType
                blocksDelete
                total
                entries {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useConditionTypeWhereUsedQuery__
 *
 * To run a query within a React component, call `useConditionTypeWhereUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useConditionTypeWhereUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConditionTypeWhereUsedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConditionTypeWhereUsedQuery(
    baseOptions: Apollo.QueryHookOptions<
        ConditionTypeWhereUsedQuery,
        ConditionTypeWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ConditionTypeWhereUsedQuery, ConditionTypeWhereUsedQueryVariables>(
        ConditionTypeWhereUsedDocument,
        options,
    );
}
export function useConditionTypeWhereUsedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ConditionTypeWhereUsedQuery,
        ConditionTypeWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ConditionTypeWhereUsedQuery, ConditionTypeWhereUsedQueryVariables>(
        ConditionTypeWhereUsedDocument,
        options,
    );
}
export type ConditionTypeWhereUsedQueryHookResult = ReturnType<
    typeof useConditionTypeWhereUsedQuery
>;
export type ConditionTypeWhereUsedLazyQueryHookResult = ReturnType<
    typeof useConditionTypeWhereUsedLazyQuery
>;
export type ConditionTypeWhereUsedQueryResult = Apollo.QueryResult<
    ConditionTypeWhereUsedQuery,
    ConditionTypeWhereUsedQueryVariables
>;
export const FetchConfigAppForConfigAppSummaryPageDocument = gql`
    query FetchConfigAppForConfigAppSummaryPage($input: GetAMSAppByBundleId!) {
        amsAppByBundleId(input: $input) {
            id
            name
            fullName
            logo
            bundleId
            values {
                ...ConfigValuePage
            }
            defaultValues {
                ...ConfigValueDefPage
            }
            config
        }
    }
    ${ConfigValuePageFragmentDoc}
    ${ConfigValueDefPageFragmentDoc}
`;

/**
 * __useFetchConfigAppForConfigAppSummaryPageQuery__
 *
 * To run a query within a React component, call `useFetchConfigAppForConfigAppSummaryPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchConfigAppForConfigAppSummaryPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchConfigAppForConfigAppSummaryPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchConfigAppForConfigAppSummaryPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchConfigAppForConfigAppSummaryPageQuery,
        FetchConfigAppForConfigAppSummaryPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchConfigAppForConfigAppSummaryPageQuery,
        FetchConfigAppForConfigAppSummaryPageQueryVariables
    >(FetchConfigAppForConfigAppSummaryPageDocument, options);
}
export function useFetchConfigAppForConfigAppSummaryPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchConfigAppForConfigAppSummaryPageQuery,
        FetchConfigAppForConfigAppSummaryPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchConfigAppForConfigAppSummaryPageQuery,
        FetchConfigAppForConfigAppSummaryPageQueryVariables
    >(FetchConfigAppForConfigAppSummaryPageDocument, options);
}
export type FetchConfigAppForConfigAppSummaryPageQueryHookResult = ReturnType<
    typeof useFetchConfigAppForConfigAppSummaryPageQuery
>;
export type FetchConfigAppForConfigAppSummaryPageLazyQueryHookResult = ReturnType<
    typeof useFetchConfigAppForConfigAppSummaryPageLazyQuery
>;
export type FetchConfigAppForConfigAppSummaryPageQueryResult = Apollo.QueryResult<
    FetchConfigAppForConfigAppSummaryPageQuery,
    FetchConfigAppForConfigAppSummaryPageQueryVariables
>;
export const SurveyScreensForOnboardingPageDocument = gql`
    query SurveyScreensForOnboardingPage {
        surveyDefs {
            id
            name
            label {
                en
            }
            organization {
                id
                brandingName
            }
        }
    }
`;

/**
 * __useSurveyScreensForOnboardingPageQuery__
 *
 * To run a query within a React component, call `useSurveyScreensForOnboardingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyScreensForOnboardingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyScreensForOnboardingPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useSurveyScreensForOnboardingPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        SurveyScreensForOnboardingPageQuery,
        SurveyScreensForOnboardingPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        SurveyScreensForOnboardingPageQuery,
        SurveyScreensForOnboardingPageQueryVariables
    >(SurveyScreensForOnboardingPageDocument, options);
}
export function useSurveyScreensForOnboardingPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SurveyScreensForOnboardingPageQuery,
        SurveyScreensForOnboardingPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        SurveyScreensForOnboardingPageQuery,
        SurveyScreensForOnboardingPageQueryVariables
    >(SurveyScreensForOnboardingPageDocument, options);
}
export type SurveyScreensForOnboardingPageQueryHookResult = ReturnType<
    typeof useSurveyScreensForOnboardingPageQuery
>;
export type SurveyScreensForOnboardingPageLazyQueryHookResult = ReturnType<
    typeof useSurveyScreensForOnboardingPageLazyQuery
>;
export type SurveyScreensForOnboardingPageQueryResult = Apollo.QueryResult<
    SurveyScreensForOnboardingPageQuery,
    SurveyScreensForOnboardingPageQueryVariables
>;
export const FetchConfigAppForOnboardingScreensPageDocument = gql`
    query FetchConfigAppForOnboardingScreensPage($input: GetAMSAppByBundleId!) {
        amsAppByBundleId(input: $input) {
            values {
                ...ConfigValuePage
            }
        }
    }
    ${ConfigValuePageFragmentDoc}
`;

/**
 * __useFetchConfigAppForOnboardingScreensPageQuery__
 *
 * To run a query within a React component, call `useFetchConfigAppForOnboardingScreensPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchConfigAppForOnboardingScreensPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchConfigAppForOnboardingScreensPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchConfigAppForOnboardingScreensPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchConfigAppForOnboardingScreensPageQuery,
        FetchConfigAppForOnboardingScreensPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchConfigAppForOnboardingScreensPageQuery,
        FetchConfigAppForOnboardingScreensPageQueryVariables
    >(FetchConfigAppForOnboardingScreensPageDocument, options);
}
export function useFetchConfigAppForOnboardingScreensPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchConfigAppForOnboardingScreensPageQuery,
        FetchConfigAppForOnboardingScreensPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchConfigAppForOnboardingScreensPageQuery,
        FetchConfigAppForOnboardingScreensPageQueryVariables
    >(FetchConfigAppForOnboardingScreensPageDocument, options);
}
export type FetchConfigAppForOnboardingScreensPageQueryHookResult = ReturnType<
    typeof useFetchConfigAppForOnboardingScreensPageQuery
>;
export type FetchConfigAppForOnboardingScreensPageLazyQueryHookResult = ReturnType<
    typeof useFetchConfigAppForOnboardingScreensPageLazyQuery
>;
export type FetchConfigAppForOnboardingScreensPageQueryResult = Apollo.QueryResult<
    FetchConfigAppForOnboardingScreensPageQuery,
    FetchConfigAppForOnboardingScreensPageQueryVariables
>;
export const UpsertAppValueForOnboardingAppDefDocument = gql`
    mutation UpsertAppValueForOnboardingAppDef($input: UpsertConfigValueByValueDefIdInput!) {
        upsertConfigValueByValueDefId(input: $input) {
            success
            message
            status
            configValue {
                ...ConfigValuePage
            }
        }
    }
    ${ConfigValuePageFragmentDoc}
`;
export type UpsertAppValueForOnboardingAppDefMutationFn = Apollo.MutationFunction<
    UpsertAppValueForOnboardingAppDefMutation,
    UpsertAppValueForOnboardingAppDefMutationVariables
>;

/**
 * __useUpsertAppValueForOnboardingAppDefMutation__
 *
 * To run a mutation, you first call `useUpsertAppValueForOnboardingAppDefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAppValueForOnboardingAppDefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAppValueForOnboardingAppDefMutation, { data, loading, error }] = useUpsertAppValueForOnboardingAppDefMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertAppValueForOnboardingAppDefMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpsertAppValueForOnboardingAppDefMutation,
        UpsertAppValueForOnboardingAppDefMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpsertAppValueForOnboardingAppDefMutation,
        UpsertAppValueForOnboardingAppDefMutationVariables
    >(UpsertAppValueForOnboardingAppDefDocument, options);
}
export type UpsertAppValueForOnboardingAppDefMutationHookResult = ReturnType<
    typeof useUpsertAppValueForOnboardingAppDefMutation
>;
export type UpsertAppValueForOnboardingAppDefMutationResult =
    Apollo.MutationResult<UpsertAppValueForOnboardingAppDefMutation>;
export type UpsertAppValueForOnboardingAppDefMutationOptions = Apollo.BaseMutationOptions<
    UpsertAppValueForOnboardingAppDefMutation,
    UpsertAppValueForOnboardingAppDefMutationVariables
>;
export const FetchExpressionForAppValuesModalDocument = gql`
    query FetchExpressionForAppValuesModal {
        configExpressions {
            ...ConfigExpression
        }
    }
    ${ConfigExpressionFragmentDoc}
`;

/**
 * __useFetchExpressionForAppValuesModalQuery__
 *
 * To run a query within a React component, call `useFetchExpressionForAppValuesModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchExpressionForAppValuesModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchExpressionForAppValuesModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchExpressionForAppValuesModalQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchExpressionForAppValuesModalQuery,
        FetchExpressionForAppValuesModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchExpressionForAppValuesModalQuery,
        FetchExpressionForAppValuesModalQueryVariables
    >(FetchExpressionForAppValuesModalDocument, options);
}
export function useFetchExpressionForAppValuesModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchExpressionForAppValuesModalQuery,
        FetchExpressionForAppValuesModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchExpressionForAppValuesModalQuery,
        FetchExpressionForAppValuesModalQueryVariables
    >(FetchExpressionForAppValuesModalDocument, options);
}
export type FetchExpressionForAppValuesModalQueryHookResult = ReturnType<
    typeof useFetchExpressionForAppValuesModalQuery
>;
export type FetchExpressionForAppValuesModalLazyQueryHookResult = ReturnType<
    typeof useFetchExpressionForAppValuesModalLazyQuery
>;
export type FetchExpressionForAppValuesModalQueryResult = Apollo.QueryResult<
    FetchExpressionForAppValuesModalQuery,
    FetchExpressionForAppValuesModalQueryVariables
>;
export const UpsertAppValueForAppDefDocument = gql`
    mutation UpsertAppValueForAppDef($input: UpsertConfigValueByValueDefIdInput!) {
        upsertConfigValueByValueDefId(input: $input) {
            success
            message
            status
            configValue {
                ...ConfigValuePage
            }
        }
    }
    ${ConfigValuePageFragmentDoc}
`;
export type UpsertAppValueForAppDefMutationFn = Apollo.MutationFunction<
    UpsertAppValueForAppDefMutation,
    UpsertAppValueForAppDefMutationVariables
>;

/**
 * __useUpsertAppValueForAppDefMutation__
 *
 * To run a mutation, you first call `useUpsertAppValueForAppDefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAppValueForAppDefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAppValueForAppDefMutation, { data, loading, error }] = useUpsertAppValueForAppDefMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertAppValueForAppDefMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpsertAppValueForAppDefMutation,
        UpsertAppValueForAppDefMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpsertAppValueForAppDefMutation,
        UpsertAppValueForAppDefMutationVariables
    >(UpsertAppValueForAppDefDocument, options);
}
export type UpsertAppValueForAppDefMutationHookResult = ReturnType<
    typeof useUpsertAppValueForAppDefMutation
>;
export type UpsertAppValueForAppDefMutationResult =
    Apollo.MutationResult<UpsertAppValueForAppDefMutation>;
export type UpsertAppValueForAppDefMutationOptions = Apollo.BaseMutationOptions<
    UpsertAppValueForAppDefMutation,
    UpsertAppValueForAppDefMutationVariables
>;
export const FetchConfigAppsForConfigAppsPageDocument = gql`
    query FetchConfigAppsForConfigAppsPage {
        amsApps {
            bundleId
            id
            name
            fullName
            logo
        }
    }
`;

/**
 * __useFetchConfigAppsForConfigAppsPageQuery__
 *
 * To run a query within a React component, call `useFetchConfigAppsForConfigAppsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchConfigAppsForConfigAppsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchConfigAppsForConfigAppsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchConfigAppsForConfigAppsPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchConfigAppsForConfigAppsPageQuery,
        FetchConfigAppsForConfigAppsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchConfigAppsForConfigAppsPageQuery,
        FetchConfigAppsForConfigAppsPageQueryVariables
    >(FetchConfigAppsForConfigAppsPageDocument, options);
}
export function useFetchConfigAppsForConfigAppsPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchConfigAppsForConfigAppsPageQuery,
        FetchConfigAppsForConfigAppsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchConfigAppsForConfigAppsPageQuery,
        FetchConfigAppsForConfigAppsPageQueryVariables
    >(FetchConfigAppsForConfigAppsPageDocument, options);
}
export type FetchConfigAppsForConfigAppsPageQueryHookResult = ReturnType<
    typeof useFetchConfigAppsForConfigAppsPageQuery
>;
export type FetchConfigAppsForConfigAppsPageLazyQueryHookResult = ReturnType<
    typeof useFetchConfigAppsForConfigAppsPageLazyQuery
>;
export type FetchConfigAppsForConfigAppsPageQueryResult = Apollo.QueryResult<
    FetchConfigAppsForConfigAppsPageQuery,
    FetchConfigAppsForConfigAppsPageQueryVariables
>;
export const FetchConfigExpressionListDocument = gql`
    query FetchConfigExpressionList {
        configExpressions {
            ...ConfigExpression
        }
    }
    ${ConfigExpressionFragmentDoc}
`;

/**
 * __useFetchConfigExpressionListQuery__
 *
 * To run a query within a React component, call `useFetchConfigExpressionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchConfigExpressionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchConfigExpressionListQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchConfigExpressionListQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchConfigExpressionListQuery,
        FetchConfigExpressionListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchConfigExpressionListQuery, FetchConfigExpressionListQueryVariables>(
        FetchConfigExpressionListDocument,
        options,
    );
}
export function useFetchConfigExpressionListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchConfigExpressionListQuery,
        FetchConfigExpressionListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchConfigExpressionListQuery,
        FetchConfigExpressionListQueryVariables
    >(FetchConfigExpressionListDocument, options);
}
export type FetchConfigExpressionListQueryHookResult = ReturnType<
    typeof useFetchConfigExpressionListQuery
>;
export type FetchConfigExpressionListLazyQueryHookResult = ReturnType<
    typeof useFetchConfigExpressionListLazyQuery
>;
export type FetchConfigExpressionListQueryResult = Apollo.QueryResult<
    FetchConfigExpressionListQuery,
    FetchConfigExpressionListQueryVariables
>;
export const FetchConfigExpressionListV2Document = gql`
    query FetchConfigExpressionListV2($input: ConfigExpressionListInput) {
        configExpressionsV2(input: $input) {
            skip
            limit
            total
            hasMore
            hasPrevious
            results {
                id
                name
                description
                useType
                profileDefIds
            }
        }
    }
`;

/**
 * __useFetchConfigExpressionListV2Query__
 *
 * To run a query within a React component, call `useFetchConfigExpressionListV2Query` and pass it any options that fit your needs.
 * When your component renders, `useFetchConfigExpressionListV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchConfigExpressionListV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchConfigExpressionListV2Query(
    baseOptions?: Apollo.QueryHookOptions<
        FetchConfigExpressionListV2Query,
        FetchConfigExpressionListV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchConfigExpressionListV2Query,
        FetchConfigExpressionListV2QueryVariables
    >(FetchConfigExpressionListV2Document, options);
}
export function useFetchConfigExpressionListV2LazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchConfigExpressionListV2Query,
        FetchConfigExpressionListV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchConfigExpressionListV2Query,
        FetchConfigExpressionListV2QueryVariables
    >(FetchConfigExpressionListV2Document, options);
}
export type FetchConfigExpressionListV2QueryHookResult = ReturnType<
    typeof useFetchConfigExpressionListV2Query
>;
export type FetchConfigExpressionListV2LazyQueryHookResult = ReturnType<
    typeof useFetchConfigExpressionListV2LazyQuery
>;
export type FetchConfigExpressionListV2QueryResult = Apollo.QueryResult<
    FetchConfigExpressionListV2Query,
    FetchConfigExpressionListV2QueryVariables
>;
export const CreateConfigExpressionDocument = gql`
    mutation CreateConfigExpression($input: ConfigExpressionInput!) {
        createConfigExpression(input: $input) {
            message
            success
            resourceCreated {
                ...ConfigExpression
            }
        }
    }
    ${ConfigExpressionFragmentDoc}
`;
export type CreateConfigExpressionMutationFn = Apollo.MutationFunction<
    CreateConfigExpressionMutation,
    CreateConfigExpressionMutationVariables
>;

/**
 * __useCreateConfigExpressionMutation__
 *
 * To run a mutation, you first call `useCreateConfigExpressionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConfigExpressionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConfigExpressionMutation, { data, loading, error }] = useCreateConfigExpressionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConfigExpressionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateConfigExpressionMutation,
        CreateConfigExpressionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateConfigExpressionMutation,
        CreateConfigExpressionMutationVariables
    >(CreateConfigExpressionDocument, options);
}
export type CreateConfigExpressionMutationHookResult = ReturnType<
    typeof useCreateConfigExpressionMutation
>;
export type CreateConfigExpressionMutationResult =
    Apollo.MutationResult<CreateConfigExpressionMutation>;
export type CreateConfigExpressionMutationOptions = Apollo.BaseMutationOptions<
    CreateConfigExpressionMutation,
    CreateConfigExpressionMutationVariables
>;
export const FetchConfigExpressionDocument = gql`
    query FetchConfigExpression($input: GetConfigExpressionInput!) {
        configExpression(input: $input) {
            ...ConfigExpression
        }
    }
    ${ConfigExpressionFragmentDoc}
`;

/**
 * __useFetchConfigExpressionQuery__
 *
 * To run a query within a React component, call `useFetchConfigExpressionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchConfigExpressionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchConfigExpressionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchConfigExpressionQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchConfigExpressionQuery,
        FetchConfigExpressionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchConfigExpressionQuery, FetchConfigExpressionQueryVariables>(
        FetchConfigExpressionDocument,
        options,
    );
}
export function useFetchConfigExpressionLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchConfigExpressionQuery,
        FetchConfigExpressionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchConfigExpressionQuery, FetchConfigExpressionQueryVariables>(
        FetchConfigExpressionDocument,
        options,
    );
}
export type FetchConfigExpressionQueryHookResult = ReturnType<typeof useFetchConfigExpressionQuery>;
export type FetchConfigExpressionLazyQueryHookResult = ReturnType<
    typeof useFetchConfigExpressionLazyQuery
>;
export type FetchConfigExpressionQueryResult = Apollo.QueryResult<
    FetchConfigExpressionQuery,
    FetchConfigExpressionQueryVariables
>;
export const FetchConfigValueDefsForExpressionPageDocument = gql`
    query FetchConfigValueDefsForExpressionPage {
        configValueDefs {
            ...ConfigValueDefPage
        }
    }
    ${ConfigValueDefPageFragmentDoc}
`;

/**
 * __useFetchConfigValueDefsForExpressionPageQuery__
 *
 * To run a query within a React component, call `useFetchConfigValueDefsForExpressionPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchConfigValueDefsForExpressionPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchConfigValueDefsForExpressionPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchConfigValueDefsForExpressionPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchConfigValueDefsForExpressionPageQuery,
        FetchConfigValueDefsForExpressionPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchConfigValueDefsForExpressionPageQuery,
        FetchConfigValueDefsForExpressionPageQueryVariables
    >(FetchConfigValueDefsForExpressionPageDocument, options);
}
export function useFetchConfigValueDefsForExpressionPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchConfigValueDefsForExpressionPageQuery,
        FetchConfigValueDefsForExpressionPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchConfigValueDefsForExpressionPageQuery,
        FetchConfigValueDefsForExpressionPageQueryVariables
    >(FetchConfigValueDefsForExpressionPageDocument, options);
}
export type FetchConfigValueDefsForExpressionPageQueryHookResult = ReturnType<
    typeof useFetchConfigValueDefsForExpressionPageQuery
>;
export type FetchConfigValueDefsForExpressionPageLazyQueryHookResult = ReturnType<
    typeof useFetchConfigValueDefsForExpressionPageLazyQuery
>;
export type FetchConfigValueDefsForExpressionPageQueryResult = Apollo.QueryResult<
    FetchConfigValueDefsForExpressionPageQuery,
    FetchConfigValueDefsForExpressionPageQueryVariables
>;
export const FetchProfileDefsForExpressionPageDocument = gql`
    query FetchProfileDefsForExpressionPage {
        userProfileDefs {
            ...UserProfileDefFragment
        }
    }
    ${UserProfileDefFragmentFragmentDoc}
`;

/**
 * __useFetchProfileDefsForExpressionPageQuery__
 *
 * To run a query within a React component, call `useFetchProfileDefsForExpressionPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProfileDefsForExpressionPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProfileDefsForExpressionPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchProfileDefsForExpressionPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchProfileDefsForExpressionPageQuery,
        FetchProfileDefsForExpressionPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchProfileDefsForExpressionPageQuery,
        FetchProfileDefsForExpressionPageQueryVariables
    >(FetchProfileDefsForExpressionPageDocument, options);
}
export function useFetchProfileDefsForExpressionPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchProfileDefsForExpressionPageQuery,
        FetchProfileDefsForExpressionPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchProfileDefsForExpressionPageQuery,
        FetchProfileDefsForExpressionPageQueryVariables
    >(FetchProfileDefsForExpressionPageDocument, options);
}
export type FetchProfileDefsForExpressionPageQueryHookResult = ReturnType<
    typeof useFetchProfileDefsForExpressionPageQuery
>;
export type FetchProfileDefsForExpressionPageLazyQueryHookResult = ReturnType<
    typeof useFetchProfileDefsForExpressionPageLazyQuery
>;
export type FetchProfileDefsForExpressionPageQueryResult = Apollo.QueryResult<
    FetchProfileDefsForExpressionPageQuery,
    FetchProfileDefsForExpressionPageQueryVariables
>;
export const UpdateConfigValueExpressionDocument = gql`
    mutation UpdateConfigValueExpression($input: UpdateConfigExpressionInput!) {
        updateConfigExpression(input: $input) {
            message
            success
            resourceUpdated {
                ...ConfigExpression
            }
        }
    }
    ${ConfigExpressionFragmentDoc}
`;
export type UpdateConfigValueExpressionMutationFn = Apollo.MutationFunction<
    UpdateConfigValueExpressionMutation,
    UpdateConfigValueExpressionMutationVariables
>;

/**
 * __useUpdateConfigValueExpressionMutation__
 *
 * To run a mutation, you first call `useUpdateConfigValueExpressionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConfigValueExpressionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConfigValueExpressionMutation, { data, loading, error }] = useUpdateConfigValueExpressionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConfigValueExpressionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateConfigValueExpressionMutation,
        UpdateConfigValueExpressionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateConfigValueExpressionMutation,
        UpdateConfigValueExpressionMutationVariables
    >(UpdateConfigValueExpressionDocument, options);
}
export type UpdateConfigValueExpressionMutationHookResult = ReturnType<
    typeof useUpdateConfigValueExpressionMutation
>;
export type UpdateConfigValueExpressionMutationResult =
    Apollo.MutationResult<UpdateConfigValueExpressionMutation>;
export type UpdateConfigValueExpressionMutationOptions = Apollo.BaseMutationOptions<
    UpdateConfigValueExpressionMutation,
    UpdateConfigValueExpressionMutationVariables
>;
export const ExpressionWhereUsedDocument = gql`
    query ExpressionWhereUsed($id: String!) {
        expressionWhereUsed(id: $id) {
            dependencies {
                entityType
                blocksDelete
                total
                entries {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useExpressionWhereUsedQuery__
 *
 * To run a query within a React component, call `useExpressionWhereUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpressionWhereUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpressionWhereUsedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExpressionWhereUsedQuery(
    baseOptions: Apollo.QueryHookOptions<
        ExpressionWhereUsedQuery,
        ExpressionWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ExpressionWhereUsedQuery, ExpressionWhereUsedQueryVariables>(
        ExpressionWhereUsedDocument,
        options,
    );
}
export function useExpressionWhereUsedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ExpressionWhereUsedQuery,
        ExpressionWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ExpressionWhereUsedQuery, ExpressionWhereUsedQueryVariables>(
        ExpressionWhereUsedDocument,
        options,
    );
}
export type ExpressionWhereUsedQueryHookResult = ReturnType<typeof useExpressionWhereUsedQuery>;
export type ExpressionWhereUsedLazyQueryHookResult = ReturnType<
    typeof useExpressionWhereUsedLazyQuery
>;
export type ExpressionWhereUsedQueryResult = Apollo.QueryResult<
    ExpressionWhereUsedQuery,
    ExpressionWhereUsedQueryVariables
>;
export const DeleteExpressionDocument = gql`
    mutation DeleteExpression($id: String!) {
        deleteExpression(id: $id) {
            status
            message
            success
            id
        }
    }
`;
export type DeleteExpressionMutationFn = Apollo.MutationFunction<
    DeleteExpressionMutation,
    DeleteExpressionMutationVariables
>;

/**
 * __useDeleteExpressionMutation__
 *
 * To run a mutation, you first call `useDeleteExpressionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExpressionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExpressionMutation, { data, loading, error }] = useDeleteExpressionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExpressionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteExpressionMutation,
        DeleteExpressionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteExpressionMutation, DeleteExpressionMutationVariables>(
        DeleteExpressionDocument,
        options,
    );
}
export type DeleteExpressionMutationHookResult = ReturnType<typeof useDeleteExpressionMutation>;
export type DeleteExpressionMutationResult = Apollo.MutationResult<DeleteExpressionMutation>;
export type DeleteExpressionMutationOptions = Apollo.BaseMutationOptions<
    DeleteExpressionMutation,
    DeleteExpressionMutationVariables
>;
export const FetchConfigValueDefsForConfigValuesPageDocument = gql`
    query FetchConfigValueDefsForConfigValuesPage {
        configValueDefs {
            ...ConfigValueDefPage
        }
    }
    ${ConfigValueDefPageFragmentDoc}
`;

/**
 * __useFetchConfigValueDefsForConfigValuesPageQuery__
 *
 * To run a query within a React component, call `useFetchConfigValueDefsForConfigValuesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchConfigValueDefsForConfigValuesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchConfigValueDefsForConfigValuesPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchConfigValueDefsForConfigValuesPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchConfigValueDefsForConfigValuesPageQuery,
        FetchConfigValueDefsForConfigValuesPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchConfigValueDefsForConfigValuesPageQuery,
        FetchConfigValueDefsForConfigValuesPageQueryVariables
    >(FetchConfigValueDefsForConfigValuesPageDocument, options);
}
export function useFetchConfigValueDefsForConfigValuesPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchConfigValueDefsForConfigValuesPageQuery,
        FetchConfigValueDefsForConfigValuesPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchConfigValueDefsForConfigValuesPageQuery,
        FetchConfigValueDefsForConfigValuesPageQueryVariables
    >(FetchConfigValueDefsForConfigValuesPageDocument, options);
}
export type FetchConfigValueDefsForConfigValuesPageQueryHookResult = ReturnType<
    typeof useFetchConfigValueDefsForConfigValuesPageQuery
>;
export type FetchConfigValueDefsForConfigValuesPageLazyQueryHookResult = ReturnType<
    typeof useFetchConfigValueDefsForConfigValuesPageLazyQuery
>;
export type FetchConfigValueDefsForConfigValuesPageQueryResult = Apollo.QueryResult<
    FetchConfigValueDefsForConfigValuesPageQuery,
    FetchConfigValueDefsForConfigValuesPageQueryVariables
>;
export const FetchConfigValueDefaultsByIdForValueDefsModalDocument = gql`
    query FetchConfigValueDefaultsByIdForValueDefsModal($input: GetConfigValueDefInput!) {
        configValueDef(input: $input) {
            ...ConfigValueDefPage
        }
    }
    ${ConfigValueDefPageFragmentDoc}
`;

/**
 * __useFetchConfigValueDefaultsByIdForValueDefsModalQuery__
 *
 * To run a query within a React component, call `useFetchConfigValueDefaultsByIdForValueDefsModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchConfigValueDefaultsByIdForValueDefsModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchConfigValueDefaultsByIdForValueDefsModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchConfigValueDefaultsByIdForValueDefsModalQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchConfigValueDefaultsByIdForValueDefsModalQuery,
        FetchConfigValueDefaultsByIdForValueDefsModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchConfigValueDefaultsByIdForValueDefsModalQuery,
        FetchConfigValueDefaultsByIdForValueDefsModalQueryVariables
    >(FetchConfigValueDefaultsByIdForValueDefsModalDocument, options);
}
export function useFetchConfigValueDefaultsByIdForValueDefsModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchConfigValueDefaultsByIdForValueDefsModalQuery,
        FetchConfigValueDefaultsByIdForValueDefsModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchConfigValueDefaultsByIdForValueDefsModalQuery,
        FetchConfigValueDefaultsByIdForValueDefsModalQueryVariables
    >(FetchConfigValueDefaultsByIdForValueDefsModalDocument, options);
}
export type FetchConfigValueDefaultsByIdForValueDefsModalQueryHookResult = ReturnType<
    typeof useFetchConfigValueDefaultsByIdForValueDefsModalQuery
>;
export type FetchConfigValueDefaultsByIdForValueDefsModalLazyQueryHookResult = ReturnType<
    typeof useFetchConfigValueDefaultsByIdForValueDefsModalLazyQuery
>;
export type FetchConfigValueDefaultsByIdForValueDefsModalQueryResult = Apollo.QueryResult<
    FetchConfigValueDefaultsByIdForValueDefsModalQuery,
    FetchConfigValueDefaultsByIdForValueDefsModalQueryVariables
>;
export const CreateConfigValueDefaultsFromValueDefPageDocument = gql`
    mutation CreateConfigValueDefaultsFromValueDefPage($input: ConfigValueDefInput!) {
        createConfigValueDef(input: $input) {
            success
            message
            status
            resourceCreated {
                ...ConfigValueDefPage
            }
        }
    }
    ${ConfigValueDefPageFragmentDoc}
`;
export type CreateConfigValueDefaultsFromValueDefPageMutationFn = Apollo.MutationFunction<
    CreateConfigValueDefaultsFromValueDefPageMutation,
    CreateConfigValueDefaultsFromValueDefPageMutationVariables
>;

/**
 * __useCreateConfigValueDefaultsFromValueDefPageMutation__
 *
 * To run a mutation, you first call `useCreateConfigValueDefaultsFromValueDefPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConfigValueDefaultsFromValueDefPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConfigValueDefaultsFromValueDefPageMutation, { data, loading, error }] = useCreateConfigValueDefaultsFromValueDefPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConfigValueDefaultsFromValueDefPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateConfigValueDefaultsFromValueDefPageMutation,
        CreateConfigValueDefaultsFromValueDefPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateConfigValueDefaultsFromValueDefPageMutation,
        CreateConfigValueDefaultsFromValueDefPageMutationVariables
    >(CreateConfigValueDefaultsFromValueDefPageDocument, options);
}
export type CreateConfigValueDefaultsFromValueDefPageMutationHookResult = ReturnType<
    typeof useCreateConfigValueDefaultsFromValueDefPageMutation
>;
export type CreateConfigValueDefaultsFromValueDefPageMutationResult =
    Apollo.MutationResult<CreateConfigValueDefaultsFromValueDefPageMutation>;
export type CreateConfigValueDefaultsFromValueDefPageMutationOptions = Apollo.BaseMutationOptions<
    CreateConfigValueDefaultsFromValueDefPageMutation,
    CreateConfigValueDefaultsFromValueDefPageMutationVariables
>;
export const UpdateConfigValueDefaultsFromValueDefPageDocument = gql`
    mutation UpdateConfigValueDefaultsFromValueDefPage($input: UpdateConfigValueDefInput!) {
        updateConfigValueDef(input: $input) {
            success
            message
            status
            resourceUpdated {
                ...ConfigValueDefPage
            }
        }
    }
    ${ConfigValueDefPageFragmentDoc}
`;
export type UpdateConfigValueDefaultsFromValueDefPageMutationFn = Apollo.MutationFunction<
    UpdateConfigValueDefaultsFromValueDefPageMutation,
    UpdateConfigValueDefaultsFromValueDefPageMutationVariables
>;

/**
 * __useUpdateConfigValueDefaultsFromValueDefPageMutation__
 *
 * To run a mutation, you first call `useUpdateConfigValueDefaultsFromValueDefPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConfigValueDefaultsFromValueDefPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConfigValueDefaultsFromValueDefPageMutation, { data, loading, error }] = useUpdateConfigValueDefaultsFromValueDefPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConfigValueDefaultsFromValueDefPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateConfigValueDefaultsFromValueDefPageMutation,
        UpdateConfigValueDefaultsFromValueDefPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateConfigValueDefaultsFromValueDefPageMutation,
        UpdateConfigValueDefaultsFromValueDefPageMutationVariables
    >(UpdateConfigValueDefaultsFromValueDefPageDocument, options);
}
export type UpdateConfigValueDefaultsFromValueDefPageMutationHookResult = ReturnType<
    typeof useUpdateConfigValueDefaultsFromValueDefPageMutation
>;
export type UpdateConfigValueDefaultsFromValueDefPageMutationResult =
    Apollo.MutationResult<UpdateConfigValueDefaultsFromValueDefPageMutation>;
export type UpdateConfigValueDefaultsFromValueDefPageMutationOptions = Apollo.BaseMutationOptions<
    UpdateConfigValueDefaultsFromValueDefPageMutation,
    UpdateConfigValueDefaultsFromValueDefPageMutationVariables
>;
export const FetchConfigValuesForConfigValuesPageDocument = gql`
    query FetchConfigValuesForConfigValuesPage {
        configValues {
            ...ConfigValuePage
        }
    }
    ${ConfigValuePageFragmentDoc}
`;

/**
 * __useFetchConfigValuesForConfigValuesPageQuery__
 *
 * To run a query within a React component, call `useFetchConfigValuesForConfigValuesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchConfigValuesForConfigValuesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchConfigValuesForConfigValuesPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchConfigValuesForConfigValuesPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchConfigValuesForConfigValuesPageQuery,
        FetchConfigValuesForConfigValuesPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchConfigValuesForConfigValuesPageQuery,
        FetchConfigValuesForConfigValuesPageQueryVariables
    >(FetchConfigValuesForConfigValuesPageDocument, options);
}
export function useFetchConfigValuesForConfigValuesPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchConfigValuesForConfigValuesPageQuery,
        FetchConfigValuesForConfigValuesPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchConfigValuesForConfigValuesPageQuery,
        FetchConfigValuesForConfigValuesPageQueryVariables
    >(FetchConfigValuesForConfigValuesPageDocument, options);
}
export type FetchConfigValuesForConfigValuesPageQueryHookResult = ReturnType<
    typeof useFetchConfigValuesForConfigValuesPageQuery
>;
export type FetchConfigValuesForConfigValuesPageLazyQueryHookResult = ReturnType<
    typeof useFetchConfigValuesForConfigValuesPageLazyQuery
>;
export type FetchConfigValuesForConfigValuesPageQueryResult = Apollo.QueryResult<
    FetchConfigValuesForConfigValuesPageQuery,
    FetchConfigValuesForConfigValuesPageQueryVariables
>;
export const ContentDirectoryListDocument = gql`
    query ContentDirectoryList($input: ContentDirectoryListInput!) {
        contentDirectorysV2(input: $input) {
            total
            results {
                ...ContentDirectoryFrag
            }
        }
    }
    ${ContentDirectoryFragFragmentDoc}
`;

/**
 * __useContentDirectoryListQuery__
 *
 * To run a query within a React component, call `useContentDirectoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentDirectoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentDirectoryListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContentDirectoryListQuery(
    baseOptions: Apollo.QueryHookOptions<
        ContentDirectoryListQuery,
        ContentDirectoryListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ContentDirectoryListQuery, ContentDirectoryListQueryVariables>(
        ContentDirectoryListDocument,
        options,
    );
}
export function useContentDirectoryListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ContentDirectoryListQuery,
        ContentDirectoryListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ContentDirectoryListQuery, ContentDirectoryListQueryVariables>(
        ContentDirectoryListDocument,
        options,
    );
}
export type ContentDirectoryListQueryHookResult = ReturnType<typeof useContentDirectoryListQuery>;
export type ContentDirectoryListLazyQueryHookResult = ReturnType<
    typeof useContentDirectoryListLazyQuery
>;
export type ContentDirectoryListQueryResult = Apollo.QueryResult<
    ContentDirectoryListQuery,
    ContentDirectoryListQueryVariables
>;
export const ContentDirectoryByIdDocument = gql`
    query ContentDirectoryById($input: GetContentDirectoryInput!) {
        contentDirectory(input: $input) {
            ...ContentDirectoryFrag
        }
    }
    ${ContentDirectoryFragFragmentDoc}
`;

/**
 * __useContentDirectoryByIdQuery__
 *
 * To run a query within a React component, call `useContentDirectoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentDirectoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentDirectoryByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContentDirectoryByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        ContentDirectoryByIdQuery,
        ContentDirectoryByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ContentDirectoryByIdQuery, ContentDirectoryByIdQueryVariables>(
        ContentDirectoryByIdDocument,
        options,
    );
}
export function useContentDirectoryByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ContentDirectoryByIdQuery,
        ContentDirectoryByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ContentDirectoryByIdQuery, ContentDirectoryByIdQueryVariables>(
        ContentDirectoryByIdDocument,
        options,
    );
}
export type ContentDirectoryByIdQueryHookResult = ReturnType<typeof useContentDirectoryByIdQuery>;
export type ContentDirectoryByIdLazyQueryHookResult = ReturnType<
    typeof useContentDirectoryByIdLazyQuery
>;
export type ContentDirectoryByIdQueryResult = Apollo.QueryResult<
    ContentDirectoryByIdQuery,
    ContentDirectoryByIdQueryVariables
>;
export const CreateContentDirectoryDocument = gql`
    mutation CreateContentDirectory($input: ContentDirectoryInput!) {
        createContentDirectory(input: $input) {
            status
            message
            success
            resourceCreated {
                ...ContentDirectoryFrag
            }
        }
    }
    ${ContentDirectoryFragFragmentDoc}
`;
export type CreateContentDirectoryMutationFn = Apollo.MutationFunction<
    CreateContentDirectoryMutation,
    CreateContentDirectoryMutationVariables
>;

/**
 * __useCreateContentDirectoryMutation__
 *
 * To run a mutation, you first call `useCreateContentDirectoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContentDirectoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContentDirectoryMutation, { data, loading, error }] = useCreateContentDirectoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContentDirectoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateContentDirectoryMutation,
        CreateContentDirectoryMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateContentDirectoryMutation,
        CreateContentDirectoryMutationVariables
    >(CreateContentDirectoryDocument, options);
}
export type CreateContentDirectoryMutationHookResult = ReturnType<
    typeof useCreateContentDirectoryMutation
>;
export type CreateContentDirectoryMutationResult =
    Apollo.MutationResult<CreateContentDirectoryMutation>;
export type CreateContentDirectoryMutationOptions = Apollo.BaseMutationOptions<
    CreateContentDirectoryMutation,
    CreateContentDirectoryMutationVariables
>;
export const UpdateContentDirectoryDocument = gql`
    mutation UpdateContentDirectory($updateContentDirectoryInput: UpdateContentDirectoryInput!) {
        updateContentDirectory(input: $updateContentDirectoryInput) {
            status
            message
            success
            resourceUpdated {
                ...ContentDirectoryFrag
            }
        }
    }
    ${ContentDirectoryFragFragmentDoc}
`;
export type UpdateContentDirectoryMutationFn = Apollo.MutationFunction<
    UpdateContentDirectoryMutation,
    UpdateContentDirectoryMutationVariables
>;

/**
 * __useUpdateContentDirectoryMutation__
 *
 * To run a mutation, you first call `useUpdateContentDirectoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentDirectoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentDirectoryMutation, { data, loading, error }] = useUpdateContentDirectoryMutation({
 *   variables: {
 *      updateContentDirectoryInput: // value for 'updateContentDirectoryInput'
 *   },
 * });
 */
export function useUpdateContentDirectoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateContentDirectoryMutation,
        UpdateContentDirectoryMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateContentDirectoryMutation,
        UpdateContentDirectoryMutationVariables
    >(UpdateContentDirectoryDocument, options);
}
export type UpdateContentDirectoryMutationHookResult = ReturnType<
    typeof useUpdateContentDirectoryMutation
>;
export type UpdateContentDirectoryMutationResult =
    Apollo.MutationResult<UpdateContentDirectoryMutation>;
export type UpdateContentDirectoryMutationOptions = Apollo.BaseMutationOptions<
    UpdateContentDirectoryMutation,
    UpdateContentDirectoryMutationVariables
>;
export const DeleteContentDirectoryDocument = gql`
    mutation DeleteContentDirectory($id: String!) {
        deleteContentDirectory(id: $id) {
            success
            message
            status
            id
        }
    }
`;
export type DeleteContentDirectoryMutationFn = Apollo.MutationFunction<
    DeleteContentDirectoryMutation,
    DeleteContentDirectoryMutationVariables
>;

/**
 * __useDeleteContentDirectoryMutation__
 *
 * To run a mutation, you first call `useDeleteContentDirectoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentDirectoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContentDirectoryMutation, { data, loading, error }] = useDeleteContentDirectoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContentDirectoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteContentDirectoryMutation,
        DeleteContentDirectoryMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteContentDirectoryMutation,
        DeleteContentDirectoryMutationVariables
    >(DeleteContentDirectoryDocument, options);
}
export type DeleteContentDirectoryMutationHookResult = ReturnType<
    typeof useDeleteContentDirectoryMutation
>;
export type DeleteContentDirectoryMutationResult =
    Apollo.MutationResult<DeleteContentDirectoryMutation>;
export type DeleteContentDirectoryMutationOptions = Apollo.BaseMutationOptions<
    DeleteContentDirectoryMutation,
    DeleteContentDirectoryMutationVariables
>;
export const ContentDirectoryWhereUsedDocument = gql`
    query ContentDirectoryWhereUsed($id: String!) {
        contentDirectoryWhereUsed(id: $id) {
            dependencies {
                entityType
                blocksDelete
                total
                entries {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useContentDirectoryWhereUsedQuery__
 *
 * To run a query within a React component, call `useContentDirectoryWhereUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentDirectoryWhereUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentDirectoryWhereUsedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContentDirectoryWhereUsedQuery(
    baseOptions: Apollo.QueryHookOptions<
        ContentDirectoryWhereUsedQuery,
        ContentDirectoryWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ContentDirectoryWhereUsedQuery, ContentDirectoryWhereUsedQueryVariables>(
        ContentDirectoryWhereUsedDocument,
        options,
    );
}
export function useContentDirectoryWhereUsedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ContentDirectoryWhereUsedQuery,
        ContentDirectoryWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ContentDirectoryWhereUsedQuery,
        ContentDirectoryWhereUsedQueryVariables
    >(ContentDirectoryWhereUsedDocument, options);
}
export type ContentDirectoryWhereUsedQueryHookResult = ReturnType<
    typeof useContentDirectoryWhereUsedQuery
>;
export type ContentDirectoryWhereUsedLazyQueryHookResult = ReturnType<
    typeof useContentDirectoryWhereUsedLazyQuery
>;
export type ContentDirectoryWhereUsedQueryResult = Apollo.QueryResult<
    ContentDirectoryWhereUsedQuery,
    ContentDirectoryWhereUsedQueryVariables
>;
export const EligibilityMatchStatsDocument = gql`
    query EligibilityMatchStats {
        eligibilityMatchStats {
            inQueue
            matches
            noMatches
        }
    }
`;

/**
 * __useEligibilityMatchStatsQuery__
 *
 * To run a query within a React component, call `useEligibilityMatchStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEligibilityMatchStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEligibilityMatchStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEligibilityMatchStatsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        EligibilityMatchStatsQuery,
        EligibilityMatchStatsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<EligibilityMatchStatsQuery, EligibilityMatchStatsQueryVariables>(
        EligibilityMatchStatsDocument,
        options,
    );
}
export function useEligibilityMatchStatsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        EligibilityMatchStatsQuery,
        EligibilityMatchStatsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<EligibilityMatchStatsQuery, EligibilityMatchStatsQueryVariables>(
        EligibilityMatchStatsDocument,
        options,
    );
}
export type EligibilityMatchStatsQueryHookResult = ReturnType<typeof useEligibilityMatchStatsQuery>;
export type EligibilityMatchStatsLazyQueryHookResult = ReturnType<
    typeof useEligibilityMatchStatsLazyQuery
>;
export type EligibilityMatchStatsQueryResult = Apollo.QueryResult<
    EligibilityMatchStatsQuery,
    EligibilityMatchStatsQueryVariables
>;
export const EligibilityMatchsV2Document = gql`
    query EligibilityMatchsV2($input: EligibilityMatchQueryInput) {
        eligibilityMatchsV2(input: $input) {
            hasMore
            hasPrevious
            limit
            orderBy
            results {
                appId
                patientId
                patientFirstName
                patientLastName
                patientDOB
                potentialMatchesHash
                toBeReviewed
                hasMatch
                firstNameMatch
                lastNameMatch
                matchDateTime
                matchUserId
                updatedAt
                isTestData
                id
                potentialMatches {
                    firstName
                    lastName
                }
            }
            skip
            total
        }
    }
`;

/**
 * __useEligibilityMatchsV2Query__
 *
 * To run a query within a React component, call `useEligibilityMatchsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useEligibilityMatchsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEligibilityMatchsV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEligibilityMatchsV2Query(
    baseOptions?: Apollo.QueryHookOptions<
        EligibilityMatchsV2Query,
        EligibilityMatchsV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<EligibilityMatchsV2Query, EligibilityMatchsV2QueryVariables>(
        EligibilityMatchsV2Document,
        options,
    );
}
export function useEligibilityMatchsV2LazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        EligibilityMatchsV2Query,
        EligibilityMatchsV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<EligibilityMatchsV2Query, EligibilityMatchsV2QueryVariables>(
        EligibilityMatchsV2Document,
        options,
    );
}
export type EligibilityMatchsV2QueryHookResult = ReturnType<typeof useEligibilityMatchsV2Query>;
export type EligibilityMatchsV2LazyQueryHookResult = ReturnType<
    typeof useEligibilityMatchsV2LazyQuery
>;
export type EligibilityMatchsV2QueryResult = Apollo.QueryResult<
    EligibilityMatchsV2Query,
    EligibilityMatchsV2QueryVariables
>;
export const UpdateEligibilityMatchDocument = gql`
    mutation UpdateEligibilityMatch($input: UpdateEligibilityMatchInput!) {
        updateEligibilityMatch(input: $input) {
            message
            resourceUpdated {
                id
            }
            status
            success
        }
    }
`;
export type UpdateEligibilityMatchMutationFn = Apollo.MutationFunction<
    UpdateEligibilityMatchMutation,
    UpdateEligibilityMatchMutationVariables
>;

/**
 * __useUpdateEligibilityMatchMutation__
 *
 * To run a mutation, you first call `useUpdateEligibilityMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEligibilityMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEligibilityMatchMutation, { data, loading, error }] = useUpdateEligibilityMatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEligibilityMatchMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateEligibilityMatchMutation,
        UpdateEligibilityMatchMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateEligibilityMatchMutation,
        UpdateEligibilityMatchMutationVariables
    >(UpdateEligibilityMatchDocument, options);
}
export type UpdateEligibilityMatchMutationHookResult = ReturnType<
    typeof useUpdateEligibilityMatchMutation
>;
export type UpdateEligibilityMatchMutationResult =
    Apollo.MutationResult<UpdateEligibilityMatchMutation>;
export type UpdateEligibilityMatchMutationOptions = Apollo.BaseMutationOptions<
    UpdateEligibilityMatchMutation,
    UpdateEligibilityMatchMutationVariables
>;
export const EligibilityMatchDocument = gql`
    query EligibilityMatch($input: GetEligibilityMatchInput!) {
        eligibilityMatch(input: $input) {
            appId
            patientId
            patientFirstName
            patientLastName
            patientDOB
            potentialMatches {
                firstName
                lastName
            }
            potentialMatchesHash
            toBeReviewed
            hasMatch
            firstNameMatch
            lastNameMatch
            matchDateTime
            matchUserId
            createdAt
            updatedAt
            isTestData
            id
        }
    }
`;

/**
 * __useEligibilityMatchQuery__
 *
 * To run a query within a React component, call `useEligibilityMatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useEligibilityMatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEligibilityMatchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEligibilityMatchQuery(
    baseOptions: Apollo.QueryHookOptions<EligibilityMatchQuery, EligibilityMatchQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<EligibilityMatchQuery, EligibilityMatchQueryVariables>(
        EligibilityMatchDocument,
        options,
    );
}
export function useEligibilityMatchLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        EligibilityMatchQuery,
        EligibilityMatchQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<EligibilityMatchQuery, EligibilityMatchQueryVariables>(
        EligibilityMatchDocument,
        options,
    );
}
export type EligibilityMatchQueryHookResult = ReturnType<typeof useEligibilityMatchQuery>;
export type EligibilityMatchLazyQueryHookResult = ReturnType<typeof useEligibilityMatchLazyQuery>;
export type EligibilityMatchQueryResult = Apollo.QueryResult<
    EligibilityMatchQuery,
    EligibilityMatchQueryVariables
>;
export const EligibilityMatchQueueMetaDataDocument = gql`
    query EligibilityMatchQueueMetaData($input: EligibilityMatchMetaDataInput) {
        eligibilityMatchQueueMetaData(input: $input) {
            nextMatchId
            remainingCount
        }
    }
`;

/**
 * __useEligibilityMatchQueueMetaDataQuery__
 *
 * To run a query within a React component, call `useEligibilityMatchQueueMetaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEligibilityMatchQueueMetaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEligibilityMatchQueueMetaDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEligibilityMatchQueueMetaDataQuery(
    baseOptions?: Apollo.QueryHookOptions<
        EligibilityMatchQueueMetaDataQuery,
        EligibilityMatchQueueMetaDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        EligibilityMatchQueueMetaDataQuery,
        EligibilityMatchQueueMetaDataQueryVariables
    >(EligibilityMatchQueueMetaDataDocument, options);
}
export function useEligibilityMatchQueueMetaDataLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        EligibilityMatchQueueMetaDataQuery,
        EligibilityMatchQueueMetaDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        EligibilityMatchQueueMetaDataQuery,
        EligibilityMatchQueueMetaDataQueryVariables
    >(EligibilityMatchQueueMetaDataDocument, options);
}
export type EligibilityMatchQueueMetaDataQueryHookResult = ReturnType<
    typeof useEligibilityMatchQueueMetaDataQuery
>;
export type EligibilityMatchQueueMetaDataLazyQueryHookResult = ReturnType<
    typeof useEligibilityMatchQueueMetaDataLazyQuery
>;
export type EligibilityMatchQueueMetaDataQueryResult = Apollo.QueryResult<
    EligibilityMatchQueueMetaDataQuery,
    EligibilityMatchQueueMetaDataQueryVariables
>;
export const EligibilityMatchsV2IdsDocument = gql`
    query EligibilityMatchsV2Ids($input: EligibilityMatchQueryInput) {
        eligibilityMatchsV2(input: $input) {
            hasMore
            hasPrevious
            limit
            orderBy
            results {
                id
            }
            skip
            total
        }
    }
`;

/**
 * __useEligibilityMatchsV2IdsQuery__
 *
 * To run a query within a React component, call `useEligibilityMatchsV2IdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEligibilityMatchsV2IdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEligibilityMatchsV2IdsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEligibilityMatchsV2IdsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        EligibilityMatchsV2IdsQuery,
        EligibilityMatchsV2IdsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<EligibilityMatchsV2IdsQuery, EligibilityMatchsV2IdsQueryVariables>(
        EligibilityMatchsV2IdsDocument,
        options,
    );
}
export function useEligibilityMatchsV2IdsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        EligibilityMatchsV2IdsQuery,
        EligibilityMatchsV2IdsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<EligibilityMatchsV2IdsQuery, EligibilityMatchsV2IdsQueryVariables>(
        EligibilityMatchsV2IdsDocument,
        options,
    );
}
export type EligibilityMatchsV2IdsQueryHookResult = ReturnType<
    typeof useEligibilityMatchsV2IdsQuery
>;
export type EligibilityMatchsV2IdsLazyQueryHookResult = ReturnType<
    typeof useEligibilityMatchsV2IdsLazyQuery
>;
export type EligibilityMatchsV2IdsQueryResult = Apollo.QueryResult<
    EligibilityMatchsV2IdsQuery,
    EligibilityMatchsV2IdsQueryVariables
>;
export const AccessCodesDocument = gql`
    query AccessCodes($input: EligibilityAccessCodeInput!) {
        accessCodes(input: $input) {
            results {
                _id
                birthDate
                firstName
                lastName
                unitName
            }
            limit
            total
            skip
        }
    }
`;

/**
 * __useAccessCodesQuery__
 *
 * To run a query within a React component, call `useAccessCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessCodesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessCodesQuery(
    baseOptions: Apollo.QueryHookOptions<AccessCodesQuery, AccessCodesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AccessCodesQuery, AccessCodesQueryVariables>(
        AccessCodesDocument,
        options,
    );
}
export function useAccessCodesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AccessCodesQuery, AccessCodesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AccessCodesQuery, AccessCodesQueryVariables>(
        AccessCodesDocument,
        options,
    );
}
export type AccessCodesQueryHookResult = ReturnType<typeof useAccessCodesQuery>;
export type AccessCodesLazyQueryHookResult = ReturnType<typeof useAccessCodesLazyQuery>;
export type AccessCodesQueryResult = Apollo.QueryResult<
    AccessCodesQuery,
    AccessCodesQueryVariables
>;
export const UnitNamesDocument = gql`
    query UnitNames {
        unitNames {
            results
        }
    }
`;

/**
 * __useUnitNamesQuery__
 *
 * To run a query within a React component, call `useUnitNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnitNamesQuery(
    baseOptions?: Apollo.QueryHookOptions<UnitNamesQuery, UnitNamesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<UnitNamesQuery, UnitNamesQueryVariables>(UnitNamesDocument, options);
}
export function useUnitNamesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<UnitNamesQuery, UnitNamesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<UnitNamesQuery, UnitNamesQueryVariables>(UnitNamesDocument, options);
}
export type UnitNamesQueryHookResult = ReturnType<typeof useUnitNamesQuery>;
export type UnitNamesLazyQueryHookResult = ReturnType<typeof useUnitNamesLazyQuery>;
export type UnitNamesQueryResult = Apollo.QueryResult<UnitNamesQuery, UnitNamesQueryVariables>;
export const EligibilityCreateAccessCodeDocument = gql`
    mutation EligibilityCreateAccessCode($input: AccessCodeInput!) {
        createAccessCode(input: $input) {
            success
            message
            status
            id
        }
    }
`;
export type EligibilityCreateAccessCodeMutationFn = Apollo.MutationFunction<
    EligibilityCreateAccessCodeMutation,
    EligibilityCreateAccessCodeMutationVariables
>;

/**
 * __useEligibilityCreateAccessCodeMutation__
 *
 * To run a mutation, you first call `useEligibilityCreateAccessCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEligibilityCreateAccessCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eligibilityCreateAccessCodeMutation, { data, loading, error }] = useEligibilityCreateAccessCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEligibilityCreateAccessCodeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        EligibilityCreateAccessCodeMutation,
        EligibilityCreateAccessCodeMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        EligibilityCreateAccessCodeMutation,
        EligibilityCreateAccessCodeMutationVariables
    >(EligibilityCreateAccessCodeDocument, options);
}
export type EligibilityCreateAccessCodeMutationHookResult = ReturnType<
    typeof useEligibilityCreateAccessCodeMutation
>;
export type EligibilityCreateAccessCodeMutationResult =
    Apollo.MutationResult<EligibilityCreateAccessCodeMutation>;
export type EligibilityCreateAccessCodeMutationOptions = Apollo.BaseMutationOptions<
    EligibilityCreateAccessCodeMutation,
    EligibilityCreateAccessCodeMutationVariables
>;
export const EligibilityUpdateAccessCodeDocument = gql`
    mutation EligibilityUpdateAccessCode($input: EligibilityUpdateAccessCodeInput!) {
        updateAccessCode(input: $input) {
            success
            message
            status
            id
        }
    }
`;
export type EligibilityUpdateAccessCodeMutationFn = Apollo.MutationFunction<
    EligibilityUpdateAccessCodeMutation,
    EligibilityUpdateAccessCodeMutationVariables
>;

/**
 * __useEligibilityUpdateAccessCodeMutation__
 *
 * To run a mutation, you first call `useEligibilityUpdateAccessCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEligibilityUpdateAccessCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eligibilityUpdateAccessCodeMutation, { data, loading, error }] = useEligibilityUpdateAccessCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEligibilityUpdateAccessCodeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        EligibilityUpdateAccessCodeMutation,
        EligibilityUpdateAccessCodeMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        EligibilityUpdateAccessCodeMutation,
        EligibilityUpdateAccessCodeMutationVariables
    >(EligibilityUpdateAccessCodeDocument, options);
}
export type EligibilityUpdateAccessCodeMutationHookResult = ReturnType<
    typeof useEligibilityUpdateAccessCodeMutation
>;
export type EligibilityUpdateAccessCodeMutationResult =
    Apollo.MutationResult<EligibilityUpdateAccessCodeMutation>;
export type EligibilityUpdateAccessCodeMutationOptions = Apollo.BaseMutationOptions<
    EligibilityUpdateAccessCodeMutation,
    EligibilityUpdateAccessCodeMutationVariables
>;
export const HighlightsForListPageDocument = gql`
    query HighlightsForListPage($input: HighlightListInput) {
        highlightsV2(input: $input) {
            total
            results {
                ...HighlightFragment
            }
        }
    }
    ${HighlightFragmentFragmentDoc}
`;

/**
 * __useHighlightsForListPageQuery__
 *
 * To run a query within a React component, call `useHighlightsForListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHighlightsForListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHighlightsForListPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHighlightsForListPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        HighlightsForListPageQuery,
        HighlightsForListPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<HighlightsForListPageQuery, HighlightsForListPageQueryVariables>(
        HighlightsForListPageDocument,
        options,
    );
}
export function useHighlightsForListPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        HighlightsForListPageQuery,
        HighlightsForListPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<HighlightsForListPageQuery, HighlightsForListPageQueryVariables>(
        HighlightsForListPageDocument,
        options,
    );
}
export type HighlightsForListPageQueryHookResult = ReturnType<typeof useHighlightsForListPageQuery>;
export type HighlightsForListPageLazyQueryHookResult = ReturnType<
    typeof useHighlightsForListPageLazyQuery
>;
export type HighlightsForListPageQueryResult = Apollo.QueryResult<
    HighlightsForListPageQuery,
    HighlightsForListPageQueryVariables
>;
export const GetHighlightDocument = gql`
    query GetHighlight($input: GetHighlightInput!) {
        highlight(input: $input) {
            ...HighlightFragment
        }
    }
    ${HighlightFragmentFragmentDoc}
`;

/**
 * __useGetHighlightQuery__
 *
 * To run a query within a React component, call `useGetHighlightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHighlightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHighlightQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetHighlightQuery(
    baseOptions: Apollo.QueryHookOptions<GetHighlightQuery, GetHighlightQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetHighlightQuery, GetHighlightQueryVariables>(
        GetHighlightDocument,
        options,
    );
}
export function useGetHighlightLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetHighlightQuery, GetHighlightQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetHighlightQuery, GetHighlightQueryVariables>(
        GetHighlightDocument,
        options,
    );
}
export type GetHighlightQueryHookResult = ReturnType<typeof useGetHighlightQuery>;
export type GetHighlightLazyQueryHookResult = ReturnType<typeof useGetHighlightLazyQuery>;
export type GetHighlightQueryResult = Apollo.QueryResult<
    GetHighlightQuery,
    GetHighlightQueryVariables
>;
export const GetAppointmentTemplateHighlightDocument = gql`
    query GetAppointmentTemplateHighlight {
        appointmentTemplateHighlight {
            ...HighlightFragment
        }
    }
    ${HighlightFragmentFragmentDoc}
`;

/**
 * __useGetAppointmentTemplateHighlightQuery__
 *
 * To run a query within a React component, call `useGetAppointmentTemplateHighlightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentTemplateHighlightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentTemplateHighlightQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppointmentTemplateHighlightQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAppointmentTemplateHighlightQuery,
        GetAppointmentTemplateHighlightQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetAppointmentTemplateHighlightQuery,
        GetAppointmentTemplateHighlightQueryVariables
    >(GetAppointmentTemplateHighlightDocument, options);
}
export function useGetAppointmentTemplateHighlightLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAppointmentTemplateHighlightQuery,
        GetAppointmentTemplateHighlightQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetAppointmentTemplateHighlightQuery,
        GetAppointmentTemplateHighlightQueryVariables
    >(GetAppointmentTemplateHighlightDocument, options);
}
export type GetAppointmentTemplateHighlightQueryHookResult = ReturnType<
    typeof useGetAppointmentTemplateHighlightQuery
>;
export type GetAppointmentTemplateHighlightLazyQueryHookResult = ReturnType<
    typeof useGetAppointmentTemplateHighlightLazyQuery
>;
export type GetAppointmentTemplateHighlightQueryResult = Apollo.QueryResult<
    GetAppointmentTemplateHighlightQuery,
    GetAppointmentTemplateHighlightQueryVariables
>;
export const SaveHighlightDocument = gql`
    mutation SaveHighlight($input: SaveHighlightInput!) {
        saveHighlight(input: $input) {
            status
            message
            success
            resource {
                ...HighlightFragment
            }
        }
    }
    ${HighlightFragmentFragmentDoc}
`;
export type SaveHighlightMutationFn = Apollo.MutationFunction<
    SaveHighlightMutation,
    SaveHighlightMutationVariables
>;

/**
 * __useSaveHighlightMutation__
 *
 * To run a mutation, you first call `useSaveHighlightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveHighlightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveHighlightMutation, { data, loading, error }] = useSaveHighlightMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveHighlightMutation(
    baseOptions?: Apollo.MutationHookOptions<SaveHighlightMutation, SaveHighlightMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SaveHighlightMutation, SaveHighlightMutationVariables>(
        SaveHighlightDocument,
        options,
    );
}
export type SaveHighlightMutationHookResult = ReturnType<typeof useSaveHighlightMutation>;
export type SaveHighlightMutationResult = Apollo.MutationResult<SaveHighlightMutation>;
export type SaveHighlightMutationOptions = Apollo.BaseMutationOptions<
    SaveHighlightMutation,
    SaveHighlightMutationVariables
>;
export const DeleteHighlightDocument = gql`
    mutation DeleteHighlight($input: DeleteHighlightInput!) {
        deleteHighlight(input: $input) {
            status
            message
            success
            id
        }
    }
`;
export type DeleteHighlightMutationFn = Apollo.MutationFunction<
    DeleteHighlightMutation,
    DeleteHighlightMutationVariables
>;

/**
 * __useDeleteHighlightMutation__
 *
 * To run a mutation, you first call `useDeleteHighlightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHighlightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHighlightMutation, { data, loading, error }] = useDeleteHighlightMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteHighlightMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteHighlightMutation,
        DeleteHighlightMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteHighlightMutation, DeleteHighlightMutationVariables>(
        DeleteHighlightDocument,
        options,
    );
}
export type DeleteHighlightMutationHookResult = ReturnType<typeof useDeleteHighlightMutation>;
export type DeleteHighlightMutationResult = Apollo.MutationResult<DeleteHighlightMutation>;
export type DeleteHighlightMutationOptions = Apollo.BaseMutationOptions<
    DeleteHighlightMutation,
    DeleteHighlightMutationVariables
>;
export const AppsTagsTimelinesForHighlightsPageDocument = gql`
    query AppsTagsTimelinesForHighlightsPage {
        whatsNewTimelines {
            id
            type
            label {
                en
            }
        }
        amsApps {
            id
            bundleId
            productConst
        }
        tags {
            id
            name
        }
        surveyDefs {
            id
            name
        }
    }
`;

/**
 * __useAppsTagsTimelinesForHighlightsPageQuery__
 *
 * To run a query within a React component, call `useAppsTagsTimelinesForHighlightsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppsTagsTimelinesForHighlightsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppsTagsTimelinesForHighlightsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppsTagsTimelinesForHighlightsPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        AppsTagsTimelinesForHighlightsPageQuery,
        AppsTagsTimelinesForHighlightsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        AppsTagsTimelinesForHighlightsPageQuery,
        AppsTagsTimelinesForHighlightsPageQueryVariables
    >(AppsTagsTimelinesForHighlightsPageDocument, options);
}
export function useAppsTagsTimelinesForHighlightsPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        AppsTagsTimelinesForHighlightsPageQuery,
        AppsTagsTimelinesForHighlightsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        AppsTagsTimelinesForHighlightsPageQuery,
        AppsTagsTimelinesForHighlightsPageQueryVariables
    >(AppsTagsTimelinesForHighlightsPageDocument, options);
}
export type AppsTagsTimelinesForHighlightsPageQueryHookResult = ReturnType<
    typeof useAppsTagsTimelinesForHighlightsPageQuery
>;
export type AppsTagsTimelinesForHighlightsPageLazyQueryHookResult = ReturnType<
    typeof useAppsTagsTimelinesForHighlightsPageLazyQuery
>;
export type AppsTagsTimelinesForHighlightsPageQueryResult = Apollo.QueryResult<
    AppsTagsTimelinesForHighlightsPageQuery,
    AppsTagsTimelinesForHighlightsPageQueryVariables
>;
export const AllMessageCenterTemplatesDocument = gql`
    query AllMessageCenterTemplates {
        messageCenterTemplates {
            id
            title
            content {
                en
                es
            }
        }
    }
`;

/**
 * __useAllMessageCenterTemplatesQuery__
 *
 * To run a query within a React component, call `useAllMessageCenterTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMessageCenterTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMessageCenterTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllMessageCenterTemplatesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        AllMessageCenterTemplatesQuery,
        AllMessageCenterTemplatesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AllMessageCenterTemplatesQuery, AllMessageCenterTemplatesQueryVariables>(
        AllMessageCenterTemplatesDocument,
        options,
    );
}
export function useAllMessageCenterTemplatesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        AllMessageCenterTemplatesQuery,
        AllMessageCenterTemplatesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        AllMessageCenterTemplatesQuery,
        AllMessageCenterTemplatesQueryVariables
    >(AllMessageCenterTemplatesDocument, options);
}
export type AllMessageCenterTemplatesQueryHookResult = ReturnType<
    typeof useAllMessageCenterTemplatesQuery
>;
export type AllMessageCenterTemplatesLazyQueryHookResult = ReturnType<
    typeof useAllMessageCenterTemplatesLazyQuery
>;
export type AllMessageCenterTemplatesQueryResult = Apollo.QueryResult<
    AllMessageCenterTemplatesQuery,
    AllMessageCenterTemplatesQueryVariables
>;
export const MessageCenterTemplatesDocument = gql`
    query MessageCenterTemplates {
        messageCenterTemplates {
            id
            title
            content {
                en
                es
            }
            createdAt
        }
    }
`;

/**
 * __useMessageCenterTemplatesQuery__
 *
 * To run a query within a React component, call `useMessageCenterTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageCenterTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageCenterTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessageCenterTemplatesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        MessageCenterTemplatesQuery,
        MessageCenterTemplatesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MessageCenterTemplatesQuery, MessageCenterTemplatesQueryVariables>(
        MessageCenterTemplatesDocument,
        options,
    );
}
export function useMessageCenterTemplatesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        MessageCenterTemplatesQuery,
        MessageCenterTemplatesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MessageCenterTemplatesQuery, MessageCenterTemplatesQueryVariables>(
        MessageCenterTemplatesDocument,
        options,
    );
}
export type MessageCenterTemplatesQueryHookResult = ReturnType<
    typeof useMessageCenterTemplatesQuery
>;
export type MessageCenterTemplatesLazyQueryHookResult = ReturnType<
    typeof useMessageCenterTemplatesLazyQuery
>;
export type MessageCenterTemplatesQueryResult = Apollo.QueryResult<
    MessageCenterTemplatesQuery,
    MessageCenterTemplatesQueryVariables
>;
export const MessageCenterTemplateDocument = gql`
    query MessageCenterTemplate($input: GetMessageCenterTemplateInput!) {
        messageCenterTemplate(input: $input) {
            ...MessageCenterTemplateFragment
        }
    }
    ${MessageCenterTemplateFragmentFragmentDoc}
`;

/**
 * __useMessageCenterTemplateQuery__
 *
 * To run a query within a React component, call `useMessageCenterTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageCenterTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageCenterTemplateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMessageCenterTemplateQuery(
    baseOptions: Apollo.QueryHookOptions<
        MessageCenterTemplateQuery,
        MessageCenterTemplateQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MessageCenterTemplateQuery, MessageCenterTemplateQueryVariables>(
        MessageCenterTemplateDocument,
        options,
    );
}
export function useMessageCenterTemplateLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        MessageCenterTemplateQuery,
        MessageCenterTemplateQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MessageCenterTemplateQuery, MessageCenterTemplateQueryVariables>(
        MessageCenterTemplateDocument,
        options,
    );
}
export type MessageCenterTemplateQueryHookResult = ReturnType<typeof useMessageCenterTemplateQuery>;
export type MessageCenterTemplateLazyQueryHookResult = ReturnType<
    typeof useMessageCenterTemplateLazyQuery
>;
export type MessageCenterTemplateQueryResult = Apollo.QueryResult<
    MessageCenterTemplateQuery,
    MessageCenterTemplateQueryVariables
>;
export const CreateMessageCenterTemplateDocument = gql`
    mutation CreateMessageCenterTemplate($input: MessageCenterTemplateInput!) {
        createMessageCenterTemplate(input: $input) {
            success
            message
            resourceCreated {
                ...MessageCenterTemplateFragment
            }
        }
    }
    ${MessageCenterTemplateFragmentFragmentDoc}
`;
export type CreateMessageCenterTemplateMutationFn = Apollo.MutationFunction<
    CreateMessageCenterTemplateMutation,
    CreateMessageCenterTemplateMutationVariables
>;

/**
 * __useCreateMessageCenterTemplateMutation__
 *
 * To run a mutation, you first call `useCreateMessageCenterTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageCenterTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageCenterTemplateMutation, { data, loading, error }] = useCreateMessageCenterTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMessageCenterTemplateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateMessageCenterTemplateMutation,
        CreateMessageCenterTemplateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateMessageCenterTemplateMutation,
        CreateMessageCenterTemplateMutationVariables
    >(CreateMessageCenterTemplateDocument, options);
}
export type CreateMessageCenterTemplateMutationHookResult = ReturnType<
    typeof useCreateMessageCenterTemplateMutation
>;
export type CreateMessageCenterTemplateMutationResult =
    Apollo.MutationResult<CreateMessageCenterTemplateMutation>;
export type CreateMessageCenterTemplateMutationOptions = Apollo.BaseMutationOptions<
    CreateMessageCenterTemplateMutation,
    CreateMessageCenterTemplateMutationVariables
>;
export const UpdateMessageCenterTemplateDocument = gql`
    mutation UpdateMessageCenterTemplate($input: UpdateMessageCenterTemplateInput!) {
        updateMessageCenterTemplate(input: $input) {
            success
            message
            resourceUpdated {
                ...MessageCenterTemplateFragment
            }
        }
    }
    ${MessageCenterTemplateFragmentFragmentDoc}
`;
export type UpdateMessageCenterTemplateMutationFn = Apollo.MutationFunction<
    UpdateMessageCenterTemplateMutation,
    UpdateMessageCenterTemplateMutationVariables
>;

/**
 * __useUpdateMessageCenterTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateMessageCenterTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageCenterTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageCenterTemplateMutation, { data, loading, error }] = useUpdateMessageCenterTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMessageCenterTemplateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateMessageCenterTemplateMutation,
        UpdateMessageCenterTemplateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateMessageCenterTemplateMutation,
        UpdateMessageCenterTemplateMutationVariables
    >(UpdateMessageCenterTemplateDocument, options);
}
export type UpdateMessageCenterTemplateMutationHookResult = ReturnType<
    typeof useUpdateMessageCenterTemplateMutation
>;
export type UpdateMessageCenterTemplateMutationResult =
    Apollo.MutationResult<UpdateMessageCenterTemplateMutation>;
export type UpdateMessageCenterTemplateMutationOptions = Apollo.BaseMutationOptions<
    UpdateMessageCenterTemplateMutation,
    UpdateMessageCenterTemplateMutationVariables
>;
export const DeleteMessageCenterTemplateDocument = gql`
    mutation DeleteMessageCenterTemplate($id: String!) {
        deleteMessageCenterTemplate(id: $id) {
            success
            message
            status
            id
        }
    }
`;
export type DeleteMessageCenterTemplateMutationFn = Apollo.MutationFunction<
    DeleteMessageCenterTemplateMutation,
    DeleteMessageCenterTemplateMutationVariables
>;

/**
 * __useDeleteMessageCenterTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteMessageCenterTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageCenterTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageCenterTemplateMutation, { data, loading, error }] = useDeleteMessageCenterTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessageCenterTemplateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteMessageCenterTemplateMutation,
        DeleteMessageCenterTemplateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteMessageCenterTemplateMutation,
        DeleteMessageCenterTemplateMutationVariables
    >(DeleteMessageCenterTemplateDocument, options);
}
export type DeleteMessageCenterTemplateMutationHookResult = ReturnType<
    typeof useDeleteMessageCenterTemplateMutation
>;
export type DeleteMessageCenterTemplateMutationResult =
    Apollo.MutationResult<DeleteMessageCenterTemplateMutation>;
export type DeleteMessageCenterTemplateMutationOptions = Apollo.BaseMutationOptions<
    DeleteMessageCenterTemplateMutation,
    DeleteMessageCenterTemplateMutationVariables
>;
export const MessageCenterTemplateWhereUsedDocument = gql`
    query MessageCenterTemplateWhereUsed($id: String!) {
        messageCenterTemplateWhereUsed(id: $id) {
            dependencies {
                entityType
                blocksDelete
                total
                entries {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useMessageCenterTemplateWhereUsedQuery__
 *
 * To run a query within a React component, call `useMessageCenterTemplateWhereUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageCenterTemplateWhereUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageCenterTemplateWhereUsedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessageCenterTemplateWhereUsedQuery(
    baseOptions: Apollo.QueryHookOptions<
        MessageCenterTemplateWhereUsedQuery,
        MessageCenterTemplateWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        MessageCenterTemplateWhereUsedQuery,
        MessageCenterTemplateWhereUsedQueryVariables
    >(MessageCenterTemplateWhereUsedDocument, options);
}
export function useMessageCenterTemplateWhereUsedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        MessageCenterTemplateWhereUsedQuery,
        MessageCenterTemplateWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        MessageCenterTemplateWhereUsedQuery,
        MessageCenterTemplateWhereUsedQueryVariables
    >(MessageCenterTemplateWhereUsedDocument, options);
}
export type MessageCenterTemplateWhereUsedQueryHookResult = ReturnType<
    typeof useMessageCenterTemplateWhereUsedQuery
>;
export type MessageCenterTemplateWhereUsedLazyQueryHookResult = ReturnType<
    typeof useMessageCenterTemplateWhereUsedLazyQuery
>;
export type MessageCenterTemplateWhereUsedQueryResult = Apollo.QueryResult<
    MessageCenterTemplateWhereUsedQuery,
    MessageCenterTemplateWhereUsedQueryVariables
>;
export const CreateMessageTemplateDocument = gql`
    mutation CreateMessageTemplate($input: MessageTemplateInput!) {
        createMessageTemplate(input: $input) {
            status
            message
            success
            resourceCreated {
                id
                name
                emailTitle {
                    en
                    es
                }
                emailBody {
                    en
                    es
                }
                substitutions {
                    configValueDefId
                    failIfMissing
                    missingValueText
                    profileVariableDefId
                    substitutionId
                    systemTokenId
                    name
                }
            }
        }
    }
`;
export type CreateMessageTemplateMutationFn = Apollo.MutationFunction<
    CreateMessageTemplateMutation,
    CreateMessageTemplateMutationVariables
>;

/**
 * __useCreateMessageTemplateMutation__
 *
 * To run a mutation, you first call `useCreateMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageTemplateMutation, { data, loading, error }] = useCreateMessageTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMessageTemplateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateMessageTemplateMutation,
        CreateMessageTemplateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateMessageTemplateMutation,
        CreateMessageTemplateMutationVariables
    >(CreateMessageTemplateDocument, options);
}
export type CreateMessageTemplateMutationHookResult = ReturnType<
    typeof useCreateMessageTemplateMutation
>;
export type CreateMessageTemplateMutationResult =
    Apollo.MutationResult<CreateMessageTemplateMutation>;
export type CreateMessageTemplateMutationOptions = Apollo.BaseMutationOptions<
    CreateMessageTemplateMutation,
    CreateMessageTemplateMutationVariables
>;
export const UpdateMessageTemplateDocument = gql`
    mutation UpdateMessageTemplate($input: UpdateMessageTemplateInput!) {
        updateMessageTemplate(input: $input) {
            status
            message
            success
            resourceUpdated {
                id
                name
                emailTitle {
                    en
                    es
                }
                emailBody {
                    en
                    es
                }
                substitutions {
                    configValueDefId
                    failIfMissing
                    missingValueText
                    profileVariableDefId
                    substitutionId
                    systemTokenId
                    name
                }
            }
        }
    }
`;
export type UpdateMessageTemplateMutationFn = Apollo.MutationFunction<
    UpdateMessageTemplateMutation,
    UpdateMessageTemplateMutationVariables
>;

/**
 * __useUpdateMessageTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageTemplateMutation, { data, loading, error }] = useUpdateMessageTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMessageTemplateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateMessageTemplateMutation,
        UpdateMessageTemplateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateMessageTemplateMutation,
        UpdateMessageTemplateMutationVariables
    >(UpdateMessageTemplateDocument, options);
}
export type UpdateMessageTemplateMutationHookResult = ReturnType<
    typeof useUpdateMessageTemplateMutation
>;
export type UpdateMessageTemplateMutationResult =
    Apollo.MutationResult<UpdateMessageTemplateMutation>;
export type UpdateMessageTemplateMutationOptions = Apollo.BaseMutationOptions<
    UpdateMessageTemplateMutation,
    UpdateMessageTemplateMutationVariables
>;
export const MessageTemplatesListDocument = gql`
    query MessageTemplatesList($input: MessageTemplateListInput!) {
        messageTemplatesV2(input: $input) {
            total
            results {
                id
                name
                emailTitle {
                    en
                    es
                }
                emailBody {
                    en
                    es
                }
                textBody {
                    en
                    es
                }
            }
        }
    }
`;

/**
 * __useMessageTemplatesListQuery__
 *
 * To run a query within a React component, call `useMessageTemplatesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageTemplatesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageTemplatesListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMessageTemplatesListQuery(
    baseOptions: Apollo.QueryHookOptions<
        MessageTemplatesListQuery,
        MessageTemplatesListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MessageTemplatesListQuery, MessageTemplatesListQueryVariables>(
        MessageTemplatesListDocument,
        options,
    );
}
export function useMessageTemplatesListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        MessageTemplatesListQuery,
        MessageTemplatesListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MessageTemplatesListQuery, MessageTemplatesListQueryVariables>(
        MessageTemplatesListDocument,
        options,
    );
}
export type MessageTemplatesListQueryHookResult = ReturnType<typeof useMessageTemplatesListQuery>;
export type MessageTemplatesListLazyQueryHookResult = ReturnType<
    typeof useMessageTemplatesListLazyQuery
>;
export type MessageTemplatesListQueryResult = Apollo.QueryResult<
    MessageTemplatesListQuery,
    MessageTemplatesListQueryVariables
>;
export const TemplateByIdDocument = gql`
    query TemplateById($input: GetMessageTemplateInput!) {
        messageTemplate(input: $input) {
            id
            name
            emailTitle {
                en
                es
            }
            emailBody {
                en
                es
            }
            textBody {
                en
                es
            }
            substitutions {
                configValueDefId
                failIfMissing
                missingValueText
                profileVariableDefId
                substitutionId
                systemTokenId
                name
            }
        }
    }
`;

/**
 * __useTemplateByIdQuery__
 *
 * To run a query within a React component, call `useTemplateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTemplateByIdQuery(
    baseOptions: Apollo.QueryHookOptions<TemplateByIdQuery, TemplateByIdQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<TemplateByIdQuery, TemplateByIdQueryVariables>(
        TemplateByIdDocument,
        options,
    );
}
export function useTemplateByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<TemplateByIdQuery, TemplateByIdQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<TemplateByIdQuery, TemplateByIdQueryVariables>(
        TemplateByIdDocument,
        options,
    );
}
export type TemplateByIdQueryHookResult = ReturnType<typeof useTemplateByIdQuery>;
export type TemplateByIdLazyQueryHookResult = ReturnType<typeof useTemplateByIdLazyQuery>;
export type TemplateByIdQueryResult = Apollo.QueryResult<
    TemplateByIdQuery,
    TemplateByIdQueryVariables
>;
export const FetchSubmittingClaimsDocument = gql`
    query FetchSubmittingClaims($input: SubmittedClaimQueryInput!) {
        submittedClaimsV2(input: $input) {
            results {
                PatLName
                PatFName
                ClaimRef
                createdAt
                ServiceFrom_1
                status
                errorText
                uploaded
                updatedAt
            }
            total
            skip
            limit
            hasPrevious
            hasMore
        }
    }
`;

/**
 * __useFetchSubmittingClaimsQuery__
 *
 * To run a query within a React component, call `useFetchSubmittingClaimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSubmittingClaimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSubmittingClaimsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchSubmittingClaimsQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchSubmittingClaimsQuery,
        FetchSubmittingClaimsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchSubmittingClaimsQuery, FetchSubmittingClaimsQueryVariables>(
        FetchSubmittingClaimsDocument,
        options,
    );
}
export function useFetchSubmittingClaimsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchSubmittingClaimsQuery,
        FetchSubmittingClaimsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchSubmittingClaimsQuery, FetchSubmittingClaimsQueryVariables>(
        FetchSubmittingClaimsDocument,
        options,
    );
}
export type FetchSubmittingClaimsQueryHookResult = ReturnType<typeof useFetchSubmittingClaimsQuery>;
export type FetchSubmittingClaimsLazyQueryHookResult = ReturnType<
    typeof useFetchSubmittingClaimsLazyQuery
>;
export type FetchSubmittingClaimsQueryResult = Apollo.QueryResult<
    FetchSubmittingClaimsQuery,
    FetchSubmittingClaimsQueryVariables
>;
export const CreateClaimsViaFileDocument = gql`
    mutation CreateClaimsViaFile($input: CreateManySubmittedClaimsViaFileInput!) {
        createManySubmittedClaimsViaFile(input: $input) {
            success
            status
            resourcesCreated {
                success
                resourceCreated {
                    createdAt
                    ClaimRef
                    PatFName
                    PatLName
                    ServiceFrom_1
                }
            }
            message
        }
    }
`;
export type CreateClaimsViaFileMutationFn = Apollo.MutationFunction<
    CreateClaimsViaFileMutation,
    CreateClaimsViaFileMutationVariables
>;

/**
 * __useCreateClaimsViaFileMutation__
 *
 * To run a mutation, you first call `useCreateClaimsViaFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClaimsViaFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClaimsViaFileMutation, { data, loading, error }] = useCreateClaimsViaFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClaimsViaFileMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateClaimsViaFileMutation,
        CreateClaimsViaFileMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateClaimsViaFileMutation, CreateClaimsViaFileMutationVariables>(
        CreateClaimsViaFileDocument,
        options,
    );
}
export type CreateClaimsViaFileMutationHookResult = ReturnType<
    typeof useCreateClaimsViaFileMutation
>;
export type CreateClaimsViaFileMutationResult = Apollo.MutationResult<CreateClaimsViaFileMutation>;
export type CreateClaimsViaFileMutationOptions = Apollo.BaseMutationOptions<
    CreateClaimsViaFileMutation,
    CreateClaimsViaFileMutationVariables
>;
export const FetchSubmittedClaimDocument = gql`
    query FetchSubmittedClaim($input: GetSubmittedClaimInput!) {
        submittedClaim(input: $input) {
            PatAddr
            PatBirthdate
            PatCity
            PatFName
            PatLName
            PatSex
            PatState
            PatZip
            SbrAddr
            SbrBirthdate
            SbrCity
            SbrFName
            SbrID
            SbrLName
            SbrSex
            SbrState
            SbrZip
            status
            errorText
        }
    }
`;

/**
 * __useFetchSubmittedClaimQuery__
 *
 * To run a query within a React component, call `useFetchSubmittedClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSubmittedClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSubmittedClaimQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchSubmittedClaimQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchSubmittedClaimQuery,
        FetchSubmittedClaimQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchSubmittedClaimQuery, FetchSubmittedClaimQueryVariables>(
        FetchSubmittedClaimDocument,
        options,
    );
}
export function useFetchSubmittedClaimLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchSubmittedClaimQuery,
        FetchSubmittedClaimQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchSubmittedClaimQuery, FetchSubmittedClaimQueryVariables>(
        FetchSubmittedClaimDocument,
        options,
    );
}
export type FetchSubmittedClaimQueryHookResult = ReturnType<typeof useFetchSubmittedClaimQuery>;
export type FetchSubmittedClaimLazyQueryHookResult = ReturnType<
    typeof useFetchSubmittedClaimLazyQuery
>;
export type FetchSubmittedClaimQueryResult = Apollo.QueryResult<
    FetchSubmittedClaimQuery,
    FetchSubmittedClaimQueryVariables
>;
export const ForceClaimUploadDocument = gql`
    mutation ForceClaimUpload($input: ForceClaimUploadInput!) {
        forceClaimUpload(input: $input) {
            message
            status
            success
        }
    }
`;
export type ForceClaimUploadMutationFn = Apollo.MutationFunction<
    ForceClaimUploadMutation,
    ForceClaimUploadMutationVariables
>;

/**
 * __useForceClaimUploadMutation__
 *
 * To run a mutation, you first call `useForceClaimUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceClaimUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceClaimUploadMutation, { data, loading, error }] = useForceClaimUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForceClaimUploadMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ForceClaimUploadMutation,
        ForceClaimUploadMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ForceClaimUploadMutation, ForceClaimUploadMutationVariables>(
        ForceClaimUploadDocument,
        options,
    );
}
export type ForceClaimUploadMutationHookResult = ReturnType<typeof useForceClaimUploadMutation>;
export type ForceClaimUploadMutationResult = Apollo.MutationResult<ForceClaimUploadMutation>;
export type ForceClaimUploadMutationOptions = Apollo.BaseMutationOptions<
    ForceClaimUploadMutation,
    ForceClaimUploadMutationVariables
>;
export const FetchPracticeResourceConfigForPracticeDocument = gql`
    query FetchPracticeResourceConfigForPractice($input: GetOrganizationInput!) {
        organization(input: $input) {
            ...OrgConfigForOrg
        }
    }
    ${OrgConfigForOrgFragmentDoc}
`;

/**
 * __useFetchPracticeResourceConfigForPracticeQuery__
 *
 * To run a query within a React component, call `useFetchPracticeResourceConfigForPracticeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPracticeResourceConfigForPracticeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPracticeResourceConfigForPracticeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPracticeResourceConfigForPracticeQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPracticeResourceConfigForPracticeQuery,
        FetchPracticeResourceConfigForPracticeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPracticeResourceConfigForPracticeQuery,
        FetchPracticeResourceConfigForPracticeQueryVariables
    >(FetchPracticeResourceConfigForPracticeDocument, options);
}
export function useFetchPracticeResourceConfigForPracticeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPracticeResourceConfigForPracticeQuery,
        FetchPracticeResourceConfigForPracticeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPracticeResourceConfigForPracticeQuery,
        FetchPracticeResourceConfigForPracticeQueryVariables
    >(FetchPracticeResourceConfigForPracticeDocument, options);
}
export type FetchPracticeResourceConfigForPracticeQueryHookResult = ReturnType<
    typeof useFetchPracticeResourceConfigForPracticeQuery
>;
export type FetchPracticeResourceConfigForPracticeLazyQueryHookResult = ReturnType<
    typeof useFetchPracticeResourceConfigForPracticeLazyQuery
>;
export type FetchPracticeResourceConfigForPracticeQueryResult = Apollo.QueryResult<
    FetchPracticeResourceConfigForPracticeQuery,
    FetchPracticeResourceConfigForPracticeQueryVariables
>;
export const UpdatePracticeResourceConfigForPracticeDocument = gql`
    mutation UpdatePracticeResourceConfigForPractice($input: UpdateOrganizationInput!) {
        updateOrganization(input: $input) {
            success
            message
            resourceUpdated {
                ...OrgConfigForOrg
            }
        }
    }
    ${OrgConfigForOrgFragmentDoc}
`;
export type UpdatePracticeResourceConfigForPracticeMutationFn = Apollo.MutationFunction<
    UpdatePracticeResourceConfigForPracticeMutation,
    UpdatePracticeResourceConfigForPracticeMutationVariables
>;

/**
 * __useUpdatePracticeResourceConfigForPracticeMutation__
 *
 * To run a mutation, you first call `useUpdatePracticeResourceConfigForPracticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePracticeResourceConfigForPracticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePracticeResourceConfigForPracticeMutation, { data, loading, error }] = useUpdatePracticeResourceConfigForPracticeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePracticeResourceConfigForPracticeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePracticeResourceConfigForPracticeMutation,
        UpdatePracticeResourceConfigForPracticeMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdatePracticeResourceConfigForPracticeMutation,
        UpdatePracticeResourceConfigForPracticeMutationVariables
    >(UpdatePracticeResourceConfigForPracticeDocument, options);
}
export type UpdatePracticeResourceConfigForPracticeMutationHookResult = ReturnType<
    typeof useUpdatePracticeResourceConfigForPracticeMutation
>;
export type UpdatePracticeResourceConfigForPracticeMutationResult =
    Apollo.MutationResult<UpdatePracticeResourceConfigForPracticeMutation>;
export type UpdatePracticeResourceConfigForPracticeMutationOptions = Apollo.BaseMutationOptions<
    UpdatePracticeResourceConfigForPracticeMutation,
    UpdatePracticeResourceConfigForPracticeMutationVariables
>;
export const FetchPracticeConfigSummaryForPracticeDocument = gql`
    query FetchPracticeConfigSummaryForPractice($input: GetOrganizationInput!) {
        organization(input: $input) {
            ...OrgConfigForOrg
        }
    }
    ${OrgConfigForOrgFragmentDoc}
`;

/**
 * __useFetchPracticeConfigSummaryForPracticeQuery__
 *
 * To run a query within a React component, call `useFetchPracticeConfigSummaryForPracticeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPracticeConfigSummaryForPracticeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPracticeConfigSummaryForPracticeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPracticeConfigSummaryForPracticeQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPracticeConfigSummaryForPracticeQuery,
        FetchPracticeConfigSummaryForPracticeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPracticeConfigSummaryForPracticeQuery,
        FetchPracticeConfigSummaryForPracticeQueryVariables
    >(FetchPracticeConfigSummaryForPracticeDocument, options);
}
export function useFetchPracticeConfigSummaryForPracticeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPracticeConfigSummaryForPracticeQuery,
        FetchPracticeConfigSummaryForPracticeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPracticeConfigSummaryForPracticeQuery,
        FetchPracticeConfigSummaryForPracticeQueryVariables
    >(FetchPracticeConfigSummaryForPracticeDocument, options);
}
export type FetchPracticeConfigSummaryForPracticeQueryHookResult = ReturnType<
    typeof useFetchPracticeConfigSummaryForPracticeQuery
>;
export type FetchPracticeConfigSummaryForPracticeLazyQueryHookResult = ReturnType<
    typeof useFetchPracticeConfigSummaryForPracticeLazyQuery
>;
export type FetchPracticeConfigSummaryForPracticeQueryResult = Apollo.QueryResult<
    FetchPracticeConfigSummaryForPracticeQuery,
    FetchPracticeConfigSummaryForPracticeQueryVariables
>;
export const UpdatePracticeConfigOptionsForPracticeDocument = gql`
    mutation UpdatePracticeConfigOptionsForPractice($input: UpdateOrganizationInput!) {
        updateOrganization(input: $input) {
            success
            message
            resourceUpdated {
                ...OrgForOrgPage
            }
        }
    }
    ${OrgForOrgPageFragmentDoc}
`;
export type UpdatePracticeConfigOptionsForPracticeMutationFn = Apollo.MutationFunction<
    UpdatePracticeConfigOptionsForPracticeMutation,
    UpdatePracticeConfigOptionsForPracticeMutationVariables
>;

/**
 * __useUpdatePracticeConfigOptionsForPracticeMutation__
 *
 * To run a mutation, you first call `useUpdatePracticeConfigOptionsForPracticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePracticeConfigOptionsForPracticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePracticeConfigOptionsForPracticeMutation, { data, loading, error }] = useUpdatePracticeConfigOptionsForPracticeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePracticeConfigOptionsForPracticeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePracticeConfigOptionsForPracticeMutation,
        UpdatePracticeConfigOptionsForPracticeMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdatePracticeConfigOptionsForPracticeMutation,
        UpdatePracticeConfigOptionsForPracticeMutationVariables
    >(UpdatePracticeConfigOptionsForPracticeDocument, options);
}
export type UpdatePracticeConfigOptionsForPracticeMutationHookResult = ReturnType<
    typeof useUpdatePracticeConfigOptionsForPracticeMutation
>;
export type UpdatePracticeConfigOptionsForPracticeMutationResult =
    Apollo.MutationResult<UpdatePracticeConfigOptionsForPracticeMutation>;
export type UpdatePracticeConfigOptionsForPracticeMutationOptions = Apollo.BaseMutationOptions<
    UpdatePracticeConfigOptionsForPracticeMutation,
    UpdatePracticeConfigOptionsForPracticeMutationVariables
>;
export const AddInviteCodeOnOrgPageDocument = gql`
    mutation AddInviteCodeOnOrgPage($input: AddOrganizationInviteCodeInput!) {
        addOrganizationInviteCode(input: $input) {
            success
            message
            status
            organization {
                ...OrgForOrgPage
            }
        }
    }
    ${OrgForOrgPageFragmentDoc}
`;
export type AddInviteCodeOnOrgPageMutationFn = Apollo.MutationFunction<
    AddInviteCodeOnOrgPageMutation,
    AddInviteCodeOnOrgPageMutationVariables
>;

/**
 * __useAddInviteCodeOnOrgPageMutation__
 *
 * To run a mutation, you first call `useAddInviteCodeOnOrgPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInviteCodeOnOrgPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInviteCodeOnOrgPageMutation, { data, loading, error }] = useAddInviteCodeOnOrgPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddInviteCodeOnOrgPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AddInviteCodeOnOrgPageMutation,
        AddInviteCodeOnOrgPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        AddInviteCodeOnOrgPageMutation,
        AddInviteCodeOnOrgPageMutationVariables
    >(AddInviteCodeOnOrgPageDocument, options);
}
export type AddInviteCodeOnOrgPageMutationHookResult = ReturnType<
    typeof useAddInviteCodeOnOrgPageMutation
>;
export type AddInviteCodeOnOrgPageMutationResult =
    Apollo.MutationResult<AddInviteCodeOnOrgPageMutation>;
export type AddInviteCodeOnOrgPageMutationOptions = Apollo.BaseMutationOptions<
    AddInviteCodeOnOrgPageMutation,
    AddInviteCodeOnOrgPageMutationVariables
>;
export const DeleteInviteCodeOnOrgPageDocument = gql`
    mutation DeleteInviteCodeOnOrgPage($input: DeleteOrganizationInviteCodeInput!) {
        deleteOrganizationInviteCode(input: $input) {
            success
            message
            status
            organization {
                ...OrgForOrgPage
            }
        }
    }
    ${OrgForOrgPageFragmentDoc}
`;
export type DeleteInviteCodeOnOrgPageMutationFn = Apollo.MutationFunction<
    DeleteInviteCodeOnOrgPageMutation,
    DeleteInviteCodeOnOrgPageMutationVariables
>;

/**
 * __useDeleteInviteCodeOnOrgPageMutation__
 *
 * To run a mutation, you first call `useDeleteInviteCodeOnOrgPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInviteCodeOnOrgPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInviteCodeOnOrgPageMutation, { data, loading, error }] = useDeleteInviteCodeOnOrgPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInviteCodeOnOrgPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteInviteCodeOnOrgPageMutation,
        DeleteInviteCodeOnOrgPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteInviteCodeOnOrgPageMutation,
        DeleteInviteCodeOnOrgPageMutationVariables
    >(DeleteInviteCodeOnOrgPageDocument, options);
}
export type DeleteInviteCodeOnOrgPageMutationHookResult = ReturnType<
    typeof useDeleteInviteCodeOnOrgPageMutation
>;
export type DeleteInviteCodeOnOrgPageMutationResult =
    Apollo.MutationResult<DeleteInviteCodeOnOrgPageMutation>;
export type DeleteInviteCodeOnOrgPageMutationOptions = Apollo.BaseMutationOptions<
    DeleteInviteCodeOnOrgPageMutation,
    DeleteInviteCodeOnOrgPageMutationVariables
>;
export const FetchOrgDetailsDocument = gql`
    query FetchOrgDetails($input: GetOrganizationInput!) {
        organization(input: $input) {
            ...OrgForOrgPage
        }
    }
    ${OrgForOrgPageFragmentDoc}
`;

/**
 * __useFetchOrgDetailsQuery__
 *
 * To run a query within a React component, call `useFetchOrgDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOrgDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOrgDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchOrgDetailsQuery(
    baseOptions: Apollo.QueryHookOptions<FetchOrgDetailsQuery, FetchOrgDetailsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchOrgDetailsQuery, FetchOrgDetailsQueryVariables>(
        FetchOrgDetailsDocument,
        options,
    );
}
export function useFetchOrgDetailsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<FetchOrgDetailsQuery, FetchOrgDetailsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchOrgDetailsQuery, FetchOrgDetailsQueryVariables>(
        FetchOrgDetailsDocument,
        options,
    );
}
export type FetchOrgDetailsQueryHookResult = ReturnType<typeof useFetchOrgDetailsQuery>;
export type FetchOrgDetailsLazyQueryHookResult = ReturnType<typeof useFetchOrgDetailsLazyQuery>;
export type FetchOrgDetailsQueryResult = Apollo.QueryResult<
    FetchOrgDetailsQuery,
    FetchOrgDetailsQueryVariables
>;
export const FetchPatientHeaderDocument = gql`
    query FetchPatientHeader($input: GetPatientHeaderInput!) {
        patientHeader(input: $input) {
            updatedAt
            profileDefs {
                disabled
                label
                profileDefId
                sortField
                isParentField
            }
            organizationId
            isTestData
            id
            createdAt
        }
    }
`;

/**
 * __useFetchPatientHeaderQuery__
 *
 * To run a query within a React component, call `useFetchPatientHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientHeaderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPatientHeaderQuery(
    baseOptions: Apollo.QueryHookOptions<FetchPatientHeaderQuery, FetchPatientHeaderQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchPatientHeaderQuery, FetchPatientHeaderQueryVariables>(
        FetchPatientHeaderDocument,
        options,
    );
}
export function useFetchPatientHeaderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientHeaderQuery,
        FetchPatientHeaderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchPatientHeaderQuery, FetchPatientHeaderQueryVariables>(
        FetchPatientHeaderDocument,
        options,
    );
}
export type FetchPatientHeaderQueryHookResult = ReturnType<typeof useFetchPatientHeaderQuery>;
export type FetchPatientHeaderLazyQueryHookResult = ReturnType<
    typeof useFetchPatientHeaderLazyQuery
>;
export type FetchPatientHeaderQueryResult = Apollo.QueryResult<
    FetchPatientHeaderQuery,
    FetchPatientHeaderQueryVariables
>;
export const CreatePatientHeaderDocument = gql`
    mutation CreatePatientHeader($input: PatientHeaderInput!) {
        createPatientHeader(input: $input) {
            success
            status
            resourceCreated {
                createdAt
                id
                isTestData
                organizationId
                profileDefs {
                    sortField
                    profileDefId
                    label
                    disabled
                    isParentField
                }
                updatedAt
            }
        }
    }
`;
export type CreatePatientHeaderMutationFn = Apollo.MutationFunction<
    CreatePatientHeaderMutation,
    CreatePatientHeaderMutationVariables
>;

/**
 * __useCreatePatientHeaderMutation__
 *
 * To run a mutation, you first call `useCreatePatientHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientHeaderMutation, { data, loading, error }] = useCreatePatientHeaderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatientHeaderMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreatePatientHeaderMutation,
        CreatePatientHeaderMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreatePatientHeaderMutation, CreatePatientHeaderMutationVariables>(
        CreatePatientHeaderDocument,
        options,
    );
}
export type CreatePatientHeaderMutationHookResult = ReturnType<
    typeof useCreatePatientHeaderMutation
>;
export type CreatePatientHeaderMutationResult = Apollo.MutationResult<CreatePatientHeaderMutation>;
export type CreatePatientHeaderMutationOptions = Apollo.BaseMutationOptions<
    CreatePatientHeaderMutation,
    CreatePatientHeaderMutationVariables
>;
export const UpdatePatientHeaderDocument = gql`
    mutation UpdatePatientHeader($input: UpdatePatientHeaderInput!) {
        updatePatientHeader(input: $input) {
            success
            status
            message
            resourceUpdated {
                createdAt
                id
                isTestData
                organizationId
                profileDefs {
                    sortField
                    profileDefId
                    label
                    disabled
                    isParentField
                }
                updatedAt
            }
        }
    }
`;
export type UpdatePatientHeaderMutationFn = Apollo.MutationFunction<
    UpdatePatientHeaderMutation,
    UpdatePatientHeaderMutationVariables
>;

/**
 * __useUpdatePatientHeaderMutation__
 *
 * To run a mutation, you first call `useUpdatePatientHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientHeaderMutation, { data, loading, error }] = useUpdatePatientHeaderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientHeaderMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePatientHeaderMutation,
        UpdatePatientHeaderMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdatePatientHeaderMutation, UpdatePatientHeaderMutationVariables>(
        UpdatePatientHeaderDocument,
        options,
    );
}
export type UpdatePatientHeaderMutationHookResult = ReturnType<
    typeof useUpdatePatientHeaderMutation
>;
export type UpdatePatientHeaderMutationResult = Apollo.MutationResult<UpdatePatientHeaderMutation>;
export type UpdatePatientHeaderMutationOptions = Apollo.BaseMutationOptions<
    UpdatePatientHeaderMutation,
    UpdatePatientHeaderMutationVariables
>;
export const DeletePatientHeaderDocument = gql`
    mutation deletePatientHeader($input: DeletePatientHeaderInput!) {
        deletePatientHeader(input: $input) {
            success
            message
            status
            id
        }
    }
`;
export type DeletePatientHeaderMutationFn = Apollo.MutationFunction<
    DeletePatientHeaderMutation,
    DeletePatientHeaderMutationVariables
>;

/**
 * __useDeletePatientHeaderMutation__
 *
 * To run a mutation, you first call `useDeletePatientHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientHeaderMutation, { data, loading, error }] = useDeletePatientHeaderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePatientHeaderMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeletePatientHeaderMutation,
        DeletePatientHeaderMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeletePatientHeaderMutation, DeletePatientHeaderMutationVariables>(
        DeletePatientHeaderDocument,
        options,
    );
}
export type DeletePatientHeaderMutationHookResult = ReturnType<
    typeof useDeletePatientHeaderMutation
>;
export type DeletePatientHeaderMutationResult = Apollo.MutationResult<DeletePatientHeaderMutation>;
export type DeletePatientHeaderMutationOptions = Apollo.BaseMutationOptions<
    DeletePatientHeaderMutation,
    DeletePatientHeaderMutationVariables
>;
export const FetchProfileDefsForProfileHeaderPageDocument = gql`
    query FetchProfileDefsForProfileHeaderPage {
        userProfileDefs {
            ...UserProfileDefFragment
        }
    }
    ${UserProfileDefFragmentFragmentDoc}
`;

/**
 * __useFetchProfileDefsForProfileHeaderPageQuery__
 *
 * To run a query within a React component, call `useFetchProfileDefsForProfileHeaderPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProfileDefsForProfileHeaderPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProfileDefsForProfileHeaderPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchProfileDefsForProfileHeaderPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchProfileDefsForProfileHeaderPageQuery,
        FetchProfileDefsForProfileHeaderPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchProfileDefsForProfileHeaderPageQuery,
        FetchProfileDefsForProfileHeaderPageQueryVariables
    >(FetchProfileDefsForProfileHeaderPageDocument, options);
}
export function useFetchProfileDefsForProfileHeaderPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchProfileDefsForProfileHeaderPageQuery,
        FetchProfileDefsForProfileHeaderPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchProfileDefsForProfileHeaderPageQuery,
        FetchProfileDefsForProfileHeaderPageQueryVariables
    >(FetchProfileDefsForProfileHeaderPageDocument, options);
}
export type FetchProfileDefsForProfileHeaderPageQueryHookResult = ReturnType<
    typeof useFetchProfileDefsForProfileHeaderPageQuery
>;
export type FetchProfileDefsForProfileHeaderPageLazyQueryHookResult = ReturnType<
    typeof useFetchProfileDefsForProfileHeaderPageLazyQuery
>;
export type FetchProfileDefsForProfileHeaderPageQueryResult = Apollo.QueryResult<
    FetchProfileDefsForProfileHeaderPageQuery,
    FetchProfileDefsForProfileHeaderPageQueryVariables
>;
export const FetchReportingJobsForReportingJobsPageDocument = gql`
    query FetchReportingJobsForReportingJobsPage($input: ReportingJobQueryInput) {
        reportingJobsV2(input: $input) {
            results {
                reportName
                status
                startedByName
                s3key
                createdAt
                updatedAt
                isTestData
                id
            }
        }
    }
`;

/**
 * __useFetchReportingJobsForReportingJobsPageQuery__
 *
 * To run a query within a React component, call `useFetchReportingJobsForReportingJobsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchReportingJobsForReportingJobsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchReportingJobsForReportingJobsPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchReportingJobsForReportingJobsPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchReportingJobsForReportingJobsPageQuery,
        FetchReportingJobsForReportingJobsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchReportingJobsForReportingJobsPageQuery,
        FetchReportingJobsForReportingJobsPageQueryVariables
    >(FetchReportingJobsForReportingJobsPageDocument, options);
}
export function useFetchReportingJobsForReportingJobsPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchReportingJobsForReportingJobsPageQuery,
        FetchReportingJobsForReportingJobsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchReportingJobsForReportingJobsPageQuery,
        FetchReportingJobsForReportingJobsPageQueryVariables
    >(FetchReportingJobsForReportingJobsPageDocument, options);
}
export type FetchReportingJobsForReportingJobsPageQueryHookResult = ReturnType<
    typeof useFetchReportingJobsForReportingJobsPageQuery
>;
export type FetchReportingJobsForReportingJobsPageLazyQueryHookResult = ReturnType<
    typeof useFetchReportingJobsForReportingJobsPageLazyQuery
>;
export type FetchReportingJobsForReportingJobsPageQueryResult = Apollo.QueryResult<
    FetchReportingJobsForReportingJobsPageQuery,
    FetchReportingJobsForReportingJobsPageQueryVariables
>;
export const FetchOrganizationReportByKeyForReportingJobsPageDocument = gql`
    query FetchOrganizationReportByKeyForReportingJobsPage($input: S3GetObjectInput!) {
        getOrganizationReportByKey(input: $input) {
            data
            filename
        }
    }
`;

/**
 * __useFetchOrganizationReportByKeyForReportingJobsPageQuery__
 *
 * To run a query within a React component, call `useFetchOrganizationReportByKeyForReportingJobsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOrganizationReportByKeyForReportingJobsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOrganizationReportByKeyForReportingJobsPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchOrganizationReportByKeyForReportingJobsPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchOrganizationReportByKeyForReportingJobsPageQuery,
        FetchOrganizationReportByKeyForReportingJobsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchOrganizationReportByKeyForReportingJobsPageQuery,
        FetchOrganizationReportByKeyForReportingJobsPageQueryVariables
    >(FetchOrganizationReportByKeyForReportingJobsPageDocument, options);
}
export function useFetchOrganizationReportByKeyForReportingJobsPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchOrganizationReportByKeyForReportingJobsPageQuery,
        FetchOrganizationReportByKeyForReportingJobsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchOrganizationReportByKeyForReportingJobsPageQuery,
        FetchOrganizationReportByKeyForReportingJobsPageQueryVariables
    >(FetchOrganizationReportByKeyForReportingJobsPageDocument, options);
}
export type FetchOrganizationReportByKeyForReportingJobsPageQueryHookResult = ReturnType<
    typeof useFetchOrganizationReportByKeyForReportingJobsPageQuery
>;
export type FetchOrganizationReportByKeyForReportingJobsPageLazyQueryHookResult = ReturnType<
    typeof useFetchOrganizationReportByKeyForReportingJobsPageLazyQuery
>;
export type FetchOrganizationReportByKeyForReportingJobsPageQueryResult = Apollo.QueryResult<
    FetchOrganizationReportByKeyForReportingJobsPageQuery,
    FetchOrganizationReportByKeyForReportingJobsPageQueryVariables
>;
export const FetchPatientsForOrganizationReportingDocument = gql`
    query FetchPatientsForOrganizationReporting($input: GetPatientsByFilterInput!) {
        currentUser {
            id
            name
            email
            organizations {
                id
                parentId
                brandingName
            }
            currentOrg {
                id
                parentId
                brandingName
                allowPatientCreation
                appPlatform
                appBundleId
            }
            patientsV2(input: $input) {
                orderBy
                skip
                limit
                total
                hasMore
                hasPrevious
                results {
                    ...PatientForPatientsPage
                    tagIds
                    surveyResponses {
                        ...SurveyResponseForSurveyPage
                    }
                }
            }
        }
    }
    ${PatientForPatientsPageFragmentDoc}
    ${SurveyResponseForSurveyPageFragmentDoc}
`;

/**
 * __useFetchPatientsForOrganizationReportingQuery__
 *
 * To run a query within a React component, call `useFetchPatientsForOrganizationReportingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientsForOrganizationReportingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientsForOrganizationReportingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPatientsForOrganizationReportingQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPatientsForOrganizationReportingQuery,
        FetchPatientsForOrganizationReportingQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientsForOrganizationReportingQuery,
        FetchPatientsForOrganizationReportingQueryVariables
    >(FetchPatientsForOrganizationReportingDocument, options);
}
export function useFetchPatientsForOrganizationReportingLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientsForOrganizationReportingQuery,
        FetchPatientsForOrganizationReportingQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientsForOrganizationReportingQuery,
        FetchPatientsForOrganizationReportingQueryVariables
    >(FetchPatientsForOrganizationReportingDocument, options);
}
export type FetchPatientsForOrganizationReportingQueryHookResult = ReturnType<
    typeof useFetchPatientsForOrganizationReportingQuery
>;
export type FetchPatientsForOrganizationReportingLazyQueryHookResult = ReturnType<
    typeof useFetchPatientsForOrganizationReportingLazyQuery
>;
export type FetchPatientsForOrganizationReportingQueryResult = Apollo.QueryResult<
    FetchPatientsForOrganizationReportingQuery,
    FetchPatientsForOrganizationReportingQueryVariables
>;
export const FetchProfileVariablesForOrganizationReportingQueryDocument = gql`
    query FetchProfileVariablesForOrganizationReportingQuery(
        $patientId: String
        $profileDefsIds: [String]
    ) {
        profileVariablesByPVDefIds(patientId: $patientId, profileDefsIds: $profileDefsIds) {
            profileVariableDefId
            patientId
            value {
                str
                num
                bool
                date
                strs
                nums
                bools
                dates
                choice
                choices
                specified {
                    choiceId
                    type
                    val {
                        str
                        num
                        bool
                        date
                    }
                }
                localized {
                    en
                    es
                }
            }
        }
    }
`;

/**
 * __useFetchProfileVariablesForOrganizationReportingQueryQuery__
 *
 * To run a query within a React component, call `useFetchProfileVariablesForOrganizationReportingQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProfileVariablesForOrganizationReportingQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProfileVariablesForOrganizationReportingQueryQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      profileDefsIds: // value for 'profileDefsIds'
 *   },
 * });
 */
export function useFetchProfileVariablesForOrganizationReportingQueryQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchProfileVariablesForOrganizationReportingQueryQuery,
        FetchProfileVariablesForOrganizationReportingQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchProfileVariablesForOrganizationReportingQueryQuery,
        FetchProfileVariablesForOrganizationReportingQueryQueryVariables
    >(FetchProfileVariablesForOrganizationReportingQueryDocument, options);
}
export function useFetchProfileVariablesForOrganizationReportingQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchProfileVariablesForOrganizationReportingQueryQuery,
        FetchProfileVariablesForOrganizationReportingQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchProfileVariablesForOrganizationReportingQueryQuery,
        FetchProfileVariablesForOrganizationReportingQueryQueryVariables
    >(FetchProfileVariablesForOrganizationReportingQueryDocument, options);
}
export type FetchProfileVariablesForOrganizationReportingQueryQueryHookResult = ReturnType<
    typeof useFetchProfileVariablesForOrganizationReportingQueryQuery
>;
export type FetchProfileVariablesForOrganizationReportingQueryLazyQueryHookResult = ReturnType<
    typeof useFetchProfileVariablesForOrganizationReportingQueryLazyQuery
>;
export type FetchProfileVariablesForOrganizationReportingQueryQueryResult = Apollo.QueryResult<
    FetchProfileVariablesForOrganizationReportingQueryQuery,
    FetchProfileVariablesForOrganizationReportingQueryQueryVariables
>;
export const FetchIntakeSurveysDocument = gql`
    query FetchIntakeSurveys($input: GetConfigValueInput!) {
        configValue(input: $input) {
            value {
                strArr
            }
        }
    }
`;

/**
 * __useFetchIntakeSurveysQuery__
 *
 * To run a query within a React component, call `useFetchIntakeSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchIntakeSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchIntakeSurveysQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchIntakeSurveysQuery(
    baseOptions: Apollo.QueryHookOptions<FetchIntakeSurveysQuery, FetchIntakeSurveysQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchIntakeSurveysQuery, FetchIntakeSurveysQueryVariables>(
        FetchIntakeSurveysDocument,
        options,
    );
}
export function useFetchIntakeSurveysLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchIntakeSurveysQuery,
        FetchIntakeSurveysQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchIntakeSurveysQuery, FetchIntakeSurveysQueryVariables>(
        FetchIntakeSurveysDocument,
        options,
    );
}
export type FetchIntakeSurveysQueryHookResult = ReturnType<typeof useFetchIntakeSurveysQuery>;
export type FetchIntakeSurveysLazyQueryHookResult = ReturnType<
    typeof useFetchIntakeSurveysLazyQuery
>;
export type FetchIntakeSurveysQueryResult = Apollo.QueryResult<
    FetchIntakeSurveysQuery,
    FetchIntakeSurveysQueryVariables
>;
export const FetchCareTeamMembersForOrganizationReportingCsvPageDocument = gql`
    query FetchCareTeamMembersForOrganizationReportingCsvPage($input: CareTeamMemberQueryInput) {
        careTeamMembersV2(input: $input) {
            results {
                id
            }
        }
    }
`;

/**
 * __useFetchCareTeamMembersForOrganizationReportingCsvPageQuery__
 *
 * To run a query within a React component, call `useFetchCareTeamMembersForOrganizationReportingCsvPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCareTeamMembersForOrganizationReportingCsvPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCareTeamMembersForOrganizationReportingCsvPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchCareTeamMembersForOrganizationReportingCsvPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchCareTeamMembersForOrganizationReportingCsvPageQuery,
        FetchCareTeamMembersForOrganizationReportingCsvPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchCareTeamMembersForOrganizationReportingCsvPageQuery,
        FetchCareTeamMembersForOrganizationReportingCsvPageQueryVariables
    >(FetchCareTeamMembersForOrganizationReportingCsvPageDocument, options);
}
export function useFetchCareTeamMembersForOrganizationReportingCsvPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchCareTeamMembersForOrganizationReportingCsvPageQuery,
        FetchCareTeamMembersForOrganizationReportingCsvPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchCareTeamMembersForOrganizationReportingCsvPageQuery,
        FetchCareTeamMembersForOrganizationReportingCsvPageQueryVariables
    >(FetchCareTeamMembersForOrganizationReportingCsvPageDocument, options);
}
export type FetchCareTeamMembersForOrganizationReportingCsvPageQueryHookResult = ReturnType<
    typeof useFetchCareTeamMembersForOrganizationReportingCsvPageQuery
>;
export type FetchCareTeamMembersForOrganizationReportingCsvPageLazyQueryHookResult = ReturnType<
    typeof useFetchCareTeamMembersForOrganizationReportingCsvPageLazyQuery
>;
export type FetchCareTeamMembersForOrganizationReportingCsvPageQueryResult = Apollo.QueryResult<
    FetchCareTeamMembersForOrganizationReportingCsvPageQuery,
    FetchCareTeamMembersForOrganizationReportingCsvPageQueryVariables
>;
export const CreateOrUpdateOneUserProfileVariableForOrganizationPageDocument = gql`
    mutation CreateOrUpdateOneUserProfileVariableForOrganizationPage(
        $input: PatientProfileVariableInput!
    ) {
        updatePatientProfileVariable(input: $input) {
            profileVariableDefId
        }
    }
`;
export type CreateOrUpdateOneUserProfileVariableForOrganizationPageMutationFn =
    Apollo.MutationFunction<
        CreateOrUpdateOneUserProfileVariableForOrganizationPageMutation,
        CreateOrUpdateOneUserProfileVariableForOrganizationPageMutationVariables
    >;

/**
 * __useCreateOrUpdateOneUserProfileVariableForOrganizationPageMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateOneUserProfileVariableForOrganizationPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateOneUserProfileVariableForOrganizationPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateOneUserProfileVariableForOrganizationPageMutation, { data, loading, error }] = useCreateOrUpdateOneUserProfileVariableForOrganizationPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateOneUserProfileVariableForOrganizationPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateOrUpdateOneUserProfileVariableForOrganizationPageMutation,
        CreateOrUpdateOneUserProfileVariableForOrganizationPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateOrUpdateOneUserProfileVariableForOrganizationPageMutation,
        CreateOrUpdateOneUserProfileVariableForOrganizationPageMutationVariables
    >(CreateOrUpdateOneUserProfileVariableForOrganizationPageDocument, options);
}
export type CreateOrUpdateOneUserProfileVariableForOrganizationPageMutationHookResult = ReturnType<
    typeof useCreateOrUpdateOneUserProfileVariableForOrganizationPageMutation
>;
export type CreateOrUpdateOneUserProfileVariableForOrganizationPageMutationResult =
    Apollo.MutationResult<CreateOrUpdateOneUserProfileVariableForOrganizationPageMutation>;
export type CreateOrUpdateOneUserProfileVariableForOrganizationPageMutationOptions =
    Apollo.BaseMutationOptions<
        CreateOrUpdateOneUserProfileVariableForOrganizationPageMutation,
        CreateOrUpdateOneUserProfileVariableForOrganizationPageMutationVariables
    >;
export const GenerateReportByReportTypeDocument = gql`
    query GenerateReportByReportType($input: ReportDataInput!) {
        generateReportByReportType(input: $input) {
            success
            message
            status
        }
    }
`;

/**
 * __useGenerateReportByReportTypeQuery__
 *
 * To run a query within a React component, call `useGenerateReportByReportTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateReportByReportTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateReportByReportTypeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateReportByReportTypeQuery(
    baseOptions: Apollo.QueryHookOptions<
        GenerateReportByReportTypeQuery,
        GenerateReportByReportTypeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GenerateReportByReportTypeQuery,
        GenerateReportByReportTypeQueryVariables
    >(GenerateReportByReportTypeDocument, options);
}
export function useGenerateReportByReportTypeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GenerateReportByReportTypeQuery,
        GenerateReportByReportTypeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GenerateReportByReportTypeQuery,
        GenerateReportByReportTypeQueryVariables
    >(GenerateReportByReportTypeDocument, options);
}
export type GenerateReportByReportTypeQueryHookResult = ReturnType<
    typeof useGenerateReportByReportTypeQuery
>;
export type GenerateReportByReportTypeLazyQueryHookResult = ReturnType<
    typeof useGenerateReportByReportTypeLazyQuery
>;
export type GenerateReportByReportTypeQueryResult = Apollo.QueryResult<
    GenerateReportByReportTypeQuery,
    GenerateReportByReportTypeQueryVariables
>;
export const OrganizationVcEligibilityEligibilityResultsDocument = gql`
    query OrganizationVCEligibilityEligibilityResults($input: GetEligibilityResultInput) {
        eligibilityResults(input: $input) {
            total
            results {
                id
                firstName
                lastName
                passed
                createdAt
            }
        }
    }
`;

/**
 * __useOrganizationVcEligibilityEligibilityResultsQuery__
 *
 * To run a query within a React component, call `useOrganizationVcEligibilityEligibilityResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationVcEligibilityEligibilityResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationVcEligibilityEligibilityResultsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationVcEligibilityEligibilityResultsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        OrganizationVcEligibilityEligibilityResultsQuery,
        OrganizationVcEligibilityEligibilityResultsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        OrganizationVcEligibilityEligibilityResultsQuery,
        OrganizationVcEligibilityEligibilityResultsQueryVariables
    >(OrganizationVcEligibilityEligibilityResultsDocument, options);
}
export function useOrganizationVcEligibilityEligibilityResultsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        OrganizationVcEligibilityEligibilityResultsQuery,
        OrganizationVcEligibilityEligibilityResultsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        OrganizationVcEligibilityEligibilityResultsQuery,
        OrganizationVcEligibilityEligibilityResultsQueryVariables
    >(OrganizationVcEligibilityEligibilityResultsDocument, options);
}
export type OrganizationVcEligibilityEligibilityResultsQueryHookResult = ReturnType<
    typeof useOrganizationVcEligibilityEligibilityResultsQuery
>;
export type OrganizationVcEligibilityEligibilityResultsLazyQueryHookResult = ReturnType<
    typeof useOrganizationVcEligibilityEligibilityResultsLazyQuery
>;
export type OrganizationVcEligibilityEligibilityResultsQueryResult = Apollo.QueryResult<
    OrganizationVcEligibilityEligibilityResultsQuery,
    OrganizationVcEligibilityEligibilityResultsQueryVariables
>;
export const OrganizationVcEligibilityEligibilityResultsByIdDocument = gql`
    query OrganizationVCEligibilityEligibilityResultsById($input: GetEligibilityResultInput) {
        eligibilityResults(input: $input) {
            total
            results {
                id
                firstName
                lastName
                passed
                createdAt
                email
                error
                phoneNumber
                response
                data
            }
        }
    }
`;

/**
 * __useOrganizationVcEligibilityEligibilityResultsByIdQuery__
 *
 * To run a query within a React component, call `useOrganizationVcEligibilityEligibilityResultsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationVcEligibilityEligibilityResultsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationVcEligibilityEligibilityResultsByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationVcEligibilityEligibilityResultsByIdQuery(
    baseOptions?: Apollo.QueryHookOptions<
        OrganizationVcEligibilityEligibilityResultsByIdQuery,
        OrganizationVcEligibilityEligibilityResultsByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        OrganizationVcEligibilityEligibilityResultsByIdQuery,
        OrganizationVcEligibilityEligibilityResultsByIdQueryVariables
    >(OrganizationVcEligibilityEligibilityResultsByIdDocument, options);
}
export function useOrganizationVcEligibilityEligibilityResultsByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        OrganizationVcEligibilityEligibilityResultsByIdQuery,
        OrganizationVcEligibilityEligibilityResultsByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        OrganizationVcEligibilityEligibilityResultsByIdQuery,
        OrganizationVcEligibilityEligibilityResultsByIdQueryVariables
    >(OrganizationVcEligibilityEligibilityResultsByIdDocument, options);
}
export type OrganizationVcEligibilityEligibilityResultsByIdQueryHookResult = ReturnType<
    typeof useOrganizationVcEligibilityEligibilityResultsByIdQuery
>;
export type OrganizationVcEligibilityEligibilityResultsByIdLazyQueryHookResult = ReturnType<
    typeof useOrganizationVcEligibilityEligibilityResultsByIdLazyQuery
>;
export type OrganizationVcEligibilityEligibilityResultsByIdQueryResult = Apollo.QueryResult<
    OrganizationVcEligibilityEligibilityResultsByIdQuery,
    OrganizationVcEligibilityEligibilityResultsByIdQueryVariables
>;
export const OrganizationVcfResultsDocument = gql`
    query OrganizationVCFResults($input: VirtualCareFormQueryInput) {
        virtualCareFormsV2(input: $input) {
            hasMore
            hasPrevious
            results {
                Destination
                id
                firstName
                lastName
                appointmentMethod
                createdAt
                phoneNumber
                insuranceId
            }
            total
        }
    }
`;

/**
 * __useOrganizationVcfResultsQuery__
 *
 * To run a query within a React component, call `useOrganizationVcfResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationVcfResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationVcfResultsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationVcfResultsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        OrganizationVcfResultsQuery,
        OrganizationVcfResultsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<OrganizationVcfResultsQuery, OrganizationVcfResultsQueryVariables>(
        OrganizationVcfResultsDocument,
        options,
    );
}
export function useOrganizationVcfResultsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        OrganizationVcfResultsQuery,
        OrganizationVcfResultsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<OrganizationVcfResultsQuery, OrganizationVcfResultsQueryVariables>(
        OrganizationVcfResultsDocument,
        options,
    );
}
export type OrganizationVcfResultsQueryHookResult = ReturnType<
    typeof useOrganizationVcfResultsQuery
>;
export type OrganizationVcfResultsLazyQueryHookResult = ReturnType<
    typeof useOrganizationVcfResultsLazyQuery
>;
export type OrganizationVcfResultsQueryResult = Apollo.QueryResult<
    OrganizationVcfResultsQuery,
    OrganizationVcfResultsQueryVariables
>;
export const OrganizationVirtualCareFormDocument = gql`
    query OrganizationVirtualCareForm($input: GetVirtualCareFormInput!) {
        virtualCareForm(input: $input) {
            Destination
            appointmentMethod
            birthDate
            createdAt
            email
            firstName
            id
            lastName
            patientId
            phoneNumber
        }
    }
`;

/**
 * __useOrganizationVirtualCareFormQuery__
 *
 * To run a query within a React component, call `useOrganizationVirtualCareFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationVirtualCareFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationVirtualCareFormQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationVirtualCareFormQuery(
    baseOptions: Apollo.QueryHookOptions<
        OrganizationVirtualCareFormQuery,
        OrganizationVirtualCareFormQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        OrganizationVirtualCareFormQuery,
        OrganizationVirtualCareFormQueryVariables
    >(OrganizationVirtualCareFormDocument, options);
}
export function useOrganizationVirtualCareFormLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        OrganizationVirtualCareFormQuery,
        OrganizationVirtualCareFormQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        OrganizationVirtualCareFormQuery,
        OrganizationVirtualCareFormQueryVariables
    >(OrganizationVirtualCareFormDocument, options);
}
export type OrganizationVirtualCareFormQueryHookResult = ReturnType<
    typeof useOrganizationVirtualCareFormQuery
>;
export type OrganizationVirtualCareFormLazyQueryHookResult = ReturnType<
    typeof useOrganizationVirtualCareFormLazyQuery
>;
export type OrganizationVirtualCareFormQueryResult = Apollo.QueryResult<
    OrganizationVirtualCareFormQuery,
    OrganizationVirtualCareFormQueryVariables
>;
export const FetchOrgDetailsForModalDocument = gql`
    query FetchOrgDetailsForModal($input: GetOrganizationInput!) {
        organization(input: $input) {
            ...OrgForOrgPage
        }
    }
    ${OrgForOrgPageFragmentDoc}
`;

/**
 * __useFetchOrgDetailsForModalQuery__
 *
 * To run a query within a React component, call `useFetchOrgDetailsForModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOrgDetailsForModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOrgDetailsForModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchOrgDetailsForModalQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchOrgDetailsForModalQuery,
        FetchOrgDetailsForModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchOrgDetailsForModalQuery, FetchOrgDetailsForModalQueryVariables>(
        FetchOrgDetailsForModalDocument,
        options,
    );
}
export function useFetchOrgDetailsForModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchOrgDetailsForModalQuery,
        FetchOrgDetailsForModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchOrgDetailsForModalQuery, FetchOrgDetailsForModalQueryVariables>(
        FetchOrgDetailsForModalDocument,
        options,
    );
}
export type FetchOrgDetailsForModalQueryHookResult = ReturnType<
    typeof useFetchOrgDetailsForModalQuery
>;
export type FetchOrgDetailsForModalLazyQueryHookResult = ReturnType<
    typeof useFetchOrgDetailsForModalLazyQuery
>;
export type FetchOrgDetailsForModalQueryResult = Apollo.QueryResult<
    FetchOrgDetailsForModalQuery,
    FetchOrgDetailsForModalQueryVariables
>;
export const FetchDevicesHealthPlansProvidersForOrgModalDocument = gql`
    query FetchDevicesHealthPlansProvidersForOrgModal {
        healthPlans {
            id
            brandingName
            name
        }
        devices {
            localizedName
            id
        }
        providers {
            id
            localizedName
        }
    }
`;

/**
 * __useFetchDevicesHealthPlansProvidersForOrgModalQuery__
 *
 * To run a query within a React component, call `useFetchDevicesHealthPlansProvidersForOrgModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDevicesHealthPlansProvidersForOrgModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDevicesHealthPlansProvidersForOrgModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchDevicesHealthPlansProvidersForOrgModalQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchDevicesHealthPlansProvidersForOrgModalQuery,
        FetchDevicesHealthPlansProvidersForOrgModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchDevicesHealthPlansProvidersForOrgModalQuery,
        FetchDevicesHealthPlansProvidersForOrgModalQueryVariables
    >(FetchDevicesHealthPlansProvidersForOrgModalDocument, options);
}
export function useFetchDevicesHealthPlansProvidersForOrgModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchDevicesHealthPlansProvidersForOrgModalQuery,
        FetchDevicesHealthPlansProvidersForOrgModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchDevicesHealthPlansProvidersForOrgModalQuery,
        FetchDevicesHealthPlansProvidersForOrgModalQueryVariables
    >(FetchDevicesHealthPlansProvidersForOrgModalDocument, options);
}
export type FetchDevicesHealthPlansProvidersForOrgModalQueryHookResult = ReturnType<
    typeof useFetchDevicesHealthPlansProvidersForOrgModalQuery
>;
export type FetchDevicesHealthPlansProvidersForOrgModalLazyQueryHookResult = ReturnType<
    typeof useFetchDevicesHealthPlansProvidersForOrgModalLazyQuery
>;
export type FetchDevicesHealthPlansProvidersForOrgModalQueryResult = Apollo.QueryResult<
    FetchDevicesHealthPlansProvidersForOrgModalQuery,
    FetchDevicesHealthPlansProvidersForOrgModalQueryVariables
>;
export const UpdateOrgForModalDocument = gql`
    mutation UpdateOrgForModal($input: UpdateOrganizationInput!) {
        updateOrganization(input: $input) {
            message
            success
            resourceUpdated {
                ...OrgForOrgPage
            }
        }
    }
    ${OrgForOrgPageFragmentDoc}
`;
export type UpdateOrgForModalMutationFn = Apollo.MutationFunction<
    UpdateOrgForModalMutation,
    UpdateOrgForModalMutationVariables
>;

/**
 * __useUpdateOrgForModalMutation__
 *
 * To run a mutation, you first call `useUpdateOrgForModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgForModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgForModalMutation, { data, loading, error }] = useUpdateOrgForModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgForModalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateOrgForModalMutation,
        UpdateOrgForModalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateOrgForModalMutation, UpdateOrgForModalMutationVariables>(
        UpdateOrgForModalDocument,
        options,
    );
}
export type UpdateOrgForModalMutationHookResult = ReturnType<typeof useUpdateOrgForModalMutation>;
export type UpdateOrgForModalMutationResult = Apollo.MutationResult<UpdateOrgForModalMutation>;
export type UpdateOrgForModalMutationOptions = Apollo.BaseMutationOptions<
    UpdateOrgForModalMutation,
    UpdateOrgForModalMutationVariables
>;
export const CreateOrgForModalDocument = gql`
    mutation CreateOrgForModal($input: OrganizationInput!) {
        createOrganization(input: $input) {
            message
            success
            resourceCreated {
                ...OrgForOrgPage
            }
        }
    }
    ${OrgForOrgPageFragmentDoc}
`;
export type CreateOrgForModalMutationFn = Apollo.MutationFunction<
    CreateOrgForModalMutation,
    CreateOrgForModalMutationVariables
>;

/**
 * __useCreateOrgForModalMutation__
 *
 * To run a mutation, you first call `useCreateOrgForModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgForModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgForModalMutation, { data, loading, error }] = useCreateOrgForModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrgForModalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateOrgForModalMutation,
        CreateOrgForModalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateOrgForModalMutation, CreateOrgForModalMutationVariables>(
        CreateOrgForModalDocument,
        options,
    );
}
export type CreateOrgForModalMutationHookResult = ReturnType<typeof useCreateOrgForModalMutation>;
export type CreateOrgForModalMutationResult = Apollo.MutationResult<CreateOrgForModalMutation>;
export type CreateOrgForModalMutationOptions = Apollo.BaseMutationOptions<
    CreateOrgForModalMutation,
    CreateOrgForModalMutationVariables
>;
export const FetchOrganizationsFromCurrentUserDocument = gql`
    query FetchOrganizationsFromCurrentUser {
        currentUser {
            organizations {
                id
                parentId
                brandingName
                logo
                ancestors
                appBundleId
                unreadMessagesCount
            }
            currentOrg {
                id
            }
        }
    }
`;

/**
 * __useFetchOrganizationsFromCurrentUserQuery__
 *
 * To run a query within a React component, call `useFetchOrganizationsFromCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOrganizationsFromCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOrganizationsFromCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchOrganizationsFromCurrentUserQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchOrganizationsFromCurrentUserQuery,
        FetchOrganizationsFromCurrentUserQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchOrganizationsFromCurrentUserQuery,
        FetchOrganizationsFromCurrentUserQueryVariables
    >(FetchOrganizationsFromCurrentUserDocument, options);
}
export function useFetchOrganizationsFromCurrentUserLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchOrganizationsFromCurrentUserQuery,
        FetchOrganizationsFromCurrentUserQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchOrganizationsFromCurrentUserQuery,
        FetchOrganizationsFromCurrentUserQueryVariables
    >(FetchOrganizationsFromCurrentUserDocument, options);
}
export type FetchOrganizationsFromCurrentUserQueryHookResult = ReturnType<
    typeof useFetchOrganizationsFromCurrentUserQuery
>;
export type FetchOrganizationsFromCurrentUserLazyQueryHookResult = ReturnType<
    typeof useFetchOrganizationsFromCurrentUserLazyQuery
>;
export type FetchOrganizationsFromCurrentUserQueryResult = Apollo.QueryResult<
    FetchOrganizationsFromCurrentUserQuery,
    FetchOrganizationsFromCurrentUserQueryVariables
>;
export const PatientActionTypesForConfigPortalDocument = gql`
    query PatientActionTypesForConfigPortal {
        patientActionTypesV2 {
            hasMore
            hasPrevious
            limit
            orderBy
            skip
            total
            results {
                id
                label
                updatedAt
                createdAt
                isTestData
            }
        }
    }
`;

/**
 * __usePatientActionTypesForConfigPortalQuery__
 *
 * To run a query within a React component, call `usePatientActionTypesForConfigPortalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientActionTypesForConfigPortalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientActionTypesForConfigPortalQuery({
 *   variables: {
 *   },
 * });
 */
export function usePatientActionTypesForConfigPortalQuery(
    baseOptions?: Apollo.QueryHookOptions<
        PatientActionTypesForConfigPortalQuery,
        PatientActionTypesForConfigPortalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        PatientActionTypesForConfigPortalQuery,
        PatientActionTypesForConfigPortalQueryVariables
    >(PatientActionTypesForConfigPortalDocument, options);
}
export function usePatientActionTypesForConfigPortalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PatientActionTypesForConfigPortalQuery,
        PatientActionTypesForConfigPortalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        PatientActionTypesForConfigPortalQuery,
        PatientActionTypesForConfigPortalQueryVariables
    >(PatientActionTypesForConfigPortalDocument, options);
}
export type PatientActionTypesForConfigPortalQueryHookResult = ReturnType<
    typeof usePatientActionTypesForConfigPortalQuery
>;
export type PatientActionTypesForConfigPortalLazyQueryHookResult = ReturnType<
    typeof usePatientActionTypesForConfigPortalLazyQuery
>;
export type PatientActionTypesForConfigPortalQueryResult = Apollo.QueryResult<
    PatientActionTypesForConfigPortalQuery,
    PatientActionTypesForConfigPortalQueryVariables
>;
export const DeletePatientActionTypeDocument = gql`
    mutation DeletePatientActionType($deletePatientActionTypeId: String) {
        deletePatientActionType(id: $deletePatientActionTypeId) {
            id
            message
            status
            success
        }
    }
`;
export type DeletePatientActionTypeMutationFn = Apollo.MutationFunction<
    DeletePatientActionTypeMutation,
    DeletePatientActionTypeMutationVariables
>;

/**
 * __useDeletePatientActionTypeMutation__
 *
 * To run a mutation, you first call `useDeletePatientActionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientActionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientActionTypeMutation, { data, loading, error }] = useDeletePatientActionTypeMutation({
 *   variables: {
 *      deletePatientActionTypeId: // value for 'deletePatientActionTypeId'
 *   },
 * });
 */
export function useDeletePatientActionTypeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeletePatientActionTypeMutation,
        DeletePatientActionTypeMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeletePatientActionTypeMutation,
        DeletePatientActionTypeMutationVariables
    >(DeletePatientActionTypeDocument, options);
}
export type DeletePatientActionTypeMutationHookResult = ReturnType<
    typeof useDeletePatientActionTypeMutation
>;
export type DeletePatientActionTypeMutationResult =
    Apollo.MutationResult<DeletePatientActionTypeMutation>;
export type DeletePatientActionTypeMutationOptions = Apollo.BaseMutationOptions<
    DeletePatientActionTypeMutation,
    DeletePatientActionTypeMutationVariables
>;
export const DeleteAndReplacePatientActionTypeDocument = gql`
    mutation DeleteAndReplacePatientActionType($input: DeleteAndReplacePatientActionTypeInput) {
        deleteAndReplacePatientActionType(input: $input) {
            id
            message
            status
            success
        }
    }
`;
export type DeleteAndReplacePatientActionTypeMutationFn = Apollo.MutationFunction<
    DeleteAndReplacePatientActionTypeMutation,
    DeleteAndReplacePatientActionTypeMutationVariables
>;

/**
 * __useDeleteAndReplacePatientActionTypeMutation__
 *
 * To run a mutation, you first call `useDeleteAndReplacePatientActionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAndReplacePatientActionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAndReplacePatientActionTypeMutation, { data, loading, error }] = useDeleteAndReplacePatientActionTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAndReplacePatientActionTypeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteAndReplacePatientActionTypeMutation,
        DeleteAndReplacePatientActionTypeMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteAndReplacePatientActionTypeMutation,
        DeleteAndReplacePatientActionTypeMutationVariables
    >(DeleteAndReplacePatientActionTypeDocument, options);
}
export type DeleteAndReplacePatientActionTypeMutationHookResult = ReturnType<
    typeof useDeleteAndReplacePatientActionTypeMutation
>;
export type DeleteAndReplacePatientActionTypeMutationResult =
    Apollo.MutationResult<DeleteAndReplacePatientActionTypeMutation>;
export type DeleteAndReplacePatientActionTypeMutationOptions = Apollo.BaseMutationOptions<
    DeleteAndReplacePatientActionTypeMutation,
    DeleteAndReplacePatientActionTypeMutationVariables
>;
export const PatientActionTypeWhereUsedDocument = gql`
    query PatientActionTypeWhereUsed($patientActionTypeWhereUsedId: String) {
        patientActionTypeWhereUsed(id: $patientActionTypeWhereUsedId) {
            dependencies {
                total
                entityType
                blocksDelete
                entries {
                    name
                    id
                }
            }
        }
    }
`;

/**
 * __usePatientActionTypeWhereUsedQuery__
 *
 * To run a query within a React component, call `usePatientActionTypeWhereUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientActionTypeWhereUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientActionTypeWhereUsedQuery({
 *   variables: {
 *      patientActionTypeWhereUsedId: // value for 'patientActionTypeWhereUsedId'
 *   },
 * });
 */
export function usePatientActionTypeWhereUsedQuery(
    baseOptions?: Apollo.QueryHookOptions<
        PatientActionTypeWhereUsedQuery,
        PatientActionTypeWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        PatientActionTypeWhereUsedQuery,
        PatientActionTypeWhereUsedQueryVariables
    >(PatientActionTypeWhereUsedDocument, options);
}
export function usePatientActionTypeWhereUsedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PatientActionTypeWhereUsedQuery,
        PatientActionTypeWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        PatientActionTypeWhereUsedQuery,
        PatientActionTypeWhereUsedQueryVariables
    >(PatientActionTypeWhereUsedDocument, options);
}
export type PatientActionTypeWhereUsedQueryHookResult = ReturnType<
    typeof usePatientActionTypeWhereUsedQuery
>;
export type PatientActionTypeWhereUsedLazyQueryHookResult = ReturnType<
    typeof usePatientActionTypeWhereUsedLazyQuery
>;
export type PatientActionTypeWhereUsedQueryResult = Apollo.QueryResult<
    PatientActionTypeWhereUsedQuery,
    PatientActionTypeWhereUsedQueryVariables
>;
export const CreatePatientActionTypeForConfigPortalDocument = gql`
    mutation CreatePatientActionTypeForConfigPortal($input: PatientActionTypeInput!) {
        createPatientActionType(input: $input) {
            success
            message
            resourceCreated {
                ...PatientActionTypeFragment
            }
        }
    }
    ${PatientActionTypeFragmentFragmentDoc}
`;
export type CreatePatientActionTypeForConfigPortalMutationFn = Apollo.MutationFunction<
    CreatePatientActionTypeForConfigPortalMutation,
    CreatePatientActionTypeForConfigPortalMutationVariables
>;

/**
 * __useCreatePatientActionTypeForConfigPortalMutation__
 *
 * To run a mutation, you first call `useCreatePatientActionTypeForConfigPortalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientActionTypeForConfigPortalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientActionTypeForConfigPortalMutation, { data, loading, error }] = useCreatePatientActionTypeForConfigPortalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatientActionTypeForConfigPortalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreatePatientActionTypeForConfigPortalMutation,
        CreatePatientActionTypeForConfigPortalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreatePatientActionTypeForConfigPortalMutation,
        CreatePatientActionTypeForConfigPortalMutationVariables
    >(CreatePatientActionTypeForConfigPortalDocument, options);
}
export type CreatePatientActionTypeForConfigPortalMutationHookResult = ReturnType<
    typeof useCreatePatientActionTypeForConfigPortalMutation
>;
export type CreatePatientActionTypeForConfigPortalMutationResult =
    Apollo.MutationResult<CreatePatientActionTypeForConfigPortalMutation>;
export type CreatePatientActionTypeForConfigPortalMutationOptions = Apollo.BaseMutationOptions<
    CreatePatientActionTypeForConfigPortalMutation,
    CreatePatientActionTypeForConfigPortalMutationVariables
>;
export const UpdatePatientActionTypeForConfigPortalDocument = gql`
    mutation UpdatePatientActionTypeForConfigPortal($input: UpdatePatientActionTypeInput!) {
        updatePatientActionType(input: $input) {
            message
            status
            success
            resourceUpdated {
                ...PatientActionTypeFragment
            }
        }
    }
    ${PatientActionTypeFragmentFragmentDoc}
`;
export type UpdatePatientActionTypeForConfigPortalMutationFn = Apollo.MutationFunction<
    UpdatePatientActionTypeForConfigPortalMutation,
    UpdatePatientActionTypeForConfigPortalMutationVariables
>;

/**
 * __useUpdatePatientActionTypeForConfigPortalMutation__
 *
 * To run a mutation, you first call `useUpdatePatientActionTypeForConfigPortalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientActionTypeForConfigPortalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientActionTypeForConfigPortalMutation, { data, loading, error }] = useUpdatePatientActionTypeForConfigPortalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientActionTypeForConfigPortalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePatientActionTypeForConfigPortalMutation,
        UpdatePatientActionTypeForConfigPortalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdatePatientActionTypeForConfigPortalMutation,
        UpdatePatientActionTypeForConfigPortalMutationVariables
    >(UpdatePatientActionTypeForConfigPortalDocument, options);
}
export type UpdatePatientActionTypeForConfigPortalMutationHookResult = ReturnType<
    typeof useUpdatePatientActionTypeForConfigPortalMutation
>;
export type UpdatePatientActionTypeForConfigPortalMutationResult =
    Apollo.MutationResult<UpdatePatientActionTypeForConfigPortalMutation>;
export type UpdatePatientActionTypeForConfigPortalMutationOptions = Apollo.BaseMutationOptions<
    UpdatePatientActionTypeForConfigPortalMutation,
    UpdatePatientActionTypeForConfigPortalMutationVariables
>;
export const FetchPatientNoteTagDocument = gql`
    query FetchPatientNoteTag($patientNoteTagInput: GetPatientNoteTagInput!) {
        patientNoteTag(input: $patientNoteTagInput) {
            id
            name
        }
    }
`;

/**
 * __useFetchPatientNoteTagQuery__
 *
 * To run a query within a React component, call `useFetchPatientNoteTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientNoteTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientNoteTagQuery({
 *   variables: {
 *      patientNoteTagInput: // value for 'patientNoteTagInput'
 *   },
 * });
 */
export function useFetchPatientNoteTagQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPatientNoteTagQuery,
        FetchPatientNoteTagQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchPatientNoteTagQuery, FetchPatientNoteTagQueryVariables>(
        FetchPatientNoteTagDocument,
        options,
    );
}
export function useFetchPatientNoteTagLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientNoteTagQuery,
        FetchPatientNoteTagQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchPatientNoteTagQuery, FetchPatientNoteTagQueryVariables>(
        FetchPatientNoteTagDocument,
        options,
    );
}
export type FetchPatientNoteTagQueryHookResult = ReturnType<typeof useFetchPatientNoteTagQuery>;
export type FetchPatientNoteTagLazyQueryHookResult = ReturnType<
    typeof useFetchPatientNoteTagLazyQuery
>;
export type FetchPatientNoteTagQueryResult = Apollo.QueryResult<
    FetchPatientNoteTagQuery,
    FetchPatientNoteTagQueryVariables
>;
export const UpdatePatientNoteTagDocument = gql`
    mutation UpdatePatientNoteTag($updatePatientNoteTagInput: UpdatePatientNoteTagInput!) {
        updatePatientNoteTag(input: $updatePatientNoteTagInput) {
            success
            message
            resourceUpdated {
                id
                name
            }
        }
    }
`;
export type UpdatePatientNoteTagMutationFn = Apollo.MutationFunction<
    UpdatePatientNoteTagMutation,
    UpdatePatientNoteTagMutationVariables
>;

/**
 * __useUpdatePatientNoteTagMutation__
 *
 * To run a mutation, you first call `useUpdatePatientNoteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientNoteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientNoteTagMutation, { data, loading, error }] = useUpdatePatientNoteTagMutation({
 *   variables: {
 *      updatePatientNoteTagInput: // value for 'updatePatientNoteTagInput'
 *   },
 * });
 */
export function useUpdatePatientNoteTagMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePatientNoteTagMutation,
        UpdatePatientNoteTagMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdatePatientNoteTagMutation, UpdatePatientNoteTagMutationVariables>(
        UpdatePatientNoteTagDocument,
        options,
    );
}
export type UpdatePatientNoteTagMutationHookResult = ReturnType<
    typeof useUpdatePatientNoteTagMutation
>;
export type UpdatePatientNoteTagMutationResult =
    Apollo.MutationResult<UpdatePatientNoteTagMutation>;
export type UpdatePatientNoteTagMutationOptions = Apollo.BaseMutationOptions<
    UpdatePatientNoteTagMutation,
    UpdatePatientNoteTagMutationVariables
>;
export const CreatePatientNoteTagDocument = gql`
    mutation CreatePatientNoteTag($createPatientNoteTagInput: PatientNoteTagInput!) {
        createPatientNoteTag(input: $createPatientNoteTagInput) {
            success
            message
            resourceCreated {
                id
                name
            }
        }
    }
`;
export type CreatePatientNoteTagMutationFn = Apollo.MutationFunction<
    CreatePatientNoteTagMutation,
    CreatePatientNoteTagMutationVariables
>;

/**
 * __useCreatePatientNoteTagMutation__
 *
 * To run a mutation, you first call `useCreatePatientNoteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientNoteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientNoteTagMutation, { data, loading, error }] = useCreatePatientNoteTagMutation({
 *   variables: {
 *      createPatientNoteTagInput: // value for 'createPatientNoteTagInput'
 *   },
 * });
 */
export function useCreatePatientNoteTagMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreatePatientNoteTagMutation,
        CreatePatientNoteTagMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreatePatientNoteTagMutation, CreatePatientNoteTagMutationVariables>(
        CreatePatientNoteTagDocument,
        options,
    );
}
export type CreatePatientNoteTagMutationHookResult = ReturnType<
    typeof useCreatePatientNoteTagMutation
>;
export type CreatePatientNoteTagMutationResult =
    Apollo.MutationResult<CreatePatientNoteTagMutation>;
export type CreatePatientNoteTagMutationOptions = Apollo.BaseMutationOptions<
    CreatePatientNoteTagMutation,
    CreatePatientNoteTagMutationVariables
>;
export const PatientNoteTagListDocument = gql`
    query PatientNoteTagList {
        patientNoteTagsV2 {
            results {
                id
                name
            }
        }
    }
`;

/**
 * __usePatientNoteTagListQuery__
 *
 * To run a query within a React component, call `usePatientNoteTagListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientNoteTagListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientNoteTagListQuery({
 *   variables: {
 *   },
 * });
 */
export function usePatientNoteTagListQuery(
    baseOptions?: Apollo.QueryHookOptions<
        PatientNoteTagListQuery,
        PatientNoteTagListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PatientNoteTagListQuery, PatientNoteTagListQueryVariables>(
        PatientNoteTagListDocument,
        options,
    );
}
export function usePatientNoteTagListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PatientNoteTagListQuery,
        PatientNoteTagListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PatientNoteTagListQuery, PatientNoteTagListQueryVariables>(
        PatientNoteTagListDocument,
        options,
    );
}
export type PatientNoteTagListQueryHookResult = ReturnType<typeof usePatientNoteTagListQuery>;
export type PatientNoteTagListLazyQueryHookResult = ReturnType<
    typeof usePatientNoteTagListLazyQuery
>;
export type PatientNoteTagListQueryResult = Apollo.QueryResult<
    PatientNoteTagListQuery,
    PatientNoteTagListQueryVariables
>;
export const DeletePatientNoteTagDocument = gql`
    mutation DeletePatientNoteTag($input: DeletePatientNoteTagInput!) {
        deletePatientNoteTag(input: $input) {
            message
            status
            success
        }
    }
`;
export type DeletePatientNoteTagMutationFn = Apollo.MutationFunction<
    DeletePatientNoteTagMutation,
    DeletePatientNoteTagMutationVariables
>;

/**
 * __useDeletePatientNoteTagMutation__
 *
 * To run a mutation, you first call `useDeletePatientNoteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientNoteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientNoteTagMutation, { data, loading, error }] = useDeletePatientNoteTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePatientNoteTagMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeletePatientNoteTagMutation,
        DeletePatientNoteTagMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeletePatientNoteTagMutation, DeletePatientNoteTagMutationVariables>(
        DeletePatientNoteTagDocument,
        options,
    );
}
export type DeletePatientNoteTagMutationHookResult = ReturnType<
    typeof useDeletePatientNoteTagMutation
>;
export type DeletePatientNoteTagMutationResult =
    Apollo.MutationResult<DeletePatientNoteTagMutation>;
export type DeletePatientNoteTagMutationOptions = Apollo.BaseMutationOptions<
    DeletePatientNoteTagMutation,
    DeletePatientNoteTagMutationVariables
>;
export const FetchUserProfileDefinitionByIdDocument = gql`
    query FetchUserProfileDefinitionById($input: GetUserProfileDefInput!) {
        userProfileDef(input: $input) {
            id
            ...UserProfileDefFragment
            history {
                username
                userId
                type
                name
                beforeState
                afterState
                datetime
            }
        }
    }
    ${UserProfileDefFragmentFragmentDoc}
`;

/**
 * __useFetchUserProfileDefinitionByIdQuery__
 *
 * To run a query within a React component, call `useFetchUserProfileDefinitionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserProfileDefinitionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserProfileDefinitionByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchUserProfileDefinitionByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchUserProfileDefinitionByIdQuery,
        FetchUserProfileDefinitionByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchUserProfileDefinitionByIdQuery,
        FetchUserProfileDefinitionByIdQueryVariables
    >(FetchUserProfileDefinitionByIdDocument, options);
}
export function useFetchUserProfileDefinitionByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchUserProfileDefinitionByIdQuery,
        FetchUserProfileDefinitionByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchUserProfileDefinitionByIdQuery,
        FetchUserProfileDefinitionByIdQueryVariables
    >(FetchUserProfileDefinitionByIdDocument, options);
}
export type FetchUserProfileDefinitionByIdQueryHookResult = ReturnType<
    typeof useFetchUserProfileDefinitionByIdQuery
>;
export type FetchUserProfileDefinitionByIdLazyQueryHookResult = ReturnType<
    typeof useFetchUserProfileDefinitionByIdLazyQuery
>;
export type FetchUserProfileDefinitionByIdQueryResult = Apollo.QueryResult<
    FetchUserProfileDefinitionByIdQuery,
    FetchUserProfileDefinitionByIdQueryVariables
>;
export const UpdateUserProfileDefinitionFromModalDocument = gql`
    mutation UpdateUserProfileDefinitionFromModal($input: UpdateUserProfileDefInput!) {
        updateUserProfileDef(input: $input) {
            message
            success
            resourceUpdated {
                ...UserProfileDefFragment
                history {
                    username
                    userId
                    type
                    name
                    beforeState
                    afterState
                    datetime
                }
            }
        }
    }
    ${UserProfileDefFragmentFragmentDoc}
`;
export type UpdateUserProfileDefinitionFromModalMutationFn = Apollo.MutationFunction<
    UpdateUserProfileDefinitionFromModalMutation,
    UpdateUserProfileDefinitionFromModalMutationVariables
>;

/**
 * __useUpdateUserProfileDefinitionFromModalMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileDefinitionFromModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileDefinitionFromModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileDefinitionFromModalMutation, { data, loading, error }] = useUpdateUserProfileDefinitionFromModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileDefinitionFromModalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateUserProfileDefinitionFromModalMutation,
        UpdateUserProfileDefinitionFromModalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateUserProfileDefinitionFromModalMutation,
        UpdateUserProfileDefinitionFromModalMutationVariables
    >(UpdateUserProfileDefinitionFromModalDocument, options);
}
export type UpdateUserProfileDefinitionFromModalMutationHookResult = ReturnType<
    typeof useUpdateUserProfileDefinitionFromModalMutation
>;
export type UpdateUserProfileDefinitionFromModalMutationResult =
    Apollo.MutationResult<UpdateUserProfileDefinitionFromModalMutation>;
export type UpdateUserProfileDefinitionFromModalMutationOptions = Apollo.BaseMutationOptions<
    UpdateUserProfileDefinitionFromModalMutation,
    UpdateUserProfileDefinitionFromModalMutationVariables
>;
export const CreateUserProfileDefinitionFromModalDocument = gql`
    mutation CreateUserProfileDefinitionFromModal($input: UserProfileDefInput!) {
        createUserProfileDef(input: $input) {
            message
            success
            resourceCreated {
                ...UserProfileDefFragment
                history {
                    username
                    userId
                    type
                    name
                    beforeState
                    afterState
                    datetime
                }
            }
        }
    }
    ${UserProfileDefFragmentFragmentDoc}
`;
export type CreateUserProfileDefinitionFromModalMutationFn = Apollo.MutationFunction<
    CreateUserProfileDefinitionFromModalMutation,
    CreateUserProfileDefinitionFromModalMutationVariables
>;

/**
 * __useCreateUserProfileDefinitionFromModalMutation__
 *
 * To run a mutation, you first call `useCreateUserProfileDefinitionFromModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserProfileDefinitionFromModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserProfileDefinitionFromModalMutation, { data, loading, error }] = useCreateUserProfileDefinitionFromModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserProfileDefinitionFromModalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateUserProfileDefinitionFromModalMutation,
        CreateUserProfileDefinitionFromModalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateUserProfileDefinitionFromModalMutation,
        CreateUserProfileDefinitionFromModalMutationVariables
    >(CreateUserProfileDefinitionFromModalDocument, options);
}
export type CreateUserProfileDefinitionFromModalMutationHookResult = ReturnType<
    typeof useCreateUserProfileDefinitionFromModalMutation
>;
export type CreateUserProfileDefinitionFromModalMutationResult =
    Apollo.MutationResult<CreateUserProfileDefinitionFromModalMutation>;
export type CreateUserProfileDefinitionFromModalMutationOptions = Apollo.BaseMutationOptions<
    CreateUserProfileDefinitionFromModalMutation,
    CreateUserProfileDefinitionFromModalMutationVariables
>;
export const PreFetchAppFullNamesDocument = gql`
    query PreFetchAppFullNames {
        amsApps {
            id
            bundleId
            fullName
        }
    }
`;

/**
 * __usePreFetchAppFullNamesQuery__
 *
 * To run a query within a React component, call `usePreFetchAppFullNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreFetchAppFullNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreFetchAppFullNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreFetchAppFullNamesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        PreFetchAppFullNamesQuery,
        PreFetchAppFullNamesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PreFetchAppFullNamesQuery, PreFetchAppFullNamesQueryVariables>(
        PreFetchAppFullNamesDocument,
        options,
    );
}
export function usePreFetchAppFullNamesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PreFetchAppFullNamesQuery,
        PreFetchAppFullNamesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PreFetchAppFullNamesQuery, PreFetchAppFullNamesQueryVariables>(
        PreFetchAppFullNamesDocument,
        options,
    );
}
export type PreFetchAppFullNamesQueryHookResult = ReturnType<typeof usePreFetchAppFullNamesQuery>;
export type PreFetchAppFullNamesLazyQueryHookResult = ReturnType<
    typeof usePreFetchAppFullNamesLazyQuery
>;
export type PreFetchAppFullNamesQueryResult = Apollo.QueryResult<
    PreFetchAppFullNamesQuery,
    PreFetchAppFullNamesQueryVariables
>;
export const JiraInfoByUrlDocument = gql`
    query JiraInfoByUrl($input: JiraInfoInput!) {
        jiraInfoByUrl(input: $input) {
            dateRequested
            requestStatus
            requestor
            assignee
            dateExpected
            dateCompleted
        }
    }
`;

/**
 * __useJiraInfoByUrlQuery__
 *
 * To run a query within a React component, call `useJiraInfoByUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useJiraInfoByUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJiraInfoByUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJiraInfoByUrlQuery(
    baseOptions: Apollo.QueryHookOptions<JiraInfoByUrlQuery, JiraInfoByUrlQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<JiraInfoByUrlQuery, JiraInfoByUrlQueryVariables>(
        JiraInfoByUrlDocument,
        options,
    );
}
export function useJiraInfoByUrlLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<JiraInfoByUrlQuery, JiraInfoByUrlQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<JiraInfoByUrlQuery, JiraInfoByUrlQueryVariables>(
        JiraInfoByUrlDocument,
        options,
    );
}
export type JiraInfoByUrlQueryHookResult = ReturnType<typeof useJiraInfoByUrlQuery>;
export type JiraInfoByUrlLazyQueryHookResult = ReturnType<typeof useJiraInfoByUrlLazyQuery>;
export type JiraInfoByUrlQueryResult = Apollo.QueryResult<
    JiraInfoByUrlQuery,
    JiraInfoByUrlQueryVariables
>;
export const ConditionTypeListDocument = gql`
    query ConditionTypeList {
        conditionTypes {
            id
            name {
                en
                es
            }
        }
    }
`;

/**
 * __useConditionTypeListQuery__
 *
 * To run a query within a React component, call `useConditionTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useConditionTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConditionTypeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useConditionTypeListQuery(
    baseOptions?: Apollo.QueryHookOptions<ConditionTypeListQuery, ConditionTypeListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ConditionTypeListQuery, ConditionTypeListQueryVariables>(
        ConditionTypeListDocument,
        options,
    );
}
export function useConditionTypeListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ConditionTypeListQuery,
        ConditionTypeListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ConditionTypeListQuery, ConditionTypeListQueryVariables>(
        ConditionTypeListDocument,
        options,
    );
}
export type ConditionTypeListQueryHookResult = ReturnType<typeof useConditionTypeListQuery>;
export type ConditionTypeListLazyQueryHookResult = ReturnType<typeof useConditionTypeListLazyQuery>;
export type ConditionTypeListQueryResult = Apollo.QueryResult<
    ConditionTypeListQuery,
    ConditionTypeListQueryVariables
>;
export const ProfileValueOriginListDocument = gql`
    query ProfileValueOriginList {
        profileValueOrigins {
            id
            label {
                en
                es
            }
        }
    }
`;

/**
 * __useProfileValueOriginListQuery__
 *
 * To run a query within a React component, call `useProfileValueOriginListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileValueOriginListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileValueOriginListQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileValueOriginListQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ProfileValueOriginListQuery,
        ProfileValueOriginListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ProfileValueOriginListQuery, ProfileValueOriginListQueryVariables>(
        ProfileValueOriginListDocument,
        options,
    );
}
export function useProfileValueOriginListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ProfileValueOriginListQuery,
        ProfileValueOriginListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ProfileValueOriginListQuery, ProfileValueOriginListQueryVariables>(
        ProfileValueOriginListDocument,
        options,
    );
}
export type ProfileValueOriginListQueryHookResult = ReturnType<
    typeof useProfileValueOriginListQuery
>;
export type ProfileValueOriginListLazyQueryHookResult = ReturnType<
    typeof useProfileValueOriginListLazyQuery
>;
export type ProfileValueOriginListQueryResult = Apollo.QueryResult<
    ProfileValueOriginListQuery,
    ProfileValueOriginListQueryVariables
>;
export const FetchProfileDefsForProfileDefsPageQeryDocument = gql`
    query FetchProfileDefsForProfileDefsPageQery {
        userProfileDefs {
            ...UserProfileDefFragment
        }
    }
    ${UserProfileDefFragmentFragmentDoc}
`;

/**
 * __useFetchProfileDefsForProfileDefsPageQeryQuery__
 *
 * To run a query within a React component, call `useFetchProfileDefsForProfileDefsPageQeryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProfileDefsForProfileDefsPageQeryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProfileDefsForProfileDefsPageQeryQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchProfileDefsForProfileDefsPageQeryQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchProfileDefsForProfileDefsPageQeryQuery,
        FetchProfileDefsForProfileDefsPageQeryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchProfileDefsForProfileDefsPageQeryQuery,
        FetchProfileDefsForProfileDefsPageQeryQueryVariables
    >(FetchProfileDefsForProfileDefsPageQeryDocument, options);
}
export function useFetchProfileDefsForProfileDefsPageQeryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchProfileDefsForProfileDefsPageQeryQuery,
        FetchProfileDefsForProfileDefsPageQeryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchProfileDefsForProfileDefsPageQeryQuery,
        FetchProfileDefsForProfileDefsPageQeryQueryVariables
    >(FetchProfileDefsForProfileDefsPageQeryDocument, options);
}
export type FetchProfileDefsForProfileDefsPageQeryQueryHookResult = ReturnType<
    typeof useFetchProfileDefsForProfileDefsPageQeryQuery
>;
export type FetchProfileDefsForProfileDefsPageQeryLazyQueryHookResult = ReturnType<
    typeof useFetchProfileDefsForProfileDefsPageQeryLazyQuery
>;
export type FetchProfileDefsForProfileDefsPageQeryQueryResult = Apollo.QueryResult<
    FetchProfileDefsForProfileDefsPageQeryQuery,
    FetchProfileDefsForProfileDefsPageQeryQueryVariables
>;
export const ProfileDefWhereUsedDocument = gql`
    query ProfileDefWhereUsed($id: String!) {
        profileDefWhereUsed(id: $id) {
            dependencies {
                entityType
                blocksDelete
                total
                entries {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useProfileDefWhereUsedQuery__
 *
 * To run a query within a React component, call `useProfileDefWhereUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileDefWhereUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileDefWhereUsedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfileDefWhereUsedQuery(
    baseOptions: Apollo.QueryHookOptions<
        ProfileDefWhereUsedQuery,
        ProfileDefWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ProfileDefWhereUsedQuery, ProfileDefWhereUsedQueryVariables>(
        ProfileDefWhereUsedDocument,
        options,
    );
}
export function useProfileDefWhereUsedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ProfileDefWhereUsedQuery,
        ProfileDefWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ProfileDefWhereUsedQuery, ProfileDefWhereUsedQueryVariables>(
        ProfileDefWhereUsedDocument,
        options,
    );
}
export type ProfileDefWhereUsedQueryHookResult = ReturnType<typeof useProfileDefWhereUsedQuery>;
export type ProfileDefWhereUsedLazyQueryHookResult = ReturnType<
    typeof useProfileDefWhereUsedLazyQuery
>;
export type ProfileDefWhereUsedQueryResult = Apollo.QueryResult<
    ProfileDefWhereUsedQuery,
    ProfileDefWhereUsedQueryVariables
>;
export const ImportUserProfileDefsDocument = gql`
    mutation ImportUserProfileDefs($input: CreateManyUserProfileDefsInput!) {
        createManyUserProfileDefs(input: $input) {
            success
            message
            resourcesCreated {
                status
                message
                success
                resourceCreated {
                    id
                    name
                    description
                    questionLabel {
                        en
                        es
                    }
                    label {
                        en
                        es
                    }
                    category
                    valueType
                    choiceValueType
                    systemGenerated
                    surveyQuestionDisabled
                    conditionTypeId
                    episodeRequired
                    reportingNotes
                    inboundNotes
                    haPermission
                    choices {
                        id
                        label {
                            en
                            es
                        }
                        answerLabel {
                            en
                            es
                        }
                        specifyValue
                        value {
                            str
                            num
                            date
                            bool
                        }
                        name
                    }
                }
            }
        }
    }
`;
export type ImportUserProfileDefsMutationFn = Apollo.MutationFunction<
    ImportUserProfileDefsMutation,
    ImportUserProfileDefsMutationVariables
>;

/**
 * __useImportUserProfileDefsMutation__
 *
 * To run a mutation, you first call `useImportUserProfileDefsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportUserProfileDefsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importUserProfileDefsMutation, { data, loading, error }] = useImportUserProfileDefsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportUserProfileDefsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ImportUserProfileDefsMutation,
        ImportUserProfileDefsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        ImportUserProfileDefsMutation,
        ImportUserProfileDefsMutationVariables
    >(ImportUserProfileDefsDocument, options);
}
export type ImportUserProfileDefsMutationHookResult = ReturnType<
    typeof useImportUserProfileDefsMutation
>;
export type ImportUserProfileDefsMutationResult =
    Apollo.MutationResult<ImportUserProfileDefsMutation>;
export type ImportUserProfileDefsMutationOptions = Apollo.BaseMutationOptions<
    ImportUserProfileDefsMutation,
    ImportUserProfileDefsMutationVariables
>;
export const FetchUserProfileDefHistoryByIdDocument = gql`
    query FetchUserProfileDefHistoryById($input: GetUserProfileDefInput!) {
        userProfileDef(input: $input) {
            id
            name
            history {
                username
                type
                name
                beforeState
                afterState
                datetime
            }
        }
    }
`;

/**
 * __useFetchUserProfileDefHistoryByIdQuery__
 *
 * To run a query within a React component, call `useFetchUserProfileDefHistoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserProfileDefHistoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserProfileDefHistoryByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchUserProfileDefHistoryByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchUserProfileDefHistoryByIdQuery,
        FetchUserProfileDefHistoryByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchUserProfileDefHistoryByIdQuery,
        FetchUserProfileDefHistoryByIdQueryVariables
    >(FetchUserProfileDefHistoryByIdDocument, options);
}
export function useFetchUserProfileDefHistoryByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchUserProfileDefHistoryByIdQuery,
        FetchUserProfileDefHistoryByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchUserProfileDefHistoryByIdQuery,
        FetchUserProfileDefHistoryByIdQueryVariables
    >(FetchUserProfileDefHistoryByIdDocument, options);
}
export type FetchUserProfileDefHistoryByIdQueryHookResult = ReturnType<
    typeof useFetchUserProfileDefHistoryByIdQuery
>;
export type FetchUserProfileDefHistoryByIdLazyQueryHookResult = ReturnType<
    typeof useFetchUserProfileDefHistoryByIdLazyQuery
>;
export type FetchUserProfileDefHistoryByIdQueryResult = Apollo.QueryResult<
    FetchUserProfileDefHistoryByIdQuery,
    FetchUserProfileDefHistoryByIdQueryVariables
>;
export const ProfileValueOriginByIdDocument = gql`
    query ProfileValueOriginById($input: GetProfileValueOriginInput!) {
        profileValueOrigin(input: $input) {
            id
            label {
                en
                es
            }
        }
    }
`;

/**
 * __useProfileValueOriginByIdQuery__
 *
 * To run a query within a React component, call `useProfileValueOriginByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileValueOriginByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileValueOriginByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfileValueOriginByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        ProfileValueOriginByIdQuery,
        ProfileValueOriginByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ProfileValueOriginByIdQuery, ProfileValueOriginByIdQueryVariables>(
        ProfileValueOriginByIdDocument,
        options,
    );
}
export function useProfileValueOriginByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ProfileValueOriginByIdQuery,
        ProfileValueOriginByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ProfileValueOriginByIdQuery, ProfileValueOriginByIdQueryVariables>(
        ProfileValueOriginByIdDocument,
        options,
    );
}
export type ProfileValueOriginByIdQueryHookResult = ReturnType<
    typeof useProfileValueOriginByIdQuery
>;
export type ProfileValueOriginByIdLazyQueryHookResult = ReturnType<
    typeof useProfileValueOriginByIdLazyQuery
>;
export type ProfileValueOriginByIdQueryResult = Apollo.QueryResult<
    ProfileValueOriginByIdQuery,
    ProfileValueOriginByIdQueryVariables
>;
export const CreateProfileValueOriginDocument = gql`
    mutation CreateProfileValueOrigin($input: ProfileValueOriginInput!) {
        createProfileValueOrigin(input: $input) {
            status
            message
            success
            resourceCreated {
                id
                label {
                    en
                    es
                }
            }
        }
    }
`;
export type CreateProfileValueOriginMutationFn = Apollo.MutationFunction<
    CreateProfileValueOriginMutation,
    CreateProfileValueOriginMutationVariables
>;

/**
 * __useCreateProfileValueOriginMutation__
 *
 * To run a mutation, you first call `useCreateProfileValueOriginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfileValueOriginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfileValueOriginMutation, { data, loading, error }] = useCreateProfileValueOriginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProfileValueOriginMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateProfileValueOriginMutation,
        CreateProfileValueOriginMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateProfileValueOriginMutation,
        CreateProfileValueOriginMutationVariables
    >(CreateProfileValueOriginDocument, options);
}
export type CreateProfileValueOriginMutationHookResult = ReturnType<
    typeof useCreateProfileValueOriginMutation
>;
export type CreateProfileValueOriginMutationResult =
    Apollo.MutationResult<CreateProfileValueOriginMutation>;
export type CreateProfileValueOriginMutationOptions = Apollo.BaseMutationOptions<
    CreateProfileValueOriginMutation,
    CreateProfileValueOriginMutationVariables
>;
export const ProfileValueOriginPageListDocument = gql`
    query ProfileValueOriginPageList {
        profileValueOrigins {
            id
            label {
                en
                es
            }
        }
    }
`;

/**
 * __useProfileValueOriginPageListQuery__
 *
 * To run a query within a React component, call `useProfileValueOriginPageListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileValueOriginPageListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileValueOriginPageListQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileValueOriginPageListQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ProfileValueOriginPageListQuery,
        ProfileValueOriginPageListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        ProfileValueOriginPageListQuery,
        ProfileValueOriginPageListQueryVariables
    >(ProfileValueOriginPageListDocument, options);
}
export function useProfileValueOriginPageListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ProfileValueOriginPageListQuery,
        ProfileValueOriginPageListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ProfileValueOriginPageListQuery,
        ProfileValueOriginPageListQueryVariables
    >(ProfileValueOriginPageListDocument, options);
}
export type ProfileValueOriginPageListQueryHookResult = ReturnType<
    typeof useProfileValueOriginPageListQuery
>;
export type ProfileValueOriginPageListLazyQueryHookResult = ReturnType<
    typeof useProfileValueOriginPageListLazyQuery
>;
export type ProfileValueOriginPageListQueryResult = Apollo.QueryResult<
    ProfileValueOriginPageListQuery,
    ProfileValueOriginPageListQueryVariables
>;
export const UpdateProfileValueOriginDocument = gql`
    mutation UpdateProfileValueOrigin(
        $updateProfileValueOriginInput: UpdateProfileValueOriginInput!
    ) {
        updateProfileValueOrigin(input: $updateProfileValueOriginInput) {
            status
            message
            success
            resourceUpdated {
                id
                label {
                    en
                    es
                }
            }
        }
    }
`;
export type UpdateProfileValueOriginMutationFn = Apollo.MutationFunction<
    UpdateProfileValueOriginMutation,
    UpdateProfileValueOriginMutationVariables
>;

/**
 * __useUpdateProfileValueOriginMutation__
 *
 * To run a mutation, you first call `useUpdateProfileValueOriginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileValueOriginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileValueOriginMutation, { data, loading, error }] = useUpdateProfileValueOriginMutation({
 *   variables: {
 *      updateProfileValueOriginInput: // value for 'updateProfileValueOriginInput'
 *   },
 * });
 */
export function useUpdateProfileValueOriginMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateProfileValueOriginMutation,
        UpdateProfileValueOriginMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateProfileValueOriginMutation,
        UpdateProfileValueOriginMutationVariables
    >(UpdateProfileValueOriginDocument, options);
}
export type UpdateProfileValueOriginMutationHookResult = ReturnType<
    typeof useUpdateProfileValueOriginMutation
>;
export type UpdateProfileValueOriginMutationResult =
    Apollo.MutationResult<UpdateProfileValueOriginMutation>;
export type UpdateProfileValueOriginMutationOptions = Apollo.BaseMutationOptions<
    UpdateProfileValueOriginMutation,
    UpdateProfileValueOriginMutationVariables
>;
export const ReferenceLinkCategoriesListDocument = gql`
    query ReferenceLinkCategoriesList {
        referenceLinkCategorysV2 {
            results {
                id
                name
            }
        }
    }
`;

/**
 * __useReferenceLinkCategoriesListQuery__
 *
 * To run a query within a React component, call `useReferenceLinkCategoriesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferenceLinkCategoriesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferenceLinkCategoriesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferenceLinkCategoriesListQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ReferenceLinkCategoriesListQuery,
        ReferenceLinkCategoriesListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        ReferenceLinkCategoriesListQuery,
        ReferenceLinkCategoriesListQueryVariables
    >(ReferenceLinkCategoriesListDocument, options);
}
export function useReferenceLinkCategoriesListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ReferenceLinkCategoriesListQuery,
        ReferenceLinkCategoriesListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ReferenceLinkCategoriesListQuery,
        ReferenceLinkCategoriesListQueryVariables
    >(ReferenceLinkCategoriesListDocument, options);
}
export type ReferenceLinkCategoriesListQueryHookResult = ReturnType<
    typeof useReferenceLinkCategoriesListQuery
>;
export type ReferenceLinkCategoriesListLazyQueryHookResult = ReturnType<
    typeof useReferenceLinkCategoriesListLazyQuery
>;
export type ReferenceLinkCategoriesListQueryResult = Apollo.QueryResult<
    ReferenceLinkCategoriesListQuery,
    ReferenceLinkCategoriesListQueryVariables
>;
export const FetchReferenceLinkCategoryDocument = gql`
    query FetchReferenceLinkCategory($input: GetReferenceLinkCategoryInput!) {
        referenceLinkCategory(input: $input) {
            id
            name
        }
    }
`;

/**
 * __useFetchReferenceLinkCategoryQuery__
 *
 * To run a query within a React component, call `useFetchReferenceLinkCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchReferenceLinkCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchReferenceLinkCategoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchReferenceLinkCategoryQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchReferenceLinkCategoryQuery,
        FetchReferenceLinkCategoryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchReferenceLinkCategoryQuery,
        FetchReferenceLinkCategoryQueryVariables
    >(FetchReferenceLinkCategoryDocument, options);
}
export function useFetchReferenceLinkCategoryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchReferenceLinkCategoryQuery,
        FetchReferenceLinkCategoryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchReferenceLinkCategoryQuery,
        FetchReferenceLinkCategoryQueryVariables
    >(FetchReferenceLinkCategoryDocument, options);
}
export type FetchReferenceLinkCategoryQueryHookResult = ReturnType<
    typeof useFetchReferenceLinkCategoryQuery
>;
export type FetchReferenceLinkCategoryLazyQueryHookResult = ReturnType<
    typeof useFetchReferenceLinkCategoryLazyQuery
>;
export type FetchReferenceLinkCategoryQueryResult = Apollo.QueryResult<
    FetchReferenceLinkCategoryQuery,
    FetchReferenceLinkCategoryQueryVariables
>;
export const CreateReferenceLinkCategoryDocument = gql`
    mutation CreateReferenceLinkCategory($input: ReferenceLinkCategoryInput!) {
        createReferenceLinkCategory(input: $input) {
            success
            message
            resourceCreated {
                id
                name
            }
        }
    }
`;
export type CreateReferenceLinkCategoryMutationFn = Apollo.MutationFunction<
    CreateReferenceLinkCategoryMutation,
    CreateReferenceLinkCategoryMutationVariables
>;

/**
 * __useCreateReferenceLinkCategoryMutation__
 *
 * To run a mutation, you first call `useCreateReferenceLinkCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferenceLinkCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferenceLinkCategoryMutation, { data, loading, error }] = useCreateReferenceLinkCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReferenceLinkCategoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateReferenceLinkCategoryMutation,
        CreateReferenceLinkCategoryMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateReferenceLinkCategoryMutation,
        CreateReferenceLinkCategoryMutationVariables
    >(CreateReferenceLinkCategoryDocument, options);
}
export type CreateReferenceLinkCategoryMutationHookResult = ReturnType<
    typeof useCreateReferenceLinkCategoryMutation
>;
export type CreateReferenceLinkCategoryMutationResult =
    Apollo.MutationResult<CreateReferenceLinkCategoryMutation>;
export type CreateReferenceLinkCategoryMutationOptions = Apollo.BaseMutationOptions<
    CreateReferenceLinkCategoryMutation,
    CreateReferenceLinkCategoryMutationVariables
>;
export const UpdateReferenceLinkCategoryDocument = gql`
    mutation UpdateReferenceLinkCategory($input: UpdateReferenceLinkCategoryInput!) {
        updateReferenceLinkCategory(input: $input) {
            success
            message
            resourceUpdated {
                id
                name
            }
        }
    }
`;
export type UpdateReferenceLinkCategoryMutationFn = Apollo.MutationFunction<
    UpdateReferenceLinkCategoryMutation,
    UpdateReferenceLinkCategoryMutationVariables
>;

/**
 * __useUpdateReferenceLinkCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateReferenceLinkCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferenceLinkCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferenceLinkCategoryMutation, { data, loading, error }] = useUpdateReferenceLinkCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReferenceLinkCategoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateReferenceLinkCategoryMutation,
        UpdateReferenceLinkCategoryMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateReferenceLinkCategoryMutation,
        UpdateReferenceLinkCategoryMutationVariables
    >(UpdateReferenceLinkCategoryDocument, options);
}
export type UpdateReferenceLinkCategoryMutationHookResult = ReturnType<
    typeof useUpdateReferenceLinkCategoryMutation
>;
export type UpdateReferenceLinkCategoryMutationResult =
    Apollo.MutationResult<UpdateReferenceLinkCategoryMutation>;
export type UpdateReferenceLinkCategoryMutationOptions = Apollo.BaseMutationOptions<
    UpdateReferenceLinkCategoryMutation,
    UpdateReferenceLinkCategoryMutationVariables
>;
export const DeleteReferenceLinkCategoryDocument = gql`
    mutation DeleteReferenceLinkCategory($input: DeleteReferenceLinkCategoryInput!) {
        deleteReferenceLinkCategory(input: $input) {
            message
            status
            success
        }
    }
`;
export type DeleteReferenceLinkCategoryMutationFn = Apollo.MutationFunction<
    DeleteReferenceLinkCategoryMutation,
    DeleteReferenceLinkCategoryMutationVariables
>;

/**
 * __useDeleteReferenceLinkCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteReferenceLinkCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReferenceLinkCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReferenceLinkCategoryMutation, { data, loading, error }] = useDeleteReferenceLinkCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteReferenceLinkCategoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteReferenceLinkCategoryMutation,
        DeleteReferenceLinkCategoryMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteReferenceLinkCategoryMutation,
        DeleteReferenceLinkCategoryMutationVariables
    >(DeleteReferenceLinkCategoryDocument, options);
}
export type DeleteReferenceLinkCategoryMutationHookResult = ReturnType<
    typeof useDeleteReferenceLinkCategoryMutation
>;
export type DeleteReferenceLinkCategoryMutationResult =
    Apollo.MutationResult<DeleteReferenceLinkCategoryMutation>;
export type DeleteReferenceLinkCategoryMutationOptions = Apollo.BaseMutationOptions<
    DeleteReferenceLinkCategoryMutation,
    DeleteReferenceLinkCategoryMutationVariables
>;
export const ReferenceLinksListDocument = gql`
    query ReferenceLinksList {
        referenceLinksV2 {
            results {
                id
                organizationId
                categoryId
                label
                url
            }
        }
    }
`;

/**
 * __useReferenceLinksListQuery__
 *
 * To run a query within a React component, call `useReferenceLinksListQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferenceLinksListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferenceLinksListQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferenceLinksListQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ReferenceLinksListQuery,
        ReferenceLinksListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ReferenceLinksListQuery, ReferenceLinksListQueryVariables>(
        ReferenceLinksListDocument,
        options,
    );
}
export function useReferenceLinksListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ReferenceLinksListQuery,
        ReferenceLinksListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ReferenceLinksListQuery, ReferenceLinksListQueryVariables>(
        ReferenceLinksListDocument,
        options,
    );
}
export type ReferenceLinksListQueryHookResult = ReturnType<typeof useReferenceLinksListQuery>;
export type ReferenceLinksListLazyQueryHookResult = ReturnType<
    typeof useReferenceLinksListLazyQuery
>;
export type ReferenceLinksListQueryResult = Apollo.QueryResult<
    ReferenceLinksListQuery,
    ReferenceLinksListQueryVariables
>;
export const FetchReferenceLinkDocument = gql`
    query FetchReferenceLink($input: GetReferenceLinkInput!) {
        referenceLink(input: $input) {
            id
            categoryId
            label
            organizationId
            pages
            roleId
            sortField
            url
        }
    }
`;

/**
 * __useFetchReferenceLinkQuery__
 *
 * To run a query within a React component, call `useFetchReferenceLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchReferenceLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchReferenceLinkQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchReferenceLinkQuery(
    baseOptions: Apollo.QueryHookOptions<FetchReferenceLinkQuery, FetchReferenceLinkQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchReferenceLinkQuery, FetchReferenceLinkQueryVariables>(
        FetchReferenceLinkDocument,
        options,
    );
}
export function useFetchReferenceLinkLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchReferenceLinkQuery,
        FetchReferenceLinkQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchReferenceLinkQuery, FetchReferenceLinkQueryVariables>(
        FetchReferenceLinkDocument,
        options,
    );
}
export type FetchReferenceLinkQueryHookResult = ReturnType<typeof useFetchReferenceLinkQuery>;
export type FetchReferenceLinkLazyQueryHookResult = ReturnType<
    typeof useFetchReferenceLinkLazyQuery
>;
export type FetchReferenceLinkQueryResult = Apollo.QueryResult<
    FetchReferenceLinkQuery,
    FetchReferenceLinkQueryVariables
>;
export const CreateReferenceLinkDocument = gql`
    mutation CreateReferenceLink($input: ReferenceLinkInput!) {
        createReferenceLink(input: $input) {
            message
            status
            success
            resourceCreated {
                id
                categoryId
                label
                organizationId
                pages
                roleId
                sortField
                url
            }
        }
    }
`;
export type CreateReferenceLinkMutationFn = Apollo.MutationFunction<
    CreateReferenceLinkMutation,
    CreateReferenceLinkMutationVariables
>;

/**
 * __useCreateReferenceLinkMutation__
 *
 * To run a mutation, you first call `useCreateReferenceLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferenceLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferenceLinkMutation, { data, loading, error }] = useCreateReferenceLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReferenceLinkMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateReferenceLinkMutation,
        CreateReferenceLinkMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateReferenceLinkMutation, CreateReferenceLinkMutationVariables>(
        CreateReferenceLinkDocument,
        options,
    );
}
export type CreateReferenceLinkMutationHookResult = ReturnType<
    typeof useCreateReferenceLinkMutation
>;
export type CreateReferenceLinkMutationResult = Apollo.MutationResult<CreateReferenceLinkMutation>;
export type CreateReferenceLinkMutationOptions = Apollo.BaseMutationOptions<
    CreateReferenceLinkMutation,
    CreateReferenceLinkMutationVariables
>;
export const UpdateReferenceLinkDocument = gql`
    mutation UpdateReferenceLink($input: UpdateReferenceLinkInput!) {
        updateReferenceLink(input: $input) {
            message
            status
            success
            resourceUpdated {
                id
                categoryId
                label
                organizationId
                pages
                roleId
                sortField
                url
            }
        }
    }
`;
export type UpdateReferenceLinkMutationFn = Apollo.MutationFunction<
    UpdateReferenceLinkMutation,
    UpdateReferenceLinkMutationVariables
>;

/**
 * __useUpdateReferenceLinkMutation__
 *
 * To run a mutation, you first call `useUpdateReferenceLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferenceLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferenceLinkMutation, { data, loading, error }] = useUpdateReferenceLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReferenceLinkMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateReferenceLinkMutation,
        UpdateReferenceLinkMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateReferenceLinkMutation, UpdateReferenceLinkMutationVariables>(
        UpdateReferenceLinkDocument,
        options,
    );
}
export type UpdateReferenceLinkMutationHookResult = ReturnType<
    typeof useUpdateReferenceLinkMutation
>;
export type UpdateReferenceLinkMutationResult = Apollo.MutationResult<UpdateReferenceLinkMutation>;
export type UpdateReferenceLinkMutationOptions = Apollo.BaseMutationOptions<
    UpdateReferenceLinkMutation,
    UpdateReferenceLinkMutationVariables
>;
export const DeleteReferenceLinkDocument = gql`
    mutation DeleteReferenceLink($input: DeleteReferenceLinkInput!) {
        deleteReferenceLink(input: $input) {
            message
            status
            success
        }
    }
`;
export type DeleteReferenceLinkMutationFn = Apollo.MutationFunction<
    DeleteReferenceLinkMutation,
    DeleteReferenceLinkMutationVariables
>;

/**
 * __useDeleteReferenceLinkMutation__
 *
 * To run a mutation, you first call `useDeleteReferenceLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReferenceLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReferenceLinkMutation, { data, loading, error }] = useDeleteReferenceLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteReferenceLinkMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteReferenceLinkMutation,
        DeleteReferenceLinkMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteReferenceLinkMutation, DeleteReferenceLinkMutationVariables>(
        DeleteReferenceLinkDocument,
        options,
    );
}
export type DeleteReferenceLinkMutationHookResult = ReturnType<
    typeof useDeleteReferenceLinkMutation
>;
export type DeleteReferenceLinkMutationResult = Apollo.MutationResult<DeleteReferenceLinkMutation>;
export type DeleteReferenceLinkMutationOptions = Apollo.BaseMutationOptions<
    DeleteReferenceLinkMutation,
    DeleteReferenceLinkMutationVariables
>;
export const FetchOrgListForReferenceLinkPageDocument = gql`
    query FetchOrgListForReferenceLinkPage {
        organizations {
            id
            name
        }
    }
`;

/**
 * __useFetchOrgListForReferenceLinkPageQuery__
 *
 * To run a query within a React component, call `useFetchOrgListForReferenceLinkPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOrgListForReferenceLinkPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOrgListForReferenceLinkPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchOrgListForReferenceLinkPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchOrgListForReferenceLinkPageQuery,
        FetchOrgListForReferenceLinkPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchOrgListForReferenceLinkPageQuery,
        FetchOrgListForReferenceLinkPageQueryVariables
    >(FetchOrgListForReferenceLinkPageDocument, options);
}
export function useFetchOrgListForReferenceLinkPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchOrgListForReferenceLinkPageQuery,
        FetchOrgListForReferenceLinkPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchOrgListForReferenceLinkPageQuery,
        FetchOrgListForReferenceLinkPageQueryVariables
    >(FetchOrgListForReferenceLinkPageDocument, options);
}
export type FetchOrgListForReferenceLinkPageQueryHookResult = ReturnType<
    typeof useFetchOrgListForReferenceLinkPageQuery
>;
export type FetchOrgListForReferenceLinkPageLazyQueryHookResult = ReturnType<
    typeof useFetchOrgListForReferenceLinkPageLazyQuery
>;
export type FetchOrgListForReferenceLinkPageQueryResult = Apollo.QueryResult<
    FetchOrgListForReferenceLinkPageQuery,
    FetchOrgListForReferenceLinkPageQueryVariables
>;
export const FetchRolesForReferenceLinkPageDocument = gql`
    query FetchRolesForReferenceLinkPage {
        roles {
            id
            name
            organizationId
        }
    }
`;

/**
 * __useFetchRolesForReferenceLinkPageQuery__
 *
 * To run a query within a React component, call `useFetchRolesForReferenceLinkPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRolesForReferenceLinkPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRolesForReferenceLinkPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchRolesForReferenceLinkPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchRolesForReferenceLinkPageQuery,
        FetchRolesForReferenceLinkPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchRolesForReferenceLinkPageQuery,
        FetchRolesForReferenceLinkPageQueryVariables
    >(FetchRolesForReferenceLinkPageDocument, options);
}
export function useFetchRolesForReferenceLinkPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchRolesForReferenceLinkPageQuery,
        FetchRolesForReferenceLinkPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchRolesForReferenceLinkPageQuery,
        FetchRolesForReferenceLinkPageQueryVariables
    >(FetchRolesForReferenceLinkPageDocument, options);
}
export type FetchRolesForReferenceLinkPageQueryHookResult = ReturnType<
    typeof useFetchRolesForReferenceLinkPageQuery
>;
export type FetchRolesForReferenceLinkPageLazyQueryHookResult = ReturnType<
    typeof useFetchRolesForReferenceLinkPageLazyQuery
>;
export type FetchRolesForReferenceLinkPageQueryResult = Apollo.QueryResult<
    FetchRolesForReferenceLinkPageQuery,
    FetchRolesForReferenceLinkPageQueryVariables
>;
export const FetchReferenceLinkCategoriesForReferenceLinkPageDocument = gql`
    query FetchReferenceLinkCategoriesForReferenceLinkPage {
        referenceLinkCategorys {
            id
            name
        }
    }
`;

/**
 * __useFetchReferenceLinkCategoriesForReferenceLinkPageQuery__
 *
 * To run a query within a React component, call `useFetchReferenceLinkCategoriesForReferenceLinkPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchReferenceLinkCategoriesForReferenceLinkPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchReferenceLinkCategoriesForReferenceLinkPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchReferenceLinkCategoriesForReferenceLinkPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchReferenceLinkCategoriesForReferenceLinkPageQuery,
        FetchReferenceLinkCategoriesForReferenceLinkPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchReferenceLinkCategoriesForReferenceLinkPageQuery,
        FetchReferenceLinkCategoriesForReferenceLinkPageQueryVariables
    >(FetchReferenceLinkCategoriesForReferenceLinkPageDocument, options);
}
export function useFetchReferenceLinkCategoriesForReferenceLinkPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchReferenceLinkCategoriesForReferenceLinkPageQuery,
        FetchReferenceLinkCategoriesForReferenceLinkPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchReferenceLinkCategoriesForReferenceLinkPageQuery,
        FetchReferenceLinkCategoriesForReferenceLinkPageQueryVariables
    >(FetchReferenceLinkCategoriesForReferenceLinkPageDocument, options);
}
export type FetchReferenceLinkCategoriesForReferenceLinkPageQueryHookResult = ReturnType<
    typeof useFetchReferenceLinkCategoriesForReferenceLinkPageQuery
>;
export type FetchReferenceLinkCategoriesForReferenceLinkPageLazyQueryHookResult = ReturnType<
    typeof useFetchReferenceLinkCategoriesForReferenceLinkPageLazyQuery
>;
export type FetchReferenceLinkCategoriesForReferenceLinkPageQueryResult = Apollo.QueryResult<
    FetchReferenceLinkCategoriesForReferenceLinkPageQuery,
    FetchReferenceLinkCategoriesForReferenceLinkPageQueryVariables
>;
export const ReportingCategoryByIdDocument = gql`
    query ReportingCategoryById($input: GetReportingCategoryInput!) {
        reportingCategory(input: $input) {
            id
            name
        }
    }
`;

/**
 * __useReportingCategoryByIdQuery__
 *
 * To run a query within a React component, call `useReportingCategoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportingCategoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingCategoryByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportingCategoryByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        ReportingCategoryByIdQuery,
        ReportingCategoryByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ReportingCategoryByIdQuery, ReportingCategoryByIdQueryVariables>(
        ReportingCategoryByIdDocument,
        options,
    );
}
export function useReportingCategoryByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ReportingCategoryByIdQuery,
        ReportingCategoryByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ReportingCategoryByIdQuery, ReportingCategoryByIdQueryVariables>(
        ReportingCategoryByIdDocument,
        options,
    );
}
export type ReportingCategoryByIdQueryHookResult = ReturnType<typeof useReportingCategoryByIdQuery>;
export type ReportingCategoryByIdLazyQueryHookResult = ReturnType<
    typeof useReportingCategoryByIdLazyQuery
>;
export type ReportingCategoryByIdQueryResult = Apollo.QueryResult<
    ReportingCategoryByIdQuery,
    ReportingCategoryByIdQueryVariables
>;
export const CreateReportingCategoryDocument = gql`
    mutation CreateReportingCategory($input: ReportingCategoryInput!) {
        createReportingCategory(input: $input) {
            status
            message
            success
            resourceCreated {
                id
                name
            }
        }
    }
`;
export type CreateReportingCategoryMutationFn = Apollo.MutationFunction<
    CreateReportingCategoryMutation,
    CreateReportingCategoryMutationVariables
>;

/**
 * __useCreateReportingCategoryMutation__
 *
 * To run a mutation, you first call `useCreateReportingCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportingCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportingCategoryMutation, { data, loading, error }] = useCreateReportingCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReportingCategoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateReportingCategoryMutation,
        CreateReportingCategoryMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateReportingCategoryMutation,
        CreateReportingCategoryMutationVariables
    >(CreateReportingCategoryDocument, options);
}
export type CreateReportingCategoryMutationHookResult = ReturnType<
    typeof useCreateReportingCategoryMutation
>;
export type CreateReportingCategoryMutationResult =
    Apollo.MutationResult<CreateReportingCategoryMutation>;
export type CreateReportingCategoryMutationOptions = Apollo.BaseMutationOptions<
    CreateReportingCategoryMutation,
    CreateReportingCategoryMutationVariables
>;
export const ReportingCategoryListDocument = gql`
    query ReportingCategoryList {
        reportingCategorys {
            id
            name
        }
    }
`;

/**
 * __useReportingCategoryListQuery__
 *
 * To run a query within a React component, call `useReportingCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportingCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingCategoryListQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportingCategoryListQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ReportingCategoryListQuery,
        ReportingCategoryListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ReportingCategoryListQuery, ReportingCategoryListQueryVariables>(
        ReportingCategoryListDocument,
        options,
    );
}
export function useReportingCategoryListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ReportingCategoryListQuery,
        ReportingCategoryListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ReportingCategoryListQuery, ReportingCategoryListQueryVariables>(
        ReportingCategoryListDocument,
        options,
    );
}
export type ReportingCategoryListQueryHookResult = ReturnType<typeof useReportingCategoryListQuery>;
export type ReportingCategoryListLazyQueryHookResult = ReturnType<
    typeof useReportingCategoryListLazyQuery
>;
export type ReportingCategoryListQueryResult = Apollo.QueryResult<
    ReportingCategoryListQuery,
    ReportingCategoryListQueryVariables
>;
export const UpdateReportingCategoryDocument = gql`
    mutation UpdateReportingCategory($updateReportingCategoryInput: UpdateReportingCategoryInput!) {
        updateReportingCategory(input: $updateReportingCategoryInput) {
            status
            message
            success
            resourceUpdated {
                id
                name
            }
        }
    }
`;
export type UpdateReportingCategoryMutationFn = Apollo.MutationFunction<
    UpdateReportingCategoryMutation,
    UpdateReportingCategoryMutationVariables
>;

/**
 * __useUpdateReportingCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateReportingCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportingCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportingCategoryMutation, { data, loading, error }] = useUpdateReportingCategoryMutation({
 *   variables: {
 *      updateReportingCategoryInput: // value for 'updateReportingCategoryInput'
 *   },
 * });
 */
export function useUpdateReportingCategoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateReportingCategoryMutation,
        UpdateReportingCategoryMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateReportingCategoryMutation,
        UpdateReportingCategoryMutationVariables
    >(UpdateReportingCategoryDocument, options);
}
export type UpdateReportingCategoryMutationHookResult = ReturnType<
    typeof useUpdateReportingCategoryMutation
>;
export type UpdateReportingCategoryMutationResult =
    Apollo.MutationResult<UpdateReportingCategoryMutation>;
export type UpdateReportingCategoryMutationOptions = Apollo.BaseMutationOptions<
    UpdateReportingCategoryMutation,
    UpdateReportingCategoryMutationVariables
>;
export const DeleteReportingCategoryDocument = gql`
    mutation DeleteReportingCategory($id: String!) {
        deleteReportingCategory(id: $id) {
            success
            message
            status
            id
        }
    }
`;
export type DeleteReportingCategoryMutationFn = Apollo.MutationFunction<
    DeleteReportingCategoryMutation,
    DeleteReportingCategoryMutationVariables
>;

/**
 * __useDeleteReportingCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteReportingCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportingCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportingCategoryMutation, { data, loading, error }] = useDeleteReportingCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReportingCategoryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteReportingCategoryMutation,
        DeleteReportingCategoryMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteReportingCategoryMutation,
        DeleteReportingCategoryMutationVariables
    >(DeleteReportingCategoryDocument, options);
}
export type DeleteReportingCategoryMutationHookResult = ReturnType<
    typeof useDeleteReportingCategoryMutation
>;
export type DeleteReportingCategoryMutationResult =
    Apollo.MutationResult<DeleteReportingCategoryMutation>;
export type DeleteReportingCategoryMutationOptions = Apollo.BaseMutationOptions<
    DeleteReportingCategoryMutation,
    DeleteReportingCategoryMutationVariables
>;
export const ReportingCategoryWhereUsedDocument = gql`
    query ReportingCategoryWhereUsed($id: String!) {
        reportingCategoryWhereUsed(id: $id) {
            dependencies {
                entityType
                blocksDelete
                total
                entries {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useReportingCategoryWhereUsedQuery__
 *
 * To run a query within a React component, call `useReportingCategoryWhereUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportingCategoryWhereUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingCategoryWhereUsedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportingCategoryWhereUsedQuery(
    baseOptions: Apollo.QueryHookOptions<
        ReportingCategoryWhereUsedQuery,
        ReportingCategoryWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        ReportingCategoryWhereUsedQuery,
        ReportingCategoryWhereUsedQueryVariables
    >(ReportingCategoryWhereUsedDocument, options);
}
export function useReportingCategoryWhereUsedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ReportingCategoryWhereUsedQuery,
        ReportingCategoryWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ReportingCategoryWhereUsedQuery,
        ReportingCategoryWhereUsedQueryVariables
    >(ReportingCategoryWhereUsedDocument, options);
}
export type ReportingCategoryWhereUsedQueryHookResult = ReturnType<
    typeof useReportingCategoryWhereUsedQuery
>;
export type ReportingCategoryWhereUsedLazyQueryHookResult = ReturnType<
    typeof useReportingCategoryWhereUsedLazyQuery
>;
export type ReportingCategoryWhereUsedQueryResult = Apollo.QueryResult<
    ReportingCategoryWhereUsedQuery,
    ReportingCategoryWhereUsedQueryVariables
>;
export const FetchRoleByIdForRoleModalDocument = gql`
    query FetchRoleByIdForRoleModal($input: GetRoleInput!) {
        role(input: $input) {
            ...RoleForRolePage
        }
    }
    ${RoleForRolePageFragmentDoc}
`;

/**
 * __useFetchRoleByIdForRoleModalQuery__
 *
 * To run a query within a React component, call `useFetchRoleByIdForRoleModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRoleByIdForRoleModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRoleByIdForRoleModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchRoleByIdForRoleModalQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchRoleByIdForRoleModalQuery,
        FetchRoleByIdForRoleModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchRoleByIdForRoleModalQuery, FetchRoleByIdForRoleModalQueryVariables>(
        FetchRoleByIdForRoleModalDocument,
        options,
    );
}
export function useFetchRoleByIdForRoleModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchRoleByIdForRoleModalQuery,
        FetchRoleByIdForRoleModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchRoleByIdForRoleModalQuery,
        FetchRoleByIdForRoleModalQueryVariables
    >(FetchRoleByIdForRoleModalDocument, options);
}
export type FetchRoleByIdForRoleModalQueryHookResult = ReturnType<
    typeof useFetchRoleByIdForRoleModalQuery
>;
export type FetchRoleByIdForRoleModalLazyQueryHookResult = ReturnType<
    typeof useFetchRoleByIdForRoleModalLazyQuery
>;
export type FetchRoleByIdForRoleModalQueryResult = Apollo.QueryResult<
    FetchRoleByIdForRoleModalQuery,
    FetchRoleByIdForRoleModalQueryVariables
>;
export const CreateRoleForRoleModalDocument = gql`
    mutation CreateRoleForRoleModal($input: RoleInput!) {
        createRole(input: $input) {
            message
            success
            resourceCreated {
                ...RoleForRolePage
            }
        }
    }
    ${RoleForRolePageFragmentDoc}
`;
export type CreateRoleForRoleModalMutationFn = Apollo.MutationFunction<
    CreateRoleForRoleModalMutation,
    CreateRoleForRoleModalMutationVariables
>;

/**
 * __useCreateRoleForRoleModalMutation__
 *
 * To run a mutation, you first call `useCreateRoleForRoleModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleForRoleModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleForRoleModalMutation, { data, loading, error }] = useCreateRoleForRoleModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleForRoleModalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateRoleForRoleModalMutation,
        CreateRoleForRoleModalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateRoleForRoleModalMutation,
        CreateRoleForRoleModalMutationVariables
    >(CreateRoleForRoleModalDocument, options);
}
export type CreateRoleForRoleModalMutationHookResult = ReturnType<
    typeof useCreateRoleForRoleModalMutation
>;
export type CreateRoleForRoleModalMutationResult =
    Apollo.MutationResult<CreateRoleForRoleModalMutation>;
export type CreateRoleForRoleModalMutationOptions = Apollo.BaseMutationOptions<
    CreateRoleForRoleModalMutation,
    CreateRoleForRoleModalMutationVariables
>;
export const UpdateRoleForRoleModalDocument = gql`
    mutation UpdateRoleForRoleModal($input: UpdateRoleInput!) {
        updateRole(input: $input) {
            message
            success
            resourceUpdated {
                ...RoleForRolePage
            }
        }
    }
    ${RoleForRolePageFragmentDoc}
`;
export type UpdateRoleForRoleModalMutationFn = Apollo.MutationFunction<
    UpdateRoleForRoleModalMutation,
    UpdateRoleForRoleModalMutationVariables
>;

/**
 * __useUpdateRoleForRoleModalMutation__
 *
 * To run a mutation, you first call `useUpdateRoleForRoleModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleForRoleModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleForRoleModalMutation, { data, loading, error }] = useUpdateRoleForRoleModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleForRoleModalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateRoleForRoleModalMutation,
        UpdateRoleForRoleModalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateRoleForRoleModalMutation,
        UpdateRoleForRoleModalMutationVariables
    >(UpdateRoleForRoleModalDocument, options);
}
export type UpdateRoleForRoleModalMutationHookResult = ReturnType<
    typeof useUpdateRoleForRoleModalMutation
>;
export type UpdateRoleForRoleModalMutationResult =
    Apollo.MutationResult<UpdateRoleForRoleModalMutation>;
export type UpdateRoleForRoleModalMutationOptions = Apollo.BaseMutationOptions<
    UpdateRoleForRoleModalMutation,
    UpdateRoleForRoleModalMutationVariables
>;
export const FetchRolesForRolesPageQueryDocument = gql`
    query FetchRolesForRolesPageQuery {
        roles {
            ...RoleForRolePage
        }
    }
    ${RoleForRolePageFragmentDoc}
`;

/**
 * __useFetchRolesForRolesPageQueryQuery__
 *
 * To run a query within a React component, call `useFetchRolesForRolesPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRolesForRolesPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRolesForRolesPageQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchRolesForRolesPageQueryQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchRolesForRolesPageQueryQuery,
        FetchRolesForRolesPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchRolesForRolesPageQueryQuery,
        FetchRolesForRolesPageQueryQueryVariables
    >(FetchRolesForRolesPageQueryDocument, options);
}
export function useFetchRolesForRolesPageQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchRolesForRolesPageQueryQuery,
        FetchRolesForRolesPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchRolesForRolesPageQueryQuery,
        FetchRolesForRolesPageQueryQueryVariables
    >(FetchRolesForRolesPageQueryDocument, options);
}
export type FetchRolesForRolesPageQueryQueryHookResult = ReturnType<
    typeof useFetchRolesForRolesPageQueryQuery
>;
export type FetchRolesForRolesPageQueryLazyQueryHookResult = ReturnType<
    typeof useFetchRolesForRolesPageQueryLazyQuery
>;
export type FetchRolesForRolesPageQueryQueryResult = Apollo.QueryResult<
    FetchRolesForRolesPageQueryQuery,
    FetchRolesForRolesPageQueryQueryVariables
>;
export const OrgListDocument = gql`
    query OrgList {
        organizations {
            ...OrgList
        }
    }
    ${OrgListFragmentDoc}
`;

/**
 * __useOrgListQuery__
 *
 * To run a query within a React component, call `useOrgListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgListQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgListQuery(
    baseOptions?: Apollo.QueryHookOptions<OrgListQuery, OrgListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<OrgListQuery, OrgListQueryVariables>(OrgListDocument, options);
}
export function useOrgListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<OrgListQuery, OrgListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<OrgListQuery, OrgListQueryVariables>(OrgListDocument, options);
}
export type OrgListQueryHookResult = ReturnType<typeof useOrgListQuery>;
export type OrgListLazyQueryHookResult = ReturnType<typeof useOrgListLazyQuery>;
export type OrgListQueryResult = Apollo.QueryResult<OrgListQuery, OrgListQueryVariables>;
export const RoleWhereUsedDocument = gql`
    query RoleWhereUsed($roleId: String!) {
        roleWhereUsed(roleId: $roleId) {
            dependencies {
                entityType
                blocksDelete
                total
                entries {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useRoleWhereUsedQuery__
 *
 * To run a query within a React component, call `useRoleWhereUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleWhereUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleWhereUsedQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useRoleWhereUsedQuery(
    baseOptions: Apollo.QueryHookOptions<RoleWhereUsedQuery, RoleWhereUsedQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<RoleWhereUsedQuery, RoleWhereUsedQueryVariables>(
        RoleWhereUsedDocument,
        options,
    );
}
export function useRoleWhereUsedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<RoleWhereUsedQuery, RoleWhereUsedQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<RoleWhereUsedQuery, RoleWhereUsedQueryVariables>(
        RoleWhereUsedDocument,
        options,
    );
}
export type RoleWhereUsedQueryHookResult = ReturnType<typeof useRoleWhereUsedQuery>;
export type RoleWhereUsedLazyQueryHookResult = ReturnType<typeof useRoleWhereUsedLazyQuery>;
export type RoleWhereUsedQueryResult = Apollo.QueryResult<
    RoleWhereUsedQuery,
    RoleWhereUsedQueryVariables
>;
export const DeleteRoleDocument = gql`
    mutation DeleteRole($input: String!) {
        deleteRole(input: $input) {
            success
            message
        }
    }
`;
export type DeleteRoleMutationFn = Apollo.MutationFunction<
    DeleteRoleMutation,
    DeleteRoleMutationVariables
>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRoleMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(
        DeleteRoleDocument,
        options,
    );
}
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<
    DeleteRoleMutation,
    DeleteRoleMutationVariables
>;
export const CreateSlaForSlaModalDocument = gql`
    mutation CreateSlaForSlaModal($input: SlaInput!) {
        createSla(input: $input) {
            success
            status
            message
            resourceCreated {
                id
                name
                notifyBefore
                responseCalculation
                timeToComplete
                timerType
                type
                updatedAt
                createdAt
                calendarId
                calendar {
                    id
                    name
                }
            }
        }
    }
`;
export type CreateSlaForSlaModalMutationFn = Apollo.MutationFunction<
    CreateSlaForSlaModalMutation,
    CreateSlaForSlaModalMutationVariables
>;

/**
 * __useCreateSlaForSlaModalMutation__
 *
 * To run a mutation, you first call `useCreateSlaForSlaModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSlaForSlaModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSlaForSlaModalMutation, { data, loading, error }] = useCreateSlaForSlaModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSlaForSlaModalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateSlaForSlaModalMutation,
        CreateSlaForSlaModalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateSlaForSlaModalMutation, CreateSlaForSlaModalMutationVariables>(
        CreateSlaForSlaModalDocument,
        options,
    );
}
export type CreateSlaForSlaModalMutationHookResult = ReturnType<
    typeof useCreateSlaForSlaModalMutation
>;
export type CreateSlaForSlaModalMutationResult =
    Apollo.MutationResult<CreateSlaForSlaModalMutation>;
export type CreateSlaForSlaModalMutationOptions = Apollo.BaseMutationOptions<
    CreateSlaForSlaModalMutation,
    CreateSlaForSlaModalMutationVariables
>;
export const UpdateSlaForSlaModalDocument = gql`
    mutation UpdateSlaForSlaModal($input: UpdateSlaInput!) {
        updateSla(input: $input) {
            success
            status
            message
            resourceUpdated {
                id
                name
                notifyBefore
                responseCalculation
                timeToComplete
                timerType
                type
                updatedAt
                createdAt
                calendarId
                calendar {
                    id
                    name
                }
            }
        }
    }
`;
export type UpdateSlaForSlaModalMutationFn = Apollo.MutationFunction<
    UpdateSlaForSlaModalMutation,
    UpdateSlaForSlaModalMutationVariables
>;

/**
 * __useUpdateSlaForSlaModalMutation__
 *
 * To run a mutation, you first call `useUpdateSlaForSlaModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSlaForSlaModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSlaForSlaModalMutation, { data, loading, error }] = useUpdateSlaForSlaModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSlaForSlaModalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateSlaForSlaModalMutation,
        UpdateSlaForSlaModalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateSlaForSlaModalMutation, UpdateSlaForSlaModalMutationVariables>(
        UpdateSlaForSlaModalDocument,
        options,
    );
}
export type UpdateSlaForSlaModalMutationHookResult = ReturnType<
    typeof useUpdateSlaForSlaModalMutation
>;
export type UpdateSlaForSlaModalMutationResult =
    Apollo.MutationResult<UpdateSlaForSlaModalMutation>;
export type UpdateSlaForSlaModalMutationOptions = Apollo.BaseMutationOptions<
    UpdateSlaForSlaModalMutation,
    UpdateSlaForSlaModalMutationVariables
>;
export const CalendarsV2ForSlaModalDocument = gql`
    query CalendarsV2ForSlaModal($input: CalendarQueryInput) {
        calendarsV2(input: $input) {
            results {
                id
                name
            }
        }
    }
`;

/**
 * __useCalendarsV2ForSlaModalQuery__
 *
 * To run a query within a React component, call `useCalendarsV2ForSlaModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarsV2ForSlaModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarsV2ForSlaModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalendarsV2ForSlaModalQuery(
    baseOptions?: Apollo.QueryHookOptions<
        CalendarsV2ForSlaModalQuery,
        CalendarsV2ForSlaModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CalendarsV2ForSlaModalQuery, CalendarsV2ForSlaModalQueryVariables>(
        CalendarsV2ForSlaModalDocument,
        options,
    );
}
export function useCalendarsV2ForSlaModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CalendarsV2ForSlaModalQuery,
        CalendarsV2ForSlaModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CalendarsV2ForSlaModalQuery, CalendarsV2ForSlaModalQueryVariables>(
        CalendarsV2ForSlaModalDocument,
        options,
    );
}
export type CalendarsV2ForSlaModalQueryHookResult = ReturnType<
    typeof useCalendarsV2ForSlaModalQuery
>;
export type CalendarsV2ForSlaModalLazyQueryHookResult = ReturnType<
    typeof useCalendarsV2ForSlaModalLazyQuery
>;
export type CalendarsV2ForSlaModalQueryResult = Apollo.QueryResult<
    CalendarsV2ForSlaModalQuery,
    CalendarsV2ForSlaModalQueryVariables
>;
export const SlasV2ForSlAsPageDocument = gql`
    query SlasV2ForSLAsPage($input: SlaQueryInput) {
        slasV2(input: $input) {
            results {
                id
                name
                calendarId
                calendar {
                    id
                    name
                }
                responseCalculation
                timeToComplete
                timerType
                type
                notifyBefore
                createdAt
                updatedAt
            }
        }
    }
`;

/**
 * __useSlasV2ForSlAsPageQuery__
 *
 * To run a query within a React component, call `useSlasV2ForSlAsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlasV2ForSlAsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlasV2ForSlAsPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSlasV2ForSlAsPageQuery(
    baseOptions?: Apollo.QueryHookOptions<SlasV2ForSlAsPageQuery, SlasV2ForSlAsPageQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SlasV2ForSlAsPageQuery, SlasV2ForSlAsPageQueryVariables>(
        SlasV2ForSlAsPageDocument,
        options,
    );
}
export function useSlasV2ForSlAsPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SlasV2ForSlAsPageQuery,
        SlasV2ForSlAsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SlasV2ForSlAsPageQuery, SlasV2ForSlAsPageQueryVariables>(
        SlasV2ForSlAsPageDocument,
        options,
    );
}
export type SlasV2ForSlAsPageQueryHookResult = ReturnType<typeof useSlasV2ForSlAsPageQuery>;
export type SlasV2ForSlAsPageLazyQueryHookResult = ReturnType<typeof useSlasV2ForSlAsPageLazyQuery>;
export type SlasV2ForSlAsPageQueryResult = Apollo.QueryResult<
    SlasV2ForSlAsPageQuery,
    SlasV2ForSlAsPageQueryVariables
>;
export const SimpleContentByIdDocument = gql`
    query SimpleContentById($input: GetSimpleContentInput!) {
        simpleContent(input: $input) {
            ...SimpleContentFrag
        }
    }
    ${SimpleContentFragFragmentDoc}
`;

/**
 * __useSimpleContentByIdQuery__
 *
 * To run a query within a React component, call `useSimpleContentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleContentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleContentByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSimpleContentByIdQuery(
    baseOptions: Apollo.QueryHookOptions<SimpleContentByIdQuery, SimpleContentByIdQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SimpleContentByIdQuery, SimpleContentByIdQueryVariables>(
        SimpleContentByIdDocument,
        options,
    );
}
export function useSimpleContentByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SimpleContentByIdQuery,
        SimpleContentByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SimpleContentByIdQuery, SimpleContentByIdQueryVariables>(
        SimpleContentByIdDocument,
        options,
    );
}
export type SimpleContentByIdQueryHookResult = ReturnType<typeof useSimpleContentByIdQuery>;
export type SimpleContentByIdLazyQueryHookResult = ReturnType<typeof useSimpleContentByIdLazyQuery>;
export type SimpleContentByIdQueryResult = Apollo.QueryResult<
    SimpleContentByIdQuery,
    SimpleContentByIdQueryVariables
>;
export const CreateSimpleContentDocument = gql`
    mutation CreateSimpleContent($input: SimpleContentInput!) {
        createSimpleContent(input: $input) {
            status
            message
            success
            resourceCreated {
                ...SimpleContentFrag
            }
        }
    }
    ${SimpleContentFragFragmentDoc}
`;
export type CreateSimpleContentMutationFn = Apollo.MutationFunction<
    CreateSimpleContentMutation,
    CreateSimpleContentMutationVariables
>;

/**
 * __useCreateSimpleContentMutation__
 *
 * To run a mutation, you first call `useCreateSimpleContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSimpleContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSimpleContentMutation, { data, loading, error }] = useCreateSimpleContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSimpleContentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateSimpleContentMutation,
        CreateSimpleContentMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateSimpleContentMutation, CreateSimpleContentMutationVariables>(
        CreateSimpleContentDocument,
        options,
    );
}
export type CreateSimpleContentMutationHookResult = ReturnType<
    typeof useCreateSimpleContentMutation
>;
export type CreateSimpleContentMutationResult = Apollo.MutationResult<CreateSimpleContentMutation>;
export type CreateSimpleContentMutationOptions = Apollo.BaseMutationOptions<
    CreateSimpleContentMutation,
    CreateSimpleContentMutationVariables
>;
export const SimpleContentListDocument = gql`
    query SimpleContentList($input: SimpleContentListInput!) {
        simpleContentsV2(input: $input) {
            total
            results {
                ...SimpleContentFrag
            }
        }
    }
    ${SimpleContentFragFragmentDoc}
`;

/**
 * __useSimpleContentListQuery__
 *
 * To run a query within a React component, call `useSimpleContentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleContentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleContentListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSimpleContentListQuery(
    baseOptions: Apollo.QueryHookOptions<SimpleContentListQuery, SimpleContentListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SimpleContentListQuery, SimpleContentListQueryVariables>(
        SimpleContentListDocument,
        options,
    );
}
export function useSimpleContentListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SimpleContentListQuery,
        SimpleContentListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SimpleContentListQuery, SimpleContentListQueryVariables>(
        SimpleContentListDocument,
        options,
    );
}
export type SimpleContentListQueryHookResult = ReturnType<typeof useSimpleContentListQuery>;
export type SimpleContentListLazyQueryHookResult = ReturnType<typeof useSimpleContentListLazyQuery>;
export type SimpleContentListQueryResult = Apollo.QueryResult<
    SimpleContentListQuery,
    SimpleContentListQueryVariables
>;
export const UpdateSimpleContentDocument = gql`
    mutation UpdateSimpleContent($updateSimpleContentInput: UpdateSimpleContentInput!) {
        updateSimpleContent(input: $updateSimpleContentInput) {
            status
            message
            success
            resourceUpdated {
                ...SimpleContentFrag
            }
        }
    }
    ${SimpleContentFragFragmentDoc}
`;
export type UpdateSimpleContentMutationFn = Apollo.MutationFunction<
    UpdateSimpleContentMutation,
    UpdateSimpleContentMutationVariables
>;

/**
 * __useUpdateSimpleContentMutation__
 *
 * To run a mutation, you first call `useUpdateSimpleContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSimpleContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSimpleContentMutation, { data, loading, error }] = useUpdateSimpleContentMutation({
 *   variables: {
 *      updateSimpleContentInput: // value for 'updateSimpleContentInput'
 *   },
 * });
 */
export function useUpdateSimpleContentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateSimpleContentMutation,
        UpdateSimpleContentMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateSimpleContentMutation, UpdateSimpleContentMutationVariables>(
        UpdateSimpleContentDocument,
        options,
    );
}
export type UpdateSimpleContentMutationHookResult = ReturnType<
    typeof useUpdateSimpleContentMutation
>;
export type UpdateSimpleContentMutationResult = Apollo.MutationResult<UpdateSimpleContentMutation>;
export type UpdateSimpleContentMutationOptions = Apollo.BaseMutationOptions<
    UpdateSimpleContentMutation,
    UpdateSimpleContentMutationVariables
>;
export const DeleteSimpleContentDocument = gql`
    mutation DeleteSimpleContent($input: ContentDeleteInput!) {
        deleteSimpleContent(input: $input) {
            status
            message
            success
            id
        }
    }
`;
export type DeleteSimpleContentMutationFn = Apollo.MutationFunction<
    DeleteSimpleContentMutation,
    DeleteSimpleContentMutationVariables
>;

/**
 * __useDeleteSimpleContentMutation__
 *
 * To run a mutation, you first call `useDeleteSimpleContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSimpleContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSimpleContentMutation, { data, loading, error }] = useDeleteSimpleContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSimpleContentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteSimpleContentMutation,
        DeleteSimpleContentMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteSimpleContentMutation, DeleteSimpleContentMutationVariables>(
        DeleteSimpleContentDocument,
        options,
    );
}
export type DeleteSimpleContentMutationHookResult = ReturnType<
    typeof useDeleteSimpleContentMutation
>;
export type DeleteSimpleContentMutationResult = Apollo.MutationResult<DeleteSimpleContentMutation>;
export type DeleteSimpleContentMutationOptions = Apollo.BaseMutationOptions<
    DeleteSimpleContentMutation,
    DeleteSimpleContentMutationVariables
>;
export const SimpleContentWhereUsedDocument = gql`
    query SimpleContentWhereUsed($input: ContentWhereUsedFetchInput!) {
        simpleContentWhereUsed(input: $input) {
            dependencies {
                entityType
                blocksDelete
                total
                entries {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useSimpleContentWhereUsedQuery__
 *
 * To run a query within a React component, call `useSimpleContentWhereUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleContentWhereUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleContentWhereUsedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSimpleContentWhereUsedQuery(
    baseOptions: Apollo.QueryHookOptions<
        SimpleContentWhereUsedQuery,
        SimpleContentWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SimpleContentWhereUsedQuery, SimpleContentWhereUsedQueryVariables>(
        SimpleContentWhereUsedDocument,
        options,
    );
}
export function useSimpleContentWhereUsedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SimpleContentWhereUsedQuery,
        SimpleContentWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SimpleContentWhereUsedQuery, SimpleContentWhereUsedQueryVariables>(
        SimpleContentWhereUsedDocument,
        options,
    );
}
export type SimpleContentWhereUsedQueryHookResult = ReturnType<
    typeof useSimpleContentWhereUsedQuery
>;
export type SimpleContentWhereUsedLazyQueryHookResult = ReturnType<
    typeof useSimpleContentWhereUsedLazyQuery
>;
export type SimpleContentWhereUsedQueryResult = Apollo.QueryResult<
    SimpleContentWhereUsedQuery,
    SimpleContentWhereUsedQueryVariables
>;
export const SelectListsDocument = gql`
    query SelectLists {
        contentDirectorys {
            id
            name
        }
        surveyDefs {
            id
            name
        }
    }
`;

/**
 * __useSelectListsQuery__
 *
 * To run a query within a React component, call `useSelectListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectListsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectListsQuery(
    baseOptions?: Apollo.QueryHookOptions<SelectListsQuery, SelectListsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SelectListsQuery, SelectListsQueryVariables>(
        SelectListsDocument,
        options,
    );
}
export function useSelectListsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SelectListsQuery, SelectListsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SelectListsQuery, SelectListsQueryVariables>(
        SelectListsDocument,
        options,
    );
}
export type SelectListsQueryHookResult = ReturnType<typeof useSelectListsQuery>;
export type SelectListsLazyQueryHookResult = ReturnType<typeof useSelectListsLazyQuery>;
export type SelectListsQueryResult = Apollo.QueryResult<
    SelectListsQuery,
    SelectListsQueryVariables
>;
export const FetchUsersForStaffPageQueryDocument = gql`
    query FetchUsersForStaffPageQuery {
        currentUser {
            id
            name
            email
            users {
                ...UserStaffOnStaffPage
            }
        }
    }
    ${UserStaffOnStaffPageFragmentDoc}
`;

/**
 * __useFetchUsersForStaffPageQueryQuery__
 *
 * To run a query within a React component, call `useFetchUsersForStaffPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUsersForStaffPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUsersForStaffPageQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchUsersForStaffPageQueryQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchUsersForStaffPageQueryQuery,
        FetchUsersForStaffPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchUsersForStaffPageQueryQuery,
        FetchUsersForStaffPageQueryQueryVariables
    >(FetchUsersForStaffPageQueryDocument, options);
}
export function useFetchUsersForStaffPageQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchUsersForStaffPageQueryQuery,
        FetchUsersForStaffPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchUsersForStaffPageQueryQuery,
        FetchUsersForStaffPageQueryQueryVariables
    >(FetchUsersForStaffPageQueryDocument, options);
}
export type FetchUsersForStaffPageQueryQueryHookResult = ReturnType<
    typeof useFetchUsersForStaffPageQueryQuery
>;
export type FetchUsersForStaffPageQueryLazyQueryHookResult = ReturnType<
    typeof useFetchUsersForStaffPageQueryLazyQuery
>;
export type FetchUsersForStaffPageQueryQueryResult = Apollo.QueryResult<
    FetchUsersForStaffPageQueryQuery,
    FetchUsersForStaffPageQueryQueryVariables
>;
export const DeleteUserForStaffPageDocument = gql`
    mutation DeleteUserForStaffPage($input: DeleteUserInput!) {
        deleteUser(input: $input) {
            message
            success
            id
        }
    }
`;
export type DeleteUserForStaffPageMutationFn = Apollo.MutationFunction<
    DeleteUserForStaffPageMutation,
    DeleteUserForStaffPageMutationVariables
>;

/**
 * __useDeleteUserForStaffPageMutation__
 *
 * To run a mutation, you first call `useDeleteUserForStaffPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserForStaffPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserForStaffPageMutation, { data, loading, error }] = useDeleteUserForStaffPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserForStaffPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteUserForStaffPageMutation,
        DeleteUserForStaffPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteUserForStaffPageMutation,
        DeleteUserForStaffPageMutationVariables
    >(DeleteUserForStaffPageDocument, options);
}
export type DeleteUserForStaffPageMutationHookResult = ReturnType<
    typeof useDeleteUserForStaffPageMutation
>;
export type DeleteUserForStaffPageMutationResult =
    Apollo.MutationResult<DeleteUserForStaffPageMutation>;
export type DeleteUserForStaffPageMutationOptions = Apollo.BaseMutationOptions<
    DeleteUserForStaffPageMutation,
    DeleteUserForStaffPageMutationVariables
>;
export const FetchStaffByIdForStaffModalDocument = gql`
    query FetchStaffByIdForStaffModal($input: GetUserInput!) {
        user(input: $input) {
            ...UserStaffOnStaffPage
            affiliateId
        }
    }
    ${UserStaffOnStaffPageFragmentDoc}
`;

/**
 * __useFetchStaffByIdForStaffModalQuery__
 *
 * To run a query within a React component, call `useFetchStaffByIdForStaffModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchStaffByIdForStaffModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchStaffByIdForStaffModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchStaffByIdForStaffModalQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchStaffByIdForStaffModalQuery,
        FetchStaffByIdForStaffModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchStaffByIdForStaffModalQuery,
        FetchStaffByIdForStaffModalQueryVariables
    >(FetchStaffByIdForStaffModalDocument, options);
}
export function useFetchStaffByIdForStaffModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchStaffByIdForStaffModalQuery,
        FetchStaffByIdForStaffModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchStaffByIdForStaffModalQuery,
        FetchStaffByIdForStaffModalQueryVariables
    >(FetchStaffByIdForStaffModalDocument, options);
}
export type FetchStaffByIdForStaffModalQueryHookResult = ReturnType<
    typeof useFetchStaffByIdForStaffModalQuery
>;
export type FetchStaffByIdForStaffModalLazyQueryHookResult = ReturnType<
    typeof useFetchStaffByIdForStaffModalLazyQuery
>;
export type FetchStaffByIdForStaffModalQueryResult = Apollo.QueryResult<
    FetchStaffByIdForStaffModalQuery,
    FetchStaffByIdForStaffModalQueryVariables
>;
export const FetchCurrentUserForStaffModalDocument = gql`
    query FetchCurrentUserForStaffModal {
        currentUser {
            id
            name
            email
            title
            smsNumber
            clientOrgId
            numberOfUnreadChatMessages
            isTestData
            currentOrg {
                id
                parentId
                brandingName
            }
            currentOrganizations {
                id
                parentId
                brandingName
            }
        }
    }
`;

/**
 * __useFetchCurrentUserForStaffModalQuery__
 *
 * To run a query within a React component, call `useFetchCurrentUserForStaffModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCurrentUserForStaffModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCurrentUserForStaffModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCurrentUserForStaffModalQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchCurrentUserForStaffModalQuery,
        FetchCurrentUserForStaffModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchCurrentUserForStaffModalQuery,
        FetchCurrentUserForStaffModalQueryVariables
    >(FetchCurrentUserForStaffModalDocument, options);
}
export function useFetchCurrentUserForStaffModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchCurrentUserForStaffModalQuery,
        FetchCurrentUserForStaffModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchCurrentUserForStaffModalQuery,
        FetchCurrentUserForStaffModalQueryVariables
    >(FetchCurrentUserForStaffModalDocument, options);
}
export type FetchCurrentUserForStaffModalQueryHookResult = ReturnType<
    typeof useFetchCurrentUserForStaffModalQuery
>;
export type FetchCurrentUserForStaffModalLazyQueryHookResult = ReturnType<
    typeof useFetchCurrentUserForStaffModalLazyQuery
>;
export type FetchCurrentUserForStaffModalQueryResult = Apollo.QueryResult<
    FetchCurrentUserForStaffModalQuery,
    FetchCurrentUserForStaffModalQueryVariables
>;
export const FetchRolesForStaffModalQueryDocument = gql`
    query FetchRolesForStaffModalQuery {
        roles {
            id
            organizationId
            name
        }
    }
`;

/**
 * __useFetchRolesForStaffModalQueryQuery__
 *
 * To run a query within a React component, call `useFetchRolesForStaffModalQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRolesForStaffModalQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRolesForStaffModalQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchRolesForStaffModalQueryQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchRolesForStaffModalQueryQuery,
        FetchRolesForStaffModalQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchRolesForStaffModalQueryQuery,
        FetchRolesForStaffModalQueryQueryVariables
    >(FetchRolesForStaffModalQueryDocument, options);
}
export function useFetchRolesForStaffModalQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchRolesForStaffModalQueryQuery,
        FetchRolesForStaffModalQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchRolesForStaffModalQueryQuery,
        FetchRolesForStaffModalQueryQueryVariables
    >(FetchRolesForStaffModalQueryDocument, options);
}
export type FetchRolesForStaffModalQueryQueryHookResult = ReturnType<
    typeof useFetchRolesForStaffModalQueryQuery
>;
export type FetchRolesForStaffModalQueryLazyQueryHookResult = ReturnType<
    typeof useFetchRolesForStaffModalQueryLazyQuery
>;
export type FetchRolesForStaffModalQueryQueryResult = Apollo.QueryResult<
    FetchRolesForStaffModalQueryQuery,
    FetchRolesForStaffModalQueryQueryVariables
>;
export const UpdateStaffInStaffModalDocument = gql`
    mutation UpdateStaffInStaffModal($input: UpdateUserInput!) {
        updateUser(input: $input) {
            success
            message
            resourceUpdated {
                ...UserStaffOnStaffPage
            }
        }
    }
    ${UserStaffOnStaffPageFragmentDoc}
`;
export type UpdateStaffInStaffModalMutationFn = Apollo.MutationFunction<
    UpdateStaffInStaffModalMutation,
    UpdateStaffInStaffModalMutationVariables
>;

/**
 * __useUpdateStaffInStaffModalMutation__
 *
 * To run a mutation, you first call `useUpdateStaffInStaffModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaffInStaffModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaffInStaffModalMutation, { data, loading, error }] = useUpdateStaffInStaffModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStaffInStaffModalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateStaffInStaffModalMutation,
        UpdateStaffInStaffModalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateStaffInStaffModalMutation,
        UpdateStaffInStaffModalMutationVariables
    >(UpdateStaffInStaffModalDocument, options);
}
export type UpdateStaffInStaffModalMutationHookResult = ReturnType<
    typeof useUpdateStaffInStaffModalMutation
>;
export type UpdateStaffInStaffModalMutationResult =
    Apollo.MutationResult<UpdateStaffInStaffModalMutation>;
export type UpdateStaffInStaffModalMutationOptions = Apollo.BaseMutationOptions<
    UpdateStaffInStaffModalMutation,
    UpdateStaffInStaffModalMutationVariables
>;
export const CreateStaffInStaffModalDocument = gql`
    mutation CreateStaffInStaffModal($input: UserInput!) {
        createUser(input: $input) {
            success
            message
            user {
                ...UserStaffOnStaffPage
            }
        }
    }
    ${UserStaffOnStaffPageFragmentDoc}
`;
export type CreateStaffInStaffModalMutationFn = Apollo.MutationFunction<
    CreateStaffInStaffModalMutation,
    CreateStaffInStaffModalMutationVariables
>;

/**
 * __useCreateStaffInStaffModalMutation__
 *
 * To run a mutation, you first call `useCreateStaffInStaffModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStaffInStaffModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStaffInStaffModalMutation, { data, loading, error }] = useCreateStaffInStaffModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStaffInStaffModalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateStaffInStaffModalMutation,
        CreateStaffInStaffModalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateStaffInStaffModalMutation,
        CreateStaffInStaffModalMutationVariables
    >(CreateStaffInStaffModalDocument, options);
}
export type CreateStaffInStaffModalMutationHookResult = ReturnType<
    typeof useCreateStaffInStaffModalMutation
>;
export type CreateStaffInStaffModalMutationResult =
    Apollo.MutationResult<CreateStaffInStaffModalMutation>;
export type CreateStaffInStaffModalMutationOptions = Apollo.BaseMutationOptions<
    CreateStaffInStaffModalMutation,
    CreateStaffInStaffModalMutationVariables
>;
export const StaffVirtualCareAffiliatessV2Document = gql`
    query StaffVirtualCareAffiliatessV2($input: VirtualCareAffiliatesQueryInput) {
        virtualCareAffiliatessV2(input: $input) {
            results {
                affiliateId
                externalName
                id
            }
            total
        }
    }
`;

/**
 * __useStaffVirtualCareAffiliatessV2Query__
 *
 * To run a query within a React component, call `useStaffVirtualCareAffiliatessV2Query` and pass it any options that fit your needs.
 * When your component renders, `useStaffVirtualCareAffiliatessV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffVirtualCareAffiliatessV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStaffVirtualCareAffiliatessV2Query(
    baseOptions?: Apollo.QueryHookOptions<
        StaffVirtualCareAffiliatessV2Query,
        StaffVirtualCareAffiliatessV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        StaffVirtualCareAffiliatessV2Query,
        StaffVirtualCareAffiliatessV2QueryVariables
    >(StaffVirtualCareAffiliatessV2Document, options);
}
export function useStaffVirtualCareAffiliatessV2LazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        StaffVirtualCareAffiliatessV2Query,
        StaffVirtualCareAffiliatessV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        StaffVirtualCareAffiliatessV2Query,
        StaffVirtualCareAffiliatessV2QueryVariables
    >(StaffVirtualCareAffiliatessV2Document, options);
}
export type StaffVirtualCareAffiliatessV2QueryHookResult = ReturnType<
    typeof useStaffVirtualCareAffiliatessV2Query
>;
export type StaffVirtualCareAffiliatessV2LazyQueryHookResult = ReturnType<
    typeof useStaffVirtualCareAffiliatessV2LazyQuery
>;
export type StaffVirtualCareAffiliatessV2QueryResult = Apollo.QueryResult<
    StaffVirtualCareAffiliatessV2Query,
    StaffVirtualCareAffiliatessV2QueryVariables
>;
export const SurveyDefListDocument = gql`
    query SurveyDefList {
        surveyDefs {
            id
            name
            createdAt
            updatedAt
            surveyType
            disableRestart
            restartFromBeginning
            requiresAdvocateReview
            shouldScore
            label {
                en
            }
            divisions {
                id
            }
            organization {
                id
                brandingName
            }
        }
    }
`;

/**
 * __useSurveyDefListQuery__
 *
 * To run a query within a React component, call `useSurveyDefListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyDefListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyDefListQuery({
 *   variables: {
 *   },
 * });
 */
export function useSurveyDefListQuery(
    baseOptions?: Apollo.QueryHookOptions<SurveyDefListQuery, SurveyDefListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SurveyDefListQuery, SurveyDefListQueryVariables>(
        SurveyDefListDocument,
        options,
    );
}
export function useSurveyDefListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SurveyDefListQuery, SurveyDefListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SurveyDefListQuery, SurveyDefListQueryVariables>(
        SurveyDefListDocument,
        options,
    );
}
export type SurveyDefListQueryHookResult = ReturnType<typeof useSurveyDefListQuery>;
export type SurveyDefListLazyQueryHookResult = ReturnType<typeof useSurveyDefListLazyQuery>;
export type SurveyDefListQueryResult = Apollo.QueryResult<
    SurveyDefListQuery,
    SurveyDefListQueryVariables
>;
export const FetchSurveyDefForSurveyBuilderDocument = gql`
    query FetchSurveyDefForSurveyBuilder($surveyDefInput: GetSurveyDefInput!) {
        surveyDef(input: $surveyDefInput) {
            ...SurveyDefForSurveyBuilder
        }
    }
    ${SurveyDefForSurveyBuilderFragmentDoc}
`;

/**
 * __useFetchSurveyDefForSurveyBuilderQuery__
 *
 * To run a query within a React component, call `useFetchSurveyDefForSurveyBuilderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSurveyDefForSurveyBuilderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSurveyDefForSurveyBuilderQuery({
 *   variables: {
 *      surveyDefInput: // value for 'surveyDefInput'
 *   },
 * });
 */
export function useFetchSurveyDefForSurveyBuilderQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchSurveyDefForSurveyBuilderQuery,
        FetchSurveyDefForSurveyBuilderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchSurveyDefForSurveyBuilderQuery,
        FetchSurveyDefForSurveyBuilderQueryVariables
    >(FetchSurveyDefForSurveyBuilderDocument, options);
}
export function useFetchSurveyDefForSurveyBuilderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchSurveyDefForSurveyBuilderQuery,
        FetchSurveyDefForSurveyBuilderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchSurveyDefForSurveyBuilderQuery,
        FetchSurveyDefForSurveyBuilderQueryVariables
    >(FetchSurveyDefForSurveyBuilderDocument, options);
}
export type FetchSurveyDefForSurveyBuilderQueryHookResult = ReturnType<
    typeof useFetchSurveyDefForSurveyBuilderQuery
>;
export type FetchSurveyDefForSurveyBuilderLazyQueryHookResult = ReturnType<
    typeof useFetchSurveyDefForSurveyBuilderLazyQuery
>;
export type FetchSurveyDefForSurveyBuilderQueryResult = Apollo.QueryResult<
    FetchSurveyDefForSurveyBuilderQuery,
    FetchSurveyDefForSurveyBuilderQueryVariables
>;
export const UpdateSurveyDefForSurveyBuilderDocument = gql`
    mutation UpdateSurveyDefForSurveyBuilder($surveyDefUpdateInput: UpdateSurveyDefInput!) {
        updateSurveyDef(input: $surveyDefUpdateInput) {
            success
            message
            resourceUpdated {
                ...SurveyDefForSurveyBuilder
            }
        }
    }
    ${SurveyDefForSurveyBuilderFragmentDoc}
`;
export type UpdateSurveyDefForSurveyBuilderMutationFn = Apollo.MutationFunction<
    UpdateSurveyDefForSurveyBuilderMutation,
    UpdateSurveyDefForSurveyBuilderMutationVariables
>;

/**
 * __useUpdateSurveyDefForSurveyBuilderMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyDefForSurveyBuilderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyDefForSurveyBuilderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyDefForSurveyBuilderMutation, { data, loading, error }] = useUpdateSurveyDefForSurveyBuilderMutation({
 *   variables: {
 *      surveyDefUpdateInput: // value for 'surveyDefUpdateInput'
 *   },
 * });
 */
export function useUpdateSurveyDefForSurveyBuilderMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateSurveyDefForSurveyBuilderMutation,
        UpdateSurveyDefForSurveyBuilderMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateSurveyDefForSurveyBuilderMutation,
        UpdateSurveyDefForSurveyBuilderMutationVariables
    >(UpdateSurveyDefForSurveyBuilderDocument, options);
}
export type UpdateSurveyDefForSurveyBuilderMutationHookResult = ReturnType<
    typeof useUpdateSurveyDefForSurveyBuilderMutation
>;
export type UpdateSurveyDefForSurveyBuilderMutationResult =
    Apollo.MutationResult<UpdateSurveyDefForSurveyBuilderMutation>;
export type UpdateSurveyDefForSurveyBuilderMutationOptions = Apollo.BaseMutationOptions<
    UpdateSurveyDefForSurveyBuilderMutation,
    UpdateSurveyDefForSurveyBuilderMutationVariables
>;
export const CreateSurveyDefForSurveyBuilderDocument = gql`
    mutation CreateSurveyDefForSurveyBuilder($createSurveyDefInput: SurveyDefInput!) {
        createSurveyDef(input: $createSurveyDefInput) {
            success
            message
            resourceCreated {
                ...SurveyDefForSurveyBuilder
            }
        }
    }
    ${SurveyDefForSurveyBuilderFragmentDoc}
`;
export type CreateSurveyDefForSurveyBuilderMutationFn = Apollo.MutationFunction<
    CreateSurveyDefForSurveyBuilderMutation,
    CreateSurveyDefForSurveyBuilderMutationVariables
>;

/**
 * __useCreateSurveyDefForSurveyBuilderMutation__
 *
 * To run a mutation, you first call `useCreateSurveyDefForSurveyBuilderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyDefForSurveyBuilderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyDefForSurveyBuilderMutation, { data, loading, error }] = useCreateSurveyDefForSurveyBuilderMutation({
 *   variables: {
 *      createSurveyDefInput: // value for 'createSurveyDefInput'
 *   },
 * });
 */
export function useCreateSurveyDefForSurveyBuilderMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateSurveyDefForSurveyBuilderMutation,
        CreateSurveyDefForSurveyBuilderMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateSurveyDefForSurveyBuilderMutation,
        CreateSurveyDefForSurveyBuilderMutationVariables
    >(CreateSurveyDefForSurveyBuilderDocument, options);
}
export type CreateSurveyDefForSurveyBuilderMutationHookResult = ReturnType<
    typeof useCreateSurveyDefForSurveyBuilderMutation
>;
export type CreateSurveyDefForSurveyBuilderMutationResult =
    Apollo.MutationResult<CreateSurveyDefForSurveyBuilderMutation>;
export type CreateSurveyDefForSurveyBuilderMutationOptions = Apollo.BaseMutationOptions<
    CreateSurveyDefForSurveyBuilderMutation,
    CreateSurveyDefForSurveyBuilderMutationVariables
>;
export const PreFetchOrgsProfileDefsForSurveyBuilderDocument = gql`
    query PreFetchOrgsProfileDefsForSurveyBuilder {
        userProfileDefs {
            id
            name
            valueType
            label {
                en
            }
            questionLabel {
                en
            }
            choices {
                id
                label {
                    en
                }
                answerLabel {
                    en
                }
            }
            surveyQuestionDisabled
        }
        organizations {
            id
            brandingName
        }
    }
`;

/**
 * __usePreFetchOrgsProfileDefsForSurveyBuilderQuery__
 *
 * To run a query within a React component, call `usePreFetchOrgsProfileDefsForSurveyBuilderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreFetchOrgsProfileDefsForSurveyBuilderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreFetchOrgsProfileDefsForSurveyBuilderQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreFetchOrgsProfileDefsForSurveyBuilderQuery(
    baseOptions?: Apollo.QueryHookOptions<
        PreFetchOrgsProfileDefsForSurveyBuilderQuery,
        PreFetchOrgsProfileDefsForSurveyBuilderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        PreFetchOrgsProfileDefsForSurveyBuilderQuery,
        PreFetchOrgsProfileDefsForSurveyBuilderQueryVariables
    >(PreFetchOrgsProfileDefsForSurveyBuilderDocument, options);
}
export function usePreFetchOrgsProfileDefsForSurveyBuilderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PreFetchOrgsProfileDefsForSurveyBuilderQuery,
        PreFetchOrgsProfileDefsForSurveyBuilderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        PreFetchOrgsProfileDefsForSurveyBuilderQuery,
        PreFetchOrgsProfileDefsForSurveyBuilderQueryVariables
    >(PreFetchOrgsProfileDefsForSurveyBuilderDocument, options);
}
export type PreFetchOrgsProfileDefsForSurveyBuilderQueryHookResult = ReturnType<
    typeof usePreFetchOrgsProfileDefsForSurveyBuilderQuery
>;
export type PreFetchOrgsProfileDefsForSurveyBuilderLazyQueryHookResult = ReturnType<
    typeof usePreFetchOrgsProfileDefsForSurveyBuilderLazyQuery
>;
export type PreFetchOrgsProfileDefsForSurveyBuilderQueryResult = Apollo.QueryResult<
    PreFetchOrgsProfileDefsForSurveyBuilderQuery,
    PreFetchOrgsProfileDefsForSurveyBuilderQueryVariables
>;
export const DeleteSurveyDefDocument = gql`
    mutation DeleteSurveyDef($id: String!) {
        deleteSurveyDef(id: $id) {
            success
            message
            status
            id
        }
    }
`;
export type DeleteSurveyDefMutationFn = Apollo.MutationFunction<
    DeleteSurveyDefMutation,
    DeleteSurveyDefMutationVariables
>;

/**
 * __useDeleteSurveyDefMutation__
 *
 * To run a mutation, you first call `useDeleteSurveyDefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveyDefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveyDefMutation, { data, loading, error }] = useDeleteSurveyDefMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSurveyDefMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteSurveyDefMutation,
        DeleteSurveyDefMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteSurveyDefMutation, DeleteSurveyDefMutationVariables>(
        DeleteSurveyDefDocument,
        options,
    );
}
export type DeleteSurveyDefMutationHookResult = ReturnType<typeof useDeleteSurveyDefMutation>;
export type DeleteSurveyDefMutationResult = Apollo.MutationResult<DeleteSurveyDefMutation>;
export type DeleteSurveyDefMutationOptions = Apollo.BaseMutationOptions<
    DeleteSurveyDefMutation,
    DeleteSurveyDefMutationVariables
>;
export const SurveyDefWhereUsedDocument = gql`
    query SurveyDefWhereUsed($id: String!) {
        surveyDefWhereUsed(id: $id) {
            dependencies {
                entityType
                blocksDelete
                total
                entries {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useSurveyDefWhereUsedQuery__
 *
 * To run a query within a React component, call `useSurveyDefWhereUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyDefWhereUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyDefWhereUsedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSurveyDefWhereUsedQuery(
    baseOptions: Apollo.QueryHookOptions<SurveyDefWhereUsedQuery, SurveyDefWhereUsedQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SurveyDefWhereUsedQuery, SurveyDefWhereUsedQueryVariables>(
        SurveyDefWhereUsedDocument,
        options,
    );
}
export function useSurveyDefWhereUsedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SurveyDefWhereUsedQuery,
        SurveyDefWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SurveyDefWhereUsedQuery, SurveyDefWhereUsedQueryVariables>(
        SurveyDefWhereUsedDocument,
        options,
    );
}
export type SurveyDefWhereUsedQueryHookResult = ReturnType<typeof useSurveyDefWhereUsedQuery>;
export type SurveyDefWhereUsedLazyQueryHookResult = ReturnType<
    typeof useSurveyDefWhereUsedLazyQuery
>;
export type SurveyDefWhereUsedQueryResult = Apollo.QueryResult<
    SurveyDefWhereUsedQuery,
    SurveyDefWhereUsedQueryVariables
>;
export const SurveyDefViewerDocument = gql`
    query SurveyDefViewer($id: String!) {
        surveyDefViewer(surveyId: $id) {
            raw
        }
    }
`;

/**
 * __useSurveyDefViewerQuery__
 *
 * To run a query within a React component, call `useSurveyDefViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyDefViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyDefViewerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSurveyDefViewerQuery(
    baseOptions: Apollo.QueryHookOptions<SurveyDefViewerQuery, SurveyDefViewerQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SurveyDefViewerQuery, SurveyDefViewerQueryVariables>(
        SurveyDefViewerDocument,
        options,
    );
}
export function useSurveyDefViewerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SurveyDefViewerQuery, SurveyDefViewerQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SurveyDefViewerQuery, SurveyDefViewerQueryVariables>(
        SurveyDefViewerDocument,
        options,
    );
}
export type SurveyDefViewerQueryHookResult = ReturnType<typeof useSurveyDefViewerQuery>;
export type SurveyDefViewerLazyQueryHookResult = ReturnType<typeof useSurveyDefViewerLazyQuery>;
export type SurveyDefViewerQueryResult = Apollo.QueryResult<
    SurveyDefViewerQuery,
    SurveyDefViewerQueryVariables
>;
export const SurveyDefsWithoutSubSurveysDocument = gql`
    query SurveyDefsWithoutSubSurveys {
        surveyDefsWithoutSubSurveys {
            id
            name
        }
    }
`;

/**
 * __useSurveyDefsWithoutSubSurveysQuery__
 *
 * To run a query within a React component, call `useSurveyDefsWithoutSubSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyDefsWithoutSubSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyDefsWithoutSubSurveysQuery({
 *   variables: {
 *   },
 * });
 */
export function useSurveyDefsWithoutSubSurveysQuery(
    baseOptions?: Apollo.QueryHookOptions<
        SurveyDefsWithoutSubSurveysQuery,
        SurveyDefsWithoutSubSurveysQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        SurveyDefsWithoutSubSurveysQuery,
        SurveyDefsWithoutSubSurveysQueryVariables
    >(SurveyDefsWithoutSubSurveysDocument, options);
}
export function useSurveyDefsWithoutSubSurveysLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SurveyDefsWithoutSubSurveysQuery,
        SurveyDefsWithoutSubSurveysQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        SurveyDefsWithoutSubSurveysQuery,
        SurveyDefsWithoutSubSurveysQueryVariables
    >(SurveyDefsWithoutSubSurveysDocument, options);
}
export type SurveyDefsWithoutSubSurveysQueryHookResult = ReturnType<
    typeof useSurveyDefsWithoutSubSurveysQuery
>;
export type SurveyDefsWithoutSubSurveysLazyQueryHookResult = ReturnType<
    typeof useSurveyDefsWithoutSubSurveysLazyQuery
>;
export type SurveyDefsWithoutSubSurveysQueryResult = Apollo.QueryResult<
    SurveyDefsWithoutSubSurveysQuery,
    SurveyDefsWithoutSubSurveysQueryVariables
>;
export const ParentSurveysDefsDocument = gql`
    query ParentSurveysDefs($surveyId: String!) {
        parentSurveysDefs(id: $surveyId) {
            id
            name
        }
    }
`;

/**
 * __useParentSurveysDefsQuery__
 *
 * To run a query within a React component, call `useParentSurveysDefsQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentSurveysDefsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentSurveysDefsQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useParentSurveysDefsQuery(
    baseOptions: Apollo.QueryHookOptions<ParentSurveysDefsQuery, ParentSurveysDefsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ParentSurveysDefsQuery, ParentSurveysDefsQueryVariables>(
        ParentSurveysDefsDocument,
        options,
    );
}
export function useParentSurveysDefsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ParentSurveysDefsQuery,
        ParentSurveysDefsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ParentSurveysDefsQuery, ParentSurveysDefsQueryVariables>(
        ParentSurveysDefsDocument,
        options,
    );
}
export type ParentSurveysDefsQueryHookResult = ReturnType<typeof useParentSurveysDefsQuery>;
export type ParentSurveysDefsLazyQueryHookResult = ReturnType<typeof useParentSurveysDefsLazyQuery>;
export type ParentSurveysDefsQueryResult = Apollo.QueryResult<
    ParentSurveysDefsQuery,
    ParentSurveysDefsQueryVariables
>;
export const CompoundQuestionsV2ForSurveyDocument = gql`
    query CompoundQuestionsV2ForSurvey($input: CompoundQuestionsListInput) {
        compoundQuestionsV2(input: $input) {
            results {
                ...CompoundQuestionFragment
            }
        }
    }
    ${CompoundQuestionFragmentFragmentDoc}
`;

/**
 * __useCompoundQuestionsV2ForSurveyQuery__
 *
 * To run a query within a React component, call `useCompoundQuestionsV2ForSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompoundQuestionsV2ForSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompoundQuestionsV2ForSurveyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompoundQuestionsV2ForSurveyQuery(
    baseOptions?: Apollo.QueryHookOptions<
        CompoundQuestionsV2ForSurveyQuery,
        CompoundQuestionsV2ForSurveyQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        CompoundQuestionsV2ForSurveyQuery,
        CompoundQuestionsV2ForSurveyQueryVariables
    >(CompoundQuestionsV2ForSurveyDocument, options);
}
export function useCompoundQuestionsV2ForSurveyLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CompoundQuestionsV2ForSurveyQuery,
        CompoundQuestionsV2ForSurveyQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        CompoundQuestionsV2ForSurveyQuery,
        CompoundQuestionsV2ForSurveyQueryVariables
    >(CompoundQuestionsV2ForSurveyDocument, options);
}
export type CompoundQuestionsV2ForSurveyQueryHookResult = ReturnType<
    typeof useCompoundQuestionsV2ForSurveyQuery
>;
export type CompoundQuestionsV2ForSurveyLazyQueryHookResult = ReturnType<
    typeof useCompoundQuestionsV2ForSurveyLazyQuery
>;
export type CompoundQuestionsV2ForSurveyQueryResult = Apollo.QueryResult<
    CompoundQuestionsV2ForSurveyQuery,
    CompoundQuestionsV2ForSurveyQueryVariables
>;
export const FetchTagDocument = gql`
    query FetchTag($tagInput: GetTagInput!) {
        tag(input: $tagInput) {
            ...TagForTagBuilder
        }
    }
    ${TagForTagBuilderFragmentDoc}
`;

/**
 * __useFetchTagQuery__
 *
 * To run a query within a React component, call `useFetchTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTagQuery({
 *   variables: {
 *      tagInput: // value for 'tagInput'
 *   },
 * });
 */
export function useFetchTagQuery(
    baseOptions: Apollo.QueryHookOptions<FetchTagQuery, FetchTagQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchTagQuery, FetchTagQueryVariables>(FetchTagDocument, options);
}
export function useFetchTagLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<FetchTagQuery, FetchTagQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchTagQuery, FetchTagQueryVariables>(FetchTagDocument, options);
}
export type FetchTagQueryHookResult = ReturnType<typeof useFetchTagQuery>;
export type FetchTagLazyQueryHookResult = ReturnType<typeof useFetchTagLazyQuery>;
export type FetchTagQueryResult = Apollo.QueryResult<FetchTagQuery, FetchTagQueryVariables>;
export const UpdateTagDocument = gql`
    mutation UpdateTag($updateTagInput: UpdateTagInput!) {
        updateTag(input: $updateTagInput) {
            success
            message
            resourceUpdated {
                ...TagForTagBuilder
            }
        }
    }
    ${TagForTagBuilderFragmentDoc}
`;
export type UpdateTagMutationFn = Apollo.MutationFunction<
    UpdateTagMutation,
    UpdateTagMutationVariables
>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      updateTagInput: // value for 'updateTagInput'
 *   },
 * });
 */
export function useUpdateTagMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(
        UpdateTagDocument,
        options,
    );
}
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<
    UpdateTagMutation,
    UpdateTagMutationVariables
>;
export const CreateTagDocument = gql`
    mutation CreateTag($createTagInput: TagInput!) {
        createTag(input: $createTagInput) {
            success
            message
            resourceCreated {
                ...TagForTagBuilder
            }
        }
    }
    ${TagForTagBuilderFragmentDoc}
`;
export type CreateTagMutationFn = Apollo.MutationFunction<
    CreateTagMutation,
    CreateTagMutationVariables
>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      createTagInput: // value for 'createTagInput'
 *   },
 * });
 */
export function useCreateTagMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(
        CreateTagDocument,
        options,
    );
}
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<
    CreateTagMutation,
    CreateTagMutationVariables
>;
export const TagListDocument = gql`
    query TagList($tagListInput: [TagUsageType!]) {
        getTagListByUsageTypes(input: $tagListInput) {
            id
            createdAt
            updatedAt
            name
            usageType
            expression {
                id
                name
            }
            includeApps
            excludeApps
            appInfo
            isPatientFilter
            patientRiskScorePoints
        }
    }
`;

/**
 * __useTagListQuery__
 *
 * To run a query within a React component, call `useTagListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagListQuery({
 *   variables: {
 *      tagListInput: // value for 'tagListInput'
 *   },
 * });
 */
export function useTagListQuery(
    baseOptions?: Apollo.QueryHookOptions<TagListQuery, TagListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<TagListQuery, TagListQueryVariables>(TagListDocument, options);
}
export function useTagListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<TagListQuery, TagListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<TagListQuery, TagListQueryVariables>(TagListDocument, options);
}
export type TagListQueryHookResult = ReturnType<typeof useTagListQuery>;
export type TagListLazyQueryHookResult = ReturnType<typeof useTagListLazyQuery>;
export type TagListQueryResult = Apollo.QueryResult<TagListQuery, TagListQueryVariables>;
export const PreFetchAppsDocument = gql`
    query PreFetchApps {
        amsApps {
            id
            bundleId
            productConst
        }
    }
`;

/**
 * __usePreFetchAppsQuery__
 *
 * To run a query within a React component, call `usePreFetchAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreFetchAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreFetchAppsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreFetchAppsQuery(
    baseOptions?: Apollo.QueryHookOptions<PreFetchAppsQuery, PreFetchAppsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PreFetchAppsQuery, PreFetchAppsQueryVariables>(
        PreFetchAppsDocument,
        options,
    );
}
export function usePreFetchAppsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PreFetchAppsQuery, PreFetchAppsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PreFetchAppsQuery, PreFetchAppsQueryVariables>(
        PreFetchAppsDocument,
        options,
    );
}
export type PreFetchAppsQueryHookResult = ReturnType<typeof usePreFetchAppsQuery>;
export type PreFetchAppsLazyQueryHookResult = ReturnType<typeof usePreFetchAppsLazyQuery>;
export type PreFetchAppsQueryResult = Apollo.QueryResult<
    PreFetchAppsQuery,
    PreFetchAppsQueryVariables
>;
export const TagWhereUsedDocument = gql`
    query TagWhereUsed($input: TagWhereUsedFetchInput!) {
        tagWhereUsed(input: $input) {
            dependencies {
                entityType
                blocksDelete
                total
                entries {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useTagWhereUsedQuery__
 *
 * To run a query within a React component, call `useTagWhereUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagWhereUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagWhereUsedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTagWhereUsedQuery(
    baseOptions: Apollo.QueryHookOptions<TagWhereUsedQuery, TagWhereUsedQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<TagWhereUsedQuery, TagWhereUsedQueryVariables>(
        TagWhereUsedDocument,
        options,
    );
}
export function useTagWhereUsedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<TagWhereUsedQuery, TagWhereUsedQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<TagWhereUsedQuery, TagWhereUsedQueryVariables>(
        TagWhereUsedDocument,
        options,
    );
}
export type TagWhereUsedQueryHookResult = ReturnType<typeof useTagWhereUsedQuery>;
export type TagWhereUsedLazyQueryHookResult = ReturnType<typeof useTagWhereUsedLazyQuery>;
export type TagWhereUsedQueryResult = Apollo.QueryResult<
    TagWhereUsedQuery,
    TagWhereUsedQueryVariables
>;
export const TimelineViewerFetchDocument = gql`
    query TimelineViewerFetch($input: TimelineViewerFetchInput!) {
        timelineViewerFetch(input: $input) {
            results {
                timelineId
                type
                itemId
                groupId
                templateId
                offsetDays
                offsetMonths
                offsetYears
                duration
                durationTimeUnit
                offsetSequence
                contentDescription
                includeApps
                excludeApps
                tags
                effectiveDate
                expiryDate
                priority
                notificationText
            }
            total
        }
    }
`;

/**
 * __useTimelineViewerFetchQuery__
 *
 * To run a query within a React component, call `useTimelineViewerFetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelineViewerFetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelineViewerFetchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTimelineViewerFetchQuery(
    baseOptions: Apollo.QueryHookOptions<
        TimelineViewerFetchQuery,
        TimelineViewerFetchQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<TimelineViewerFetchQuery, TimelineViewerFetchQueryVariables>(
        TimelineViewerFetchDocument,
        options,
    );
}
export function useTimelineViewerFetchLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        TimelineViewerFetchQuery,
        TimelineViewerFetchQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<TimelineViewerFetchQuery, TimelineViewerFetchQueryVariables>(
        TimelineViewerFetchDocument,
        options,
    );
}
export type TimelineViewerFetchQueryHookResult = ReturnType<typeof useTimelineViewerFetchQuery>;
export type TimelineViewerFetchLazyQueryHookResult = ReturnType<
    typeof useTimelineViewerFetchLazyQuery
>;
export type TimelineViewerFetchQueryResult = Apollo.QueryResult<
    TimelineViewerFetchQuery,
    TimelineViewerFetchQueryVariables
>;
export const UpdateCarePlanOffsetsDocument = gql`
    mutation UpdateCarePlanOffsets($input: UpdateCarePlanOffsetsInput!) {
        updateCarePlanOffsets(input: $input) {
            success
            message
            resourceUpdated {
                ...CarePlanFragment
            }
        }
    }
    ${CarePlanFragmentFragmentDoc}
`;
export type UpdateCarePlanOffsetsMutationFn = Apollo.MutationFunction<
    UpdateCarePlanOffsetsMutation,
    UpdateCarePlanOffsetsMutationVariables
>;

/**
 * __useUpdateCarePlanOffsetsMutation__
 *
 * To run a mutation, you first call `useUpdateCarePlanOffsetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCarePlanOffsetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCarePlanOffsetsMutation, { data, loading, error }] = useUpdateCarePlanOffsetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCarePlanOffsetsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateCarePlanOffsetsMutation,
        UpdateCarePlanOffsetsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateCarePlanOffsetsMutation,
        UpdateCarePlanOffsetsMutationVariables
    >(UpdateCarePlanOffsetsDocument, options);
}
export type UpdateCarePlanOffsetsMutationHookResult = ReturnType<
    typeof useUpdateCarePlanOffsetsMutation
>;
export type UpdateCarePlanOffsetsMutationResult =
    Apollo.MutationResult<UpdateCarePlanOffsetsMutation>;
export type UpdateCarePlanOffsetsMutationOptions = Apollo.BaseMutationOptions<
    UpdateCarePlanOffsetsMutation,
    UpdateCarePlanOffsetsMutationVariables
>;
export const PreFetchProfileDefsForWhatsNewTimelineBuilderDocument = gql`
    query PreFetchProfileDefsForWhatsNewTimelineBuilder($input: UserProfileDefQueryInput!) {
        userProfileDefsV2(input: $input) {
            results {
                id
                label {
                    en
                }
            }
        }
    }
`;

/**
 * __usePreFetchProfileDefsForWhatsNewTimelineBuilderQuery__
 *
 * To run a query within a React component, call `usePreFetchProfileDefsForWhatsNewTimelineBuilderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreFetchProfileDefsForWhatsNewTimelineBuilderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreFetchProfileDefsForWhatsNewTimelineBuilderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePreFetchProfileDefsForWhatsNewTimelineBuilderQuery(
    baseOptions: Apollo.QueryHookOptions<
        PreFetchProfileDefsForWhatsNewTimelineBuilderQuery,
        PreFetchProfileDefsForWhatsNewTimelineBuilderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        PreFetchProfileDefsForWhatsNewTimelineBuilderQuery,
        PreFetchProfileDefsForWhatsNewTimelineBuilderQueryVariables
    >(PreFetchProfileDefsForWhatsNewTimelineBuilderDocument, options);
}
export function usePreFetchProfileDefsForWhatsNewTimelineBuilderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PreFetchProfileDefsForWhatsNewTimelineBuilderQuery,
        PreFetchProfileDefsForWhatsNewTimelineBuilderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        PreFetchProfileDefsForWhatsNewTimelineBuilderQuery,
        PreFetchProfileDefsForWhatsNewTimelineBuilderQueryVariables
    >(PreFetchProfileDefsForWhatsNewTimelineBuilderDocument, options);
}
export type PreFetchProfileDefsForWhatsNewTimelineBuilderQueryHookResult = ReturnType<
    typeof usePreFetchProfileDefsForWhatsNewTimelineBuilderQuery
>;
export type PreFetchProfileDefsForWhatsNewTimelineBuilderLazyQueryHookResult = ReturnType<
    typeof usePreFetchProfileDefsForWhatsNewTimelineBuilderLazyQuery
>;
export type PreFetchProfileDefsForWhatsNewTimelineBuilderQueryResult = Apollo.QueryResult<
    PreFetchProfileDefsForWhatsNewTimelineBuilderQuery,
    PreFetchProfileDefsForWhatsNewTimelineBuilderQueryVariables
>;
export const FetchWhatsNewTimelineForWhatsNewTimelineBuilderDocument = gql`
    query FetchWhatsNewTimelineForWhatsNewTimelineBuilder(
        $whatsNewTimelineInput: GetWhatsNewTimelineInput!
    ) {
        whatsNewTimeline(input: $whatsNewTimelineInput) {
            ...WhatsNewTimelineForWhatsNewTimelineBuilder
        }
    }
    ${WhatsNewTimelineForWhatsNewTimelineBuilderFragmentDoc}
`;

/**
 * __useFetchWhatsNewTimelineForWhatsNewTimelineBuilderQuery__
 *
 * To run a query within a React component, call `useFetchWhatsNewTimelineForWhatsNewTimelineBuilderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchWhatsNewTimelineForWhatsNewTimelineBuilderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchWhatsNewTimelineForWhatsNewTimelineBuilderQuery({
 *   variables: {
 *      whatsNewTimelineInput: // value for 'whatsNewTimelineInput'
 *   },
 * });
 */
export function useFetchWhatsNewTimelineForWhatsNewTimelineBuilderQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchWhatsNewTimelineForWhatsNewTimelineBuilderQuery,
        FetchWhatsNewTimelineForWhatsNewTimelineBuilderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchWhatsNewTimelineForWhatsNewTimelineBuilderQuery,
        FetchWhatsNewTimelineForWhatsNewTimelineBuilderQueryVariables
    >(FetchWhatsNewTimelineForWhatsNewTimelineBuilderDocument, options);
}
export function useFetchWhatsNewTimelineForWhatsNewTimelineBuilderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchWhatsNewTimelineForWhatsNewTimelineBuilderQuery,
        FetchWhatsNewTimelineForWhatsNewTimelineBuilderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchWhatsNewTimelineForWhatsNewTimelineBuilderQuery,
        FetchWhatsNewTimelineForWhatsNewTimelineBuilderQueryVariables
    >(FetchWhatsNewTimelineForWhatsNewTimelineBuilderDocument, options);
}
export type FetchWhatsNewTimelineForWhatsNewTimelineBuilderQueryHookResult = ReturnType<
    typeof useFetchWhatsNewTimelineForWhatsNewTimelineBuilderQuery
>;
export type FetchWhatsNewTimelineForWhatsNewTimelineBuilderLazyQueryHookResult = ReturnType<
    typeof useFetchWhatsNewTimelineForWhatsNewTimelineBuilderLazyQuery
>;
export type FetchWhatsNewTimelineForWhatsNewTimelineBuilderQueryResult = Apollo.QueryResult<
    FetchWhatsNewTimelineForWhatsNewTimelineBuilderQuery,
    FetchWhatsNewTimelineForWhatsNewTimelineBuilderQueryVariables
>;
export const UpdateWhatsNewTimelineForWhatsNewTimelineBuilderDocument = gql`
    mutation UpdateWhatsNewTimelineForWhatsNewTimelineBuilder(
        $whatsNewTimelineUpdateInput: UpdateWhatsNewTimelineInput!
    ) {
        updateWhatsNewTimeline(input: $whatsNewTimelineUpdateInput) {
            success
            message
            timeline {
                ...WhatsNewTimelineForWhatsNewTimelineBuilder
            }
        }
    }
    ${WhatsNewTimelineForWhatsNewTimelineBuilderFragmentDoc}
`;
export type UpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutationFn = Apollo.MutationFunction<
    UpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutation,
    UpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutationVariables
>;

/**
 * __useUpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutation__
 *
 * To run a mutation, you first call `useUpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWhatsNewTimelineForWhatsNewTimelineBuilderMutation, { data, loading, error }] = useUpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutation({
 *   variables: {
 *      whatsNewTimelineUpdateInput: // value for 'whatsNewTimelineUpdateInput'
 *   },
 * });
 */
export function useUpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutation,
        UpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutation,
        UpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutationVariables
    >(UpdateWhatsNewTimelineForWhatsNewTimelineBuilderDocument, options);
}
export type UpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutationHookResult = ReturnType<
    typeof useUpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutation
>;
export type UpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutationResult =
    Apollo.MutationResult<UpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutation>;
export type UpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutationOptions =
    Apollo.BaseMutationOptions<
        UpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutation,
        UpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutationVariables
    >;
export const CreateWhatsNewTimelineForWhatsNewTimelineBuilderDocument = gql`
    mutation CreateWhatsNewTimelineForWhatsNewTimelineBuilder(
        $createWhatsNewTimelineInput: WhatsNewTimelineInput!
    ) {
        createWhatsNewTimeline(input: $createWhatsNewTimelineInput) {
            success
            message
            timeline {
                ...WhatsNewTimelineForWhatsNewTimelineBuilder
            }
        }
    }
    ${WhatsNewTimelineForWhatsNewTimelineBuilderFragmentDoc}
`;
export type CreateWhatsNewTimelineForWhatsNewTimelineBuilderMutationFn = Apollo.MutationFunction<
    CreateWhatsNewTimelineForWhatsNewTimelineBuilderMutation,
    CreateWhatsNewTimelineForWhatsNewTimelineBuilderMutationVariables
>;

/**
 * __useCreateWhatsNewTimelineForWhatsNewTimelineBuilderMutation__
 *
 * To run a mutation, you first call `useCreateWhatsNewTimelineForWhatsNewTimelineBuilderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWhatsNewTimelineForWhatsNewTimelineBuilderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWhatsNewTimelineForWhatsNewTimelineBuilderMutation, { data, loading, error }] = useCreateWhatsNewTimelineForWhatsNewTimelineBuilderMutation({
 *   variables: {
 *      createWhatsNewTimelineInput: // value for 'createWhatsNewTimelineInput'
 *   },
 * });
 */
export function useCreateWhatsNewTimelineForWhatsNewTimelineBuilderMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateWhatsNewTimelineForWhatsNewTimelineBuilderMutation,
        CreateWhatsNewTimelineForWhatsNewTimelineBuilderMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateWhatsNewTimelineForWhatsNewTimelineBuilderMutation,
        CreateWhatsNewTimelineForWhatsNewTimelineBuilderMutationVariables
    >(CreateWhatsNewTimelineForWhatsNewTimelineBuilderDocument, options);
}
export type CreateWhatsNewTimelineForWhatsNewTimelineBuilderMutationHookResult = ReturnType<
    typeof useCreateWhatsNewTimelineForWhatsNewTimelineBuilderMutation
>;
export type CreateWhatsNewTimelineForWhatsNewTimelineBuilderMutationResult =
    Apollo.MutationResult<CreateWhatsNewTimelineForWhatsNewTimelineBuilderMutation>;
export type CreateWhatsNewTimelineForWhatsNewTimelineBuilderMutationOptions =
    Apollo.BaseMutationOptions<
        CreateWhatsNewTimelineForWhatsNewTimelineBuilderMutation,
        CreateWhatsNewTimelineForWhatsNewTimelineBuilderMutationVariables
    >;
export const WhatsNewTimelinesForWhatsNewTimelinesPageDocument = gql`
    query WhatsNewTimelinesForWhatsNewTimelinesPage {
        whatsNewTimelinesV2 {
            results {
                id
                label {
                    en
                }
                type
            }
        }
    }
`;

/**
 * __useWhatsNewTimelinesForWhatsNewTimelinesPageQuery__
 *
 * To run a query within a React component, call `useWhatsNewTimelinesForWhatsNewTimelinesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhatsNewTimelinesForWhatsNewTimelinesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhatsNewTimelinesForWhatsNewTimelinesPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhatsNewTimelinesForWhatsNewTimelinesPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        WhatsNewTimelinesForWhatsNewTimelinesPageQuery,
        WhatsNewTimelinesForWhatsNewTimelinesPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        WhatsNewTimelinesForWhatsNewTimelinesPageQuery,
        WhatsNewTimelinesForWhatsNewTimelinesPageQueryVariables
    >(WhatsNewTimelinesForWhatsNewTimelinesPageDocument, options);
}
export function useWhatsNewTimelinesForWhatsNewTimelinesPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        WhatsNewTimelinesForWhatsNewTimelinesPageQuery,
        WhatsNewTimelinesForWhatsNewTimelinesPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        WhatsNewTimelinesForWhatsNewTimelinesPageQuery,
        WhatsNewTimelinesForWhatsNewTimelinesPageQueryVariables
    >(WhatsNewTimelinesForWhatsNewTimelinesPageDocument, options);
}
export type WhatsNewTimelinesForWhatsNewTimelinesPageQueryHookResult = ReturnType<
    typeof useWhatsNewTimelinesForWhatsNewTimelinesPageQuery
>;
export type WhatsNewTimelinesForWhatsNewTimelinesPageLazyQueryHookResult = ReturnType<
    typeof useWhatsNewTimelinesForWhatsNewTimelinesPageLazyQuery
>;
export type WhatsNewTimelinesForWhatsNewTimelinesPageQueryResult = Apollo.QueryResult<
    WhatsNewTimelinesForWhatsNewTimelinesPageQuery,
    WhatsNewTimelinesForWhatsNewTimelinesPageQueryVariables
>;
export const DeleteTimelineDocument = gql`
    mutation DeleteTimeline($id: String!) {
        deleteTimeline(id: $id) {
            message
            success
            id
        }
    }
`;
export type DeleteTimelineMutationFn = Apollo.MutationFunction<
    DeleteTimelineMutation,
    DeleteTimelineMutationVariables
>;

/**
 * __useDeleteTimelineMutation__
 *
 * To run a mutation, you first call `useDeleteTimelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimelineMutation, { data, loading, error }] = useDeleteTimelineMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTimelineMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteTimelineMutation,
        DeleteTimelineMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteTimelineMutation, DeleteTimelineMutationVariables>(
        DeleteTimelineDocument,
        options,
    );
}
export type DeleteTimelineMutationHookResult = ReturnType<typeof useDeleteTimelineMutation>;
export type DeleteTimelineMutationResult = Apollo.MutationResult<DeleteTimelineMutation>;
export type DeleteTimelineMutationOptions = Apollo.BaseMutationOptions<
    DeleteTimelineMutation,
    DeleteTimelineMutationVariables
>;
export const TimelineWhereUsedDocument = gql`
    query TimelineWhereUsed($id: String!) {
        timelineWhereUsed(id: $id) {
            dependencies {
                entityType
                blocksDelete
                total
                entries {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useTimelineWhereUsedQuery__
 *
 * To run a query within a React component, call `useTimelineWhereUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelineWhereUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelineWhereUsedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTimelineWhereUsedQuery(
    baseOptions: Apollo.QueryHookOptions<TimelineWhereUsedQuery, TimelineWhereUsedQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<TimelineWhereUsedQuery, TimelineWhereUsedQueryVariables>(
        TimelineWhereUsedDocument,
        options,
    );
}
export function useTimelineWhereUsedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        TimelineWhereUsedQuery,
        TimelineWhereUsedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<TimelineWhereUsedQuery, TimelineWhereUsedQueryVariables>(
        TimelineWhereUsedDocument,
        options,
    );
}
export type TimelineWhereUsedQueryHookResult = ReturnType<typeof useTimelineWhereUsedQuery>;
export type TimelineWhereUsedLazyQueryHookResult = ReturnType<typeof useTimelineWhereUsedLazyQuery>;
export type TimelineWhereUsedQueryResult = Apollo.QueryResult<
    TimelineWhereUsedQuery,
    TimelineWhereUsedQueryVariables
>;
export const TokenByIdDocument = gql`
    query TokenById($input: GetTokenInput!) {
        token(input: $input) {
            id
            name
            description
            replacements
        }
    }
`;

/**
 * __useTokenByIdQuery__
 *
 * To run a query within a React component, call `useTokenByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTokenByIdQuery(
    baseOptions: Apollo.QueryHookOptions<TokenByIdQuery, TokenByIdQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<TokenByIdQuery, TokenByIdQueryVariables>(TokenByIdDocument, options);
}
export function useTokenByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<TokenByIdQuery, TokenByIdQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<TokenByIdQuery, TokenByIdQueryVariables>(TokenByIdDocument, options);
}
export type TokenByIdQueryHookResult = ReturnType<typeof useTokenByIdQuery>;
export type TokenByIdLazyQueryHookResult = ReturnType<typeof useTokenByIdLazyQuery>;
export type TokenByIdQueryResult = Apollo.QueryResult<TokenByIdQuery, TokenByIdQueryVariables>;
export const CreateTokenDocument = gql`
    mutation CreateToken($input: TokenInput!) {
        createToken(input: $input) {
            status
            message
            success
            resourceCreated {
                id
                name
                description
                replacements
            }
        }
    }
`;
export type CreateTokenMutationFn = Apollo.MutationFunction<
    CreateTokenMutation,
    CreateTokenMutationVariables
>;

/**
 * __useCreateTokenMutation__
 *
 * To run a mutation, you first call `useCreateTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTokenMutation, { data, loading, error }] = useCreateTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTokenMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateTokenMutation, CreateTokenMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateTokenMutation, CreateTokenMutationVariables>(
        CreateTokenDocument,
        options,
    );
}
export type CreateTokenMutationHookResult = ReturnType<typeof useCreateTokenMutation>;
export type CreateTokenMutationResult = Apollo.MutationResult<CreateTokenMutation>;
export type CreateTokenMutationOptions = Apollo.BaseMutationOptions<
    CreateTokenMutation,
    CreateTokenMutationVariables
>;
export const TokenListDocument = gql`
    query TokenList {
        tokens {
            id
            name
            description
            replacements
        }
    }
`;

/**
 * __useTokenListQuery__
 *
 * To run a query within a React component, call `useTokenListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenListQuery({
 *   variables: {
 *   },
 * });
 */
export function useTokenListQuery(
    baseOptions?: Apollo.QueryHookOptions<TokenListQuery, TokenListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<TokenListQuery, TokenListQueryVariables>(TokenListDocument, options);
}
export function useTokenListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<TokenListQuery, TokenListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<TokenListQuery, TokenListQueryVariables>(TokenListDocument, options);
}
export type TokenListQueryHookResult = ReturnType<typeof useTokenListQuery>;
export type TokenListLazyQueryHookResult = ReturnType<typeof useTokenListLazyQuery>;
export type TokenListQueryResult = Apollo.QueryResult<TokenListQuery, TokenListQueryVariables>;
export const UpdateTokenDocument = gql`
    mutation UpdateToken($updateTokenInput: UpdateTokenInput!) {
        updateToken(input: $updateTokenInput) {
            status
            message
            success
            resourceUpdated {
                id
                name
                description
                replacements
            }
        }
    }
`;
export type UpdateTokenMutationFn = Apollo.MutationFunction<
    UpdateTokenMutation,
    UpdateTokenMutationVariables
>;

/**
 * __useUpdateTokenMutation__
 *
 * To run a mutation, you first call `useUpdateTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTokenMutation, { data, loading, error }] = useUpdateTokenMutation({
 *   variables: {
 *      updateTokenInput: // value for 'updateTokenInput'
 *   },
 * });
 */
export function useUpdateTokenMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateTokenMutation, UpdateTokenMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateTokenMutation, UpdateTokenMutationVariables>(
        UpdateTokenDocument,
        options,
    );
}
export type UpdateTokenMutationHookResult = ReturnType<typeof useUpdateTokenMutation>;
export type UpdateTokenMutationResult = Apollo.MutationResult<UpdateTokenMutation>;
export type UpdateTokenMutationOptions = Apollo.BaseMutationOptions<
    UpdateTokenMutation,
    UpdateTokenMutationVariables
>;
export const DeleteTagDocument = gql`
    mutation DeleteTag($input: DeleteTagInput!) {
        deleteTag(input: $input) {
            success
            message
            status
            id
        }
    }
`;
export type DeleteTagMutationFn = Apollo.MutationFunction<
    DeleteTagMutation,
    DeleteTagMutationVariables
>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTagMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(
        DeleteTagDocument,
        options,
    );
}
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<
    DeleteTagMutation,
    DeleteTagMutationVariables
>;
export const PreFetchInterventionDataDocument = gql`
    query PreFetchInterventionData {
        amsApps {
            id
            bundleId
            productConst
        }
        userProfileDefs {
            ...UserProfileDefFragment
        }
        conditionTypes {
            id
            name {
                en
                es
            }
        }
        messageCenterTemplates {
            id
            title
        }
        messageTemplatesV2 {
            results {
                name
                id
            }
        }
    }
    ${UserProfileDefFragmentFragmentDoc}
`;

/**
 * __usePreFetchInterventionDataQuery__
 *
 * To run a query within a React component, call `usePreFetchInterventionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreFetchInterventionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreFetchInterventionDataQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreFetchInterventionDataQuery(
    baseOptions?: Apollo.QueryHookOptions<
        PreFetchInterventionDataQuery,
        PreFetchInterventionDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PreFetchInterventionDataQuery, PreFetchInterventionDataQueryVariables>(
        PreFetchInterventionDataDocument,
        options,
    );
}
export function usePreFetchInterventionDataLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PreFetchInterventionDataQuery,
        PreFetchInterventionDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        PreFetchInterventionDataQuery,
        PreFetchInterventionDataQueryVariables
    >(PreFetchInterventionDataDocument, options);
}
export type PreFetchInterventionDataQueryHookResult = ReturnType<
    typeof usePreFetchInterventionDataQuery
>;
export type PreFetchInterventionDataLazyQueryHookResult = ReturnType<
    typeof usePreFetchInterventionDataLazyQuery
>;
export type PreFetchInterventionDataQueryResult = Apollo.QueryResult<
    PreFetchInterventionDataQuery,
    PreFetchInterventionDataQueryVariables
>;
export const ProfileDefsListDocument = gql`
    query ProfileDefsList {
        userProfileDefs {
            ...UserProfileDefFragment
        }
    }
    ${UserProfileDefFragmentFragmentDoc}
`;

/**
 * __useProfileDefsListQuery__
 *
 * To run a query within a React component, call `useProfileDefsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileDefsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileDefsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileDefsListQuery(
    baseOptions?: Apollo.QueryHookOptions<ProfileDefsListQuery, ProfileDefsListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ProfileDefsListQuery, ProfileDefsListQueryVariables>(
        ProfileDefsListDocument,
        options,
    );
}
export function useProfileDefsListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ProfileDefsListQuery, ProfileDefsListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ProfileDefsListQuery, ProfileDefsListQueryVariables>(
        ProfileDefsListDocument,
        options,
    );
}
export type ProfileDefsListQueryHookResult = ReturnType<typeof useProfileDefsListQuery>;
export type ProfileDefsListLazyQueryHookResult = ReturnType<typeof useProfileDefsListLazyQuery>;
export type ProfileDefsListQueryResult = Apollo.QueryResult<
    ProfileDefsListQuery,
    ProfileDefsListQueryVariables
>;
export const FetchInterventionDocument = gql`
    query FetchIntervention($interventionInput: GetInterventionInput!) {
        intervention(input: $interventionInput) {
            ...InterventionFragment
        }
    }
    ${InterventionFragmentFragmentDoc}
`;

/**
 * __useFetchInterventionQuery__
 *
 * To run a query within a React component, call `useFetchInterventionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchInterventionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchInterventionQuery({
 *   variables: {
 *      interventionInput: // value for 'interventionInput'
 *   },
 * });
 */
export function useFetchInterventionQuery(
    baseOptions: Apollo.QueryHookOptions<FetchInterventionQuery, FetchInterventionQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchInterventionQuery, FetchInterventionQueryVariables>(
        FetchInterventionDocument,
        options,
    );
}
export function useFetchInterventionLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchInterventionQuery,
        FetchInterventionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchInterventionQuery, FetchInterventionQueryVariables>(
        FetchInterventionDocument,
        options,
    );
}
export type FetchInterventionQueryHookResult = ReturnType<typeof useFetchInterventionQuery>;
export type FetchInterventionLazyQueryHookResult = ReturnType<typeof useFetchInterventionLazyQuery>;
export type FetchInterventionQueryResult = Apollo.QueryResult<
    FetchInterventionQuery,
    FetchInterventionQueryVariables
>;
export const UpdateInterventionDocument = gql`
    mutation UpdateIntervention($updateInterventionInput: UpdateInterventionInput!) {
        updateIntervention(input: $updateInterventionInput) {
            success
            message
            resourceUpdated {
                ...InterventionFragment
            }
        }
    }
    ${InterventionFragmentFragmentDoc}
`;
export type UpdateInterventionMutationFn = Apollo.MutationFunction<
    UpdateInterventionMutation,
    UpdateInterventionMutationVariables
>;

/**
 * __useUpdateInterventionMutation__
 *
 * To run a mutation, you first call `useUpdateInterventionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterventionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterventionMutation, { data, loading, error }] = useUpdateInterventionMutation({
 *   variables: {
 *      updateInterventionInput: // value for 'updateInterventionInput'
 *   },
 * });
 */
export function useUpdateInterventionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateInterventionMutation,
        UpdateInterventionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateInterventionMutation, UpdateInterventionMutationVariables>(
        UpdateInterventionDocument,
        options,
    );
}
export type UpdateInterventionMutationHookResult = ReturnType<typeof useUpdateInterventionMutation>;
export type UpdateInterventionMutationResult = Apollo.MutationResult<UpdateInterventionMutation>;
export type UpdateInterventionMutationOptions = Apollo.BaseMutationOptions<
    UpdateInterventionMutation,
    UpdateInterventionMutationVariables
>;
export const CreateInterventionDocument = gql`
    mutation CreateIntervention($createInterventionInput: InterventionInput!) {
        createIntervention(input: $createInterventionInput) {
            status
            message
            success
            resourceCreated {
                ...InterventionFragment
            }
        }
    }
    ${InterventionFragmentFragmentDoc}
`;
export type CreateInterventionMutationFn = Apollo.MutationFunction<
    CreateInterventionMutation,
    CreateInterventionMutationVariables
>;

/**
 * __useCreateInterventionMutation__
 *
 * To run a mutation, you first call `useCreateInterventionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInterventionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInterventionMutation, { data, loading, error }] = useCreateInterventionMutation({
 *   variables: {
 *      createInterventionInput: // value for 'createInterventionInput'
 *   },
 * });
 */
export function useCreateInterventionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateInterventionMutation,
        CreateInterventionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateInterventionMutation, CreateInterventionMutationVariables>(
        CreateInterventionDocument,
        options,
    );
}
export type CreateInterventionMutationHookResult = ReturnType<typeof useCreateInterventionMutation>;
export type CreateInterventionMutationResult = Apollo.MutationResult<CreateInterventionMutation>;
export type CreateInterventionMutationOptions = Apollo.BaseMutationOptions<
    CreateInterventionMutation,
    CreateInterventionMutationVariables
>;
export const InterventionListDocument = gql`
    query InterventionList($interventionListInput: InterventionQueryInput!) {
        interventionsV2(input: $interventionListInput) {
            orderBy
            total
            skip
            limit
            hasMore
            hasPrevious
            results {
                ...InterventionFragment
            }
        }
    }
    ${InterventionFragmentFragmentDoc}
`;

/**
 * __useInterventionListQuery__
 *
 * To run a query within a React component, call `useInterventionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterventionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterventionListQuery({
 *   variables: {
 *      interventionListInput: // value for 'interventionListInput'
 *   },
 * });
 */
export function useInterventionListQuery(
    baseOptions: Apollo.QueryHookOptions<InterventionListQuery, InterventionListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<InterventionListQuery, InterventionListQueryVariables>(
        InterventionListDocument,
        options,
    );
}
export function useInterventionListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        InterventionListQuery,
        InterventionListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<InterventionListQuery, InterventionListQueryVariables>(
        InterventionListDocument,
        options,
    );
}
export type InterventionListQueryHookResult = ReturnType<typeof useInterventionListQuery>;
export type InterventionListLazyQueryHookResult = ReturnType<typeof useInterventionListLazyQuery>;
export type InterventionListQueryResult = Apollo.QueryResult<
    InterventionListQuery,
    InterventionListQueryVariables
>;
export const DeleteInterventionDocument = gql`
    mutation DeleteIntervention($input: DeleteInterventionInput!) {
        deleteIntervention(input: $input) {
            success
            message
            status
            id
        }
    }
`;
export type DeleteInterventionMutationFn = Apollo.MutationFunction<
    DeleteInterventionMutation,
    DeleteInterventionMutationVariables
>;

/**
 * __useDeleteInterventionMutation__
 *
 * To run a mutation, you first call `useDeleteInterventionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInterventionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInterventionMutation, { data, loading, error }] = useDeleteInterventionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInterventionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteInterventionMutation,
        DeleteInterventionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteInterventionMutation, DeleteInterventionMutationVariables>(
        DeleteInterventionDocument,
        options,
    );
}
export type DeleteInterventionMutationHookResult = ReturnType<typeof useDeleteInterventionMutation>;
export type DeleteInterventionMutationResult = Apollo.MutationResult<DeleteInterventionMutation>;
export type DeleteInterventionMutationOptions = Apollo.BaseMutationOptions<
    DeleteInterventionMutation,
    DeleteInterventionMutationVariables
>;
export const FetchAdvocateTaskTemplatesForInterventionDocument = gql`
    query FetchAdvocateTaskTemplatesForIntervention($input: AdvocateTaskTemplateQueryInput!) {
        advocateTaskTemplatesV2(input: $input) {
            results {
                id
                label
            }
        }
    }
`;

/**
 * __useFetchAdvocateTaskTemplatesForInterventionQuery__
 *
 * To run a query within a React component, call `useFetchAdvocateTaskTemplatesForInterventionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAdvocateTaskTemplatesForInterventionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAdvocateTaskTemplatesForInterventionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchAdvocateTaskTemplatesForInterventionQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchAdvocateTaskTemplatesForInterventionQuery,
        FetchAdvocateTaskTemplatesForInterventionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchAdvocateTaskTemplatesForInterventionQuery,
        FetchAdvocateTaskTemplatesForInterventionQueryVariables
    >(FetchAdvocateTaskTemplatesForInterventionDocument, options);
}
export function useFetchAdvocateTaskTemplatesForInterventionLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchAdvocateTaskTemplatesForInterventionQuery,
        FetchAdvocateTaskTemplatesForInterventionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchAdvocateTaskTemplatesForInterventionQuery,
        FetchAdvocateTaskTemplatesForInterventionQueryVariables
    >(FetchAdvocateTaskTemplatesForInterventionDocument, options);
}
export type FetchAdvocateTaskTemplatesForInterventionQueryHookResult = ReturnType<
    typeof useFetchAdvocateTaskTemplatesForInterventionQuery
>;
export type FetchAdvocateTaskTemplatesForInterventionLazyQueryHookResult = ReturnType<
    typeof useFetchAdvocateTaskTemplatesForInterventionLazyQuery
>;
export type FetchAdvocateTaskTemplatesForInterventionQueryResult = Apollo.QueryResult<
    FetchAdvocateTaskTemplatesForInterventionQuery,
    FetchAdvocateTaskTemplatesForInterventionQueryVariables
>;
export const FetchMessageCenterTemplatesForInterventionDocument = gql`
    query FetchMessageCenterTemplatesForIntervention {
        messageCenterTemplates {
            id
            title
        }
    }
`;

/**
 * __useFetchMessageCenterTemplatesForInterventionQuery__
 *
 * To run a query within a React component, call `useFetchMessageCenterTemplatesForInterventionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMessageCenterTemplatesForInterventionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMessageCenterTemplatesForInterventionQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMessageCenterTemplatesForInterventionQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchMessageCenterTemplatesForInterventionQuery,
        FetchMessageCenterTemplatesForInterventionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchMessageCenterTemplatesForInterventionQuery,
        FetchMessageCenterTemplatesForInterventionQueryVariables
    >(FetchMessageCenterTemplatesForInterventionDocument, options);
}
export function useFetchMessageCenterTemplatesForInterventionLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchMessageCenterTemplatesForInterventionQuery,
        FetchMessageCenterTemplatesForInterventionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchMessageCenterTemplatesForInterventionQuery,
        FetchMessageCenterTemplatesForInterventionQueryVariables
    >(FetchMessageCenterTemplatesForInterventionDocument, options);
}
export type FetchMessageCenterTemplatesForInterventionQueryHookResult = ReturnType<
    typeof useFetchMessageCenterTemplatesForInterventionQuery
>;
export type FetchMessageCenterTemplatesForInterventionLazyQueryHookResult = ReturnType<
    typeof useFetchMessageCenterTemplatesForInterventionLazyQuery
>;
export type FetchMessageCenterTemplatesForInterventionQueryResult = Apollo.QueryResult<
    FetchMessageCenterTemplatesForInterventionQuery,
    FetchMessageCenterTemplatesForInterventionQueryVariables
>;
export const FetchDateTriggerDocument = gql`
    query FetchDateTrigger($input: GetDateTriggerInput!) {
        dateTrigger(input: $input) {
            id
            name
            offsetDays
            offsetMonths
            offsetSequence
            offsetYears
            gracePeriod
            tagIds
            timelineId
            createdAt
            updatedAt
        }
    }
`;

/**
 * __useFetchDateTriggerQuery__
 *
 * To run a query within a React component, call `useFetchDateTriggerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDateTriggerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDateTriggerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchDateTriggerQuery(
    baseOptions: Apollo.QueryHookOptions<FetchDateTriggerQuery, FetchDateTriggerQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchDateTriggerQuery, FetchDateTriggerQueryVariables>(
        FetchDateTriggerDocument,
        options,
    );
}
export function useFetchDateTriggerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchDateTriggerQuery,
        FetchDateTriggerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchDateTriggerQuery, FetchDateTriggerQueryVariables>(
        FetchDateTriggerDocument,
        options,
    );
}
export type FetchDateTriggerQueryHookResult = ReturnType<typeof useFetchDateTriggerQuery>;
export type FetchDateTriggerLazyQueryHookResult = ReturnType<typeof useFetchDateTriggerLazyQuery>;
export type FetchDateTriggerQueryResult = Apollo.QueryResult<
    FetchDateTriggerQuery,
    FetchDateTriggerQueryVariables
>;
export const UpdateDateTriggerDocument = gql`
    mutation UpdateDateTrigger($input: UpdateDateTriggerInput!) {
        updateDateTrigger(input: $input) {
            success
            message
            resourceUpdated {
                id
                name
                offsetDays
                offsetMonths
                offsetSequence
                offsetYears
                gracePeriod
                tagIds
                timelineId
                createdAt
                updatedAt
            }
        }
    }
`;
export type UpdateDateTriggerMutationFn = Apollo.MutationFunction<
    UpdateDateTriggerMutation,
    UpdateDateTriggerMutationVariables
>;

/**
 * __useUpdateDateTriggerMutation__
 *
 * To run a mutation, you first call `useUpdateDateTriggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDateTriggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDateTriggerMutation, { data, loading, error }] = useUpdateDateTriggerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDateTriggerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateDateTriggerMutation,
        UpdateDateTriggerMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateDateTriggerMutation, UpdateDateTriggerMutationVariables>(
        UpdateDateTriggerDocument,
        options,
    );
}
export type UpdateDateTriggerMutationHookResult = ReturnType<typeof useUpdateDateTriggerMutation>;
export type UpdateDateTriggerMutationResult = Apollo.MutationResult<UpdateDateTriggerMutation>;
export type UpdateDateTriggerMutationOptions = Apollo.BaseMutationOptions<
    UpdateDateTriggerMutation,
    UpdateDateTriggerMutationVariables
>;
export const CreateDateTriggerDocument = gql`
    mutation CreateDateTrigger($input: DateTriggerInput!) {
        createDateTrigger(input: $input) {
            success
            message
            resourceCreated {
                id
                name
                offsetDays
                offsetMonths
                offsetSequence
                offsetYears
                gracePeriod
                tagIds
                timelineId
                createdAt
                updatedAt
            }
        }
    }
`;
export type CreateDateTriggerMutationFn = Apollo.MutationFunction<
    CreateDateTriggerMutation,
    CreateDateTriggerMutationVariables
>;

/**
 * __useCreateDateTriggerMutation__
 *
 * To run a mutation, you first call `useCreateDateTriggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDateTriggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDateTriggerMutation, { data, loading, error }] = useCreateDateTriggerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDateTriggerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateDateTriggerMutation,
        CreateDateTriggerMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateDateTriggerMutation, CreateDateTriggerMutationVariables>(
        CreateDateTriggerDocument,
        options,
    );
}
export type CreateDateTriggerMutationHookResult = ReturnType<typeof useCreateDateTriggerMutation>;
export type CreateDateTriggerMutationResult = Apollo.MutationResult<CreateDateTriggerMutation>;
export type CreateDateTriggerMutationOptions = Apollo.BaseMutationOptions<
    CreateDateTriggerMutation,
    CreateDateTriggerMutationVariables
>;
export const DateTriggerListDocument = gql`
    query DateTriggerList($input: DateTriggerQueryInput) {
        dateTriggersV2(input: $input) {
            results {
                id
                createdAt
                updatedAt
                name
                offsetDays
                offsetMonths
                offsetSequence
                offsetYears
                gracePeriod
                tagIds
                timelineId
                createdAt
                updatedAt
            }
        }
    }
`;

/**
 * __useDateTriggerListQuery__
 *
 * To run a query within a React component, call `useDateTriggerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDateTriggerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDateTriggerListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDateTriggerListQuery(
    baseOptions?: Apollo.QueryHookOptions<DateTriggerListQuery, DateTriggerListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<DateTriggerListQuery, DateTriggerListQueryVariables>(
        DateTriggerListDocument,
        options,
    );
}
export function useDateTriggerListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<DateTriggerListQuery, DateTriggerListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<DateTriggerListQuery, DateTriggerListQueryVariables>(
        DateTriggerListDocument,
        options,
    );
}
export type DateTriggerListQueryHookResult = ReturnType<typeof useDateTriggerListQuery>;
export type DateTriggerListLazyQueryHookResult = ReturnType<typeof useDateTriggerListLazyQuery>;
export type DateTriggerListQueryResult = Apollo.QueryResult<
    DateTriggerListQuery,
    DateTriggerListQueryVariables
>;
export const DeleteDateTriggerDocument = gql`
    mutation DeleteDateTrigger($id: String!) {
        deleteDateTrigger(id: $id) {
            success
            message
            status
            id
        }
    }
`;
export type DeleteDateTriggerMutationFn = Apollo.MutationFunction<
    DeleteDateTriggerMutation,
    DeleteDateTriggerMutationVariables
>;

/**
 * __useDeleteDateTriggerMutation__
 *
 * To run a mutation, you first call `useDeleteDateTriggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDateTriggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDateTriggerMutation, { data, loading, error }] = useDeleteDateTriggerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDateTriggerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteDateTriggerMutation,
        DeleteDateTriggerMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteDateTriggerMutation, DeleteDateTriggerMutationVariables>(
        DeleteDateTriggerDocument,
        options,
    );
}
export type DeleteDateTriggerMutationHookResult = ReturnType<typeof useDeleteDateTriggerMutation>;
export type DeleteDateTriggerMutationResult = Apollo.MutationResult<DeleteDateTriggerMutation>;
export type DeleteDateTriggerMutationOptions = Apollo.BaseMutationOptions<
    DeleteDateTriggerMutation,
    DeleteDateTriggerMutationVariables
>;
export const PreFetchAppsTagsDocument = gql`
    query PreFetchAppsTags {
        amsApps {
            id
            bundleId
            productConst
        }
        configExpressions {
            id
            name
        }
        tags {
            id
            name
        }
        whatsNewTimelines {
            id
            type
            label {
                en
            }
        }
    }
`;

/**
 * __usePreFetchAppsTagsQuery__
 *
 * To run a query within a React component, call `usePreFetchAppsTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreFetchAppsTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreFetchAppsTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreFetchAppsTagsQuery(
    baseOptions?: Apollo.QueryHookOptions<PreFetchAppsTagsQuery, PreFetchAppsTagsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PreFetchAppsTagsQuery, PreFetchAppsTagsQueryVariables>(
        PreFetchAppsTagsDocument,
        options,
    );
}
export function usePreFetchAppsTagsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PreFetchAppsTagsQuery,
        PreFetchAppsTagsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PreFetchAppsTagsQuery, PreFetchAppsTagsQueryVariables>(
        PreFetchAppsTagsDocument,
        options,
    );
}
export type PreFetchAppsTagsQueryHookResult = ReturnType<typeof usePreFetchAppsTagsQuery>;
export type PreFetchAppsTagsLazyQueryHookResult = ReturnType<typeof usePreFetchAppsTagsLazyQuery>;
export type PreFetchAppsTagsQueryResult = Apollo.QueryResult<
    PreFetchAppsTagsQuery,
    PreFetchAppsTagsQueryVariables
>;
export const FetchEventTriggerDocument = gql`
    query FetchEventTrigger($input: GetEventTriggerInput!) {
        eventTrigger(input: $input) {
            id
            name
            profileDefId
            trackerType
            expressionId
            canRepeat
            ignoreSameValue
            createdAt
            updatedAt
        }
    }
`;

/**
 * __useFetchEventTriggerQuery__
 *
 * To run a query within a React component, call `useFetchEventTriggerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchEventTriggerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchEventTriggerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchEventTriggerQuery(
    baseOptions: Apollo.QueryHookOptions<FetchEventTriggerQuery, FetchEventTriggerQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchEventTriggerQuery, FetchEventTriggerQueryVariables>(
        FetchEventTriggerDocument,
        options,
    );
}
export function useFetchEventTriggerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchEventTriggerQuery,
        FetchEventTriggerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchEventTriggerQuery, FetchEventTriggerQueryVariables>(
        FetchEventTriggerDocument,
        options,
    );
}
export type FetchEventTriggerQueryHookResult = ReturnType<typeof useFetchEventTriggerQuery>;
export type FetchEventTriggerLazyQueryHookResult = ReturnType<typeof useFetchEventTriggerLazyQuery>;
export type FetchEventTriggerQueryResult = Apollo.QueryResult<
    FetchEventTriggerQuery,
    FetchEventTriggerQueryVariables
>;
export const UpdateEventTriggerDocument = gql`
    mutation UpdateEventTrigger($input: UpdateEventTriggerInput!) {
        updateEventTrigger(input: $input) {
            success
            message
            resourceUpdated {
                id
                name
                profileDefId
                trackerType
                expressionId
                canRepeat
                ignoreSameValue
                createdAt
                updatedAt
            }
        }
    }
`;
export type UpdateEventTriggerMutationFn = Apollo.MutationFunction<
    UpdateEventTriggerMutation,
    UpdateEventTriggerMutationVariables
>;

/**
 * __useUpdateEventTriggerMutation__
 *
 * To run a mutation, you first call `useUpdateEventTriggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventTriggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventTriggerMutation, { data, loading, error }] = useUpdateEventTriggerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventTriggerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateEventTriggerMutation,
        UpdateEventTriggerMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateEventTriggerMutation, UpdateEventTriggerMutationVariables>(
        UpdateEventTriggerDocument,
        options,
    );
}
export type UpdateEventTriggerMutationHookResult = ReturnType<typeof useUpdateEventTriggerMutation>;
export type UpdateEventTriggerMutationResult = Apollo.MutationResult<UpdateEventTriggerMutation>;
export type UpdateEventTriggerMutationOptions = Apollo.BaseMutationOptions<
    UpdateEventTriggerMutation,
    UpdateEventTriggerMutationVariables
>;
export const CreateEventTriggerDocument = gql`
    mutation CreateEventTrigger($input: EventTriggerInput!) {
        createEventTrigger(input: $input) {
            success
            message
            resourceCreated {
                id
                name
                profileDefId
                trackerType
                expressionId
                canRepeat
                ignoreSameValue
                createdAt
                updatedAt
            }
        }
    }
`;
export type CreateEventTriggerMutationFn = Apollo.MutationFunction<
    CreateEventTriggerMutation,
    CreateEventTriggerMutationVariables
>;

/**
 * __useCreateEventTriggerMutation__
 *
 * To run a mutation, you first call `useCreateEventTriggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventTriggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventTriggerMutation, { data, loading, error }] = useCreateEventTriggerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventTriggerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateEventTriggerMutation,
        CreateEventTriggerMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateEventTriggerMutation, CreateEventTriggerMutationVariables>(
        CreateEventTriggerDocument,
        options,
    );
}
export type CreateEventTriggerMutationHookResult = ReturnType<typeof useCreateEventTriggerMutation>;
export type CreateEventTriggerMutationResult = Apollo.MutationResult<CreateEventTriggerMutation>;
export type CreateEventTriggerMutationOptions = Apollo.BaseMutationOptions<
    CreateEventTriggerMutation,
    CreateEventTriggerMutationVariables
>;
export const EventTriggerListDocument = gql`
    query EventTriggerList($input: EventTriggerQueryInput) {
        eventTriggersV2(input: $input) {
            results {
                id
                name
                profileDefId
                trackerType
                expressionId
                canRepeat
                ignoreSameValue
                createdAt
                updatedAt
            }
        }
    }
`;

/**
 * __useEventTriggerListQuery__
 *
 * To run a query within a React component, call `useEventTriggerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventTriggerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventTriggerListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEventTriggerListQuery(
    baseOptions?: Apollo.QueryHookOptions<EventTriggerListQuery, EventTriggerListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<EventTriggerListQuery, EventTriggerListQueryVariables>(
        EventTriggerListDocument,
        options,
    );
}
export function useEventTriggerListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        EventTriggerListQuery,
        EventTriggerListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<EventTriggerListQuery, EventTriggerListQueryVariables>(
        EventTriggerListDocument,
        options,
    );
}
export type EventTriggerListQueryHookResult = ReturnType<typeof useEventTriggerListQuery>;
export type EventTriggerListLazyQueryHookResult = ReturnType<typeof useEventTriggerListLazyQuery>;
export type EventTriggerListQueryResult = Apollo.QueryResult<
    EventTriggerListQuery,
    EventTriggerListQueryVariables
>;
export const DeleteEventTriggerDocument = gql`
    mutation DeleteEventTrigger($id: String!) {
        deleteEventTrigger(id: $id) {
            success
            message
            status
            id
        }
    }
`;
export type DeleteEventTriggerMutationFn = Apollo.MutationFunction<
    DeleteEventTriggerMutation,
    DeleteEventTriggerMutationVariables
>;

/**
 * __useDeleteEventTriggerMutation__
 *
 * To run a mutation, you first call `useDeleteEventTriggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventTriggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventTriggerMutation, { data, loading, error }] = useDeleteEventTriggerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventTriggerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteEventTriggerMutation,
        DeleteEventTriggerMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteEventTriggerMutation, DeleteEventTriggerMutationVariables>(
        DeleteEventTriggerDocument,
        options,
    );
}
export type DeleteEventTriggerMutationHookResult = ReturnType<typeof useDeleteEventTriggerMutation>;
export type DeleteEventTriggerMutationResult = Apollo.MutationResult<DeleteEventTriggerMutation>;
export type DeleteEventTriggerMutationOptions = Apollo.BaseMutationOptions<
    DeleteEventTriggerMutation,
    DeleteEventTriggerMutationVariables
>;
export const FetchTrendTriggerDocument = gql`
    query FetchTrendTrigger($input: GetTrendTriggerInput!) {
        trendTrigger(input: $input) {
            id
            name
            eventTriggerId
            tagId
            trueCount
            period
            contiguous
            resetOnRun
            createdAt
            updatedAt
        }
    }
`;

/**
 * __useFetchTrendTriggerQuery__
 *
 * To run a query within a React component, call `useFetchTrendTriggerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTrendTriggerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTrendTriggerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchTrendTriggerQuery(
    baseOptions: Apollo.QueryHookOptions<FetchTrendTriggerQuery, FetchTrendTriggerQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchTrendTriggerQuery, FetchTrendTriggerQueryVariables>(
        FetchTrendTriggerDocument,
        options,
    );
}
export function useFetchTrendTriggerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchTrendTriggerQuery,
        FetchTrendTriggerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchTrendTriggerQuery, FetchTrendTriggerQueryVariables>(
        FetchTrendTriggerDocument,
        options,
    );
}
export type FetchTrendTriggerQueryHookResult = ReturnType<typeof useFetchTrendTriggerQuery>;
export type FetchTrendTriggerLazyQueryHookResult = ReturnType<typeof useFetchTrendTriggerLazyQuery>;
export type FetchTrendTriggerQueryResult = Apollo.QueryResult<
    FetchTrendTriggerQuery,
    FetchTrendTriggerQueryVariables
>;
export const UpdateTrendTriggerDocument = gql`
    mutation UpdateTrendTrigger($input: UpdateTrendTriggerInput!) {
        updateTrendTrigger(input: $input) {
            success
            message
            resourceUpdated {
                id
                name
                eventTriggerId
                tagId
                trueCount
                period
                contiguous
                resetOnRun
                createdAt
                updatedAt
            }
        }
    }
`;
export type UpdateTrendTriggerMutationFn = Apollo.MutationFunction<
    UpdateTrendTriggerMutation,
    UpdateTrendTriggerMutationVariables
>;

/**
 * __useUpdateTrendTriggerMutation__
 *
 * To run a mutation, you first call `useUpdateTrendTriggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrendTriggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrendTriggerMutation, { data, loading, error }] = useUpdateTrendTriggerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTrendTriggerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateTrendTriggerMutation,
        UpdateTrendTriggerMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateTrendTriggerMutation, UpdateTrendTriggerMutationVariables>(
        UpdateTrendTriggerDocument,
        options,
    );
}
export type UpdateTrendTriggerMutationHookResult = ReturnType<typeof useUpdateTrendTriggerMutation>;
export type UpdateTrendTriggerMutationResult = Apollo.MutationResult<UpdateTrendTriggerMutation>;
export type UpdateTrendTriggerMutationOptions = Apollo.BaseMutationOptions<
    UpdateTrendTriggerMutation,
    UpdateTrendTriggerMutationVariables
>;
export const CreateTrendTriggerDocument = gql`
    mutation CreateTrendTrigger($input: TrendTriggerInput!) {
        createTrendTrigger(input: $input) {
            success
            message
            resourceCreated {
                id
                name
                eventTriggerId
                tagId
                trueCount
                period
                contiguous
                resetOnRun
                createdAt
                updatedAt
            }
        }
    }
`;
export type CreateTrendTriggerMutationFn = Apollo.MutationFunction<
    CreateTrendTriggerMutation,
    CreateTrendTriggerMutationVariables
>;

/**
 * __useCreateTrendTriggerMutation__
 *
 * To run a mutation, you first call `useCreateTrendTriggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrendTriggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrendTriggerMutation, { data, loading, error }] = useCreateTrendTriggerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTrendTriggerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateTrendTriggerMutation,
        CreateTrendTriggerMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateTrendTriggerMutation, CreateTrendTriggerMutationVariables>(
        CreateTrendTriggerDocument,
        options,
    );
}
export type CreateTrendTriggerMutationHookResult = ReturnType<typeof useCreateTrendTriggerMutation>;
export type CreateTrendTriggerMutationResult = Apollo.MutationResult<CreateTrendTriggerMutation>;
export type CreateTrendTriggerMutationOptions = Apollo.BaseMutationOptions<
    CreateTrendTriggerMutation,
    CreateTrendTriggerMutationVariables
>;
export const TrendTriggerListDocument = gql`
    query TrendTriggerList($input: TrendTriggerQueryInput) {
        trendTriggersV2(input: $input) {
            results {
                id
                name
                eventTriggerId
                tagId
                trueCount
                period
                contiguous
                resetOnRun
                createdAt
                updatedAt
            }
        }
    }
`;

/**
 * __useTrendTriggerListQuery__
 *
 * To run a query within a React component, call `useTrendTriggerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrendTriggerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrendTriggerListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrendTriggerListQuery(
    baseOptions?: Apollo.QueryHookOptions<TrendTriggerListQuery, TrendTriggerListQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<TrendTriggerListQuery, TrendTriggerListQueryVariables>(
        TrendTriggerListDocument,
        options,
    );
}
export function useTrendTriggerListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        TrendTriggerListQuery,
        TrendTriggerListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<TrendTriggerListQuery, TrendTriggerListQueryVariables>(
        TrendTriggerListDocument,
        options,
    );
}
export type TrendTriggerListQueryHookResult = ReturnType<typeof useTrendTriggerListQuery>;
export type TrendTriggerListLazyQueryHookResult = ReturnType<typeof useTrendTriggerListLazyQuery>;
export type TrendTriggerListQueryResult = Apollo.QueryResult<
    TrendTriggerListQuery,
    TrendTriggerListQueryVariables
>;
export const DeleteTrendTriggerDocument = gql`
    mutation DeleteTrendTrigger($id: String!) {
        deleteTrendTrigger(id: $id) {
            success
            message
            status
            id
        }
    }
`;
export type DeleteTrendTriggerMutationFn = Apollo.MutationFunction<
    DeleteTrendTriggerMutation,
    DeleteTrendTriggerMutationVariables
>;

/**
 * __useDeleteTrendTriggerMutation__
 *
 * To run a mutation, you first call `useDeleteTrendTriggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTrendTriggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrendTriggerMutation, { data, loading, error }] = useDeleteTrendTriggerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTrendTriggerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteTrendTriggerMutation,
        DeleteTrendTriggerMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteTrendTriggerMutation, DeleteTrendTriggerMutationVariables>(
        DeleteTrendTriggerDocument,
        options,
    );
}
export type DeleteTrendTriggerMutationHookResult = ReturnType<typeof useDeleteTrendTriggerMutation>;
export type DeleteTrendTriggerMutationResult = Apollo.MutationResult<DeleteTrendTriggerMutation>;
export type DeleteTrendTriggerMutationOptions = Apollo.BaseMutationOptions<
    DeleteTrendTriggerMutation,
    DeleteTrendTriggerMutationVariables
>;
export const FetchTagsForTrendTriggerPageDocument = gql`
    query FetchTagsForTrendTriggerPage {
        tagsV2 {
            results {
                name
                id
            }
        }
    }
`;

/**
 * __useFetchTagsForTrendTriggerPageQuery__
 *
 * To run a query within a React component, call `useFetchTagsForTrendTriggerPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTagsForTrendTriggerPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTagsForTrendTriggerPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchTagsForTrendTriggerPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchTagsForTrendTriggerPageQuery,
        FetchTagsForTrendTriggerPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchTagsForTrendTriggerPageQuery,
        FetchTagsForTrendTriggerPageQueryVariables
    >(FetchTagsForTrendTriggerPageDocument, options);
}
export function useFetchTagsForTrendTriggerPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchTagsForTrendTriggerPageQuery,
        FetchTagsForTrendTriggerPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchTagsForTrendTriggerPageQuery,
        FetchTagsForTrendTriggerPageQueryVariables
    >(FetchTagsForTrendTriggerPageDocument, options);
}
export type FetchTagsForTrendTriggerPageQueryHookResult = ReturnType<
    typeof useFetchTagsForTrendTriggerPageQuery
>;
export type FetchTagsForTrendTriggerPageLazyQueryHookResult = ReturnType<
    typeof useFetchTagsForTrendTriggerPageLazyQuery
>;
export type FetchTagsForTrendTriggerPageQueryResult = Apollo.QueryResult<
    FetchTagsForTrendTriggerPageQuery,
    FetchTagsForTrendTriggerPageQueryVariables
>;
export const FetchAffiliateClaimSubmissionsDocument = gql`
    query FetchAffiliateClaimSubmissions($input: PaginatedAffiliateClaimInput!) {
        paginatedAffiliateClaim(input: $input) {
            total
            hasMore
            hasPrevious
            orderBy
            results {
                id
                affiliateId
                dateTime
                status
                location
                fileName
                errorText
                affiliate {
                    id
                    internalName
                }
            }
        }
    }
`;

/**
 * __useFetchAffiliateClaimSubmissionsQuery__
 *
 * To run a query within a React component, call `useFetchAffiliateClaimSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAffiliateClaimSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAffiliateClaimSubmissionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchAffiliateClaimSubmissionsQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchAffiliateClaimSubmissionsQuery,
        FetchAffiliateClaimSubmissionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchAffiliateClaimSubmissionsQuery,
        FetchAffiliateClaimSubmissionsQueryVariables
    >(FetchAffiliateClaimSubmissionsDocument, options);
}
export function useFetchAffiliateClaimSubmissionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchAffiliateClaimSubmissionsQuery,
        FetchAffiliateClaimSubmissionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchAffiliateClaimSubmissionsQuery,
        FetchAffiliateClaimSubmissionsQueryVariables
    >(FetchAffiliateClaimSubmissionsDocument, options);
}
export type FetchAffiliateClaimSubmissionsQueryHookResult = ReturnType<
    typeof useFetchAffiliateClaimSubmissionsQuery
>;
export type FetchAffiliateClaimSubmissionsLazyQueryHookResult = ReturnType<
    typeof useFetchAffiliateClaimSubmissionsLazyQuery
>;
export type FetchAffiliateClaimSubmissionsQueryResult = Apollo.QueryResult<
    FetchAffiliateClaimSubmissionsQuery,
    FetchAffiliateClaimSubmissionsQueryVariables
>;
export const FetchAffiliateClaimCsvDocument = gql`
    query FetchAffiliateClaimCSV($input: S3GetObjectInput!) {
        getAffiliateClaimCSVByKey(input: $input) {
            data
            filename
        }
    }
`;

/**
 * __useFetchAffiliateClaimCsvQuery__
 *
 * To run a query within a React component, call `useFetchAffiliateClaimCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAffiliateClaimCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAffiliateClaimCsvQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchAffiliateClaimCsvQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchAffiliateClaimCsvQuery,
        FetchAffiliateClaimCsvQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchAffiliateClaimCsvQuery, FetchAffiliateClaimCsvQueryVariables>(
        FetchAffiliateClaimCsvDocument,
        options,
    );
}
export function useFetchAffiliateClaimCsvLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchAffiliateClaimCsvQuery,
        FetchAffiliateClaimCsvQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchAffiliateClaimCsvQuery, FetchAffiliateClaimCsvQueryVariables>(
        FetchAffiliateClaimCsvDocument,
        options,
    );
}
export type FetchAffiliateClaimCsvQueryHookResult = ReturnType<
    typeof useFetchAffiliateClaimCsvQuery
>;
export type FetchAffiliateClaimCsvLazyQueryHookResult = ReturnType<
    typeof useFetchAffiliateClaimCsvLazyQuery
>;
export type FetchAffiliateClaimCsvQueryResult = Apollo.QueryResult<
    FetchAffiliateClaimCsvQuery,
    FetchAffiliateClaimCsvQueryVariables
>;
export const CreateVirtualCareAffiliateDocument = gql`
    mutation CreateVirtualCareAffiliate($input: CreateVirtualCareAffiliateInput!) {
        createVirtualCareAffiliate(input: $input) {
            status
            message
            success
            data {
                id
            }
        }
    }
`;
export type CreateVirtualCareAffiliateMutationFn = Apollo.MutationFunction<
    CreateVirtualCareAffiliateMutation,
    CreateVirtualCareAffiliateMutationVariables
>;

/**
 * __useCreateVirtualCareAffiliateMutation__
 *
 * To run a mutation, you first call `useCreateVirtualCareAffiliateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVirtualCareAffiliateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVirtualCareAffiliateMutation, { data, loading, error }] = useCreateVirtualCareAffiliateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVirtualCareAffiliateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateVirtualCareAffiliateMutation,
        CreateVirtualCareAffiliateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateVirtualCareAffiliateMutation,
        CreateVirtualCareAffiliateMutationVariables
    >(CreateVirtualCareAffiliateDocument, options);
}
export type CreateVirtualCareAffiliateMutationHookResult = ReturnType<
    typeof useCreateVirtualCareAffiliateMutation
>;
export type CreateVirtualCareAffiliateMutationResult =
    Apollo.MutationResult<CreateVirtualCareAffiliateMutation>;
export type CreateVirtualCareAffiliateMutationOptions = Apollo.BaseMutationOptions<
    CreateVirtualCareAffiliateMutation,
    CreateVirtualCareAffiliateMutationVariables
>;
export const UpdateVirtualCareAffiliatesDocument = gql`
    mutation UpdateVirtualCareAffiliates($input: UpdateVirtualCareAffiliateInput!) {
        updateVirtualCareAffiliate(input: $input) {
            message
            success
        }
    }
`;
export type UpdateVirtualCareAffiliatesMutationFn = Apollo.MutationFunction<
    UpdateVirtualCareAffiliatesMutation,
    UpdateVirtualCareAffiliatesMutationVariables
>;

/**
 * __useUpdateVirtualCareAffiliatesMutation__
 *
 * To run a mutation, you first call `useUpdateVirtualCareAffiliatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVirtualCareAffiliatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVirtualCareAffiliatesMutation, { data, loading, error }] = useUpdateVirtualCareAffiliatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVirtualCareAffiliatesMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateVirtualCareAffiliatesMutation,
        UpdateVirtualCareAffiliatesMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateVirtualCareAffiliatesMutation,
        UpdateVirtualCareAffiliatesMutationVariables
    >(UpdateVirtualCareAffiliatesDocument, options);
}
export type UpdateVirtualCareAffiliatesMutationHookResult = ReturnType<
    typeof useUpdateVirtualCareAffiliatesMutation
>;
export type UpdateVirtualCareAffiliatesMutationResult =
    Apollo.MutationResult<UpdateVirtualCareAffiliatesMutation>;
export type UpdateVirtualCareAffiliatesMutationOptions = Apollo.BaseMutationOptions<
    UpdateVirtualCareAffiliatesMutation,
    UpdateVirtualCareAffiliatesMutationVariables
>;
export const DeleteVirtualCareAffiliateDocument = gql`
    mutation DeleteVirtualCareAffiliate($input: String) {
        deleteVirtualCareAffiliate(input: $input) {
            message
            success
        }
    }
`;
export type DeleteVirtualCareAffiliateMutationFn = Apollo.MutationFunction<
    DeleteVirtualCareAffiliateMutation,
    DeleteVirtualCareAffiliateMutationVariables
>;

/**
 * __useDeleteVirtualCareAffiliateMutation__
 *
 * To run a mutation, you first call `useDeleteVirtualCareAffiliateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVirtualCareAffiliateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVirtualCareAffiliateMutation, { data, loading, error }] = useDeleteVirtualCareAffiliateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVirtualCareAffiliateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteVirtualCareAffiliateMutation,
        DeleteVirtualCareAffiliateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteVirtualCareAffiliateMutation,
        DeleteVirtualCareAffiliateMutationVariables
    >(DeleteVirtualCareAffiliateDocument, options);
}
export type DeleteVirtualCareAffiliateMutationHookResult = ReturnType<
    typeof useDeleteVirtualCareAffiliateMutation
>;
export type DeleteVirtualCareAffiliateMutationResult =
    Apollo.MutationResult<DeleteVirtualCareAffiliateMutation>;
export type DeleteVirtualCareAffiliateMutationOptions = Apollo.BaseMutationOptions<
    DeleteVirtualCareAffiliateMutation,
    DeleteVirtualCareAffiliateMutationVariables
>;
export const FetchVirtualCareAffiliateByIdDocument = gql`
    query FetchVirtualCareAffiliateById($input: GetVirtualCareAffiliateInput!) {
        virtualCareAffiliateById(input: $input) {
            affiliateURL
            affiliateSelfCheckURL
            data {
                id
                affiliateId
                internalName
                contactEmail
                contactName
                contactPhoneNumber
                supportMethodType
                supportMethodValue
                customSuccessText
                emailAddressForScheduling
                externalName
                notificationEmail
                onSuccessfulEligibility
                phoneNumberForScheduling
                routingURL
                notifyCompanyWhenPatientPassesEligibility
                onSuccessfulZipCodeMatch
                zipCodeMatchCustomSuccessString
                BillDotComVendorId
                website
                inviteAffiliate
            }
        }
    }
`;

/**
 * __useFetchVirtualCareAffiliateByIdQuery__
 *
 * To run a query within a React component, call `useFetchVirtualCareAffiliateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchVirtualCareAffiliateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchVirtualCareAffiliateByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchVirtualCareAffiliateByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchVirtualCareAffiliateByIdQuery,
        FetchVirtualCareAffiliateByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchVirtualCareAffiliateByIdQuery,
        FetchVirtualCareAffiliateByIdQueryVariables
    >(FetchVirtualCareAffiliateByIdDocument, options);
}
export function useFetchVirtualCareAffiliateByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchVirtualCareAffiliateByIdQuery,
        FetchVirtualCareAffiliateByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchVirtualCareAffiliateByIdQuery,
        FetchVirtualCareAffiliateByIdQueryVariables
    >(FetchVirtualCareAffiliateByIdDocument, options);
}
export type FetchVirtualCareAffiliateByIdQueryHookResult = ReturnType<
    typeof useFetchVirtualCareAffiliateByIdQuery
>;
export type FetchVirtualCareAffiliateByIdLazyQueryHookResult = ReturnType<
    typeof useFetchVirtualCareAffiliateByIdLazyQuery
>;
export type FetchVirtualCareAffiliateByIdQueryResult = Apollo.QueryResult<
    FetchVirtualCareAffiliateByIdQuery,
    FetchVirtualCareAffiliateByIdQueryVariables
>;
export const FetchVirtualCareAffiliatesDocument = gql`
    query FetchVirtualCareAffiliates($input: GetVirtualCareAffiliatesInput!) {
        virtualCareAffiliates(input: $input) {
            total
            hasMore
            hasPrevious
            orderBy
            results {
                id
                affiliateId
                internalName
                externalName
            }
        }
    }
`;

/**
 * __useFetchVirtualCareAffiliatesQuery__
 *
 * To run a query within a React component, call `useFetchVirtualCareAffiliatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchVirtualCareAffiliatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchVirtualCareAffiliatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchVirtualCareAffiliatesQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchVirtualCareAffiliatesQuery,
        FetchVirtualCareAffiliatesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchVirtualCareAffiliatesQuery,
        FetchVirtualCareAffiliatesQueryVariables
    >(FetchVirtualCareAffiliatesDocument, options);
}
export function useFetchVirtualCareAffiliatesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchVirtualCareAffiliatesQuery,
        FetchVirtualCareAffiliatesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchVirtualCareAffiliatesQuery,
        FetchVirtualCareAffiliatesQueryVariables
    >(FetchVirtualCareAffiliatesDocument, options);
}
export type FetchVirtualCareAffiliatesQueryHookResult = ReturnType<
    typeof useFetchVirtualCareAffiliatesQuery
>;
export type FetchVirtualCareAffiliatesLazyQueryHookResult = ReturnType<
    typeof useFetchVirtualCareAffiliatesLazyQuery
>;
export type FetchVirtualCareAffiliatesQueryResult = Apollo.QueryResult<
    FetchVirtualCareAffiliatesQuery,
    FetchVirtualCareAffiliatesQueryVariables
>;
export const GetVendorByIdFromExternalServiceDocument = gql`
    query GetVendorByIdFromExternalService($input: GetVendorByIdInput) {
        getVendorById(input: $input) {
            address1
            address2
            addressCity
            addressState
            addressZip
            nameOnCheck
            vendorId
        }
    }
`;

/**
 * __useGetVendorByIdFromExternalServiceQuery__
 *
 * To run a query within a React component, call `useGetVendorByIdFromExternalServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorByIdFromExternalServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorByIdFromExternalServiceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetVendorByIdFromExternalServiceQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetVendorByIdFromExternalServiceQuery,
        GetVendorByIdFromExternalServiceQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetVendorByIdFromExternalServiceQuery,
        GetVendorByIdFromExternalServiceQueryVariables
    >(GetVendorByIdFromExternalServiceDocument, options);
}
export function useGetVendorByIdFromExternalServiceLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetVendorByIdFromExternalServiceQuery,
        GetVendorByIdFromExternalServiceQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetVendorByIdFromExternalServiceQuery,
        GetVendorByIdFromExternalServiceQueryVariables
    >(GetVendorByIdFromExternalServiceDocument, options);
}
export type GetVendorByIdFromExternalServiceQueryHookResult = ReturnType<
    typeof useGetVendorByIdFromExternalServiceQuery
>;
export type GetVendorByIdFromExternalServiceLazyQueryHookResult = ReturnType<
    typeof useGetVendorByIdFromExternalServiceLazyQuery
>;
export type GetVendorByIdFromExternalServiceQueryResult = Apollo.QueryResult<
    GetVendorByIdFromExternalServiceQuery,
    GetVendorByIdFromExternalServiceQueryVariables
>;
export const VirtualCareEligibilityWhitelistsV2Document = gql`
    query VirtualCareEligibilityWhitelistsV2($input: VirtualCareEligibilityWhitelistQueryInput) {
        virtualCareEligibilityWhitelistsV2(input: $input) {
            hasMore
            limit
            orderBy
            results {
                firstName
                id
                lastName
                memberId
                updatedAt
            }
        }
    }
`;

/**
 * __useVirtualCareEligibilityWhitelistsV2Query__
 *
 * To run a query within a React component, call `useVirtualCareEligibilityWhitelistsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useVirtualCareEligibilityWhitelistsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualCareEligibilityWhitelistsV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVirtualCareEligibilityWhitelistsV2Query(
    baseOptions?: Apollo.QueryHookOptions<
        VirtualCareEligibilityWhitelistsV2Query,
        VirtualCareEligibilityWhitelistsV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        VirtualCareEligibilityWhitelistsV2Query,
        VirtualCareEligibilityWhitelistsV2QueryVariables
    >(VirtualCareEligibilityWhitelistsV2Document, options);
}
export function useVirtualCareEligibilityWhitelistsV2LazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        VirtualCareEligibilityWhitelistsV2Query,
        VirtualCareEligibilityWhitelistsV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        VirtualCareEligibilityWhitelistsV2Query,
        VirtualCareEligibilityWhitelistsV2QueryVariables
    >(VirtualCareEligibilityWhitelistsV2Document, options);
}
export type VirtualCareEligibilityWhitelistsV2QueryHookResult = ReturnType<
    typeof useVirtualCareEligibilityWhitelistsV2Query
>;
export type VirtualCareEligibilityWhitelistsV2LazyQueryHookResult = ReturnType<
    typeof useVirtualCareEligibilityWhitelistsV2LazyQuery
>;
export type VirtualCareEligibilityWhitelistsV2QueryResult = Apollo.QueryResult<
    VirtualCareEligibilityWhitelistsV2Query,
    VirtualCareEligibilityWhitelistsV2QueryVariables
>;
export const VirtualCareEligibilityWhitelistDocument = gql`
    query VirtualCareEligibilityWhitelist($input: GetVirtualCareEligibilityWhitelistInput!) {
        virtualCareEligibilityWhitelist(input: $input) {
            createdAt
            firstName
            id
            isTestData
            lastName
            memberId
            updatedAt
        }
    }
`;

/**
 * __useVirtualCareEligibilityWhitelistQuery__
 *
 * To run a query within a React component, call `useVirtualCareEligibilityWhitelistQuery` and pass it any options that fit your needs.
 * When your component renders, `useVirtualCareEligibilityWhitelistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualCareEligibilityWhitelistQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVirtualCareEligibilityWhitelistQuery(
    baseOptions: Apollo.QueryHookOptions<
        VirtualCareEligibilityWhitelistQuery,
        VirtualCareEligibilityWhitelistQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        VirtualCareEligibilityWhitelistQuery,
        VirtualCareEligibilityWhitelistQueryVariables
    >(VirtualCareEligibilityWhitelistDocument, options);
}
export function useVirtualCareEligibilityWhitelistLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        VirtualCareEligibilityWhitelistQuery,
        VirtualCareEligibilityWhitelistQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        VirtualCareEligibilityWhitelistQuery,
        VirtualCareEligibilityWhitelistQueryVariables
    >(VirtualCareEligibilityWhitelistDocument, options);
}
export type VirtualCareEligibilityWhitelistQueryHookResult = ReturnType<
    typeof useVirtualCareEligibilityWhitelistQuery
>;
export type VirtualCareEligibilityWhitelistLazyQueryHookResult = ReturnType<
    typeof useVirtualCareEligibilityWhitelistLazyQuery
>;
export type VirtualCareEligibilityWhitelistQueryResult = Apollo.QueryResult<
    VirtualCareEligibilityWhitelistQuery,
    VirtualCareEligibilityWhitelistQueryVariables
>;
export const CreateVirtualCareEligibilityWhitelistDocument = gql`
    mutation CreateVirtualCareEligibilityWhitelist($input: VirtualCareEligibilityWhitelistInput!) {
        createVirtualCareEligibilityWhitelist(input: $input) {
            status
            success
            resourceCreated {
                createdAt
                firstName
                id
                lastName
                memberId
                updatedAt
            }
            message
        }
    }
`;
export type CreateVirtualCareEligibilityWhitelistMutationFn = Apollo.MutationFunction<
    CreateVirtualCareEligibilityWhitelistMutation,
    CreateVirtualCareEligibilityWhitelistMutationVariables
>;

/**
 * __useCreateVirtualCareEligibilityWhitelistMutation__
 *
 * To run a mutation, you first call `useCreateVirtualCareEligibilityWhitelistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVirtualCareEligibilityWhitelistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVirtualCareEligibilityWhitelistMutation, { data, loading, error }] = useCreateVirtualCareEligibilityWhitelistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVirtualCareEligibilityWhitelistMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateVirtualCareEligibilityWhitelistMutation,
        CreateVirtualCareEligibilityWhitelistMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateVirtualCareEligibilityWhitelistMutation,
        CreateVirtualCareEligibilityWhitelistMutationVariables
    >(CreateVirtualCareEligibilityWhitelistDocument, options);
}
export type CreateVirtualCareEligibilityWhitelistMutationHookResult = ReturnType<
    typeof useCreateVirtualCareEligibilityWhitelistMutation
>;
export type CreateVirtualCareEligibilityWhitelistMutationResult =
    Apollo.MutationResult<CreateVirtualCareEligibilityWhitelistMutation>;
export type CreateVirtualCareEligibilityWhitelistMutationOptions = Apollo.BaseMutationOptions<
    CreateVirtualCareEligibilityWhitelistMutation,
    CreateVirtualCareEligibilityWhitelistMutationVariables
>;
export const UpdateVirtualCareEligibilityWhitelistDocument = gql`
    mutation UpdateVirtualCareEligibilityWhitelist(
        $input: UpdateVirtualCareEligibilityWhitelistInput!
    ) {
        updateVirtualCareEligibilityWhitelist(input: $input) {
            message
            resourceUpdated {
                createdAt
                firstName
                id
                lastName
                memberId
                updatedAt
            }
        }
    }
`;
export type UpdateVirtualCareEligibilityWhitelistMutationFn = Apollo.MutationFunction<
    UpdateVirtualCareEligibilityWhitelistMutation,
    UpdateVirtualCareEligibilityWhitelistMutationVariables
>;

/**
 * __useUpdateVirtualCareEligibilityWhitelistMutation__
 *
 * To run a mutation, you first call `useUpdateVirtualCareEligibilityWhitelistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVirtualCareEligibilityWhitelistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVirtualCareEligibilityWhitelistMutation, { data, loading, error }] = useUpdateVirtualCareEligibilityWhitelistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVirtualCareEligibilityWhitelistMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateVirtualCareEligibilityWhitelistMutation,
        UpdateVirtualCareEligibilityWhitelistMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateVirtualCareEligibilityWhitelistMutation,
        UpdateVirtualCareEligibilityWhitelistMutationVariables
    >(UpdateVirtualCareEligibilityWhitelistDocument, options);
}
export type UpdateVirtualCareEligibilityWhitelistMutationHookResult = ReturnType<
    typeof useUpdateVirtualCareEligibilityWhitelistMutation
>;
export type UpdateVirtualCareEligibilityWhitelistMutationResult =
    Apollo.MutationResult<UpdateVirtualCareEligibilityWhitelistMutation>;
export type UpdateVirtualCareEligibilityWhitelistMutationOptions = Apollo.BaseMutationOptions<
    UpdateVirtualCareEligibilityWhitelistMutation,
    UpdateVirtualCareEligibilityWhitelistMutationVariables
>;
export const DeleteVirtualCareEligibilityWhitelistDocument = gql`
    mutation DeleteVirtualCareEligibilityWhitelist(
        $input: DeleteVirtualCareEligibilityWhitelistInput!
    ) {
        deleteVirtualCareEligibilityWhitelist(input: $input) {
            id
            message
            status
            success
        }
    }
`;
export type DeleteVirtualCareEligibilityWhitelistMutationFn = Apollo.MutationFunction<
    DeleteVirtualCareEligibilityWhitelistMutation,
    DeleteVirtualCareEligibilityWhitelistMutationVariables
>;

/**
 * __useDeleteVirtualCareEligibilityWhitelistMutation__
 *
 * To run a mutation, you first call `useDeleteVirtualCareEligibilityWhitelistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVirtualCareEligibilityWhitelistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVirtualCareEligibilityWhitelistMutation, { data, loading, error }] = useDeleteVirtualCareEligibilityWhitelistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVirtualCareEligibilityWhitelistMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteVirtualCareEligibilityWhitelistMutation,
        DeleteVirtualCareEligibilityWhitelistMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteVirtualCareEligibilityWhitelistMutation,
        DeleteVirtualCareEligibilityWhitelistMutationVariables
    >(DeleteVirtualCareEligibilityWhitelistDocument, options);
}
export type DeleteVirtualCareEligibilityWhitelistMutationHookResult = ReturnType<
    typeof useDeleteVirtualCareEligibilityWhitelistMutation
>;
export type DeleteVirtualCareEligibilityWhitelistMutationResult =
    Apollo.MutationResult<DeleteVirtualCareEligibilityWhitelistMutation>;
export type DeleteVirtualCareEligibilityWhitelistMutationOptions = Apollo.BaseMutationOptions<
    DeleteVirtualCareEligibilityWhitelistMutation,
    DeleteVirtualCareEligibilityWhitelistMutationVariables
>;
export const CreateVcZipCodeToLocationDocument = gql`
    mutation CreateVCZipCodeToLocation($input: AddVCZipCodeToLocationInput!) {
        createVCZipCodeToLocation(input: $input) {
            message
            resourceCreated {
                id
                locationId
                locationName
                zipCode
                createdAt
                routingMethod
                affiliateId
            }
            status
            success
        }
    }
`;
export type CreateVcZipCodeToLocationMutationFn = Apollo.MutationFunction<
    CreateVcZipCodeToLocationMutation,
    CreateVcZipCodeToLocationMutationVariables
>;

/**
 * __useCreateVcZipCodeToLocationMutation__
 *
 * To run a mutation, you first call `useCreateVcZipCodeToLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVcZipCodeToLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVcZipCodeToLocationMutation, { data, loading, error }] = useCreateVcZipCodeToLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVcZipCodeToLocationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateVcZipCodeToLocationMutation,
        CreateVcZipCodeToLocationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateVcZipCodeToLocationMutation,
        CreateVcZipCodeToLocationMutationVariables
    >(CreateVcZipCodeToLocationDocument, options);
}
export type CreateVcZipCodeToLocationMutationHookResult = ReturnType<
    typeof useCreateVcZipCodeToLocationMutation
>;
export type CreateVcZipCodeToLocationMutationResult =
    Apollo.MutationResult<CreateVcZipCodeToLocationMutation>;
export type CreateVcZipCodeToLocationMutationOptions = Apollo.BaseMutationOptions<
    CreateVcZipCodeToLocationMutation,
    CreateVcZipCodeToLocationMutationVariables
>;
export const UpdateVcZipCodeToLocationDocument = gql`
    mutation UpdateVCZipCodeToLocation($input: UpdateVCZipCodeToLocationInput!) {
        updateVCZipCodeToLocation(input: $input) {
            status
            message
            success
            resourceUpdated {
                id
                locationId
                locationName
                zipCode
                createdAt
            }
        }
    }
`;
export type UpdateVcZipCodeToLocationMutationFn = Apollo.MutationFunction<
    UpdateVcZipCodeToLocationMutation,
    UpdateVcZipCodeToLocationMutationVariables
>;

/**
 * __useUpdateVcZipCodeToLocationMutation__
 *
 * To run a mutation, you first call `useUpdateVcZipCodeToLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVcZipCodeToLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVcZipCodeToLocationMutation, { data, loading, error }] = useUpdateVcZipCodeToLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVcZipCodeToLocationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateVcZipCodeToLocationMutation,
        UpdateVcZipCodeToLocationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateVcZipCodeToLocationMutation,
        UpdateVcZipCodeToLocationMutationVariables
    >(UpdateVcZipCodeToLocationDocument, options);
}
export type UpdateVcZipCodeToLocationMutationHookResult = ReturnType<
    typeof useUpdateVcZipCodeToLocationMutation
>;
export type UpdateVcZipCodeToLocationMutationResult =
    Apollo.MutationResult<UpdateVcZipCodeToLocationMutation>;
export type UpdateVcZipCodeToLocationMutationOptions = Apollo.BaseMutationOptions<
    UpdateVcZipCodeToLocationMutation,
    UpdateVcZipCodeToLocationMutationVariables
>;
export const VcZipCodeToLocationsV2Document = gql`
    query VCZipCodeToLocationsV2($input: VCZipCodeToLocationSearchInput) {
        VCZipCodeToLocationSearch(input: $input) {
            hasMore
            skip
            total
            results {
                id
                locationId
                locationName
                zipCode
                createdAt
                routingMethod
                affiliateId
                affiliate {
                    internalName
                    affiliateId
                    id
                }
                updatedAt
            }
            limit
            orderBy
        }
    }
`;

/**
 * __useVcZipCodeToLocationsV2Query__
 *
 * To run a query within a React component, call `useVcZipCodeToLocationsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useVcZipCodeToLocationsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVcZipCodeToLocationsV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVcZipCodeToLocationsV2Query(
    baseOptions?: Apollo.QueryHookOptions<
        VcZipCodeToLocationsV2Query,
        VcZipCodeToLocationsV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<VcZipCodeToLocationsV2Query, VcZipCodeToLocationsV2QueryVariables>(
        VcZipCodeToLocationsV2Document,
        options,
    );
}
export function useVcZipCodeToLocationsV2LazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        VcZipCodeToLocationsV2Query,
        VcZipCodeToLocationsV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<VcZipCodeToLocationsV2Query, VcZipCodeToLocationsV2QueryVariables>(
        VcZipCodeToLocationsV2Document,
        options,
    );
}
export type VcZipCodeToLocationsV2QueryHookResult = ReturnType<
    typeof useVcZipCodeToLocationsV2Query
>;
export type VcZipCodeToLocationsV2LazyQueryHookResult = ReturnType<
    typeof useVcZipCodeToLocationsV2LazyQuery
>;
export type VcZipCodeToLocationsV2QueryResult = Apollo.QueryResult<
    VcZipCodeToLocationsV2Query,
    VcZipCodeToLocationsV2QueryVariables
>;
export const VcZipCodeToLocationDocument = gql`
    query VCZipCodeToLocation($input: GetVCZipCodeToLocationInput!) {
        vCZipCodeToLocation(input: $input) {
            createdAt
            id
            locationId
            locationName
            updatedAt
            zipCode
            affiliateId
            routingMethod
        }
    }
`;

/**
 * __useVcZipCodeToLocationQuery__
 *
 * To run a query within a React component, call `useVcZipCodeToLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useVcZipCodeToLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVcZipCodeToLocationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVcZipCodeToLocationQuery(
    baseOptions: Apollo.QueryHookOptions<
        VcZipCodeToLocationQuery,
        VcZipCodeToLocationQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<VcZipCodeToLocationQuery, VcZipCodeToLocationQueryVariables>(
        VcZipCodeToLocationDocument,
        options,
    );
}
export function useVcZipCodeToLocationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        VcZipCodeToLocationQuery,
        VcZipCodeToLocationQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<VcZipCodeToLocationQuery, VcZipCodeToLocationQueryVariables>(
        VcZipCodeToLocationDocument,
        options,
    );
}
export type VcZipCodeToLocationQueryHookResult = ReturnType<typeof useVcZipCodeToLocationQuery>;
export type VcZipCodeToLocationLazyQueryHookResult = ReturnType<
    typeof useVcZipCodeToLocationLazyQuery
>;
export type VcZipCodeToLocationQueryResult = Apollo.QueryResult<
    VcZipCodeToLocationQuery,
    VcZipCodeToLocationQueryVariables
>;
export const DeleteVcZipCodeToLocationDocument = gql`
    mutation DeleteVCZipCodeToLocation($input: DeleteVCZipCodeToLocationInput!) {
        deleteVCZipCodeToLocation(input: $input) {
            id
            message
            status
            success
        }
    }
`;
export type DeleteVcZipCodeToLocationMutationFn = Apollo.MutationFunction<
    DeleteVcZipCodeToLocationMutation,
    DeleteVcZipCodeToLocationMutationVariables
>;

/**
 * __useDeleteVcZipCodeToLocationMutation__
 *
 * To run a mutation, you first call `useDeleteVcZipCodeToLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVcZipCodeToLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVcZipCodeToLocationMutation, { data, loading, error }] = useDeleteVcZipCodeToLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVcZipCodeToLocationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteVcZipCodeToLocationMutation,
        DeleteVcZipCodeToLocationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteVcZipCodeToLocationMutation,
        DeleteVcZipCodeToLocationMutationVariables
    >(DeleteVcZipCodeToLocationDocument, options);
}
export type DeleteVcZipCodeToLocationMutationHookResult = ReturnType<
    typeof useDeleteVcZipCodeToLocationMutation
>;
export type DeleteVcZipCodeToLocationMutationResult =
    Apollo.MutationResult<DeleteVcZipCodeToLocationMutation>;
export type DeleteVcZipCodeToLocationMutationOptions = Apollo.BaseMutationOptions<
    DeleteVcZipCodeToLocationMutation,
    DeleteVcZipCodeToLocationMutationVariables
>;
export const VcZipCodesAffiliatessListDocument = gql`
    query VCZipCodesAffiliatessList {
        virtualCareAffiliatessV2 {
            results {
                id
                internalName
                affiliateId
                onSuccessfulZipCodeMatch
            }
        }
    }
`;

/**
 * __useVcZipCodesAffiliatessListQuery__
 *
 * To run a query within a React component, call `useVcZipCodesAffiliatessListQuery` and pass it any options that fit your needs.
 * When your component renders, `useVcZipCodesAffiliatessListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVcZipCodesAffiliatessListQuery({
 *   variables: {
 *   },
 * });
 */
export function useVcZipCodesAffiliatessListQuery(
    baseOptions?: Apollo.QueryHookOptions<
        VcZipCodesAffiliatessListQuery,
        VcZipCodesAffiliatessListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<VcZipCodesAffiliatessListQuery, VcZipCodesAffiliatessListQueryVariables>(
        VcZipCodesAffiliatessListDocument,
        options,
    );
}
export function useVcZipCodesAffiliatessListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        VcZipCodesAffiliatessListQuery,
        VcZipCodesAffiliatessListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        VcZipCodesAffiliatessListQuery,
        VcZipCodesAffiliatessListQueryVariables
    >(VcZipCodesAffiliatessListDocument, options);
}
export type VcZipCodesAffiliatessListQueryHookResult = ReturnType<
    typeof useVcZipCodesAffiliatessListQuery
>;
export type VcZipCodesAffiliatessListLazyQueryHookResult = ReturnType<
    typeof useVcZipCodesAffiliatessListLazyQuery
>;
export type VcZipCodesAffiliatessListQueryResult = Apollo.QueryResult<
    VcZipCodesAffiliatessListQuery,
    VcZipCodesAffiliatessListQueryVariables
>;
export const CreateManyVcZipCodeToLocationsDocument = gql`
    mutation CreateManyVCZipCodeToLocations($input: CreateManyVCZipCodeToLocationsInput!) {
        createManyVCZipCodeToLocations(input: $input) {
            message
            resourcesCreated {
                resourceCreated {
                    id
                    locationName
                    locationId
                    affiliateId
                    routingMethod
                    zipCode
                }
                status
                success
                message
            }
        }
    }
`;
export type CreateManyVcZipCodeToLocationsMutationFn = Apollo.MutationFunction<
    CreateManyVcZipCodeToLocationsMutation,
    CreateManyVcZipCodeToLocationsMutationVariables
>;

/**
 * __useCreateManyVcZipCodeToLocationsMutation__
 *
 * To run a mutation, you first call `useCreateManyVcZipCodeToLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyVcZipCodeToLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManyVcZipCodeToLocationsMutation, { data, loading, error }] = useCreateManyVcZipCodeToLocationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManyVcZipCodeToLocationsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateManyVcZipCodeToLocationsMutation,
        CreateManyVcZipCodeToLocationsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateManyVcZipCodeToLocationsMutation,
        CreateManyVcZipCodeToLocationsMutationVariables
    >(CreateManyVcZipCodeToLocationsDocument, options);
}
export type CreateManyVcZipCodeToLocationsMutationHookResult = ReturnType<
    typeof useCreateManyVcZipCodeToLocationsMutation
>;
export type CreateManyVcZipCodeToLocationsMutationResult =
    Apollo.MutationResult<CreateManyVcZipCodeToLocationsMutation>;
export type CreateManyVcZipCodeToLocationsMutationOptions = Apollo.BaseMutationOptions<
    CreateManyVcZipCodeToLocationsMutation,
    CreateManyVcZipCodeToLocationsMutationVariables
>;
export const AdvocateTasksForAdvocateTaskListPageDocument = gql`
    query AdvocateTasksForAdvocateTaskListPage($input: GetFilteredAdvocateTasksInput) {
        customFilteredAdvocateTasks(input: $input) {
            orderBy
            skip
            limit
            total
            hasMore
            hasPrevious
            results {
                id
                label
                description
                patient {
                    id
                    fullName
                    practice {
                        id
                        brandingName
                    }
                }
                dueDate
                createdAt
                completedAt
                overdue
                priority
                status
            }
        }
    }
`;

/**
 * __useAdvocateTasksForAdvocateTaskListPageQuery__
 *
 * To run a query within a React component, call `useAdvocateTasksForAdvocateTaskListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvocateTasksForAdvocateTaskListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvocateTasksForAdvocateTaskListPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdvocateTasksForAdvocateTaskListPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        AdvocateTasksForAdvocateTaskListPageQuery,
        AdvocateTasksForAdvocateTaskListPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        AdvocateTasksForAdvocateTaskListPageQuery,
        AdvocateTasksForAdvocateTaskListPageQueryVariables
    >(AdvocateTasksForAdvocateTaskListPageDocument, options);
}
export function useAdvocateTasksForAdvocateTaskListPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        AdvocateTasksForAdvocateTaskListPageQuery,
        AdvocateTasksForAdvocateTaskListPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        AdvocateTasksForAdvocateTaskListPageQuery,
        AdvocateTasksForAdvocateTaskListPageQueryVariables
    >(AdvocateTasksForAdvocateTaskListPageDocument, options);
}
export type AdvocateTasksForAdvocateTaskListPageQueryHookResult = ReturnType<
    typeof useAdvocateTasksForAdvocateTaskListPageQuery
>;
export type AdvocateTasksForAdvocateTaskListPageLazyQueryHookResult = ReturnType<
    typeof useAdvocateTasksForAdvocateTaskListPageLazyQuery
>;
export type AdvocateTasksForAdvocateTaskListPageQueryResult = Apollo.QueryResult<
    AdvocateTasksForAdvocateTaskListPageQuery,
    AdvocateTasksForAdvocateTaskListPageQueryVariables
>;
export const FetchCurrentUserForAdvocateTaskListPageDocument = gql`
    query FetchCurrentUserForAdvocateTaskListPage {
        currentUser {
            id
            name
            patients {
                fullName
                id
                practice {
                    id
                }
                isTestData
            }
            currentOrganizations {
                id
            }
        }
    }
`;

/**
 * __useFetchCurrentUserForAdvocateTaskListPageQuery__
 *
 * To run a query within a React component, call `useFetchCurrentUserForAdvocateTaskListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCurrentUserForAdvocateTaskListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCurrentUserForAdvocateTaskListPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCurrentUserForAdvocateTaskListPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchCurrentUserForAdvocateTaskListPageQuery,
        FetchCurrentUserForAdvocateTaskListPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchCurrentUserForAdvocateTaskListPageQuery,
        FetchCurrentUserForAdvocateTaskListPageQueryVariables
    >(FetchCurrentUserForAdvocateTaskListPageDocument, options);
}
export function useFetchCurrentUserForAdvocateTaskListPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchCurrentUserForAdvocateTaskListPageQuery,
        FetchCurrentUserForAdvocateTaskListPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchCurrentUserForAdvocateTaskListPageQuery,
        FetchCurrentUserForAdvocateTaskListPageQueryVariables
    >(FetchCurrentUserForAdvocateTaskListPageDocument, options);
}
export type FetchCurrentUserForAdvocateTaskListPageQueryHookResult = ReturnType<
    typeof useFetchCurrentUserForAdvocateTaskListPageQuery
>;
export type FetchCurrentUserForAdvocateTaskListPageLazyQueryHookResult = ReturnType<
    typeof useFetchCurrentUserForAdvocateTaskListPageLazyQuery
>;
export type FetchCurrentUserForAdvocateTaskListPageQueryResult = Apollo.QueryResult<
    FetchCurrentUserForAdvocateTaskListPageQuery,
    FetchCurrentUserForAdvocateTaskListPageQueryVariables
>;
export const FetchAdvocateTaskTypesQueryDocument = gql`
    query FetchAdvocateTaskTypesQuery {
        advocateTaskTypesV2 {
            results {
                label
                id
            }
        }
    }
`;

/**
 * __useFetchAdvocateTaskTypesQueryQuery__
 *
 * To run a query within a React component, call `useFetchAdvocateTaskTypesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAdvocateTaskTypesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAdvocateTaskTypesQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAdvocateTaskTypesQueryQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchAdvocateTaskTypesQueryQuery,
        FetchAdvocateTaskTypesQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchAdvocateTaskTypesQueryQuery,
        FetchAdvocateTaskTypesQueryQueryVariables
    >(FetchAdvocateTaskTypesQueryDocument, options);
}
export function useFetchAdvocateTaskTypesQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchAdvocateTaskTypesQueryQuery,
        FetchAdvocateTaskTypesQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchAdvocateTaskTypesQueryQuery,
        FetchAdvocateTaskTypesQueryQueryVariables
    >(FetchAdvocateTaskTypesQueryDocument, options);
}
export type FetchAdvocateTaskTypesQueryQueryHookResult = ReturnType<
    typeof useFetchAdvocateTaskTypesQueryQuery
>;
export type FetchAdvocateTaskTypesQueryLazyQueryHookResult = ReturnType<
    typeof useFetchAdvocateTaskTypesQueryLazyQuery
>;
export type FetchAdvocateTaskTypesQueryQueryResult = Apollo.QueryResult<
    FetchAdvocateTaskTypesQueryQuery,
    FetchAdvocateTaskTypesQueryQueryVariables
>;
export const FetchAdvocatesForAdvocateTaskListPageQueryDocument = gql`
    query FetchAdvocatesForAdvocateTaskListPageQuery($permission: String) {
        getUsersByPermission(permission: $permission) {
            id
            name
        }
    }
`;

/**
 * __useFetchAdvocatesForAdvocateTaskListPageQueryQuery__
 *
 * To run a query within a React component, call `useFetchAdvocatesForAdvocateTaskListPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAdvocatesForAdvocateTaskListPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAdvocatesForAdvocateTaskListPageQueryQuery({
 *   variables: {
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useFetchAdvocatesForAdvocateTaskListPageQueryQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchAdvocatesForAdvocateTaskListPageQueryQuery,
        FetchAdvocatesForAdvocateTaskListPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchAdvocatesForAdvocateTaskListPageQueryQuery,
        FetchAdvocatesForAdvocateTaskListPageQueryQueryVariables
    >(FetchAdvocatesForAdvocateTaskListPageQueryDocument, options);
}
export function useFetchAdvocatesForAdvocateTaskListPageQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchAdvocatesForAdvocateTaskListPageQueryQuery,
        FetchAdvocatesForAdvocateTaskListPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchAdvocatesForAdvocateTaskListPageQueryQuery,
        FetchAdvocatesForAdvocateTaskListPageQueryQueryVariables
    >(FetchAdvocatesForAdvocateTaskListPageQueryDocument, options);
}
export type FetchAdvocatesForAdvocateTaskListPageQueryQueryHookResult = ReturnType<
    typeof useFetchAdvocatesForAdvocateTaskListPageQueryQuery
>;
export type FetchAdvocatesForAdvocateTaskListPageQueryLazyQueryHookResult = ReturnType<
    typeof useFetchAdvocatesForAdvocateTaskListPageQueryLazyQuery
>;
export type FetchAdvocatesForAdvocateTaskListPageQueryQueryResult = Apollo.QueryResult<
    FetchAdvocatesForAdvocateTaskListPageQueryQuery,
    FetchAdvocatesForAdvocateTaskListPageQueryQueryVariables
>;
export const FetchUsersForAdvocateTaskListPageQueryDocument = gql`
    query FetchUsersForAdvocateTaskListPageQuery {
        usersV2 {
            results {
                id
                name
            }
        }
    }
`;

/**
 * __useFetchUsersForAdvocateTaskListPageQueryQuery__
 *
 * To run a query within a React component, call `useFetchUsersForAdvocateTaskListPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUsersForAdvocateTaskListPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUsersForAdvocateTaskListPageQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchUsersForAdvocateTaskListPageQueryQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchUsersForAdvocateTaskListPageQueryQuery,
        FetchUsersForAdvocateTaskListPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchUsersForAdvocateTaskListPageQueryQuery,
        FetchUsersForAdvocateTaskListPageQueryQueryVariables
    >(FetchUsersForAdvocateTaskListPageQueryDocument, options);
}
export function useFetchUsersForAdvocateTaskListPageQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchUsersForAdvocateTaskListPageQueryQuery,
        FetchUsersForAdvocateTaskListPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchUsersForAdvocateTaskListPageQueryQuery,
        FetchUsersForAdvocateTaskListPageQueryQueryVariables
    >(FetchUsersForAdvocateTaskListPageQueryDocument, options);
}
export type FetchUsersForAdvocateTaskListPageQueryQueryHookResult = ReturnType<
    typeof useFetchUsersForAdvocateTaskListPageQueryQuery
>;
export type FetchUsersForAdvocateTaskListPageQueryLazyQueryHookResult = ReturnType<
    typeof useFetchUsersForAdvocateTaskListPageQueryLazyQuery
>;
export type FetchUsersForAdvocateTaskListPageQueryQueryResult = Apollo.QueryResult<
    FetchUsersForAdvocateTaskListPageQueryQuery,
    FetchUsersForAdvocateTaskListPageQueryQueryVariables
>;
export const FetchReferenceLinksForAdvocateTasksListPageDocument = gql`
    query FetchReferenceLinksForAdvocateTasksListPage($input: GetReferenceLinksForPageInput!) {
        getReferenceLinksForPage(input: $input) {
            category
            data {
                label
                url
            }
        }
    }
`;

/**
 * __useFetchReferenceLinksForAdvocateTasksListPageQuery__
 *
 * To run a query within a React component, call `useFetchReferenceLinksForAdvocateTasksListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchReferenceLinksForAdvocateTasksListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchReferenceLinksForAdvocateTasksListPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchReferenceLinksForAdvocateTasksListPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchReferenceLinksForAdvocateTasksListPageQuery,
        FetchReferenceLinksForAdvocateTasksListPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchReferenceLinksForAdvocateTasksListPageQuery,
        FetchReferenceLinksForAdvocateTasksListPageQueryVariables
    >(FetchReferenceLinksForAdvocateTasksListPageDocument, options);
}
export function useFetchReferenceLinksForAdvocateTasksListPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchReferenceLinksForAdvocateTasksListPageQuery,
        FetchReferenceLinksForAdvocateTasksListPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchReferenceLinksForAdvocateTasksListPageQuery,
        FetchReferenceLinksForAdvocateTasksListPageQueryVariables
    >(FetchReferenceLinksForAdvocateTasksListPageDocument, options);
}
export type FetchReferenceLinksForAdvocateTasksListPageQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForAdvocateTasksListPageQuery
>;
export type FetchReferenceLinksForAdvocateTasksListPageLazyQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForAdvocateTasksListPageLazyQuery
>;
export type FetchReferenceLinksForAdvocateTasksListPageQueryResult = Apollo.QueryResult<
    FetchReferenceLinksForAdvocateTasksListPageQuery,
    FetchReferenceLinksForAdvocateTasksListPageQueryVariables
>;
export const FetchApptByIdForApptModalDocument = gql`
    query FetchApptByIdForApptModal($input: GetAppointmentInput!) {
        appointment(input: $input) {
            ...AppointmentFragmentOnApptPage
        }
    }
    ${AppointmentFragmentOnApptPageFragmentDoc}
`;

/**
 * __useFetchApptByIdForApptModalQuery__
 *
 * To run a query within a React component, call `useFetchApptByIdForApptModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchApptByIdForApptModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchApptByIdForApptModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchApptByIdForApptModalQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchApptByIdForApptModalQuery,
        FetchApptByIdForApptModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchApptByIdForApptModalQuery, FetchApptByIdForApptModalQueryVariables>(
        FetchApptByIdForApptModalDocument,
        options,
    );
}
export function useFetchApptByIdForApptModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchApptByIdForApptModalQuery,
        FetchApptByIdForApptModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchApptByIdForApptModalQuery,
        FetchApptByIdForApptModalQueryVariables
    >(FetchApptByIdForApptModalDocument, options);
}
export type FetchApptByIdForApptModalQueryHookResult = ReturnType<
    typeof useFetchApptByIdForApptModalQuery
>;
export type FetchApptByIdForApptModalLazyQueryHookResult = ReturnType<
    typeof useFetchApptByIdForApptModalLazyQuery
>;
export type FetchApptByIdForApptModalQueryResult = Apollo.QueryResult<
    FetchApptByIdForApptModalQuery,
    FetchApptByIdForApptModalQueryVariables
>;
export const FetchCurrentUserForApptModalDocument = gql`
    query FetchCurrentUserForApptModal {
        currentUser {
            id
            name
            email
            currentOrg {
                id
                parentId
                brandingName
                timezone
            }
            currentOrganizations {
                id
                parentId
                brandingName
            }
            patients {
                id
                firstName
                lastName
            }
            users {
                id
                name
            }
        }
    }
`;

/**
 * __useFetchCurrentUserForApptModalQuery__
 *
 * To run a query within a React component, call `useFetchCurrentUserForApptModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCurrentUserForApptModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCurrentUserForApptModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCurrentUserForApptModalQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchCurrentUserForApptModalQuery,
        FetchCurrentUserForApptModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchCurrentUserForApptModalQuery,
        FetchCurrentUserForApptModalQueryVariables
    >(FetchCurrentUserForApptModalDocument, options);
}
export function useFetchCurrentUserForApptModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchCurrentUserForApptModalQuery,
        FetchCurrentUserForApptModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchCurrentUserForApptModalQuery,
        FetchCurrentUserForApptModalQueryVariables
    >(FetchCurrentUserForApptModalDocument, options);
}
export type FetchCurrentUserForApptModalQueryHookResult = ReturnType<
    typeof useFetchCurrentUserForApptModalQuery
>;
export type FetchCurrentUserForApptModalLazyQueryHookResult = ReturnType<
    typeof useFetchCurrentUserForApptModalLazyQuery
>;
export type FetchCurrentUserForApptModalQueryResult = Apollo.QueryResult<
    FetchCurrentUserForApptModalQuery,
    FetchCurrentUserForApptModalQueryVariables
>;
export const CreateNewApptFromApptModalDocument = gql`
    mutation CreateNewApptFromApptModal($input: AppointmentInput!) {
        createAppointment(input: $input) {
            message
            success
            resourceCreated {
                ...AppointmentFragmentOnApptPage
            }
        }
    }
    ${AppointmentFragmentOnApptPageFragmentDoc}
`;
export type CreateNewApptFromApptModalMutationFn = Apollo.MutationFunction<
    CreateNewApptFromApptModalMutation,
    CreateNewApptFromApptModalMutationVariables
>;

/**
 * __useCreateNewApptFromApptModalMutation__
 *
 * To run a mutation, you first call `useCreateNewApptFromApptModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewApptFromApptModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewApptFromApptModalMutation, { data, loading, error }] = useCreateNewApptFromApptModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewApptFromApptModalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateNewApptFromApptModalMutation,
        CreateNewApptFromApptModalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateNewApptFromApptModalMutation,
        CreateNewApptFromApptModalMutationVariables
    >(CreateNewApptFromApptModalDocument, options);
}
export type CreateNewApptFromApptModalMutationHookResult = ReturnType<
    typeof useCreateNewApptFromApptModalMutation
>;
export type CreateNewApptFromApptModalMutationResult =
    Apollo.MutationResult<CreateNewApptFromApptModalMutation>;
export type CreateNewApptFromApptModalMutationOptions = Apollo.BaseMutationOptions<
    CreateNewApptFromApptModalMutation,
    CreateNewApptFromApptModalMutationVariables
>;
export const UpdateNewApptFromApptModalDocument = gql`
    mutation UpdateNewApptFromApptModal($input: UpdateAppointmentInput!) {
        updateAppointment(input: $input) {
            message
            success
            resourceUpdated {
                ...AppointmentFragmentOnApptPage
            }
        }
    }
    ${AppointmentFragmentOnApptPageFragmentDoc}
`;
export type UpdateNewApptFromApptModalMutationFn = Apollo.MutationFunction<
    UpdateNewApptFromApptModalMutation,
    UpdateNewApptFromApptModalMutationVariables
>;

/**
 * __useUpdateNewApptFromApptModalMutation__
 *
 * To run a mutation, you first call `useUpdateNewApptFromApptModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNewApptFromApptModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNewApptFromApptModalMutation, { data, loading, error }] = useUpdateNewApptFromApptModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNewApptFromApptModalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateNewApptFromApptModalMutation,
        UpdateNewApptFromApptModalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateNewApptFromApptModalMutation,
        UpdateNewApptFromApptModalMutationVariables
    >(UpdateNewApptFromApptModalDocument, options);
}
export type UpdateNewApptFromApptModalMutationHookResult = ReturnType<
    typeof useUpdateNewApptFromApptModalMutation
>;
export type UpdateNewApptFromApptModalMutationResult =
    Apollo.MutationResult<UpdateNewApptFromApptModalMutation>;
export type UpdateNewApptFromApptModalMutationOptions = Apollo.BaseMutationOptions<
    UpdateNewApptFromApptModalMutation,
    UpdateNewApptFromApptModalMutationVariables
>;
export const FetchApptsForApptsPageQueryDocument = gql`
    query FetchApptsForApptsPageQuery($input: UserAppointmentsInput) {
        currentUser {
            id
            currentOrg {
                id
                brandingName
            }
            appointmentsV2(input: $input) {
                hasMore
                hasPrevious
                limit
                orderBy
                skip
                total
                results {
                    ...AppointmentFragmentOnApptPage
                }
            }
        }
    }
    ${AppointmentFragmentOnApptPageFragmentDoc}
`;

/**
 * __useFetchApptsForApptsPageQueryQuery__
 *
 * To run a query within a React component, call `useFetchApptsForApptsPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchApptsForApptsPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchApptsForApptsPageQueryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchApptsForApptsPageQueryQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchApptsForApptsPageQueryQuery,
        FetchApptsForApptsPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchApptsForApptsPageQueryQuery,
        FetchApptsForApptsPageQueryQueryVariables
    >(FetchApptsForApptsPageQueryDocument, options);
}
export function useFetchApptsForApptsPageQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchApptsForApptsPageQueryQuery,
        FetchApptsForApptsPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchApptsForApptsPageQueryQuery,
        FetchApptsForApptsPageQueryQueryVariables
    >(FetchApptsForApptsPageQueryDocument, options);
}
export type FetchApptsForApptsPageQueryQueryHookResult = ReturnType<
    typeof useFetchApptsForApptsPageQueryQuery
>;
export type FetchApptsForApptsPageQueryLazyQueryHookResult = ReturnType<
    typeof useFetchApptsForApptsPageQueryLazyQuery
>;
export type FetchApptsForApptsPageQueryQueryResult = Apollo.QueryResult<
    FetchApptsForApptsPageQueryQuery,
    FetchApptsForApptsPageQueryQueryVariables
>;
export const DeleteAppointmentByIdDocument = gql`
    mutation DeleteAppointmentById($input: DeleteAppointmentInput!) {
        deleteAppointment(input: $input) {
            success
            message
            status
            id
        }
    }
`;
export type DeleteAppointmentByIdMutationFn = Apollo.MutationFunction<
    DeleteAppointmentByIdMutation,
    DeleteAppointmentByIdMutationVariables
>;

/**
 * __useDeleteAppointmentByIdMutation__
 *
 * To run a mutation, you first call `useDeleteAppointmentByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppointmentByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppointmentByIdMutation, { data, loading, error }] = useDeleteAppointmentByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAppointmentByIdMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteAppointmentByIdMutation,
        DeleteAppointmentByIdMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteAppointmentByIdMutation,
        DeleteAppointmentByIdMutationVariables
    >(DeleteAppointmentByIdDocument, options);
}
export type DeleteAppointmentByIdMutationHookResult = ReturnType<
    typeof useDeleteAppointmentByIdMutation
>;
export type DeleteAppointmentByIdMutationResult =
    Apollo.MutationResult<DeleteAppointmentByIdMutation>;
export type DeleteAppointmentByIdMutationOptions = Apollo.BaseMutationOptions<
    DeleteAppointmentByIdMutation,
    DeleteAppointmentByIdMutationVariables
>;
export const GetPatientReportByKeyDocument = gql`
    query GetPatientReportByKey($input: S3GetObjectInput!) {
        getPatientReportByKey(input: $input) {
            data
            filename
        }
    }
`;

/**
 * __useGetPatientReportByKeyQuery__
 *
 * To run a query within a React component, call `useGetPatientReportByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientReportByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientReportByKeyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPatientReportByKeyQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetPatientReportByKeyQuery,
        GetPatientReportByKeyQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetPatientReportByKeyQuery, GetPatientReportByKeyQueryVariables>(
        GetPatientReportByKeyDocument,
        options,
    );
}
export function useGetPatientReportByKeyLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetPatientReportByKeyQuery,
        GetPatientReportByKeyQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetPatientReportByKeyQuery, GetPatientReportByKeyQueryVariables>(
        GetPatientReportByKeyDocument,
        options,
    );
}
export type GetPatientReportByKeyQueryHookResult = ReturnType<typeof useGetPatientReportByKeyQuery>;
export type GetPatientReportByKeyLazyQueryHookResult = ReturnType<
    typeof useGetPatientReportByKeyLazyQuery
>;
export type GetPatientReportByKeyQueryResult = Apollo.QueryResult<
    GetPatientReportByKeyQuery,
    GetPatientReportByKeyQueryVariables
>;
export const FetchLoadingStateDocument = gql`
    query FetchLoadingState {
        GlobalLoading @client {
            open
            message
        }
    }
`;

/**
 * __useFetchLoadingStateQuery__
 *
 * To run a query within a React component, call `useFetchLoadingStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchLoadingStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchLoadingStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchLoadingStateQuery(
    baseOptions?: Apollo.QueryHookOptions<FetchLoadingStateQuery, FetchLoadingStateQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchLoadingStateQuery, FetchLoadingStateQueryVariables>(
        FetchLoadingStateDocument,
        options,
    );
}
export function useFetchLoadingStateLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchLoadingStateQuery,
        FetchLoadingStateQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchLoadingStateQuery, FetchLoadingStateQueryVariables>(
        FetchLoadingStateDocument,
        options,
    );
}
export type FetchLoadingStateQueryHookResult = ReturnType<typeof useFetchLoadingStateQuery>;
export type FetchLoadingStateLazyQueryHookResult = ReturnType<typeof useFetchLoadingStateLazyQuery>;
export type FetchLoadingStateQueryResult = Apollo.QueryResult<
    FetchLoadingStateQuery,
    FetchLoadingStateQueryVariables
>;
export const FetchTagsForFilterModalPageDocument = gql`
    query FetchTagsForFilterModalPage($input: TagQueryInput) {
        tagsV2(input: $input) {
            results {
                id
                name
                isPatientFilter
            }
        }
    }
`;

/**
 * __useFetchTagsForFilterModalPageQuery__
 *
 * To run a query within a React component, call `useFetchTagsForFilterModalPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTagsForFilterModalPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTagsForFilterModalPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchTagsForFilterModalPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchTagsForFilterModalPageQuery,
        FetchTagsForFilterModalPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchTagsForFilterModalPageQuery,
        FetchTagsForFilterModalPageQueryVariables
    >(FetchTagsForFilterModalPageDocument, options);
}
export function useFetchTagsForFilterModalPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchTagsForFilterModalPageQuery,
        FetchTagsForFilterModalPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchTagsForFilterModalPageQuery,
        FetchTagsForFilterModalPageQueryVariables
    >(FetchTagsForFilterModalPageDocument, options);
}
export type FetchTagsForFilterModalPageQueryHookResult = ReturnType<
    typeof useFetchTagsForFilterModalPageQuery
>;
export type FetchTagsForFilterModalPageLazyQueryHookResult = ReturnType<
    typeof useFetchTagsForFilterModalPageLazyQuery
>;
export type FetchTagsForFilterModalPageQueryResult = Apollo.QueryResult<
    FetchTagsForFilterModalPageQuery,
    FetchTagsForFilterModalPageQueryVariables
>;
export const GetUsersByPermissionForFilterModalPageDocument = gql`
    query GetUsersByPermissionForFilterModalPage($permission: String) {
        getUsersByPermission(permission: $permission) {
            id
            name
        }
    }
`;

/**
 * __useGetUsersByPermissionForFilterModalPageQuery__
 *
 * To run a query within a React component, call `useGetUsersByPermissionForFilterModalPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByPermissionForFilterModalPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByPermissionForFilterModalPageQuery({
 *   variables: {
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useGetUsersByPermissionForFilterModalPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetUsersByPermissionForFilterModalPageQuery,
        GetUsersByPermissionForFilterModalPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetUsersByPermissionForFilterModalPageQuery,
        GetUsersByPermissionForFilterModalPageQueryVariables
    >(GetUsersByPermissionForFilterModalPageDocument, options);
}
export function useGetUsersByPermissionForFilterModalPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetUsersByPermissionForFilterModalPageQuery,
        GetUsersByPermissionForFilterModalPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetUsersByPermissionForFilterModalPageQuery,
        GetUsersByPermissionForFilterModalPageQueryVariables
    >(GetUsersByPermissionForFilterModalPageDocument, options);
}
export type GetUsersByPermissionForFilterModalPageQueryHookResult = ReturnType<
    typeof useGetUsersByPermissionForFilterModalPageQuery
>;
export type GetUsersByPermissionForFilterModalPageLazyQueryHookResult = ReturnType<
    typeof useGetUsersByPermissionForFilterModalPageLazyQuery
>;
export type GetUsersByPermissionForFilterModalPageQueryResult = Apollo.QueryResult<
    GetUsersByPermissionForFilterModalPageQuery,
    GetUsersByPermissionForFilterModalPageQueryVariables
>;
export const UpdateMeasurementReviewedDocument = gql`
    mutation UpdateMeasurementReviewed($input: UpdateMeasurementReviewedInput!) {
        UpdateMeasurementReviewed(input: $input) {
            status
            message
            success
            resourceUpdated {
                ...MeasurementForMeasurementsPage
            }
        }
    }
    ${MeasurementForMeasurementsPageFragmentDoc}
`;
export type UpdateMeasurementReviewedMutationFn = Apollo.MutationFunction<
    UpdateMeasurementReviewedMutation,
    UpdateMeasurementReviewedMutationVariables
>;

/**
 * __useUpdateMeasurementReviewedMutation__
 *
 * To run a mutation, you first call `useUpdateMeasurementReviewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeasurementReviewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeasurementReviewedMutation, { data, loading, error }] = useUpdateMeasurementReviewedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeasurementReviewedMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateMeasurementReviewedMutation,
        UpdateMeasurementReviewedMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateMeasurementReviewedMutation,
        UpdateMeasurementReviewedMutationVariables
    >(UpdateMeasurementReviewedDocument, options);
}
export type UpdateMeasurementReviewedMutationHookResult = ReturnType<
    typeof useUpdateMeasurementReviewedMutation
>;
export type UpdateMeasurementReviewedMutationResult =
    Apollo.MutationResult<UpdateMeasurementReviewedMutation>;
export type UpdateMeasurementReviewedMutationOptions = Apollo.BaseMutationOptions<
    UpdateMeasurementReviewedMutation,
    UpdateMeasurementReviewedMutationVariables
>;
export const FetchMeasurementsForMeasurementsPageQueryDocument = gql`
    query FetchMeasurementsForMeasurementsPageQuery($input: UserMeasurementsInput!) {
        currentUser {
            measurementsV2(input: $input) {
                orderBy
                skip
                limit
                total
                hasMore
                hasPrevious
                results {
                    ...MeasurementForMeasurementsPage
                }
            }
        }
    }
    ${MeasurementForMeasurementsPageFragmentDoc}
`;

/**
 * __useFetchMeasurementsForMeasurementsPageQueryQuery__
 *
 * To run a query within a React component, call `useFetchMeasurementsForMeasurementsPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeasurementsForMeasurementsPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeasurementsForMeasurementsPageQueryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchMeasurementsForMeasurementsPageQueryQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchMeasurementsForMeasurementsPageQueryQuery,
        FetchMeasurementsForMeasurementsPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchMeasurementsForMeasurementsPageQueryQuery,
        FetchMeasurementsForMeasurementsPageQueryQueryVariables
    >(FetchMeasurementsForMeasurementsPageQueryDocument, options);
}
export function useFetchMeasurementsForMeasurementsPageQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchMeasurementsForMeasurementsPageQueryQuery,
        FetchMeasurementsForMeasurementsPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchMeasurementsForMeasurementsPageQueryQuery,
        FetchMeasurementsForMeasurementsPageQueryQueryVariables
    >(FetchMeasurementsForMeasurementsPageQueryDocument, options);
}
export type FetchMeasurementsForMeasurementsPageQueryQueryHookResult = ReturnType<
    typeof useFetchMeasurementsForMeasurementsPageQueryQuery
>;
export type FetchMeasurementsForMeasurementsPageQueryLazyQueryHookResult = ReturnType<
    typeof useFetchMeasurementsForMeasurementsPageQueryLazyQuery
>;
export type FetchMeasurementsForMeasurementsPageQueryQueryResult = Apollo.QueryResult<
    FetchMeasurementsForMeasurementsPageQueryQuery,
    FetchMeasurementsForMeasurementsPageQueryQueryVariables
>;
export const FetchReferenceLinksForMeasurmentsPageDocument = gql`
    query FetchReferenceLinksForMeasurmentsPage($input: GetReferenceLinksForPageInput!) {
        getReferenceLinksForPage(input: $input) {
            category
            data {
                label
                url
            }
        }
    }
`;

/**
 * __useFetchReferenceLinksForMeasurmentsPageQuery__
 *
 * To run a query within a React component, call `useFetchReferenceLinksForMeasurmentsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchReferenceLinksForMeasurmentsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchReferenceLinksForMeasurmentsPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchReferenceLinksForMeasurmentsPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchReferenceLinksForMeasurmentsPageQuery,
        FetchReferenceLinksForMeasurmentsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchReferenceLinksForMeasurmentsPageQuery,
        FetchReferenceLinksForMeasurmentsPageQueryVariables
    >(FetchReferenceLinksForMeasurmentsPageDocument, options);
}
export function useFetchReferenceLinksForMeasurmentsPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchReferenceLinksForMeasurmentsPageQuery,
        FetchReferenceLinksForMeasurmentsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchReferenceLinksForMeasurmentsPageQuery,
        FetchReferenceLinksForMeasurmentsPageQueryVariables
    >(FetchReferenceLinksForMeasurmentsPageDocument, options);
}
export type FetchReferenceLinksForMeasurmentsPageQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForMeasurmentsPageQuery
>;
export type FetchReferenceLinksForMeasurmentsPageLazyQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForMeasurmentsPageLazyQuery
>;
export type FetchReferenceLinksForMeasurmentsPageQueryResult = Apollo.QueryResult<
    FetchReferenceLinksForMeasurmentsPageQuery,
    FetchReferenceLinksForMeasurmentsPageQueryVariables
>;
export const FetchTrackerHistoryByRecordIdDocument = gql`
    query FetchTrackerHistoryByRecordId($uuid: String) {
        getTrackerHistoryByRecordId(uuid: $uuid) {
            updatedAt
            createdAt
            movement
            startDate
            endDate
            weight
            pulse
            diastolicBP
            systolicBP
            glucose
            moodLevel
            painLevel
            steps
            temperature
            sleepStart
            sleepEnd
            notes
            type
            timezoneName
        }
    }
`;

/**
 * __useFetchTrackerHistoryByRecordIdQuery__
 *
 * To run a query within a React component, call `useFetchTrackerHistoryByRecordIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTrackerHistoryByRecordIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTrackerHistoryByRecordIdQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useFetchTrackerHistoryByRecordIdQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchTrackerHistoryByRecordIdQuery,
        FetchTrackerHistoryByRecordIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchTrackerHistoryByRecordIdQuery,
        FetchTrackerHistoryByRecordIdQueryVariables
    >(FetchTrackerHistoryByRecordIdDocument, options);
}
export function useFetchTrackerHistoryByRecordIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchTrackerHistoryByRecordIdQuery,
        FetchTrackerHistoryByRecordIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchTrackerHistoryByRecordIdQuery,
        FetchTrackerHistoryByRecordIdQueryVariables
    >(FetchTrackerHistoryByRecordIdDocument, options);
}
export type FetchTrackerHistoryByRecordIdQueryHookResult = ReturnType<
    typeof useFetchTrackerHistoryByRecordIdQuery
>;
export type FetchTrackerHistoryByRecordIdLazyQueryHookResult = ReturnType<
    typeof useFetchTrackerHistoryByRecordIdLazyQuery
>;
export type FetchTrackerHistoryByRecordIdQueryResult = Apollo.QueryResult<
    FetchTrackerHistoryByRecordIdQuery,
    FetchTrackerHistoryByRecordIdQueryVariables
>;
export const ChatConversationsV2ForMessageCenterDocument = gql`
    query ChatConversationsV2ForMessageCenter($input: ChatConversationQueryInput) {
        chatConversationsV2(input: $input) {
            results {
                id
                startedAt
                resolvedAt
                chatRoomId
                resolutionTime
                createdAt
                messages {
                    id
                    text
                    attachments
                    senderId
                    read
                    chatRoomId
                    chatConversationId
                    createdAt
                    updatedAt
                }
            }
        }
    }
`;

/**
 * __useChatConversationsV2ForMessageCenterQuery__
 *
 * To run a query within a React component, call `useChatConversationsV2ForMessageCenterQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatConversationsV2ForMessageCenterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatConversationsV2ForMessageCenterQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatConversationsV2ForMessageCenterQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ChatConversationsV2ForMessageCenterQuery,
        ChatConversationsV2ForMessageCenterQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        ChatConversationsV2ForMessageCenterQuery,
        ChatConversationsV2ForMessageCenterQueryVariables
    >(ChatConversationsV2ForMessageCenterDocument, options);
}
export function useChatConversationsV2ForMessageCenterLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ChatConversationsV2ForMessageCenterQuery,
        ChatConversationsV2ForMessageCenterQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ChatConversationsV2ForMessageCenterQuery,
        ChatConversationsV2ForMessageCenterQueryVariables
    >(ChatConversationsV2ForMessageCenterDocument, options);
}
export type ChatConversationsV2ForMessageCenterQueryHookResult = ReturnType<
    typeof useChatConversationsV2ForMessageCenterQuery
>;
export type ChatConversationsV2ForMessageCenterLazyQueryHookResult = ReturnType<
    typeof useChatConversationsV2ForMessageCenterLazyQuery
>;
export type ChatConversationsV2ForMessageCenterQueryResult = Apollo.QueryResult<
    ChatConversationsV2ForMessageCenterQuery,
    ChatConversationsV2ForMessageCenterQueryVariables
>;
export const ResolveChatConversationForMessageCenterDocument = gql`
    mutation ResolveChatConversationForMessageCenter($input: ResolveChatConversationInput) {
        resolveChatConversation(input: $input) {
            status
            message
            success
            chatConversations {
                id
                startedAt
                resolvedAt
                chatRoomId
                resolutionTime
                createdAt
                messages {
                    id
                    text
                    attachments
                    senderId
                    read
                    chatRoomId
                    chatConversationId
                    createdAt
                    updatedAt
                }
            }
        }
    }
`;
export type ResolveChatConversationForMessageCenterMutationFn = Apollo.MutationFunction<
    ResolveChatConversationForMessageCenterMutation,
    ResolveChatConversationForMessageCenterMutationVariables
>;

/**
 * __useResolveChatConversationForMessageCenterMutation__
 *
 * To run a mutation, you first call `useResolveChatConversationForMessageCenterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveChatConversationForMessageCenterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveChatConversationForMessageCenterMutation, { data, loading, error }] = useResolveChatConversationForMessageCenterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResolveChatConversationForMessageCenterMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ResolveChatConversationForMessageCenterMutation,
        ResolveChatConversationForMessageCenterMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        ResolveChatConversationForMessageCenterMutation,
        ResolveChatConversationForMessageCenterMutationVariables
    >(ResolveChatConversationForMessageCenterDocument, options);
}
export type ResolveChatConversationForMessageCenterMutationHookResult = ReturnType<
    typeof useResolveChatConversationForMessageCenterMutation
>;
export type ResolveChatConversationForMessageCenterMutationResult =
    Apollo.MutationResult<ResolveChatConversationForMessageCenterMutation>;
export type ResolveChatConversationForMessageCenterMutationOptions = Apollo.BaseMutationOptions<
    ResolveChatConversationForMessageCenterMutation,
    ResolveChatConversationForMessageCenterMutationVariables
>;
export const MoveMessagesInChatConversationForMessageCenterDocument = gql`
    mutation MoveMessagesInChatConversationForMessageCenter(
        $input: MoveMessagesInChatConversationInput
    ) {
        moveMessagesInChatConversation(input: $input) {
            status
            message
            success
            chatConversations {
                id
                startedAt
                resolvedAt
                chatRoomId
                resolutionTime
                createdAt
                messages {
                    id
                    text
                    attachments
                    senderId
                    read
                    chatRoomId
                    chatConversationId
                    createdAt
                    updatedAt
                }
            }
        }
    }
`;
export type MoveMessagesInChatConversationForMessageCenterMutationFn = Apollo.MutationFunction<
    MoveMessagesInChatConversationForMessageCenterMutation,
    MoveMessagesInChatConversationForMessageCenterMutationVariables
>;

/**
 * __useMoveMessagesInChatConversationForMessageCenterMutation__
 *
 * To run a mutation, you first call `useMoveMessagesInChatConversationForMessageCenterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveMessagesInChatConversationForMessageCenterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveMessagesInChatConversationForMessageCenterMutation, { data, loading, error }] = useMoveMessagesInChatConversationForMessageCenterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveMessagesInChatConversationForMessageCenterMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MoveMessagesInChatConversationForMessageCenterMutation,
        MoveMessagesInChatConversationForMessageCenterMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MoveMessagesInChatConversationForMessageCenterMutation,
        MoveMessagesInChatConversationForMessageCenterMutationVariables
    >(MoveMessagesInChatConversationForMessageCenterDocument, options);
}
export type MoveMessagesInChatConversationForMessageCenterMutationHookResult = ReturnType<
    typeof useMoveMessagesInChatConversationForMessageCenterMutation
>;
export type MoveMessagesInChatConversationForMessageCenterMutationResult =
    Apollo.MutationResult<MoveMessagesInChatConversationForMessageCenterMutation>;
export type MoveMessagesInChatConversationForMessageCenterMutationOptions =
    Apollo.BaseMutationOptions<
        MoveMessagesInChatConversationForMessageCenterMutation,
        MoveMessagesInChatConversationForMessageCenterMutationVariables
    >;
export const FetchChatRoomMessagesForMessageCenterDocument = gql`
    query FetchChatRoomMessagesForMessageCenter($input: GetChatRoomInput!) {
        chatRoom(input: $input) {
            id
            numberOfUnreadMessages
            careTeamMemberTypeId
            careTeamMemberType {
                name
            }
            patient {
                id
                firstName
                lastName
                practice {
                    name
                }
            }
            participants {
                id
                name
            }
            messages {
                ...ChatMessageForMessageCenter
            }
        }
    }
    ${ChatMessageForMessageCenterFragmentDoc}
`;

/**
 * __useFetchChatRoomMessagesForMessageCenterQuery__
 *
 * To run a query within a React component, call `useFetchChatRoomMessagesForMessageCenterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchChatRoomMessagesForMessageCenterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchChatRoomMessagesForMessageCenterQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchChatRoomMessagesForMessageCenterQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchChatRoomMessagesForMessageCenterQuery,
        FetchChatRoomMessagesForMessageCenterQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchChatRoomMessagesForMessageCenterQuery,
        FetchChatRoomMessagesForMessageCenterQueryVariables
    >(FetchChatRoomMessagesForMessageCenterDocument, options);
}
export function useFetchChatRoomMessagesForMessageCenterLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchChatRoomMessagesForMessageCenterQuery,
        FetchChatRoomMessagesForMessageCenterQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchChatRoomMessagesForMessageCenterQuery,
        FetchChatRoomMessagesForMessageCenterQueryVariables
    >(FetchChatRoomMessagesForMessageCenterDocument, options);
}
export type FetchChatRoomMessagesForMessageCenterQueryHookResult = ReturnType<
    typeof useFetchChatRoomMessagesForMessageCenterQuery
>;
export type FetchChatRoomMessagesForMessageCenterLazyQueryHookResult = ReturnType<
    typeof useFetchChatRoomMessagesForMessageCenterLazyQuery
>;
export type FetchChatRoomMessagesForMessageCenterQueryResult = Apollo.QueryResult<
    FetchChatRoomMessagesForMessageCenterQuery,
    FetchChatRoomMessagesForMessageCenterQueryVariables
>;
export const UpdateParticipantsForChatRoomDocument = gql`
    mutation UpdateParticipantsForChatRoom($input: UpdateParticipantsForChatRoomInput!) {
        updateParticipants(input: $input) {
            success
            chatRoom {
                participants {
                    id
                    name
                }
            }
        }
    }
`;
export type UpdateParticipantsForChatRoomMutationFn = Apollo.MutationFunction<
    UpdateParticipantsForChatRoomMutation,
    UpdateParticipantsForChatRoomMutationVariables
>;

/**
 * __useUpdateParticipantsForChatRoomMutation__
 *
 * To run a mutation, you first call `useUpdateParticipantsForChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParticipantsForChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParticipantsForChatRoomMutation, { data, loading, error }] = useUpdateParticipantsForChatRoomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateParticipantsForChatRoomMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateParticipantsForChatRoomMutation,
        UpdateParticipantsForChatRoomMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateParticipantsForChatRoomMutation,
        UpdateParticipantsForChatRoomMutationVariables
    >(UpdateParticipantsForChatRoomDocument, options);
}
export type UpdateParticipantsForChatRoomMutationHookResult = ReturnType<
    typeof useUpdateParticipantsForChatRoomMutation
>;
export type UpdateParticipantsForChatRoomMutationResult =
    Apollo.MutationResult<UpdateParticipantsForChatRoomMutation>;
export type UpdateParticipantsForChatRoomMutationOptions = Apollo.BaseMutationOptions<
    UpdateParticipantsForChatRoomMutation,
    UpdateParticipantsForChatRoomMutationVariables
>;
export const UpdateReadMessagesForChatRoomDocument = gql`
    mutation UpdateReadMessagesForChatRoom($input: UpdateReadeMessagesForChatRoomInput!) {
        updateReadMessages(input: $input) {
            message
            success
            chatRoom {
                id
                numberOfUnreadMessages
                careTeamMemberTypeId
                patient {
                    id
                    firstName
                    lastName
                }
                participants {
                    id
                    name
                }
                messages {
                    ...ChatMessageForMessageCenter
                }
            }
        }
    }
    ${ChatMessageForMessageCenterFragmentDoc}
`;
export type UpdateReadMessagesForChatRoomMutationFn = Apollo.MutationFunction<
    UpdateReadMessagesForChatRoomMutation,
    UpdateReadMessagesForChatRoomMutationVariables
>;

/**
 * __useUpdateReadMessagesForChatRoomMutation__
 *
 * To run a mutation, you first call `useUpdateReadMessagesForChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReadMessagesForChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReadMessagesForChatRoomMutation, { data, loading, error }] = useUpdateReadMessagesForChatRoomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReadMessagesForChatRoomMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateReadMessagesForChatRoomMutation,
        UpdateReadMessagesForChatRoomMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateReadMessagesForChatRoomMutation,
        UpdateReadMessagesForChatRoomMutationVariables
    >(UpdateReadMessagesForChatRoomDocument, options);
}
export type UpdateReadMessagesForChatRoomMutationHookResult = ReturnType<
    typeof useUpdateReadMessagesForChatRoomMutation
>;
export type UpdateReadMessagesForChatRoomMutationResult =
    Apollo.MutationResult<UpdateReadMessagesForChatRoomMutation>;
export type UpdateReadMessagesForChatRoomMutationOptions = Apollo.BaseMutationOptions<
    UpdateReadMessagesForChatRoomMutation,
    UpdateReadMessagesForChatRoomMutationVariables
>;
export const SubscribeToNewChatMessagesForChatRoomDocument = gql`
    subscription SubscribeToNewChatMessagesForChatRoom($input: NewMessageInput!) {
        newMessage(input: $input) {
            ...ChatMessageForMessageCenter
        }
    }
    ${ChatMessageForMessageCenterFragmentDoc}
`;

/**
 * __useSubscribeToNewChatMessagesForChatRoomSubscription__
 *
 * To run a query within a React component, call `useSubscribeToNewChatMessagesForChatRoomSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToNewChatMessagesForChatRoomSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToNewChatMessagesForChatRoomSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeToNewChatMessagesForChatRoomSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        SubscribeToNewChatMessagesForChatRoomSubscription,
        SubscribeToNewChatMessagesForChatRoomSubscriptionVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<
        SubscribeToNewChatMessagesForChatRoomSubscription,
        SubscribeToNewChatMessagesForChatRoomSubscriptionVariables
    >(SubscribeToNewChatMessagesForChatRoomDocument, options);
}
export type SubscribeToNewChatMessagesForChatRoomSubscriptionHookResult = ReturnType<
    typeof useSubscribeToNewChatMessagesForChatRoomSubscription
>;
export type SubscribeToNewChatMessagesForChatRoomSubscriptionResult =
    Apollo.SubscriptionResult<SubscribeToNewChatMessagesForChatRoomSubscription>;
export const CreateChatMessageForMessageCenterDocument = gql`
    mutation CreateChatMessageForMessageCenter($input: ChatMessageInput!) {
        createChatMessageEncoded(input: $input) {
            message
            success
            chatMessage {
                ...ChatMessageForMessageCenter
            }
        }
    }
    ${ChatMessageForMessageCenterFragmentDoc}
`;
export type CreateChatMessageForMessageCenterMutationFn = Apollo.MutationFunction<
    CreateChatMessageForMessageCenterMutation,
    CreateChatMessageForMessageCenterMutationVariables
>;

/**
 * __useCreateChatMessageForMessageCenterMutation__
 *
 * To run a mutation, you first call `useCreateChatMessageForMessageCenterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatMessageForMessageCenterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatMessageForMessageCenterMutation, { data, loading, error }] = useCreateChatMessageForMessageCenterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChatMessageForMessageCenterMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateChatMessageForMessageCenterMutation,
        CreateChatMessageForMessageCenterMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateChatMessageForMessageCenterMutation,
        CreateChatMessageForMessageCenterMutationVariables
    >(CreateChatMessageForMessageCenterDocument, options);
}
export type CreateChatMessageForMessageCenterMutationHookResult = ReturnType<
    typeof useCreateChatMessageForMessageCenterMutation
>;
export type CreateChatMessageForMessageCenterMutationResult =
    Apollo.MutationResult<CreateChatMessageForMessageCenterMutation>;
export type CreateChatMessageForMessageCenterMutationOptions = Apollo.BaseMutationOptions<
    CreateChatMessageForMessageCenterMutation,
    CreateChatMessageForMessageCenterMutationVariables
>;
export const FetchCurrentUserForChatMessagesDocument = gql`
    query FetchCurrentUserForChatMessages {
        currentUser {
            id
            name
        }
    }
`;

/**
 * __useFetchCurrentUserForChatMessagesQuery__
 *
 * To run a query within a React component, call `useFetchCurrentUserForChatMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCurrentUserForChatMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCurrentUserForChatMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCurrentUserForChatMessagesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchCurrentUserForChatMessagesQuery,
        FetchCurrentUserForChatMessagesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchCurrentUserForChatMessagesQuery,
        FetchCurrentUserForChatMessagesQueryVariables
    >(FetchCurrentUserForChatMessagesDocument, options);
}
export function useFetchCurrentUserForChatMessagesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchCurrentUserForChatMessagesQuery,
        FetchCurrentUserForChatMessagesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchCurrentUserForChatMessagesQuery,
        FetchCurrentUserForChatMessagesQueryVariables
    >(FetchCurrentUserForChatMessagesDocument, options);
}
export type FetchCurrentUserForChatMessagesQueryHookResult = ReturnType<
    typeof useFetchCurrentUserForChatMessagesQuery
>;
export type FetchCurrentUserForChatMessagesLazyQueryHookResult = ReturnType<
    typeof useFetchCurrentUserForChatMessagesLazyQuery
>;
export type FetchCurrentUserForChatMessagesQueryResult = Apollo.QueryResult<
    FetchCurrentUserForChatMessagesQuery,
    FetchCurrentUserForChatMessagesQueryVariables
>;
export const FetchChatRoomsForMessageCenterDocument = gql`
    query FetchChatRoomsForMessageCenter($input: UserChatRoomsInput) {
        currentUser {
            id
            name
            email
            currentOrg {
                id
                parentId
                brandingName
            }
            chatRooms(input: $input) {
                id
                numberOfUnreadMessages
                careTeamMemberTypeId
                careTeamMemberType {
                    name
                }
                patient {
                    id
                    firstName
                    lastName
                    fullName
                    birthDate
                    email
                    phoneNumber
                    mailingAddress {
                        code
                    }
                    practice {
                        name
                    }
                }
                participants {
                    id
                    name
                }
                latestMessage {
                    ...ChatMessageForMessageCenter
                }
            }
        }
    }
    ${ChatMessageForMessageCenterFragmentDoc}
`;

/**
 * __useFetchChatRoomsForMessageCenterQuery__
 *
 * To run a query within a React component, call `useFetchChatRoomsForMessageCenterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchChatRoomsForMessageCenterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchChatRoomsForMessageCenterQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchChatRoomsForMessageCenterQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchChatRoomsForMessageCenterQuery,
        FetchChatRoomsForMessageCenterQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchChatRoomsForMessageCenterQuery,
        FetchChatRoomsForMessageCenterQueryVariables
    >(FetchChatRoomsForMessageCenterDocument, options);
}
export function useFetchChatRoomsForMessageCenterLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchChatRoomsForMessageCenterQuery,
        FetchChatRoomsForMessageCenterQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchChatRoomsForMessageCenterQuery,
        FetchChatRoomsForMessageCenterQueryVariables
    >(FetchChatRoomsForMessageCenterDocument, options);
}
export type FetchChatRoomsForMessageCenterQueryHookResult = ReturnType<
    typeof useFetchChatRoomsForMessageCenterQuery
>;
export type FetchChatRoomsForMessageCenterLazyQueryHookResult = ReturnType<
    typeof useFetchChatRoomsForMessageCenterLazyQuery
>;
export type FetchChatRoomsForMessageCenterQueryResult = Apollo.QueryResult<
    FetchChatRoomsForMessageCenterQuery,
    FetchChatRoomsForMessageCenterQueryVariables
>;
export const FetchChatMessagesForMessageCenterDocument = gql`
    query FetchChatMessagesForMessageCenter($input: ChatMessageQueryInput) {
        chatMessagesV2(input: $input) {
            results {
                text
                senderId
                createdAt
                chatRoomId
                attachments
                read
                updatedAt
                id
                cacheId
            }
        }
    }
`;

/**
 * __useFetchChatMessagesForMessageCenterQuery__
 *
 * To run a query within a React component, call `useFetchChatMessagesForMessageCenterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchChatMessagesForMessageCenterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchChatMessagesForMessageCenterQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchChatMessagesForMessageCenterQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchChatMessagesForMessageCenterQuery,
        FetchChatMessagesForMessageCenterQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchChatMessagesForMessageCenterQuery,
        FetchChatMessagesForMessageCenterQueryVariables
    >(FetchChatMessagesForMessageCenterDocument, options);
}
export function useFetchChatMessagesForMessageCenterLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchChatMessagesForMessageCenterQuery,
        FetchChatMessagesForMessageCenterQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchChatMessagesForMessageCenterQuery,
        FetchChatMessagesForMessageCenterQueryVariables
    >(FetchChatMessagesForMessageCenterDocument, options);
}
export type FetchChatMessagesForMessageCenterQueryHookResult = ReturnType<
    typeof useFetchChatMessagesForMessageCenterQuery
>;
export type FetchChatMessagesForMessageCenterLazyQueryHookResult = ReturnType<
    typeof useFetchChatMessagesForMessageCenterLazyQuery
>;
export type FetchChatMessagesForMessageCenterQueryResult = Apollo.QueryResult<
    FetchChatMessagesForMessageCenterQuery,
    FetchChatMessagesForMessageCenterQueryVariables
>;
export const FetchReferenceLinksForMessageCenterPageDocument = gql`
    query FetchReferenceLinksForMessageCenterPage($input: GetReferenceLinksForPageInput!) {
        getReferenceLinksForPage(input: $input) {
            category
            data {
                label
                url
            }
        }
    }
`;

/**
 * __useFetchReferenceLinksForMessageCenterPageQuery__
 *
 * To run a query within a React component, call `useFetchReferenceLinksForMessageCenterPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchReferenceLinksForMessageCenterPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchReferenceLinksForMessageCenterPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchReferenceLinksForMessageCenterPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchReferenceLinksForMessageCenterPageQuery,
        FetchReferenceLinksForMessageCenterPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchReferenceLinksForMessageCenterPageQuery,
        FetchReferenceLinksForMessageCenterPageQueryVariables
    >(FetchReferenceLinksForMessageCenterPageDocument, options);
}
export function useFetchReferenceLinksForMessageCenterPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchReferenceLinksForMessageCenterPageQuery,
        FetchReferenceLinksForMessageCenterPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchReferenceLinksForMessageCenterPageQuery,
        FetchReferenceLinksForMessageCenterPageQueryVariables
    >(FetchReferenceLinksForMessageCenterPageDocument, options);
}
export type FetchReferenceLinksForMessageCenterPageQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForMessageCenterPageQuery
>;
export type FetchReferenceLinksForMessageCenterPageLazyQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForMessageCenterPageLazyQuery
>;
export type FetchReferenceLinksForMessageCenterPageQueryResult = Apollo.QueryResult<
    FetchReferenceLinksForMessageCenterPageQuery,
    FetchReferenceLinksForMessageCenterPageQueryVariables
>;
export const CreateNewOrderDocument = gql`
    mutation CreateNewOrder($input: OrderInput!) {
        createOrder(input: $input) {
            success
            status
            resourceCreated {
                visitId
                updatedAt
                transactionDateTime
                createdAt
                isTestData
                id
                redoxEhrId
                redoxSourceId
                patientId
                practiceId
                isDeviceOrder
                orderId
                status
                procedure
                procedureDescription
                providerNPI
                providerFirstName
                providerLastName
                comments
                notes
                patientFullName
                diagnoses {
                    code
                    codeset
                    name
                    type
                    documentedDateTime
                }
                clinicalInfo {
                    code
                    codeset
                    description
                    value
                    units
                    abbreviation
                    notes
                }
            }
            message
        }
    }
`;
export type CreateNewOrderMutationFn = Apollo.MutationFunction<
    CreateNewOrderMutation,
    CreateNewOrderMutationVariables
>;

/**
 * __useCreateNewOrderMutation__
 *
 * To run a mutation, you first call `useCreateNewOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewOrderMutation, { data, loading, error }] = useCreateNewOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewOrderMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateNewOrderMutation,
        CreateNewOrderMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateNewOrderMutation, CreateNewOrderMutationVariables>(
        CreateNewOrderDocument,
        options,
    );
}
export type CreateNewOrderMutationHookResult = ReturnType<typeof useCreateNewOrderMutation>;
export type CreateNewOrderMutationResult = Apollo.MutationResult<CreateNewOrderMutation>;
export type CreateNewOrderMutationOptions = Apollo.BaseMutationOptions<
    CreateNewOrderMutation,
    CreateNewOrderMutationVariables
>;
export const OrdersForOrdersPageDocument = gql`
    query OrdersForOrdersPage($input: OrdersV2ByFilterInput!) {
        ordersV2(input: $input) {
            results {
                isTestData
                id
                redoxEhrId
                patientId
                patientFullName
                isDeviceOrder
                transactionDateTime
                procedure
                procedureDescription
                status
            }
            orderBy
            skip
            limit
            total
            hasMore
            hasPrevious
        }
    }
`;

/**
 * __useOrdersForOrdersPageQuery__
 *
 * To run a query within a React component, call `useOrdersForOrdersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersForOrdersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersForOrdersPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrdersForOrdersPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        OrdersForOrdersPageQuery,
        OrdersForOrdersPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<OrdersForOrdersPageQuery, OrdersForOrdersPageQueryVariables>(
        OrdersForOrdersPageDocument,
        options,
    );
}
export function useOrdersForOrdersPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        OrdersForOrdersPageQuery,
        OrdersForOrdersPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<OrdersForOrdersPageQuery, OrdersForOrdersPageQueryVariables>(
        OrdersForOrdersPageDocument,
        options,
    );
}
export type OrdersForOrdersPageQueryHookResult = ReturnType<typeof useOrdersForOrdersPageQuery>;
export type OrdersForOrdersPageLazyQueryHookResult = ReturnType<
    typeof useOrdersForOrdersPageLazyQuery
>;
export type OrdersForOrdersPageQueryResult = Apollo.QueryResult<
    OrdersForOrdersPageQuery,
    OrdersForOrdersPageQueryVariables
>;
export const OrderForOrderModalDocument = gql`
    query OrderForOrderModal($input: GetOrderInput!) {
        order(input: $input) {
            createdAt
            updatedAt
            isTestData
            id
            redoxEhrId
            redoxSourceId
            patientId
            patientFullName
            isDeviceOrder
            orderId
            visitId
            status
            transactionDateTime
            procedure
            procedureDescription
            providerNPI
            providerFirstName
            providerLastName
            comments
            notes
            diagnoses {
                code
                codeset
                name
                type
                documentedDateTime
            }
            clinicalInfo {
                code
                codeset
                description
                value
                units
                abbreviation
                notes
            }
        }
    }
`;

/**
 * __useOrderForOrderModalQuery__
 *
 * To run a query within a React component, call `useOrderForOrderModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderForOrderModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderForOrderModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderForOrderModalQuery(
    baseOptions: Apollo.QueryHookOptions<OrderForOrderModalQuery, OrderForOrderModalQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<OrderForOrderModalQuery, OrderForOrderModalQueryVariables>(
        OrderForOrderModalDocument,
        options,
    );
}
export function useOrderForOrderModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        OrderForOrderModalQuery,
        OrderForOrderModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<OrderForOrderModalQuery, OrderForOrderModalQueryVariables>(
        OrderForOrderModalDocument,
        options,
    );
}
export type OrderForOrderModalQueryHookResult = ReturnType<typeof useOrderForOrderModalQuery>;
export type OrderForOrderModalLazyQueryHookResult = ReturnType<
    typeof useOrderForOrderModalLazyQuery
>;
export type OrderForOrderModalQueryResult = Apollo.QueryResult<
    OrderForOrderModalQuery,
    OrderForOrderModalQueryVariables
>;
export const DeleteOrderForOrdersPageDocument = gql`
    mutation DeleteOrderForOrdersPage($input: DeleteOrderInput!) {
        deleteOrder(input: $input) {
            success
            message
            status
            id
        }
    }
`;
export type DeleteOrderForOrdersPageMutationFn = Apollo.MutationFunction<
    DeleteOrderForOrdersPageMutation,
    DeleteOrderForOrdersPageMutationVariables
>;

/**
 * __useDeleteOrderForOrdersPageMutation__
 *
 * To run a mutation, you first call `useDeleteOrderForOrdersPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderForOrdersPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderForOrdersPageMutation, { data, loading, error }] = useDeleteOrderForOrdersPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOrderForOrdersPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteOrderForOrdersPageMutation,
        DeleteOrderForOrdersPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteOrderForOrdersPageMutation,
        DeleteOrderForOrdersPageMutationVariables
    >(DeleteOrderForOrdersPageDocument, options);
}
export type DeleteOrderForOrdersPageMutationHookResult = ReturnType<
    typeof useDeleteOrderForOrdersPageMutation
>;
export type DeleteOrderForOrdersPageMutationResult =
    Apollo.MutationResult<DeleteOrderForOrdersPageMutation>;
export type DeleteOrderForOrdersPageMutationOptions = Apollo.BaseMutationOptions<
    DeleteOrderForOrdersPageMutation,
    DeleteOrderForOrdersPageMutationVariables
>;
export const FetchReferenceLinksForOrdersPageDocument = gql`
    query FetchReferenceLinksForOrdersPage($input: GetReferenceLinksForPageInput!) {
        getReferenceLinksForPage(input: $input) {
            category
            data {
                label
                url
            }
        }
    }
`;

/**
 * __useFetchReferenceLinksForOrdersPageQuery__
 *
 * To run a query within a React component, call `useFetchReferenceLinksForOrdersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchReferenceLinksForOrdersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchReferenceLinksForOrdersPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchReferenceLinksForOrdersPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchReferenceLinksForOrdersPageQuery,
        FetchReferenceLinksForOrdersPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchReferenceLinksForOrdersPageQuery,
        FetchReferenceLinksForOrdersPageQueryVariables
    >(FetchReferenceLinksForOrdersPageDocument, options);
}
export function useFetchReferenceLinksForOrdersPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchReferenceLinksForOrdersPageQuery,
        FetchReferenceLinksForOrdersPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchReferenceLinksForOrdersPageQuery,
        FetchReferenceLinksForOrdersPageQueryVariables
    >(FetchReferenceLinksForOrdersPageDocument, options);
}
export type FetchReferenceLinksForOrdersPageQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForOrdersPageQuery
>;
export type FetchReferenceLinksForOrdersPageLazyQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForOrdersPageLazyQuery
>;
export type FetchReferenceLinksForOrdersPageQueryResult = Apollo.QueryResult<
    FetchReferenceLinksForOrdersPageQuery,
    FetchReferenceLinksForOrdersPageQueryVariables
>;
export const FetchPatientCallListsForDashboardDocument = gql`
    query FetchPatientCallListsForDashboard($input: PatientCallListQueryInput) {
        patientCallListsV2(input: $input) {
            results {
                id
                name
                user {
                    name
                }
                tags {
                    id
                    name
                }
                createdAt
            }
            total
        }
    }
`;

/**
 * __useFetchPatientCallListsForDashboardQuery__
 *
 * To run a query within a React component, call `useFetchPatientCallListsForDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientCallListsForDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientCallListsForDashboardQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPatientCallListsForDashboardQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchPatientCallListsForDashboardQuery,
        FetchPatientCallListsForDashboardQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientCallListsForDashboardQuery,
        FetchPatientCallListsForDashboardQueryVariables
    >(FetchPatientCallListsForDashboardDocument, options);
}
export function useFetchPatientCallListsForDashboardLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientCallListsForDashboardQuery,
        FetchPatientCallListsForDashboardQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientCallListsForDashboardQuery,
        FetchPatientCallListsForDashboardQueryVariables
    >(FetchPatientCallListsForDashboardDocument, options);
}
export type FetchPatientCallListsForDashboardQueryHookResult = ReturnType<
    typeof useFetchPatientCallListsForDashboardQuery
>;
export type FetchPatientCallListsForDashboardLazyQueryHookResult = ReturnType<
    typeof useFetchPatientCallListsForDashboardLazyQuery
>;
export type FetchPatientCallListsForDashboardQueryResult = Apollo.QueryResult<
    FetchPatientCallListsForDashboardQuery,
    FetchPatientCallListsForDashboardQueryVariables
>;
export const FetchPatientCallListsExportToCsvDocument = gql`
    query FetchPatientCallListsExportToCSV($input: ExportToCSVCallListFilterInput) {
        PatientCallListsExportToCSV(input: $input) {
            data
            fileName
        }
    }
`;

/**
 * __useFetchPatientCallListsExportToCsvQuery__
 *
 * To run a query within a React component, call `useFetchPatientCallListsExportToCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientCallListsExportToCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientCallListsExportToCsvQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPatientCallListsExportToCsvQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchPatientCallListsExportToCsvQuery,
        FetchPatientCallListsExportToCsvQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientCallListsExportToCsvQuery,
        FetchPatientCallListsExportToCsvQueryVariables
    >(FetchPatientCallListsExportToCsvDocument, options);
}
export function useFetchPatientCallListsExportToCsvLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientCallListsExportToCsvQuery,
        FetchPatientCallListsExportToCsvQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientCallListsExportToCsvQuery,
        FetchPatientCallListsExportToCsvQueryVariables
    >(FetchPatientCallListsExportToCsvDocument, options);
}
export type FetchPatientCallListsExportToCsvQueryHookResult = ReturnType<
    typeof useFetchPatientCallListsExportToCsvQuery
>;
export type FetchPatientCallListsExportToCsvLazyQueryHookResult = ReturnType<
    typeof useFetchPatientCallListsExportToCsvLazyQuery
>;
export type FetchPatientCallListsExportToCsvQueryResult = Apollo.QueryResult<
    FetchPatientCallListsExportToCsvQuery,
    FetchPatientCallListsExportToCsvQueryVariables
>;
export const DeletePatientCallListForDashboardDocument = gql`
    mutation DeletePatientCallListForDashboard($input: DeletePatientCallListInput!) {
        deletePatientCallList(input: $input) {
            status
            message
            success
            id
        }
    }
`;
export type DeletePatientCallListForDashboardMutationFn = Apollo.MutationFunction<
    DeletePatientCallListForDashboardMutation,
    DeletePatientCallListForDashboardMutationVariables
>;

/**
 * __useDeletePatientCallListForDashboardMutation__
 *
 * To run a mutation, you first call `useDeletePatientCallListForDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientCallListForDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientCallListForDashboardMutation, { data, loading, error }] = useDeletePatientCallListForDashboardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePatientCallListForDashboardMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeletePatientCallListForDashboardMutation,
        DeletePatientCallListForDashboardMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeletePatientCallListForDashboardMutation,
        DeletePatientCallListForDashboardMutationVariables
    >(DeletePatientCallListForDashboardDocument, options);
}
export type DeletePatientCallListForDashboardMutationHookResult = ReturnType<
    typeof useDeletePatientCallListForDashboardMutation
>;
export type DeletePatientCallListForDashboardMutationResult =
    Apollo.MutationResult<DeletePatientCallListForDashboardMutation>;
export type DeletePatientCallListForDashboardMutationOptions = Apollo.BaseMutationOptions<
    DeletePatientCallListForDashboardMutation,
    DeletePatientCallListForDashboardMutationVariables
>;
export const PreFetchTagsPatientCallListDocument = gql`
    query PreFetchTagsPatientCallList {
        tags {
            id
            name
        }
    }
`;

/**
 * __usePreFetchTagsPatientCallListQuery__
 *
 * To run a query within a React component, call `usePreFetchTagsPatientCallListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreFetchTagsPatientCallListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreFetchTagsPatientCallListQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreFetchTagsPatientCallListQuery(
    baseOptions?: Apollo.QueryHookOptions<
        PreFetchTagsPatientCallListQuery,
        PreFetchTagsPatientCallListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        PreFetchTagsPatientCallListQuery,
        PreFetchTagsPatientCallListQueryVariables
    >(PreFetchTagsPatientCallListDocument, options);
}
export function usePreFetchTagsPatientCallListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PreFetchTagsPatientCallListQuery,
        PreFetchTagsPatientCallListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        PreFetchTagsPatientCallListQuery,
        PreFetchTagsPatientCallListQueryVariables
    >(PreFetchTagsPatientCallListDocument, options);
}
export type PreFetchTagsPatientCallListQueryHookResult = ReturnType<
    typeof usePreFetchTagsPatientCallListQuery
>;
export type PreFetchTagsPatientCallListLazyQueryHookResult = ReturnType<
    typeof usePreFetchTagsPatientCallListLazyQuery
>;
export type PreFetchTagsPatientCallListQueryResult = Apollo.QueryResult<
    PreFetchTagsPatientCallListQuery,
    PreFetchTagsPatientCallListQueryVariables
>;
export const CreatePatientCallListDocument = gql`
    mutation CreatePatientCallList($input: PatientCallListInput!) {
        createPatientCallList(input: $input) {
            status
            message
            success
        }
    }
`;
export type CreatePatientCallListMutationFn = Apollo.MutationFunction<
    CreatePatientCallListMutation,
    CreatePatientCallListMutationVariables
>;

/**
 * __useCreatePatientCallListMutation__
 *
 * To run a mutation, you first call `useCreatePatientCallListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientCallListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientCallListMutation, { data, loading, error }] = useCreatePatientCallListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatientCallListMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreatePatientCallListMutation,
        CreatePatientCallListMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreatePatientCallListMutation,
        CreatePatientCallListMutationVariables
    >(CreatePatientCallListDocument, options);
}
export type CreatePatientCallListMutationHookResult = ReturnType<
    typeof useCreatePatientCallListMutation
>;
export type CreatePatientCallListMutationResult =
    Apollo.MutationResult<CreatePatientCallListMutation>;
export type CreatePatientCallListMutationOptions = Apollo.BaseMutationOptions<
    CreatePatientCallListMutation,
    CreatePatientCallListMutationVariables
>;
export const UpdatePatientCallListDocument = gql`
    mutation UpdatePatientCallList($input: PatientCallListUpdateInput!) {
        updatePatientCallList(input: $input) {
            status
            message
            success
            resourceUpdated {
                id
                name
                user {
                    name
                }
                tags {
                    id
                    name
                }
                createdAt
            }
        }
    }
`;
export type UpdatePatientCallListMutationFn = Apollo.MutationFunction<
    UpdatePatientCallListMutation,
    UpdatePatientCallListMutationVariables
>;

/**
 * __useUpdatePatientCallListMutation__
 *
 * To run a mutation, you first call `useUpdatePatientCallListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientCallListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientCallListMutation, { data, loading, error }] = useUpdatePatientCallListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientCallListMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePatientCallListMutation,
        UpdatePatientCallListMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdatePatientCallListMutation,
        UpdatePatientCallListMutationVariables
    >(UpdatePatientCallListDocument, options);
}
export type UpdatePatientCallListMutationHookResult = ReturnType<
    typeof useUpdatePatientCallListMutation
>;
export type UpdatePatientCallListMutationResult =
    Apollo.MutationResult<UpdatePatientCallListMutation>;
export type UpdatePatientCallListMutationOptions = Apollo.BaseMutationOptions<
    UpdatePatientCallListMutation,
    UpdatePatientCallListMutationVariables
>;
export const DeletePatientCallListDocument = gql`
    mutation DeletePatientCallList($input: DeletePatientCallListInput!) {
        deletePatientCallList(input: $input) {
            status
            message
            success
        }
    }
`;
export type DeletePatientCallListMutationFn = Apollo.MutationFunction<
    DeletePatientCallListMutation,
    DeletePatientCallListMutationVariables
>;

/**
 * __useDeletePatientCallListMutation__
 *
 * To run a mutation, you first call `useDeletePatientCallListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientCallListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientCallListMutation, { data, loading, error }] = useDeletePatientCallListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePatientCallListMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeletePatientCallListMutation,
        DeletePatientCallListMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeletePatientCallListMutation,
        DeletePatientCallListMutationVariables
    >(DeletePatientCallListDocument, options);
}
export type DeletePatientCallListMutationHookResult = ReturnType<
    typeof useDeletePatientCallListMutation
>;
export type DeletePatientCallListMutationResult =
    Apollo.MutationResult<DeletePatientCallListMutation>;
export type DeletePatientCallListMutationOptions = Apollo.BaseMutationOptions<
    DeletePatientCallListMutation,
    DeletePatientCallListMutationVariables
>;
export const FetchPatientCallListForPreviewDocument = gql`
    query FetchPatientCallListForPreview(
        $input: GetPatientCallListInput!
        $patientsToCallInput2: GetPatientsByFilterInput
    ) {
        patientCallList(input: $input) {
            name
            patientsToCall(input: $patientsToCallInput2) {
                limit
                skip
                total
                results {
                    called
                    patient {
                        id
                        createdAt
                        invitationCode
                        externalId
                        firstName
                        lastName
                        assignedHealthAdvocate
                        openAdvocateTaskCount
                        openCriticalAdvocateTaskCount
                        onboardingDate
                        patientCallStatus
                        birthDate
                        practice {
                            name
                        }
                        onboardingCompleteDate
                        isPregnant
                        appBundleId
                        dueDate
                        deliveryDate
                        email
                    }
                }
            }
        }
    }
`;

/**
 * __useFetchPatientCallListForPreviewQuery__
 *
 * To run a query within a React component, call `useFetchPatientCallListForPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientCallListForPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientCallListForPreviewQuery({
 *   variables: {
 *      input: // value for 'input'
 *      patientsToCallInput2: // value for 'patientsToCallInput2'
 *   },
 * });
 */
export function useFetchPatientCallListForPreviewQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPatientCallListForPreviewQuery,
        FetchPatientCallListForPreviewQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientCallListForPreviewQuery,
        FetchPatientCallListForPreviewQueryVariables
    >(FetchPatientCallListForPreviewDocument, options);
}
export function useFetchPatientCallListForPreviewLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientCallListForPreviewQuery,
        FetchPatientCallListForPreviewQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientCallListForPreviewQuery,
        FetchPatientCallListForPreviewQueryVariables
    >(FetchPatientCallListForPreviewDocument, options);
}
export type FetchPatientCallListForPreviewQueryHookResult = ReturnType<
    typeof useFetchPatientCallListForPreviewQuery
>;
export type FetchPatientCallListForPreviewLazyQueryHookResult = ReturnType<
    typeof useFetchPatientCallListForPreviewLazyQuery
>;
export type FetchPatientCallListForPreviewQueryResult = Apollo.QueryResult<
    FetchPatientCallListForPreviewQuery,
    FetchPatientCallListForPreviewQueryVariables
>;
export const FetchPatientCallListPreviewExportToCsvDocument = gql`
    query FetchPatientCallListPreviewExportToCSV($input: ExportToCSVCallListPreviewInput) {
        PatientCallListPreviewExportToCSV(input: $input) {
            data
            fileName
        }
    }
`;

/**
 * __useFetchPatientCallListPreviewExportToCsvQuery__
 *
 * To run a query within a React component, call `useFetchPatientCallListPreviewExportToCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientCallListPreviewExportToCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientCallListPreviewExportToCsvQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPatientCallListPreviewExportToCsvQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchPatientCallListPreviewExportToCsvQuery,
        FetchPatientCallListPreviewExportToCsvQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientCallListPreviewExportToCsvQuery,
        FetchPatientCallListPreviewExportToCsvQueryVariables
    >(FetchPatientCallListPreviewExportToCsvDocument, options);
}
export function useFetchPatientCallListPreviewExportToCsvLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientCallListPreviewExportToCsvQuery,
        FetchPatientCallListPreviewExportToCsvQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientCallListPreviewExportToCsvQuery,
        FetchPatientCallListPreviewExportToCsvQueryVariables
    >(FetchPatientCallListPreviewExportToCsvDocument, options);
}
export type FetchPatientCallListPreviewExportToCsvQueryHookResult = ReturnType<
    typeof useFetchPatientCallListPreviewExportToCsvQuery
>;
export type FetchPatientCallListPreviewExportToCsvLazyQueryHookResult = ReturnType<
    typeof useFetchPatientCallListPreviewExportToCsvLazyQuery
>;
export type FetchPatientCallListPreviewExportToCsvQueryResult = Apollo.QueryResult<
    FetchPatientCallListPreviewExportToCsvQuery,
    FetchPatientCallListPreviewExportToCsvQueryVariables
>;
export const UpdatePatientCallListCallStatusForPreviewDocument = gql`
    mutation UpdatePatientCallListCallStatusForPreview(
        $input: UpdatePatientCallListCallStatusInput
    ) {
        updatePatientCallListCallStatus(input: $input) {
            status
            message
            success
        }
    }
`;
export type UpdatePatientCallListCallStatusForPreviewMutationFn = Apollo.MutationFunction<
    UpdatePatientCallListCallStatusForPreviewMutation,
    UpdatePatientCallListCallStatusForPreviewMutationVariables
>;

/**
 * __useUpdatePatientCallListCallStatusForPreviewMutation__
 *
 * To run a mutation, you first call `useUpdatePatientCallListCallStatusForPreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientCallListCallStatusForPreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientCallListCallStatusForPreviewMutation, { data, loading, error }] = useUpdatePatientCallListCallStatusForPreviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientCallListCallStatusForPreviewMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePatientCallListCallStatusForPreviewMutation,
        UpdatePatientCallListCallStatusForPreviewMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdatePatientCallListCallStatusForPreviewMutation,
        UpdatePatientCallListCallStatusForPreviewMutationVariables
    >(UpdatePatientCallListCallStatusForPreviewDocument, options);
}
export type UpdatePatientCallListCallStatusForPreviewMutationHookResult = ReturnType<
    typeof useUpdatePatientCallListCallStatusForPreviewMutation
>;
export type UpdatePatientCallListCallStatusForPreviewMutationResult =
    Apollo.MutationResult<UpdatePatientCallListCallStatusForPreviewMutation>;
export type UpdatePatientCallListCallStatusForPreviewMutationOptions = Apollo.BaseMutationOptions<
    UpdatePatientCallListCallStatusForPreviewMutation,
    UpdatePatientCallListCallStatusForPreviewMutationVariables
>;
export const FetchTagsForFilterPatientsModalDocument = gql`
    query FetchTagsForFilterPatientsModal($input: TagQueryInput) {
        tagsV2(input: $input) {
            results {
                id
                name
                isPatientFilter
            }
        }
    }
`;

/**
 * __useFetchTagsForFilterPatientsModalQuery__
 *
 * To run a query within a React component, call `useFetchTagsForFilterPatientsModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTagsForFilterPatientsModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTagsForFilterPatientsModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchTagsForFilterPatientsModalQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchTagsForFilterPatientsModalQuery,
        FetchTagsForFilterPatientsModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchTagsForFilterPatientsModalQuery,
        FetchTagsForFilterPatientsModalQueryVariables
    >(FetchTagsForFilterPatientsModalDocument, options);
}
export function useFetchTagsForFilterPatientsModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchTagsForFilterPatientsModalQuery,
        FetchTagsForFilterPatientsModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchTagsForFilterPatientsModalQuery,
        FetchTagsForFilterPatientsModalQueryVariables
    >(FetchTagsForFilterPatientsModalDocument, options);
}
export type FetchTagsForFilterPatientsModalQueryHookResult = ReturnType<
    typeof useFetchTagsForFilterPatientsModalQuery
>;
export type FetchTagsForFilterPatientsModalLazyQueryHookResult = ReturnType<
    typeof useFetchTagsForFilterPatientsModalLazyQuery
>;
export type FetchTagsForFilterPatientsModalQueryResult = Apollo.QueryResult<
    FetchTagsForFilterPatientsModalQuery,
    FetchTagsForFilterPatientsModalQueryVariables
>;
export const GetUsersByPermissionForFilterPatientsModalDocument = gql`
    query GetUsersByPermissionForFilterPatientsModal($permission: String) {
        getUsersByPermission(permission: $permission) {
            id
            name
        }
    }
`;

/**
 * __useGetUsersByPermissionForFilterPatientsModalQuery__
 *
 * To run a query within a React component, call `useGetUsersByPermissionForFilterPatientsModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByPermissionForFilterPatientsModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByPermissionForFilterPatientsModalQuery({
 *   variables: {
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useGetUsersByPermissionForFilterPatientsModalQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetUsersByPermissionForFilterPatientsModalQuery,
        GetUsersByPermissionForFilterPatientsModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetUsersByPermissionForFilterPatientsModalQuery,
        GetUsersByPermissionForFilterPatientsModalQueryVariables
    >(GetUsersByPermissionForFilterPatientsModalDocument, options);
}
export function useGetUsersByPermissionForFilterPatientsModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetUsersByPermissionForFilterPatientsModalQuery,
        GetUsersByPermissionForFilterPatientsModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetUsersByPermissionForFilterPatientsModalQuery,
        GetUsersByPermissionForFilterPatientsModalQueryVariables
    >(GetUsersByPermissionForFilterPatientsModalDocument, options);
}
export type GetUsersByPermissionForFilterPatientsModalQueryHookResult = ReturnType<
    typeof useGetUsersByPermissionForFilterPatientsModalQuery
>;
export type GetUsersByPermissionForFilterPatientsModalLazyQueryHookResult = ReturnType<
    typeof useGetUsersByPermissionForFilterPatientsModalLazyQuery
>;
export type GetUsersByPermissionForFilterPatientsModalQueryResult = Apollo.QueryResult<
    GetUsersByPermissionForFilterPatientsModalQuery,
    GetUsersByPermissionForFilterPatientsModalQueryVariables
>;
export const FetchPatientForPatientSummaryPageQueryDocument = gql`
    query FetchPatientForPatientSummaryPageQuery($input: GetPatientInput!) {
        patient(input: $input) {
            id
            email
            fullName
            firstName
            lastName
            assignedHealthAdvocate
            patientHeaderData {
                label
                value
            }
            practice {
                id
            }
        }
    }
`;

/**
 * __useFetchPatientForPatientSummaryPageQueryQuery__
 *
 * To run a query within a React component, call `useFetchPatientForPatientSummaryPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientForPatientSummaryPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientForPatientSummaryPageQueryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPatientForPatientSummaryPageQueryQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPatientForPatientSummaryPageQueryQuery,
        FetchPatientForPatientSummaryPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientForPatientSummaryPageQueryQuery,
        FetchPatientForPatientSummaryPageQueryQueryVariables
    >(FetchPatientForPatientSummaryPageQueryDocument, options);
}
export function useFetchPatientForPatientSummaryPageQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientForPatientSummaryPageQueryQuery,
        FetchPatientForPatientSummaryPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientForPatientSummaryPageQueryQuery,
        FetchPatientForPatientSummaryPageQueryQueryVariables
    >(FetchPatientForPatientSummaryPageQueryDocument, options);
}
export type FetchPatientForPatientSummaryPageQueryQueryHookResult = ReturnType<
    typeof useFetchPatientForPatientSummaryPageQueryQuery
>;
export type FetchPatientForPatientSummaryPageQueryLazyQueryHookResult = ReturnType<
    typeof useFetchPatientForPatientSummaryPageQueryLazyQuery
>;
export type FetchPatientForPatientSummaryPageQueryQueryResult = Apollo.QueryResult<
    FetchPatientForPatientSummaryPageQueryQuery,
    FetchPatientForPatientSummaryPageQueryQueryVariables
>;
export const FetchActionsForPatientActionsPageQueryDocument = gql`
    query FetchActionsForPatientActionsPageQuery($actionInput: PatientActionQueryInput!) {
        patientActionsV2(input: $actionInput) {
            results {
                id
                updatedAt
                description
                taskId
                stepId
                patientId
                source
                completedAt
                typeId
                type {
                    id
                    label
                }
            }
        }
    }
`;

/**
 * __useFetchActionsForPatientActionsPageQueryQuery__
 *
 * To run a query within a React component, call `useFetchActionsForPatientActionsPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchActionsForPatientActionsPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchActionsForPatientActionsPageQueryQuery({
 *   variables: {
 *      actionInput: // value for 'actionInput'
 *   },
 * });
 */
export function useFetchActionsForPatientActionsPageQueryQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchActionsForPatientActionsPageQueryQuery,
        FetchActionsForPatientActionsPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchActionsForPatientActionsPageQueryQuery,
        FetchActionsForPatientActionsPageQueryQueryVariables
    >(FetchActionsForPatientActionsPageQueryDocument, options);
}
export function useFetchActionsForPatientActionsPageQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchActionsForPatientActionsPageQueryQuery,
        FetchActionsForPatientActionsPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchActionsForPatientActionsPageQueryQuery,
        FetchActionsForPatientActionsPageQueryQueryVariables
    >(FetchActionsForPatientActionsPageQueryDocument, options);
}
export type FetchActionsForPatientActionsPageQueryQueryHookResult = ReturnType<
    typeof useFetchActionsForPatientActionsPageQueryQuery
>;
export type FetchActionsForPatientActionsPageQueryLazyQueryHookResult = ReturnType<
    typeof useFetchActionsForPatientActionsPageQueryLazyQuery
>;
export type FetchActionsForPatientActionsPageQueryQueryResult = Apollo.QueryResult<
    FetchActionsForPatientActionsPageQueryQuery,
    FetchActionsForPatientActionsPageQueryQueryVariables
>;
export const FetchPatientActionTypesForPatientActionsPageDocument = gql`
    query FetchPatientActionTypesForPatientActionsPage {
        patientActionTypesV2 {
            results {
                label
                updatedAt
                createdAt
                id
            }
        }
    }
`;

/**
 * __useFetchPatientActionTypesForPatientActionsPageQuery__
 *
 * To run a query within a React component, call `useFetchPatientActionTypesForPatientActionsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientActionTypesForPatientActionsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientActionTypesForPatientActionsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchPatientActionTypesForPatientActionsPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchPatientActionTypesForPatientActionsPageQuery,
        FetchPatientActionTypesForPatientActionsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientActionTypesForPatientActionsPageQuery,
        FetchPatientActionTypesForPatientActionsPageQueryVariables
    >(FetchPatientActionTypesForPatientActionsPageDocument, options);
}
export function useFetchPatientActionTypesForPatientActionsPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientActionTypesForPatientActionsPageQuery,
        FetchPatientActionTypesForPatientActionsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientActionTypesForPatientActionsPageQuery,
        FetchPatientActionTypesForPatientActionsPageQueryVariables
    >(FetchPatientActionTypesForPatientActionsPageDocument, options);
}
export type FetchPatientActionTypesForPatientActionsPageQueryHookResult = ReturnType<
    typeof useFetchPatientActionTypesForPatientActionsPageQuery
>;
export type FetchPatientActionTypesForPatientActionsPageLazyQueryHookResult = ReturnType<
    typeof useFetchPatientActionTypesForPatientActionsPageLazyQuery
>;
export type FetchPatientActionTypesForPatientActionsPageQueryResult = Apollo.QueryResult<
    FetchPatientActionTypesForPatientActionsPageQuery,
    FetchPatientActionTypesForPatientActionsPageQueryVariables
>;
export const CreatePatientActionForPatientActionPageDocument = gql`
    mutation CreatePatientActionForPatientActionPage($input: PatientActionInput!) {
        createPatientAction(input: $input) {
            status
            message
            success
            resourceCreated {
                updatedAt
                description
                taskId
                typeId
            }
        }
    }
`;
export type CreatePatientActionForPatientActionPageMutationFn = Apollo.MutationFunction<
    CreatePatientActionForPatientActionPageMutation,
    CreatePatientActionForPatientActionPageMutationVariables
>;

/**
 * __useCreatePatientActionForPatientActionPageMutation__
 *
 * To run a mutation, you first call `useCreatePatientActionForPatientActionPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientActionForPatientActionPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientActionForPatientActionPageMutation, { data, loading, error }] = useCreatePatientActionForPatientActionPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatientActionForPatientActionPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreatePatientActionForPatientActionPageMutation,
        CreatePatientActionForPatientActionPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreatePatientActionForPatientActionPageMutation,
        CreatePatientActionForPatientActionPageMutationVariables
    >(CreatePatientActionForPatientActionPageDocument, options);
}
export type CreatePatientActionForPatientActionPageMutationHookResult = ReturnType<
    typeof useCreatePatientActionForPatientActionPageMutation
>;
export type CreatePatientActionForPatientActionPageMutationResult =
    Apollo.MutationResult<CreatePatientActionForPatientActionPageMutation>;
export type CreatePatientActionForPatientActionPageMutationOptions = Apollo.BaseMutationOptions<
    CreatePatientActionForPatientActionPageMutation,
    CreatePatientActionForPatientActionPageMutationVariables
>;
export const UpdatePatientActionForPatientActionPageDocument = gql`
    mutation UpdatePatientActionForPatientActionPage($input: UpdatePatientActionInput!) {
        updatePatientAction(input: $input) {
            status
            message
            success
            resourceUpdated {
                id
                updatedAt
                description
                taskId
                typeId
                patientId
                source
            }
        }
    }
`;
export type UpdatePatientActionForPatientActionPageMutationFn = Apollo.MutationFunction<
    UpdatePatientActionForPatientActionPageMutation,
    UpdatePatientActionForPatientActionPageMutationVariables
>;

/**
 * __useUpdatePatientActionForPatientActionPageMutation__
 *
 * To run a mutation, you first call `useUpdatePatientActionForPatientActionPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientActionForPatientActionPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientActionForPatientActionPageMutation, { data, loading, error }] = useUpdatePatientActionForPatientActionPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientActionForPatientActionPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePatientActionForPatientActionPageMutation,
        UpdatePatientActionForPatientActionPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdatePatientActionForPatientActionPageMutation,
        UpdatePatientActionForPatientActionPageMutationVariables
    >(UpdatePatientActionForPatientActionPageDocument, options);
}
export type UpdatePatientActionForPatientActionPageMutationHookResult = ReturnType<
    typeof useUpdatePatientActionForPatientActionPageMutation
>;
export type UpdatePatientActionForPatientActionPageMutationResult =
    Apollo.MutationResult<UpdatePatientActionForPatientActionPageMutation>;
export type UpdatePatientActionForPatientActionPageMutationOptions = Apollo.BaseMutationOptions<
    UpdatePatientActionForPatientActionPageMutation,
    UpdatePatientActionForPatientActionPageMutationVariables
>;
export const DeletePatientActionForPatientActionPageDocument = gql`
    mutation DeletePatientActionForPatientActionPage($input: DeletePatientActionInput!) {
        deletePatientAction(input: $input) {
            success
            message
            status
            id
        }
    }
`;
export type DeletePatientActionForPatientActionPageMutationFn = Apollo.MutationFunction<
    DeletePatientActionForPatientActionPageMutation,
    DeletePatientActionForPatientActionPageMutationVariables
>;

/**
 * __useDeletePatientActionForPatientActionPageMutation__
 *
 * To run a mutation, you first call `useDeletePatientActionForPatientActionPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientActionForPatientActionPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientActionForPatientActionPageMutation, { data, loading, error }] = useDeletePatientActionForPatientActionPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePatientActionForPatientActionPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeletePatientActionForPatientActionPageMutation,
        DeletePatientActionForPatientActionPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeletePatientActionForPatientActionPageMutation,
        DeletePatientActionForPatientActionPageMutationVariables
    >(DeletePatientActionForPatientActionPageDocument, options);
}
export type DeletePatientActionForPatientActionPageMutationHookResult = ReturnType<
    typeof useDeletePatientActionForPatientActionPageMutation
>;
export type DeletePatientActionForPatientActionPageMutationResult =
    Apollo.MutationResult<DeletePatientActionForPatientActionPageMutation>;
export type DeletePatientActionForPatientActionPageMutationOptions = Apollo.BaseMutationOptions<
    DeletePatientActionForPatientActionPageMutation,
    DeletePatientActionForPatientActionPageMutationVariables
>;
export const FetchAppUserTelemetryDocument = gql`
    query FetchAppUserTelemetry($input: AppUserTelemetryPaginationInput!) {
        appUserTelemetrys(input: $input) {
            itemcount
            eventTypes
            items {
                eventType
                appUserId
                data
                eventDate
            }
        }
    }
`;

/**
 * __useFetchAppUserTelemetryQuery__
 *
 * To run a query within a React component, call `useFetchAppUserTelemetryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAppUserTelemetryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAppUserTelemetryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchAppUserTelemetryQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchAppUserTelemetryQuery,
        FetchAppUserTelemetryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchAppUserTelemetryQuery, FetchAppUserTelemetryQueryVariables>(
        FetchAppUserTelemetryDocument,
        options,
    );
}
export function useFetchAppUserTelemetryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchAppUserTelemetryQuery,
        FetchAppUserTelemetryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchAppUserTelemetryQuery, FetchAppUserTelemetryQueryVariables>(
        FetchAppUserTelemetryDocument,
        options,
    );
}
export type FetchAppUserTelemetryQueryHookResult = ReturnType<typeof useFetchAppUserTelemetryQuery>;
export type FetchAppUserTelemetryLazyQueryHookResult = ReturnType<
    typeof useFetchAppUserTelemetryLazyQuery
>;
export type FetchAppUserTelemetryQueryResult = Apollo.QueryResult<
    FetchAppUserTelemetryQuery,
    FetchAppUserTelemetryQueryVariables
>;
export const FetchApptsForPatientApptsPageQueryDocument = gql`
    query FetchApptsForPatientApptsPageQuery(
        $patientInput: GetPatientInput!
        $appointmentInput: PatientAppointmentsQueryInput!
    ) {
        patient(input: $patientInput) {
            ...PatientForPatientsPage
            appointments(input: $appointmentInput) {
                ...AppointmentFragmentOnApptPage
            }
        }
    }
    ${PatientForPatientsPageFragmentDoc}
    ${AppointmentFragmentOnApptPageFragmentDoc}
`;

/**
 * __useFetchApptsForPatientApptsPageQueryQuery__
 *
 * To run a query within a React component, call `useFetchApptsForPatientApptsPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchApptsForPatientApptsPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchApptsForPatientApptsPageQueryQuery({
 *   variables: {
 *      patientInput: // value for 'patientInput'
 *      appointmentInput: // value for 'appointmentInput'
 *   },
 * });
 */
export function useFetchApptsForPatientApptsPageQueryQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchApptsForPatientApptsPageQueryQuery,
        FetchApptsForPatientApptsPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchApptsForPatientApptsPageQueryQuery,
        FetchApptsForPatientApptsPageQueryQueryVariables
    >(FetchApptsForPatientApptsPageQueryDocument, options);
}
export function useFetchApptsForPatientApptsPageQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchApptsForPatientApptsPageQueryQuery,
        FetchApptsForPatientApptsPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchApptsForPatientApptsPageQueryQuery,
        FetchApptsForPatientApptsPageQueryQueryVariables
    >(FetchApptsForPatientApptsPageQueryDocument, options);
}
export type FetchApptsForPatientApptsPageQueryQueryHookResult = ReturnType<
    typeof useFetchApptsForPatientApptsPageQueryQuery
>;
export type FetchApptsForPatientApptsPageQueryLazyQueryHookResult = ReturnType<
    typeof useFetchApptsForPatientApptsPageQueryLazyQuery
>;
export type FetchApptsForPatientApptsPageQueryQueryResult = Apollo.QueryResult<
    FetchApptsForPatientApptsPageQueryQuery,
    FetchApptsForPatientApptsPageQueryQueryVariables
>;
export const DeletePatientAppointmentByIdDocument = gql`
    mutation DeletePatientAppointmentById($input: DeleteAppointmentInput!) {
        deleteAppointment(input: $input) {
            success
            message
            status
            id
        }
    }
`;
export type DeletePatientAppointmentByIdMutationFn = Apollo.MutationFunction<
    DeletePatientAppointmentByIdMutation,
    DeletePatientAppointmentByIdMutationVariables
>;

/**
 * __useDeletePatientAppointmentByIdMutation__
 *
 * To run a mutation, you first call `useDeletePatientAppointmentByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientAppointmentByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientAppointmentByIdMutation, { data, loading, error }] = useDeletePatientAppointmentByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePatientAppointmentByIdMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeletePatientAppointmentByIdMutation,
        DeletePatientAppointmentByIdMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeletePatientAppointmentByIdMutation,
        DeletePatientAppointmentByIdMutationVariables
    >(DeletePatientAppointmentByIdDocument, options);
}
export type DeletePatientAppointmentByIdMutationHookResult = ReturnType<
    typeof useDeletePatientAppointmentByIdMutation
>;
export type DeletePatientAppointmentByIdMutationResult =
    Apollo.MutationResult<DeletePatientAppointmentByIdMutation>;
export type DeletePatientAppointmentByIdMutationOptions = Apollo.BaseMutationOptions<
    DeletePatientAppointmentByIdMutation,
    DeletePatientAppointmentByIdMutationVariables
>;
export const FetchPatientArticleFeedDocument = gql`
    query FetchPatientArticleFeed($appUserId: String) {
        AppUserArticleFeed(appUserId: $appUserId) {
            items {
                id
                title
                summary
                availableUntil
            }
        }
    }
`;

/**
 * __useFetchPatientArticleFeedQuery__
 *
 * To run a query within a React component, call `useFetchPatientArticleFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientArticleFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientArticleFeedQuery({
 *   variables: {
 *      appUserId: // value for 'appUserId'
 *   },
 * });
 */
export function useFetchPatientArticleFeedQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchPatientArticleFeedQuery,
        FetchPatientArticleFeedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchPatientArticleFeedQuery, FetchPatientArticleFeedQueryVariables>(
        FetchPatientArticleFeedDocument,
        options,
    );
}
export function useFetchPatientArticleFeedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientArticleFeedQuery,
        FetchPatientArticleFeedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchPatientArticleFeedQuery, FetchPatientArticleFeedQueryVariables>(
        FetchPatientArticleFeedDocument,
        options,
    );
}
export type FetchPatientArticleFeedQueryHookResult = ReturnType<
    typeof useFetchPatientArticleFeedQuery
>;
export type FetchPatientArticleFeedLazyQueryHookResult = ReturnType<
    typeof useFetchPatientArticleFeedLazyQuery
>;
export type FetchPatientArticleFeedQueryResult = Apollo.QueryResult<
    FetchPatientArticleFeedQuery,
    FetchPatientArticleFeedQueryVariables
>;
export const FetchPatientAppUsersForArticleFeedDocument = gql`
    query FetchPatientAppUsersForArticleFeed($input: GetPatientInput!) {
        patient(input: $input) {
            id
            appUserId
            appBundleId
        }
    }
`;

/**
 * __useFetchPatientAppUsersForArticleFeedQuery__
 *
 * To run a query within a React component, call `useFetchPatientAppUsersForArticleFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientAppUsersForArticleFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientAppUsersForArticleFeedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPatientAppUsersForArticleFeedQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPatientAppUsersForArticleFeedQuery,
        FetchPatientAppUsersForArticleFeedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientAppUsersForArticleFeedQuery,
        FetchPatientAppUsersForArticleFeedQueryVariables
    >(FetchPatientAppUsersForArticleFeedDocument, options);
}
export function useFetchPatientAppUsersForArticleFeedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientAppUsersForArticleFeedQuery,
        FetchPatientAppUsersForArticleFeedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientAppUsersForArticleFeedQuery,
        FetchPatientAppUsersForArticleFeedQueryVariables
    >(FetchPatientAppUsersForArticleFeedDocument, options);
}
export type FetchPatientAppUsersForArticleFeedQueryHookResult = ReturnType<
    typeof useFetchPatientAppUsersForArticleFeedQuery
>;
export type FetchPatientAppUsersForArticleFeedLazyQueryHookResult = ReturnType<
    typeof useFetchPatientAppUsersForArticleFeedLazyQuery
>;
export type FetchPatientAppUsersForArticleFeedQueryResult = Apollo.QueryResult<
    FetchPatientAppUsersForArticleFeedQuery,
    FetchPatientAppUsersForArticleFeedQueryVariables
>;
export const ProfileVariableForPatientCareTeamDocument = gql`
    query ProfileVariableForPatientCareTeam($patientId: String, $profileVariableDefId: String) {
        profileVariable(patientId: $patientId, profileVariableDefId: $profileVariableDefId) {
            familyId
            keyId
            patientId
            profileVariableDefId
            value {
                str
            }
        }
    }
`;

/**
 * __useProfileVariableForPatientCareTeamQuery__
 *
 * To run a query within a React component, call `useProfileVariableForPatientCareTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileVariableForPatientCareTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileVariableForPatientCareTeamQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      profileVariableDefId: // value for 'profileVariableDefId'
 *   },
 * });
 */
export function useProfileVariableForPatientCareTeamQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ProfileVariableForPatientCareTeamQuery,
        ProfileVariableForPatientCareTeamQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        ProfileVariableForPatientCareTeamQuery,
        ProfileVariableForPatientCareTeamQueryVariables
    >(ProfileVariableForPatientCareTeamDocument, options);
}
export function useProfileVariableForPatientCareTeamLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ProfileVariableForPatientCareTeamQuery,
        ProfileVariableForPatientCareTeamQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ProfileVariableForPatientCareTeamQuery,
        ProfileVariableForPatientCareTeamQueryVariables
    >(ProfileVariableForPatientCareTeamDocument, options);
}
export type ProfileVariableForPatientCareTeamQueryHookResult = ReturnType<
    typeof useProfileVariableForPatientCareTeamQuery
>;
export type ProfileVariableForPatientCareTeamLazyQueryHookResult = ReturnType<
    typeof useProfileVariableForPatientCareTeamLazyQuery
>;
export type ProfileVariableForPatientCareTeamQueryResult = Apollo.QueryResult<
    ProfileVariableForPatientCareTeamQuery,
    ProfileVariableForPatientCareTeamQueryVariables
>;
export const UsersV2ForPatientCareTeamDocument = gql`
    query UsersV2ForPatientCareTeam {
        usersV2 {
            results {
                id
                name
                role {
                    permissions {
                        permission
                        target
                        canExport
                    }
                }
            }
        }
    }
`;

/**
 * __useUsersV2ForPatientCareTeamQuery__
 *
 * To run a query within a React component, call `useUsersV2ForPatientCareTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersV2ForPatientCareTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersV2ForPatientCareTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersV2ForPatientCareTeamQuery(
    baseOptions?: Apollo.QueryHookOptions<
        UsersV2ForPatientCareTeamQuery,
        UsersV2ForPatientCareTeamQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<UsersV2ForPatientCareTeamQuery, UsersV2ForPatientCareTeamQueryVariables>(
        UsersV2ForPatientCareTeamDocument,
        options,
    );
}
export function useUsersV2ForPatientCareTeamLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        UsersV2ForPatientCareTeamQuery,
        UsersV2ForPatientCareTeamQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        UsersV2ForPatientCareTeamQuery,
        UsersV2ForPatientCareTeamQueryVariables
    >(UsersV2ForPatientCareTeamDocument, options);
}
export type UsersV2ForPatientCareTeamQueryHookResult = ReturnType<
    typeof useUsersV2ForPatientCareTeamQuery
>;
export type UsersV2ForPatientCareTeamLazyQueryHookResult = ReturnType<
    typeof useUsersV2ForPatientCareTeamLazyQuery
>;
export type UsersV2ForPatientCareTeamQueryResult = Apollo.QueryResult<
    UsersV2ForPatientCareTeamQuery,
    UsersV2ForPatientCareTeamQueryVariables
>;
export const CareTeamMemberTypesV2ForPatientCareTeamDocument = gql`
    query CareTeamMemberTypesV2ForPatientCareTeam {
        careTeamMemberTypesV2 {
            results {
                id
                name
                profileDefId
                profileDef {
                    name
                    createdAt
                    id
                    updatedAt
                    category
                    valueType
                }
                createdAt
                updatedAt
            }
            total
        }
    }
`;

/**
 * __useCareTeamMemberTypesV2ForPatientCareTeamQuery__
 *
 * To run a query within a React component, call `useCareTeamMemberTypesV2ForPatientCareTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareTeamMemberTypesV2ForPatientCareTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareTeamMemberTypesV2ForPatientCareTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useCareTeamMemberTypesV2ForPatientCareTeamQuery(
    baseOptions?: Apollo.QueryHookOptions<
        CareTeamMemberTypesV2ForPatientCareTeamQuery,
        CareTeamMemberTypesV2ForPatientCareTeamQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        CareTeamMemberTypesV2ForPatientCareTeamQuery,
        CareTeamMemberTypesV2ForPatientCareTeamQueryVariables
    >(CareTeamMemberTypesV2ForPatientCareTeamDocument, options);
}
export function useCareTeamMemberTypesV2ForPatientCareTeamLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CareTeamMemberTypesV2ForPatientCareTeamQuery,
        CareTeamMemberTypesV2ForPatientCareTeamQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        CareTeamMemberTypesV2ForPatientCareTeamQuery,
        CareTeamMemberTypesV2ForPatientCareTeamQueryVariables
    >(CareTeamMemberTypesV2ForPatientCareTeamDocument, options);
}
export type CareTeamMemberTypesV2ForPatientCareTeamQueryHookResult = ReturnType<
    typeof useCareTeamMemberTypesV2ForPatientCareTeamQuery
>;
export type CareTeamMemberTypesV2ForPatientCareTeamLazyQueryHookResult = ReturnType<
    typeof useCareTeamMemberTypesV2ForPatientCareTeamLazyQuery
>;
export type CareTeamMemberTypesV2ForPatientCareTeamQueryResult = Apollo.QueryResult<
    CareTeamMemberTypesV2ForPatientCareTeamQuery,
    CareTeamMemberTypesV2ForPatientCareTeamQueryVariables
>;
export const CareTeamMembersV2ForPatientCareTeamDocument = gql`
    query CareTeamMembersV2ForPatientCareTeam($input: CareTeamMemberQueryInput) {
        careTeamMembersV2(input: $input) {
            results {
                ...CareTeamMemberFragment
            }
            total
        }
    }
    ${CareTeamMemberFragmentFragmentDoc}
`;

/**
 * __useCareTeamMembersV2ForPatientCareTeamQuery__
 *
 * To run a query within a React component, call `useCareTeamMembersV2ForPatientCareTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareTeamMembersV2ForPatientCareTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareTeamMembersV2ForPatientCareTeamQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCareTeamMembersV2ForPatientCareTeamQuery(
    baseOptions?: Apollo.QueryHookOptions<
        CareTeamMembersV2ForPatientCareTeamQuery,
        CareTeamMembersV2ForPatientCareTeamQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        CareTeamMembersV2ForPatientCareTeamQuery,
        CareTeamMembersV2ForPatientCareTeamQueryVariables
    >(CareTeamMembersV2ForPatientCareTeamDocument, options);
}
export function useCareTeamMembersV2ForPatientCareTeamLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CareTeamMembersV2ForPatientCareTeamQuery,
        CareTeamMembersV2ForPatientCareTeamQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        CareTeamMembersV2ForPatientCareTeamQuery,
        CareTeamMembersV2ForPatientCareTeamQueryVariables
    >(CareTeamMembersV2ForPatientCareTeamDocument, options);
}
export type CareTeamMembersV2ForPatientCareTeamQueryHookResult = ReturnType<
    typeof useCareTeamMembersV2ForPatientCareTeamQuery
>;
export type CareTeamMembersV2ForPatientCareTeamLazyQueryHookResult = ReturnType<
    typeof useCareTeamMembersV2ForPatientCareTeamLazyQuery
>;
export type CareTeamMembersV2ForPatientCareTeamQueryResult = Apollo.QueryResult<
    CareTeamMembersV2ForPatientCareTeamQuery,
    CareTeamMembersV2ForPatientCareTeamQueryVariables
>;
export const PatientProfileVariablesForPatientCareTeamPageDocument = gql`
    query PatientProfileVariablesForPatientCareTeamPage(
        $patientId: String!
        $profileVariableDefIds: [String!]
    ) {
        patientProfileVariables(
            patientId: $patientId
            profileVariableDefIds: $profileVariableDefIds
        ) {
            ...PatientProfileVariablesFragment
        }
    }
    ${PatientProfileVariablesFragmentFragmentDoc}
`;

/**
 * __usePatientProfileVariablesForPatientCareTeamPageQuery__
 *
 * To run a query within a React component, call `usePatientProfileVariablesForPatientCareTeamPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientProfileVariablesForPatientCareTeamPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientProfileVariablesForPatientCareTeamPageQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      profileVariableDefIds: // value for 'profileVariableDefIds'
 *   },
 * });
 */
export function usePatientProfileVariablesForPatientCareTeamPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        PatientProfileVariablesForPatientCareTeamPageQuery,
        PatientProfileVariablesForPatientCareTeamPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        PatientProfileVariablesForPatientCareTeamPageQuery,
        PatientProfileVariablesForPatientCareTeamPageQueryVariables
    >(PatientProfileVariablesForPatientCareTeamPageDocument, options);
}
export function usePatientProfileVariablesForPatientCareTeamPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PatientProfileVariablesForPatientCareTeamPageQuery,
        PatientProfileVariablesForPatientCareTeamPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        PatientProfileVariablesForPatientCareTeamPageQuery,
        PatientProfileVariablesForPatientCareTeamPageQueryVariables
    >(PatientProfileVariablesForPatientCareTeamPageDocument, options);
}
export type PatientProfileVariablesForPatientCareTeamPageQueryHookResult = ReturnType<
    typeof usePatientProfileVariablesForPatientCareTeamPageQuery
>;
export type PatientProfileVariablesForPatientCareTeamPageLazyQueryHookResult = ReturnType<
    typeof usePatientProfileVariablesForPatientCareTeamPageLazyQuery
>;
export type PatientProfileVariablesForPatientCareTeamPageQueryResult = Apollo.QueryResult<
    PatientProfileVariablesForPatientCareTeamPageQuery,
    PatientProfileVariablesForPatientCareTeamPageQueryVariables
>;
export const UpdatePatientProfileVariableForCareTeamDocument = gql`
    mutation UpdatePatientProfileVariableForCareTeam($input: PatientProfileVariableInput!) {
        updatePatientProfileVariable(input: $input) {
            profileVariableDefId
            value {
                str
            }
        }
    }
`;
export type UpdatePatientProfileVariableForCareTeamMutationFn = Apollo.MutationFunction<
    UpdatePatientProfileVariableForCareTeamMutation,
    UpdatePatientProfileVariableForCareTeamMutationVariables
>;

/**
 * __useUpdatePatientProfileVariableForCareTeamMutation__
 *
 * To run a mutation, you first call `useUpdatePatientProfileVariableForCareTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientProfileVariableForCareTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientProfileVariableForCareTeamMutation, { data, loading, error }] = useUpdatePatientProfileVariableForCareTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientProfileVariableForCareTeamMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePatientProfileVariableForCareTeamMutation,
        UpdatePatientProfileVariableForCareTeamMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdatePatientProfileVariableForCareTeamMutation,
        UpdatePatientProfileVariableForCareTeamMutationVariables
    >(UpdatePatientProfileVariableForCareTeamDocument, options);
}
export type UpdatePatientProfileVariableForCareTeamMutationHookResult = ReturnType<
    typeof useUpdatePatientProfileVariableForCareTeamMutation
>;
export type UpdatePatientProfileVariableForCareTeamMutationResult =
    Apollo.MutationResult<UpdatePatientProfileVariableForCareTeamMutation>;
export type UpdatePatientProfileVariableForCareTeamMutationOptions = Apollo.BaseMutationOptions<
    UpdatePatientProfileVariableForCareTeamMutation,
    UpdatePatientProfileVariableForCareTeamMutationVariables
>;
export const FetchChatRoomForPortalUserForPatientChatPageDocument = gql`
    query FetchChatRoomForPortalUserForPatientChatPage($input: FetchChatRoomForPortalUserInput) {
        fetchChatRoomForPortalUser(input: $input) {
            id
            numberOfUnreadMessages
            careTeamMemberTypeId
            careTeamMemberType {
                name
            }
            patient {
                id
                firstName
                lastName
                practice {
                    name
                }
            }
            participants {
                id
                name
            }
            messages {
                ...ChatMessageForMessageCenter
            }
        }
    }
    ${ChatMessageForMessageCenterFragmentDoc}
`;

/**
 * __useFetchChatRoomForPortalUserForPatientChatPageQuery__
 *
 * To run a query within a React component, call `useFetchChatRoomForPortalUserForPatientChatPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchChatRoomForPortalUserForPatientChatPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchChatRoomForPortalUserForPatientChatPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchChatRoomForPortalUserForPatientChatPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchChatRoomForPortalUserForPatientChatPageQuery,
        FetchChatRoomForPortalUserForPatientChatPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchChatRoomForPortalUserForPatientChatPageQuery,
        FetchChatRoomForPortalUserForPatientChatPageQueryVariables
    >(FetchChatRoomForPortalUserForPatientChatPageDocument, options);
}
export function useFetchChatRoomForPortalUserForPatientChatPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchChatRoomForPortalUserForPatientChatPageQuery,
        FetchChatRoomForPortalUserForPatientChatPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchChatRoomForPortalUserForPatientChatPageQuery,
        FetchChatRoomForPortalUserForPatientChatPageQueryVariables
    >(FetchChatRoomForPortalUserForPatientChatPageDocument, options);
}
export type FetchChatRoomForPortalUserForPatientChatPageQueryHookResult = ReturnType<
    typeof useFetchChatRoomForPortalUserForPatientChatPageQuery
>;
export type FetchChatRoomForPortalUserForPatientChatPageLazyQueryHookResult = ReturnType<
    typeof useFetchChatRoomForPortalUserForPatientChatPageLazyQuery
>;
export type FetchChatRoomForPortalUserForPatientChatPageQueryResult = Apollo.QueryResult<
    FetchChatRoomForPortalUserForPatientChatPageQuery,
    FetchChatRoomForPortalUserForPatientChatPageQueryVariables
>;
export const CareTeamMemberTypesV2ForPatientChatDocument = gql`
    query CareTeamMemberTypesV2ForPatientChat($input: CareTeamMemberTypeQueryInput) {
        careTeamMemberTypesV2(input: $input) {
            results {
                id
                isJob
                name
            }
        }
    }
`;

/**
 * __useCareTeamMemberTypesV2ForPatientChatQuery__
 *
 * To run a query within a React component, call `useCareTeamMemberTypesV2ForPatientChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareTeamMemberTypesV2ForPatientChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareTeamMemberTypesV2ForPatientChatQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCareTeamMemberTypesV2ForPatientChatQuery(
    baseOptions?: Apollo.QueryHookOptions<
        CareTeamMemberTypesV2ForPatientChatQuery,
        CareTeamMemberTypesV2ForPatientChatQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        CareTeamMemberTypesV2ForPatientChatQuery,
        CareTeamMemberTypesV2ForPatientChatQueryVariables
    >(CareTeamMemberTypesV2ForPatientChatDocument, options);
}
export function useCareTeamMemberTypesV2ForPatientChatLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CareTeamMemberTypesV2ForPatientChatQuery,
        CareTeamMemberTypesV2ForPatientChatQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        CareTeamMemberTypesV2ForPatientChatQuery,
        CareTeamMemberTypesV2ForPatientChatQueryVariables
    >(CareTeamMemberTypesV2ForPatientChatDocument, options);
}
export type CareTeamMemberTypesV2ForPatientChatQueryHookResult = ReturnType<
    typeof useCareTeamMemberTypesV2ForPatientChatQuery
>;
export type CareTeamMemberTypesV2ForPatientChatLazyQueryHookResult = ReturnType<
    typeof useCareTeamMemberTypesV2ForPatientChatLazyQuery
>;
export type CareTeamMemberTypesV2ForPatientChatQueryResult = Apollo.QueryResult<
    CareTeamMemberTypesV2ForPatientChatQuery,
    CareTeamMemberTypesV2ForPatientChatQueryVariables
>;
export const ClinicalSummarysV2Document = gql`
    query ClinicalSummarysV2($input: ClinicalSummaryQueryInput) {
        clinicalSummarysV2(input: $input) {
            hasMore
            limit
            hasPrevious
            skip
            total
            results {
                createdAt
                updatedAt
                isTestData
                id
                logId
                patientId
                visitId
                redoxEhrId
            }
            orderBy
        }
    }
`;

/**
 * __useClinicalSummarysV2Query__
 *
 * To run a query within a React component, call `useClinicalSummarysV2Query` and pass it any options that fit your needs.
 * When your component renders, `useClinicalSummarysV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClinicalSummarysV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClinicalSummarysV2Query(
    baseOptions?: Apollo.QueryHookOptions<
        ClinicalSummarysV2Query,
        ClinicalSummarysV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ClinicalSummarysV2Query, ClinicalSummarysV2QueryVariables>(
        ClinicalSummarysV2Document,
        options,
    );
}
export function useClinicalSummarysV2LazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ClinicalSummarysV2Query,
        ClinicalSummarysV2QueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ClinicalSummarysV2Query, ClinicalSummarysV2QueryVariables>(
        ClinicalSummarysV2Document,
        options,
    );
}
export type ClinicalSummarysV2QueryHookResult = ReturnType<typeof useClinicalSummarysV2Query>;
export type ClinicalSummarysV2LazyQueryHookResult = ReturnType<
    typeof useClinicalSummarysV2LazyQuery
>;
export type ClinicalSummarysV2QueryResult = Apollo.QueryResult<
    ClinicalSummarysV2Query,
    ClinicalSummarysV2QueryVariables
>;
export const GetClinicalSummaryLogByIdDocument = gql`
    query GetClinicalSummaryLogById($input: GetClinicalSummaryByLogIdInput!) {
        getClinicalSummaryLogById(input: $input) {
            raw
        }
    }
`;

/**
 * __useGetClinicalSummaryLogByIdQuery__
 *
 * To run a query within a React component, call `useGetClinicalSummaryLogByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicalSummaryLogByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicalSummaryLogByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClinicalSummaryLogByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetClinicalSummaryLogByIdQuery,
        GetClinicalSummaryLogByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetClinicalSummaryLogByIdQuery, GetClinicalSummaryLogByIdQueryVariables>(
        GetClinicalSummaryLogByIdDocument,
        options,
    );
}
export function useGetClinicalSummaryLogByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetClinicalSummaryLogByIdQuery,
        GetClinicalSummaryLogByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetClinicalSummaryLogByIdQuery,
        GetClinicalSummaryLogByIdQueryVariables
    >(GetClinicalSummaryLogByIdDocument, options);
}
export type GetClinicalSummaryLogByIdQueryHookResult = ReturnType<
    typeof useGetClinicalSummaryLogByIdQuery
>;
export type GetClinicalSummaryLogByIdLazyQueryHookResult = ReturnType<
    typeof useGetClinicalSummaryLogByIdLazyQuery
>;
export type GetClinicalSummaryLogByIdQueryResult = Apollo.QueryResult<
    GetClinicalSummaryLogByIdQuery,
    GetClinicalSummaryLogByIdQueryVariables
>;
export const FetchReferenceLinksForPatientClinicalSummariesPageDocument = gql`
    query FetchReferenceLinksForPatientClinicalSummariesPage(
        $input: GetReferenceLinksForPageInput!
    ) {
        getReferenceLinksForPage(input: $input) {
            category
            data {
                label
                url
            }
        }
    }
`;

/**
 * __useFetchReferenceLinksForPatientClinicalSummariesPageQuery__
 *
 * To run a query within a React component, call `useFetchReferenceLinksForPatientClinicalSummariesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchReferenceLinksForPatientClinicalSummariesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchReferenceLinksForPatientClinicalSummariesPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchReferenceLinksForPatientClinicalSummariesPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchReferenceLinksForPatientClinicalSummariesPageQuery,
        FetchReferenceLinksForPatientClinicalSummariesPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchReferenceLinksForPatientClinicalSummariesPageQuery,
        FetchReferenceLinksForPatientClinicalSummariesPageQueryVariables
    >(FetchReferenceLinksForPatientClinicalSummariesPageDocument, options);
}
export function useFetchReferenceLinksForPatientClinicalSummariesPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchReferenceLinksForPatientClinicalSummariesPageQuery,
        FetchReferenceLinksForPatientClinicalSummariesPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchReferenceLinksForPatientClinicalSummariesPageQuery,
        FetchReferenceLinksForPatientClinicalSummariesPageQueryVariables
    >(FetchReferenceLinksForPatientClinicalSummariesPageDocument, options);
}
export type FetchReferenceLinksForPatientClinicalSummariesPageQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForPatientClinicalSummariesPageQuery
>;
export type FetchReferenceLinksForPatientClinicalSummariesPageLazyQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForPatientClinicalSummariesPageLazyQuery
>;
export type FetchReferenceLinksForPatientClinicalSummariesPageQueryResult = Apollo.QueryResult<
    FetchReferenceLinksForPatientClinicalSummariesPageQuery,
    FetchReferenceLinksForPatientClinicalSummariesPageQueryVariables
>;
export const ClinicalSummaryDocument = gql`
    query ClinicalSummary($input: GetClinicalSummaryInput!) {
        clinicalSummary(input: $input) {
            createdAt
            updatedAt
            isTestData
            id
            logId
            redoxEhrId
            redoxSourceId
            patientId
            patient {
                fullName
            }
            visitId
            problemsText
            problems {
                name
                code
                codeSystem
                codeSystemName
                startDate
                endDate
                category {
                    name
                    code
                    codeSystem
                    codeSystemName
                }
                status {
                    name
                    code
                    codeSystem
                    codeSystemName
                }
                comment
                comments {
                    text
                }
                altCodes {
                    name
                    code
                    codeSystem
                    codeSystemName
                }
            }
            resolvedProblemsText
            resolvedProblems {
                name
                code
                codeSystem
                codeSystemName
                startDate
                endDate
                category {
                    name
                    code
                    codeSystem
                    codeSystemName
                }
                status {
                    name
                    code
                    codeSystem
                    codeSystemName
                }
                comment
                comments {
                    text
                }
                altCodes {
                    name
                    code
                    codeSystem
                    codeSystemName
                }
            }
            demographics {
                language
                race
                raceCodes {
                    name
                    code
                    codeSystem
                    codeSystemName
                }
                ethnicity
                ethnicGroupCodes {
                    name
                    code
                    codeSystem
                    codeSystemName
                }
            }
            medications {
                prescription
                freeTextSig
                dose {
                    quantity
                    units
                }
                rate {
                    quantity
                    units
                }
                route {
                    name
                    code
                    codeSystem
                    codeSystemName
                }
                status
                startDate
                endDate
                frequency {
                    period
                    periodMax
                    unit
                }
                isPRN
                product {
                    name
                    code
                    codeSystem
                    codeSystemName
                }
                indications {
                    name
                    code
                    codeSystem
                    codeSystemName
                    altCodes {
                        name
                        code
                        codeSystem
                        codeSystemName
                    }
                }
            }
        }
    }
`;

/**
 * __useClinicalSummaryQuery__
 *
 * To run a query within a React component, call `useClinicalSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useClinicalSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClinicalSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClinicalSummaryQuery(
    baseOptions: Apollo.QueryHookOptions<ClinicalSummaryQuery, ClinicalSummaryQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ClinicalSummaryQuery, ClinicalSummaryQueryVariables>(
        ClinicalSummaryDocument,
        options,
    );
}
export function useClinicalSummaryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ClinicalSummaryQuery, ClinicalSummaryQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ClinicalSummaryQuery, ClinicalSummaryQueryVariables>(
        ClinicalSummaryDocument,
        options,
    );
}
export type ClinicalSummaryQueryHookResult = ReturnType<typeof useClinicalSummaryQuery>;
export type ClinicalSummaryLazyQueryHookResult = ReturnType<typeof useClinicalSummaryLazyQuery>;
export type ClinicalSummaryQueryResult = Apollo.QueryResult<
    ClinicalSummaryQuery,
    ClinicalSummaryQueryVariables
>;
export const FetchPatientForPatientDetailsPageDocument = gql`
    query FetchPatientForPatientDetailsPage($input: GetPatientInput!) {
        patient(input: $input) {
            ...PatientForPatientsPage
        }
    }
    ${PatientForPatientsPageFragmentDoc}
`;

/**
 * __useFetchPatientForPatientDetailsPageQuery__
 *
 * To run a query within a React component, call `useFetchPatientForPatientDetailsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientForPatientDetailsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientForPatientDetailsPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPatientForPatientDetailsPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPatientForPatientDetailsPageQuery,
        FetchPatientForPatientDetailsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientForPatientDetailsPageQuery,
        FetchPatientForPatientDetailsPageQueryVariables
    >(FetchPatientForPatientDetailsPageDocument, options);
}
export function useFetchPatientForPatientDetailsPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientForPatientDetailsPageQuery,
        FetchPatientForPatientDetailsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientForPatientDetailsPageQuery,
        FetchPatientForPatientDetailsPageQueryVariables
    >(FetchPatientForPatientDetailsPageDocument, options);
}
export type FetchPatientForPatientDetailsPageQueryHookResult = ReturnType<
    typeof useFetchPatientForPatientDetailsPageQuery
>;
export type FetchPatientForPatientDetailsPageLazyQueryHookResult = ReturnType<
    typeof useFetchPatientForPatientDetailsPageLazyQuery
>;
export type FetchPatientForPatientDetailsPageQueryResult = Apollo.QueryResult<
    FetchPatientForPatientDetailsPageQuery,
    FetchPatientForPatientDetailsPageQueryVariables
>;
export const FetchPatientProfileVariablesForPatientDetailsPageDocument = gql`
    query FetchPatientProfileVariablesForPatientDetailsPage(
        $patientId: String!
        $profileVariableDefIds: [String!]
    ) {
        patientProfileVariables(
            patientId: $patientId
            profileVariableDefIds: $profileVariableDefIds
        ) {
            ...PatientProfileVariablesFragment
        }
    }
    ${PatientProfileVariablesFragmentFragmentDoc}
`;

/**
 * __useFetchPatientProfileVariablesForPatientDetailsPageQuery__
 *
 * To run a query within a React component, call `useFetchPatientProfileVariablesForPatientDetailsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientProfileVariablesForPatientDetailsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientProfileVariablesForPatientDetailsPageQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      profileVariableDefIds: // value for 'profileVariableDefIds'
 *   },
 * });
 */
export function useFetchPatientProfileVariablesForPatientDetailsPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPatientProfileVariablesForPatientDetailsPageQuery,
        FetchPatientProfileVariablesForPatientDetailsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientProfileVariablesForPatientDetailsPageQuery,
        FetchPatientProfileVariablesForPatientDetailsPageQueryVariables
    >(FetchPatientProfileVariablesForPatientDetailsPageDocument, options);
}
export function useFetchPatientProfileVariablesForPatientDetailsPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientProfileVariablesForPatientDetailsPageQuery,
        FetchPatientProfileVariablesForPatientDetailsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientProfileVariablesForPatientDetailsPageQuery,
        FetchPatientProfileVariablesForPatientDetailsPageQueryVariables
    >(FetchPatientProfileVariablesForPatientDetailsPageDocument, options);
}
export type FetchPatientProfileVariablesForPatientDetailsPageQueryHookResult = ReturnType<
    typeof useFetchPatientProfileVariablesForPatientDetailsPageQuery
>;
export type FetchPatientProfileVariablesForPatientDetailsPageLazyQueryHookResult = ReturnType<
    typeof useFetchPatientProfileVariablesForPatientDetailsPageLazyQuery
>;
export type FetchPatientProfileVariablesForPatientDetailsPageQueryResult = Apollo.QueryResult<
    FetchPatientProfileVariablesForPatientDetailsPageQuery,
    FetchPatientProfileVariablesForPatientDetailsPageQueryVariables
>;
export const AdvocateTasksForPatientHaTaskPageDocument = gql`
    query AdvocateTasksForPatientHATaskPage($input: GetFilteredAdvocateTasksInput) {
        customFilteredAdvocateTasks(input: $input) {
            orderBy
            skip
            limit
            total
            hasMore
            hasPrevious
            results {
                id
                label
                description
                patient {
                    id
                    fullName
                    practice {
                        id
                        brandingName
                    }
                }
                dueDate
                createdAt
                completedAt
                overdue
                priority
                status
                assignedTo
            }
        }
    }
`;

/**
 * __useAdvocateTasksForPatientHaTaskPageQuery__
 *
 * To run a query within a React component, call `useAdvocateTasksForPatientHaTaskPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvocateTasksForPatientHaTaskPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvocateTasksForPatientHaTaskPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdvocateTasksForPatientHaTaskPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        AdvocateTasksForPatientHaTaskPageQuery,
        AdvocateTasksForPatientHaTaskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        AdvocateTasksForPatientHaTaskPageQuery,
        AdvocateTasksForPatientHaTaskPageQueryVariables
    >(AdvocateTasksForPatientHaTaskPageDocument, options);
}
export function useAdvocateTasksForPatientHaTaskPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        AdvocateTasksForPatientHaTaskPageQuery,
        AdvocateTasksForPatientHaTaskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        AdvocateTasksForPatientHaTaskPageQuery,
        AdvocateTasksForPatientHaTaskPageQueryVariables
    >(AdvocateTasksForPatientHaTaskPageDocument, options);
}
export type AdvocateTasksForPatientHaTaskPageQueryHookResult = ReturnType<
    typeof useAdvocateTasksForPatientHaTaskPageQuery
>;
export type AdvocateTasksForPatientHaTaskPageLazyQueryHookResult = ReturnType<
    typeof useAdvocateTasksForPatientHaTaskPageLazyQuery
>;
export type AdvocateTasksForPatientHaTaskPageQueryResult = Apollo.QueryResult<
    AdvocateTasksForPatientHaTaskPageQuery,
    AdvocateTasksForPatientHaTaskPageQueryVariables
>;
export const FetchAdvocatesForHaTaskPatientPageDocument = gql`
    query FetchAdvocatesForHaTaskPatientPage($permission: String) {
        getUsersByPermission(permission: $permission) {
            id
            name
        }
    }
`;

/**
 * __useFetchAdvocatesForHaTaskPatientPageQuery__
 *
 * To run a query within a React component, call `useFetchAdvocatesForHaTaskPatientPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAdvocatesForHaTaskPatientPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAdvocatesForHaTaskPatientPageQuery({
 *   variables: {
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useFetchAdvocatesForHaTaskPatientPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchAdvocatesForHaTaskPatientPageQuery,
        FetchAdvocatesForHaTaskPatientPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchAdvocatesForHaTaskPatientPageQuery,
        FetchAdvocatesForHaTaskPatientPageQueryVariables
    >(FetchAdvocatesForHaTaskPatientPageDocument, options);
}
export function useFetchAdvocatesForHaTaskPatientPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchAdvocatesForHaTaskPatientPageQuery,
        FetchAdvocatesForHaTaskPatientPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchAdvocatesForHaTaskPatientPageQuery,
        FetchAdvocatesForHaTaskPatientPageQueryVariables
    >(FetchAdvocatesForHaTaskPatientPageDocument, options);
}
export type FetchAdvocatesForHaTaskPatientPageQueryHookResult = ReturnType<
    typeof useFetchAdvocatesForHaTaskPatientPageQuery
>;
export type FetchAdvocatesForHaTaskPatientPageLazyQueryHookResult = ReturnType<
    typeof useFetchAdvocatesForHaTaskPatientPageLazyQuery
>;
export type FetchAdvocatesForHaTaskPatientPageQueryResult = Apollo.QueryResult<
    FetchAdvocatesForHaTaskPatientPageQuery,
    FetchAdvocatesForHaTaskPatientPageQueryVariables
>;
export const FetchReferenceLinksForPatientHaTaskPageDocument = gql`
    query FetchReferenceLinksForPatientHaTaskPage($input: GetReferenceLinksForPageInput!) {
        getReferenceLinksForPage(input: $input) {
            category
            data {
                label
                url
            }
        }
    }
`;

/**
 * __useFetchReferenceLinksForPatientHaTaskPageQuery__
 *
 * To run a query within a React component, call `useFetchReferenceLinksForPatientHaTaskPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchReferenceLinksForPatientHaTaskPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchReferenceLinksForPatientHaTaskPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchReferenceLinksForPatientHaTaskPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchReferenceLinksForPatientHaTaskPageQuery,
        FetchReferenceLinksForPatientHaTaskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchReferenceLinksForPatientHaTaskPageQuery,
        FetchReferenceLinksForPatientHaTaskPageQueryVariables
    >(FetchReferenceLinksForPatientHaTaskPageDocument, options);
}
export function useFetchReferenceLinksForPatientHaTaskPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchReferenceLinksForPatientHaTaskPageQuery,
        FetchReferenceLinksForPatientHaTaskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchReferenceLinksForPatientHaTaskPageQuery,
        FetchReferenceLinksForPatientHaTaskPageQueryVariables
    >(FetchReferenceLinksForPatientHaTaskPageDocument, options);
}
export type FetchReferenceLinksForPatientHaTaskPageQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForPatientHaTaskPageQuery
>;
export type FetchReferenceLinksForPatientHaTaskPageLazyQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForPatientHaTaskPageLazyQuery
>;
export type FetchReferenceLinksForPatientHaTaskPageQueryResult = Apollo.QueryResult<
    FetchReferenceLinksForPatientHaTaskPageQuery,
    FetchReferenceLinksForPatientHaTaskPageQueryVariables
>;
export const GetPatientHighlightsDocument = gql`
    query getPatientHighlights($patientId: String, $appBundleId: String) {
        patientHighlights(patientId: $patientId, appBundleId: $appBundleId) {
            todayitemcount
            todayitems {
                ...PatientHighlightFragment
            }
            futureitemcount
            futureitems {
                ...PatientHighlightFragment
            }
        }
    }
    ${PatientHighlightFragmentFragmentDoc}
`;

/**
 * __useGetPatientHighlightsQuery__
 *
 * To run a query within a React component, call `useGetPatientHighlightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientHighlightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientHighlightsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      appBundleId: // value for 'appBundleId'
 *   },
 * });
 */
export function useGetPatientHighlightsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetPatientHighlightsQuery,
        GetPatientHighlightsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetPatientHighlightsQuery, GetPatientHighlightsQueryVariables>(
        GetPatientHighlightsDocument,
        options,
    );
}
export function useGetPatientHighlightsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetPatientHighlightsQuery,
        GetPatientHighlightsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetPatientHighlightsQuery, GetPatientHighlightsQueryVariables>(
        GetPatientHighlightsDocument,
        options,
    );
}
export type GetPatientHighlightsQueryHookResult = ReturnType<typeof useGetPatientHighlightsQuery>;
export type GetPatientHighlightsLazyQueryHookResult = ReturnType<
    typeof useGetPatientHighlightsLazyQuery
>;
export type GetPatientHighlightsQueryResult = Apollo.QueryResult<
    GetPatientHighlightsQuery,
    GetPatientHighlightsQueryVariables
>;
export const UpdateHighlightFeedItemDocument = gql`
    mutation UpdateHighlightFeedItem($input: PatientHighlightUpdateInput!) {
        highlightFeedUpdatePriorityClosed(input: $input) {
            status
            message
            success
        }
    }
`;
export type UpdateHighlightFeedItemMutationFn = Apollo.MutationFunction<
    UpdateHighlightFeedItemMutation,
    UpdateHighlightFeedItemMutationVariables
>;

/**
 * __useUpdateHighlightFeedItemMutation__
 *
 * To run a mutation, you first call `useUpdateHighlightFeedItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHighlightFeedItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHighlightFeedItemMutation, { data, loading, error }] = useUpdateHighlightFeedItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHighlightFeedItemMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateHighlightFeedItemMutation,
        UpdateHighlightFeedItemMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateHighlightFeedItemMutation,
        UpdateHighlightFeedItemMutationVariables
    >(UpdateHighlightFeedItemDocument, options);
}
export type UpdateHighlightFeedItemMutationHookResult = ReturnType<
    typeof useUpdateHighlightFeedItemMutation
>;
export type UpdateHighlightFeedItemMutationResult =
    Apollo.MutationResult<UpdateHighlightFeedItemMutation>;
export type UpdateHighlightFeedItemMutationOptions = Apollo.BaseMutationOptions<
    UpdateHighlightFeedItemMutation,
    UpdateHighlightFeedItemMutationVariables
>;
export const FetchMeasurementsForPatientMeasurementsPageDocument = gql`
    query FetchMeasurementsForPatientMeasurementsPage(
        $patientInput: GetPatientInput!
        $measurementsInput: PatientMeasurementsQueryInput
    ) {
        patient(input: $patientInput) {
            ...PatientForPatientsPage
            measurements(input: $measurementsInput) {
                id
                flagged
                externalId
                updatedAt
                createdAt
                value {
                    movement {
                        duration
                    }
                    weight {
                        measure
                        units
                    }
                    bloodPressure {
                        pulse
                        diastolic
                        systolic
                        symptoms
                        symptomsOption
                    }
                    dailyMeasurement {
                        glucose
                        moodLevel
                        painLevel
                        steps
                        temperature
                        sleepStarts
                        sleepEnds
                        notes
                    }
                    bloodGlucose {
                        dateTime
                        activityOption
                        glucose
                        meal
                        mealOption
                        mealTime
                        movement
                        notes
                    }
                }
                type
                takenDate
                timezoneName
                inputType
                comment
                patient {
                    id
                    externalId
                    firstName
                    lastName
                    fullName
                    birthDate
                    practice {
                        timezone
                    }
                }
                device {
                    id
                    localizedName
                }
                hasHistory
            }
        }
    }
    ${PatientForPatientsPageFragmentDoc}
`;

/**
 * __useFetchMeasurementsForPatientMeasurementsPageQuery__
 *
 * To run a query within a React component, call `useFetchMeasurementsForPatientMeasurementsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeasurementsForPatientMeasurementsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeasurementsForPatientMeasurementsPageQuery({
 *   variables: {
 *      patientInput: // value for 'patientInput'
 *      measurementsInput: // value for 'measurementsInput'
 *   },
 * });
 */
export function useFetchMeasurementsForPatientMeasurementsPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchMeasurementsForPatientMeasurementsPageQuery,
        FetchMeasurementsForPatientMeasurementsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchMeasurementsForPatientMeasurementsPageQuery,
        FetchMeasurementsForPatientMeasurementsPageQueryVariables
    >(FetchMeasurementsForPatientMeasurementsPageDocument, options);
}
export function useFetchMeasurementsForPatientMeasurementsPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchMeasurementsForPatientMeasurementsPageQuery,
        FetchMeasurementsForPatientMeasurementsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchMeasurementsForPatientMeasurementsPageQuery,
        FetchMeasurementsForPatientMeasurementsPageQueryVariables
    >(FetchMeasurementsForPatientMeasurementsPageDocument, options);
}
export type FetchMeasurementsForPatientMeasurementsPageQueryHookResult = ReturnType<
    typeof useFetchMeasurementsForPatientMeasurementsPageQuery
>;
export type FetchMeasurementsForPatientMeasurementsPageLazyQueryHookResult = ReturnType<
    typeof useFetchMeasurementsForPatientMeasurementsPageLazyQuery
>;
export type FetchMeasurementsForPatientMeasurementsPageQueryResult = Apollo.QueryResult<
    FetchMeasurementsForPatientMeasurementsPageQuery,
    FetchMeasurementsForPatientMeasurementsPageQueryVariables
>;
export const FetchReferenceLinksForPatientMeasurementsPageDocument = gql`
    query FetchReferenceLinksForPatientMeasurementsPage($input: GetReferenceLinksForPageInput!) {
        getReferenceLinksForPage(input: $input) {
            category
            data {
                label
                url
            }
        }
    }
`;

/**
 * __useFetchReferenceLinksForPatientMeasurementsPageQuery__
 *
 * To run a query within a React component, call `useFetchReferenceLinksForPatientMeasurementsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchReferenceLinksForPatientMeasurementsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchReferenceLinksForPatientMeasurementsPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchReferenceLinksForPatientMeasurementsPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchReferenceLinksForPatientMeasurementsPageQuery,
        FetchReferenceLinksForPatientMeasurementsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchReferenceLinksForPatientMeasurementsPageQuery,
        FetchReferenceLinksForPatientMeasurementsPageQueryVariables
    >(FetchReferenceLinksForPatientMeasurementsPageDocument, options);
}
export function useFetchReferenceLinksForPatientMeasurementsPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchReferenceLinksForPatientMeasurementsPageQuery,
        FetchReferenceLinksForPatientMeasurementsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchReferenceLinksForPatientMeasurementsPageQuery,
        FetchReferenceLinksForPatientMeasurementsPageQueryVariables
    >(FetchReferenceLinksForPatientMeasurementsPageDocument, options);
}
export type FetchReferenceLinksForPatientMeasurementsPageQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForPatientMeasurementsPageQuery
>;
export type FetchReferenceLinksForPatientMeasurementsPageLazyQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForPatientMeasurementsPageLazyQuery
>;
export type FetchReferenceLinksForPatientMeasurementsPageQueryResult = Apollo.QueryResult<
    FetchReferenceLinksForPatientMeasurementsPageQuery,
    FetchReferenceLinksForPatientMeasurementsPageQueryVariables
>;
export const FetchPatientNotesForPatientNotesDocument = gql`
    query FetchPatientNotesForPatientNotes($input: GetPatientInput!) {
        patient(input: $input) {
            id
            firstName
            notes {
                id
                createdAt
                title
                date
                noteSource
                noteType
                staffName
                text
                patient {
                    id
                }
            }
        }
    }
`;

/**
 * __useFetchPatientNotesForPatientNotesQuery__
 *
 * To run a query within a React component, call `useFetchPatientNotesForPatientNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientNotesForPatientNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientNotesForPatientNotesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPatientNotesForPatientNotesQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPatientNotesForPatientNotesQuery,
        FetchPatientNotesForPatientNotesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientNotesForPatientNotesQuery,
        FetchPatientNotesForPatientNotesQueryVariables
    >(FetchPatientNotesForPatientNotesDocument, options);
}
export function useFetchPatientNotesForPatientNotesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientNotesForPatientNotesQuery,
        FetchPatientNotesForPatientNotesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientNotesForPatientNotesQuery,
        FetchPatientNotesForPatientNotesQueryVariables
    >(FetchPatientNotesForPatientNotesDocument, options);
}
export type FetchPatientNotesForPatientNotesQueryHookResult = ReturnType<
    typeof useFetchPatientNotesForPatientNotesQuery
>;
export type FetchPatientNotesForPatientNotesLazyQueryHookResult = ReturnType<
    typeof useFetchPatientNotesForPatientNotesLazyQuery
>;
export type FetchPatientNotesForPatientNotesQueryResult = Apollo.QueryResult<
    FetchPatientNotesForPatientNotesQuery,
    FetchPatientNotesForPatientNotesQueryVariables
>;
export const NoteTagListForPatientNotesPageDocument = gql`
    query NoteTagListForPatientNotesPage {
        patientNoteTagsV2 {
            results {
                id
                name
            }
        }
    }
`;

/**
 * __useNoteTagListForPatientNotesPageQuery__
 *
 * To run a query within a React component, call `useNoteTagListForPatientNotesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteTagListForPatientNotesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteTagListForPatientNotesPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useNoteTagListForPatientNotesPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        NoteTagListForPatientNotesPageQuery,
        NoteTagListForPatientNotesPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        NoteTagListForPatientNotesPageQuery,
        NoteTagListForPatientNotesPageQueryVariables
    >(NoteTagListForPatientNotesPageDocument, options);
}
export function useNoteTagListForPatientNotesPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        NoteTagListForPatientNotesPageQuery,
        NoteTagListForPatientNotesPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        NoteTagListForPatientNotesPageQuery,
        NoteTagListForPatientNotesPageQueryVariables
    >(NoteTagListForPatientNotesPageDocument, options);
}
export type NoteTagListForPatientNotesPageQueryHookResult = ReturnType<
    typeof useNoteTagListForPatientNotesPageQuery
>;
export type NoteTagListForPatientNotesPageLazyQueryHookResult = ReturnType<
    typeof useNoteTagListForPatientNotesPageLazyQuery
>;
export type NoteTagListForPatientNotesPageQueryResult = Apollo.QueryResult<
    NoteTagListForPatientNotesPageQuery,
    NoteTagListForPatientNotesPageQueryVariables
>;
export const FetchReferenceLinksForPatientNotesPageDocument = gql`
    query FetchReferenceLinksForPatientNotesPage($input: GetReferenceLinksForPageInput!) {
        getReferenceLinksForPage(input: $input) {
            category
            data {
                label
                url
            }
        }
    }
`;

/**
 * __useFetchReferenceLinksForPatientNotesPageQuery__
 *
 * To run a query within a React component, call `useFetchReferenceLinksForPatientNotesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchReferenceLinksForPatientNotesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchReferenceLinksForPatientNotesPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchReferenceLinksForPatientNotesPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchReferenceLinksForPatientNotesPageQuery,
        FetchReferenceLinksForPatientNotesPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchReferenceLinksForPatientNotesPageQuery,
        FetchReferenceLinksForPatientNotesPageQueryVariables
    >(FetchReferenceLinksForPatientNotesPageDocument, options);
}
export function useFetchReferenceLinksForPatientNotesPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchReferenceLinksForPatientNotesPageQuery,
        FetchReferenceLinksForPatientNotesPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchReferenceLinksForPatientNotesPageQuery,
        FetchReferenceLinksForPatientNotesPageQueryVariables
    >(FetchReferenceLinksForPatientNotesPageDocument, options);
}
export type FetchReferenceLinksForPatientNotesPageQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForPatientNotesPageQuery
>;
export type FetchReferenceLinksForPatientNotesPageLazyQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForPatientNotesPageLazyQuery
>;
export type FetchReferenceLinksForPatientNotesPageQueryResult = Apollo.QueryResult<
    FetchReferenceLinksForPatientNotesPageQuery,
    FetchReferenceLinksForPatientNotesPageQueryVariables
>;
export const FetchReferenceLinksForPatientOrdersPageDocument = gql`
    query FetchReferenceLinksForPatientOrdersPage($input: GetReferenceLinksForPageInput!) {
        getReferenceLinksForPage(input: $input) {
            category
            data {
                label
                url
            }
        }
    }
`;

/**
 * __useFetchReferenceLinksForPatientOrdersPageQuery__
 *
 * To run a query within a React component, call `useFetchReferenceLinksForPatientOrdersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchReferenceLinksForPatientOrdersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchReferenceLinksForPatientOrdersPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchReferenceLinksForPatientOrdersPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchReferenceLinksForPatientOrdersPageQuery,
        FetchReferenceLinksForPatientOrdersPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchReferenceLinksForPatientOrdersPageQuery,
        FetchReferenceLinksForPatientOrdersPageQueryVariables
    >(FetchReferenceLinksForPatientOrdersPageDocument, options);
}
export function useFetchReferenceLinksForPatientOrdersPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchReferenceLinksForPatientOrdersPageQuery,
        FetchReferenceLinksForPatientOrdersPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchReferenceLinksForPatientOrdersPageQuery,
        FetchReferenceLinksForPatientOrdersPageQueryVariables
    >(FetchReferenceLinksForPatientOrdersPageDocument, options);
}
export type FetchReferenceLinksForPatientOrdersPageQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForPatientOrdersPageQuery
>;
export type FetchReferenceLinksForPatientOrdersPageLazyQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForPatientOrdersPageLazyQuery
>;
export type FetchReferenceLinksForPatientOrdersPageQueryResult = Apollo.QueryResult<
    FetchReferenceLinksForPatientOrdersPageQuery,
    FetchReferenceLinksForPatientOrdersPageQueryVariables
>;
export const FetchPatientForPatientOverviewPageDocument = gql`
    query FetchPatientForPatientOverviewPage(
        $patientInput: GetPatientInput!
        $appointmentInput: PatientAppointmentsQueryInput
    ) {
        patient(input: $patientInput) {
            ...PatientForPatientsPage
            appointments(input: $appointmentInput) {
                ...AppointmentFragmentOnApptPage
            }
        }
    }
    ${PatientForPatientsPageFragmentDoc}
    ${AppointmentFragmentOnApptPageFragmentDoc}
`;

/**
 * __useFetchPatientForPatientOverviewPageQuery__
 *
 * To run a query within a React component, call `useFetchPatientForPatientOverviewPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientForPatientOverviewPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientForPatientOverviewPageQuery({
 *   variables: {
 *      patientInput: // value for 'patientInput'
 *      appointmentInput: // value for 'appointmentInput'
 *   },
 * });
 */
export function useFetchPatientForPatientOverviewPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPatientForPatientOverviewPageQuery,
        FetchPatientForPatientOverviewPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientForPatientOverviewPageQuery,
        FetchPatientForPatientOverviewPageQueryVariables
    >(FetchPatientForPatientOverviewPageDocument, options);
}
export function useFetchPatientForPatientOverviewPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientForPatientOverviewPageQuery,
        FetchPatientForPatientOverviewPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientForPatientOverviewPageQuery,
        FetchPatientForPatientOverviewPageQueryVariables
    >(FetchPatientForPatientOverviewPageDocument, options);
}
export type FetchPatientForPatientOverviewPageQueryHookResult = ReturnType<
    typeof useFetchPatientForPatientOverviewPageQuery
>;
export type FetchPatientForPatientOverviewPageLazyQueryHookResult = ReturnType<
    typeof useFetchPatientForPatientOverviewPageLazyQuery
>;
export type FetchPatientForPatientOverviewPageQueryResult = Apollo.QueryResult<
    FetchPatientForPatientOverviewPageQuery,
    FetchPatientForPatientOverviewPageQueryVariables
>;
export const FetchPatientProfileVariablesDocument = gql`
    query FetchPatientProfileVariables($patientId: String!, $systemGenerated: Boolean) {
        patientProfileVariables(patientId: $patientId, systemGenerated: $systemGenerated) {
            ...PatientProfileVariablesFragment
        }
    }
    ${PatientProfileVariablesFragmentFragmentDoc}
`;

/**
 * __useFetchPatientProfileVariablesQuery__
 *
 * To run a query within a React component, call `useFetchPatientProfileVariablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientProfileVariablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientProfileVariablesQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      systemGenerated: // value for 'systemGenerated'
 *   },
 * });
 */
export function useFetchPatientProfileVariablesQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPatientProfileVariablesQuery,
        FetchPatientProfileVariablesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientProfileVariablesQuery,
        FetchPatientProfileVariablesQueryVariables
    >(FetchPatientProfileVariablesDocument, options);
}
export function useFetchPatientProfileVariablesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientProfileVariablesQuery,
        FetchPatientProfileVariablesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientProfileVariablesQuery,
        FetchPatientProfileVariablesQueryVariables
    >(FetchPatientProfileVariablesDocument, options);
}
export type FetchPatientProfileVariablesQueryHookResult = ReturnType<
    typeof useFetchPatientProfileVariablesQuery
>;
export type FetchPatientProfileVariablesLazyQueryHookResult = ReturnType<
    typeof useFetchPatientProfileVariablesLazyQuery
>;
export type FetchPatientProfileVariablesQueryResult = Apollo.QueryResult<
    FetchPatientProfileVariablesQuery,
    FetchPatientProfileVariablesQueryVariables
>;
export const FetchChoicesForProfileDefinitionByNameDocument = gql`
    query FetchChoicesForProfileDefinitionByName($input: GetUserProfileDefInput!) {
        userProfileDef(input: $input) {
            choices {
                id
                label {
                    en
                }
                value {
                    str
                    num
                    bool
                    date
                }
                specifyValue
            }
            choiceValueType
        }
    }
`;

/**
 * __useFetchChoicesForProfileDefinitionByNameQuery__
 *
 * To run a query within a React component, call `useFetchChoicesForProfileDefinitionByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchChoicesForProfileDefinitionByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchChoicesForProfileDefinitionByNameQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchChoicesForProfileDefinitionByNameQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchChoicesForProfileDefinitionByNameQuery,
        FetchChoicesForProfileDefinitionByNameQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchChoicesForProfileDefinitionByNameQuery,
        FetchChoicesForProfileDefinitionByNameQueryVariables
    >(FetchChoicesForProfileDefinitionByNameDocument, options);
}
export function useFetchChoicesForProfileDefinitionByNameLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchChoicesForProfileDefinitionByNameQuery,
        FetchChoicesForProfileDefinitionByNameQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchChoicesForProfileDefinitionByNameQuery,
        FetchChoicesForProfileDefinitionByNameQueryVariables
    >(FetchChoicesForProfileDefinitionByNameDocument, options);
}
export type FetchChoicesForProfileDefinitionByNameQueryHookResult = ReturnType<
    typeof useFetchChoicesForProfileDefinitionByNameQuery
>;
export type FetchChoicesForProfileDefinitionByNameLazyQueryHookResult = ReturnType<
    typeof useFetchChoicesForProfileDefinitionByNameLazyQuery
>;
export type FetchChoicesForProfileDefinitionByNameQueryResult = Apollo.QueryResult<
    FetchChoicesForProfileDefinitionByNameQuery,
    FetchChoicesForProfileDefinitionByNameQueryVariables
>;
export const EpisodesByConditionIdDocument = gql`
    query EpisodesByConditionId($conditionId: String!) {
        proxiedEpisodesByConditionId(conditionId: $conditionId) {
            beginDate
            endDate
        }
    }
`;

/**
 * __useEpisodesByConditionIdQuery__
 *
 * To run a query within a React component, call `useEpisodesByConditionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEpisodesByConditionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEpisodesByConditionIdQuery({
 *   variables: {
 *      conditionId: // value for 'conditionId'
 *   },
 * });
 */
export function useEpisodesByConditionIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        EpisodesByConditionIdQuery,
        EpisodesByConditionIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<EpisodesByConditionIdQuery, EpisodesByConditionIdQueryVariables>(
        EpisodesByConditionIdDocument,
        options,
    );
}
export function useEpisodesByConditionIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        EpisodesByConditionIdQuery,
        EpisodesByConditionIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<EpisodesByConditionIdQuery, EpisodesByConditionIdQueryVariables>(
        EpisodesByConditionIdDocument,
        options,
    );
}
export type EpisodesByConditionIdQueryHookResult = ReturnType<typeof useEpisodesByConditionIdQuery>;
export type EpisodesByConditionIdLazyQueryHookResult = ReturnType<
    typeof useEpisodesByConditionIdLazyQuery
>;
export type EpisodesByConditionIdQueryResult = Apollo.QueryResult<
    EpisodesByConditionIdQuery,
    EpisodesByConditionIdQueryVariables
>;
export const UpdatePatientProfileVariableDocument = gql`
    mutation UpdatePatientProfileVariable($input: PatientProfileVariableInput!) {
        updatePatientProfileVariable(input: $input) {
            ...PatientProfileVariablesFragment
        }
    }
    ${PatientProfileVariablesFragmentFragmentDoc}
`;
export type UpdatePatientProfileVariableMutationFn = Apollo.MutationFunction<
    UpdatePatientProfileVariableMutation,
    UpdatePatientProfileVariableMutationVariables
>;

/**
 * __useUpdatePatientProfileVariableMutation__
 *
 * To run a mutation, you first call `useUpdatePatientProfileVariableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientProfileVariableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientProfileVariableMutation, { data, loading, error }] = useUpdatePatientProfileVariableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientProfileVariableMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePatientProfileVariableMutation,
        UpdatePatientProfileVariableMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdatePatientProfileVariableMutation,
        UpdatePatientProfileVariableMutationVariables
    >(UpdatePatientProfileVariableDocument, options);
}
export type UpdatePatientProfileVariableMutationHookResult = ReturnType<
    typeof useUpdatePatientProfileVariableMutation
>;
export type UpdatePatientProfileVariableMutationResult =
    Apollo.MutationResult<UpdatePatientProfileVariableMutation>;
export type UpdatePatientProfileVariableMutationOptions = Apollo.BaseMutationOptions<
    UpdatePatientProfileVariableMutation,
    UpdatePatientProfileVariableMutationVariables
>;
export const FetchProfileValueOriginListForPatientDocument = gql`
    query FetchProfileValueOriginListForPatient {
        profileValueOrigins {
            id
            label {
                en
                es
            }
        }
    }
`;

/**
 * __useFetchProfileValueOriginListForPatientQuery__
 *
 * To run a query within a React component, call `useFetchProfileValueOriginListForPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProfileValueOriginListForPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProfileValueOriginListForPatientQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchProfileValueOriginListForPatientQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchProfileValueOriginListForPatientQuery,
        FetchProfileValueOriginListForPatientQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchProfileValueOriginListForPatientQuery,
        FetchProfileValueOriginListForPatientQueryVariables
    >(FetchProfileValueOriginListForPatientDocument, options);
}
export function useFetchProfileValueOriginListForPatientLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchProfileValueOriginListForPatientQuery,
        FetchProfileValueOriginListForPatientQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchProfileValueOriginListForPatientQuery,
        FetchProfileValueOriginListForPatientQueryVariables
    >(FetchProfileValueOriginListForPatientDocument, options);
}
export type FetchProfileValueOriginListForPatientQueryHookResult = ReturnType<
    typeof useFetchProfileValueOriginListForPatientQuery
>;
export type FetchProfileValueOriginListForPatientLazyQueryHookResult = ReturnType<
    typeof useFetchProfileValueOriginListForPatientLazyQuery
>;
export type FetchProfileValueOriginListForPatientQueryResult = Apollo.QueryResult<
    FetchProfileValueOriginListForPatientQuery,
    FetchProfileValueOriginListForPatientQueryVariables
>;
export const ProfileKeysDocument = gql`
    query ProfileKeys($input: ProfileKeyQueryInput!) {
        profileKeys(input: $input) {
            results {
                id
            }
        }
    }
`;

/**
 * __useProfileKeysQuery__
 *
 * To run a query within a React component, call `useProfileKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileKeysQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfileKeysQuery(
    baseOptions: Apollo.QueryHookOptions<ProfileKeysQuery, ProfileKeysQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ProfileKeysQuery, ProfileKeysQueryVariables>(
        ProfileKeysDocument,
        options,
    );
}
export function useProfileKeysLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ProfileKeysQuery, ProfileKeysQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ProfileKeysQuery, ProfileKeysQueryVariables>(
        ProfileKeysDocument,
        options,
    );
}
export type ProfileKeysQueryHookResult = ReturnType<typeof useProfileKeysQuery>;
export type ProfileKeysLazyQueryHookResult = ReturnType<typeof useProfileKeysLazyQuery>;
export type ProfileKeysQueryResult = Apollo.QueryResult<
    ProfileKeysQuery,
    ProfileKeysQueryVariables
>;
export const FetchProfileValueHistoriesByProfileKeysForPatientDocument = gql`
    query FetchProfileValueHistoriesByProfileKeysForPatient($keyIds: [String]!) {
        getProfileValueHistoriesByProfileKeys(keyIds: $keyIds) {
            keyId
            profileVariableDefId
            surveyDefId
            type
            val {
                str
                num
                bool
                date
                strs
                nums
                bools
                dates
                choice
                choices
                specified {
                    choiceId
                    type
                    val {
                        str
                        num
                        bool
                        date
                    }
                }
                localized {
                    en
                    es
                }
                dateTimeZone
            }
            createdAt
            updatedAt
            isTestData
            id
            source {
                type
                portalUserId
            }
        }
    }
`;

/**
 * __useFetchProfileValueHistoriesByProfileKeysForPatientQuery__
 *
 * To run a query within a React component, call `useFetchProfileValueHistoriesByProfileKeysForPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProfileValueHistoriesByProfileKeysForPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProfileValueHistoriesByProfileKeysForPatientQuery({
 *   variables: {
 *      keyIds: // value for 'keyIds'
 *   },
 * });
 */
export function useFetchProfileValueHistoriesByProfileKeysForPatientQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchProfileValueHistoriesByProfileKeysForPatientQuery,
        FetchProfileValueHistoriesByProfileKeysForPatientQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchProfileValueHistoriesByProfileKeysForPatientQuery,
        FetchProfileValueHistoriesByProfileKeysForPatientQueryVariables
    >(FetchProfileValueHistoriesByProfileKeysForPatientDocument, options);
}
export function useFetchProfileValueHistoriesByProfileKeysForPatientLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchProfileValueHistoriesByProfileKeysForPatientQuery,
        FetchProfileValueHistoriesByProfileKeysForPatientQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchProfileValueHistoriesByProfileKeysForPatientQuery,
        FetchProfileValueHistoriesByProfileKeysForPatientQueryVariables
    >(FetchProfileValueHistoriesByProfileKeysForPatientDocument, options);
}
export type FetchProfileValueHistoriesByProfileKeysForPatientQueryHookResult = ReturnType<
    typeof useFetchProfileValueHistoriesByProfileKeysForPatientQuery
>;
export type FetchProfileValueHistoriesByProfileKeysForPatientLazyQueryHookResult = ReturnType<
    typeof useFetchProfileValueHistoriesByProfileKeysForPatientLazyQuery
>;
export type FetchProfileValueHistoriesByProfileKeysForPatientQueryResult = Apollo.QueryResult<
    FetchProfileValueHistoriesByProfileKeysForPatientQuery,
    FetchProfileValueHistoriesByProfileKeysForPatientQueryVariables
>;
export const UserProfileDefForHistoriesDocument = gql`
    query UserProfileDefForHistories($input: GetUserProfileDefInput!) {
        userProfileDef(input: $input) {
            valueType
            choiceValueType
            choices {
                id
                label {
                    en
                    es
                }
                answerLabel {
                    en
                    es
                }
                specifyValue
                value {
                    str
                    num
                    bool
                    date
                }
                name
            }
            id
            name
            label {
                en
                es
            }
        }
    }
`;

/**
 * __useUserProfileDefForHistoriesQuery__
 *
 * To run a query within a React component, call `useUserProfileDefForHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileDefForHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileDefForHistoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserProfileDefForHistoriesQuery(
    baseOptions: Apollo.QueryHookOptions<
        UserProfileDefForHistoriesQuery,
        UserProfileDefForHistoriesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        UserProfileDefForHistoriesQuery,
        UserProfileDefForHistoriesQueryVariables
    >(UserProfileDefForHistoriesDocument, options);
}
export function useUserProfileDefForHistoriesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        UserProfileDefForHistoriesQuery,
        UserProfileDefForHistoriesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        UserProfileDefForHistoriesQuery,
        UserProfileDefForHistoriesQueryVariables
    >(UserProfileDefForHistoriesDocument, options);
}
export type UserProfileDefForHistoriesQueryHookResult = ReturnType<
    typeof useUserProfileDefForHistoriesQuery
>;
export type UserProfileDefForHistoriesLazyQueryHookResult = ReturnType<
    typeof useUserProfileDefForHistoriesLazyQuery
>;
export type UserProfileDefForHistoriesQueryResult = Apollo.QueryResult<
    UserProfileDefForHistoriesQuery,
    UserProfileDefForHistoriesQueryVariables
>;
export const FetchSurveyResponsesForPatientSurveyPageDocument = gql`
    query FetchSurveyResponsesForPatientSurveyPage($patientInput: GetPatientInput!) {
        patient(input: $patientInput) {
            ...PatientForPatientsPage
            surveyResponses {
                ...SurveyResponseForSurveyPage
            }
        }
    }
    ${PatientForPatientsPageFragmentDoc}
    ${SurveyResponseForSurveyPageFragmentDoc}
`;

/**
 * __useFetchSurveyResponsesForPatientSurveyPageQuery__
 *
 * To run a query within a React component, call `useFetchSurveyResponsesForPatientSurveyPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSurveyResponsesForPatientSurveyPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSurveyResponsesForPatientSurveyPageQuery({
 *   variables: {
 *      patientInput: // value for 'patientInput'
 *   },
 * });
 */
export function useFetchSurveyResponsesForPatientSurveyPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchSurveyResponsesForPatientSurveyPageQuery,
        FetchSurveyResponsesForPatientSurveyPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchSurveyResponsesForPatientSurveyPageQuery,
        FetchSurveyResponsesForPatientSurveyPageQueryVariables
    >(FetchSurveyResponsesForPatientSurveyPageDocument, options);
}
export function useFetchSurveyResponsesForPatientSurveyPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchSurveyResponsesForPatientSurveyPageQuery,
        FetchSurveyResponsesForPatientSurveyPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchSurveyResponsesForPatientSurveyPageQuery,
        FetchSurveyResponsesForPatientSurveyPageQueryVariables
    >(FetchSurveyResponsesForPatientSurveyPageDocument, options);
}
export type FetchSurveyResponsesForPatientSurveyPageQueryHookResult = ReturnType<
    typeof useFetchSurveyResponsesForPatientSurveyPageQuery
>;
export type FetchSurveyResponsesForPatientSurveyPageLazyQueryHookResult = ReturnType<
    typeof useFetchSurveyResponsesForPatientSurveyPageLazyQuery
>;
export type FetchSurveyResponsesForPatientSurveyPageQueryResult = Apollo.QueryResult<
    FetchSurveyResponsesForPatientSurveyPageQuery,
    FetchSurveyResponsesForPatientSurveyPageQueryVariables
>;
export const FetchReferenceLinksForPatientSurveysPageDocument = gql`
    query FetchReferenceLinksForPatientSurveysPage($input: GetReferenceLinksForPageInput!) {
        getReferenceLinksForPage(input: $input) {
            category
            data {
                label
                url
            }
        }
    }
`;

/**
 * __useFetchReferenceLinksForPatientSurveysPageQuery__
 *
 * To run a query within a React component, call `useFetchReferenceLinksForPatientSurveysPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchReferenceLinksForPatientSurveysPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchReferenceLinksForPatientSurveysPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchReferenceLinksForPatientSurveysPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchReferenceLinksForPatientSurveysPageQuery,
        FetchReferenceLinksForPatientSurveysPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchReferenceLinksForPatientSurveysPageQuery,
        FetchReferenceLinksForPatientSurveysPageQueryVariables
    >(FetchReferenceLinksForPatientSurveysPageDocument, options);
}
export function useFetchReferenceLinksForPatientSurveysPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchReferenceLinksForPatientSurveysPageQuery,
        FetchReferenceLinksForPatientSurveysPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchReferenceLinksForPatientSurveysPageQuery,
        FetchReferenceLinksForPatientSurveysPageQueryVariables
    >(FetchReferenceLinksForPatientSurveysPageDocument, options);
}
export type FetchReferenceLinksForPatientSurveysPageQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForPatientSurveysPageQuery
>;
export type FetchReferenceLinksForPatientSurveysPageLazyQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForPatientSurveysPageLazyQuery
>;
export type FetchReferenceLinksForPatientSurveysPageQueryResult = Apollo.QueryResult<
    FetchReferenceLinksForPatientSurveysPageQuery,
    FetchReferenceLinksForPatientSurveysPageQueryVariables
>;
export const FetchPatientTagsForPatientTagsDocument = gql`
    query FetchPatientTagsForPatientTags($input: GetPatientInput!) {
        patient(input: $input) {
            id
            firstName
            tags {
                id
                name
            }
        }
    }
`;

/**
 * __useFetchPatientTagsForPatientTagsQuery__
 *
 * To run a query within a React component, call `useFetchPatientTagsForPatientTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientTagsForPatientTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientTagsForPatientTagsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPatientTagsForPatientTagsQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPatientTagsForPatientTagsQuery,
        FetchPatientTagsForPatientTagsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientTagsForPatientTagsQuery,
        FetchPatientTagsForPatientTagsQueryVariables
    >(FetchPatientTagsForPatientTagsDocument, options);
}
export function useFetchPatientTagsForPatientTagsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientTagsForPatientTagsQuery,
        FetchPatientTagsForPatientTagsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientTagsForPatientTagsQuery,
        FetchPatientTagsForPatientTagsQueryVariables
    >(FetchPatientTagsForPatientTagsDocument, options);
}
export type FetchPatientTagsForPatientTagsQueryHookResult = ReturnType<
    typeof useFetchPatientTagsForPatientTagsQuery
>;
export type FetchPatientTagsForPatientTagsLazyQueryHookResult = ReturnType<
    typeof useFetchPatientTagsForPatientTagsLazyQuery
>;
export type FetchPatientTagsForPatientTagsQueryResult = Apollo.QueryResult<
    FetchPatientTagsForPatientTagsQuery,
    FetchPatientTagsForPatientTagsQueryVariables
>;
export const CreatePatientTodoTaskDocument = gql`
    mutation CreatePatientTodoTask($input: TodoCreateInput!) {
        createHACreatedTodoTask(input: $input) {
            status
            message
            success
            resourceCreated {
                id
                title {
                    en
                    es
                }
                description {
                    en
                }
                dueDate
                repeatType
                createdBy
                isComplete
                priority
                effectiveDate
                isEssential
                dateCompleted
                carePlan {
                    label {
                        en
                    }
                    group {
                        id
                        label {
                            en
                        }
                    }
                    template {
                        id
                        tags
                    }
                }
            }
        }
    }
`;
export type CreatePatientTodoTaskMutationFn = Apollo.MutationFunction<
    CreatePatientTodoTaskMutation,
    CreatePatientTodoTaskMutationVariables
>;

/**
 * __useCreatePatientTodoTaskMutation__
 *
 * To run a mutation, you first call `useCreatePatientTodoTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientTodoTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientTodoTaskMutation, { data, loading, error }] = useCreatePatientTodoTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatientTodoTaskMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreatePatientTodoTaskMutation,
        CreatePatientTodoTaskMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreatePatientTodoTaskMutation,
        CreatePatientTodoTaskMutationVariables
    >(CreatePatientTodoTaskDocument, options);
}
export type CreatePatientTodoTaskMutationHookResult = ReturnType<
    typeof useCreatePatientTodoTaskMutation
>;
export type CreatePatientTodoTaskMutationResult =
    Apollo.MutationResult<CreatePatientTodoTaskMutation>;
export type CreatePatientTodoTaskMutationOptions = Apollo.BaseMutationOptions<
    CreatePatientTodoTaskMutation,
    CreatePatientTodoTaskMutationVariables
>;
export const UpdateTodoCpUpcomingDocument = gql`
    mutation UpdateTodoCPUpcoming($input: TodoUpdateInput!) {
        updateProxiedTodoTask(input: $input) {
            status
            message
            success
            resourceCreated {
                id
                dueDate
                effectiveDate
                isEssential
                priority
                isComplete
                title {
                    en
                    es
                }
                description {
                    en
                }
            }
        }
    }
`;
export type UpdateTodoCpUpcomingMutationFn = Apollo.MutationFunction<
    UpdateTodoCpUpcomingMutation,
    UpdateTodoCpUpcomingMutationVariables
>;

/**
 * __useUpdateTodoCpUpcomingMutation__
 *
 * To run a mutation, you first call `useUpdateTodoCpUpcomingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTodoCpUpcomingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTodoCpUpcomingMutation, { data, loading, error }] = useUpdateTodoCpUpcomingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTodoCpUpcomingMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateTodoCpUpcomingMutation,
        UpdateTodoCpUpcomingMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateTodoCpUpcomingMutation, UpdateTodoCpUpcomingMutationVariables>(
        UpdateTodoCpUpcomingDocument,
        options,
    );
}
export type UpdateTodoCpUpcomingMutationHookResult = ReturnType<
    typeof useUpdateTodoCpUpcomingMutation
>;
export type UpdateTodoCpUpcomingMutationResult =
    Apollo.MutationResult<UpdateTodoCpUpcomingMutation>;
export type UpdateTodoCpUpcomingMutationOptions = Apollo.BaseMutationOptions<
    UpdateTodoCpUpcomingMutation,
    UpdateTodoCpUpcomingMutationVariables
>;
export const DeleteTodoHaCreatedDocument = gql`
    mutation DeleteTodoHACreated($input: TodoDeleteInput) {
        deleteProxiedTodoTask(input: $input) {
            status
            message
            success
        }
    }
`;
export type DeleteTodoHaCreatedMutationFn = Apollo.MutationFunction<
    DeleteTodoHaCreatedMutation,
    DeleteTodoHaCreatedMutationVariables
>;

/**
 * __useDeleteTodoHaCreatedMutation__
 *
 * To run a mutation, you first call `useDeleteTodoHaCreatedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTodoHaCreatedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTodoHaCreatedMutation, { data, loading, error }] = useDeleteTodoHaCreatedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTodoHaCreatedMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteTodoHaCreatedMutation,
        DeleteTodoHaCreatedMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteTodoHaCreatedMutation, DeleteTodoHaCreatedMutationVariables>(
        DeleteTodoHaCreatedDocument,
        options,
    );
}
export type DeleteTodoHaCreatedMutationHookResult = ReturnType<
    typeof useDeleteTodoHaCreatedMutation
>;
export type DeleteTodoHaCreatedMutationResult = Apollo.MutationResult<DeleteTodoHaCreatedMutation>;
export type DeleteTodoHaCreatedMutationOptions = Apollo.BaseMutationOptions<
    DeleteTodoHaCreatedMutation,
    DeleteTodoHaCreatedMutationVariables
>;
export const PatientTodosItemsDocument = gql`
    query PatientTodosItems($patientId: String!) {
        patientProxiedTodosItems(patientId: $patientId) {
            id
            title {
                en
                es
            }
            carePlan {
                label {
                    en
                }
                group {
                    label {
                        en
                    }
                }
            }
            dueDate
            repeatType
            createdBy
            isComplete
            priority
            dateCompleted
        }
    }
`;

/**
 * __usePatientTodosItemsQuery__
 *
 * To run a query within a React component, call `usePatientTodosItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientTodosItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientTodosItemsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientTodosItemsQuery(
    baseOptions: Apollo.QueryHookOptions<PatientTodosItemsQuery, PatientTodosItemsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PatientTodosItemsQuery, PatientTodosItemsQueryVariables>(
        PatientTodosItemsDocument,
        options,
    );
}
export function usePatientTodosItemsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PatientTodosItemsQuery,
        PatientTodosItemsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PatientTodosItemsQuery, PatientTodosItemsQueryVariables>(
        PatientTodosItemsDocument,
        options,
    );
}
export type PatientTodosItemsQueryHookResult = ReturnType<typeof usePatientTodosItemsQuery>;
export type PatientTodosItemsLazyQueryHookResult = ReturnType<typeof usePatientTodosItemsLazyQuery>;
export type PatientTodosItemsQueryResult = Apollo.QueryResult<
    PatientTodosItemsQuery,
    PatientTodosItemsQueryVariables
>;
export const PatientTodoTaskQueryDocument = gql`
    query PatientTodoTaskQuery($patientTodoTaskId: String!) {
        patientProxiedTodoTask(id: $patientTodoTaskId) {
            title {
                en
                es
            }
            carePlan {
                id
                label {
                    en
                }
                group {
                    id
                    label {
                        en
                    }
                }
                template {
                    id
                    tags
                }
            }
            description {
                en
            }
            dueDate
            repeatType
            createdBy
            isComplete
            priority
            effectiveDate
            isEssential
            dateCompleted
        }
    }
`;

/**
 * __usePatientTodoTaskQueryQuery__
 *
 * To run a query within a React component, call `usePatientTodoTaskQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientTodoTaskQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientTodoTaskQueryQuery({
 *   variables: {
 *      patientTodoTaskId: // value for 'patientTodoTaskId'
 *   },
 * });
 */
export function usePatientTodoTaskQueryQuery(
    baseOptions: Apollo.QueryHookOptions<
        PatientTodoTaskQueryQuery,
        PatientTodoTaskQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PatientTodoTaskQueryQuery, PatientTodoTaskQueryQueryVariables>(
        PatientTodoTaskQueryDocument,
        options,
    );
}
export function usePatientTodoTaskQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PatientTodoTaskQueryQuery,
        PatientTodoTaskQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PatientTodoTaskQueryQuery, PatientTodoTaskQueryQueryVariables>(
        PatientTodoTaskQueryDocument,
        options,
    );
}
export type PatientTodoTaskQueryQueryHookResult = ReturnType<typeof usePatientTodoTaskQueryQuery>;
export type PatientTodoTaskQueryLazyQueryHookResult = ReturnType<
    typeof usePatientTodoTaskQueryLazyQuery
>;
export type PatientTodoTaskQueryQueryResult = Apollo.QueryResult<
    PatientTodoTaskQueryQuery,
    PatientTodoTaskQueryQueryVariables
>;
export const FetchPatientByIdForPatientModalDocument = gql`
    query FetchPatientByIdForPatientModal($input: GetPatientInput!) {
        patient(input: $input) {
            ...PatientForPatientsPage
        }
    }
    ${PatientForPatientsPageFragmentDoc}
`;

/**
 * __useFetchPatientByIdForPatientModalQuery__
 *
 * To run a query within a React component, call `useFetchPatientByIdForPatientModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientByIdForPatientModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientByIdForPatientModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPatientByIdForPatientModalQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPatientByIdForPatientModalQuery,
        FetchPatientByIdForPatientModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientByIdForPatientModalQuery,
        FetchPatientByIdForPatientModalQueryVariables
    >(FetchPatientByIdForPatientModalDocument, options);
}
export function useFetchPatientByIdForPatientModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientByIdForPatientModalQuery,
        FetchPatientByIdForPatientModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientByIdForPatientModalQuery,
        FetchPatientByIdForPatientModalQueryVariables
    >(FetchPatientByIdForPatientModalDocument, options);
}
export type FetchPatientByIdForPatientModalQueryHookResult = ReturnType<
    typeof useFetchPatientByIdForPatientModalQuery
>;
export type FetchPatientByIdForPatientModalLazyQueryHookResult = ReturnType<
    typeof useFetchPatientByIdForPatientModalLazyQuery
>;
export type FetchPatientByIdForPatientModalQueryResult = Apollo.QueryResult<
    FetchPatientByIdForPatientModalQuery,
    FetchPatientByIdForPatientModalQueryVariables
>;
export const UpdatePatientForPatientsPageDocument = gql`
    mutation UpdatePatientForPatientsPage($input: UpdatePatientInput!) {
        updatePatient(input: $input) {
            success
            status
            message
            resourceUpdated {
                ...PatientForPatientsPage
            }
        }
    }
    ${PatientForPatientsPageFragmentDoc}
`;
export type UpdatePatientForPatientsPageMutationFn = Apollo.MutationFunction<
    UpdatePatientForPatientsPageMutation,
    UpdatePatientForPatientsPageMutationVariables
>;

/**
 * __useUpdatePatientForPatientsPageMutation__
 *
 * To run a mutation, you first call `useUpdatePatientForPatientsPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientForPatientsPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientForPatientsPageMutation, { data, loading, error }] = useUpdatePatientForPatientsPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientForPatientsPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePatientForPatientsPageMutation,
        UpdatePatientForPatientsPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdatePatientForPatientsPageMutation,
        UpdatePatientForPatientsPageMutationVariables
    >(UpdatePatientForPatientsPageDocument, options);
}
export type UpdatePatientForPatientsPageMutationHookResult = ReturnType<
    typeof useUpdatePatientForPatientsPageMutation
>;
export type UpdatePatientForPatientsPageMutationResult =
    Apollo.MutationResult<UpdatePatientForPatientsPageMutation>;
export type UpdatePatientForPatientsPageMutationOptions = Apollo.BaseMutationOptions<
    UpdatePatientForPatientsPageMutation,
    UpdatePatientForPatientsPageMutationVariables
>;
export const CreatePatientForPatientsPageDocument = gql`
    mutation CreatePatientForPatientsPage($input: PatientInput!) {
        createPatient(input: $input) {
            success
            message
            patient {
                ...PatientForPatientsPage
            }
        }
    }
    ${PatientForPatientsPageFragmentDoc}
`;
export type CreatePatientForPatientsPageMutationFn = Apollo.MutationFunction<
    CreatePatientForPatientsPageMutation,
    CreatePatientForPatientsPageMutationVariables
>;

/**
 * __useCreatePatientForPatientsPageMutation__
 *
 * To run a mutation, you first call `useCreatePatientForPatientsPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientForPatientsPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientForPatientsPageMutation, { data, loading, error }] = useCreatePatientForPatientsPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatientForPatientsPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreatePatientForPatientsPageMutation,
        CreatePatientForPatientsPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreatePatientForPatientsPageMutation,
        CreatePatientForPatientsPageMutationVariables
    >(CreatePatientForPatientsPageDocument, options);
}
export type CreatePatientForPatientsPageMutationHookResult = ReturnType<
    typeof useCreatePatientForPatientsPageMutation
>;
export type CreatePatientForPatientsPageMutationResult =
    Apollo.MutationResult<CreatePatientForPatientsPageMutation>;
export type CreatePatientForPatientsPageMutationOptions = Apollo.BaseMutationOptions<
    CreatePatientForPatientsPageMutation,
    CreatePatientForPatientsPageMutationVariables
>;
export const FetchCurrentUserForPatientModalDocument = gql`
    query FetchCurrentUserForPatientModal {
        currentUser {
            id
            name
            email
            currentOrg {
                id
            }
            currentOrganizations {
                id
                parentId
                brandingName
            }
        }
    }
`;

/**
 * __useFetchCurrentUserForPatientModalQuery__
 *
 * To run a query within a React component, call `useFetchCurrentUserForPatientModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCurrentUserForPatientModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCurrentUserForPatientModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCurrentUserForPatientModalQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchCurrentUserForPatientModalQuery,
        FetchCurrentUserForPatientModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchCurrentUserForPatientModalQuery,
        FetchCurrentUserForPatientModalQueryVariables
    >(FetchCurrentUserForPatientModalDocument, options);
}
export function useFetchCurrentUserForPatientModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchCurrentUserForPatientModalQuery,
        FetchCurrentUserForPatientModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchCurrentUserForPatientModalQuery,
        FetchCurrentUserForPatientModalQueryVariables
    >(FetchCurrentUserForPatientModalDocument, options);
}
export type FetchCurrentUserForPatientModalQueryHookResult = ReturnType<
    typeof useFetchCurrentUserForPatientModalQuery
>;
export type FetchCurrentUserForPatientModalLazyQueryHookResult = ReturnType<
    typeof useFetchCurrentUserForPatientModalLazyQuery
>;
export type FetchCurrentUserForPatientModalQueryResult = Apollo.QueryResult<
    FetchCurrentUserForPatientModalQuery,
    FetchCurrentUserForPatientModalQueryVariables
>;
export const FetchPatientNotesForPatientNotesModalDocument = gql`
    query FetchPatientNotesForPatientNotesModal($input: GetPatientInput!) {
        patient(input: $input) {
            id
            firstName
            notes {
                id
                createdAt
                title
                date
                noteSource
                noteType
                staffName
                text
                NPI
                patient {
                    id
                }
            }
        }
    }
`;

/**
 * __useFetchPatientNotesForPatientNotesModalQuery__
 *
 * To run a query within a React component, call `useFetchPatientNotesForPatientNotesModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientNotesForPatientNotesModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientNotesForPatientNotesModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPatientNotesForPatientNotesModalQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPatientNotesForPatientNotesModalQuery,
        FetchPatientNotesForPatientNotesModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientNotesForPatientNotesModalQuery,
        FetchPatientNotesForPatientNotesModalQueryVariables
    >(FetchPatientNotesForPatientNotesModalDocument, options);
}
export function useFetchPatientNotesForPatientNotesModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientNotesForPatientNotesModalQuery,
        FetchPatientNotesForPatientNotesModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientNotesForPatientNotesModalQuery,
        FetchPatientNotesForPatientNotesModalQueryVariables
    >(FetchPatientNotesForPatientNotesModalDocument, options);
}
export type FetchPatientNotesForPatientNotesModalQueryHookResult = ReturnType<
    typeof useFetchPatientNotesForPatientNotesModalQuery
>;
export type FetchPatientNotesForPatientNotesModalLazyQueryHookResult = ReturnType<
    typeof useFetchPatientNotesForPatientNotesModalLazyQuery
>;
export type FetchPatientNotesForPatientNotesModalQueryResult = Apollo.QueryResult<
    FetchPatientNotesForPatientNotesModalQuery,
    FetchPatientNotesForPatientNotesModalQueryVariables
>;
export const CreateNewPatientNoteFromPatientNotesModalDocument = gql`
    mutation CreateNewPatientNoteFromPatientNotesModal($input: PatientNotesInput!) {
        createPatientNotes(input: $input) {
            message
            success
            resourceCreated {
                id
                createdAt
                title
                date
                noteSource
                noteType
                staffName
                text
                patient {
                    id
                    firstName
                    notes {
                        id
                        createdAt
                        title
                        date
                        noteSource
                        noteType
                        staffName
                        text
                        patient {
                            id
                        }
                    }
                }
            }
        }
    }
`;
export type CreateNewPatientNoteFromPatientNotesModalMutationFn = Apollo.MutationFunction<
    CreateNewPatientNoteFromPatientNotesModalMutation,
    CreateNewPatientNoteFromPatientNotesModalMutationVariables
>;

/**
 * __useCreateNewPatientNoteFromPatientNotesModalMutation__
 *
 * To run a mutation, you first call `useCreateNewPatientNoteFromPatientNotesModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewPatientNoteFromPatientNotesModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewPatientNoteFromPatientNotesModalMutation, { data, loading, error }] = useCreateNewPatientNoteFromPatientNotesModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewPatientNoteFromPatientNotesModalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateNewPatientNoteFromPatientNotesModalMutation,
        CreateNewPatientNoteFromPatientNotesModalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateNewPatientNoteFromPatientNotesModalMutation,
        CreateNewPatientNoteFromPatientNotesModalMutationVariables
    >(CreateNewPatientNoteFromPatientNotesModalDocument, options);
}
export type CreateNewPatientNoteFromPatientNotesModalMutationHookResult = ReturnType<
    typeof useCreateNewPatientNoteFromPatientNotesModalMutation
>;
export type CreateNewPatientNoteFromPatientNotesModalMutationResult =
    Apollo.MutationResult<CreateNewPatientNoteFromPatientNotesModalMutation>;
export type CreateNewPatientNoteFromPatientNotesModalMutationOptions = Apollo.BaseMutationOptions<
    CreateNewPatientNoteFromPatientNotesModalMutation,
    CreateNewPatientNoteFromPatientNotesModalMutationVariables
>;
export const UpdatePatientNoteOnPatientsNotesModalDocument = gql`
    mutation UpdatePatientNoteOnPatientsNotesModal($input: UpdatePatientNotesInput!) {
        updatePatientNotes(input: $input) {
            success
            message
            resourceUpdated {
                id
                createdAt
                title
                date
                noteSource
                noteType
                staffName
                text
                patient {
                    id
                }
            }
        }
    }
`;
export type UpdatePatientNoteOnPatientsNotesModalMutationFn = Apollo.MutationFunction<
    UpdatePatientNoteOnPatientsNotesModalMutation,
    UpdatePatientNoteOnPatientsNotesModalMutationVariables
>;

/**
 * __useUpdatePatientNoteOnPatientsNotesModalMutation__
 *
 * To run a mutation, you first call `useUpdatePatientNoteOnPatientsNotesModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientNoteOnPatientsNotesModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientNoteOnPatientsNotesModalMutation, { data, loading, error }] = useUpdatePatientNoteOnPatientsNotesModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientNoteOnPatientsNotesModalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePatientNoteOnPatientsNotesModalMutation,
        UpdatePatientNoteOnPatientsNotesModalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdatePatientNoteOnPatientsNotesModalMutation,
        UpdatePatientNoteOnPatientsNotesModalMutationVariables
    >(UpdatePatientNoteOnPatientsNotesModalDocument, options);
}
export type UpdatePatientNoteOnPatientsNotesModalMutationHookResult = ReturnType<
    typeof useUpdatePatientNoteOnPatientsNotesModalMutation
>;
export type UpdatePatientNoteOnPatientsNotesModalMutationResult =
    Apollo.MutationResult<UpdatePatientNoteOnPatientsNotesModalMutation>;
export type UpdatePatientNoteOnPatientsNotesModalMutationOptions = Apollo.BaseMutationOptions<
    UpdatePatientNoteOnPatientsNotesModalMutation,
    UpdatePatientNoteOnPatientsNotesModalMutationVariables
>;
export const NoteTagListForPatientNotePageDocument = gql`
    query NoteTagListForPatientNotePage {
        patientNoteTagsV2 {
            results {
                id
                name
            }
        }
    }
`;

/**
 * __useNoteTagListForPatientNotePageQuery__
 *
 * To run a query within a React component, call `useNoteTagListForPatientNotePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteTagListForPatientNotePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteTagListForPatientNotePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useNoteTagListForPatientNotePageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        NoteTagListForPatientNotePageQuery,
        NoteTagListForPatientNotePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        NoteTagListForPatientNotePageQuery,
        NoteTagListForPatientNotePageQueryVariables
    >(NoteTagListForPatientNotePageDocument, options);
}
export function useNoteTagListForPatientNotePageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        NoteTagListForPatientNotePageQuery,
        NoteTagListForPatientNotePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        NoteTagListForPatientNotePageQuery,
        NoteTagListForPatientNotePageQueryVariables
    >(NoteTagListForPatientNotePageDocument, options);
}
export type NoteTagListForPatientNotePageQueryHookResult = ReturnType<
    typeof useNoteTagListForPatientNotePageQuery
>;
export type NoteTagListForPatientNotePageLazyQueryHookResult = ReturnType<
    typeof useNoteTagListForPatientNotePageLazyQuery
>;
export type NoteTagListForPatientNotePageQueryResult = Apollo.QueryResult<
    NoteTagListForPatientNotePageQuery,
    NoteTagListForPatientNotePageQueryVariables
>;
export const FetchPatientsForPatientsPageQueryDocument = gql`
    query FetchPatientsForPatientsPageQuery($input: GetPatientsByFilterInput!) {
        currentUser {
            id
            name
            email
            organizations {
                id
                parentId
                brandingName
            }
            currentOrg {
                id
                parentId
                brandingName
                allowPatientCreation
                appPlatform
                appBundleId
                configOptions {
                    riskScoreEnabled
                }
            }
            patientsV2(input: $input) {
                orderBy
                skip
                limit
                total
                hasMore
                hasPrevious
                results {
                    ...PatientForPatientsPage
                }
            }
        }
    }
    ${PatientForPatientsPageFragmentDoc}
`;

/**
 * __useFetchPatientsForPatientsPageQueryQuery__
 *
 * To run a query within a React component, call `useFetchPatientsForPatientsPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientsForPatientsPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientsForPatientsPageQueryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPatientsForPatientsPageQueryQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPatientsForPatientsPageQueryQuery,
        FetchPatientsForPatientsPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientsForPatientsPageQueryQuery,
        FetchPatientsForPatientsPageQueryQueryVariables
    >(FetchPatientsForPatientsPageQueryDocument, options);
}
export function useFetchPatientsForPatientsPageQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientsForPatientsPageQueryQuery,
        FetchPatientsForPatientsPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientsForPatientsPageQueryQuery,
        FetchPatientsForPatientsPageQueryQueryVariables
    >(FetchPatientsForPatientsPageQueryDocument, options);
}
export type FetchPatientsForPatientsPageQueryQueryHookResult = ReturnType<
    typeof useFetchPatientsForPatientsPageQueryQuery
>;
export type FetchPatientsForPatientsPageQueryLazyQueryHookResult = ReturnType<
    typeof useFetchPatientsForPatientsPageQueryLazyQuery
>;
export type FetchPatientsForPatientsPageQueryQueryResult = Apollo.QueryResult<
    FetchPatientsForPatientsPageQueryQuery,
    FetchPatientsForPatientsPageQueryQueryVariables
>;
export const ResendPatientInviteOnPatientsPageDocument = gql`
    mutation ResendPatientInviteOnPatientsPage($input: ResendPatientInvitationFromPortalInput!) {
        resendPatientInvitationFromPortal(input: $input) {
            success
            message
            patient {
                ...PatientForPatientsPage
            }
        }
    }
    ${PatientForPatientsPageFragmentDoc}
`;
export type ResendPatientInviteOnPatientsPageMutationFn = Apollo.MutationFunction<
    ResendPatientInviteOnPatientsPageMutation,
    ResendPatientInviteOnPatientsPageMutationVariables
>;

/**
 * __useResendPatientInviteOnPatientsPageMutation__
 *
 * To run a mutation, you first call `useResendPatientInviteOnPatientsPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendPatientInviteOnPatientsPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendPatientInviteOnPatientsPageMutation, { data, loading, error }] = useResendPatientInviteOnPatientsPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendPatientInviteOnPatientsPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ResendPatientInviteOnPatientsPageMutation,
        ResendPatientInviteOnPatientsPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        ResendPatientInviteOnPatientsPageMutation,
        ResendPatientInviteOnPatientsPageMutationVariables
    >(ResendPatientInviteOnPatientsPageDocument, options);
}
export type ResendPatientInviteOnPatientsPageMutationHookResult = ReturnType<
    typeof useResendPatientInviteOnPatientsPageMutation
>;
export type ResendPatientInviteOnPatientsPageMutationResult =
    Apollo.MutationResult<ResendPatientInviteOnPatientsPageMutation>;
export type ResendPatientInviteOnPatientsPageMutationOptions = Apollo.BaseMutationOptions<
    ResendPatientInviteOnPatientsPageMutation,
    ResendPatientInviteOnPatientsPageMutationVariables
>;
export const ResetPatientPasswordOnPatientsPageDocument = gql`
    mutation ResetPatientPasswordOnPatientsPage($input: ResetPatientPasswordFromPortalInput!) {
        resetPatientPasswordFromPortal(input: $input) {
            success
            message
            patient {
                ...PatientForPatientsPage
            }
        }
    }
    ${PatientForPatientsPageFragmentDoc}
`;
export type ResetPatientPasswordOnPatientsPageMutationFn = Apollo.MutationFunction<
    ResetPatientPasswordOnPatientsPageMutation,
    ResetPatientPasswordOnPatientsPageMutationVariables
>;

/**
 * __useResetPatientPasswordOnPatientsPageMutation__
 *
 * To run a mutation, you first call `useResetPatientPasswordOnPatientsPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPatientPasswordOnPatientsPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPatientPasswordOnPatientsPageMutation, { data, loading, error }] = useResetPatientPasswordOnPatientsPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPatientPasswordOnPatientsPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ResetPatientPasswordOnPatientsPageMutation,
        ResetPatientPasswordOnPatientsPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        ResetPatientPasswordOnPatientsPageMutation,
        ResetPatientPasswordOnPatientsPageMutationVariables
    >(ResetPatientPasswordOnPatientsPageDocument, options);
}
export type ResetPatientPasswordOnPatientsPageMutationHookResult = ReturnType<
    typeof useResetPatientPasswordOnPatientsPageMutation
>;
export type ResetPatientPasswordOnPatientsPageMutationResult =
    Apollo.MutationResult<ResetPatientPasswordOnPatientsPageMutation>;
export type ResetPatientPasswordOnPatientsPageMutationOptions = Apollo.BaseMutationOptions<
    ResetPatientPasswordOnPatientsPageMutation,
    ResetPatientPasswordOnPatientsPageMutationVariables
>;
export const DeletePatientOnPatientsPageDocument = gql`
    mutation DeletePatientOnPatientsPage($input: DeletePatientAppUserDownstreamDataInput!) {
        deletePatientAppUserDownstreamData(input: $input) {
            status
            message
            success
            patientId
            deletedCollections {
                collection
                isDeleted
                deletedCount
                error
            }
        }
    }
`;
export type DeletePatientOnPatientsPageMutationFn = Apollo.MutationFunction<
    DeletePatientOnPatientsPageMutation,
    DeletePatientOnPatientsPageMutationVariables
>;

/**
 * __useDeletePatientOnPatientsPageMutation__
 *
 * To run a mutation, you first call `useDeletePatientOnPatientsPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientOnPatientsPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientOnPatientsPageMutation, { data, loading, error }] = useDeletePatientOnPatientsPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePatientOnPatientsPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeletePatientOnPatientsPageMutation,
        DeletePatientOnPatientsPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeletePatientOnPatientsPageMutation,
        DeletePatientOnPatientsPageMutationVariables
    >(DeletePatientOnPatientsPageDocument, options);
}
export type DeletePatientOnPatientsPageMutationHookResult = ReturnType<
    typeof useDeletePatientOnPatientsPageMutation
>;
export type DeletePatientOnPatientsPageMutationResult =
    Apollo.MutationResult<DeletePatientOnPatientsPageMutation>;
export type DeletePatientOnPatientsPageMutationOptions = Apollo.BaseMutationOptions<
    DeletePatientOnPatientsPageMutation,
    DeletePatientOnPatientsPageMutationVariables
>;
export const UploadManyPatientsForImporterDocument = gql`
    mutation UploadManyPatientsForImporter($input: CreateManyPatientsInput!) {
        createManyPatients(input: $input) {
            success
            message
            resourcesCreated {
                success
                message
                resourceCreated {
                    ...PatientForPatientsPage
                }
            }
        }
    }
    ${PatientForPatientsPageFragmentDoc}
`;
export type UploadManyPatientsForImporterMutationFn = Apollo.MutationFunction<
    UploadManyPatientsForImporterMutation,
    UploadManyPatientsForImporterMutationVariables
>;

/**
 * __useUploadManyPatientsForImporterMutation__
 *
 * To run a mutation, you first call `useUploadManyPatientsForImporterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadManyPatientsForImporterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadManyPatientsForImporterMutation, { data, loading, error }] = useUploadManyPatientsForImporterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadManyPatientsForImporterMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UploadManyPatientsForImporterMutation,
        UploadManyPatientsForImporterMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UploadManyPatientsForImporterMutation,
        UploadManyPatientsForImporterMutationVariables
    >(UploadManyPatientsForImporterDocument, options);
}
export type UploadManyPatientsForImporterMutationHookResult = ReturnType<
    typeof useUploadManyPatientsForImporterMutation
>;
export type UploadManyPatientsForImporterMutationResult =
    Apollo.MutationResult<UploadManyPatientsForImporterMutation>;
export type UploadManyPatientsForImporterMutationOptions = Apollo.BaseMutationOptions<
    UploadManyPatientsForImporterMutation,
    UploadManyPatientsForImporterMutationVariables
>;
export const UsersV2ForPatientsPageDocument = gql`
    query UsersV2ForPatientsPage {
        usersV2 {
            results {
                id
                name
                role {
                    permissions {
                        permission
                        target
                        canExport
                    }
                }
            }
        }
    }
`;

/**
 * __useUsersV2ForPatientsPageQuery__
 *
 * To run a query within a React component, call `useUsersV2ForPatientsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersV2ForPatientsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersV2ForPatientsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersV2ForPatientsPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        UsersV2ForPatientsPageQuery,
        UsersV2ForPatientsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<UsersV2ForPatientsPageQuery, UsersV2ForPatientsPageQueryVariables>(
        UsersV2ForPatientsPageDocument,
        options,
    );
}
export function useUsersV2ForPatientsPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        UsersV2ForPatientsPageQuery,
        UsersV2ForPatientsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<UsersV2ForPatientsPageQuery, UsersV2ForPatientsPageQueryVariables>(
        UsersV2ForPatientsPageDocument,
        options,
    );
}
export type UsersV2ForPatientsPageQueryHookResult = ReturnType<
    typeof useUsersV2ForPatientsPageQuery
>;
export type UsersV2ForPatientsPageLazyQueryHookResult = ReturnType<
    typeof useUsersV2ForPatientsPageLazyQuery
>;
export type UsersV2ForPatientsPageQueryResult = Apollo.QueryResult<
    UsersV2ForPatientsPageQuery,
    UsersV2ForPatientsPageQueryVariables
>;
export const FetchPatientsExportDocument = gql`
    query FetchPatientsExport($input: ExportPatientsByFilterInput!) {
        currentUser {
            exportPatients(input: $input) {
                data
                fileName
            }
        }
    }
`;

/**
 * __useFetchPatientsExportQuery__
 *
 * To run a query within a React component, call `useFetchPatientsExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientsExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientsExportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPatientsExportQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPatientsExportQuery,
        FetchPatientsExportQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchPatientsExportQuery, FetchPatientsExportQueryVariables>(
        FetchPatientsExportDocument,
        options,
    );
}
export function useFetchPatientsExportLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientsExportQuery,
        FetchPatientsExportQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchPatientsExportQuery, FetchPatientsExportQueryVariables>(
        FetchPatientsExportDocument,
        options,
    );
}
export type FetchPatientsExportQueryHookResult = ReturnType<typeof useFetchPatientsExportQuery>;
export type FetchPatientsExportLazyQueryHookResult = ReturnType<
    typeof useFetchPatientsExportLazyQuery
>;
export type FetchPatientsExportQueryResult = Apollo.QueryResult<
    FetchPatientsExportQuery,
    FetchPatientsExportQueryVariables
>;
export const FetchPatientByIdForVbcPatientModalDocument = gql`
    query FetchPatientByIdForVBCPatientModal($input: GetPatientInput!) {
        patient(input: $input) {
            ...PatientForPatientsPage
        }
    }
    ${PatientForPatientsPageFragmentDoc}
`;

/**
 * __useFetchPatientByIdForVbcPatientModalQuery__
 *
 * To run a query within a React component, call `useFetchPatientByIdForVbcPatientModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientByIdForVbcPatientModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientByIdForVbcPatientModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPatientByIdForVbcPatientModalQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPatientByIdForVbcPatientModalQuery,
        FetchPatientByIdForVbcPatientModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientByIdForVbcPatientModalQuery,
        FetchPatientByIdForVbcPatientModalQueryVariables
    >(FetchPatientByIdForVbcPatientModalDocument, options);
}
export function useFetchPatientByIdForVbcPatientModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientByIdForVbcPatientModalQuery,
        FetchPatientByIdForVbcPatientModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientByIdForVbcPatientModalQuery,
        FetchPatientByIdForVbcPatientModalQueryVariables
    >(FetchPatientByIdForVbcPatientModalDocument, options);
}
export type FetchPatientByIdForVbcPatientModalQueryHookResult = ReturnType<
    typeof useFetchPatientByIdForVbcPatientModalQuery
>;
export type FetchPatientByIdForVbcPatientModalLazyQueryHookResult = ReturnType<
    typeof useFetchPatientByIdForVbcPatientModalLazyQuery
>;
export type FetchPatientByIdForVbcPatientModalQueryResult = Apollo.QueryResult<
    FetchPatientByIdForVbcPatientModalQuery,
    FetchPatientByIdForVbcPatientModalQueryVariables
>;
export const FetchProfileDefsForVbcPatientModalDocument = gql`
    query FetchProfileDefsForVBCPatientModal($input: AdditionalPatientFieldsInput!) {
        additionalPatientFieldsProfileDefs(input: $input) {
            profileDefs {
                name
                label {
                    en
                }
                choices {
                    id
                    label {
                        en
                    }
                    value {
                        str
                        num
                        bool
                        date
                    }
                    specifyValue
                }
                choiceValueType
                valueType
                category
                id
            }
            careTeamMembers {
                careTeamType {
                    name
                    profileDefId
                }
                careTeamMembers {
                    id
                    label {
                        en
                    }
                }
            }
        }
    }
`;

/**
 * __useFetchProfileDefsForVbcPatientModalQuery__
 *
 * To run a query within a React component, call `useFetchProfileDefsForVbcPatientModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProfileDefsForVbcPatientModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProfileDefsForVbcPatientModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchProfileDefsForVbcPatientModalQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchProfileDefsForVbcPatientModalQuery,
        FetchProfileDefsForVbcPatientModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchProfileDefsForVbcPatientModalQuery,
        FetchProfileDefsForVbcPatientModalQueryVariables
    >(FetchProfileDefsForVbcPatientModalDocument, options);
}
export function useFetchProfileDefsForVbcPatientModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchProfileDefsForVbcPatientModalQuery,
        FetchProfileDefsForVbcPatientModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchProfileDefsForVbcPatientModalQuery,
        FetchProfileDefsForVbcPatientModalQueryVariables
    >(FetchProfileDefsForVbcPatientModalDocument, options);
}
export type FetchProfileDefsForVbcPatientModalQueryHookResult = ReturnType<
    typeof useFetchProfileDefsForVbcPatientModalQuery
>;
export type FetchProfileDefsForVbcPatientModalLazyQueryHookResult = ReturnType<
    typeof useFetchProfileDefsForVbcPatientModalLazyQuery
>;
export type FetchProfileDefsForVbcPatientModalQueryResult = Apollo.QueryResult<
    FetchProfileDefsForVbcPatientModalQuery,
    FetchProfileDefsForVbcPatientModalQueryVariables
>;
export const FetchUserProfileDefinitionsForVbcPatientModalDocument = gql`
    query FetchUserProfileDefinitionsForVBCPatientModal($input: UserProfileDefQueryInput) {
        userProfileDefsV2(input: $input) {
            results {
                name
                label {
                    en
                }
                choices {
                    id
                    label {
                        en
                    }
                    value {
                        str
                        num
                        bool
                        date
                    }
                    specifyValue
                }
                choiceValueType
                valueType
                category
                id
            }
        }
    }
`;

/**
 * __useFetchUserProfileDefinitionsForVbcPatientModalQuery__
 *
 * To run a query within a React component, call `useFetchUserProfileDefinitionsForVbcPatientModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserProfileDefinitionsForVbcPatientModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserProfileDefinitionsForVbcPatientModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchUserProfileDefinitionsForVbcPatientModalQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchUserProfileDefinitionsForVbcPatientModalQuery,
        FetchUserProfileDefinitionsForVbcPatientModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchUserProfileDefinitionsForVbcPatientModalQuery,
        FetchUserProfileDefinitionsForVbcPatientModalQueryVariables
    >(FetchUserProfileDefinitionsForVbcPatientModalDocument, options);
}
export function useFetchUserProfileDefinitionsForVbcPatientModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchUserProfileDefinitionsForVbcPatientModalQuery,
        FetchUserProfileDefinitionsForVbcPatientModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchUserProfileDefinitionsForVbcPatientModalQuery,
        FetchUserProfileDefinitionsForVbcPatientModalQueryVariables
    >(FetchUserProfileDefinitionsForVbcPatientModalDocument, options);
}
export type FetchUserProfileDefinitionsForVbcPatientModalQueryHookResult = ReturnType<
    typeof useFetchUserProfileDefinitionsForVbcPatientModalQuery
>;
export type FetchUserProfileDefinitionsForVbcPatientModalLazyQueryHookResult = ReturnType<
    typeof useFetchUserProfileDefinitionsForVbcPatientModalLazyQuery
>;
export type FetchUserProfileDefinitionsForVbcPatientModalQueryResult = Apollo.QueryResult<
    FetchUserProfileDefinitionsForVbcPatientModalQuery,
    FetchUserProfileDefinitionsForVbcPatientModalQueryVariables
>;
export const CreateAppUserForPatientDocument = gql`
    mutation CreateAppUserForPatient($input: CreateAppUserForPatientInput!) {
        createAppUserForPatient(input: $input) {
            appUserId
            patientId
        }
    }
`;
export type CreateAppUserForPatientMutationFn = Apollo.MutationFunction<
    CreateAppUserForPatientMutation,
    CreateAppUserForPatientMutationVariables
>;

/**
 * __useCreateAppUserForPatientMutation__
 *
 * To run a mutation, you first call `useCreateAppUserForPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppUserForPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppUserForPatientMutation, { data, loading, error }] = useCreateAppUserForPatientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppUserForPatientMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateAppUserForPatientMutation,
        CreateAppUserForPatientMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateAppUserForPatientMutation,
        CreateAppUserForPatientMutationVariables
    >(CreateAppUserForPatientDocument, options);
}
export type CreateAppUserForPatientMutationHookResult = ReturnType<
    typeof useCreateAppUserForPatientMutation
>;
export type CreateAppUserForPatientMutationResult =
    Apollo.MutationResult<CreateAppUserForPatientMutation>;
export type CreateAppUserForPatientMutationOptions = Apollo.BaseMutationOptions<
    CreateAppUserForPatientMutation,
    CreateAppUserForPatientMutationVariables
>;
export const CreateOrUpdateManyUserProfileVariablesDocument = gql`
    mutation CreateOrUpdateManyUserProfileVariables($input: [ProfileVariableInput!]!) {
        createOrUpdateManyUserProfileVariables(input: $input) {
            success
        }
    }
`;
export type CreateOrUpdateManyUserProfileVariablesMutationFn = Apollo.MutationFunction<
    CreateOrUpdateManyUserProfileVariablesMutation,
    CreateOrUpdateManyUserProfileVariablesMutationVariables
>;

/**
 * __useCreateOrUpdateManyUserProfileVariablesMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateManyUserProfileVariablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateManyUserProfileVariablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateManyUserProfileVariablesMutation, { data, loading, error }] = useCreateOrUpdateManyUserProfileVariablesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateManyUserProfileVariablesMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateOrUpdateManyUserProfileVariablesMutation,
        CreateOrUpdateManyUserProfileVariablesMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateOrUpdateManyUserProfileVariablesMutation,
        CreateOrUpdateManyUserProfileVariablesMutationVariables
    >(CreateOrUpdateManyUserProfileVariablesDocument, options);
}
export type CreateOrUpdateManyUserProfileVariablesMutationHookResult = ReturnType<
    typeof useCreateOrUpdateManyUserProfileVariablesMutation
>;
export type CreateOrUpdateManyUserProfileVariablesMutationResult =
    Apollo.MutationResult<CreateOrUpdateManyUserProfileVariablesMutation>;
export type CreateOrUpdateManyUserProfileVariablesMutationOptions = Apollo.BaseMutationOptions<
    CreateOrUpdateManyUserProfileVariablesMutation,
    CreateOrUpdateManyUserProfileVariablesMutationVariables
>;
export const UpdatePatientOnPatientsPageDocument = gql`
    mutation UpdatePatientOnPatientsPage($input: UpdatePatientInput!) {
        updatePatient(input: $input) {
            success
            status
            message
            resourceUpdated {
                id
                firstName
                lastName
                email
                isTestData
            }
        }
    }
`;
export type UpdatePatientOnPatientsPageMutationFn = Apollo.MutationFunction<
    UpdatePatientOnPatientsPageMutation,
    UpdatePatientOnPatientsPageMutationVariables
>;

/**
 * __useUpdatePatientOnPatientsPageMutation__
 *
 * To run a mutation, you first call `useUpdatePatientOnPatientsPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientOnPatientsPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientOnPatientsPageMutation, { data, loading, error }] = useUpdatePatientOnPatientsPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientOnPatientsPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePatientOnPatientsPageMutation,
        UpdatePatientOnPatientsPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdatePatientOnPatientsPageMutation,
        UpdatePatientOnPatientsPageMutationVariables
    >(UpdatePatientOnPatientsPageDocument, options);
}
export type UpdatePatientOnPatientsPageMutationHookResult = ReturnType<
    typeof useUpdatePatientOnPatientsPageMutation
>;
export type UpdatePatientOnPatientsPageMutationResult =
    Apollo.MutationResult<UpdatePatientOnPatientsPageMutation>;
export type UpdatePatientOnPatientsPageMutationOptions = Apollo.BaseMutationOptions<
    UpdatePatientOnPatientsPageMutation,
    UpdatePatientOnPatientsPageMutationVariables
>;
export const FetchDevicesForDevicesPageQueryDocument = gql`
    query FetchDevicesForDevicesPageQuery {
        devices {
            ...DeviceForDevicesPage
        }
    }
    ${DeviceForDevicesPageFragmentDoc}
`;

/**
 * __useFetchDevicesForDevicesPageQueryQuery__
 *
 * To run a query within a React component, call `useFetchDevicesForDevicesPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDevicesForDevicesPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDevicesForDevicesPageQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchDevicesForDevicesPageQueryQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchDevicesForDevicesPageQueryQuery,
        FetchDevicesForDevicesPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchDevicesForDevicesPageQueryQuery,
        FetchDevicesForDevicesPageQueryQueryVariables
    >(FetchDevicesForDevicesPageQueryDocument, options);
}
export function useFetchDevicesForDevicesPageQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchDevicesForDevicesPageQueryQuery,
        FetchDevicesForDevicesPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchDevicesForDevicesPageQueryQuery,
        FetchDevicesForDevicesPageQueryQueryVariables
    >(FetchDevicesForDevicesPageQueryDocument, options);
}
export type FetchDevicesForDevicesPageQueryQueryHookResult = ReturnType<
    typeof useFetchDevicesForDevicesPageQueryQuery
>;
export type FetchDevicesForDevicesPageQueryLazyQueryHookResult = ReturnType<
    typeof useFetchDevicesForDevicesPageQueryLazyQuery
>;
export type FetchDevicesForDevicesPageQueryQueryResult = Apollo.QueryResult<
    FetchDevicesForDevicesPageQueryQuery,
    FetchDevicesForDevicesPageQueryQueryVariables
>;
export const FetchDeviceByIdForDeviceModalDocument = gql`
    query FetchDeviceByIdForDeviceModal($input: GetDeviceInput!) {
        device(input: $input) {
            ...DeviceForDevicesPage
        }
    }
    ${DeviceForDevicesPageFragmentDoc}
`;

/**
 * __useFetchDeviceByIdForDeviceModalQuery__
 *
 * To run a query within a React component, call `useFetchDeviceByIdForDeviceModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDeviceByIdForDeviceModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDeviceByIdForDeviceModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchDeviceByIdForDeviceModalQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchDeviceByIdForDeviceModalQuery,
        FetchDeviceByIdForDeviceModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchDeviceByIdForDeviceModalQuery,
        FetchDeviceByIdForDeviceModalQueryVariables
    >(FetchDeviceByIdForDeviceModalDocument, options);
}
export function useFetchDeviceByIdForDeviceModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchDeviceByIdForDeviceModalQuery,
        FetchDeviceByIdForDeviceModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchDeviceByIdForDeviceModalQuery,
        FetchDeviceByIdForDeviceModalQueryVariables
    >(FetchDeviceByIdForDeviceModalDocument, options);
}
export type FetchDeviceByIdForDeviceModalQueryHookResult = ReturnType<
    typeof useFetchDeviceByIdForDeviceModalQuery
>;
export type FetchDeviceByIdForDeviceModalLazyQueryHookResult = ReturnType<
    typeof useFetchDeviceByIdForDeviceModalLazyQuery
>;
export type FetchDeviceByIdForDeviceModalQueryResult = Apollo.QueryResult<
    FetchDeviceByIdForDeviceModalQuery,
    FetchDeviceByIdForDeviceModalQueryVariables
>;
export const CreateDeviceFromDevicesPageDocument = gql`
    mutation CreateDeviceFromDevicesPage($input: DeviceInput!) {
        createDevice(input: $input) {
            success
            message
            status
            resourceCreated {
                ...DeviceForDevicesPage
            }
        }
    }
    ${DeviceForDevicesPageFragmentDoc}
`;
export type CreateDeviceFromDevicesPageMutationFn = Apollo.MutationFunction<
    CreateDeviceFromDevicesPageMutation,
    CreateDeviceFromDevicesPageMutationVariables
>;

/**
 * __useCreateDeviceFromDevicesPageMutation__
 *
 * To run a mutation, you first call `useCreateDeviceFromDevicesPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeviceFromDevicesPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeviceFromDevicesPageMutation, { data, loading, error }] = useCreateDeviceFromDevicesPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeviceFromDevicesPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateDeviceFromDevicesPageMutation,
        CreateDeviceFromDevicesPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateDeviceFromDevicesPageMutation,
        CreateDeviceFromDevicesPageMutationVariables
    >(CreateDeviceFromDevicesPageDocument, options);
}
export type CreateDeviceFromDevicesPageMutationHookResult = ReturnType<
    typeof useCreateDeviceFromDevicesPageMutation
>;
export type CreateDeviceFromDevicesPageMutationResult =
    Apollo.MutationResult<CreateDeviceFromDevicesPageMutation>;
export type CreateDeviceFromDevicesPageMutationOptions = Apollo.BaseMutationOptions<
    CreateDeviceFromDevicesPageMutation,
    CreateDeviceFromDevicesPageMutationVariables
>;
export const UpdateDeviceFromDevicesPageDocument = gql`
    mutation UpdateDeviceFromDevicesPage($input: UpdateDeviceInput!) {
        updateDevice(input: $input) {
            success
            message
            status
            resourceUpdated {
                ...DeviceForDevicesPage
            }
        }
    }
    ${DeviceForDevicesPageFragmentDoc}
`;
export type UpdateDeviceFromDevicesPageMutationFn = Apollo.MutationFunction<
    UpdateDeviceFromDevicesPageMutation,
    UpdateDeviceFromDevicesPageMutationVariables
>;

/**
 * __useUpdateDeviceFromDevicesPageMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceFromDevicesPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceFromDevicesPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceFromDevicesPageMutation, { data, loading, error }] = useUpdateDeviceFromDevicesPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeviceFromDevicesPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateDeviceFromDevicesPageMutation,
        UpdateDeviceFromDevicesPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateDeviceFromDevicesPageMutation,
        UpdateDeviceFromDevicesPageMutationVariables
    >(UpdateDeviceFromDevicesPageDocument, options);
}
export type UpdateDeviceFromDevicesPageMutationHookResult = ReturnType<
    typeof useUpdateDeviceFromDevicesPageMutation
>;
export type UpdateDeviceFromDevicesPageMutationResult =
    Apollo.MutationResult<UpdateDeviceFromDevicesPageMutation>;
export type UpdateDeviceFromDevicesPageMutationOptions = Apollo.BaseMutationOptions<
    UpdateDeviceFromDevicesPageMutation,
    UpdateDeviceFromDevicesPageMutationVariables
>;
export const FetchHealthPlanByIdForHealthPlanModalDocument = gql`
    query FetchHealthPlanByIdForHealthPlanModal($input: GetHealthPlanInput!) {
        healthPlan(input: $input) {
            ...HealthPlan
        }
    }
    ${HealthPlanFragmentDoc}
`;

/**
 * __useFetchHealthPlanByIdForHealthPlanModalQuery__
 *
 * To run a query within a React component, call `useFetchHealthPlanByIdForHealthPlanModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchHealthPlanByIdForHealthPlanModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchHealthPlanByIdForHealthPlanModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchHealthPlanByIdForHealthPlanModalQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchHealthPlanByIdForHealthPlanModalQuery,
        FetchHealthPlanByIdForHealthPlanModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchHealthPlanByIdForHealthPlanModalQuery,
        FetchHealthPlanByIdForHealthPlanModalQueryVariables
    >(FetchHealthPlanByIdForHealthPlanModalDocument, options);
}
export function useFetchHealthPlanByIdForHealthPlanModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchHealthPlanByIdForHealthPlanModalQuery,
        FetchHealthPlanByIdForHealthPlanModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchHealthPlanByIdForHealthPlanModalQuery,
        FetchHealthPlanByIdForHealthPlanModalQueryVariables
    >(FetchHealthPlanByIdForHealthPlanModalDocument, options);
}
export type FetchHealthPlanByIdForHealthPlanModalQueryHookResult = ReturnType<
    typeof useFetchHealthPlanByIdForHealthPlanModalQuery
>;
export type FetchHealthPlanByIdForHealthPlanModalLazyQueryHookResult = ReturnType<
    typeof useFetchHealthPlanByIdForHealthPlanModalLazyQuery
>;
export type FetchHealthPlanByIdForHealthPlanModalQueryResult = Apollo.QueryResult<
    FetchHealthPlanByIdForHealthPlanModalQuery,
    FetchHealthPlanByIdForHealthPlanModalQueryVariables
>;
export const UpdateHealthPlanForModalDocument = gql`
    mutation UpdateHealthPlanForModal($input: UpdateHealthPlanInput!) {
        updateHealthPlan(input: $input) {
            message
            success
            resourceUpdated {
                ...HealthPlan
            }
        }
    }
    ${HealthPlanFragmentDoc}
`;
export type UpdateHealthPlanForModalMutationFn = Apollo.MutationFunction<
    UpdateHealthPlanForModalMutation,
    UpdateHealthPlanForModalMutationVariables
>;

/**
 * __useUpdateHealthPlanForModalMutation__
 *
 * To run a mutation, you first call `useUpdateHealthPlanForModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHealthPlanForModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHealthPlanForModalMutation, { data, loading, error }] = useUpdateHealthPlanForModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHealthPlanForModalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateHealthPlanForModalMutation,
        UpdateHealthPlanForModalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateHealthPlanForModalMutation,
        UpdateHealthPlanForModalMutationVariables
    >(UpdateHealthPlanForModalDocument, options);
}
export type UpdateHealthPlanForModalMutationHookResult = ReturnType<
    typeof useUpdateHealthPlanForModalMutation
>;
export type UpdateHealthPlanForModalMutationResult =
    Apollo.MutationResult<UpdateHealthPlanForModalMutation>;
export type UpdateHealthPlanForModalMutationOptions = Apollo.BaseMutationOptions<
    UpdateHealthPlanForModalMutation,
    UpdateHealthPlanForModalMutationVariables
>;
export const CreateHealthPlanForModalDocument = gql`
    mutation CreateHealthPlanForModal($input: HealthPlanInput!) {
        createHealthPlan(input: $input) {
            message
            success
            resourceCreated {
                ...HealthPlan
            }
        }
    }
    ${HealthPlanFragmentDoc}
`;
export type CreateHealthPlanForModalMutationFn = Apollo.MutationFunction<
    CreateHealthPlanForModalMutation,
    CreateHealthPlanForModalMutationVariables
>;

/**
 * __useCreateHealthPlanForModalMutation__
 *
 * To run a mutation, you first call `useCreateHealthPlanForModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHealthPlanForModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHealthPlanForModalMutation, { data, loading, error }] = useCreateHealthPlanForModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateHealthPlanForModalMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateHealthPlanForModalMutation,
        CreateHealthPlanForModalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateHealthPlanForModalMutation,
        CreateHealthPlanForModalMutationVariables
    >(CreateHealthPlanForModalDocument, options);
}
export type CreateHealthPlanForModalMutationHookResult = ReturnType<
    typeof useCreateHealthPlanForModalMutation
>;
export type CreateHealthPlanForModalMutationResult =
    Apollo.MutationResult<CreateHealthPlanForModalMutation>;
export type CreateHealthPlanForModalMutationOptions = Apollo.BaseMutationOptions<
    CreateHealthPlanForModalMutation,
    CreateHealthPlanForModalMutationVariables
>;
export const FetchHealthPlansForHealthPlansPageQueryDocument = gql`
    query FetchHealthPlansForHealthPlansPageQuery {
        healthPlans {
            ...HealthPlan
        }
    }
    ${HealthPlanFragmentDoc}
`;

/**
 * __useFetchHealthPlansForHealthPlansPageQueryQuery__
 *
 * To run a query within a React component, call `useFetchHealthPlansForHealthPlansPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchHealthPlansForHealthPlansPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchHealthPlansForHealthPlansPageQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchHealthPlansForHealthPlansPageQueryQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchHealthPlansForHealthPlansPageQueryQuery,
        FetchHealthPlansForHealthPlansPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchHealthPlansForHealthPlansPageQueryQuery,
        FetchHealthPlansForHealthPlansPageQueryQueryVariables
    >(FetchHealthPlansForHealthPlansPageQueryDocument, options);
}
export function useFetchHealthPlansForHealthPlansPageQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchHealthPlansForHealthPlansPageQueryQuery,
        FetchHealthPlansForHealthPlansPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchHealthPlansForHealthPlansPageQueryQuery,
        FetchHealthPlansForHealthPlansPageQueryQueryVariables
    >(FetchHealthPlansForHealthPlansPageQueryDocument, options);
}
export type FetchHealthPlansForHealthPlansPageQueryQueryHookResult = ReturnType<
    typeof useFetchHealthPlansForHealthPlansPageQueryQuery
>;
export type FetchHealthPlansForHealthPlansPageQueryLazyQueryHookResult = ReturnType<
    typeof useFetchHealthPlansForHealthPlansPageQueryLazyQuery
>;
export type FetchHealthPlansForHealthPlansPageQueryQueryResult = Apollo.QueryResult<
    FetchHealthPlansForHealthPlansPageQueryQuery,
    FetchHealthPlansForHealthPlansPageQueryQueryVariables
>;
export const FetchProviderByIdForProviderModalDocument = gql`
    query FetchProviderByIdForProviderModal($input: GetProviderInput!) {
        provider(input: $input) {
            ...ProviderForProvidersPage
        }
    }
    ${ProviderForProvidersPageFragmentDoc}
`;

/**
 * __useFetchProviderByIdForProviderModalQuery__
 *
 * To run a query within a React component, call `useFetchProviderByIdForProviderModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProviderByIdForProviderModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProviderByIdForProviderModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchProviderByIdForProviderModalQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchProviderByIdForProviderModalQuery,
        FetchProviderByIdForProviderModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchProviderByIdForProviderModalQuery,
        FetchProviderByIdForProviderModalQueryVariables
    >(FetchProviderByIdForProviderModalDocument, options);
}
export function useFetchProviderByIdForProviderModalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchProviderByIdForProviderModalQuery,
        FetchProviderByIdForProviderModalQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchProviderByIdForProviderModalQuery,
        FetchProviderByIdForProviderModalQueryVariables
    >(FetchProviderByIdForProviderModalDocument, options);
}
export type FetchProviderByIdForProviderModalQueryHookResult = ReturnType<
    typeof useFetchProviderByIdForProviderModalQuery
>;
export type FetchProviderByIdForProviderModalLazyQueryHookResult = ReturnType<
    typeof useFetchProviderByIdForProviderModalLazyQuery
>;
export type FetchProviderByIdForProviderModalQueryResult = Apollo.QueryResult<
    FetchProviderByIdForProviderModalQuery,
    FetchProviderByIdForProviderModalQueryVariables
>;
export const CreateProviderFromProvidersPageDocument = gql`
    mutation CreateProviderFromProvidersPage($input: ProviderInput!) {
        createProvider(input: $input) {
            success
            message
            status
            resourceCreated {
                ...ProviderForProvidersPage
            }
        }
    }
    ${ProviderForProvidersPageFragmentDoc}
`;
export type CreateProviderFromProvidersPageMutationFn = Apollo.MutationFunction<
    CreateProviderFromProvidersPageMutation,
    CreateProviderFromProvidersPageMutationVariables
>;

/**
 * __useCreateProviderFromProvidersPageMutation__
 *
 * To run a mutation, you first call `useCreateProviderFromProvidersPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProviderFromProvidersPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProviderFromProvidersPageMutation, { data, loading, error }] = useCreateProviderFromProvidersPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProviderFromProvidersPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateProviderFromProvidersPageMutation,
        CreateProviderFromProvidersPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateProviderFromProvidersPageMutation,
        CreateProviderFromProvidersPageMutationVariables
    >(CreateProviderFromProvidersPageDocument, options);
}
export type CreateProviderFromProvidersPageMutationHookResult = ReturnType<
    typeof useCreateProviderFromProvidersPageMutation
>;
export type CreateProviderFromProvidersPageMutationResult =
    Apollo.MutationResult<CreateProviderFromProvidersPageMutation>;
export type CreateProviderFromProvidersPageMutationOptions = Apollo.BaseMutationOptions<
    CreateProviderFromProvidersPageMutation,
    CreateProviderFromProvidersPageMutationVariables
>;
export const UpdateProviderFromProvidersPageDocument = gql`
    mutation UpdateProviderFromProvidersPage($input: UpdateProviderInput!) {
        updateProvider(input: $input) {
            success
            message
            status
            resourceUpdated {
                ...ProviderForProvidersPage
            }
        }
    }
    ${ProviderForProvidersPageFragmentDoc}
`;
export type UpdateProviderFromProvidersPageMutationFn = Apollo.MutationFunction<
    UpdateProviderFromProvidersPageMutation,
    UpdateProviderFromProvidersPageMutationVariables
>;

/**
 * __useUpdateProviderFromProvidersPageMutation__
 *
 * To run a mutation, you first call `useUpdateProviderFromProvidersPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProviderFromProvidersPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProviderFromProvidersPageMutation, { data, loading, error }] = useUpdateProviderFromProvidersPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProviderFromProvidersPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateProviderFromProvidersPageMutation,
        UpdateProviderFromProvidersPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateProviderFromProvidersPageMutation,
        UpdateProviderFromProvidersPageMutationVariables
    >(UpdateProviderFromProvidersPageDocument, options);
}
export type UpdateProviderFromProvidersPageMutationHookResult = ReturnType<
    typeof useUpdateProviderFromProvidersPageMutation
>;
export type UpdateProviderFromProvidersPageMutationResult =
    Apollo.MutationResult<UpdateProviderFromProvidersPageMutation>;
export type UpdateProviderFromProvidersPageMutationOptions = Apollo.BaseMutationOptions<
    UpdateProviderFromProvidersPageMutation,
    UpdateProviderFromProvidersPageMutationVariables
>;
export const FetchProvidersForProvidersPageQueryDocument = gql`
    query FetchProvidersForProvidersPageQuery {
        providers {
            ...ProviderForProvidersPage
        }
    }
    ${ProviderForProvidersPageFragmentDoc}
`;

/**
 * __useFetchProvidersForProvidersPageQueryQuery__
 *
 * To run a query within a React component, call `useFetchProvidersForProvidersPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProvidersForProvidersPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProvidersForProvidersPageQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchProvidersForProvidersPageQueryQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchProvidersForProvidersPageQueryQuery,
        FetchProvidersForProvidersPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchProvidersForProvidersPageQueryQuery,
        FetchProvidersForProvidersPageQueryQueryVariables
    >(FetchProvidersForProvidersPageQueryDocument, options);
}
export function useFetchProvidersForProvidersPageQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchProvidersForProvidersPageQueryQuery,
        FetchProvidersForProvidersPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchProvidersForProvidersPageQueryQuery,
        FetchProvidersForProvidersPageQueryQueryVariables
    >(FetchProvidersForProvidersPageQueryDocument, options);
}
export type FetchProvidersForProvidersPageQueryQueryHookResult = ReturnType<
    typeof useFetchProvidersForProvidersPageQueryQuery
>;
export type FetchProvidersForProvidersPageQueryLazyQueryHookResult = ReturnType<
    typeof useFetchProvidersForProvidersPageQueryLazyQuery
>;
export type FetchProvidersForProvidersPageQueryQueryResult = Apollo.QueryResult<
    FetchProvidersForProvidersPageQueryQuery,
    FetchProvidersForProvidersPageQueryQueryVariables
>;
export const SlaTimersV2ForSlaTimersPageDocument = gql`
    query SlaTimersV2ForSlaTimersPage($input: GetSlaTimersByFilterInput) {
        slaTimersV2(input: $input) {
            total
            skip
            orderBy
            limit
            hasPrevious
            hasMore
            results {
                id
                createdAt
                updatedAt
                completeBy
                completedAt
                isTestData
                taskId
                slaId
                sla {
                    id
                    name
                }
                organization {
                    id
                    name
                }
                task {
                    id
                    label
                }
                completedByUser {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useSlaTimersV2ForSlaTimersPageQuery__
 *
 * To run a query within a React component, call `useSlaTimersV2ForSlaTimersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlaTimersV2ForSlaTimersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlaTimersV2ForSlaTimersPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSlaTimersV2ForSlaTimersPageQuery(
    baseOptions?: Apollo.QueryHookOptions<
        SlaTimersV2ForSlaTimersPageQuery,
        SlaTimersV2ForSlaTimersPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        SlaTimersV2ForSlaTimersPageQuery,
        SlaTimersV2ForSlaTimersPageQueryVariables
    >(SlaTimersV2ForSlaTimersPageDocument, options);
}
export function useSlaTimersV2ForSlaTimersPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SlaTimersV2ForSlaTimersPageQuery,
        SlaTimersV2ForSlaTimersPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        SlaTimersV2ForSlaTimersPageQuery,
        SlaTimersV2ForSlaTimersPageQueryVariables
    >(SlaTimersV2ForSlaTimersPageDocument, options);
}
export type SlaTimersV2ForSlaTimersPageQueryHookResult = ReturnType<
    typeof useSlaTimersV2ForSlaTimersPageQuery
>;
export type SlaTimersV2ForSlaTimersPageLazyQueryHookResult = ReturnType<
    typeof useSlaTimersV2ForSlaTimersPageLazyQuery
>;
export type SlaTimersV2ForSlaTimersPageQueryResult = Apollo.QueryResult<
    SlaTimersV2ForSlaTimersPageQuery,
    SlaTimersV2ForSlaTimersPageQueryVariables
>;
export const UpdateSurveyResponseForSurveyPageDocument = gql`
    mutation UpdateSurveyResponseForSurveyPage($input: UpdateSurveyResponseInput!) {
        updateSurveyResponse(input: $input) {
            success
            message
            resourceUpdated {
                ...SurveyResponseForSurveyPage
            }
        }
    }
    ${SurveyResponseForSurveyPageFragmentDoc}
`;
export type UpdateSurveyResponseForSurveyPageMutationFn = Apollo.MutationFunction<
    UpdateSurveyResponseForSurveyPageMutation,
    UpdateSurveyResponseForSurveyPageMutationVariables
>;

/**
 * __useUpdateSurveyResponseForSurveyPageMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyResponseForSurveyPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyResponseForSurveyPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyResponseForSurveyPageMutation, { data, loading, error }] = useUpdateSurveyResponseForSurveyPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSurveyResponseForSurveyPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateSurveyResponseForSurveyPageMutation,
        UpdateSurveyResponseForSurveyPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateSurveyResponseForSurveyPageMutation,
        UpdateSurveyResponseForSurveyPageMutationVariables
    >(UpdateSurveyResponseForSurveyPageDocument, options);
}
export type UpdateSurveyResponseForSurveyPageMutationHookResult = ReturnType<
    typeof useUpdateSurveyResponseForSurveyPageMutation
>;
export type UpdateSurveyResponseForSurveyPageMutationResult =
    Apollo.MutationResult<UpdateSurveyResponseForSurveyPageMutation>;
export type UpdateSurveyResponseForSurveyPageMutationOptions = Apollo.BaseMutationOptions<
    UpdateSurveyResponseForSurveyPageMutation,
    UpdateSurveyResponseForSurveyPageMutationVariables
>;
export const FetchSurveyResponsesForSurveyPageQueryDocument = gql`
    query FetchSurveyResponsesForSurveyPageQuery($input: UserSurveyResponsesInput!) {
        currentUser {
            surveyResponsesV2(input: $input) {
                orderBy
                skip
                limit
                total
                hasMore
                hasPrevious
                results {
                    ...SurveyResponseForSurveyPage
                }
            }
        }
    }
    ${SurveyResponseForSurveyPageFragmentDoc}
`;

/**
 * __useFetchSurveyResponsesForSurveyPageQueryQuery__
 *
 * To run a query within a React component, call `useFetchSurveyResponsesForSurveyPageQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSurveyResponsesForSurveyPageQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSurveyResponsesForSurveyPageQueryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchSurveyResponsesForSurveyPageQueryQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchSurveyResponsesForSurveyPageQueryQuery,
        FetchSurveyResponsesForSurveyPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchSurveyResponsesForSurveyPageQueryQuery,
        FetchSurveyResponsesForSurveyPageQueryQueryVariables
    >(FetchSurveyResponsesForSurveyPageQueryDocument, options);
}
export function useFetchSurveyResponsesForSurveyPageQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchSurveyResponsesForSurveyPageQueryQuery,
        FetchSurveyResponsesForSurveyPageQueryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchSurveyResponsesForSurveyPageQueryQuery,
        FetchSurveyResponsesForSurveyPageQueryQueryVariables
    >(FetchSurveyResponsesForSurveyPageQueryDocument, options);
}
export type FetchSurveyResponsesForSurveyPageQueryQueryHookResult = ReturnType<
    typeof useFetchSurveyResponsesForSurveyPageQueryQuery
>;
export type FetchSurveyResponsesForSurveyPageQueryLazyQueryHookResult = ReturnType<
    typeof useFetchSurveyResponsesForSurveyPageQueryLazyQuery
>;
export type FetchSurveyResponsesForSurveyPageQueryQueryResult = Apollo.QueryResult<
    FetchSurveyResponsesForSurveyPageQueryQuery,
    FetchSurveyResponsesForSurveyPageQueryQueryVariables
>;
export const FetchReferenceLinksForSurveysPageDocument = gql`
    query FetchReferenceLinksForSurveysPage($input: GetReferenceLinksForPageInput!) {
        getReferenceLinksForPage(input: $input) {
            category
            data {
                label
                url
            }
        }
    }
`;

/**
 * __useFetchReferenceLinksForSurveysPageQuery__
 *
 * To run a query within a React component, call `useFetchReferenceLinksForSurveysPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchReferenceLinksForSurveysPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchReferenceLinksForSurveysPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchReferenceLinksForSurveysPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchReferenceLinksForSurveysPageQuery,
        FetchReferenceLinksForSurveysPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchReferenceLinksForSurveysPageQuery,
        FetchReferenceLinksForSurveysPageQueryVariables
    >(FetchReferenceLinksForSurveysPageDocument, options);
}
export function useFetchReferenceLinksForSurveysPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchReferenceLinksForSurveysPageQuery,
        FetchReferenceLinksForSurveysPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchReferenceLinksForSurveysPageQuery,
        FetchReferenceLinksForSurveysPageQueryVariables
    >(FetchReferenceLinksForSurveysPageDocument, options);
}
export type FetchReferenceLinksForSurveysPageQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForSurveysPageQuery
>;
export type FetchReferenceLinksForSurveysPageLazyQueryHookResult = ReturnType<
    typeof useFetchReferenceLinksForSurveysPageLazyQuery
>;
export type FetchReferenceLinksForSurveysPageQueryResult = Apollo.QueryResult<
    FetchReferenceLinksForSurveysPageQuery,
    FetchReferenceLinksForSurveysPageQueryVariables
>;
export const FetchDeleteUserForFrontDeskPageDocument = gql`
    mutation FetchDeleteUserForFrontDeskPage($input: DeleteUserDataInput!) {
        userManagementDeleteUser(input: $input) {
            message
            success
        }
    }
`;
export type FetchDeleteUserForFrontDeskPageMutationFn = Apollo.MutationFunction<
    FetchDeleteUserForFrontDeskPageMutation,
    FetchDeleteUserForFrontDeskPageMutationVariables
>;

/**
 * __useFetchDeleteUserForFrontDeskPageMutation__
 *
 * To run a mutation, you first call `useFetchDeleteUserForFrontDeskPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFetchDeleteUserForFrontDeskPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fetchDeleteUserForFrontDeskPageMutation, { data, loading, error }] = useFetchDeleteUserForFrontDeskPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchDeleteUserForFrontDeskPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        FetchDeleteUserForFrontDeskPageMutation,
        FetchDeleteUserForFrontDeskPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        FetchDeleteUserForFrontDeskPageMutation,
        FetchDeleteUserForFrontDeskPageMutationVariables
    >(FetchDeleteUserForFrontDeskPageDocument, options);
}
export type FetchDeleteUserForFrontDeskPageMutationHookResult = ReturnType<
    typeof useFetchDeleteUserForFrontDeskPageMutation
>;
export type FetchDeleteUserForFrontDeskPageMutationResult =
    Apollo.MutationResult<FetchDeleteUserForFrontDeskPageMutation>;
export type FetchDeleteUserForFrontDeskPageMutationOptions = Apollo.BaseMutationOptions<
    FetchDeleteUserForFrontDeskPageMutation,
    FetchDeleteUserForFrontDeskPageMutationVariables
>;
export const FetchDownloadFamilyDataForFrontDeskPageDocument = gql`
    query FetchDownloadFamilyDataForFrontDeskPage($input: DownloadFamilyDataInput!) {
        userManagementDownloadFamilyData(input: $input) {
            message
            success
            familyData
        }
    }
`;

/**
 * __useFetchDownloadFamilyDataForFrontDeskPageQuery__
 *
 * To run a query within a React component, call `useFetchDownloadFamilyDataForFrontDeskPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDownloadFamilyDataForFrontDeskPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDownloadFamilyDataForFrontDeskPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchDownloadFamilyDataForFrontDeskPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchDownloadFamilyDataForFrontDeskPageQuery,
        FetchDownloadFamilyDataForFrontDeskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchDownloadFamilyDataForFrontDeskPageQuery,
        FetchDownloadFamilyDataForFrontDeskPageQueryVariables
    >(FetchDownloadFamilyDataForFrontDeskPageDocument, options);
}
export function useFetchDownloadFamilyDataForFrontDeskPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchDownloadFamilyDataForFrontDeskPageQuery,
        FetchDownloadFamilyDataForFrontDeskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchDownloadFamilyDataForFrontDeskPageQuery,
        FetchDownloadFamilyDataForFrontDeskPageQueryVariables
    >(FetchDownloadFamilyDataForFrontDeskPageDocument, options);
}
export type FetchDownloadFamilyDataForFrontDeskPageQueryHookResult = ReturnType<
    typeof useFetchDownloadFamilyDataForFrontDeskPageQuery
>;
export type FetchDownloadFamilyDataForFrontDeskPageLazyQueryHookResult = ReturnType<
    typeof useFetchDownloadFamilyDataForFrontDeskPageLazyQuery
>;
export type FetchDownloadFamilyDataForFrontDeskPageQueryResult = Apollo.QueryResult<
    FetchDownloadFamilyDataForFrontDeskPageQuery,
    FetchDownloadFamilyDataForFrontDeskPageQueryVariables
>;
export const FetchFindUserByEmailForFrontDeskPageDocument = gql`
    query FetchFindUserByEmailForFrontDeskPage($input: FindUserByEmailDataInput!) {
        userManagementFindUserByEmail(input: $input) {
            users
            success
            message
        }
    }
`;

/**
 * __useFetchFindUserByEmailForFrontDeskPageQuery__
 *
 * To run a query within a React component, call `useFetchFindUserByEmailForFrontDeskPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchFindUserByEmailForFrontDeskPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchFindUserByEmailForFrontDeskPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchFindUserByEmailForFrontDeskPageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchFindUserByEmailForFrontDeskPageQuery,
        FetchFindUserByEmailForFrontDeskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchFindUserByEmailForFrontDeskPageQuery,
        FetchFindUserByEmailForFrontDeskPageQueryVariables
    >(FetchFindUserByEmailForFrontDeskPageDocument, options);
}
export function useFetchFindUserByEmailForFrontDeskPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchFindUserByEmailForFrontDeskPageQuery,
        FetchFindUserByEmailForFrontDeskPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchFindUserByEmailForFrontDeskPageQuery,
        FetchFindUserByEmailForFrontDeskPageQueryVariables
    >(FetchFindUserByEmailForFrontDeskPageDocument, options);
}
export type FetchFindUserByEmailForFrontDeskPageQueryHookResult = ReturnType<
    typeof useFetchFindUserByEmailForFrontDeskPageQuery
>;
export type FetchFindUserByEmailForFrontDeskPageLazyQueryHookResult = ReturnType<
    typeof useFetchFindUserByEmailForFrontDeskPageLazyQuery
>;
export type FetchFindUserByEmailForFrontDeskPageQueryResult = Apollo.QueryResult<
    FetchFindUserByEmailForFrontDeskPageQuery,
    FetchFindUserByEmailForFrontDeskPageQueryVariables
>;
export const FetchImportFamilyDataForFrontDeskPageDocument = gql`
    mutation FetchImportFamilyDataForFrontDeskPage($input: ImportFamilyDataInput!) {
        userManagementImportFamilyData(input: $input) {
            message
            success
        }
    }
`;
export type FetchImportFamilyDataForFrontDeskPageMutationFn = Apollo.MutationFunction<
    FetchImportFamilyDataForFrontDeskPageMutation,
    FetchImportFamilyDataForFrontDeskPageMutationVariables
>;

/**
 * __useFetchImportFamilyDataForFrontDeskPageMutation__
 *
 * To run a mutation, you first call `useFetchImportFamilyDataForFrontDeskPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFetchImportFamilyDataForFrontDeskPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fetchImportFamilyDataForFrontDeskPageMutation, { data, loading, error }] = useFetchImportFamilyDataForFrontDeskPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchImportFamilyDataForFrontDeskPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        FetchImportFamilyDataForFrontDeskPageMutation,
        FetchImportFamilyDataForFrontDeskPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        FetchImportFamilyDataForFrontDeskPageMutation,
        FetchImportFamilyDataForFrontDeskPageMutationVariables
    >(FetchImportFamilyDataForFrontDeskPageDocument, options);
}
export type FetchImportFamilyDataForFrontDeskPageMutationHookResult = ReturnType<
    typeof useFetchImportFamilyDataForFrontDeskPageMutation
>;
export type FetchImportFamilyDataForFrontDeskPageMutationResult =
    Apollo.MutationResult<FetchImportFamilyDataForFrontDeskPageMutation>;
export type FetchImportFamilyDataForFrontDeskPageMutationOptions = Apollo.BaseMutationOptions<
    FetchImportFamilyDataForFrontDeskPageMutation,
    FetchImportFamilyDataForFrontDeskPageMutationVariables
>;
export const FetchResetUserPasswordForFrontDeskPageDocument = gql`
    mutation FetchResetUserPasswordForFrontDeskPage($input: ResetUserPasswordDataInput!) {
        userManagementResetUserPassword(input: $input) {
            message
            success
        }
    }
`;
export type FetchResetUserPasswordForFrontDeskPageMutationFn = Apollo.MutationFunction<
    FetchResetUserPasswordForFrontDeskPageMutation,
    FetchResetUserPasswordForFrontDeskPageMutationVariables
>;

/**
 * __useFetchResetUserPasswordForFrontDeskPageMutation__
 *
 * To run a mutation, you first call `useFetchResetUserPasswordForFrontDeskPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFetchResetUserPasswordForFrontDeskPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fetchResetUserPasswordForFrontDeskPageMutation, { data, loading, error }] = useFetchResetUserPasswordForFrontDeskPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchResetUserPasswordForFrontDeskPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        FetchResetUserPasswordForFrontDeskPageMutation,
        FetchResetUserPasswordForFrontDeskPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        FetchResetUserPasswordForFrontDeskPageMutation,
        FetchResetUserPasswordForFrontDeskPageMutationVariables
    >(FetchResetUserPasswordForFrontDeskPageDocument, options);
}
export type FetchResetUserPasswordForFrontDeskPageMutationHookResult = ReturnType<
    typeof useFetchResetUserPasswordForFrontDeskPageMutation
>;
export type FetchResetUserPasswordForFrontDeskPageMutationResult =
    Apollo.MutationResult<FetchResetUserPasswordForFrontDeskPageMutation>;
export type FetchResetUserPasswordForFrontDeskPageMutationOptions = Apollo.BaseMutationOptions<
    FetchResetUserPasswordForFrontDeskPageMutation,
    FetchResetUserPasswordForFrontDeskPageMutationVariables
>;
export const FetchOrgByInviteCodeForPatientInvitePageDocument = gql`
    query FetchOrgByInviteCodeForPatientInvitePage($input: OrganizationByInviteCodeInput!) {
        orgByInviteCode(input: $input) {
            id
            brandingName
            logo
            appPlatform
            appBundleId
        }
    }
`;

/**
 * __useFetchOrgByInviteCodeForPatientInvitePageQuery__
 *
 * To run a query within a React component, call `useFetchOrgByInviteCodeForPatientInvitePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOrgByInviteCodeForPatientInvitePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOrgByInviteCodeForPatientInvitePageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchOrgByInviteCodeForPatientInvitePageQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchOrgByInviteCodeForPatientInvitePageQuery,
        FetchOrgByInviteCodeForPatientInvitePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchOrgByInviteCodeForPatientInvitePageQuery,
        FetchOrgByInviteCodeForPatientInvitePageQueryVariables
    >(FetchOrgByInviteCodeForPatientInvitePageDocument, options);
}
export function useFetchOrgByInviteCodeForPatientInvitePageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchOrgByInviteCodeForPatientInvitePageQuery,
        FetchOrgByInviteCodeForPatientInvitePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchOrgByInviteCodeForPatientInvitePageQuery,
        FetchOrgByInviteCodeForPatientInvitePageQueryVariables
    >(FetchOrgByInviteCodeForPatientInvitePageDocument, options);
}
export type FetchOrgByInviteCodeForPatientInvitePageQueryHookResult = ReturnType<
    typeof useFetchOrgByInviteCodeForPatientInvitePageQuery
>;
export type FetchOrgByInviteCodeForPatientInvitePageLazyQueryHookResult = ReturnType<
    typeof useFetchOrgByInviteCodeForPatientInvitePageLazyQuery
>;
export type FetchOrgByInviteCodeForPatientInvitePageQueryResult = Apollo.QueryResult<
    FetchOrgByInviteCodeForPatientInvitePageQuery,
    FetchOrgByInviteCodeForPatientInvitePageQueryVariables
>;
export const CreatePatientForSelfSignUpPageDocument = gql`
    mutation CreatePatientForSelfSignUpPage($input: CreatePatientForSelfSignUpInput!) {
        createPatientForSelfSignUp(input: $input) {
            message
            success
        }
    }
`;
export type CreatePatientForSelfSignUpPageMutationFn = Apollo.MutationFunction<
    CreatePatientForSelfSignUpPageMutation,
    CreatePatientForSelfSignUpPageMutationVariables
>;

/**
 * __useCreatePatientForSelfSignUpPageMutation__
 *
 * To run a mutation, you first call `useCreatePatientForSelfSignUpPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientForSelfSignUpPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientForSelfSignUpPageMutation, { data, loading, error }] = useCreatePatientForSelfSignUpPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatientForSelfSignUpPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreatePatientForSelfSignUpPageMutation,
        CreatePatientForSelfSignUpPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreatePatientForSelfSignUpPageMutation,
        CreatePatientForSelfSignUpPageMutationVariables
    >(CreatePatientForSelfSignUpPageDocument, options);
}
export type CreatePatientForSelfSignUpPageMutationHookResult = ReturnType<
    typeof useCreatePatientForSelfSignUpPageMutation
>;
export type CreatePatientForSelfSignUpPageMutationResult =
    Apollo.MutationResult<CreatePatientForSelfSignUpPageMutation>;
export type CreatePatientForSelfSignUpPageMutationOptions = Apollo.BaseMutationOptions<
    CreatePatientForSelfSignUpPageMutation,
    CreatePatientForSelfSignUpPageMutationVariables
>;
export const ResendPatientInviteIfExistsForSelfSignUpPageDocument = gql`
    mutation ResendPatientInviteIfExistsForSelfSignUpPage(
        $input: ResendPatientInvitationByEmailIfExistsInput!
    ) {
        resendPatientInvitationByEmailIfExists(input: $input) {
            message
            success
        }
    }
`;
export type ResendPatientInviteIfExistsForSelfSignUpPageMutationFn = Apollo.MutationFunction<
    ResendPatientInviteIfExistsForSelfSignUpPageMutation,
    ResendPatientInviteIfExistsForSelfSignUpPageMutationVariables
>;

/**
 * __useResendPatientInviteIfExistsForSelfSignUpPageMutation__
 *
 * To run a mutation, you first call `useResendPatientInviteIfExistsForSelfSignUpPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendPatientInviteIfExistsForSelfSignUpPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendPatientInviteIfExistsForSelfSignUpPageMutation, { data, loading, error }] = useResendPatientInviteIfExistsForSelfSignUpPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendPatientInviteIfExistsForSelfSignUpPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ResendPatientInviteIfExistsForSelfSignUpPageMutation,
        ResendPatientInviteIfExistsForSelfSignUpPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        ResendPatientInviteIfExistsForSelfSignUpPageMutation,
        ResendPatientInviteIfExistsForSelfSignUpPageMutationVariables
    >(ResendPatientInviteIfExistsForSelfSignUpPageDocument, options);
}
export type ResendPatientInviteIfExistsForSelfSignUpPageMutationHookResult = ReturnType<
    typeof useResendPatientInviteIfExistsForSelfSignUpPageMutation
>;
export type ResendPatientInviteIfExistsForSelfSignUpPageMutationResult =
    Apollo.MutationResult<ResendPatientInviteIfExistsForSelfSignUpPageMutation>;
export type ResendPatientInviteIfExistsForSelfSignUpPageMutationOptions =
    Apollo.BaseMutationOptions<
        ResendPatientInviteIfExistsForSelfSignUpPageMutation,
        ResendPatientInviteIfExistsForSelfSignUpPageMutationVariables
    >;
export const LoginUserForResetPasswordPageDocument = gql`
    mutation LoginUserForResetPasswordPage($input: LoginUserInput!) {
        loginUser(input: $input) {
            success
            message
            tokens {
                idToken
                accessToken
            }
            newPasswordRequired
            tempPasswordToken
        }
    }
`;
export type LoginUserForResetPasswordPageMutationFn = Apollo.MutationFunction<
    LoginUserForResetPasswordPageMutation,
    LoginUserForResetPasswordPageMutationVariables
>;

/**
 * __useLoginUserForResetPasswordPageMutation__
 *
 * To run a mutation, you first call `useLoginUserForResetPasswordPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserForResetPasswordPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserForResetPasswordPageMutation, { data, loading, error }] = useLoginUserForResetPasswordPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserForResetPasswordPageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        LoginUserForResetPasswordPageMutation,
        LoginUserForResetPasswordPageMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        LoginUserForResetPasswordPageMutation,
        LoginUserForResetPasswordPageMutationVariables
    >(LoginUserForResetPasswordPageDocument, options);
}
export type LoginUserForResetPasswordPageMutationHookResult = ReturnType<
    typeof useLoginUserForResetPasswordPageMutation
>;
export type LoginUserForResetPasswordPageMutationResult =
    Apollo.MutationResult<LoginUserForResetPasswordPageMutation>;
export type LoginUserForResetPasswordPageMutationOptions = Apollo.BaseMutationOptions<
    LoginUserForResetPasswordPageMutation,
    LoginUserForResetPasswordPageMutationVariables
>;
export const FetchCurrentUserResetPasswordDocument = gql`
    query FetchCurrentUserResetPassword {
        currentUser {
            id
            name
        }
    }
`;

/**
 * __useFetchCurrentUserResetPasswordQuery__
 *
 * To run a query within a React component, call `useFetchCurrentUserResetPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCurrentUserResetPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCurrentUserResetPasswordQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCurrentUserResetPasswordQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchCurrentUserResetPasswordQuery,
        FetchCurrentUserResetPasswordQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchCurrentUserResetPasswordQuery,
        FetchCurrentUserResetPasswordQueryVariables
    >(FetchCurrentUserResetPasswordDocument, options);
}
export function useFetchCurrentUserResetPasswordLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchCurrentUserResetPasswordQuery,
        FetchCurrentUserResetPasswordQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchCurrentUserResetPasswordQuery,
        FetchCurrentUserResetPasswordQueryVariables
    >(FetchCurrentUserResetPasswordDocument, options);
}
export type FetchCurrentUserResetPasswordQueryHookResult = ReturnType<
    typeof useFetchCurrentUserResetPasswordQuery
>;
export type FetchCurrentUserResetPasswordLazyQueryHookResult = ReturnType<
    typeof useFetchCurrentUserResetPasswordLazyQuery
>;
export type FetchCurrentUserResetPasswordQueryResult = Apollo.QueryResult<
    FetchCurrentUserResetPasswordQuery,
    FetchCurrentUserResetPasswordQueryVariables
>;
export const FetchSurveyDocument = gql`
    query FetchSurvey($surveyDefInput: GetSurveyDefInput!) {
        surveyDef(input: $surveyDefInput) {
            ...SurveyDefForSurveyBuilder
        }
    }
    ${SurveyDefForSurveyBuilderFragmentDoc}
`;

/**
 * __useFetchSurveyQuery__
 *
 * To run a query within a React component, call `useFetchSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSurveyQuery({
 *   variables: {
 *      surveyDefInput: // value for 'surveyDefInput'
 *   },
 * });
 */
export function useFetchSurveyQuery(
    baseOptions: Apollo.QueryHookOptions<FetchSurveyQuery, FetchSurveyQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchSurveyQuery, FetchSurveyQueryVariables>(
        FetchSurveyDocument,
        options,
    );
}
export function useFetchSurveyLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<FetchSurveyQuery, FetchSurveyQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchSurveyQuery, FetchSurveyQueryVariables>(
        FetchSurveyDocument,
        options,
    );
}
export type FetchSurveyQueryHookResult = ReturnType<typeof useFetchSurveyQuery>;
export type FetchSurveyLazyQueryHookResult = ReturnType<typeof useFetchSurveyLazyQuery>;
export type FetchSurveyQueryResult = Apollo.QueryResult<
    FetchSurveyQuery,
    FetchSurveyQueryVariables
>;
export const FetchProfileDefsForSurveyTakerDocument = gql`
    query FetchProfileDefsForSurveyTaker {
        userProfileDefs {
            ...UserProfileDefFragment
        }
    }
    ${UserProfileDefFragmentFragmentDoc}
`;

/**
 * __useFetchProfileDefsForSurveyTakerQuery__
 *
 * To run a query within a React component, call `useFetchProfileDefsForSurveyTakerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProfileDefsForSurveyTakerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProfileDefsForSurveyTakerQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchProfileDefsForSurveyTakerQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchProfileDefsForSurveyTakerQuery,
        FetchProfileDefsForSurveyTakerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchProfileDefsForSurveyTakerQuery,
        FetchProfileDefsForSurveyTakerQueryVariables
    >(FetchProfileDefsForSurveyTakerDocument, options);
}
export function useFetchProfileDefsForSurveyTakerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchProfileDefsForSurveyTakerQuery,
        FetchProfileDefsForSurveyTakerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchProfileDefsForSurveyTakerQuery,
        FetchProfileDefsForSurveyTakerQueryVariables
    >(FetchProfileDefsForSurveyTakerDocument, options);
}
export type FetchProfileDefsForSurveyTakerQueryHookResult = ReturnType<
    typeof useFetchProfileDefsForSurveyTakerQuery
>;
export type FetchProfileDefsForSurveyTakerLazyQueryHookResult = ReturnType<
    typeof useFetchProfileDefsForSurveyTakerLazyQuery
>;
export type FetchProfileDefsForSurveyTakerQueryResult = Apollo.QueryResult<
    FetchProfileDefsForSurveyTakerQuery,
    FetchProfileDefsForSurveyTakerQueryVariables
>;
export const FetchBatchTaskListDocument = gql`
    query FetchBatchTaskList {
        batchTasks {
            id
            type
            status
            addedBy
            runAfter
            startedAt
            completedAt
            priority
            settings
            createdAt
        }
    }
`;

/**
 * __useFetchBatchTaskListQuery__
 *
 * To run a query within a React component, call `useFetchBatchTaskListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchBatchTaskListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchBatchTaskListQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchBatchTaskListQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchBatchTaskListQuery,
        FetchBatchTaskListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchBatchTaskListQuery, FetchBatchTaskListQueryVariables>(
        FetchBatchTaskListDocument,
        options,
    );
}
export function useFetchBatchTaskListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchBatchTaskListQuery,
        FetchBatchTaskListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchBatchTaskListQuery, FetchBatchTaskListQueryVariables>(
        FetchBatchTaskListDocument,
        options,
    );
}
export type FetchBatchTaskListQueryHookResult = ReturnType<typeof useFetchBatchTaskListQuery>;
export type FetchBatchTaskListLazyQueryHookResult = ReturnType<
    typeof useFetchBatchTaskListLazyQuery
>;
export type FetchBatchTaskListQueryResult = Apollo.QueryResult<
    FetchBatchTaskListQuery,
    FetchBatchTaskListQueryVariables
>;
export const DeleteBatchTaskDocument = gql`
    mutation DeleteBatchTask($input: DeleteBatchTaskInput!) {
        deleteBatchTask(input: $input) {
            status
            message
            success
            id
        }
    }
`;
export type DeleteBatchTaskMutationFn = Apollo.MutationFunction<
    DeleteBatchTaskMutation,
    DeleteBatchTaskMutationVariables
>;

/**
 * __useDeleteBatchTaskMutation__
 *
 * To run a mutation, you first call `useDeleteBatchTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBatchTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBatchTaskMutation, { data, loading, error }] = useDeleteBatchTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBatchTaskMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteBatchTaskMutation,
        DeleteBatchTaskMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteBatchTaskMutation, DeleteBatchTaskMutationVariables>(
        DeleteBatchTaskDocument,
        options,
    );
}
export type DeleteBatchTaskMutationHookResult = ReturnType<typeof useDeleteBatchTaskMutation>;
export type DeleteBatchTaskMutationResult = Apollo.MutationResult<DeleteBatchTaskMutation>;
export type DeleteBatchTaskMutationOptions = Apollo.BaseMutationOptions<
    DeleteBatchTaskMutation,
    DeleteBatchTaskMutationVariables
>;
export const FetchAppsTagsForBatchTasksDocument = gql`
    query FetchAppsTagsForBatchTasks {
        amsApps {
            id
            bundleId
            productConst
        }
        tags {
            id
            name
        }
    }
`;

/**
 * __useFetchAppsTagsForBatchTasksQuery__
 *
 * To run a query within a React component, call `useFetchAppsTagsForBatchTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAppsTagsForBatchTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAppsTagsForBatchTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAppsTagsForBatchTasksQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FetchAppsTagsForBatchTasksQuery,
        FetchAppsTagsForBatchTasksQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchAppsTagsForBatchTasksQuery,
        FetchAppsTagsForBatchTasksQueryVariables
    >(FetchAppsTagsForBatchTasksDocument, options);
}
export function useFetchAppsTagsForBatchTasksLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchAppsTagsForBatchTasksQuery,
        FetchAppsTagsForBatchTasksQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchAppsTagsForBatchTasksQuery,
        FetchAppsTagsForBatchTasksQueryVariables
    >(FetchAppsTagsForBatchTasksDocument, options);
}
export type FetchAppsTagsForBatchTasksQueryHookResult = ReturnType<
    typeof useFetchAppsTagsForBatchTasksQuery
>;
export type FetchAppsTagsForBatchTasksLazyQueryHookResult = ReturnType<
    typeof useFetchAppsTagsForBatchTasksLazyQuery
>;
export type FetchAppsTagsForBatchTasksQueryResult = Apollo.QueryResult<
    FetchAppsTagsForBatchTasksQuery,
    FetchAppsTagsForBatchTasksQueryVariables
>;
export const CreateTestAppUserForSystemAdminMutationDocument = gql`
    mutation CreateTestAppUserForSystemAdminMutation(
        $createTestAppUserInput: CreateTestAppUserInput
    ) {
        createTestAppUserMutation(input: $createTestAppUserInput) {
            password
            patientId
        }
    }
`;
export type CreateTestAppUserForSystemAdminMutationMutationFn = Apollo.MutationFunction<
    CreateTestAppUserForSystemAdminMutationMutation,
    CreateTestAppUserForSystemAdminMutationMutationVariables
>;

/**
 * __useCreateTestAppUserForSystemAdminMutationMutation__
 *
 * To run a mutation, you first call `useCreateTestAppUserForSystemAdminMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestAppUserForSystemAdminMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestAppUserForSystemAdminMutationMutation, { data, loading, error }] = useCreateTestAppUserForSystemAdminMutationMutation({
 *   variables: {
 *      createTestAppUserInput: // value for 'createTestAppUserInput'
 *   },
 * });
 */
export function useCreateTestAppUserForSystemAdminMutationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateTestAppUserForSystemAdminMutationMutation,
        CreateTestAppUserForSystemAdminMutationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateTestAppUserForSystemAdminMutationMutation,
        CreateTestAppUserForSystemAdminMutationMutationVariables
    >(CreateTestAppUserForSystemAdminMutationDocument, options);
}
export type CreateTestAppUserForSystemAdminMutationMutationHookResult = ReturnType<
    typeof useCreateTestAppUserForSystemAdminMutationMutation
>;
export type CreateTestAppUserForSystemAdminMutationMutationResult =
    Apollo.MutationResult<CreateTestAppUserForSystemAdminMutationMutation>;
export type CreateTestAppUserForSystemAdminMutationMutationOptions = Apollo.BaseMutationOptions<
    CreateTestAppUserForSystemAdminMutationMutation,
    CreateTestAppUserForSystemAdminMutationMutationVariables
>;
export const GetPatientsForMassUpdateDocument = gql`
    query GetPatientsForMassUpdate($input: PatientQueryInput!) {
        patientsV2(input: $input) {
            total
            results {
                id
                firstName
                lastName
                practice {
                    name
                }
            }
        }
    }
`;

/**
 * __useGetPatientsForMassUpdateQuery__
 *
 * To run a query within a React component, call `useGetPatientsForMassUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientsForMassUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientsForMassUpdateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPatientsForMassUpdateQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetPatientsForMassUpdateQuery,
        GetPatientsForMassUpdateQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetPatientsForMassUpdateQuery, GetPatientsForMassUpdateQueryVariables>(
        GetPatientsForMassUpdateDocument,
        options,
    );
}
export function useGetPatientsForMassUpdateLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetPatientsForMassUpdateQuery,
        GetPatientsForMassUpdateQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetPatientsForMassUpdateQuery,
        GetPatientsForMassUpdateQueryVariables
    >(GetPatientsForMassUpdateDocument, options);
}
export type GetPatientsForMassUpdateQueryHookResult = ReturnType<
    typeof useGetPatientsForMassUpdateQuery
>;
export type GetPatientsForMassUpdateLazyQueryHookResult = ReturnType<
    typeof useGetPatientsForMassUpdateLazyQuery
>;
export type GetPatientsForMassUpdateQueryResult = Apollo.QueryResult<
    GetPatientsForMassUpdateQuery,
    GetPatientsForMassUpdateQueryVariables
>;
export const GetMassUpdateTasksDocument = gql`
    query GetMassUpdateTasks($massUpdatePatientsId: String!, $input: MassUpdateTaskListInput) {
        massUpdateTasksV2(massUpdatePatientsId: $massUpdatePatientsId, input: $input) {
            results {
                id
                createdAt
                updatedAt
                submittedByName
                massUpdatePatientsId
                startedAt
                finishedAt
                status
                processType
                patientIds
                errors {
                    patientId
                    errorText
                }
            }
            total
        }
    }
`;

/**
 * __useGetMassUpdateTasksQuery__
 *
 * To run a query within a React component, call `useGetMassUpdateTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMassUpdateTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMassUpdateTasksQuery({
 *   variables: {
 *      massUpdatePatientsId: // value for 'massUpdatePatientsId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMassUpdateTasksQuery(
    baseOptions: Apollo.QueryHookOptions<GetMassUpdateTasksQuery, GetMassUpdateTasksQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetMassUpdateTasksQuery, GetMassUpdateTasksQueryVariables>(
        GetMassUpdateTasksDocument,
        options,
    );
}
export function useGetMassUpdateTasksLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetMassUpdateTasksQuery,
        GetMassUpdateTasksQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetMassUpdateTasksQuery, GetMassUpdateTasksQueryVariables>(
        GetMassUpdateTasksDocument,
        options,
    );
}
export type GetMassUpdateTasksQueryHookResult = ReturnType<typeof useGetMassUpdateTasksQuery>;
export type GetMassUpdateTasksLazyQueryHookResult = ReturnType<
    typeof useGetMassUpdateTasksLazyQuery
>;
export type GetMassUpdateTasksQueryResult = Apollo.QueryResult<
    GetMassUpdateTasksQuery,
    GetMassUpdateTasksQueryVariables
>;
export const GetMassUpdateTaskDocument = gql`
    query GetMassUpdateTask($input: GetMassUpdateTaskInput!) {
        massUpdateTask(input: $input) {
            createdAt
            updatedAt
            id
            massUpdatePatientsId
            startedAt
            finishedAt
            status
            processType
            patientIds
            errors {
                patientId
                errorText
            }
            profileValues {
                profileVariableDefId
                type
                pvName
                valueBool
                valueNum
                valueStr
                valueId
                valueIds
                valueDate
                clear
            }
            conditionTypeId
            tagIds
            sendEmail
            postToSlack
        }
    }
`;

/**
 * __useGetMassUpdateTaskQuery__
 *
 * To run a query within a React component, call `useGetMassUpdateTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMassUpdateTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMassUpdateTaskQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMassUpdateTaskQuery(
    baseOptions: Apollo.QueryHookOptions<GetMassUpdateTaskQuery, GetMassUpdateTaskQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetMassUpdateTaskQuery, GetMassUpdateTaskQueryVariables>(
        GetMassUpdateTaskDocument,
        options,
    );
}
export function useGetMassUpdateTaskLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetMassUpdateTaskQuery,
        GetMassUpdateTaskQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetMassUpdateTaskQuery, GetMassUpdateTaskQueryVariables>(
        GetMassUpdateTaskDocument,
        options,
    );
}
export type GetMassUpdateTaskQueryHookResult = ReturnType<typeof useGetMassUpdateTaskQuery>;
export type GetMassUpdateTaskLazyQueryHookResult = ReturnType<typeof useGetMassUpdateTaskLazyQuery>;
export type GetMassUpdateTaskQueryResult = Apollo.QueryResult<
    GetMassUpdateTaskQuery,
    GetMassUpdateTaskQueryVariables
>;
export const CreateMassUpdateTaskDocument = gql`
    mutation CreateMassUpdateTask($input: MassUpdateTaskInput!) {
        createMassUpdateTask(input: $input) {
            success
            message
            resourceCreated {
                id
            }
        }
    }
`;
export type CreateMassUpdateTaskMutationFn = Apollo.MutationFunction<
    CreateMassUpdateTaskMutation,
    CreateMassUpdateTaskMutationVariables
>;

/**
 * __useCreateMassUpdateTaskMutation__
 *
 * To run a mutation, you first call `useCreateMassUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMassUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMassUpdateTaskMutation, { data, loading, error }] = useCreateMassUpdateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMassUpdateTaskMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateMassUpdateTaskMutation,
        CreateMassUpdateTaskMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateMassUpdateTaskMutation, CreateMassUpdateTaskMutationVariables>(
        CreateMassUpdateTaskDocument,
        options,
    );
}
export type CreateMassUpdateTaskMutationHookResult = ReturnType<
    typeof useCreateMassUpdateTaskMutation
>;
export type CreateMassUpdateTaskMutationResult =
    Apollo.MutationResult<CreateMassUpdateTaskMutation>;
export type CreateMassUpdateTaskMutationOptions = Apollo.BaseMutationOptions<
    CreateMassUpdateTaskMutation,
    CreateMassUpdateTaskMutationVariables
>;
export const UpdateMassUpdateTaskDocument = gql`
    mutation UpdateMassUpdateTask($input: UpdateMassUpdateTaskInput!) {
        updateMassUpdateTask(input: $input) {
            success
            message
            resourceUpdated {
                id
            }
        }
    }
`;
export type UpdateMassUpdateTaskMutationFn = Apollo.MutationFunction<
    UpdateMassUpdateTaskMutation,
    UpdateMassUpdateTaskMutationVariables
>;

/**
 * __useUpdateMassUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateMassUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMassUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMassUpdateTaskMutation, { data, loading, error }] = useUpdateMassUpdateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMassUpdateTaskMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateMassUpdateTaskMutation,
        UpdateMassUpdateTaskMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateMassUpdateTaskMutation, UpdateMassUpdateTaskMutationVariables>(
        UpdateMassUpdateTaskDocument,
        options,
    );
}
export type UpdateMassUpdateTaskMutationHookResult = ReturnType<
    typeof useUpdateMassUpdateTaskMutation
>;
export type UpdateMassUpdateTaskMutationResult =
    Apollo.MutationResult<UpdateMassUpdateTaskMutation>;
export type UpdateMassUpdateTaskMutationOptions = Apollo.BaseMutationOptions<
    UpdateMassUpdateTaskMutation,
    UpdateMassUpdateTaskMutationVariables
>;
export const DeleteMassUpdateTaskDocument = gql`
    mutation DeleteMassUpdateTask($input: DeleteMassUpdateTaskInput!) {
        deleteMassUpdateTask(input: $input) {
            success
            message
            status
            id
        }
    }
`;
export type DeleteMassUpdateTaskMutationFn = Apollo.MutationFunction<
    DeleteMassUpdateTaskMutation,
    DeleteMassUpdateTaskMutationVariables
>;

/**
 * __useDeleteMassUpdateTaskMutation__
 *
 * To run a mutation, you first call `useDeleteMassUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMassUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMassUpdateTaskMutation, { data, loading, error }] = useDeleteMassUpdateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMassUpdateTaskMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteMassUpdateTaskMutation,
        DeleteMassUpdateTaskMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteMassUpdateTaskMutation, DeleteMassUpdateTaskMutationVariables>(
        DeleteMassUpdateTaskDocument,
        options,
    );
}
export type DeleteMassUpdateTaskMutationHookResult = ReturnType<
    typeof useDeleteMassUpdateTaskMutation
>;
export type DeleteMassUpdateTaskMutationResult =
    Apollo.MutationResult<DeleteMassUpdateTaskMutation>;
export type DeleteMassUpdateTaskMutationOptions = Apollo.BaseMutationOptions<
    DeleteMassUpdateTaskMutation,
    DeleteMassUpdateTaskMutationVariables
>;
export const SubmitMassUpdateTaskDocument = gql`
    mutation SubmitMassUpdateTask($taskId: String!) {
        submitMassUpdateTask(taskId: $taskId) {
            success
            message
        }
    }
`;
export type SubmitMassUpdateTaskMutationFn = Apollo.MutationFunction<
    SubmitMassUpdateTaskMutation,
    SubmitMassUpdateTaskMutationVariables
>;

/**
 * __useSubmitMassUpdateTaskMutation__
 *
 * To run a mutation, you first call `useSubmitMassUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitMassUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitMassUpdateTaskMutation, { data, loading, error }] = useSubmitMassUpdateTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useSubmitMassUpdateTaskMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SubmitMassUpdateTaskMutation,
        SubmitMassUpdateTaskMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SubmitMassUpdateTaskMutation, SubmitMassUpdateTaskMutationVariables>(
        SubmitMassUpdateTaskDocument,
        options,
    );
}
export type SubmitMassUpdateTaskMutationHookResult = ReturnType<
    typeof useSubmitMassUpdateTaskMutation
>;
export type SubmitMassUpdateTaskMutationResult =
    Apollo.MutationResult<SubmitMassUpdateTaskMutation>;
export type SubmitMassUpdateTaskMutationOptions = Apollo.BaseMutationOptions<
    SubmitMassUpdateTaskMutation,
    SubmitMassUpdateTaskMutationVariables
>;
export const GetMassUpdatePatientListsDocument = gql`
    query GetMassUpdatePatientLists($input: MassUpdatePatientQueryInput) {
        massUpdatePatientsV2(input: $input) {
            results {
                createdAt
                updatedAt
                id
                name
                patients
            }
            total
        }
    }
`;

/**
 * __useGetMassUpdatePatientListsQuery__
 *
 * To run a query within a React component, call `useGetMassUpdatePatientListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMassUpdatePatientListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMassUpdatePatientListsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMassUpdatePatientListsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetMassUpdatePatientListsQuery,
        GetMassUpdatePatientListsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetMassUpdatePatientListsQuery, GetMassUpdatePatientListsQueryVariables>(
        GetMassUpdatePatientListsDocument,
        options,
    );
}
export function useGetMassUpdatePatientListsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetMassUpdatePatientListsQuery,
        GetMassUpdatePatientListsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetMassUpdatePatientListsQuery,
        GetMassUpdatePatientListsQueryVariables
    >(GetMassUpdatePatientListsDocument, options);
}
export type GetMassUpdatePatientListsQueryHookResult = ReturnType<
    typeof useGetMassUpdatePatientListsQuery
>;
export type GetMassUpdatePatientListsLazyQueryHookResult = ReturnType<
    typeof useGetMassUpdatePatientListsLazyQuery
>;
export type GetMassUpdatePatientListsQueryResult = Apollo.QueryResult<
    GetMassUpdatePatientListsQuery,
    GetMassUpdatePatientListsQueryVariables
>;
export const GetMassUpdatePatientListDocument = gql`
    query GetMassUpdatePatientList($input: GetMassUpdatePatientInput!) {
        massUpdatePatient(input: $input) {
            createdAt
            updatedAt
            id
            name
            patients
        }
    }
`;

/**
 * __useGetMassUpdatePatientListQuery__
 *
 * To run a query within a React component, call `useGetMassUpdatePatientListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMassUpdatePatientListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMassUpdatePatientListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMassUpdatePatientListQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetMassUpdatePatientListQuery,
        GetMassUpdatePatientListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetMassUpdatePatientListQuery, GetMassUpdatePatientListQueryVariables>(
        GetMassUpdatePatientListDocument,
        options,
    );
}
export function useGetMassUpdatePatientListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetMassUpdatePatientListQuery,
        GetMassUpdatePatientListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetMassUpdatePatientListQuery,
        GetMassUpdatePatientListQueryVariables
    >(GetMassUpdatePatientListDocument, options);
}
export type GetMassUpdatePatientListQueryHookResult = ReturnType<
    typeof useGetMassUpdatePatientListQuery
>;
export type GetMassUpdatePatientListLazyQueryHookResult = ReturnType<
    typeof useGetMassUpdatePatientListLazyQuery
>;
export type GetMassUpdatePatientListQueryResult = Apollo.QueryResult<
    GetMassUpdatePatientListQuery,
    GetMassUpdatePatientListQueryVariables
>;
export const CreateMassUpdatePatientListDocument = gql`
    mutation CreateMassUpdatePatientList($input: MassUpdatePatientsInput!) {
        createMassUpdatePatient(input: $input) {
            success
            message
            resourceCreated {
                id
            }
        }
    }
`;
export type CreateMassUpdatePatientListMutationFn = Apollo.MutationFunction<
    CreateMassUpdatePatientListMutation,
    CreateMassUpdatePatientListMutationVariables
>;

/**
 * __useCreateMassUpdatePatientListMutation__
 *
 * To run a mutation, you first call `useCreateMassUpdatePatientListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMassUpdatePatientListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMassUpdatePatientListMutation, { data, loading, error }] = useCreateMassUpdatePatientListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMassUpdatePatientListMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateMassUpdatePatientListMutation,
        CreateMassUpdatePatientListMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateMassUpdatePatientListMutation,
        CreateMassUpdatePatientListMutationVariables
    >(CreateMassUpdatePatientListDocument, options);
}
export type CreateMassUpdatePatientListMutationHookResult = ReturnType<
    typeof useCreateMassUpdatePatientListMutation
>;
export type CreateMassUpdatePatientListMutationResult =
    Apollo.MutationResult<CreateMassUpdatePatientListMutation>;
export type CreateMassUpdatePatientListMutationOptions = Apollo.BaseMutationOptions<
    CreateMassUpdatePatientListMutation,
    CreateMassUpdatePatientListMutationVariables
>;
export const UpdateMassUpdatePatientListDocument = gql`
    mutation UpdateMassUpdatePatientList($input: UpdateMassUpdatePatientInput!) {
        updateMassUpdatePatient(input: $input) {
            success
            message
            resourceUpdated {
                id
            }
        }
    }
`;
export type UpdateMassUpdatePatientListMutationFn = Apollo.MutationFunction<
    UpdateMassUpdatePatientListMutation,
    UpdateMassUpdatePatientListMutationVariables
>;

/**
 * __useUpdateMassUpdatePatientListMutation__
 *
 * To run a mutation, you first call `useUpdateMassUpdatePatientListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMassUpdatePatientListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMassUpdatePatientListMutation, { data, loading, error }] = useUpdateMassUpdatePatientListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMassUpdatePatientListMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateMassUpdatePatientListMutation,
        UpdateMassUpdatePatientListMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateMassUpdatePatientListMutation,
        UpdateMassUpdatePatientListMutationVariables
    >(UpdateMassUpdatePatientListDocument, options);
}
export type UpdateMassUpdatePatientListMutationHookResult = ReturnType<
    typeof useUpdateMassUpdatePatientListMutation
>;
export type UpdateMassUpdatePatientListMutationResult =
    Apollo.MutationResult<UpdateMassUpdatePatientListMutation>;
export type UpdateMassUpdatePatientListMutationOptions = Apollo.BaseMutationOptions<
    UpdateMassUpdatePatientListMutation,
    UpdateMassUpdatePatientListMutationVariables
>;
export const DeleteMassUpdatePatientListDocument = gql`
    mutation DeleteMassUpdatePatientList($id: String!) {
        deleteMassUpdatePatientList(id: $id) {
            success
            message
            status
            id
        }
    }
`;
export type DeleteMassUpdatePatientListMutationFn = Apollo.MutationFunction<
    DeleteMassUpdatePatientListMutation,
    DeleteMassUpdatePatientListMutationVariables
>;

/**
 * __useDeleteMassUpdatePatientListMutation__
 *
 * To run a mutation, you first call `useDeleteMassUpdatePatientListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMassUpdatePatientListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMassUpdatePatientListMutation, { data, loading, error }] = useDeleteMassUpdatePatientListMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMassUpdatePatientListMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteMassUpdatePatientListMutation,
        DeleteMassUpdatePatientListMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteMassUpdatePatientListMutation,
        DeleteMassUpdatePatientListMutationVariables
    >(DeleteMassUpdatePatientListDocument, options);
}
export type DeleteMassUpdatePatientListMutationHookResult = ReturnType<
    typeof useDeleteMassUpdatePatientListMutation
>;
export type DeleteMassUpdatePatientListMutationResult =
    Apollo.MutationResult<DeleteMassUpdatePatientListMutation>;
export type DeleteMassUpdatePatientListMutationOptions = Apollo.BaseMutationOptions<
    DeleteMassUpdatePatientListMutation,
    DeleteMassUpdatePatientListMutationVariables
>;
export const GetMassUpdateTaskSelectorsDocument = gql`
    query GetMassUpdateTaskSelectors {
        tags {
            id
            name
        }
        conditionTypes {
            id
            name {
                en
            }
        }
    }
`;

/**
 * __useGetMassUpdateTaskSelectorsQuery__
 *
 * To run a query within a React component, call `useGetMassUpdateTaskSelectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMassUpdateTaskSelectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMassUpdateTaskSelectorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMassUpdateTaskSelectorsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetMassUpdateTaskSelectorsQuery,
        GetMassUpdateTaskSelectorsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetMassUpdateTaskSelectorsQuery,
        GetMassUpdateTaskSelectorsQueryVariables
    >(GetMassUpdateTaskSelectorsDocument, options);
}
export function useGetMassUpdateTaskSelectorsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetMassUpdateTaskSelectorsQuery,
        GetMassUpdateTaskSelectorsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetMassUpdateTaskSelectorsQuery,
        GetMassUpdateTaskSelectorsQueryVariables
    >(GetMassUpdateTaskSelectorsDocument, options);
}
export type GetMassUpdateTaskSelectorsQueryHookResult = ReturnType<
    typeof useGetMassUpdateTaskSelectorsQuery
>;
export type GetMassUpdateTaskSelectorsLazyQueryHookResult = ReturnType<
    typeof useGetMassUpdateTaskSelectorsLazyQuery
>;
export type GetMassUpdateTaskSelectorsQueryResult = Apollo.QueryResult<
    GetMassUpdateTaskSelectorsQuery,
    GetMassUpdateTaskSelectorsQueryVariables
>;
export const FetchPatientForAddPatientToListDocument = gql`
    query FetchPatientForAddPatientToList($input: GetPatientInput!) {
        patient(input: $input) {
            id
        }
    }
`;

/**
 * __useFetchPatientForAddPatientToListQuery__
 *
 * To run a query within a React component, call `useFetchPatientForAddPatientToListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientForAddPatientToListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientForAddPatientToListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPatientForAddPatientToListQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchPatientForAddPatientToListQuery,
        FetchPatientForAddPatientToListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchPatientForAddPatientToListQuery,
        FetchPatientForAddPatientToListQueryVariables
    >(FetchPatientForAddPatientToListDocument, options);
}
export function useFetchPatientForAddPatientToListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientForAddPatientToListQuery,
        FetchPatientForAddPatientToListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchPatientForAddPatientToListQuery,
        FetchPatientForAddPatientToListQueryVariables
    >(FetchPatientForAddPatientToListDocument, options);
}
export type FetchPatientForAddPatientToListQueryHookResult = ReturnType<
    typeof useFetchPatientForAddPatientToListQuery
>;
export type FetchPatientForAddPatientToListLazyQueryHookResult = ReturnType<
    typeof useFetchPatientForAddPatientToListLazyQuery
>;
export type FetchPatientForAddPatientToListQueryResult = Apollo.QueryResult<
    FetchPatientForAddPatientToListQuery,
    FetchPatientForAddPatientToListQueryVariables
>;
export const AddToPatientListDocument = gql`
    mutation AddToPatientList($input: AddToPatientListInput!) {
        addToPatientList(input: $input) {
            success
            message
            status
            id
        }
    }
`;
export type AddToPatientListMutationFn = Apollo.MutationFunction<
    AddToPatientListMutation,
    AddToPatientListMutationVariables
>;

/**
 * __useAddToPatientListMutation__
 *
 * To run a mutation, you first call `useAddToPatientListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToPatientListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToPatientListMutation, { data, loading, error }] = useAddToPatientListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddToPatientListMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AddToPatientListMutation,
        AddToPatientListMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<AddToPatientListMutation, AddToPatientListMutationVariables>(
        AddToPatientListDocument,
        options,
    );
}
export type AddToPatientListMutationHookResult = ReturnType<typeof useAddToPatientListMutation>;
export type AddToPatientListMutationResult = Apollo.MutationResult<AddToPatientListMutation>;
export type AddToPatientListMutationOptions = Apollo.BaseMutationOptions<
    AddToPatientListMutation,
    AddToPatientListMutationVariables
>;
export const FetchPatientByIdDocument = gql`
    query FetchPatientById($input: GetPatientInput!) {
        patient(input: $input) {
            id
            appUserId
            appBundleId
            email
            practice {
                id
            }
        }
    }
`;

/**
 * __useFetchPatientByIdQuery__
 *
 * To run a query within a React component, call `useFetchPatientByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchPatientByIdQuery(
    baseOptions: Apollo.QueryHookOptions<FetchPatientByIdQuery, FetchPatientByIdQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FetchPatientByIdQuery, FetchPatientByIdQueryVariables>(
        FetchPatientByIdDocument,
        options,
    );
}
export function useFetchPatientByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchPatientByIdQuery,
        FetchPatientByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FetchPatientByIdQuery, FetchPatientByIdQueryVariables>(
        FetchPatientByIdDocument,
        options,
    );
}
export type FetchPatientByIdQueryHookResult = ReturnType<typeof useFetchPatientByIdQuery>;
export type FetchPatientByIdLazyQueryHookResult = ReturnType<typeof useFetchPatientByIdLazyQuery>;
export type FetchPatientByIdQueryResult = Apollo.QueryResult<
    FetchPatientByIdQuery,
    FetchPatientByIdQueryVariables
>;
export const UnsubscribePatientEmailDocument = gql`
    mutation UnsubscribePatientEmail($id: String!, $setting: Boolean!) {
        unsubscribePatientEmail(id: $id, setting: $setting) {
            status
        }
    }
`;
export type UnsubscribePatientEmailMutationFn = Apollo.MutationFunction<
    UnsubscribePatientEmailMutation,
    UnsubscribePatientEmailMutationVariables
>;

/**
 * __useUnsubscribePatientEmailMutation__
 *
 * To run a mutation, you first call `useUnsubscribePatientEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribePatientEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribePatientEmailMutation, { data, loading, error }] = useUnsubscribePatientEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      setting: // value for 'setting'
 *   },
 * });
 */
export function useUnsubscribePatientEmailMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UnsubscribePatientEmailMutation,
        UnsubscribePatientEmailMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UnsubscribePatientEmailMutation,
        UnsubscribePatientEmailMutationVariables
    >(UnsubscribePatientEmailDocument, options);
}
export type UnsubscribePatientEmailMutationHookResult = ReturnType<
    typeof useUnsubscribePatientEmailMutation
>;
export type UnsubscribePatientEmailMutationResult =
    Apollo.MutationResult<UnsubscribePatientEmailMutation>;
export type UnsubscribePatientEmailMutationOptions = Apollo.BaseMutationOptions<
    UnsubscribePatientEmailMutation,
    UnsubscribePatientEmailMutationVariables
>;
export const UnsubscribePatientTextDocument = gql`
    mutation UnsubscribePatientText($id: String!, $setting: Boolean!) {
        unsubscribePatientText(id: $id, setting: $setting) {
            status
        }
    }
`;
export type UnsubscribePatientTextMutationFn = Apollo.MutationFunction<
    UnsubscribePatientTextMutation,
    UnsubscribePatientTextMutationVariables
>;

/**
 * __useUnsubscribePatientTextMutation__
 *
 * To run a mutation, you first call `useUnsubscribePatientTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribePatientTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribePatientTextMutation, { data, loading, error }] = useUnsubscribePatientTextMutation({
 *   variables: {
 *      id: // value for 'id'
 *      setting: // value for 'setting'
 *   },
 * });
 */
export function useUnsubscribePatientTextMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UnsubscribePatientTextMutation,
        UnsubscribePatientTextMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UnsubscribePatientTextMutation,
        UnsubscribePatientTextMutationVariables
    >(UnsubscribePatientTextDocument, options);
}
export type UnsubscribePatientTextMutationHookResult = ReturnType<
    typeof useUnsubscribePatientTextMutation
>;
export type UnsubscribePatientTextMutationResult =
    Apollo.MutationResult<UnsubscribePatientTextMutation>;
export type UnsubscribePatientTextMutationOptions = Apollo.BaseMutationOptions<
    UnsubscribePatientTextMutation,
    UnsubscribePatientTextMutationVariables
>;
export const CreateOrUpdateVirtualCareFormDocument = gql`
    mutation CreateOrUpdateVirtualCareForm($input: VirtualCareFormInput) {
        createOrUpdateVirtualCareForm(input: $input) {
            message
            status
            success
            data {
                patientId
                firstName
                lastName
                email
                phoneNumber
                Destination
                birthDate
                appointmentMethod
            }
            redirectUrl
            successMessage
        }
    }
`;
export type CreateOrUpdateVirtualCareFormMutationFn = Apollo.MutationFunction<
    CreateOrUpdateVirtualCareFormMutation,
    CreateOrUpdateVirtualCareFormMutationVariables
>;

/**
 * __useCreateOrUpdateVirtualCareFormMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateVirtualCareFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateVirtualCareFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateVirtualCareFormMutation, { data, loading, error }] = useCreateOrUpdateVirtualCareFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateVirtualCareFormMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateOrUpdateVirtualCareFormMutation,
        CreateOrUpdateVirtualCareFormMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateOrUpdateVirtualCareFormMutation,
        CreateOrUpdateVirtualCareFormMutationVariables
    >(CreateOrUpdateVirtualCareFormDocument, options);
}
export type CreateOrUpdateVirtualCareFormMutationHookResult = ReturnType<
    typeof useCreateOrUpdateVirtualCareFormMutation
>;
export type CreateOrUpdateVirtualCareFormMutationResult =
    Apollo.MutationResult<CreateOrUpdateVirtualCareFormMutation>;
export type CreateOrUpdateVirtualCareFormMutationOptions = Apollo.BaseMutationOptions<
    CreateOrUpdateVirtualCareFormMutation,
    CreateOrUpdateVirtualCareFormMutationVariables
>;
export const SettingsForVirtualCareFormDocument = gql`
    query SettingsForVirtualCareForm {
        settings {
            env
        }
    }
`;

/**
 * __useSettingsForVirtualCareFormQuery__
 *
 * To run a query within a React component, call `useSettingsForVirtualCareFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsForVirtualCareFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsForVirtualCareFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsForVirtualCareFormQuery(
    baseOptions?: Apollo.QueryHookOptions<
        SettingsForVirtualCareFormQuery,
        SettingsForVirtualCareFormQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        SettingsForVirtualCareFormQuery,
        SettingsForVirtualCareFormQueryVariables
    >(SettingsForVirtualCareFormDocument, options);
}
export function useSettingsForVirtualCareFormLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SettingsForVirtualCareFormQuery,
        SettingsForVirtualCareFormQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        SettingsForVirtualCareFormQuery,
        SettingsForVirtualCareFormQueryVariables
    >(SettingsForVirtualCareFormDocument, options);
}
export type SettingsForVirtualCareFormQueryHookResult = ReturnType<
    typeof useSettingsForVirtualCareFormQuery
>;
export type SettingsForVirtualCareFormLazyQueryHookResult = ReturnType<
    typeof useSettingsForVirtualCareFormLazyQuery
>;
export type SettingsForVirtualCareFormQueryResult = Apollo.QueryResult<
    SettingsForVirtualCareFormQuery,
    SettingsForVirtualCareFormQueryVariables
>;
export const CheckEligibilityVirtualCareFormDocument = gql`
    mutation CheckEligibilityVirtualCareForm($input: CheckEligibilityFormInput) {
        checkVCFEligibility(input: $input) {
            isEligible
            isMocked
            message
            status
            success
            resultId
        }
    }
`;
export type CheckEligibilityVirtualCareFormMutationFn = Apollo.MutationFunction<
    CheckEligibilityVirtualCareFormMutation,
    CheckEligibilityVirtualCareFormMutationVariables
>;

/**
 * __useCheckEligibilityVirtualCareFormMutation__
 *
 * To run a mutation, you first call `useCheckEligibilityVirtualCareFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckEligibilityVirtualCareFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkEligibilityVirtualCareFormMutation, { data, loading, error }] = useCheckEligibilityVirtualCareFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckEligibilityVirtualCareFormMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CheckEligibilityVirtualCareFormMutation,
        CheckEligibilityVirtualCareFormMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CheckEligibilityVirtualCareFormMutation,
        CheckEligibilityVirtualCareFormMutationVariables
    >(CheckEligibilityVirtualCareFormDocument, options);
}
export type CheckEligibilityVirtualCareFormMutationHookResult = ReturnType<
    typeof useCheckEligibilityVirtualCareFormMutation
>;
export type CheckEligibilityVirtualCareFormMutationResult =
    Apollo.MutationResult<CheckEligibilityVirtualCareFormMutation>;
export type CheckEligibilityVirtualCareFormMutationOptions = Apollo.BaseMutationOptions<
    CheckEligibilityVirtualCareFormMutation,
    CheckEligibilityVirtualCareFormMutationVariables
>;
export const VcfSettingsVirtualCareFormDocument = gql`
    query VCFSettingsVirtualCareForm {
        VCFSettings {
            showOnlyAtHomeZipCodes
        }
    }
`;

/**
 * __useVcfSettingsVirtualCareFormQuery__
 *
 * To run a query within a React component, call `useVcfSettingsVirtualCareFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useVcfSettingsVirtualCareFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVcfSettingsVirtualCareFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useVcfSettingsVirtualCareFormQuery(
    baseOptions?: Apollo.QueryHookOptions<
        VcfSettingsVirtualCareFormQuery,
        VcfSettingsVirtualCareFormQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        VcfSettingsVirtualCareFormQuery,
        VcfSettingsVirtualCareFormQueryVariables
    >(VcfSettingsVirtualCareFormDocument, options);
}
export function useVcfSettingsVirtualCareFormLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        VcfSettingsVirtualCareFormQuery,
        VcfSettingsVirtualCareFormQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        VcfSettingsVirtualCareFormQuery,
        VcfSettingsVirtualCareFormQueryVariables
    >(VcfSettingsVirtualCareFormDocument, options);
}
export type VcfSettingsVirtualCareFormQueryHookResult = ReturnType<
    typeof useVcfSettingsVirtualCareFormQuery
>;
export type VcfSettingsVirtualCareFormLazyQueryHookResult = ReturnType<
    typeof useVcfSettingsVirtualCareFormLazyQuery
>;
export type VcfSettingsVirtualCareFormQueryResult = Apollo.QueryResult<
    VcfSettingsVirtualCareFormQuery,
    VcfSettingsVirtualCareFormQueryVariables
>;
export const FetchVirtualCareAffiliateByAffiliateIdDocument = gql`
    query FetchVirtualCareAffiliateByAffiliateId(
        $input: GetVirtualCareAffiliateByAffiliateIdInput!
    ) {
        virtualCareAffiliateByAffiliateId(input: $input) {
            data {
                externalName
                customSuccessText
                supportMethodType
                supportMethodValue
            }
        }
    }
`;

/**
 * __useFetchVirtualCareAffiliateByAffiliateIdQuery__
 *
 * To run a query within a React component, call `useFetchVirtualCareAffiliateByAffiliateIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchVirtualCareAffiliateByAffiliateIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchVirtualCareAffiliateByAffiliateIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchVirtualCareAffiliateByAffiliateIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        FetchVirtualCareAffiliateByAffiliateIdQuery,
        FetchVirtualCareAffiliateByAffiliateIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FetchVirtualCareAffiliateByAffiliateIdQuery,
        FetchVirtualCareAffiliateByAffiliateIdQueryVariables
    >(FetchVirtualCareAffiliateByAffiliateIdDocument, options);
}
export function useFetchVirtualCareAffiliateByAffiliateIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FetchVirtualCareAffiliateByAffiliateIdQuery,
        FetchVirtualCareAffiliateByAffiliateIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FetchVirtualCareAffiliateByAffiliateIdQuery,
        FetchVirtualCareAffiliateByAffiliateIdQueryVariables
    >(FetchVirtualCareAffiliateByAffiliateIdDocument, options);
}
export type FetchVirtualCareAffiliateByAffiliateIdQueryHookResult = ReturnType<
    typeof useFetchVirtualCareAffiliateByAffiliateIdQuery
>;
export type FetchVirtualCareAffiliateByAffiliateIdLazyQueryHookResult = ReturnType<
    typeof useFetchVirtualCareAffiliateByAffiliateIdLazyQuery
>;
export type FetchVirtualCareAffiliateByAffiliateIdQueryResult = Apollo.QueryResult<
    FetchVirtualCareAffiliateByAffiliateIdQuery,
    FetchVirtualCareAffiliateByAffiliateIdQueryVariables
>;
