import {
    Button,
    Checkbox,
    CheckboxProps,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
    TextFieldProps,
} from '@mui/material';
import { Add, DeleteForever } from '@mui/icons-material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

interface AddAnotherValueProps {
    append: () => void;
    remove: (index: number) => void;
    fields: Record<string, any>[];
    name: string;
    inputProps?: Partial<TextFieldProps>;
    type: 'input' | 'checkbox' | 'date';
    control: Control<any>;
    disabled?: boolean;
}

type TypeToElementArgType<T> = {
    name: string;
    value: any;
    props: Partial<T>;
    control: Control;
    disabled?: boolean;
};

const TypeToElement: { [key: string]: (args: TypeToElementArgType<any>) => JSX.Element } = {
    input: ({ name, value, props, control, disabled }: TypeToElementArgType<TextFieldProps>) => (
        <Controller
            name={name}
            control={control}
            defaultValue={value}
            render={({ field: { onChange, value } }) => (
                <TextField
                    value={value}
                    onChange={onChange}
                    label="Value*"
                    variant="outlined"
                    size="small"
                    style={{ flexBasis: '90%' }}
                    required
                    disabled={disabled}
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...props}
                />
            )}
        />
    ),
    checkbox: ({ name, value, control, disabled }: TypeToElementArgType<CheckboxProps>) => (
        <Controller
            name={name}
            control={control}
            defaultValue={value}
            render={({ field: { onChange, value } }) => (
                <FormControl variant="outlined" style={{ flexBasis: '90%' }}>
                    <FormControlLabel
                        disabled={disabled}
                        control={
                            <Checkbox onChange={e => onChange(e.target.checked)} checked={value} />
                        }
                        label="True/False"
                    />
                </FormControl>
            )}
        />
    ),
    date: ({ name, value, control, disabled }: TypeToElementArgType<CheckboxProps>) => (
        <Controller
            control={control}
            name={name}
            defaultValue={value}
            render={({ field: { onChange, value } }) => {
                let date = new Date();
                if (value) date = new Date(value);
                return (
                    <DesktopDatePicker
                        label="Value*"
                        onChange={onChange}
                        value={date}
                        disabled={disabled}
                    />
                );
            }}
        />
    ),
};

const AddAnotherValue: React.FC<AddAnotherValueProps> = ({
    append,
    remove,
    name,
    fields,
    inputProps = {},
    type,
    control,
    disabled,
}) => {
    return (
        <>
            <FormControl variant="outlined" style={{ float: 'right' }}>
                <Button
                    color="secondary"
                    variant="contained"
                    startIcon={<Add />}
                    size="small"
                    onClick={append}
                    disabled={disabled}
                >
                    Add Value
                </Button>
            </FormControl>
            {fields.map((field, index) => (
                <Grid container key={field.id} wrap="nowrap">
                    {TypeToElement[type]({
                        name: `${name}.${index}.value`,
                        value: field.value,
                        props: inputProps,
                        control,
                        disabled,
                    })}
                    <IconButton
                        size="medium"
                        onClick={() => remove(index)}
                        style={{ flexBasis: '10%' }}
                        disabled={disabled}
                    >
                        <DeleteForever />
                    </IconButton>
                </Grid>
            ))}
        </>
    );
};

export default AddAnotherValue;
