import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    Grid,
    MenuItem,
    TextField,
    Autocomplete,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useForm } from 'react-hook-form';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { SuppressNextGlobalAlert, TriggerGlobalAlert } from '~/state';
import {
    AlertSeverity,
    RoutingMethod,
    useUpdateVcZipCodeToLocationMutation,
    useCreateManyVcZipCodeToLocationsMutation,
    VcZipCodesAffiliatessListQuery,
} from '~/schemaTypes';
import { VC_ZIP_CODE_TO_LOCATION_SCHEMA } from './yupSchema';
import { EditFormData } from './types';

type VCZipCodeToLocationModalProps = {
    onComplete: Dispatch<SetStateAction<boolean>>;
    defaultData?: EditFormData;
    affiliates: VcZipCodesAffiliatessListQuery['virtualCareAffiliatessV2']['results'];
};

type FormInput = {
    locationId?: string | null;
    locationName: string;
    zipCode: string;
    affiliateId?: string | null;
    routingMethod: RoutingMethod;
};

const duplicateEntityHandler = (message: string, data: FormInput) => {
    if (message.includes('E11000 duplicate key')) {
        TriggerGlobalAlert({
            severity: AlertSeverity.Error,
            message: `Virtual Care Zip Code to Location with "${data.locationId}" Location ID and "${data.zipCode}" zipCode already exists`,
        });
        SuppressNextGlobalAlert(true);
    }
};

export const VCZipCodeToLocationEditorModal: React.FC<VCZipCodeToLocationModalProps> = props => {
    const { defaultData, onComplete, affiliates } = props;
    const isEditAction = !!defaultData;
    const affiliatesOptions = useMemo(
        () =>
            affiliates.map(a => ({
                value: a.id,
                title: a.internalName,
            })),
        [affiliates],
    );
    const defaultOption = useMemo(
        () => affiliatesOptions.find(a => a.value === defaultData?.affiliateId),
        [defaultData?.affiliateId, affiliatesOptions],
    );
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        watch,
    } = useForm<FormInput>({
        resolver: yupResolver(VC_ZIP_CODE_TO_LOCATION_SCHEMA as any),
        defaultValues: {
            locationId: defaultData?.locationId ?? '',
            locationName: defaultData?.locationName,
            zipCode: defaultData?.zipCode,
            affiliateId: defaultData?.affiliateId || '',
            routingMethod: defaultData?.routingMethod || RoutingMethod.TimeTap,
        },
    });
    const [routingMethod] = watch(['routingMethod']);
    useEffect(() => {
        if (!defaultOption) {
            setValue('affiliateId', null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (routingMethod === RoutingMethod.Affiliate) {
            setValue('locationId', null);
        } else if (routingMethod === RoutingMethod.TimeTap) {
            setValue('affiliateId', null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routingMethod]);
    const [createEntity, { loading: createLoading }] = useCreateManyVcZipCodeToLocationsMutation({
        onCompleted: () => {
            TriggerGlobalAlert({
                severity: AlertSeverity.Success,
                message: 'Virtual Care Zip Code(s) created successfully',
            });
            onComplete(true);
        },
        onError: (error, data) => {
            duplicateEntityHandler(error.message, data?.variables?.input);
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: error.message,
            });
        },
    });
    const [updateEntity, { loading: updateLoading }] = useUpdateVcZipCodeToLocationMutation({
        onCompleted: () => {
            TriggerGlobalAlert({
                severity: AlertSeverity.Success,
                message: 'Virtual Care Zip Code updated successfully',
            });
            onComplete(true);
        },
        onError: (error, data) => {
            duplicateEntityHandler(error.message, data?.variables?.input.data);
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: error.message,
            });
        },
    });
    const onSubmit = (data: FormInput) => {
        SuppressNextGlobalAlert(true);
        if (isEditAction) {
            updateEntity({
                variables: {
                    input: {
                        id: defaultData.id,
                        data,
                    },
                },
            });
        } else {
            const zipCodeRawData = data.zipCode;
            const zipCodeArray = zipCodeRawData.split(',');
            const payload = zipCodeArray.map(zc => ({
                locationId: data.locationId,
                locationName: data.locationName,
                zipCode: zc.trim(),
                routingMethod: data.routingMethod,
                affiliateId: data.affiliateId,
            }));
            createEntity({
                variables: {
                    input: {
                        data: payload,
                    },
                },
            });
        }
    };
    return (
        <div>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => onComplete(false)}>
                {isEditAction ? 'Edit VC Zip Code' : 'New VC Zip Code'}
            </DialogTitleWithClose>
            <DialogContent dividers>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Grid container justifyContent="flex-start" alignItems="center">
                        <Grid item xs={7}>
                            <TextField
                                variant="outlined"
                                type="text"
                                label="Location Name"
                                fullWidth
                                margin="dense"
                                {...register('locationName')}
                                error={!!errors.locationName}
                                helperText={errors.locationName?.message}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <TextField
                                variant="outlined"
                                type="text"
                                label={isEditAction ? 'Zip Code' : 'Zip Codes (Comma Delimited)'}
                                fullWidth
                                margin="dense"
                                {...register('zipCode')}
                                error={!!errors.zipCode}
                                helperText={errors.zipCode?.message}
                                multiline={!isEditAction}
                                rows={3}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <TextField
                                select
                                fullWidth
                                {...register('routingMethod')}
                                error={!!errors.routingMethod}
                                helperText={errors.routingMethod?.message}
                                label="Routing Method"
                                defaultValue={defaultData?.routingMethod || RoutingMethod.TimeTap}
                            >
                                <MenuItem key={RoutingMethod.TimeTap} value={RoutingMethod.TimeTap}>
                                    TimeTap
                                </MenuItem>
                                <MenuItem
                                    key={RoutingMethod.Affiliate}
                                    value={RoutingMethod.Affiliate}
                                >
                                    Affiliate
                                </MenuItem>
                            </TextField>
                        </Grid>
                        {routingMethod === RoutingMethod.TimeTap && (
                            <Grid item xs={7}>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Time Tap Location ID"
                                    fullWidth
                                    margin="dense"
                                    {...register('locationId')}
                                    error={!!errors.locationId}
                                    helperText={errors.locationId?.message}
                                />
                            </Grid>
                        )}
                        {routingMethod === RoutingMethod.Affiliate && (
                            <Grid item xs={7}>
                                <Autocomplete
                                    fullWidth
                                    autoSelect
                                    disableClearable
                                    getOptionLabel={option => option.title}
                                    onChange={(_, option) => {
                                        setValue('affiliateId', option?.value);
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            type="text"
                                            label="Affiliate"
                                            margin="dense"
                                            error={!!errors.affiliateId}
                                            helperText={errors.affiliateId?.message}
                                        />
                                    )}
                                    isOptionEqualToValue={(option, value) =>
                                        option.value === value.value
                                    }
                                    options={affiliatesOptions}
                                    getOptionKey={option => option.value}
                                    value={defaultOption}
                                />
                            </Grid>
                        )}
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onComplete(false)} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <LoadingButton
                    startIcon={<SaveIcon />}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    loading={createLoading || updateLoading}
                    onClick={() => handleSubmit(onSubmit)()}
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </div>
    );
};
