import { faClone, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Dialog, Tooltip, TablePagination } from '@mui/material';
import { Add, InfoOutlined } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomFilter, { IFilterChange } from '~/components/CustomFilter/CustomFilter';
import { PAGESIZE } from '~/constants';
import { displayDate } from '~/helpers';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    AlertSeverity,
    MessageTemplate,
    MessageTemplatesListDocument,
    MessageTemplatesListQuery,
    MessageTemplatesListQueryVariables,
    useMessageTemplatesListQuery,
    useCreateMessageTemplateMutation,
    MessageTemplateFilterInput,
} from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { toBase64 } from '~/helpers/base64Helper';
import CloneMessageTemplateModal from './CloneMessageTemplateModal';
import { useStyles } from './styles';

type TemplatesList = NonNullable<MessageTemplatesListQuery['messageTemplatesV2']>['results'][0];

export interface CloneTemplateModalParams {
    existingTemplateId: string;
    submitHandler: (id: string, name: string) => void;
}

const MessageTemplates: React.FC = () => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();
    const [showCloneModal, setShowCloneModal] = useState(false);
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [filter, setFilter] = useState<IFilterChange<TemplatesList> | null>(null);
    const [templateId, setTemplateId] = useState<string>('');
    const [templateName, setTemplateName] = useState<string>('');

    const templatesListQueryVariables: MessageTemplatesListQueryVariables = {
        input: {
            pagination: {
                skip: page * rowsPerPage,
                limit: rowsPerPage,
            },
            filter: filter as MessageTemplateFilterInput | null,
        },
    };

    const { data: templatesList, loading: templatesLoading } = useMessageTemplatesListQuery({
        variables: templatesListQueryVariables,
    });

    const [cloneTemplate, { loading: cloneTemplateLoading }] = useCreateMessageTemplateMutation({
        update: (cache, response) => {
            const newTemplate = response.data?.createMessageTemplate?.resourceCreated;
            if (response.data?.createMessageTemplate?.success && newTemplate) {
                const currentTemplates = cache.readQuery<MessageTemplatesListQuery>({
                    query: MessageTemplatesListDocument,
                    variables: templatesListQueryVariables,
                });
                if (currentTemplates?.messageTemplatesV2.results) {
                    cache.writeQuery<MessageTemplatesListQuery>({
                        query: MessageTemplatesListDocument,
                        data: {
                            messageTemplatesV2: {
                                ...currentTemplates.messageTemplatesV2,
                                results: [
                                    ...currentTemplates.messageTemplatesV2.results,
                                    newTemplate,
                                ],
                            },
                        },
                    });
                }
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: MessageTemplatesListDocument,
                variables: templatesListQueryVariables,
            },
        ],
    });

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    const handleCloneTemplateSubmit = (id: string, name: string) => {
        const templateToClone = templatesList?.messageTemplatesV2.results.find(
            template => template.id === id,
        );
        let emailBody;
        let emailTitle;
        let textBody;
        if (templateToClone?.emailBody) {
            emailBody = {
                en: toBase64(templateToClone.emailBody.en || ''),
                es: toBase64(templateToClone.emailBody.es || ''),
            };
        }
        if (templateToClone?.emailTitle) {
            emailTitle = {
                en: templateToClone.emailTitle.en || '',
                es: templateToClone.emailTitle.es || '',
            };
        }
        if (templateToClone?.textBody) {
            textBody = {
                en: toBase64(templateToClone.textBody.en || ''),
                es: toBase64(templateToClone.textBody.es || ''),
            };
        }
        if (templateToClone) {
            cloneTemplate({
                variables: {
                    input: {
                        name,
                        emailBody,
                        emailTitle,
                        textBody,
                    },
                },
            });
            setShowCloneModal(false);
        }
    };

    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable
                title="Message Templates"
                icons={tableIcons}
                isLoading={templatesLoading}
                data={templatesList?.messageTemplatesV2.results ?? []}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={templatesList?.messageTemplatesV2.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                }}
                actions={[
                    {
                        onClick: event => {
                            event.preventDefault();
                        },
                        hidden: !pagePermissions?.EmailTemplates.Edit,
                        icon: () => (
                            <CustomFilter<TemplatesList> setFilter={setFilter} fields={['name']} />
                        ),
                        isFreeAction: true,
                    },
                    {
                        onClick: () => history('/app-config/message-templates/new'),
                        hidden: !pagePermissions?.EmailTemplates.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add Template',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, { id }: any) => history(`/app-config/message-templates/${id}`),
                        hidden: !pagePermissions?.EmailTemplates.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Template',
                    },
                    {
                        onClick: (_e, template) => {
                            setTemplateName((template as MessageTemplate).name);
                            setTemplateId((template as MessageTemplate).id);
                            setShowCloneModal(true);
                        },
                        icon: () => <FontAwesomeIcon icon={faClone} />,
                        tooltip: 'Clone Template',
                    },
                ]}
                columns={[
                    {
                        title: 'Id',
                        field: 'id',
                        sorting: false,
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'Template Id copied to clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                    { title: 'Name', field: 'name', defaultSort: 'asc' },
                    {
                        title: 'Last Updated',
                        field: 'updatedAt',
                        render: ({ updatedAt }) => (
                            <>
                                {displayDate({
                                    isoDateStr: updatedAt,
                                })}
                            </>
                        ),
                    },
                ]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{ pageSize: rowsPerPage, pageSizeOptions: [25, 50, 100], search: false }}
            />
            <Dialog scroll="paper" open={!cloneTemplateLoading && showCloneModal}>
                <DialogTitleWithClose onClose={() => setShowCloneModal(false)} id="cloneTitle">
                    Clone &apos;{templateName}&apos;
                </DialogTitleWithClose>
                <CloneMessageTemplateModal
                    existingTemplateId={templateId}
                    submitHandler={handleCloneTemplateSubmit}
                />
            </Dialog>
        </div>
    );
};

export default MessageTemplates;
