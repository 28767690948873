import { yupResolver } from '@hookform/resolvers/yup';
import {
    Autocomplete,
    Button,
    Card,
    Dialog,
    Divider,
    FormHelperText,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { Add as AddIcon, Save as SaveIcon } from '@mui/icons-material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import SortableList, {
    ListItemType,
    SortableListOnChangeProps,
} from '~/components/SortableList/SortableList';
import { lowercaseFirstLetter } from '~/helpers';
import {
    FetchAdvocateTaskTypesForAdvocateTaskPageDocument,
    Target,
    useAdvocateTaskTemplateForAdvocateTaskTemplatePageLazyQuery,
    useAdvocateTaskTemplatesForAdvocateTaskTemplatesPageQuery,
    useCreateAdvocateTaskForNewAdvocateTaskPageMutation,
    useCreateAdvocateTaskTypeForNewAdvocateTaskPageMutation,
    useFetchAdvocatesForAdvocateTaskPageQueryQuery,
    useFetchAdvocateTaskTypesForAdvocateTaskPageQuery,
    useFetchCurrentUserForCreateAdvocateTaskPageQuery,
    useFetchPatientForAdvocateTaskPageLazyQuery,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import RichTextEditor, { RichTextEditorCommands } from '~/components/RichTextEditor/RichTextEditor';
import { addMinutes } from 'date-fns';
import { useDashboard } from '~/views/Dashboard/DashboardProvider';
import useQueryParams from '~/hooks/useQueryParams';
import PatientPicker from './PatientPicker/PatientPicker';
import { ChecklistItemModal } from '../components';
import { ChecklistItemInput as ChecklistItemModalType } from '../components/ChecklistItemModal/types';
import { Priorities } from '../helpers';
import { getDefaultFormValues, toRequestInput } from './helpers';
import { useStyles } from './styles';
import { AdvocateTaskChecklistItem, AdvocateTaskFormInput } from './types';
import { ADVOCATE_TASK_SCHEMA, CREATE_NEW_TYPE } from './yupSchema';

const ADVOCATE_TASK_LIST_PATHNAME = '/portal/advocate-tasks';

const AdvocateTask: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [checklist, setChecklist] = useState<AdvocateTaskChecklistItem[]>([]);
    const [hasFormError, setHasFormError] = useState<boolean>(false);
    const [selectedListItem, setSelectedListItem] = useState<ChecklistItemModalType | null>(null);
    const [editorCommands, setEditorCommands] = useState<RichTextEditorCommands | null>(null);
    const [descriptionValue, setDescriptionValue] = useState<string>('');
    const [taskTemplateId, setTaskTemplateId] = useState('');
    const [query] = useQueryParams();
    const dashboard = useDashboard();

    const { classes } = useStyles();
    const history = useNavigate();

    const { data: advocateTaskTypes } = useFetchAdvocateTaskTypesForAdvocateTaskPageQuery();
    const { data: currentUserData } = useFetchCurrentUserForCreateAdvocateTaskPageQuery();

    const { data: advocates } = useFetchAdvocatesForAdvocateTaskPageQueryQuery({
        variables: {
            permission: lowercaseFirstLetter(Target.IsHealthAdvocate),
        },
    });

    const advocateTaskTemplates = useAdvocateTaskTemplatesForAdvocateTaskTemplatesPageQuery();

    const [createTaskType] = useCreateAdvocateTaskTypeForNewAdvocateTaskPageMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: FetchAdvocateTaskTypesForAdvocateTaskPageDocument,
            },
        ],
    });

    const [createTask] = useCreateAdvocateTaskForNewAdvocateTaskPageMutation({
        onCompleted: data => {
            history(
                `${ADVOCATE_TASK_LIST_PATHNAME}/${data.createAdvocateTaskEncoded?.resourceCreated?.id}`,
            );
        },
    });

    const [getPatient] = useFetchPatientForAdvocateTaskPageLazyQuery();

    const {
        control,
        watch,
        register,
        handleSubmit: handleFormSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm<AdvocateTaskFormInput>({
        resolver: yupResolver(ADVOCATE_TASK_SCHEMA as any),
        defaultValues: getDefaultFormValues(),
    });

    useEffect(() => {
        const defaultValues = getDefaultFormValues();
        reset(defaultValues);
        setChecklist(defaultValues.checklist);
        setDescriptionValue(defaultValues.description);
        setTaskTemplateId('');
        editorCommands?.commands.clearContent();
    }, [dashboard.orgId, editorCommands?.commands, reset]);

    const [getAdvocateTaskTemplate] = useAdvocateTaskTemplateForAdvocateTaskTemplatePageLazyQuery({
        onCompleted: ({ advocateTaskTemplate }) => {
            if (!advocateTaskTemplate) return;
            const {
                label,
                description,
                priority,
                typeId,
                whenType,
                checklist,
                interventionDurationMinutes,
            } = advocateTaskTemplate;

            const defaultFormValues = getDefaultFormValues();

            setValue('label', label);
            setValue('description', description);
            editorCommands?.commands.setContent(description);
            setValue('priority', priority);
            setValue('typeId', typeId);
            setValue(
                'dueDate',
                whenType === 'intervention' && interventionDurationMinutes
                    ? addMinutes(new Date(), interventionDurationMinutes).toDateString()
                    : defaultFormValues.dueDate,
            );
            setValue(
                'displayDate',
                whenType === 'intervention'
                    ? new Date().toDateString()
                    : defaultFormValues.displayDate,
            );
            setDescriptionValue(description);
            if (checklist) {
                setChecklist(
                    advocateTaskTemplate?.checklist.map(item => ({
                        ...item,
                        label: { en: item.label },
                    })),
                );
            }
        },
    });

    const typeId = watch('typeId');

    const deleteItem = (fn: () => void) => {
        TriggerGlobalConfirm({
            message: `Are you sure you want to delete this item?`,
            callback: () => {
                fn();
            },
        });
    };

    const handleChecklistItemChange = ({ startIndex, endIndex }: SortableListOnChangeProps) => {
        const checklistClone = [...checklist];
        const element = checklistClone[startIndex];
        checklistClone.splice(startIndex, 1);
        checklistClone.splice(endIndex, 0, element);
        setChecklist(checklistClone);
    };

    const handleDeleteChecklistItemClick = (index: number) => {
        deleteItem(() => setChecklist(checklist.filter((_, groupIndex) => groupIndex !== index)));
    };

    const handleAddChecklistItemClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseChecklistModal = () => {
        setIsModalOpen(false);
        setSelectedListItem(null);
    };

    const handleModalSubmit = (checklistItemModal: ChecklistItemModalType) => {
        const checklistItem = { ...checklistItemModal, label: { en: checklistItemModal.label } };
        const indexToUpdate = checklist.findIndex(item => item.id === checklistItem.id);
        if (indexToUpdate === -1) {
            setChecklist(list => [...list, checklistItem]);
        } else {
            const updatedChecklist = [...checklist];
            updatedChecklist[indexToUpdate] = checklistItem;
            setChecklist(updatedChecklist);
        }
        setIsModalOpen(false);
        setSelectedListItem(null);
    };

    const handleChecklistItemClick = (checklistItem: ListItemType) => {
        const selectedCheklistItem = checklistItem as AdvocateTaskChecklistItem;
        setSelectedListItem({ ...selectedCheklistItem, label: selectedCheklistItem.label.en });
        setIsModalOpen(true);
    };

    const handleSubmit: SubmitHandler<AdvocateTaskFormInput> = async form => {
        if (checklist.length === 0) {
            setHasFormError(true);
            return;
        }
        setHasFormError(false);
        const typeId =
            form.typeId === CREATE_NEW_TYPE
                ? await (
                      await createTaskType({ variables: { input: { label: form.newType } } })
                  ).data?.createAdvocateTaskType?.resourceCreated?.id
                : form.typeId;
        if (!typeId || !currentUserData?.currentUser) return;
        const { data } = await getPatient({ variables: { input: { id: form.patientId } } });

        const task = toRequestInput(
            form,
            checklist,
            currentUserData.currentUser.id,
            data?.patient?.isTestData || false,
        );
        await createTask({
            variables: { input: { ...task, typeId } },
        });
        editorCommands?.commands.clearContent();
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <Card>
                    <Typography variant="h6" paragraph>
                        Create HA Task
                    </Typography>
                    <Divider style={{ marginBottom: 20 }} />
                    <form onSubmit={handleFormSubmit(handleSubmit)}>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                label="Label *"
                                fullWidth
                                margin="dense"
                                {...register('label')}
                                InputLabelProps={{ shrink: true }}
                                error={Boolean(errors.label)}
                                helperText={errors.label?.message}
                            />
                        </Grid>
                        <Grid item style={{ padding: '10px' }}>
                            <RichTextEditor
                                onInit={setEditorCommands}
                                onChange={value => setValue('description', value)}
                                placeholder="Description *"
                                label="Description *"
                                initialValue={descriptionValue}
                            />
                        </Grid>
                        <Grid item className={classes.checklistLabel}>
                            <Typography variant="h6" paragraph>
                                Steps
                            </Typography>
                        </Grid>
                        <Grid item className={classes.addTodoBtnWrap}>
                            <Button
                                color="secondary"
                                variant="contained"
                                fullWidth
                                onClick={handleAddChecklistItemClick}
                                startIcon={<AddIcon />}
                            >
                                Add Step
                            </Button>
                        </Grid>
                        {checklist.length === 0 && hasFormError && (
                            <FormHelperText error className={classes.checklistError}>
                                Must select at least one step
                            </FormHelperText>
                        )}
                        <Grid item>
                            {checklist && checklist.length > 0 && (
                                <OutlinedSection className={classes.sortableList} title="Todos">
                                    <Grid item xs={12}>
                                        <SortableList
                                            list={checklist}
                                            droppableId="AdvocateTaskChecklist"
                                            onChange={handleChecklistItemChange}
                                            onClick={handleChecklistItemClick}
                                            onDelete={handleDeleteChecklistItemClick}
                                        />
                                    </Grid>
                                </OutlinedSection>
                            )}
                        </Grid>
                        <Grid item>
                            <ReactHookFormSelect
                                control={control}
                                name="priority"
                                variant="outlined"
                                label="Priority"
                                margin="dense"
                                fullWidth
                                defaultValue=""
                            >
                                {Priorities.map(i => (
                                    <MenuItem key={i} value={i}>
                                        {i}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                        </Grid>
                        <Grid item>
                            <PatientPicker
                                name="patientId"
                                patientId={query.get('patientId')}
                                control={control}
                                onChange={({ patient }) => {
                                    setValue('assignedTo', patient?.assignedHealthAdvocate);
                                }}
                                watch={watch}
                                setValue={setValue}
                                orgId={dashboard.orgId}
                            />
                            {errors?.patientId && (
                                <FormHelperText error className={classes.helperMessage}>
                                    {errors?.patientId?.message}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item>
                            <Controller
                                name="assignedTo"
                                defaultValue=""
                                control={control}
                                render={() => (
                                    <Autocomplete
                                        options={
                                            advocates?.getUsersByPermission?.concat([
                                                { id: undefined, name: 'No one' },
                                            ]) ?? []
                                        }
                                        getOptionLabel={option =>
                                            advocates?.getUsersByPermission
                                                ?.concat([{ id: undefined, name: 'No one' }])
                                                .find(i => i?.id === option?.id)?.name ?? ''
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                            option?.id === value?.id
                                        }
                                        value={
                                            advocates?.getUsersByPermission
                                                ?.concat([{ id: undefined, name: 'No one' }])
                                                .find(i => i?.id === watch('assignedTo')) ?? null
                                        }
                                        onChange={(event, value) => {
                                            setValue('assignedTo', value?.id);
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...params}
                                                label="Assigned to"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                )}
                            />
                            {errors?.assignedTo && (
                                <FormHelperText error className={classes.helperMessage}>
                                    {errors?.assignedTo?.message}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item>
                            <ReactHookFormSelect
                                control={control}
                                defaultValue=""
                                name="typeId"
                                variant="outlined"
                                label="Type"
                                fullWidth
                                margin="dense"
                            >
                                <MenuItem key={CREATE_NEW_TYPE} value={CREATE_NEW_TYPE}>
                                    {CREATE_NEW_TYPE}
                                </MenuItem>
                                {advocateTaskTypes?.advocateTaskTypesV2?.results.map(
                                    ({ id, label }) => (
                                        <MenuItem key={id} value={id}>
                                            {label}
                                        </MenuItem>
                                    ),
                                )}
                            </ReactHookFormSelect>
                            {errors?.typeId && (
                                <FormHelperText error className={classes.helperMessage}>
                                    {errors?.typeId?.message}
                                </FormHelperText>
                            )}
                            {typeId === CREATE_NEW_TYPE && (
                                <TextField
                                    variant="outlined"
                                    label="New Type *"
                                    fullWidth
                                    margin="dense"
                                    {...register('newType')}
                                    error={Boolean(errors.newType)}
                                    helperText={errors.newType?.message}
                                />
                            )}
                        </Grid>
                        <Grid item>
                            <Controller
                                control={control}
                                name="dueDate"
                                render={({ field: { onChange, value } }) => {
                                    let date = new Date();
                                    if (value) date = new Date(value);
                                    return (
                                        <DesktopDatePicker
                                            label="Date To Complete By"
                                            onChange={onChange}
                                            value={date}
                                        />
                                    );
                                }}
                            />
                            {errors?.dueDate && (
                                <FormHelperText error className={classes.helperMessage}>
                                    {errors?.dueDate?.message}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item>
                            <Controller
                                control={control}
                                name="displayDate"
                                render={({ field: { onChange, value } }) => {
                                    let date = new Date();
                                    if (value) date = new Date(value);
                                    return (
                                        <DesktopDatePicker
                                            label="Date To Show"
                                            onChange={onChange}
                                            value={date}
                                        />
                                    );
                                }}
                            />
                            {errors?.displayDate && (
                                <FormHelperText error className={classes.helperMessage}>
                                    {errors?.displayDate?.message}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid
                            container
                            item
                            spacing={1}
                            justifyContent="space-between"
                            direction="row"
                            className={classes.actionButtons}
                        >
                            <div style={{ width: '100%', textAlign: 'right' }}>
                                <Button
                                    onClick={handleFormSubmit(handleSubmit)}
                                    startIcon={<SaveIcon />}
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                >
                                    Create Task
                                </Button>
                            </div>
                        </Grid>
                    </form>
                </Card>
            </Grid>
            <Grid item xs={4}>
                <Card>
                    <Grid item>
                        <Autocomplete
                            options={
                                advocateTaskTemplates.data?.advocateTaskTemplatesV2.results ?? []
                            }
                            onChange={(event, value) => {
                                if (value) {
                                    setTaskTemplateId(value.id);
                                    getAdvocateTaskTemplate({
                                        variables: { input: { id: value.id } },
                                    });
                                }
                            }}
                            value={
                                advocateTaskTemplates.data?.advocateTaskTemplatesV2.results?.find(
                                    item => item.id === taskTemplateId,
                                ) ?? null
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Pre-populate by Task Template"
                                    placeholder="Select template"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        />
                    </Grid>
                </Card>
            </Grid>
            <Dialog
                scroll="paper"
                open={Boolean(isModalOpen)}
                fullWidth
                maxWidth="sm"
                aria-labelledby="form-dialog-title"
            >
                <ChecklistItemModal
                    onSubmit={handleModalSubmit}
                    data={selectedListItem}
                    closeModal={handleCloseChecklistModal}
                    isEditMode={Boolean(selectedListItem)}
                />
            </Dialog>
        </Grid>
    );
};

export default AdvocateTask;
