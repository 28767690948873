import { FetchSurveyResponsesForPatientSurveyPageQuery } from '~/schemaTypes';

type SurveyResponse = NonNullable<
    NonNullable<FetchSurveyResponsesForPatientSurveyPageQuery['patient']>['surveyResponses'][0]
>;

const flaggedSurveyTypes = {
    POSTPARTUM: 'Postpartum',
    EPDS: 'EPDS Survey',
    CIGNA_EPDS_POSTPARTUM: 'Cigna_EPDS Postpartum Survey',
    AXIA_EPDS_SURVEY: 'Axia_EPDS Survey',
};

const EPDS_SELF_HARM_QUESTION_ID = 'Self-Harm';
const POSTPARTUM_HARMING_YOURSELF_QUESTION_ID = 'Harming yourself';
const epdsScoreThreshold = 10;
enum ClientSurveyIds {
    SWC_NEW_PATIENT_INTAKE = 'SWC_NEW_PATIENT_INTAKE',
}

export const shouldFlagSurvey = (survey: SurveyResponse): boolean => {
    const { surveyScore, surveyType, answers } = survey;
    if (answers) {
        const q = answers.find(
            ({ questionId }) =>
                questionId === POSTPARTUM_HARMING_YOURSELF_QUESTION_ID ||
                questionId === EPDS_SELF_HARM_QUESTION_ID,
        );

        switch (surveyType) {
            // if more surveys appear, then we can remove the switch
            case flaggedSurveyTypes.POSTPARTUM:
                if (q?.answerText !== 'Never') {
                    return true;
                }
                if (surveyScore && surveyScore >= epdsScoreThreshold) {
                    return true;
                }
                return false;
            case flaggedSurveyTypes.EPDS:
            case flaggedSurveyTypes.CIGNA_EPDS_POSTPARTUM:
                if (q?.answerText !== '"Never"') {
                    return true;
                }
                if (surveyScore && surveyScore >= epdsScoreThreshold) {
                    return true;
                }
                return false;
            case flaggedSurveyTypes.AXIA_EPDS_SURVEY:
                if (q?.answerText !== '"Never"') {
                    return true;
                }
                if (surveyScore && surveyScore >= epdsScoreThreshold) {
                    return true;
                }
                return false;
            default:
        }
    }
    return false;
};

export const combineSurveyResponses = (surveyResponses: SurveyResponse[]): SurveyResponse[] =>
    Object.values(
        surveyResponses.reduce(
            (
                responses: { [key: string]: SurveyResponse },
                currentResponse: SurveyResponse,
                index: number,
            ) => {
                const id = currentResponse?.clientSurveyId;

                if (!id) {
                    return responses;
                }

                if (id !== ClientSurveyIds.SWC_NEW_PATIENT_INTAKE) {
                    responses[index] = currentResponse;

                    return responses;
                }

                if (!responses[id]) {
                    responses[id] = currentResponse;
                    return responses;
                }

                responses[id] = {
                    ...responses[id],
                    answers: [...responses[id].answers, ...currentResponse.answers],
                };

                return responses;
            },
            {},
        ),
    );
