import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Link, Typography } from '@mui/material';
import useUserPermissions from '~/hooks/useUserPermissions';
import { HomePageEnum } from '~/selectors';
import useAffiliateCareView from '~/hooks/useAffiliateCareView';
import useStyles from './styles';
import AffiliateUrl from './components/AffiliateUrl';

const AffiliateWelcome = () => {
    const { classes } = useStyles();
    const history = useNavigate();
    const { pagePermissions } = useUserPermissions();
    const { affiliateURL, userAffiliateId } = useAffiliateCareView();

    return (
        <div className={classes.root} data-test={HomePageEnum.CONTAINER}>
            <br />
            <div className="titleCont">
                <Typography variant="h5">Welcome to the</Typography>
                <Typography variant="h4">{'Affiliate Care Portal'.toUpperCase()}</Typography>
            </div>
            <AffiliateUrl url={affiliateURL} affiliateId={userAffiliateId} />

            {pagePermissions?.PatientList.Read && (
                <div className="searchCont">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => history('/portal/patients/')}
                    >
                        Go to the Patient List
                    </Button>
                </div>
            )}
            <div className={classes.card}>
                <div>
                    <p>
                        The Affiliate Care Portal provides affiliates with a single access point to
                        tools and data driven by Wildflower Health. Through this portal you can:
                    </p>
                    <ul>
                        <li>Add and view eligible patients</li>
                        <li>See claims previously submitted</li>
                    </ul>
                    <p>
                        For questions and support please contact{' '}
                        <Link
                            href="mailto:Lactationsupport@wildflowerhealth.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            color="primary"
                            underline="hover"
                        >
                            Lactationsupport@wildflowerhealth.com
                        </Link>
                    </p>
                </div>
            </div>

            <div className="footerImgCont">
                <img src="/home-image1.png" alt="Mom and daughter hugging" />
                <img src="/home-image2.png" alt="Mom and son on swing" />
                <img
                    src="/home-image3.png"
                    alt="Mom holding hands over pregnant belly in shape of heart"
                />
                <img src="/home-image4.png" alt="Family hugging and smiling" />
                <img src="/home-image5.png" alt="Mother and child hugging on beach" />
            </div>
        </div>
    );
};

export default AffiliateWelcome;
