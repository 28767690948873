/* eslint-disable func-names */
/* eslint-disable no-self-compare */
import moment from 'moment';
import * as Yup from 'yup';

/**
 * Types of organization details sections
 */
export enum OrganizationDetailsSections {
    GeneralInfo = 'GeneralInfo',
    Address = 'Address',
    PhoneNumbers = 'PhoneNumbers',
    Links = 'Links',
    Services = 'Services',
    DataInterfaces = 'DataInterfaces',
    Surveys = 'Surveys',
    HealthPlans = 'HealthPlans',
    Providers = 'Providers',
    Measurements = 'Measurements',
}

const PHONE_REGEX =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const GENERAL_INFO_SCHEMA = {
    externalId: Yup.string(),
    name: Yup.string().required(),
    allowPatientCreation: Yup.boolean().required('Must either be yes or no'),
    email: Yup.string(),
    conferencingAppName: Yup.string(),
    eligibilityType: Yup.string(),
    timezone: Yup.string().required(),
    parentId: Yup.string(),
    brandingName: Yup.string(),
    healthAdvocateTitle: Yup.string(),
    // logo: Yup.string(),
};

const ADDRESS_SCHEMA = {
    address: Yup.object()
        .nullable()
        .shape(
            {
                street1: Yup.string().when(
                    ['city', 'state', 'code'],
                    ([city, state, code], schema) => {
                        if (!!city || !!state || !!code) {
                            return schema.required('Complete address required');
                        }
                        return schema;
                    },
                ),
                street2: Yup.string().nullable(),
                city: Yup.string().when(
                    ['street1', 'state', 'code'],
                    ([street1, state, code], schema) => {
                        if (!!street1 || !!state || !!code) {
                            return schema.required('Complete address required');
                        }
                        return schema;
                    },
                ),
                state: Yup.string().when(
                    ['street1', 'city', 'code'],
                    ([street1, city, code], schema) => {
                        if (!!street1 || !!city || !!code) {
                            return schema.required('Complete address required');
                        }
                        return schema;
                    },
                ),
                code: Yup.string().when(
                    ['street1', 'state', 'city'],
                    ([street1, state, city], schema) => {
                        if (!!street1 || !!state || !!city) {
                            return schema.required('Complete address required');
                        }
                        return schema;
                    },
                ),
            },
            [
                ['street1', 'city'],
                ['street1', 'state'],
                ['street1', 'code'],
                ['city', 'state'],
                ['city', 'code'],
                ['state', 'code'],
            ],
        ),
};

const PHONE_NUMBERS_SCHEMA = {
    phoneNumbers: Yup.object().shape({
        main: Yup.string()
            .nullable()
            .when('phoneNumber', ([phoneNumber], schema) => {
                if (!phoneNumber) {
                    return schema.matches(PHONE_REGEX, 'Invalid phone number');
                }
                return schema;
            }),
        nurseLine: Yup.string()
            .nullable()
            .when('phoneNumber', ([phoneNumber], schema) => {
                if (!phoneNumber) {
                    return schema.matches(PHONE_REGEX, 'Invalid phone number');
                }
                return schema;
            }),
        lactationConsultant: Yup.string()
            .nullable()
            .when('phoneNumber', ([phoneNumber], schema) => {
                if (!phoneNumber) {
                    return schema.matches(PHONE_REGEX, 'Invalid phone number');
                }
                return schema;
            }),
    }),
};

const LINKS_SCHEMA = {
    links: Yup.object().shape({
        home: Yup.string(),
        directory: Yup.string(),
        scheduling: Yup.string(),
        conferencingAppIos: Yup.string(),
        conferencingAppAndroid: Yup.string(),
        virtualVisitLink: Yup.string(),
        patientPortal: Yup.string(),
        breastfeedingSupport: Yup.string(),
        newMomSupport: Yup.string(),
        covid19: Yup.string(),
        prenatalBooklet: Yup.string(),
    }),
};

const SERVICES_SCHEMA = {
    services: Yup.array().of(
        Yup.object().shape({
            value: Yup.string(),
        }),
    ),
};

const DATA_INTERFACES_SCHEMA = {
    dataInterfaces: Yup.object().shape({
        appointment: Yup.string(),
        deviceMeasurement: Yup.string(),
        surveyResponse: Yup.string(),
    }),
};

const SURVEYS_SCHEMA = {
    surveys: Yup.object().shape({
        birthPlan: Yup.object().shape({
            surveyActive: Yup.boolean(),
            surveyId: Yup.string(),
            displayName: Yup.string(),
        }),
        edps: Yup.object().shape({
            surveyActive: Yup.boolean(),
            surveyId: Yup.string(),
            displayName: Yup.string(),
        }),
        satisfaction: Yup.object().shape({
            surveyActive: Yup.boolean(),
            surveyId: Yup.string(),
            displayName: Yup.string(),
        }),
        newPatientPreAppointment: Yup.object().shape({
            surveyActive: Yup.boolean(),
            surveyId: Yup.string(),
            displayName: Yup.string(),
        }),
        repeatPatientPreAppointment: Yup.object().shape({
            surveyActive: Yup.boolean(),
            surveyId: Yup.string(),
            displayName: Yup.string(),
        }),
    }),
};

const HEALTH_PLANS_SCHEMA = {
    healthPlans: Yup.array().of(
        Yup.object().shape({
            planId: Yup.string(),
            supportedDeviceIds: Yup.array().of(Yup.string()),
            checkEligibility: Yup.boolean(),
        }),
    ),
};

const PROVIDERS_SCHEMA = {
    providers: Yup.array().of(
        Yup.object().shape({
            providerId: Yup.string(),
        }),
    ),
};

const MEASUREMENTS_SCHEMA = {
    organizationMeasurementConfig: Yup.object().shape({
        bloodPressure:
            Yup.object().shape({
                systolic:
                    Yup.object().shape({
                        min:
                            Yup.number()
                                .nullable()
                                .transform((_, val) => (val === val ? val : null)) || null,
                        max:
                            Yup.number()
                                .moreThan(Yup.ref('min'), 'max should be > min')
                                .nullable()
                                .transform((_, val) => (val === val ? val : null)) || null,
                    }) || null,
                diastolic:
                    Yup.object().shape({
                        min:
                            Yup.number()
                                .nullable()
                                .transform((_, val) => (val === val ? val : null)) || null,
                        max:
                            Yup.number()
                                .moreThan(Yup.ref('min'), 'max should be > min')
                                .nullable()
                                .transform((_, val) => (val === val ? val : null)) || null,
                    }) || null,
                pulse:
                    Yup.object().shape({
                        min:
                            Yup.number()
                                .nullable()
                                .transform((_, val) => (val === val ? val : null)) || null,
                        max:
                            Yup.number()
                                .moreThan(Yup.ref('min'), 'max should be > min')
                                .nullable()
                                .transform((_, val) => (val === val ? val : null)) || null,
                    }) || null,
            }) || null,
        weight:
            Yup.object().shape({
                units: Yup.string(),
                measure: Yup.object().shape({
                    min:
                        Yup.number()
                            .nullable()
                            .transform((_, val) => (val === val ? val : null)) || null,
                    max:
                        Yup.number()
                            .moreThan(Yup.ref('min'), 'max should be > min')
                            .nullable()
                            .transform((_, val) => (val === val ? val : null)) || null,
                }),
            }) || null,
        movement:
            Yup.object().shape({
                duration: Yup.object().shape({
                    min:
                        Yup.string()
                            .nullable()
                            .transform((_, val) => (val === val ? val : null)) || null,
                    max:
                        Yup.string()
                            .test('is greater', 'max should be > min', function (value) {
                                const minimum = this.resolve(Yup.ref('min'));
                                if (
                                    moment(value, 'HH:mm:ss').isAfter(
                                        moment(minimum as any, 'HH:mm:ss'),
                                    )
                                ) {
                                    return true;
                                }
                                if (
                                    value === undefined ||
                                    value === null ||
                                    (value === '00:00:00' && minimum === '00:00:00')
                                ) {
                                    return true;
                                }
                                return false;
                            })
                            .nullable()
                            .transform((_, val) => (val === val ? val : null)) || null,
                }),
            }) || null,
        dailyMeasurement:
            Yup.object().shape({
                glucose:
                    Yup.object().shape({
                        min:
                            Yup.number()
                                .nullable()
                                .transform((_, val) => (val === val ? val : null)) || null,
                        max:
                            Yup.number()
                                .moreThan(Yup.ref('min'), 'max should be > min')
                                .nullable()
                                .transform((_, val) => (val === val ? val : null)) || null,
                    }) || null,
                moodLevel:
                    Yup.object().shape({
                        min:
                            Yup.number()
                                .nullable()
                                .transform((_, val) => (val === val ? val : null)) || null,
                        max:
                            Yup.number()
                                .moreThan(Yup.ref('min'), 'max should be > min')
                                .nullable()
                                .transform((_, val) => (val === val ? val : null)) || null,
                    }) || null,
                painLevel:
                    Yup.object().shape({
                        min:
                            Yup.number()
                                .nullable()
                                .transform((_, val) => (val === val ? val : null)) || null,
                        max:
                            Yup.number()
                                .moreThan(Yup.ref('min'), 'max should be > min')
                                .nullable()
                                .transform((_, val) => (val === val ? val : null)) || null,
                    }) || null,
                steps:
                    Yup.object().shape({
                        min:
                            Yup.number()
                                .nullable()
                                .transform((_, val) => (val === val ? val : null)) || null,
                        max:
                            Yup.number()
                                .moreThan(Yup.ref('min'), 'max should be > min')
                                .nullable()
                                .transform((_, val) => (val === val ? val : null)) || null,
                    }) || null,
                temperature:
                    Yup.object().shape({
                        min:
                            Yup.number()
                                .nullable()
                                .transform((_, val) => (val === val ? val : null)) || null,
                        max:
                            Yup.number()
                                .moreThan(Yup.ref('min'), 'max should be > min')
                                .nullable()
                                .transform((_, val) => (val === val ? val : null)) || null,
                    }) || null,
                sleepStarts:
                    Yup.object().shape({
                        min:
                            Yup.string()
                                .nullable()
                                .transform((_, val) => (val === val ? val : null)) || null,
                        max:
                            Yup.string()
                                .test('is greater', 'max should be > min', function (value) {
                                    const minimum = this.resolve(Yup.ref('min'));
                                    if (
                                        moment(value, 'HH:mm').isAfter(
                                            moment(minimum as any, 'HH:mm'),
                                        )
                                    ) {
                                        return true;
                                    }
                                    if (
                                        value === undefined ||
                                        value === null ||
                                        (value === '00:00' && minimum === '00:00')
                                    ) {
                                        return true;
                                    }
                                    return false;
                                })
                                .nullable()
                                .transform((_, val) => (val === val ? val : null)) || null,
                    }) || null,
                sleepEnds:
                    Yup.object().shape({
                        min:
                            Yup.string()
                                .nullable()
                                .transform((_, val) => (val === val ? val : null)) || null,
                        max:
                            Yup.string()
                                .test('is greater', 'max should be > min', function (value) {
                                    const minimum = this.resolve(Yup.ref('min'));
                                    if (
                                        moment(value, 'HH:mm').isAfter(
                                            moment(minimum as any, 'HH:mm'),
                                        )
                                    ) {
                                        return true;
                                    }
                                    if (
                                        value === undefined ||
                                        value === null ||
                                        (value === '00:00' && minimum === '00:00')
                                    ) {
                                        return true;
                                    }
                                    return false;
                                })
                                .nullable()
                                .transform((_, val) => (val === val ? val : null)) || null,
                    }) || null,
            }) || null,
    }),
};

export const generateFormSchema = (orgDetailsSection?: string) => {
    switch (orgDetailsSection) {
        case OrganizationDetailsSections.GeneralInfo:
            return Yup.object().shape(GENERAL_INFO_SCHEMA);
        case OrganizationDetailsSections.Address:
            return Yup.object().shape(ADDRESS_SCHEMA);
        case OrganizationDetailsSections.PhoneNumbers:
            return Yup.object().shape(PHONE_NUMBERS_SCHEMA);
        case OrganizationDetailsSections.Links:
            return Yup.object().shape(LINKS_SCHEMA);
        case OrganizationDetailsSections.Services:
            return Yup.object().shape(SERVICES_SCHEMA);
        case OrganizationDetailsSections.DataInterfaces:
            return Yup.object().shape(DATA_INTERFACES_SCHEMA);
        case OrganizationDetailsSections.Surveys:
            return Yup.object().shape(SURVEYS_SCHEMA);
        case OrganizationDetailsSections.HealthPlans:
            return Yup.object().shape(HEALTH_PLANS_SCHEMA);
        case OrganizationDetailsSections.Providers:
            return Yup.object().shape(PROVIDERS_SCHEMA);
        case OrganizationDetailsSections.Measurements:
            return Yup.object().shape(MEASUREMENTS_SCHEMA);
        default:
            return Yup.object().shape({
                ...GENERAL_INFO_SCHEMA,
                // ...ADDRESS_SCHEMA,
                // ...PHONE_NUMBERS_SCHEMA,
                // ...LINKS_SCHEMA,
                // ...SERVICES_SCHEMA,
                // ...DATA_INTERFACES_SCHEMA,
                // ...SURVEYS_SCHEMA,
                // ...HEALTH_PLANS_SCHEMA,
                // ...PROVIDERS_SCHEMA,
                // ...MEASUREMENTS_SCHEMA,
            });
    }
};
