import { OrderByDirectionEnum } from '~/schemaTypes';

export enum OrderByFields {
    name = 'internalName',
}
export const OrderDirectionMap: Record<string, OrderByDirectionEnum> = {
    desc: OrderByDirectionEnum.Desc,
    asc: OrderByDirectionEnum.Asc,
};
export const ROWS_PER_PAGE = 10;
export const DEFAULT_ORDER_DIRECTION = OrderByDirectionEnum.Asc;

export enum SupportMethodType {
    Email = 'email',
    Phone = 'phone',
    URL = 'url',
}

export const DEFAULT_SUCCESS_TEXT = `To schedule your consult, please Call/Text {AffiliatePhoneNumber} or email {AffiliateSupportEmail} - Be sure to mention your coverage through Wildflower!

{AffiliateWebsite}`;
