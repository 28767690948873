import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Grid, MenuItem, TextField } from '@mui/material';
import { OnSuccessfulZipCodeMatch } from '~/schemaTypes';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';

const ZipCodeRouting = () => {
    const {
        control,
        formState: { errors },
        resetField,
    } = useFormContext();
    const onSuccessfulZipCodeMatch = useWatch({
        name: 'onSuccessfulZipCodeMatch',
        control,
        exact: true,
    });
    const clearDependentValues = () => {
        resetField('zipCodeMatchCustomSuccessString');
    };
    return (
        <OutlinedSection title="Zip Code Routing">
            <Grid item>
                <Controller
                    control={control}
                    name="onSuccessfulZipCodeMatch"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            select
                            fullWidth
                            error={!!errors.onSuccessfulZipCodeMatch}
                            helperText={
                                errors.onSuccessfulZipCodeMatch && 'Please select an option'
                            }
                            onChange={e => {
                                clearDependentValues();
                                field.onChange(e);
                            }}
                        >
                            <MenuItem
                                key={OnSuccessfulZipCodeMatch.ToSuccessScreen}
                                value={OnSuccessfulZipCodeMatch.ToSuccessScreen}
                            >
                                Route To Success Screen
                            </MenuItem>
                        </TextField>
                    )}
                />
            </Grid>
            {onSuccessfulZipCodeMatch === OnSuccessfulZipCodeMatch.ToSuccessScreen && (
                <Grid item>
                    <Controller
                        control={control}
                        name="zipCodeMatchCustomSuccessString"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                fullWidth
                                multiline
                                rows={6}
                                label="Custom Success Text"
                                error={!!errors.zipCodeMatchCustomSuccessString}
                                helperText={
                                    errors.zipCodeMatchCustomSuccessString && 'Please enter a value'
                                }
                            />
                        )}
                    />
                </Grid>
            )}
        </OutlinedSection>
    );
};

export default ZipCodeRouting;
