import React, { useState, useEffect, useMemo, useRef } from 'react';
import debounce from 'lodash/debounce';
import { format, toDate } from 'date-fns-tz';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import useAffiliateCareView from '~/hooks/useAffiliateCareView';
import { useClaimsBuilderAffiliatePatientsLazyQuery, OrderByDirectionEnum } from '~/schemaTypes';
import { SelectedPatient } from './types';
import NoOptionText from './NoOptionText';

const MIN_SEARCH_INPUT_LENGTH = 3;
const SEARCH_DEBOUNCE_INTERVAL = 500;

type PatientSearchProps = {
    setSelectedPatient: (patient: SelectedPatient) => void;
};

const PatientsSearch: React.FC<PatientSearchProps> = props => {
    const { setSelectedPatient } = props;
    const { userAffiliateId } = useAffiliateCareView();
    const [inputValue, setInputValue] = useState<string>('');
    const [search, setSearch] = useState<string>('');
    const setSearchDebounced = useRef(
        debounce(newSearch => setSearch(newSearch), SEARCH_DEBOUNCE_INTERVAL),
    ).current;
    const onInputChange = (_e: any, newInputValue: string) => {
        setInputValue(newInputValue);
        setSearchDebounced(newInputValue);
    };
    const [fetchPatients, { data, loading }] = useClaimsBuilderAffiliatePatientsLazyQuery({
        fetchPolicy: 'network-only',
    });
    useEffect(() => {
        if (search.length >= MIN_SEARCH_INPUT_LENGTH) {
            fetchPatients({
                variables: {
                    input: {
                        affiliateId: userAffiliateId,
                        onlyName: true,
                        search: search.trim(),
                        orderBy: {
                            field: 'createdAt',
                            order: OrderByDirectionEnum.Desc,
                        },
                    },
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);
    const options = useMemo(
        () =>
            data?.virtualCareAffiliatePatients?.results.map(p => ({
                title: `${p.fullName} -- ${format(
                    toDate(p.birthDate.split('T')[0]),
                    'MM/dd/yyyy',
                )}`,
                value: p.id,
            })) || [],
        [data?.virtualCareAffiliatePatients?.results],
    );
    useEffect(() => {
        return () => {
            setSearchDebounced.cancel();
        };
    }, [setSearchDebounced]);
    return (
        <Autocomplete
            options={search.length < MIN_SEARCH_INPUT_LENGTH ? [] : options}
            loading={loading}
            inputValue={inputValue}
            onInputChange={onInputChange}
            getOptionLabel={option => option.title}
            getOptionKey={option => option.value}
            value={null}
            blurOnSelect
            open={search.length >= MIN_SEARCH_INPUT_LENGTH}
            noOptionsText={<NoOptionText fullName={search} />}
            filterOptions={options => options}
            onChange={(event, value) => {
                if (value?.value) {
                    const patient = data?.virtualCareAffiliatePatients?.results.find(
                        p => p.id === value.value,
                    );
                    if (patient) {
                        setSelectedPatient({
                            id: patient.id,
                            firstName: patient.firstName,
                            lastName: patient.lastName,
                            birthDate: patient.birthDate,
                            email: patient.email,
                            fullName: patient.fullName,
                        });
                        onInputChange(null, '');
                        setSearch('');
                    }
                }
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Search and Add Patient Visit"
                    InputLabelProps={{
                        ...params.InputLabelProps,
                        shrink: true,
                    }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="end">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            fullWidth
        />
    );
};

export default PatientsSearch;
