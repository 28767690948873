import {
    Badge,
    Checkbox,
    Dialog,
    DialogContent,
    IconButton,
    Link,
    TablePagination,
    Tooltip,
    Typography,
} from '@mui/material';
import { Info, InfoOutlined, PersonOutlined, QuestionAnswer, Warning } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import copy from 'copy-to-clipboard';
import MaterialTable, { MaterialTableProps, OrderByCollection } from '@material-table/core';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import {
    combineSurveyResponses,
    displayBirthday,
    displayDateWithAbbrTimeZone,
    shouldFlagSurvey,
} from '~/helpers';
import tableIcons from '~/helpers/tableIcons';
import useUserPermissions from '~/hooks/useUserPermissions';
import {
    AlertSeverity,
    FetchSurveyResponsesForSurveyPageQueryQuery,
    FetchSurveyResponsesForSurveyPageQueryQueryVariables,
    OrderByDirectionEnum,
    ReferencePages,
    useFetchSurveyResponsesForSurveyPageQueryQuery,
    UserSurveyResponsesFilterInput,
    useUpdateSurveyResponseForSurveyPageMutation,
    useFetchReferenceLinksForSurveysPageQuery,
} from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import { PAGESIZE } from '~/constants';
import { primaryColor } from '~/theme/WildTheme';
import { flipDirection } from '../../../helpers/flipDirection';
import { FilterSurveysModal } from './FilterSurveysModal/FilterSurveysModal';
import PostpartumModalInfo from './PostpartumModalInfo/PostpartumModalInfo';
import SurveyResults from './SurveyResults/SurveyResults';
import { ISurveyResponsesOrderChange, SurveyResponsesSortingFields } from './types';

type SurveyResponse = NonNullable<
    NonNullable<FetchSurveyResponsesForSurveyPageQueryQuery['currentUser']>['surveyResponsesV2']
>['results'][0];

type SurveysProps = {
    setLinks?: any;
};

const useStyles = makeStyles()(_theme => ({
    root: {
        position: 'relative',
        height: '100%',
        '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
            opacity: '0',
        },
    },
}));

// corresponds to columns={[]} passed to MaterialUI
const columnIdToSortingFieldMap: Record<number, SurveyResponsesSortingFields> = {
    0: SurveyResponsesSortingFields.reviewed,
    8: SurveyResponsesSortingFields.displayName,
    10: SurveyResponsesSortingFields.responseDate,
    11: SurveyResponsesSortingFields.surveyScore,
};

const Surveys: React.FC<SurveysProps> = ({ setLinks }) => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();
    const tableRef = useRef<HTMLDivElement>(null);

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [order, setOrder] = useState<ISurveyResponsesOrderChange>({
        field: SurveyResponsesSortingFields.responseDate,
        direction: OrderByDirectionEnum.Desc,
        isInit: true,
    });

    const [showPostpartumInfo, setShowPostpartumInfo] = useState<boolean>(false);
    const [showSurveyAnswers, setShowSurveyAnswers] = useState<boolean>(false);
    const [surveyData, setSurveyData] = useState<SurveyResponse[]>([]);
    const [updatingSurveyResponseId, setUpdatingSurveyResponseId] = useState<string>('');
    const [viewSurvey, setViewSurvey] = useState<SurveyResponse>();
    const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);

    const suffix = 'Surveys';
    const filteredTagIds = localStorage
        .getItem(`filteredTags${suffix}`)
        ?.split(',')
        .filter(str => str.length > 1);
    const filteredAdvocate = localStorage.getItem(`filteredAdvocate${suffix}`);
    const filterTestData = localStorage.getItem('filterTestData') === 'true' ?? false;
    const filterFlagged = localStorage.getItem(`filterFlagged${suffix}`) === 'true' ?? false;
    const filterDisplayName = localStorage.getItem(`filterDisplayName${suffix}`);
    const filterFirstName = localStorage.getItem(`filterFirstName${suffix}`);
    const filterLastName = localStorage.getItem(`filterLastName${suffix}`);
    const filterResponseDate = localStorage.getItem(`filterResponseDate${suffix}`);
    const filterDueDate = localStorage.getItem(`filterDueDate${suffix}`);
    const filterSurveyScore = parseInt(localStorage.getItem(`filterScore${suffix}`) ?? '0', 10);

    const fetchSurveyResponsesForSurveyPageQueryVariables: FetchSurveyResponsesForSurveyPageQueryQueryVariables =
        {
            input: {
                pagination: {
                    skip: page * rowsPerPage,
                    limit: rowsPerPage,
                },
                orderBy: {
                    field: order.field,
                    order: order.direction,
                },
                filter: {
                    fields: {
                        ...(filterDisplayName && { displayName: filterDisplayName }),
                        ...(filterResponseDate && { responseDate: filterResponseDate }),
                        ...(filterSurveyScore &&
                            filterSurveyScore > 0 && { surveyScore: filterSurveyScore }),
                    },
                } as UserSurveyResponsesFilterInput,
                ...(filteredTagIds && { tagIds: filteredTagIds }),
                ...(filteredAdvocate && { assignedTo: filteredAdvocate }),
                filterTestData,
                filterPatient: {
                    ...(filterFirstName && { firstName: filterFirstName }),
                    ...(filterLastName && { lastName: filterLastName }),
                    ...(filterDueDate && { dueDate: filterDueDate }),
                },
            },
        };

    const {
        data: surveyResponseData,
        loading: surveyDataLoading,
        refetch: refetchSurveyResponsesPageData,
    } = useFetchSurveyResponsesForSurveyPageQueryQuery({
        variables: fetchSurveyResponsesForSurveyPageQueryVariables,
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true, // triggers loading to change even when calling refetch https://www.apollographql.com/docs/react/data/queries/#inspecting-loading-states
        onCompleted: data => {
            if (data?.currentUser?.surveyResponsesV2?.results) {
                const combinedSurveyResponses = combineSurveyResponses(
                    data?.currentUser?.surveyResponsesV2?.results,
                );
                setSurveyData(combinedSurveyResponses);
            }
        },
    });

    const { data: refereneLinksData, loading: refereneLinksLoading } =
        useFetchReferenceLinksForSurveysPageQuery({
            variables: {
                input: {
                    page: ReferencePages.SurveyResponses,
                },
            },
        });

    useEffect(() => {
        if (refereneLinksData && setLinks) {
            setLinks(refereneLinksData.getReferenceLinksForPage);
        }
    }, [refereneLinksData, setLinks]);

    const [updateSurveyResponse, { loading: updateSurveyResponseLoading }] =
        useUpdateSurveyResponseForSurveyPageMutation({
            onCompleted: () => {
                refetchSurveyResponsesPageData();
            },
        });

    const getDirection = (order: string): OrderByDirectionEnum =>
        order.toUpperCase() === OrderByDirectionEnum.Asc
            ? OrderByDirectionEnum.Asc
            : OrderByDirectionEnum.Desc;

    const changeDirection = (previousDirection: OrderByDirectionEnum): void => {
        setOrder(state => ({
            ...state,
            direction: flipDirection(previousDirection),
            isInit: false,
        }));
    };

    const handleOrderChange = (orderByCollection: OrderByCollection[]) => {
        // material table could pass an empty array as parameter. Not sure why.
        if (!orderByCollection || orderByCollection.length === 0) {
            return;
        }
        const orderBy = orderByCollection[0];
        const field = columnIdToSortingFieldMap[orderBy.orderBy];
        if (field) {
            if (field !== order.field) {
                setOrder(state => ({
                    ...state,
                    isInit: true,
                }));
            }
            const direction = order.isInit ? getDirection(orderBy.orderDirection) : order.direction;
            changeDirection(direction);
            setOrder(state => ({
                ...state,
                field,
            }));
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const getHeaderStyle = (field: SurveyResponsesSortingFields): React.CSSProperties => ({
        fontWeight: order.field === field ? 'bold' : 'initial',
    });
    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable
                isLoading={surveyDataLoading}
                icons={tableIcons as MaterialTableProps<any>['icons']}
                actions={[
                    {
                        tooltip: 'Filter Surveys',
                        onClick: () => {
                            setOpenFilterModal(true);
                        },
                        icon: () => (
                            <Badge
                                invisible={
                                    parseInt(
                                        localStorage.getItem(`filtersAppliedCount${suffix}`) ?? '',
                                        10,
                                    ) === 0
                                }
                                badgeContent={localStorage.getItem(`filtersAppliedCount${suffix}`)}
                                color="error"
                                style={{
                                    padding: '12px',
                                    backgroundColor: primaryColor,
                                    color: 'white',
                                    fontSize: '1rem',
                                    borderRadius: '1rem',
                                }}
                            >
                                Filter Surveys
                            </Badge>
                        ),
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, survey: any) => {
                            setViewSurvey(survey);
                            setShowSurveyAnswers(true);
                        },
                        hidden: false,
                        tooltip: 'Survey Answers',
                        icon: () => <QuestionAnswer />,
                    },
                    rowData => ({
                        icon: () => <Info />,
                        onClick: () => {
                            setShowPostpartumInfo(true);
                        },
                        tooltip: 'Survey Info',
                        disabled: rowData.surveyType !== 'Postpartum',
                        hidden: rowData.surveyType !== 'Postpartum',
                    }),
                ]}
                columns={[
                    {
                        title: 'Reviewed',
                        field: 'reviewed',
                        align: 'center',
                        headerStyle: getHeaderStyle(SurveyResponsesSortingFields.reviewed),
                        render: ({ reviewed, id }) =>
                            updatingSurveyResponseId === id &&
                            updateSurveyResponseLoading &&
                            refereneLinksLoading ? (
                                <Loading height={50} />
                            ) : (
                                <Checkbox
                                    checked={reviewed}
                                    disabled={!pagePermissions?.SurveyResponses.Edit}
                                    onChange={() => {
                                        setUpdatingSurveyResponseId(id);
                                        updateSurveyResponse({
                                            variables: {
                                                input: {
                                                    id,
                                                    data: {
                                                        reviewed: !reviewed,
                                                    },
                                                },
                                            },
                                        });
                                    }}
                                />
                            ),
                    },
                    {
                        title: 'Id',
                        field: 'id',
                        align: 'center',
                        sorting: false,
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'Survey Id Link Copied to Clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                    {
                        title: 'Patient Id',
                        field: 'patient.id',
                        align: 'center',
                        sorting: false,
                        render: ({ patient }) => (
                            <Tooltip title="View Patient">
                                <IconButton
                                    onClick={() => {
                                        history(`/portal/patients/${patient.id}/patient-details`);
                                    }}
                                    size="large"
                                >
                                    <PersonOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                    {
                        title: 'External Id',
                        field: 'externalId',
                        align: 'center',
                        sorting: false,
                        render: ({ patient }) => {
                            if (patient?.externalId) {
                                return (
                                    <Tooltip title={patient?.externalId ?? ''}>
                                        <IconButton
                                            onClick={() => {
                                                copy(`${patient?.externalId}`);
                                                TriggerGlobalAlert({
                                                    message: 'External Id Copied to Clipboard',
                                                    severity: AlertSeverity.Success,
                                                });
                                            }}
                                            size="large"
                                        >
                                            <InfoOutlined />
                                        </IconButton>
                                    </Tooltip>
                                );
                            }
                            return '';
                        },
                    },
                    {
                        title: 'Patient',
                        field: 'patient',
                        render: ({ patient }) => {
                            return (
                                <Link
                                    to={`/portal/patients/${patient?.id}/overview`}
                                    component={RouterLink}
                                    underline="hover"
                                >
                                    {patient?.fullName}
                                </Link>
                            );
                        },
                        sorting: false,
                    },
                    {
                        title: 'DOB',
                        field: 'patient.birthDate',
                        sorting: false,
                        render: ({ patient }) => {
                            return (
                                <Typography>
                                    {patient?.birthDate &&
                                        displayBirthday({ isoDateStr: patient?.birthDate })}
                                </Typography>
                            );
                        },
                    },
                    {
                        title: 'Due Date',
                        field: 'patient.dueDate',
                        headerStyle: getHeaderStyle(SurveyResponsesSortingFields.dueDate),
                        sorting: false,
                        render: ({ patient }) => {
                            return (
                                <Typography>
                                    {patient?.dueDate &&
                                        displayBirthday({ isoDateStr: patient?.dueDate })}
                                </Typography>
                            );
                        },
                    },
                    { title: 'Practice', field: 'practice.brandingName', sorting: false },
                    {
                        title: 'Display Name',
                        field: 'displayName',
                        headerStyle: getHeaderStyle(SurveyResponsesSortingFields.displayName),
                    },
                    {
                        title: 'Flagged',
                        sorting: false,
                        render: ({ isFlagged }) => {
                            if (isFlagged) {
                                return <Warning />;
                            }
                            return <div />;
                        },
                        customSort: (a, b) => Number(a.isFlagged) - Number(b.isFlagged),
                    },
                    {
                        title: 'Response Date',
                        headerStyle: getHeaderStyle(SurveyResponsesSortingFields.responseDate),
                        render: ({ responseDate }) => (
                            <Typography>
                                {displayDateWithAbbrTimeZone({
                                    isoDateStr: new Date(responseDate).toISOString(),
                                    format: 'MM/DD/yyyy, hh:mm:ss A',
                                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                })}
                            </Typography>
                        ),
                    },
                    {
                        title: 'Score',
                        field: 'surveyScore',
                        headerStyle: getHeaderStyle(SurveyResponsesSortingFields.surveyScore),
                    },
                ]}
                data={surveyData
                    .map(survey => ({
                        ...survey,
                        isFlagged: shouldFlagSurvey(survey),
                    }))
                    .filter(survey => {
                        if (filterFlagged) {
                            return survey.isFlagged;
                        }
                        return true;
                    })}
                title="Surveys"
                onOrderCollectionChange={handleOrderChange}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={surveyResponseData?.currentUser?.surveyResponsesV2.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                }}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    draggable: false,
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                    search: false,
                    exportAllData: pagePermissions?.SurveyResponses.Export,
                    paginationPosition: 'both',
                    actionsColumnIndex: -1,
                }}
            />
            <Dialog fullWidth maxWidth="lg" open={showPostpartumInfo || showSurveyAnswers}>
                <DialogTitleWithClose
                    id="modalTitle"
                    onClose={() => {
                        setShowPostpartumInfo(false);
                        setShowSurveyAnswers(false);
                    }}
                >
                    {viewSurvey?.displayName} Answers
                </DialogTitleWithClose>
                <DialogContent>
                    {showPostpartumInfo && <PostpartumModalInfo />}
                    {showSurveyAnswers && (
                        <SurveyResults
                            survey={viewSurvey}
                            results={viewSurvey?.answers || []}
                            score={viewSurvey?.surveyScore}
                        />
                    )}
                </DialogContent>
            </Dialog>
            <FilterSurveysModal open={openFilterModal} onClose={() => setOpenFilterModal(false)} />
        </div>
    );
};

export default Surveys;
