import { Checkbox, Grid, Link, TablePagination } from '@mui/material';
import { Add } from '@mui/icons-material';
import MaterialTable, { OrderByCollection } from '@material-table/core';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { flipDirection } from '~/helpers/flipDirection';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    AdvocateTasksForAdvocateTaskListPageQuery,
    AdvocateTaskTypeStatus,
    OrderByDirectionEnum,
    ReferencePages,
    useAdvocateTasksForAdvocateTaskListPageLazyQuery,
    useFetchCurrentUserForAdvocateTaskListPageQuery,
    useFetchReferenceLinksForAdvocateTasksListPageQuery,
} from '~/schemaTypes';
import { PAGESIZE } from '~/constants';
import parse from 'html-react-parser';
import AdvocateTaskListFilters, { emptyFilters } from './AdvocateTaskListFilters';
import {
    AdvocateTaskListFiltersType,
    AdvocateTaskListSortingFields,
    IAdvocateTaskListOrderChange,
} from './shared/types';

type AdvocateTask = NonNullable<
    AdvocateTasksForAdvocateTaskListPageQuery['customFilteredAdvocateTasks']
>['results'][0];

type AdvocateTaskListProps = {
    setLinks?: any;
};

export const UNASSIGNED = 'unassigned';
export const NOT_APPLICABLE = 'N/A';

// corresponds to columns={[]} passed to MaterialUI
const columnIdToSortingFieldMap: Record<number, AdvocateTaskListSortingFields> = {
    3: AdvocateTaskListSortingFields.dueDate,
    4: AdvocateTaskListSortingFields.completedAt,
    5: AdvocateTaskListSortingFields.createdAt,
    7: AdvocateTaskListSortingFields.priority,
};

const AdvocateTaskList: React.FC<AdvocateTaskListProps> = ({ setLinks }) => {
    const history = useNavigate();
    const tableRef = useRef<HTMLDivElement>(null);
    const { pagePermissions } = useUserPermissions();
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const defaultFilters = {
        ...emptyFilters,
        filterTestData: sessionStorage.getItem('HAfilterTestData') === 'true' ?? false,
        status: AdvocateTaskTypeStatus.Open,
    };
    const [order, setOrder] = useState<IAdvocateTaskListOrderChange>({
        field: AdvocateTaskListSortingFields.dueDate,
        direction: OrderByDirectionEnum.Desc,
        isInit: true,
    });
    const [filters, setFilters] = useState<AdvocateTaskListFiltersType>(defaultFilters);

    const { data: currentUser, loading: loadingCurrentUser } =
        useFetchCurrentUserForAdvocateTaskListPageQuery();

    const [fetchAdvocateTasks, { data: filteredAdvocateTasks, loading: loadingFiltered }] =
        useAdvocateTasksForAdvocateTaskListPageLazyQuery({
            fetchPolicy: 'network-only',
        });

    const { data: refereneLinksData, loading: refereneLinksLoading } =
        useFetchReferenceLinksForAdvocateTasksListPageQuery({
            variables: {
                input: {
                    page: ReferencePages.HaTaskList,
                },
            },
        });

    useEffect(() => {
        if (refereneLinksData && setLinks) {
            setLinks(refereneLinksData.getReferenceLinksForPage);
        }
    }, [refereneLinksData, setLinks]);

    useEffect(() => {
        if (loadingCurrentUser) return;
        const {
            assignedTo,
            status,
            type,
            createdBy,
            due,
            customDue,
            importance,
            patients,
            label,
            description,
            filterTestData,
        } = filters;

        let shouldFetchAssignedTo = true;
        if (assignedTo === NOT_APPLICABLE) {
            shouldFetchAssignedTo = false;
        }
        fetchAdvocateTasks({
            variables: {
                input: {
                    filter: {
                        fields: {
                            ...(shouldFetchAssignedTo
                                ? {
                                      // eslint-disable-next-line no-nested-ternary
                                      assignedTo: assignedTo
                                          ? assignedTo === UNASSIGNED
                                              ? null
                                              : assignedTo
                                          : currentUser?.currentUser?.id,
                                  }
                                : {}),
                            status: status || undefined,
                            typeId: type || undefined,
                            createdBy: createdBy || undefined,
                        },
                    },
                    customFilters: {
                        due: due || undefined,
                        customDue: customDue || undefined,
                        importance: importance || undefined,
                        patients: patients || undefined,
                        label: label || undefined,
                        description: description || undefined,
                        filterTestData,
                    },
                    pagination: {
                        skip: page * rowsPerPage,
                        limit: rowsPerPage,
                    },
                    orderBy: {
                        field: order.field,
                        order: order.direction,
                    },
                },
            },
        });
    }, [page, rowsPerPage, filters, fetchAdvocateTasks, currentUser, order, loadingCurrentUser]);

    const getDirection = (order: string): OrderByDirectionEnum =>
        order.toUpperCase() === OrderByDirectionEnum.Asc
            ? OrderByDirectionEnum.Asc
            : OrderByDirectionEnum.Desc;

    const changeDirection = (previousDirection: OrderByDirectionEnum): void => {
        setOrder(state => ({
            ...state,
            direction: flipDirection(previousDirection),
            isInit: false,
        }));
    };

    const handleOrderChange = (orderByCollection: OrderByCollection[]) => {
        // material table passes empty array some times, which causes undefined error
        if (!orderByCollection || orderByCollection.length === 0) {
            return;
        }
        const orderBy = orderByCollection[0];
        const field = columnIdToSortingFieldMap[orderBy.orderBy];
        if (field) {
            if (field !== order.field) {
                setOrder(state => ({
                    ...state,
                    isInit: true,
                }));
            }
            const direction = order.isInit ? getDirection(orderBy.orderDirection) : order.direction;
            changeDirection(direction);
            setOrder(state => ({
                ...state,
                field,
            }));
        }
    };

    const applyFilters = (values: AdvocateTaskListFiltersType) => {
        setPage(0);
        setFilters(values);
    };

    const getHeaderStyle = (field: AdvocateTaskListSortingFields): React.CSSProperties => ({
        fontWeight: order.field === field ? 'bold' : 'initial',
    });

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <Grid container item spacing={2} wrap="nowrap">
            <Grid item>
                <AdvocateTaskListFilters
                    filterApplyHandler={applyFilters}
                    currentUser={currentUser?.currentUser ?? undefined}
                />
            </Grid>
            <Grid item style={{ flex: 1 }} ref={tableRef}>
                <MaterialTable<AdvocateTask>
                    onOrderCollectionChange={handleOrderChange}
                    components={{
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                count={
                                    filteredAdvocateTasks?.customFilteredAdvocateTasks.total ?? 0
                                }
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        ),
                    }}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    options={{
                        pageSize: rowsPerPage,
                        pageSizeOptions: [25, 50, 100],
                        search: false,
                        paginationPosition: 'both',
                    }}
                    isLoading={loadingFiltered || loadingCurrentUser || refereneLinksLoading}
                    icons={tableIcons}
                    title="Advocate Task list"
                    data={filteredAdvocateTasks?.customFilteredAdvocateTasks.results ?? []}
                    actions={[
                        {
                            onClick: () => history('/portal/advocate-tasks/new'),
                            hidden: !pagePermissions?.AdvocateTaskList.Edit,
                            icon: () => <Add />,
                            tooltip: 'Add Advocate Task',
                            isFreeAction: true,
                        },
                    ]}
                    columns={[
                        {
                            title: 'Title',
                            field: 'label',
                            render: ({ id, label }) => {
                                return (
                                    <Link
                                        to={`/portal/advocate-tasks/${id}`}
                                        component={RouterLink}
                                        underline="hover"
                                    >
                                        {label}
                                    </Link>
                                );
                            },
                            sorting: false,
                        },
                        {
                            title: 'Description',
                            field: 'description',
                            render: ({ description }) => {
                                return parse(description);
                            },
                            sorting: false,
                        },
                        {
                            title: 'Patient',
                            field: 'patient',
                            render: ({ patient }) => {
                                return (
                                    <Link
                                        to={`/portal/patients/${patient?.id}/overview`}
                                        component={RouterLink}
                                        underline="hover"
                                    >
                                        {patient?.fullName}
                                    </Link>
                                );
                            },
                            sorting: false,
                        },
                        {
                            title: 'Complete By',
                            field: 'dueDate',
                            render: ({ dueDate }) => {
                                return (
                                    <>
                                        {moment(dueDate).format('MM/DD/YYYY')}
                                        {moment(new Date()).isAfter(moment(dueDate)) && (
                                            <span style={{ color: 'red', fontWeight: 'bold' }}>
                                                {' !'}
                                            </span>
                                        )}
                                    </>
                                );
                            },
                            headerStyle: getHeaderStyle(AdvocateTaskListSortingFields.dueDate),
                            hidden: filters.status === AdvocateTaskTypeStatus.Closed,
                        },
                        {
                            title: 'Closed On',
                            field: 'completedAt',
                            render: ({ completedAt }) => {
                                return moment(completedAt).format('MM/DD/YYYY');
                            },
                            headerStyle: getHeaderStyle(AdvocateTaskListSortingFields.completedAt),
                            searchable: false,
                            hidden: filters.status === AdvocateTaskTypeStatus.Open,
                        },
                        {
                            title: 'Created On',
                            field: 'createdAt',
                            render: ({ createdAt }) => {
                                return moment(createdAt).format('MM/DD/YYYY');
                            },
                            headerStyle: getHeaderStyle(AdvocateTaskListSortingFields.createdAt),
                        },
                        {
                            title: 'Status',
                            field: 'status',
                            render: ({ status }) => {
                                return (
                                    <Checkbox
                                        disabled
                                        checked={status === AdvocateTaskTypeStatus.Closed}
                                    />
                                );
                            },
                            sorting: false,
                        },
                        {
                            title: 'Importance',
                            field: 'priority',
                            headerStyle: getHeaderStyle(AdvocateTaskListSortingFields.priority),
                        },
                        {
                            title: 'App',
                            field: 'patient.practice.brandingName',
                            sorting: false,
                        },
                    ]}
                />
            </Grid>
        </Grid>
    );
};

export default AdvocateTaskList;
