import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns-tz';
import { Typography, Grid, Divider, Dialog, Button, Link } from '@mui/material';
import { TriggerGlobalConfirm } from '~/state';
import useAffiliateCareView from '~/hooks/useAffiliateCareView';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import { useClaimsBuilderCreateAffiliateClaimMutation } from '~/schemaTypes';
import ErrorDialog from '~/views/AffiliateClaimSubmission/components/ErrorDialog';
import { ProcessingError } from '~/views/AffiliateClaimSubmission/types';
import { TabsId } from '../Claims/Claims';
import PatientsSearch from './PatientsSearch';
import { SelectedPatient, Claim } from './types';
import AddPatientToClaimModal from './AddPatientToClaimModal';
import ClaimsToSubmit from './ClaimsToSubmit';

const ClaimsBuilder = () => {
    const history = useNavigate();
    const { userAffiliateId } = useAffiliateCareView();
    const [selectedPatient, setSelectedPatient] = useState<SelectedPatient | null>(null);
    const [showPatientModal, setShowPatientModal] = useState<boolean>(false);
    const [claims, setClaims] = useState<Claim[]>([]);
    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
    const [processingError, setProcessingError] = useState<ProcessingError>();
    const selectPatientHandler = useCallback((patient: SelectedPatient) => {
        setSelectedPatient(patient);
        setShowPatientModal(true);
    }, []);
    const closePatientModalHandler = useCallback(() => {
        setShowPatientModal(false);
        setSelectedPatient(null);
    }, []);
    const addClaimHandler = useCallback((claim: Claim) => {
        setClaims(prev => [...prev, claim]);
        setShowPatientModal(false);
    }, []);
    const deleteClaimHandler = useCallback((id: number) => {
        setClaims(prev => prev.filter(c => c.id !== id));
    }, []);
    const [createClaims, { loading }] = useClaimsBuilderCreateAffiliateClaimMutation({
        onCompleted: response => {
            if (response.createAffiliateClaimFromBuilder?.success) {
                history('/affiliate-care/claims', { state: TabsId.Patients });
            } else if (response.createAffiliateClaimFromBuilder?.error) {
                setProcessingError(response.createAffiliateClaimFromBuilder.error);
                setShowErrorDialog(true);
            }
        },
    });
    const createClaimsHandler = () => {
        TriggerGlobalConfirm({
            message: `Are you sure you want to submit these (${claims.length}) Claims for Reimbursement?`,
            callback: () => {
                createClaims({
                    variables: {
                        input: {
                            affiliateId: userAffiliateId,
                            claims: claims.map(claim => ({
                                firstName: claim.firstName,
                                lastName: claim.lastName,
                                serviceType: claim.serviceType,
                                dateOfService: format(claim.dateOfService, 'MM/dd/yyyy'),
                                birthDate: claim.birthDate,
                                patientId: claim.patientId,
                            })),
                        },
                    },
                });
            },
        });
    };
    return (
        <Grid container rowSpacing={2}>
            <Grid item xs={12} container justifyContent="space-between">
                <Grid item>
                    <Typography variant="h6">Claim Builder Assistant</Typography>
                </Grid>
                <Grid item>
                    <Link variant="button" href="/affiliate-care/claims/submit">
                        <Button variant="contained" color="primary">
                            Upload Batch Claims (Advanced)
                        </Button>
                    </Link>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12} paddingBottom={3}>
                <Typography variant="body1">
                    Welcome to the Claim Builder Assistant! Use the search bar below to find your
                    patients by name and add the visit details to complete your claim. You can enter
                    multiple visits before submitting your batch of claims.
                </Typography>
            </Grid>
            <Grid item xs={8} minHeight={150}>
                <PatientsSearch setSelectedPatient={selectPatientHandler} />
            </Grid>
            <Grid item xs={12} paddingBottom={4}>
                <ClaimsToSubmit claims={claims} handleDelete={deleteClaimHandler} />
            </Grid>
            <Grid item xs={12}>
                <AsyncActionButton loading={loading}>
                    <Button
                        onClick={createClaimsHandler}
                        variant="outlined"
                        disabled={!claims.length}
                    >
                        Submit Claims for Reimbursement
                    </Button>
                </AsyncActionButton>
            </Grid>
            {selectedPatient && (
                <Dialog open={showPatientModal}>
                    <AddPatientToClaimModal
                        onClose={closePatientModalHandler}
                        patient={selectedPatient}
                        addClaimHandler={addClaimHandler}
                    />
                </Dialog>
            )}
            <ErrorDialog
                open={showErrorDialog}
                onClose={() => setShowErrorDialog(false)}
                processingError={processingError}
            />
        </Grid>
    );
};

export default ClaimsBuilder;
