import React, { useMemo } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import { states } from './mappers';

const EMPTY_STATE_VALUE = { label: '', id: '', path: '' } as ValueType;

const inputStates = [
    EMPTY_STATE_VALUE,
    ...states.map(state => ({
        label: state.label,
        id: state.value,
        path: state.label.toLowerCase(),
    })),
] as ValueType[];

type ValueType = {
    label: string;
    id: string;
    path: string;
};

type StateAutocompleteProps = {
    isMobileView: boolean;
    errorMessage?: string;
    name: string;
    label: string;
    register: UseFormRegisterReturn;
    onChange: (value: string) => void;
    value?: string;
    disabled?: boolean;
};

const StateAutocomplete: React.FC<StateAutocompleteProps> = props => {
    const { register, onChange, isMobileView, errorMessage, name, label, disabled, value } = props;
    const valueOption = useMemo(() => {
        if (value) {
            return inputStates.find(state => state.id === value);
        }
        return EMPTY_STATE_VALUE;
    }, [value]);
    return (
        <Autocomplete
            {...(isMobileView && { size: 'small' })}
            id={name}
            disablePortal
            options={inputStates}
            {...(value !== undefined && { value: valueOption })}
            renderInput={params => (
                <TextField
                    {...params}
                    name={name}
                    error={!!errorMessage}
                    helperText={errorMessage}
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'address-level1',
                    }}
                />
            )}
            {...register}
            onChange={(event, selectedOption) => onChange(selectedOption?.id || '')}
            onBlur={e => {
                e.preventDefault();
            }}
            disabled={!!disabled}
        />
    );
};

export default StateAutocomplete;
