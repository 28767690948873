/* eslint-disable camelcase */
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AutocompleteWithRecordOptions } from '~/components/AutocompleteWithRecordOptions/AutocompleteWithRecordOptions';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import { lowercaseFirstLetter } from '~/helpers';
import {
    AdvocateTaskTypeStatus,
    FetchCurrentUserForAdvocateTaskListPageQuery,
    Target,
    useFetchAdvocatesForAdvocateTaskListPageQueryQuery,
    useFetchAdvocateTaskTypesQueryQuery,
    useFetchUsersForAdvocateTaskListPageQueryQuery,
} from '~/schemaTypes';
import { Priorities } from '../../ConfigDashboard/AdvocateTasks/helpers';
import { NOT_APPLICABLE, UNASSIGNED } from './AdvocateTaskList';
import { DueFilterConfig } from './shared/configs';
import { AdvocateTaskListFiltersType } from './shared/types';

type CurrentUser = NonNullable<FetchCurrentUserForAdvocateTaskListPageQuery['currentUser']>;

export const emptyFilters: AdvocateTaskListFiltersType = {
    assignedTo: '',
    status: '',
    due: '',
    customDue: new Date().toISOString(),
    importance: [],
    patients: [],
    createdBy: '',
    type: '',
    label: '',
    description: '',
    filterTestData: false,
};

interface AdvocateTaskListFiltersProps {
    filterApplyHandler: (values: AdvocateTaskListFiltersType) => void;
    currentUser: CurrentUser | undefined;
}

const AdvocateTaskListFilters: React.FC<AdvocateTaskListFiltersProps> = ({
    filterApplyHandler,
    currentUser,
}) => {
    const [filterTestData, setFilterTestData] = useState<boolean>(
        sessionStorage.getItem('HAfilterTestData') === 'true' ?? false,
    );
    const { data: advocateTaskTypes } = useFetchAdvocateTaskTypesQueryQuery();
    const { data: advocates } = useFetchAdvocatesForAdvocateTaskListPageQueryQuery({
        variables: {
            permission: lowercaseFirstLetter(Target.IsHealthAdvocate),
        },
    });
    const { data: users } = useFetchUsersForAdvocateTaskListPageQueryQuery();
    const [filterImportance, setFilterImportance] = useState<number[]>([]);
    const { control, reset, handleSubmit, setValue, watch } = useForm<AdvocateTaskListFiltersType>(
        {},
    );

    useEffect(() => {
        reset({
            assignedTo: currentUser?.id,
        });
    }, [currentUser, reset]);

    const filterTestDataCheckboxHandler = () => {
        setFilterTestData(!filterTestData);
        setValue('patients', []);
        setValue('filterTestData', !filterTestData);
    };
    const onSubmit = (values: any) => {
        const filters = { ...values, filterTestData };
        filterApplyHandler(filters);
    };

    const advocateList = () => {
        const data = _.sortBy(advocates?.getUsersByPermission, 'name');
        return [
            { id: NOT_APPLICABLE, name: 'All Tasks' },
            { id: UNASSIGNED, name: 'Unassigned' },
        ].concat(data.map(a => ({ id: a?.id || '', name: a?.name || '' })));
    };
    return (
        <Box sx={{ minWidth: 150, maxWidth: 250 }}>
            <Controller
                name="assignedTo"
                defaultValue=""
                control={control}
                render={() => (
                    <Autocomplete
                        options={advocateList()}
                        getOptionLabel={option =>
                            advocateList().find(i => i?.id === option?.id)?.name ?? ''
                        }
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        value={advocateList().find(i => i?.id === watch('assignedTo')) ?? null}
                        onChange={(event, value) => {
                            if (value?.id === NOT_APPLICABLE) {
                                setValue('assignedTo', NOT_APPLICABLE);
                            } else if (value?.id === UNASSIGNED) {
                                setValue('assignedTo', UNASSIGNED);
                            } else {
                                setValue('assignedTo', value?.id);
                            }
                        }}
                        renderInput={params => (
                            <TextField
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...params}
                                label="Assigned to"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                )}
            />
            <FormControl variant="outlined" fullWidth style={{ padding: 0 }}>
                <AutocompleteWithRecordOptions
                    options={
                        _.sortBy(
                            currentUser?.patients
                                .filter(i =>
                                    currentUser.currentOrganizations.filter(
                                        c => c.id === i.practice.id,
                                    ),
                                )
                                .filter(i => i.isTestData === filterTestData),
                            'fullName',
                        ) ?? []
                    }
                    valueKey="id"
                    labelKey="fullName"
                    name="patients"
                    label="Patients"
                    placeholder="Select patients..."
                    control={control}
                />
            </FormControl>
            <Controller
                name="label"
                defaultValue=""
                control={control}
                render={() => (
                    <TextField
                        variant="outlined"
                        onChange={event => setValue('label', event.target.value)}
                        name="label"
                        label="Search by title"
                        type="text"
                        fullWidth
                    />
                )}
            />
            <Controller
                name="description"
                defaultValue=""
                control={control}
                render={() => (
                    <TextField
                        variant="outlined"
                        onChange={event => setValue('description', event.target.value)}
                        name="description"
                        label="Search by description"
                        type="text"
                        fullWidth
                    />
                )}
            />
            <FormControl variant="outlined" fullWidth>
                <ReactHookFormSelect
                    style={{ padding: 0 }}
                    label="Status"
                    defaultValue={AdvocateTaskTypeStatus.Open}
                    control={control}
                    name="status"
                    variant="outlined"
                >
                    <MenuItem value={AdvocateTaskTypeStatus.Open}>Open</MenuItem>
                    <MenuItem value={AdvocateTaskTypeStatus.Closed}>Closed</MenuItem>
                </ReactHookFormSelect>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
                <ReactHookFormSelect
                    style={{ padding: 0 }}
                    label="Due"
                    defaultValue=""
                    control={control}
                    name="due"
                    variant="outlined"
                >
                    {DueFilterConfig.map(({ label, value }) => (
                        <MenuItem value={value} key={value}>
                            {label}
                        </MenuItem>
                    ))}
                </ReactHookFormSelect>
            </FormControl>
            {watch('due') === 'custom' ? (
                <Controller
                    name="customDue"
                    defaultValue={undefined}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                        let date = new Date();
                        if (value) date = new Date(value);
                        return (
                            <FormControl variant="outlined" fullWidth>
                                <DesktopDatePicker
                                    label="Custom Date"
                                    value={date}
                                    onChange={onChange}
                                />
                            </FormControl>
                        );
                    }}
                />
            ) : (
                ''
            )}
            <FormControl variant="outlined" fullWidth>
                <Typography gutterBottom>Filter Importance</Typography>
                <Controller
                    name="importance"
                    defaultValue={[]}
                    control={control}
                    render={() => (
                        <>
                            {Priorities.map(priority => {
                                return (
                                    <FormControlLabel
                                        key={priority.toString()}
                                        label={`Priority ${priority}`}
                                        control={
                                            <Checkbox
                                                value={priority}
                                                checked={filterImportance.includes(priority)}
                                                onChange={event => {
                                                    const { checked, value } = event.target;
                                                    const numericValue = Number(value);
                                                    let filterArray = [...filterImportance];
                                                    if (
                                                        checked &&
                                                        !filterImportance.includes(numericValue)
                                                    ) {
                                                        setFilterImportance(fi => [
                                                            ...fi,
                                                            numericValue,
                                                        ]);
                                                        filterArray.push(numericValue);
                                                    } else if (!checked) {
                                                        setFilterImportance(fi =>
                                                            fi.filter(p => p !== numericValue),
                                                        );
                                                        filterArray = filterArray.filter(
                                                            p => p !== numericValue,
                                                        );
                                                    }
                                                    setValue('importance', filterArray);
                                                }}
                                            />
                                        }
                                    />
                                );
                            })}
                        </>
                    )}
                />
            </FormControl>
            <FormControl variant="outlined" fullWidth>
                <ReactHookFormSelect
                    style={{ padding: 0 }}
                    label="Created By"
                    defaultValue=""
                    control={control}
                    name="createdBy"
                    variant="outlined"
                >
                    {users?.usersV2.results.map(({ name, id }) => (
                        <MenuItem value={id} key={id}>
                            {name}
                        </MenuItem>
                    ))}
                    <MenuItem value="">All</MenuItem>
                </ReactHookFormSelect>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
                <ReactHookFormSelect
                    style={{ padding: 0 }}
                    label="Type"
                    defaultValue=""
                    control={control}
                    name="type"
                    variant="outlined"
                >
                    {advocateTaskTypes?.advocateTaskTypesV2.results.map(({ label, id }) => (
                        <MenuItem value={id} key={id}>
                            {label}
                        </MenuItem>
                    ))}
                    <MenuItem value="">All</MenuItem>
                </ReactHookFormSelect>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
                <FormControlLabel
                    name="filterTestData"
                    control={
                        <Checkbox
                            name="filterTestData"
                            checked={filterTestData}
                            onChange={filterTestDataCheckboxHandler}
                        />
                    }
                    label="Show Test Data"
                />
            </FormControl>
            <FormControl variant="outlined" fullWidth>
                <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                    Apply
                </Button>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
                <Button variant="outlined" onClick={() => reset(emptyFilters)}>
                    Clear Filters
                </Button>
            </FormControl>
        </Box>
    );
};
export default AdvocateTaskListFilters;
