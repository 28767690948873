import React from 'react';
import { Grid, Button } from '@mui/material';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import useStyles from '../styles';

type ButtonProps = {
    label: string;
    handler: () => void;
    disabled?: boolean;
    loading?: boolean;
};

type ButtonContainerProps = {
    nextButton: ButtonProps;
    prevButton?: ButtonProps;
    isMobileView: boolean;
};

const ButtonContainer: React.FC<ButtonContainerProps> = props => {
    const { nextButton, prevButton, isMobileView } = props;
    const { classes } = useStyles();
    return (
        <Grid item xs={12} className={classes.buttonContainer}>
            <Grid
                container
                justifyContent={isMobileView ? 'center' : 'flex-start'}
                flexDirection={isMobileView ? 'column' : 'row-reverse'}
                spacing={isMobileView ? 0 : 2}
                rowSpacing={isMobileView ? 2 : 0}
            >
                <Grid item>
                    <AsyncActionButton loading={!!nextButton.loading}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={nextButton.handler}
                            size={isMobileView ? 'small' : 'large'}
                            fullWidth
                            disabled={nextButton.disabled}
                        >
                            {nextButton.label}
                        </Button>
                    </AsyncActionButton>
                </Grid>
                {prevButton ? (
                    <Grid item>
                        <Button
                            variant={isMobileView ? 'outlined' : 'text'}
                            color="primary"
                            onClick={prevButton.handler}
                            size={isMobileView ? 'small' : 'large'}
                            fullWidth
                        >
                            {prevButton.label}
                        </Button>
                    </Grid>
                ) : null}
            </Grid>
        </Grid>
    );
};

export default ButtonContainer;
