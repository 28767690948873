import * as Yup from 'yup';

const schema = Yup.object().shape({
    label: Yup.object().shape({
        en: Yup.string().required('English Label is required'),
        es: Yup.string(),
    }),
    type: Yup.string().required('Type is required'),
    targetDateProfileDefId: Yup.string().when('type', ([type], schema) => {
        return type === 'profile' ? schema.required('Profile definition is required') : schema;
    }),
    fixedTargetDate: Yup.date().when('type', ([type], schema) => {
        return type === 'fixed' ? schema.required('Fixed date is required') : schema;
    }),
    description: Yup.object().shape({
        en: Yup.string().required('English Description is required'),
        es: Yup.string(),
    }),
});

export default schema;
