import React, { useMemo } from 'react';
import moment from 'moment';
import { Link as RouterLink, useParams } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import startCase from 'lodash/startCase';
import Loading from '~/components/Loading/Loading';
import { Breadcrumbs, Grid, IconButton, Link, Tooltip, Typography, Divider } from '@mui/material';
import {
    InfoOutlined,
    PaidOutlined,
    CircleOutlined,
    AccessTimeOutlined,
} from '@mui/icons-material';
import {
    AffiliateCareClaimsInvoicesV2Query,
    AlertSeverity,
    useAffiliateCareClaimsInvoicesV2Query,
    OrderByDirectionEnum,
} from '~/schemaTypes';
import MaterialTable from '@material-table/core';
import { TriggerGlobalAlert } from '~/state';
import useAffiliateCareView from '~/hooks/useAffiliateCareView';
import InfoBox from './InfoBox';

type RouteParams = {
    id: string;
};

const Invoice = () => {
    const { id } = useParams<RouteParams>();
    const { isAffiliateView } = useAffiliateCareView();
    const invoicesPathname = isAffiliateView
        ? '/affiliate-care/invoices'
        : '/app-config/affiliate-invoices';
    const patientPathname = isAffiliateView ? '/affiliate-care/patients' : '/portal/patients';
    const { data, loading } = useAffiliateCareClaimsInvoicesV2Query({
        variables: {
            input: {
                filter: {
                    fields: {
                        affiliateInvoiceId: id,
                    },
                },
                orderBy: {
                    field: 'createdAt',
                    order: OrderByDirectionEnum.Desc,
                },
            },
        },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const invoice = useMemo(() => data?.submittedClaimsV2.results[0]?.affiliateInvoice, [data]);

    if (loading) {
        return <Loading subtitle="Loading invoice data" />;
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Breadcrumbs>
                    <Link
                        to={invoicesPathname}
                        component={RouterLink}
                        underline="hover"
                        color="inherit"
                    >
                        <Typography variant="h6">Invoices</Typography>
                    </Link>
                    <Typography>Invoice {id}</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12} container columnSpacing={4}>
                <Grid item xs="auto">
                    <InfoBox
                        Icon={CircleOutlined}
                        infoText={startCase(invoice?.paymentStatus || '')}
                        infoTitle="Payment Status"
                    />
                </Grid>
                <Grid item xs="auto">
                    <InfoBox
                        Icon={PaidOutlined}
                        infoText={`$${invoice?.paymentTotal}`}
                        infoTitle="Payment Total"
                    />
                </Grid>
                <Grid item xs="auto">
                    <InfoBox
                        Icon={AccessTimeOutlined}
                        infoText={moment(invoice?.createdAt)
                            .local()
                            .format('MM/DD/YYYY, hh:mm:ss A')}
                        infoTitle="Created At"
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <MaterialTable<
                    AffiliateCareClaimsInvoicesV2Query['submittedClaimsV2']['results'][0]
                >
                    columns={[
                        {
                            title: 'Claim ID',
                            field: 'id',
                            render: ({ id }) => (
                                <Tooltip title={id}>
                                    <IconButton
                                        onClick={() => {
                                            copy(`${id}`);
                                            TriggerGlobalAlert({
                                                message: 'Claim Id Copied to Clipboard',
                                                severity: AlertSeverity.Success,
                                            });
                                        }}
                                        size="large"
                                    >
                                        <InfoOutlined />
                                    </IconButton>
                                </Tooltip>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Date Submitted',
                            field: 'createdAt',
                            render: ({ createdAt }) => (
                                <Typography>{moment(createdAt).format('MM/DD/YYYY')}</Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Patient First Name',
                            field: 'patient',
                            render: ({ patient }) =>
                                patient?.firstName && (
                                    <Link
                                        to={`${patientPathname}/${patient.id}`}
                                        component={RouterLink}
                                    >
                                        {patient.firstName}
                                    </Link>
                                ),
                            sorting: false,
                        },
                        {
                            title: 'Patient Last Name',
                            field: 'patient',
                            render: ({ patient }) =>
                                patient?.lastName && (
                                    <Link
                                        to={`${patientPathname}/${patient.id}`}
                                        component={RouterLink}
                                    >
                                        {patient.lastName}
                                    </Link>
                                ),
                            sorting: false,
                        },
                        {
                            title: 'Visit Date',
                            field: 'ServiceFrom_1',
                            render: ({ ServiceFrom_1: visitDate }) =>
                                visitDate && (
                                    <Typography data-test={visitDate}>
                                        {moment(visitDate, 'M/D/YYYY').format('MM-DD-YYYY')}
                                    </Typography>
                                ),
                            sorting: false,
                        },
                        {
                            title: 'Service Type',
                            field: 'service_type',
                            render: ({ service_type: serviceType }) => (
                                <Typography>{serviceType}</Typography>
                            ),
                            sorting: false,
                        },
                    ]}
                    data={
                        data?.submittedClaimsV2.results.map((o: any) => ({
                            ...o,
                        })) || []
                    }
                    options={{
                        search: false,
                        paging: false,
                        showTitle: false,
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default Invoice;
