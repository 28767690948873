import {
    Button,
    DialogActions,
    DialogContent,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { Dispatch, SetStateAction, useMemo, useRef } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import FormBuilder, {
    FormBuilderFormField,
    FormBuilderRef,
} from '~/components/FormBuilder/FormBuilder';
import { FormBuilderInputType } from '~/components/FormBuilder/FormInput/FormInput';
import Loading from '~/components/Loading/Loading';
import { lowercaseFirstLetter } from '~/helpers/lowercaseFirstLetter';
import {
    ConfigValueType,
    FetchConfigAppForConfigAppSummaryPageDocument,
    FetchConfigAppForConfigAppSummaryPageQuery,
    useFetchExpressionForAppValuesModalQuery,
    useUpsertAppValueForAppDefMutation,
} from '~/schemaTypes';
import { AppValueEnum } from '~/selectors';

type ConfigValueDef = NonNullable<
    FetchConfigAppForConfigAppSummaryPageQuery['amsAppByBundleId']
>['defaultValues'][0];
type ConfigValueDefDefault = NonNullable<ConfigValueDef['defaultValue']>;
type ConfigValue = NonNullable<
    FetchConfigAppForConfigAppSummaryPageQuery['amsAppByBundleId']
>['values'][0];
type ConfigValueValue = NonNullable<ConfigValue['value']>;

const useStyles = makeStyles()({
    root: {},
});

type ConfigValueFormInput = {
    value: any;
    nullValue: boolean;
};

type AppSummaryValueModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    configValueDef: ConfigValueDef;
    configValue?: ConfigValue | null;
    appBundleId: string;
};

const AppSummaryValueModal: React.FC<AppSummaryValueModalProps> = ({
    setOpen,
    configValueDef,
    configValue,
    appBundleId,
}) => {
    const { classes } = useStyles();
    const formRef = useRef<FormBuilderRef>(null);

    const { data: expressionsData, loading: expressionsLoading } =
        useFetchExpressionForAppValuesModalQuery();

    const [upsertValue, { loading: upsertValueLoading }] = useUpsertAppValueForAppDefMutation({
        onCompleted: data => {
            if (data.upsertConfigValueByValueDefId?.success) {
                setOpen(false);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: FetchConfigAppForConfigAppSummaryPageDocument,
                variables: { input: { bundleId: appBundleId } },
            },
        ],
    });

    const parseValue = (value: ConfigValueFormInput['value']) => {
        if (configValueDef.valueType === ConfigValueType.Num) {
            return parseInt(value, 10);
        }

        if (configValueDef.valueType === ConfigValueType.NumArr) {
            return value.map((valueArrayItem: string) => parseInt(valueArrayItem, 10));
        }

        return value;
    };

    const handleSubmit = (data: ConfigValueFormInput) => {
        if (configValueDef) {
            const value = data.nullValue
                ? { [lowercaseFirstLetter(configValueDef.valueType)]: null }
                : { [lowercaseFirstLetter(configValueDef.valueType)]: parseValue(data.value) };

            upsertValue({
                variables: {
                    input: {
                        data: {
                            name: configValueDef.name,
                            value,
                            appBundleId,
                            valueDefId: configValueDef.id,
                        },
                    },
                },
            });
        }
    };

    const defValueToInputType = useMemo<
        Record<
            ConfigValueType,
            { type: FormBuilderInputType; array: boolean; transformer: (v: any) => any }
        >
    >(
        () => ({
            [ConfigValueType.Str]: {
                type: 'string',
                array: false,
                transformer: value => ({
                    [lowercaseFirstLetter(configValueDef.valueType)]: value,
                }),
            },
            [ConfigValueType.StrArr]: {
                type: 'string',
                array: true,
                transformer: value => value,
            },
            [ConfigValueType.Bool]: {
                type: 'boolean',
                array: false,
                transformer: value => ({
                    [lowercaseFirstLetter(configValueDef.valueType)]: value,
                }),
            },
            [ConfigValueType.BoolArr]: {
                type: 'boolean',
                array: true,
                transformer: value => value,
            },
            [ConfigValueType.Num]: {
                type: 'number',
                array: false,
                transformer: value => ({
                    [lowercaseFirstLetter(configValueDef.valueType)]: value,
                }),
            },
            [ConfigValueType.NumArr]: {
                type: 'number',
                array: true,
                transformer: value => value,
            },
            [ConfigValueType.LocalizedStr]: {
                type: 'object',
                array: false,
                transformer: value => ({
                    [lowercaseFirstLetter(configValueDef.valueType)]: value,
                }),
            },
            [ConfigValueType.LocalizedStrArr]: {
                type: 'object',
                array: true,
                transformer: value => value,
            },
        }),
        [configValueDef.valueType],
    );

    if (expressionsLoading || upsertValueLoading) {
        return <Loading />;
    }

    const formBuilderFields: FormBuilderFormField[] = [
        {
            label: `Value (${configValueDef.valueType})`,
            name: 'value',
            type: defValueToInputType[configValueDef.valueType].type,
            array: defValueToInputType[configValueDef.valueType].array,
            defaultValue: configValue
                ? configValue.value[
                      lowercaseFirstLetter(configValueDef.valueType) as keyof ConfigValueValue
                  ]
                : configValueDef.defaultValue[
                      lowercaseFirstLetter(configValueDef.valueType) as keyof ConfigValueDefDefault
                  ],
        },
        {
            label: 'Reset Value to null',
            name: 'nullValue',
            type: 'boolean',
            defaultValue:
                (configValue != null &&
                    configValue?.value[
                        lowercaseFirstLetter(configValueDef.valueType) as keyof ConfigValueValue
                    ] == null) ||
                false,
        },
    ];

    const formBuilderData: ConfigValueFormInput = {
        value: formBuilderFields[0].defaultValue,
        nullValue: formBuilderFields[1].defaultValue,
    };

    return (
        <div className={classes.root} data-test={AppValueEnum.APP_VALUES_MODAL}>
            <DialogTitleWithClose onClose={() => setOpen(false)} id="dialogTitle">
                {configValueDef ? 'Edit Value' : 'Create Value'}
            </DialogTitleWithClose>
            <DialogContent dividers>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <FormBuilder<ConfigValueFormInput>
                            data={formBuilderData}
                            ref={formRef}
                            handleSubmit={data => {
                                handleSubmit(data);
                            }}
                            fields={formBuilderFields}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Expression Overrides:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Override Value"
                            fullWidth
                            data-test={AppValueEnum.MODAL_OVERRIDE_VALUE}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            id="SelectOverrideForValue"
                            label="Override Expression"
                            select
                            fullWidth
                            data-test={AppValueEnum.APP_VALUES_MODAL_OVERRIDE_EXPRESSION}
                        >
                            {expressionsData?.configExpressions.map(({ id, name }) => (
                                <MenuItem value={id} data-test={name}>
                                    {name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                        formRef.current?.triggerSubmit();
                    }}
                    data-test={AppValueEnum.APP_VALUES_MODAL_SAVE}
                >
                    Save
                </Button>
            </DialogActions>
        </div>
    );
};

export default AppSummaryValueModal;
