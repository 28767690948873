import React from 'react';
import { Editor } from '@tiptap/react';
import {
    ContentType,
    SimpleContent,
    useContentDirectorysV2ForRichTextEditorQuery,
    useSimpleContentsForRichTextEditorMenuBarImageDialogQuery,
} from '~/schemaTypes';
import Loading from '~/components/Loading/Loading';
import { Button, Dialog, DialogTitle, TextField } from '@mui/material';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import NonImageContentPicker from '~/components/NonImageContentPicker/NonImageContentPicker';
import { excludeContentTypes } from '~/components/NonImageContentPicker/helpers/excludeContentTypes';
import simpleContentToLink from '~/helpers/SimpleContentHelpers/simpleContentToLink';

type LinkSelectFormInput = {
    label?: string;
    simpleContent?: {
        id: string;
        name: string;
    };
};

type RichTextEditorMenuBarLinkDialogProps = {
    editor: Editor;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    label: string;
};

const LinkSelectSchema = Yup.object().shape({
    label: Yup.string(),
    simpleContent: Yup.object().shape({
        id: Yup.string(),
        name: Yup.string(),
        content: Yup.string(),
    }),
});

const RichTextEditorMenuBarLinkDialog = ({
    editor,
    open,
    setOpen,
    label,
}: RichTextEditorMenuBarLinkDialogProps) => {
    const { register, setValue, reset, handleSubmit } = useForm<LinkSelectFormInput>({
        resolver: yupResolver(LinkSelectSchema as any),
    });

    const { data: links, loading } = useSimpleContentsForRichTextEditorMenuBarImageDialogQuery({});

    const { data: directory } = useContentDirectorysV2ForRichTextEditorQuery();

    const handleClose = () => {
        setOpen(false);
        reset();
    };

    const onSubmit = (data: LinkSelectFormInput) => {
        if (!data.simpleContent) return;
        if (!data.simpleContent?.id) {
            editor.chain().focus().extendMarkRange('link').unsetLink().run();
        } else {
            const selectedSimpleContent = links?.simpleContentsV2.results.find(
                link => link.id === data.simpleContent?.id,
            );
            if (!selectedSimpleContent) return;
            let contentDirectory;
            if (
                selectedSimpleContent.contentDirectoryId &&
                selectedSimpleContent.contentType === ContentType.ContentDirectoryLink
            ) {
                contentDirectory = directory?.contentDirectorysV2?.results?.find(
                    contentDirectory =>
                        contentDirectory.id === selectedSimpleContent.contentDirectoryId,
                );
            }
            const link = simpleContentToLink({
                content: selectedSimpleContent as SimpleContent,
                ...(data.label && { overrideLabel: data.label }),
                ...(contentDirectory?.displayStyle && {
                    displayStyle: contentDirectory.displayStyle,
                }),
            });
            if (label === data.label) {
                editor.chain().focus().extendMarkRange('link').insertContent(link).run();
            } else {
                editor.chain().focus().insertContent(link).run();
            }
        }
        handleClose();
    };

    const handleSelectLinkContent = (linkContentId: string) => {
        const linkContent = links?.simpleContentsV2.results.find(link => link.id === linkContentId);
        if (!linkContent) return;
        setValue('simpleContent', {
            id: linkContent.id,
            name: linkContent.name,
        });
    };

    if (loading) {
        return <Loading subtitle="Loading links..." />;
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Select a link</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    {...register('label')}
                    fullWidth
                    label="Label"
                    variant="outlined"
                    defaultValue={label}
                />
                <NonImageContentPicker
                    setSelectedContentId={handleSelectLinkContent}
                    allowedTypes={excludeContentTypes([ContentType.Image])}
                    required={false}
                />
            </form>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: '0 10px 10px 0',
                    gap: '10px',
                }}
            >
                <Button size="medium" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="contained" size="medium" onClick={handleSubmit(onSubmit)}>
                    Select
                </Button>
            </div>
        </Dialog>
    );
};

export default RichTextEditorMenuBarLinkDialog;
