import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import React, { useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { FetchProfileDefsForVbcPatientModalQuery, UserProfileValueType } from '~/schemaTypes';
import useCareTeamMembers, { CareTeamMember } from '../../../../hooks/useCareTeamMembers';

type AdditionalPatientFields =
    FetchProfileDefsForVbcPatientModalQuery['additionalPatientFieldsProfileDefs'];
type ProfileDefinitionsData = AdditionalPatientFields['profileDefs'];

export interface ProfileVariableInputsProps {
    additionalPatientFields: AdditionalPatientFields;
    profileVariablesLoading: boolean;
    bundleId: string | null;
    useForm: UseFormReturn;
}
export const ProfileVariableInputs: React.FC<ProfileVariableInputsProps> = ({
    additionalPatientFields,
    profileVariablesLoading,
    bundleId,
    useForm,
}) => {
    const careTeams = useMemo(
        () => additionalPatientFields.careTeamMembers,
        [additionalPatientFields.careTeamMembers],
    );
    const { careTeamsMembersByTypes, isCareTeamProfileDefinition } = useCareTeamMembers({
        profileDefinitions: additionalPatientFields.profileDefs,
        bundleId,
        careTeams,
    });
    const { register, control, getValues } = useForm;

    const renderProfileVariableFields = (profileDefinitionsData: ProfileDefinitionsData) => {
        if (!profileDefinitionsData) return null;
        return profileDefinitionsData.map(profileDef => {
            const isCareTeamProfileDef = isCareTeamProfileDefinition(profileDef);

            if (isCareTeamProfileDef) {
                const members =
                    careTeamsMembersByTypes.find(
                        item => item.careTeamType?.profileDefId === profileDef.id,
                    )?.careTeamMembers ?? [];
                return (
                    <Grid key={profileDef?.id} item xs={12}>
                        <Controller
                            control={control}
                            name={profileDef.name}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete<CareTeamMember, false>
                                    size="small"
                                    onChange={(_, val) => {
                                        onChange(val?.id);
                                    }}
                                    getOptionLabel={selected => selected?.label?.en ?? ''}
                                    isOptionEqualToValue={(option, val) => option?.id === val?.id}
                                    options={members}
                                    value={members.find(member => member.id === value) ?? null}
                                    renderInput={params => (
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...params}
                                            placeholder={`Select ${profileDef.name}`}
                                            label={profileDef.name}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                );
            }
            switch (profileDef.valueType) {
                case UserProfileValueType.Str:
                    return (
                        <Grid key={profileDef?.id} item xs={6}>
                            <TextField
                                variant="outlined"
                                label={profileDef.label?.en}
                                id={profileDef.name}
                                type="text"
                                margin="dense"
                                fullWidth
                                {...register('profileDef.name')}
                            />
                        </Grid>
                    );
                case UserProfileValueType.Date:
                    return (
                        <Grid key={profileDef?.id} item xs={6}>
                            <Controller
                                name={profileDef.name}
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                    let date = new Date();
                                    if (value) date = new Date(value);
                                    return (
                                        <DesktopDatePicker
                                            label={profileDef.label?.en}
                                            value={date}
                                            onChange={onChange}
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    );
                case UserProfileValueType.Bool:
                    return (
                        <FormControl variant="outlined" key={profileDef?.id}>
                            <FormControlLabel
                                control={
                                    <Controller
                                        name={profileDef.name}
                                        defaultValue={false}
                                        control={control}
                                        render={({ field }) => (
                                            <Checkbox
                                                color="primary"
                                                checked={field.value}
                                                onChange={e => field.onChange(e.target.checked)}
                                            />
                                        )}
                                    />
                                }
                                label={profileDef.label?.en}
                            />
                        </FormControl>
                    );
                case UserProfileValueType.Num:
                    return (
                        <Grid key={profileDef?.id} item xs={6}>
                            <Controller
                                control={control}
                                name={profileDef.name}
                                render={({ field: { onChange } }) => (
                                    <TextField
                                        variant="outlined"
                                        label={profileDef.label?.en}
                                        id={profileDef.name}
                                        type="number"
                                        margin="dense"
                                        fullWidth
                                        {...register('riskIndicators.other.comment')}
                                        InputProps={{
                                            inputProps: { min: 0, step: 1 },
                                        }}
                                        defaultValue={0}
                                        onChange={event => {
                                            onChange(Number(event.target.value));
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    );
                case UserProfileValueType.Choice:
                    return (
                        <Grid key={profileDef?.id} item xs={12}>
                            <OutlinedSection
                                outlineColor="secondary"
                                title={profileDef?.label?.en ?? ''}
                            >
                                <Controller
                                    control={control}
                                    name={profileDef.name}
                                    render={({ field: { value, onChange } }) => (
                                        <FormControl variant="outlined" fullWidth>
                                            <RadioGroup value={value} onChange={onChange}>
                                                <Grid container spacing={2} direction="column">
                                                    {profileDef?.choices?.map(choice => {
                                                        return (
                                                            <Grid item key={choice?.id}>
                                                                <FormControlLabel
                                                                    value={choice?.id}
                                                                    control={<Radio />}
                                                                    label={choice?.label?.en}
                                                                />
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </RadioGroup>
                                        </FormControl>
                                    )}
                                />
                            </OutlinedSection>
                        </Grid>
                    );
                case UserProfileValueType.Choices:
                    return (
                        <Grid key={profileDef?.id} item xs={12}>
                            <OutlinedSection
                                outlineColor="secondary"
                                title={profileDef?.label?.en ?? ''}
                            >
                                <FormControl variant="outlined" fullWidth>
                                    <Grid container spacing={2} direction="column">
                                        {profileDef.choices?.map(choice => (
                                            <Controller
                                                key={choice?.id}
                                                control={control}
                                                name={profileDef.name}
                                                render={({ field: { onChange, value } }) => (
                                                    <Grid item>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={value?.includes(
                                                                        choice?.id,
                                                                    )}
                                                                    value={choice?.id}
                                                                    onChange={({
                                                                        target: { checked, value },
                                                                    }) => {
                                                                        const currentChoiceIds =
                                                                            getValues(
                                                                                profileDef.name,
                                                                            ) ?? [];
                                                                        const currentChoiceIdsSet =
                                                                            new Set([
                                                                                ...currentChoiceIds,
                                                                            ]);

                                                                        currentChoiceIdsSet[
                                                                            checked
                                                                                ? 'add'
                                                                                : 'delete'
                                                                        ](value);

                                                                        onChange(
                                                                            Array.from(
                                                                                currentChoiceIdsSet,
                                                                            ),
                                                                        );
                                                                    }}
                                                                />
                                                            }
                                                            label={choice?.label?.en}
                                                        />
                                                    </Grid>
                                                )}
                                            />
                                        ))}
                                    </Grid>
                                </FormControl>
                            </OutlinedSection>
                        </Grid>
                    );
                default:
                    return null;
            }
        });
    };
    return (
        <div>
            {profileVariablesLoading ? (
                <Loading />
            ) : (
                renderProfileVariableFields(additionalPatientFields.profileDefs)
            )}
        </div>
    );
};

export default ProfileVariableInputs;
