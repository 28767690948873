import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, Grid, Link, Typography } from '@mui/material';
import { Chat, Phone, Save } from '@mui/icons-material';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { displayBirthday } from '~/helpers';
import {
    FetchAdvocateTaskForAdvocateTaskPageQuery,
    useCreateChatRoomForAdvocateTaskPageMutation,
    useUpdateChatRoomForAdvocateTaskPageMutation,
} from '~/schemaTypes';
import RichTextEditor from '~/components/RichTextEditor/RichTextEditor';
import parse from 'html-react-parser';
import { useStyles } from './styles';
import { DESCRIPTION_SCHEMA } from './yupSchema';

const HEALTH_ADVOCATE_CARE_TEAM_MEMBER_TYPE_ID = '666757d763090369ea9a754f';

type Patient = NonNullable<
    NonNullable<FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']>['patient']
>;

type ChatRoom = NonNullable<
    NonNullable<FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']>['patient']
>['chatRoom'];

type DescriptionInput = {
    description: string;
};

interface TaskInfoProps {
    userId: string;
    patient: Patient;
    chatRoom: ChatRoom;
    description: string;
    onDescriptionUpdated: (description: string) => void;
}

const TaskInfo: React.FC<TaskInfoProps> = ({
    userId,
    chatRoom,
    patient,
    description,
    onDescriptionUpdated,
}) => {
    const { classes } = useStyles();
    const history = useNavigate();
    const [isEditDescription, setIsEditDescription] = useState<boolean>(false);
    const { reset, handleSubmit, setValue } = useForm<DescriptionInput>({
        resolver: yupResolver(DESCRIPTION_SCHEMA as any),
    });

    const [createChatRoom] = useCreateChatRoomForAdvocateTaskPageMutation({
        awaitRefetchQueries: true,
        onCompleted: ({ createChatRoom }) => {
            if (createChatRoom && createChatRoom.resourceCreated) {
                history(`/portal/message-center/${createChatRoom?.resourceCreated.id}`);
            }
        },
    });

    const [updateChatRoom] = useUpdateChatRoomForAdvocateTaskPageMutation({
        awaitRefetchQueries: true,
        onCompleted: () => {
            history(`/portal/message-center/${chatRoom.id}`);
        },
    });

    const onSubmit: SubmitHandler<DescriptionInput> = ({ description }) => {
        onDescriptionUpdated(description);
        setIsEditDescription(false);
    };

    const handleCancel = () => {
        setIsEditDescription(false);
        reset();
    };

    const handleOpenChatRoom = () => {
        if (chatRoom.id && userId) {
            const { participants } = chatRoom;
            const isUserExistedInChatRoom = participants.filter(user => user.id === userId);

            if (isUserExistedInChatRoom.length) {
                history(`/portal/message-center/${chatRoom.id}`);
            } else if (userId) {
                const updatedParticipant = participants.map(participant => participant.id);
                const participantWithCurrentUser = [...updatedParticipant, userId];
                updateChatRoom({
                    variables: {
                        input: {
                            id: chatRoom.id,
                            data: {
                                participants: participantWithCurrentUser,
                            },
                        },
                    },
                });
            }
        } else if (patient.id && userId) {
            createChatRoom({
                variables: {
                    input: {
                        patientId: patient.id,
                        participantIds: [userId],
                        organizationId: patient.practice.id,
                        careTeamMemberTypeId: HEALTH_ADVOCATE_CARE_TEAM_MEMBER_TYPE_ID,
                    },
                },
            });
        }
    };

    return (
        <div>
            <Grid item xs={12} className={classes.patientSection}>
                <Typography variant="subtitle1" className={classes.subtitle}>
                    Patient
                </Typography>
                <Grid container item alignItems="flex-start" spacing={1}>
                    <Grid item>
                        <Link
                            to={`/portal/patients/${patient.id}`}
                            component={RouterLink}
                            className={classes.fullName}
                            underline="hover"
                        >
                            <Typography>{patient.fullName}</Typography>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Chat
                            className={classes.chat}
                            fontSize="small"
                            onClick={handleOpenChatRoom}
                        />
                    </Grid>
                    <Grid item>
                        <Phone fontSize="small" />
                    </Grid>
                    <Grid item>
                        <Typography>{patient.phoneNumber}</Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        DOB:{' '}
                        {patient?.birthDate &&
                            displayBirthday({
                                isoDateStr: patient.birthDate,
                            })}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        App Name: {patient.practice.brandingName}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1" className={classes.subtitle}>
                    Description
                </Typography>
                {isEditDescription ? (
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <RichTextEditor
                            onChange={value => setValue('description', value)}
                            placeholder="Description *"
                            initialValue={description}
                            label="Description *"
                        />
                        <DialogActions>
                            <Button
                                color="secondary"
                                variant="outlined"
                                size="small"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                startIcon={<Save />}
                                type="submit"
                                color="secondary"
                                variant="contained"
                                size="small"
                                onClick={handleSubmit(onSubmit)}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                ) : (
                    <Grid container item alignItems="center" wrap="nowrap">
                        <Grid item>
                            <Typography variant="body2">
                                <span>{parse(description)}</span>
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default TaskInfo;
