import { Typography, Tooltip, IconButton } from '@mui/material';
import { Warning, Restore } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { displayDateWithAbbrTimeZone } from '~/helpers/dateHelpers';
import tableIcons from '~/helpers/tableIcons';
import { useQueryParams } from '~/hooks';
import useUserPermissions from '~/hooks/useUserPermissions';
import {
    FetchMeasurementsForPatientMeasurementsPageQuery,
    MeasurementType,
    useFetchMeasurementsForPatientMeasurementsPageLazyQuery,
} from '~/schemaTypes';
import { TimePeriodFilter } from '~/views/Dashboard/Patients/Patient/PatientMeasurements/components/TimePeriodFilter';
import { Measurement } from '~/views/Dashboard/Patients/Patient/PatientMeasurements/PatientMeasurements';
import {
    SYMPTOMS,
    Symptom,
} from '~/views/ConfigDashboard/ConfigExpressions/ConfigExpression/ConfigExpression';
import { BloodPressurePropsInterface } from '../interfaces';
import { useStyles } from '../styles';

type BloodPressureMeasurement = NonNullable<
    NonNullable<
        NonNullable<
            NonNullable<
                FetchMeasurementsForPatientMeasurementsPageQuery['patient']
            >['measurements'][0]
        >['value']
    >['bloodPressure']
>;

const filterByType = (measurements?: Measurement[]) =>
    measurements?.filter(measurement => measurement.type === MeasurementType.BloodPressure) ?? [];

export const BloodPressureMeasurementTable: React.FC<BloodPressurePropsInterface> = ({
    data,
    isLoading,
    openHistory,
    setHistoryId,
}) => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const [query] = useQueryParams();
    const [search, setSearch] = useState<string>(query.get('_search') ?? '');
    const history = useNavigate();
    const location = useLocation();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    const { id } = useParams<{ id: string }>();

    const [fetchMeasurements, { data: measurements, loading: measurementsLoading }] =
        useFetchMeasurementsForPatientMeasurementsPageLazyQuery();

    const onChangePeriod = (datePeriod: Date | null) => {
        fetchMeasurements({
            variables: {
                patientInput: {
                    id,
                },
                measurementsInput: {
                    filter: {
                        createdAfter: datePeriod?.toISOString(),
                        fields: {
                            type: MeasurementType.BloodPressure,
                        },
                    },
                },
            },
        });
    };

    return (
        <div ref={tableRef}>
            <TimePeriodFilter onSelectPeriod={onChangePeriod} />
            <MaterialTable
                title="Measurements"
                isLoading={isLoading || measurementsLoading}
                icons={tableIcons as MaterialTableProps<any>['icons']}
                columns={[
                    {
                        title: 'Date of Test',
                        render: ({ takenDate, patient, timezoneName }) => (
                            <Typography>
                                {displayDateWithAbbrTimeZone({
                                    isoDateStr: takenDate,
                                    format: 'MM/DD/yyyy, hh:mm:ss A',
                                    timeZone:
                                        timezoneName ??
                                        patient?.practice.timezone ??
                                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                                })}
                            </Typography>
                        ),
                        customSort: (a, b) =>
                            new Date(a.takenDate).getTime() - new Date(b.takenDate).getTime(),
                        defaultSort: 'desc',
                    },
                    {
                        title: 'Date of Report',
                        render: ({ createdAt }) => (
                            <Typography>
                                {displayDateWithAbbrTimeZone({
                                    isoDateStr: createdAt,
                                    format: 'MM/DD/yyyy, hh:mm:ss A',
                                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                })}
                            </Typography>
                        ),
                        customSort: (a, b) =>
                            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
                        defaultSort: 'desc',
                    },
                    {
                        title: 'Entry',
                        render: ({ value, externalId, hasHistory }) => {
                            const displayBP = ({
                                systolic,
                                diastolic,
                            }: BloodPressureMeasurement) => (
                                <div className={classes.horizontalAlign}>
                                    <Typography>
                                        {systolic && diastolic && `${systolic}/${diastolic} mmHg`}
                                    </Typography>
                                    {hasHistory && (
                                        <Tooltip title="History">
                                            <IconButton
                                                onClick={() => {
                                                    setHistoryId(externalId);
                                                    openHistory(true);
                                                }}
                                            >
                                                <Restore />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            );
                            if (value?.bloodPressure) {
                                return displayBP(value.bloodPressure);
                            }
                            return <div />;
                        },
                    },
                    {
                        title: 'Pulse',
                        render: ({ value }) => {
                            const displayBP = ({ pulse }: BloodPressureMeasurement) => (
                                <Typography>{pulse && `${pulse} bpm`}</Typography>
                            );
                            if (value?.bloodPressure) {
                                return displayBP(value.bloodPressure);
                            }
                            return <div />;
                        },
                    },

                    {
                        title: 'Flagged',
                        render: ({ flagged }) => {
                            if (flagged) {
                                return <Warning />;
                            }
                            return <div />;
                        },
                        customSort: (a, b) => Number(a.flagged) - Number(b.flagged),
                    },
                    {
                        title: 'Symptoms',
                        render: ({ value }) => {
                            const symptoms =
                                value?.bloodPressure.symptoms?.map((s: Symptom) =>
                                    SYMPTOMS.get(s),
                                ) ?? [];
                            const symptomsOption = value?.bloodPressure.symptomsOption;
                            if (symptoms?.length > 0) {
                                return <Typography>{symptoms?.join(', ')}</Typography>;
                            }
                            if (symptomsOption) {
                                return <Typography>{symptomsOption}</Typography>;
                            }
                            return <div />;
                        },
                    },
                ]}
                data={
                    (measurements?.patient?.measurements
                        ? filterByType(_.cloneDeep(measurements?.patient?.measurements))
                        : _.cloneDeep(data)) ?? []
                }
                onSearchChange={searchText => {
                    setSearch(searchText);
                    history(`${location.pathname}${searchText && `?_search=${searchText}`}`);
                }}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                    searchText: search ?? '',
                    exportAllData: pagePermissions?.Measurements.Export,
                    grouping: true,
                }}
            />
        </div>
    );
};
