import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { format, toDate } from 'date-fns-tz';
import {
    DialogContent,
    Grid,
    Select,
    InputLabel,
    MenuItem,
    FormControl,
    Button,
    DialogActions,
    FormHelperText,
    Typography,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import DateInput from '~/components/DateInput/DateInput';
import { SelectedPatient, Claim, ServiceTypes } from './types';
import { ADD_PATIENT_TO_CLAIM_SCHEMA } from './yupSchema';

type AddPatientToClaimModalProps = {
    onClose: () => void;
    patient: SelectedPatient;
    addClaimHandler: (claim: Claim) => void;
};

const AddPatientToClaimModal: React.FC<AddPatientToClaimModalProps> = props => {
    const { onClose, patient, addClaimHandler } = props;
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<Claim>({
        resolver: yupResolver(ADD_PATIENT_TO_CLAIM_SCHEMA() as any),
        defaultValues: {
            lastName: patient.lastName,
            firstName: patient.firstName,
            birthDate: format(toDate(patient.birthDate.split('T')[0]), 'MM/dd/yyyy'),
            id: Date.now(),
            patientId: patient.id,
        },
    });
    const onSubmit = (data: Claim) => {
        addClaimHandler(data);
    };
    return (
        <>
            <DialogTitleWithClose id="add-patient-to-calims" onClose={onClose}>
                Add Patient Visit
            </DialogTitleWithClose>
            <DialogContent dividers>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Grid container justifyContent="flex-start" alignItems="center" rowSpacing={2}>
                        <Grid item xs={7} padding="10px">
                            <Typography variant="body1" color="primary">
                                Name:
                            </Typography>
                            <Typography variant="body1">
                                {patient.firstName} {patient.lastName}
                            </Typography>
                        </Grid>
                        <Grid item xs={7} padding="10px">
                            <Typography variant="body1" color="primary">
                                DOB:
                            </Typography>
                            <Typography variant="body1">
                                {format(toDate(patient.birthDate.split('T')[0]), 'MM/dd/yyyy')}
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Controller
                                name="dateOfService"
                                control={control}
                                render={({ field }) => (
                                    <DateInput
                                        label="Date Of Service"
                                        field={field}
                                        error={errors.dateOfService}
                                        inputProps={{
                                            fullWidth: true,
                                            InputLabelProps: {
                                                shrink: true,
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <Controller
                                control={control}
                                name="serviceType"
                                defaultValue=""
                                render={({ field: { onChange, value } }) => (
                                    <FormControl fullWidth error={!!errors.serviceType}>
                                        <InputLabel id="serviceType">Service Type</InputLabel>
                                        <Select
                                            value={value}
                                            fullWidth
                                            onChange={e => onChange(e.target.value)}
                                            error={!!errors.serviceType}
                                            labelId="serviceType"
                                        >
                                            <MenuItem
                                                key={ServiceTypes.Home}
                                                value={ServiceTypes.Home}
                                            >
                                                Home
                                            </MenuItem>
                                            <MenuItem
                                                key={ServiceTypes.Office}
                                                value={ServiceTypes.Office}
                                            >
                                                Office
                                            </MenuItem>
                                            <MenuItem
                                                key={ServiceTypes.Virtual}
                                                value={ServiceTypes.Virtual}
                                            >
                                                Virtual
                                            </MenuItem>
                                        </Select>
                                        <FormHelperText>
                                            {errors.serviceType?.message}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={() => handleSubmit(onSubmit)()}
                >
                    Add Visit
                </Button>
                <Button onClick={onClose} color="secondary" variant="outlined">
                    Cancel
                </Button>
            </DialogActions>
        </>
    );
};

export default AddPatientToClaimModal;
