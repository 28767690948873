import React from 'react';
import { Grid, TextField, Typography, Divider, Autocomplete } from '@mui/material';
import { Controller } from 'react-hook-form';
import DateInput from '~/components/DateInput/DateInput';
import { EMPTY_GENDER_VALUE, inputGenders, RelationshipToInsuredEnum, StepsProps } from '../types';
import FooterDivider from '../components/FooterDivider';
import ButtonContainer from '../components/ButtonContainer';
import SupportBlock from '../components/SupportBlock';

type PatientInfoProps = {
    stepHandler: () => void;
    setSelfSelected: (value: boolean) => void;
    stepLoading: boolean;
} & StepsProps;

const PatientInfo: React.FC<PatientInfoProps> = props => {
    const {
        stepLoading,
        setSelfSelected,
        register,
        errors,
        control,
        classes,
        stepHandler,
        isMobileView,
        setValue,
        getValues,
    } = props;
    return (
        <>
            <Grid item xs={12} className={classes.subHeader} paddingBottom={isMobileView ? 2 : 4}>
                <Typography paragraph variant="h6">
                    Patient Information
                </Typography>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between" rowSpacing={{ md: 2, xs: 0 }}>
                    <Grid item md={5} xs={12}>
                        <TextField
                            label="First Name"
                            fullWidth
                            {...register('firstName')}
                            error={!!errors.firstName}
                            helperText={errors.firstName?.message}
                            {...(isMobileView && { size: 'small' })}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <TextField
                            label="Last Name"
                            fullWidth
                            {...register('lastName')}
                            error={!!errors.lastName}
                            helperText={errors.lastName?.message}
                            {...(isMobileView && { size: 'small' })}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <Controller
                            control={control}
                            name="birthDate"
                            render={({ field }) => (
                                <DateInput
                                    label="Date of Birth"
                                    field={field}
                                    error={errors.birthDate}
                                    inputProps={{
                                        ...(isMobileView && { size: 'small' }),
                                        fullWidth: true,
                                        InputLabelProps: {
                                            shrink: true,
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <Autocomplete
                            {...(isMobileView && { size: 'small' })}
                            id="sex"
                            disablePortal
                            options={inputGenders}
                            value={
                                getValues
                                    ? inputGenders.find(v => v.id === getValues('sex'))
                                    : EMPTY_GENDER_VALUE
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    name="sex"
                                    error={!!errors.sex}
                                    helperText={errors.sex?.message}
                                    label="Sex"
                                />
                            )}
                            {...register('sex')}
                            onChange={(event, value) => {
                                setValue('sex', value?.id || '', { shouldValidate: true });
                            }}
                            onBlur={e => {
                                e.preventDefault();
                            }}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <TextField
                            label="Phone Number"
                            fullWidth
                            {...register('phoneNumber')}
                            error={!!errors.phoneNumber}
                            helperText={errors.phoneNumber?.message}
                            {...(isMobileView && { size: 'small' })}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <TextField
                            label="Email"
                            fullWidth
                            {...register('email')}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            {...(isMobileView && { size: 'small' })}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <TextField
                            label="Insurance ID Number"
                            fullWidth
                            {...register('insuranceId')}
                            error={!!errors.insuranceId}
                            helperText={errors.insuranceId?.message}
                            {...(isMobileView && { size: 'small' })}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <TextField
                            label="Insurance Group Number"
                            fullWidth
                            {...register('insuranceGroup')}
                            error={!!errors.insuranceGroup}
                            helperText={errors.insuranceGroup?.message}
                            {...(isMobileView && { size: 'small' })}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <Autocomplete
                            {...(isMobileView && { size: 'small' })}
                            id="patientRelationshipToInsured"
                            disablePortal
                            options={Object.values(RelationshipToInsuredEnum)}
                            value={
                                getValues
                                    ? Object.values(RelationshipToInsuredEnum).find(
                                          v => v === getValues('patientRelationshipToInsured'),
                                      )
                                    : ''
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    name="patientRelationshipToInsured"
                                    error={!!errors.patientRelationshipToInsured}
                                    helperText={errors.patientRelationshipToInsured?.message}
                                    label="Relationship To Primary Insured"
                                />
                            )}
                            {...register('patientRelationshipToInsured')}
                            onChange={(event, value) => {
                                setValue('patientRelationshipToInsured', value || '', {
                                    shouldValidate: true,
                                });
                                setSelfSelected(value === 'self');
                            }}
                            onBlur={e => {
                                e.preventDefault();
                            }}
                        />
                    </Grid>
                    <Grid item md={5} xs={12} />
                </Grid>
            </Grid>
            <FooterDivider isMobileView={isMobileView} />
            <ButtonContainer
                nextButton={{
                    handler: stepHandler,
                    label: 'Next',
                    disabled: stepLoading,
                    loading: stepLoading,
                }}
                isMobileView={isMobileView}
            />
            <SupportBlock isMobileView={isMobileView} />
        </>
    );
};

export default PatientInfo;
