import { useState, useEffect } from 'react';
import useUserPermissions from './useUserPermissions';
import useUser from './useUser';

const useAffiliateCareView = () => {
    const [isAffiliateView, setIsAffiliateView] = useState(false);
    const { groupPermissions } = useUserPermissions();
    useEffect(() => {
        if (groupPermissions?.VirtualCare) {
            setIsAffiliateView(true);
        }
    }, [groupPermissions?.VirtualCare]);
    const { data: userData } = useUser();
    return {
        isAffiliateView,
        userAffiliateId: userData?.currentUser?.affiliate?.data?.affiliateId || '',
        affiliateName: userData?.currentUser?.affiliate?.data?.externalName,
        affiliateURL: userData?.currentUser?.affiliate?.affiliateURL || '',
    };
};

export default useAffiliateCareView;
