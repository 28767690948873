export const DueFilterConfig: {
    label: string;
    value: string;
}[] = [
    {
        label: 'Today',
        value: 'today',
    },
    {
        label: 'Next 3 Days',
        value: '3days',
    },
    {
        label: 'This week',
        value: 'week',
    },
    {
        label: 'Custom Date',
        value: 'custom',
    },
    {
        label: 'Overdue',
        value: 'overdue',
    },
];
