import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import moment from 'moment';
import { Grid, Typography, Dialog, TablePagination, FormControlLabel, Switch } from '@mui/material';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import {
    useOrganizationVcfResultsLazyQuery,
    OrganizationVcfResultsQuery,
    OrderByDirectionEnum,
    AppointmentMethod,
} from '~/schemaTypes';
import { PAGESIZE } from '~/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import ViewVCF from './ViewVCF';
import { AppointmentMethodViewMap } from './constants';

type AppointmentMethodViewProps = {
    method?: AppointmentMethod | null;
};

const AppointmentMethodView: React.FC<AppointmentMethodViewProps> = React.memo(props => {
    const { method } = props;
    if (!method) {
        return <Typography data-test="">-</Typography>;
    }
    const value = AppointmentMethodViewMap[method];
    return <Typography data-test={value}>{value}</Typography>;
});

type VCFResult = NonNullable<OrganizationVcfResultsQuery['virtualCareFormsV2']>['results'][0];

const OrganizationVCF: React.FC = () => {
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [showViewModal, setShowViewModal] = useState<boolean>(false);
    const [showAtHomeResults, setShowAtHomeResults] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<string>();
    const [searchText, setSearchText] = useState<string>('');
    const [fetchVCFResults, { data: VCFResults, loading }] = useOrganizationVcfResultsLazyQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    });
    const fetchVCFResultsPayload = useMemo(
        () => ({
            variables: {
                input: {
                    filter: {
                        fields: {
                            ...(showAtHomeResults && {
                                appointmentMethod: AppointmentMethod.AtHome,
                            }),
                            ...(searchText && { _id: searchText }),
                        },
                    },
                    orderBy: {
                        field: 'createdAt',
                        order: OrderByDirectionEnum.Desc,
                    },
                    pagination: {
                        skip: page * rowsPerPage,
                        limit: rowsPerPage,
                    },
                },
            },
        }),
        [showAtHomeResults, page, rowsPerPage, searchText],
    );
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    useEffect(() => {
        fetchVCFResults(fetchVCFResultsPayload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchVCFResultsPayload]);
    const resultViewHandler = useCallback((_: any, item: VCFResult) => {
        setSelectedId(item.id);
        setShowViewModal(true);
    }, []);
    return (
        <div style={{ marginRight: '1rem' }} ref={tableRef}>
            <Grid>
                <MaterialTable<VCFResult>
                    columns={[
                        {
                            title: '_id',
                            field: 'id',
                            render: ({ id }) => <Typography data-test={id}>{id}</Typography>,
                            sorting: false,
                        },
                        {
                            title: 'First Name',
                            field: 'firstName',
                            render: ({ firstName }) => (
                                <Typography data-test={firstName}>{firstName}</Typography>
                            ),
                            sorting: false,
                            searchable: false,
                        },
                        {
                            title: 'Last Name',
                            field: 'lastName',
                            render: ({ lastName }) => (
                                <Typography data-test={lastName}>{lastName}</Typography>
                            ),
                            sorting: false,
                            searchable: false,
                        },
                        {
                            title: 'Phone Number',
                            field: 'phoneNumber',
                            render: ({ phoneNumber }) => (
                                <Typography data-test={phoneNumber}>{phoneNumber}</Typography>
                            ),
                            sorting: false,
                            searchable: false,
                        },
                        {
                            title: 'Insurance Id',
                            field: 'insuranceId',
                            render: ({ insuranceId }) => (
                                <Typography data-test={insuranceId}>{insuranceId}</Typography>
                            ),
                            sorting: false,
                            searchable: false,
                        },
                        {
                            title: 'Method',
                            field: 'appointmentMethod',
                            render: ({ appointmentMethod }) => (
                                <AppointmentMethodView method={appointmentMethod} />
                            ),
                            sorting: false,
                            searchable: false,
                        },
                        {
                            title: 'Date Time',
                            field: 'createdAt',
                            render: ({ createdAt }) => (
                                <Typography data-test={createdAt}>
                                    {moment(createdAt).local().format('MM-DD-YYYY hh:mm A')}
                                </Typography>
                            ),
                            sorting: false,
                            searchable: false,
                        },
                    ]}
                    data={
                        VCFResults?.virtualCareFormsV2?.results.map((o: any) => ({
                            ...o,
                        })) || []
                    }
                    title="Virtual Care Forms"
                    options={{
                        search: true,
                        paginationPosition: 'bottom',
                        pageSize: rowsPerPage,
                        pageSizeOptions: [25, 50, 100],
                        debounceInterval: 500,
                    }}
                    isLoading={loading}
                    localization={{
                        header: {
                            actions: '',
                        },
                        toolbar: {
                            searchPlaceholder: 'by _id',
                        },
                    }}
                    actions={[
                        {
                            onClick: resultViewHandler,
                            icon: () => <FontAwesomeIcon icon={faEye} />,
                            tooltip: 'View Virtual Care Form Result',
                        },
                    ]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    onSearchChange={setSearchText}
                    components={{
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                count={VCFResults?.virtualCareFormsV2?.total ?? 0}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        ),
                        Toolbar: props => (
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item xs={5}>
                                    <MTableToolbar {...props} />
                                </Grid>
                                <Grid item>
                                    <Grid container columnSpacing={1}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={showAtHomeResults}
                                                    onChange={(_, checked) =>
                                                        setShowAtHomeResults(checked)
                                                    }
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                            label={'Only Show "At Home" Appointment Method'}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ),
                    }}
                />
            </Grid>
            {showViewModal && (
                <Dialog open={showViewModal} scroll="paper" fullWidth maxWidth="lg">
                    <ViewVCF VCFId={selectedId} setOpen={setShowViewModal} />
                </Dialog>
            )}
        </div>
    );
};

export default OrganizationVCF;
