import { LocalizedString } from '../types/LocalizedString';
import { MessageCenterTemplateFormInput } from '../types/MessageCenterTemplateFormInput';
import { MessageCenterTemplates, MessageCenterTemplatesContent } from '../types/Schema';

const defaultLocalizedString = (): LocalizedString => ({ en: '', es: '' });

export const defaultFormValues = (): Partial<MessageCenterTemplateFormInput> => ({
    title: '',
    content: defaultLocalizedString() as MessageCenterTemplatesContent,
});

export const toFormValuesFromTemplate = (): MessageCenterTemplateFormInput => ({
    title: '',
    content: defaultLocalizedString() as MessageCenterTemplatesContent,
});

export const toFormValuesFromMessageTemplate = (
    template: MessageCenterTemplates,
): MessageCenterTemplateFormInput => {
    return {
        title: template?.title ?? '',
        content: {
            en: template?.content?.en ?? '',
            es: template?.content?.es ?? '',
        },
    };
};
