import React, { useEffect, useState } from 'react';
import Loading from '~/components/Loading/Loading';
import {
    ReferencePages,
    useCareTeamMemberTypesV2ForPatientChatQuery,
    useFetchReferenceLinksForMessageCenterPageQuery,
} from '~/schemaTypes';
import ChatRoom from '~/views/Dashboard/MessageCenter/ChatRoom/ChatRoom';
import { HEALTH_ADVOCATE_CARE_TEAM_MEMBER_TYPE_ID } from '~/constants';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import PatientChatRoom from './PatientChatRoom/PatientChatRoom';

export const PatientChat = ({
    setLinks,
}: {
    setLinks: React.Dispatch<React.SetStateAction<any>>;
}) => {
    const [careTeamMemberTypeId, setCareTeamMemberTypeId] = useState(
        HEALTH_ADVOCATE_CARE_TEAM_MEMBER_TYPE_ID,
    );

    const { data: jobs, loading: jobsLoading } = useCareTeamMemberTypesV2ForPatientChatQuery({
        variables: {
            input: {
                filter: {
                    fields: {
                        isJob: true,
                    },
                },
            },
        },
    });

    const { data: referenceLinksData, loading: referenceLinksLoading } =
        useFetchReferenceLinksForMessageCenterPageQuery({
            variables: {
                input: {
                    page: ReferencePages.PatientMessageCenter,
                },
            },
        });

    useEffect(() => {
        if (referenceLinksData && setLinks) {
            setLinks(referenceLinksData.getReferenceLinksForPage);
        }
    }, [referenceLinksData, setLinks]);

    const handleChangeChatRoom = (event: SelectChangeEvent) => {
        setCareTeamMemberTypeId(event.target.value);
    };

    if (referenceLinksLoading || jobsLoading) {
        return <Loading />;
    }

    if (jobs?.careTeamMemberTypesV2.results.length === 0) {
        return <ChatRoom chatRoomId="" />;
    }

    return (
        <div className="flex flex-col">
            <div className="flex flex-row p-2">
                <Select
                    value={careTeamMemberTypeId}
                    label="Job"
                    variant="outlined"
                    onChange={handleChangeChatRoom}
                    className="min-w-52"
                >
                    <MenuItem value={HEALTH_ADVOCATE_CARE_TEAM_MEMBER_TYPE_ID}>
                        Health Advocate
                    </MenuItem>
                    {jobs?.careTeamMemberTypesV2.results
                        .filter(job => job.id !== HEALTH_ADVOCATE_CARE_TEAM_MEMBER_TYPE_ID)
                        .map(job => (
                            <MenuItem key={job.id} value={job.id}>
                                {job.name}
                            </MenuItem>
                        ))}
                </Select>
            </div>
            <PatientChatRoom careTeamMemberTypeId={careTeamMemberTypeId} />
        </div>
    );
};
