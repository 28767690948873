import {
    faAdd,
    faCalendar,
    faClone,
    faPenToSquare,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TablePagination } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomFilter, { IFilterChange } from '~/components/CustomFilter/CustomFilter';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    HighlightsForListPageDocument,
    HighlightFilterInput,
    HighlightsForListPageQueryVariables,
    useHighlightsForListPageQuery,
    useDeleteHighlightMutation,
    HighlightsForListPageQuery,
    HighlightType,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { CloneHighlightModal } from './HighlightEditor/CloneHighlightModal';

type HighlightList = NonNullable<HighlightsForListPageQuery['highlightsV2']>['results'][0];

const useStyles = makeStyles()({
    root: {
        position: 'relative',
        height: '100%',
        '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
            opacity: '0',
        },
    },
    paper: { minWidth: '400px' },
});

const Highlights: React.FC = () => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [filter, setFilter] = useState<IFilterChange<HighlightList> | null>(null);
    const [selectedHighlight, setSelectedHighlight] = useState<any>();
    const [showCloneModal, setShowCloneModal] = useState<boolean>(false);

    const highlightsListQueryVariables: HighlightsForListPageQueryVariables = {
        input: {
            pagination: {
                skip: page * rowsPerPage,
                limit: rowsPerPage,
            },
            filter: filter as HighlightFilterInput | null,
        },
    };

    const { data: highlightList, loading: highlightsLoading } = useHighlightsForListPageQuery({
        variables: highlightsListQueryVariables,
    });
    const [deleteHighlight, { loading: deleteHighlightLoading }] = useDeleteHighlightMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: HighlightsForListPageDocument,
                variables: highlightsListQueryVariables,
            },
        ],
    });

    const handleDelete = (id: string, name: string) => {
        TriggerGlobalConfirm({
            message: `Do you really want to delete '${name}'?`,
            callback: () => {
                deleteHighlight({ variables: { input: { id } } });
            },
        });
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleClone = () => {
        setShowCloneModal(true);
    };
    return (
        <div className={classes.root} ref={tableRef}>
            <CloneHighlightModal
                isOpen={showCloneModal}
                selectedHighlight={selectedHighlight}
                onClose={() => setShowCloneModal(false)}
            />

            <MaterialTable
                title="Highlights"
                icons={tableIcons}
                isLoading={highlightsLoading || deleteHighlightLoading}
                data={
                    highlightList?.highlightsV2.results.filter(
                        h => h.type !== HighlightType.AppointmentTemplate,
                    ) ?? []
                }
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={highlightList?.highlightsV2.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                }}
                actions={[
                    {
                        onClick: event => {
                            event.preventDefault();
                        },
                        hidden: !pagePermissions?.Highlights.Edit,
                        icon: () => (
                            <CustomFilter<HighlightList> setFilter={setFilter} fields={['name']} />
                        ),
                        isFreeAction: true,
                    },
                    {
                        onClick: () => history('/app-config/highlights/new'),
                        hidden: !pagePermissions?.Highlights.Edit,
                        icon: () => <FontAwesomeIcon icon={faAdd} />,
                        tooltip: 'Add Highlight',
                        isFreeAction: true,
                    },
                    {
                        onClick: () => history('/app-config/highlighttemplate'),
                        hidden: !pagePermissions?.Highlights.Edit,
                        icon: () => <FontAwesomeIcon icon={faCalendar} />,
                        tooltip: 'Appointment Template',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, { id }: any) => history(`/app-config/highlights/${id}`),
                        hidden: !pagePermissions?.Highlights.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Highlight',
                    },
                    {
                        onClick: (_, { id, name }: any) => {
                            handleDelete(id, name);
                        },
                        hidden: !pagePermissions?.Highlights.Delete,
                        icon: () => <FontAwesomeIcon icon={faTrash} />,
                        tooltip: 'Delete Highlight',
                    },
                    {
                        onClick: (_, item: any) => {
                            setSelectedHighlight(item);
                            handleClone();
                        },
                        hidden: !pagePermissions?.Highlights.Edit,
                        icon: () => <FontAwesomeIcon icon={faClone} />,
                        tooltip: 'Clone Highlight',
                    },
                ]}
                columns={[
                    { title: 'Name', field: 'name' },
                    { title: 'Type', field: 'highlightTypeName' },
                    { title: 'Clock Type', field: 'clockTypeName' },
                    { title: 'Timeline', field: 'timeline.label.en' },
                ]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{ pageSize: rowsPerPage, pageSizeOptions: [25, 50, 100], search: false }}
            />
        </div>
    );
};

export default Highlights;
