import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';

export const CustomLink = Link.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            'data-id': {
                default: null,
                parseHTML: element => element.getAttribute('data-id'),
                renderHTML: attributes => {
                    return {
                        'data-id': attributes['data-id'],
                    };
                },
            },
            title: {
                default: null,
                parseHTML: element => element.getAttribute('title'),
                renderHTML: attributes => {
                    return {
                        title: attributes.title,
                    };
                },
            },
            'data-content-type': {
                default: null,
                parseHTML: element => element.getAttribute('data-content-type'),
                renderHTML: attributes => {
                    return {
                        'data-content-type': attributes['data-content-type'],
                    };
                },
            },
            'data-content-id': {
                default: null,
                parseHTML: element => element.getAttribute('data-content-id'),
                renderHTML: attributes => {
                    return {
                        'data-content-id': attributes['data-content-id'],
                    };
                },
            },
            'data-content-displayStyle': {
                default: null,
                parseHTML: element => element.getAttribute('data-content-displayStyle'),
                renderHTML: attributes => {
                    return {
                        'data-content-displayStyle': attributes['data-content-displayStyle'],
                    };
                },
            },
            class: {
                default: null,
                parseHTML: element => element.getAttribute('class'),
                renderHTML: attributes => {
                    return {
                        class: attributes.class,
                    };
                },
            },
        };
    },
});

export const CustomImage = Image.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            'data-id': {
                default: null,
                parseHTML: element => element.getAttribute('data-id'),
                renderHTML: attributes => {
                    return {
                        'data-id': attributes['data-id'],
                    };
                },
            },
            style: {
                default: 'max-width: 100%;',
                parseHTML: element => element.getAttribute('style'),
                renderHTML: attributes => {
                    return {
                        style: attributes.style,
                    };
                },
            },
        };
    },
});
