import { Autocomplete, TextField } from '@mui/material';
import React, { useMemo } from 'react';
import { Control, Controller, UseControllerReturn } from 'react-hook-form';
import { isNotUndefined } from '~/typeguards/isNotUndefined';

interface AutocompleteWithRecordOptionsProps<
    T extends Record<V | L, string>,
    V extends keyof T,
    L extends keyof T,
> {
    options: T[];
    defaultValues?: T[]; // make sure you pass defaultValues to useForm if none are provided to the components
    valueKey: V;
    labelKey: L;
    name: string;
    control: Control<any>;
    sortOptionsComparator?: (optionA: T, optionB: T) => number;
    label: string;
    placeholder?: string;
    required?: boolean;
    requiredCount?: number;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
    dataTest?: string;
    loading?: boolean;
    loadingText?: string;
}

export const AutocompleteWithRecordOptions = <
    T extends Record<V | L, string>,
    V extends keyof T,
    L extends keyof T,
>({
    name,
    control,
    options: propOptions,
    defaultValues,
    sortOptionsComparator,
    label,
    placeholder = '',
    valueKey,
    labelKey,
    required,
    requiredCount = 1,
    error,
    helperText,
    loading,
    loadingText,
    disabled = false,
    dataTest,
}: AutocompleteWithRecordOptionsProps<T, V, L>): JSX.Element => {
    const optionMap = useMemo(
        () =>
            propOptions.reduce(
                (map, option) => map.set(option[valueKey], option),
                new Map<T[V], T>(),
            ),
        [propOptions, valueKey],
    );

    const optionIds = useMemo(
        () =>
            sortOptionsComparator
                ? propOptions
                      .slice()
                      .sort(sortOptionsComparator)
                      .map(option => option[valueKey])
                : propOptions.map(option => option[valueKey]),
        [propOptions, valueKey, sortOptionsComparator],
    );

    const getOptionLabelByValue = (value: T[V]) => optionMap.get(value)?.[labelKey] ?? '';

    const renderProp = ({ field: { value: _values, onChange } }: UseControllerReturn) => {
        const values: T[V][] = _values; // for typecasting
        const selectedOptionValues = sortOptionsComparator
            ? values
                  .map(value => optionMap.get(value))
                  .filter(isNotUndefined)
                  .sort(sortOptionsComparator)
                  .map(option => option[valueKey])
            : values;

        return (
            <Autocomplete<T[V], true>
                multiple
                limitTags={5}
                options={optionIds}
                value={selectedOptionValues}
                onChange={(e, optionValuesOnChange) => onChange(optionValuesOnChange)}
                getOptionLabel={getOptionLabelByValue}
                disabled={disabled}
                loading={loading}
                loadingText={loadingText}
                renderInput={params => (
                    <TextField
                        variant="outlined"
                        label={label}
                        placeholder={placeholder}
                        error={error}
                        helperText={helperText}
                        required={required && selectedOptionValues.length < requiredCount}
                        // eslint-disable-line react/jsx-props-no-spreading
                        {...(dataTest && { 'data-test': dataTest })}
                        // eslint-disable-line react/jsx-props-no-spreading
                        {...params}
                    />
                )}
            />
        );
    };
    const controllerWithDefs = (
        <Controller
            name={name}
            control={control}
            render={renderProp}
            defaultValue={defaultValues}
        />
    );
    const controllerWithoutDefs = <Controller name={name} control={control} render={renderProp} />;
    return defaultValues ? controllerWithDefs : controllerWithoutDefs;
};
