import * as Yup from 'yup';
import moment from 'moment';
import { RelationshipToInsuredEnum } from './types';
import { DATE_FORMAT } from './helpers';

declare module 'yup' {
    interface DateSchema {
        dateFormatVCF(): this;
    }
    interface ValidateOptions {
        originalValue?: string;
    }
}

// eslint-disable-next-line func-names
Yup.addMethod<Yup.DateSchema>(Yup.date, 'dateFormatVCF', function (this: Yup.DateSchema) {
    return this.test(
        'date validation',
        'Invalid date',
        // eslint-disable-next-line func-names
        function (this, val) {
            if (!val) {
                return true;
            }
            const originalValue = this.options.originalValue || this.originalValue;
            return moment(originalValue, DATE_FORMAT, true).isValid();
        },
    );
});

export default [
    Yup.object().shape({}),
    Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        birthDate: Yup.date().dateFormatVCF().required('Birth date is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        sex: Yup.string().required('Sex is required'),
        phoneNumber: Yup.string().required('Phone number is required'),
        insuranceId: Yup.string().required('Insurance ID is required'),
        insuranceGroup: Yup.string().required('Insurance group is required'),
        patientRelationshipToInsured: Yup.string().required(
            'Patient relationship to insured is required',
        ),
    }),
    Yup.object().shape({
        addressLine1: Yup.string().required('Address is required'),
        addressLine2: Yup.string(),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        zipCode: Yup.string()
            .required('Zip code is required')
            .matches(/^\d{5}(-\d{4})?$/, 'Please enter a valid zip code.'),
        insuredFirstName: Yup.string().when(
            'patientRelationshipToInsured',
            ([patientRelationshipToInsure], schema) => {
                if (patientRelationshipToInsure !== RelationshipToInsuredEnum.SELF) {
                    return schema.required('Insured first name is required');
                }
                return schema.notRequired();
            },
        ),
        insuredLastName: Yup.string().when(
            'patientRelationshipToInsured',
            ([patientRelationshipToInsure], schema) => {
                if (patientRelationshipToInsure !== RelationshipToInsuredEnum.SELF) {
                    return schema.required('Insured last name is required');
                }
                return schema.notRequired();
            },
        ),
        subscriberSex: Yup.string().when(
            'patientRelationshipToInsured',
            ([patientRelationshipToInsure], schema) => {
                if (patientRelationshipToInsure !== RelationshipToInsuredEnum.SELF) {
                    return schema.required('Subscriber Sex is required');
                }
                return schema.notRequired();
            },
        ),
        subscriberBirthDate: Yup.date().when(
            'patientRelationshipToInsured',
            ([patientRelationshipToInsure], schema) => {
                if (patientRelationshipToInsure !== RelationshipToInsuredEnum.SELF) {
                    return schema.dateFormatVCF().required('Subscriber birth date is required');
                }
                return schema.notRequired();
            },
        ),
        insuredAddressSameAsPatient: Yup.boolean(),
        insuredAddressLine1: Yup.string().when(
            'insuredAddressSameAsPatient',
            ([insuredAddressSameAsPatient], schema) => {
                if (!insuredAddressSameAsPatient) {
                    return schema.required('Insured address is required');
                }
                return schema.notRequired();
            },
        ),
        insuredAddressLine2: Yup.string(),
        insuredCity: Yup.string().when(
            'insuredAddressSameAsPatient',
            ([insuredAddressSameAsPatient], schema) => {
                if (!insuredAddressSameAsPatient) {
                    return schema.required('Insured city is required');
                }
                return schema.notRequired();
            },
        ),
        insuredState: Yup.string().when(
            'insuredAddressSameAsPatient',
            ([insuredAddressSameAsPatient], schema) => {
                if (!insuredAddressSameAsPatient) {
                    return schema.required('Insured state is required');
                }
                return schema.notRequired();
            },
        ),
        insuredZipCode: Yup.string().when(
            'insuredAddressSameAsPatient',
            ([insuredAddressSameAsPatient], schema) => {
                if (!insuredAddressSameAsPatient) {
                    return schema
                        .required('Insured zip code is required')
                        .matches(/^\d{5}(-\d{4})?$/, 'Please enter a valid zip code.');
                }
                return schema.notRequired();
            },
        ),
    }),
    Yup.object().shape({
        appointmentMethod: Yup.string().required('Appointment method is required'),
    }),
    Yup.object().shape({}),
    Yup.object().shape({}),
];
