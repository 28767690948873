import React, { useMemo, useRef, useState } from 'react';
import { PAGESIZE } from '~/constants';
import {
    OrderByDirectionEnum,
    SlaTimersV2ForSlaTimersPageQueryVariables,
    useSlaTimersV2ForSlaTimersPageQuery,
} from '~/schemaTypes';
import MaterialTable, { MaterialTableProps, OrderByCollection } from '@material-table/core';
import tableIcons from '~/helpers/tableIcons';
import { TablePagination, Typography } from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ISlaTimerOrderChange, SlaTimerSortingField, columnIdToSortingFieldMap } from './types';
import type { SlaTimer } from './types';

const SLATimers = () => {
    const tableRef = useRef<HTMLDivElement>(null);

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [order, setOrder] = useState<ISlaTimerOrderChange>({
        field: SlaTimerSortingField.completeBy,
        direction: OrderByDirectionEnum.Desc,
        isInit: false,
    });

    const SlaTimersFilters = useMemo(() => {
        const filters: SlaTimersV2ForSlaTimersPageQueryVariables = {
            input: {
                pagination: {
                    skip: page * rowsPerPage,
                    limit: rowsPerPage,
                },
                orderBy: {
                    field: order.field,
                    order: order.direction,
                },
                filter: {},
            },
        };
        return filters;
    }, [page, rowsPerPage, order]);

    const { data: slaTimers, loading: slaTimersLoading } = useSlaTimersV2ForSlaTimersPageQuery({
        variables: SlaTimersFilters,
    });

    const getDirection = (order: string): OrderByDirectionEnum =>
        order.toUpperCase() === OrderByDirectionEnum.Asc
            ? OrderByDirectionEnum.Asc
            : OrderByDirectionEnum.Desc;

    const handleOrderChange = (orderByCollection: OrderByCollection[]) => {
        // material table passes empty array some times, which causes undefined error
        if (!orderByCollection || orderByCollection.length === 0) {
            return;
        }
        const orderBy = orderByCollection[0];
        const field = columnIdToSortingFieldMap[orderBy.orderBy];
        let isInit = false;
        if (field) {
            if (field !== order.field) {
                isInit = true;
            }
            const direction = getDirection(orderBy.orderDirection);
            const stateObject: ISlaTimerOrderChange = {
                direction,
                field,
                isInit,
            };
            setOrder(stateObject);
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div ref={tableRef}>
            <MaterialTable<SlaTimer>
                isLoading={slaTimersLoading}
                icons={tableIcons as MaterialTableProps<any>['icons']}
                actions={[]}
                columns={[
                    {
                        title: 'Sla Name',
                        field: 'sla.name',
                    },
                    {
                        title: 'Task',
                        field: 'task.label',
                        render: ({ task }) => {
                            if (task != null) {
                                return (
                                    <Link to={`/portal/advocate-tasks/${task.id}`}>
                                        {task.label}
                                    </Link>
                                );
                            }
                            return <p>No task</p>;
                        },
                    },
                    {
                        title: 'Time left',
                        render: ({ completeBy, completedAt }) => {
                            if (completedAt) {
                                return <Typography>Completed</Typography>;
                            }
                            return (
                                <Typography>{moment.utc(completeBy).local().fromNow()}</Typography>
                            );
                        },
                        customSort: (a, b) => {
                            if (a.completedAt) {
                                return -1;
                            }
                            if (b.completedAt) {
                                return 1;
                            }
                            return (
                                new Date(b.completeBy).getTime() - new Date(a.completeBy).getTime()
                            );
                        },
                        defaultSort: 'desc',
                    },
                    {
                        title: 'Time to Complete By',
                        field: 'completeBy',
                        render: ({ completeBy }) => (
                            <Typography>
                                {`${moment
                                    .utc(completeBy)
                                    .local()
                                    .format('MM/DD/yyyy hh:mm:ss a')} ${
                                    new Date()
                                        .toLocaleTimeString('en-us', {
                                            timeZoneName: 'short',
                                        })
                                        .split(' ')[2]
                                }`}
                            </Typography>
                        ),
                        customSort: (a, b) =>
                            new Date(a.completeBy).getTime() - new Date(b.completeBy).getTime(),
                    },
                    {
                        title: 'Created At',
                        field: 'createdAt',
                        render: ({ createdAt }) => (
                            <Typography>{moment.utc(createdAt).format('MM/DD/yyyy')}</Typography>
                        ),
                        customSort: (a, b) =>
                            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
                    },
                    {
                        title: 'Completed At',
                        field: 'completedAt',
                        render: ({ completedAt }) => (
                            <Typography>
                                {completedAt
                                    ? `${moment
                                          .utc(completedAt)
                                          .local()
                                          .format('MM/DD/yyyy hh:mm:ss a')}
                                          ${
                                              new Date()
                                                  .toLocaleTimeString('en-us', {
                                                      timeZoneName: 'short',
                                                  })
                                                  .split(' ')[2]
                                          }`
                                    : 'Inprogress'}
                            </Typography>
                        ),
                        customSort: (a, b) =>
                            new Date(a.completedAt).getTime() - new Date(b.completedAt).getTime(),
                    },
                    {
                        title: 'Organization',
                        field: 'organization.name',
                    },
                    {
                        title: 'Completed By',
                        field: 'completedByUser.name',
                    },
                ]}
                data={slaTimers?.slaTimersV2?.results ?? []}
                onOrderCollectionChange={handleOrderChange}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={slaTimers?.slaTimersV2?.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                }}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                    search: false,
                    paginationPosition: 'both',
                }}
                title="Sla Timers"
            />
        </div>
    );
};

export default SLATimers;
