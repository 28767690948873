import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { Link } from 'react-router-dom';
import { useUserPermissions } from '~/hooks';

import ConfigLinks from './ConfigDashboardLinks';

const gridRows = 11;
const gridColumns = 4;
const useStyles = makeStyles()(theme => ({
    root: {},
    scrollCont: {
        paddingTop: '20px',
        ...theme.containerStyles(theme),
        height: `calc(100vh - 75px)`,
        overflow: 'auto',
    },
    table: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridGap: '0',
        gridTemplateColumns: `repeat(${gridColumns}, 20%)`,
        gridTemplateRows: `repeat(${gridRows}, 3rem)`,
        marginTop: '10px',
    },
    cell: {
        display: 'flex',
        border: 'solid 1px lightgray',
        paddingTop: '10px',
    },
    link: {
        cursor: 'pointer',
        width: '100%',
        paddingLeft: 8,
        paddingRight: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        position: 'relative',
        overflow: 'visible',
        textDecoration: 'none',
    },
    linkTitle: {
        fontSize: 'clamp(14px, 1.25vw, 20px)',
    },
}));

const ConfigDashboardTable: React.FC<any> = () => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    return (
        <div className={classes.root}>
            <div className={classes.scrollCont}>
                <Typography variant="h4">Config Menu</Typography>
                <div className={classes.table}>
                    {pagePermissions &&
                        ConfigLinks.filter(({ hide }) => !hide(pagePermissions))
                            .sort((a, b) => a.text.localeCompare(b.text))
                            .map(cl => {
                                return (
                                    <div key={cl.to} className={classes.cell}>
                                        <Link
                                            className={classes.link}
                                            to={cl.to}
                                            data-test={cl.dataTest}
                                        >
                                            <Typography
                                                className={classes.linkTitle}
                                                color="primary"
                                                variant="h6"
                                            >
                                                {cl.text}
                                            </Typography>
                                        </Link>
                                    </div>
                                );
                            })}
                </div>
            </div>
        </div>
    );
};

export default ConfigDashboardTable;
