import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Input,
    TextField,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import Loading from '~/components/Loading/Loading';
import {
    AlertSeverity,
    FetchUserProfileDefinitionsForVbcPatientModalQuery,
    useCreateAppUserForPatientMutation,
    useCreateOrUpdateManyUserProfileVariablesMutation,
    useFetchPatientByIdForVbcPatientModalLazyQuery,
    useUpdatePatientOnPatientsPageMutation,
} from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import ProfileVariableInputs from '~/views/Dashboard/Patients/VBCPatientModal/ProfileVariableInputs';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useProfileVariableInputs } from './useProfileVariableInputs';
import { dateToISOString, displayDate, displayDateLocale } from '../../../../helpers';

// eslint-disable-next-line prefer-regex-literals
const PHONE_REGEX = RegExp(
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
);

interface CreateVBCAppUserForPatientInput {
    [key: string]: any;
}

export const PATIENT_VALIDATION_SCHEMA = Yup.object().shape(
    {
        appBundleId: Yup.string().required('App is required'),
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        email: Yup.string(),
        birthDate: Yup.date().required('Date is required'),
        isTestData: Yup.boolean(),
        phone_number: Yup.string()
            .nullable()
            .when('phone_number', ([phoneNumber], schema) => {
                if (phoneNumber) {
                    return schema.matches(PHONE_REGEX, 'Invalid phone number');
                }
                return schema;
            }),
    },
    [['phone_number', 'phone_number']],
);

const useStyles = makeStyles()({
    root: {},
});

type PatientModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setEditPatientId: Dispatch<SetStateAction<string>>;
    id?: string;
    bundleId: string | null;
    onCreateCompleted: () => Promise<any>;
};

type ProfileDefinitionsData =
    FetchUserProfileDefinitionsForVbcPatientModalQuery['userProfileDefsV2']['results'];
type ProfileDefinitionsDataItem = ProfileDefinitionsData[0];

const VBCPatientModal: React.FC<PatientModalProps> = ({
    setOpen,
    onCreateCompleted,
    bundleId,
    setEditPatientId,
    id,
}) => {
    const { classes } = useStyles();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const useFormObject = useForm<CreateVBCAppUserForPatientInput>({
        resolver: yupResolver(PATIENT_VALIDATION_SCHEMA as any),
    });
    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useFormObject;
    const { profileDefinitionsData, profileVariableInputs } = useProfileVariableInputs({
        useFormObject,
        patientId: id,
        bundleId,
        VBCPatientModalInputs: ProfileVariableInputs,
    });

    const [getPatient, { data: patientData, loading: patientLoading }] =
        useFetchPatientByIdForVbcPatientModalLazyQuery({
            onCompleted: data => {
                if (data.patient) {
                    const { firstName, lastName, email, birthDate, isTestData } = data.patient;
                    reset({
                        firstName,
                        lastName,
                        email: email || '',
                        birthDate: dateToISOString(birthDate),
                        isTestData,
                    });
                }
            },
        });

    const [createOrUpdateManyUserProfileVariables] =
        useCreateOrUpdateManyUserProfileVariablesMutation();

    const [updatePatient, { loading: updatePatientLoading }] =
        useUpdatePatientOnPatientsPageMutation({
            onCompleted: data => {
                if (data.updatePatient?.resourceUpdated?.id) {
                    setOpen(false);
                    setEditPatientId('');
                    onCreateCompleted();
                } else if (data.updatePatient?.status === 409) {
                    setIsModalOpen(true);
                }
            },
            onError: error => {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: `${error}`,
                });
            },
        });

    const [createPatient, { loading: createPatientLoading }] = useCreateAppUserForPatientMutation({
        onCompleted: data => {
            if (
                data.createAppUserForPatient?.appUserId &&
                data.createAppUserForPatient?.patientId
            ) {
                setOpen(false);
                setEditPatientId('');
                onCreateCompleted();
            }
        },
        onError: error => {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `${error}`,
            });
        },
    });

    const getCreateOrUpdateProfileVariablesInput = (patientId: string, additionalData: any) => {
        return profileDefinitionsData.map((item: ProfileDefinitionsDataItem) => ({
            patientId,
            profileVariableDefId: item.id,
            value: {
                [item.valueType]: additionalData[item.name],
            },
        }));
    };

    const onSubmit = async (data: CreateVBCAppUserForPatientInput) => {
        const {
            appBundleId,
            birthDate,
            firstName,
            lastName,
            email,
            isTestData,
            ...additionalData
        } = data;

        if (id) {
            await updatePatient({
                variables: {
                    input: {
                        id,
                        data: {
                            firstName,
                            lastName,
                            isTestData,
                            ...(email && { email }),
                            birthDate: dateToISOString(birthDate),
                        },
                    },
                },
            });
            createOrUpdateManyUserProfileVariables({
                variables: {
                    input: getCreateOrUpdateProfileVariablesInput(id, additionalData),
                },
            });
        } else {
            const createPatientResponse = await createPatient({
                variables: {
                    input: {
                        appBundleId,
                        firstName,
                        lastName,
                        email,
                        isTestData,
                        birthDate: dateToISOString(birthDate),
                        isCreatedByPortal: true,
                    },
                },
            });
            const patientId = createPatientResponse?.data?.createAppUserForPatient?.patientId;
            if (!patientId) return;
            createOrUpdateManyUserProfileVariables({
                variables: {
                    input: getCreateOrUpdateProfileVariablesInput(patientId, additionalData),
                },
            });
        }
    };

    useEffect(() => {
        if (id) {
            getPatient({
                variables: {
                    input: {
                        id,
                    },
                },
            });
        }
    }, [id, getPatient]);

    if (patientLoading) {
        return <Loading subtitle="Loading patient..." />;
    }
    if (createPatientLoading) {
        return <Loading subtitle="Creating patient..." />;
    }
    if (updatePatientLoading) {
        return <Loading subtitle="Updating patient..." />;
    }
    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <div>
            <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">
                    {patientData?.patient?.id === undefined ? 'Create Patient' : 'Edit Patient'}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container>
                        <Grid item xs={12}>
                            <Controller
                                name="isTestData"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        label="Is Test Patient?"
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                {...field}
                                                id="isTestData"
                                                checked={!!field.value}
                                            />
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                value={bundleId}
                                error={!!errors.appBundleId}
                                type="hidden"
                                {...register('appBundleId')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="First Name"
                                type="text"
                                margin="dense"
                                fullWidth
                                error={!!errors.firstName}
                                {...register('firstName')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Last Name"
                                type="text"
                                margin="dense"
                                fullWidth
                                error={!!errors.lastName}
                                {...register('lastName')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Email"
                                type="text"
                                margin="dense"
                                fullWidth
                                error={!!errors.email}
                                {...register('email')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                {...register('birthDate')}
                                control={control}
                                render={({ field: { onChange, value, ref } }) => {
                                    const dateString = displayDate({ isoDateStr: value });
                                    let date = new Date();
                                    if (dateString) date = new Date(dateString);
                                    return (
                                        <DesktopDatePicker
                                            label="Birth Date"
                                            format="MM/dd/yyyy"
                                            maxDate={new Date()}
                                            onChange={value => {
                                                onChange(
                                                    displayDateLocale({
                                                        isoDateStr:
                                                            value?.toISOString() ??
                                                            new Date().toISOString(),
                                                    }),
                                                );
                                            }}
                                            value={date}
                                            ref={ref}
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        {profileVariableInputs}
                    </Grid>
                </DialogContent>
                <DialogActions
                    style={{
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: 'white',
                        zIndex: 1000,
                    }}
                >
                    <Button onClick={handleCancel} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button color="primary" variant="contained" type="submit">
                        {id ? 'Update' : 'Create Patient'}
                    </Button>
                </DialogActions>
            </form>
            <Dialog scroll="paper" open={isModalOpen} fullWidth>
                <DialogTitle>This email could not be saved</DialogTitle>
                <DialogContent>
                    This email is already being used by a patient in this org.
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setIsModalOpen(false)}
                        type="submit"
                        color="primary"
                        variant="contained"
                    >
                        OK
                    </Button>
                    <Button
                        onClick={() => setIsModalOpen(false)}
                        color="secondary"
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default VBCPatientModal;
