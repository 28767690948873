import React from 'react';
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import DateInput from '~/components/DateInput/DateInput';
import StateAutocomplete from '~/components/StateAutocomplete/StateAutocomplete';
import { StepsProps } from '../types';
import FooterDivider from '../components/FooterDivider';
import ButtonContainer from '../components/ButtonContainer';
import SupportBlock from '../components/SupportBlock';
import Disclaimer from '../components/Disclaimer';
import Agreement from '../components/Agreement';
import AssignmentOfBenefits from '../components/AssignmentOfBenefits';

type PatientInfoContactProps = {
    selfSelected: boolean;
    backHandler: () => void;
    sameAddress: boolean;
    stepHandler: () => void;
    stepLoading: boolean;
    patientState?: string;
    insuredState?: string;
    isAffiliateView: boolean;
} & StepsProps;

const PatientInfoContact: React.FC<PatientInfoContactProps> = props => {
    const {
        register,
        selfSelected,
        errors,
        control,
        classes,
        backHandler,
        sameAddress,
        stepHandler,
        isMobileView,
        stepLoading,
        setValue,
        getValues,
        isAffiliateView,
    } = props;
    return (
        <>
            <Grid item xs={12} className={classes.subHeader}>
                <Typography paragraph variant="h6">
                    Additional Patient Information
                </Typography>
            </Grid>
            <Grid container justifyContent="space-between" rowSpacing={{ md: 2, xs: 0 }}>
                <Grid item md={12} xs={12}>
                    <TextField
                        label="Address Line 1"
                        {...register('addressLine1')}
                        fullWidth
                        error={!!errors.addressLine1}
                        helperText={errors.addressLine1?.message}
                        {...(isMobileView && { size: 'small' })}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <TextField
                        label="Address Line 2"
                        fullWidth
                        {...register('addressLine2')}
                        error={!!errors.addressLine2}
                        helperText={errors.addressLine2?.message}
                        {...(isMobileView && { size: 'small' })}
                    />
                </Grid>
                <Grid item md={5} xs={12}>
                    <TextField
                        label="City"
                        fullWidth
                        {...register('city')}
                        error={!!errors.city}
                        helperText={errors.city?.message}
                        {...(isMobileView && { size: 'small' })}
                    />
                </Grid>
                <Grid item md={5} xs={12}>
                    <StateAutocomplete
                        isMobileView={isMobileView}
                        errorMessage={errors.state?.message}
                        name="state"
                        label="State"
                        register={register('state')}
                        onChange={(value: string) => {
                            setValue('state', value, { shouldValidate: true });
                        }}
                    />
                </Grid>
                <Grid item md={5} xs={12}>
                    <TextField
                        label="Zip Code"
                        fullWidth
                        {...register('zipCode')}
                        error={!!errors.zipCode}
                        helperText={errors.zipCode?.message}
                        {...(isMobileView && { size: 'small' })}
                    />
                </Grid>
                <Grid item xs={5} />
            </Grid>
            <Grid item xs={12} className={classes.subHeader}>
                <Typography paragraph variant="h6">
                    Primary Insurance Information
                </Typography>
            </Grid>
            <Grid container justifyContent="space-between" rowSpacing={{ md: 2, xs: 0 }}>
                <Grid item md={5} xs={12}>
                    <TextField
                        label="Insured First Name"
                        fullWidth
                        disabled={selfSelected}
                        {...register('insuredFirstName')}
                        error={!!errors.insuredFirstName}
                        helperText={errors.insuredFirstName?.message}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...(selfSelected && { InputLabelProps: { shrink: true } })}
                        {...(isMobileView && { size: 'small' })}
                    />
                </Grid>
                <Grid item md={5} xs={12}>
                    <TextField
                        label="Insured Last Name"
                        fullWidth
                        disabled={selfSelected}
                        {...register('insuredLastName')}
                        error={!!errors.insuredLastName}
                        helperText={errors.insuredLastName?.message}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...(selfSelected && { InputLabelProps: { shrink: true } })}
                        {...(isMobileView && { size: 'small' })}
                    />
                </Grid>
                <Grid item md={5} xs={12}>
                    <Controller
                        control={control}
                        name="subscriberSex"
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                            <FormControl
                                fullWidth
                                disabled={selfSelected}
                                {...(isMobileView && { size: 'small' })}
                            >
                                <InputLabel
                                    id="subscriberSex"
                                    {...(isMobileView && { size: 'small' })}
                                >
                                    Sex
                                </InputLabel>
                                <Select
                                    value={value}
                                    fullWidth
                                    onChange={e => onChange(e.target.value)}
                                    error={!!errors.subscriberSex}
                                >
                                    <MenuItem key="female" value="female">
                                        Female
                                    </MenuItem>
                                    <MenuItem key="male" value="male">
                                        Male
                                    </MenuItem>
                                    <MenuItem key="non-binary" value="non-binary">
                                        Non-Binary
                                    </MenuItem>
                                    <MenuItem key="other" value="other">
                                        Other
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    />
                </Grid>
                <Grid item md={5} xs={12}>
                    <Controller
                        control={control}
                        name="subscriberBirthDate"
                        render={({ field }) => (
                            <DateInput
                                label="Date of Birth"
                                field={field}
                                error={errors.subscriberBirthDate}
                                disabled={selfSelected}
                                inputProps={{
                                    ...(isMobileView && { size: 'small' }),
                                    fullWidth: true,
                                    InputLabelProps: {
                                        shrink: true,
                                    },
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={11} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...register('insuredAddressSameAsPatient')}
                                checked={sameAddress}
                            />
                        }
                        label="Insured Address Same as Patient Address"
                        className={classes.checkbox}
                        {...(isMobileView && { size: 'small' })}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <TextField
                        label="Insured Address Line 1"
                        fullWidth
                        disabled={sameAddress}
                        {...register('insuredAddressLine1')}
                        error={!!errors.insuredAddressLine1}
                        helperText={errors.insuredAddressLine1?.message}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...(sameAddress && { InputLabelProps: { shrink: true } })}
                        {...(isMobileView && { size: 'small' })}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <TextField
                        label="Insured Address Line 2"
                        fullWidth
                        disabled={sameAddress}
                        {...register('insuredAddressLine2')}
                        error={!!errors.insuredAddressLine2}
                        helperText={errors.insuredAddressLine2?.message}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...(sameAddress && { InputLabelProps: { shrink: true } })}
                        {...(isMobileView && { size: 'small' })}
                    />
                </Grid>
                <Grid item md={5} xs={12}>
                    <TextField
                        label="Insured City"
                        fullWidth
                        disabled={sameAddress}
                        {...register('insuredCity')}
                        error={!!errors.insuredCity}
                        helperText={errors.insuredCity?.message}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...(sameAddress && { InputLabelProps: { shrink: true } })}
                        {...(isMobileView && { size: 'small' })}
                    />
                </Grid>
                <Grid item md={5} xs={12}>
                    <StateAutocomplete
                        isMobileView={isMobileView}
                        name="insuredState"
                        label="Insured State"
                        register={register('insuredState')}
                        onChange={value => {
                            setValue('insuredState', value, { shouldValidate: true });
                        }}
                        disabled={sameAddress}
                        errorMessage={errors.insuredState?.message}
                        value={
                            // eslint-disable-next-line no-nested-ternary
                            getValues
                                ? getValues('insuredAddressSameAsPatient')
                                    ? getValues('state')
                                    : getValues('insuredState') ?? ''
                                : ''
                        }
                    />
                </Grid>
                <Grid item md={5} xs={12} alignSelf="flex-end">
                    <TextField
                        label="Insured Zip Code"
                        fullWidth
                        disabled={sameAddress}
                        {...register('insuredZipCode')}
                        error={!!errors.insuredZipCode}
                        helperText={errors.insuredZipCode?.message}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...(sameAddress && { InputLabelProps: { shrink: true } })}
                        {...(isMobileView && { size: 'small' })}
                    />
                </Grid>
            </Grid>
            <FooterDivider isMobileView={isMobileView} bottomPadding={0} />
            {!isAffiliateView && <Agreement isMobileView={isMobileView} />}
            {!isAffiliateView && <Disclaimer isMobileView={isMobileView} />}
            {!isAffiliateView && <AssignmentOfBenefits isMobileView={isMobileView} />}
            <SupportBlock isMobileView={isMobileView} />
            <ButtonContainer
                nextButton={{
                    handler: stepHandler,
                    label: 'Continue',
                    disabled: stepLoading,
                    loading: stepLoading,
                }}
                isMobileView={isMobileView}
                prevButton={{
                    handler: backHandler,
                    label: 'Back',
                }}
            />
        </>
    );
};

export default PatientInfoContact;
