import React, { useCallback, useMemo, useState } from 'react';
import { Grid, IconButton, TablePagination, Tooltip, Typography } from '@mui/material';
import copy from 'copy-to-clipboard';
import { TriggerGlobalAlert } from '~/state';
import {
    AlertSeverity,
    OrderByDirectionEnum,
    useFetchVirtualCareAffiliatesQuery,
    VirtualCareAffiliatesEntity,
} from '~/schemaTypes';
import { InfoOutlined } from '@mui/icons-material';
import { faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaterialTable, { OrderByCollection } from '@material-table/core';
import { useNavigate } from 'react-router-dom';
import {
    DEFAULT_ORDER_DIRECTION,
    OrderByFields,
    OrderDirectionMap,
    ROWS_PER_PAGE,
} from '~/views/ConfigDashboard/VCAffiliates/constants/constants';

export const VCAffiliates: React.FC = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
    const [orderDirection, setOrderDirection] =
        useState<OrderByDirectionEnum>(DEFAULT_ORDER_DIRECTION);
    const [search, setSearch] = useState<string>();
    const onSearchChange = useCallback((searchString: string) => {
        setSearch(searchString);
    }, []);
    const fetchVariables = useMemo(() => {
        return {
            input: {
                pagination: {
                    skip: page * rowsPerPage,
                    limit: rowsPerPage,
                },
                orderBy: {
                    field: OrderByFields.name,
                    order: orderDirection,
                },
                ...(search && { search }),
            },
        };
    }, [orderDirection, page, rowsPerPage, search]);
    const { loading, data } = useFetchVirtualCareAffiliatesQuery({
        variables: fetchVariables,
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });

    const onChangePage = useCallback(
        (_: React.MouseEvent<HTMLButtonElement>, pageNumber: number) => {
            setPage(() => pageNumber);
        },
        [],
    );
    const onChangeRowsPerPage = useCallback((pageSize: number) => {
        setRowsPerPage(() => pageSize);
    }, []);
    const onOrderChange = useCallback(
        (orderByCollection: OrderByCollection[]) => {
            const orderOptions = orderByCollection[0];
            if (orderOptions) {
                const { orderDirection: direction } = orderOptions;
                if (direction.toUpperCase() !== orderDirection) {
                    setOrderDirection(OrderDirectionMap[direction]);
                }
            }
        },
        [orderDirection],
    );

    return (
        <MaterialTable
            title="VC Affiliates"
            actions={[
                {
                    icon: () => <FontAwesomeIcon icon={faPlus} />,
                    isFreeAction: true,
                    onClick: () => {
                        navigate('/app-config/vcaffiliates/new');
                    },
                    tooltip: 'Add New Entry',
                },
                {
                    onClick: (_, rowData: VirtualCareAffiliatesEntity) => {
                        navigate(`/app-config/vcaffiliates/${rowData.id}`);
                    },
                    icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                    tooltip: 'Edit',
                    position: 'row',
                },
            ]}
            columns={[
                {
                    title: 'Name',
                    field: 'internalName',
                    render: ({ internalName }) => (
                        <Typography data-test={internalName}>{internalName}</Typography>
                    ),
                    sorting: true,
                    customFilterAndSearch: () => true,
                },
                {
                    title: 'ID',
                    field: 'id',
                    render: ({ id, affiliateId }) => (
                        <Grid container alignItems="center">
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'VC Affiliate ID is copied to clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                            <Typography data-test={id}>{affiliateId}</Typography>
                        </Grid>
                    ),
                    sorting: false,
                    searchable: false,
                },
            ]}
            data={data?.virtualCareAffiliates?.results ?? []}
            options={{
                search: true,
                pageSize: ROWS_PER_PAGE,
                pageSizeOptions: [ROWS_PER_PAGE, 20, 50],
                actionsColumnIndex: -1,
                debounceInterval: 500,
            }}
            onSearchChange={onSearchChange}
            localization={{ header: { actions: '' } }}
            isLoading={loading}
            onRowsPerPageChange={onChangeRowsPerPage}
            onOrderCollectionChange={onOrderChange}
            totalCount={data?.virtualCareAffiliates?.total}
            components={{
                Pagination: props => (
                    <TablePagination
                        {...props}
                        count={data?.virtualCareAffiliates?.total ?? 0}
                        page={page}
                        onPageChange={onChangePage}
                    />
                ),
            }}
        />
    );
};
