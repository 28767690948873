import { ClientSelectionType } from '~/enums/enums';
import { TimeUnit } from '~/schemaTypes';
import { ArticlePromotion, ArticlePromotionFormInput } from '../types/types';
import { getOffsets } from './minuteConverters';

export const getDefaultFormValues = (): Partial<ArticlePromotionFormInput> => ({
    articleId: '',
    priority: 5,
    clientSelectionType: ClientSelectionType.IncludeAllApps,
    includeApps: [],
    excludeApps: [],
    tagIds: [],
    type: undefined,
    startDate: new Date().toLocaleString(),
    endDate: new Date().toLocaleString(),
    timelineId: '',
    offsetDays: 0,
    offsetMonths: 0,
    offsetYears: 0,
    duration: 1,
});

const toClientSelectionType = ({
    excludeApps,
    includeApps,
}: ArticlePromotion): ClientSelectionType => {
    if (includeApps && includeApps.length > 0) return ClientSelectionType.IncludeCertainApps;
    if (excludeApps && excludeApps.length > 0) return ClientSelectionType.ExcludeCertainApps;
    return ClientSelectionType.IncludeAllApps;
};

export const toFormValuesFromArticlePromotion = (
    promotion?: ArticlePromotion,
): ArticlePromotionFormInput => {
    const offset = getOffsets(
        promotion?.offsetDays ?? 0,
        promotion?.offsetMonths ?? 0,
        promotion?.offsetYears ?? 0,
    );

    return {
        articleId: promotion?.articleId ?? '',
        title: promotion?.title ?? '',
        titleSp: promotion?.titleSp ?? '',
        priority: promotion?.priority ?? 5,
        clientSelectionType: promotion
            ? toClientSelectionType(promotion)
            : ClientSelectionType.IncludeAllApps,
        includeApps: promotion?.includeApps ?? [],
        excludeApps: promotion?.excludeApps ?? [],
        tagIds: promotion?.tagIds ?? [],
        type: promotion?.type ?? null,
        startDate: promotion?.startDate ?? new Date().toLocaleString(),
        endDate: promotion?.endDate ?? new Date().toLocaleString(),
        timelineId: promotion?.timelineId ?? '',
        offsetDays: offset.days,
        offsetMonths: offset.months,
        offsetYears: offset.years,
        offsetType: offset.type,
        duration: promotion?.duration ?? 1,
        durationTimeUnit: promotion?.durationTimeUnit || TimeUnit.Days,
    };
};
