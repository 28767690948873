import React, { useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Grid, MenuItem, TextField } from '@mui/material';
import { ON_SUCCESSFUL_ELIGIBILITY } from '~/views/ConfigDashboard/VCAffiliates/components/yupSchema';
import { onSuccessEligibilityLabel } from '~/views/ConfigDashboard/VCAffiliates/mappers';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { useVCAffiliateFormContext } from '~/views/ConfigDashboard/VCAffiliates/components/VCAffiliateFormContextProvider';

export const OnSuccessfulEligibilityForm = () => {
    const { setIsValid } = useVCAffiliateFormContext();
    const {
        control,
        formState: { errors },
        resetField,
    } = useFormContext();
    const onSuccessfulEligibilityWatch = useWatch({
        name: 'onSuccessfulEligibility',
        control,
        exact: true,
    });
    const routingURLWatch = useWatch({
        name: 'routingURL',
        control,
        exact: true,
    });
    const customSuccessTextWatch = useWatch({
        name: 'customSuccessText',
        control,
        exact: true,
    });

    const [routingURLError, setRoutingURLError] = useState(false);
    const [customSuccessTextError, setCustomSuccessTextError] = useState(false);

    const clearDependentValues = () => {
        resetField('routingURL');
        resetField('customSuccessText');
    };

    useEffect(() => {
        if (onSuccessfulEligibilityWatch === ON_SUCCESSFUL_ELIGIBILITY.ROUTE_DIRECTLY) {
            setRoutingURLError(!routingURLWatch);
            setIsValid(!!routingURLWatch);
        }
        if (onSuccessfulEligibilityWatch === ON_SUCCESSFUL_ELIGIBILITY.ROUTE_TO_LANDING) {
            setCustomSuccessTextError(!customSuccessTextWatch);
            setIsValid(!!customSuccessTextWatch);
        }
    }, [onSuccessfulEligibilityWatch, routingURLWatch, customSuccessTextWatch, setIsValid]);
    return (
        <OutlinedSection title="On Successful Eligibility">
            <Grid item>
                <Controller
                    control={control}
                    name="onSuccessfulEligibility"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            select
                            fullWidth
                            error={!!errors.onSuccessfulEligibility}
                            helperText={errors.onSuccessfulEligibility && 'Please select an option'}
                            onChange={e => {
                                clearDependentValues();
                                field.onChange(e);
                            }}
                        >
                            {Object.entries(ON_SUCCESSFUL_ELIGIBILITY).map(([key, value]) => (
                                <MenuItem key={key} value={value}>
                                    {onSuccessEligibilityLabel[value]}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                    rules={{ required: true }}
                />
            </Grid>
            {/* in case of Directly to URL OR Landing Page URL selected */}
            {onSuccessfulEligibilityWatch === ON_SUCCESSFUL_ELIGIBILITY.ROUTE_DIRECTLY ? (
                <Grid item>
                    <Controller
                        control={control}
                        name="routingURL"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label="Routing URL"
                                error={routingURLError}
                                helperText={routingURLError && 'Please, enter routing URL'}
                            />
                        )}
                        rules={{
                            required:
                                onSuccessfulEligibilityWatch ===
                                ON_SUCCESSFUL_ELIGIBILITY.ROUTE_DIRECTLY,
                        }}
                    />
                </Grid>
            ) : null}

            {/* in case of Landing Page URL selected */}
            {onSuccessfulEligibilityWatch === ON_SUCCESSFUL_ELIGIBILITY.ROUTE_TO_LANDING ? (
                <Grid item>
                    <Controller
                        control={control}
                        name="customSuccessText"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                fullWidth
                                multiline
                                rows={6}
                                label="Custom Success Text"
                                error={customSuccessTextError}
                                helperText={customSuccessTextError && 'Please enter a value'}
                            />
                        )}
                        rules={{
                            required:
                                onSuccessfulEligibilityWatch ===
                                ON_SUCCESSFUL_ELIGIBILITY.ROUTE_TO_LANDING,
                        }}
                    />
                </Grid>
            ) : null}
        </OutlinedSection>
    );
};
