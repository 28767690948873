import {
    Avatar,
    Badge,
    Button,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { primaryColor } from '~/theme/WildTheme';
import { QuestionAnswerOutlined } from '@mui/icons-material';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import { displayBirthday, isToday } from '~/helpers/dateHelpers';
import {
    AlertSeverity,
    FetchChatRoomsForMessageCenterQueryVariables,
    ReferencePages,
    useCareTeamMemberTypesV2ForPatientChatQuery,
    useFetchChatRoomsForMessageCenterLazyQuery,
    useFetchReferenceLinksForMessageCenterPageQuery,
} from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import history from 'history/browser';
import { getTextFromHTML } from '~/helpers/getTextFromHTML';
import { HEALTH_ADVOCATE_CARE_TEAM_MEMBER_TYPE_ID } from '~/constants';
import { useQueryParams } from '~/hooks';
import { useDashboard } from '../DashboardProvider';
import { FilterMessageCenterModal } from './FilterMessageCenterModal';
import ChatRoom from './ChatRoom/ChatRoom';
import { getMessageCenterWorkbook } from './MessageCenterExcelWorkbook';

type MessageCenterProps = {
    setLinks?: any;
};

const useStyles = makeStyles()({
    absoluteBar: {
        width: '100%',
        marginLeft: '-5px',
        height: 20,
        top: '-20px',
        position: 'sticky',
        background: '#fff',
        zIndex: 500,
        marginTop: '-20px',
    },
    root: {
        marginBottom: 20,
        paddingTop: 0,
        position: 'relative',
        display: 'grid',
        minHeight: 'calc(100vh-80px)',
        gridTemplateColumns: '300px auto',
        gridTemplateRows: 'auto calc(100vh - 20px - 70px - 40px - 20px) auto',
        gridTemplateAreas: `
            "header header"
            "sidebar main"
            "sidebar none"
        `,
        '& > .header': {
            gridArea: 'header',
            background: '#fff',
            top: 0,
            zIndex: 500,
            position: 'sticky',
            padding: '0px 10px',
            margin: 0,
            width: '100%',
            borderBottom: '1px lightgray solid',
            '& .searchField': {
                margin: 0,
                padding: 0,
            },
        },
        '& > .sidebar': {
            gridArea: 'sidebar',
            borderRight: '1px solid lightgray',
        },
        '& > .main': {
            top: 58,
            position: 'sticky',
            gridArea: 'main',
            padding: 0,
        },
    },
});

const MessageCenter: React.FC<MessageCenterProps> = ({ setLinks }) => {
    const { classes } = useStyles();
    const { id } = useParams<{ id: string }>();
    const [selectedChatRoomId, setSelectedChatRoomId] = useState<string>('');
    const [filterChats, setFilterChats] = useState<string>('');
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [careTeamMemberTypeId, setCareTeamMemberTypeId] = useState(
        HEALTH_ADVOCATE_CARE_TEAM_MEMBER_TYPE_ID,
    );

    const dashboard = useDashboard();

    const [urlFilters] = useQueryParams();
    const filterOpenConvs = !!urlFilters.get('filterOpenConversations');

    const suffix = 'MessageCenter';
    const filteredTagIds = localStorage
        .getItem(`filteredTags${suffix}`)
        ?.split(',')
        .filter(str => str.length > 1);
    const filteredAdvocate = localStorage.getItem(`filteredAdvocate${suffix}`);
    const filterTestData = localStorage.getItem('filterTestData') === 'true' ?? false;
    const filterOpenConversations =
        (filterOpenConvs || localStorage.getItem(`filterOpenConversations${suffix}`) === 'true') ??
        false;
    const filterFollowed = localStorage.getItem(`filterFollowed${suffix}`) === 'true' ?? false;

    const fetchChatRoomVariables: FetchChatRoomsForMessageCenterQueryVariables = useMemo(
        () => ({
            input: {
                ...(filteredTagIds && { tagIds: filteredTagIds }),
                ...(filteredAdvocate && { assignedTo: filteredAdvocate }),
                filterTestData,
                careTeamMemberTypeId: careTeamMemberTypeId !== 'all' ? careTeamMemberTypeId : '',
                filterOpenConversations,
                filterFollowedChatRooms: filterFollowed,
            },
        }),
        [
            filteredTagIds,
            filteredAdvocate,
            filterTestData,
            careTeamMemberTypeId,
            filterOpenConversations,
            filterFollowed,
        ],
    );

    const [fetchChatRoomData, { data: chatRoomData, loading: chatRoomDataLoading }] =
        useFetchChatRoomsForMessageCenterLazyQuery({
            variables: fetchChatRoomVariables,
            fetchPolicy: 'network-only',
            onCompleted: data => {
                const chatRooms = data?.currentUser?.chatRooms ?? [];
                if (chatRooms && !selectedChatRoomId) {
                    const chatRoom = chatRooms.find(r => r.id === id) ?? chatRooms[0];

                    history.replace(`/portal/message-center/${chatRoom?.id}`);

                    setSelectedChatRoomId(chatRoom?.id ?? '');
                }

                const hasSelectedChatRoom = chatRooms?.some(i => i.id === selectedChatRoomId);

                if (!hasSelectedChatRoom) {
                    setSelectedChatRoomId(chatRooms[0]?.id ?? '');
                    setFilterChats('');
                }
            },
            pollInterval: 10000,
        });

    const { data: jobs, loading: jobsLoading } = useCareTeamMemberTypesV2ForPatientChatQuery({
        variables: {
            input: {
                filter: {
                    fields: {
                        isJob: true,
                    },
                },
            },
        },
    });

    useEffect(() => {
        fetchChatRoomData();
    }, [dashboard.orgId, fetchChatRoomData]);

    const { data: refereneLinksData, loading: refereneLinksLoading } =
        useFetchReferenceLinksForMessageCenterPageQuery({
            variables: {
                input: {
                    page: ReferencePages.MessageCenter,
                },
            },
        });

    useEffect(() => {
        if (refereneLinksData && setLinks) {
            setLinks(refereneLinksData.getReferenceLinksForPage);
        }
    }, [refereneLinksData, setLinks]);

    // I think this was here for when switching orgs in message center it would not switch
    // chatrooms so a patient in the other orgs message center would appear -Mason
    useEffect(() => {
        if (!chatRoomData?.currentUser?.currentOrg) {
            setSelectedChatRoomId('');
        }
    }, [chatRoomData?.currentUser?.currentOrg]);

    const handleDownloadMessageCenterMessages = () => {
        const downloadErrorMessage = 'Unable to download Message Center spreadsheet.';
        if (chatRoomData && !chatRoomDataLoading) {
            getMessageCenterWorkbook(chatRoomData);
        } else if (!chatRoomData) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `${downloadErrorMessage} Chatroom data is not defined.`,
            });
        } else {
            TriggerGlobalAlert({ severity: AlertSeverity.Error, message: downloadErrorMessage });
        }
    };

    const handleChangeChatRoom = (event: SelectChangeEvent) => {
        setCareTeamMemberTypeId(event.target.value);
    };

    if (chatRoomDataLoading || refereneLinksLoading || jobsLoading) {
        return <Loading />;
    }

    return (
        <>
            <div className={classes.absoluteBar} />
            <Paper className={classes.root}>
                <div className="flex flex-row justify-between items-center w-full header">
                    <div className="flex flex-col gap-2 mb-4">
                        <Typography variant="h5">Message Center</Typography>
                        <div className="flex flex-row gap-2">
                            <Select
                                value={careTeamMemberTypeId}
                                label="Job"
                                variant="outlined"
                                onChange={handleChangeChatRoom}
                                className="min-w-52"
                            >
                                <MenuItem value={HEALTH_ADVOCATE_CARE_TEAM_MEMBER_TYPE_ID}>
                                    Health Advocate
                                </MenuItem>
                                {jobs?.careTeamMemberTypesV2.results
                                    .filter(
                                        job => job.id !== HEALTH_ADVOCATE_CARE_TEAM_MEMBER_TYPE_ID,
                                    )
                                    .map(job => (
                                        <MenuItem key={job.id} value={job.id}>
                                            {job.name}
                                        </MenuItem>
                                    ))}
                                <MenuItem value="all">All</MenuItem>
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-row gap-2 items-end">
                        <Badge
                            invisible={
                                parseInt(
                                    localStorage.getItem(`filtersAppliedCount${suffix}`) ?? '',
                                    10,
                                ) === 0
                            }
                            badgeContent={localStorage.getItem(`filtersAppliedCount${suffix}`)}
                            color="error"
                            style={{
                                backgroundColor: primaryColor,
                                color: 'white',
                                fontSize: '1rem',
                                borderRadius: '1rem',
                            }}
                        >
                            <Button
                                onClick={() => setOpenFilterModal(true)}
                                variant="contained"
                                color="primary"
                            >
                                Filter Message Center
                            </Button>
                        </Badge>
                        <Button
                            onClick={handleDownloadMessageCenterMessages}
                            variant="contained"
                            color="secondary"
                        >
                            Export Messages
                        </Button>
                        <TextField
                            className="searchField"
                            id="chatRoomSearch"
                            label="Patient Search"
                            margin="dense"
                            variant="outlined"
                            value={filterChats}
                            onChange={e => setFilterChats(e.target.value)}
                        />
                    </div>
                </div>
                <div className="sidebar">
                    <Grid container direction="column" alignItems="stretch">
                        {(chatRoomData?.currentUser?.chatRooms?.length ?? 0) === 0 && (
                            <Grid
                                container
                                spacing={1}
                                style={{ marginTop: 150, padding: 40, textAlign: 'center' }}
                            >
                                <Grid item>
                                    <QuestionAnswerOutlined />
                                    <Typography variant="h6">
                                        No messages yet for this practice
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        <List disablePadding className="list">
                            {(chatRoomData?.currentUser?.chatRooms ?? [])
                                // FIXME move this to the DB query
                                .filter(
                                    ({ patient }) =>
                                        patient.firstName
                                            .toLowerCase()
                                            .startsWith(filterChats.toLowerCase()) ||
                                        patient.lastName
                                            .toLowerCase()
                                            .startsWith(filterChats.toLowerCase()) ||
                                        `${patient.firstName.toLowerCase()} ${patient.lastName.toLowerCase()}`.startsWith(
                                            filterChats.toLowerCase(),
                                        ),
                                )
                                .map(
                                    ({
                                        id,
                                        patient,
                                        careTeamMemberType,
                                        latestMessage,
                                        numberOfUnreadMessages,
                                    }) => {
                                        const { createdAt, text, attachments } =
                                            latestMessage ?? {};
                                        return (
                                            <React.Fragment key={id}>
                                                <ListItem
                                                    className="listItem"
                                                    style={{ maxWidth: '100%' }}
                                                    button
                                                    onClick={() => {
                                                        history.replace(
                                                            `/portal/message-center/${id}`,
                                                        );
                                                        setSelectedChatRoomId(id);
                                                    }}
                                                >
                                                    <ListItemAvatar>
                                                        <Badge
                                                            badgeContent={numberOfUnreadMessages}
                                                            color="error"
                                                            overlap="circular"
                                                            anchorOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >
                                                            <Avatar>
                                                                {patient.firstName[0]}
                                                                {patient.lastName[0]}
                                                            </Avatar>
                                                        </Badge>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primaryTypographyProps={{
                                                            noWrap: true,
                                                            color: 'primary',
                                                        }}
                                                        primary={
                                                            <Grid
                                                                container
                                                                spacing={0}
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    flexWrap: 'nowrap',
                                                                }}
                                                            >
                                                                <Grid
                                                                    item
                                                                    container
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                    }}
                                                                >
                                                                    <Typography variant="subtitle1">
                                                                        <strong>
                                                                            {`${patient.firstName} ${patient.lastName}`
                                                                                .length > 13
                                                                                ? `${`${patient.firstName} ${patient.lastName}`.slice(
                                                                                      0,
                                                                                      12,
                                                                                  )}...`
                                                                                : `${patient.firstName} ${patient.lastName}`}
                                                                        </strong>
                                                                    </Typography>
                                                                    <Typography variant="subtitle1">
                                                                        {`${patient?.practice?.name} - ${careTeamMemberType.name}`
                                                                            ?.length > 18
                                                                            ? `${`${patient?.practice?.name} - ${careTeamMemberType.name}`
                                                                                  .slice(0, 17)
                                                                                  .trim()}...`
                                                                            : `${patient?.practice?.name} - ${careTeamMemberType.name}`}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        style={{
                                                                            color: 'gray',
                                                                        }}
                                                                    >
                                                                        DOB:{' '}
                                                                        {displayBirthday({
                                                                            isoDateStr:
                                                                                patient.birthDate,
                                                                        })}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    {createdAt && (
                                                                        <Typography
                                                                            variant="subtitle1"
                                                                            style={{
                                                                                color: 'gray',
                                                                            }}
                                                                        >
                                                                            {isToday(
                                                                                new Date(
                                                                                    createdAt ?? '',
                                                                                ),
                                                                            )
                                                                                ? format(
                                                                                      new Date(
                                                                                          createdAt ??
                                                                                              '',
                                                                                      ),
                                                                                      'h:mm aaa',
                                                                                  )
                                                                                : format(
                                                                                      new Date(
                                                                                          createdAt ??
                                                                                              '',
                                                                                      ),
                                                                                      'MMM d',
                                                                                  )}
                                                                        </Typography>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                        secondary={
                                                            <Grid
                                                                container
                                                                justifyContent="space-between"
                                                            >
                                                                <Typography variant="subtitle1">
                                                                    {/* eslint-disable-next-line no-nested-ternary */}
                                                                    {text
                                                                        ? getTextFromHTML(text)
                                                                              .length > 20
                                                                            ? `${getTextFromHTML(
                                                                                  text,
                                                                              )
                                                                                  .slice(0, 20)
                                                                                  .trim()}...`
                                                                            : getTextFromHTML(text)
                                                                        : attachments
                                                                        ? `${attachments?.length} Attachments Sent`
                                                                        : 'No messages'}
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                    />
                                                </ListItem>
                                                <Divider className="divider" />
                                            </React.Fragment>
                                        );
                                    },
                                )}
                        </List>
                    </Grid>
                    <FilterMessageCenterModal
                        suffix={suffix}
                        open={openFilterModal}
                        onClose={() => setOpenFilterModal(false)}
                        filters={{ openConversations: filterOpenConversations }}
                        currentOrgId={chatRoomData?.currentUser?.currentOrg?.id}
                    />
                </div>
                {selectedChatRoomId && (
                    <ChatRoom
                        chatRoomId={selectedChatRoomId}
                        fetchChatRoomsInput={fetchChatRoomVariables}
                    />
                )}
            </Paper>
        </>
    );
};

export default MessageCenter;
