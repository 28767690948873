import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
    AlertSeverity,
    OrderByDirectionEnum,
    useAffiliateCarePatientsSubmittedClaimsV2Query,
    AffiliateInvoicePaymentStatus,
} from '~/schemaTypes';
import useAffiliateCareView from '~/hooks/useAffiliateCareView';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { PAGESIZE } from '~/constants';
import { Button, IconButton, Link, TablePagination, Tooltip, Typography } from '@mui/material';
import copy from 'copy-to-clipboard';
import { TriggerGlobalAlert } from '~/state';
import { InfoOutlined } from '@mui/icons-material';
import MaterialTable, { MTableAction } from '@material-table/core';
import moment from 'moment/moment';
import useUserPermissions from '~/hooks/useUserPermissions';

type PaymentStatusProps = {
    status: AffiliateInvoicePaymentStatus;
};

const PaymentStatus: React.FC<PaymentStatusProps> = React.memo(({ status }) => {
    return status === AffiliateInvoicePaymentStatus.PaidInFull ? (
        <Typography color="green">Paid</Typography>
    ) : (
        <Typography>{status}</Typography>
    );
});

const PatientClaims = () => {
    const { userAffiliateId } = useAffiliateCareView();
    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(PAGESIZE);
    const [searchInput, setSearchInput] = useState<string>('');
    const tableRef = useRef<HTMLDivElement>(null);
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement>, pageNumber: number) => {
        setPage(pageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    const onSearchChange = useCallback((searchString: string) => {
        setSearchInput(searchString);
    }, []);
    const fetchVariables = useMemo(() => {
        return {
            input: {
                pagination: {
                    skip: page * rowsPerPage,
                    limit: rowsPerPage,
                },
                orderBy: {
                    field: 'createdAt',
                    order: OrderByDirectionEnum.Desc,
                },
                filter: {
                    fields: {
                        affiliateId: userAffiliateId ?? 'no-data',
                    },
                },
                ...(searchInput && { search: searchInput.trim() }),
            },
        };
    }, [page, rowsPerPage, userAffiliateId, searchInput]);
    const { data, loading } = useAffiliateCarePatientsSubmittedClaimsV2Query({
        variables: fetchVariables,
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    return (
        <div ref={tableRef}>
            <MaterialTable
                title="Patient Claims"
                columns={[
                    {
                        title: 'id',
                        field: 'id',
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'Claim Id Copied to Clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                        sorting: false,
                        customFilterAndSearch: () => true,
                    },
                    {
                        title: 'Invoice ID',
                        field: 'affiliateInvoiceId',
                        render: ({ affiliateInvoice }) =>
                            affiliateInvoice && (
                                <Link
                                    to={`/affiliate-care/invoices/${affiliateInvoice.id}`}
                                    component={RouterLink}
                                >
                                    {affiliateInvoice.id}
                                </Link>
                            ),
                        sorting: false,
                        searchable: false,
                    },
                    {
                        title: 'Date Submitted',
                        field: 'createdAt',
                        render: ({ createdAt }) =>
                            moment(createdAt).local().format('MM-DD-YYYY hh:mm A'),
                        sorting: false,
                        searchable: false,
                    },
                    {
                        title: 'Patient First Name',
                        field: 'firstName',
                        render: ({ patient }) =>
                            patient && (
                                <Link
                                    to={`/affiliate-care/patients/${patient.id}`}
                                    component={RouterLink}
                                >
                                    {patient.firstName}
                                </Link>
                            ),
                        sorting: false,
                        searchable: false,
                    },
                    {
                        title: 'Patient Last Name',
                        field: 'lastName',
                        render: ({ patient }) =>
                            patient && (
                                <Link
                                    to={`/affiliate-care/patients/${patient.id}`}
                                    component={RouterLink}
                                >
                                    {patient.lastName}
                                </Link>
                            ),
                        sorting: false,
                        searchable: false,
                    },
                    {
                        title: 'Date Of Service',
                        field: 'ServiceFrom_1',
                        render: ({ ServiceFrom_1: visitDate }) =>
                            visitDate && moment(visitDate, 'M/D/YYYY').format('MM-DD-YYYY'),
                        sorting: false,
                        searchable: false,
                    },
                    {
                        title: 'Service Type',
                        field: 'service_type',
                        render: ({ service_type: serviceType }) => (
                            <Typography>{serviceType}</Typography>
                        ),
                        sorting: false,
                        searchable: false,
                    },
                    {
                        title: 'Payment Status',
                        field: 'affiliateInvoicePaymentStatus',
                        render: ({ affiliateInvoice }) =>
                            affiliateInvoice && (
                                <PaymentStatus status={affiliateInvoice.paymentStatus} />
                            ),
                        sorting: false,
                        searchable: false,
                    },
                ]}
                data={data?.claimSubmittedGlobalSearch?.results ?? []}
                options={{
                    search: true,
                    paging: true,
                    pageSize: rowsPerPage,
                    pageSizeOptions: [10, PAGESIZE, 50],
                    debounceInterval: 500,
                }}
                isLoading={loading}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onSearchChange={onSearchChange}
                totalCount={data?.claimSubmittedGlobalSearch?.total ?? 0}
                page={page}
                actions={[
                    {
                        isFreeAction: true,
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onClick: () => {},
                        hidden: Boolean(!pagePermissions?.AffiliateCareClaimsSubmission.Edit),
                        icon: () => (
                            <Button
                                variant="contained"
                                onClick={() => history('/affiliate-care/claims/build-claims')}
                            >
                                Submit Claim
                            </Button>
                        ),
                    },
                ]}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={data?.claimSubmittedGlobalSearch?.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                    Action: props => {
                        const {
                            action: { isFreeAction, icon, hidden },
                        } = props;
                        if (isFreeAction && !hidden) {
                            return icon();
                        }
                        return <MTableAction {...props} />;
                    },
                }}
            />
        </div>
    );
};

export default PatientClaims;
