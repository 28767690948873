import React from 'react';
import { Grid, Typography } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import useStyles from './styles';

type InfoBoxProps = {
    Icon: SvgIconComponent;
    infoText: string;
    infoTitle: string;
};

const InfoBox: React.FC<InfoBoxProps> = props => {
    const { Icon, infoText, infoTitle } = props;
    const { classes } = useStyles();
    return (
        <Grid container className={classes.container}>
            <Grid
                container
                item
                className={classes.iconContainer}
                xs="auto"
                alignItems="center"
                justifyContent="center"
            >
                <Icon className={classes.icon} />
            </Grid>
            <Grid container item className={classes.infoContainer} xs>
                <Grid item xs={12} alignSelf="flex-end">
                    <Typography variant="body2">{infoTitle}</Typography>
                </Grid>
                <Grid item xs={12} alignSelf="flex-start">
                    <Typography variant="h6" color="primary">
                        {infoText.toUpperCase()}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default InfoBox;
