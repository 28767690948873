import { AdvocateTaskTypeStatus, OrderByDirectionEnum } from '~/schemaTypes';

export type AdvocateTaskListFiltersType = {
    assignedTo?: string;
    status?: AdvocateTaskTypeStatus | '';
    due?: string;
    customDue?: string;
    importance?: number[];
    createdBy?: string;
    patients?: string[];
    type?: string;
    label?: string;
    description?: string;
    filterTestData: boolean;
    createdAt?: string;
    completedAt?: string;
};

export enum AdvocateTaskListSortingFields {
    dueDate = 'dueDate',
    createdAt = 'createdAt',
    completedAt = 'completedAt',
    priority = 'priority',
    assignedTo = 'assignedTo',
}

export interface IAdvocateTaskListOrderChange {
    field: AdvocateTaskListSortingFields;
    direction: OrderByDirectionEnum;
    isInit: boolean;
}
