import { faEye, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, IconButton, TablePagination, Tooltip } from '@mui/material';
import { Add, InfoOutlined, Visibility } from '@mui/icons-material';
import MaterialTable from '@material-table/core';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomFilter, { IFilterChange } from '~/components/CustomFilter/CustomFilter';
import WhereUsedModal from '~/components/WhereUsedModal/WhereUsedModal';
import { CLOUDFRONT_IMAGE_SOURCE, PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    AlertSeverity,
    ContentType,
    OrderByDirectionEnum,
    SimpleContentListDocument,
    SimpleContentListFilterInput,
    SimpleContentListQuery,
    SimpleContentListQueryVariables,
    useDeleteSimpleContentMutation,
    useSimpleContentListQuery,
    useSimpleContentWhereUsedLazyQuery,
} from '~/schemaTypes';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';
import _ from 'lodash';
import copy from 'copy-to-clipboard';
import { SimpleContentModal } from './components/SimpleContentModal';
import { useStyles } from './styles';

type SimpleContent = NonNullable<SimpleContentListQuery['simpleContentsV2']>['results'][0];

const renderContentType = (
    contentType: string,
    contentTypeName: string | null,
    content: string | null,
) => (
    <>
        {contentTypeName}
        {contentType === ContentType.Image && (
            <Tooltip
                title={
                    <img
                        width="200"
                        src={`${CLOUDFRONT_IMAGE_SOURCE}${content}`}
                        alt="Simple content media"
                    />
                }
            >
                <IconButton size="large">
                    <Visibility />
                </IconButton>
            </Tooltip>
        )}
    </>
);

const SimpleContents: React.FC = () => {
    const { classes } = useStyles();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const { id: contentId } = useParams<{ id: string }>();
    const [showUsageModal, setShowUsageModal] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [filter, setFilter] = useState<IFilterChange<SimpleContent> | null>(null);
    const [content, setContent] = useState<SimpleContent>();

    const simpleContentListQueryVariables: SimpleContentListQueryVariables = {
        input: {
            pagination: {
                skip: page * rowsPerPage,
                limit: rowsPerPage,
            },
            filter: filter as SimpleContentListFilterInput | null,
            orderBy: { field: 'name', order: OrderByDirectionEnum.Asc },
        },
    };

    const { data, loading } = useSimpleContentListQuery({
        variables: simpleContentListQueryVariables,
    });
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedItem, setItem] = useState<SimpleContent | null>(null);
    const { pagePermissions } = useUserPermissions();
    useEffect(() => {
        if (contentId) {
            const item = _.cloneDeep(data?.simpleContentsV2.results.find(c => c.id === contentId));
            if (item) {
                setItem(item);
                setModalOpen(true);
            }
        }
    }, [contentId, data?.simpleContentsV2.results]);
    const [whereUsed, { data: whereUsedData, loading: whereUsedLoading }] =
        useSimpleContentWhereUsedLazyQuery();

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const [deleteContent, { loading: deleteContentLoading }] = useDeleteSimpleContentMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: SimpleContentListDocument,
                variables: simpleContentListQueryVariables,
            },
        ],
    });

    const handleDelete = () => {
        TriggerGlobalConfirm({
            message: `Do you really want to delete '${content?.name}'?`,
            callback: () => {
                deleteContent({
                    variables: {
                        input: {
                            id: content?.id,
                            isImage: content?.contentType === ContentType.Image,
                        },
                    },
                });
            },
        });
        setShowUsageModal(false);
    };
    const handleShowUsage = (content: SimpleContent) => {
        whereUsed({
            variables: {
                input: {
                    id: content?.id,
                    isImage: content?.contentType === ContentType.Image,
                },
            },
        });
        setShowUsageModal(true);
    };

    return (
        <>
            <div className={classes.root} ref={tableRef}>
                <MaterialTable<SimpleContent>
                    title="Simple Content"
                    icons={tableIcons}
                    isLoading={loading || deleteContentLoading || whereUsedLoading}
                    data={data?.simpleContentsV2.results ?? []}
                    components={{
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                count={data?.simpleContentsV2.total ?? 0}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        ),
                    }}
                    actions={[
                        {
                            onClick: event => {
                                event.preventDefault();
                            },
                            hidden: !pagePermissions?.Articles.Edit,
                            icon: () => (
                                <CustomFilter<SimpleContent>
                                    setFilter={setFilter}
                                    fields={['name']}
                                />
                            ),
                            isFreeAction: true,
                        },
                        {
                            onClick: () => setModalOpen(true),
                            icon: () => <Add />,
                            tooltip: 'Add Simple Content',
                            isFreeAction: true,
                            hidden: !pagePermissions?.SimpleContent.Edit,
                        },
                        {
                            onClick: (_, item) => {
                                const isSingleItemSelected = !Array.isArray(item);
                                if (isSingleItemSelected) {
                                    setModalOpen(true);
                                    setItem(item);
                                }
                            },
                            icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                            tooltip: 'Edit Simple Content',
                            hidden: !pagePermissions?.SimpleContent.Edit,
                        },
                        {
                            onClick: (_e, content) => {
                                setContent(content as SimpleContent);
                                handleShowUsage(content as SimpleContent);
                            },
                            icon: () => <FontAwesomeIcon icon={faEye} />,
                            tooltip: 'View References',
                        },
                    ]}
                    columns={[
                        {
                            title: 'Id',
                            field: 'id',
                            render: ({ id }) => (
                                <Tooltip title={id}>
                                    <IconButton
                                        onClick={() => {
                                            copy(`${id}`);
                                            TriggerGlobalAlert({
                                                message: 'Id copied to clipboard',
                                                severity: AlertSeverity.Success,
                                            });
                                        }}
                                        size="large"
                                    >
                                        <InfoOutlined />
                                    </IconButton>
                                </Tooltip>
                            ),
                        },
                        {
                            title: 'Name',
                            field: 'name',
                            sorting: false,
                        },
                        {
                            title: 'Type',
                            field: 'contentTypeName',
                            sorting: false,
                            render: ({ contentType, contentTypeName, content }) =>
                                renderContentType(
                                    contentType,
                                    contentTypeName ?? null,
                                    content ?? null,
                                ),
                        },
                    ]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    options={{
                        pageSize: rowsPerPage,
                        pageSizeOptions: [25, 50, 100],
                        search: false,
                    }}
                />
            </div>
            {isModalOpen && (
                <SimpleContentModal
                    item={selectedItem || null}
                    onClose={() => {
                        setModalOpen(false);
                        setItem(null);
                    }}
                />
            )}
            <Dialog scroll="paper" open={!whereUsedLoading && showUsageModal}>
                <WhereUsedModal
                    onClose={() => setShowUsageModal(false)}
                    onDelete={handleDelete}
                    title={`Usage for ${content?.name}`}
                    canDelete={pagePermissions?.SimpleContent.Delete || false}
                    usageData={whereUsedData?.simpleContentWhereUsed?.dependencies || []}
                    permissions={pagePermissions}
                />
            </Dialog>
        </>
    );
};

export default SimpleContents;
