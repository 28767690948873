import { ON_SUCCESSFUL_ELIGIBILITY } from '~/views/ConfigDashboard/VCAffiliates/components/yupSchema';
import { IVCAffiliatesFormValues } from '~/views/ConfigDashboard/VCAffiliates/interfaces/IVCAffiliatesFormValues';
import {
    FetchVirtualCareAffiliateByIdQuery,
    SupportMethodType,
    VirtualCareAffiliatesUpdateInput,
    OnSuccessfulZipCodeMatch,
    GetVendorByIdFromExternalServiceQuery,
} from '~/schemaTypes';

export const onSuccessEligibilityLabel = {
    [ON_SUCCESSFUL_ELIGIBILITY.ROUTE_DIRECTLY]: 'Route Directly To URL',
    [ON_SUCCESSFUL_ELIGIBILITY.ROUTE_TO_LANDING]: 'Route To Success Screen',
};

export const mapToFormValues = (
    data?: FetchVirtualCareAffiliateByIdQuery,
    vendorData?: GetVendorByIdFromExternalServiceQuery,
): IVCAffiliatesFormValues => ({
    internalName: data?.virtualCareAffiliateById?.data?.internalName || '',
    externalName: data?.virtualCareAffiliateById?.data?.externalName || '',
    contactName: data?.virtualCareAffiliateById?.data?.contactName || '',
    contactEmail: data?.virtualCareAffiliateById?.data?.contactEmail || '',
    contactPhoneNumber: data?.virtualCareAffiliateById?.data?.contactPhoneNumber || '',
    supportMethodType: data?.virtualCareAffiliateById?.data?.supportMethodType || '',
    supportMethodValue: data?.virtualCareAffiliateById?.data?.supportMethodValue || '',
    onSuccessfulEligibility: data?.virtualCareAffiliateById?.data?.onSuccessfulEligibility || '',
    routingURL: data?.virtualCareAffiliateById?.data?.routingURL || '',
    phoneNumberForScheduling: data?.virtualCareAffiliateById?.data?.phoneNumberForScheduling || '',
    emailAddressForScheduling:
        data?.virtualCareAffiliateById?.data?.emailAddressForScheduling || '',
    customSuccessText: data?.virtualCareAffiliateById?.data?.customSuccessText || '',
    notificationEmail: data?.virtualCareAffiliateById?.data?.notificationEmail?.join(',') || '',
    notifyCompanyWhenPatientPassesEligibility:
        data?.virtualCareAffiliateById?.data?.notifyCompanyWhenPatientPassesEligibility || false,
    onSuccessfulZipCodeMatch:
        data?.virtualCareAffiliateById?.data?.onSuccessfulZipCodeMatch ||
        OnSuccessfulZipCodeMatch.ToSuccessScreen,
    zipCodeMatchCustomSuccessString:
        data?.virtualCareAffiliateById?.data?.zipCodeMatchCustomSuccessString || '',
    BillDotComNameOnCheck: vendorData?.getVendorById?.nameOnCheck || '',
    BillDotComAddress1: vendorData?.getVendorById?.address1 || '',
    BillDotComAddress2: vendorData?.getVendorById?.address2 || '',
    BillDotComAddressCity: vendorData?.getVendorById?.addressCity || '',
    BillDotComAddressState: vendorData?.getVendorById?.addressState || '',
    BillDotComAddressZip: vendorData?.getVendorById?.addressZip || '',
    BillDotComVendorId: data?.virtualCareAffiliateById?.data?.BillDotComVendorId || '',
    website: data?.virtualCareAffiliateById?.data?.website || '',
    inviteAffiliate: data?.virtualCareAffiliateById?.data?.inviteAffiliate || false,
});

export const mapFromFormToInput = (
    data: IVCAffiliatesFormValues,
): VirtualCareAffiliatesUpdateInput => ({
    ...data,
    supportMethodType: data.supportMethodType as SupportMethodType,
    notificationEmail: data.notificationEmail
        ?.split(',')
        .map(email => email.trim())
        .filter(Boolean),
});
