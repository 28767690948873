import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Add } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import MaterialTable from '@material-table/core';
import { TablePagination } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomFilter, { IFilterChange } from '~/components/CustomFilter/CustomFilter';
import Loading from '~/components/Loading/Loading';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    ArticlePromotionListFilterInput,
    ArticlePromotionsForArticlePromotionListPageDocument,
    ArticlePromotionsForArticlePromotionListPageQueryVariables,
    ArticlePromotionUpdateInputForV2Query,
    useArticlePromotionsForArticlePromotionListPageQuery,
    useDeleteArticlePromotionMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';

const useStyles = makeStyles()({
    root: {
        position: 'relative',
        height: '100%',
        '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
            opacity: '0',
        },
    },
});

const ArticlePromotionList: React.FC = () => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();
    const tableRef = useRef<HTMLDivElement>(null);
    const DEFAULT_PAGE_NUMBER = 0;
    const [page, setPage] = useState<number>(DEFAULT_PAGE_NUMBER);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [filter, setFilter] =
        useState<IFilterChange<ArticlePromotionUpdateInputForV2Query> | null>(null);

    const articlePromotionsListQueryVariables: ArticlePromotionsForArticlePromotionListPageQueryVariables =
        {
            input: {
                pagination: {
                    skip: page * rowsPerPage,
                    limit: rowsPerPage,
                },
                filter: filter as ArticlePromotionListFilterInput | null,
            },
        };

    const { data: articlePromotions, loading: promotionArticleLoading } =
        useArticlePromotionsForArticlePromotionListPageQuery({
            variables: articlePromotionsListQueryVariables,
        });

    const [deleteItem, { loading: deleteItemLoading }] = useDeleteArticlePromotionMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: ArticlePromotionsForArticlePromotionListPageDocument,
                variables: articlePromotionsListQueryVariables,
            },
        ],
    });

    const handleDelete = (id: string) => {
        TriggerGlobalConfirm({
            message: `Are you sure you want to delete this Promotion?`,
            callback: () => {
                deleteItem({ variables: { input: { id } } });
            },
        });
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    if (deleteItemLoading) return <Loading />;
    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable
                title="Article Promotions"
                icons={tableIcons}
                isLoading={promotionArticleLoading}
                data={articlePromotions?.articlePromotionsV2.results ?? []}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={articlePromotions?.articlePromotionsV2.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                }}
                actions={[
                    {
                        onClick: event => {
                            event.preventDefault();
                        },
                        hidden: !pagePermissions?.Articles.Edit,
                        icon: () => (
                            <CustomFilter<ArticlePromotionUpdateInputForV2Query>
                                setFilter={searchQuery => {
                                    setFilter(searchQuery);
                                    setPage(DEFAULT_PAGE_NUMBER);
                                }}
                                fields={['articleName']}
                                fieldNames={{
                                    articleName: 'article',
                                }}
                            />
                        ),
                        isFreeAction: true,
                    },
                    {
                        onClick: () => history('/app-config/articlepromotions/new'),
                        hidden: !pagePermissions?.ArticlePromotions.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add Article Promotion',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, { id }: any) => history(`/app-config/articlepromotions/${id}`),
                        hidden: !pagePermissions?.ArticlePromotions.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Article Promotion',
                    },
                    {
                        onClick: (_, { id }: any) => handleDelete(id),
                        hidden: !pagePermissions?.ArticlePromotions.Delete,
                        icon: () => <FontAwesomeIcon icon={faTrash} />,
                        tooltip: 'Delete Article Promotion',
                    },
                ]}
                columns={[
                    {
                        title: 'Article',
                        field: 'article.name',
                        defaultSort: 'asc',
                    },
                    {
                        title: 'Priority',
                        field: 'priority',
                    },
                    {
                        title: 'Type',
                        field: 'typeName',
                    },
                    {
                        title: 'Timeline',
                        field: 'timeline.label.en',
                    },
                ]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{ pageSize: rowsPerPage, pageSizeOptions: [25, 50, 100], search: false }}
            />
        </div>
    );
};

export default ArticlePromotionList;
