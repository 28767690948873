// This component has a UI Test
import { faEye, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Dialog, TablePagination } from '@mui/material';
import { Add } from '@mui/icons-material';
import MaterialTable from '@material-table/core';
import React, { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomFilter, { IFilterChange } from '~/components/CustomFilter/CustomFilter';
import WhereUsedModal from '~/components/WhereUsedModal/WhereUsedModal';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    ConfigExpressionFilterInput,
    FetchConfigExpressionListV2Document,
    FetchConfigExpressionListV2Query,
    FetchConfigExpressionListV2QueryVariables,
    useDeleteExpressionMutation,
    useExpressionWhereUsedLazyQuery,
    useFetchConfigExpressionListV2Query,
} from '~/schemaTypes';
import { ExpressionEnum } from '~/selectors';
import { TriggerGlobalConfirm } from '~/state';
import { PAGESIZE } from '~/constants';
import { FiltersModal } from './components/FilterModal';
import useStyles from './styles';

export type ConfigExpression = NonNullable<
    FetchConfigExpressionListV2Query['configExpressionsV2']
>['results'][0];

const Expressions: React.FC = () => {
    const { classes } = useStyles();
    const history = useNavigate();
    const tableRef = useRef<HTMLDivElement>(null);
    const { pagePermissions } = useUserPermissions();
    const [page, setPage] = useState<number>(0);
    const [showUsageModal, setShowUsageModal] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [filter, setFilter] = useState<IFilterChange<ConfigExpression> | null>(null);
    const [openFiltersModal, setOpenFiltersModal] = useState<boolean>(false);
    const expressionsFilteredProfileDefs = localStorage.getItem('expressionsFilteredProfileDefs');
    const [selectedItem, setSelectedItem] = useState<ConfigExpression>();

    const profileDefIds = useMemo(() => {
        let fields;
        if (expressionsFilteredProfileDefs) {
            fields = expressionsFilteredProfileDefs.split(',') || [''];
        }
        return fields;
    }, [expressionsFilteredProfileDefs]);

    const fetchExpressionsForPatientsPageQueryVariables: FetchConfigExpressionListV2QueryVariables =
        {
            input: {
                pagination: {
                    skip: page * rowsPerPage,
                    limit: rowsPerPage,
                },
                filter: {
                    fields: {
                        profileDefIds,
                        ...filter?.fields,
                    },
                } as ConfigExpressionFilterInput | null,
            },
        };

    const { data: expressionData, loading: expressionsLoading } =
        useFetchConfigExpressionListV2Query({
            variables: fetchExpressionsForPatientsPageQueryVariables,
            fetchPolicy: 'network-only',
        });

    const [whereUsed, { data: whereUsedData, loading: whereUsedLoading }] =
        useExpressionWhereUsedLazyQuery();

    const [deleteExpression, { loading: deleteExpressionLoading }] = useDeleteExpressionMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: FetchConfigExpressionListV2Document,
                variables: fetchExpressionsForPatientsPageQueryVariables,
            },
        ],
    });

    const handleDelete = () => {
        TriggerGlobalConfirm({
            message: `Do you really want to delete '${selectedItem?.name}'?`,
            callback: () => {
                deleteExpression({ variables: { id: selectedItem?.id } });
            },
        });
        setShowUsageModal(false);
    };
    const handleShowUsage = (id: string) => {
        whereUsed({ variables: { id } });
        setShowUsageModal(true);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    return (
        <div className={classes.root} data-test={ExpressionEnum.CONTAINER} ref={tableRef}>
            <MaterialTable<ConfigExpression>
                title="Expressions"
                icons={tableIcons}
                isLoading={expressionsLoading || whereUsedLoading || deleteExpressionLoading}
                data={expressionData?.configExpressionsV2.results ?? []}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={expressionData?.configExpressionsV2.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                }}
                actions={[
                    {
                        tooltip: 'Filter',
                        onClick: event => {
                            event.preventDefault();
                        },
                        icon: () => (
                            <Badge
                                badgeContent={
                                    localStorage
                                        .getItem('expressionsFilteredProfileDefs')
                                        ?.split(',')
                                        .filter(str => str.length > 1).length as number
                                }
                                color="error"
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setOpenFiltersModal(true)}
                                >
                                    Filter
                                </Button>
                            </Badge>
                        ),
                        isFreeAction: true,
                    },
                    {
                        onClick: event => {
                            event.preventDefault();
                        },
                        icon: () => (
                            <CustomFilter<ConfigExpression>
                                setFilter={setFilter}
                                fields={['description', 'name']}
                            />
                        ),
                        isFreeAction: true,
                    },
                    {
                        onClick: () => history('/app-config/expressions/new'),
                        icon: () => <Add />,
                        tooltip: 'Add Expression',
                        isFreeAction: true,
                        hidden: !pagePermissions?.Expressions.Edit,
                    },
                    {
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Expression',
                        hidden: !pagePermissions?.Expressions.Edit,
                        onClick: (e, rowData) =>
                            history(`/app-config/expressions/${(rowData as ConfigExpression).id}`),
                    },
                    {
                        onClick: (_e, item) => {
                            setSelectedItem(item as ConfigExpression);
                            handleShowUsage((item as ConfigExpression).id);
                        },
                        icon: () => <FontAwesomeIcon icon={faEye} />,
                        tooltip: 'View References',
                    },
                ]}
                columns={[
                    { title: 'Name', field: 'name', defaultSort: 'asc' },
                    { title: 'Description', field: 'description' },
                    { title: 'Can be used on', field: 'useType' },
                ]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{ pageSize: rowsPerPage, pageSizeOptions: [25, 50, 100], search: false }}
            />
            <FiltersModal open={openFiltersModal} onClose={() => setOpenFiltersModal(false)} />
            <Dialog scroll="paper" open={!whereUsedLoading && showUsageModal}>
                <WhereUsedModal
                    onClose={() => setShowUsageModal(false)}
                    onDelete={handleDelete}
                    title={`Usage for ${selectedItem?.name}`}
                    canDelete={pagePermissions?.Expressions.Delete || false}
                    usageData={whereUsedData?.expressionWhereUsed?.dependencies || []}
                    permissions={pagePermissions}
                />
            </Dialog>
        </div>
    );
};

export default Expressions;
