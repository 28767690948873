import React, { useState } from 'react';
import { TextField, Box, Button, InputLabelProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { UseFormRegister } from 'react-hook-form';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { ResetPasswordFormInput } from '../types';

const useStyles = makeStyles()({
    inputField: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconButton: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        background: 'inherit',
        cursor: 'pointer',
    },
});

type PasswordInputProps = {
    error: boolean;
    errorText?: string;
    register: UseFormRegister<ResetPasswordFormInput>;
    name: keyof ResetPasswordFormInput;
    label: string;
    inputLabelProps?: InputLabelProps;
};

const PasswordInput: React.FC<PasswordInputProps> = props => {
    const { error, errorText, register, name, label, inputLabelProps } = props;
    const { classes } = useStyles();
    const [visible, setVisible] = useState(false);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const handleClickShowPassword = () => {
        setVisible(prev => !prev);
    };
    return (
        <TextField
            variant="outlined"
            label={label}
            type={visible ? 'text' : 'password'}
            fullWidth
            error={error}
            helperText={errorText}
            InputLabelProps={inputLabelProps}
            InputProps={{
                endAdornment: (
                    <Box className={classes.inputField}>
                        <Button
                            className={classes.iconButton}
                            size="small"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {visible ? (
                                <VisibilityOffOutlined fontSize="small" />
                            ) : (
                                <VisibilityOutlined fontSize="small" />
                            )}
                        </Button>
                    </Box>
                ),
            }}
            {...register(name)}
        />
    );
};

export default PasswordInput;
