import {
    Avatar,
    Badge,
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Fade,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import {
    AddPhotoAlternateOutlined,
    Close,
    Launch,
    People,
    PersonAdd,
    SendOutlined,
} from '@mui/icons-material';
import { FileInfo } from '@uploadcare/react-widget';
import _ from 'lodash';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import useUserPermissions from '~/hooks/useUserPermissions';
import {
    AlertSeverity,
    GetChatRoomInput,
    FetchChatRoomMessagesForMessageCenterDocument,
    FetchChatRoomMessagesForMessageCenterQuery,
    FetchChatRoomMessagesForMessageCenterQueryVariables,
    FetchChatRoomsForMessageCenterDocument,
    FetchChatRoomsForMessageCenterQuery,
    FetchCurrentUserForUseUserHookDocument,
    NewMessageInput,
    UpdateParticipantsForChatRoomMutationVariables,
    useCreateChatMessageForMessageCenterMutation,
    useFetchCurrentUserForChatMessagesQuery,
    useSubscribeToNewChatMessagesForChatRoomSubscription,
    useUpdateParticipantsForChatRoomMutation,
    useUpdateReadMessagesForChatRoomMutation,
    ChatConversationsV2ForMessageCenterDocument,
    OrderByDirectionEnum,
    ChatConversationsV2ForMessageCenterQuery,
    ChatConversationsV2ForMessageCenterQueryVariables,
    ChatConversation,
    FetchChatRoomsForMessageCenterQueryVariables,
} from '~/schemaTypes';
import { SuppressNextGlobalAlert, TriggerGlobalAlert } from '~/state';
import { toBase64 } from '~/helpers/base64Helper';
import RichTextEditor from '~/components/RichTextEditor/RichTextEditor';
import { useAtom } from 'jotai';
import { refreshCognitoTokenAndInitSignOutWorker } from '~/utils/refreshCognitoAccessToken';
import Loading from '~/components/Loading/Loading';
import ChatConversations from './ChatConversations/ChatConversations';
import { conversationsLoadedAtom } from '../MessageCenter.context';

const useStyles = makeStyles()(theme => ({
    root: {
        display: 'grid',
        maxHeight: '85vh',
        gridTemplateRows: '65px 1fr auto',
        gridTemplateColumns: 'auto',
        gridTemplateAreas: `
            "title"
            "messages"
            "actions"
        `,
        '& .title': {
            borderBottom: '1px solid lightgray',
        },
        '& .messages': {
            overflowY: 'auto',
        },
        '& .actions': {
            borderTop: '1px solid lightgray',
        },
    },
    imagePreviews: {
        border: '2px solid lightgray',
        padding: 3,
        borderRadius: 15,
        height: 75,
        width: 75,
    },
    deletePhotoIcon: {
        background: '#fff',
        boxShadow: theme.shadows[1],
        transition: 'color .2s',
        '&:hover': {
            opacity: 1,
            background: '#fff',
            color: theme.colors.ErrorRed,
        },
    },
    followButton: {
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(3),
        color: theme.colors.Primary(),
        borderColor: theme.colors.Primary(),
        '&:hover': {
            color: theme.colors.Primary(),
            borderColor: theme.colors.Primary(),
            backgroundColor: alpha(theme.colors.Primary(), 0.05),
        },
    },
    unfollowButton: {
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(3),
        color: theme.colors.ErrorRed,
        borderColor: theme.colors.ErrorRed,
        '&:hover': {
            color: theme.colors.ErrorRed,
            borderColor: theme.colors.ErrorRed,
            backgroundColor: alpha(theme.colors.ErrorRed, 0.05),
        },
    },
    actionWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
}));

type ChatMessagesProps = {
    chatRoomData: FetchChatRoomMessagesForMessageCenterQuery['chatRoom'];
    getChatRoomInput?: GetChatRoomInput;
    fetchChatRoomsInput?: FetchChatRoomsForMessageCenterQueryVariables;
};

let timeoutId: number | undefined;

const debounce = (callback: () => void, delay: number) => {
    return () => {
        clearTimeout(timeoutId);
        timeoutId = window.setTimeout(callback, delay);
    };
};

const ChatMessages = ({
    chatRoomData,
    getChatRoomInput,
    fetchChatRoomsInput,
}: ChatMessagesProps) => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const [messageText, setMessageText] = useState<string>('');
    const [attachments, setAttachments] = useState<Array<{ uuid: string; label: string }>>([]);
    const [clearEditor, setClearEditor] = useState<boolean>(false);
    const messagesCont = useRef<HTMLDivElement>(null);
    const history = useNavigate();
    const chatRoomId = useMemo(() => chatRoomData?.id, [chatRoomData?.id]);
    const [chatConversationsLoaded] = useAtom(conversationsLoadedAtom);

    const { data: currentUserData } = useFetchCurrentUserForChatMessagesQuery();

    const refreshHandler = () => {
        refreshCognitoTokenAndInitSignOutWorker();
    };

    const isCurrentUserFollowTheConversation = useMemo(
        () =>
            !!chatRoomData?.participants.find(({ id }) => id === currentUserData?.currentUser?.id),
        [chatRoomData, currentUserData?.currentUser?.id],
    );

    const subscribeToNewMessagesInput: NewMessageInput = useMemo(() => {
        return {
            chatRoomId,
            ...(chatRoomId === '' && { chatRoomId: chatRoomData?.id }),
        };
    }, [chatRoomId, chatRoomData?.id]);

    useSubscribeToNewChatMessagesForChatRoomSubscription({
        variables: {
            input: subscribeToNewMessagesInput,
        },
        shouldResubscribe: true,
        onData: async ({ client, data }) => {
            const { cache } = client;
            const newOrUpdatedMessage = data.data?.newMessage;
            const chatRoomQuery = cache.readQuery<
                FetchChatRoomMessagesForMessageCenterQuery,
                FetchChatRoomMessagesForMessageCenterQueryVariables
            >({
                query: FetchChatRoomMessagesForMessageCenterDocument,
                variables: {
                    input: {
                        id: chatRoomId,
                    },
                },
            });
            if (chatRoomQuery?.chatRoom && newOrUpdatedMessage) {
                cache.writeQuery<FetchChatRoomMessagesForMessageCenterQuery>({
                    query: FetchChatRoomMessagesForMessageCenterDocument,
                    data: {
                        ...chatRoomQuery,
                        chatRoom: {
                            ...chatRoomQuery.chatRoom,
                            messages: [
                                ...chatRoomQuery.chatRoom.messages.map(currentMessage => {
                                    if (currentMessage.id === newOrUpdatedMessage.id) {
                                        return newOrUpdatedMessage;
                                    }
                                    return currentMessage;
                                }),
                                ...(!_.map(chatRoomQuery.chatRoom.messages, 'id').includes(
                                    newOrUpdatedMessage.id,
                                )
                                    ? [newOrUpdatedMessage]
                                    : []),
                            ],
                        },
                    },
                });
            }
            const messageCenterQuery = cache.readQuery<FetchChatRoomsForMessageCenterQuery>({
                query: FetchChatRoomsForMessageCenterDocument,
                variables: fetchChatRoomsInput,
            });
            if (messageCenterQuery?.currentUser?.chatRooms && newOrUpdatedMessage) {
                cache.writeQuery<FetchChatRoomsForMessageCenterQuery>({
                    query: FetchChatRoomsForMessageCenterDocument,
                    data: {
                        ...messageCenterQuery,
                        currentUser: {
                            ...messageCenterQuery.currentUser,
                            chatRooms: messageCenterQuery.currentUser?.chatRooms.map(chatRoom => {
                                if (chatRoom.id === chatRoomId) {
                                    return {
                                        ...chatRoom,
                                        latestMessage: newOrUpdatedMessage,
                                    };
                                }
                                return chatRoom;
                            }),
                        },
                    },
                });
            }
            if (newOrUpdatedMessage?.senderId !== currentUserData?.currentUser?.id) {
                // check if the user sent the message
                const chatConversationsQuery = cache.readQuery<
                    ChatConversationsV2ForMessageCenterQuery,
                    ChatConversationsV2ForMessageCenterQueryVariables
                >({
                    query: ChatConversationsV2ForMessageCenterDocument,
                    variables: {
                        input: {
                            filter: {
                                fields: {
                                    chatRoomId,
                                },
                            },
                            orderBy: {
                                field: 'createdAt',
                                order: OrderByDirectionEnum.Asc,
                            },
                        },
                    },
                });
                if (chatConversationsQuery?.chatConversationsV2.results && newOrUpdatedMessage) {
                    const chatConversation =
                        chatConversationsQuery.chatConversationsV2.results.find(
                            conversation =>
                                conversation.id === newOrUpdatedMessage.chatConversationId,
                        );
                    const updatedConversation = {
                        ...chatConversation,
                        ...(chatConversation?.startedAt == null
                            ? { startedAt: newOrUpdatedMessage.createdAt }
                            : {}),
                    } as ChatConversation;
                    const messageExists = updatedConversation?.messages?.find(
                        message => message.id === newOrUpdatedMessage.id,
                    );
                    let updatedMessages;
                    if (messageExists != null) {
                        updatedMessages = updatedConversation?.messages;
                    } else {
                        updatedMessages = updatedConversation?.messages
                            ? [...updatedConversation.messages, newOrUpdatedMessage]
                            : [newOrUpdatedMessage];
                    }
                    const updatedConversations = [
                        ...chatConversationsQuery.chatConversationsV2.results.filter(
                            res => res.id !== updatedConversation?.id,
                        ),
                        ...(updatedConversation != null
                            ? [
                                  {
                                      ...updatedConversation,
                                      messages: updatedMessages,
                                  },
                              ]
                            : []),
                    ].sort(
                        (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
                    );
                    cache.writeQuery<
                        ChatConversationsV2ForMessageCenterQuery,
                        ChatConversationsV2ForMessageCenterQueryVariables
                    >({
                        query: ChatConversationsV2ForMessageCenterDocument,
                        variables: {
                            input: {
                                filter: {
                                    fields: {
                                        chatRoomId,
                                    },
                                },
                                orderBy: {
                                    field: 'createdAt',
                                    order: OrderByDirectionEnum.Asc,
                                },
                            },
                        },
                        data: {
                            ...chatConversationsQuery,
                            chatConversationsV2: {
                                ...chatConversationsQuery.chatConversationsV2,
                                results: updatedConversations,
                            },
                        },
                    });
                }
            }
        },
    });

    const [sendMessage, { loading: sendMessageLoading }] =
        useCreateChatMessageForMessageCenterMutation({
            onCompleted: data => {
                if (data.createChatMessageEncoded?.success) {
                    setMessageText('');
                    setAttachments([]);
                }
            },
            update: (cache, response) => {
                const newMessage = response.data?.createChatMessageEncoded?.chatMessage;
                if (newMessage) {
                    if (getChatRoomInput) {
                        const messageCenterQuery =
                            cache.readQuery<FetchChatRoomMessagesForMessageCenterQuery>({
                                query: FetchChatRoomMessagesForMessageCenterDocument,
                                variables: {
                                    input: getChatRoomInput,
                                },
                            });
                        if (messageCenterQuery?.chatRoom?.id && newMessage) {
                            const alreadyInCache = messageCenterQuery.chatRoom?.messages.find(
                                msg => msg.id === newMessage.id,
                            );
                            cache.writeQuery<FetchChatRoomMessagesForMessageCenterQuery>({
                                query: FetchChatRoomMessagesForMessageCenterDocument,
                                variables: { input: getChatRoomInput },
                                data: {
                                    ...messageCenterQuery,
                                    chatRoom: {
                                        ...messageCenterQuery.chatRoom,
                                        messages: alreadyInCache
                                            ? messageCenterQuery.chatRoom.messages
                                            : [...messageCenterQuery.chatRoom.messages, newMessage],
                                    },
                                },
                            });
                        }
                    }
                    const chatConversationsQuery =
                        cache.readQuery<ChatConversationsV2ForMessageCenterQuery>({
                            query: ChatConversationsV2ForMessageCenterDocument,
                            variables: {
                                input: {
                                    filter: {
                                        fields: {
                                            chatRoomId,
                                        },
                                    },
                                    orderBy: {
                                        field: 'createdAt',
                                        order: OrderByDirectionEnum.Asc,
                                    },
                                },
                            },
                        });
                    if (chatConversationsQuery?.chatConversationsV2.results && newMessage) {
                        const chatConversation =
                            chatConversationsQuery.chatConversationsV2.results.find(
                                conversation => conversation.id === newMessage.chatConversationId,
                            );
                        const updatedMessages = chatConversation?.messages
                            ? [...chatConversation.messages, newMessage]
                            : [newMessage];
                        cache.writeQuery<ChatConversationsV2ForMessageCenterQuery>({
                            query: ChatConversationsV2ForMessageCenterDocument,
                            variables: {
                                input: {
                                    filter: {
                                        fields: {
                                            chatRoomId,
                                        },
                                    },
                                    orderBy: {
                                        field: 'createdAt',
                                        order: OrderByDirectionEnum.Asc,
                                    },
                                },
                            },
                            data: {
                                ...chatConversationsQuery,
                                chatConversationsV2: {
                                    ...chatConversationsQuery.chatConversationsV2,
                                    results: [
                                        ...chatConversationsQuery.chatConversationsV2.results.filter(
                                            res => res.id !== chatConversation?.id,
                                        ),
                                        ...(chatConversation != null
                                            ? [
                                                  {
                                                      ...chatConversation,
                                                      messages: updatedMessages,
                                                  },
                                              ]
                                            : []),
                                    ],
                                },
                            },
                        });
                    }
                }
            },
        });

    const [updateReadMessages] = useUpdateReadMessagesForChatRoomMutation({
        refetchQueries: [{ query: FetchCurrentUserForUseUserHookDocument }],
    });

    const [updateParticipants, { loading: isUpdatingParticipantsLoading }] =
        useUpdateParticipantsForChatRoomMutation({
            update: (cache, response) => {
                const updatedParticipants =
                    response.data?.updateParticipants?.chatRoom?.participants;
                if (updatedParticipants && getChatRoomInput) {
                    const chatRoomQuery = cache.readQuery<
                        FetchChatRoomMessagesForMessageCenterQuery,
                        FetchChatRoomMessagesForMessageCenterQueryVariables
                    >({
                        query: FetchChatRoomMessagesForMessageCenterDocument,
                        variables: {
                            input: getChatRoomInput,
                        },
                    });
                    if (chatRoomQuery?.chatRoom) {
                        cache.writeQuery<
                            FetchChatRoomMessagesForMessageCenterQuery,
                            FetchChatRoomMessagesForMessageCenterQueryVariables
                        >({
                            query: FetchChatRoomMessagesForMessageCenterDocument,
                            data: {
                                ...chatRoomQuery,
                                chatRoom: {
                                    ...chatRoomQuery?.chatRoom,
                                    participants: updatedParticipants,
                                },
                            },
                        });
                    }
                }
            },
        });

    const onFollowButtonClick = useCallback(() => {
        const input: UpdateParticipantsForChatRoomMutationVariables = {
            input: {
                chatRoomId,
                participantId: currentUserData?.currentUser?.id,
                isIntendToFollow: !isCurrentUserFollowTheConversation,
            },
        };
        updateParticipants({ variables: input });
    }, [
        chatRoomId,
        currentUserData?.currentUser?.id,
        isCurrentUserFollowTheConversation,
        updateParticipants,
    ]);

    useEffect(() => {
        if (
            chatRoomId &&
            _.map(chatRoomData?.participants, 'id').includes(currentUserData?.currentUser?.id ?? '')
        ) {
            SuppressNextGlobalAlert(true);
            updateReadMessages({
                variables: {
                    input: {
                        chatRoomId,
                    },
                },
            });
        }
    }, [
        chatRoomData?.participants,
        chatRoomId,
        currentUserData?.currentUser?.id,
        updateReadMessages,
    ]);

    const handleSendMessage = (e: any) => {
        e.preventDefault();
        if (!messageText) {
            TriggerGlobalAlert({
                message: 'Cannot send empty message',
                severity: AlertSeverity.Warning,
            });
            return;
        }
        const encodedMessage = toBase64(messageText);
        SuppressNextGlobalAlert(true);
        sendMessage({
            variables: {
                input: {
                    text: encodedMessage,
                    chatRoomId,
                    attachments: _.map(attachments, 'uuid'),
                },
            },
        });
        setClearEditor(true);
    };

    useLayoutEffect(() => {
        if (messagesCont?.current) {
            messagesCont.current.scrollTo(0, messagesCont.current.scrollHeight);
        }
    }, [chatConversationsLoaded, chatRoomId]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onFileUpload = (file: FileInfo) => {
        const newAttachment = file.uuid
            ? {
                  uuid: file.uuid,
                  label: file.name || 'new-file',
              }
            : null;
        if (newAttachment) {
            setAttachments(prevAttachments => [...prevAttachments, newAttachment]);
        }
    };

    const handleDeleteAttachment = (id: string) => {
        if (id) {
            const filteredAttachments = attachments.filter(item => item.uuid !== id);
            setAttachments(filteredAttachments);
        }
    };

    if (chatRoomId == null) {
        return <Loading subtitle="Fetching chatRoomId" />;
    }

    return (
        <div className={`${classes.root} main`}>
            <CardHeader
                className="title"
                title={`${chatRoomData?.patient.firstName} ${chatRoomData?.patient.lastName} (${chatRoomData?.patient?.practice?.name} - ${chatRoomData?.careTeamMemberType.name})`}
                action={
                    <div className={classes.actionWrapper}>
                        {pagePermissions?.PatientDetails.Read && (
                            <Tooltip title="View Patient">
                                <IconButton
                                    aria-label="view patient"
                                    onClick={() => {
                                        history(
                                            `/portal/patients/${chatRoomData?.patient.id}/patient-details`,
                                        );
                                    }}
                                    size="large"
                                >
                                    <Launch />
                                </IconButton>
                            </Tooltip>
                        )}
                        {pagePermissions?.MessageCenter.Edit && (
                            <AsyncActionButton loading={isUpdatingParticipantsLoading}>
                                <Button
                                    variant="outlined"
                                    startIcon={<PersonAdd />}
                                    className={
                                        isCurrentUserFollowTheConversation
                                            ? classes.unfollowButton
                                            : classes.followButton
                                    }
                                    onClick={onFollowButtonClick}
                                    disabled={isUpdatingParticipantsLoading}
                                >
                                    {isCurrentUserFollowTheConversation ? 'Unfollow' : 'Follow'}
                                </Button>
                            </AsyncActionButton>
                        )}
                        <Tooltip
                            title={
                                <Grid container direction="column" style={{ padding: 10 }}>
                                    <Typography variant="h6">Participants:</Typography>
                                    <Divider />
                                    <List dense disablePadding>
                                        {chatRoomData?.participants.map(
                                            ({ id, name }, i, participants) => (
                                                <React.Fragment key={id}>
                                                    <ListItem>
                                                        <ListItemText primary={name} />
                                                    </ListItem>
                                                    {participants.length - 1 > i && <Divider />}
                                                </React.Fragment>
                                            ),
                                        )}
                                    </List>
                                </Grid>
                            }
                        >
                            <IconButton aria-label="participants" size="large">
                                <People />
                            </IconButton>
                        </Tooltip>
                    </div>
                }
            />
            <CardContent className="messages" ref={messagesCont}>
                <ChatConversations
                    chatRoom={chatRoomData ?? null}
                    currentUserId={currentUserData?.currentUser?.id}
                    chatRoomId={chatRoomId}
                />
            </CardContent>
            <CardActions className="actions">
                <form style={{ width: '100%' }} onSubmit={handleSendMessage}>
                    <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                        <Grid item xs={8} sm={9} lg={10}>
                            <RichTextEditor
                                initialValue={messageText}
                                onChange={(value: string) => {
                                    debounce(refreshHandler, 10000)();
                                    setMessageText(value);
                                }}
                                disabled={
                                    !pagePermissions?.MessageCenter.Edit ?? sendMessageLoading
                                }
                                clear={clearEditor}
                                setClear={setClearEditor}
                                allowImages={false}
                            />
                        </Grid>
                        <Grid item style={{ display: 'none' }}>
                            <IconButton
                                size="medium"
                                color="primary"
                                disabled={
                                    !pagePermissions?.MessageCenter.Edit ?? sendMessageLoading
                                }
                                onClick={() =>
                                    TriggerGlobalAlert({
                                        message: 'Not yet implemented',
                                        severity: AlertSeverity.Warning,
                                    })
                                }
                            >
                                <AddPhotoAlternateOutlined />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <AsyncActionButton loading={sendMessageLoading}>
                                <IconButton
                                    disabled={
                                        !pagePermissions?.MessageCenter.Edit ?? sendMessageLoading
                                    }
                                    type="submit"
                                    size="medium"
                                    color="primary"
                                >
                                    <SendOutlined />
                                </IconButton>
                            </AsyncActionButton>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={4} style={{ marginLeft: 5 }}>
                                {attachments.map(({ uuid, label }) => (
                                    <Fade key={uuid} in timeout={500}>
                                        <Grid item>
                                            <Badge
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                badgeContent={
                                                    <IconButton
                                                        className={classes.deletePhotoIcon}
                                                        size="small"
                                                        edge="end"
                                                        onClick={() => handleDeleteAttachment(uuid)}
                                                    >
                                                        <Close />
                                                    </IconButton>
                                                }
                                            >
                                                <Avatar
                                                    className={classes.imagePreviews}
                                                    variant="square"
                                                    src={`https://ucarecdn.com/${uuid}/`}
                                                    alt={label}
                                                />
                                            </Badge>
                                        </Grid>
                                    </Fade>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </CardActions>
        </div>
    );
};

export default ChatMessages;
